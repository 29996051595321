import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

import { addAuthVars } from "@teamrota/authlib";

export const VALIDATE_PAYROLL_RATE = addAuthVars(gql`
  mutation validatePayrollRate($name: String!, $config: [JSONObject]!) {
    validatePayrollRate(name: $name, config: $config)
  }
`);

export const useValidatePayrollRate = () => {
  const [update, { loading, error }] = useMutation(VALIDATE_PAYROLL_RATE, {
    onError: error => {
      throw error;
    }
  });
  const validatePayrollRate = async ({
    name,
    config
  }: {
    name: string;
    config: JSON;
  }) => {
    await update({
      variables: {
        name,
        config
      }
    });
  };

  return { validatePayrollRate, loading, error };
};

import { graphql } from "@apollo/client/react/hoc";
import { gql } from "@apollo/client";
import { set, findIndex, flow, getOr, filter, get } from "lodash/fp";

import { ZonedDate } from "@teamrota/rota-common";
import { addAuthVars } from "@teamrota/authlib";

import { APPROVAL_STATES } from "~/src/consts";
import useAuth from "~/src/auth/hooks/use-auth";
import { TimesheetBookingFragment } from "./get-timesheet-bookings";

export const APPROVE_SHIFT = addAuthVars(gql`
  ${TimesheetBookingFragment}
  mutation approveShift(
    $shiftId: ID!
    $bookings: [BookingInputType]!
    $sourceAccountId: ID
    $isApproved: Boolean
  ) {
    shift: approveShift(
      id: $shiftId
      bookings: $bookings
      isApproved: $isApproved
    ) {
      id
      bookings(stateEquals: ACCEPTED, offset: 0, limit: 100) {
        data {
          ...TimesheetBookingFragment
        }
      }
    }
  }
`);

export default graphql(APPROVE_SHIFT, {
  name: "approveShift",
  props: ({ approveShift, ownProps }) => {
    const auth = useAuth();

    return {
      approveShift: (shiftId, bookings) =>
        approveShift({
          variables: auth.addVals(APPROVE_SHIFT, {
            shiftId,
            bookings: bookings.map(booking => ({
              id: booking.id,
              startOnApproval: booking.startOnApproval,
              endOnApproval: booking.endOnApproval,
              breakOnApproval: booking.breakOnApproval,
              ratingOfMember: booking.ratingOfMember,
              isNoShow: booking.isNoShow,
              isFavourited: booking.isFavourited,
              feedbackForMember: booking.feedbackForMember
            })),
            sourceAccountId: get(
              "sourceAccount.id",
              ownProps.shifts.find(({ id }) => id === ownProps.openTimesheetId)
            )
          }),
          updateQueries: {
            getShifts(
              currentResult,
              {
                mutationResult: {
                  data: { shift }
                }
              }
            ) {
              const shiftIndex = findIndex(
                { id: shift.id },
                currentResult.account.shifts.data
              );

              return flow(
                set(
                  `account.shifts.data[${shiftIndex}].approvalState`,
                  APPROVAL_STATES.APPROVED
                ),
                set(
                  `account.shifts.data[${shiftIndex}].reviewedAt`,
                  new ZonedDate()
                )
              )(currentResult);
            },
            getProfile: (
              currentResult,
              {
                mutationResult: {
                  data: { shift }
                }
              }
            ) => {
              const pathToTimesheetCount = "user.account.outstandingTimesheets";

              return set(
                pathToTimesheetCount,
                filter(
                  currShift => currShift.id !== shift.id,
                  getOr([], pathToTimesheetCount, currentResult)
                ),
                currentResult
              );
            },
            getTimesheetBookings(
              currentResult,
              {
                mutationResult: {
                  data: { shift }
                }
              }
            ) {
              return set(
                "account.shift.bookings.data",
                shift.bookings.data,
                currentResult
              );
            }
          }
        })
    };
  }
});

import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const NotifierNumber = styled.span`
  font-size: ${({ isSmall }) =>
    isSmall ? STYLE_CONSTS.FONT_XXSMALL : STYLE_CONSTS.FONT_XSMALL};
  color: ${({ theme }) => theme.white};
  height: ${({ isSmall }) =>
    isSmall ? STYLE_CONSTS.UNIT_3 : STYLE_CONSTS.UNIT_4};
  line-height: ${({ isSmall }) =>
    isSmall ? STYLE_CONSTS.UNIT_3 : STYLE_CONSTS.UNIT_4};
  width: ${({ isSmall }) =>
    isSmall ? STYLE_CONSTS.UNIT_3 : STYLE_CONSTS.UNIT_4};
  display: inline-block;
  border-radius: ${STYLE_CONSTS.FONT_XLARGE};
  text-align: center;
  background-image: ${STYLE_CONSTS.BACKGROUND_GRADIENT};
`;

import React, { Component } from "react";
import { curry } from "lodash";
import asyncConfirm from "~/src/utils/async-confirm";
import Icon from "../icon";
import { StarsContainer, IconWrapper, Rating } from "./stars.styles";

// return async confirm string and config option
export const buildModalConfig = (upComingShift, title) => {
  const modalText = upComingShift
    ? `This member is accepted onto ${upComingShift} upcoming shifts. This will cancel all accepted shifts.`
    : "This member has no more upcoming shifts with you.";
  return {
    title,
    body: {
      subText:
        !upComingShift && title.match("removed")
          ? "This member will now be available for shifts"
          : modalText,
      confirmButtonText: "Confirm",
      isConfirmButtonGreen: true
    }
  };
};

export default class Stars extends Component {
  state = {
    hoverIndex: null
  };

  // if an ID has been passed down to the component it is due to the
  // star sticking issue when user attempts to edit star rating
  componentDidMount() {
    if (this.props.id) {
      this.attachEventListener(this.props.id);
    }
  }

  componentWillUnmount() {
    if (this.props.id) {
      this.starContainer.removeEventListener("mouseover", this.setStarOffset);
      document.removeEventListener("mousemove", this.setMouseListener);
    }
  }

  getValue = () => {
    if (!this.props.value) {
      return 0;
    }
    return Math.round(this.props.value * 10) / 10;
  };

  // @BUG : Issue with React render and mouseover event not being captured 100% of the time
  // Once user hovers over stars for the first time get the position of the stars
  // and add event listener to capture element when leaving and reset hoverIndex state
  setStarOffset = e => {
    if (e) {
      this.starContainerCoords = e;
      document.addEventListener("mousemove", this.setMouseListener);
    }
  };

  // Determine when the mouse cursor leaves the star container element
  // and reset the hoverIndex to null again to remove stars highlighted
  // on hover
  setMouseListener = e => {
    const EXTRA_PX_DISTANCE = 20;
    const STAR_HEIGHT = 24;
    const { screenX: starRight, screenY: starTop } = this.starContainerCoords;
    if (
      e.screenX > starRight + EXTRA_PX_DISTANCE ||
      e.screenY > starTop + EXTRA_PX_DISTANCE ||
      e.screenY < starTop - STAR_HEIGHT
    ) {
      this.setState({ hoverIndex: null });
    }
  };

  // attach initial event listener to the star container on component
  // mount
  attachEventListener = elementId => {
    this.starContainer = document.getElementById(elementId);
    this.starContainer.addEventListener("mouseover", this.setStarOffset);
  };

  async processRatingChange(indexOfSelectedStar) {
    const { props } = this;
    const { upcomingAccepted } = props;
    const modalMessage = curry(buildModalConfig)(upcomingAccepted);

    if (!props.id || !props.isMember) {
      return true;
    }

    // @NOTE Checking for type object as memberRating may be null if not
    // set in the member app, however need to check for presence of
    // memberRating on prop as only star logic for member should display
    // blacklist modal based on user prvoided star score
    if (props.memberRating !== undefined) {
      const memberRating = props.memberRating || 6;

      if (props.isMember && indexOfSelectedStar < 3 && memberRating <= 3) {
        return true;
      }

      if (props.isMember && indexOfSelectedStar < 3 && memberRating >= 3) {
        const modalData = modalMessage("Member will be blacklisted");
        return asyncConfirm(modalData.title, modalData.body);
      }

      if (props.isMember && indexOfSelectedStar > 3 && memberRating <= 3) {
        const modalData = modalMessage("Member will be removed from blacklist");
        return asyncConfirm(modalData.title, modalData.body);
      }

      if (props.isMember && indexOfSelectedStar > 3 && memberRating >= 3) {
        return true;
      }

      if (props.isMember && indexOfSelectedStar === 3) {
        return true;
      }
    }

    return false;
  }

  async handleClickOverrideLogic(index) {
    const { props } = this;
    if (
      (await this.processRatingChange(index)) &&
      props.onChange &&
      props.isEditable
    ) {
      props.onChange(index + 1);
    }
  }

  render() {
    const { props, state } = this;
    const starData = Array(6)
      .fill(null)
      .map((_, index) =>
        state.hoverIndex !== null
          ? index < state.hoverIndex
          : index < Math.round(props.value)
      );

    return (
      <StarsContainer
        id={props.id}
        isDisabled={props.isDisabled}
        isEditable={props.isEditable}
        isGrey={props.isGrey}
      >
        {starData.map((isActive, index) => (
          <IconWrapper
            onMouseOver={() =>
              this.setState({
                hoverIndex:
                  props.isDisabled || !props.isEditable ? null : index + 1
              })
            }
            onMouseLeave={() => this.setState({ hoverIndex: null })}
            onClick={() =>
              props.shouldOverrideClick
                ? this.handleClickOverrideLogic(index)
                : props.onChange &&
                  props.isEditable &&
                  props.onChange(index + 1)
            }
            key={index}
            isSmall={props.isSmall}
          >
            <Icon
              name={isActive ? Icon.names.STAR_FILLED : Icon.names.STAR}
              size={props.isSmall ? 14 : 20}
            />
          </IconWrapper>
        ))}
        {props.showRating ? <Rating>{`(${this.getValue()})`}</Rating> : null}
      </StarsContainer>
    );
  }
}

import React, { useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { isEqual } from "lodash";
import moment from "moment-timezone";
import { useLazyQuery, useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";

import { ZonedDate } from "@teamrota/rota-common";
import { RotaButton, RotaSnackBar } from "@teamrota/rota-design";
import { Role } from "@teamrota/authlib";

import useAuth from "~/src/auth/hooks/use-auth";
import HasRole from "~/src/containers/has-role";
import { errorModal } from "~/src/utils/errors";

import ShiftDetailsComponent from "./shift-details";
import { getValuesFromProps } from "./shift-details/schema";
import { GET_MEMBERS_OVER_LIMIT } from "./shift-details/graphql";
import { CREATE_OR_UPDATE_SHIFT } from "./graphql/create-or-update-shift";
import { validationSchema } from "./shift-details/validate";
import { reducer } from "./reducer";
import { Form, Row, Section } from "./styles";

import ShiftUpdateMemberConflict from "../shift-update-member-conflict";

const initialState = {
  inEditMode: false,
  isSnackOpen: false,
  isSaving: false,
  hasSaved: false,
  errorMessage: ""
};

const changeDateTimeToTimeString = date =>
  moment(date)
    .format("HH:mm")
    .toString();

const changeDateTimeToDateString = date =>
  moment(date)
    .format("YYYY-MM-DD")
    .toString();

const getDates = data => {
  return {
    startTime: new ZonedDate(`${data.startDate} ${data.startTime}`),
    endTime: new ZonedDate(`${data.endDate} ${data.endTime}`)
  };
};

const ViewEditShiftFormComponent = props => {
  const [warning, setWarning] = useState(null);

  const [
    { inEditMode, isSnackOpen, isSaving, hasSaved, errorMessage },
    dispatch
  ] = useReducer(reducer, initialState);

  const [createOrUpdateShift] = useMutation(CREATE_OR_UPDATE_SHIFT, {
    refetchQueries: ["getShift"]
  });

  const auth = useAuth();
  const userCanEdit = auth.hasRole(Role.SHIFTS_EDIT);
  const valuesFromApi = getValuesFromProps(props);
  const startDate = changeDateTimeToDateString(valuesFromApi?.startTime);
  const endDate = changeDateTimeToDateString(valuesFromApi?.endTime);
  const startTime = changeDateTimeToTimeString(valuesFromApi?.startTime);
  const endTime = changeDateTimeToTimeString(valuesFromApi?.endTime);
  const initialValues = {
    ...valuesFromApi,
    startDate,
    startTime,
    endTime,
    endDate
  };
  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
    reset
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "onChange"
  });

  const [getMembersOverLimit] = useLazyQuery(GET_MEMBERS_OVER_LIMIT, {
    fetchPolicy: "cache-and-network",
    onError: error => {
      errorModal(error);
    },
    onCompleted: async data => {
      if (data?.shiftMembersOverLimit?.length > 0) {
        setWarning({
          members: data?.shiftMembersOverLimit,
          callback: makeUpdates
        });
      } else {
        makeUpdates();
      }
    }
  });

  const handleOnSave = async data => {
    const currentDates = getDates(data);
    const lastDates = getDates({
      startTime,
      endTime,
      startDate,
      endDate
    });

    if (!isEqual(currentDates, lastDates)) {
      await getMembersOverLimit({
        variables: {
          shiftId: data.shiftId,
          dates: currentDates
        }
      });
    } else {
      makeUpdates();
    }
  };

  const makeUpdates = async () => {
    const data = getValues();
    const dates = getDates(data);
    const updatedData = { ...data, ...dates };
    try {
      dispatch({ type: "SUBMIT_START" });
      await createOrUpdateShift({
        variables: { ...updatedData, id: data.shiftId }
      });
      dispatch({ type: "SUBMIT_END" });
    } catch (e) {
      errorModal(e);
      dispatch({
        type: "SUBMIT_ERROR",
        errorMessage:
          e?.message || "An error occured, please check your changes."
      });
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(handleOnSave)}>
        <Section>
          <ShiftDetailsComponent
            {...props}
            register={register}
            errors={errors}
            initialValues={initialValues}
            shift={props.shift}
            control={control}
            inEditMode={inEditMode && userCanEdit}
            setValue={setValue}
            clearErrors={clearErrors}
          />
        </Section>
        <Row style={{ margin: "20px 0", justifyContent: "end" }}>
          {!inEditMode && userCanEdit && (
            <HasRole role={Role.SHIFTS_EDIT}>
              <RotaButton
                onClick={() => dispatch({ type: "SET_EDIT_MODE_ON" })}
                variant="outlined"
                size="small"
                text="Edit"
                colors="grey"
                style={{ marginBottom: 10 }}
              >
                Edit
              </RotaButton>
            </HasRole>
          )}
          {inEditMode && (
            <RotaButton
              onClick={() => {
                reset();
                dispatch({ type: "SET_EDIT_MODE_OFF" });
              }}
              variant="outlined"
              size="small"
              colors="grey"
              style={{ marginRight: "10px" }}
            >
              Cancel
            </RotaButton>
          )}
          {inEditMode && userCanEdit && (
            <RotaButton size="small" disabled={isSaving} type="submit">
              {hasSaved ? "Saved" : "Save"}
            </RotaButton>
          )}
        </Row>
      </Form>

      <RotaSnackBar
        snackOpen={isSnackOpen}
        onClose={() => {
          dispatch({ type: "CLOSE_SNACK" });
        }}
        message={errorMessage || "Success"}
        severity={errorMessage ? "error" : "success"}
      />
      <ShiftUpdateMemberConflict
        warning={warning}
        onClose={() => {
          setWarning(null);
        }}
      />
    </>
  );
};

export default ViewEditShiftFormComponent;

/* eslint-disable import/no-unassigned-import */
import React from "react";
import ReactDOM from "react-dom";
import "isomorphic-fetch";
import smoothscroll from "smoothscroll-polyfill";

import Root from "./root";

smoothscroll.polyfill();

const renderApp = AppRoot => {
  ReactDOM.render(<AppRoot />, document.getElementById("root"));
};

renderApp(Root);
if (module.hot) {
  // eslint-disable-next-line
  module.hot.accept("./root", () => renderApp(require("./root").default));
}

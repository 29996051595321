import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";

import MemberRoleFragment from "~/src/graphql/fragments/member-role";
import AccountRoleFragment from "~/src/graphql/fragments/account-role";

const GET_MEMBER_ROLES = gql`
  ${MemberRoleFragment}
  ${AccountRoleFragment}
  query getMemberRoles($memberId: ID!) {
    account {
      id
      roles {
        ...AccountRoleFragment
      }
      member(id: $memberId) {
        id
        roles {
          ...MemberRoleFragment
        }
      }
    }
  }
`;

export const useMemberRoles = ({ memberId }) => {
  const { data, loading, error, refetch } = useQuery(GET_MEMBER_ROLES, {
    fetchPolicy: "cache-and-network",
    variables: { memberId },
    onError: error => {
      throw error;
    }
  });

  const accountRoles = data?.account?.roles;
  const memberRoles = data?.account?.member?.roles;

  // allRoles is an array of merged accountRoles and memberRoles with additional flag isEnabledForMember
  // and following properies: name, defaultPayRate, isDayRate overriden by corresponding properies of memberRole
  const allRoles = accountRoles?.map(role => {
    const matchingMemberRole = memberRoles?.find(
      memberRole => memberRole.roleId === role.id
    );

    if (!matchingMemberRole) return { ...role, isEnabledForMember: false };
    return { ...role, ...matchingMemberRole, isEnabledForMember: true };
  });

  return {
    allRoles,
    memberRoles,
    loading,
    error,
    refetch
  };
};

import styled from "styled-components";
import { COLORS } from "~/src/styles/config.style";

export const CountDown = styled.div`
  color: ${({ theme }) => theme.primary.main};
`;

export const Remaining = styled.div`
  color: ${COLORS.COOL_GREY};
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

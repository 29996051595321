import styled from "styled-components";

export const CalendarContainer = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.white};
  min-height: 100%;
  cursor: crosshair;
  overflow-x: hidden;
  overflow-y: auto;
`;

import moment from "moment-timezone";
import { ACCOUNT_TIMEZONE } from "~/src/consts";

export const getDefaultShiftStartTime = selectedDay => {
  const result = moment(selectedDay, ACCOUNT_TIMEZONE);
  const now = moment.tz(new Date(), ACCOUNT_TIMEZONE);

  result.hour(now.hour());
  result.minute(now.minute() + 15);
  result.second(0);
  result.millisecond(0);

  return result;
};

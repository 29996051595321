import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";

const ASSIGN_MEMBER_TO_SHIFT = gql`
  mutation createBookings($shiftId: ID!, $members: [memberBookingType]!) {
    createBookings(members: $members, shiftId: $shiftId) {
      id
    }
  }
`;

const useAssignMemberToShift = () => {
  const [assignMember, { loading, error }] = useMutation(
    ASSIGN_MEMBER_TO_SHIFT,
    {
      refetchQueries: ["getSchedules"]
    }
  );

  const assignMemberToShift = useCallback(
    ({ shiftId, memberId }) =>
      assignMember({
        variables: {
          members: [{ id: memberId, isAssigned: true }],
          shiftId
        }
      }),
    [assignMember]
  );

  return { assignMemberToShift, loading, error };
};

export default useAssignMemberToShift;

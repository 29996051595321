import React, { useEffect, useState } from "react";
import {
  RotaButton,
  iconNew,
  RotaSwitch,
  RotaCheckbox
} from "@teamrota/rota-design";
import { connect } from "react-redux";

import { useLazyQuery } from "@apollo/client";

import map from "lodash/fp/map";
import compose from "lodash/fp/compose";
import moment from "moment-timezone";

import { AVAILABILITY_TYPES, SHIFT_TYPES } from "~/src/consts";
import { errorModal } from "~/src/utils/errors";
import { DATE_FORMAT } from "~/src/containers/requester-calendar/ShiftCreateModal/selectors";
import Calendar from "~/src/components/calendar";
import TimeDateInput from "~/src/components/form-components/time-date-input";
import MemberAddWarningModal from "~/src/components/StaffZonesPanel/components/MemberAddWarningModal";

import FormStepSegment from "../FormStepSegment";
import { useValidateCalendar } from "../shared";

import { GET_MEMBER } from "./graphql";

import { mapStateToProps, mapDispatchToProps, getFormTitle } from "./selectors";

import { useUpdateTime } from "~/src/utils/useUpdateTime.js";

import {
  LinkedShiftCheckbox,
  DateTitle,
  DateTime,
  Group,
  Item,
  ScrollerContainer,
  Scroller,
  TableHeader,
  BreakInputWrapper,
  BreakWrapper,
  BreakMinText,
  ErrorText,
  AdditionalNoteText,
  DayOrNightShiftWrapper
} from "./form-calendar.styles";

import { Actions, Header, HeaderTitle, EditButton } from "../styles";
import useAuth from "~/src/auth/hooks/use-auth";
import { isSleepTimesValid } from "~/src/components/SleepTime/helpers";
import SleepTime from "~/src/components/SleepTime";
import { useHasBreaksConfig } from "~/src/graphql/queries/use-has-breaks-config/use-has-breaks-config";

const { Moon, Sun } = iconNew;

const formatTime = date => moment(date).format("HH:mm");

const FormCalendar = props => {
  const {
    isProviderScheduler,
    selectedAccountId,
    isOpen,
    stepNumber,
    hasVisited,
    goToSelf,
    selectedDates,
    setEndRangeDate,
    toggleDate,
    shift,
    goToNext,
    handleShiftUpdate,
    hasCompletedAllSteps,
    isAccountSleepTimeEnabled
  } = props;

  const auth = useAuth();

  const isCalendarValid = useValidateCalendar(props);
  const onUpdateTime = useUpdateTime(props);
  const [minutesDifference, setMinutesDifference] = useState(15);
  const [timeValue, setTimeValue] = useState("");
  const [memberWarning, setMemberWarning] = useState(null);

  const timeNow = moment();

  const shiftForm = props.shiftForm?.shifts?.[0];
  const { selectedSleepTimes, isSleepTimesEnabled } = shiftForm;
  const memberId = shiftForm?.assignedMemberIds?.[0];
  const roleRateId = shiftForm?.roleRateId;

  const dates = selectedDates?.map(({ startTime, endTime }) => ({
    startTime,
    endTime
  }));

  const [getMember, { data: memberData }] = useLazyQuery(GET_MEMBER, {
    variables: auth.addVals(GET_MEMBER, {
      selectedAccountId,
      id: memberId,
      dates,
      shiftType: shift?.type,
      roleRateId,
      venueId: shiftForm?.venueId,
      tagIds: shiftForm?.tags.map(({ id }) => id)
    }),
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    if (memberId && roleRateId) getMember();
  }, [memberId, roleRateId, selectedDates]);

  const getToggledDate = date => {
    toggleDate(date);
    const isToday = moment(date).isSame(timeNow.clone(), "day");

    if (isToday && timeValue) {
      const [hour, minute] = timeValue;
      const userInputtedStartTime = timeNow.clone().set({
        hour,
        minute,
        second: 0,
        millisecond: 0
      });
      const minutesDifference = userInputtedStartTime.diff(timeNow, "minutes");
      setMinutesDifference(minutesDifference);
    }
  };

  const handleUpdateTime = (dateType, date, val) => {
    onUpdateTime(dateType, date, val);
    setTimeValue(val);
  };

  const onChangeBreakMinutes = (event, key) => {
    const newTimes = { ...shift.times };

    newTimes[key] = {
      ...newTimes[key],
      breakMinutes: parseInt(event?.target?.value) || 0
    };

    handleShiftUpdate({
      times: newTimes
    });
  };

  const hasPassedFinalCheck = () => {
    if (shift?.isLinkedShifts) {
      let sortedArr = [
        ...selectedDates.sort((a, b) => {
          if (a.startTime < b.startTime) {
            return -1;
          }
          if (a.startTime > b.startTime) {
            return 1;
          }
          return 0;
        })
      ];

      let hasOverlappingShifts = false;
      while (sortedArr.length >= 2) {
        let first = sortedArr.shift();
        let second = sortedArr.shift();

        if (moment(first.endTime) > moment(second.startTime)) {
          hasOverlappingShifts = true;
        }

        if (sortedArr.length === 1) {
          let last = sortedArr.shift();
          if (moment(second.endTime) > moment(last.startTime)) {
            hasOverlappingShifts = true;
          }
        }
      }

      if (hasOverlappingShifts) {
        errorModal("A linked shift can not have overlapping times");
        return;
      }
    }

    const showMemberWarning =
      isMemberConflicted ||
      isMemberHitWorkingHoursLimit ||
      isMemberUnavailable ||
      isMemberUnqualified;

    if (member && showMemberWarning) {
      setMemberWarning({
        member,
        callback: () => goToNext()
      });
    } else {
      goToNext();
    }
  };

  const member = memberData?.account?.member;

  const isMemberHitWorkingHoursLimit = member?.isHitWorkingHoursLimit;

  const isMemberConflicted = member?.isConflicted;

  const isMemberAvailabilityEnabled =
    memberData?.account?.isMemberAvailabilityEnabled;

  const isMemberUnavailable =
    isMemberAvailabilityEnabled && member?.isUnavailable;

  const isMemberUnqualified =
    !member?.isQualifiedWithTags || !member?.isQualifiedWithRole;

  const availability =
    isMemberAvailabilityEnabled &&
    member?.memberAvailability
      ?.filter(({ shiftType }) =>
        [shift?.type, AVAILABILITY_TYPES.ALL].includes(shiftType)
      )
      ?.map(
        ({ startsAt, endsAt }) =>
          `${formatTime(startsAt)} - ${formatTime(endsAt)}`
      );

  const isOverLimitWorkError =
    !memberData?.account?.isOverLimitWorkAllowed &&
    member?.isHitWorkingHoursLimit;

  const isPastError = minutesDifference < 5;

  const onToggleShiftType = e => {
    const type = e.target.checked ? SHIFT_TYPES.DAY : SHIFT_TYPES.NIGHT;
    handleShiftUpdate({
      type
    });
  };

  const isSleepTimesError =
    isSleepTimesEnabled &&
    !isSleepTimesValid(selectedDates, selectedSleepTimes);

  const hasBreaksConfig = useHasBreaksConfig(shift);

  return (
    <FormStepSegment
      isOpen={isOpen}
      stepNumber={stepNumber}
      title={() => (
        <Header>
          <HeaderTitle>
            {hasVisited && isCalendarValid
              ? getFormTitle(props)
              : "Dates & Time"}
          </HeaderTitle>
          {hasVisited && !isOpen && (
            <EditButton onClick={goToSelf}>
              {isCalendarValid ? "Edit" : "Missing Info!"}
            </EditButton>
          )}
        </Header>
      )}
    >
      {() => (
        <div>
          <Group>
            <Item width="55%">
              <Calendar
                startDate={selectedDates?.[0]?.startTime || moment()}
                selectedDates={map("startTime")(selectedDates)}
                onDateClick={getToggledDate}
                onEndDateClick={setEndRangeDate}
              />
            </Item>
            {selectedDates.length > 0 && (
              <Item width="45%">
                <ScrollerContainer>
                  <DayOrNightShiftWrapper>
                    <span>Day or night shift</span>
                    <RotaSwitch
                      iconOn={<Sun />}
                      iconOff={<Moon />}
                      checked={shift.type === SHIFT_TYPES.DAY}
                      onChange={onToggleShiftType}
                    />
                  </DayOrNightShiftWrapper>

                  {isAccountSleepTimeEnabled && (
                    <SleepTime
                      selectedDates={selectedDates}
                      selectedSleepTimes={selectedSleepTimes}
                      handleShiftUpdate={handleShiftUpdate}
                      isSleepTimesEnabled={isSleepTimesEnabled}
                    />
                  )}

                  <Scroller>
                    <table cellPadding={0} cellSpacing={0}>
                      <tbody>
                        <tr>
                          <td />
                          <td>
                            <TableHeader>Start</TableHeader>
                          </td>
                          <td />
                          <td>
                            <TableHeader>End</TableHeader>
                          </td>
                          {!hasBreaksConfig && (
                            <td>
                              <TableHeader>Break</TableHeader>
                            </td>
                          )}
                        </tr>
                        {selectedDates.map((date, index) => (
                          <tr key={index}>
                            <td>
                              <DateTitle>
                                {moment(date.startTime).isSame(
                                  moment(date.endTime),
                                  "day"
                                )
                                  ? moment(date.startTime).format("Do MMM")
                                  : `${moment(date.startTime).format(
                                      "Do"
                                    )} - ${moment(date.endTime).format(
                                      "Do MMM"
                                    )}`}
                                :
                              </DateTitle>
                            </td>
                            <td>
                              <DateTime>
                                <TimeDateInput
                                  isSmall
                                  isBorderless
                                  type="time"
                                  onChange={val =>
                                    handleUpdateTime("startTime", date, val)
                                  }
                                  value={moment(date.startTime).format("HH:mm")}
                                />
                              </DateTime>
                            </td>
                            <td>
                              <p>-</p>
                            </td>
                            <td>
                              <DateTime style={{ marginLeft: "5px" }}>
                                <TimeDateInput
                                  isSmall
                                  isBorderless
                                  type="time"
                                  onChange={val =>
                                    onUpdateTime("endTime", date, val)
                                  }
                                  value={moment(date.endTime).format("HH:mm")}
                                />
                              </DateTime>
                            </td>
                            {!hasBreaksConfig && (
                              <td>
                                <BreakWrapper>
                                  <BreakInputWrapper>
                                    <TimeDateInput
                                      style={{ paddingLeft: "5px" }}
                                      isSmall
                                      isBorderless
                                      type="text"
                                      onChange={event =>
                                        onChangeBreakMinutes(
                                          event,
                                          moment(date.startTime).format(
                                            DATE_FORMAT
                                          )
                                        )
                                      }
                                      value={date.breakMinutes}
                                    />
                                  </BreakInputWrapper>
                                  <BreakMinText> min</BreakMinText>
                                </BreakWrapper>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    {isPastError && (
                      <ErrorText>
                        You can't create a shift in the past
                      </ErrorText>
                    )}

                    {isMemberConflicted && (
                      <ErrorText>This member has a conflict</ErrorText>
                    )}

                    {isMemberHitWorkingHoursLimit && (
                      <ErrorText>
                        This member is over weekly working limit
                      </ErrorText>
                    )}

                    {isMemberAvailabilityEnabled && isMemberUnavailable && (
                      <>
                        {availability.length == 0 && (
                          <ErrorText>
                            This member has no set availability for the shift
                            date(s)
                          </ErrorText>
                        )}

                        {availability.length > 0 && (
                          <ErrorText>
                            This member has only set availability between{" "}
                            {availability.join(", ")}
                          </ErrorText>
                        )}
                      </>
                    )}

                    {isMemberUnavailable && !isMemberAvailabilityEnabled && (
                      <ErrorText>This member is unavailable</ErrorText>
                    )}

                    <AdditionalNoteText>
                      Note: Start time must always come before end time.
                      {hasBreaksConfig && (
                        <> Break will be set automatically for this shift.</>
                      )}
                    </AdditionalNoteText>
                  </Scroller>
                </ScrollerContainer>
              </Item>
            )}
          </Group>

          <Actions isVertical>
            {selectedDates.length > 1 && (
              <LinkedShiftCheckbox>
                <RotaCheckbox
                  isChecked={shift?.isLinkedShifts}
                  onClick={() => {
                    handleShiftUpdate({
                      isLinkedShifts: !shift?.isLinkedShifts
                    });
                  }}
                  label="Post as linked shifts?"
                />
              </LinkedShiftCheckbox>
            )}

            <MemberAddWarningModal
              accountType={isProviderScheduler ? "provider" : "requester"}
              roleRateId={roleRateId}
              dates={dates}
              warning={memberWarning}
              onClose={() => setMemberWarning(null)}
              isMemberAvailabilityWarning={true}
            />

            {!memberData?.account?.isOverLimitWorkAllowed &&
            isMemberHitWorkingHoursLimit ? (
              <ErrorText>
                Linking {member?.firstName} {member?.lastName} to these shifts
                would take them over their weekly hour working limit
              </ErrorText>
            ) : (
              <RotaButton
                onClick={() => hasPassedFinalCheck()}
                disabled={
                  !isCalendarValid || isOverLimitWorkError || isSleepTimesError
                }
              >
                {hasCompletedAllSteps ? "Done" : "Next"}
              </RotaButton>
            )}
          </Actions>
        </div>
      )}
    </FormStepSegment>
  );
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  C => props => <C {...props} isCreate />
)(FormCalendar);

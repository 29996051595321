import React from "react";
import moment from "moment-timezone";
// eslint-disable-next-line import/no-unassigned-import
import "moment-duration-format";

import { TableHeader, DateTime, SleepTimeTable, ErrorMessage } from "./styles";
import TimeDateInput from "~/src/components/form-components/time-date-input";
import {
  getFormattedSleepTimeDuration,
  isSleepTimesValid,
  onSleepTimeChange
} from "./helpers";
import { RotaCheckbox } from "@teamrota/rota-design";

const SleepTime = ({
  selectedDates,
  selectedSleepTimes,
  handleShiftUpdate,
  isSleepTimesEnabled
}) => {
  const durationText = getFormattedSleepTimeDuration(selectedSleepTimes);

  return (
    <div>
      <RotaCheckbox
        label={"Add sleep times"}
        isChecked={isSleepTimesEnabled}
        onClick={() => {
          handleShiftUpdate({
            isSleepTimesEnabled: !isSleepTimesEnabled
          });
        }}
      />
      {isSleepTimesEnabled && (
        <div>
          <SleepTimeTable cellPadding={0} cellSpacing={0}>
            <tbody>
              <tr>
                <td />
                <td>
                  <TableHeader>Start</TableHeader>
                </td>
                <td />
                <td>
                  <TableHeader>End</TableHeader>
                </td>
                <td>
                  <TableHeader>Duration</TableHeader>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <DateTime>
                    <TimeDateInput
                      isSmall
                      isBorderless
                      type="time"
                      onChange={val =>
                        onSleepTimeChange(
                          handleShiftUpdate,
                          "startTime",
                          selectedSleepTimes,
                          val
                        )
                      }
                      value={moment(selectedSleepTimes.startTime).format(
                        "HH:mm"
                      )}
                    />
                  </DateTime>
                </td>
                <td>
                  <p style={{ margin: "0 5px" }}>-</p>
                </td>
                <td>
                  <DateTime>
                    <TimeDateInput
                      isSmall
                      isBorderless
                      type="time"
                      onChange={val => {
                        onSleepTimeChange(
                          handleShiftUpdate,
                          "endTime",
                          selectedSleepTimes,
                          val
                        );
                      }}
                      value={moment(selectedSleepTimes.endTime).format("HH:mm")}
                    />
                  </DateTime>
                </td>
                <td>{durationText}</td>
              </tr>
            </tbody>
            {!isSleepTimesValid(selectedDates, selectedSleepTimes) && (
              <ErrorMessage>
                Sleep time can't be outside of the shift
              </ErrorMessage>
            )}
          </SleepTimeTable>
        </div>
      )}
    </div>
  );
};

export default SleepTime;

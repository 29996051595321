import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../reducer";
import Icon from "../../../../../components/icon";
import { StyledInput, StyledSpan } from "./custom-tabs.styles";

const mapStateToProps = state => ({
  groupName: state.pools.groupName,
  isGroupNameEditable: state.pools.isGroupNameEditable,
  inputValue: state.pools.inputValue
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      toggleGroupNameEdit: actions.toggleGroupNameEdit,
      unToggleGroupNameEdit: actions.unToggleGroupNameEdit,
      clearInputValue: actions.clearInputValue,
      updateInputValue: actions.updateInputValue
    },
    dispatch
  );

@connect(mapStateToProps, mapDispatchToProps)
export class Tabs extends Component {
  constructor(props) {
    super(props);
    this.handleSwitchTab = this.handleSwitchTab.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
  }
  // Throws constant erros about props being placed before or after different methods - Weird.
  // eslint-disable-next-line react/sort-comp
  props;
  state;

  componentDidMount() {
    if (this.props.draggable) {
      window.addEventListener("mousemove", this.mouseMoveHandler);
      window.addEventListener("mouseup", this.mouseUpHandler);
    }
  }

  componentWillUnmount() {
    if (this.props.draggable) {
      window.removeEventListener("mousemove", this.mouseMoveHandler);
      window.removeEventListener("mouseup", this.mouseUpHandler);
    }
  }

  dragTarget = null;
  mouseMoveHandler = this.handleMouseMove.bind(this);
  mouseUpHandler = this.handleMouseUp.bind(this);
  singleTabWidth = 0;
  tabTotal = 0;
  isSafari =
    navigator.userAgent.indexOf("Safari") !== -1 &&
    navigator.userAgent.indexOf("Chrome") === -1;

  handleSwitchTab(index) {
    this.props.onTabSwitch(index);
  }

  handleMouseDown(event, tabId) {
    this.dragTarget = {
      target: event.currentTarget,
      x: event.currentTarget.offsetLeft,
      left: `${event.currentTarget.offsetLeft}px`,
      mouseX: event.pageX,
      index: this.getIndexByLeft(event.currentTarget.offsetLeft),
      tabId
    };
  }

  handleMouseUp() {
    this.dragTarget = null;
    this.forceUpdate();
  }

  handleMouseMove(event) {
    // Draggable
    if (this.dragTarget && this.dragTarget.tabId === this.props.currentGroup) {
      event.preventDefault();
      event.stopPropagation();

      const { dragTarget } = this;
      const one = this.tabsRef.offsetWidth / 100;
      const maxLeft = one + one * this.singleTabWidth * (this.tabTotal - 1);
      let left = dragTarget.x + event.pageX - dragTarget.mouseX;
      if (left < one) {
        left = one;
      }

      if (left > maxLeft) {
        left = maxLeft;
      }

      const index = this.getIndexByLeft(left);
      if (index !== dragTarget.index) {
        const a = index;
        const b = dragTarget.index;

        this.props.onTabPositionChange(a, b, dragTarget.tabId);

        dragTarget.index = index;
      }

      dragTarget.left = `${left}px`;

      this.forceUpdate();
    }
  }

  handleDelete(event, id) {
    event.preventDefault();
    event.stopPropagation();
    this.props.onTabDelete(id);
  }

  handleAdd(event) {
    event.preventDefault();
    event.stopPropagation();
    this.props.onTabAdd();
  }

  handleEdit(id) {
    this.props.toggleGroupNameEdit();
    this[`textInput${id}`].focus();
  }

  getLeftByIndex(index) {
    return 0 + this.singleTabWidth * index;
  }

  getIndexByLeft(left) {
    const one = this.tabsRef.offsetWidth / 100;
    return Math.round((left - one) / (one * this.singleTabWidth));
  }

  onBlur(event) {
    if (this.props.isGroupNameEditable) {
      if (
        this.isSafari ||
        (event.target.value !== "" &&
          event.target.value === this.props.inputValue)
      ) {
        this.props.onTabRename(this.props.inputValue);
      }
      this.props.unToggleGroupNameEdit();
      this.props.clearInputValue();
    }
  }

  // Requires '.this' to be called in the method and throws an error.
  // Can't find use for this.👇
  // eslint-disable-next-line  class-methods-use-this
  handleFocus(event) {
    event.target.select();
  }

  render() {
    const tabsRef = element => {
      this.tabsRef = element;
    };
    const { props } = this;
    this.tabTotal = props.children.length;
    this.singleTabWidth = this.tabTotal === 1 ? 40 : 100 / this.tabTotal;

    const tabs = props.children.map((tab, index) => {
      const style = {};
      const position = index;

      style.zIndex = this.tabTotal - position;
      style.left = `${this.getLeftByIndex(position)}%`;
      style.width = `${this.singleTabWidth}%`;

      if (this.dragTarget && this.dragTarget.index === position) {
        style.left = this.dragTarget.left;
      }

      return (
        <div
          key={index}
          className={`tab-button ${props.active === index ? " active" : ""}`}
          style={style}
          onClick={() => {
            this.handleSwitchTab(index);
          }}
          onKeyPress={() => {}}
          onMouseDown={event => this.handleMouseDown(event, tab.props.id)}
        >
          <div className="tab" />

          <div className={`text ${tab.props.showClose ? "with-close" : ""}`}>
            {!(props.active === index) ? (
              <StyledSpan>{tab.props.title}</StyledSpan>
            ) : (
              <StyledInput
                type="text"
                key={index}
                ref={input => {
                  this[`textInput${index}`] = input;
                }}
                readOnly={!this.props.isGroupNameEditable}
                placeholder={tab.props.title}
                value={this.props.inputValue}
                onChange={event => {
                  this.props.updateInputValue(
                    event.target.value.toString(),
                    this.isSafari
                  );
                  if (this.isSafari) {
                    event.target.select();
                  }
                }}
                onBlur={event => this.onBlur(event)}
                onFocus={this.handleFocus}
              />
            )}
          </div>
          <div
            style={
              props.active === index
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <div
              className="edit"
              onKeyPress={() => {}}
              onClick={() => {
                this.handleEdit(index);
              }}
            >
              <Icon
                color={Icon.colors.PALE_GREY_TRANSPARENT_98}
                size={Icon.sizes.SMALLMID}
                name={Icon.names.EDIT}
              />
            </div>
            <div
              className="close"
              onKeyPress={() => {}}
              onClick={event => {
                this.handleDelete(event, tab.props.id);
              }}
            >
              <Icon
                color={Icon.colors.PALE_GREY_TRANSPARENT_98}
                size={Icon.sizes.SMALLMID}
                name={Icon.names.DELETE}
              />
            </div>
          </div>
        </div>
      );
    });

    return (
      <div className="r-a-t">
        <div
          className={`tab-wrapper ${this.props.showAdd ? "with-add" : ""}`}
          ref={tabsRef}
        >
          {tabs}
        </div>
        {this.props.showAdd ? (
          <div
            className="add-wrapper"
            onKeyPress={() => {}}
            onClick={this.handleAdd}
          />
        ) : null}
      </div>
    );
  }
}
// eslint-disable-next-line   react/prop-types
const Tab = ({ children }) => <div>{children}</div>;

export default Tabs;
export { Tab };

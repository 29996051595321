import React from "react";
import { dateRangeToHuman } from "~/src/utils/formatting";
import rotaLogo from "~/src/images/logo-black.png";
import defaultPhoto from "~/src/images/worker-default-photo.png";
import {
  Wrapper,
  TableHeader,
  TableRow,
  TableCell,
  ShiftHead,
  ShiftDetailsLabel,
  MemberDetails,
  ShiftDetailsContent,
  MemberPhoto,
  MemberPhotoWrapper,
  Image
} from "./printable.styles";

const widths = {
  member: 1
};

const pluralEnding = number => (number > 1 ? "s" : "");

const hasNotMetShiftRequestedReq = (numRequested, bookingLength) =>
  Boolean(numRequested >= bookingLength);

const staffListFormat = shift => {
  const {
    overbookPercentage,
    numberRequested,
    bookings,
    roleRate: { roleName }
  } = shift;
  const memberCount = bookings?.ACCEPTED?.length;
  if (
    !overbookPercentage ||
    hasNotMetShiftRequestedReq(numberRequested, memberCount)
  ) {
    return `${memberCount}x ${roleName}${pluralEnding(memberCount)}`;
  }
  const actualOverbooked = memberCount - numberRequested;
  return `${numberRequested} ${roleName}${pluralEnding(
    numberRequested
  )} & ${actualOverbooked} overbooked`;
};

export default ({ shift, timesheetBookings }) => (
  <Wrapper>
    <Image src={shift.targetAccount?.logo || rotaLogo} />

    <ShiftHead>
      <ShiftDetailsLabel>Shift Details</ShiftDetailsLabel>
      <ShiftDetailsContent>
        <div>{dateRangeToHuman(shift.startTime, shift.endTime)}</div>
        <div>{shift.venue.name}</div>
        <div>{staffListFormat(shift)}</div>
      </ShiftDetailsContent>
    </ShiftHead>

    <TableHeader>
      <TableCell width={widths.member}>Member</TableCell>
    </TableHeader>
    {timesheetBookings.map((booking, i) => (
      <TableRow key={i} isBordered>
        <TableCell width={widths.member}>
          <MemberPhotoWrapper>
            <MemberPhoto src={booking.member.photo || defaultPhoto} />
          </MemberPhotoWrapper>
          <MemberDetails>
            <span>
              {booking.member.firstName} {booking.member.lastName}
            </span>
          </MemberDetails>
        </TableCell>
      </TableRow>
    ))}
  </Wrapper>
);

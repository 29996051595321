import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

const Text = styled.div`
  color: ${({ theme }) => theme.text.primary};
  font-size: ${STYLE_CONSTS.FONT_MEDIUM};
  margin-bottom: ${({ noMarginBottom }) =>
    noMarginBottom ? 0 : STYLE_CONSTS.UNIT_1};
  margin-top: ${({ marginTop }) => (marginTop ? STYLE_CONSTS.UNIT_2 : 0)};
  text-transform: ${({ uppercase }) => (uppercase ? "uppercase" : "none")};
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? STYLE_CONSTS.SMALL_DIVIDER_BORDER : "none"};
  padding-bottom: ${({ borderBottom }) =>
    borderBottom ? STYLE_CONSTS.UNIT_3 : 0};
  cursor: ${({ pointer }) => (pointer ? "pointer" : "default")};
  width: ${({ limitWidth }) => limitWidth && "max-content"};
`;

export default Text;

export const SmallText = styled(Text)`
  font-size: ${STYLE_CONSTS.FONT_SMALL};
`;

import { COLORS } from "~/src/styles/config.style";

const styles = {
  label: {
    display: "flex",
    textAlign: "left",
    paddingTop: 10,
    paddingRight: 10
  },
  error: {
    color: COLORS.RED
  }
};

export default styles;

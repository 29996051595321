import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const MemberWrapper = styled.div`
  vertical-align: top;
  height: 100px;
  width: 100px;
  margin: 5px;
  text-align: center;
  font-size: ${STYLE_CONSTS.FONT_SMALL};
  display: inline-block;
  ${({ normalWhiteSpacing }) => normalWhiteSpacing && `white-space: normal;`};

  transition: ${STYLE_CONSTS.STANDARD_TRANSITON};
  transform: perspective(1000px) translate3d(0, 0, 0);

  &:hover {
    transform: perspective(1000px) translate3d(0, 0, 100px);
    cursor: move;
  }
`;

export const Image = styled.div`
  height: 60px;
  width: 60px;
  border-radius: 60px;
  margin: 0 auto;
  background-size: cover;
  background-image: ${({ src }) => `url(${src})`};
`;

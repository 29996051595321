import gql from "graphql-tag";
import { graphql } from "@apollo/client/react/hoc";
import { handleErrors } from "~/src/utils/errors";

const GET_USER_STATS = gql`
  query getUserStats {
    account {
      id
      userStats {
        activeUsersCount
        newUsersCount
      }
    }
  }
`;

export default graphql(GET_USER_STATS, {
  props: handleErrors(({ data: { loading, account, refetch } }) => ({
    isUsersCountLoading: loading,
    activeUsersCount: account?.userStats?.activeUsersCount,
    newUsersCount: account?.userStats?.newUsersCount,
    refetchUsers: refetch
  }))
});

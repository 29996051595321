import React from "react";
import {
  StyledLoader,
  StyledFullHeight,
  StyledWrapSpinner
} from "../../styles";

const LoadSpinner = () => {
  return (
    <StyledWrapSpinner>
      <StyledFullHeight>
        <StyledLoader>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </StyledLoader>
      </StyledFullHeight>
    </StyledWrapSpinner>
  );
};

export default LoadSpinner;

import React, { useEffect, useState } from "react";
import { parse } from "query-string";

import {
  ModalHeader,
  RotaButton,
  RotaNewModal,
  RotaTab,
  iconNew,
  useToast
} from "@teamrota/rota-design";

import JsonEditor from "../JsonEditor";

import {
  Error,
  ErrorMessage,
  OptionsButton,
  SettingsWrapper,
  TabWrapper
} from "./styles";
import { jsonToString } from "./utils";

const { Cancel } = iconNew;

interface Props {
  title: string;
  canEdit: boolean;
  onClose: () => void;
  onValidate?: (data: any) => void;
  onSave?: (data: any) => void;
  value: any;
}

enum TABS {
  RULES = "Rules",
  SETTINGS = "Settings"
}

const JsonEditModal: React.FC<React.PropsWithChildren<Props>> = ({
  canEdit,
  onSave,
  onValidate,
  onClose,
  title,
  value,
  children
}) => {
  const { showToast } = useToast();

  const params = parse(window.location.search) as any;

  const initialSearch =
    params.search !== undefined ? `"name": "${params.search}"` : undefined;

  const [subtitle, setSubtitle] = useState(value?.name);
  const [tab, setTab] = useState(TABS.RULES);
  const [jsonInput, setJsonInput] = useState(jsonToString(value));
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    try {
      const { name } = JSON.parse(jsonInput);
      if (name) {
        setSubtitle(name);
      }
    } catch (e) {
      // do nothing if JSON isn't parseable
    }
  }, [jsonInput]);

  return (
    <RotaNewModal
      onClose={onClose}
      header={
        <ModalHeader
          title={title}
          subtitle={subtitle}
          endAction={
            <OptionsButton onClick={onClose}>
              <Cancel />
            </OptionsButton>
          }
        />
      }
    >
      {children && (
        <TabWrapper>
          <RotaTab
            label="Rules"
            onClick={() => setTab(TABS.RULES)}
            size="md"
            isActive={tab === TABS.RULES}
          />

          <RotaTab
            label="Settings"
            onClick={() => setTab(TABS.SETTINGS)}
            size="md"
            isActive={tab === TABS.SETTINGS}
          />
        </TabWrapper>
      )}

      {tab === TABS.RULES && (
        <JsonEditor
          name={`${title}-json-editor`}
          height="400px"
          canEdit={canEdit}
          value={jsonInput}
          initialSearch={initialSearch}
          onChange={setJsonInput}
        />
      )}

      {tab === TABS.SETTINGS && <SettingsWrapper>{children}</SettingsWrapper>}

      <Error isShow={error !== null}>
        <ErrorMessage>{error}</ErrorMessage>
      </Error>

      {canEdit && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            paddingTop: "10px"
          }}
        >
          {onSave && (
            <RotaButton
              onClick={async () => {
                try {
                  await onSave(JSON.parse(jsonInput));
                } catch (e) {
                  showToast((e as Error).message, { severity: "error" });
                }
              }}
              disabled={false}
            >
              Save
            </RotaButton>
          )}

          {onValidate && (
            <RotaButton
              onClick={async () => {
                try {
                  await onValidate(JSON.parse(jsonInput));
                  showToast("Validation success!", { severity: "success" });
                  setError(null);
                } catch (e) {
                  setError((e as Error).message);
                }
              }}
              variant="secondary"
              disabled={false}
            >
              Validate
            </RotaButton>
          )}
        </div>
      )}
    </RotaNewModal>
  );
};

export default JsonEditModal;

import { useCallback } from "react";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { currencySupport } from "@teamrota/rota-common";
import { TimesheetMemberStatus, TimesheetStatus } from "./types";
import { TimesheetMemberFragment } from "./fragments";
import { useToast } from "@teamrota/rota-design";
import { StatsEnum } from "./use-timesheets-with-stats";

const TIMESHEET_MEMBERS_WITH_STATS = gql`
  query TimesheetMembersSummary(
    $startDate: Date
    $endDate: Date
    $venueIds: [ID!]!
    $roleIds: [ID!]!
    $search: String
    $statuses: [TimesheetMemberRowStatusType!]
    $offset: Int!
    $limit: Int!
  ) {
    account {
      id
      logo
      currencyRegion
      timesheetMembersWithStats(
        startDate: $startDate
        endDate: $endDate
        venueIds: $venueIds
        roleIds: $roleIds
        search: $search
        statuses: $statuses
        offset: $offset
        limit: $limit
      ) {
        stats {
          totalShifts
          totalHours
          totalBookings
        }
        statusCount {
          todoCount
          inProgressCount
          approvedCount
          lockedCount
        }
        timesheetMembers {
          ...TimesheetMemberFragment
        }
        previewTimesheetMembers {
          id
          shiftId
          shiftName
          shiftStartTime
          shiftEndTime
          totalMinutes
          status
          venueName
          memberId
          memberName
          memberAvatar
          checkInTime
          checkOutTime
          roleName
          scheduledStartTime
          scheduledEndTime
          venueId
          subvenueId
          subvenueName
          venueAddress
          numberRequested
        }
      }
    }
  }
  ${TimesheetMemberFragment}
`;

type TimesheetMemberBase = {
  id: string;
  shiftName: string;
  venueName: string;
  shiftId: string;
  timesheetId: string;
  totalMinutes: number;
  purchaseOrderNumber: string;
  status: TimesheetMemberStatus;
  shiftStartTime: string;
  shiftEndTime: string;
  isUncalculatedRole: boolean;
  memberId: string;
  memberName: string;
  memberAvatar: string;
  roleName: string;
  checkInTime: string;
  checkOutTime: string;
  venueId: string;
  subvenueName: string;
  providerName: string;
  venueAddress: string;
  numberRequested: number;
  subvenueId: string;
  isNoShow: boolean;
  isTurnedAway: boolean;
  scheduledStartTime: string;
  scheduledEndTime: string;
};

export interface TimesheetMemberWithStats extends TimesheetMemberBase {
  isPreview: boolean;
}

const getSelectedStatuses = (statuses: TimesheetStatus[]) => {
  return statuses.reduce<TimesheetMemberStatus[]>((acc, status) => {
    if (status === TimesheetStatus.Approved) {
      return [...acc, TimesheetMemberStatus.APPROVED];
    }
    if (status === TimesheetStatus.Open) {
      return [
        ...acc,
        ...Object.values(TimesheetMemberStatus).filter(
          status => status !== TimesheetMemberStatus.APPROVED
        )
      ];
    }
    return acc;
  }, []);
};

interface Params {
  startDate?: Date;
  endDate?: Date;
  venueIds: string[];
  roleIds?: string[];
  search: string;
  statuses?: TimesheetStatus[];
}

export const useTimesheetMembersWithStats = ({
  startDate,
  endDate,
  venueIds,
  roleIds,
  search,
  statuses
}: Params) => {
  const { showToast } = useToast();
  const result = useQuery(TIMESHEET_MEMBERS_WITH_STATS, {
    variables: {
      startDate,
      endDate,
      venueIds,
      roleIds,
      search,
      statuses: statuses ? getSelectedStatuses(statuses) : [],
      offset: 0,
      limit: 50
    },
    fetchPolicy: "cache-and-network",
    onError: error => {
      showToast(error.message, { severity: "error" });
    },
    skip: !startDate || !endDate
  });

  const data = result.data || result.previousData;

  const stats = data?.account?.timesheetMembersWithStats?.stats;
  const timesheetStatusCount =
    data?.account?.timesheetMembersWithStats?.statusCount;
  const timesheets:
    | TimesheetMemberWithStats[]
    | undefined = data?.account?.timesheetMembersWithStats?.timesheetMembers?.map(
    (timesheet: TimesheetMemberWithStats) => ({
      ...timesheet,
      isPreview: false
    })
  );
  const previewTimesheetMembers: TimesheetMemberWithStats[] =
    data?.account?.timesheetMembersWithStats?.previewTimesheetMembers?.map(
      (timesheet: TimesheetMemberWithStats) => ({
        ...timesheet,
        isPreview: true
      })
    ) || [];
  const accountId = data?.account.id;
  const currencyCode =
    data?.account?.currencyRegion &&
    currencySupport[data?.account?.currencyRegion].code;

  const timesheetStats = (Object.keys(StatsEnum) as Array<
    keyof typeof StatsEnum
  >).map(key => ({
    label: StatsEnum[key],
    value: stats?.[key] ?? "0"
  }));

  const timesheetsCount =
    (timesheets || []).length + previewTimesheetMembers?.length;
  const hasMore = stats?.totalShifts > timesheetsCount;

  const loadMore = useCallback(async () => {
    if (!hasMore) return;
    await result.fetchMore({
      variables: { offset: timesheetsCount },
      updateQuery: (prevData, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prevData;

        return {
          ...fetchMoreResult,
          account: {
            ...fetchMoreResult.account,
            timesheetMembersWithStats: {
              ...fetchMoreResult.account.timesheetMembersWithStats,
              timesheetMembers: [
                ...prevData.account.timesheetMembersWithStats.timesheetMembers,
                ...fetchMoreResult.account.timesheetMembersWithStats
                  .timesheetMembers
              ]
            }
          }
        };
      }
    });
  }, [hasMore, timesheetsCount]);

  return {
    timesheetStats,
    totalShifts: stats?.totalShifts,
    totalBookings: stats?.totalBookings,
    hasMore,
    loadMore,
    timesheetStatusCount,
    currencyCode,
    timesheets,
    previewTimesheets: previewTimesheetMembers,
    accountId,
    ...result
  };
};

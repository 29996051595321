import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

const styles = {
  tg: {
    display: "inline-table",
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%"
  },
  td: {
    borderColor: "black 1px solid",
    fontSize: STYLE_CONSTS.FONT,
    overflow: "hidden",
    padding: "10px 5px",
    margin: "7px 0 7px 0"
  },
  th: {
    borderColor: "black 1px solid",
    fontSize: STYLE_CONSTS.FONT,
    overflow: "hidden",
    padding: "10px 5px"
  },
  tableDiv: {
    width: "125px",
    textAlign: "center",
    verticalAlign: "center",
    fontSize: STYLE_CONSTS.FONT_SMALL,
    margin: "7px 0 7px 0"
  },
  pinCheckBox: {
    textAlign: "center",
    verticalAlign: "center"
  },
  qrCheckBox: {
    textAlign: "center",
    verticalAlign: "center"
  },
  qrSpan: {
    fontSize: "10px",
    color: "#9E9E9E"
  },
  formWrapper: {
    marginTop: "40px",
    width: "75%"
  },
  tableWrapper: {
    height: "350px",
    overflowY: "auto"
  }
};

export const SetUpModalWrapper = styled.div`
  width: 710px;
  padding: 0 0 33px 0;
  border-radius: 10px;
  border: 0px solid;
  box-shadow: 0px 2px 4px rgba(170, 170, 170, 0.5);
`;

export const ModalHeaderBar = styled.div`
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #444444;
  color: white;
  padding: 0 13px 0 0;
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_MEDIUM};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const ModalTitleDiv = styled.div`
  width: 100%;
  height: 82px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  line-height: 28px;
  border: 1px solid #edecec;
`;

export const CheckInFormWrapper = styled.div`
  width: 100%;
  height: 444px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SaveButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Th = styled.th`
  position: sticky;
  text-align: ${({ textAlign }) => textAlign || "center"};
  top: 0px;
  vertical-align: top;
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_BOLD};
  background: #fff;
  z-index: 1;
`;

export const TableDiv = styled.th`
  width: 125px;
  text-align: left;
  vertical-align: center;
  font-size: ${STYLE_CONSTS.FONT_SMALL};
  margin: 7px 0 7px 0;
`;

export const QrWrapper = styled.td`
  position: sticky;
  text-align: ${({ textAlign }) => textAlign || "center"};
  top: 0px;
  vertical-align: top;
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_BOLD};
  margin: 7px 0 7px 0;
`;

export default styles;

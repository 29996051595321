import React from "react";
import { useLocation } from "react-router-dom";

import Icon from "~/src/components/icon";
import logoWhite from "~/src/images/logo-white.png";

import {
  ROTA_LOGO_HEIGHT,
  MenuNavWrapper,
  LogoWrapper,
  LogoWhite,
  IconWrapper,
  NoUnderline,
  OptionName
} from "./index.styles";

export default function MenuNav({ options }) {
  const { pathname } = useLocation();

  const handleClick = opt => e => {
    if (opt.onClick) {
      opt.onClick();
      if (!opt.to) {
        e.preventDefault();
      }
    }
  };

  return (
    <MenuNavWrapper>
      <LogoWrapper>
        <LogoWhite src={logoWhite} height={ROTA_LOGO_HEIGHT / 2} alt="logo" />
      </LogoWrapper>

      {options.map((opt, i) =>
        opt.isVisible ? (
          <NoUnderline key={i} to={opt?.to || ""} onClick={handleClick(opt)}>
            <IconWrapper isActive={pathname === opt.to}>
              <Icon size={Icon.sizes.SMALL} name={opt.icon} />
              <OptionName isActive={pathname === opt.to}>{opt.name}</OptionName>
            </IconWrapper>
          </NoUnderline>
        ) : null
      )}
    </MenuNavWrapper>
  );
}

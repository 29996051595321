import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import set from "lodash/fp/set";
import flow from "lodash/fp/flow";

import { addAuthVars } from "@teamrota/authlib";

import ShiftFragment from "~/src/graphql/fragments/shift/shift.fragment";
import useAuth from "~/src/auth/hooks/use-auth";

const CREATE_OR_UPDATE_SHIFT = addAuthVars(gql`
  ${ShiftFragment}

  mutation createOrUpdateShift(
    $isLinkedShifts: Boolean
    $shifts: [ShiftInputType]!
    $shouldSkipTimeValidation: Boolean
    $partnerId: ID
  ) {
    createOrUpdateShift(
      isLinkedShifts: $isLinkedShifts
      shifts: $shifts
      shouldSkipTimeValidation: $shouldSkipTimeValidation
      partnerId: $partnerId
    ) {
      ...ShiftFragment
      ... on shift {
        chargeRate
        bonuses(type: "reward", isActive: true) {
          id
          bookings
          type: period
          amount
        }
      }
    }
  }
`);

export default graphql(CREATE_OR_UPDATE_SHIFT, {
  name: "createOrUpdateShift",
  props: ownProps => {
    const auth = useAuth();
    return {
      ...ownProps,
      createOrUpdateShift: values =>
        ownProps.createOrUpdateShift({
          variables: auth.addVals(CREATE_OR_UPDATE_SHIFT, values),
          updateQueries: { getShifts, getShiftGroup }
        })
    };
  }
});

function getShiftGroup(
  currentData,
  {
    mutationResult: {
      data: { createOrUpdateShift: shifts }
    }
  }
) {
  const [shift] = shifts;
  const currentShift = currentData.account.shift || {};
  const merge = prop => ({ [prop]: { ...shift[prop], ...currentShift[prop] } });
  const updateData = set("account.shift", {
    ...currentShift,
    briefing: shift.briefing,
    startTime: shift.startTime,
    endTime: shift.endTime,
    name: shift.name,
    identifier: shift.identifier,
    tags: shift.tags,
    isPartnerManaged: shift.isPartnerManaged,
    numberRequested: shift.numberRequested,
    // ...merge('roleRate'),
    ...merge("venue"),
    ...merge("uniform")
  });

  return updateData(currentData);
}

function getShifts(
  currentData,
  {
    mutationResult: {
      data: { createOrUpdateShift: shifts }
    }
  }
) {
  const { data } = currentData.account.shifts;
  const upsertNewShifts = currentShifts => {
    const upsertedShifts = [...currentShifts];
    shifts.forEach(shift => {
      const index = upsertedShifts.findIndex(({ id }) => id === shift.id);
      if (index < 0) upsertedShifts.push(shift);
      else upsertedShifts[index] = shift;
    });
    return upsertedShifts;
  };

  return flow(
    set("account.shifts.data", upsertNewShifts(data)),
    set("account.shifts.totalResults", data.length + shifts.length)
  )(currentData);
}

import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";

import { Role } from "@teamrota/authlib";

import {
  RotaSearchInput,
  RotaButtonIcon,
  RotaButton,
  RotaCheckbox
} from "@teamrota/rota-design";

import adminClient from "../../graphql/admin-client";

import { GET_USERS } from "../../graphql/get-users";
import { UPDATE_USER } from "../../graphql/update-user";

import useLoadMore from "~/src/containers/admin-dashboard/hooks/load-more";
import DashboardScrollHandler from "~/src/components/dashboard-scroll-handler";

import AccountsList from "../accounts-list";
import Modal from "~/src/components/modal";
import ModalContent from "~/src/containers/modals/components/modal-content";
import User from "~/src/containers/admin-dashboard/components/user";

import { StyledContentModal } from "./styles";

import {
  StyledMainHeading,
  StyledMainDetail,
  StyledMainHeaderWrapper,
  StyledWrapper,
  StyledFeaturesWrapper,
  StyledFeatureHeader,
  StyledWrapperList
} from "../styles";

const EnablingUserFeatures = ({ setSnack }) => {
  const [selectedAccountId, setSelectedAccountId] = useState();
  const [selectedAccountName, setSelectedAccountName] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [isPayrollView, setIsPayrollView] = useState(false);
  const [isPayrollEdit, setIsPayrollEdit] = useState(false);

  useEffect(() => {
    setIsPayrollView(
      selectedUser?.extraRoles?.includes?.(Role.PAYROLL_VIEW) ?? false
    );

    setIsPayrollEdit(
      selectedUser?.extraRoles?.includes?.(Role.PAYROLL_EDIT) ?? false
    );
  }, [selectedUser]);

  const { data, fetchMore, loading } = useQuery(GET_USERS, {
    variables: {
      searchTerm,
      accountId: selectedAccountId,
      limit: 10,
      offset: 0
    },
    client: adminClient,
    skip: !selectedAccountId
  });

  const [updateUser] = useMutation(UPDATE_USER, {
    client: adminClient
  });

  const SCROLL_ELEMENT_ID = "users_list_scroll";

  const dataLength = data?.users?.length;

  const [handleLoadMore] = useLoadMore({
    dataLength,
    fetchMore,
    nameData: "users"
  });

  const openModalAndGetUser = user => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const handleUpdateUser = async userId => {
    await updateUser({
      variables: {
        userId,
        extraRoles: [
          ...(isPayrollView ? [Role.PAYROLL_VIEW] : []),
          ...(isPayrollEdit ? [Role.PAYROLL_EDIT] : [])
        ]
      }
    });

    setSnack({ message: "Success!", severity: "success" });
    setIsModalOpen(false);
  };

  return (
    <>
      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <ModalContent isSmallContent title="Enable features for users">
            <StyledContentModal>
              <div>
                <h3>
                  <span>
                    {selectedUser.firstName} {selectedUser.lastName}{" "}
                  </span>
                  <div>({selectedUser.email})</div>
                </h3>
              </div>

              <div style={{ textAlign: "center" }}>
                <RotaCheckbox
                  label="View Payroll"
                  isLabelTop
                  isChecked={isPayrollView}
                  onClick={e => setIsPayrollView(e.target.checked)}
                />
              </div>

              <div>
                <RotaCheckbox
                  label="Edit Payroll"
                  isLabelTop
                  isChecked={isPayrollEdit}
                  onClick={e => setIsPayrollEdit(e.target.checked)}
                />
              </div>

              <div>
                <RotaButton
                  variant="secondary"
                  onClick={() => setIsModalOpen(false)}
                >
                  Cancel
                </RotaButton>

                <RotaButton onClick={() => handleUpdateUser(selectedUser.id)}>
                  Save
                </RotaButton>
              </div>
            </StyledContentModal>
          </ModalContent>
        </Modal>
      )}
      <StyledMainHeaderWrapper>
        <StyledMainHeading>Enabling User Features</StyledMainHeading>
        <StyledMainDetail>Enable hidden features for a user</StyledMainDetail>
      </StyledMainHeaderWrapper>

      <StyledWrapper>
        <AccountsList
          onSelect={(id, accountName) => {
            setSelectedAccountId(id);
            setSelectedAccountName(accountName);
          }}
          selectedAccountId={selectedAccountId}
          selectedAccountName={selectedAccountName}
        />
        {selectedAccountId && (
          <StyledFeaturesWrapper>
            <StyledFeatureHeader>
              <h3>Members: {selectedAccountName}</h3>
              <RotaSearchInput
                placeholder="search member"
                onChange={value => {
                  setSearchTerm(value);
                }}
              />
            </StyledFeatureHeader>
            <DashboardScrollHandler
              onReachEnd={() => {
                handleLoadMore();
              }}
              scrollElementId={SCROLL_ELEMENT_ID}
            >
              <StyledWrapperList id={SCROLL_ELEMENT_ID}>
                {loading && <p>loading...</p>}
                {data?.users?.map(user => (
                  <User key={user.id} user={user}>
                    <RotaButtonIcon
                      icon="edit"
                      onClick={() => openModalAndGetUser(user)}
                    />
                  </User>
                ))}
              </StyledWrapperList>
            </DashboardScrollHandler>
          </StyledFeaturesWrapper>
        )}
      </StyledWrapper>
    </>
  );
};

export default EnablingUserFeatures;

import styled from "styled-components";
import { COLORS, STYLE_CONSTS } from "~/src/styles/config.style";

export const PHOTO_SIZE = "100px";

export const Scrollable = styled.div`
  height: calc(100% - 50px);
  overflow-y: auto;
  padding-right: 10px;
`;

export const FilesContainer = styled.div`
  margin-bottom: ${({ isMargin }) => (isMargin ? STYLE_CONSTS.UNIT_6 : 0)};
`;

export const Text = styled.span`
  color: ${({ type }) => {
    if (type === "missing") {
      return COLORS.YELLOW;
    }
    if (type === "expired") {
      return COLORS.RED;
    }
    return COLORS.BLACK;
  }};
`;

export const Title = styled.div`
  font-size: ${STYLE_CONSTS.FONT_MEDIUM};
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  color: ${({ theme }) => theme.text.primary};
  text-align: center;
  text-align: ${({ center }) => (center ? "center" : "left")};
`;

export const RightToWork = styled.div`
  display: flex;
  margin: ${STYLE_CONSTS.UNIT_6} 0};
`;

export const RTWLabel = styled.div`
  color: ${COLORS.COOL_GREY};
  margin: 0 ${STYLE_CONSTS.UNIT_1};
  margin-left: ${({ marginLeft }) =>
    marginLeft ? STYLE_CONSTS.UNIT_5 : STYLE_CONSTS.UNIT_1};
`;

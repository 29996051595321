import React from "react";
import Icon from "~/src/components/icon";
import {
  ActionSpan,
  ActionSpanIcon,
  ActionSpanContainer
} from "./action-dropdown.styles";

const ActionSpanComponent = ({
  isIconPrimary,
  isPrimary,
  isRewardDropDown,
  onClick,
  placeholder
}) => (
  <ActionSpanContainer onClick={onClick} isRewardDropDown={isRewardDropDown}>
    <ActionSpan isRewardDropDown={isRewardDropDown}>
      {placeholder || ""}
    </ActionSpan>
    <ActionSpanIcon
      color={
        isIconPrimary || isPrimary ? Icon.colors.MAIN_ORANGE : Icon.colors.BLACK
      }
      name={Icon.names.ARROW_DOWN}
      size={isRewardDropDown ? "xsmall" : "xxsmall"}
    />
  </ActionSpanContainer>
);

export default ActionSpanComponent;

/* global google */
import React, { Component } from "react";
import debounce from "es6-promise-debounce";
import Select from "./";

const debouncedFetchOptions = debounce(fetchPredictions, 300);

export default class SelectGeoSuggest extends Component {
  state = {
    circle: undefined,
    predictions: []
  };

  async componentDidMount() {
    const { circle } = await geolocate();
    this.setState({
      circle
    });
  }

  handleChange = async value => {
    const existingPrediction = this.state.predictions.find(
      pred => pred.description === value
    );

    if (existingPrediction) {
      this.props.onChange({
        ...(await geocodePrediction(existingPrediction.placeId)),
        address: value
      });
    } else {
      this.props.onChange({ ...this.props.value, address: value });
      this.setState({
        predictions: await debouncedFetchOptions(value, this.state.circle)
      });
    }
  };

  props;

  render() {
    const { props, state } = this;

    return (
      <Select
        isDisabled={props?.isDisabled}
        isEditable
        placeholder={props.placeholder}
        value={props.value.address}
        onChange={this.handleChange}
        options={parsePredictionsToOptions(state.predictions)}
        errorLabel={props.errorLabel}
      />
    );
  }
}

function fetchPredictions(search, boundingCircle) {
  return new Promise(resolve => {
    if (!search) resolve([]);
    else {
      const autocomplete = new google.maps.places.AutocompleteService();

      const insertBound = boundingCircle ? { bounds: boundingCircle } : {};

      autocomplete.getPlacePredictions(
        { ...insertBound, input: search },
        (predictions, status) => {
          if (status !== google.maps.places.PlacesServiceStatus.OK) {
            // eslint-disable-next-line no-console
            console.warn("Zero results");
            resolve([]);
          } else {
            resolve(
              predictions.slice(0, 10).map(pred => ({
                placeId: pred.place_id,
                description: pred.description
              }))
            );
          }
        }
      );
    }
  });
}

function parsePredictionsToOptions(predictions) {
  return predictions.map(item => ({
    value: item.description,
    label: item.description
  }));
}

function geocodePrediction(placeId) {
  const geocoder = new google.maps.Geocoder();

  return new Promise((resolve, reject) => {
    geocoder.geocode({ placeId }, (places, status) => {
      if (status === google.maps.GeocoderStatus.OK && places && places.length) {
        resolve({
          lat: places[0].geometry.location.lat(),
          lng: places[0].geometry.location.lng()
        });
      } else {
        reject(status);
      }
    });
  });
}

// Bias the autocomplete object to the user's geographical location,
// as supplied by the browser's 'navigator.geolocation' object.
function geolocate() {
  return new Promise(resolve => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        const circle = new google.maps.Circle({
          center: geolocation,
          radius: position.coords.accuracy
        });

        resolve({ geolocation, circle: circle.getBounds() });
      });
    } else {
      resolve({});
    }
  });
}

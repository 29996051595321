import { gql } from "@apollo/client";

export const GET_SERVICE_AREAS = gql`
  query getServiceAreas {
    account {
      id
      serviceAreas {
        id
        name
        path
        createdAt
      }
    }
  }
`;

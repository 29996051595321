import React, { useState } from "react";
import { get, getOr } from "lodash/fp";
import { flow } from "lodash";
import { Role } from "@teamrota/authlib";
import { RotaButtonNew } from "@teamrota/rota-design";

import { CANCELLATION_REASONS } from "~/src/consts";
import { withAuth } from "~/src/auth/hoc";
import HasRole from "~/src/containers/has-role";
import Modal from "~/src/components/modal";
import Tabs from "~/src/containers/modals/components/tabs";
import History from "~/src/containers/modals/components/history";
import { getTotalCharge, showShiftTimeRange } from "~/src/utils/formatting";
import getProfile from "~/src/graphql/queries/get-profile/get-profile-query.decorator";
import asyncConfirm from "~/src/utils/async-confirm";
import { errorModal } from "~/src/utils/errors";
import Icon from "~/src/components/icon";
import { APPROVAL_STATES_LABELS, DRESS_CODE_IMAGES } from "~/src/consts";
import TimeDisplay from "~/src/components/shift-time-display";
import { SurgeString } from "~/src/services/surge-pricing";
import { createBriefingOptions } from "~/src/utils/briefing";

import updateShiftState from "./graphql/update-shift-state";
import getShift from "./graphql/get-shift";
import EditPolicies from "./edit-policies";
import { CancelText, Link, StyledTooltip } from "./index.styles";
import ViewEditShiftFormComponent from "./view-edit-shift-form";
import ModalContent from "../components/modal-content";
import Title from "../components/title";
import Text from "../components/text";
import { LeftPane, RightPane } from "../components/content-wrapper";
import { useCurrency } from "../../profile-loader";

const ViewEditShiftComponent = props => {
  const [isSaving, setIsSaving] = useState(false);
  const briefingOptions = createBriefingOptions(
    props?.user?.account?.briefingTemplates || []
  );

  const { taxRate } = useCurrency();

  const handleCancelShift = async () => {
    try {
      const ret = await asyncConfirm(
        "Are you sure you want to cancel this shift?",
        {
          confirmButtonText: "Cancel shift",
          falseButtonText: "Back",
          callback: () => {},
          dropDownOptions: Object.values(CANCELLATION_REASONS).map(reason => ({
            value: reason,
            label: reason
          }))
        }
      );
      if (ret) {
        setIsSaving(true);
        await props.updateShiftState(props.shift.id, "cancelled", ret);
      }
    } catch (e) {
      setIsSaving(false);
      errorModal(e);
    } finally {
      setIsSaving(false);
    }
  };

  if (!props.isOpen) return false;

  const accountHasProviderPolicy = props.user?.account?.policy;
  const isUncalculatedRate = props.shift?.role?.isUncalculatedRate;

  const tabs = props.isOpen
    ? [
        {
          name: "Info",
          content: (
            <ViewEditShiftFormComponent
              shift={props.shift}
              uniformTemplates={props.uniformTemplates}
              venueTemplates={props.venueTemplates}
              briefingOptions={briefingOptions}
            />
          )
        },
        ...(accountHasProviderPolicy
          ? [
              {
                name: "Policies",
                content: <EditPolicies shift={props?.shift} />
              }
            ]
          : []),
        ...(props?.auth?.hasRole(Role?.SHIFTS_HISTORY)
          ? [
              {
                name: "History",
                content: <History logs={get("shift.logs", props)} />
              }
            ]
          : [])
      ]
    : null;

  const startTime = get("shift.startTime", props);
  const endTime = get("shift.endTime", props);

  const totalCharge =
    props.auth.hasRole(Role.SHIFTS_COST) && props.shift
      ? getTotalCharge({
          roleRateId: get("shift.roleRate.id", props),
          numberRequested: get("shift.numberRequested", props),
          endTime,
          startTime,
          roleRates: [get("shift.roleRate", props)],
          policy: get("shift.policy", props),
          bonusAmount: get("shift.totalCostBonusesAndRewards", props),
          bonus: get("shift.bonuses", props),
          isDayRate: props?.shift?.isDayRate,
          taxRate
        })
      : 0;

  const getUniformItems = items =>
    items.reduce(
      (acc, curr, index) => [
        ...acc,
        <span key={`item-${index}`}>{curr}</span>,
        <br key={`br-${index}`} />
      ],
      []
    );

  const formatUniforms = items => {
    let dressCodeId = null;
    items.map(item => {
      if (/(ITEM:ID:)/g.exec(item)) {
        dressCodeId = item.replace(/\D/g, "");
      }
      return item;
    });
    if (dressCodeId && dressCodeId !== null) {
      return [
        DRESS_CODE_IMAGES[dressCodeId].label,
        ...items.filter(item => !/(ITEM:ID:)/g.exec(item))
      ];
    }
    return items;
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      isLoading={props.isLoading}
    >
      <ModalContent title="Shift Details">
        <LeftPane>
          <Title marginBottom>Summary</Title>
          <Text>{get("shift.venue.name", props)}</Text>
          <Link
            href={`http://maps.google.com/?q=${get(
              "shift.venue.address",
              props
            )}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {get("shift.venue.address", props)}
          </Link>
          <Text>
            {get("shift.numberRequested", props)}{" "}
            {`${get("shift.role.name", props)}${
              props?.shift?.isDayRate ? "(Daily Rate)" : ""
            }`}
          </Text>
          <Text>
            <TimeDisplay
              displayDate={showShiftTimeRange(startTime, endTime, {
                includeYear: true
              })}
              startTime={startTime}
              endTime={endTime}
            />
          </Text>
          <Text pointer data-tip data-for="uniform-tooltip" limitWidth>
            {get("shift.uniform.identifier", props)
              ? get("shift.uniform.identifier", props)
              : "Custom Uniform"}{" "}
            <Icon name={Icon.names.INFO} size="small" />
          </Text>
          <StyledTooltip
            multiline
            id="uniform-tooltip"
            effect="solid"
            place="right"
          >
            {getUniformItems(
              formatUniforms(getOr([], "shift.uniform.items", props))
            )}
          </StyledTooltip>

          <HasRole role={Role.SHIFTS_COST}>
            <Title marginTop>
              Shift cost
              {SurgeString({
                startTime,
                targetAccountId: get("shift.targetAccount.id", props),
                fullDescription: true
              })}
            </Title>
            <Text>
              {isUncalculatedRate && "Uncalculated"}
              {!isUncalculatedRate && totalCharge}
            </Text>
          </HasRole>

          <Title marginTop>Approval state</Title>
          <Text>
            {APPROVAL_STATES_LABELS[get("shift.approvalState", props)]}
          </Text>

          <HasRole role={Role.SHIFTS_CANCEL}>
            <Title marginTop>Cancel Shift</Title>
            <div>
              {props?.shift?.cancelledAt ? (
                <CancelText>This shift has been cancelled</CancelText>
              ) : (
                <div>
                  <RotaButtonNew
                    onClick={handleCancelShift}
                    isLoading={isSaving}
                    variant="outlined"
                  >
                    Cancel shift
                  </RotaButtonNew>
                  <CancelText>
                    Cancelling shift will notify all accepted members.
                  </CancelText>
                </div>
              )}
            </div>
          </HasRole>
        </LeftPane>
        <RightPane>
          <Tabs tabs={tabs} defaultTab="Info" />
        </RightPane>
      </ModalContent>
    </Modal>
  );
};

export default flow(
  getShift,
  getProfile,
  updateShiftState,
  withAuth
)(ViewEditShiftComponent);

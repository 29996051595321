import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const UPDATE_MEMBER_PHOTO = gql`
  mutation Mutation($memberId: ID!, $newPhotoId: ID!) {
    updateMemberPhoto(memberId: $memberId, newPhotoId: $newPhotoId) {
      id
    }
  }
`;

export default graphql(UPDATE_MEMBER_PHOTO, {
  name: "updateMemberPhoto",
  props: props => ({
    updateMemberPhoto: async (memberId, newPhotoId) => {
      const memberMutationResult = await props.updateMemberPhoto({
        variables: { memberId, newPhotoId }
      });
      return memberMutationResult.data.updateMemberPhoto;
    }
  })
});

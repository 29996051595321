import debounce from "lodash/debounce";
import React, { useEffect } from "react";
import ActionDropdown from "~/src/components/action-dropdown";
import Button from "~/src/components/button";
import FiltersDropdown from "~/src/components/filters-dropdown";
import Icon from "~/src/components/icon";
import PageHeader from "~/src/components/page-header";
import Filters from "~/src/containers/provide/components/filters";
import styles, { SortedBy, StyledSearchInput } from "./header.styles";
import parseFiltersToTags from "./helpers";

const Header = ({
  totalResults,
  onSearch,
  filters,
  sortBy,
  onUpdateSortBy,
  serviceAreaOptions,
  roleOptions,
  onUpdateFilter,
  onResetState,
  onRemoveFilters,
  isLoading,
  isRotaAccount,
  searchText
}) => {
  const [localSearchText, setLocalSearchText] = React.useState("");

  useEffect(() => {
    setLocalSearchText(searchText);
  }, []);

  useEffect(() => {
    const debouncedSearch = debounce(() => {
      onSearch(localSearchText);
    }, 300);

    debouncedSearch();

    return () => {
      debouncedSearch.cancel();
    };
  }, [localSearchText, onSearch]);

  const filterTags = parseFiltersToTags(filters);
  const s = totalResults === 1 ? "" : "s";

  return (
    <PageHeader title="Provide" subtext={`${totalResults} shift${s}`}>
      <div>
        <StyledSearchInput
          isInline
          isLarge
          placeholder="Search shift by name, account name, venue or ID"
          onChange={text => setLocalSearchText(text)}
          value={localSearchText}
          isLoading={isLoading}
        />
        <FiltersDropdown>
          <Filters
            onResetState={onResetState}
            onUpdate={onUpdateFilter}
            filters={filters}
            serviceAreaOptions={serviceAreaOptions}
            roleOptions={roleOptions}
            isRotaAccount={isRotaAccount}
          />
        </FiltersDropdown>
        <div style={styles.block}>
          {filterTags.map(({ keys, label }, index) => (
            <div key={label} style={styles.groupedButton}>
              <Button
                key={index}
                isIconRight
                iconName={Icon.names.EXIT}
                iconSize={11}
                isSmall
                onClick={() => onRemoveFilters(keys)}
                iconTranslateY="1"
                isUsingFlexbox
              >
                {label}
              </Button>
            </div>
          ))}
        </div>
      </div>

      <div style={{ marginRight: "22px" }}>
        <SortedBy>Sort by</SortedBy>
        <ActionDropdown
          defaultValue="Sort"
          shouldCloseOnSelect
          isFontPrimary
          isIconPrimary
          value={sortBy}
          options={[
            { label: "Start time", value: "startTime" },
            { label: "Requested time", value: "postedAt" }
          ]}
          onChange={options => onUpdateSortBy(options)}
        />
      </div>
    </PageHeader>
  );
};

export default Header;

import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { ZonedDate } from "@teamrota/rota-common";

import { dateRangeToHuman } from "~/src/utils/formatting";
import ToggleTray from "~/src/components/toggle-tray";
import TextInput from "../text-input";
import Picker from "./components/picker";
import { mergeDateTime, getIsValid } from "./helpers";
import {
  PICKER_WIDTH,
  TIP_SIZE,
  PICKER_TIME_HEIGHT
} from "./components/picker/picker.styles";

const DatePicker = ({
  input,
  inputProps,
  isDisabled,
  isDateOnly,
  isError,
  isSmall,
  start,
  end,
  shouldFixLabelHeight,
  centerText,
  borderRadiusSize,
  shouldNotSelectPastDate,
  shouldNotSelectFutureDate,
  isStartOnly,
  highlightToday,
  onChange
}) => {
  const startMonth = start ? moment(start) : null;

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [visibleDate, setVisibleDate] = useState(
    start ? moment(start) : new ZonedDate()
  );
  const [isSelectingStart, setIsSelectingStart] = useState(true);
  const [startTime, setStartTime] = useState(
    start ? moment(start).format("HH:mm") : "00:00"
  );
  const [endTime, setEndTime] = useState(
    end ? moment(end).format("HH:mm") : "00:00"
  );

  useEffect(() => handleTimeChange(), [startTime, endTime]);

  const changeWithTime = (start, end) => {
    const startWithTime = mergeDateTime(start, startTime);
    const endWithTime = mergeDateTime(end || start, endTime);
    onChange({
      start: startWithTime,
      end: endWithTime,
      isError: !getIsValid(startWithTime, endWithTime)
    });
  };

  const handleTimeChange = () => {
    const startWithTime = mergeDateTime(
      moment(start || new ZonedDate())
        .startOf("day")
        .toDate(),
      startTime
    );
    const endWithTime = mergeDateTime(
      moment(end || start)
        .startOf("day")
        .toDate(),
      endTime
    );
    onChange({
      start: startWithTime,
      end: endWithTime,
      isError: !getIsValid(startWithTime, endWithTime)
    });
  };

  const handleVisibleDateChange = date => setVisibleDate(new ZonedDate(date));

  const handleSelectDate = date => {
    const isStart = isSelectingStart || isStartOnly || isDateOnly;

    let startDate = null;
    let endDate = null;

    // Prevent the start being before the end.
    if (isStart) {
      startDate = date;
    } else if (moment(date).isBefore(moment(start))) {
      startDate = date;
      endDate = start;
    } else {
      // eslint-disable-next-line prefer-destructuring
      startDate = start;
      endDate = date;
    }

    changeWithTime(startDate, endDate);
    setIsSelectingStart(!isStart);
  };

  const openCalendar = e => {
    e.target.blur();
    setIsCalendarOpen(true);
  };

  // Note: This has been left as props.input and props.inputProps because it is CRITICAL to understand that
  // this component comes from props, and is NOT a default HTML <input />!!!!

  const inputComponent = input ? (
    <input {...inputProps} onFocus={openCalendar} />
  ) : (
    <TextInput
      isDisabled={isDisabled}
      placeholder={isDateOnly ? "DD/MM/YYYY" : "Select a start and end"}
      isError={isError}
      value={
        isDateOnly && start
          ? moment(start).format("DD/MM/YYYY")
          : start && end
          ? dateRangeToHuman(start, end)
          : ""
      }
      onFocus={openCalendar}
      isBlock
      shouldFixLabelHeight={shouldFixLabelHeight}
      isSmall={isSmall}
      centerText={centerText}
      borderRadiusSize={borderRadiusSize}
    />
  );

  return (
    <ToggleTray
      preventHide
      fixedTrayHeight={PICKER_TIME_HEIGHT}
      isDisabled={isDisabled}
      addedTopOffset={TIP_SIZE}
      fixedTrayWidth={PICKER_WIDTH}
      isOpen={isCalendarOpen}
      onClose={() => setIsCalendarOpen(false)}
      toggle={inputComponent}
      tray={
        <Picker
          startMonth={startMonth}
          isDisabled={isDisabled}
          shouldNotSelectPastDate={shouldNotSelectPastDate}
          shouldNotSelectFutureDate={shouldNotSelectFutureDate}
          isStartOnly={isStartOnly}
          rangeStart={start}
          rangeEnd={end}
          isFieldError={isError}
          onVisibleDateChange={handleVisibleDateChange}
          visibleDate={visibleDate}
          onClose={() => setIsCalendarOpen(false)}
          onSelectDate={handleSelectDate}
          onSetStart={s => changeWithTime(s, end)}
          onSetEnd={e => changeWithTime(start, e)}
          startTime={startTime}
          endTime={endTime}
          handleStartChange={startTime => setStartTime(startTime)}
          handleEndChange={endTime => setEndTime(endTime)}
          isDateOnly={isDateOnly}
          highlightToday={highlightToday}
        />
      }
    />
  );
};

export default DatePicker;

import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const NoResults = styled.div`
  color: ${({ theme }) => theme.neutral.main};
  text-align: center;
  margin: 0 ${STYLE_CONSTS.UNIT_8};
  height: calc(100vh - 220px);
  display: flex;
  font-size: ${STYLE_CONSTS.FONT_LARGE};
  justify-content: center;
  align-items: center;
`;

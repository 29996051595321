import { gql } from "@apollo/client";

export default gql`
  fragment VenueFragment on venue {
    id
    name
    address
    meetingPoint
    directions
    lat
    lng
  }
`;

import React, { useState } from "react";
import { RotaCheckbox } from "@teamrota/rota-design";

import { toDateRange } from "~/src/utils/date-range";

import { SHIFT_STATUS, canShiftBeRestricted } from "./restrict";

import StaffZonesPanel from "~/src/components/StaffZonesPanel";
import RestrictShiftModal from "./components/RestrictShiftModal";

import {
  StyledContainer,
  StyledCheckBoxContainer,
  EmptyPlaceholder
} from "./styles";
import getProfile from "~/src/graphql/queries/get-profile/get-profile-query.decorator";

// Request/Scheduler wrapper for StaffZonesPanel
// integrates with formData structure and adds controls for restricting shifts

const RequestZonesPanel = ({
  isVisible,
  isEditMode,
  initialShift,
  formData,
  onUpdate,
  isProvide = false,
  selectedAccountId,
  isShiftCancelled,
  user
}) => {
  const hasShiftUnfilledCancelledBookings =
    initialShift?.cancelledBookings?.totalResults > 0;

  const shiftOpenIndex = formData.isLinkedShifts ? 0 : formData.shiftOpenIndex;
  const shift = formData.shifts[shiftOpenIndex];

  const dates = isEditMode
    ? [toDateRange(shift)]
    : Object.values(shift.times).map(toDateRange);

  const [isShowRestrictModal, setIsShowRestrictModal] = useState(false);

  // patch formData with our changes when it changes

  const updateShift = changedFields => {
    onUpdate(
      {
        ...formData,
        shifts: [
          ...formData.shifts.slice(0, shiftOpenIndex),
          {
            ...shift,
            ...changedFields
          },
          ...formData.shifts.slice(shiftOpenIndex + 1)
        ]
      },
      shiftOpenIndex
    );
  };

  const { canRestrict, disable24Hours } = canShiftBeRestricted(
    shift.startTime,
    shift.requestedMemberIds.length
  );

  const isMemberAvailabilityEnabled =
    user?.account?.isMemberAvailabilityEnabled;

  if (!isVisible) return null;

  if (!shift.privates.roleRateAccount) {
    return (
      <EmptyPlaceholder>
        Please select a role at the top of the form before requesting or
        assigning a staff member.
      </EmptyPlaceholder>
    );
  }

  const handleAssign = assignedMemberIds => {
    updateShift({ assignedMemberIds });
  };

  return (
    <StyledContainer>
      <StaffZonesPanel
        shiftId={initialShift?.id}
        isShiftCancelled={isShiftCancelled}
        isProvide={isProvide}
        targetAccountId={shift.privates?.roleRateAccount?.id}
        roleRateId={shift.roleRateId}
        memberType={shift.memberType}
        venueId={shift.venueId}
        dates={dates}
        shiftType={shift.type}
        maxAssigned={shift.numberRequested}
        acceptedBookings={initialShift?.bookings?.ACCEPTED}
        requestedBookings={initialShift?.requested?.data}
        declinedBookings={initialShift?.declined?.data}
        requestedMemberIds={shift.requestedMemberIds}
        assignedMemberIds={shift.assignedMemberIds}
        isRequestAll={shift.isRequestAll}
        isIncludeUnavailable={shift.isIncludeUnavailable}
        onRequest={requestedMemberIds => updateShift({ requestedMemberIds })}
        onAssign={assignedMemberIds => handleAssign(assignedMemberIds)}
        onUnassign={unassignedMemberIds => updateShift({ unassignedMemberIds })}
        selectedAccountId={selectedAccountId}
        tags={shift.tags}
        isDraftShift={initialShift?.isDraft}
        hasShiftUnfilledCancelledBookings={hasShiftUnfilledCancelledBookings}
        totalNumberOfCancelledBookings={
          initialShift?.cancelledBookings?.totalResults
        }
      />

      {!isEditMode && (
        <>
          <StyledCheckBoxContainer isVisible>
            <RotaCheckbox
              label="Restricted to the staff selected above"
              isDisabled={!canRestrict}
              isChecked={shift.skipAlgoShift || shift.delayHoursAlgoShift > 0}
              onClick={e => {
                if (e.target.checked) setIsShowRestrictModal(true);
                else updateShift(SHIFT_STATUS.DEFAULT);
              }}
            />
          </StyledCheckBoxContainer>

          <StyledCheckBoxContainer
            isVisible={user?.account?.isRequestAllEnabled}
          >
            <RotaCheckbox
              label="Request All (Staff who are Qualified & Recommended)"
              isChecked={shift.isRequestAll}
              onClick={e => updateShift({ isRequestAll: e.target.checked })}
            />
          </StyledCheckBoxContainer>
          <StyledCheckBoxContainer
            isMarginLeft={40}
            isVisible={isMemberAvailabilityEnabled && shift.isRequestAll}
          >
            <RotaCheckbox
              label="Include staff members that are not available"
              isChecked={shift.isIncludeUnavailable}
              onClick={e =>
                updateShift({ isIncludeUnavailable: e.target.checked })
              }
            />
          </StyledCheckBoxContainer>
        </>
      )}

      {isEditMode && (
        <StyledCheckBoxContainer isVisible>
          <RotaCheckbox
            label="Restricted to the staff selected above"
            isDisabled
            isChecked={shift.skipAlgoShift || shift.delayHoursAlgoShift > 0}
          />
        </StyledCheckBoxContainer>
      )}

      <RestrictShiftModal
        isVisible={isShowRestrictModal}
        disable24Hours={disable24Hours}
        onUpdate={updateShift}
        onClose={() => setIsShowRestrictModal(false)}
      />
    </StyledContainer>
  );
};

export default getProfile(RequestZonesPanel);

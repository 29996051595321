import React, { useEffect, useState } from "react";
import moment from "moment-timezone";

import {
  colors,
  icon,
  RotaButton,
  RotaButtonNew,
  RotaButtonIcon,
  RotaModal
} from "@teamrota/rota-design";

import { POOL_TYPES } from "@teamrota/rota-common";

import { useCurrency } from "~/src/containers/profile-loader";
import {
  FeedbackWrapper,
  Label
} from "~/src/containers/timesheets/components/timesheet/components/rating/rating.styles";

import { errorModal } from "~/src/utils/errors";
import { getFormatterMoney } from "~/src/utils/formatting";
import { hoursAndMinutesDisplay } from "~/src/utils/formatting-hours-timesheet";
import Attendance from "../attendance";
import CheckInOut from "../check-in-out";
import DialogBadRate from "../dialog/bad-rate";
import DialogTurnedAway from "../dialog/turned-away";
import Member from "../member";
import Rating from "../rating";
import ScheduledHours from "../scheduled-hours";

import {
  BreakInput,
  StyledBookingColumn,
  StyledBookingColumnPayRates,
  StyledBookingGridColumn,
  StyledColumnPayRates,
  StyledGridColumn,
  StyledGridColumnPayRates,
  StyledTextArea,
  StyledWrapButton,
  StyledWrapEditMode,
  StyledWrapFeedbackRating,
  StyledWrapGrid,
  ButtonsWrapper
} from "./timesheet-row.styles";

import { useCreateOrUpdateRelationship } from "./use-create-or-update-relationship";

const feedbackOptions = [
  "Punctuality",
  "Wrong Uniform",
  "Unkempt presentation",
  "Skills",
  "Attitude",
  "Damages the brand"
];

const { TurnedAwayIcon, StarIcons } = icon;

const TimesheetRow = ({
  bookingData,
  setBookingUpdates,
  handleApprove,
  isApproved,
  isUncalculatedRate,
  setIsApproveEnabled,
  refetchShift,
  setSnackMessage,
  isPayCalculationOnCheckInOutTimes,
  isTTM
}) => {
  const { currencyCode, currencySymbol } = useCurrency();
  const formatterMoney = getFormatterMoney(currencyCode);

  const { booking, member, bookingState } = bookingData;

  const initialStateRow = {
    id: booking.id,
    startOnApproval: isPayCalculationOnCheckInOutTimes
      ? booking?.startFinal
      : booking?.startTime,
    endOnApproval: isPayCalculationOnCheckInOutTimes
      ? booking?.endFinal
      : booking?.endTime,
    breakOnApproval: booking?.breakFinal || 0,
    ratingOfMember: booking?.ratingOfMember,
    feedbackForMember: booking?.feedbackForMember,
    feedbackLabelsForMember: booking?.feedbackLabelsForMember,
    isFavourited: member?.poolType === POOL_TYPES.FAVOURITED,
    notes: booking?.notes,
    isNoShow: booking?.isNoShow,
    isTurnedAway: booking?.isTurnedAway
  };

  const [stateRow, setStateRow] = useState(initialStateRow);

  const [isBlacklisted, setIsBlacklisted] = useState(
    member?.poolType === POOL_TYPES.BLACKLISTED
  );

  const [breakInputValue, setBreakInputValue] = useState(
    stateRow?.breakOnApproval
  );

  const [isSaving, setIsSaving] = useState(false);
  const [isAutoSaving, setIsAutoSaving] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const [isTurnedAwayModal, setIsTurnedAwayModal] = useState(false);
  const [isBlacklistModalVisible, setIsBlacklistModalVisible] = useState(false);

  const [isOpenModalRatingBadShift, setIsOpenModalBadShift] = useState(false);
  const [badRatingOfMember, setBadRatingOfMember] = useState(0);

  const [createOrUpdateRelationship] = useCreateOrUpdateRelationship();

  useEffect(() => {
    if (isEditMode) setIsApproveEnabled(prev => prev + 1);
    else setIsApproveEnabled(prev => (prev <= 0 ? 0 : prev - 1));
  }, [isEditMode]);

  useEffect(() => {
    // autosave good star ratings
    if (
      !isEditMode &&
      stateRow?.ratingOfMember !== initialStateRow?.ratingOfMember
    ) {
      if (stateRow.ratingOfMember > 2) {
        handleSave(true); // autosave good ratings when not in edit mode
      } else {
        addBookingUpdate(); // pick it up if approve is pressed
      }
    }
  }, [stateRow?.ratingOfMember]);

  const onUpdateStartTime = date => {
    setStateRow(prev => {
      return {
        ...prev,
        startOnApproval: moment(date).format()
      };
    });
  };

  const onUpdateEndTime = date => {
    setStateRow(prev => {
      return {
        ...prev,
        endOnApproval: moment(date).format()
      };
    });
  };

  const onFeedbackUpdate = value => {
    setStateRow(prev => {
      return { ...prev, feedbackForMember: value };
    });
  };

  const onUpdateFeedbackLabels = labels => {
    setStateRow(prev => {
      return { ...prev, feedbackLabelsForMember: labels };
    });
  };

  const handleChangeRating = async value => {
    if (stateRow.isNoShow || stateRow.isTurnedAway) {
      return await errorModal(
        `You cannot rate if marked as ${
          stateRow.isTurnedAway ? "Turned Away" : "No Show"
        }`
      );
    }

    // if valued 3 or below and there is a pop up open
    if (value <= 3) {
      if (!isTTM) {
        setBadRatingOfMember(value);
        setIsOpenModalBadShift(true);
      } else {
        setStateRow(prev => ({
          ...prev,
          ratingOfMember: value,
          feedbackLabelsForMember: []
        }));
      }
    } else if (value <= 5) {
      onToggleFavourite(false);
      setStateRow(prev => ({
        ...prev,
        ratingOfMember: value,
        feedbackLabelsForMember: []
      }));
    } else if (value === 6) {
      setStateRow(prev => ({
        ...prev,
        ratingOfMember: value,
        feedbackLabelsForMember: []
      }));
    }
  };

  const onToggleFavourite = value => {
    if (value) {
      if (stateRow.isNoShow || stateRow.isTurnedAway) {
        setStateRow(prev => {
          return {
            ...prev,
            isFavourited: true,
            isNoShow: false,
            isTurnedAway: false,
            feedbackForMember: null,
            feedbackLabelsForMember: null,
            ratingOfMember: 6
          };
        });

        setIsBlacklisted(false);
      } else {
        setStateRow(prev => {
          return {
            ...prev,
            isFavourited: true,
            feedbackLabelsForMember: null,
            ratingOfMember: 6
          };
        });
      }
    } else {
      setStateRow(prev => {
        return {
          ...prev,
          isFavourited: false
        };
      });
    }
  };

  const onConfirmBadRating = async () => {
    if (badRatingOfMember === 3) {
      setStateRow(prev => {
        return { ...prev, feedbackLabelsForMember: [] };
      });
    }

    onToggleFavourite(false);

    setStateRow(prev => {
      return {
        ...prev,
        ratingOfMember: badRatingOfMember
      };
    });

    setBadRatingOfMember(0);
    setIsOpenModalBadShift(false);
  };

  const onChangeBreakInput = event => {
    const { endFinal, startFinal } = booking;
    const { value } = event?.target;

    const fullShiftInminutes = moment
      .duration(
        moment(stateRow?.endOnApproval || endFinal).diff(
          moment(stateRow?.startOnApproval || startFinal)
        )
      )
      .asMinutes();

    if (/^\d*$/.test(value) && value < fullShiftInminutes) {
      setBreakInputValue(Number(value));
      setStateRow(prev => {
        return { ...prev, breakOnApproval: Number(value) };
      });
    }
  };

  const onToggleNoShow = value => {
    if (value) {
      setStateRow(prev => {
        return {
          ...prev,
          isNoShow: true,
          isFavourited: false,
          isTurnedAway: false,
          ratingOfMember: null,
          notes: ""
        };
      });

      setIsBlacklistModalVisible(true);
    } else {
      setStateRow(prev => {
        return {
          ...prev,
          isNoShow: false
        };
      });

      setIsBlacklisted(false);
    }
  };

  const onToggleTurnedAway = value => {
    if (value) {
      setIsTurnedAwayModal(true);
    } else {
      setIsTurnedAwayModal(false);

      setStateRow(prev => {
        return {
          ...prev,
          isTurnedAway: false
        };
      });

      setIsBlacklisted(false);
    }
  };

  const onToggleTurnedAwayConfirm = () => {
    setIsTurnedAwayModal(false);

    setStateRow(prev => {
      return {
        ...prev,
        isTurnedAway: true,
        isNoShow: false,
        isFavourited: false,
        ratingOfMember: null
      };
    });

    setIsBlacklistModalVisible(true);
  };

  const onToggleBlacklist = value => {
    setIsBlacklistModalVisible(false);
    setIsBlacklisted(value);
  };

  const onNotesUpdate = value => {
    setStateRow(prev => {
      return { ...prev, notes: value };
    });
  };

  const addBookingUpdate = () => {
    setBookingUpdates(prev => {
      const oldBooking = prev.filter(el => el.id !== booking.id);
      return [
        ...oldBooking,
        {
          id: booking?.id,
          ...stateRow
        }
      ];
    });
  };

  const handleSave = async (auto = false) => {
    if (!isSaving && !isAutoSaving) {
      if (auto) {
        setIsAutoSaving(true);
      } else {
        setIsSaving(true);
      }

      setIsEditMode(false);

      try {
        if (!auto) {
          if (isBlacklisted) {
            await createOrUpdateRelationship({
              variables: {
                poolType: POOL_TYPES.BLACKLISTED,
                memberId: member.id
              }
            });
          } else if (stateRow.isFavourited) {
            await createOrUpdateRelationship({
              variables: {
                poolType: POOL_TYPES.FAVOURITED,
                memberId: member.id
              }
            });
          }
        }

        addBookingUpdate();

        const approved = await handleApprove({
          isApprovingShift: false,
          bookingForUpdate: { id: booking?.id, ...stateRow }
        });

        if (approved) {
          await refetchShift();
          setSnackMessage("Saved successfully", "success");

          setIsSaving(false);
          setIsAutoSaving(false);
        } else {
          setIsSaving(false);
          setIsAutoSaving(false);
          setIsEditMode(true);
        }
      } catch (e) {
        console.log(e);

        setSnackMessage("Something went wrong", "error");
        setIsSaving(false);
        setIsAutoSaving(false);
        setIsEditMode(true);
      }
    }
  };

  const handleCancel = async () => {
    if (!isSaving && !isAutoSaving) {
      setIsEditMode(false);
      setBreakInputValue(initialStateRow.breakOnApproval);
      setStateRow(initialStateRow);
    }
  };

  const isFeedbackShown =
    !!stateRow?.ratingOfMember &&
    !isApproved &&
    !stateRow?.isNoShow &&
    !stateRow?.isTurnedAway;

  const isStarRatingEditable =
    !isApproved && !stateRow?.isNoShow && !stateRow?.isTurnedAway;

  const shouldShowBlacklistModal = isBlacklistModalVisible && !isTTM;

  return (
    <div>
      <StyledWrapGrid>
        <StyledGridColumn start={1} vertical>
          Staff member
        </StyledGridColumn>
        <StyledGridColumn start={2}>Scheduled hours</StyledGridColumn>
        <StyledGridColumn start={3}>Check in/out</StyledGridColumn>
        <StyledGridColumn start={4}>Break</StyledGridColumn>
        <StyledGridColumn start={5}>Other</StyledGridColumn>
        <StyledGridColumn start={6}>Rate Shift</StyledGridColumn>
        <StyledWrapButton>
          {isApproved ||
            (!isEditMode && (
              <RotaButtonIcon icon="edit" onClick={() => setIsEditMode(true)} />
            ))}
        </StyledWrapButton>
      </StyledWrapGrid>
      <StyledBookingGridColumn>
        <StyledBookingColumn start={1} padding="10px">
          <Member member={member} />
        </StyledBookingColumn>

        <StyledBookingColumn start={2} justify="center">
          <ScheduledHours
            onUpdateStartTime={date => onUpdateStartTime(date)}
            onUpdateEndTime={date => onUpdateEndTime(date)}
            onToggleEditMode={() => setIsEditMode(!isEditMode)}
            isEditMode={isEditMode}
            startTimeUpdate={stateRow?.startOnApproval}
            endTimeUpdate={stateRow?.endOnApproval}
            isNoShow={stateRow?.isNoShow}
            booking={booking}
            isTurnedAway={stateRow?.isTurnedAway}
            bookingState={bookingState}
          />
        </StyledBookingColumn>

        <StyledBookingColumn start={3} justify="center">
          <CheckInOut booking={booking} bookingState={bookingState} />
        </StyledBookingColumn>

        <StyledBookingColumn start={4} justify="center">
          {!stateRow?.isNoShow &&
            !stateRow?.isTurnedAway &&
            (isEditMode ? (
              <span>
                <BreakInput
                  value={breakInputValue}
                  onChange={onChangeBreakInput}
                />
                &nbsp;min
              </span>
            ) : (
              <span>
                <p>{breakInputValue} min</p>
              </span>
            ))}
        </StyledBookingColumn>

        <StyledBookingColumn start={5} justify="center">
          <Attendance
            isFavourited={stateRow?.isFavourited}
            isNoShow={stateRow?.isNoShow}
            isTurnedAway={stateRow?.isTurnedAway}
            onToggleFavourite={() => onToggleFavourite(!stateRow?.isFavourited)}
            onToggleNoShow={() => onToggleNoShow(!stateRow?.isNoShow)}
            onToggleTurnedAway={() =>
              onToggleTurnedAway(!stateRow?.isTurnedAway)
            }
            isEditMode={isEditMode}
          />
        </StyledBookingColumn>

        <StyledBookingColumn start={6} justify="center">
          <Rating
            onChange={value => handleChangeRating(value)}
            ratingUpdate={badRatingOfMember || stateRow?.ratingOfMember}
            isGrey={stateRow?.isNoShow || stateRow?.isTurnedAway}
            isEditMode={isStarRatingEditable}
          />
        </StyledBookingColumn>
      </StyledBookingGridColumn>
      {isFeedbackShown && (
        <StyledWrapFeedbackRating>
          <FeedbackWrapper
            style={{
              gridColumnStart: 2
            }}
          >
            {stateRow?.feedbackLabelsForMember?.length > 0 && !isEditMode && (
              <>
                <p style={{ marginRight: "8px" }}>Your feedback:&nbsp;</p>
                {stateRow.ratingOfMember < 3 &&
                  stateRow.feedbackLabelsForMember.map(label => (
                    <Label isSelected={true} key={label}>
                      {label}
                    </Label>
                  ))}
              </>
            )}
            {isEditMode && stateRow?.ratingOfMember < 3 && (
              <>
                <p style={{ marginRight: "8px" }}>Provide feedback:&nbsp;</p>
                {stateRow.ratingOfMember < 3 &&
                  feedbackOptions.map(label => (
                    <Label
                      isSelected={
                        stateRow.feedbackLabelsForMember &&
                        stateRow.feedbackLabelsForMember.includes(label)
                      }
                      onClick={() =>
                        onUpdateFeedbackLabels(
                          stateRow.feedbackLabelsForMember &&
                            stateRow.feedbackLabelsForMember.includes(label)
                            ? stateRow.feedbackLabelsForMember.filter(
                                l => l !== label
                              )
                            : [
                                ...(stateRow.feedbackLabelsForMember || []),
                                label
                              ]
                        )
                      }
                      key={label}
                    >
                      {label}
                    </Label>
                  ))}
              </>
            )}
          </FeedbackWrapper>

          <StyledTextArea
            placeholder="Enter Feedback"
            defaultValue={stateRow.feedbackForMember}
            onBlur={e => onFeedbackUpdate(e.target.value)}
            row="2"
            col="5"
            disabled={!isEditMode}
          />
        </StyledWrapFeedbackRating>
      )}
      {(isEditMode || isSaving) && (
        <StyledWrapEditMode>
          <div
            style={{
              display: "flex",
              gridColumnStart: 3,
              justifyContent: "flex-end",
              marginBottom: "18px",
              marginRight: "15px"
            }}
          >
            <RotaButton
              variant="secondary"
              size="small"
              disabled={isSaving}
              onClick={handleCancel}
            >
              Cancel
            </RotaButton>

            <RotaButton
              size="small"
              style={{ margin: "0 17px 0 22px" }}
              isLoading={isSaving}
              disabled={isSaving}
              onClick={() => handleSave(false)}
            >
              Save
            </RotaButton>
          </div>
        </StyledWrapEditMode>
      )}
      {!isTTM && (
        <>
          <StyledGridColumnPayRates>
            <StyledColumnPayRates start={1} bold>
              Payment info
            </StyledColumnPayRates>
            <StyledColumnPayRates start={2}>Regular rate</StyledColumnPayRates>
            <StyledColumnPayRates start={3}>Paid hours</StyledColumnPayRates>
            {!!booking?.overtimeChargeRate && (
              <>
                <StyledColumnPayRates start={4}>
                  Overtime rate
                </StyledColumnPayRates>
                <StyledColumnPayRates start={5}>
                  Paid hours
                </StyledColumnPayRates>
              </>
            )}
            {!!booking?.overstayChargeRate && (
              <>
                <StyledColumnPayRates start={4}>
                  Overstay rate
                </StyledColumnPayRates>
                <StyledColumnPayRates start={5}>
                  Paid hours
                </StyledColumnPayRates>
              </>
            )}
            <StyledColumnPayRates
              start={
                booking?.overtimeChargeRate || booking?.overstayChargeRate
                  ? 6
                  : 4
              }
            >
              Bonus
            </StyledColumnPayRates>
          </StyledGridColumnPayRates>
          <StyledBookingColumnPayRates>
            <StyledBookingColumn start={2} justify="center">
              {isUncalculatedRate && "Uncalculated"}
              {!isUncalculatedRate &&
                formatterMoney.format(booking?.chargeRate / 100)}
            </StyledBookingColumn>
            <StyledBookingColumn start={3} justify="center">
              {hoursAndMinutesDisplay(bookingState)}
            </StyledBookingColumn>
            {!!booking?.overtimeChargeRate && (
              <>
                <StyledBookingColumn start={4} justify="center">
                  {`${currencySymbol} ${(
                    booking?.overtimeChargeRate / 100
                  ).toFixed(2)}`}
                </StyledBookingColumn>
                <StyledBookingColumn start={5} justify="center">
                  {hoursAndMinutesDisplay(
                    bookingState,
                    booking?.overtimeHoursWorked
                  )}
                </StyledBookingColumn>
              </>
            )}
            {!!booking?.overstayChargeRate && (
              <>
                <StyledBookingColumn start={4} justify="center">
                  {`${currencySymbol} ${(
                    booking?.overstayChargeRate / 100
                  ).toFixed(2)}`}
                </StyledBookingColumn>
                <StyledBookingColumn start={5} justify="center">
                  {hoursAndMinutesDisplay(
                    bookingState,
                    booking?.overtimeHoursLimit
                  )}
                </StyledBookingColumn>
              </>
            )}
            <StyledBookingColumn
              start={
                booking?.overtimeChargeRate || booking?.overstayChargeRate
                  ? 6
                  : 4
              }
              justify="center"
            >
              {formatterMoney.format(bookingState?.bonusFinal / 100)}
            </StyledBookingColumn>
          </StyledBookingColumnPayRates>
        </>
      )}

      {isTurnedAwayModal && (
        <RotaModal
          name="Turned Away"
          isSmall
          onClose={() => setIsTurnedAwayModal(false)}
        >
          <DialogTurnedAway
            onConfirm={onToggleTurnedAwayConfirm}
            onCancel={() => onToggleTurnedAway(false)}
            firstName={member.firstName}
            lastName={member.lastName}
            onSaveNotes={onNotesUpdate}
            notesUpdate={stateRow?.notes}
            icon={
              <TurnedAwayIcon
                color={colors.rotaOrange}
                colorLine={colors.black}
                styles={{ width: "50px", height: "50px" }}
              />
            }
            isInput
          />
        </RotaModal>
      )}

      {shouldShowBlacklistModal && (
        <RotaModal
          name="Blacklist"
          isSmall
          onClose={() => setIsBlacklistModalVisible(false)}
        >
          <div>Do you want to also blacklist this member?</div>
          <ButtonsWrapper>
            <RotaButtonNew
              onClick={() => onToggleBlacklist(false)}
              variant="outlined"
            >
              No
            </RotaButtonNew>
            <RotaButtonNew onClick={() => onToggleBlacklist(true)}>
              Yes
            </RotaButtonNew>
          </ButtonsWrapper>
        </RotaModal>
      )}

      {isOpenModalRatingBadShift && (
        <RotaModal
          isSmall
          onClose={() => {
            setIsOpenModalBadShift(false);
            setBadRatingOfMember(0);
          }}
        >
          <DialogBadRate
            onConfirm={() => onConfirmBadRating()}
            onCancel={() => {
              setIsOpenModalBadShift(false);
              setBadRatingOfMember(0);
            }}
            value={badRatingOfMember}
            upcomingAccepted={member.upcomingAccepted}
            icon={
              <StarIcons
                color={colors.red}
                styles={{ width: "50px", height: "50px" }}
              />
            }
          />
        </RotaModal>
      )}
    </div>
  );
};

export default TimesheetRow;

import React from "react";
import { getCustomLogActions, formatAction } from "../../helpers";
import { Action } from "../../index.styles";

export default props => {
  const customLogAction = getCustomLogActions(props.log);

  if (customLogAction) {
    return <Action>{customLogAction}</Action>;
  }

  return <Action>{formatAction(props.log)}:</Action>;
};

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import getOr from "lodash/fp/getOr";

import TextInput from "~/src/components/form-components/text-input";
import { FilterOptions } from "../../notifications.styles";
import { onRatingUpdate } from "../../reducer";

const bindActions = dispatch =>
  bindActionCreators(
    {
      onRatingUpdate
    },
    dispatch
  );

const FilterRatings = props => (
  <FilterOptions>
    <p>Rating from</p>
    <TextInput
      type="number"
      placeholder="Any"
      min="1"
      max="6"
      isOutline
      value={getOr(1, "rating.min", props.selectedFilterOptions)}
      onChangeValue={value => props.onRatingUpdate({ name: "min", value })}
    />
    <p>to</p>
    <TextInput
      type="number"
      placeholder="Any"
      min="1"
      max="6"
      isOutline
      value={getOr(6, "rating.max", props.selectedFilterOptions)}
      onChangeValue={value => props.onRatingUpdate({ name: "max", value })}
    />
  </FilterOptions>
);

export default connect(
  s => ({
    selectedFilterOptions: s.notifications.selectedFilterOptions
  }),
  bindActions
)(FilterRatings);

import { InnerCell, iconNew } from "@teamrota/rota-design";
import styled from "styled-components";
import { RotaTable } from "@teamrota/rota-design";

const { Pin, PinSuccess, PinWarn, PinError } = iconNew;

export const StyledPin = styled(Pin)`
  color: ${({ theme }) => theme.primary.main};
`;

export const StyledPinSuccess = styled(PinSuccess)`
  color: ${({ theme }) => theme.success.main};
`;

export const StyledPinWarn = styled(PinWarn)<{ isChecked: boolean }>`
  color: ${({ theme, isChecked }) =>
    isChecked ? theme.text.primary : theme.warning.main};
`;

export const StyledPinError = styled(PinError)`
  color: ${({ theme }) => theme.error.main};
`;

export const InnerCellTime = styled(InnerCell)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RotaTableScrollable = styled(RotaTable)`
  & > thead tr {
    position: relative;
    z-index: 2;
  }

  & > thead th {
    position: sticky;
    top: 0px;
    background-color: ${({ theme }) => theme.white};
    z-index: 1;
  }
`;

export const SelectedItemsInfo = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.text.secondary};
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 32px;
  min-height: 38px;
`;

import styled from "styled-components";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";
import Icon from "~/src/components/icon";

export const Heading = styled.div`
  font-weight: 400;
  text-transform: uppercase;
  color: ${({ isSubHeading }) =>
    isSubHeading ? COLORS.COOL_GREY : COLORS.BLACK};

  font-size: ${({ isSubHeading }) =>
    isSubHeading ? STYLE_CONSTS.FONT_SMALL : STYLE_CONSTS.FONT};

  ${({ isSubHeading }) =>
    isSubHeading &&
    `padding: 10px;
    text-transform: none;
    text-align: center;`};
`;

export const Wrapper = styled.div`
  padding: 10px;
  margin-top: 10px;
  box-shadow: ${STYLE_CONSTS.FLOATING_MEDIUM};
  border-radius: 6px;
  background: ${({ theme }) => theme.white};
  overflow-x: hidden;
  overflow-y: auto;
  height: auto;
  min-height: 155px;
  max-height: 280px;
  ${({ isOpen }) =>
    isOpen
      ? `
    height: 280px;
transition: height 0.4s ease-out;`
      : `height: 155px;
transition: height 0.4s ease-in;`};

  ${({ isEmpty }) =>
    isEmpty &&
    `    
    min-height: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;`}
`;

export const Row = styled.div`
  margin-top: ${STYLE_CONSTS.UNIT_2};
  position: relative;
  display: flex;
  align-items: flex-start;

  ${({ shouldFlex }) =>
    shouldFlex &&
    `
  display: flex;
  flex-direction: column;
  justify-content: center;
  `}
`;

export const Placeholder = styled.div`
  font-size: ${STYLE_CONSTS.FONT_SMALL};
  color: ${COLORS.COOL_GREY};
  text-align: center;
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const Column = styled.div`
  display: flex;
  flex: 5;
  justify-content: flex-start;
  align-items: center;
  ${({ isPrintButton }) =>
    isPrintButton &&
    `
  &:last-child {
    flex: 1;
    text-align: right;
    justify-content: flex-end;
    
  }
  `}
`;

export const StyledButton = styled.div`
  border: 1px solid ${({ theme }) => theme.primary.main};
  color: ${({ theme }) => theme.primary.main};
  padding: 5px;
  border-radius: 6px;
  text-align: center;
  text-transform: uppercase;
  font-size: 9px;
  height: 20px;
  letter-spacing: 1px;

  &:hover {
    background: ${({ theme }) => theme.primary.main};
    color: ${({ theme }) => theme.white};
    cursor: pointer;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 15px;
`;

export const SeeMoreWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  &:hover {
    cursor: pointer;
  }
  ${({ isArrowToggled }) =>
    isArrowToggled
      ? `transform: rotate(180deg);
transition: transform 0.5s linear;`
      : `  transform: rotate(0deg);
transition: transform 0.5s linear;`};
`;

export const Label = styled.div`
  padding-left: 5px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
`;

export const StyledIcon = styled(Icon).attrs({
  name: Icon.names.ARROW_DOWN,
  size: "small",
  color: COLORS.MAIN_ORANGE
})`
  ${({ isOpen }) =>
    isOpen
      ? `transform: rotate(180deg);
transition: transform 0.5s linear;`
      : `  transform: rotate(0deg);
transition: transform 0.5s linear;`};
`;

export const AppliedMemberWrapper = styled.div`
  width: 100%;
  padding: 0 30px;
`;

export const Number = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 100%;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 6px;
  box-sizing: border-box;
  background: ${({ theme }) => theme.primary.main};
  color: ${({ theme }) => theme.white};
`;

export const FadeOut = styled.div`
  position: absolute;
  pointer-events: none;
  width: 96%;
  height: 4em;
  border-radius: 6px;
  bottom: 52px;
  left: 20;
  background: linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
`;

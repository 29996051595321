import { regex } from "@teamrota/rota-common";

export default values => {
  const errors = {};

  if (!values.accountName) {
    errors.accountName = "Account name is required";
  }

  if (values.contactEmail && !regex.EMAIL_ADDRESS.test(values.contactEmail)) {
    errors.contactEmail = "Please enter a valid email";
  }

  return errors;
};

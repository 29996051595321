import React, { Fragment } from "react";

import { useUncalculatedRoleDetails } from "./use-uncalculated-role-details";
import { LabelCell, StyledGrid, StyledWrapSubtitle, ValueCell } from "./style";

const UncalculatedRoleDetails = ({ roleId }) => {
  const { uncalculatedRoleRates, loading, error } = useUncalculatedRoleDetails({
    roleId
  });

  if (loading && !uncalculatedRoleRates) return "Loading...";
  if (error && !uncalculatedRoleRates) return "Ooops! Something went wrong...";
  if (!uncalculatedRoleRates) return null;

  return (
    <StyledGrid>
      <StyledWrapSubtitle>Rate Info</StyledWrapSubtitle>
      <LabelCell>Pay Type</LabelCell>
      <LabelCell>Pay Rate</LabelCell>
      <LabelCell>Charge Rate</LabelCell>
      <LabelCell>Applicable</LabelCell>

      {uncalculatedRoleRates.map(uncalculatedRoleRate => {
        return (
          <Fragment key={uncalculatedRoleRate.id}>
            <LabelCell>{uncalculatedRoleRate.name}</LabelCell>
            <ValueCell>
              {uncalculatedRoleRate?.isDayRate ? "Daily" : "Hourly"}
            </ValueCell>
            <ValueCell>{uncalculatedRoleRate?.payRate}</ValueCell>
            <ValueCell>{uncalculatedRoleRate?.chargeRate}</ValueCell>
            <ValueCell>{uncalculatedRoleRate?.applicable || "-"}</ValueCell>
          </Fragment>
        );
      })}
    </StyledGrid>
  );
};

export default UncalculatedRoleDetails;

import React, { useState } from "react";
import { useQuery } from "@apollo/client";

import update from "lodash/fp/update";
import get from "lodash/fp/get";

import { GET_NOTIFS } from "./graphql/get-history";
import { StyledSearchInput } from "./notification-history.styles";

import {
  FloatingTable,
  ColumnHeading,
  TableBody,
  TableData,
  TableRow
} from "~/src/components/floating-table";
import TablePlaceholder from "~/src/components/table-placeholder";
import LoadMore from "~/src/components/load-more";
import PageHeader from "~/src/components/page-header";
import NoResults from "~/src/components/no-results";
import FiltersDropdown from "~/src/components/filters-dropdown";
import FiltersDateRange from "~/src/components/form-components/filters-date-range";

import { errorModal } from "~/src/utils/errors";
import { formatDateFull } from "~/src/utils/formatting";

const TABLE_WIDTHS = {
  Type: 3 / 12,
  Notes: 2 / 12,
  Recipient: 2 / 12,
  Sender: 2 / 12,
  Content: 3 / 12
};

const LIMIT = 20;

const NotificationsHistory = () => {
  const [offset, setOffset] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const { data, loading, fetchMore } = useQuery(GET_NOTIFS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    variables: {
      offset: 0,
      limit: LIMIT,
      searchText,
      startTime,
      endTime
    }
  });

  const notificationsData = data?.account?.notificationsList || {};
  const messages = notificationsData.data || [];
  const totalResults = notificationsData.totalResults;
  const isNoResults = messages.length === 0 && !loading;
  const isNoMoreResults = totalResults === messages.length;

  const handleLoadMore = () => {
    try {
      const newOffset = offset + LIMIT;
      const path = "account.notificationsList.data";
      fetchMore({
        variables: { offset: newOffset },
        updateQuery: (previousResult, { fetchMoreResult }) =>
          update(
            path,
            current => [...current, ...get(path, fetchMoreResult)],
            previousResult
          )
      });
      setOffset(newOffset);
    } catch (e) {
      errorModal(e);
    }
  };

  return (
    <>
      <PageHeader title="History" subtext={`${totalResults} messages`}>
        <div>
          <StyledSearchInput
            isInline
            isLarge
            placeholder="Search history by member name, email or ID"
            onChange={setSearchText}
          />
          <FiltersDropdown>
            <FiltersDateRange
              startTime={startTime}
              endTime={endTime}
              onChangeStartTime={setStartTime}
              onChangeEndTime={setEndTime}
            />
          </FiltersDropdown>
        </div>
        {/* empty div to align the search bar same as on the provide page */}
        <div style={{ width: "165px" }}></div>
      </PageHeader>
      <FloatingTable>
        <ColumnHeading width={TABLE_WIDTHS.Type}>Type</ColumnHeading>
        <ColumnHeading width={TABLE_WIDTHS.Notes}>Notes</ColumnHeading>
        <ColumnHeading width={TABLE_WIDTHS.Recipient}>Recipient</ColumnHeading>
        <ColumnHeading width={TABLE_WIDTHS.Sender}>Sender</ColumnHeading>
        <ColumnHeading width={TABLE_WIDTHS.Content}>Content</ColumnHeading>
        <TableBody>
          {isNoResults ? (
            <NoResults />
          ) : (
            <>
              {loading && (
                <TablePlaceholder
                  columnWidths={[
                    TABLE_WIDTHS.Type,
                    TABLE_WIDTHS.Notes,
                    TABLE_WIDTHS.Recipient,
                    TABLE_WIDTHS.Sender,
                    TABLE_WIDTHS.Content
                  ]}
                />
              )}
              {messages.map((msg, i) => (
                <TableRow key={i}>
                  <TableData width={TABLE_WIDTHS.Type}>
                    <div>{msg.notificationType}</div>
                    <div>{formatDateFull(msg.dateSent)}</div>
                  </TableData>
                  <TableData width={TABLE_WIDTHS.Notes} wordWrap="break-word">
                    <div>{msg.status}</div>
                  </TableData>
                  <TableData
                    width={TABLE_WIDTHS.Recipient}
                    wordWrap="break-word"
                  >
                    <div>{msg.recipient}</div>
                  </TableData>
                  <TableData width={TABLE_WIDTHS.Sender} wordWrap="break-word">
                    {msg.sender && (
                      <div>
                        {msg.sender.firstName} {msg.sender.lastName}
                      </div>
                    )}
                  </TableData>
                  <TableData width={TABLE_WIDTHS.Content} wordWrap="break-word">
                    <div
                      // Must be done as the body can contain HTML
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{ __html: msg.body }}
                    />
                  </TableData>
                </TableRow>
              ))}
            </>
          )}
        </TableBody>
      </FloatingTable>
      {!isNoResults && !isNoMoreResults && (
        <LoadMore loading={loading} onLoadMore={handleLoadMore} />
      )}
    </>
  );
};

export default NotificationsHistory;

import styled from "styled-components";

export const ControlWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 300px;
`;

export const Label = styled.div`
  display: block;
  width: 75px;
`;

export const Error = styled.div`
  color: red;
  padding-top: 10px;
`;

import React, { useState } from "react";
import { flow } from "lodash";
import { get } from "lodash/fp";
import { Role } from "@teamrota/authlib";

import { withAuth } from "~/src/auth/hoc";
import Modal from "~/src/components/modal";
import { dateRangeToHuman, getPolicyQueryParams } from "~/src/utils/formatting";
import Button from "~/src/components/button";
import getProfile from "~/src/graphql/queries/get-profile/get-profile-query.decorator";
import Tabs from "~/src/containers/modals/components/tabs";
import History from "~/src/containers/modals/components/history";
import { APPROVAL_STATES_LABELS, BOOKING_STATES } from "~/src/consts";
import { errorModal } from "~/src/utils/errors";
import Icon from "~/src/components/icon";
import TimeDisplay from "~/src/components/shift-time-display";
import Money from "~/src/utils/money";
import { SurgeString } from "~/src/services/surge-pricing";

import BookingDetails from "./booking-details";
import getBooking from "./graphql/get-booking";
import EditPolicies from "./edit-policies";
import {
  ButtonWrapper,
  ResentSuccess,
  CostWrapper
} from "./view-edit-booking.styles";
import resendAcceptedEmail from "./graphql/resend-accepted-email";
import ModalContent from "../components/modal-content";
import Title from "../components/title";
import Text, { SmallText } from "../components/text";
import { LeftPane, RightPane } from "../components/content-wrapper";
import HasRole from "../../has-role";
import { useCurrency } from "../../profile-loader";

const ViewEditBooking = ({
  resendAcceptedEmail,
  onClose,
  user,
  booking,
  auth,
  isLoading,
  isOpen
}) => {
  const [isResendingEmail, setIsResendingEmail] = useState(false);
  const [isResent, setIsResent] = useState(false);

  const { taxRate } = useCurrency();

  const constants = get("account.constants", user);
  const accountHasProviderPolicy = get("account.policy", user);

  const tabs = [
    {
      name: "Info",
      content: <BookingDetails constants={constants} booking={booking} />
    },
    ...(accountHasProviderPolicy
      ? [
          {
            name: "Policies",
            content: <EditPolicies booking={booking} />
          }
        ]
      : []),
    ...(auth.hasRole(Role.BOOKINGS_HISTORY)
      ? [
          {
            name: "History",
            content: <History logs={booking?.logs} />
          }
        ]
      : [])
  ];

  const isPayCalculationOnCheckInOutTimes = get(
    "account.isPayCalculationOnCheckInOutTimes",
    user
  );
  const startTime = isPayCalculationOnCheckInOutTimes
    ? get("result.startFinal", booking)
    : get("shift.startTime", booking);
  const endTime = isPayCalculationOnCheckInOutTimes
    ? get("result.endFinal", booking)
    : get("shift.endTime", booking);

  const bookingState = get("state", booking);

  const isPolicyApplied =
    get("result.isPolicyApplied", booking) &&
    [BOOKING_STATES.ACCEPTED, BOOKING_STATES.SHIFT_CANCELLED].includes(
      bookingState
    );

  const costFinal = Money({
    amount: get("result.costFinal", booking) || 0
  });

  const lengthFinal = get("result.lengthFinal", booking);

  const handleResendAcceptedEmail = async () => {
    try {
      setIsResendingEmail(true);
      await resendAcceptedEmail();
      setIsResent(true);
    } catch (e) {
      errorModal(e);
    }
    setIsResendingEmail(false);
  };

  const handleClose = () => {
    onClose();
    setIsResendingEmail(false);
    setIsResent(false);
  };

  if (!isOpen) return false;

  return (
    <Modal isLoading={isLoading} isOpen={isOpen} onClose={handleClose}>
      <ModalContent title="Booking Details">
        <LeftPane>
          <Title marginBottom>Summary</Title>
          <Text>{get("shift.venue.name", booking)}</Text>
          <Text>
            {get("shift.numberRequested", booking)}{" "}
            {`${get("shift.role.name", booking)}${
              booking?.shift?.role?.isDayRate ? "(Daily Rate)" : ""
            }`}
          </Text>
          <Text>
            <TimeDisplay
              displayDate={dateRangeToHuman(startTime, endTime, {
                includeYear: true
              })}
              startTime={startTime}
              endTime={endTime}
            />
          </Text>
          <Text>
            <Icon
              name={Icon.names.CLOCK}
              size={14}
              color={Icon.colors.COOL_GREY}
            />{" "}
            {lengthFinal && Number(lengthFinal).toFixed(1)}hrs
            {isPolicyApplied ? "*" : ""}
          </Text>
          <Text>
            Approval State:{" "}
            {APPROVAL_STATES_LABELS[get("approvalState", booking)]}
          </Text>

          {get("compatability", booking) && (
            <CostWrapper>
              <Text>
                Compatability: {get("compatability", booking)}
                <br />
                Factor Score: {get("factorScore", booking)}
              </Text>
            </CostWrapper>
          )}

          {bookingState === BOOKING_STATES.ACCEPTED ? (
            <div>
              <Text>
                {SurgeString({
                  startTime: get("shift.startTime", booking),
                  targetAccountId: get("shift.targetAccount.id", booking),
                  fullDescription: true
                })}
              </Text>

              <HasRole role={Role.SHIFTS_COST}>
                <CostWrapper>
                  <Text>Total</Text>
                  <Text>
                    {costFinal.toFormat()} + {taxRate}% VAT
                  </Text>
                </CostWrapper>
              </HasRole>

              {isPolicyApplied ? (
                <SmallText>
                  * The total chargable length has been increased in accordance
                  with our{" "}
                  <a
                    href={getPolicyQueryParams(booking.policy)}
                    target="_blank"
                  >
                    Minimum Shift Length Policy.
                  </a>
                </SmallText>
              ) : null}
              <ButtonWrapper>
                {isResent ? (
                  <ResentSuccess>Confirmation email resent</ResentSuccess>
                ) : (
                  <Button
                    isLoading={isResendingEmail}
                    isOutline
                    onClick={handleResendAcceptedEmail}
                  >
                    Resend Confirmation Email
                  </Button>
                )}
              </ButtonWrapper>
            </div>
          ) : null}
        </LeftPane>
        <RightPane>
          <Tabs tabs={tabs} defaultTab="Info" />
        </RightPane>
      </ModalContent>
    </Modal>
  );
};

export default flow(
  getProfile,
  resendAcceptedEmail,
  getBooking,
  withAuth
)(ViewEditBooking);

import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { useQuery } from "@apollo/client";

import { getCountryValidations } from "@teamrota/rota-common";
import { RotaCheckbox } from "@teamrota/rota-design";

import styles, {
  Field,
  Label,
  Input,
  Row,
  StyledErrorMessage,
  Select
} from "./styles";

import { GET_CURRENCY_REGION } from "./graphql/account-query";
import {
  validateAccountNumber,
  validateNiNumber,
  validateSortCode
} from "./validate";

const PaymentDetails = ({
  errors,
  register,
  initialValues,
  setValue,
  serviceAreas,
  setServiceAreas,
  inEditMode,
  control
}) => {
  const [checkedAreas, setCheckedAreas] = useState(
    initialValues?.serviceAreas || []
  );

  const { data } = useQuery(GET_CURRENCY_REGION, {
    fetchPolicy: "network-only"
  });

  const region = data?.account?.currencyRegion;
  const { accountNumber, sortCode, niNumber } = getCountryValidations(region);

  /**
   * Handle checkbox change and update checked service area array
   * @param {string} id service area id
   * @param {boolean} checked checkbox checked or not (add or remove id from array)
   */
  const handleCheckbox = (id, checked) => {
    let updatedCheckedAreas;

    if (checked) {
      updatedCheckedAreas = [...checkedAreas, id];
    } else {
      updatedCheckedAreas = checkedAreas.filter(item => item !== id);
    }
    setCheckedAreas(updatedCheckedAreas);
    setServiceAreas(updatedCheckedAreas);
  };

  return (
    <>
      <p>Payment Details</p>
      <Row>
        <Field>
          <Label>Bank name</Label>
          <Input
            {...register("bankName")}
            placeholder={initialValues?.bankName}
            disabled={!inEditMode}
          />
          <StyledErrorMessage>{errors.bankName?.message}</StyledErrorMessage>
        </Field>
        <Field>
          <Label>Account name</Label>
          <Input
            {...register("accountName")}
            placeholder={initialValues?.accountName}
            disabled={!inEditMode}
          />
          <StyledErrorMessage>{errors.accountName?.message}</StyledErrorMessage>
        </Field>
      </Row>
      <Row>
        <Field>
          <Label>{sortCode.name}</Label>
          <Controller
            control={control}
            defaultValue={initialValues.sortCode}
            name="memberType"
            render={() => {
              return (
                <Input
                  {...register("sortCode", {
                    setValueAs: value => sortCode.transform(value),
                    validate: value => {
                      setValue("sortCode", value);
                      return validateSortCode(region, value, false);
                    }
                  })}
                  placeholder={initialValues?.sortCode}
                  disabled={!inEditMode}
                />
              );
            }}
          />
          <StyledErrorMessage>{errors.sortCode?.message}</StyledErrorMessage>
        </Field>
        <Field>
          <Label>Roll number</Label>
          <Input
            {...register("rollNumber")}
            placeholder={initialValues?.rollNumber}
            disabled={!inEditMode}
          />
          <StyledErrorMessage>{errors.rollNumber?.message}</StyledErrorMessage>
        </Field>
      </Row>
      <Row>
        <Field>
          <Label>{accountNumber.name}</Label>
          <Input
            {...register("accountNumber", {
              setValueAs: value => accountNumber.transform(value),
              validate: value => {
                setValue("accountNumber", value);
                return validateAccountNumber(region, value, false);
              }
            })}
            placeholder={initialValues?.accountNumber}
            disabled={!inEditMode}
          />
          <StyledErrorMessage>
            {errors.accountNumber?.message}
          </StyledErrorMessage>
        </Field>
        <Field>
          <Label>{niNumber.name}</Label>
          <Input
            {...register("NINumber", {
              setValueAs: value => niNumber.transform(value),
              validate: value => {
                setValue("NINumber", value);
                return validateNiNumber(region, value, false);
              }
            })}
            id="NINumber"
            placeholder={initialValues?.NINumber}
            disabled={!inEditMode}
          />
          <StyledErrorMessage>{errors.NINumber?.message}</StyledErrorMessage>
        </Field>
      </Row>
      <Row>
        <Field style={{ width: "50%" }}>
          <Label>P46 Tax Code Response</Label>
          <Controller
            control={control}
            name="p46Question"
            render={({ field }) => {
              return (
                <Select
                  defaultValue={initialValues.p46Question}
                  placeholder="Select option"
                  disabled={!inEditMode}
                  onChange={e => {
                    field.onChange(e?.target?.value);
                  }}
                >
                  <option value="">Select option</option>
                  <option value="A">Option A</option>
                  <option value="B">Option B</option>
                  <option value="C">Option C</option>
                </Select>
              );
            }}
          />
        </Field>
        <Field style={{ width: "50%" }}>
          <Label>Requires travel</Label>
          <Controller
            control={control}
            name="requiresTravel"
            render={({ field }) => {
              return (
                <Select
                  defaultValue={initialValues.requiresTravel}
                  disabled={!inEditMode}
                  onChange={e => {
                    field.onChange(e?.target?.value);
                  }}
                >
                  <option value={false}>No</option>
                  <option value={true}>Yes</option>
                </Select>
              );
            }}
          />
        </Field>
      </Row>
      <Row>
        <Field>
          <Label>Service Area</Label>
          {serviceAreas?.map((serviceArea, i) => (
            <Label key={serviceArea.id}>
              <RotaCheckbox
                key={i}
                onClick={e =>
                  handleCheckbox(serviceArea.id, e.currentTarget.checked)
                }
                isDisabled={!inEditMode}
                isChecked={checkedAreas.includes(serviceArea.id)}
                style={styles.Checkbox}
                label={serviceArea.name}
              />
            </Label>
          ))}
          <StyledErrorMessage>{errors.serviceArea?.message}</StyledErrorMessage>
        </Field>
      </Row>
    </>
  );
};
export default PaymentDetails;

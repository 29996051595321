import styled from "styled-components";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";
import ExitButton from "~/src/components/button/exit-button";

export const OuterWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: ${STYLE_CONSTS.UNIT_HALF};
  background: ${COLORS.PALE_GREY_TRANSPARENT_30};
`;

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${STYLE_CONSTS.UNIT_2} ${STYLE_CONSTS.UNIT_4};
  border-bottom: ${({ theme }) => `solid 1px ${theme.neutral.light}`};
  padding-right: ${STYLE_CONSTS.UNIT_2};
`;

export const Title = styled.span`
  flex: 1;
  font-weight: regular;
  font-size: 16px;
  margin-left: ${STYLE_CONSTS.UNIT_1};
  color: ${({ isActive, theme }) =>
    isActive ? theme.primary.main : theme.neutral.main};
  :hover {
    color: ${({ theme }) => theme.primary.main};
  }
`;

export const ClearIconButton = styled(ExitButton).attrs({
  size: "8",
  isRed: true,
  isXSmall: true
})`
  margin-left: ${STYLE_CONSTS.UNIT_1};
  float: right;
  display: ${({ isVisible }) => (isVisible ? null : "none")};
`;

import React, { useState } from "react";
import moment from "moment-timezone";

import MemberPhoto from "~/src/components/member-photo";
import copyToClipboard from "copy-to-clipboard";
import Stars from "~/src/components/stars";
import MembersDetailModal from "~/src/containers/modals/view-edit-member";
import {
  MEMBER_STATES,
  APPROVAL_STATES,
  APPROVAL_STATES_LABELS
} from "~/src/consts";
import { formatFullName } from "~/src/utils/formatting";
import {
  Details,
  DetailRow,
  DetailSummaryRow,
  Id,
  CopySuccess,
  FlexContainer,
  HoverHighlight,
  MaybeError,
  Dot,
  Notes
} from "./index.style";

const MemberDetails = ({
  id,
  internalId,
  actionCallback,
  approvalState,
  firstName,
  lastName,
  email,
  phone,
  canRenderMemberModal,
  profile,
  state,
  shouldHighlightMemberPhoto,
  closestAcceptedShiftToNow,
  photo,
  isAsleep,
  poolType,
  rating,
  notes,
  showNotes
}) => {
  const [isEmailCopied, setIsEmailCopied] = useState(false);
  const [isPhoneCopied, setIsPhoneCopied] = useState(false);
  const [isMemberModalOpen, setIsMemberModalOpen] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState();

  const isModalAllowedToOpen = canRenderMemberModal || false;

  const copyEmail = () => {
    copyToClipboard(email);
    setIsEmailCopied(true);
    setTimeout(() => {
      setIsEmailCopied(false);
    }, 1000);
  };

  const copyPhone = () => {
    copyToClipboard(phone);
    setIsPhoneCopied(true);
    setTimeout(() => {
      setIsPhoneCopied(false);
    }, 1000);
  };

  const handleMemberModalOpen = id => {
    if (isModalAllowedToOpen) {
      setSelectedMemberId(id);
      setIsMemberModalOpen(true);
    }
  };

  const handleMemberModalClose = () => {
    setIsMemberModalOpen(false);
  };

  const idText = internalId
    ? `Internal ID ${internalId}`
    : id
    ? `ID ${id}`
    : "No ID";

  const isOnboardingLayout = state === MEMBER_STATES.PENDING;

  return (
    <div>
      {isMemberModalOpen && (
        <MembersDetailModal
          isOpen={isMemberModalOpen}
          memberId={selectedMemberId}
          onClose={handleMemberModalClose}
        />
      )}
      <Id>{idText}</Id>
      <FlexContainer>
        {shouldHighlightMemberPhoto ? (
          <HoverHighlight onClick={() => handleMemberModalOpen(id)}>
            <MemberPhoto
              src={photo}
              status={state === null ? MEMBER_STATES.PENDING : state}
              isInline
              isAsleep={isAsleep}
              actionCallback={actionCallback}
              poolType={poolType}
            />
          </HoverHighlight>
        ) : (
          <MemberPhoto
            src={photo}
            status={state === null ? MEMBER_STATES.PENDING : state}
            isInline
            isAsleep={isAsleep}
            actionCallback={actionCallback}
            poolType={poolType}
          />
        )}
        {isOnboardingLayout ? (
          <Details>
            <DetailRow>
              {firstName} {lastName}
            </DetailRow>
            <DetailRow>{email}</DetailRow>
            <DetailRow>{phone}</DetailRow>
            {profile && (
              <DetailRow>
                <a href={profile.linkedInProfile || ""} target="_blank">
                  {profile.linkedInProfile || ""}
                </a>
              </DetailRow>
            )}
            {profile && (
              <DetailSummaryRow hasDivider={!profile.linkedInProfile}>
                {profile.summary || ""}
              </DetailSummaryRow>
            )}
          </Details>
        ) : (
          <Details>
            <div>{formatFullName({ firstName, lastName }) || "No name"}</div>
            {rating && <Stars isSmall showRating value={rating || 4.5} />}
            {email && (
              <DetailRow onDoubleClick={copyEmail}>
                {email || "No email"}
              </DetailRow>
            )}
            {isEmailCopied && <CopySuccess>Copied!</CopySuccess>}
            {phone && (
              <DetailRow onDoubleClick={copyPhone}>
                {phone || "No phone number"}
              </DetailRow>
            )}
            {profile && (
              <DetailRow>
                <a href={profile.linkedInProfile || ""} target="_blank">
                  {profile.linkedInProfile || ""}
                </a>
              </DetailRow>
            )}
            {profile && (
              <DetailSummaryRow>{profile.summary || ""}</DetailSummaryRow>
            )}
            {closestAcceptedShiftToNow && !isOnboardingLayout ? (
              <DetailRow>
                {moment().isBetween(
                  closestAcceptedShiftToNow.startTime,
                  closestAcceptedShiftToNow.endTime
                ) ? (
                  <FlexContainer>
                    <Dot />
                    On Shift now
                  </FlexContainer>
                ) : (
                  <FlexContainer>
                    <Dot isDonut />
                    Shift&nbsp;
                    {moment(
                      moment().isAfter(closestAcceptedShiftToNow.startTime)
                        ? closestAcceptedShiftToNow.endTime
                        : closestAcceptedShiftToNow.startTime
                    ).fromNow()}
                  </FlexContainer>
                )}
              </DetailRow>
            ) : null}
            {isPhoneCopied && <CopySuccess>Copied!</CopySuccess>}
            {approvalState && (
              <MaybeError
                isError={approvalState === APPROVAL_STATES.UNDER_REVIEW}
              >
                {APPROVAL_STATES_LABELS[approvalState]}
              </MaybeError>
            )}
          </Details>
        )}
      </FlexContainer>
      {showNotes && notes && <Notes>{notes}</Notes>}
    </div>
  );
};

export default MemberDetails;

import React from "react";
import moment from "moment-timezone";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font
} from "@react-pdf/renderer";
import { Currency } from "@teamrota/rota-common/dist/currency-support";
import { getFormatterMoney } from "~/src/utils/formatting";

import { MemberBreakdown } from "./use-timesheet-breakdown";
import { TimesheetCostDetails } from "./use-timesheet-breakdown-cost-details";
import { convertMinutesToTimeString, convertHoursToTimeString } from "./utils";

import GothamBold from "../../../public/fonts/GothamBold.ttf";
import GothamMedium from "../../../public/fonts/GothamMedium.ttf";
import GothamBook from "../../../public/fonts/GothamBook.ttf";
import { TimesheetMemberWithStats } from "./use-timesheet-members-with-stats";

Font.register({
  family: "Gotham",
  fonts: [
    {
      src: GothamBold,
      fontWeight: 700
    },
    {
      src: GothamMedium,
      fontWeight: 500
    },
    {
      src: GothamBook,
      fontWeight: 400
    }
  ]
});

const rowStyle = {
  fontSize: 11,
  padding: 4
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    width: "100%",
    padding: 16,
    paddingBottom: 64,
    paddingTop: 32,
    fontFamily: "Gotham",
    fontSize: 12,
    fontWeight: 400
  },
  table: {
    width: "100%"
  },
  header: {
    borderBottom: "2px solid #A1A1A1",
    fontWeight: 500,
    textAlign: "center"
  },
  row: {
    fontSize: 16,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 8,
    paddingBottom: 8,
    height: 48
  },
  col1: {
    width: "21%",
    ...rowStyle
  },
  col2: {
    width: "14%",
    ...rowStyle
  },
  col3: {
    width: "12%",
    ...rowStyle
  },
  col4: {
    width: "12%",
    ...rowStyle
  },
  col5: {
    width: "13%",
    ...rowStyle
  },
  col6: {
    width: "8%",
    ...rowStyle
  },
  col7: {
    width: "19%",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    ...rowStyle
  },
  colJustified: {
    width: "20%",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    ...rowStyle
  },
  leftLeftAligned: {
    textAlign: "left"
  },
  approvedColumn: {
    marginBottom: 20
  },
  shiftName: {
    fontWeight: 500,
    fontSize: 16
  },
  detailsWrapper: {
    width: "100%"
  },
  details: {
    display: "flex",
    flexDirection: "row",
    marginTop: 64,
    marginBottom: 64
  },
  detailsProvider: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "center",
    flexGrow: 1,
    fontSize: 14,
    fontWeight: 500
  },
  detailsList: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column"
  },
  detailsItem: {
    textDecoration: "underline"
  },
  detailsListItem: {
    marginTop: 16,
    marginBottom: 16,
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline"
  },
  detailsListItemBold: {
    fontSize: 14,
    fontWeight: 500
  },
  detailsListItemNormal: {
    fontSize: 12,
    marginLeft: 16
  },
  costDetailsWrapper: {
    width: "100%"
  },
  costDetails: {
    marginTop: 24,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end"
  },
  costDetailsLine: {
    fontSize: 12,
    fontWeight: 500,
    marginTop: 16,
    marginBottom: 16
  },
  costDetailsLineBold: {
    fontSize: 12,
    fontWeight: "bold"
  },
  costDetailsLineSummary: {
    fontSize: 14,
    fontWeight: 700,
    width: "100%",
    borderTop: "2px solid #A1A1A1",
    textAlign: "right",
    marginTop: 0,
    paddingTop: 16
  },
  total: {
    marginRight: 100
  },
  footer: {
    position: "absolute",
    bottom: 16,
    width: "100%",
    padding: 16,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  pageNumber: {
    fontSize: 12,
    textAlign: "left"
  },
  date: {
    fontSize: 12,
    textAlign: "right"
  },
  spacer: {
    marginTop: 15,
    marginBottom: 15
  }
});

interface TimesheetPdfProps {
  timesheet: TimesheetMemberWithStats;
  timesheetMembers: MemberBreakdown[];
  timesheetCostDetails: TimesheetCostDetails;
  currencyCode: Currency;
}

// Create Document Component
export const TimesheetPdf = ({
  timesheet,
  timesheetMembers,
  timesheetCostDetails,
  currencyCode
}: TimesheetPdfProps) => {
  const formatterMoney = getFormatterMoney(currencyCode);
  const {
    totalRegularWorkedHours,
    chargeRate,
    totalCharge,
    totalBonusAmount,
    totalVat,
    totalWithVat,
    totalOverHours,
    totalOverHourAmount,
    overstayChargeRate,
    overtimeChargeRate
  } = timesheetCostDetails;

  const isSameDay = moment(timesheet.shiftStartTime).isSame(
    timesheet.shiftEndTime,
    "day"
  );

  const timesheetDate = isSameDay
    ? moment(timesheet.shiftStartTime).format("Do MMM YYYY")
    : `${moment(timesheet.shiftStartTime).format("Do MMM")} - ${moment(
        timesheet.shiftEndTime
      ).format("Do MMM YYYY")}`;

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.detailsWrapper}>
          <View>
            <Text style={styles.shiftName}>
              {timesheet.shiftName || `Shift at ${timesheet.venueName}`}{" "}
              {timesheet.subvenueName && `/ ${timesheet.subvenueName}`}
            </Text>
          </View>
          <View style={styles.details}>
            <View style={styles.detailsProvider}>
              <Text>{timesheet.providerName}</Text>
            </View>
            <View style={styles.detailsList}>
              <View style={styles.detailsListItem}>
                <Text style={[styles.detailsListItemBold, styles.detailsItem]}>
                  Details
                </Text>
              </View>
              <View style={styles.detailsListItem}>
                <Text style={styles.detailsListItemBold}>Date & Time:</Text>
                <Text style={styles.detailsListItemNormal}>
                  {timesheetDate}
                </Text>
              </View>
              <View style={styles.detailsListItem}>
                <Text style={styles.detailsListItemBold}>Location:</Text>
                <Text style={styles.detailsListItemNormal}>
                  {timesheet.venueAddress}{" "}
                  {timesheet.subvenueName && `, ${timesheet.subvenueName}`}
                </Text>
              </View>
              <View style={styles.detailsListItem}>
                <Text style={styles.detailsListItemBold}>People:</Text>
                <Text
                  style={styles.detailsListItemNormal}
                >{`${timesheetMembers.length}/${timesheet.numberRequested} ${timesheetMembers[0]?.roleName}`}</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.table}>
          <View style={[styles.row, styles.header]}>
            <Text style={styles.col1}>Name</Text>
            <Text style={styles.col2}>In / Out</Text>
            <Text style={styles.col3}>Break</Text>
            <Text style={styles.col4}>Time</Text>
            <Text style={styles.col5}>Sub-venue</Text>
            <Text style={styles.col6}>Price</Text>
            {/* <Text style={styles.col6}>Bonus</Text> */}
            <Text style={styles.col7}>Approved</Text>
          </View>
          {timesheetMembers.map(
            (
              {
                name,
                checkInTime,
                checkOutTime,
                totalMinutes,
                totalCharge,
                break: breakTime,
                approvedAt,
                approvedBy,
                subvenueName
              },
              i
            ) => {
              const checkIn = checkInTime
                ? moment(checkInTime).format("HH:mm")
                : "--:--";
              const checkOut = checkOutTime
                ? moment(checkOutTime).format("HH:mm")
                : "--:--";
              return (
                <View key={i} style={styles.row} wrap={false}>
                  <Text style={styles.col1}>{name}</Text>
                  <Text style={styles.col2}>{`${checkIn} - ${checkOut}`}</Text>
                  <Text style={styles.col3}>{breakTime}</Text>
                  <Text style={styles.col4}>
                    {convertMinutesToTimeString(totalMinutes)}
                  </Text>
                  <Text style={styles.col5}>{subvenueName || "-"}</Text>
                  <Text style={styles.col6}>
                    {timesheet.isUncalculatedRole
                      ? "N/A"
                      : formatterMoney.format(totalCharge / 100)}
                  </Text>
                  {/* <Text style={styles.col6}>bonus</Text> */}
                  <View style={styles.col7}>
                    <Text style={styles.approvedColumn}>
                      {approvedBy || "-"}
                    </Text>
                    {approvedAt && (
                      <Text>
                        {moment(approvedAt).format("DD/MM/YYYY HH:mm")}
                      </Text>
                    )}
                  </View>
                </View>
              );
            }
          )}
        </View>
        <View style={styles.costDetailsWrapper} wrap={false}>
          <View style={styles.costDetails}>
            {timesheet.isUncalculatedRole ? (
              <>
                <Text style={styles.costDetailsLine}>
                  {`Total Working Hours = `}
                  <Text style={styles.costDetailsLineBold}>
                    {convertHoursToTimeString(totalRegularWorkedHours)}
                  </Text>
                </Text>
                <Text style={styles.costDetailsLineBold}>
                  Pay for this shift will be calculated in the Payroll download
                </Text>
              </>
            ) : (
              <>
                <Text style={styles.costDetailsLine}>
                  {`${convertHoursToTimeString(
                    totalRegularWorkedHours
                  )} x ${formatterMoney.format(chargeRate / 100)} = `}
                  <Text style={styles.costDetailsLineBold}>
                    {formatterMoney.format(totalCharge / 100)}
                  </Text>
                </Text>
                <Text style={styles.costDetailsLine}>
                  {`${convertHoursToTimeString(
                    totalOverHours
                  )} x ${formatterMoney.format(
                    (overstayChargeRate || overtimeChargeRate) / 100
                  )} = `}
                  <Text style={styles.costDetailsLineBold}>
                    {formatterMoney.format(totalOverHourAmount / 100)}
                  </Text>
                </Text>
                <Text style={styles.costDetailsLine}>
                  {`Total Bonus = `}
                  <Text style={styles.costDetailsLineBold}>
                    {formatterMoney.format(totalBonusAmount / 100)}
                  </Text>
                </Text>
                <Text style={styles.costDetailsLine}>
                  {`VAT (20%) = `}
                  <Text style={styles.costDetailsLineBold}>
                    {formatterMoney.format(totalVat / 100)}
                  </Text>
                </Text>
                <Text
                  style={[
                    styles.costDetailsLine,
                    styles.costDetailsLineSummary
                  ]}
                >
                  <Text style={styles.total}>{`Total:                `}</Text>
                  <Text>{formatterMoney.format(totalWithVat / 100)}</Text>
                </Text>
              </>
            )}
          </View>
        </View>
        <View fixed style={styles.footer}>
          <View>
            <Text style={styles.date} fixed>{`Printed on ${moment().format(
              "MMMM Do YYYY, h:mm a"
            )}`}</Text>
          </View>
          <View>
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `${pageNumber}/${totalPages} Pages`
              }
              fixed
            />
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.detailsWrapper}>
          <View>
            <Text style={styles.shiftName}>Sleep Time Proposed</Text>
          </View>
        </View>
        <View style={styles.spacer} />
        <View style={styles.table}>
          <View style={[styles.row, styles.header]}>
            <Text style={styles.colJustified}>Name</Text>
            <Text style={styles.colJustified}>Start</Text>
            <Text style={styles.colJustified}>End</Text>
            <Text style={styles.colJustified}>Time</Text>
            <Text style={styles.colJustified}>Approved</Text>
          </View>
          {timesheetMembers.map(
            (
              {
                name,
                plannedSleepStartTime,
                plannedSleepEndTime,
                plannedSleepTimeMinutes,
                approvedAt,
                approvedBy
              },
              i
            ) => {
              const plannedSleepStart = plannedSleepStartTime
                ? moment(plannedSleepStartTime).format("HH:mm")
                : "--:--";
              const plannedSleepEnd = plannedSleepEndTime
                ? moment(plannedSleepEndTime).format("HH:mm")
                : "--:--";
              return (
                <View key={i} style={styles.row} wrap={false}>
                  <Text style={[styles.colJustified, styles.leftLeftAligned]}>
                    {name}
                  </Text>
                  <Text style={styles.colJustified}>{plannedSleepStart}</Text>
                  <Text style={styles.colJustified}>{plannedSleepEnd}</Text>
                  <Text style={styles.colJustified}>
                    {convertMinutesToTimeString(plannedSleepTimeMinutes)}
                  </Text>
                  <View style={styles.colJustified}>
                    <Text style={styles.approvedColumn}>
                      {approvedBy || "-"}
                    </Text>
                    {approvedAt && (
                      <Text>
                        {moment(approvedAt).format("DD/MM/YYYY HH:mm")}
                      </Text>
                    )}
                  </View>
                </View>
              );
            }
          )}
        </View>
        <View style={styles.spacer} />
        <View style={styles.detailsWrapper}>
          <View>
            <Text style={styles.shiftName}>Sleep Time Actual</Text>
          </View>
        </View>
        <View style={styles.spacer} />
        <View style={styles.table}>
          <View style={[styles.row, styles.header]}>
            <Text style={styles.colJustified}>Name</Text>
            <Text style={styles.colJustified}>Start</Text>
            <Text style={styles.colJustified}>End</Text>
            <Text style={styles.colJustified}>Time</Text>
            <Text style={styles.colJustified}>Approved</Text>
          </View>
          {timesheetMembers.map(
            (
              {
                name,
                sleepStartTime,
                sleepEndTime,
                sleepTimeMinutes,
                approvedAt,
                approvedBy
              },
              i
            ) => {
              const sleepStart = sleepStartTime
                ? moment(sleepStartTime).format("HH:mm")
                : "--:--";
              const sleepEnd = sleepEndTime
                ? moment(sleepEndTime).format("HH:mm")
                : "--:--";
              return (
                <View key={i} style={styles.row} wrap={false}>
                  <Text style={[styles.colJustified, styles.leftLeftAligned]}>
                    {name}
                  </Text>
                  <Text style={styles.colJustified}>{sleepStart}</Text>
                  <Text style={styles.colJustified}>{sleepEnd}</Text>
                  <Text style={styles.colJustified}>
                    {convertMinutesToTimeString(sleepTimeMinutes)}
                  </Text>
                  <View style={styles.colJustified}>
                    <Text style={styles.approvedColumn}>
                      {approvedBy || "-"}
                    </Text>
                    {approvedAt && (
                      <Text>
                        {moment(approvedAt).format("DD/MM/YYYY HH:mm")}
                      </Text>
                    )}
                  </View>
                </View>
              );
            }
          )}
        </View>
        <View style={styles.costDetailsWrapper} wrap={false}>
          <View style={styles.costDetails}>
            {timesheet.isUncalculatedRole ? (
              <>
                <Text style={styles.costDetailsLine}>
                  {`Total Working Hours = `}
                  <Text style={styles.costDetailsLineBold}>
                    {convertHoursToTimeString(totalRegularWorkedHours)}
                  </Text>
                </Text>
                <Text style={styles.costDetailsLineBold}>
                  Pay for this shift will be calculated in the Payroll download
                </Text>
              </>
            ) : (
              <>
                <Text style={styles.costDetailsLine}>
                  {`${convertHoursToTimeString(
                    totalRegularWorkedHours
                  )} x ${formatterMoney.format(chargeRate / 100)} = `}
                  <Text style={styles.costDetailsLineBold}>
                    {formatterMoney.format(totalCharge / 100)}
                  </Text>
                </Text>
                <Text style={styles.costDetailsLine}>
                  {`${convertHoursToTimeString(
                    totalOverHours
                  )} x ${formatterMoney.format(
                    (overstayChargeRate || overtimeChargeRate) / 100
                  )} = `}
                  <Text style={styles.costDetailsLineBold}>
                    {formatterMoney.format(totalOverHourAmount / 100)}
                  </Text>
                </Text>
                <Text style={styles.costDetailsLine}>
                  {`Total Bonus = `}
                  <Text style={styles.costDetailsLineBold}>
                    {formatterMoney.format(totalBonusAmount / 100)}
                  </Text>
                </Text>
                <Text style={styles.costDetailsLine}>
                  {`VAT (20%) = `}
                  <Text style={styles.costDetailsLineBold}>
                    {formatterMoney.format(totalVat / 100)}
                  </Text>
                </Text>
                <Text
                  style={[
                    styles.costDetailsLine,
                    styles.costDetailsLineSummary
                  ]}
                >
                  <Text style={styles.total}>{`Total:                `}</Text>
                  <Text>{formatterMoney.format(totalWithVat / 100)}</Text>
                </Text>
              </>
            )}
          </View>
        </View>
        <View fixed style={styles.footer}>
          <View>
            <Text style={styles.date} fixed>{`Printed on ${moment().format(
              "MMMM Do YYYY, h:mm a"
            )}`}</Text>
          </View>
          <View>
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `${pageNumber}/${totalPages} Pages`
              }
              fixed
            />
          </View>
        </View>
      </Page>
    </Document>
  );
};

import moment from "moment-timezone";
import { get, getOr } from "lodash/fp";
import Money from "~/src/utils/money";
import MoneyTransaction from "~/src/utils/money/money-transaction";

export function getIsSelfProvider(shift) {
  return shift.targetAccount.id === shift.sourceAccount.id;
}

/**
 *
 * @function: Multiply final cost for booking with policy hours applied
 *
 */
// export const sumTotal = (shift: ShiftItemType) => {
//   const bookings = shift.bookings.data;
//   const manager = BookingManager(
//     `containers timesheets [${bookings.map(({ id }) => id)}]`,
//     bookings
//   );

//   return manager.totalCost; // Money instance
// };

/**
 *
 * @function : Attempt to get manager name from the booking state
 * @returns {String} Either manager name or default string
 *
 */
export const formatManagerName = userObject => {
  const firstName = get("firstName", userObject.endOnShiftUser);
  const lastName = get("lastName", userObject.endOnShiftUser);
  if (!firstName && !lastName) {
    return "No manager name provided";
  }
  return `${firstName} ${lastName}`;
};

/**
 *
 * @function : Get the method of checkout
 * @returns {String} Either method of checkout or default string
 *
 */
export const formatCheckoutMethod = userObject =>
  getOr("Unknown method of checkout ", "methodOfCheckOut", userObject);

/**
 *
 * @function: Calculate the booking cost in pounds from a penny input
 * @returns {Object} containing
 *
 */
export const calculateBookingCosts = bookingTotal => {
  const bookingMoney = Money({ amount: bookingTotal || 0 });
  const bookingWithVAT = new MoneyTransaction("calculateBookingCosts");
  bookingWithVAT.add(bookingMoney).applyVAT();

  return {
    costInPounds: bookingMoney, // Money instance
    vatInCost: bookingWithVAT.current.subtract(bookingMoney) // Money instance
  };
};

/**
 *
 * @function : Convert input string into a valid Date object
 * @returns {Date} valid date object
 *
 */
export const convertDateFromString = dateString => {
  const convertedDate = moment(dateString, "DD-MM-YYYY");
  if (convertedDate.isValid()) {
    return convertedDate.toISOString();
  }
  return moment()
    .subtract(1, "months")
    .toISOString();
};

import styled from "styled-components";
import Button from "~/src/components/button";
import SearchInput from "~/src/components/form-components/search-input";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";

export const Container = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  flex-direction: ${({ flexDirection }) => flexDirection || "row"};
  flex: ${({ flex }) => flex};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  margin: ${({ margin }) => margin};
  height: ${({ height }) => height};
  width: ${({ width }) => width};
`;

export const StyledButton = styled(Button)`
  margin-right: ${STYLE_CONSTS.UNIT_1};
`;

export const Divider = styled.div`
  border-bottom: ${STYLE_CONSTS.SMALL_DIVIDER_BORDER};
  margin: ${STYLE_CONSTS.UNIT_3} 0;
  width: ${({ width }) => (width ? `${width}px` : "100%")};
`;

export const MemberName = styled.div`
  color: ${COLORS.COOL_GREY};
  font-size: ${STYLE_CONSTS.FONT_XSMALL};
  white-space: nowrap;
  overflow: hidden;
  margin-top: ${STYLE_CONSTS.UNIT_1};
  text-align: center;
`;

export const MemberWrapper = styled.div`
  margin: ${STYLE_CONSTS.UNIT_1} 0;
  width: 16.6666666%;
`;

export const Scroll = styled.div`
  max-height: 250px;
  overflow: auto;
`;

export const FullHeightScroll = styled.div`
  height: 100%;
  overflow: auto;
`;

export const MembersContainer = styled.div`
  max-height: 250px;
  overflow: visible;
`;

export const MemberContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const StyledSearchInput = styled(SearchInput)`
  width: 100%;
`;

export const ModalContent = styled.div`
  padding: ${STYLE_CONSTS.UNIT_3};

  &:not(:last-child) {
    border-bottom: ${STYLE_CONSTS.MED_DIVIDER_BORDER_MED_LIGHT};
  }
`;

export const Panel = styled.div`
  border-radius: ${STYLE_CONSTS.BORDER_RADIUS};
  background-color: ${({ theme }) => theme.white};
  box-shadow: ${STYLE_CONSTS.FLOATING_LARGE};
  padding: ${STYLE_CONSTS.UNIT_3};
  ${props => (props.minHeight ? `min-height: ${props.minHeight}px` : "")};
  flex: ${({ flex }) => flex};
  height: ${({ height }) => height};
  margin: ${({ margin }) => margin};
`;

export const Title = styled.div`
  margin: ${STYLE_CONSTS.UNIT_4} 0 ${STYLE_CONSTS.UNIT_2};
  font-size: ${STYLE_CONSTS.FONT_LARGE};
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_MEDIUM};
  font-size: ${STYLE_CONSTS.FONT_LARGE};
  color: ${({ color }) => (COLORS[color] ? COLORS[color] : COLORS.COOL_GREY)};
`;

export const ButtonContainer = styled.div`
  align-self: flex-end;
  margin-top: ${STYLE_CONSTS.UNIT_3};
`;

export const Tab = styled.div`
  color: ${({ isDisabled }) => isDisabled && COLORS.CLOUDY_BLUE};
  cursor: pointer;
`;

export const ErrorLabel = styled.div`
  margin-top: ${STYLE_CONSTS.UNIT_1};
  color: ${COLORS.RED};
`;

export const FilterOptions = styled.div`
  margin-bottom: ${STYLE_CONSTS.UNIT_1};
`;

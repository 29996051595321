import React, { useState, useEffect } from "react";
import styles from "./styles";
import { RotaCheckbox } from "@teamrota/rota-design";

const UsersHomeVenueSelection = ({
  allVenues,
  usersSelectedVenuesIds,
  readOnly,
  meta,
  input,
  ...props
}) => {
  const [venueIdsChecked, setVenueIdsChecked] = useState([]);

  useEffect(() => {
    usersSelectedVenuesIds?.length === 0
      ? setVenueIdsChecked(allVenues.map(({ id }) => parseInt(id)))
      : setVenueIdsChecked(usersSelectedVenuesIds);
  }, [readOnly]);

  const handleVenuesChecked = id => {
    if (venueIdsChecked.includes(id)) {
      const newVenuesChecked = venueIdsChecked.filter(
        venueId => venueId !== id
      );
      setVenueIdsChecked(newVenuesChecked);
    } else {
      const newVenuesChecked = [...venueIdsChecked, id];
      setVenueIdsChecked(newVenuesChecked);
    }
  };

  useEffect(() => {
    input?.onChange(venueIdsChecked);
  }, [venueIdsChecked]);

  return (
    <div style={styles.radioButtonContainer}>
      {meta?.error && !readOnly && <p style={styles.error}>{meta?.error}</p>}
      {allVenues?.map(venue => (
        <label key={venue?.name} style={styles.label}>
          <RotaCheckbox
            label={venue?.name}
            isDisabled={readOnly}
            onClick={() => handleVenuesChecked(parseInt(venue.id))}
            isChecked={venueIdsChecked.includes(parseInt(venue?.id))}
          />
        </label>
      ))}
    </div>
  );
};

export default UsersHomeVenueSelection;

import React, { useState } from "react";

import { Role } from "@teamrota/authlib";
import useAuth from "~/src/auth/hooks/use-auth";
import { RotaButton, RotaCheckbox } from "@teamrota/rota-design";

import set from "lodash/fp/set";
import remove from "lodash/fp/remove";
import Button from "~/src/components/button";
import { Column, Row } from "~/src/components/grid";
import LabelValue from "~/src/components/label-value";
import AlertDialog from "~/src/containers/templates/components/dialog";
import MapSearchable from "~/src/components/map-searchable";
import Modal from "~/src/components/modal";
import PageHeader from "~/src/components/page-header";
import CheckInSetupModalContent from "~/src/containers/templates/containers/venue-templates/check-in/check-in-setup-modal-content";
import deleteItem from "~/src/graphql/mutations/delete-item/delete-item.decorator";
import getProfile from "~/src/graphql/queries/get-profile/get-profile-query.decorator";
import createOrUpdateVenue from "./graphql/create-update-venue-templates";
import { STYLE_CONSTS } from "~/src/styles/config.style";
import { stallPromise } from "~/src/utils";
import asyncConfirm from "~/src/utils/async-confirm";
import { errorModal } from "~/src/utils/errors";
import TemplateItem from "../../components/template-item";
import TemplatePanel from "../../components/template-panel";
import {
  ContentPadding,
  Divider,
  SetUpRowDiv,
  SetUpWrapperDiv,
  StyledGrid,
  StyledRow,
  SubTitle,
  Title,
  TitleContainer,
  StyledTextInput,
  VenueButtonsContainer,
  FieldWrapper,
  MultiFieldContainer
} from "../../templates.styles";
import SubvenuesSection from "./subvenues/SubvenuesSection";
import { useTheme } from "styled-components";
import { TTM_PROVIDER_ACCOUNTS } from "@teamrota/rota-common";

const DEFAULT_TEMPLATE = {
  name: "",
  lat: 51.505,
  lng: -0.09,
  address: "",
  meetingPoint: "",
  directions: "",
  sageRef: ""
};

const VenueTemplates = props => {
  const auth = useAuth();
  const theme = useTheme();

  const { deleteItem, createOrUpdateVenue, isLoading, user } = props;
  const [activeTemplate, setActiveTemplate] = useState(DEFAULT_TEMPLATE);
  const [shouldShowSavedState, setShouldShowSavedState] = useState(false);
  const [isStateLoading, setIsStateLoading] = useState(false);
  const [nameIsInvalid, setNameIsInvalid] = useState(false);
  const [addressIsInvalid, setAddressIsInvalid] = useState(false);
  const [directionsIsInvalid, setDirectionsIsInvalid] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isShowVenueForm, setIsShowVenueForm] = useState(true);

  const handleSetupModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handlePostSaveIndicator = async () => {
    setShouldShowSavedState(true);
    await Button.successDelay();
    setShouldShowSavedState(false);
  };

  const handleDelete = async venueTemplateId => {
    try {
      if (
        await asyncConfirm("Are you sure you want to delete this template?", {
          confirmButtonText: "Delete"
        })
      ) {
        await deleteItem({
          updateQueries: {
            getProfile: prevData => {
              const prevVenues = prevData?.user?.account?.venues;
              return set(
                "user.account.venues",
                remove({ id: venueTemplateId }, prevVenues)
              )(prevData);
            }
          },
          variables: {
            type: "venues",
            id: venueTemplateId
          }
        });
      }
    } catch (e) {
      errorModal(e);
    }
  };

  const saveVenue = async event => {
    event.preventDefault();

    const {
      name,
      address,
      id,
      meetingPoint,
      directions,
      lat,
      lng,
      sageRef,
      integraId,
      isLive
    } = activeTemplate;

    if (!name || !address || !directions) {
      setNameIsInvalid(!name);
      setAddressIsInvalid(!address);
      setDirectionsIsInvalid(!directions);

      return;
    }

    setIsStateLoading(true);

    const variables = {
      id,
      // Make use of GraphQLNonNull by coercing to undefined if string is falsy
      name: name || undefined,
      address: address || undefined,
      meetingPoint,
      directions,
      lat,
      lng,
      sageRef: sageRef || undefined,
      integraId: integraId || undefined,
      isLive: isLive !== undefined ? isLive : undefined
    };

    try {
      await stallPromise(createOrUpdateVenue(variables));

      setIsStateLoading(false);
      handlePostSaveIndicator();
    } catch (e) {
      setIsStateLoading(false);
      errorModal(e);
    }
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleDeleteClicked = template => {
    handleDelete(template.id);
  };

  const handleEditClicked = template => {
    handleEditMode(template);
  };

  const handleEditMode = venueTemplate => {
    const { name, address } = venueTemplate;
    setActiveTemplate(venueTemplate);
    setNameIsInvalid(!name);
    setAddressIsInvalid(!address);
  };

  const handleChange = key => value => {
    const valueIsObject = typeof value === "object";
    setActiveTemplate({
      ...activeTemplate,
      ...(valueIsObject ? value : { [key]: value })
    });
  };

  const myVenues = user?.account?.venues || [];

  const getVenueSubvenues = venueId =>
    myVenues.find(v => v.id === venueId)?.subvenues || [];

  const openCreateVenueModal = () => {
    setIsShowVenueForm(true);
    setActiveTemplate(DEFAULT_TEMPLATE);
  };

  const nameErrorLabel = nameIsInvalid && "Please name this venue";
  const addressErrorLabel = addressIsInvalid && "Please add an address";
  const directionErrorLabel =
    directionsIsInvalid &&
    "Please add directions so that staff can find you easily";

  const hasVenuesCreate = auth.hasRole(Role.VENUES_CREATE);
  const hasVenuesEdit = auth.hasRole(Role.VENUES_EDIT);

  const isTTMAccount = TTM_PROVIDER_ACCOUNTS.includes(user?.account?.id);

  const sourceAccount =
    user?.account?.requesterConnections?.data?.[0]?.sourceAccount?.id;
  const isTTMPartner = TTM_PROVIDER_ACCOUNTS.includes(sourceAccount);

  const isTTM = isTTMAccount || isTTMPartner;

  return (
    <div>
      <PageHeader title="Venue Setup" subtext="Create and manage venues" />
      <StyledRow>
        <Column medium={hasVenuesCreate ? 1 / 2 : 1}>
          <TitleContainer>
            <Title>My Venues</Title>
          </TitleContainer>
          <Row>
            <SetUpWrapperDiv>
              <SubTitle
                fontSize={STYLE_CONSTS.FONT}
                color={theme.text.primary}
                fontWeight={STYLE_CONSTS.FONT_WEIGHT_MEDIUM}
              >
                Check in/out & Timesheets:
              </SubTitle>
              <SetUpRowDiv>
                <SubTitle color="#00000" fontSize={STYLE_CONSTS.FONT}>
                  Select and check input method:
                </SubTitle>
                {hasVenuesEdit && (
                  <VenueButtonsContainer>
                    <span>
                      <RotaButton onClick={openCreateVenueModal}>
                        Create New
                      </RotaButton>
                    </span>
                    <span>
                      <RotaButton onClick={e => handleSetupModal(e)}>
                        Setup
                      </RotaButton>
                    </span>
                  </VenueButtonsContainer>
                )}
              </SetUpRowDiv>
            </SetUpWrapperDiv>
          </Row>
          <Modal padding="0" isOpen={isModalOpen} isLightOverlay hasBtn>
            <CheckInSetupModalContent
              handleSetupModal={e => handleSetupModal(e)}
              setIsModalOpen={setIsModalOpen}
              isModalOpen={isModalOpen}
            />
          </Modal>
          <Row>
            {!isLoading &&
              myVenues?.map((template, index) => (
                <StyledGrid
                  key={index}
                  gutter="small"
                  xlarge={hasVenuesCreate ? 1 / 2 : 1 / 4}
                >
                  <TemplateItem
                    item={template}
                    title={template?.name}
                    createdAt={template?.createdAt}
                    handleEdit={() => handleEditClicked(template)}
                    handleDelete={() => handleDeleteClicked(template)}
                    canEdit={hasVenuesEdit}
                  >
                    <p>{template?.address}</p>
                  </TemplateItem>
                </StyledGrid>
              ))}
          </Row>
        </Column>
        {isShowVenueForm && hasVenuesCreate && (
          <Column medium={1 / 2}>
            <TemplatePanel
              title="Create new Venue"
              onReset={() => setActiveTemplate(DEFAULT_TEMPLATE)}
              isEditMode={Boolean(activeTemplate?.id)}
            >
              <ContentPadding>
                <StyledTextInput
                  isDisabled={false}
                  errorLabel={nameErrorLabel}
                  isRequired
                  label="Template name"
                  placeholder={"Write here"}
                  isBorderless
                  value={activeTemplate?.name}
                  onChangeValue={handleChange("name")}
                />
                <Divider isMarginless />
              </ContentPadding>
              <ContentPadding>
                <MapSearchable
                  value={{ ...activeTemplate }}
                  onChange={handleChange("address")}
                  errorLabel={addressErrorLabel}
                />
                <ContentPadding isVerticalPaddingOnly>
                  <ContentPadding isVerticalPaddingOnly>
                    <LabelValue labelWidth={95} label="Directions">
                      <StyledTextInput
                        isDisabled={false}
                        errorLabel={directionErrorLabel}
                        placeholder={"Write here"}
                        value={activeTemplate?.directions}
                        onChangeValue={handleChange("directions")}
                      />
                    </LabelValue>
                  </ContentPadding>
                  <ContentPadding isVerticalPaddingOnly>
                    <LabelValue
                      labelWidth={95}
                      label="Instructions upon arrival"
                    >
                      <StyledTextInput
                        placeholder="Write here"
                        value={activeTemplate?.meetingPoint}
                        onChangeValue={handleChange("meetingPoint")}
                      />
                    </LabelValue>
                  </ContentPadding>

                  <MultiFieldContainer>
                    <FieldWrapper>
                      <ContentPadding isVerticalPaddingOnly>
                        <LabelValue labelWidth={95} label="Sage Ref">
                          <StyledTextInput
                            placeholder="Write here"
                            value={activeTemplate?.sageRef || ""}
                            onChangeValue={handleChange("sageRef")}
                          />
                        </LabelValue>
                      </ContentPadding>
                    </FieldWrapper>
                    <FieldWrapper>
                      <ContentPadding isVerticalPaddingOnly>
                        <LabelValue labelWidth={70} label="Integra ID">
                          <StyledTextInput
                            placeholder="Write here"
                            value={activeTemplate?.integraId || ""}
                            onChangeValue={handleChange("integraId")}
                          />
                        </LabelValue>
                      </ContentPadding>
                    </FieldWrapper>
                    <FieldWrapper>
                      <ContentPadding isVerticalPaddingOnly>
                        <LabelValue labelWidth={50} label="Is Live?">
                          <RotaCheckbox
                            isChecked={activeTemplate?.isLive}
                            padding={10}
                            onClick={e =>
                              handleChange("isLive")(e.target.checked)
                            }
                          />
                        </LabelValue>
                      </ContentPadding>
                    </FieldWrapper>
                  </MultiFieldContainer>
                </ContentPadding>
              </ContentPadding>
              <Divider />
              <ContentPadding style={{ textAlign: "right" }}>
                <RotaButton
                  onClick={saveVenue}
                  isSuccess={shouldShowSavedState}
                  isLoading={isStateLoading}
                >
                  {shouldShowSavedState ? "Saved" : "Save"}
                </RotaButton>
              </ContentPadding>

              {activeTemplate?.subvenues && (
                <SubvenuesSection
                  venueId={activeTemplate.id}
                  subvenues={getVenueSubvenues(activeTemplate.id)}
                  isTTM={isTTM}
                />
              )}
            </TemplatePanel>
          </Column>
        )}
      </StyledRow>
      <AlertDialog
        onHide={handleDialogClose}
        openDialog={isDialogOpen}
        handleClose={handleDialogClose}
        dialogTitle="You do not have the permission to edit/delete this template"
        declineBtnText="OK"
      />
    </div>
  );
};

export default deleteItem(getProfile(createOrUpdateVenue(VenueTemplates)));

import { gql, useQuery } from "@apollo/client";
import adminClient from "~/src/containers/admin-dashboard/graphql/admin-client";

const GET_REBOOK_ROLES = gql`
  query getRebookRolesOnAccount($accountId: ID!) {
    rebookRoles(accountId: $accountId) {
      roleId
      roleName
      roleIdMappedToAlternateRoleId
      mappedToName
    }
  }
`;

export const useRebookRoles = ({ accountId }) => {
  const { data, loading, refetch } = useQuery(GET_REBOOK_ROLES, {
    variables: {
      accountId
    },
    client: adminClient,
    skip: !accountId
  });

  const rebookRoles = data?.rebookRoles || [];

  return {
    rebookRoles,
    loading,
    refetch
  };
};

import styled from "styled-components";

export const StyledUploadedFileTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: center;

  thead {
    background-color: #f4f4f4;
    border-radius: 10px;
    font-weight: 400;
    height: 42px;
    color: ${({ theme }) => theme.text.secondary};
    tr {
      height: 32px;
      th:first-child {
        border-radius: 10px 0 0 10px;
      }

      th:last-child {
        border-radius: 0 10px 10px 0;
      }
    }
  }

  tbody {
    tr {
      height: 42px;
    }
  }
`;

import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";
import { clearFix } from "~/src/styles/style-helpers";

export const HOUR_COL_WIDTH = 70;
export const DRAG_END_WIDTH = 40;
export const DAY_ROW_HEIGHT = 40;

export const Wrapper = styled.div`
  position: relative;
`;

export const GridWrapper = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
`;

export const Grid = styled.div`
  ${clearFix()};
  margin-left: ${HOUR_COL_WIDTH}px;
  margin-right: ${DRAG_END_WIDTH}px;
  border-left: ${STYLE_CONSTS.SMALL_DIVIDER_BORDER_LIGHT};
  position: relative;
  user-select: none;
`;

export const GridEnd = styled.div`
  width: ${DRAG_END_WIDTH}px;
  height: ${props => props.pixelsPerMinute * 60 * 24}px;
  position: absolute;
  right: 0;
  top: ${DAY_ROW_HEIGHT}px;
`;

import React, { memo, useState } from "react";

import Icon from "~/src/components/icon";
import { Surround } from "./styles";
import { COLORS } from "~/src/styles/config.style";

interface IconLinkProps {
  name: string;
  color: string;
  hoverColor?: string;
  size: number;
  isButton?: boolean;
  isDisabled?: boolean;
  onClick: () => void;
}

const IconLink: React.FC<IconLinkProps> = ({
  name,
  color,
  size,
  isButton = false,
  isDisabled = false,
  onClick
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const currentColor =
    isButton && isHovering
      ? Icon.colors.WHITE
      : isDisabled
      ? Icon.colors.WARM_GREY
      : color;

  const backgroundColorCode = COLORS[color as keyof typeof COLORS];

  return (
    <Surround
      size={size + (isButton ? 12 : 0)}
      isDisabled={isDisabled}
      isButton
      isHovering={isButton && isHovering}
      backgroundColor={backgroundColorCode}
      onPointerOver={() => !isHovering && setIsHovering(!isDisabled && true)}
      onPointerLeave={() => isHovering && setIsHovering(false)}
    >
      <Icon
        name={Icon.names[name.toUpperCase()]}
        size={size}
        color={currentColor}
        onClick={() => !isDisabled && onClick()}
      />
    </Surround>
  );
};

export default memo(IconLink);

import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const Heading = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: ${({ alignItems }) => alignItems || "flex-start"};
  justify-content: space-between;
  background-color: ${({ theme }) => theme.white};
  padding: ${props =>
    props.overridePadding
      ? props.overridePadding
      : `${STYLE_CONSTS.UNIT_2} ${STYLE_CONSTS.UNIT_4}`};
`;

export const Title = styled.h2`
  margin: 0;
  color: ${({ theme }) => theme.text.primary};
  font-size: ${STYLE_CONSTS.FONT_LARGE};
  font-weight: 400;
`;

export const Subtext = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.text.secondary};
`;

export const TextContainer = styled.div`
  flex-basis: ${({ isAddMinWidth }) => (isAddMinWidth ? "20%" : "auto")};
`;

export const StyledRedBox = styled.div`
  width: 5px;
  height: 5px;
`;

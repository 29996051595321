import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";

import { addAuthVars } from "@teamrota/authlib";

const GET_LAST_USED_UNIFORM_AND_BRIEFING = addAuthVars(gql`
  query getLastUsedUniformAndBriefing(
    $venueId: ID
    $roleRateId: ID
    $partnerAccountId: ID
  ) {
    user {
      id
      lastUsedBriefingTemplate(
        venueId: $venueId
        roleRateId: $roleRateId
        partnerAccountId: $partnerAccountId
      ) {
        id
        identifier
        content
      }
      lastUsedUniformTemplate(
        venueId: $venueId
        roleRateId: $roleRateId
        partnerAccountId: $partnerAccountId
      ) {
        id
        identifier
      }
    }
  }
`);

const useGetLastUsedUniformAndBriefing = ({
  roleRateId,
  venueId,
  partnerAccountId
}) => {
  const { data, loading, error, refetch } = useQuery(
    GET_LAST_USED_UNIFORM_AND_BRIEFING,
    {
      variables: {
        roleRateId: roleRateId,
        venueId: venueId,
        partnerAccountId: partnerAccountId,
        connectionLimit: 0,
        connectionOffset: 0
      },
      skip: !roleRateId || !venueId,
      fetchPolicy: "network-only"
    }
  );

  return {
    lastUsedUniform: data?.user?.lastUsedUniformTemplate?.[0] || {},
    lastUsedBriefing: data?.user?.lastUsedBriefingTemplate?.[0] || {},
    isLoadingLastUsedUniformAndBriefing: loading,
    errorLoadingLastUsedUniformAndBriefing: error,
    refetchLastUsedUniformAndBriefing: refetch
  };
};

export default useGetLastUsedUniformAndBriefing;

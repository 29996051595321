import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Styled_DropZoneFileUpload } from "./styles";
import uploadCloudIcon from "~/src/images/Upload_Cloud_Icon.png";
import { RotaButton } from "@teamrota/rota-design";

const DropZoneFileUpload = ({ setIsSizeOrFileTypeError, setFile }) => {
  const isFileTypeValid = fileType => {
    switch (fileType) {
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return true;
      default:
        return false;
    }
  };

  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");

      reader.onload = e => {
        setIsSizeOrFileTypeError(false);
        setFile(file);

        // takes the file signature to determine it's real type
        const arr = new Uint8Array(e.target.result).subarray(0, 6);
        let signature = "";
        for (let i = 0; i < arr.length; i++) {
          signature += arr[i].toString(16);
        }

        // 504b34140 is an XLSX file signature and common for different versions of XLSX
        if (!isFileTypeValid(file.type) || signature !== "504b34140") {
          setIsSizeOrFileTypeError(true);
          return;
        }
        // 25MB
        if (file.size > 25000000) {
          setIsSizeOrFileTypeError(true);
        }
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true
  });

  return (
    <Styled_DropZoneFileUpload
      {...getRootProps({ isDragActive, refKey: "innerRef" })}
    >
      <input {...getInputProps()} accept=".xlsx" />
      <div className="dropzone_content">
        <img src={uploadCloudIcon} className="img" />
        <div className="description">Drop your file here</div>
        <div className="sub_description">or</div>
        <RotaButton className="browse_btn" onClick={open}>
          Browse
        </RotaButton>
      </div>
    </Styled_DropZoneFileUpload>
  );
};

export default DropZoneFileUpload;

import React from "react";
import AccountLogo from "~/src/components/account-logo";
import { dateRangeToHuman, getHoursWorked } from "~/src/utils/formatting";
import TimeDisplay from "~/src/components/shift-time-display";
import Icon from "~/src/components/icon";
import { SHIFT_TYPE } from "~/src/consts";
import { COLORS } from "~/src/styles/config.style";
import {
  SmallText,
  XSmallText,
  GreyText,
  MemberDetailWrapper,
  ShiftDetails,
  StyledIcon,
  ApplicantRow,
  FulfilmentText,
  TextHighlight
} from "./shift-column.styles";
import { RotaButtonIcon, RotaCheckbox, iconNew } from "@teamrota/rota-design";

const { Plus } = iconNew;

const ShiftColumn = ({
  shift,
  onActivateLinkingMode,
  onPartnerClick,
  onOverbookShift,
  onSetUpFeaturedShift,
  isFeatured,
  onSetAutoAccept,
  isAutoAcceptEnabled,
  shouldHideAutoAccept,
  shouldHideApplicants
}) => {
  const numberRequestedWithOverbook = Math.ceil(
    shift.numberRequested +
      shift.numberRequested * ((shift.overbookPercentage || 0) / 100)
  );

  const overbookedPrecentToInt = () =>
    shift.numberRequested * ((shift.overbookPercentage || 0) / 100);

  const extraBooked = shift.overbookPercentage
    ? ` + ${Math.floor(overbookedPrecentToInt(shift.overbookPercentage))}`
    : ``;

  const totalHours = getHoursWorked(shift.startTime, shift.endTime);

  const role = `${shift.numberRequested} x ${lowerCaseOnboarding(
    shift?.roleRate?.roleName
  )}`;

  function lowerCaseOnboarding(text) {
    if (text === "ONBOARDING") {
      return "Onboarding";
    }
    return text;
  }

  const fulfilment = `${shift?.bookingCounts?.accepted} / ${shift?.numberRequested} ${extraBooked}`;
  const applied = `${shift?.bookingCounts?.applied}`;
  const visibleTo = `${shift?.bookingCounts?.visibleTo}`;
  const visibleToPercent =
    Math.round((visibleTo / shift?.bookingCounts?.excludedTotal) * 100) || 0;

  const sourceAccountName = shift?.sourceAccount?.accountName;

  const { startTime, endTime } = shift;
  return (
    <div>
      <XSmallText>
        <GreyText>ID: {shift.id}</GreyText>
        {shift.identifier && (
          <GreyText leftMargin>Internal ID: {shift.identifier}</GreyText>
        )}
      </XSmallText>
      <MemberDetailWrapper>
        <AccountLogo
          isLinked={shift.totalInGroup > 1}
          onClickLink={onActivateLinkingMode || (() => {})}
          color={shift?.shiftGroupColor}
          src={shift?.sourceAccount?.logo}
          onClick={() => {
            if (onPartnerClick) onPartnerClick();
          }}
        />
        <ShiftDetails>
          <div>
            <XSmallText>
              <TimeDisplay
                displayDate={dateRangeToHuman(startTime, endTime, {
                  includeDay: true
                })}
                startTime={startTime}
                endTime={endTime}
              />
            </XSmallText>
            <XSmallText>
              <StyledIcon
                size={Icon.sizes.XSMALL}
                color={Icon.colors.BLACK}
                name={Icon.names.CLOCK}
              />
              {totalHours}h
            </XSmallText>
          </div>
          <div>
            <SmallText>
              {sourceAccountName === SHIFT_TYPE.ONBOARDING
                ? shift?.name
                : sourceAccountName}
            </SmallText>
          </div>
          <div>
            <XSmallText>
              <GreyText>
                {role}, {shift?.venue?.name}
              </GreyText>
              <GreyText>
                {shift?.isShiftRebook && ", (Rebooked shift)"}
                {shift?.isRestricted &&
                  !shift.isShiftRebook &&
                  ", (restricted)"}
              </GreyText>
            </XSmallText>
          </div>
          {!shouldHideApplicants && (
            <ApplicantRow>
              <RotaButtonIcon onClick={onOverbookShift}>
                <Plus />
              </RotaButtonIcon>
              <XSmallText>
                <FulfilmentText
                  isOrange={
                    numberRequestedWithOverbook > shift?.bookingCounts?.accepted
                  }
                >
                  Fulfilment: {fulfilment}
                </FulfilmentText>
                <GreyText>
                  Applicants: {applied}
                  {"  "}; Visible to: {visibleTo} ; {`(${visibleToPercent}%)`}
                  {"  "} Seen count: {shift.seenCount}
                </GreyText>
              </XSmallText>
            </ApplicantRow>
          )}

          <div>
            {!shouldHideAutoAccept && (
              <GreyText>
                <RotaCheckbox
                  label="Auto accept"
                  isChecked={isAutoAcceptEnabled}
                  onClick={onSetAutoAccept}
                />
                &nbsp;&nbsp;{" "}
                <RotaCheckbox
                  label="Featured shift"
                  isChecked={isFeatured}
                  onClick={onSetUpFeaturedShift}
                />
                &nbsp;&nbsp;
                {shift.cancelledAt && (
                  <TextHighlight textColor={COLORS.RED}>
                    Cancelled
                  </TextHighlight>
                )}
                &nbsp;&nbsp;{shift?.editedBySourceAt && "Edited"}
              </GreyText>
            )}
          </div>
        </ShiftDetails>
      </MemberDetailWrapper>
    </div>
  );
};

export default ShiftColumn;

import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const LeftPane = styled.div`
  vertical-align: top;
  display: inline-block;
  width: 320px;
  height: 100%;
  overflow: auto;
  padding: ${STYLE_CONSTS.UNIT_1};
`;

export const RightPane = styled.div`
  display: inline-block;
  width: calc(100% - 320px);
  height: 100%;
  min-height: 73vh;
  border-left: 1px solid ${({ theme }) => theme.neutral.main};
  padding: 12px 0 0 48px;
`;

import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const StyledInputWrapper = styled.div`
  display: inline-block;
  width: 60%;
  .fancyInput {
    color: ${({ theme }) => theme.primary.main};
    font-size: ${STYLE_CONSTS.FONT_MEDIUM};
    border: none;
    background-color: ${({ theme }) => theme.white};
    &:focus {
      outline: none;
    }
    &:disabled {
      color: ${({ theme }) => theme.text.primary};
    }
    &::-webkit-input-placeholder {
      color: ${({ theme }) => theme.neutral.main};
      background-color: ${({ theme }) => theme.white};
    }
  }
`;

import { graphql } from "@apollo/client/react/hoc";
import { gql } from "@apollo/client";

import { REPORT_REQUEST_TYPES } from "~/src/consts";
import { handleErrors } from "~/src/utils/errors";

const GET_REPORTS = gql`
  query getReports {
    account {
      id
      reports(requestType: ${REPORT_REQUEST_TYPES.PAYROLL}) {
        id
        status
        url
        createdAt
        rangeStart
        rangeEnd
      }
    }
  }
`;

export default graphql(GET_REPORTS, {
  props: handleErrors(({ data: { loading, account, refetch } }) => ({
    isLoading: loading,
    reports: account?.reports ?? [],
    refetchReports: refetch
  }))
});

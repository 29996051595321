import React from "react";

import { Wrapper, Label, Value } from "./label-value.styles";

export default function LabelValue(props) {
  return (
    <Wrapper className={props.className}>
      <Label
        spacingBottom={props.spacingBottom}
        spacingTop={props.spacingTop}
        isTopAlignedLabel={props.isTopAlignedLabel}
        minWidth={props.labelWidth}
        labelWidth={props.labelWidth}
      >
        {props.label}
      </Label>
      <Value isNoGutter={props.isNoGutter} spacingBottom={props.spacingBottom}>
        {props.children}
      </Value>
    </Wrapper>
  );
}

import React from "react";
import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import { ZonedDate, ethnicities, nationalities } from "@teamrota/rota-common";

import { GENDER_OPTIONS } from "~/src/consts";
import { isFormattedDateValid } from "~/src/utils/time-utils";

import { Field, Label, Input, Select, Row, StyledErrorMessage } from "./styles";

const PersonalDetails = ({
  errors,
  setError,
  register,
  control,
  initialValues,
  inEditMode,
  isAllowedToViewMemberDetails
}) => {
  return (
    <>
      <p>Personal Details</p>
      {isAllowedToViewMemberDetails && (
        <Row>
          <Field>
            <Label>Title</Label>
            <Input
              {...register("title")}
              placeholder={initialValues?.title}
              disabled={!inEditMode}
            />
            <StyledErrorMessage>{errors.title?.message}</StyledErrorMessage>
          </Field>
          <Field>
            <Label>Nickname</Label>
            <Input
              {...register("nickname")}
              placeholder={initialValues?.nickname}
              disabled={!inEditMode}
            />
            <StyledErrorMessage>{errors.Nickname?.message}</StyledErrorMessage>
          </Field>
        </Row>
      )}

      <Row>
        <Field>
          <Label>First name</Label>
          <Input
            {...register("firstName")}
            placeholder={initialValues?.firstName}
            disabled={!inEditMode}
          />
          <StyledErrorMessage>{errors.firstName?.message}</StyledErrorMessage>
        </Field>
        <Field>
          <Label>Last name</Label>
          <Input
            {...register("lastName")}
            placeholder={initialValues?.lastName}
            disabled={!inEditMode}
          />
          <StyledErrorMessage>{errors.lastName?.message}</StyledErrorMessage>
        </Field>
      </Row>
      {isAllowedToViewMemberDetails && (
        <>
          <Row>
            <Field>
              <Label>Middle name</Label>
              <Input
                {...register("middleName")}
                placeholder={initialValues?.middleName}
                disabled={!inEditMode}
              />
              <StyledErrorMessage>
                {errors.middleName?.message}
              </StyledErrorMessage>
            </Field>
            <Field>
              <Label>Date of birth</Label>
              <Controller
                control={control}
                name="birthDate"
                render={({ field }) => (
                  <DatePicker
                    placeholderText={initialValues?.birthDate || "Select date"}
                    disabled={!inEditMode}
                    selected={field.value}
                    onChange={date =>
                      field.onChange(ZonedDate.fromSelectedDate(date))
                    }
                    onChangeRaw={e => {
                      const date = e.target.value;

                      if (date && !isFormattedDateValid(date)) {
                        setError("birthDate", {
                          type: "custom",
                          message: "Invalid date format."
                        });
                      }
                    }}
                    open={false}
                    dateFormat="dd/MM/yyyy"
                    strictParsing
                    customInput={<Input />}
                  />
                )}
              />
              <StyledErrorMessage>
                {errors.birthDate?.message}
              </StyledErrorMessage>
            </Field>
          </Row>
          <Row>
            <Field>
              <Label>Gender</Label>
              <Select disabled={!inEditMode} {...register("gender")}>
                {GENDER_OPTIONS.map((label, i) => (
                  <option style={{ color: "black" }} key={i} value={label}>
                    {label}
                  </option>
                ))}
              </Select>
              <StyledErrorMessage>{errors.gender?.message}</StyledErrorMessage>
            </Field>
            <Field>
              <Label>Nationality</Label>
              <Select {...register("nationality")} disabled={!inEditMode}>
                {nationalities.map((label, i) => (
                  <option key={i} value={label}>
                    {label}
                  </option>
                ))}
              </Select>
              <StyledErrorMessage>
                {errors.nationality?.message}
              </StyledErrorMessage>
            </Field>
          </Row>

          <Row>
            <Field>
              <Label>Ethnicity</Label>
              <Select {...register("ethnicity")} disabled={!inEditMode}>
                {ethnicities.map((label, i) => (
                  <option key={i} value={label}>
                    {label}
                  </option>
                ))}
              </Select>
              <StyledErrorMessage>
                {errors.ethnicity?.message}
              </StyledErrorMessage>
            </Field>
            <Field>
              <Label>Known medical issues</Label>
              <Input
                {...register("disability")}
                placeholder={initialValues?.disability}
                disabled={!inEditMode}
              />
              <StyledErrorMessage>
                {errors.disability?.message}
              </StyledErrorMessage>
            </Field>
          </Row>
        </>
      )}
    </>
  );
};
export default PersonalDetails;

import React from "react";
import { Field, FieldArray } from "redux-form";
import TagsContainer from "~/src/containers/tags";
import { Wrapper, Label } from "./index.styles";
import MemberStateInput from "./inputs/member-state-input";
import TextInput from "./inputs/text-input";
import SortCodeInput from "./inputs/sort-code-input";
import DateInput from "./inputs/date-input";
import NumberInput from "./inputs/number-input";
import MultilineTextInput from "./inputs/multiline-text-input";
import Dropdown from "./inputs/dropdown";
import DatePicker from "./inputs/date-picker";
import DateRangePicker from "./inputs/date-range-picker";
import CurrencyInput from "./inputs/currency-input";
import PhoneNumberInput from "./inputs/phone-number-input";
import CheckboxInput from "./inputs/checkbox-input";
import StarsInput from "./inputs/stars-input";
import PinInput from "./inputs/pin-input";
import SwitchInput from "./inputs/switch-input";
import getAccountId from "./get-account";
import HomeVenueSelectionButtons from "~/src/containers/modals/create-edit-user/users-home-venue-selection";

export const INPUTS = {
  "member-state-input": MemberStateInput,
  "text-input": TextInput,
  "sort-code-input": SortCodeInput,
  "date-input": DateInput,
  "number-input": NumberInput,
  "multiline-text-input": MultilineTextInput,
  dropdown: Dropdown,
  "date-picker": DatePicker,
  "date-range-picker": DateRangePicker,
  "currency-input": CurrencyInput,
  "phone-number-input": PhoneNumberInput,
  "checkbox-input": CheckboxInput,
  "stars-input": StarsInput,
  "pin-input": PinInput,
  "home-venues-selection": HomeVenueSelectionButtons,
  "switch-input": SwitchInput
};

export const ARRAY_INPUTS = {
  tags: TagsContainer
};

const FormField = props => {
  const {
    isSingleLine,
    name,
    label,
    component,
    type,
    readOnly,
    placeholder,
    isFieldArray,
    id
  } = props;
  const accountId = id || null;

  const showDefaultLabel = !["checkbox-input", "switch-input"].includes(
    component
  );

  return (
    <Wrapper isSingleLine={isSingleLine}>
      {showDefaultLabel && (
        <Label htmlFor={name} isSingleLine={isSingleLine}>
          {label}
        </Label>
      )}
      {isFieldArray ? (
        <FieldArray
          {...props}
          accountId={accountId}
          component={ARRAY_INPUTS[component]}
        />
      ) : (
        <Field
          {...props}
          props={{
            accountId
          }}
          component={INPUTS[component] || TextInput}
          type={type || "text"}
          placeholder={readOnly ? "" : placeholder}
        />
      )}
    </Wrapper>
  );
};

export default getAccountId(FormField);

import { graphql } from "@apollo/client/react/hoc";
import moment from "moment-timezone";

import { uniqBy } from "lodash";
import { getOr, update, get, flow } from "lodash/fp";

import { withAuth } from "~/src/auth/hoc";
import { isValidDate } from "~/src/utils/validators";
import { handleErrors } from "~/src/utils/errors";

import SHIFTS_QUERY from "~/src/graphql/queries/get-shifts/get-shifts.query";

export const LIMIT = 10;

const getIfDateValid = dateStr =>
  dateStr && isValidDate(dateStr)
    ? moment(dateStr, "DD/MM/YYYY").toString()
    : null;

const getSortBy = sortBy => {
  const sortByValue = get("value", sortBy);
  if (sortByValue === "postedAt") {
    return {
      orderByPropAsc: undefined,
      orderByPropDesc: sortByValue
    };
  }
  if (sortByValue) {
    return {
      orderByPropDesc: undefined,
      orderByPropAsc: sortByValue
    };
  }
  return {};
};

export default flow(
  graphql(SHIFTS_QUERY, {
    props: handleErrors(
      ({ data: { loading, account, fetchMore, refetch } }) => ({
        isLoading: loading,
        shifts: account?.shifts?.data ?? [],
        totalResults: account?.shifts?.totalResults ?? 0,
        roleOptions: account?.shifts?.roleOptions ?? [],
        refetch: async () => {
          const shiftsLength = account?.shifts?.data?.length;
          await refetch({
            limit: shiftsLength > LIMIT ? shiftsLength : LIMIT
          });
        },
        fetchMore: () =>
          fetchMore({
            notifyOnNetworkStatusChange: true,
            fetchPolicy: "network-only",
            variables: {
              offset: account?.shifts?.data?.length ?? 0
            },
            updateQuery: (previousResult, { fetchMoreResult }) =>
              update(
                "account.shifts.data",
                current => {
                  const fetched = get("account.shifts.data", fetchMoreResult);
                  const result = current ? [...current, ...fetched] : fetched;
                  return uniqBy(result, "id");
                },
                previousResult
              )
          })
      })
    ),
    options: ({ auth, ...props }) => {
      const getFilterProp = path => {
        const filters = get(path, props.filters);
        return (filters && filters.length > 0) || undefined;
      };

      return {
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        variables: auth.addVals(SHIFTS_QUERY, {
          accountType: "provider",
          offset: 0,
          limit: LIMIT,
          withBookings: false, // Don't want bookings
          bookingsLimit: 0,
          bookingsOffset: 0,
          searchText: props.searchText,
          startTimeRangeStart: getIfDateValid(props.filters.startTime),
          startTimeRangeEnd: getIfDateValid(props.filters.endTime),
          accountId: get("filters.account.value", props),
          serviceAreaIds:
            getFilterProp("serviceAreaIds") &&
            getOr([], "serviceAreaIds", props.filters).map(({ id }) => id),
          hasBookingWithStateIn:
            getFilterProp("bookingStateIn") &&
            getOr([], "filters.bookingStateIn", props).map(({ id }) => id),
          roleIds:
            getFilterProp("roleIds") &&
            getOr([], "filters.roleIds", props).map(({ id }) => id),
          tagIds:
            getFilterProp("tags") &&
            getOr([], "filters.tags", props).map(({ id }) => id),
          shiftGroupId: get("filters.shiftGroupId", props),
          isFeatured: props.filters.isFeatured,
          isFinalised: props.filters.isFinalised,
          isPartnerManaged: props.filters.isPartnerManaged,
          isUnderReview: props.filters.isUnderReview,
          isCancelled: props.filters.isCancelled,
          isShiftRebook: props.filters.isShiftRebook,
          isRestricted: props.filters.isRestricted,
          isRegistration: props.filters.isRegistration,
          shiftType: props.filters.shiftType,
          hasCancelledBookings: props.filters.hasCancelledBookings,
          hasAppliedBookings: props.filters.hasAppliedBookings,
          ...getSortBy(props.sortBy)
        })
      };
    }
  }),
  withAuth
);

/**
 * @description generates an array of object usable in a select form having value and label
 * @param {Object[]} briefingTemplates Array of briefing template object
 * @returns {Object[]} custom array of objects
 */
export function createBriefingOptions(briefingTemplates) {
  return briefingTemplates.map(briefingTemplate => ({
    value: briefingTemplate.id,
    label: briefingTemplate.identifier,
    content: briefingTemplate.content
  }));
}

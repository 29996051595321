import styled from "styled-components";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";
import { HOUR_COL_WIDTH } from "../styles";

export const GridHourCol = styled.div`
  width: ${HOUR_COL_WIDTH}px;
  height: 100%;
  position: absolute;
  margin-top: -1px;
  color: ${COLORS.COOL_GREY};
`;

export const GridHourColItem = styled.div`
  text-align: right;
  border-top: ${STYLE_CONSTS.SMALL_DIVIDER_BORDER_LIGHT};
`;

export const GridHourColItemInner = styled.div`
  transform: translateY(${parseInt(STYLE_CONSTS.FONT, 10) / -1.5}px);
  margin-right: ${STYLE_CONSTS.UNIT_1};
  background-color: ${({ theme }) => theme.white};
  width: ${HOUR_COL_WIDTH -
    parseInt(STYLE_CONSTS.UNIT_1, 10) -
    parseInt(STYLE_CONSTS.UNIT_HALF, 10)}px;
  padding-right: ${STYLE_CONSTS.UNIT_HALF};
`;

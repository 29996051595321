import React from "react";
import moment from "moment-timezone";
import ToolTip from "react-tooltip";
import { TIME_FORMAT } from "~/src/utils/formatting";
import { TimeDisplay } from "./check-in-out.styles";
import { colors, icon } from "@teamrota/rota-design";
import TimesheetToolTip from "~/src/containers/timesheets/tooltip";

const formatTime = time => (time ? moment(time).format(TIME_FORMAT) : "00:00");

const CheckInOut = ({ booking, bookingState }) => {
  const { LocationIcon } = icon;
  const startTime = bookingState?.startOnShiftAt;
  const startLate = moment(booking?.startFinal).isBefore(
    bookingState?.startOnShiftAt,
    "minute"
  );
  const endTime = bookingState?.endOnShiftAt;
  const endEarly = moment(bookingState?.endOnShiftAt).isBefore(
    booking?.endFinal,
    "minute"
  );
  const proximity = booking?.isWithinProximityAtCheckIn;

  const iconStyle = type => {
    if (proximity === null || proximity === undefined) {
      if (type === "color") return colors.rotaOrange;
      if (type === "tip") return `Check-In in Proximity: Not Sure`;
    }
    if (proximity === true) {
      if (type === "color") return colors.green;
      if (type === "tip") return "Check-In in Proximity: Yes";
    }
    if (proximity === false) {
      if (type === "color") return colors.red;
      if (type === "tip") return "Check-In in Proximity: No";
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ margin: "1px 0 0 5px" }}>
        <p data-tip="check-in-tooltip" data-for="check-in-tooltip">
          <LocationIcon color={iconStyle("color")} />
        </p>
        <ToolTip
          data-place="bottom"
          multiline
          id="check-in-tooltip"
          effect="solid"
        >
          {iconStyle("tip")}
        </ToolTip>
      </div>

      <TimeDisplay style={{ margin: "0 5px" }} error={startLate}>
        {formatTime(startTime)}
      </TimeDisplay>
      {"-"}
      <TimeDisplay style={{ marginLeft: "5px" }} error={endEarly}>
        {formatTime(endTime)}
      </TimeDisplay>
      <TimesheetToolTip
        text={booking?.checkInMethod}
        style={{ margin: "1px 0 0 5px" }}
        id="check-in-method"
      />
    </div>
  );
};

export default CheckInOut;

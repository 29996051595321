import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

import { Role } from "@teamrota/authlib";

import {
  RotaButtonNew,
  RotaSearchInput,
  RotaButtonIcon,
  iconNew
} from "@teamrota/rota-design";

import useAuth from "~/src/auth/hooks/use-auth";
import HasRole from "~/src/containers/has-role";

import {
  Wrapper,
  HeaderWrapper,
  Box,
  Heading,
  Title,
  Actions,
  Subheader,
  SubheaderWrapper
} from "~/src/components/payroll/style";

import JsonEditModal from "../payroll/components/JsonEditModal";

import Table from "./Table";

import { useCreatePayrollBreak } from "./graphql/use-create-payroll-break";
import { useUpdatePayrollBreak } from "./graphql/use-update-payroll-break";
import { usePayrollBreaks } from "./graphql/use-payroll-breaks";

const { Filter, ChevronLeft } = iconNew;

const ExampleJsonInput = {
  name: "Name",

  config: [
    {
      name: "Weekday",
      type: "break",
      conditions: {},
      event: {
        type: "break",
        params: {
          set: 30
        }
      }
    },
    {
      name: "Evening",
      type: "break",
      conditions: {
        "shift.shiftStart.time >= ": "20:00",
        "shift.shiftStart.time <  ": "00:00"
      },
      event: {
        type: "break",
        params: {
          set: 45
        }
      }
    },
    {
      name: "Night",
      type: "break",
      conditions: {
        "shift.shiftStart.time >= ": "00:00",
        "shift.shiftStart.time <  ": "08:00"
      },
      event: {
        type: "break",
        params: {
          set: 45
        }
      }
    },
    {
      name: "Weekend",
      type: "break",
      conditions: {
        "shift.shiftStart.dayOfWeek in": ["SAT", "SUN"]
      },
      event: {
        type: "break",
        params: {
          add: 10
        }
      }
    }
  ]
};

const Breaks = () => {
  const history = useHistory();
  const params = useParams() as any;
  const auth = useAuth();

  const canEdit = auth.hasRole(Role.PAYROLL_EDIT);

  const { createPayrollBreak } = useCreatePayrollBreak();
  const { updatePayrollBreak } = useUpdatePayrollBreak();

  const { payrollBreaks } = usePayrollBreaks("");

  const [isShowCreateModal, setIsShowCreateModal] = useState(false);
  const [selectedOpenId, setSelectedOpenId] = useState<string>("");

  useEffect(() => {
    if (params.id !== undefined) {
      if (payrollBreaks.find((p: any) => p.id === params.id)) {
        setSelectedOpenId(params.id);
      }
    }
  }, [params, payrollBreaks]);

  const handleOpenBreak = (id: string) => setSelectedOpenId(id);

  const handleClose = () => {
    setSelectedOpenId("");
    history.push("/payroll/breaks");
  };

  return (
    <Wrapper>
      <HeaderWrapper>
        <RotaSearchInput onChange={() => {}} />
        <HasRole role={Role.PAYROLL_EDIT}>
          <RotaButtonNew onClick={() => setIsShowCreateModal(true)}>
            Create
          </RotaButtonNew>
        </HasRole>
      </HeaderWrapper>
      <SubheaderWrapper>
        <RotaButtonIcon onClick={() => history.push("/payroll")}>
          <ChevronLeft />
        </RotaButtonIcon>
        <Subheader>Breaks</Subheader>
      </SubheaderWrapper>
      <Box>
        <Heading>
          <Title>{`All ${payrollBreaks?.length} records`}</Title>
          <Actions>
            <RotaButtonIcon onClick={() => {}}>
              <Filter />
            </RotaButtonIcon>
          </Actions>
        </Heading>

        <Table
          payrollBreaks={payrollBreaks}
          handleOpenBreak={handleOpenBreak}
          canEdit={canEdit}
        />
      </Box>

      {isShowCreateModal && (
        <JsonEditModal
          canEdit={canEdit}
          onSave={async ({ name, config }) => {
            await createPayrollBreak({ name, config });
            setIsShowCreateModal(false);
          }}
          onClose={() => setIsShowCreateModal(false)}
          value={ExampleJsonInput}
          title="Create Break Rules"
        />
      )}

      {selectedOpenId && (
        <JsonEditModal
          canEdit={canEdit}
          onSave={async ({ name, config }) => {
            await updatePayrollBreak({ id: selectedOpenId, name, config });
            handleClose();
          }}
          onClose={handleClose}
          value={payrollBreaks.find(
            ({ id }: { id: string }) => id === selectedOpenId
          )}
          title="Edit Break Rules"
        />
      )}
    </Wrapper>
  );
};

export default Breaks;

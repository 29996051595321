import styled from "styled-components";

export const MainContainer = styled.div`
  padding: 10px 15px;
`;

export const TagsContainer = styled.div`
  display: flex;
  margin: 5px 0 15px;
`;

export const Label = styled.h4`
  font-size: 16px;
  font-weight: 500;
  margin: 10px 20px 10px 0;
  flex: 1;
  max-width: 130px;
`;

export const ChipsContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: start;
`;

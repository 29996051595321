import React from "react";
import moment from "moment-timezone";
import get from "lodash/fp/get";
import { dateRangeToHuman } from "~/src/utils/formatting";
import TimeDisplay from "~/src/components/shift-time-display";
import AccountLogo from "~/src/components/account-logo";
import Icon from "~/src/components/icon";
import {
  InfoWrapper,
  StyledIcon,
  Wrapper,
  XSmallText,
  GreyText
} from "./shift-info.styles";

export default function ShiftInfo(props) {
  const totalHours = moment(props.shift.endTime).diff(
    props.shift.startTime,
    "hours"
  );
  const role = `${props.shift.numberRequested} x ${props.shift.roleRate.roleName}`;
  const { startTime, endTime } = props.shift;
  return (
    <Wrapper>
      <AccountLogo
        src={props.shift.sourceAccount.logo}
        isLinked={props.shift.totalInGroup > 1}
        color={props.shift.shiftGroupColor}
      />
      <InfoWrapper>
        <div>
          <XSmallText>
            <TimeDisplay
              displayDate={dateRangeToHuman(startTime, endTime, {
                includeYear: true
              })}
              startTime={startTime}
              endTime={endTime}
            />
          </XSmallText>
          <XSmallText>
            <StyledIcon
              size={Icon.sizes.XSMALL}
              color={Icon.colors.BLACK}
              name={Icon.names.CLOCK}
            />
            {totalHours}h
          </XSmallText>
        </div>
        <div>{props.shift.name || `Shift at ${props?.shift?.venue?.name}`}</div>
        <div>
          <XSmallText>
            <GreyText>
              {role}, {get("shift.venue.name", props)}
            </GreyText>
          </XSmallText>
        </div>
      </InfoWrapper>
    </Wrapper>
  );
}

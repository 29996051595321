import styled from "styled-components";

import { typography } from "@teamrota/rota-design";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";

const { StyledSubtitle } = typography;

export const StyledWrapSearchRow = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  padding-right: 10px;
`;

export const StyledWrapSection = styled.div`
  border: 1px solid transparent;
  padding: 0 18px 20px 18px;
`;

export const StyledWrapText = styled.span`
  color: ${({ theme }) => theme.neutral.main};
  font-size: 12px;
  font-weight: 500;
`;

export const StyledRedBox = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

export const StyledWrapFilters = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 0;
`;

export const StyledWrapAssignRequested = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 0px 12px 0;
`;

export const StyledWrapAssigned = styled.div`
  border: 1px solid ${({ theme }) => theme.neutral.lighter};
  background-color: ${({ theme }) => theme.neutral.lighter};
  border-radius: 13px;
  padding: 20px ${STYLE_CONSTS.UNIT_3};
`;

export const StyledWrapElement = styled.div`
  display: flex;
`;

export const StyledWarning = styled.span`
  color: ${({ theme }) => theme.error.main};
  font-size: 12px;
`;

export const StyledZoneTitle = styled(StyledSubtitle)`
  line-height: 15px;
`;

export default () => ({
  formControl: {
    width: "100%",
    height: "36px",
    margin: "0px",
    "& .MuiFormControl-marginDense": {
      margin: "0px"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${COLORS.MAIN_ORANGE}`
    },
    "& .MuiOutlinedInput-input": {
      height: "12px",
      padding: "12px",
      fontSize: STYLE_CONSTS.FONT,
      fontStyle: "normal",
      color: COLORS.BLACK,
      backgroundColor: "transparent"
    },
    "& .MuiButton-root": {
      border: "1px solid rgba(0, 0, 0, 0.23)",
      "&:hover": {
        borderColor: COLORS.MAIN_ORANGE,
        backgroundColor: "transparent"
      }
    },
    "& .MuiButton-text": {
      padding: "5px 8px"
    },
    "& .MuiButton-label": {
      color: COLORS.BLACK,
      fontSize: STYLE_CONSTS.FONT,
      fontStyle: "normal",
      textTransform: "none"
    },
    "& .MuiInputBase-root": {
      borderRadius: "10px",
      height: "36px",
      "&:hover fieldset": {
        borderColor: COLORS.MAIN_ORANGE
      }
    }
  },
  selectGroup: {
    width: "160px",
    height: "36px",
    borderBotton: "none",
    boxSizing: "border-box",
    borderRadius: "10px",
    color: COLORS.BLACK,
    fontSize: STYLE_CONSTS.FONT,
    fontStyle: "normal",
    padding: "12px 24px 12px 12px"
  },
  searchAutocomplee: {
    height: "36px"
  },
  searchTextField: {
    width: "195px",
    height: "36px",
    borderBotton: "none",
    boxSizing: "border-box",
    color: COLORS.BLACK,
    fontSize: STYLE_CONSTS.FONT,
    fontStyle: "normal",
    padding: "0px"
  },
  filterButton: {
    width: "110px",
    border: `1px solid ${COLORS.MAIN_ORANGE}`,
    borderRadius: "10px",
    "& .MuiButton-root": {
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderColor: COLORS.MAIN_ORANGE
    }
  },
  filterButtonOn: {
    width: "110px",
    border: `1px solid ${COLORS.MAIN_ORANGE}`,
    borderRadius: "10px",
    backgroundColor: COLORS.MAIN_ORANGE,
    "& .MuiButton-label": {
      color: COLORS.WHITE
    },
    "&:hover": {
      backgroundColor: `${COLORS.MAIN_ORANGE} !important`
    }
  }
});

import { gql } from "@apollo/client";

export default gql`
  fragment AccountRoleFragment on accountRole {
    id
    name
    defaultPayRate
    defaultChargeRate
    defaultOverstayPayRate
    isOverstayRate
    defaultOverstayChargeRate
    defaultOverstayHoursLimit
    isOvertimeRate
    defaultOvertimePayRate
    defaultOvertimeChargeRate
    defaultOvertimeHoursLimit
    isDayRate
    isUncalculatedRate
  }
`;

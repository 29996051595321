import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

import { addAuthVars } from "@teamrota/authlib";

export const VALIDATE_PAYROLL_SCALE = addAuthVars(gql`
  mutation validatePayrollScale($name: String!, $config: [JSONObject]!) {
    validatePayrollScale(name: $name, config: $config)
  }
`);

export const useValidatePayrollScale = () => {
  const [update, { loading, error }] = useMutation(VALIDATE_PAYROLL_SCALE, {
    onError: error => {
      throw error;
    }
  });
  const validatePayrollScale = async ({
    name,
    config
  }: {
    name: string;
    config: JSON;
  }) => {
    await update({
      variables: {
        name,
        config
      }
    });
  };

  return { validatePayrollScale, loading, error };
};

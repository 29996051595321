import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const Wrapper = styled.div`
  ${props =>
    props.height
      ? `height: ${props.height} !important;`
      : "border-bottom: 1.5px solid grey; padding-bottom: 30px; color: black; padding-top: 60px;"};
`;

export const MemberPhoto = styled.image`
  width: 70px;
`;

export const MemberPhotoWrapper = styled.div`
  ${props => props.isHidden && "display: none;"};
  height: 70px;
  width: 70px;
  border-radius: 35px;
  overflow: hidden;
`;

export const Image = styled.image`
  width: 100px;
  display: block;
  margin-bottom: ${STYLE_CONSTS.UNIT_3};
`;

export const TableHeader = styled.div`
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_MEDIUM};
  margin-bottom: ${STYLE_CONSTS.UNIT_2};
  display: flex;
  flex-direction: row;
`;

export const TableRow = styled.div`
  display: flex;
  margin-bottom: ${STYLE_CONSTS.UNIT_2};
  padding-bottom: ${STYLE_CONSTS.UNIT_2};
  border-bottom: ${props =>
    props.isBordered && STYLE_CONSTS.SMALL_DIVIDER_BORDER};
`;

export const TableCell = styled.div`
  ${props =>
    props.isTitle &&
    "font-weight: bold; padding-bottom: 5px; padding-top: 30px; font-size: 25px"};
  ${props => props.isSubTitle && "font-weight: bold;"};
  flex: ${props => props.width};
  display: flex;
  align-items: center;
  justify-content: ${props => (props.isRightAlign ? "flex-end" : "flex-start")};
  margin-right: ${STYLE_CONSTS.UNIT_2};
  flex-direction: ${({ column }) => column && "column"};
`;

export const MemberDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${STYLE_CONSTS.UNIT_2};
  justify-content: center;
`;

export const LightText = styled.div`
  color: ${({ theme }) => theme.neutral.main};
  font-size: ${STYLE_CONSTS.FONT_SMALL};
`;

export const SmallText = styled.div`
  font-size: ${STYLE_CONSTS.FONT_SMALL};
`;

export const TotalLabel = styled.div`
  align-self: flex-end;
`;

export const BoldText = styled.div`
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_MEDIUM};
`;

export const ShiftHead = styled.div`
  display: flex;
  border-bottom: ${STYLE_CONSTS.SMALL_DIVIDER_BORDER};
  padding-bottom: ${STYLE_CONSTS.UNIT_2};
  margin-bottom: ${STYLE_CONSTS.UNIT_2};
`;

export const ShiftDetailsLabel = styled.div`
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_MEDIUM};
  width: 200px;
`;

export const ShiftDetailsContent = styled.div`
  flex: 1;
`;

export const Divider = styled.div`
  padding-top: 50px;
`;

export const StyledTimeSpan = styled.span`
  font-size: 10px;
`;

export const StyledWrapPrice = styled.span`
  margin-top: -55px;
`;

import React from "react";
import { RotaButton, icon as RotaIcon, colors } from "@teamrota/rota-design";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";

import { Typography } from "@material-ui/core";
import Loading from "~/src/components/loading";

import styles, {
  DeleteBadgeWrapper,
  DeleteBadgeContainer,
  useStyles,
  StyledIcon
} from "./dialog.styles";
import DialogTitle from "~/src/containers/settings-page/dialog-title";

const AlertDialog = ({
  openDialog,
  handleClose,
  isLoading,
  children,
  dialogTitle,
  onClickHandler,
  declineBtnText,
  acceptBtnText,
  ignoreBtnText,
  onClickHandlerIgnore,
  input,
  singleButton,
  isDelete,
  icon
}) => {
  const classes = useStyles();

  const btnContainerStyle = input
    ? styles.dialogActionsInput
    : singleButton
    ? styles.singleButton
    : styles.dialogActions;

  const { DeleteButton } = RotaIcon;

  return (
    <Dialog
      classes={
        ignoreBtnText
          ? { paper: classes.dialogPaper }
          : { paper: classes.dialogPaperInput }
      }
      fullWidth
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={openDialog}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose} />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          padding: "21px"
        }}
      >
        {isDelete && (
          <DeleteBadgeWrapper>
            <DeleteBadgeContainer>
              <DeleteButton color={colors.rotaOrange} />
            </DeleteBadgeContainer>
          </DeleteBadgeWrapper>
        )}
        {icon && (
          <div>
            <StyledIcon name={icon} size="XXXLARGE" />
          </div>
        )}
        <Typography align="center" style={styles.dialogTitleText}>
          {dialogTitle}
        </Typography>
      </div>
      <div style={styles.dialogContent}>
        <div style={styles.dialogText}>{children}</div>
      </div>
      <DialogActions style={btnContainerStyle}>
        {declineBtnText && (
          <RotaButton variant="secondary" onClick={handleClose} size="medium">
            {declineBtnText}
          </RotaButton>
        )}
        {ignoreBtnText && (
          <RotaButton
            onClick={onClickHandlerIgnore}
            size="medium"
            variant="secondary"
          >
            {ignoreBtnText}
          </RotaButton>
        )}
        <RotaButton
          color={isDelete ? colors.red : ""}
          autoFocus
          onClick={onClickHandler}
          size="medium"
        >
          {!isLoading && acceptBtnText}
          {""} {isLoading && <Loading color="black" />}
        </RotaButton>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;

import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";

import Tags from "~/src/components/tag-input-suggestions";

import { Row } from "./styles";

import {
  SHIFT_TAGS_SUGGESTIONS,
  GET_ACCOUNT_ID,
  GET_TARGET_ACCOUNT
} from "~/src/containers/tags/graphql/tag-queries";

const TagsContainer = ({
  onDeleteTag,
  accountId,
  setTags,
  tags,
  inEditMode
}) => {
  const [allTags, setAllTags] = useState(tags || []);

  useEffect(() => {
    if (!setTags) return null;
    setTags(allTags);
  }, [allTags]);

  const { accountIdData } = useQuery(GET_ACCOUNT_ID, {
    fetchPolicy: "network-only"
  });

  const { data: shiftTagsData, refetch } = useQuery(SHIFT_TAGS_SUGGESTIONS, {
    fetchPolicy: "network-only",
    variables: {
      accountId: accountIdData?.account?.id
    }
  });

  const { data: targetAccountData } = useQuery(GET_TARGET_ACCOUNT, {
    fetchPolicy: "network-only",
    variables: {
      accountId
    }
  });

  const targetAccountRequesterTags =
    targetAccountData?.account?.targetAccount?.tags;
  const availableProviderAccountTags =
    shiftTagsData?.shiftTagsSuggestions?.suggestions;

  const addTags = (tag, tags) => {
    if (tags.some(availableTag => availableTag.name === tag?.name)) return null;
    setAllTags([...tags, tag]);
  };

  return (
    <Row style={{ paddingRight: "20px" }}>
      <Tags
        refetch={refetch}
        tags={allTags || []}
        suggestions={
          targetAccountRequesterTags?.length
            ? targetAccountRequesterTags
            : availableProviderAccountTags
        }
        isInputDisabled={!inEditMode}
        onAddTag={(tag, tags) => addTags(tag, tags)}
        onDeleteTag={index => {
          const updatedTags = onDeleteTag(index, tags);
          if (!updatedTags) return null;
          setAllTags(updatedTags);
        }}
      />
    </Row>
  );
};

export default TagsContainer;

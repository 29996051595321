import { TIME_FORMAT } from "~/src/utils/formatting";
import moment from "moment-timezone";

export default (
  booking,
  bookingState,
  startTimeUpdate,
  endTimeUpdate,
  isNoShow,
  isTurnedAway
) => {
  const format = time =>
    time ? moment(time).format(TIME_FORMAT) : "Not submitted";
  const { startFinal, endFinal } = booking;
  const {
    startOnShift,
    endOnShift,
    startOnShiftManager,
    endOnShiftManager,
    startOnShiftUser,
    endOnShiftUser
  } = bookingState;

  const isEdited = startTimeUpdate || endTimeUpdate;
  if (isNoShow) {
    return {
      times: "No show",
      actionBy: ""
    };
  }

  if (isTurnedAway) {
    return {
      times: "Turned away",
      actionBy: ""
    };
  }

  if (startOnShift || endOnShift || startOnShiftManager || endOnShiftManager) {
    const start = format(startTimeUpdate || startFinal);
    const end = format(endTimeUpdate || endFinal);
    let via = null;
    if (!endOnShift && !endOnShiftManager) {
      via = startOnShift ? "Member" : "Manager";
    } else {
      via = endOnShift ? "Member" : "Manager";
    }

    let by = "Unknown";
    if (startOnShiftUser) {
      by = `${startOnShiftUser?.firstName} ${startOnShiftUser?.lastName}`;
    }
    if (endOnShiftUser && !startOnShiftUser) {
      by = `${endOnShiftUser?.firstName} ${endOnShiftUser?.lastName}`;
    } else if (endOnShiftUser && startOnShiftUser?.id !== endOnShiftUser?.id) {
      by = `${by} and ${endOnShiftUser?.firstName} ${endOnShiftUser?.lastName}`;
    }

    return {
      times: `${start} - ${end}`,
      actionBy: `by ${by} via ${via} App${isEdited ? " (edited)" : ""}`
    };
  }

  return {
    times: `${format(startTimeUpdate || startFinal)} - ${format(
      endTimeUpdate || endFinal
    )}`,
    actionBy: isEdited ? "Edited" : "Not submitted - rate or set to no show"
  };
};

import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import adminClient from "~/src/containers/admin-dashboard/graphql/admin-client";

import { GET_MEMBERS } from "~/src/containers/admin-dashboard/graphql/get-members";

import useLoadMore from "~/src/containers/admin-dashboard/hooks/load-more";
import DashboardScrollHandler from "~/src/components/dashboard-scroll-handler";

import { RotaSearchInput } from "@teamrota/rota-design";

import AccountsList from "../accounts-list";
import MemberContainer from "./member-container";

import {
  StyledMainHeading,
  StyledMainDetail,
  StyledMainHeaderWrapper,
  StyledFeatureHeader,
  StyledWrapper,
  StyledFeaturesWrapper,
  StyledWrapperList
} from "../styles";

const UpdateMembersEmail = ({ setSnack }) => {
  const [selectedAccountId, setSelectedAccountId] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedAccountName, setSelectedAccountName] = useState();

  const SCROLL_ELEMENT_ID = "members_list_scroll";

  const { data, fetchMore, loading } = useQuery(GET_MEMBERS, {
    variables: {
      searchTerm,
      accountId: selectedAccountId,
      limit: 10,
      offset: 0
    },
    client: adminClient,
    skip: !selectedAccountId
  });
  const dataLength = data?.members.length;

  const [handleLoadMore] = useLoadMore({
    dataLength,
    fetchMore,
    nameData: "members"
  });

  return (
    <>
      <StyledMainHeaderWrapper>
        <StyledMainHeading>Update Members Email</StyledMainHeading>
        <StyledMainDetail>Find and update members email </StyledMainDetail>
      </StyledMainHeaderWrapper>

      <div>
        <StyledWrapper>
          <AccountsList
            onSelect={(id, accountName) => {
              setSelectedAccountId(id);
              setSelectedAccountName(accountName);
            }}
            selectedAccountId={selectedAccountId}
            selectedAccountName={selectedAccountName}
          />
          {selectedAccountId && (
            <StyledFeaturesWrapper>
              <StyledFeatureHeader>
                <h3>{selectedAccountName} members</h3>
                <RotaSearchInput
                  placeholder="search member"
                  onChange={value => {
                    setSearchTerm(value);
                  }}
                />
              </StyledFeatureHeader>
              <DashboardScrollHandler
                onReachEnd={() => {
                  handleLoadMore();
                }}
                scrollElementId={SCROLL_ELEMENT_ID}
              >
                <StyledWrapperList id={SCROLL_ELEMENT_ID}>
                  {loading && <p>loading...</p>}
                  {data?.members?.map(member => (
                    <MemberContainer
                      key={member.id}
                      member={member}
                      setSnack={setSnack}
                    />
                  ))}
                </StyledWrapperList>
              </DashboardScrollHandler>
            </StyledFeaturesWrapper>
          )}
        </StyledWrapper>
      </div>
    </>
  );
};

export default UpdateMembersEmail;

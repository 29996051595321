import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const Wrapper = styled.div`
  font-size: ${({ isMediumText }) =>
    isMediumText ? STYLE_CONSTS.FONT_MEDIUM : "inherit"};
`;

export const Toggle = styled.div`
  color: ${({ theme }) => theme.primary.main};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

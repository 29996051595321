import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";
import Icon from "~/src/components/icon";

const TRAY_HEIGHT = 200;

export const ReadOnly = styled.div`
  font-size: ${STYLE_CONSTS.FONT_MEDIUM};
`;

export const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
  min-width: 200px;
`;

export const DropdownContent = styled.div`
  background-color: ${({ theme }) => theme.white};
  width: 100%;
  position: absolute;
  overflow: auto;
  max-height: ${TRAY_HEIGHT}px;
  height: auto;
  box-shadow: ${STYLE_CONSTS.FLOATING};
  border: ${({ theme }) => `solid 1.2px ${theme.neutral.lighter}`};
  border-top: none;
  border-radius: ${STYLE_CONSTS.BORDER_RADIUS};
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  z-index: ${STYLE_CONSTS.Z_INDEX_SELECT};
  text-align: left;
`;

export const Option = styled.div`
  padding: ${STYLE_CONSTS.UNIT_2};
  font-size: ${STYLE_CONSTS.FONT};
  &:hover {
    background-color: ${({ theme }) => theme.neutral.lighter};
    cursor: pointer;
  }
`;

export const StyledIcon = styled(Icon)`
  margin-left: ${({ isSmall }) =>
    isSmall ? STYLE_CONSTS.UNIT_1 : STYLE_CONSTS.UNIT_2};
`;

export const StyledButton = styled.button`
  width: 100%;
  font-size: ${STYLE_CONSTS.FONT_MEDIUM};
  color: ${({ readOnly, theme }) =>
    readOnly ? theme.text.primary : theme.primary.main};
  border: none;
  background: transparent;
  transition: all 0.4 ease;
  cursor: pointer;
  padding: 0;
  text-align: left;
  &:focus {
    outline: none;
  }
  &:active {
    opacity: 0.5;
  }
  &:hover {
    opacity: 0.7;
  }
`;

export const Placeholder = styled.span`
  color: ${({ theme }) => theme.neutral.light};
`;

import React from "react";
import ReactTooltip from "react-tooltip";
import Icon from "../icon";
import styles, { ButtonComponent, ButtonIcon } from "./button.styles";

export default function Button({
  borderRadius,
  background,
  btnWidth,
  label,
  tooltip,
  children,
  className,
  color,
  type,
  fontSize,
  fontStyle,
  onClick,
  iconName,
  iconSize,
  isDisabled,
  isLarge,
  isLoading,
  isBlock,
  isGrey,
  isOutline,
  isSmall,
  isXSmall,
  isGreen,
  isRed,
  isIconOnly,
  isSuccess,
  isIconRight,
  isBorderless,
  iconTranslateY,
  isUsingFlexbox
}) {
  const buttonLabel = children || label;

  const buttonIconName = () => {
    if (isLoading) {
      return Icon.names.LOADING;
    }
    if (isSuccess) {
      return Icon.names.DONE;
    }
    if (iconName) {
      return Icon.names[iconName];
    }
    return null;
  };

  const iconColor =
    isOutline || isBorderless ? Icon.colors.CLOUDY_BLUE : Icon.colors.WHITE;

  // eslint-disable-next-line no-nested-ternary
  const buttonIconSize = iconSize ? iconSize : isXSmall ? "xsmall" : "small";

  const icon = (
    <ButtonIcon
      isIconOnly={isIconOnly}
      name={buttonIconName()}
      iconTranslateY={iconTranslateY}
      size={buttonIconSize}
      color={iconColor}
      isSmall={isSmall}
    />
  );

  const TOOL_ID = `tooltip-${Math.random()}`;

  return (
    <ButtonComponent
      btnWidth={btnWidth}
      data-tip
      data-for={TOOL_ID}
      className={className}
      onClick={onClick}
      type={type || "button"}
      disabled={isDisabled || isLoading}
      isLoading={isLoading}
      isDisabled={isDisabled}
      isLarge={isLarge}
      isOutline={isOutline}
      isSmall={isSmall}
      isXSmall={isXSmall}
      isBlock={isBlock}
      isGrey={isGrey}
      isGreen={isGreen}
      isRed={isRed}
      isIconOnly={isIconOnly}
      isBorderless={isBorderless}
      background={background}
      borderRadius={borderRadius}
      color={color}
      fontSize={fontSize}
      fontStyle={fontStyle}
    >
      {tooltip && <ReactTooltip id={TOOL_ID}>{tooltip}</ReactTooltip>}

      {isUsingFlexbox && (
        <div style={styles.centeredFlexbox}>
          {buttonIconName() && !isIconRight && (
            <span style={styles.centeredFlexboxIconLeft}>{icon}</span>
          )}
          {!isIconOnly && (
            <span style={styles.centeredFlexbox}>
              {isLoading ? "Loading..." : buttonLabel}
            </span>
          )}
          {buttonIconName() && isIconRight && (
            <span style={styles.centeredFlexboxIconRight}>{icon}</span>
          )}
        </div>
      )}
      {!isUsingFlexbox && buttonIconName() && !isIconRight && icon}
      {!isUsingFlexbox &&
        (!isIconOnly && isLoading ? "Loading..." : buttonLabel)}
      {!isUsingFlexbox && buttonIconName() && isIconRight && icon}
    </ButtonComponent>
  );
}

Button.iconNames = Icon.names;

Button.successDelay = () =>
  new Promise(resolve => {
    setTimeout(resolve, 2000);
  });

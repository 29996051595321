import React, { useEffect, useState } from "react";
import moment from "moment-timezone";

import { RotaCalendar, RotaInput } from "@teamrota/rota-design";
import {
  CalendarSurround,
  Container,
  NumberSurround,
  Separator
} from "./styles";

interface DateTimeSelectProps {
  time: Date | undefined;
  onChangeTime: (date: Date | undefined) => void;
}

export const fixHours = (value: string | undefined) => {
  if (value !== null && value !== undefined) {
    let result = parseInt(value.replace(/[^0-9.]/g, ""));
    if (result > 23 || isNaN(result)) result = 23;
    return result < 10 ? `0${result}` : `${result}`;
  }

  return "";
};

export const fixMinutes = (value: string | undefined) => {
  if (value !== null && value !== undefined) {
    let result = parseInt(value.replace(/[^0-9.]/g, ""));
    if (result > 60 || isNaN(result)) result = 59;
    return result < 10 ? `0${result}` : `${result}`;
  }

  return "";
};

const DateTimeSelect: React.FC<DateTimeSelectProps> = ({
  time,
  onChangeTime
}) => {
  const [date, setDate] = useState<Date | undefined>(time);

  const [hours, setHours] = useState<string | undefined>(
    fixHours(time !== undefined ? `${moment(time).hours()}` : undefined)
  );

  const [mins, setMins] = useState<string | undefined>(
    fixMinutes(time !== undefined ? `${moment(time).minutes()}` : undefined)
  );

  useEffect(() => {
    if (date !== undefined && hours !== undefined && mins !== undefined) {
      const mm = moment(date);
      mm.hour(parseInt(hours, 10));
      mm.minute(parseInt(mins, 10));
      onChangeTime(mm.toDate());
    } else {
      onChangeTime(undefined);
    }
  }, [date, hours, mins]);

  return (
    <Container>
      <CalendarSurround>
        <RotaCalendar
          value={date}
          onChangeDate={date => {
            setDate(date);
          }}
        />
      </CalendarSurround>

      <NumberSurround>
        <RotaInput
          placeholder={"h"}
          value={hours ?? ""}
          onChange={e => setHours(fixHours(e.target.value))}
        ></RotaInput>
      </NumberSurround>

      <Separator>:</Separator>

      <NumberSurround>
        <RotaInput
          placeholder={"m"}
          value={mins ?? ""}
          onChange={e => setMins(fixMinutes(e.target.value))}
        ></RotaInput>
      </NumberSurround>
    </Container>
  );
};

export default DateTimeSelect;

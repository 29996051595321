import React, { useEffect } from "react";
import ReactTags from "react-tag-autocomplete";
import { TagsWrapper } from "./tag-input-suggestions.styles";
import SelectedTag from "./components/selected-tag";

const Tags = ({
  tags,
  emptyMessage,
  isSingleLine,
  isInputDisabled,
  onDeleteTag,
  onAddTag,
  placeholder,
  suggestions,
  refetch
}) => {
  useEffect(() => {
    refetch();
  }, []);

  if (!tags) {
    return null;
  }

  if (isInputDisabled) {
    if (!tags.length) {
      return (
        <TagsWrapper isSingleLine={isSingleLine}>
          {emptyMessage || `There are no tags added yet`}
        </TagsWrapper>
      );
    }

    return (
      <TagsWrapper isSingleLine={isSingleLine}>
        {tags.map((tag, index) => (
          <SelectedTag key={index} tag={tag} />
        ))}
      </TagsWrapper>
    );
  }

  return (
    <TagsWrapper>
      <ReactTags
        tags={tags}
        suggestions={suggestions}
        placeholder={placeholder}
        autofocus={false}
        handleDelete={!isInputDisabled && onDeleteTag}
        handleAddition={tag => onAddTag(tag, tags)}
        minQueryLength={isInputDisabled ? 1 : 0}
        inputAttributes={{ readOnly: isInputDisabled }}
        tagComponent={SelectedTag}
      />
    </TagsWrapper>
  );
};

export default Tags;

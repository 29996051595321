import React from "react";
import History from "~/src/components/history";
import { HistoryContainer, Spacer } from "./index.styles";

const HistoryComponent = ({ logs }) => (
  <HistoryContainer>
    <Spacer />
    <History logs={logs} />
  </HistoryContainer>
);

export default HistoryComponent;

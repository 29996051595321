import { createReducer, createAction } from "~/src/utils";

const DEFAULT_STATE = {
  isIncludingSummarySheet: false,
  rangeStart: "",
  rangeEnd: "",
  isGenerating: false
};

export const UPDATE_SUMMARY_CHECK = "reports/UPDATE_SUMMARY_CHECK";
export const SET_RANGE_START = "reports/SET_RANGE_START";
export const SET_RANGE_END = "reports/SET_RANGE_END";

export const onUpdateSummaryCheckbox = createAction(UPDATE_SUMMARY_CHECK);
export const onSetRangeStart = createAction(SET_RANGE_START);
export const onSetRangeEnd = createAction(SET_RANGE_END);

export default createReducer(
  {
    [UPDATE_SUMMARY_CHECK]: state => ({
      ...state,
      isIncludingSummarySheet: !state.isIncludingSummarySheet
    }),
    [SET_RANGE_START]: (state, { payload }) => ({
      ...state,
      rangeStart: payload
    }),
    [SET_RANGE_END]: (state, { payload }) => ({
      ...state,
      rangeEnd: payload
    })
  },
  DEFAULT_STATE
);

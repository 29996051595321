import { useQuery } from "@apollo/client";

import useAuth from "~/src/auth/hooks/use-auth";
import { POOL_TYPES } from "@teamrota/rota-common";
import { checkErrors } from "~/src/utils/errors";

import { GET_GROUPS } from "~/src/graphql/queries/get-group/get-group.decorator";
import { GET_MEMBER_POOLS } from "./queries/get-pools";
import { useState } from "react";

const PAGE_SIZE = 200;

export const useMemberPools = (
  shiftId,
  targetAccountId,
  selectedAccountId,
  roleRateId,
  memberType,
  venueId,
  dates,
  shiftType,
  searchText,
  tagIds
) => {
  const auth = useAuth();
  const [isInitialFetch, setIsInitialFetch] = useState(true);
  const [limit, setLimit] = useState(PAGE_SIZE);

  const { loading, data, fetchMore } = checkErrors(
    useQuery(GET_MEMBER_POOLS, {
      skip: !(targetAccountId || selectedAccountId) || !venueId,
      variables: auth.addVals(GET_MEMBER_POOLS, {
        shiftId,
        targetAccountId,
        selectedAccountId,
        roleRateId,
        hasRoleRateId: roleRateId,
        poolTypes: [POOL_TYPES.FAVOURITED, POOL_TYPES.RECOMMENDED],
        searchText,
        memberType,
        venueId,
        dates,
        shiftType,
        offset: 0,
        limit,
        tagIds
      }),
      fetchPolicy: isInitialFetch ? "network-only" : "cache-first",
      notifyOnNetworkStatusChange: true
    })
  );

  const members = data?.account?.memberPools?.data ?? [];
  const totalResults = data?.account?.memberPools?.totalResults;

  return {
    loading: isInitialFetch && loading,
    loadingMore: !isInitialFetch && loading,
    members,
    totalResults,
    hasMore: members.length < totalResults,
    fetchMore: () => {
      if (members.length < totalResults) {
        setIsInitialFetch(false);

        fetchMore({
          variables: {
            offset: limit,
            limit: PAGE_SIZE
          },
          fetchPolicy: "network-only"
        }).then(({ data }) => {
          setLimit(limit + data.account?.memberPools?.data?.length);
        });
      }
    }
  };
};

export const useUserGroups = (
  shiftId,
  isProvide,
  targetAccountId,
  roleRateId,
  dates,
  shiftType,
  venueId,
  selectedAccountId,
  tagIds
) => {
  const auth = useAuth();

  const { loading, data } = checkErrors(
    useQuery(GET_GROUPS, {
      skip: !targetAccountId || !roleRateId,
      variables: auth.addVals(GET_GROUPS, {
        shiftId,
        sourceAccountId: targetAccountId,
        selectedAccountId,
        roleRateId,
        dates,
        shiftType,
        isRoleRateId: true,
        venueId,
        checkLocationConflict: true,
        tagIds
      }),
      fetchPolicy: "network-only"
    })
  );

  return {
    loading,
    groups: data?.groups ?? []
  };
};

import { set, omit, get } from "lodash/fp";
import { BOOKING_STATES } from "~/src/consts";
import { createReducer, createAction } from "~/src/utils";

export const ON_UPDATE_FILTERS = "provide/ON_UPDATE_FILTERS";
export const ON_SEARCH = "provide/ON_SEARCH";
export const ON_RESET_STATE = "provide/ON_RESET_STATE";
export const ON_UPDATE_SORT_BY = "provide/ON_UPDATE_SORT_BY";
export const ON_REMOVE_FILTERS = "provide/ON_REMOVE_FILTERS";
export const SET_TAB_INDEX = "provide/SET_TAB_INDEX";
export const OPEN_SHIFT_BOOKINGS = "provide/OPEN_SHIFT_BOOKINGS";
export const ADD_NEW_REWARD = "provide/ADD_NEW_REWARD";
export const REMOVE_REWARD = "provide/REMOVE_REWARD";
export const UPDATE_REWARD_TYPE = "provide/UPDATE_REWARD_TYPE";
export const UPDATE_REWARD_AMOUNT = "provide/UPDATE_REWARD_AMOUNT";
export const UPDATE_REWARD_REASON = "provide/UPDATE_REWARD_REASON";
export const ADD_EXISTING_REWARDS = "provide/ADD_EXISTING_REWARDS";
export const CLEAR_REWARDS_STATE = "provide/CLEAR_REWARDS_STATE";
export const UPDATE_BONUS_ID = "provide/UPDATE_BONUS_ID";
export const CLEAR_DELETED_REWARDS = "provide/CLEAR_DELETED_REWARDS";
export const TOGGLE_REWARD_SAVING = "provide/TOGGLE_REWARD_SAVING";
export const onUpdateFilters = createAction(ON_UPDATE_FILTERS);
export const onUpdateSortBy = createAction(ON_UPDATE_SORT_BY);
export const onSearch = createAction(ON_SEARCH);
export const onRemoveFilters = createAction(ON_REMOVE_FILTERS);
export const onResetState = createAction(ON_RESET_STATE);
export const setTabIndex = createAction(SET_TAB_INDEX);
export const openShiftBookings = createAction(OPEN_SHIFT_BOOKINGS);
export const addNewReward = createAction(ADD_NEW_REWARD);
export const removeReward = createAction(REMOVE_REWARD);
export const updateRewardType = createAction(UPDATE_REWARD_TYPE);
export const updateRewardAmount = createAction(UPDATE_REWARD_AMOUNT);
export const updateRewardReason = createAction(UPDATE_REWARD_REASON);
export const addExistingRewards = createAction(ADD_EXISTING_REWARDS);
export const clearRewardsState = createAction(CLEAR_REWARDS_STATE);
export const updateBonusId = createAction(UPDATE_BONUS_ID);
export const clearDeletedRewards = createAction(CLEAR_DELETED_REWARDS);
export const toggleRewardSaving = createAction(TOGGLE_REWARD_SAVING);
const defaultReward = {
  type: null,
  amount: null,
  modified: false,
  reason: undefined
};

const DEFAULT_STATE = {
  searchText: "",
  sortBy: { label: "Start time", value: "startTime" },
  filters: {
    startTime: "",
    endTime: "",
    shiftState: null,
    member: null,
    shiftGroupId: null,
    shiftType: null,
    hasCancelledBookings: null,
    hasAppliedBookings: null,
    isFinalised: false,
    isUnderReview: false,
    isCancelled: false,
    isRestricted: false,
    isRegistration: false,
    isShiftRebook: false,
    isPartnerManaged: false
  },
  rewards: {},
  shiftBookingList: {}, // Has map of shift bookings
  isRewardSaving: false
};

export const BOOKING_LIMIT = 5;

const getTempRewardId = currentRewards => {
  if (currentRewards && Object.keys(currentRewards).length !== 0) {
    let id;
    let x = 2;
    while (x < 50) {
      id = `TEMP${x}`;
      if (!currentRewards[id]) {
        return id;
      }
      x += 1;
    }
  }
  return "TEMP1";
};

export default createReducer(
  {
    [TOGGLE_REWARD_SAVING]: state => ({
      ...state,
      isRewardSaving: !state.isRewardSaving
    }),
    [ADD_EXISTING_REWARDS]: (state, { payload }) => ({
      ...state,
      rewards: {
        ...state.rewards,
        [payload.shiftId]: {
          all: {
            ...payload.mappedRewards
          },
          deletedRewardIds: []
        }
      }
    }),
    [UPDATE_BONUS_ID]: (state, { payload }) => ({
      ...state,
      rewards: {
        ...state.rewards,
        [payload.shiftId]: {
          ...state.rewards[payload.shiftId],
          all: {
            ...omit(
              payload.rewardId,
              get(`rewards[${payload.shiftId}].all`, state)
            ),
            [payload.id]: {
              type: payload.type,
              amount: payload.amount,
              reason: payload.reason,
              modified: false
            }
          }
        }
      }
    }),
    [CLEAR_REWARDS_STATE]: state => ({
      ...state,
      rewards: {}
    }),
    [UPDATE_REWARD_AMOUNT]: (state, { payload }) => ({
      ...state,
      rewards: {
        ...state.rewards,
        [payload.shiftId]: {
          ...state.rewards[payload.shiftId],
          all: {
            ...get(`rewards[${payload.shiftId}].all`, state),
            [payload.rewardId]: {
              ...get(
                `rewards[${payload.shiftId}].all[${payload.rewardId}]`,
                state
              ),
              amount: payload.amount,
              modified: true
            }
          }
        }
      }
    }),
    [UPDATE_REWARD_REASON]: (state, { payload }) => ({
      ...state,
      rewards: {
        ...state.rewards,
        [payload.shiftId]: {
          ...state.rewards[payload.shiftId],
          all: {
            ...get(`rewards[${payload.shiftId}].all`, state),
            [payload.rewardId]: {
              ...get(
                `rewards[${payload.shiftId}].all[${payload.rewardId}]`,
                state
              ),
              reason: payload.reason,
              modified: true
            }
          }
        }
      }
    }),
    [CLEAR_DELETED_REWARDS]: (state, { payload }) => ({
      ...state,
      rewards: {
        ...state.rewards,
        [payload.shiftId]: {
          all: {
            ...omit(
              state.rewards[payload.shiftId].deletedRewardIds,
              state.rewards[payload.shiftId].all
            )
          },
          deletedRewardIds: []
        }
      }
    }),
    [UPDATE_REWARD_TYPE]: (state, { payload }) => ({
      ...state,
      rewards: {
        ...state.rewards,
        [payload.shiftId]: {
          ...state.rewards[payload.shiftId],
          all: {
            ...get(`rewards[${payload.shiftId}].all`, state),
            [payload.rewardId]: {
              ...get(
                `rewards[${payload.shiftId}].all[${payload.rewardId}]`,
                state
              ),
              type: payload.type,
              modified: true
            }
          }
        }
      }
    }),
    [REMOVE_REWARD]: (state, { payload }) => ({
      ...state,
      rewards: {
        ...state.rewards,
        [payload.shiftId]: {
          ...state.rewards[payload.shiftId],
          deletedRewardIds: state.rewards[payload.shiftId].deletedRewardIds
            ? [
                ...state.rewards[payload.shiftId].deletedRewardIds,
                payload.rewardId
              ]
            : [payload.rewardId]
        }
      }
    }),
    [ADD_NEW_REWARD]: (state, { payload }) => ({
      ...state,
      rewards: {
        ...state.rewards,
        [payload]: {
          ...state.rewards[payload],
          all: {
            ...get(`rewards[${payload}].all`, state),
            [getTempRewardId(get(`rewards[${payload}].all`, state))]: {
              ...defaultReward
            }
          }
        }
      }
    }),
    [ON_UPDATE_FILTERS]: (state, { payload }) => ({
      ...state,
      filters: {
        ...state.filters,
        ...payload
      }
    }),
    [ON_SEARCH]: (state, { payload }) => ({
      ...state,
      searchText: payload
    }),
    [ON_RESET_STATE]: () => DEFAULT_STATE,
    [ON_UPDATE_SORT_BY]: (state, { payload }) => set("sortBy", payload, state),
    [ON_REMOVE_FILTERS]: (state, { payload }) => ({
      ...state,
      filters: {
        ...DEFAULT_STATE.filters,
        ...omit(payload, state.filters)
      }
    }),
    [SET_TAB_INDEX]: (state, { payload }) => ({
      ...state,
      shiftBookingList: {
        ...state.shiftBookingList,
        [payload.shiftId]: {
          ...(state.shiftBookingList[payload.shiftId] || {}),
          activeBookingStates: payload.activeBookingStates,
          keyProp: payload.keyProp
        }
      }
    }),
    [OPEN_SHIFT_BOOKINGS]: (state, { payload }) => ({
      ...state,
      shiftBookingList: {
        ...state.shiftBookingList,
        [payload.shiftId]: {
          ...(state.shiftBookingList[payload.shiftId] || {}),
          isOpen: !(state.shiftBookingList[payload.shiftId] || {}).isOpen,
          // Default to applied tab
          activeBookingStates: payload.defaultStates || [
            BOOKING_STATES.APPLIED
          ],
          keyProp: payload.defaultKeyProp
        }
      }
    })
  },
  DEFAULT_STATE
);

import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const Input = styled.input`
  width: 100%;
  font-size: ${STYLE_CONSTS.FONT_MEDIUM};
  background-color: ${({ theme }) => theme.white};
  border: none;
  &:focus {
    outline: none;
  }
  &:disabled {
    color: ${({ theme }) => theme.text.primary};
  }
  &.isCopiable {
    color: ${({ theme }) => theme.text.primary};
  }
  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.neutral.main};
    background-color: ${({ theme }) => theme.white};
  }
`;

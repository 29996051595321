import React, { useState } from "react";
import {
  countries,
  getCountryValidations,
  countryByCode,
  regex
} from "@teamrota/rota-common";

import { Field, Label, Input, Row, Select, StyledErrorMessage } from "./styles";
import {
  validatePostcode,
  validatePhoneCountry,
  validatePhoneNumber
} from "./validate";

const emptyEmailFieldMessage = "Please enter an email address";
const invalidEmailMessage = "Please enter a valid email address";
const onlyLettersErrorMessage = "Field must contain only letters";

const prioritisedCountries = [
  ...countries.filter(({ priority }) => priority),
  ...countries
];

const ContactDetails = ({
  errors,
  register,
  initialValues,
  inEditMode,
  isAllowedToViewMemberDetails,
  getValues,
  setValue
}) => {
  const [addressCountry, setAddressCountry] = useState(getValues().country);

  const division =
    countryByCode[addressCountry]?.division ?? "County/State/Province";
  const { postcode } = getCountryValidations(addressCountry);

  return (
    <>
      <p>Contact Details</p>
      {isAllowedToViewMemberDetails && (
        <>
          <Row>
            <Field>
              <Label>Country/Territory</Label>
              <Select
                {...register("country", {
                  onChange: e => setAddressCountry(e.target.value)
                })}
                disabled={!inEditMode}
              >
                {prioritisedCountries.map(({ name, code, flag }, i) => (
                  <option key={i} value={code}>
                    {flag} {name}
                  </option>
                ))}
              </Select>
              <StyledErrorMessage>{errors.country?.message}</StyledErrorMessage>
            </Field>
            <Field>
              <Label htmlFor="county">{division}</Label>
              <Input
                {...register("county", {
                  pattern: {
                    value: regex.LETTERS,
                    message: onlyLettersErrorMessage
                  }
                })}
                id="county"
                placeholder={initialValues?.county}
                disabled={!inEditMode}
              />
              <p>{errors.county?.message}</p>
            </Field>
          </Row>
          <Row>
            <Field>
              <Label htmlFor="town">Town/City</Label>
              <Input
                {...register("town", {
                  pattern: {
                    value: regex.LETTERS,
                    message: onlyLettersErrorMessage
                  }
                })}
                id="town"
                placeholder={initialValues?.town}
                disabled={!inEditMode}
              />
              <StyledErrorMessage>{errors.town?.message}</StyledErrorMessage>
            </Field>
            <Field>
              <Label htmlFor="postcode">{postcode.name}</Label>
              <Input
                {...register("postcode", {
                  setValueAs: v => postcode.transform(v),
                  validate: value => {
                    setValue("postcode", value);
                    return validatePostcode(addressCountry, value, false);
                  }
                })}
                id="postcode"
                placeholder={initialValues?.postcode}
                disabled={!inEditMode}
              />
              <StyledErrorMessage>
                {errors.postcode?.message}
              </StyledErrorMessage>
            </Field>
          </Row>
          <Row>
            <Field>
              <Label htmlFor="streetName">Street Name</Label>
              <Input
                {...register("streetName")}
                id="streetName"
                placeholder={initialValues?.streetName}
                disabled={!inEditMode}
              />
              <StyledErrorMessage>
                {errors.streetName?.message}
              </StyledErrorMessage>
            </Field>
            <Field>
              <Label htmlFor="houseNumber">House Name/Number</Label>
              <Input
                {...register("houseNumber")}
                id="houseNumber"
                placeholder={initialValues?.houseNumber}
                disabled={!inEditMode}
              />
              <StyledErrorMessage>
                {errors.houseNumber?.message}
              </StyledErrorMessage>
            </Field>
          </Row>
        </>
      )}

      <Row>
        <Field>
          <Label htmlFor="email">Email*</Label>
          <Input
            {...register("email", {
              required: {
                value: true,
                message: emptyEmailFieldMessage
              },
              pattern: {
                value: regex.EMAIL_ADDRESS,
                message: invalidEmailMessage
              }
            })}
            id="email"
            disabled={!inEditMode}
            placeholder={initialValues?.email}
          />
          <StyledErrorMessage>{errors.email?.message}</StyledErrorMessage>
        </Field>
      </Row>
      {isAllowedToViewMemberDetails && (
        <Row>
          <Field>
            <Label htmlFor="phoneCountry">Phone Country</Label>
            <Select
              {...register("phoneCountry", {
                validate: {
                  countryCodeValidation: value =>
                    validatePhoneCountry(getValues().phoneNumber, value)
                }
              })}
              id="phoneCountry"
              value={initialValues?.phoneCountry}
              disabled={!inEditMode}
            >
              <option key={0} value={null}>
                Please choose
              </option>
              {prioritisedCountries.map(
                ({ name, flag, code, dial_code }, i) => (
                  <option key={i} value={code}>
                    {flag} {name} +{dial_code}
                  </option>
                )
              )}
            </Select>
            <StyledErrorMessage>
              {errors.phoneCountry?.message}
            </StyledErrorMessage>
          </Field>
          <Field>
            <Label htmlFor="phoneNumber">Phone Number</Label>
            <Input
              {...register("phoneNumber", {
                validate: {
                  phoneNumberValidation: value =>
                    validatePhoneNumber(getValues().phoneCountry, value, false)
                }
              })}
              id="phone"
              disabled={!inEditMode}
              placeholder={initialValues?.phoneNumber}
            />
            <StyledErrorMessage>
              {errors.phoneNumber?.message}
            </StyledErrorMessage>
          </Field>
        </Row>
      )}

      {isAllowedToViewMemberDetails && (
        <>
          <p>Emergency Contact</p>

          <Row>
            <Field>
              <Label htmlFor="emergencyContactTitle">Contact Title</Label>
              <Input
                {...register("emergencyContactTitle")}
                id="emergencyContactTitle"
                placeholder={initialValues?.emergencyContactTitle}
                disabled={!inEditMode}
              />
              <StyledErrorMessage>
                {errors.emergencyContactTitle?.message}
              </StyledErrorMessage>
            </Field>
            <Field>
              <Label htmlFor="emergencyContactName">Contact Name</Label>
              <Input
                {...register("emergencyContactName", {
                  pattern: {
                    value: regex.LETTERS,
                    message: onlyLettersErrorMessage
                  }
                })}
                id="emergencyContactName"
                placeholder={initialValues?.emergencyContactName}
                disabled={!inEditMode}
              />
              <StyledErrorMessage>
                {errors.emergencyContactName?.message}
              </StyledErrorMessage>
            </Field>
          </Row>
          <Row>
            <Field>
              <Label htmlFor="emergencyContactRelationship">
                Contact Relationship
              </Label>
              <Input
                {...register("emergencyContactRelationship")}
                id="emergencyContactRelationship"
                placeholder={initialValues?.emergencyContactRelationship}
                disabled={!inEditMode}
              />
              <StyledErrorMessage>
                {errors.emergencyContactRelationship?.message}
              </StyledErrorMessage>
            </Field>
            <Field>
              <Label htmlFor="emergencyContactEmail">Contact Email</Label>
              <Input
                {...register("emergencyContactEmail", {
                  pattern: {
                    value: regex.EMAIL_ADDRESS,
                    message: invalidEmailMessage
                  }
                })}
                id="emergencyContactEmail"
                placeholder={initialValues?.emergencyContactEmail}
                disabled={!inEditMode}
              />
              <StyledErrorMessage>
                {errors.emergencyContactEmail?.message}
              </StyledErrorMessage>
            </Field>
          </Row>
          <Row>
            <Field>
              <Label htmlFor="emergencyPhoneCountry">
                Contact Phone Country
              </Label>
              <Select
                {...register("emergencyPhoneCountry", {
                  validate: {
                    countryCodeValidation: value =>
                      validatePhoneCountry(
                        getValues().emergencyPhoneNumber,
                        value
                      )
                  }
                })}
                id="emergencyPhoneCountry"
                disabled={!inEditMode}
              >
                <option key={0} value={null}>
                  Please choose
                </option>
                {prioritisedCountries.map(
                  ({ name, flag, code, dial_code }, i) => (
                    <option key={i} value={code}>
                      {flag} {name} +{dial_code}
                    </option>
                  )
                )}
              </Select>
              <StyledErrorMessage>
                {errors.emergencyPhoneCountry?.message}
              </StyledErrorMessage>
            </Field>
            <Field>
              <Label htmlFor="emergencyPhoneNumber">Contact Phone Number</Label>
              <Input
                {...register("emergencyPhoneNumber", {
                  validate: {
                    phoneNumberValidation: value =>
                      validatePhoneNumber(
                        getValues().emergencyPhoneCountry,
                        value,
                        false
                      )
                  }
                })}
                id="emergencyPhoneNumber"
                placeholder={initialValues?.emergencyPhoneNumber}
                disabled={!inEditMode}
              />
              <StyledErrorMessage>
                {errors.emergencyPhoneNumber?.message}
              </StyledErrorMessage>
            </Field>
          </Row>
        </>
      )}
    </>
  );
};
export default ContactDetails;

import { RotaButtonIcon } from "@teamrota/rota-design";
import styled from "styled-components";

export const OptionsButton = styled(RotaButtonIcon)`
  position: relative;
`;

export const ContentArea = styled.div`
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;

  min-height: 300px;

  & > div {
    width: 100%;
  }
`;

export const Status = styled.span`
  font-size: 110%;
  cursor: default;

  &.success::after {
    content: "\\2705";
  }

  &.recalculating::after {
    content: "\\23F3";
  }

  &.error::after {
    content: "\\274C";
  }
`;

export const Recalculating = styled.span`
  display: block;
  width: 100%;
  font-weight: 500;
  color: ${({ theme }) => theme.primary.main};

  & h1 {
    font-size: 110%;
  }
`;

export const ErrorPanel = styled.span`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const Error = styled.span`
  color: red;
  font-size: 150%;

  & h1 {
    font-size: 110%;
  }
`;

export const ErrorButtons = styled.span`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

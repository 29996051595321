import styled from "styled-components";

export const StyledWrapTags = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  padding: 20px;
`;

export const StyledContentModal = styled.div`
  & > div {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    gap: 40px;
  }
`;

import React, { useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import { DND_ITEM_TYPES } from "~/src/consts";

import { StyledDropZone, StyledForbid } from "./styles";

const DropZone = ({ onHoverEnter, onDropMember, isForbidden, children }) => {
  const [hoveringMemberId, setHoveringMemberId] = useState(null);

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: DND_ITEM_TYPES.MEMBER,
      drop: member => onDropMember(member),
      hover: member => {
        if (hoveringMemberId !== member.id) {
          setHoveringMemberId(member.id);
        }
      },
      canDrop: () => !isForbidden,
      collect: monitor => ({
        isOver: monitor.isOver()
      })
    }),
    [onDropMember, isForbidden]
  );

  useEffect(() => {
    if (!isOver) {
      setHoveringMemberId(null);
    } else {
      onHoverEnter(hoveringMemberId);
    }
  }, [isOver]);

  return (
    <div
      ref={drop}
      style={{
        ...(isForbidden ? StyledForbid : {}),
        ...StyledDropZone
      }}
    >
      {children}
    </div>
  );
};

export default DropZone;

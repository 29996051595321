import React from "react";
import moment from "moment-timezone";
import {
  Experience,
  Timeline,
  TimeDetails,
  EmptyStateText,
  SimpleDivWrapper,
  BoldText
} from "./member-experience.styles";
import { Scrollable } from "../member-documents/member-documents.styles";

const MemberExperience = props => (
  <Scrollable>
    {props.memberExperiences && props.memberExperiences.length > 0 ? (
      <Timeline>
        {props.memberExperiences.map((xp, index) => {
          const xpPeriod = xp.isCurrentRole
            ? moment().diff(xp.startDate, "months")
            : moment(xp.endDate).diff(xp.startDate, "months");
          return (
            <Experience withPadding key={index}>
              <TimeDetails>
                <SimpleDivWrapper>
                  {moment(xp.startDate).format("MMM YYYY")}
                </SimpleDivWrapper>
                <SimpleDivWrapper>to</SimpleDivWrapper>
                <SimpleDivWrapper>
                  {xp.isCurrentRole
                    ? "Present"
                    : moment(xp.endDate).format("MMM YYYY")}
                </SimpleDivWrapper>
                <SimpleDivWrapper isMonthDifference>
                  ({xpPeriod > 0 ? `${xpPeriod} months` : "less than a month"})
                </SimpleDivWrapper>
              </TimeDetails>
              <SimpleDivWrapper isDetailsWrapper>
                <SimpleDivWrapper>
                  <BoldText>Role: </BoldText> {xp.role}
                </SimpleDivWrapper>
                <SimpleDivWrapper>
                  <BoldText>Venue: </BoldText> {xp.venue}
                </SimpleDivWrapper>
                {/* {xp.supervisor &&
                  <SimpleDivWrapper>
                    <BoldText>Supervisor: </BoldText>
                    {xp.supervisor}
                  </SimpleDivWrapper>
                }
                {xp.supervisorEmail &&
                  <SimpleDivWrapper>
                    <BoldText>Supervisor&apos;s email: </BoldText>
                    {xp.supervisorEmail}
                  </SimpleDivWrapper>
                } */}
              </SimpleDivWrapper>
            </Experience>
          );
        })}
      </Timeline>
    ) : (
      <EmptyStateText>
        It looks like this member has not added any experience yet. Once they
        do, the details will be shown here.
      </EmptyStateText>
    )}
  </Scrollable>
);

export default MemberExperience;

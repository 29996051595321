import { colors, iconNew } from "@teamrota/rota-design";
import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";

import { ACCOUNT_TIMEZONE, SHIFT_TYPES } from "~/src/consts";
import {
  PlusIcon,
  StyledOpenShiftEmptyCard
} from "../../../GridCard/grid-card.styles";

import { OpenShiftDailyCard } from "../../OpenShiftDailyCard/open-shift-daily-card";
import {
  StyledBackButton,
  StyledCreateShiftContainer,
  StyledDailyView,
  StyledDayViewShiftsContainer,
  StyledDot,
  StyledOpenShiftsRow,
  StyledScrollContainer,
  StyledScrollControls
} from "~/src/containers/scheduler/OpenShifts/OpenShiftTableRow/DailyView/daily-view.styles";
import { getDefaultShiftStartTime } from "../utils";

const { ChevronLeft, ChevronRight } = iconNew;

const DailyView = ({
  selectedDayIndex,
  unassignedShifts,
  setSelectedDayIndex,
  getInitialShiftData,
  onPressShiftCell,
  assignMemberLoading
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [lastDraggedShiftId, setLastDraggedShiftId] = useState(null);

  const shiftsPageRef = useRef();
  const shiftsPageWidth = shiftsPageRef?.current?.clientWidth || 0;

  const dayShifts =
    selectedDayIndex !== null && unassignedShifts?.[selectedDayIndex]?.shifts
      ? unassignedShifts[selectedDayIndex].shifts.filter(
          shift => shift.type !== SHIFT_TYPES.NIGHT
        )
      : [];

  const nightShifts =
    selectedDayIndex !== null && unassignedShifts?.[selectedDayIndex]?.shifts
      ? unassignedShifts[selectedDayIndex].shifts.filter(
          shift => shift.type === SHIFT_TYPES.NIGHT
        )
      : [];

  const unassignedDate =
    selectedDayIndex !== null && unassignedShifts?.[selectedDayIndex]?.date;

  const unassignedDayStart =
    unassignedDate &&
    moment.tz(unassignedDate, ACCOUNT_TIMEZONE).startOf("day");

  const unassignedDayEnd = unassignedDayStart.endOf("day");

  const isDayInFuture = unassignedDayEnd && unassignedDayEnd.isAfter(moment());

  const getNumberOfpages = () =>
    Math.floor(Math.max(dayShifts.length, nightShifts.length) / 5) + 1;

  const nextPage = () => {
    const nextPageIndex =
      currentPage === getNumberOfpages() - 1 ? 0 : currentPage + 1;

    setCurrentPage(nextPageIndex);
  };

  const prevPage = () => {
    const prevPageIndex =
      currentPage === 0 ? getNumberOfpages() - 1 : currentPage - 1;

    setCurrentPage(prevPageIndex);
  };

  useEffect(() => {
    setCurrentPage(0);
  }, [selectedDayIndex]);

  return (
    <StyledDailyView>
      <StyledBackButton onClick={() => setSelectedDayIndex(null)} />

      {isDayInFuture && (
        <StyledCreateShiftContainer>
          <StyledOpenShiftEmptyCard
            lineHeight="4px"
            onClick={() =>
              unassignedDayStart &&
              getInitialShiftData(
                selectedDayIndex,
                getDefaultShiftStartTime(unassignedDayStart)
              )
            }
          >
            <PlusIcon name="PLUS" size="small" color={colors.rotaOrange} />
          </StyledOpenShiftEmptyCard>
        </StyledCreateShiftContainer>
      )}

      <StyledDayViewShiftsContainer ref={shiftsPageRef}>
        <StyledScrollContainer
          shiftsPageWidth={shiftsPageWidth}
          pageNumber={currentPage}
        >
          <StyledOpenShiftsRow>
            {dayShifts.map(shift => (
              <OpenShiftDailyCard
                key={shift.id}
                shift={shift}
                onPressShiftCell={onPressShiftCell}
                assignMemberLoading={assignMemberLoading}
                lastDraggedShiftId={lastDraggedShiftId}
                setLastDraggedShiftId={setLastDraggedShiftId}
              />
            ))}
          </StyledOpenShiftsRow>

          <StyledOpenShiftsRow>
            {nightShifts.map(shift => (
              <OpenShiftDailyCard
                key={shift.id}
                shift={shift}
                onPressShiftCell={onPressShiftCell}
                assignMemberLoading={assignMemberLoading}
                lastDraggedShiftId={lastDraggedShiftId}
                setLastDraggedShiftId={setLastDraggedShiftId}
              />
            ))}
          </StyledOpenShiftsRow>
        </StyledScrollContainer>

        {getNumberOfpages() > 1 && (
          <StyledScrollControls>
            <ChevronLeft onClick={prevPage} />
            {Array(getNumberOfpages())
              .fill(null)
              .map((_, i) => (
                <StyledDot
                  isActive={currentPage === i}
                  key={i}
                  onClick={() => setCurrentPage(i)}
                />
              ))}
            <ChevronRight onClick={nextPage} />
          </StyledScrollControls>
        )}
      </StyledDayViewShiftsContainer>
    </StyledDailyView>
  );
};

export default DailyView;

import { RotaButtonIcon } from "@teamrota/rota-design";
import React, { useState } from "react";
import {
  StyleSubvenueCard,
  SubvenueCardButtonsContainer,
  SubvenueCardName,
  SubvenueCardDetails
} from "./subvenues.styles";
import { useDeleteSubvenue } from "./graphql/use-delete-subvenue";
import { DeleteConfirmation } from "./DeleteConfirmation";

const SubvenueCard = ({ subvenue, openSubvenueEditModal }) => {
  const [isShowDeleteConfirmation, setIsShowDeleteConfirmation] = useState(
    false
  );

  const { deleteSubvenue } = useDeleteSubvenue({
    onError: error => {
      throw new Error(error);
    }
  });

  const handleDelete = () => {
    deleteSubvenue({
      variables: {
        id: subvenue.id
      }
    });
  };

  return (
    <StyleSubvenueCard>
      <SubvenueCardName>{subvenue.name}</SubvenueCardName>
      <SubvenueCardDetails>
        <div>{subvenue.integraId}</div>
        <div>{subvenue.costCode}</div>
        <div>{subvenue.mmCode}</div>
      </SubvenueCardDetails>
      <SubvenueCardButtonsContainer>
        <RotaButtonIcon
          icon="delete"
          onClick={() => setIsShowDeleteConfirmation(true)}
        />
        <RotaButtonIcon
          icon="edit"
          onClick={() => openSubvenueEditModal(subvenue)}
        />
      </SubvenueCardButtonsContainer>

      {isShowDeleteConfirmation && (
        <DeleteConfirmation
          handleDelete={handleDelete}
          setIsShowDeleteConfirmation={setIsShowDeleteConfirmation}
        />
      )}
    </StyleSubvenueCard>
  );
};

export default SubvenueCard;

import React from "react";
import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";

import { ZonedDate } from "@teamrota/rota-common";
import { Role } from "@teamrota/authlib";
import { RotaSwitch } from "@teamrota/rota-design";

import useAuth from "~/src/auth/hooks/use-auth";
import { createVenueOptions } from "~/src/containers/modals/utils.js";
import MemberStateInput from "./member-state-input";
import { Field, Label, Input, Select, Row, StyledErrorMessage } from "./styles";

const AdditionalInformation = ({
  errors,
  register,
  watch,
  control,
  initialValues,
  user,
  upcomingBookingsCount,
  inEditMode,
  setMemberState,
  memberState,
  isRightToWorkValid,
  isAllowedToViewMemberDetails
}) => {
  const auth = useAuth();
  const venues = user?.account?.venues || [];
  const venueOptions = createVenueOptions(venues);
  const startDate = watch("startDate");
  const isAllowedToViewMemberState = auth.hasRole(Role.MEMBERS_STATE);

  return (
    <>
      {(isAllowedToViewMemberDetails || isAllowedToViewMemberState) && (
        <p>Additional Information</p>
      )}
      <Row>
        {isAllowedToViewMemberDetails && (
          <Field>
            <Label>Home Venue</Label>
            <Controller
              control={control}
              name="homeVenue"
              render={({ field }) => (
                <Select
                  defaultValue={initialValues?.venueId}
                  disabled={!inEditMode}
                  onChange={e => {
                    field.onChange(e?.target?.value);
                  }}
                >
                  {/* value 0 is used to indicate removal of venue on backend */}
                  <option style={{ color: "black" }} value="0"></option>
                  {venueOptions?.map(obj => (
                    <option
                      style={{ color: "black" }}
                      key={obj.value}
                      value={obj?.value}
                    >
                      {obj?.label}
                    </option>
                  ))}
                </Select>
              )}
            />
            <StyledErrorMessage>{errors.homeVenue?.message}</StyledErrorMessage>
          </Field>
        )}
        {isAllowedToViewMemberState && (
          <Field>
            <Label>Member state</Label>
            <MemberStateInput
              upcomingBookingsCount={upcomingBookingsCount}
              inEditMode={
                auth.hasRole(Role.MEMBERS_STATE_EDIT) ? inEditMode : false
              }
              setMemberState={setMemberState}
              memberState={memberState}
              isRightToWorkValid={isRightToWorkValid}
            />
            <StyledErrorMessage>
              {errors.memberState?.message}
            </StyledErrorMessage>
          </Field>
        )}
      </Row>
      {isAllowedToViewMemberDetails && (
        <>
          <Row>
            <Field>
              <Controller
                control={control}
                {...register("isEligibleForRebook")}
                render={({ field }) => (
                  <RotaSwitch
                    checked={field.value}
                    onChange={checked => field.onChange(checked)}
                    label={"Eligible for rebook"}
                    disabled={!inEditMode}
                  />
                )}
              />
              <StyledErrorMessage>{errors.notes?.message}</StyledErrorMessage>
            </Field>
            <Field>
              <Label>Internal staff ID</Label>
              <Input
                {...register("internalId")}
                placeholder={initialValues?.internalId}
                disabled={!inEditMode}
              />
              <StyledErrorMessage>
                {errors.internalId?.message}
              </StyledErrorMessage>
            </Field>
          </Row>
          <Row>
            <Field>
              <Label>Staff area</Label>
              <Input
                {...register("staffArea")}
                placeholder={initialValues?.staffArea}
                disabled={!inEditMode}
              />
              <StyledErrorMessage>
                {errors.staffArea?.message}
              </StyledErrorMessage>
            </Field>
          </Row>
          <Row>
            <Field>
              <Label>Start date</Label>
              <Controller
                control={control}
                name="date-input"
                {...register("startDate")}
                render={({ field }) => (
                  <DatePicker
                    disabled={!inEditMode}
                    placeholderText={initialValues?.startDate || "Start date"}
                    onChange={date =>
                      field.onChange(ZonedDate.fromSelectedDate(date))
                    }
                    selected={field.value}
                    showYearDropdown
                    adjustDateOnChange
                    dateFormat="dd/MM/yyyy"
                    customInput={<Input />}
                  />
                )}
              />
              <StyledErrorMessage>
                {errors.startDate?.message}
              </StyledErrorMessage>
            </Field>
            <Field>
              <Label>End date</Label>
              <Controller
                control={control}
                name="date-input"
                {...register("endDate", {
                  validate: endDate => {
                    // endDate is permitted to be blank
                    if (endDate === null) {
                      return;
                    }

                    return (
                      moment(endDate).isSameOrAfter(
                        startDate || new ZonedDate()
                      ) || "End date must be after start date"
                    );
                  }
                })}
                render={({ field }) => (
                  <DatePicker
                    disabled={!inEditMode}
                    placeholderText={initialValues?.endDate || "End date"}
                    onChange={date =>
                      field.onChange(ZonedDate.fromSelectedDate(date))
                    }
                    selected={field.value}
                    showYearDropdown
                    adjustDateOnChange
                    dateFormat="dd/MM/yyyy"
                    customInput={<Input />}
                  />
                )}
              />
              <StyledErrorMessage>{errors.endDate?.message}</StyledErrorMessage>
            </Field>
          </Row>
        </>
      )}
    </>
  );
};
export default AdditionalInformation;

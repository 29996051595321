import React, { useState } from "react";
import { CSVLink } from "react-csv";

import {
  ModalHeader,
  RotaCheckbox,
  RotaDropdown,
  RotaNewModal,
  iconNew
} from "@teamrota/rota-design";

import { POINT_OF_SCALE_OPTIONS, RATE_TYPE_OPTIONS } from "../consts";
import { makeData } from "./csv";
import { getFilename } from "../util";

import Money from "../Money";
import RuleDescription from "../RuleDescription";
import RuleName from "../RuleName";

import { usePayRateCard } from "./graphql/use-pay-rate-card";

import {
  ContentArea,
  CheckBoxContainer,
  DropDownContainer,
  InputRow,
  OptionsButton,
  Placeholder,
  Key
} from "./styles";

const { Cancel } = iconNew;

interface RateCardModalProps {
  id: string;
  payrollRateCards: any[];
  onClose: () => void;
}

const RateCardModal: React.FC<RateCardModalProps> = ({
  id,
  payrollRateCards,
  onClose
}) => {
  const [rateType, setRateType] = useState<string | undefined>(undefined);
  const [pos, setPoS] = useState<string | undefined>(undefined);
  const [isShowWorking, setShowWorking] = useState(false);

  const { entries, loading } = usePayRateCard({
    id,
    rateType,
    pointOfScale: pos
  });

  return (
    <RotaNewModal
      size={"medium"}
      onClose={onClose}
      header={
        <ModalHeader
          title={`Rate Card`}
          endAction={
            <OptionsButton onClick={onClose}>
              <Cancel />
            </OptionsButton>
          }
        />
      }
    >
      <RuleDescription id={id} payrollRateCards={payrollRateCards} />

      <InputRow>
        <DropDownContainer width={210}>
          <RotaDropdown
            id="rateType"
            label={"Rate Type"}
            placeholder="Choose..."
            options={RATE_TYPE_OPTIONS}
            value={rateType}
            onChange={value => setRateType(value)}
            width="120px"
            isError={false}
            errorMessage={""}
          />
        </DropDownContainer>

        <DropDownContainer width={250}>
          <RotaDropdown
            id="pointofScale"
            label={"Point of Scale"}
            placeholder="Choose..."
            options={POINT_OF_SCALE_OPTIONS}
            value={pos}
            onChange={value => setPoS(value)}
            width="120px"
            isError={false}
            errorMessage={""}
          />
        </DropDownContainer>

        <CheckBoxContainer>
          <RotaCheckbox
            label="Show Working"
            onClick={e => setShowWorking(e.target.checked)}
            isChecked={isShowWorking}
          />
        </CheckBoxContainer>

        <Key>
          {entries && !loading && (
            <CSVLink
              filename={"rate-card-" + getFilename(id, payrollRateCards)}
              data={makeData(entries, rateType)}
            >
              Download as CSV
            </CSVLink>
          )}
        </Key>
      </InputRow>

      <ContentArea>
        <Placeholder isShow={!entries && !loading}>
          Please choose options
        </Placeholder>

        <Placeholder isShow={loading}>Loading...</Placeholder>

        {entries && (
          <table>
            <thead>
              <tr>
                <th>Rules</th>
                <th>
                  {isShowWorking && "Calculation"}
                  {!isShowWorking && <>TOTAL {rateType} / hour</>}
                </th>
              </tr>
            </thead>

            <tbody>
              {entries?.map?.((entry: any) => {
                return (
                  <>
                    <tr>
                      <td>
                        <RuleName
                          name={entry.rule}
                          id={id}
                          payrollRateCards={payrollRateCards}
                        />
                      </td>

                      <td>
                        {isShowWorking && (
                          <table>
                            {entry.appliedRates.map((rate: any, i: number) => (
                              <tr>
                                <td>&nbsp; {i === 0 ? "" : "+"}</td>
                                <td>
                                  <RuleName
                                    name={rate.name}
                                    isRate
                                    id={id}
                                    payrollRateCards={payrollRateCards}
                                  />
                                </td>
                                <td>
                                  {typeof rate.description === "number" && (
                                    <Money value={rate.description} />
                                  )}

                                  {typeof rate.description !== "number" &&
                                    rate.description}
                                </td>
                              </tr>
                            ))}

                            <tfoot>
                              <tr>
                                <td>&nbsp; =</td>
                                <td>TOTAL {rateType} / hour</td>
                                <td>
                                  <Money value={entry.calculatedRate} />
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        )}

                        {!isShowWorking && (
                          <Money value={entry.calculatedRate} />
                        )}
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        )}
      </ContentArea>
    </RotaNewModal>
  );
};

export default RateCardModal;

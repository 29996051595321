import React, { Component } from "react";
import Icon from "~/src/components/icon";
import Modal from "~/src/components/modal";
import { POOL_TYPES } from "@teamrota/rota-common";
import { formatDateMonthYear } from "~/src/utils/formatting";
import ModalContent from "~/src/containers/modals/components/modal-content";
import getMemberRelationships from "../../graphql/get-member-relationships";
import {
  IconWrapper,
  IconContainer,
  RelationshipContainer
} from "./relationships.styles";

const POOL_LABEL = {
  BLACKLISTED: "Unsuitable",
  FAVOURITED: "Favourited"
};

@getMemberRelationships // eslint-disable-next-line react/prefer-stateless-function
export default class MemberRelationshipModal extends Component {
  props;

  render() {
    const { props } = this;
    return (
      <Modal
        isOpen={props.isOpen}
        onClose={props.onClose}
        isLoading={props.isLoading}
      >
        <ModalContent title="Favourites & Unsuitable">
          {(props.relationships || []).reduce(
            (arr, rel) =>
              rel.poolType !== POOL_TYPES.RECOMMENDED
                ? [
                    ...arr,
                    <RelationshipContainer key={rel.id}>
                      <IconContainer poolType={rel.poolType}>
                        <IconWrapper>
                          {rel.poolType === POOL_TYPES.FAVOURITED && (
                            <Icon
                              name={Icon.names.FAVOURITE_HEART}
                              color={Icon.colors.WHITE}
                              size={9}
                            />
                          )}
                        </IconWrapper>
                      </IconContainer>
                      <div>
                        {POOL_LABEL[rel.poolType]} by &nbsp;
                        {rel.account.accountName} on{" "}
                        {formatDateMonthYear(rel.updatedAt)}
                      </div>
                    </RelationshipContainer>
                  ]
                : arr,
            []
          )}
        </ModalContent>
      </Modal>
    );
  }
}

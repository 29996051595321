import React from "react";
import Button from "~/src/components/button";
import {
  Container,
  Title,
  SubHeader,
  Body,
  ButtonWrapper
} from "./policy.styles";

export default function PolicyPage(props) {
  const {
    query: { minimumShiftPercentage, cancellationPeriod, minimumShiftLength }
  } = props.router.location;

  if (!minimumShiftPercentage || !cancellationPeriod || !minimumShiftLength) {
    return (
      <Container>
        <h1>No Policy found</h1>
      </Container>
    );
  }
  return (
    <Container>
      <Title>Policies</Title>
      <SubHeader>Post shift</SubHeader>
      <Body>
        If the shift length is less than {minimumShiftLength} hours (the minimum
        shift length), then you will be charged for {minimumShiftLength} hours.
      </Body>
      <SubHeader>Reduce shift length</SubHeader>
      <Body>
        If you reduce the shift length to less than {minimumShiftLength} hours
        or less than {minimumShiftPercentage}% of the original shift length,
        then you will be charged for a minimum {minimumShiftLength} hours or{" "}
        {minimumShiftPercentage}% of the shift length per member accepted,
        whichever is greater.
      </Body>
      <SubHeader>Cancelling or editing a shift</SubHeader>
      <Body>
        If you cancel a shift within {cancellationPeriod} hours of the scheduled
        start time of the shift then you will be charged for a minimum of{" "}
        {minimumShiftLength} hours or {minimumShiftPercentage}% of the original
        shift length per member accepted, whichever is greater.
      </Body>
      <ButtonWrapper>
        <Button onClick={() => window.close()}>Close</Button>
      </ButtonWrapper>
    </Container>
  );
}

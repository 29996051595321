import { COLORS, flexCentered } from "~/src/styles/config.style";

export const styles = {
  alertsContainer: {
    fontWeight: "bold",
    margin: "31px 0 18px 0"
  },
  button: {
    backgroundColor: `${COLORS.MAIN_ORANGE}`,
    color: "white",
    width: "174px",
    height: "40px",
    borderRadius: "20px"
  },
  centered: {
    ...flexCentered
  },
  checkboxRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    paddingBottom: "53px"
  }
};

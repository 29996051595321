import { useState, useCallback } from "react";
import { useMutation } from "@apollo/client";
import { CREATE_UPDATE_TAGS } from "../graphql/create-update-tags";

const useTags = () => {
  const [venueTags, setVenueTags] = useState([]);
  const [selectedVenueTag, setSelectedVenueTag] = useState(null);
  const [rolesWithTags, setRolesWithTags] = useState([]);

  const [createOrUpdateVenueTags] = useMutation(CREATE_UPDATE_TAGS, {
    refetchQueries: ["getVenues", "getVenueOwnerExclusionsAndTags"]
  });

  const createOrUpdateTags = useCallback(
    (partnerId, venueId, tagIds, roleWithTags) =>
      createOrUpdateVenueTags({
        variables: {
          partnerId,
          venueId,
          tagIds,
          roleWithTags
        }
      }),
    [createOrUpdateVenueTags]
  );

  return {
    venueTags,
    setVenueTags,
    selectedVenueTag,
    setSelectedVenueTag,
    rolesWithTags,
    setRolesWithTags,
    createOrUpdateTags
  };
};

export default useTags;

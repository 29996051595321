import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";

const GET_RELATED_MEMBER = gql`
  query getMember($selectedAccountId: ID, $id: ID!, $partnerId: ID) {
    account(sourceAccountId: $selectedAccountId) {
      member(id: $id) {
        id
        firstName
        lastName
        photo
        state
        isAsleep
        excludedVenueIds(partnerId: $partnerId, memberId: $id)

        relationship {
          poolType
        }
      }
    }
  }
`;

export const useGetRelatedMember = ({
  assignedMemberId,
  selectedAccountId,
  partnerId
}) => {
  const { data, loading, error } = useQuery(GET_RELATED_MEMBER, {
    skip: !assignedMemberId,
    variables: {
      id: assignedMemberId,
      selectedAccountId,
      partnerId
    },
    fetchPolicy: "cache-first",
    onError: error => {
      throw error;
    }
  });

  return {
    member: data?.account?.member,
    loading,
    error
  };
};

import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

import { Role } from "@teamrota/authlib";

import {
  RotaButtonNew,
  RotaSearchInput,
  RotaButtonIcon,
  iconNew
} from "@teamrota/rota-design";

import useAuth from "~/src/auth/hooks/use-auth";
import HasRole from "~/src/containers/has-role";

import {
  Wrapper,
  HeaderWrapper,
  Box,
  Heading,
  Title,
  Actions,
  Subheader,
  SubheaderWrapper
} from "~/src/components/payroll/style";

import JsonEditModal from "~/src/containers/payroll/components/JsonEditModal";

import Table from "./Table";

import { useCreatePayrollScale } from "./graphql/use-create-payroll-scale";
import { useValidatePayrollScale } from "./graphql/use-validate-payroll-scale";
import { useUpdatePayrollScale } from "./graphql/use-update-payroll-scale";

import { usePayrollScales } from "./graphql/use-payroll-scales";

const { Filter, ChevronLeft } = iconNew;

const ExampleJsonInput = JSON.stringify(
  {
    name: "Name",

    config: [
      {
        name: "BASE",
        amount: [
          null,
          16.53,
          17.23,
          18.1,
          18.74,
          19.38,
          20.03,
          20.69,
          21.36,
          22.02,
          22.69,
          23.37,
          23.82
        ]
      }
    ]
  },
  null,
  2
);

const PayScale = () => {
  const history = useHistory();
  const params = useParams() as any;
  const auth = useAuth();

  const canEdit = auth.hasRole(Role.PAYROLL_EDIT);

  const { createPayrollScale } = useCreatePayrollScale();
  const { validatePayrollScale } = useValidatePayrollScale();
  const { updatePayrollScale } = useUpdatePayrollScale();

  const { payrollScales } = usePayrollScales("");

  const [isShowCreateModal, setIsShowCreateModal] = useState(false);
  const [selectedOpenId, setSelectedOpenId] = useState<string>("");

  useEffect(() => {
    if (params.id !== undefined) {
      if (payrollScales.find((p: any) => p.id === params.id)) {
        setSelectedOpenId(params.id);
      }
    }
  }, [params, payrollScales]);

  const handleOpenScale = (id: string) => setSelectedOpenId(id);

  const handleClose = () => {
    setSelectedOpenId("");
    history.push("/payroll/pay-scales");
  };

  return (
    <Wrapper>
      <HeaderWrapper>
        <RotaSearchInput onChange={() => {}} />
        <HasRole role={Role.PAYROLL_EDIT}>
          <RotaButtonNew onClick={() => setIsShowCreateModal(true)}>
            Create
          </RotaButtonNew>
        </HasRole>
      </HeaderWrapper>
      <SubheaderWrapper>
        <RotaButtonIcon onClick={() => history.push("/payroll")}>
          <ChevronLeft />
        </RotaButtonIcon>
        <Subheader>Pay scale</Subheader>
      </SubheaderWrapper>
      <Box>
        <Heading>
          <Title>{`All ${payrollScales?.length} records`}</Title>
          <Actions>
            <RotaButtonIcon onClick={() => {}}>
              <Filter />
            </RotaButtonIcon>
          </Actions>
        </Heading>

        <Table
          payrollScales={payrollScales}
          handleOpenScale={handleOpenScale}
          canEdit={canEdit}
        />
      </Box>

      {isShowCreateModal && (
        <JsonEditModal
          canEdit={canEdit}
          onSave={async ({ name, config }) => {
            await createPayrollScale({ name, config });
            setIsShowCreateModal(false);
          }}
          onValidate={({ name, config }) =>
            validatePayrollScale({ name, config })
          }
          onClose={() => setIsShowCreateModal(false)}
          value={ExampleJsonInput}
          title="Create pay scale"
        />
      )}

      {selectedOpenId && (
        <JsonEditModal
          canEdit={canEdit}
          onSave={async ({ name, config }) => {
            await updatePayrollScale({ id: selectedOpenId, name, config });
            handleClose();
          }}
          onValidate={({ name, config }) =>
            validatePayrollScale({ name, config })
          }
          onClose={handleClose}
          value={payrollScales.find(
            ({ id }: { id: string }) => id === selectedOpenId
          )}
          title="Edit pay scale"
        />
      )}
    </Wrapper>
  );
};

export default PayScale;

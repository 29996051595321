import { graphql } from "@apollo/client/react/hoc";
import { gql } from "@apollo/client";

import update from "lodash/fp/update";
import { handleErrors } from "~/src/utils/errors";

const LIMIT = 5;

const MEMBER_SEARCH = gql`
  query getMembersBySearchText(
    $shiftId: ID
    $searchText: String
    $offset: Int!
    $limit: Int!
  ) {
    account {
      id
      shift(id: $shiftId) {
        id
        memberSearch(searchText: $searchText, offset: $offset, limit: $limit) {
          data {
            id
            visibleAt
            state
            member {
              id
              firstName
              lastName
              photo
            }
          }
          offset
          limit
          totalResults
        }
      }
    }
  }
`;

export default graphql(MEMBER_SEARCH, {
  skip: props => !props.shiftId,
  props: handleErrors(({ data: { loading, account, refetch, fetchMore } }) => ({
    limit: LIMIT,
    isLoading: loading,
    members: account?.shift?.memberSearch?.data ?? [],
    offset: account?.shift?.memberSearch?.offset ?? 0,
    totalResults: account?.shift?.memberSearch?.totalResults,
    refetchMembers: refetch,
    fetchMoreMembers: () =>
      fetchMore({
        notifyOnNetworkStatusChange: true,
        variables: {
          offset: account?.shift?.memberSearch?.data?.length ?? 0
        },
        updateQuery: (previousResult, { fetchMoreResult }) =>
          update(
            "account.shift.memberSearch.data",
            current => [
              ...current,
              ...(fetchMoreResult?.account?.shift?.memberSearch?.data ?? [])
            ],
            previousResult
          )
      })
  })),
  options: props => ({
    fetchPolicy: "network-only",
    variables: {
      shiftId: props.shiftId,
      searchText: props.searchText,
      limit: LIMIT,
      offset: props?.account?.shift?.memberSearch?.data?.length ?? 0
    }
  })
});

import React, { useState } from "react";
import { Toggle, Wrapper } from "./ellipsis.styles";

const Ellipsis = ({
  maxLength = 50,
  isExpandable = true,
  children,
  isMediumText,
  className
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const truncateText = () => {
    if (children.length > maxLength) {
      return `${children.substr(0, maxLength)}...`;
    }

    return children;
  };

  if (!children) {
    return null;
  }

  if (children.length <= maxLength) {
    return <div>{children}</div>;
  }

  const text = isExpanded ? children : truncateText();

  return (
    <Wrapper className={className} isMediumText={isMediumText}>
      {text}
      {isExpandable && (
        <Toggle onClick={toggleExpanded}>{isExpanded ? "Less" : "More"}</Toggle>
      )}
    </Wrapper>
  );
};

export default Ellipsis;

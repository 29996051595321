import card from "./card.png";

// Safari needs a specific preview image for dragdrop
// it does not cope well with generating them on complex layouts

const isSafari =
  navigator.userAgent.indexOf("Safari") !== -1 &&
  navigator.userAgent.indexOf("Chrome") === -1;

export default isSafari ? card : null;

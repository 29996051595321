import React from "react";
import moment from "moment-timezone";

import { Role } from "@teamrota/authlib";
import { POOL_TYPES } from "@teamrota/rota-common";
import { iconNew } from "@teamrota/rota-design";

import { AVAILABILITY_TYPES, MEMBER_STATES } from "~/src/consts";
import Stars from "~/src/components/stars";
import MemberPhoto from "~/src/components/member-photo";

import { getLabel } from "~/src/components/StaffZonesPanel/components/MemberCarousel/labels";

import {
  QualifiedAndUnavailable,
  WorkingHoursLimit,
  Conflicted
} from "./issue";

import BottomModal from "./bottom-modal";

import {
  StyledPhotoWrapper,
  StyledEmph,
  StyledHighlight,
  StyledAvail,
  StyledTitle
} from "../styles";

import useAuth from "~/src/auth/hooks/use-auth/index.js";

const { Moon, Sun } = iconNew;

const getStars = member => {
  const memberRating = member?.rating || 4.5;
  return memberRating === null ? 4.5 : memberRating;
};

const formatTime = date => moment(date).format("HH:mm");

const SingleMember = ({
  member,
  roleName,
  dates,
  accountData,
  setConfirmationInput,
  confirmationInput,
  onAdd,
  onCancel
}) => {
  const auth = useAuth();

  const isOverrideNoncomplianceDisabled = !auth.roles.includes(
    Role.OVERRIDE_NONCOMPLIANCE
  );

  const memberName = `${member.firstName?.trim()} ${member.lastName?.trim()}`;
  const label = getLabel(member);

  const conflictTimes = dates
    ?.map(
      ({ startTime, endTime }) =>
        `${formatTime(startTime)} - ${formatTime(endTime)}`
    )
    ?.join(", ");

  const availability = member.memberAvailability?.map(
    ({ startsAt, endsAt, shiftType }) => (
      <>
        {[AVAILABILITY_TYPES.DAY, AVAILABILITY_TYPES.ALL].includes(
          shiftType
        ) && <Sun width={14} height={14} />}{" "}
        {[AVAILABILITY_TYPES.NIGHT, AVAILABILITY_TYPES.ALL].includes(
          shiftType
        ) && <Moon width={14} height={14} />}{" "}
        {formatTime(startsAt)} - {formatTime(endsAt)}
      </>
    )
  );

  // add other conditions for enhanced confirmation
  const mustConfirm = member.isHitWorkingHoursLimit || member.isConflicted;

  const isConfirmed =
    !mustConfirm ||
    confirmationInput?.trim()?.toLowerCase() === memberName?.toLowerCase();

  const isOverLimitError =
    !accountData?.account?.isOverLimitWorkAllowed &&
    member.isHitWorkingHoursLimit;

  const isMemberUnsuitable =
    member.relationship?.poolType === POOL_TYPES.BLACKLISTED;

  const isMemberAvailabilityEnabled =
    member.accounts?.[0].isMemberAvailabilityEnabled;

  const isMemberInactive =
    member.state === MEMBER_STATES.PENDING ||
    member.state === MEMBER_STATES.LOCKED;

  return (
    <>
      <StyledPhotoWrapper>
        <MemberPhoto
          src={member?.photo}
          size="large"
          errorLabelColor={label?.color}
          errorLabel={label?.text}
        />
      </StyledPhotoWrapper>
      {isMemberInactive ? (
        <StyledTitle>
          The member
          <b>{` ${member.firstName} ${member.lastName} `}</b>
          has been set to <b>{member.state}</b>, so cannot be scheduled.
        </StyledTitle>
      ) : (
        <>
          {isOverLimitError ? (
            <>
              <WorkingHoursLimit member={member} memberName={memberName}>
                This member is over their weekly hour working limit, and so
                cannot be booked onto the shift.
              </WorkingHoursLimit>
            </>
          ) : (
            member.isHitWorkingHoursLimit && (
              <>
                <WorkingHoursLimit member={member} memberName={memberName}>
                  <StyledEmph>
                    By overriding, {member.firstName}'s working hours will
                    exceed the limit you set for them this week:
                  </StyledEmph>
                </WorkingHoursLimit>
              </>
            )
          )}
          {member.isConflicted && (
            <Conflicted
              memberName={memberName}
              conflictTimes={conflictTimes}
              member={member}
            />
          )}
          {!member.isQualifiedWithRole && (
            <QualifiedAndUnavailable>
              This member does not have the correct role for this shift.
              <StyledHighlight>{roleName}</StyledHighlight>
            </QualifiedAndUnavailable>
          )}
          {!member.isQualifiedWithTags && (
            <QualifiedAndUnavailable>
              {`The member "${memberName}" does not have the required tags to qualify for this shift.`}
            </QualifiedAndUnavailable>
          )}
          {!member.isInVenueServiceArea && (
            <QualifiedAndUnavailable>
              {`The member "${memberName}" does not belong to a service area that contains this venue.`}
            </QualifiedAndUnavailable>
          )}
          {member.isExcludedFromVenue && (
            <QualifiedAndUnavailable>
              {`The member "${memberName}" was previously excluded from working at the venue.`}
            </QualifiedAndUnavailable>
          )}
          {!isMemberAvailabilityEnabled && member.isUnavailable && (
            <QualifiedAndUnavailable>
              This member is not available for this shift.
              <StyledHighlight>{conflictTimes}</StyledHighlight>
            </QualifiedAndUnavailable>
          )}
          {isMemberAvailabilityEnabled && member.isUnavailable && (
            <QualifiedAndUnavailable memberName={memberName}>
              This member is not available{"  "}{" "}
              {availability === undefined && "for this shift time"}
              {availability?.length == 0 && "for this shift date"}
              {availability?.length > 0 && (
                <>
                  at:{"  "}
                  <StyledHighlight>{conflictTimes}</StyledHighlight>
                  This member has only set availability between:
                  {availability?.map((avail, i) => (
                    <StyledAvail key={i}>{avail}</StyledAvail>
                  ))}
                </>
              )}
            </QualifiedAndUnavailable>
          )}
          {isMemberUnsuitable && (
            <QualifiedAndUnavailable>
              This member was previously marked as unsuitable.
              <StyledHighlight>
                <Stars value={getStars(member)} />
              </StyledHighlight>
            </QualifiedAndUnavailable>
          )}
          {!isOverLimitError && (
            <BottomModal
              handleAdd={onAdd}
              handleCancel={onCancel}
              isConfirmed={isConfirmed}
              mustConfirm={mustConfirm}
              memberName={memberName}
              onChangeInput={({ target }) => setConfirmationInput(target.value)}
              disableOverride={
                isMemberUnsuitable ||
                !member.isInVenueServiceArea ||
                member?.isExcludedFromVenue ||
                isOverrideNoncomplianceDisabled
              }
            />
          )}
        </>
      )}
    </>
  );
};

export default SingleMember;

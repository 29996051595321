import React, { useContext, useState } from "react";

import { RolesContext } from "~/src/containers/settings-page/context/roles-context";
import { SnackContext } from "~/src/containers/settings-page/context/snack-context";
import { RotaSnackBar } from "@teamrota/rota-design";
import { Role } from "@teamrota/authlib";
import useAuth from "~/src/auth/hooks/use-auth";

import AlertDialog from "~/src/containers/settings-page/dialog";
import createUpdateUserTypeTemplate from "~/src/containers/settings-page/graphql/create-custom-user-type";
import { useMutation } from "@apollo/client";
import { DELETE_USER_TYPE_TEMPLATE } from "../../graphql/delete-user-type-template";
import {
  useStyles,
  StyledTextField,
  StyledTextFieldSpan
} from "./permissions-tab-content.styles";

import { TextField } from "@material-ui/core";

const ContainerUtility = ({
  handleUserTypeChange,
  loading,
  setOpenDotMenu,
  setOpenDialog,
  openDialog,
  setOpenChangeDialog,
  openChangeDialog,
  setOpenSaveDialog,
  openSaveDialog,
  changeId,
  setOpenRenameDialog,
  openRenameDialog,
  setOpenDeleteDialog,
  openDeleteDialog,
  createUpdateUserTypeTemplate,
  refetchUserTypeTemplates,
  userTypeTemplates
}) => {
  const {
    roles,
    setRoles,
    selectedUserTypeId,
    setIsEditable,
    setHasChanged,
    setIsSearched
  } = useContext(RolesContext);

  const {
    severity,
    setSeverity,
    setIsColor,
    snackOpen,
    setSnackOpen,
    snackText,
    setSnackText
  } = useContext(SnackContext);

  const auth = useAuth();
  const hasPermission = auth.hasRoles(Role.TYPE_TEMPLATES);

  const classes = useStyles();

  const [userTypeName, setUserTypeName] = useState();
  const [userChangeTypeName, setUserChangeTypeName] = useState();
  const [deleteUserTypeTemplate] = useMutation(DELETE_USER_TYPE_TEMPLATE, {
    refetchQueries: () => ["getUserTypeTemplates"]
  });

  const handleChangeClose = () => {
    setOpenChangeDialog(false);
  };

  const onClickHandlerChange = async () => {
    setHasChanged(false);
    setIsEditable(true);
    handleUserTypeChange(changeId, false);
    handleChangeClose();
  };

  const handleChange = event => {
    setUserTypeName(event.target.value);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const onClickHandler = async () => {
    if (!loading && hasPermission) {
      try {
        await createUpdateUserTypeTemplate({
          name: userTypeName,
          roles: []
        });

        refetchUserTypeTemplates();

        setSeverity("success");
        setSnackText("Created Successfully");
        setSnackOpen(true);
        setUserTypeName("");
        handleDialogClose();
      } catch (error) {
        setSeverity("error");
        setSnackText("Your new User Type was not created, please try again.");
        setSnackOpen(true);
        console.log("create error", error);
      }
    }
  };

  const handleSaveClose = () => {
    setOpenSaveDialog(false);
    setIsSearched("");
  };

  const saveHandler = async () => {
    try {
      await createUpdateUserTypeTemplate({
        id: parseInt(selectedUserTypeId),
        roles: roles
      });
      setHasChanged(false);
      setSeverity("success");
      setIsColor("success");
      setSnackText("Saved Successfully");
      setSnackOpen(true);
      setOpenSaveDialog(false);
      setIsSearched("");
      setIsEditable(true);
    } catch (error) {
      setSeverity("error");
      setIsColor("error");
      setSnackText(
        "Your changes were not saved, check the entered details and try"
      );
      setSnackOpen(false);
      console.log("save error", error);
    }
  };

  const handlerIgnoreChanges = () => {
    setRoles(userTypeTemplates.find(el => el.id == selectedUserTypeId).roles);
    setIsEditable(true);
    setHasChanged(false);
    setOpenSaveDialog(false);
    setIsSearched("");
  };

  const handleRenameClose = () => {
    setUserChangeTypeName("");
    setOpenRenameDialog(false);
    setOpenDotMenu(false);
  };

  const handleNameChange = event => {
    setUserChangeTypeName(event.target.value);
  };

  const renameHandler = async () => {
    try {
      await createUpdateUserTypeTemplate({
        id: parseInt(selectedUserTypeId),
        name: userChangeTypeName,
        roles: roles
      });

      setOpenRenameDialog(false);
      setIsColor("success");
      setSnackText("Renamed Sucessfully");
      setSnackOpen(true);
      setUserChangeTypeName("");
    } catch (err) {
      setIsColor("error");
      setSnackText("Something went wrong. Please try again.");
      setSnackOpen(true);
      console.log(err);
    }
  };

  const handleDeleteClose = () => {
    setOpenDeleteDialog(false);
    setOpenDotMenu(false);
  };

  const deleteHandler = async () => {
    if (hasPermission) {
      try {
        await deleteUserTypeTemplate({
          variables: {
            id: parseInt(selectedUserTypeId)
          }
        });
        setOpenDeleteDialog(false);
        setSnackText("Deleted sucessfully");
        setSnackOpen(true);
      } catch (err) {
        setSnackText(`${err.message}`);
        setSnackOpen(true);
      }
    }
  };

  return (
    <div>
      <RotaSnackBar
        snackOpen={snackOpen}
        severity={severity}
        message={snackText}
        onClose={() => setSnackOpen(false)}
      />
      <AlertDialog
        show={setOpenDialog}
        onHide={handleDialogClose}
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
        handleClose={handleDialogClose}
        onClickHandler={onClickHandler}
        dialogTitle="Do you want to create a new User Type?"
        declineBtnText="Cancel"
        acceptBtnText="Create New"
        isLoading={loading}
        input
      >
        <StyledTextField>
          <StyledTextFieldSpan>Name of User Type</StyledTextFieldSpan>
          <TextField
            id="user-type-name"
            label="Name"
            value={userTypeName}
            className={classes.inputDialog}
            onChange={handleChange}
            variant="outlined"
          />
        </StyledTextField>
      </AlertDialog>
      <AlertDialog
        show={setOpenChangeDialog}
        onHide={handleChangeClose}
        setOpenDialog={setOpenChangeDialog}
        openDialog={openChangeDialog}
        handleClose={handleChangeClose}
        onClickHandler={onClickHandlerChange}
        dialogTitle="You have unsaved changes, are you sure you want to change permission without saving first?"
        declineBtnText="Cancel"
        acceptBtnText="Yes"
      >
        If you click "Yes" you will lose any changes that have been made on the
        permission checklist.
      </AlertDialog>
      <AlertDialog
        show={setOpenSaveDialog}
        onHide={handleSaveClose}
        setOpenDialog={setOpenSaveDialog}
        openDialog={openSaveDialog}
        handleClose={handleSaveClose}
        onClickHandler={saveHandler}
        onClickHandlerIgnore={handlerIgnoreChanges}
        dialogTitle="Do you want to save changes?"
        declineBtnText="Cancel"
        acceptBtnText="Save"
        ignoreBtnText="Don't Save"
      >
        If you click "Don't Save" you will lose any changes that have been made
        on the permission checklist.
      </AlertDialog>
      <AlertDialog
        show={setOpenDeleteDialog}
        onHide={handleDeleteClose}
        setOpenDialog={setOpenDeleteDialog}
        openDialog={openDeleteDialog}
        handleClose={handleDeleteClose}
        onClickHandler={deleteHandler}
        dialogTitle="Delete User Type"
        declineBtnText="Cancel"
        acceptBtnText="Delete"
      >
        This user type will be completed removed from the list. If deleted you
        can no longer reverse the action.
      </AlertDialog>
      <AlertDialog
        show={setOpenRenameDialog}
        onHide={handleRenameClose}
        setOpenDialog={setOpenRenameDialog}
        openDialog={openRenameDialog}
        handleClose={handleRenameClose}
        onClickHandler={renameHandler}
        dialogTitle="Rename Type"
        declineBtnText="Cancel"
        acceptBtnText="Okay"
        input
      >
        <StyledTextField>
          <StyledTextFieldSpan>
            Please enter the new name for the item:
          </StyledTextFieldSpan>
          <TextField
            id="user-type-name"
            label="Name"
            value={userChangeTypeName}
            onChange={handleNameChange}
            variant="outlined"
            className={classes.inputDialog}
          />
        </StyledTextField>
      </AlertDialog>
    </div>
  );
};

export default createUpdateUserTypeTemplate(ContainerUtility);

import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";
import DateInputRaw from "../date-input-raw";

export const Input = styled(DateInputRaw)`
  border: 1px solid ${({ theme }) => theme.neutral.light};
  box-sizing: border-box;
  height: 38px;
  color: ${({ theme }) => theme.text.primary};

  font-size: ${STYLE_CONSTS.FONT_SMALL};
  width: 100%;
  height: 36px;
  border: 1px solid ${({ theme }) => theme.neutral.light};
  border-radius: 5px;
  padding-left: 10px;
  &:focus {
    outline: none;
    color: ${({ theme }) => theme.neutral.main};
    border-color: ${({ theme }) => theme.primary.main};
  }
  &:disabled {
    color: ${({ theme }) => theme.text.secondary};
    background-color: ${({ theme }) => theme.neutral.lighter};
  }
  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.primary.main};
  }
  &::placeholder {
    color: #c0ccda;
  }
`;

export const Wrapper = styled.div`
  > input {
    color: ${({ isError, theme }) =>
      isError ? theme.error.main : theme.text.main};
  }
`;

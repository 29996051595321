import styled from "styled-components";

import { COLORS } from "~/src/styles/config.style";

const styles = {
  textContentWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "15px"
  },
  numberOfMembersWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  numberOfMembersInput: { width: 60 },
  warningText: {
    color: "red"
  }
};

export default styles;

export const AppliedMemberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

export const LoadingWrapper = styled.div`
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const LoadingTitle = styled.div`
  padding-bottom: 5px;
  ${({ isDecision }) => isDecision && `font-size: 12px;`}
`;

export const SubTitle = styled.div`
  font-size: 10px;
`;
export const DecisionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 5px 5px 5px;
  text-align: center;
`;

export const ActionButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding: 10px;
`;

export const ActionButton = styled.div`
  width: 115px;
  height: 20px;
  line-height: 20px;
  border-radius: 5px;
  font-size: 9px;
  text-transform: uppercase;
  text-align: center;
  color: ${({ isDecline }) => (isDecline ? COLORS.RED : COLORS.GREEN)};
  border: 1px solid
    ${({ isDecline }) => (isDecline ? COLORS.RED : COLORS.GREEN)};
  letter-spacing: 1px;
  font-weight: 400;

  &:hover {
    cursor: pointer;
    color: white;
    background-color: ${({ isDecline }) =>
      isDecline ? COLORS.RED : COLORS.GREEN};
  }
`;

export const TitleWrapper = styled.div``;

export const Title = styled.div`
  width: 80%;
  margin: 0 auto;
  text-align: center;
  font-size: 20px;
  font-weight: bolder;
  padding: 25px 0 5px 0;
  border-bottom: solid 1px grey;
`;
export const Text = styled.div`
  text-align: center;
  padding: 12px;
`;

import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { useLazyQuery } from "@apollo/client";

import { Role } from "@teamrota/authlib";

import useAuth from "~/src/auth/hooks/use-auth";
import Loading from "~/src/components/loading";
import ProvideScheduleCalendarHeader from "~/src/containers/provide-schedule/CalendarHeader/calendar-header";
import ShiftCreateModal from "~/src/containers/scheduler/ShiftCreateModal";
import SchedulerGrid from "~/src/containers/scheduler/SchedulerGrid/scheduler-grid";
import { GET_REQUESTER_PROFILE } from "~/src/containers/provide-schedule/EditShiftModal/graphql/GetRequesterProfile";

import getProfile from "~/src/graphql/queries/get-profile/get-profile-query.decorator";
import { useSchedules } from "~/src/graphql/queries/use-schedules";

import {
  postShift,
  cancelPostShift,
  clearBonusState,
  countDraftShifts
} from "~/src/containers/scheduler/reducer";

import {
  CalendarContainer,
  GridWrapper,
  Wrapper,
  LoadingWrapper
} from "~/src/containers/scheduler/scheduler.styles";

import { usePartners } from "~/src/graphql/queries/use-partners.js";
import ShiftEditModal from "./EditShiftModal";

import {
  getprovideScheduleStartDate,
  setprovideScheduleStartDate
} from "./storage";

const ProviderScheduler = ({
  policy,
  initialPostStart,
  postShift,
  countDraftShifts,
  countOfDraftShifts,
  draftShiftIds
}) => {
  const auth = useAuth();

  const isSelfProvider = auth.hasRoles(Role.PROVIDER, Role.REQUESTER);

  const { partners } = usePartners();

  const [isShiftCreateModalOpen, setIsShiftCreateModalOpen] = useState(false);
  const [roleIds, setRoleIds] = useState([]);
  const [venueIds, setVenueIds] = useState([]);
  const [homeVenueIds, setHomeVenueIds] = useState([]);
  const [userIds, setUserIds] = useState([]);
  const [sortBy, setSortBy] = useState("FIRSTNAME");
  const [searchTerm, setSearchTerm] = useState("");
  const [editShiftModalShiftId, setEditShiftModalShiftId] = useState(null);
  const [filteredPartnerIds, setFilteredPartnerIds] = useState([]);
  const [schedule, setSchedule] = useState(null);
  const [startDate, setStartDate] = useState(
    getprovideScheduleStartDate() || moment().startOf("isoWeek")
  );
  const [isShowCancelledShifts, setIsShowCancelledShifts] = useState(false);
  const [isShowCancelledBookings, setIsShowCancelledBookings] = useState(false);
  const [shiftTypes, setShiftTypes] = useState([]);
  const [availableOnDays, setAvailabilityWeekDays] = useState([]);

  const startTime = startDate.format();
  const endTime = startDate
    .clone()
    .add(6, "days")
    .startOf("day")
    .format();

  const getSchedulesProps = {
    startTime,
    endTime,
    homeVenueIds,
    venueIds,
    userIds,
    roleIds,
    sortBy,
    searchTerm,
    partnerIds: filteredPartnerIds,
    isShowCancelledShifts,
    isShowCancelledBookings,
    shiftTypes,
    availableOnDays,
    isSelfProvider
  };

  const {
    schedulesLoading,
    scheduleData,
    refetchSchedules,
    forceRefetchSchedules,
    loadMoreSchedules
  } = useSchedules({
    ...getSchedulesProps,
    skip: !filteredPartnerIds.length
  });

  const [
    getRequesterProfile,
    { loading: requesterProfileLoading, data: requesterProfileData }
  ] = useLazyQuery(GET_REQUESTER_PROFILE);

  useEffect(() => {
    setSchedule(scheduleData?.account?.schedule);
  }, [scheduleData]);

  useEffect(() => {
    setprovideScheduleStartDate({ startDate });
  }, [startDate]);

  const user = requesterProfileData?.requesterProfile;

  const targetAccountId = user?.account?.id;

  const handlePartnerSelect = selectedPartnerIds => {
    selectedPartnerIds.length === 0
      ? setFilteredPartnerIds(partners.map(({ id }) => id))
      : setFilteredPartnerIds(selectedPartnerIds);
  };

  const handleShiftCellPress = async (booking, sourceAccountId) => {
    if (!auth.hasRole(Role.SHIFTS_EDIT)) return;

    await getRequesterProfile({
      variables: {
        userId: sourceAccountId
      }
    });

    setEditShiftModalShiftId(booking);
  };

  const handleOpenShiftCreateModal = ({ startTime, scheduledMember }) => {
    if (!auth.hasRole(Role.SHIFTS_CREATE)) return;
    //this func is also happening in the dashboard for the side menu navigation
    const now = moment();
    const hour = now.hour();
    const minute = now.minute();
    const updatedStartTime = moment(startTime).set({
      hour: parseInt(hour, 10),
      minute: parseInt(minute + 15, 10)
    });

    postShift({
      defaultBreakMinutes: user?.account?.defaultBreakMinutes,
      ...(!!startTime && {
        startTime: updatedStartTime,
        endTime: moment(updatedStartTime)
          .clone()
          .endOf("day")
      }),
      ...(!!scheduledMember && {
        assignedMemberIds: [scheduledMember?.member?.id],
        assignedMemberName: `${scheduledMember?.member?.firstName} ${scheduledMember?.member?.lastName}`,
        assignedMemberRoleIds: scheduledMember?.member?.roles,
        memberType: scheduledMember?.member?.memberType,
        isGridSelectedShift: true
      })
    });

    setIsShiftCreateModalOpen(true);
  };

  return (
    <CalendarContainer>
      {partners && (
        <ProvideScheduleCalendarHeader
          setStartDate={setStartDate}
          startDate={startDate}
          startTime={startTime}
          endTime={endTime}
          setIsShiftCreateModalOpen={handleOpenShiftCreateModal}
          handleCountOfDraftShifts={() => countDraftShifts()}
          countOfDraftShifts={countOfDraftShifts}
          draftShiftIds={draftShiftIds}
          refetchSchedules={refetchSchedules}
          forceRefetchSchedules={forceRefetchSchedules}
          schedulesLoading={schedulesLoading}
          handlePartnerSelect={handlePartnerSelect}
          partners={partners}
          setRoleIds={setRoleIds}
          setVenueIds={setVenueIds}
          setHomeVenueIds={setHomeVenueIds}
          isShowCancelledShifts={isShowCancelledShifts}
          setIsShowCancelledShifts={setIsShowCancelledShifts}
          isShowCancelledBookings={isShowCancelledBookings}
          setIsShowCancelledBookings={setIsShowCancelledBookings}
          setShiftTypes={setShiftTypes}
          setAvailabilityWeekDays={setAvailabilityWeekDays}
          setSelectedUserIds={setUserIds}
          schedule={schedule}
          setSearchTerm={setSearchTerm}
          setSortBy={setSortBy}
          sortBy={sortBy}
        />
      )}

      <Wrapper>
        <GridWrapper>
          {!schedulesLoading && schedule ? (
            <SchedulerGrid
              schedule={schedule}
              refetchSchedules={refetchSchedules}
              startDate={startDate}
              setHomeVenueIds={setHomeVenueIds}
              setRoleIds={setRoleIds}
              venueIds={venueIds}
              setVenueIds={setVenueIds}
              setSearchTerm={setSearchTerm}
              setSortBy={setSortBy}
              onPressGridCell={booking => handleOpenShiftCreateModal(booking)}
              onPressShiftCell={handleShiftCellPress}
              totalMembersCount={schedule.totalMembersCount}
              loadMore={loadMoreSchedules}
              {...getSchedulesProps}
              isProviderScheduler
              isMemberAvailabilityEnabled={
                scheduleData?.account?.isMemberAvailabilityEnabled
              }
            />
          ) : (
            <LoadingWrapper>
              <Loading color="black" />
            </LoadingWrapper>
          )}
        </GridWrapper>
      </Wrapper>

      <ShiftCreateModal
        isOpen={isShiftCreateModalOpen}
        initialPostStart={initialPostStart}
        policy={policy}
        user={user}
        refetchSchedules={refetchSchedules}
        setIsShiftCreateModalOpen={setIsShiftCreateModalOpen}
        getRequesterProfile={getRequesterProfile}
        requesterProfileLoading={requesterProfileLoading}
        isProviderScheduler
        filteredPartners={partners?.filter(p =>
          filteredPartnerIds.includes(p.id)
        )}
      />

      {editShiftModalShiftId && filteredPartnerIds?.length && (
        <ShiftEditModal
          policy={policy}
          shiftId={editShiftModalShiftId}
          onClose={() => {
            setEditShiftModalShiftId(null);
            refetchSchedules();
          }}
          targetAccountId={targetAccountId}
          user={user}
          isProviderScheduler
          partners={partners}
          {...getSchedulesProps}
        />
      )}
    </CalendarContainer>
  );
};

const mapStateToProps = s => ({
  initialPostStart: s.scheduler?.initialPostStart,
  countOfDraftShifts: s.scheduler?.countOfDraftShifts,
  draftShiftIds: s.scheduler?.draftShiftIds
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      postShift,
      clearBonusState,
      cancelPostShift,
      countDraftShifts
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(getProfile(ProviderScheduler));

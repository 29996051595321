import React, { useEffect, useState } from "react";

export const SaveList = ({ list }) => {
  const [downloadLink, setDownloadLink] = useState("");

  const makeTextFile = () => {
    const data = new Blob([list?.join("\n") || ""], { type: "text/plain" });

    // this part avoids memory leaks
    if (downloadLink !== "") window.URL.revokeObjectURL(downloadLink);

    setDownloadLink(window.URL.createObjectURL(data));
  };

  useEffect(() => {
    makeTextFile();
  }, [list]);

  return (
    <a
      // this attribute sets the filename
      download="validationErrors.txt"
      href={downloadLink}
    >
      Download the error file
    </a>
  );
};

export default SaveList;

import { colors } from "@teamrota/rota-design";
import styled from "styled-components";

export const StyledWeeklyView = styled.div`
  background: ${({ theme }) => theme.white};
  display: flex;
  width: 100%;
`;

export const StyledOpenShiftCell = styled.div`
  flex: 1;
  height: 100%;
  position: relative;
  z-index: 1;
  cursor: default;
  border: 0.5px solid ${({ theme }) => theme.background.primary};

  pointer-events: ${({ isBookings, isDayInFuture }) =>
    isBookings || isDayInFuture ? "auto" : "none"};
  background-color: ${({ isDayInFuture }) =>
    isDayInFuture ? colors.white : colors.smokeGrey};
`;

import React, { useEffect, useState } from "react";
import {
  RotaSnackBar,
  iconNew as RotaIcon,
  RotaAccordionItem,
  useAccordion
} from "@teamrota/rota-design";
import ViewVenueExclusions from "./view-venue-exclusions";
import EditVenueExclusions from "./edit-venue-exclusions";
import ViewVenueTags from "./view-venue-tags";
import EditVenueTags from "./edit-venue-tags";
import { useGetVenues } from "../graphql/get-venues-with-exclusions-or-tags";
import { useGetVenueRoles } from "../graphql/get-roles";
import { useCreateUpdateOwner } from "../graphql/create-update-owner";
import useExclusions from "../hooks/use-exclusions";
import useTags from "../hooks/use-tags";
import {
  getFormattedExclusions,
  getRolesWithTagIds,
  getRolesWithTags,
  getVenueTags
} from "../utils";

import {
  Header,
  Heading,
  VenueName,
  StyledSaveButton,
  StyledRotaAccordion,
  StyledEditButton
} from "./venues.styles";

const { ChevronLeft } = RotaIcon;

const Venues = ({ venues, partnerId, selectedVenue, setSelectedVenue }) => {
  const [ownerId, setOwnerId] = useState("");
  const [snackBar, setSnackBar] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const { venueOwnerExclusionsAndTags } = useGetVenues(partnerId);
  const { venueRoles } = useGetVenueRoles(partnerId);

  const accordionProps = useAccordion({});

  const { createOrUpdateOwner } = useCreateUpdateOwner();

  const {
    exclusions,
    setExclusions,
    editExclusions,
    createOrUpdateExclusions
  } = useExclusions();

  const {
    venueTags,
    setVenueTags,
    selectedVenueTag,
    setSelectedVenueTag,
    rolesWithTags,
    setRolesWithTags,
    createOrUpdateTags
  } = useTags();

  const venueId = selectedVenue?.id;
  const venueRoleOptions = venueRoles?.map(role => ({ ...role, roleTags: [] }));
  const newExclusion = [{ memberId: "", reason: "", isEditable: true }];

  useEffect(() => {
    const isNewVenue = !!venues.find(({ id }) => id === venueId);

    if (isNewVenue) {
      setOwnerId("");
      setVenueTags([]);
      setExclusions(newExclusion);
      setRolesWithTags(venueRoleOptions);
      setIsButtonDisabled(true);
    }
  }, [selectedVenue]);

  const handleEdit = ({ name, id, owner, excludedMembers, tags, roleTags }) => {
    setSelectedVenue({ id, name });
    setOwnerId(owner?.id);
    setVenueTags(getVenueTags(tags));
    setSelectedVenueTag(null);

    if (excludedMembers.length) setExclusions(excludedMembers);
    else setExclusions(newExclusion);

    if (roleTags.length)
      setRolesWithTags(getRolesWithTags(venueRoles, roleTags, tags));
    else setRolesWithTags(venueRoleOptions);

    setIsButtonDisabled(true);
  };

  const handleSave = async () => {
    const formattedExclusions = getFormattedExclusions(exclusions, venueId);
    const tagIds = venueTags?.map(({ value }) => value);
    const roleWithTagIds = getRolesWithTagIds(rolesWithTags, tagIds);

    try {
      !!ownerId && (await createOrUpdateOwner(partnerId, venueId, ownerId));
      if (formattedExclusions.length) {
        await createOrUpdateExclusions(formattedExclusions, partnerId);
      }
      if (tagIds.length || roleWithTagIds.length) {
        await createOrUpdateTags(partnerId, venueId, tagIds, roleWithTagIds);
      }

      setSnackBar({
        open: true,
        message: "Saved successfully.",
        severity: "success"
      });
      setSelectedVenue(null);
    } catch {
      setSnackBar({
        open: true,
        message: "Something went wrong. Please try again.",
        severity: "error"
      });
    }
  };

  return (
    <>
      {!selectedVenue && (
        <StyledRotaAccordion {...accordionProps}>
          {venueOwnerExclusionsAndTags.map(venue => {
            const { name, id, owner, excludedMembers, tags, roleTags } = venue;
            const isExcludedMembers = !!excludedMembers.length;
            const isTags = !!(tags.length || roleTags.length);
            return (
              (owner || isExcludedMembers || isTags) && (
                <RotaAccordionItem key={id} label={name}>
                  <StyledEditButton onClick={() => handleEdit(venue)} />
                  <ViewVenueExclusions
                    owner={owner}
                    excludedMembers={excludedMembers}
                  />
                  <ViewVenueTags
                    venueRoles={venueRoles}
                    venueRoleTags={venue}
                  />
                </RotaAccordionItem>
              )
            );
          })}
        </StyledRotaAccordion>
      )}

      {selectedVenue && (
        <>
          <Header>
            <ChevronLeft onClick={() => setSelectedVenue(null)} />
            <VenueName>{selectedVenue?.name}</VenueName>
          </Header>
          <EditVenueExclusions
            venueId={venueId}
            partnerId={partnerId}
            setSnackBar={setSnackBar}
            ownerId={ownerId}
            setOwnerId={setOwnerId}
            exclusions={exclusions}
            setExclusions={setExclusions}
            {...editExclusions}
            setIsButtonDisabled={setIsButtonDisabled}
          />
          <EditVenueTags
            title="Venue Tags"
            selectedTags={venueTags}
            setSelectedTags={setVenueTags}
            isParentTag={true}
            setSelectedParentTag={setSelectedVenueTag}
            setIsButtonDisabled={setIsButtonDisabled}
          >
            <Heading>Role Tags:</Heading>
            {rolesWithTags?.map(({ roleName, roleId }) => (
              <EditVenueTags
                key={roleId}
                id={roleId}
                title={roleName}
                selectedTags={rolesWithTags}
                setSelectedTags={setRolesWithTags}
                selectedParentTag={selectedVenueTag}
                setIsButtonDisabled={setIsButtonDisabled}
              />
            ))}
          </EditVenueTags>
          <StyledSaveButton disabled={isButtonDisabled} onClick={handleSave}>
            Save
          </StyledSaveButton>
        </>
      )}

      <RotaSnackBar
        snackOpen={snackBar.open}
        onClose={() => setSnackBar({})}
        message={snackBar.message}
        severity={snackBar.severity}
      />
    </>
  );
};

export default Venues;

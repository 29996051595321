import { gql } from "@apollo/client";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import useAuth from "~/src/auth/hooks/use-auth";
import { checkErrors } from "~/src/utils/errors";

export type PayrollRateCardType = {
  id: string;
  account: { id: string; accountName: string };
  partnerAccount: { id: string; accountName: string } | null;
  role: { id: string; name: string } | null;
  venue: { id: string; name: string } | null;
  payrollRate: { id: string; name: string } | null;
  payrollCalculation: { id: string; name: string } | null;
  payrollScale: { id: string; name: string } | null;
  payrollRule: { id: string; name: string } | null;
  allVenues: boolean;
  effectiveFrom: string;
  createdAt: string;
  updatedAt: string;
  updatedBy: { id: string; accountName: string } | null;
};

type dataType = {
  account: {
    payrollRateCards: PayrollRateCardType[];
  };
};

const GET_PAYROLL_RATE_CARDS = gql`
  query payrollRateCards($search: String, $offset: Int!, $limit: Int!) {
    account {
      id
      payrollRateCards(search: $search, offset: $offset, limit: $limit) {
        id
        account {
          id
          accountName
        }
        partnerAccount {
          id
          accountName
        }
        role {
          id
          name
        }
        venue {
          id
          name
        }
        payrollRate {
          id
          name
        }
        payrollCalculation {
          id
          name
        }
        payrollScale {
          id
          name
        }
        payrollRule {
          id
          name
        }
        allVenues
        effectiveFrom
        createdAt
        updatedAt
        updatedBy {
          id
          accountName
        }
      }
    }
  }
`;

const PAGE_SIZE = 200;

export const usePayrollRateCards = (searchText: string) => {
  const auth = useAuth();
  const [isInitialFetch, setIsInitialFetch] = useState(true);
  const [limit, setLimit] = useState(PAGE_SIZE);
  const [offset, setOffset] = useState(0);

  const { loading, data, fetchMore } = checkErrors(
    useQuery(GET_PAYROLL_RATE_CARDS, {
      variables: auth.addVals(GET_PAYROLL_RATE_CARDS, {
        search: searchText,
        offset,
        limit
      }),
      fetchPolicy: isInitialFetch ? "network-only" : "cache-first",
      notifyOnNetworkStatusChange: true
    })
  );

  const payrollRateCards = data?.account?.payrollRateCards ?? [];
  const totalResults = payrollRateCards.length;

  return {
    loading: isInitialFetch && loading,
    loadingMore: !isInitialFetch && loading,
    payrollRateCards,
    hasMore: payrollRateCards.length < totalResults,
    fetchMore: () => {
      if (payrollRateCards.length < totalResults) {
        setIsInitialFetch(false);
        const newOffset = offset + limit;
        setOffset(newOffset);

        fetchMore({
          variables: {
            offset: newOffset,
            limit: PAGE_SIZE
          },
          fetchPolicy: "network-only"
        }).then(({ data }: { data: dataType }) => {
          setLimit(limit + data.account.payrollRateCards.length);
        });
      }
    }
  };
};

import { graphql } from "@apollo/client/react/hoc";
import { gql } from "@apollo/client";
import { getOr, get, flow, cond, isString, slice, stubTrue } from "lodash/fp";

import { addAuthVars } from "@teamrota/authlib";

import { withAuth } from "~/src/auth/hoc";
import { handleErrors } from "~/src/utils/errors";

export const TimesheetBookingFragment = gql`
  fragment TimesheetBookingFragment on booking {
    id
    state
    visibleAt
    ratingOfMember
    ratingOfManager
    showRating
    feedbackForMember
    chargeRate
    metadata
    notes: bookingNotes
    isNoShow
    isWithinProximityAtCheckIn
    checkInMethod

    member {
      id
      internalId
      firstName
      lastName
      annualSalary
      memberType
      photo

      bookingsCount(sourceAccountId: $sourceAccountId) {
        upcomingAccepted
      }

      relationship {
        id
        poolType

        account {
          id
        }
      }
    }

    policy {
      id
      minimumShiftLength
      minimumShiftPercentage
      cancellationPeriod
    }

    result {
      isPolicyApplied
      lengthFinal
    }

    bookingState {
      id
      # TODO: Remove in favour of result
      startFinal
      methodOfCheckIn
      methodOfCheckOut
      endFinal
      breakFinal
      lengthFinal

      ... on bookingState {
        costFinal
      }

      # END: TODO
      startOnShift
      endOnShift
      startOnShiftAt
      endOnShiftAt
      startOnShiftManager
      endOnShiftManager

      startOnApproval
      endOnApproval

      startOnShiftUser {
        id
        firstName
        lastName
      }

      endOnShiftUser {
        id
        firstName
        lastName
      }
    }

    ... on booking {
      bonuses(type: "bonus", hasBookings: true) {
        id
        type: period
        bookings
        amount
      }
    }
    isDayRate
  }
`;

export const GET_TIMESHEET_BOOKINGS = addAuthVars(gql`
  ${TimesheetBookingFragment}
  query getTimesheetBookings(
    $shiftId: ID!
    $limit: Int!
    $offset: Int!
    $sourceAccountId: ID!
  ) {
    account {
      id
      shift(id: $shiftId) {
        id
        numberRequested
        cancelledAt

        ... on shift {
          bookings(
            stateIn: [ACCEPTED, NO_SHOW, SHIFT_CANCELLED, TURNED_AWAY]
            limit: $limit
            offset: $offset
          ) {
            data {
              ...TimesheetBookingFragment
            }
          }
        }
      }
    }
  }
`);

const getShifts = getOr([], "account.shift.bookings.data");
const getIsCancelled = flow(get("account.shift.cancelledAt"), isString);
const getNumberRequested = getOr(0, "account.shift.numberRequested");

export default flow(
  graphql(GET_TIMESHEET_BOOKINGS, {
    props: handleErrors(({ data }) => ({
      isLoadingTimesheetBookings: data.loading,
      // If the shift is cancelled we only want bookings upto the number of the
      // number requested for the shift
      timesheetBookings: cond([
        [getIsCancelled, flow(getShifts, slice(0, getNumberRequested(data)))],
        [stubTrue, getShifts]
      ])(data)
    })),
    options: ({ auth, shifts, openTimesheetId }) => ({
      skip: !openTimesheetId,
      variables: auth.addVals(GET_TIMESHEET_BOOKINGS, {
        sourceAccountId:
          shifts?.find(({ id }) => id === openTimesheetId)?.sourceAccount.id ??
          0,
        shiftId: openTimesheetId || 0,
        offset: 0,
        limit: 1000
      })
    })
  }),
  withAuth
);

import React from "react";
import {
  FooterLegendContainer,
  ItemContainer,
  Label,
  LegendSquare
} from "./styles";
import { COLORS } from "~/src/styles/config.style";

const LEGEND_ITEMS = [
  {
    label: "Published Shifts",
    backgroundColor: "#FFF2E1",
    borderColor: "#FF9300",
    borderStyle: "solid"
  },
  {
    label: "Unpublished Shifts",
    backgroundColor: "white",
    borderColor: "#FF9300",
    borderStyle: "dashed"
  },
  {
    label: "Published Mixed Shifts",
    backgroundColor: COLORS.ROMANTIC,
    borderColor: COLORS.BLAZE_ORANGE,
    borderStyle: "solid"
  },
  {
    label: "Unpublished Mixed Shifts",
    backgroundColor: "white",
    borderColor: "#FFDB21",
    borderStyle: "dashed"
  },
  {
    label: "Cancelled Shifts",
    backgroundColor: `repeating-linear-gradient(
      -60deg,
      ${COLORS.WHITE},
      ${COLORS.WHITE} 2px,
      ${COLORS.WILD_SAND_GREY} 2px,
      ${COLORS.WILD_SAND_GREY} 4px
    )`,
    borderColor: "unset",
    borderStyle: "none"
  },
  {
    label: "Busy (Unavailable times)",
    backgroundColor: `repeating-linear-gradient(
      -60deg,
      ${COLORS.WHITE},
      ${COLORS.WHITE} 2px,
      ${COLORS.LIGHT_YELLOW} 2px,
      ${COLORS.LIGHT_YELLOW} 4px
    )`,
    borderColor: "unset",
    borderStyle: "none"
  }
];

export const FooterLegend = () => (
  <FooterLegendContainer>
    {LEGEND_ITEMS.map((item, index) => (
      <ItemContainer key={index}>
        <LegendSquare
          backgroundColor={item.backgroundColor}
          borderColor={item.borderColor}
          borderStyle={item.borderStyle}
        />
        <Label>{item.label}</Label>
      </ItemContainer>
    ))}
  </FooterLegendContainer>
);

export default FooterLegend;

import { POOL_TYPES } from "@teamrota/rota-common";
import { useTheme } from "styled-components";
import { getColorToRGBA } from "@teamrota/rota-design";

export const getLabel = ({
  accounts,
  isAccepted,
  isAssigned,
  isDeclined,
  isPending,
  isConflicted,
  isLocationConflict,
  isQualifiedWithRole,
  isQualifiedWithTags,
  isUnavailable,
  isHitWorkingHoursLimit,
  relationship
}) => {
  const theme = useTheme();
  const isMemberAvailabilityEnabled =
    accounts?.[0]?.isMemberAvailabilityEnabled;

  if (isAccepted) {
    return {
      text: "Accepted",
      color: getColorToRGBA(theme.success.main, 0.25)
    };
  }

  if (!isAccepted && !isAssigned) {
    if (isDeclined) {
      return {
        text: "Declined",
        color: getColorToRGBA(theme.background.secondary, 0.6)
      };
    }

    if (isPending) {
      return {
        text: "Request Sent",
        color: getColorToRGBA(theme.neutral.light, 0.5)
      };
    }

    if (!isAccepted) {
      // order for these is important see RD-2909
      if (isHitWorkingHoursLimit) {
        return {
          text: "Over Limit",
          color: getColorToRGBA(theme.error.main, 0.5)
        };
      }

      if (!isMemberAvailabilityEnabled && isUnavailable) {
        return {
          text: "Unavailable",
          color: getColorToRGBA(theme.error.main, 0.5)
        };
      }

      if (isConflicted) {
        return {
          text: "Conflicted",
          color: getColorToRGBA(theme.error.main, 0.5)
        };
      }

      if (isLocationConflict) {
        return {
          text: "Location Conflict",
          color: getColorToRGBA(theme.error.main, 0.5)
        };
      }

      if (relationship?.poolType === POOL_TYPES.BLACKLISTED) {
        return {
          text: "Unsuitable",
          color: getColorToRGBA(theme.background.secondary, 0.5)
        };
      }

      if (!isQualifiedWithRole || !isQualifiedWithTags) {
        return {
          text: "Unqualified",
          color: getColorToRGBA(theme.warning.main, 0.5)
        };
      }

      if (isMemberAvailabilityEnabled && !isUnavailable) {
        return {
          text: "Available",
          color: getColorToRGBA(theme.success.main, 0.5)
        };
      }
    }
  }

  return null;
};

import styled from "styled-components";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";
import { colors } from "@teamrota/rota-design";

export const TableRow = styled.div`
  display: flex;
  border-bottom: ${STYLE_CONSTS.SMALL_DIVIDER_BORDER_LIGHT};
  padding: ${STYLE_CONSTS.UNIT_2} 0;
`;

export const BreakInput = styled.input`
  width: 38px;
  height: 32px;
  border: 1px solid ${({ theme }) => theme.neutral.light};
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;

  &:focus {
    outline: none;
  }
  &:disabled {
    color: ${({ theme }) => theme.text.primary};
  }
  &::-webkit-input-placeholder {
    color: ${COLORS.CLOUDY_BLUE};
  }
`;

export const StyledWrapGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr) repeat(2, 0.75fr) 1fr 0.25fr;
  grid-gap: 2px;
  height: 41px;
  background-color: ${({ theme }) => theme.neutral.lighter};
  border-top: 1px solid ${colors.darkGrey};
`;

export const StyledGridColumn = styled.div`
  grid-column-start: ${({ start }) => start};
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutral.main};
  vertical-align: ${({ vertical }) => vertical && "middle"};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledWrapButton = styled.div`
  grid-column-start: 7;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledBookingGridColumn = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr) repeat(2, 0.75fr) 1fr 0.25fr;
  grid-gap: 2px;
  min-height: 65px;
  max-height: 113px;
`;

export const StyledBookingColumn = styled.div`
  grid-column-start: ${({ start }) => start};
  padding-left: ${({ padding }) => padding};
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify};
`;

export const StyledWrapEditMode = styled.div`
  display: grid;
  grid-template-columns: 2fr 0.75fr 0.5fr;
  height: 48px;
`;

export const StyledInput = styled.input`
  border: 1px solid ${({ theme }) => theme.neutral.light};
  border-radius: 4px;
  width: 195px;
  padding-left: 10px;
  font-size: 12px;
  outline: none;
  margin-bottom: 14px;
`;

export const StyledGridColumnPayRates = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 2px;
  height: 41px;
  border-top: 1px solid ${({ theme }) => theme.neutral.lighter};
`;

export const StyledColumnPayRates = styled.div`
  grid-column-start: ${({ start }) => start};
  color: ${({ theme }) => theme.neutral.main};
  font-size: ${({ bold }) => (bold ? "16px" : "14px")};
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledBookingColumnPayRates = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 2px;
  height: 45px;
`;

export const StyledWrapFeedbackRating = styled.div`
  display: grid;
  grid-template-columns: 0.1fr 1fr 0.3fr;
  grid-gap: 5px;
  padding: 0 0 5px 10px;
`;

export const StyledTextArea = styled.textarea`
  grid-column-start: 3;
  border: 1px solid ${({ theme }) => theme.neutral.lighter};
  padding: 5px;
  outline: none;
  margin-right: 34px;
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-top: 16px;
`;

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { GET_AVAILABLE_MEMBER_TYPES } from "./get-account-member-types.decorator";

export const UPDATE_TARGET_ACCOUNT = gql`
  mutation updateTargetAccount(
    $id: ID!
    $defaultMemberType: String
    $isCasualMemberTypeAvailable: Boolean
    $isPermanentMemberTypeAvailable: Boolean
    $isAgencyMemberTypeAvailable: Boolean
    $isContractorLimitedCompanyMemberTypeAvailable: Boolean
    $isContractorLimitedCompanyMemberTypeInfoRequired: Boolean
    $isContractorUmbrellaCompanyMemberTypeAvailable: Boolean
    $isContractorUmbrellaCompanyMemberTypeInfoRequired: Boolean
    $isContractorSelfEmployedMemberTypeAvailable: Boolean
    $isContractorSelfEmployedMemberTypeInfoRequired: Boolean
  ) {
    updateTargetAccount(
      id: $id
      defaultMemberType: $defaultMemberType
      isCasualMemberTypeAvailable: $isCasualMemberTypeAvailable
      isPermanentMemberTypeAvailable: $isPermanentMemberTypeAvailable
      isAgencyMemberTypeAvailable: $isAgencyMemberTypeAvailable
      isContractorLimitedCompanyMemberTypeAvailable: $isContractorLimitedCompanyMemberTypeAvailable
      isContractorLimitedCompanyMemberTypeInfoRequired: $isContractorLimitedCompanyMemberTypeInfoRequired
      isContractorUmbrellaCompanyMemberTypeAvailable: $isContractorUmbrellaCompanyMemberTypeAvailable
      isContractorUmbrellaCompanyMemberTypeInfoRequired: $isContractorUmbrellaCompanyMemberTypeInfoRequired
      isContractorSelfEmployedMemberTypeAvailable: $isContractorSelfEmployedMemberTypeAvailable
      isContractorSelfEmployedMemberTypeInfoRequired: $isContractorSelfEmployedMemberTypeInfoRequired
    ) {
      id
      accountName
    }
  }
`;
export default graphql(UPDATE_TARGET_ACCOUNT, {
  name: "updateTargetAccount",
  props: ({ updateTargetAccount }) => ({
    updateTargetAccount: accountId => values =>
      updateTargetAccount({
        variables: { id: accountId, ...values },
        refetchQueries: [{ query: GET_AVAILABLE_MEMBER_TYPES }]
      })
  })
});

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

export const UPDATE_TARGET_ACCOUNT = gql`
  mutation updateTargetAccount(
    $id: ID!
    $isCasualMemberTypeRatings: Boolean
    $isPermanentMemberTypeRatings: Boolean
    $isAgencyMemberTypeRatings: Boolean
    $isContractorLimitedCompanyMemberTypeRatings: Boolean
    $isContractorUmbrellaCompanyMemberTypeRatings: Boolean
    $isContractorSelfEmployedMemberTypeRatings: Boolean
    $isPayCalculationOnCheckInOutTimes: Boolean
    $isPinAutomateTimesheetApproval: Boolean
  ) {
    updateTargetAccount(
      id: $id
      isCasualMemberTypeRatings: $isCasualMemberTypeRatings
      isPermanentMemberTypeRatings: $isPermanentMemberTypeRatings
      isAgencyMemberTypeRatings: $isAgencyMemberTypeRatings
      isContractorLimitedCompanyMemberTypeRatings: $isContractorLimitedCompanyMemberTypeRatings
      isContractorUmbrellaCompanyMemberTypeRatings: $isContractorUmbrellaCompanyMemberTypeRatings
      isContractorSelfEmployedMemberTypeRatings: $isContractorSelfEmployedMemberTypeRatings
      isPayCalculationOnCheckInOutTimes: $isPayCalculationOnCheckInOutTimes
      isPinAutomateTimesheetApproval: $isPinAutomateTimesheetApproval
    ) {
      id
      accountName
    }
  }
`;
export default graphql(UPDATE_TARGET_ACCOUNT, {
  name: "updateTargetAccount",
  props: ({ updateTargetAccount }) => ({
    updateTargetAccount: values => {
      return updateTargetAccount({
        variables: { ...values },
        refetchQueries: ["getProfile"]
      });
    }
  })
});

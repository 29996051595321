import { useState, useCallback } from "react";
import { useMutation } from "@apollo/client";
import { CREATE_UPDATE_EXCLUSIONS } from "../graphql/create-update-exclusions";
import { DELETE_EXCLUSION } from "../graphql/delete-exclusion";

const useExclusions = () => {
  const [exclusions, setExclusions] = useState([]);

  const refetchQueries = ["getVenues", "getVenueOwnerExclusionsAndTags"];

  const [
    createOrUpdateMemberVenueExclusion
  ] = useMutation(CREATE_UPDATE_EXCLUSIONS, { refetchQueries });

  const [deleteMemberVenueExclusion] = useMutation(DELETE_EXCLUSION, {
    refetchQueries
  });

  const createOrUpdateExclusions = useCallback(
    (memberVenueExclusions, partnerId) =>
      createOrUpdateMemberVenueExclusion({
        variables: {
          memberVenueExclusions,
          partnerId
        }
      }),
    [createOrUpdateMemberVenueExclusion]
  );

  const deleteExclusion = useCallback(
    (id, partnerId) =>
      deleteMemberVenueExclusion({
        variables: {
          id,
          partnerId
        }
      }),
    [deleteMemberVenueExclusion]
  );

  const addExclusion = () => {
    setExclusions(exclusions => {
      return [...exclusions, { memberId: "", reason: "", isEditable: true }];
    });
  };

  const removeExclusion = id => {
    setExclusions(
      exclusions.filter(
        ({ memberId, memberVenueExclusionId }) =>
          memberVenueExclusionId || memberId !== id
      )
    );
  };

  const updateExclusion = (value, index, key) => {
    setExclusions(exclusions => {
      const newExclusions = [...exclusions];
      newExclusions[index][key] = value;

      return newExclusions;
    });
  };

  return {
    exclusions,
    setExclusions,
    createOrUpdateExclusions,
    editExclusions: {
      addExclusion,
      updateExclusion,
      removeExclusion,
      deleteExclusion
    }
  };
};

export default useExclusions;

import React from "react";
import moment from "moment-timezone";

import { Role } from "@teamrota/authlib";

import { BOOKING_STATES } from "~/src/consts";
import Money from "~/src/utils/money";

import HasRole from "~/src/containers/has-role";

import {
  TIME_FORMAT,
  dateRangeToHuman,
  getFormatterMoney
} from "~/src/utils/formatting";
import { some } from "lodash/fp";
import { isNotSameDay } from "~/src/utils/time-utils";
import defaultPhoto from "~/src/images/worker-default-photo.png";
import { isMemberSalariedWorker } from "~/src/utils/money/consts";
import { hoursAndMinutesDisplay } from "~/src/utils/formatting-hours-timesheet";

import {
  Wrapper,
  TableHeader,
  TableRow,
  LightText,
  TableCell,
  MemberDetails,
  BoldText,
  ShiftHead,
  SmallText,
  ShiftDetailsLabel,
  ShiftDetailsContent,
  MemberPhoto,
  MemberPhotoWrapper,
  StyledTimeSpan,
  StyledWrapPrice
} from "./printable.styles";

const widths = {
  member: 3,
  start: 1,
  end: 1,
  break: 1,
  hours: 1,
  price: 1,
  signature: 1
};

const time = date => (date ? moment(date).format(TIME_FORMAT) : "Time not set");

const PrintableTimesheet = ({ props, shiftPrinted, shift, currencyCode }) => {
  if (!shiftPrinted) return null;
  const {
    bookings,
    bonuses,
    paidHours,
    totalVat,
    totalWithBonus,
    totalWithVat
  } = shiftPrinted?.account?.shiftById;

  const formatterMoney = getFormatterMoney(currencyCode);

  const roleDetail = `${shift?.numberRequested} x ${shift?.roleRate?.roleName}`;

  const isSelfProvider = shift?.targetAccount?.id === shift?.sourceAccount?.id;

  const isPolicyApplied = some("booking.policyApplied", bookings);

  const chargeRate = Money({ amount: shift?.roleRate?.chargeRate });

  return (
    <Wrapper height={props?.containerHeight}>
      <ShiftHead id="shiftHead">
        <ShiftDetailsLabel>Shift Details - Print</ShiftDetailsLabel>
        <ShiftDetailsContent>
          <div>{dateRangeToHuman(shift?.startTime, shift?.endTime)}</div>
          <div>{shift?.venue?.address}</div>
          <div>
            {roleDetail} {!isSelfProvider ? chargeRate?.toFormat() : ""}
          </div>
        </ShiftDetailsContent>
      </ShiftHead>

      <TableHeader>
        <TableCell width={widths?.member}>Member</TableCell>
        <TableCell width={widths?.start}>Start</TableCell>
        <TableCell width={widths?.end}>End</TableCell>
        <TableCell width={widths?.break}>Break</TableCell>
        <TableCell width={widths?.hours}>Hours</TableCell>
        <HasRole role={Role.SHIFTS_COST}>
          <TableCell width={widths?.hours}>Bonus</TableCell>
          <TableCell width={widths?.price}>Price</TableCell>
        </HasRole>
        <TableCell width={widths?.signature}>Signature</TableCell>
      </TableHeader>
      {bookings
        ?.sort((a, b) =>
          a?.member?.firstName?.localeCompare(b?.member?.firstName)
        )
        ?.map((booking, i) => {
          const isSalaried = isMemberSalariedWorker(
            booking?.member?.annualSalary,
            booking?.member?.memberType
          );
          return booking?.booking.isNoShow || booking?.booking.isTurnedAway ? (
            <TableRow key={i} isBordered>
              <TableCell width={widths?.member}>
                {!props?.isHidden && (
                  <MemberPhotoWrapper>
                    <MemberPhoto src={booking?.member?.photo || defaultPhoto} />
                  </MemberPhotoWrapper>
                )}
                <MemberDetails>
                  <span>
                    {booking?.member?.firstName} {booking?.member?.lastName}
                  </span>
                  <LightText>{shift?.roleRate?.roleName}</LightText>
                </MemberDetails>
              </TableCell>
              <TableCell width={widths?.start}>
                {booking?.booking.isTurnedAway === BOOKING_STATES?.TURNED_AWAY
                  ? "Turned Away"
                  : "No Show"}
              </TableCell>
              <TableCell width={widths?.end} />
              <TableCell width={widths?.break} />
              <TableCell width={widths?.hours} />
              <TableCell width={widths?.hours}> 0.00 </TableCell>
              <HasRole role={Role.SHIFTS_COST}>
                <TableCell width={widths?.price}>
                  {Money()?.toFormat()}
                </TableCell>
              </HasRole>
              <TableCell width={widths?.break} />
            </TableRow>
          ) : (
            <TableRow key={i} isBordered>
              <TableCell width={widths?.member}>
                {props?.isHidden ? null : (
                  <MemberPhotoWrapper>
                    <MemberPhoto src={booking?.member?.photo || defaultPhoto} />
                  </MemberPhotoWrapper>
                )}
                <MemberDetails>
                  <span>
                    {booking?.member?.firstName} {booking?.member?.lastName}
                  </span>
                  <LightText>{shift?.roleRate?.roleName}</LightText>
                </MemberDetails>
              </TableCell>
              <TableCell column width={widths?.start}>
                {time(booking?.booking?.startFinal)}
              </TableCell>
              <TableCell column width={widths?.end}>
                {time(booking?.booking?.endFinal)}
                {isNotSameDay(
                  booking?.booking?.startFinal,
                  booking?.booking?.endFinal
                ) && <sup>+1</sup>}
              </TableCell>
              <TableCell column width={widths?.break}>
                {booking?.booking?.breakFinal || 0}m
              </TableCell>
              <TableCell width={widths?.hours} column>
                {parseFloat(booking?.bookingState?.paidHours || 0)}
                {booking?.booking?.isPolicyApplied ? "*" : ""}
                <StyledTimeSpan>
                  {hoursAndMinutesDisplay(booking?.bookingState || 0)}
                </StyledTimeSpan>
              </TableCell>
              <HasRole role={Role.SHIFTS_COST}>
                <TableCell column width={widths?.price}>
                  {!!isSalaried && <span>-</span>}
                  {!isSalaried &&
                    formatterMoney.format(
                      booking?.bookingState?.bonusFinal / 100
                    )}
                </TableCell>
                <TableCell width={widths?.price}>
                  <StyledWrapPrice>
                    {!!isSalaried && <span>-</span>}
                    {!isSalaried &&
                      formatterMoney.format(
                        booking?.bookingState?.totalWithBonus / 100
                      )}
                  </StyledWrapPrice>
                </TableCell>
              </HasRole>
              <TableCell column width={widths?.break} />
            </TableRow>
          );
        })}
      <TableRow>
        <TableCell isRightAlign width={7}>
          Total hours
        </TableCell>
        <TableCell width={2}>
          {paidHours}
          {isPolicyApplied ? "*" : ""}
        </TableCell>
      </TableRow>

      <HasRole role={Role.SHIFTS_COST}>
        <TableRow>
          <TableCell isRightAlign width={7}>
            Bonus
          </TableCell>
          <TableCell width={2}>
            {formatterMoney.format(bonuses / 100)}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell isRightAlign width={7}>
            Total
          </TableCell>
          <TableCell width={2}>
            {formatterMoney.format(totalWithBonus / 100)}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell isRightAlign width={7}>
            VAT
          </TableCell>
          <TableCell width={2}>
            {formatterMoney.format(totalVat / 100)}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell isRightAlign width={7}>
            <BoldText>Total inc. VAT</BoldText>
          </TableCell>
          <TableCell width={2}>
            <BoldText>{formatterMoney.format(totalWithVat / 100)}</BoldText>
          </TableCell>
        </TableRow>
      </HasRole>

      {isPolicyApplied && (
        <SmallText>
          *These hours have been adjusted in accordance with our Minimum Shift
          Length Policy.
        </SmallText>
      )}
    </Wrapper>
  );
};

export default PrintableTimesheet;

import { gql } from "@apollo/client";

export const CREATE_ACCOUNT_AND_USER = gql`
  mutation(
    $accountName: String!
    $accountType: accountType!
    $partnerAccountId: ID
    $firstName: String!
    $lastName: String!
    $email: String!
    $phoneNumber: String!
    $password: String
  ) {
    createAccountAndUser(
      accountName: $accountName
      accountType: $accountType
      partnerAccountId: $partnerAccountId
      firstName: $firstName
      lastName: $lastName
      email: $email
      phoneNumber: $phoneNumber
      password: $password
    ) {
      success
      data
    }
  }
`;

import { gql } from "@apollo/client";

export default gql`
  fragment ConnectionFragment on connection {
    id
    sourceAccount {
      id
      accountName
      logo
      newTimesheetsStartDate
    }
    targetAccount {
      id
      accountName
      logo
      notes
    }
    payrollBreakId
  }
`;

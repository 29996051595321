import { range, reduce } from "lodash/fp";
import React from "react";
import Icon from "~/src/components/icon";
import { keyMirror } from "~/src/utils";
import { StyledIcon, Wrapper } from "./icon-button.styles";

const BASE_SIZE = 6;

// SIZE_1: 12px, SIZE_2: 18px, SIZE_3: 24px... yes, you read that correct.
const sizes = reduce(
  (obj, num) => ({
    ...obj,
    [`SIZE_${num - 1}`]: `${num * BASE_SIZE}px`
  }),
  {},
  range(2, 10)
);

const IconButton = ({
  onClick,
  iconName,
  size,
  className,
  color,
  isOutline,
  isBorderless,
  noEffectOnHover,
  isNotCentered,
  borderRadius,
  noPointer,
  title
}) => {
  const iconSize = `${parseInt(sizes?.[size], 10) * 0.5}px`;
  return (
    <Wrapper
      onClick={onClick}
      className={className}
      size={sizes[size]}
      color={color}
      isOutline={isOutline}
      isBorderless={isBorderless}
      noEffectOnHover={noEffectOnHover}
      borderRadius={borderRadius}
      noPointer={noPointer}
      title={title}
    >
      <StyledIcon
        isOutline={isOutline}
        wrapperSize={sizes[size]}
        size={iconSize}
        name={iconName}
        iconColor={color}
        isNotCentered={isNotCentered}
      />
    </Wrapper>
  );
};

IconButton.iconNames = Icon.names;
IconButton.iconColors = Icon.colors;
IconButton.sizes = keyMirror(sizes);

export default IconButton;

import styled from "styled-components";

export const Styled_PaddingContainer = styled.div`
  padding: 24px;
`;

export const Styled_ComponentContainer = styled.div`
  padding: 48px;
  background-color: ${({ theme }) => theme.white};
  border-radius: 10px;
`;

export const Styled_BusyMembersFiles = styled.div`
  width: 70%;

  .header {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 40px;
  }

  .heading {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 24px;
    color: ${({ theme }) => theme.text.secondary};
  }

  .file_requirements {
    font-size: 12px;
    font-weight: 400;
    color: #9b9b9b;
    margin: 16px 0;
  }

  .buttons_wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 48px;

    .btn_width {
      width: 144px;
    }

    .cancel_btn {
      margin-right: 24px;
    }
  }

  .error_container_wrapper {
    margin-bottom: 48px;
  }
`;

import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const SPACING = {
  xsmall: STYLE_CONSTS.UNIT_HALF,
  small: STYLE_CONSTS.UNIT_1,
  medium: STYLE_CONSTS.UNIT_2,
  large: STYLE_CONSTS.UNIT_4,
  xlarge: STYLE_CONSTS.UNIT_8
};

export const Wrapper = styled.div`
  display: table-row;
`;

export const Label = styled.div`
  display: table-cell;
  width: ${STYLE_CONSTS.THUMBNAIL_SIZE};
  min-width: ${STYLE_CONSTS.THUMBNAIL_SIZE};
  vertical-align: middle;
  padding-bottom: ${({ spacingBottom }) => SPACING[spacingBottom] || 0};
  padding-top: ${({ spacingTop }) => SPACING[spacingTop] || 0};
  vertical-align: ${({ isTopAlignedLabel }) =>
    isTopAlignedLabel ? "top" : "middle"};
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : "0")};
  width: ${({ labelWidth }) => (labelWidth ? `${labelWidth}px` : "auto")};
`;

export const Value = styled.div`
  display: table-cell;
  padding-left: ${({ isNoGutter }) => (isNoGutter ? 0 : STYLE_CONSTS.UNIT_4)};
  width: 100%;
  padding-bottom: ${({ spacingBottom }) => SPACING[spacingBottom] || 0};
`;

import { createSelector } from "reselect";
import moment from "moment-timezone";
import omit from "lodash/fp/omit";
import has from "lodash/has";
import merge from "lodash/fp/merge";
import getOr from "lodash/fp/getOr";
import last from "lodash/fp/last";

import { extractShiftTimesText } from "../shared";
import { DATE_FORMAT } from "../selectors";

// Map props to formTitle
export const getFormTitle = createSelector(
  [props => props.shift],
  extractShiftTimesText
);

// Map state to props<shift>
export const mapStateToProps = ({ scheduler: { shiftForm } }) => {
  const shift = shiftForm.shifts[shiftForm.shiftOpenIndex];
  const times = getOr({}, "times", shift);

  const selectedDates = Object.values(times).sort((a, b) =>
    moment(a.startTime).diff(b.startTime, "days")
  );

  return {
    shift,
    shiftForm,
    selectedDates
  };
};

const cloneDates = (startTime, endTime, date, breakMinutes) => ({
  startTime: moment(startTime)
    .clone()
    .set("year", date.year())
    .set("month", date.month())
    .set("date", date.date()),
  endTime: moment(endTime)
    .clone()
    .set("year", date.year())
    .set("month", date.month())
    .set("date", date.date())
    .add(moment(startTime).isSame(moment(endTime), "day") ? 0 : 1, "days"),
  breakMinutes
});

// Map dispatch to props<toggleDate, toggleLinkedShifts>
export const mapDispatchToProps = (dispatch, props) => ({
  toggleDate: date => {
    dispatch((_, getState) => {
      const state = getState();
      const { shiftForm } = state.scheduler;

      const formattedDate = date.format(DATE_FORMAT);
      const shift = shiftForm.shifts[shiftForm.shiftOpenIndex];
      const times = getOr({}, "times", shift);

      // Get last shift time to duplicate
      const { startTime, endTime } = last(Object.values(times)) || {
        startTime: state.scheduler.initialPostStart,
        endTime: state.scheduler.initialPostEnd
      };

      props.handleShiftUpdate({
        times: {
          ...(has(times, formattedDate)
            ? omit(formattedDate, times)
            : merge(times, {
                [formattedDate]: cloneDates(
                  startTime,
                  endTime,
                  date,
                  props?.defaultBreakMinutes
                )
              }))
        }
      });
    });
  },
  setEndRangeDate: date => {
    dispatch((_, getState) => {
      const state = getState();
      const { shiftForm } = state.scheduler;

      const shift = shiftForm.shifts[shiftForm.shiftOpenIndex];
      const times = getOr({}, "times", shift);

      const lastShift = last(Object.values(times));
      const { startTime, endTime } = lastShift || {
        startTime: state.scheduler.initialPostStart,
        endTime: state.scheduler.initialPostEnd
      };

      if (!lastShift) {
        return;
      }

      let isReverse;
      let diffDays = date.diff(startTime, "days") + 1;

      if (diffDays <= 0) {
        diffDays = diffDays * -1 + 1;
        isReverse = true;
      }

      const formattedDate = date.format(DATE_FORMAT);

      const update = Array.from(Array(diffDays)).reduce(
        (agg, _, index) => {
          const baseDate = moment(startTime).clone();
          const changedDate = isReverse
            ? baseDate.subtract(index, "days")
            : baseDate.add(index, "days");
          const key = changedDate.format(DATE_FORMAT);

          return {
            ...agg,
            [key]: cloneDates(startTime, endTime, changedDate)
          };
        },
        {
          [formattedDate]: cloneDates(startTime, endTime, date)
        }
      );

      props.handleShiftUpdate({
        times: merge(times, update)
      });
    });
  }
});

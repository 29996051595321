import { flexCentered, STYLE_CONSTS } from "~/src/styles/config.style";
import { colors } from "@teamrota/rota-design";

import styled from "styled-components";
import Icon from "~/src/components/icon";

export const StyledDialog = styled.div`
  min-width: ${({ input }) => (input ? "389px" : "516")};
  min-height: "285px";
  overflow-y: "hidden";
  border-radius: "10px";
`;

export const DeleteBadgeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DeleteBadgeContainer = styled.div`
  width: 100px;
  height: 100px;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fda53a80;
  border: 3px solid #ff9300;
  border-radius: 100px;
  svg {
    width: 80px;
    height: 80px;
    path {
      fill: #ff9300;
    }
  }
`;

export const StyledIcon = styled(Icon)`
  vertical-align: middle;
`;

export const useStyles = () => ({
  dialogPaper: {
    minHeight: "285px",
    width: "516px",
    overflowY: "hidden",
    borderRadius: "10px"
  },
  dialogPaperInput: {
    minHeight: "330px",
    width: "389px",
    overflowY: "hidden",
    borderRadius: "10px"
  }
});

const styles = {
  centered: {
    ...flexCentered,
    padding: "44px"
  },
  declineBtn: {
    backgroundColor: `${colors.seashellGrey}`,
    color: `${colors.black}`,
    width: "174px",
    height: "40px",
    borderRadius: "20px",
    textTransform: "none"
  },
  declineBtnInput: {
    backgroundColor: `${colors.seashellGrey}`,
    color: `${colors.black}`,
    width: "142px",
    height: "36px",
    borderRadius: "20px",
    textTransform: "none"
  },
  dialogActions: {
    padding: "30px 40px 40px",
    display: "flex",
    justifyContent: "space-between"
  },
  dialogActionsInput: {
    marginTop: "45px",
    padding: "35px 25px",
    display: "flex",
    justifyContent: "space-between"
  },
  dialogContent: {
    height: "auto",
    textAlign: "center"
  },
  dialogText: {
    padding: "6% 8%",
    textSize: "14px",
    fontWeight: "400",
    lineHeight: "23px",
    letterSpacing: "-0.3px"
  },
  dialogTitle: {
    height: "42px",
    borderBottom: `1px solid ${colors.lightGrey}`
  },
  dialogTitleText: {
    border: `1px solid ${colors.orange}`,
    borderWidth: "0 0 1px 0",
    fontSize: STYLE_CONSTS.FONT_LARGE,
    padding: "15px 20px",
    fontWeight: "400",
    width: "80%"
  },
  singleButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "40px"
  }
};

export default styles;

import React, { useEffect, useState } from "react";

import { useLazyQuery, useMutation } from "@apollo/client";
import { RotaButton, RotaCheckbox } from "@teamrota/rota-design";

import AccountsList from "../accounts-list";
import adminClient from "../../graphql/admin-client";
import { UPDATE_OPTIONAL_FEATURES } from "../../graphql/update-optional-features";
import { GET_OPTIONAL_FEATURES } from "../../graphql/get-optional-features";
import {
  StyledWrapper,
  StyledFeaturesWrapper,
  StyledFeatureWrapper,
  StyledHeading,
  StyledDetail,
  StyledFeaturesList
} from "./styles";
import {
  StyledMainHeading,
  StyledMainDetail,
  StyledMainHeaderWrapper
} from "../styles";

const optionalFeatures = [
  {
    id: "scheduler",
    name: "Scheduler",
    detail: "Show/Hide Schedule tab on top navigation"
  },
  {
    id: "providerScheduler",
    name: "Scheduler for Provider",
    detail: "Show/Hide Provide-Schedule tab on top navigation"
  },
  {
    id: "isOverLimitWorkAllowed",
    name: "Allow over-limit work",
    detail:
      "Allow members who are over their weekly working hours limit to work shifts (with an override)"
  },
  {
    id: "isShiftCancellingAllowed",
    name: "Allow shift cancelling",
    detail: "Allow members to cancel shifts in the app"
  },
  {
    id: "isComplianceExpiryEnabled",
    name: "Enable Compliance Expiry",
    detail: "Set Compliance tags to disappear when Expiry occurs"
  },
  {
    id: "isProviderPayRateOverride",
    name: "Provider Pay rate override",
    detail:
      "Enables Pay Rate field on member modal roles page to allow individual override for provider pay rates"
  },
  {
    id: "isPayCalculationOnCheckInOutTimes",
    name: "Pay calculation at check in/out",
    detail:
      "When enabled pay rate calculation is by the members check-in and check-out time (the manager override and approval times still take priority)"
  },
  {
    id: "isSmsOptionAvailable",
    name: "Allow Provider SMS Messages",
    detail: "Whether SMS text messages can be sent from Provide -> Message"
  }
];

const EnablingOptionalFeatures = ({ setSnack }) => {
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [featuresState, setFeaturesState] = useState({});

  const [getOptionalFeatures, { data, loading }] = useLazyQuery(
    GET_OPTIONAL_FEATURES,
    {
      variables: { accountId: selectedAccountId },
      client: adminClient,
      fetchPolicy: "network-only"
    }
  );

  const [updateOptionalFeatures] = useMutation(UPDATE_OPTIONAL_FEATURES, {
    variables: { accountId: selectedAccountId, ...featuresState },
    client: adminClient
  });

  useEffect(() => {
    if (selectedAccountId) getOptionalFeatures();
  }, [selectedAccountId]);

  useEffect(() => {
    if (data?.optionalFeatures) setFeaturesState(data.optionalFeatures);
  }, [data]);

  const handleFeatureStateUpdate = id => {
    setFeaturesState(state => ({ ...state, [id]: !state[id] }));
  };

  const handleSave = async () => {
    try {
      await updateOptionalFeatures();
      setSnack({ message: "Success!", severity: "success" });
    } catch (e) {
      setSnack({ message: "Something Went Wrong.", severity: "error" });
    }
  };

  return (
    <>
      <StyledMainHeaderWrapper>
        <StyledMainHeading>Enabling Optional Features</StyledMainHeading>
        <StyledMainDetail>
          Manage optional features for all accounts
        </StyledMainDetail>
      </StyledMainHeaderWrapper>
      <StyledWrapper>
        <AccountsList
          onSelect={id => {
            setSelectedAccountId(id);
          }}
          selectedAccountId={selectedAccountId}
        />
        {selectedAccountId && !loading && (
          <StyledFeaturesWrapper>
            <StyledFeaturesList>
              {optionalFeatures.map(({ id, name, detail }) => (
                <StyledFeatureWrapper key={id}>
                  <div>
                    <StyledHeading>{name}</StyledHeading>
                    <StyledDetail>{detail}</StyledDetail>
                  </div>
                  <RotaCheckbox
                    isChecked={featuresState?.[id]}
                    onClick={() => {
                      handleFeatureStateUpdate(id);
                    }}
                  />
                </StyledFeatureWrapper>
              ))}
            </StyledFeaturesList>
            <RotaButton onClick={handleSave}>Save</RotaButton>
          </StyledFeaturesWrapper>
        )}
      </StyledWrapper>
    </>
  );
};

export default EnablingOptionalFeatures;

import React, { useEffect, useRef, useState } from "react";
import { useLazyQuery } from "@apollo/client";

import {
  ModalHeader,
  RotaButton,
  RotaCheckbox,
  RotaDropdown,
  RotaInput,
  RotaNewModal,
  RotaSwitchWithLabel,
  iconNew
} from "@teamrota/rota-design";

import { SHIFT_TYPES } from "~/src/consts";
import StarsInput from "~/src/components/form-field/inputs/stars-input";

import { POINT_OF_SCALE_OPTIONS } from "../consts";

import { removeNonNumbers } from "../util";

import DateTimeSelect from "../DateTimeSelect";
import OutputPanel from "../OutputPanel";
import RuleDescription from "../RuleDescription";

import { PAY_CALCULATION } from "./graphql/pay-calculation";

import {
  ContentArea,
  Group,
  GroupTitle,
  Input,
  Label,
  OptionsButton,
  Error,
  Bottom,
  CheckBoxGroup,
  SwitchWrapper,
  InputFieldWrapper,
  DropDownWrapper
} from "./styles";

const { Sun, Moon, Cancel } = iconNew;

interface SimulatorModalProps {
  id: string;
  payrollRateCards: any[];
  onClose: () => void;
}

const SimulatorModal: React.FC<SimulatorModalProps> = ({
  id,
  payrollRateCards,
  onClose
}) => {
  const outputRef = useRef<any>(null);

  const [pos, setPoS] = useState<string | undefined>(undefined);
  const [shiftType, setShiftType] = useState<string>("DAY");
  const [shiftStart, setShiftStart] = useState<Date | undefined>(undefined);
  const [shiftEnd, setShiftEnd] = useState<Date | undefined>(undefined);
  const [breakDur, setBreakDur] = useState<string | undefined>(undefined);
  const [sleepStart, setSleepStart] = useState<Date | undefined>(undefined);
  const [sleepEnd, setSleepEnd] = useState<Date | undefined>(undefined);
  const [tsShiftStart, setTSShiftStart] = useState<Date | undefined>(undefined);
  const [tsShiftEnd, setTSShiftEnd] = useState<Date | undefined>(undefined);
  const [tsBreakDur, setTSBreakDur] = useState<string | undefined>(undefined);
  const [tsSleepStart, setTSSleepStart] = useState<Date | undefined>(undefined);
  const [tsSleepEnd, setTSSleepEnd] = useState<Date | undefined>(undefined);

  const [tsDisturbedStart, setTSDisturbedStart] = useState<Date | undefined>(
    undefined
  );

  const [tsDisturbedEnd, setTSDisturbedEnd] = useState<Date | undefined>(
    undefined
  );

  const [isTSFavourite, setIsTSFavourite] = useState<boolean>(false);
  const [isTSNoShow, setIsTSNoShow] = useState<boolean>(false);
  const [isTSTurnedAway, setIsTSTurnedAway] = useState<boolean>(false);
  const [tsRating, setTSRating] = useState<string | undefined>(undefined);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [
    runPayCalculation,
    { data, loading, error }
  ] = useLazyQuery(PAY_CALCULATION, { fetchPolicy: "no-cache" });

  const results = data?.getPayCalculation;

  useEffect(() => {
    if (error || results) {
      outputRef.current?.scrollIntoView?.({ behavior: "smooth" });
    }
  }, [error, results]);

  const run = () => {
    const shift = {
      type: shiftType,
      shiftStart,
      shiftEnd,
      breakDuration: breakDur,
      sleepStart,
      sleepEnd
    };

    if (shift.type === undefined)
      return setErrorMessage("You must set shift type");
    if (shift.shiftStart === undefined)
      return setErrorMessage("You must set shift start");
    if (shift.shiftEnd === undefined)
      return setErrorMessage("You must set shift end");

    const timesheet = {
      shiftStart: tsShiftStart,
      shiftEnd: tsShiftEnd,
      breakDuration: tsBreakDur,
      sleepStart: tsSleepStart,
      sleepEnd: tsSleepEnd,
      disturbedStart: tsDisturbedStart,
      disturbedEnd: tsDisturbedEnd,
      isNoShow: isTSNoShow,
      isTurnedAway: isTSTurnedAway,
      isFavourite: isTSFavourite,
      rating: tsRating
    };

    const worker = {
      pointOfScale: pos
    };

    if (worker.pointOfScale === undefined)
      return setErrorMessage("You must set point of scale");

    setErrorMessage(null);

    runPayCalculation({
      variables: {
        id,
        shift,
        timesheet,
        worker
      }
    });
  };

  return (
    <RotaNewModal
      onClose={onClose}
      header={
        <ModalHeader
          title={"Simulate Run"}
          endAction={
            <OptionsButton onClick={onClose}>
              <Cancel />
            </OptionsButton>
          }
        />
      }
    >
      <RuleDescription id={id} payrollRateCards={payrollRateCards} />

      <ContentArea>
        <Group width={475}>
          <GroupTitle>Shift</GroupTitle>

          <Input>
            <Label>Shift Start</Label>
            <DateTimeSelect time={shiftStart} onChangeTime={setShiftStart} />
          </Input>

          <Input>
            <Label>Shift End</Label>
            <DateTimeSelect time={shiftEnd} onChangeTime={setShiftEnd} />
          </Input>

          <Input>
            <Label>Break Duration</Label>
            <InputFieldWrapper>
              <RotaInput
                placeholder={""}
                value={breakDur ?? ""}
                onChange={e =>
                  setBreakDur(
                    e.target.value !== ""
                      ? removeNonNumbers(e.target.value)
                      : undefined
                  )
                }
              ></RotaInput>
            </InputFieldWrapper>
          </Input>

          <Input>
            <Label>Sleep Start</Label>
            <DateTimeSelect time={sleepStart} onChangeTime={setSleepStart} />
          </Input>

          <Input>
            <Label>Sleep End</Label>
            <DateTimeSelect time={sleepEnd} onChangeTime={setSleepEnd} />
          </Input>

          <SwitchWrapper>
            <RotaSwitchWithLabel
              iconOn={<Sun />}
              iconOff={<Moon />}
              checked={shiftType === SHIFT_TYPES.DAY}
              onChange={e =>
                setShiftType(
                  e.target.checked ? SHIFT_TYPES.DAY : SHIFT_TYPES.NIGHT
                )
              }
            >
              Shift Type
            </RotaSwitchWithLabel>
          </SwitchWrapper>
        </Group>

        <Group width={475}>
          <GroupTitle>Timesheet</GroupTitle>

          <Input>
            <Label>Shift Start</Label>
            <DateTimeSelect
              time={tsShiftStart}
              onChangeTime={setTSShiftStart}
            />
          </Input>

          <Input>
            <Label>Shift End</Label>
            <DateTimeSelect time={tsShiftEnd} onChangeTime={setTSShiftEnd} />
          </Input>

          <Input>
            <Label>Break Duration</Label>
            <InputFieldWrapper>
              <RotaInput
                placeholder={""}
                value={tsBreakDur ?? ""}
                onChange={e =>
                  setTSBreakDur(
                    e.target.value !== ""
                      ? removeNonNumbers(e.target.value)
                      : undefined
                  )
                }
              ></RotaInput>
            </InputFieldWrapper>
          </Input>

          <Input>
            <Label>Sleep Start</Label>
            <DateTimeSelect
              time={tsSleepStart}
              onChangeTime={setTSSleepStart}
            />
          </Input>

          <Input>
            <Label>Sleep End</Label>
            <DateTimeSelect time={tsSleepEnd} onChangeTime={setTSSleepEnd} />
          </Input>

          <Input>
            <Label>Disturbed Start</Label>
            <DateTimeSelect
              time={tsDisturbedStart}
              onChangeTime={setTSDisturbedStart}
            />
          </Input>

          <Input>
            <Label>Disturbed End</Label>
            <DateTimeSelect
              time={tsDisturbedEnd}
              onChangeTime={setTSDisturbedEnd}
            />
          </Input>

          <Input>
            <Label>Rating</Label>

            <StarsInput
              id={"rating"}
              input={{
                value: tsRating,
                onChange: setTSRating
              }}
              isEditable={false}
              shouldOverrideClick={false}
              upcomingAccepted={true}
              isMember={true}
              memberRating={0}
            />
          </Input>

          <CheckBoxGroup>
            <RotaCheckbox
              label="Turned Away"
              onClick={e => setIsTSTurnedAway(e.target.checked)}
              isChecked={isTSTurnedAway}
            />

            <RotaCheckbox
              label="No Show"
              onClick={e => setIsTSNoShow(e.target.checked)}
              isChecked={isTSNoShow}
            />

            <RotaCheckbox
              label="Is Favourite"
              onClick={e => setIsTSFavourite(e.target.checked)}
              isChecked={isTSFavourite}
            />
          </CheckBoxGroup>
        </Group>

        <Group width={475}>
          <GroupTitle>Worker</GroupTitle>

          <Input>
            <Label>Point of Scale</Label>

            <DropDownWrapper>
              <RotaDropdown
                id="pointofScale"
                label={""}
                placeholder="Choose..."
                options={POINT_OF_SCALE_OPTIONS}
                value={pos}
                onChange={value => setPoS(value)}
                width={"200px"}
                isError={false}
                errorMessage={""}
              />
            </DropDownWrapper>
          </Input>

          <Bottom>
            <RotaButton disabled={false} onClick={run}>
              Run Simulation
            </RotaButton>
          </Bottom>
        </Group>
      </ContentArea>

      {(loading || error || errorMessage || results) && (
        <div ref={outputRef}>
          <ContentArea>
            <Group width={1445}>
              <GroupTitle>Output</GroupTitle>

              {loading && <>Loading...</>}
              {error && <Error>{error?.message}</Error>}
              {errorMessage && <Error>{errorMessage}</Error>}

              {results && !error && !loading && (
                <OutputPanel
                  id={id}
                  payrollRateCards={payrollRateCards}
                  input={results.input}
                  blocks={results.blocks}
                  extras={results.extras}
                  facts={results.facts}
                  totals={results.totals}
                />
              )}
            </Group>
          </ContentArea>
        </div>
      )}
    </RotaNewModal>
  );
};

export default SimulatorModal;

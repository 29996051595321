import React from "react";
import Icon from "~/src/components/icon";

import {
  Image,
  IconBackgound,
  ClickableArea,
  Wrapper
} from "./document-image.styles";

const loadingIcon = (
  <Icon
    name={Icon.names.LOADING}
    color={Icon.colors.WHITE}
    size={Icon.sizes.LARGE}
  />
);

export const LoadingImage = ({ isRound }) => (
  <Image isRound={isRound} isLoading>
    {loadingIcon}
  </Image>
);

const DocumentImage = props => {
  const { fileName, src, isActive, isRound, fileType } = props;
  return (
    <Wrapper>
      {fileType === "PDF" ? (
        <Image>
          <p>{fileName}</p>
          <ClickableArea>
            {props.onPdfOpen ? (
              <IconBackgound
                pdf={true}
                onClick={() => {
                  props.onPdfOpen(src);
                }}
                title="Download"
              >
                <Icon
                  name={Icon.names.DOWNLOAD}
                  color={Icon.colors.WHITE}
                  size={Icon.sizes.XSMALL}
                />
              </IconBackgound>
            ) : null}
            {props.onDelete ? (
              <IconBackgound
                pdf={true}
                onClick={() => {
                  props.onDelete();
                }}
                title="Delete"
              >
                <Icon
                  name={Icon.names.DELETE}
                  color={Icon.colors.WHITE}
                  size={Icon.sizes.XSMALL}
                />
              </IconBackgound>
            ) : null}
          </ClickableArea>
        </Image>
      ) : (
        <Image isActive={isActive} src={src} isRound={isRound}>
          <ClickableArea>
            <IconBackgound
              target="_blank"
              href={src}
              title="View original image"
            >
              <Icon
                name={Icon.names.VISIBLE}
                color={Icon.colors.WHITE}
                size={Icon.sizes.SMALL}
              />
            </IconBackgound>
            {props.onSelect ? (
              <IconBackgound
                onClick={() => {
                  props.onSelect();
                }}
                title="Set as primary"
              >
                <Icon
                  name={Icon.names.CHECK}
                  color={Icon.colors.WHITE}
                  size={Icon.sizes.XSMALL}
                />
              </IconBackgound>
            ) : null}
            {props.onDelete ? (
              <IconBackgound
                onClick={() => {
                  props.onDelete();
                }}
                title="Delete"
              >
                <Icon
                  name={Icon.names.DELETE}
                  color={Icon.colors.WHITE}
                  size={Icon.sizes.XSMALL}
                />
              </IconBackgound>
            ) : null}
          </ClickableArea>
        </Image>
      )}
    </Wrapper>
  );
};

export default DocumentImage;

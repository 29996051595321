import styled from "styled-components";
import Icon from "~/src/components/icon";

export const PublishMessageWrapper = styled.div`
  display: flex;
`;

export const StyledIcon = styled(Icon).attrs(({ countOfDraftShifts }) => ({
  size: 13,
  color: countOfDraftShifts > 0 ? Icon.colors.MAIN_ORANGE : Icon.colors.BLACK,
  name: Icon.names.ADD_SHIFT
}))``;

export const Label = styled.div`
  padding-left: 5px;
  line-height: 15px;
`;

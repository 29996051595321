import styled from "styled-components";
import { COLORS, STYLE_CONSTS } from "~/src/styles/config.style";

export const GridDayColumn = styled.div`
  float: left;
  width: ${(1 / 7) * 100}%;
  border-right: ${STYLE_CONSTS.MED_DIVIDER_BORDER_MED_LIGHT};
  user-select: none;
`;

export const GridDayColumnHour = styled.div`
  border-bottom: ${STYLE_CONSTS.MED_DIVIDER_BORDER_MED_LIGHT};
  background-color: ${props =>
    props.isFilled ? COLORS.PALE_GREY_TRANSPARENT_30 : COLORS.WHITE};
  user-select: none;
`;

import styled from "styled-components";

import { STYLE_CONSTS } from "~/src/styles/config.style";
import ExitButton from "~/src/components/button/exit-button";

export const StyledModal = styled.div`
  transition: all 0.15s ease-in;

  background-color: ${({ theme }) => theme.white};
  cursor: default;
  height: fit-content;
  width: 420px;
  z-index: 200;
  box-shadow: 0 2px 20px 100vmax rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  padding-bottom: 15px;

  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;

  overflow-x: hidden;
  overflow-y: auto;

  & svg {
    margin: 4px 0;
  }
`;

export const StyledExitButton = styled(ExitButton)`
  position: absolute;
  right: 0;
  margin: 4px;
`;

export const StyledPhotoWrapper = styled.div`
  margin: 32px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledIssue = styled.div`
  padding: 32px 0px 0px;
  font-size: 16px;
  text-align: center;
  border-top: solid 1px ${({ theme }) => theme.error.main};
`;

export const StyledEmph = styled.div`
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_BOLD};
  padding: 5px 0 0 0;
`;

export const StyledHighlight = styled.div`
  font-size: ${STYLE_CONSTS.FONT_LARGE};
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_BOLD};
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const StyledAvail = styled.div`
  font-size: ${STYLE_CONSTS.FONT_LARGE};
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_BOLD};
  text-align: center;
  margin-top: 10px;
`;

export const StyledTitle = styled.div`
  font-size: ${STYLE_CONSTS.FONT_LARGE};
  padding: 25px 0 5px 0;
  text-align: center;
  font-weight: bolder;
`;

export const StyledMemberName = styled.div`
  margin: 0 0 32px 0;
  text-align: center;
  font-size: 20px;
  font-weight: bolder;
`;

export const StyledConfirmWrapper = styled.div`
  width: 100%;
  margin: 0 auto 10px auto;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  margin: 10px 0px;

  & .option {
    height: 50px;
    width: 300px;
  }
`;

export const StyledWrapButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding-bottom: 48px;
`;

export const StyledWrapConfirmation = styled.p`
  font-size: 12px;
  text-align: center;
  color: ${({ theme }) => theme.text.primary};
`;

export const StyledGroupReason = styled.div`
  font-size: 14px;
  padding: 32px 0 16px;
  border-top: 1px solid ${({ theme }) => theme.primary.main};
  text-align: center;
`;

export const StyledGroupMembers = styled.div`
  border: 1px solid ${({ theme }) => theme.neutral.light};
  border-radius: 4px;
  overflow: auto;
  max-height: 185px;
  margin: 0 0 16px;
`;

import React, { useState } from "react";
import noop from "lodash/noop";

const initialIndex = 0;

const FormStepList = props => {
  const [openIndex, setOpenIndex] = useState(initialIndex);
  const [visitedIndexes, setVisitedIndexes] = useState({ 0: [initialIndex] });
  const { segments, openStateIndex, isGridSelectedShift } = props;

  const goToNext = () => {
    const currentIndex = visitedIndexes[openStateIndex];
    const nextUnvisitedStepIndex = segments.findIndex(
      (_, i) => currentIndex[i] === noop()
    );

    const latestVisitedStepIndex = currentIndex[currentIndex.length - 1];

    let newIndex =
      latestVisitedStepIndex !== openIndex
        ? latestVisitedStepIndex
        : nextUnvisitedStepIndex;

    if (isGridSelectedShift && newIndex === 3) {
      newIndex = newIndex + 1;
    }

    setOpenIndex(newIndex);
    setVisitedIndexes({
      ...visitedIndexes,
      [openStateIndex]: Array.from(new Set([...currentIndex, newIndex]))
    });
  };

  const goToSelf = openIndex => () => {
    setOpenIndex(openIndex);
  };

  const goToStart = async () => {
    if (visitedIndexes[openStateIndex]) {
      goToSelf(initialIndex)();
    } else {
      setOpenIndex(initialIndex);
      setVisitedIndexes({
        ...visitedIndexes,
        [openStateIndex]: [initialIndex]
      });
    }
  };

  const currentOpenStateIndex = openStateIndex || 0;
  if (!visitedIndexes[currentOpenStateIndex]) return null;

  const hasCompletedAllSteps =
    segments.length === visitedIndexes[currentOpenStateIndex].length;

  return (
    <div>
      {segments.map((Segment, index) => {
        return (
          <Segment
            {...props.passedProps}
            key={index}
            stepNumber={index + 1}
            hasVisited={visitedIndexes[currentOpenStateIndex][index] !== noop()}
            isOpen={index === openIndex}
            goToSelf={goToSelf(index)}
            goToNext={goToNext}
            goToStart={goToStart}
            hasCompletedAllSteps={hasCompletedAllSteps}
          />
        );
      })}
    </div>
  );
};

export default FormStepList;

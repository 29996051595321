import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "@apollo/client";
import { RotaModal } from "@teamrota/rota-design";

import { GET_ACCOUNT_WITH_ROLE_RATES } from "./graphql/get-role-rates";

import GroupMember from "./group-member";
import SingleMember from "./single-member";

const MemberAddWarningModal = ({
  warning,
  accountType,
  roleRateId,
  dates,
  onClose
}) => {
  const node = useRef(null);
  const [confirmationInput, setConfirmationInput] = useState("");

  const { data: accountData } = useQuery(GET_ACCOUNT_WITH_ROLE_RATES, {
    skip: !accountType || !roleRateId,
    fetchPolicy: "network-only",
    variables: { accountType, roleRateId }
  });

  const handleCancel = () => {
    onClose();
  };

  const handleAdd = () => {
    warning?.callback();
    onClose(true);
  };

  const handleClose = () => onClose();

  const handleClick = e => {
    if (!node.current?.contains(e.target)) onClose();
  };

  useEffect(() => {
    if (warning) {
      document.addEventListener("mousedown", handleClick, false);
    } else {
      document.removeEventListener("mousedown", handleClick, false);
    }
  }, [warning]);

  if (!warning) {
    return null;
  }

  const { member } = warning;

  const roleName = accountData?.account?.roleRates?.find(
    ({ id }) => id === roleRateId
  )?.role?.name;

  const MemberComponent = member.length ? GroupMember : SingleMember;

  return (
    <RotaModal
      isSmall
      onClose={handleClose}
      styles={{ maxHeight: "900px", overflow: "auto" }}
    >
      <div ref={node}>
        <MemberComponent
          member={member}
          roleName={roleName}
          dates={dates}
          accountData={accountData}
          confirmationInput={confirmationInput}
          setConfirmationInput={setConfirmationInput}
          onAdd={handleAdd}
          onCancel={handleCancel}
        />
      </div>
    </RotaModal>
  );
};

export default MemberAddWarningModal;

import React from "react";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import { RotaButton } from "@teamrota/rota-design";

import RequestZonesPanel from "~/src/components/RequestZonesPanel";
import FormStepSegment from "../FormStepSegment";

import { Actions, HeaderTitle, Header, EditButton } from "../styles";

import { mapStateToProps } from "./selectors";
import { extractTotalReqText } from "../shared";

const FormMembers = props => {
  const { shifts, shiftOpenIndex } = props.shiftForm;
  const shift = shifts[shiftOpenIndex];

  const onUpdatePoolsFormSection = ({ shifts, shiftOpenIndex }) => {
    const {
      assignedMemberIds,
      requestedMemberIds,
      isPartnerManaged,
      skipAlgoShift,
      delayHoursAlgoShift,
      isRequestAll,
      isIncludeUnavailable
    } = shifts[shiftOpenIndex];

    props.handleShiftUpdate({
      assignedMemberIds,
      requestedMemberIds,
      isPartnerManaged,
      skipAlgoShift,
      delayHoursAlgoShift,
      isRequestAll,
      isIncludeUnavailable
    });
  };

  return (
    <FormStepSegment
      isOpen={props.isOpen}
      stepNumber={props.stepNumber}
      title={() => (
        <Header>
          <HeaderTitle>
            {props.isGridSelectedShift
              ? `Assigned member: ${shift.assignedMemberName}`
              : props.hasVisited && !props.isOpen
              ? extractTotalReqText(shift)
              : "Select & Request Staff"}
          </HeaderTitle>
          {props.hasVisited && !props.isOpen && (
            <EditButton onClick={props.goToSelf}>Edit</EditButton>
          )}
        </Header>
      )}
    >
      {() => (
        <div>
          <RequestZonesPanel
            isProvide={props.isProviderScheduler}
            isVisible={props.isOpen}
            connections={props.connections}
            poolConnectionSearchText={props.searchText}
            formData={props.shiftForm}
            onUpdate={onUpdatePoolsFormSection}
            selectedAccountId={props.selectedPartnerId}
          />

          <Actions>
            <RotaButton onClick={props.goToNext} isButtonClickable={true}>
              {props.hasCompletedAllSteps ? "Done" : "Next"}
            </RotaButton>
          </Actions>
        </div>
      )}
    </FormStepSegment>
  );
};

export default compose(connect(mapStateToProps, null), C => props => (
  <C {...props} isCreate />
))(FormMembers);

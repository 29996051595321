import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

import update from "lodash/fp/update";
import get from "lodash/fp/get";

import { handleErrors } from "~/src/utils/errors";
import { BOOKING_LIMIT } from "../../../reducer";

export const GET_PROVIDE_BOOKINGS = gql`
  query getProvideBookings(
    $shiftId: ID!
    $bookingsLimit: Int!
    $bookingsOffset: Int!
    $bookingStates: [BookingStateType]
  ) {
    account {
      id
      shift(id: $shiftId) {
        id
        bookings(
          limit: $bookingsLimit
          offset: $bookingsOffset
          stateIn: $bookingStates
          orderByStatePropDesc: "appliedAt"
        ) {
          data {
            id
            state
            bucketReason
            visibleAt
            ratingOfMember
            ratingOfManager
            isNoShow
            bookingState {
              id
              startFinal
              endFinal
              breakFinal
              lengthFinal
              appliedAt
            }
            approvalState
            notes
            bookingNotes
            feedbackForMember
            feedbackLabelsForMember
            compatability
            factorScore
            member {
              id
              internalId
              firstName
              lastName
              photo
              phone
              email
              rating
              notes
              state
              isAsleep
              memberRatingForShift(shiftId: $shiftId) {
                averageRequesterRating
                requesterRatings
                averageRoleRating
                roleRatings
                globalRating
                allRatings
                requesterShiftTotal
                roleTotal
                globalTotal
              }
              relationships {
                id
                poolType
                account {
                  id
                }
              }
            }
          }
          offset
          limit
          totalResults
        }
      }
    }
  }
`;

export default graphql(GET_PROVIDE_BOOKINGS, {
  skip: props => !get("shiftItem.isOpen", props),
  props: handleErrors(({ data: { loading, account, fetchMore, refetch } }) => ({
    isLoadingProvideBookings: loading,
    bookings: account?.shift?.bookings ?? {},
    fetchMore: () => loadMore(account?.shift?.bookings?.data.length, fetchMore),
    refetchBookings: refetch
  })),
  options(props) {
    return {
      fetchPolicy: "cache-and-network",
      variables: {
        shiftId: props.shift.id,
        bookingStates: get("shiftItem.activeBookingStates", props),
        bookingsOffset: props.bookingsOffset || 0,
        bookingsLimit: BOOKING_LIMIT
      }
    };
  }
});

export function loadMore(offset, fetchMore) {
  const path = "account.shift.bookings.data";
  return fetchMore({
    notifyOnNetworkStatusChange: true,
    variables: { bookingsOffset: offset },
    updateQuery: (previousResult, { fetchMoreResult }) =>
      update(
        path,
        current => [
          ...current,
          ...fetchMoreResult?.account.shift.bookings.data
        ],
        previousResult
      )
  });
}

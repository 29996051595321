import { useQuery } from "@apollo/client";
import { Venue } from "./types";

import { gql } from "@apollo/client";

const GET_VENUES = gql`
  query getVenues {
    user {
      id
      account {
        id
        venues {
          id
          name
          subvenues {
            id
            name
          }
        }
      }
    }
  }
`;

const useVenues = () => {
  const { data: venueData } = useQuery(GET_VENUES);
  const venues: Venue[] = venueData?.user?.account?.venues || [];

  return venues;
};

export default useVenues;

import React, { useEffect, useState } from "react";
import moment from "moment-timezone";

import { Row, Col, Label, SmallText } from "./filters-date-range.styles";
import DatePicker from "~/src/components/form-components/date-picker";

const FiltersDateRange = ({
  startTime,
  endTime,
  onChangeStartTime,
  onChangeEndTime,
  isInline
}) => {
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    validateTimeDifference(startTime, endTime);
  }, [startTime, endTime]);

  const validateTimeDifference = (startTime, endTime) => {
    const isFuture = moment(endTime).isSameOrAfter(moment(startTime));
    if (!isFuture) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  };
  return (
    <>
      <Row>
        <Col isInline={isInline}>
          <Label>From</Label>
          <DatePicker
            isSmall
            isDateOnly
            highlightToday
            borderRadiusSize="24px"
            centerText
            start={startTime}
            onChange={value => onChangeStartTime(value?.start)}
          />
        </Col>
        <Col isInline={isInline}>
          <Label>To</Label>
          <DatePicker
            isSmall
            isDateOnly
            highlightToday
            borderRadiusSize="24px"
            centerText
            start={endTime}
            onChange={value =>
              JSON.stringify(value?.end) !== "null" &&
              onChangeEndTime(value?.start)
            }
          />
        </Col>
      </Row>
      <Row>
        {showError && (
          <SmallText> From date cannot be later than To date</SmallText>
        )}
      </Row>
    </>
  );
};

export default FiltersDateRange;

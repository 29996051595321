import React, { useState, useEffect, useRef } from "react";
import { RotaDropdownHeader, RotaMultiSelectMenu } from "@teamrota/rota-design";
import { uniqBy } from "lodash";
import { useHomeVenues } from "~/src/containers/provide-schedule/use-home-venues";

import useAuth from "~/src/auth/hooks/use-auth";

import {
  GET_FILTERS,
  getSelectedIds,
  filterBySearchTerm,
  clearFilteredOptions,
  formatUsers,
  isLastItem
} from "./utils";

import {
  StyledRotaDropdownHeader,
  StyledDropdownsContainer,
  StyledSeparator,
  StyledFilterWrapper,
  StyledChipsContainer
} from "./filter-dropdown.styles";
import FilterChips from "./FilterChips/filter-chips";
import {
  getProvideScheduleFilters,
  setProvideScheduleFilters
} from "../../storage";
import { useUsers } from "~/src/containers/provide-schedule/use-users";
import { Role } from "@teamrota/authlib";

const FilterDropdown = ({
  partners,
  handlePartnerSelect,
  setRoleIds,
  setVenueIds,
  setHomeVenueIds,
  setSelectedUserIds,
  isShowCancelledShifts,
  setIsShowCancelledShifts,
  isShowCancelledBookings,
  setIsShowCancelledBookings,
  setShiftTypes,
  setAvailabilityWeekDays
}) => {
  const auth = useAuth();
  const isSelfProvider = auth.hasRoles(Role.PROVIDER, Role.REQUESTER);

  // ================= STATE ==================
  const [filters, setFilters] = useState(GET_FILTERS(isSelfProvider).FILTERS);
  const [openDropdowns, setOpenDropdowns] = useState(
    GET_FILTERS(isSelfProvider).DROPDOWNS
  );

  const chipsContainerRef = useRef();

  const { users, isUsersLoading } = useUsers();

  const currentUserId = auth.info.id;

  // ================= COMPUTED_STATE ==================
  const selectedPartnersIds = getSelectedIds("partnerFilter", filters);
  const selectedRolesIds = getSelectedIds("roleFilter", filters);
  const selectedVenuesIds = getSelectedIds("venueFilter", filters);
  const selectedHomeVenuesIds = getSelectedIds("homeVenueFilter", filters);
  const selectedUsersIds = getSelectedIds("createdByFilter", filters);
  const selectedShiftStatusIds = getSelectedIds("shiftStatusFilter", filters);
  const selectedShiftTypes = getSelectedIds("shiftTypesFilter", filters);
  const selectedWeekDays = getSelectedIds("availabilityFilter", filters);
  // const selectedAvailabilityWeekDays

  const { homeVenues } = useHomeVenues();
  const homeVenueIds = homeVenues.map(venue => venue.id);

  const filtersArray = Object.values(filters);

  const selectedPartners = selectedPartnersIds.length
    ? partners.filter(partner => selectedPartnersIds.includes(partner.id))
    : partners;

  const rolesOfSelectedPartners = uniqBy(
    selectedPartners.map(partner => partner.roleRates).flat(),
    "roleId"
  );

  const venuesOfSelectedPartners = selectedPartners
    .map(partner => partner.venues)
    .flat();

  const isAnyFilterSelected = filtersArray.some(
    filter => filter.selectedItems.length
  );

  // ================= STATE HANDLERS ==================
  // ---- filters ----
  const handleSelect = (selectedItem, filterName) => {
    if (getSelectedIds(filterName, filters).includes(selectedItem.value)) {
      setFilters({
        ...filters,
        [filterName]: {
          ...filters[filterName],
          selectedItems: filters[filterName].selectedItems.filter(
            item => item.value !== selectedItem.value
          )
        }
      });
    } else {
      setFilters({
        ...filters,
        [filterName]: {
          ...filters[filterName],
          selectedItems: [...filters[filterName].selectedItems, selectedItem]
        }
      });
    }
  };

  const handleSearch = (value, filterName) => {
    setFilters({
      ...filters,
      [filterName]: {
        ...filters[filterName],
        searchTerm: value
      }
    });
  };

  const handleClear = filterName => {
    setFilters({
      ...filters,
      [filterName]: {
        ...filters[filterName],
        selectedItems: []
      }
    });
  };

  // ---- dropdown ----
  const toggleDropdown = name => {
    if (openDropdowns.main === true && name === "main") {
      setOpenDropdowns(GET_FILTERS(isSelfProvider).DROPDOWNS);
    } else {
      setOpenDropdowns({ ...openDropdowns, [name]: !openDropdowns[name] });
    }
  };

  const setDropdownOpen = name => {
    setOpenDropdowns({
      ...GET_FILTERS(isSelfProvider).DROPDOWNS,
      main: true,
      [name]: true
    });
  };

  // ================= DROPDOWN OPTIONS ==================
  const dropdownOptions = {
    partnerFilter: partners
      ?.map(partner => ({
        value: partner.id,
        label: partner.accountName,
        selected: selectedPartnersIds.includes(partner.id)
      }))
      .sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      ),

    roleFilter: rolesOfSelectedPartners
      ?.map(role => ({
        value: role.roleId,
        label: role.roleName,
        selected: selectedRolesIds.includes(role.roleId)
      }))
      .sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      ),

    venueFilter: venuesOfSelectedPartners
      .filter(venue =>
        isSelfProvider ? homeVenueIds.includes(venue.id) : true
      )
      ?.map(venue => ({
        value: venue.id,
        label: venue.name,
        selected: selectedVenuesIds.includes(venue.id)
      }))
      .sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      ),

    homeVenueFilter:
      isSelfProvider &&
      homeVenues
        ?.map(venue => ({
          value: venue.id,
          label: venue.name,
          selected: selectedHomeVenuesIds.includes(venue.id)
        }))
        .sort((a, b) =>
          a.label.toLowerCase().localeCompare(b.label.toLowerCase())
        ),

    createdByFilter: isUsersLoading
      ? []
      : formatUsers(users, currentUserId).map(user => ({
          ...user,
          selected: selectedUsersIds.includes(user.value)
        })),

    shiftStatusFilter: [
      {
        value: "CANCELLED_SHIFTS",
        label: "Cancelled shifts",
        selected: !!isShowCancelledShifts
      },
      {
        value: "CANCELLED_BOOKINGS",
        label: "Cancelled bookings",
        selected: !!isShowCancelledBookings
      }
    ],

    shiftTypesFilter: [
      {
        value: "DAY",
        label: "Day shifts",
        selected: selectedShiftTypes.includes("DAY")
      },
      {
        value: "NIGHT",
        label: "Night shifts",
        selected: selectedShiftTypes.includes("NIGHT")
      }
    ],

    availabilityFilter: [
      {
        value: "MON",
        label: "Monday",
        selected: selectedWeekDays.includes("MON")
      },
      {
        value: "TUE",
        label: "Tuesday",
        selected: selectedWeekDays.includes("TUE")
      },
      {
        value: "WED",
        label: "Wednesday",
        selected: selectedWeekDays.includes("WED")
      },
      {
        value: "THU",
        label: "Thursday",
        selected: selectedWeekDays.includes("THU")
      },
      {
        value: "FRI",
        label: "Friday",
        selected: selectedWeekDays.includes("FRI")
      },
      {
        value: "SAT",
        label: "Saturday",
        selected: selectedWeekDays.includes("SAT")
      },
      {
        value: "SUN",
        label: "Sunday",
        selected: selectedWeekDays.includes("SUN")
      }
    ]
  };

  // ================= USE EFFECTS =================

  useEffect(() => {
    handlePartnerSelect(selectedPartnersIds);
    clearFilteredOptions(filters, setFilters, dropdownOptions);
  }, [selectedPartnersIds.length]);

  useEffect(() => {
    setRoleIds(selectedRolesIds);
  }, [selectedRolesIds.length]);

  useEffect(() => {
    setVenueIds(selectedVenuesIds);
  }, [selectedVenuesIds.length]);

  useEffect(() => {
    setHomeVenueIds(selectedHomeVenuesIds);
  }, [selectedHomeVenuesIds?.length]);

  useEffect(() => {
    setSelectedUserIds(selectedUsersIds);
  }, [selectedUsersIds.length]);

  useEffect(() => {
    setIsShowCancelledShifts(
      !!selectedShiftStatusIds.includes("CANCELLED_SHIFTS")
    );
  }, [selectedShiftStatusIds.length]);

  useEffect(() => {
    setIsShowCancelledBookings(
      !!selectedShiftStatusIds.includes("CANCELLED_BOOKINGS")
    );
  }, [selectedShiftStatusIds.length]);

  useEffect(() => {
    setShiftTypes(selectedShiftTypes);
  }, [selectedShiftTypes.length]);

  useEffect(() => {
    setAvailabilityWeekDays(selectedWeekDays);
  }, [selectedWeekDays.length]);

  useEffect(() => {
    const preselectedFilters = getProvideScheduleFilters({
      id: currentUserId
    });
    if (preselectedFilters) {
      setFilters({
        ...GET_FILTERS(isSelfProvider).FILTERS,
        ...preselectedFilters
      });
    }
  }, []);

  useEffect(() => {
    const filtersToSave = { ...filters };
    // Do not save searchTerm into local storage
    Object.keys(filtersToSave).forEach(
      filterKey =>
        (filtersToSave[filterKey] = {
          ...filtersToSave[filterKey],
          searchTerm: ""
        })
    );
    setProvideScheduleFilters({
      id: currentUserId,
      filters: filtersToSave
    });
  }, [filters]);

  // ================= RENDER =================
  return (
    <StyledFilterWrapper>
      <RotaDropdownHeader
        labelText={"Filter by..."}
        isOpen={openDropdowns.main}
        setIsOpen={() => toggleDropdown("main")}
        shouldShowDot={isAnyFilterSelected}
      >
        <StyledDropdownsContainer>
          {filtersArray.map(
            ({ name, displayName, searchPlaceholder }, index) => (
              <div key={name}>
                <StyledRotaDropdownHeader
                  labelText={displayName.toUpperCase()}
                  isOpen={openDropdowns[name]}
                  setIsOpen={() => toggleDropdown(name)}
                  shouldShowDot={!!filters[name].selectedItems.length}
                  opensToRight
                  onClose={() => handleSearch("", name)}
                  searchPlaceholder={searchPlaceholder}
                >
                  <RotaMultiSelectMenu
                    items={filterBySearchTerm(
                      dropdownOptions[name],
                      filters[name].searchTerm
                    )}
                    onSearch={value => handleSearch(value, name)}
                    onSelect={item => handleSelect(item, name)}
                    onClear={() => handleClear(name)}
                    showSearchInput={
                      !["shiftStatusFilter", "shiftTypesFilter"].includes(name)
                    }
                  />
                </StyledRotaDropdownHeader>
                {!isLastItem(filtersArray, index) && <StyledSeparator />}
              </div>
            )
          )}
        </StyledDropdownsContainer>
      </RotaDropdownHeader>
      <StyledChipsContainer ref={chipsContainerRef}>
        <FilterChips
          filters={filters}
          handleClear={handleClear}
          setDropdownOpen={setDropdownOpen}
          containerWidth={chipsContainerRef?.current?.clientWidth}
        />
      </StyledChipsContainer>
    </StyledFilterWrapper>
  );
};

export default FilterDropdown;

import React from "react";
import { Route } from "react-router-dom";

import useAuth from "~/src/auth/hooks/use-auth";
import NotFound from "~/src/components/not-found";

export default ({ component: C, role, extraProps, ...props }) => {
  const { authed, roles } = useAuth();
  const allow = authed && (role ? roles.includes(role) : true);

  return (
    <Route
      {...props}
      render={componentProps =>
        allow ? <C {...componentProps} {...extraProps} /> : <NotFound />
      }
    />
  );
};

import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

import { addAuthVars } from "@teamrota/authlib";

export const VALIDATE_PAYROLL_CALCULATION = addAuthVars(gql`
  mutation validatePayrollCalculation($name: String!, $config: [JSONObject]!) {
    validatePayrollCalculation(name: $name, config: $config)
  }
`);

export const useValidatePayrollCalculation = () => {
  const [update, { loading, error }] = useMutation(
    VALIDATE_PAYROLL_CALCULATION,
    {
      onError: error => {
        throw error;
      }
    }
  );

  const validatePayrollCalculation = async ({
    name,
    config
  }: {
    name: string;
    config: JSON;
  }) => {
    await update({
      variables: {
        name,
        config
      }
    });
  };

  return { validatePayrollCalculation, loading, error };
};

import { useEffect, useState } from "react";
import { update } from "lodash/fp";

const useLoadMore = ({ dataLength, fetchMore, nameData }) => {
  const [offset, setOffset] = useState(0);

  const handleLoadMore = () => {
    setOffset(offset => offset + 10);
  };

  useEffect(() => {
    if (offset > dataLength)
      fetchMore({
        variables: {
          offset
        },
        updateQuery: (previousResult, { fetchMoreResult }) =>
          update(nameData, current => [
            ...current,
            ...fetchMoreResult[nameData]
          ])(previousResult)
      });
  }, [offset]);

  return [handleLoadMore];
};

export default useLoadMore;

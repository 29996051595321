import { STYLE_CONSTS } from "~/src/styles/config.style";

import styled from "styled-components";

export const StyledDialog = styled.div`
  min-width: ${({ input }) => (input ? "389px" : "516")};
  min-height: 285px;
  overflow-y: hidden;
  border-radius: 10px;
`;

export const useStyles = () => ({
  dialogPaperInput: {
    minHeight: "330px",
    width: "389px",
    overflowY: "hidden",
    borderRadius: "10px"
  }
});

export const Styled_DialogActions = styled.div`
  display: flex;
  padding: 30px 40px;
  justify-content: center;
`;

export const Styled_DialogContent = styled.div`
  height: auto;
`;

export const Styled_DialogTitleText = styled.div`
  border: solid #e0e0e0;
  border-width: 0 0 1px 0;
  font-size: ${STYLE_CONSTS.FONT_LARGE};
  padding: 33px 44px;
  font-weight: 400;
`;
export const Styled_DialogText = styled.div`
  padding: 6% 8%;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: -0.3px;
`;

import React, { useEffect, useState } from "react";
import map from "lodash/fp/map";
import join from "lodash/fp/join";
import flow from "lodash/fp/flow";
import take from "lodash/fp/take";

import Modal from "~/src/components/modal";
import MemberPhoto from "~/src/components/member-photo";
import Stars from "~/src/components/stars";
import Textarea from "~/src/components/form-components/textarea";
import Button from "~/src/components/button";
import { checkErrors, errorModal } from "~/src/utils/errors";
import { stallPromise } from "~/src/utils";
import { useQuery, useMutation } from "@apollo/client";
import { CREATE_UPDATE_MEMBER_NOTES } from "../../graphql/create-update-member-notes";
// NOTE: shouldNotShow prop passed to shift and divider is an experimental feature where by the actual overall
// shifts are not displayed to the partner to help stimulate the selection of non favorite
// members
import {
  Wrapper,
  StarsWrapper,
  Name,
  Roles,
  ToggleMoreRoles,
  Shift,
  NumberWrapper,
  TextInputWrapper,
  Divider,
  LinkedInProfileWrapper,
  SummaryWrapper,
  ExperienceTimelineWrapper,
  ContainerWrapper,
  TitleWrapper,
  LinkWrapper
} from "./member-modal.styles";
import MemberExperience from "../../../modals/view-edit-member/member-experience";
import { GET_SINGLE_MEMBER } from "../../graphql/get-single-member";

const MemberModal = ({
  isOpen,
  isLoading,
  onClose,
  memberId,
  sourceAccountId
}) => {
  const [showAllRoles, setShowAllRoles] = useState(false);
  const [notesState, setNotesState] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [hasSaved, setHasSaved] = useState(false);

  const { data } = checkErrors(
    useQuery(GET_SINGLE_MEMBER, {
      variables: { memberId: memberId, sourceAccountId: sourceAccountId }
    })
  );

  const [createOrUpdateNote] = useMutation(CREATE_UPDATE_MEMBER_NOTES);

  const fetchedMember = data?.account?.member;

  useEffect(() => {
    setNotesState(fetchedMember?.notes);
  }, [fetchedMember?.notes]);

  const handleSaveNotes = async () => {
    setIsSaving(true);
    try {
      await stallPromise(
        createOrUpdateNote({
          refetchQueries: ["getMember"],
          variables: {
            tableName: "members",
            tableId: fetchedMember?.id,
            note: notesState
          }
        })
      );

      setIsSaving(false);
      setHasSaved(true);

      setTimeout(setHasSaved(false), 1000);
    } catch (e) {
      setIsSaving(false);
      errorModal(e);
    }
  };

  const roles =
    fetchedMember &&
    flow(
      map(role => role.name),
      take(showAllRoles ? fetchedMember?.roles?.length : 3),
      join(", ")
    )(fetchedMember?.roles);

  const getStars = () => {
    const memberRating = fetchedMember?.rating || 4.5;
    return memberRating === null ? 4.5 : memberRating;
  };

  const experience = fetchedMember?.profile?.experience || null;
  const linkedInURL = fetchedMember?.profile?.linkedInProfile || "";

  return (
    <Modal isOverlay isOpen={isOpen} onClose={onClose} isLoading={isLoading}>
      {fetchedMember && (
        <Wrapper>
          <MemberPhoto
            src={fetchedMember?.photo}
            size="large"
            poolType={fetchedMember?.relationship?.poolType}
          />
          <StarsWrapper>
            <Stars value={getStars()} />
          </StarsWrapper>
          <Name>{`${fetchedMember?.firstName} ${fetchedMember?.lastName}`}</Name>
          <LinkedInProfileWrapper>
            <LinkWrapper href={linkedInURL} target="_blank">
              {linkedInURL}
            </LinkWrapper>
          </LinkedInProfileWrapper>
          <SummaryWrapper>
            <ContainerWrapper>
              {fetchedMember?.profile?.summary || ""}
            </ContainerWrapper>
          </SummaryWrapper>
          <ExperienceTimelineWrapper hasExperience={experience}>
            <TitleWrapper> Experience </TitleWrapper>
            <MemberExperience memberExperiences={experience} />
          </ExperienceTimelineWrapper>
          <Roles>{roles}</Roles>
          {fetchedMember?.roles?.length > 3 && (
            <ToggleMoreRoles onClick={() => setShowAllRoles(!showAllRoles)}>
              {showAllRoles ? "Less" : "More"}
            </ToggleMoreRoles>
          )}
          <div>
            <Shift shouldNotShow>
              Shifts Completed
              <NumberWrapper>
                {fetchedMember?.bookingsCount?.allPreviouslyAccepted}
              </NumberWrapper>
            </Shift>
            <Divider shouldNotShow />
            <Shift>
              Shifts with you
              <NumberWrapper>
                {fetchedMember?.bookingsCount?.previouslyAccepted}
              </NumberWrapper>
            </Shift>
          </div>
          <TextInputWrapper>
            <Textarea
              value={notesState}
              label="Notes"
              rowCount={6}
              isResizable={false}
              onChangeValue={note => setNotesState(note)}
            />
          </TextInputWrapper>
          <Button
            onClick={() => handleSaveNotes()}
            isLoading={isSaving}
            isSuccess={hasSaved}
            isUsingFlexbox
          >
            {hasSaved ? "Saved" : "Save"}
          </Button>
        </Wrapper>
      )}
    </Modal>
  );
};

export default MemberModal;

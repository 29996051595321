import React, { Component } from "react";
import { getOr } from "lodash/fp";
import { TileLayer, FeatureGroup, Polygon } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import DivIcon from "react-leaflet-div-icon";
import Icon from "~/src/components/icon";
import { COLORS } from "~/src/styles/config.style";
import { MapWrapper, StyledIcon } from "./map.styles";

const THEME = {
  dark:
    "https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png",
  blackAndWhite: "http://{s}.tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png",
  color: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
  fade:
    "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
};

const DEFAULT_MARKER = {
  lat: 51.505,
  lng: -0.09
};

const getCoords = layer =>
  layer.toGeoJSON().geometry.coordinates[0].map(([lng, lat]) => ({
    lat,
    lng
  }));

export default class Map extends Component {
  onChangePath = path => {
    if (this.props.onChangePath) {
      this.props.onChangePath(path);
    }
  };

  onCreatePath = e => {
    const path = getCoords(e.layer);

    this.onChangePath(path);
  };

  onEditPath = e => {
    const editedLayers = [];

    e.layers.eachLayer(layer => {
      editedLayers.push(...getCoords(layer));
    });

    this.onChangePath(editedLayers);
  };

  onDeletePath = () => {
    this.onChangePath([]);
  };

  getMarkerPosition = marker => [
    getOr(DEFAULT_MARKER.lat, "lat", marker),
    getOr(DEFAULT_MARKER.lng, "lng", marker)
  ];

  handleDragEnd = () => {
    if (this.props.onDragMarker) {
      const { lat, lng } = this.leafletMarkerRef.leafletElement.getLatLng();
      this.props.onDragMarker({ lat, lng });
    }
  };

  leafletMarkerRef = null;

  props;

  leafletMarkerRef;

  leafletPolygonRef;

  render() {
    const { props } = this;
    // Take first marker as default for centering the map
    const markerPosition = this.getMarkerPosition(
      props.markers && props.markers[0]
    );
    return (
      <MapWrapper
        style={{ ...(props.style || {}) }}
        bounds={props.fitBounds}
        height={props.height}
        width={props.width}
        center={markerPosition}
        zoom={13}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url={THEME[props.theme || "fade"]}
        />
        {props.isPathEditable && (
          <FeatureGroup>
            <EditControl
              position="topright"
              onEdited={this.onEditPath}
              onCreated={this.onCreatePath}
              onDeleted={this.onDeletePath}
              draw={{
                rectangle: false,
                polyline: false,
                marker: false,
                circle: false, // leaflet bug as of 26/07/2017 stop circles working
                circlemarker: false,
                path: false
              }}
            />
            <Polygon
              positions={props.polygonPath && [props.polygonPath]}
              style={{ color: COLORS.MAIN_ORANGE }}
              ref={ref => {
                this.leafletPolygonRef = ref;
              }}
            />
          </FeatureGroup>
        )}
        {!props.shouldHideMarker &&
          props.markers &&
          props.markers.map((marker, i) => (
            <DivIcon
              key={i}
              ref={ref => {
                this.leafletMarkerRef = ref;
              }}
              position={this.getMarkerPosition(marker)}
              draggable
              onDragend={this.handleDragEnd}
            >
              <StyledIcon
                name={Icon.names.ORANGE_PIN}
                size={Icon.sizes.LARGE}
              />
            </DivIcon>
          ))}
      </MapWrapper>
    );
  }
}

import React from "react";
import { connect } from "react-redux";

import { StyledScheduleCells } from "~/src/containers/scheduler/GridCard/grid-card.styles";
import ShiftCell from "./ShiftCell/shift-cell";

const CellRow = ({
  startTime,
  scheduledMember,
  onPressShiftCell,
  onPressGridCell,
  onPressAvailabilityBadge,
  showCancelledShifts,
  selectedDayIndex,
  isDailyView,
  isWeeklyView,
  isMemberAvailabilityEnabled,
  assignMemberToShift
}) => {
  const daysArray = new Array(isWeeklyView ? 7 : 2).fill(null);

  return (
    <StyledScheduleCells>
      {daysArray.map((_, i) => {
        const dayIndex = isWeeklyView ? i : selectedDayIndex;

        return (
          <ShiftCell
            key={i}
            dayIndex={dayIndex}
            cellIndex={i}
            scheduledMember={scheduledMember}
            startTime={startTime}
            isMemberAvailabilityEnabled={isMemberAvailabilityEnabled}
            isDailyView={isDailyView}
            isWeeklyView={isWeeklyView}
            onPressShiftCell={onPressShiftCell}
            onPressGridCell={onPressGridCell}
            onPressAvailabilityBadge={availabilityStartAt =>
              onPressAvailabilityBadge(i, availabilityStartAt)
            }
            showCancelledShifts={showCancelledShifts}
            assignMemberToShift={assignMemberToShift}
          />
        );
      })}
    </StyledScheduleCells>
  );
};

const mapStateToProps = s => ({
  showCancelledShifts: s.scheduler?.showCancelledShifts
});

export default connect(mapStateToProps)(CellRow);

import React from "react";
import flow from "lodash/fp/flow";
import { RotaButtonNew } from "@teamrota/rota-design";
import Modal from "~/src/components/modal";
import { formatFullName } from "~/src/utils/formatting";
import Details from "~/src/components/member-details";
import ModalContent from "~/src/containers/modals/components/modal-content";
import Divider from "~/src/containers/modals/components/divider";
import Title from "~/src/containers/modals/components/title";
import Text from "~/src/containers/modals/components/text";
import Tabs from "~/src/containers/modals/components/tabs";
import { errorModal } from "~/src/utils/errors";
import {
  LeftPane,
  RightPane
} from "~/src/containers/modals/components/content-wrapper";
import asyncConfirm from "~/src/utils/async-confirm";
import createUpdateUser from "~/src/graphql/mutations/create-update-user";
import deleteItem from "~/src/graphql/mutations/delete-item/delete-item.decorator";
import Info from "./info";
import Documents from "./documents";
import getUser from "./graphql/get-user";

import { Role } from "@teamrota/authlib";
import useAuth from "~/src/auth/hooks/use-auth";

const refetchQueries = ["getProfile", "getUsers"];

export const ViewEditUserComponent = ({
  isOpen,
  onClose,
  isLoading,
  user,
  userId,
  createUpdateUser,
  refetchUser,
  afterSubmitNewUser,
  deleteItem,
  afterDeleteUser
}) => {
  if (!isOpen) return false;

  const auth = useAuth();

  const canDeleteUser =
    auth.hasRole(Role.USER_DELETE) &&
    parseInt(auth.info.id) !== parseInt(userId);

  const handleDeleteUser = async () => {
    if (
      await asyncConfirm("Are you sure you want to delete this user?", {
        shouldAddPadding: false,
        confirmButtonText: "Delete"
      })
    ) {
      try {
        await deleteItem({
          variables: {
            type: "users",
            id: userId
          }
        });
        if (afterDeleteUser) {
          await afterDeleteUser();
        }
        onClose();
      } catch (e) {
        errorModal(e);
      }
    }
  };

  const handleGeneratePin = async () => {
    try {
      await createUpdateUser(refetchQueries)({
        id: userId,
        email: user.email,
        shouldRegeneratePin: true
      });
    } catch (e) {
      errorModal(e);
    }
  };

  const tabs = [
    {
      name: "Info",
      content: (
        <Info
          user={user}
          afterSubmitNewUser={afterSubmitNewUser}
          createUpdateUser={createUpdateUser(refetchQueries)}
        />
      )
    }
  ];

  const isAddUser = !userId;

  if (!isAddUser) {
    tabs.push({
      name: "Documents",
      content: <Documents user={user} refetchUser={refetchUser} />
    });
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isLoading={isLoading}>
      <ModalContent title={isAddUser ? "New User" : formatFullName(user)}>
        <LeftPane>
          <Title marginBottom>Team</Title>
          <Details
            firstName={user?.account?.accountName}
            photo={user?.account?.logo}
            id={user?.account?.id}
          />
          <Divider width={270} />
          {!isAddUser && (
            <div>
              <Title marginTop>Pin</Title>
              <Text>{user?.pin || "no pin"}</Text>
              <RotaButtonNew variant="outlined" onClick={handleGeneratePin}>
                Generate a new pin
              </RotaButtonNew>
            </div>
          )}
          {!isAddUser && canDeleteUser && (
            <div>
              <Title marginTop>Other</Title>
              <RotaButtonNew variant="outlined" onClick={handleDeleteUser}>
                Delete user
              </RotaButtonNew>
            </div>
          )}
        </LeftPane>
        <RightPane>
          <Tabs tabs={tabs} defaultTab="Info" />
        </RightPane>
      </ModalContent>
    </Modal>
  );
};

export default flow(
  getUser,
  createUpdateUser,
  deleteItem
)(ViewEditUserComponent);

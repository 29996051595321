import React from "react";
import { Title, Separator } from "./styles";

interface RuleDescriptionProps {
  id: string;
  payrollRateCards: any[];
}

const RuleDescription: React.FC<RuleDescriptionProps> = ({
  id,
  payrollRateCards
}) => {
  const payrollRateCard = payrollRateCards?.find?.(p => p.id === id);
  if (!payrollRateCard) return <></>;

  return (
    <Title>
      {payrollRateCard.partnerAccount.accountName}
      <Separator />
      {payrollRateCard.role.name}
      <Separator />
      {payrollRateCard.allVenues ? "All venues" : payrollRateCard.venue?.name}
      <Separator />
      {payrollRateCard.payrollScale.name}
      <Separator />
      {payrollRateCard.payrollRate.name}
      <Separator />
      {payrollRateCard.payrollCalculation.name}
      <Separator />
      {payrollRateCard.payrollRule.name}
    </Title>
  );
};

export default RuleDescription;

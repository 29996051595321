import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

import { addAuthVars } from "@teamrota/authlib";

export const UPDATE_PAYROLL_BREAK = addAuthVars(gql`
  mutation updatePayrollBreak(
    $id: ID!
    $name: String!
    $config: [JSONObject]!
  ) {
    updatePayrollBreak(id: $id, name: $name, config: $config) {
      id
      name
      config
    }
  }
`);

export const useUpdatePayrollBreak = () => {
  const [update, { loading, error }] = useMutation(UPDATE_PAYROLL_BREAK, {
    onError: error => {
      throw error;
    }
  });
  const updatePayrollBreak = async ({
    id,
    name,
    config
  }: {
    id: string;
    name: string;
    config: JSON;
  }) => {
    await update({
      variables: {
        id,
        name,
        config
      },
      refetchQueries: ["payrollBreaks"]
    });
  };

  return { updatePayrollBreak, loading, error };
};

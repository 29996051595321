import React, { useState, useReducer } from "react";
import { useForm } from "react-hook-form";

import { RotaSnackBar } from "@teamrota/rota-design";
import { RotaButton, RotaTextarea } from "@teamrota/rota-design";
import { Role } from "@teamrota/authlib";
import { getCountryByPhoneNumber } from "@teamrota/rota-common";

import Loading from "~/src/components/loading";
import useAuth from "~/src/auth/hooks/use-auth";
import HasRole from "~/src/containers/has-role";
import { LoadingWrapper } from "~/src/components/load-more/load-more.styles";
import getProfile from "~/src/graphql/queries/get-profile/get-profile-query.decorator";

import TagsContainer from "./tags-container";
import PaymentDetails from "./payment-details";
import ContactDetails from "./contact-details";
import PersonalDetails from "./personal-details";
import AdditionalInformation from "./additional-information";
import MemberOwner from "./member-owner";
import MemberTypeDropdown from "./member-type-dropdown";
import { getValuesFromProps, getValuesToSave } from "./schema";
import { Form, Section, Row } from "./styles";
import createOrUpdateMember from "../graphql/create-or-update-member";
import { useUpcomingBookingsCount } from "../graphql/use-upcoming-bookings-count";
import { useDefaultMemberAccount } from "../graphql/use-default-member-account";

const initialState = {
  inEditMode: false,
  isSnackOpen: false,
  isSaving: false,
  hasSaved: false,
  errorMessage: ""
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SUBMIT_START": {
      return { ...state, errorMessage: "", isSaving: true };
    }
    case "SUBMIT_END": {
      return { ...state, inEditMode: false, isSaving: false, hasSaved: true };
    }
    case "SUBMIT_ERROR": {
      return {
        ...state,
        inEditMode: false,
        isSaving: false,
        hasSaved: false,
        isSnackOpen: true,
        errorMessage: action.errorMessage
      };
    }
    case "SET_EDIT_MODE_ON": {
      return { ...state, hasSaved: false, inEditMode: true };
    }
    case "SET_EDIT_MODE_OFF": {
      return { ...state, inEditMode: false };
    }
    case "CLOSE_SNACK": {
      return { ...state, isSnackOpen: false };
    }
    default:
      throw new Error();
  }
};

const MemberInfoComponent = props => {
  const {
    isAddMember,
    memberId,
    onCreate,
    createOrUpdateMember,
    member,
    user
  } = props;

  const auth = useAuth();
  const [
    { inEditMode, isSnackOpen, isSaving, hasSaved, errorMessage },
    dispatch
  ] = useReducer(reducer, initialState);
  const accountId = user?.account?.id;

  const initialValues = getValuesFromProps(auth, props);
  const [tags, setTags] = useState(initialValues?.tags || []);
  const [serviceAreas, setServiceAreas] = useState(false);
  const [memberState, setMemberState] = useState(initialValues?.state);
  const isAllowedToViewMemberDetails = auth.hasRole(Role.MEMBERS_VIEW);
  const {
    memberTypesAvailableForAccount,
    isLoadingAccountMemberTypes
  } = useDefaultMemberAccount();
  const { upcomingBookingsCount } = useUpcomingBookingsCount({ memberId });

  const {
    countryCode: phoneCountry,
    number: phoneNumber
  } = getCountryByPhoneNumber(initialValues?.phone ?? null);

  const {
    countryCode: emergencyPhoneCountry,
    number: emergencyPhoneNumber
  } = getCountryByPhoneNumber(initialValues?.emergencyContactNumber ?? null);

  const values = {
    ...initialValues,
    birthDate: null,
    startDate: null,
    endDate: null,
    phoneCountry,
    phoneNumber,
    emergencyPhoneCountry,
    emergencyPhoneNumber
  };

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    setValue,
    setError
  } = useForm({
    mode: "all",
    defaultValues: values
  });

  const onDeleteTag = (tagIndex, tags) =>
    tags.filter(tag => {
      return parseInt(tags[tagIndex].id, 10) !== parseInt(tag?.id, 10);
    });

  const onSubmit = args =>
    createOrUpdateMember(memberId, getValuesToSave(auth, args));

  const afterSubmit = isAddMember ? ({ id }) => onCreate(id) : () => {};

  const saveMemberInfo = async data => {
    const updatedData = {
      ...data,
      sortCode: data?.sortCode?.replaceAll("-", ""),
      memberType:
        data?.memberType?.replace(/ /g, "_") ||
        memberTypesAvailableForAccount?.defaultMemberType,
      tags,
      serviceAreas: serviceAreas || null,
      state: memberState,
      venueId: data?.homeVenue
    };

    try {
      dispatch({ type: "SUBMIT_START" });
      const newItem = await onSubmit(updatedData);
      if (afterSubmit) afterSubmit(newItem);
      dispatch({ type: "SUBMIT_END" });
    } catch (e) {
      console.log(e);
      dispatch({
        type: "SUBMIT_ERROR",
        errorMessage: "An error occured, please check your changes."
      });
    }
  };

  const isSaveButtonDisabled = !!Object.keys(errors).length;

  return (
    <>
      <Form onSubmit={handleSubmit(saveMemberInfo)}>
        <Section>
          <PersonalDetails
            register={register}
            errors={errors}
            setError={setError}
            control={control}
            initialValues={initialValues}
            inEditMode={inEditMode || isAddMember}
            isAllowedToViewMemberDetails={isAllowedToViewMemberDetails}
          />
          <ContactDetails
            register={register}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            initialValues={initialValues}
            inEditMode={inEditMode || isAddMember}
            isAllowedToViewMemberDetails={isAllowedToViewMemberDetails}
          />
          <HasRole role={Role.MEMBERS_BANK}>
            <PaymentDetails
              register={register}
              errors={errors}
              getValues={getValues}
              setValue={setValue}
              initialValues={initialValues}
              serviceAreas={props?.serviceAreas}
              setServiceAreas={setServiceAreas}
              control={control}
              inEditMode={
                auth.hasRole(Role.MEMBERS_BANK_EDIT)
                  ? inEditMode || isAddMember
                  : false
              }
            />
          </HasRole>
          <HasRole role={Role.MEMBERS_VIEW}>
            {isLoadingAccountMemberTypes ? (
              <LoadingWrapper>
                <Loading color="black" />
              </LoadingWrapper>
            ) : (
              <MemberTypeDropdown
                register={register}
                watch={watch}
                errors={errors}
                initialValues={initialValues}
                memberId={memberId}
                member={member}
                control={control}
                getValues={getValues}
                setValue={setValue}
                memberTypesAvailableForAccount={memberTypesAvailableForAccount}
                inEditMode={inEditMode || isAddMember}
              >
                <MemberOwner
                  errors={errors}
                  initialValues={initialValues}
                  control={control}
                  inEditMode={inEditMode || isAddMember}
                  isAddMember={isAddMember}
                />
              </MemberTypeDropdown>
            )}
          </HasRole>

          <AdditionalInformation
            register={register}
            watch={watch}
            errors={errors}
            control={control}
            initialValues={initialValues}
            user={user}
            setMemberState={setMemberState}
            memberState={memberState}
            upcomingBookingsCount={upcomingBookingsCount}
            inEditMode={inEditMode || isAddMember}
            isRightToWorkValid={member?.isRightToWorkValid}
            isAllowedToViewMemberDetails={isAllowedToViewMemberDetails}
          />
          <HasRole role={Role.MEMBERS_VIEW}>
            <TagsContainer
              setTags={setTags}
              tags={tags}
              emptyMessage="There were no tags added"
              accountId={accountId}
              onDeleteTag={onDeleteTag}
              inEditMode={inEditMode || isAddMember}
            />
          </HasRole>
          <Row>
            <RotaTextarea
              {...register("notes")}
              label={"Notes"}
              placeholder={initialValues?.notes}
              isDisabled={!inEditMode}
            />
          </Row>
        </Section>
        <Row style={{ margin: "20px 0", justifyContent: "end" }}>
          {!inEditMode && !isAddMember && (
            <HasRole role={Role.MEMBERS_EDIT}>
              <RotaButton
                onClick={() => dispatch({ type: "SET_EDIT_MODE_ON" })}
                variant="outlined"
                size="small"
                text="Edit"
                colors="grey"
                style={{ marginBottom: 10 }}
              >
                Edit
              </RotaButton>
            </HasRole>
          )}
          {inEditMode && (
            <RotaButton
              onClick={() => dispatch({ type: "SET_EDIT_MODE_OFF" })}
              variant="outlined"
              size="small"
              colors="grey"
              style={{ marginRight: "10px" }}
            >
              Cancel
            </RotaButton>
          )}
          {(inEditMode || isAddMember) && (
            <RotaButton
              size="small"
              disabled={isSaving || isSaveButtonDisabled}
              type="submit"
            >
              {hasSaved ? "Saved" : "Save"}
            </RotaButton>
          )}
        </Row>
      </Form>

      {isSnackOpen && (
        <RotaSnackBar
          snackOpen={isSnackOpen}
          onClose={() => {
            dispatch({ type: "CLOSE_SNACK" });
          }}
          message={errorMessage || "Success"}
          severity={errorMessage ? "error" : "success"}
        />
      )}
    </>
  );
};

export default getProfile(createOrUpdateMember(MemberInfoComponent));

import { gql, useQuery } from "@apollo/client";

export const GET_TAGS_BY_ACCOUNT = gql`
  query getTags {
    account {
      id
      tags {
        id
        name
        isCompulsory
      }
    }
  }
`;

export const useGetAccountTags = () => {
  const { data } = useQuery(GET_TAGS_BY_ACCOUNT);

  return { accountTags: data?.account?.tags ?? [] };
};

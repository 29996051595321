import { gql } from "@apollo/client";

export const UPDATE_NEW_TIMESHEETS_START_DATE = gql`
  mutation($accountId: ID!, $newTimesheetsStartDate: Date) {
    updateNewTimesheetsStartDate(
      accountId: $accountId
      newTimesheetsStartDate: $newTimesheetsStartDate
    )
  }
`;

export default UPDATE_NEW_TIMESHEETS_START_DATE;

import styled, { keyframes } from "styled-components";
import { COLORS, STYLE_CONSTS } from "~/src/styles/config.style";

export const Note = styled.div`
  color: ${COLORS.COOL_GREY};
  margin-top: ${({ marginTop }) => (marginTop ? STYLE_CONSTS.UNIT_2 : 0)};
  text-transform: ${({ uppercase }) => (uppercase ? "uppercase" : "none")};
`;

export const Link = styled.a`
  display: block;
  color: ${({ isDisabled, theme }) =>
    isDisabled ? COLORS.COOL_GREY : theme.primary.main};
  cursor: ${({ isDisabled }) => (isDisabled ? "default" : "pointer")};
  &:hover {
    text-decoration: ${({ isDisabled }) => (isDisabled ? "none" : "underline")};
    color: ${({ isDisabled, theme }) =>
      isDisabled ? COLORS.COOL_GREY : theme.primary.main};
  }
`;

export const ResendEmail = styled.div`
  position: relative;
`;

const fadeOutUp = keyframes`
  from {
    opacity: 1;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -300%, 0);
  }
`;

export const SentMessage = styled.div`
  animation: ${fadeOutUp} 1s forwards ease;
  background-color: ${({ theme }) => theme.neutral.lighter};
  border-radius: 5px;
  display: inline-block;
  padding: ${STYLE_CONSTS.UNIT_HALF};
`;

import { graphql } from "@apollo/client/react/hoc";
import { gql } from "@apollo/client";
import { flow } from "lodash";

import { addAuthVars } from "@teamrota/authlib";

import { withAuth } from "~/src/auth/hoc";
import { handleErrors } from "~/src/utils/errors";

export const GET_ACCOUNT_VENUES = addAuthVars(gql`
  query getAccountVenues($accountId: ID!) {
    account {
      id
      targetAccount(accountId: $accountId) {
        id
        accountName
        venues {
          id
          name
          subvenues {
            id
            name
          }
        }
      }
    }
  }
`);

export default flow(
  graphql(GET_ACCOUNT_VENUES, {
    skip: props => !props.isOpen,
    props: handleErrors(({ data: { loading, account } }) => ({
      isLoading: loading,
      venues: account?.targetAccount?.venues
    })),
    options: ({ auth, sourceAccountId }) => ({
      fetchPolicy: "network-only",
      variables: auth.addVals(GET_ACCOUNT_VENUES, {
        accountId: sourceAccountId
      })
    })
  }),
  withAuth
);

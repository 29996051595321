import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { useToast } from "@teamrota/rota-design";
import { useCallback } from "react";

const TIMESHEET_MEMBERS_APPROVE = gql`
  mutation approveTimesheetMembers($timesheetMemberIds: [ID]!) {
    approveTimesheetMembers(timesheetMemberIds: $timesheetMemberIds) {
      id
      status
    }
  }
`;
interface Params {
  onCompleted?: (count: number) => void;
}

export const useApproveTimesheetMembers = ({ onCompleted }: Params) => {
  const { showToast } = useToast();
  const [approve, { loading }] = useMutation(TIMESHEET_MEMBERS_APPROVE, {
    onError: error => {
      showToast(error.message, { severity: "error" });
    },
    onCompleted: ({ approveTimesheetMembers }) => {
      onCompleted && onCompleted(approveTimesheetMembers.length);
    },
    refetchQueries: ["TimesheetsDetails", "TimesheetMembersSummary"]
  });

  const approveTimesheetMembers = useCallback(
    async ({ timesheetMemberIds }) => {
      await approve({ variables: { timesheetMemberIds } });
    },
    [approve]
  );

  return {
    approveTimesheetMembers,
    loading
  };
};

import { gql } from "@apollo/client";

const ARCHIVE_TARGET_ACCOUNT = gql`
  mutation archiveTargetAccount($id: ID!) {
    archiveTargetAccount(id: $id) {
      id
    }
  }
`;

export default ARCHIVE_TARGET_ACCOUNT;

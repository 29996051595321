import React from "react";
import Icon from "../../icon";
import { Input, TimeInput } from "./text-input.styles";
import {
  InputWrapper,
  IconWrapper,
  InputInnerWrapper,
  Label,
  ErrorLabel,
  CharLimit
} from "../input-styles";

const TextInput = ({
  type,
  isError,
  errorLabel,
  isLabelGrey,
  label,
  iconLeft,
  isSmall,
  isDisabled,
  onClick,
  onRegister,
  onKeyDown,
  className,
  isRounded,
  borderRadiusSize,
  min,
  max,
  value,
  iconRight,
  showMaxLength,
  onFocus,
  onBlur,
  shouldFixLabelHeight,
  isInline,
  onChange,
  onChangeValue,
  placeholder,
  rowCount,
  isRequired,
  fontColor,
  isBorderless,
  maxLength,
  isOutline,
  noFocus,
  centerText,
  fontSizeOveride
}) => {
  const Field = type === "time" ? TimeInput : Input;

  const isErrorCond = isError || errorLabel;

  return (
    <InputWrapper isError={isError}>
      <Label isLabelGrey={isLabelGrey} isVisible={!!label}>
        {label}
      </Label>
      <InputInnerWrapper isInline={isInline}>
        {!!iconLeft && (
          <IconWrapper isSmall={isSmall} isLeft>
            {iconLeft}
          </IconWrapper>
        )}
        <Field
          bgColor={isDisabled}
          onClick={onClick}
          ref={onRegister}
          onKeyDown={onKeyDown}
          className={className}
          isRounded={isRounded}
          borderRadiusSize={borderRadiusSize}
          isError={isErrorCond}
          min={min}
          max={max}
          value={value}
          type={type || "text"}
          hasIconRight={iconRight || showMaxLength}
          hasIconLeft={iconLeft}
          onFocus={onFocus}
          onBlur={onBlur}
          shouldFixLabelHeight={shouldFixLabelHeight}
          errorLabel={errorLabel}
          isInline={isInline}
          isSmall={isSmall}
          onChange={handleChange(onChange, onChangeValue)}
          placeholder={placeholder}
          rows={rowCount}
          required={isRequired}
          isBorderless={isBorderless}
          fontColor={fontColor}
          maxLength={maxLength}
          disabled={isDisabled}
          isOutline={isOutline}
          noFocus={noFocus}
          centerText={centerText}
          fontSizeOveride={fontSizeOveride}
        />
        {!!showMaxLength && (
          <CharLimit>{Number(maxLength) - (value?.length || 0)}</CharLimit>
        )}
        {!!iconRight && (
          <IconWrapper isSmall={isSmall} isRight>
            {iconRight}
          </IconWrapper>
        )}
      </InputInnerWrapper>
      <div style={{ height: "10px" }}></div>
      {errorLabel && <ErrorLabel>{errorLabel}</ErrorLabel>}
    </InputWrapper>
  );
};

TextInput.iconNames = Icon.names;

const handleChange = (onChange, onChangeValue) => {
  return e => {
    if (onChange) onChange(e);
    if (onChangeValue) onChangeValue(e.target ? e.target.value : e);
  };
};
export default TextInput;

import moment from "moment-timezone";
import { isEqual, sortBy } from "lodash/fp";
import { ZonedDate } from "@teamrota/rota-common";

import { dateRangeToHuman } from "~/src/utils/formatting";

export function formatRoleTime(shift) {
  const roleName = shift?.roleRate?.roleName ?? null;
  const numberRequsted = shift?.numberRequested ?? 0;

  const startTime = shift?.startTime ?? null;
  const endTime = shift?.endTime ?? null;

  if (!numberRequsted || !roleName || !startTime || !endTime) {
    return "";
  }

  const s = numberRequsted === 1 ? "" : "s";
  const date = dateRangeToHuman(startTime, endTime);
  return `${numberRequsted} ${roleName}${s} on ${date}`;
}

export function calculateShiftTotal(shift, roleRates) {
  const rate =
    roleRates.find(({ id }) => id === shift.roleRateId)?.chargeRate ?? 0;

  const hours = moment(shift.endTime).diff(shift.startTime, "hours");

  return rate
    .multiply(hours)
    .multiply(parseInt(shift.numberRequested, 10) || 0)
    .toUnit();
}

export function parseToSavableData(shiftFormState, replacementBookings) {
  const shift = shiftFormState.shifts[0];
  const replacementMemberIds = replacementBookings.map(
    booking => booking.memberId
  );

  return {
    shifts: [
      {
        replacementBookings,
        id: shift.id,
        name: shift.name,
        identifier: shift.identifier,
        numberRequested: parseInt(shift.numberRequested),
        roleRateId: shift.roleRateId,
        startTime: shift.startTime,
        endTime: shift.endTime,
        uniformTemplateId: shift.uniformTemplateId,
        venueId: shift.venueId,
        subvenueId: shift.subvenueId,
        briefing: shift.briefing,
        assignedMemberIds: shift.assignedMemberIds.filter(
          id => !replacementMemberIds.includes(id)
        ),
        requestedMemberIds: shift.requestedMemberIds,
        unassignedMemberIds: shift.unassignedMemberIds,
        algoSkew: shift.algoSkew.algoSkew,
        isPartnerManaged: shift.isPartnerManaged,
        isRequestAll: shift.isRequestAll,
        isIncludeUnavailable: shift.isIncludeUnavailable,
        type: shift.type
      }
    ]
  };
}

const sort = sortBy((a, b) => a > b);

/**
 * This function takes the data fetched from the 'get-shift-group-query', and parses
 * it into the shape compatible with both the post-shift-pop-up and the shift-modal.
 */
export function parseShiftToForm({ user, shift }) {
  // HACK: Idealy we would perhaps just save the uniformTemplateId on the shift,
  // but for now this solution keeps it flexible for the frontend to potentially
  // make these things flexible.
  const uniformTemplates = user?.account?.uniformTemplates ?? [];
  const uniformItems = shift?.uniform?.items ?? [];
  const uniformTemplate = uniformTemplates.find(({ items }) =>
    isEqual(sort(uniformItems), sort(items))
  );

  return {
    shifts: [
      {
        id: shift?.id ?? null,
        name: shift?.name ?? "",
        numberRequested: shift?.numberRequested ?? 0,
        startTime: shift?.startTime
          ? new ZonedDate(shift.startTime)
          : new ZonedDate(),
        endTime: shift?.endTime
          ? new ZonedDate(shift.endTime)
          : new ZonedDate(),
        originalStartTime: shift?.originalStartTime
          ? new ZonedDate(shift.originalStartTime)
          : undefined,
        originalEndTime: shift?.originalEndTime
          ? new ZonedDate(shift.originalEndTime)
          : undefined,
        roleRateId: shift?.roleRate?.id ?? null,
        venueId: shift?.venue?.id ?? null,
        subvenueId: shift?.subvenueId ?? null,
        briefing: shift?.briefing ?? null,
        identifier: shift?.identifier ?? null,
        uniformTemplateId: uniformTemplate?.id ?? null,
        assignedMemberIds: [],
        requestedMemberIds: [],
        isPartnerManaged: shift?.isPartnerManaged ?? false,
        skipAlgoShift: shift?.isRestricted ?? false,
        delayHoursAlgoShift: shift?.delayHoursAlgoShift ?? 0,
        postedAt: shift?.postedAt ? new ZonedDate(shift.postedAt) : undefined,
        algoSkew: shift?.algoSkew ?? null,
        isRequestAll: false,
        isIncludeUnavailable: false,
        type: shift?.type,
        privates: {
          shouldShowErrors: false,
          roleRateAccount: {
            id: shift?.targetAccount?.id,
            accountName: shift?.targetAccount?.accountName
          }
        }
      }
    ],
    name: shift?.name ?? "",
    internalId: shift?.identifier ?? "",
    isLinkedShifts: false,
    shiftOpenIndex: 0
  };
}

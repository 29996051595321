import { gql } from "@apollo/client";
import ShiftFragment from "~/src/graphql/fragments/shift/shift.fragment";
import RelationshipsFragment from "~/src/graphql/fragments/relationships/relationships-fragment";
import { addAuthVars } from "@teamrota/authlib";

export const GET_SHIFT_WITH_BOOKINGS = addAuthVars(gql`
  ${ShiftFragment}
  ${RelationshipsFragment}

  fragment BookingsResultSetFragment on BookingResultSet {
    data {
      id
      state
      bucketReason
      visibleAt

      member {
        id
        firstName
        lastName
        photo
        isQualifiedWithRole(shiftId: $shiftId, roleRateId: $roleRateId)
        isQualifiedWithTags(shiftId: $shiftId)
        isConflicted: isConflictedWithDates(shiftId: $shiftId, dates: $dates)
        isUnavailable: isUnavailableWithDates(
          shiftId: $shiftId
          dates: $dates
          shiftType: $shiftType
        )
        isHitWorkingHoursLimit(shiftId: $shiftId, dates: $dates)
        isInVenueServiceArea(venueId: $venueId)
        isLocationConflict: isConflictedWithShift(
          shiftId: $shiftId
          dates: $dates
          venueId: $venueId
        )
        memberAvailability(dates: $dates) {
          startsAt
          endsAt
          shiftType
        }
        isDeclined(shiftId: $shiftId)
        relationships {
          ...RelationshipsFragment
        }
      }
    }
    offset
    limit
    totalResults
  }

  query getShiftWithBookings(
    $shiftId: ID!
    $dates: [DateRangeType]!
    $shiftType: ShiftTypeEnumType
    $roleRateId: ID!
    $venueId: ID!
  ) {
    account {
      id
      isMemberAvailabilityEnabled
      shift(id: $shiftId) {
        ...ShiftFragment

        assigned: bookings(bucketReasonIn: [ASSIGNED], offset: 0, limit: 100) {
          ...BookingsResultSetFragment
        }

        requested: bookings(
          stateEquals: MATCHED
          bucketReasonIn: [REQUESTED]
          offset: 0
          limit: 100
        ) {
          ...BookingsResultSetFragment
        }

        declined: bookings(
          stateEquals: DECLINED
          bucketReasonIn: [REQUESTED]
          offset: 0
          limit: 100
        ) {
          ...BookingsResultSetFragment
        }

        accepted: bookings(stateEquals: ACCEPTED, offset: 0, limit: 100) {
          ...BookingsResultSetFragment
        }

        cancelledBookings: bookings(
          stateEquals: CANCELLED
          offset: 0
          limit: 100
          hasReplacementBookingId: false
        ) {
          totalResults
        }
      }
    }
  }
`);

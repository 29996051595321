import { MEMBER_VENUE_EXCLUSION_TYPES } from "@teamrota/rota-common";

export const reasonOptions = [
  { value: MEMBER_VENUE_EXCLUSION_TYPES.HR_ISSUE, label: "HR Issue" },
  {
    value: MEMBER_VENUE_EXCLUSION_TYPES.EXCLUDED_BY_CLIENT,
    label: "Excluded by Client"
  },
  {
    value: MEMBER_VENUE_EXCLUSION_TYPES.EXCLUDED_BY_CANDIDATE,
    label: "Excluded by Candidate"
  }
];

import styled from "styled-components";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";

const Form = styled.form`
  height: 100%;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 120px);
  overflow-x: hidden;
  overflow-y: scroll;
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 20px;
  width: 100%;
  font-size: ${STYLE_CONSTS.FONT_MEDIUM};
`;

const Label = styled.label`
  font-size: 14px;
  line-height: 17px;
  color: ${COLORS.COOL_GREY};
  padding-bottom: 5px;
`;

const Input = styled.input`
  width: 100%;
  font-size: ${STYLE_CONSTS.FONT_MEDIUM} !important;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 5px;
  background-color: ${({ theme }) => theme.white};
  height: 38px;
  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.primary.main};
  }
  &:disabled {
    /* border: 1px solid #fff; */
    background-color: #f0f0f0;
  }
  &::-webkit-input-placeholder {
    color: ${COLORS.CLOUDY_BLUE};
    background-color: ${({ theme }) => theme.white};
  }
`;

const Select = styled.select`
  width: 100%;
  height: 38px;
  outline: none;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 5px;
  background-color: ${({ theme }) => theme.white};
  &:disabled {
    appearance: none;
    /* border: 1px solid #fff; */
    background-color: #f0f0f0;
  }
  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.primary.main};
  }
`;

const Button = styled.button`
  height: 36px;
  padding: 0 36px;
  border-radius: 36px;
  border: none;
  font-weight: 500;
  font-size: 12px;
  color: ${({ theme }) => theme.white};
  background: ${({ disabled, theme }) =>
    disabled ? COLORS.PALE_GREY : theme.primary.main};
  cursor: pointer;
  margin-top: 10px;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  padding: ${({ noPadding }) => (noPadding ? "0" : "0 10px")};
`;

const Content = styled.div`
  width: 100%;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
  color: #939ba7;
`;

const StyledErrorMessage = styled.p`
  color: ${COLORS.RED};
  font-size: 12px;
  margin-top: 4px;
`;

const styles = {
  Checkbox: {
    marginRight: "5px",
    width: "20px",
    height: "20px",
    verticalAlign: "middle"
  }
};

export {
  Form,
  Section,
  Field,
  Input,
  Select,
  Row,
  Label,
  Button,
  Content,
  StyledErrorMessage,
  styles as default
};

import { RotaDropdownHeader } from "@teamrota/rota-design";
import styled from "styled-components";

export const TagsContainer = styled.div`
  ${({ isParentTag }) =>
    isParentTag
      ? ` display: flex; `
      : ` display: flex;
      margin-bottom: 20px; `}
`;

export const Heading = styled.p`
  font-weight: 500;
  width: 100%;
  ${({ isParentTag }) =>
    isParentTag
      ? ` font-size: 20px;
      max-width: 18%;`
      : ` font-size: 16px;
      max-width: 130px;`};
`;

export const TagsWrapper = styled.div`
  flex: 1;
`;

export const StyledRotaDropdownHeader = styled(RotaDropdownHeader)`
  width: 100%;
  > div:first-of-type {
    width: 100%;
  }
`;

export const ChipsContainer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: start;
`;

import React, { useState, useEffect } from "react";

import {
  MenuItem,
  Select,
  InputAdornment,
  FormControl,
  Button,
  Menu,
  TextField
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { RotaCheckbox, iconNew } from "@teamrota/rota-design";

import {
  RECOMMENDED_ID,
  shouldShowMember,
  staffGroupComparator
} from "./utils";

import DropZone from "./components/DropZone";
import ToolTip from "./components/ToolTip";
import {
  MemberCarousel,
  MembersSet,
  MembersGroup
} from "./components/MemberCarousel";

import useStyles, {
  StyledWrapSection,
  StyledWrapSearchRow,
  StyledWrapFilters,
  StyledZoneTitle
} from "./styles";

const { Search, ChevronDown } = iconNew;

const GroupsZone = ({
  isLoading,
  staffGroups,
  onSearch,
  canDragDrop,
  onDragHover,
  onDragDrop,
  onDragEnd
}) => {
  const classes = useStyles();

  const [selectedGroupId, setSelectedGroupId] = useState(RECOMMENDED_ID);
  const selectedGroup = staffGroups[selectedGroupId];

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    onSearch(searchText);
  }, [searchText]);

  const [filtersPopupAnchor, setFiltersPopupAnchor] = React.useState(null);

  const [isHideUnqualified, setIsHideUnqualified] = useState(false);
  const [isHideConflicted, setIsHideConflicted] = useState(false);
  const [isHideLimited, setIsHideLimited] = useState(false);
  const [isHideUnavailable, setIsHideUnavalable] = useState(false);

  const handleHideUnqualifiedChange = e => {
    setIsHideUnqualified(e.target.checked);
    setFiltersPopupAnchor(null);
  };

  const handleHideConflictedChange = e => {
    setIsHideConflicted(e.target.checked);
    setFiltersPopupAnchor(null);
  };

  const handleHideLimitedChange = e => {
    setIsHideLimited(e.target.checked);
    setFiltersPopupAnchor(null);
  };

  const handleHideUnavailableChange = e => {
    setIsHideUnavalable(e.target.checked);
    setFiltersPopupAnchor(null);
  };

  const numFiltersEnabled =
    (isHideUnqualified ? 1 : 0) +
    (isHideConflicted ? 1 : 0) +
    (isHideLimited ? 1 : 0) +
    (isHideUnavailable ? 1 : 0);

  const members =
    selectedGroup?.members.filter(
      shouldShowMember(
        isHideConflicted,
        isHideUnqualified,
        isHideLimited,
        isHideUnavailable,
        !selectedGroup.isPreSearched && searchText
      )
    ) ?? [];

  const isFiltered =
    searchText || members?.length !== selectedGroup?.members.length;

  return (
    <StyledWrapSection>
      <StyledWrapFilters>
        <StyledWrapSearchRow>
          <StyledZoneTitle>
            <ToolTip>
              Choose a pool or group to find suitable members of staff to
              allocate for this shift, using the search and filters where
              necessary
            </ToolTip>
            Invite from:
          </StyledZoneTitle>
        </StyledWrapSearchRow>

        <StyledWrapSearchRow style={{ marginLeft: "auto" }}>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              value={selectedGroupId}
              onChange={e => setSelectedGroupId(e.target.value)}
              autoWidth={true}
              fullWidth={true}
              className={classes.selectGroup}
            >
              {staffGroups &&
                Object.values(staffGroups)
                  .sort(staffGroupComparator)
                  .map(({ id, name }) => (
                    <MenuItem value={id} key={id}>
                      {name}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </StyledWrapSearchRow>

        <StyledWrapSearchRow>
          <FormControl variant="outlined" className={classes.formControl}>
            <Button
              aria-controls="filters-menu"
              aria-haspopup="true"
              onClick={e => setFiltersPopupAnchor(e.currentTarget)}
              className={
                numFiltersEnabled > 0
                  ? classes.filterButtonOn
                  : classes.filterButton
              }
            >
              Filters {numFiltersEnabled > 0 && `(${numFiltersEnabled})`}
              <ChevronDown />
            </Button>
          </FormControl>

          <Menu
            id="filters-menu"
            anchorEl={filtersPopupAnchor}
            keepMounted
            open={!!filtersPopupAnchor}
            onClose={() => setFiltersPopupAnchor(null)}
          >
            <MenuItem>
              <RotaCheckbox
                label="Hide unqualified"
                isChecked={isHideUnqualified}
                onClick={handleHideUnqualifiedChange}
              />
            </MenuItem>

            <MenuItem>
              <RotaCheckbox
                label="Hide conflicted"
                isChecked={isHideConflicted}
                onClick={handleHideConflictedChange}
              />
            </MenuItem>

            <MenuItem>
              <RotaCheckbox
                label="Hide over limit"
                isChecked={isHideLimited}
                onClick={handleHideLimitedChange}
              />
            </MenuItem>

            <MenuItem>
              <RotaCheckbox
                label="Hide unavailable"
                isChecked={isHideUnavailable}
                onClick={handleHideUnavailableChange}
              />
            </MenuItem>
          </Menu>
        </StyledWrapSearchRow>

        <StyledWrapSearchRow>
          <Autocomplete
            freeSolo
            onInputChange={(e, value) =>
              setSearchText(value !== null ? value : "")
            }
            options={members.map(m => `${m.firstName} ${m.lastName}`) || []}
            renderInput={params => (
              <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                  {...params}
                  placeholder="Search for a member"
                  margin="dense"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    )
                  }}
                  className={classes.searchTextField}
                />
              </FormControl>
            )}
            className={classes.searchAutocomplee}
          />
        </StyledWrapSearchRow>
      </StyledWrapFilters>

      <DropZone
        onDropMember={onDragDrop}
        onHoverEnter={onDragHover}
        isForbidden={!canDragDrop}
      >
        <MemberCarousel
          selectedGroup={selectedGroup}
          isShowPlaceholder
          isLoading={isLoading}
          isEmpty={members?.length === 0}
          isUsing={selectedGroup?.isUsing}
          isFiltered={isFiltered}
          hasMore={selectedGroup?.hasMore}
          onLoadMore={selectedGroup?.fetchMore}
          selectedGroupId={selectedGroupId}
        >
          {!isLoading && selectedGroup?.isShowAll && members?.length > 0 && (
            <MembersGroup
              selectedGroup={selectedGroup}
              members={members}
              isFiltered={isFiltered}
              onDragEnd={onDragEnd}
            />
          )}

          {!isLoading && <MembersSet members={members} onDragEnd={onDragEnd} />}
        </MemberCarousel>
      </DropZone>
    </StyledWrapSection>
  );
};

export default GroupsZone;

import styled from "styled-components";
import ReactTooltip from "react-tooltip";

export const ActionButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px;
  border: ${({ theme }) => `1px solid ${theme.neutral.main}`};
  border-radius: 60px;
  fill: ${({ theme }) => theme.neutral.main};

  &:hover {
    border-color: ${({ color }) => `${color};`};
    cursor: pointer;
    background: ${({ color }) => `${color};`};
    color: ${({ theme }) => theme.white};
    > * {
      fill: ${({ theme }) => theme.white};
    }
  }
`;

export const Label = styled.div`
  padding-bottom: 3px;
  padding-bottom: 3px;
  text-transform: uppercase;
  font-size: 9px;
`;

export const ActionButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledToolTip = styled(ReactTooltip)``;

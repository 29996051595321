import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";
import { inputStyles } from "~/src/components/form-components/input-styles";
import { colors } from "@teamrota/rota-design";

export const Wrapper = styled.div`
  font-size: ${STYLE_CONSTS.FONT_SMALL};
  margin-top: ${({ margin }) => margin && "16px"};
`;

export const FeedbackLink = styled.div`
  color: ${({ isFeedbackVisible, theme }) =>
    isFeedbackVisible ? theme.primary.main : theme.neutral.main};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.primary.main};
  }
`;

export const TextareaElement = styled.textarea`
  ${inputStyles};
`;

export const Label = styled.div`
  display: inline-block;
  padding: ${STYLE_CONSTS.UNIT_1};
  border-radius: 20px;
  border: 1px solid;
  background: ${props => (props.isSelected ? colors.rotaOrange : colors.white)};
  color: ${props => (props.isSelected ? colors.white : colors.rotaOrange)};
  border-color: ${props =>
    props.isSelected ? colors.rotaOrange : colors.dustGrey};
  margin-right: 16px;
  font-size: ${STYLE_CONSTS.FONT_SMALL};
  cursor: pointer;
`;

export const FeedbackField = styled.input`
  border: none;
  &:focus {
    outline: none;
  }
`;

export const FeedbackWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

import React from "react";
import Stars from "~/src/components/stars";
import { Wrapper } from "./rating.styles";

const ratingLabels = [
  "Gross misconduct",
  "Bad shift",
  "Not for us",
  "Good shift",
  "Great Shift",
  "Perfect shift"
];

// eslint-disable-next-line complexity
export default ({ onChange, ratingUpdate, isGrey, isEditMode }) => (
  <Wrapper margin={ratingUpdate}>
    <Stars
      id="time-sheetStars"
      isSmall
      isEditable={isEditMode}
      isGrey={isGrey}
      onChange={onChange}
      value={ratingUpdate}
    />
    {!!ratingUpdate && <div>{ratingLabels[ratingUpdate - 1]}</div>}
  </Wrapper>
);

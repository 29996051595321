import { gql, useQuery } from "@apollo/client";

const GET_PAY_CALCULATION_SETTING = gql`
  query getAccountPayCalculationSetting {
    account {
      id
      isPayCalculationOnCheckInOutTimes
    }
  }
`;

export const useGetPayCalculationSetting = () => {
  const { data } = useQuery(GET_PAY_CALCULATION_SETTING);

  const isPayCalculationOnCheckInOutTimes: boolean =
    data?.account?.isPayCalculationOnCheckInOutTimes || false;

  return isPayCalculationOnCheckInOutTimes;
};

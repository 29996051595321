import React, { useState } from "react";
import { RotaButton, RotaSearchInput } from "@teamrota/rota-design";
import { useQuery, useMutation } from "@apollo/client";

import useLoadMore from "~/src/containers/admin-dashboard/hooks/load-more";
import DashboardScrollHandler from "~/src/components/dashboard-scroll-handler";

import adminClient from "../../graphql/admin-client";
import { GET_MEMBERS } from "../../graphql/get-members";

import {
  StyledMainHeading,
  StyledMainDetail,
  StyledMainHeaderWrapper,
  StyledFeaturesWrapper,
  StyledFeatureHeader,
  StyledWrapperList
} from "../styles";
import { StyledMessage, StyledInviteWrapper } from "./styles";
import Member from "../../components/member";
import { RESEND_INVITE_EMAIL } from "../../graphql/resend-invite-email";

const SCROLL_ELEMENT_ID = "members_list_scroll";

const ResendInviteEmail = ({ setSnack }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const { data, fetchMore, loading } = useQuery(GET_MEMBERS, {
    variables: {
      searchTerm,
      limit: 10,
      offset: 0
    },
    client: adminClient
  });

  const [sendInviteEmail] = useMutation(RESEND_INVITE_EMAIL, {
    client: adminClient,
    refetchQueries: ["getMembersList"]
  });

  const dataLength = data?.members.length;

  const [handleLoadMore] = useLoadMore({
    dataLength,
    fetchMore,
    nameData: "members"
  });

  const handleResendEmail = memberId => {
    try {
      sendInviteEmail({
        variables: {
          memberId
        }
      });
      setSnack({
        message: "Success! Invite has been resent.",
        severity: "success"
      });
    } catch (e) {
      setSnack({ message: "Something Went Wrong.", severity: "error" });
    }
  };

  return (
    <>
      <StyledMainHeaderWrapper>
        <StyledMainHeading>Resend Invite Email</StyledMainHeading>
        <StyledMainDetail>
          Resend invite email for members (invite code will become visble in
          some time after resending the email)
        </StyledMainDetail>
      </StyledMainHeaderWrapper>
      <StyledFeaturesWrapper noBorder noPadding>
        <StyledFeatureHeader>
          <RotaSearchInput
            placeholder="search member"
            onChange={value => {
              setSearchTerm(value);
            }}
          />
        </StyledFeatureHeader>
        <DashboardScrollHandler
          onReachEnd={handleLoadMore}
          scrollElementId={SCROLL_ELEMENT_ID}
        >
          <StyledWrapperList id={SCROLL_ELEMENT_ID}>
            {loading && <p>loading...</p>}
            {data?.members?.map(member => (
              <Member key={member?.id} member={member}>
                {!member?.isSignedUp ? (
                  <StyledInviteWrapper>
                    <p>
                      <span>Invite Code:</span> {member?.inviteCode}
                    </p>
                    <RotaButton
                      size="large"
                      variant="outlined"
                      onClick={() => handleResendEmail(member.id)}
                    >
                      Resend Invite Email
                    </RotaButton>
                  </StyledInviteWrapper>
                ) : (
                  <StyledMessage>
                    This member is already signed up.
                  </StyledMessage>
                )}
              </Member>
            ))}
          </StyledWrapperList>
        </DashboardScrollHandler>
      </StyledFeaturesWrapper>
    </>
  );
};

export default ResendInviteEmail;

import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { useToast } from "@teamrota/rota-design";
import { useCallback } from "react";

// For preview timesheet we need to update booking
export const UPDATE_BOOKING_STATUS = gql`
  mutation updateBookingState(
    $id: ID!
    $isTurnedAway: Boolean
    $isNoShow: Boolean
    $subvenueId: ID
  ) {
    updateBookingState(
      id: $id
      isTurnedAway: $isTurnedAway
      isNoShow: $isNoShow
      subvenueId: $subvenueId
    ) {
      id
    }
  }
`;

interface Params {
  onCompleted?: () => void;
}

interface UpdateParams {
  id: string;
  isNoShow?: boolean;
  isTurnedAway?: boolean;
  subvenueId?: string | null;
}

export const useUpdatePreviewTimesheetMember = ({ onCompleted }: Params) => {
  const { showToast } = useToast();

  const [updatePreview, { loading: loadingPreview }] = useMutation(
    UPDATE_BOOKING_STATUS,
    {
      onError: error => {
        showToast(error.message, { severity: "error" });
      },
      onCompleted
    }
  );

  const updatePreviewTimesheetMember = useCallback(
    async ({ id, isNoShow, isTurnedAway, subvenueId }: UpdateParams) => {
      await updatePreview({
        variables: {
          id,
          isNoShow,
          isTurnedAway,
          subvenueId
        }
      });
    },
    [updatePreview]
  );

  return {
    updatePreviewTimesheetMember,
    loadingPreview
  };
};

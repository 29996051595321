import styled from "styled-components";
import TimeField from "time-input";
import Textarea from "react-textarea-autosize";
import { inputStyles } from "../input-styles";

export const Input = styled.input.attrs({
  tabIndex: props => (props.noFocus ? "-1" : "0")
})`
  ${inputStyles};
  ${({ bgColor, theme }) => bgColor && `background: ${theme.neutral.light};`}
  text-align: ${({ centerText }) => (centerText ? "center" : "unset")};
`;

export const TimeInput = styled(TimeField)`
  > input {
    ${inputStyles};
  }
`;

export const MultilineInput = styled(Textarea)`
  ${inputStyles};
`;

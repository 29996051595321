import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const Title = styled.div`
  margin-bottom: ${STYLE_CONSTS.UNIT_2};
`;
export const ErrorText = styled.div`
  color: ${({ theme }) => theme.error.main};
`;

export const StyledWrapDynamicForm = styled.div`
  padding: 20px 0;
`;

export const StyledSubtitleForm = styled.h3`
  font-size: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.neutral.light};
  margin: 10px 0 30px;
`;

import { gql, useQuery } from "@apollo/client";
import { uniqBy } from "lodash";

export const GET_ROLES = gql`
  query getRoles($userId: ID!) {
    requesterProfile(userId: $userId) {
      account {
        roleRates(accountType: requester) {
          roleId
          roleName
        }
      }
    }
  }
`;

export const useGetVenueRoles = partnerId => {
  const { data } = useQuery(GET_ROLES, {
    variables: { userId: partnerId },
    fetchPolicy: "network-only"
  });

  const venueRoles = uniqBy(
    data?.requesterProfile?.account?.roleRates ?? [],
    "roleId"
  );

  return { venueRoles };
};

import styled from "styled-components";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";
import { clearFix } from "~/src/styles/style-helpers";
import { HOUR_COL_WIDTH, DRAG_END_WIDTH, DAY_ROW_HEIGHT } from "../styles";

export const Row = styled.div`
  ${clearFix()};
  border-bottom: ${STYLE_CONSTS.SMALL_DIVIDER_BORDER_LIGHT};
  margin-left: ${HOUR_COL_WIDTH}px;
  margin-right: ${DRAG_END_WIDTH}px;
`;

export const DateRowDate = styled.div`
  width: ${(1 / 7) * 100}%;
  float: left;
  text-align: center;
  line-height: ${DAY_ROW_HEIGHT}px;
  color: ${props => (props.isToday ? COLORS.BLACK : COLORS.COOL_GREY)};
`;

import React, { memo } from "react";
import moment from "moment-timezone";

import { Role } from "@teamrota/authlib";

import Icon from "~/src/components/icon";

import ManageShiftsHeaderComponent from "./ManageShiftsHeader/manage-shifts";
import PublishContainer from "../PublishContainer";

import styles, {
  CalendarHeaderBox,
  ToolBar,
  HeaderMonth,
  HeaderItem,
  HeaderItemRight
} from "./calendar-header.styles";
import HasRole from "../../has-role";
import { RotaCheckbox } from "@teamrota/rota-design";

const getMonthRange = (startTime, endTime) => {
  const startDate = moment(startTime);
  const endDate = moment(endTime);

  const dayStarting = startDate.format("DD");
  const monthStarting = startDate.format("MMMM");
  const yearStarting = startDate.format("YYYY");
  const monthEnding = endDate.format("MMMM");
  const yearEnding = endDate.format("YYYY");

  const isSameMonth = monthStarting === monthEnding;
  const isSameYear = yearStarting === yearEnding;

  if (isSameMonth && isSameYear)
    return `${dayStarting} ${monthStarting} ${yearEnding}`;
  if (isSameYear) return `${monthStarting}/${monthEnding} ${yearEnding}`;
  return `${dayStarting} ${monthStarting} ${yearStarting} / ${monthEnding} ${yearEnding}`;
};

const CalendarHeader = ({
  startTime,
  endTime,
  setStartDate,
  shouldShowCancelled,
  handleShowCancelShifts,
  onToggleSideBar,
  setIsShiftCreateModalOpen,
  countOfDraftShifts,
  draftShiftIds,
  refetchSchedules
}) => {
  const handleBackOneWeek = () => {
    setStartDate(moment(startTime).subtract(1, "week"));
  };

  const handleSetCurrentWeek = () => {
    setStartDate(
      moment()
        .startOf("isoWeek")
        .utc()
    );
  };

  const handleForwardOneWeek = () => {
    setStartDate(moment(startTime).add(1, "week"));
  };

  return (
    <CalendarHeaderBox>
      <ToolBar>
        <HeaderItem left="14px" position="absolute">
          <HasRole role={Role.SHIFTS_CREATE}>
            <HeaderItem>
              <span
                style={styles.headerAlign}
                onClick={() => setIsShiftCreateModalOpen(true)}
              >
                <img style={styles.icon} src={"/images/add_button.svg"} />
                Create Shift
              </span>
            </HeaderItem>
          </HasRole>

          <HeaderItem onClick={handleBackOneWeek}>
            <Icon
              size={12}
              color={Icon.colors.BLACK}
              name={Icon.names.ARROW_LEFT}
            />
          </HeaderItem>
          <HeaderItem orange weight onClick={handleSetCurrentWeek}>
            Weekly
          </HeaderItem>
          <HeaderItem onClick={handleForwardOneWeek}>
            <Icon
              size={12}
              color={Icon.colors.BLACK}
              name={Icon.names.ARROW_RIGHT}
            />
          </HeaderItem>
          <HeaderItem>
            <HeaderMonth>{getMonthRange(startTime, endTime)}</HeaderMonth>
          </HeaderItem>
        </HeaderItem>

        <HeaderItemRight onClick={onToggleSideBar}>
          <ManageShiftsHeaderComponent />
        </HeaderItemRight>

        <HeaderItem>
          <RotaCheckbox
            onClick={handleShowCancelShifts}
            isChecked={shouldShowCancelled}
            label="Cancelled Shifts"
          />
        </HeaderItem>

        <HeaderItem>
          <PublishContainer
            countOfDraftShifts={countOfDraftShifts}
            draftShiftIds={draftShiftIds}
            refetchSchedules={refetchSchedules}
          />
        </HeaderItem>
      </ToolBar>
    </CalendarHeaderBox>
  );
};

export default memo(CalendarHeader);

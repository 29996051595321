import styled from "styled-components";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";
import Icon from "~/src/components/icon";
import Button from "~/src/components/button";

export const XSmallText = styled.span`
  font-size: ${STYLE_CONSTS.FONT_XSMALL};
  display: inline-block;
`;

export const SmallText = styled.span`
  font-size: ${STYLE_CONSTS.FONT_SMALL};
  display: inline-block;
`;

export const GreyText = styled.span`
  display: inline-block;
  padding-left: 10;
  color: ${COLORS.COOL_GREY};
  margin-left: ${props => (props.leftMargin ? "10px" : "")};
`;

export const MemberDetailWrapper = styled.div`
  margin-top: ${STYLE_CONSTS.UNIT_1};
  display: flex;
`;

export const ShiftDetails = styled.div`
  margin-left: ${STYLE_CONSTS.UNIT_1};
  flex: 1;
`;

export const StyledIcon = styled(Icon)`
  margin-left: ${STYLE_CONSTS.UNIT_2};
  margin-right: ${STYLE_CONSTS.UNIT_1};
`;

export const ApplicantRow = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  margin-right: ${STYLE_CONSTS.UNIT_2};
`;

export const FulfilmentText = styled.div`
  display: inline-block;
  color: ${({ isOrange, theme }) =>
    isOrange ? theme.primary.main : theme.neutral.main};
`;

export const TextHighlight = styled.span`
  color: ${props => (props.textColor ? props.textColor : "")};
  font-weight: ${props => (props.bold ? "bold" : "")};
`;

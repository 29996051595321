import { gql } from "@apollo/client";

const UPDATE_TARGET_MATCHER = gql`
  mutation updateTargetMatcher(
    $accountId: ID!
    $id: ID!
    $hoursBeforeShift: Int
    $algoSkew: Int
    $favouriteFactor: Int
    $isPartnerManagedEnabled: Boolean
    $globalRatingFactor: Int
    $roleFactor: Int
    $relationshipFactor: Int
    $autoAcceptThreshold: Int
  ) {
    updateTargetMatcher(
      id: $accountId
      matcherConfig: {
        id: $id
        hoursBeforeShift: $hoursBeforeShift
        algoSkew: $algoSkew
        favouriteFactor: $favouriteFactor
        isPartnerManagedEnabled: $isPartnerManagedEnabled
        globalRatingFactor: $globalRatingFactor
        roleFactor: $roleFactor
        relationshipFactor: $relationshipFactor
        autoAcceptThreshold: $autoAcceptThreshold
      }
    ) {
      id
      hoursBeforeShift
      algoSkew
      favouriteFactor
      isPartnerManagedEnabled
      globalRatingFactor
      roleFactor
      relationshipFactor
      autoAcceptThreshold
    }
  }
`;

export default UPDATE_TARGET_MATCHER;

import styled from "styled-components";

export const StyledMessage = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.background.primary};
  padding: 10px;
  border-radius: 5px;
`;

export const StyledInviteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    display: initial;
  }
`;

import { graphql } from "@apollo/client/react/hoc";
import { gql } from "@apollo/client";

import { toDateRange } from "~/src/utils/date-range";
import { handleErrors } from "~/src/utils/errors";

export const GET_GROUPS = gql`
  query getGroups(
    $shiftId: ID
    $sourceAccountId: ID!
    $selectedAccountId: ID
    $dates: [DateRangeType]!
    $shiftType: ShiftTypeEnumType
    $roleRateId: ID
    $isRoleRateId: Boolean!
    $venueId: ID
    $checkLocationConflict: Boolean! = false
    $tagIds: [ID]
  ) {
    groups(
      sourceAccountId: $sourceAccountId
      selectedAccountId: $selectedAccountId
    ) {
      id
      name
      sortNumber
      members {
        id
        member {
          id
          photo
          firstName
          lastName
          shouldShowInGroup
          state
          isAsleep
          limitedHours
          rating
          isQualifiedWithRole(roleRateId: $roleRateId, shiftId: $shiftId)
            @include(if: $isRoleRateId)
          isQualifiedWithTags(shiftId: $shiftId, tagIds: $tagIds)
          isInVenueServiceArea(venueId: $venueId)
          isConflicted: isConflictedWithDates(shiftId: $shiftId, dates: $dates)
            @include(if: $isRoleRateId)
          isUnavailable: isUnavailableWithDates(
            shiftId: $shiftId
            dates: $dates
            shiftType: $shiftType
          )
          isHitWorkingHoursLimit(shiftId: $shiftId, dates: $dates)
          isLocationConflict: isConflictedWithShift(
            shiftId: $shiftId
            dates: $dates
            venueId: $venueId
          ) @include(if: $checkLocationConflict)

          memberAvailability(dates: $dates) {
            startsAt
            endsAt
            shiftType
          }

          relationship(selectedAccountId: $selectedAccountId) {
            id
            poolType
          }

          accounts {
            isMemberAvailabilityEnabled
          }
        }
      }
    }
  }
`;

export default graphql(GET_GROUPS, {
  props: handleErrors(({ data: { groups, refetch } }) => ({
    groups: groups || [],
    refetchGroups: refetch
  })),
  skip: ({ formData }) =>
    formData ? !formData?.shifts?.[0]?.roleRateId : false,
  options: ({ selectedConnectionId, formData, membersWithShiftStatus }) => {
    let sourceAccountId = 0;
    const { times, roleRateId, privates, startTime, endTime } =
      formData?.shifts?.[0] || {};
    if (selectedConnectionId) {
      // set by Pools component
      // see src/containers/pools/index.js
      sourceAccountId = selectedConnectionId;
    } else if (privates?.roleRateAccount) {
      // set by ShiftCreateModal/FormAudience
      // see src/containers/requester-calendar/ShiftCreateModal/FormAudience/selectors.js
      sourceAccountId = privates?.roleRateAccount?.id;
    }
    return {
      variables: {
        sourceAccountId,
        roleRateId: roleRateId || null,
        isRoleRateId: membersWithShiftStatus || false,
        dates: times
          ? Object.values(times).map(toDateRange)
          : startTime && endTime
          ? [toDateRange({ startTime, endTime })]
          : []
      }
    };
  }
});

import React from "react";
import { Styled_ErrorContainer } from "./styles";
import Icon from "~/src/components/icon";
import { icon } from "@teamrota/rota-design";

const ErrorContainer = ({ textContent }) => {
  const { InfoIcon } = icon;
  return (
    <Styled_ErrorContainer>
      <InfoIcon
        color={Icon.colors.RED}
        styles={{ transform: "rotate(180deg)" }}
      />
      <div className="text_content">{textContent}</div>
    </Styled_ErrorContainer>
  );
};

export default ErrorContainer;

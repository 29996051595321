import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";

export const UNALCULATED_ROLE_RATE_DETAILS = gql`
  query getUncalculatedRoleRate($roleId: ID!) {
    account {
      id
      uncalculatedRoleRatesByRoleId(roleId: $roleId) {
        id
        name
        payRate
        chargeRate
        isDayRate
        applicable
      }
    }
  }
`;

export const useUncalculatedRoleDetails = ({ roleId }) => {
  const { data, loading, error } = useQuery(UNALCULATED_ROLE_RATE_DETAILS, {
    fetchPolicy: "cache-and-network",
    variables: { roleId },
    onError: error => {
      throw error;
    }
  });

  const uncalculatedRoleRates = data?.account.uncalculatedRoleRatesByRoleId;

  return {
    uncalculatedRoleRates,
    loading,
    error
  };
};

import React, { useState } from "react";
import ViewEditMember from "~/src/containers/modals/view-edit-member";

const AddMember = props => {
  const [newMemberId, setNewMemberId] = useState(null);

  const updateMemberId = memberId => {
    setNewMemberId(memberId);
  };

  return (
    <ViewEditMember
      {...props}
      isOpen
      isAddMember={!newMemberId}
      memberId={newMemberId}
      onCreate={newMemberId => updateMemberId(newMemberId)}
    />
  );
};

export default AddMember;

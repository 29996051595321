import { gql } from "@apollo/client";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import useAuth from "~/src/auth/hooks/use-auth";
import { checkErrors } from "~/src/utils/errors";
import { PayrollRule } from "../types";

type dataType = {
  account: {
    payrollRules: PayrollRule[];
  };
};

export const GET_PAYROLL_RULES = gql`
  query payrollRules($search: String, $offset: Int!, $limit: Int!) {
    account {
      id
      payrollRules(search: $search, offset: $offset, limit: $limit) {
        id
        name
        config
        twilightStart
        twilightEnd
        createdAt
        updatedAt
      }
    }
  }
`;

const PAGE_SIZE = 200;

export const usePayrollRules = (searchText: string) => {
  const auth = useAuth();
  const [isInitialFetch, setIsInitialFetch] = useState(true);
  const [limit, setLimit] = useState(PAGE_SIZE);
  const [offset, setOffset] = useState(0);

  const { loading, data, fetchMore } = checkErrors(
    useQuery(GET_PAYROLL_RULES, {
      variables: auth.addVals(GET_PAYROLL_RULES, {
        search: searchText,
        offset,
        limit
      }),
      fetchPolicy: isInitialFetch ? "network-only" : "cache-first",
      notifyOnNetworkStatusChange: true
    })
  );

  const payrollRules = data?.account?.payrollRules ?? [];
  const totalResults = payrollRules.length;

  return {
    loading: isInitialFetch && loading,
    loadingMore: !isInitialFetch && loading,
    payrollRules,
    hasMore: payrollRules.length < totalResults,
    fetchMore: () => {
      if (payrollRules.length < totalResults) {
        setIsInitialFetch(false);
        const newOffset = offset + limit;
        setOffset(newOffset);

        fetchMore({
          variables: {
            offset: newOffset,
            limit: PAGE_SIZE
          },
          fetchPolicy: "network-only"
        }).then(({ data }: { data: dataType }) => {
          setLimit(limit + data.account.payrollRules.length);
        });
      }
    }
  };
};

import React, { useEffect, useState } from "react";

import { RotaDropdown } from "@teamrota/rota-design";

import { Container, Separator } from "./styles";

interface TimeSelectProps {
  time: string | null;
  isDisabled?: boolean;
  onChangeTime: (time: string | null) => void;
}

const HH = [
  { label: " ", value: "" },
  ...Array.from(Array(24).keys()).map(h => {
    const hh = h < 10 ? `0${h}` : `${h}`;
    return { label: hh, value: hh };
  })
];

const MM = [
  { label: " ", value: "" },
  ...Array.from(Array(4).keys()).map(v => {
    const m = v * 15;
    const mm = m < 10 ? `0${m}` : `${m}`;
    return { label: mm, value: mm };
  })
];

const TimeSelect: React.FC<TimeSelectProps> = ({
  time,
  isDisabled,
  onChangeTime
}) => {
  const [hh, setHH] = useState<string>(HH[0].value);
  const [mm, setMM] = useState<string>(MM[0].value);

  useEffect(() => {
    if (
      time !== undefined &&
      time !== null &&
      time?.length > 4 &&
      time?.length <= 5 &&
      time?.indexOf(":") > 1
    ) {
      const [hh, mm] = time?.split(":");

      if (HH.find(v => v.value === hh)) setHH(hh);
      if (MM.find(v => v.value === mm)) setMM(mm);
    }
  }, []);

  useEffect(() => {
    if (hh?.length && mm?.length) {
      onChangeTime(`${hh}:${mm}`);
    } else {
      onChangeTime(null);
    }
  }, [hh, mm]);

  return (
    <Container>
      <RotaDropdown
        id={""}
        label={""}
        options={HH}
        placeholder={""}
        isSearchable
        isDisabled={isDisabled}
        width={""}
        value={hh}
        onChange={setHH}
        errorMessage={""}
      />
      <Separator>:</Separator>
      <RotaDropdown
        id={""}
        label={""}
        options={MM}
        placeholder={""}
        isSearchable
        isDisabled={isDisabled}
        width={""}
        value={mm}
        onChange={setMM}
        errorMessage={""}
      />
    </Container>
  );
};

export default TimeSelect;

import React from "react";
import {
  Styled_Container,
  Styled_Filler,
  Styled_Label
} from "~/src/components/progress-bar/progress-bar.styles";

const ProgressBar = ({ progress }) => {
  return (
    <Styled_Container>
      <Styled_Filler completed={progress}>
        <Styled_Label>&nbsp</Styled_Label>
      </Styled_Filler>
    </Styled_Container>
  );
};

export default ProgressBar;

import { gql } from "@apollo/client";

export const UPDATE_ROLE_RATE = gql`
  mutation updateRoleRate(
    $roleRateId: ID!
    $payRate: Int
    $chargeRate: Int
    $isOverstayRate: Boolean
    $overstayPayRate: Int
    $overstayChargeRate: Int
    $isOvertimeRate: Boolean
    $overtimePayRate: Int
    $overtimeChargeRate: Int
    $overstayHoursLimit: Int
    $overtimeHoursLimit: Int
    $divisionId: ID
    $consultantId: ID
    $sageRef: String
    $canCandidatesEditBreaks: Boolean
  ) {
    updateRoleRate(
      roleRateId: $roleRateId
      payRate: $payRate
      chargeRate: $chargeRate
      isOverstayRate: $isOverstayRate
      overstayPayRate: $overstayPayRate
      overstayChargeRate: $overstayChargeRate
      isOvertimeRate: $isOvertimeRate
      overtimePayRate: $overtimePayRate
      overtimeChargeRate: $overtimeChargeRate
      overtimeHoursLimit: $overtimeHoursLimit
      overstayHoursLimit: $overstayHoursLimit
      divisionId: $divisionId
      consultantId: $consultantId
      sageRef: $sageRef
      canCandidatesEditBreaks: $canCandidatesEditBreaks
    ) {
      id
      roleId
    }
  }
`;

import { RotaCalendar } from "@teamrota/rota-design";

import TimeDateInput from "~/src/components/form-components/time-date-input";

import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px;
`;

export const InputsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledRotaCalendar = styled(RotaCalendar)`
  margin-right: 8px;
`;

export const StyledTimeDateInput = styled(TimeDateInput)`
  width: 60px;
`;

export const Label = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
`;

export const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin: 8px;
  color: ${({ theme }) => theme.error.main};
`;

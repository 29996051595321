import React from "react";
import { TagItem, DeleteButton } from "../../tag-input-suggestions.styles";
import Icon from "~/src/components/icon";

const SelectedTag = ({ tag: { isCompulsory, disabled, name }, onDelete }) => {
  const isDisabled = isCompulsory || disabled;
  return (
    <TagItem disabled={isDisabled}>
      {name}
      {!isDisabled && onDelete && (
        <DeleteButton type="button" onClick={onDelete}>
          <Icon
            name={Icon.names.CLOSE}
            color={Icon.colors.WHITE}
            size={Icon.sizes.XSMALL}
          />
        </DeleteButton>
      )}
    </TagItem>
  );
};

export default SelectedTag;

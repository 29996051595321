import { createSelector } from "reselect";
import moment from "moment-timezone";
import isEmpty from "lodash/fp/isEmpty";
import { updateShiftForm, removeShift } from "../../reducer";
import { getMinTime } from "../shared";

// Map dispatch to props<handleShiftUpdate>
export const mapDispatchToProps = dispatch => ({
  handleShiftChange: index => {
    dispatch(
      updateShiftForm({
        shiftOpenIndex: index || 0
      })
    );
  },
  handleRemoveShift: index => {
    dispatch(removeShift(index));
  }
});

const useActiveShifts = createSelector(
  [props => props.shifts, props => props.shiftOpenIndex],
  (shifts, shiftOpenIndex) => {
    const filteredShifts = shifts
      .map((s, index) => ({
        ...s,
        index,
        isActive: index === shiftOpenIndex
      }))
      .filter(s => !s.isDeleted);

    return filteredShifts
      .map((s, idx) => ({
        ...s,
        isNew: idx === filteredShifts.length - 1
      }))
      .sort((a, b) => {
        // Ensure new shift is always at bottom
        if (a.isNew && isEmpty(a.times)) return 1;

        const startA = getMinTime(a, moment().add(100, "y"));
        const startB = getMinTime(b, moment().add(100, "y"));

        return startA.isAfter(startB) ? 1 : -1;
      });
  }
);

export const mapStateToProps = ({
  calendar: {
    shiftForm: { shifts, shiftOpenIndex }
  }
}) => ({
  shifts: useActiveShifts({ shifts, shiftOpenIndex }),
  shiftOpenIndex
});

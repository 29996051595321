import { gql, useQuery } from "@apollo/client";

const GET_MEMBER = gql`
  query getMember($memberId: ID!) {
    account {
      id
      member(id: $memberId) {
        id
        firstName
        lastName
        state
      }
    }
  }
`;

export const useGetMember = ({ memberId }) => {
  const { data } = useQuery(GET_MEMBER, {
    variables: { memberId }
  });

  return { member: data?.account?.member };
};

import { RotaButtonNew, iconNew as RotaIcon } from "@teamrota/rota-design";
import styled from "styled-components";

const { Plus } = RotaIcon;

export const Container = styled.div`
  margin-left: 18%;
`;

export const Heading = styled.p`
  font-weight: 500;
  font-size: 20px;
  width: 18%;
`;

export const Exclusions = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const DropdownWrapper = styled.div`
  flex: 1;
  margin-right: 10px;
`;

export const ActionsContainer = styled.div`
  width: 18%;
`;

export const StyledButton = styled(RotaButtonNew)`
  margin-top: 15px;
`;

export const StyledPlusIcon = styled(Plus).attrs(({ isActive, theme }) => ({
  color: isActive ? theme.text.primary : theme.neutral.main,
  styles: { cursor: !isActive && "not-allowed" }
}))`
  margin: 0 15px 0 5px;
`;

export const Divider = styled.div`
  border-top: ${({ theme }) => `1px solid ${theme.neutral.main}`};
  margin: 30px 0 35px 0;
`;

export const OwnerContainer = styled.div`
  display: flex;
  align-items: center;
`;

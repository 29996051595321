import { gql } from "@apollo/client";

export default gql`
  fragment MemberRoleFragment on memberRole {
    roleId
    memberRoleId
    name
    defaultPayRate
    payRateOverride
    isDayRate
    payScaleLevel
    currentCreditsNumber
    incrementDate
  }
`;

export default {
  showModal(modalType, modalProps) {
    return {
      type: "OPEN_MODAL",
      payload: { modalType, modalProps }
    };
  },

  closeModal() {
    return {
      // NOTE: This is OPEN_MODAL intentionally
      type: "OPEN_MODAL",
      payload: { modalType: null, modalProps: null }
    };
  },

  resetReduxState() {
    return { type: "RESET_STATE" };
  }
};

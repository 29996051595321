import React from "react";

import DropZone from "./components/DropZone";
import { MemberCarousel, MembersSet } from "./components/MemberCarousel";
import ToolTip from "./components/ToolTip";

import {
  StyledWrapAssignRequested,
  StyledWarning,
  StyledWrapText,
  StyledWrapElement,
  StyledWrapAssigned,
  StyledZoneTitle,
  StyledWrapSearchRow
} from "./styles";

const AssignZone = ({
  assignedMembers,
  acceptedMembers,
  hasFreeSpace,
  canDragDrop,
  onDragHover,
  onDragDrop,
  onDragEnd,
  onRemoveMember,
  isLoading
}) => {
  const totalAssigned = assignedMembers?.length + acceptedMembers?.length ?? 0;
  return (
    <StyledWrapAssigned>
      <StyledWrapAssignRequested>
        <StyledWrapSearchRow>
          <ToolTip>
            Once you assign a member, they will automatically be accepted on to
            the shift without having to apply.
          </ToolTip>
          <StyledZoneTitle>
            {`Assign (${totalAssigned} Assigned)`}
            <StyledWarning>{!hasFreeSpace && " - Shift is full"}</StyledWarning>
          </StyledZoneTitle>
        </StyledWrapSearchRow>

        <StyledWrapText>Drag and drop to assign staff</StyledWrapText>
      </StyledWrapAssignRequested>
      <StyledWrapElement>
        <DropZone
          onHoverEnter={onDragHover}
          onDropMember={onDragDrop}
          isForbidden={!canDragDrop}
        >
          <MemberCarousel
            isLoading={isLoading}
            isShowPlaceholder={false}
            totalCardCount={totalAssigned}
          >
            <MembersSet
              members={assignedMembers}
              onDragEnd={onDragEnd}
              isAssigned
              onRemove={onRemoveMember}
            />

            <MembersSet
              members={acceptedMembers}
              onDragEnd={onDragEnd}
              isAccepted
            />
          </MemberCarousel>
        </DropZone>
      </StyledWrapElement>
    </StyledWrapAssigned>
  );
};

export default AssignZone;

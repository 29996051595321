import React from "react";

import { Wrapper, ErrorText } from "./index.styles";

const InputWrapper = ({
  error,
  isSingleLine,
  isMediumText,
  memberType,
  children,
  title
}) => (
  <Wrapper
    isError={error}
    isSingleLine={isSingleLine}
    isMediumText={isMediumText}
  >
    {memberType && title}
    {children}
    <ErrorText>{error}</ErrorText>
  </Wrapper>
);

export default InputWrapper;

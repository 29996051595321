import styled from "styled-components";
import { colors } from "@teamrota/rota-design";

export const StyledBinIconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledMainText = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
  font-size: 20px;
  text-align: center;
`;

export const StyledRedBar = styled.div`
  border: solid;
  border-width: 1px;
  border-color: ${({ theme }) => theme.error.main};
`;

export const StyledSubText = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
  font-size: 14px;
  text-align: center;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RoleName = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
`;

export const StyledUploadButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  margin-bottom: 48px;
`;

export const StyledRedParagraph = styled.p`
  color: ${({ theme }) => theme.error.main};
`;

export const StyledFileRow = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
`;

export const PageHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ theme }) => theme.white};
  margin-bottom: 24px;
`;

export const Header = styled.div`
  padding: 36px 48px 0px;
  display: grid;
  grid-template-columns: 120px 1fr;
  gap: 64px;
  align-items: center;
`;

export const HeaderContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Subheader = styled.div`
  padding: 12px 48px;
  display: grid;
  grid-template-columns: 120px 1fr;
  gap: 64px;
  align-items: baseline;
`;

export const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.p`
  font-size: 34px;
  font-weight: 400;
`;

export const RolesCount = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutral.main};

  & > span {
    font-size: 24px;
    font-weight: 400;
    color: ${colors.darkGrey};
    margin-right: 8px;
  }
`;

export const Subtitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  padding-left: 16px;
  color: ${colors.darkGrey};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

import styled from "styled-components";
import { Row } from "~/src/components/grid";
import Icon from "~/src/components/icon";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const ModalHeader = styled(Row)`
  padding: ${STYLE_CONSTS.UNIT_1};
  display: flex;
  border-radius: 6px;
  box-shadow: ${STYLE_CONSTS.FLOATING_MEDIUM};
  overflow: hidden;
  background: ${({ theme }) => theme.primary.main};
  color: ${({ theme }) => theme.white};
  padding: 10px;
`;

export const ShiftTitle = styled.span`
  font-size: ${STYLE_CONSTS.FONT_LARGE};
  font-weight: 400;
  margin-right: ${STYLE_CONSTS.UNIT_1};
`;

export const ShiftTitleDimRestricted = styled.span`
  font-size: ${STYLE_CONSTS.FONT};
  color: ${({ theme }) => theme.text.primary};
  margin-right: ${STYLE_CONSTS.UNIT_1};
`;

export const ShiftTitleDim = styled.span`
  font-size: ${STYLE_CONSTS.FONT};
`;

export const StyledButton = styled.div`
  border: 2px solid white;
  padding: 5px;
  border-radius: 6px;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;

  &:hover {
    background: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.primary.main};
    cursor: pointer;
  }
`;
export const ModalFooter = styled.div`
  padding: ${STYLE_CONSTS.UNIT_2};
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex: 1;
  ${({ shouldFlex }) => shouldFlex && `flex: 5;`}
  flex-direction: column;
  justify-content: space-between;
`;

export const Heading = styled.div`
margin-top: 3px;
${({ isContainer }) =>
  isContainer &&
  `display: flex;
   justify-content: flex-start;
   align-items: center;
   padding-left: 5px;
   margin-top: 15px;`};

text-transform: uppercase;
font-weight: 400;
font-size: 14px;
padding-left: 5px;
}`;

export const GridContainer = styled.div`
  background-color: #fafafa;
  border-radius: 6px;
  margin-top: 15px;
`;

export const StyledIcon = styled(Icon).attrs({
  name: Icon.names.CLOCK,
  size: "small",
  color: "black"
})``;

import styled from "styled-components";

import { STYLE_CONSTS } from "~/src/styles/config.style";

export const MODAL_ANI_NAME = "modal-animation";
export const ENTER_TIME = 200;
export const LEAVE_TIME = 500;

export const ExitIconWrapper = styled.div`
  position: fixed;
  right: ${STYLE_CONSTS.UNIT_4};
  top: ${STYLE_CONSTS.UNIT_8};
  z-index: ${STYLE_CONSTS.Z_INDEX_MODAL + 1};
  @media screen and (min-width: ${STYLE_CONSTS.BREAK_POINT_XLARGE}) {
    top: 5vh;
    right: 5vh;
  }
`;

export const Content = styled.div`
  border-radius: ${STYLE_CONSTS.BORDER_RADIUS_L};
  background-color: ${({ background, theme }) =>
    background ? background : theme.white};
  padding: ${({ padding }) => padding || STYLE_CONSTS.UNIT_2};
`;

export const modalStyles = (isOverlay, isLightOverlay) => ({
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "none",
    border: "none",
    overflow: "visible"
  },
  overlay: {
    zIndex: `${
      isOverlay || isLightOverlay
        ? STYLE_CONSTS.Z_INDEX_MODAL + 1
        : STYLE_CONSTS.Z_INDEX_MODAL
    }`,
    backgroundColor: `${
      isLightOverlay ? "rgba(255, 255, 255, 0.7) " : "rgba(0, 0, 0, 0.7)"
    }`
  }
});

import Cleave from "cleave.js/react";
import omit from "lodash/fp/omit";
import React from "react";
import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const Input = styled(({ ...props }) => (
  <Cleave {...omit("isAlignCenter", props)} />
))`
  width: 100%;
  font-size: ${({ isfontsmall }) =>
    isfontsmall ? "10px" : `${STYLE_CONSTS.FONT_MEDIUM}`};
  border: none;
  background-color: ${({ theme }) => theme.white};
  &:focus {
    outline: none;
  }
  &:disabled {
    color: ${({ theme }) => theme.text.primary};
  }
  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.neutral.light};
    background-color: ${({ theme }) => theme.white};
  }
  text-align: ${({ isAlignCenter }) => (isAlignCenter ? "center" : "left")};
`;

export const Disabled = styled.div`
  width: ${({ isSingleLine }) => (isSingleLine ? "60%" : "100%")};
  display: ${({ isSingleLine }) => (isSingleLine ? "inline-block" : "block")};
  font-size: ${STYLE_CONSTS.FONT_MEDIUM};
  color: ${({ theme }) => theme.text.primary};
`;

export const Wrapper = styled.div`
  width: ${({ isSingleLine }) => (isSingleLine ? "60%" : "100%")};
  display: ${({ isSingleLine }) => (isSingleLine ? "inline-block" : "block")};
  > input {
    color: ${({ isError, theme }) =>
      isError ? theme.error.main : theme.primary.main};
  }
`;

export const ErrorText = styled.div`
  width: 100px;
  height: ${STYLE_CONSTS.UNIT_2};
  font-size: ${STYLE_CONSTS.FONT_XSMALL};
  color: ${({ theme }) => theme.error.main};
`;

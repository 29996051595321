import React, { Component } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  Wrapper,
  ToggleWrapper,
  TrayPositioner,
  PICKER_ANI_NAME,
  ENTER_TIME,
  LEAVE_TIME
} from "./toggle-tray.styles";

class ToggleTray extends Component {
  state = {
    isOpen: this.props.isOpen,
    topTranslate: 0,
    leftTranslate: 0,
    trayWidth: 0,
    isVisible: false
  };

  componentDidUpdate(prevProps) {
    if (this.props.isOpen && this.toggleRef && !this.state.isOpen) {
      const { width } = this.toggleRef.getBoundingClientRect();
      const trayWidth = prevProps.fixedTrayWidth || width;
      this.addClickListener();
      this.setState(
        {
          isOpen: true,
          isVisible: false,
          trayWidth,
          topTranslate: 0,
          leftTranslate: 0
        },
        () => {
          if (this.toggleRef && this.trayRef) {
            const toggle = this.toggleRef.getBoundingClientRect();
            const tray = this.trayRef.getBoundingClientRect();
            const normalisedLeft = toggle.left - tray.left;
            const addedTopOffset = prevProps.addedTopOffset || 0;
            this.setState({
              isVisible: true,
              topTranslate:
                toggle.top - tray.top + toggle.height + addedTopOffset,
              leftTranslate: normalisedLeft - (trayWidth - toggle.width) / 2
            });
          }
        }
      );
    } else if (!this.props.isOpen && this.state.isOpen) {
      this.removeClickListener();
      this.setState({ isOpen: false });
    }
  }

  addClickListener() {
    if (!this.isClickListened && document.body) {
      this.isClickListened = true;
      document.body.addEventListener("click", this.handleClose);
    }
  }

  removeClickListener() {
    if (this.isClickListened && document.body) {
      this.isClickListened = false;
      document.body.addEventListener("click", this.handleClose);
    }
  }

  handleClose = e => {
    const specifiedElement = document.getElementById(this.preventClickName);
    if (specifiedElement && !specifiedElement.contains(e.target))
      this.props.onClose();
  };

  preventClickName = `toggle-tray-${Math.random()}`;
  isClickListened = false;
  trayRef = null;
  toggleRef = null;

  render() {
    const { props, state } = this;
    return (
      <Wrapper id={this.preventClickName}>
        <ToggleWrapper
          ref={ref => {
            this.toggleRef = ref;
          }}
        >
          {props.toggle}
        </ToggleWrapper>
        <CSSTransitionGroup
          transitionName={PICKER_ANI_NAME}
          transitionEnterTimeout={ENTER_TIME}
          transitionLeaveTimeout={LEAVE_TIME}
        >
          {state.isOpen && (
            <TrayPositioner
              ref={ref => {
                this.trayRef = ref;
              }}
              style={{
                visibility: state.isVisible ? "visible" : "hidden",
                width: `${state.trayWidth}px`,
                transform: `translate(${state.leftTranslate}px, ${state.topTranslate}px)`
              }}
            >
              {props.tray}
            </TrayPositioner>
          )}
        </CSSTransitionGroup>
      </Wrapper>
    );
  }
}

export default ToggleTray;

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

import get from "lodash/fp/get";
import getOr from "lodash/fp/getOr";

import { handleErrors } from "~/src/utils/errors";

export const getQueryArgs = limit => {
  return {
    props: handleErrors(({ data: { account, loading, fetchMore } }) => ({
      isLoading: loading,
      members: account?.members?.data,
      totalResults: account?.members?.totalResults,
      loadMore: offset => {
        fetchMore({
          notifyOnNetworkStatusChange: true,
          variables: { offset },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              return previousResult;
            }

            fetchMoreResult.account.members.data = [
              ...previousResult?.account?.members?.data,
              ...fetchMoreResult?.account?.members?.data
            ];

            return { ...fetchMoreResult };
          }
        });
      }
    })),
    options(props) {
      const getFilterProp = path => get(path, props?.filters) || undefined;
      const orderBy = getFilterProp("orderByProp");

      return {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only",
        variables: {
          offset: props?.offset || 0,
          limit,
          searchText: getFilterProp("searchText"),
          minRating: getFilterProp("minRating"),
          maxRating: getFilterProp("maxRating"),
          memberType: getFilterProp("memberType")?.map(
            ({ memberType }) => memberType
          ),
          roleNames:
            getFilterProp("roleNames") &&
            getOr([], "roleNames", props?.filters).map(({ name }) => name),
          tagNames:
            getFilterProp("tags") &&
            getOr([], "tags", props?.filters).map(({ name }) => name),
          venueId: getFilterProp("venue.value"),
          serviceAreaIds:
            getFilterProp("serviceAreaIds") &&
            getOr([], "serviceAreaIds", props?.filters).map(({ id }) => id),
          stateIn:
            getFilterProp("stateIn") &&
            getOr([], "stateIn", props?.filters).map(({ id }) => id),
          // HACK: Order by props must be reset else both are sent and ruins sorting
          orderByPropDesc: undefined,
          orderByPropAsc: undefined,
          [orderBy.orderDirection]: orderBy.value
        }
      };
    }
  };
};

export const GET_MEMBERS = gql`
  query getMembers(
    $searchText: String
    $minRating: Float
    $maxRating: Float
    $roleNames: [String]
    $tagNames: [String]
    $venueId: ID
    $offset: Int!
    $limit: Int!
    $stateIn: [MemberStateType]
    $serviceAreaIds: [ID]
    $orderByPropDesc: String
    $orderByPropAsc: String
    $memberType: [String]
  ) {
    account {
      id
      members(
        searchText: $searchText
        minRating: $minRating
        maxRating: $maxRating
        roleNames: $roleNames
        tagNames: $tagNames
        venueId: $venueId
        offset: $offset
        limit: $limit
        stateIn: $stateIn
        serviceAreaIds: $serviceAreaIds
        orderByPropDesc: $orderByPropDesc
        orderByPropAsc: $orderByPropAsc
        memberType: $memberType
      ) {
        totalResults
        data {
          id
          internalId
          firstName
          lastName
          nickname
          photo
          phone
          email
          rating
          notes
          memberType
          roles {
            name
          }
          serviceAreas {
            id
            name
          }
          closestAcceptedShiftToNow {
            id
            startTime
            endTime
          }
          state
          isAsleep
        }
      }
    }
  }
`;

export default limit => graphql(GET_MEMBERS, getQueryArgs(limit));

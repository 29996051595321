import { useCallback } from "react";
import { gql, useMutation } from "@apollo/client";

const CREATE_OR_UPDATE_ROLE = gql`
  mutation createOrUpdateRole(
    $id: ID
    $name: String
    $description: String
    $internalId: String
    $isDayRate: Boolean
    $defaultPayRate: Int
    $defaultChargeRate: Int
    $isOvertimeRate: Boolean
    $defaultOvertimePayRate: Int
    $defaultOvertimeChargeRate: Int
    $defaultOvertimeHoursLimit: Int
    $isOverstayRate: Boolean
    $defaultOverstayPayRate: Int
    $defaultOverstayChargeRate: Int
    $defaultOverstayHoursLimit: Int
  ) {
    createOrUpdateRole(
      id: $id
      name: $name
      description: $description
      internalId: $internalId
      isDayRate: $isDayRate
      defaultPayRate: $defaultPayRate
      defaultChargeRate: $defaultChargeRate
      isOvertimeRate: $isOvertimeRate
      defaultOvertimePayRate: $defaultOvertimePayRate
      defaultOvertimeChargeRate: $defaultOvertimeChargeRate
      defaultOvertimeHoursLimit: $defaultOvertimeHoursLimit
      isOverstayRate: $isOverstayRate
      defaultOverstayPayRate: $defaultOverstayPayRate
      defaultOverstayChargeRate: $defaultOverstayChargeRate
      defaultOverstayHoursLimit: $defaultOverstayHoursLimit
    ) {
      id
      name
      defaultPayRate
      defaultChargeRate
      internalId
      description
      isDayRate
      isOvertimeRate
      defaultOvertimePayRate
      defaultOvertimeChargeRate
      defaultOvertimeHoursLimit
      isOverstayRate
      defaultOverstayPayRate
      defaultOverstayChargeRate
      defaultOverstayHoursLimit
    }
  }
`;

const useCreateOrUpdateRole = ({ onCompleted, onError }) => {
  const [createOrUpdate, { loading, error }] = useMutation(
    CREATE_OR_UPDATE_ROLE,
    {
      refetchQueries: ["getRoles"],
      onCompleted,
      onError
    }
  );

  const createOrUpdateRole = useCallback(
    variables => {
      return createOrUpdate({ variables });
    },
    [createOrUpdate]
  );

  return { createOrUpdateRole, loading, error };
};

export default useCreateOrUpdateRole;

import React from "react";
import { RotaButton } from "@teamrota/rota-design";
import { LoadingWrapper } from "./load-more.styles";

const LoadMore = ({ shouldHide, isLoading, onLoadMore, isTransparent }) => {
  if (shouldHide) {
    return null;
  }

  return (
    <LoadingWrapper>
      <RotaButton
        onClick={onLoadMore}
        variant="outlined"
        isLoading={isLoading}
        style={{ backgroundColor: isTransparent && "transparent" }}
      >
        Load more
      </RotaButton>
    </LoadingWrapper>
  );
};

export default LoadMore;

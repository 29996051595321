import styled from "styled-components";

export const StyledAccountsListWrapper = styled.div`
  overflow: auto;
  height: calc(100vh - 547px);
  min-width: 30%;
  max-width: 100%;
`;

export const StyledHeading = styled.p`
  font-size: 16px;
  padding: 10px 0px;
`;

export const StyledAccountName = styled.p`
  weight: 400;
  font-size: 14px;
  color: ${({ selected, theme }) =>
    selected ? theme.primary.main : theme.text.primary};
`;

export const StyledAccountDetail = styled.p`
  weight: 400;
  font-size: 13px;
  color: ${({ theme }) => theme.neutral.light};
`;

export const StyledAccountWrapper = styled.div`
  padding: 10px 0px;
  border-bottom: 1px solid ${({ theme }) => theme.neutral.light};
  border-radius: 0px !important;
  cursor: pointer;
  &:hover {
    // order of the components is important for this to work
    // referred component must be declared first
    ${StyledAccountName} {
      color: ${({ theme }) => theme.primary.main};
    }
  }
`;

import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import getOr from "lodash/fp/getOr";
import PageHeader from "~/src/components/page-header";
import { errorModal } from "~/src/utils/errors";
import Ellipsis from "~/src/components/ellipsis";
import ActionDropdown from "~/src/components/action-dropdown";
import Icon from "~/src/components/icon";
import LoadMore from "~/src/components/load-more";
import { connect } from "react-redux";
import getProfile from "~/src/graphql/queries/get-profile/get-profile-query.decorator";
import {
  FloatingTable,
  ColumnHeading,
  TableBody,
  TableData,
  TableRow,
  StyledWrapButtonHeader
} from "~/src/components/floating-table";
import DashboardScrollHandler from "~/src/components/dashboard-scroll-handler";
import Member from "~/src/components/member-details";
import MemberDetailsModal from "~/src/containers/modals/view-edit-member";
import NoResults from "~/src/components/no-results";
import TablePlaceholder from "~/src/components/table-placeholder";
import MemberSearch from "./components/member-search";
import MemberShiftList from "./components/member-shift-list";
import RelationshipModal from "./components/relationship-modal";
import RemoveItemsDuplicates from "~/src/utils/remove-duplicates";
import { GET_MEMBERS } from "./graphql/get-members";
import getAccountAvailableMemberTypes from "./graphql/get-available-member-types";
import {
  openMemberBookings,
  updateFilterFields,
  resetFilters
} from "./reducer";
import { StyledButton, SortedBy, Buttons } from "./my-staff.styles";
import { useQuery } from "@apollo/client";

const GET_MEMBERS_LIMIT = 10;

const MyStaff = ({
  filters,
  user,
  resetFilters,
  updateFilterFields,
  openMemberBookings,
  ...props
}) => {
  const [members, setMembers] = useState([]);
  const [offset, setOffset] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isRelationshipModalOpen, setIsRelationshipModalOpen] = useState(false);
  const [isGraphicalLoadAllowed, setIsGraphicalLoadAllowed] = useState(true);
  const [selectedMemberId, setSelectedMemberId] = useState("");

  const { loading: isLoading, data, error, fetchMore } = useQuery(GET_MEMBERS, {
    variables: {
      offset: 0,
      limit: GET_MEMBERS_LIMIT,
      searchText: filters?.searchText,
      ...(filters?.minRating && { maxRating: filters?.minRating }),
      ...(filters?.maxRating && { maxRating: filters?.maxRating }),
      memberType: filters?.memberType?.map(({ memberType }) => memberType),
      roleNames: filters?.roleNames?.map(({ name }) => name),
      tagNames: filters?.tags?.map(({ name }) => name),
      venueId: filters?.venue?.value,
      serviceAreaIds: filters?.serviceAreaIds?.map(({ id }) => id),
      stateIn: filters?.stateIn?.map(({ id }) => id),
      ...(filters?.orderByProp && {
        [filters?.orderByProp?.orderDirection]: filters?.orderByProp?.value
      })
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true
  });

  useEffect(() => {
    resetFilters();
  }, []);

  useEffect(() => {
    setMembers(data?.account?.members?.data || []);
  }, [data]);

  useEffect(() => {
    setIsGraphicalLoadAllowed(false);

    if (offset === 0) return;

    fetchMore({
      variables: {
        offset: offset
      },
      updateQuery: (_, { fetchMoreResult }) => {
        setMembers(members => {
          const newMembersMerged = [
            ...members,
            ...(fetchMoreResult?.account?.members?.data || [])
          ];

          const membersWithoutDuplicated = RemoveItemsDuplicates({
            items: newMembersMerged,
            key: "id"
          });

          return membersWithoutDuplicated;
        });
      }
    });
    setIsGraphicalLoadAllowed(true);
  }, [offset]);

  useEffect(() => {
    if (error) errorModal(error);
  }, [error]);

  const totalResults = data?.account?.members?.totalResults;

  const handleLoadMore = () => {
    if (!isLoading && offset < totalResults) {
      setOffset(offset => offset + GET_MEMBERS_LIMIT);
    }
  };

  const handleOpen = memberId => {
    setIsOpen(true);
    setSelectedMemberId(memberId);
  };

  const handleOpenShiftList = memberId => {
    openMemberBookings({ memberId });
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const isNoResults = members?.length === 0 && !isLoading;
  const isNoMoreResults = members?.length === totalResults;

  return (
    <DashboardScrollHandler onReachEnd={handleLoadMore}>
      <PageHeader title="My Staff" subtext={`${totalResults || 0} Members`}>
        <MemberSearch
          updateField={filter => updateFilterFields({ ...filter })}
          filters={filters}
          userProfile={user}
          isLoading={isLoading}
          resetFilters={resetFilters}
        />
        <StyledWrapButtonHeader>
          <SortedBy>Sort by</SortedBy>
          <ActionDropdown
            defaultValue="Sort"
            isFontPrimary
            isIconPrimary
            value={filters.orderByProp}
            options={[
              {
                label: "Name",
                value: "firstName",
                orderDirection: "orderByPropAsc"
              },
              {
                label: "Rating",
                value: "rating",
                orderDirection: "orderByPropDesc"
              }
            ]}
            onChange={orderByProp => updateFilterFields({ orderByProp })}
          />
        </StyledWrapButtonHeader>
      </PageHeader>
      <MemberDetailsModal
        isOpen={isOpen}
        onClose={handleClose}
        memberId={selectedMemberId}
      />
      <RelationshipModal
        isOpen={isRelationshipModalOpen}
        onClose={() => setIsRelationshipModalOpen(false)}
        memberId={selectedMemberId}
      />
      <FloatingTable>
        <ColumnHeading width={4 / 12} widthSmall={6 / 12}>
          Member
        </ColumnHeading>
        <ColumnHeading width={2 / 12} widthSmall={3 / 12}>
          Notes
        </ColumnHeading>
        <ColumnHeading width={2 / 12} widthSmall={0}>
          Service Area
        </ColumnHeading>
        <ColumnHeading width={2 / 12} widthSmall={0}>
          Roles
        </ColumnHeading>
        <ColumnHeading width={2 / 12} widthSmall={3 / 12}>
          &nbsp;
        </ColumnHeading>
        <TableBody>
          {isNoResults && <NoResults />}
          {!isNoResults && (
            <div>
              {isLoading && isGraphicalLoadAllowed && (
                <TablePlaceholder
                  columnWidths={[4 / 12, 2 / 12, 2 / 12, 2 / 12, 2 / 12]}
                />
              )}
              {members?.map(member => {
                const isMemberBookingListOpen = getOr(
                  false,
                  `memberBookingList.${member?.id}.isOpen`,
                  props
                );
                return (
                  <TableRow key={member?.id}>
                    <TableData width={4 / 12} widthSmall={6 / 12}>
                      <Member
                        actionCallback={() => {
                          setIsRelationshipModalOpen(true);
                          setSelectedMemberId(member?.id);
                        }}
                        {...member}
                      />
                    </TableData>
                    <TableData width={2 / 12} widthSmall={3 / 12}>
                      <Ellipsis maxLength={81}>{member?.notes}</Ellipsis>
                    </TableData>
                    <TableData width={2 / 12} widthSmall={0}>
                      <Ellipsis maxLength={90}>
                        {member?.serviceAreas.map(area => area.name).join(", ")}
                      </Ellipsis>
                    </TableData>
                    <TableData width={2 / 12} widthSmall={0}>
                      <Ellipsis maxLength={75}>
                        {[...member?.roles]
                          .sort((a, b) => a?.name?.localeCompare(b?.name))
                          .map(role => role?.name)
                          .join(", ")}
                      </Ellipsis>
                    </TableData>
                    <TableData width={2 / 12} widthSmall={3 / 12}>
                      <Buttons>
                        <StyledButton
                          iconName={Icon.names.SHIFTS}
                          isIconOnly
                          isBorderless
                          onClick={() => handleOpenShiftList(member?.id)}
                        />
                        <StyledButton
                          iconName={Icon.names.INFO}
                          isIconOnly
                          isBorderless
                          onClick={() => handleOpen(member?.id)}
                        />
                      </Buttons>
                    </TableData>
                    <div>
                      {isMemberBookingListOpen && (
                        <MemberShiftList
                          isExpanded={isMemberBookingListOpen}
                          memberId={member?.id}
                        />
                      )}
                    </div>
                  </TableRow>
                );
              })}
            </div>
          )}
        </TableBody>
      </FloatingTable>
      {!isNoResults && !isNoMoreResults && (
        <LoadMore isLoading={isLoading} onLoadMore={handleLoadMore} />
      )}
    </DashboardScrollHandler>
  );
};

const mapStateToProps = s => ({
  memberBookingList: s.myStaff.memberBookingList,
  filters: s.myStaff.filters
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resetFilters,
      openMemberBookings,
      updateFilterFields
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(getProfile(getAccountAvailableMemberTypes(MyStaff)));

import styled from "styled-components";
import { COLORS } from "~/src/styles/config.style";

export const Timeline = styled.ul`
  list-style: none;
  padding: 5px 10px;
  border-left: 4px solid ${({ theme }) => theme.primary.main};
  margin: 20px 0px 50px 120px;
  position: relative;
  text-align: left;
  height: auto;
`;
export const Experience = styled.li`
  padding: 10px 0 15px 0;
  color: ${COLORS.DARK_GREY};
  position: relative;
  margin-bottom: 6px;

  &:after {
    position: absolute;
    display: block;
    top: 33px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    border: 2px solid ${COLORS.CLOUDY_BLUE};
    left: -19px;
    content: "";
    background: white;
  }
`;

export const SimpleDivWrapper = styled.div`
  ${props =>
    props.isMonthDifference && `font-weight: 400; font-size: 12px;`} ${props =>
    !props.isDetailsWrapper && `margin: 5px;`};
`;

export const TimeDetails = styled.div`
  position: absolute;
  left: -130px;
  white-space: pre-wrap;
  color: ${COLORS.DARK_GREY};
  text-align: center;
  width: 100px;
  ${SimpleDivWrapper}:last-child {
    color: ${COLORS.CLOUDY_BLUE};
  }
  ${SimpleDivWrapper}:nth-child(2) {
    margin: -5px;
  }
`;

export const EmptyStateText = styled.div`
  padding: 5px;
`;
export const InputContainer = styled.div`
  margin: 15px 0;
`;

export const BoldText = styled.p`
  font-weight: 500;
  margin-top: 30px;
`;

import React from "react";
import { useMutation } from "@apollo/client";
import EditRoles from "~/src/components/new-pay-rate/edit-roles";
import UPDATE_TARGET_ACCOUNT_ROLE_RATES from "../graphql/update-target-account-role-rates";
import { useDivisions } from "../graphql/use-divisions";
import { useConsultants } from "../graphql/use-consultants";

const EditRolesComponent = ({
  accountId,
  roles,
  roleRates,
  tags,
  isPartner,
  disableEditRole
}) => {
  const [updateTargetAccountRoleRates, { error }] = useMutation(
    UPDATE_TARGET_ACCOUNT_ROLE_RATES,
    {
      refetchQueries: ["getTargetAccount", "getProfile"]
    }
  );

  const { divisions } = useDivisions({
    accountId
  });

  const { consultants } = useConsultants({
    accountId
  });

  return (
    <EditRoles
      accountId={accountId}
      allRoles={roles || []}
      assignedRoles={roleRates || []}
      isPartner={isPartner}
      error={error}
      onSave={roleRates => {
        updateTargetAccountRoleRates({
          variables: {
            roleRates,
            id: accountId
          }
        });
      }}
      getSelectedRole={role => ({
        chargeRate: role?.defaultChargeRate,
        payRate: role?.defaultPayRate,
        overstayPayRate: role?.defaultOverstayPayRate,
        isOverstayRate: role?.isOverstayRate,
        overstayChargeRate: role?.defaultOverstayChargeRate,
        overstayHoursLimit: role?.defaultOverstayHoursLimit,
        isOvertimeRate: role?.isOvertimeRate,
        overtimePayRate: role?.defaultOvertimePayRate,
        overtimeChargeRate: role?.defaultOvertimeChargeRate,
        overtimeHoursLimit: role?.defaultOvertimeHoursLimit,
        roleId: role?.id,
        name: role?.name,
        tags,
        isDayRate: role?.isDayRate
      })}
      disableEditRole={disableEditRole}
      divisions={divisions}
      consultants={consultants}
    />
  );
};

export default EditRolesComponent;

import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { useMutation } from "@apollo/client";
import moment from "moment-timezone";

import { ZonedDate } from "@teamrota/rota-common";
import { RotaButtonIcon } from "@teamrota/rota-design";

import { isFormattedDateValid } from "~/src/utils/time-utils";

import {
  StyledInput,
  StyledP,
  StyledHoursWorkedHeader,
  StyledExceptionsContainer,
  StyledCircle,
  StyledSmallBoldP,
  StyledPlus,
  StyledException,
  StyledCount,
  StyledRowContainer,
  StyledErrorMessage
} from "./styles";

import ADD_MEMBER_AVAILABILITY_EXCEPTIONS from "./graphql/add-member-availability-exceptions";
import DELETE_MEMBER_AVAILABILITY_EXCEPTIONS from "./graphql/delete-member-availability-exceptions";

const ExceptionDates = ({
  memberAvailabilityExceptions,
  memberId,
  isLimitedHours
}) => {
  const [exceptions, setExceptions] = useState(
    memberAvailabilityExceptions || []
  );

  const initialStartDate = new ZonedDate();
  initialStartDate.setHours(0, 0, 0, 0);
  const [exceptionStartDate, setExceptionStartDate] = useState(
    initialStartDate.toISOString()
  );

  const initialEndDate = new ZonedDate();
  initialEndDate.setHours(23, 59, 59, 999);
  const [exceptionEndDate, setExceptionEndDate] = useState(
    initialEndDate.toISOString()
  );

  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");

  const [addMemberAvailabilityExceptions] = useMutation(
    ADD_MEMBER_AVAILABILITY_EXCEPTIONS,
    {
      refetchQueries: ["getMember"]
    }
  );

  const [deleteMemberAvailabilityExceptions] = useMutation(
    DELETE_MEMBER_AVAILABILITY_EXCEPTIONS,
    {
      refetchQueries: ["getMember"]
    }
  );

  const updateExceptionStartDate = value => {
    if (value) {
      value.setHours(0, 0, 0, 0);
      const date = value.toISOString();

      setStartDateError("");
      if (moment(date).isBefore(moment(exceptionEndDate))) {
        setStartDateError(null);
        setEndDateError(null);

        setExceptionStartDate(date);
      } else {
        setStartDateError("Start date must be before end date.");
      }
    }
  };

  const updateExceptionEndDate = value => {
    if (value) {
      value.setHours(23, 59, 59, 999);
      const date = value.toISOString();

      setEndDateError("");
      if (moment(date).isAfter(moment(exceptionStartDate))) {
        setStartDateError(null);
        setEndDateError(null);

        setExceptionEndDate(date);
      } else {
        setEndDateError("End date must be after start date.");
      }
    }
  };

  const handleAddException = () => {
    if (isAddExceptionDisabled) return;
    setExceptions(exceptions => [
      ...exceptions,
      {
        startDate: exceptionStartDate,
        endDate: exceptionEndDate
      }
    ]);
    addMemberAvailabilityExceptions({
      variables: {
        memberId,
        startDate: exceptionStartDate,
        endDate: exceptionEndDate
      }
    });
  };

  const handleDeleteException = idx => {
    const id = exceptions[idx]?.id;
    setExceptions(data => {
      const exceptions = [...data];
      exceptions.splice(idx, 1);
      return exceptions;
    });
    if (id) deleteMemberAvailabilityExceptions({ variables: { id } });
  };

  const handleDateValidation = (type, date) => {
    if (type === "startDate") {
      if (isFormattedDateValid(date)) setStartDateError("");
      else setStartDateError("Invalid start date format.");
    } else {
      if (isFormattedDateValid(date)) setEndDateError("");
      else setEndDateError("Invalid end date format.");
    }
  };

  const isAddExceptionDisabled =
    !!startDateError || !!endDateError || !isLimitedHours;

  return (
    <>
      <StyledRowContainer>
        <StyledP disabled={!isLimitedHours}>
          Number of Exemption Periods:
          <StyledCount disabled={!isLimitedHours}>
            {exceptions?.length || 0}
          </StyledCount>
        </StyledP>
      </StyledRowContainer>
      <StyledHoursWorkedHeader>
        <StyledP disabled={!isLimitedHours} style={{ paddingRight: "5px" }}>
          Add dates:
        </StyledP>
        <StyledSmallBoldP disabled={!isLimitedHours}>from</StyledSmallBoldP>
        <DatePicker
          placeholderText={"Start date"}
          selected={new ZonedDate(exceptionStartDate)}
          onChange={date =>
            updateExceptionStartDate(ZonedDate.fromSelectedDate(date))
          }
          onChangeRaw={e => handleDateValidation("startDate", e.target.value)}
          todayButton={true}
          disabled={!isLimitedHours}
          open={false}
          dateFormat="dd/MM/yyyy"
          strictParsing
          customInput={
            <StyledInput isError={startDateError} disabled={!isLimitedHours} />
          }
        />
        <StyledSmallBoldP disabled={!isLimitedHours}>until</StyledSmallBoldP>
        <DatePicker
          placeholderText={"End date"}
          selected={new ZonedDate(exceptionEndDate)}
          onChange={date =>
            updateExceptionEndDate(ZonedDate.fromSelectedDate(date))
          }
          onChangeRaw={e => handleDateValidation("endDate", e.target.value)}
          disabled={!isLimitedHours}
          open={false}
          dateFormat="dd/MM/yyyy"
          strictParsing
          customInput={
            <StyledInput isError={endDateError} disabled={!isLimitedHours} />
          }
        />
        <StyledCircle
          disabled={isAddExceptionDisabled}
          onClick={handleAddException}
        >
          <StyledPlus>+</StyledPlus>
        </StyledCircle>
      </StyledHoursWorkedHeader>
      <StyledErrorMessage>{startDateError}</StyledErrorMessage>
      <StyledErrorMessage>{endDateError}</StyledErrorMessage>
      {isLimitedHours && exceptions.length ? (
        <StyledExceptionsContainer>
          {exceptions.map(({ startDate, endDate }, idx) => (
            <StyledException key={idx}>
              <p>{`${new ZonedDate(startDate).toLocaleDateString()} -
              ${new ZonedDate(endDate).toLocaleDateString()}`}</p>
              <RotaButtonIcon
                icon="close"
                onClick={() => handleDeleteException(idx)}
              />
            </StyledException>
          ))}
        </StyledExceptionsContainer>
      ) : (
        <StyledP
          disabled={!isLimitedHours}
          style={{ paddingRight: "5px", fontStyle: "italic" }}
        >
          Click on + to add exception
        </StyledP>
      )}
    </>
  );
};

export default ExceptionDates;

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

export const SEND_INVITE_EMAIL = gql`
  mutation sendInviteEmail(
    $channelName: channelNameEnumType!
    $payload: JSON!
  ) {
    queueBossJob(channelName: $channelName, payload: $payload)
  }
`;

export default graphql(SEND_INVITE_EMAIL, {
  name: "sendInviteEmail",
  props: props => ({
    sendInviteEmail: async memberId => {
      const memberMutationResult = await props.sendInviteEmail({
        variables: {
          channelName: "automation",
          payload: {
            id: memberId,
            actionType: "sendMemberInviteEmail"
          }
        }
      });
      return memberMutationResult.data.sendInviteEmail;
    }
  })
});

import React, { useState } from "react";
import { connect } from "react-redux";
import { change } from "redux-form";
import copyToClipboard from "copy-to-clipboard";
import InputWrapper from "../input-wrapper";
import { Input } from "./index.styles";
import { CopySuccess } from "../../../member-details/index.style";

const TxtInput = ({
  dispatch,
  meta = {},
  input: { name, value, onChange },
  placeholder,
  readOnly,
  isCopiable,
  copiedValue,
  onFocus,
  onClick,
  ...props
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyItem = () => {
    copyToClipboard(copiedValue);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <InputWrapper isSingleLine error={meta?.submitFailed && meta?.error}>
      {isCopied && <CopySuccess>Copied!</CopySuccess>}
      <Input
        className={isCopiable ? "isCopiable" : "isNotCopiable"}
        value={value}
        readOnly={readOnly}
        disabled={readOnly && !isCopiable}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        onClick={onClick}
        onBlur={() => {
          dispatch(change(meta?.form, name, value?.trim()));
        }}
        onDoubleClick={copyItem}
      />
    </InputWrapper>
  );
};

const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(null, mapDispatchToProps)(TxtInput);

import React, { useEffect, useState } from "react";
import moment from "moment-timezone";

import Icon from "~/src/components/icon";
import {
  Main,
  Header,
  Title,
  Month,
  Day,
  DayName,
  DayContent,
  ArrowButton
} from "./calendar.styles";

const MODIFIER_KEY_CODE = 16; // shift key

const MONDAY = 1;
const SATURDAY = 6;
const SUNDAY = 7;

const getDisplayRows = month => {
  const monthLength = month.daysInMonth();
  const firstDay = month.startOf("month").isoWeekday();

  // most month displays require 5 rows, but there are exceptions
  if (monthLength === 28 && firstDay === MONDAY) return 4;
  if (monthLength === 30 && firstDay === SUNDAY) return 6;
  if (monthLength === 31 && (firstDay === SATURDAY || firstDay === SUNDAY))
    return 6;

  return 5;
};

const getDates = month => {
  return Array.from(Array(getDisplayRows(month) * 7)).map((_, i) => {
    return month
      .clone()
      .startOf("month")
      .startOf("isoWeek")
      .add(i, "days");
  });
};

const Calendar = ({
  startDate,
  selectedDates,
  onDateClick,
  onEndDateClick
}) => {
  const [month, setMonth] = useState(
    moment(startDate)
      .startOf("day")
      .startOf("month")
  );

  const [isShiftHeld, setIsShiftHeld] = useState(false);

  const trackKeysDown = e => {
    if (e.keyCode === MODIFIER_KEY_CODE) {
      setIsShiftHeld(true);
    }
  };

  const trackKeysUp = e => {
    if (e.keyCode === MODIFIER_KEY_CODE) {
      setIsShiftHeld(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", trackKeysDown);
    window.addEventListener("keyup", trackKeysUp);

    return () => {
      window.removeEventListener("keydown", trackKeysDown);
      window.removeEventListener("keyup", trackKeysUp);
    };
  }, [isShiftHeld]);

  const nextMonth = () => setMonth(month.clone().add(1, "month"));
  const prevMonth = () => setMonth(month.clone().subtract(1, "month"));

  const dates = getDates(month);

  return (
    <Main>
      <Header>
        <ArrowButton onClick={prevMonth}>
          <Icon
            size={12}
            color={Icon.colors.BLACK}
            name={Icon.names.ARROW_LEFT}
          />
        </ArrowButton>
        <Title>{month?.format("MMMM YYYY")}</Title>
        <ArrowButton onClick={nextMonth}>
          <Icon
            size={12}
            color={Icon.colors.BLACK}
            name={Icon.names.ARROW_RIGHT}
          />
        </ArrowButton>
      </Header>
      <Month>
        {["M", "T", "W", "T", "F", "S", "S"].map((day, i) => (
          <DayName key={i}>{day}</DayName>
        ))}
        {dates?.map((date, i) => {
          const selected = !!selectedDates?.find(selectedDate => {
            return moment(selectedDate)
              .startOf("day")
              .isSame(date);
          });

          const disabled = date.isBefore(moment().startOf("day"));
          const diffMonth = !month?.isSame(date, "month");

          return (
            <Day
              key={i}
              isDisabled={disabled}
              isGreyed={diffMonth}
              isSelected={selected}
              onClick={() => {
                if (isShiftHeld) {
                  return onEndDateClick(date);
                }
                onDateClick(date);
              }}
            >
              <DayContent>{date.format("DD")}</DayContent>
            </Day>
          );
        })}
      </Month>
    </Main>
  );
};

export default Calendar;

import React from "react";
import ReactModal from "react-modal";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import Loading from "~/src/components/loading";
import ExitButton from "~/src/components/button/exit-button";
import minLoadingDecorator from "./min-loading.decorator";
import {
  modalStyles,
  ExitIconWrapper,
  Content,
  MODAL_ANI_NAME,
  ENTER_TIME,
  LEAVE_TIME
} from "./modal.styles";

export const minLoading = minLoadingDecorator;

const Modal = ({
  isOpen,
  isLoading,
  isOverlay,
  onClose,
  isLightOverlay,
  hasBtn,
  padding,
  background,
  children
}) => {
  return (
    <div>
      {isOpen && !isLoading && !hasBtn && (
        <ExitIconWrapper>
          <ExitButton isLarge isGrey onClick={onClose} size={14} />
        </ExitIconWrapper>
      )}
      <ReactModal
        ariaHideApp={false}
        contentLabel="modal"
        isOpen={isOpen}
        style={modalStyles(isOverlay, isLightOverlay)}
        onRequestClose={onClose}
      >
        <CSSTransitionGroup
          transitionName={MODAL_ANI_NAME}
          transitionEnterTimeout={ENTER_TIME}
          transitionLeaveTimeout={LEAVE_TIME}
        >
          {!isLoading && (
            <>
              {/* This invisible <input /> grabs "tabbable" focus on Chrome and Safari for the other modal elements */}
              {/* Firefox works without it */}
              {/* tabIndex -1 means it never receives focus itself, it just means the browser detects the others */}
              {/* Complete hack, but effective. */}
              {/* See https://rota.atlassian.net/browse/RD-2992 */}
              <input
                tabIndex="-1"
                style={{ opacity: 0, height: 0, width: 0 }}
              ></input>
              <Content padding={padding} background={background}>
                {children}
              </Content>
            </>
          )}
        </CSSTransitionGroup>
        {isLoading && <Loading />}
      </ReactModal>
    </div>
  );
};

export default Modal;

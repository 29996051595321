import gql from "graphql-tag";
import { graphql } from "@apollo/client/react/hoc";
import { errorModal } from "~/src/utils/errors";

const PUBLISH_DRAFT_SHIFTS = gql`
  mutation publishShifts($shiftIds: [ID]!) {
    publishShifts(shiftIds: $shiftIds) {
      id
    }
  }
`;

export default graphql(PUBLISH_DRAFT_SHIFTS, {
  name: "publishShifts",
  props: ({
    publishShifts,
    ownProps: { draftShiftIds, refetchSchedules }
  }) => ({
    publishShifts: () => {
      // if (draftShiftIds.length) {
      publishShifts({
        variables: { shiftIds: draftShiftIds }
      })
        .then(result => {
          if (result.data?.publishShifts?.length > 0) {
            refetchSchedules();
          } else {
            throw new Error("All shifts were already published");
          }
        })
        .catch(e => {
          errorModal(e);
        });
    }
    // }
  })
});

import { COLORS } from "~/src/styles/config.style";

export const useStyles = () => ({
  downloadButton: {
    margin: "14px 0 0 0",
    height: "29px",
    width: "104px",
    border: "1px solid #9E9E9E",
    backgroundColor: "white",
    color: "#9E9E9E",
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
    textTransform: "capitalize"
  }
});

const styles = {
  btnSpan: {
    fontSize: "10px",
    color: "#9E9E9E"
  },
  row: {
    margin: "31px 0 18px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    paddingBottom: "25px",
    borderBottom: "1px solid #F1EFEF"
  },
  downloadBtnContainer: {
    padding: "31px 0 22px 0",
    borderBottom: "1px solid #F1EFEF"
  },
  rowTitle: {
    margin: "31px 0 18px 0",
    fontWeight: " 500",
    fontSize: " 15px",
    lineHeight: " 16px",
    letterSpacing: " -0.177778px"
  },
  button: {
    backgroundColor: `${COLORS.MAIN_ORANGE}`,
    color: "white",
    width: "174px",
    height: "40px",
    borderRadius: "20px"
  },
  radio: {
    color: COLORS.MAIN_ORANGE
  }
};

export default styles;

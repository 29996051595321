import { graphql } from "@apollo/client/react/hoc";
import { gql } from "@apollo/client";
import { handleErrors } from "~/src/utils/errors";

export const GET_PROFILE = gql`
  query getProfile {
    user {
      id
      account {
        id
        serviceAreas {
          id
          name
          path
        }
      }
    }
  }
`;

export default graphql(GET_PROFILE, {
  props: handleErrors(({ data: { user: { account } } }) => ({
    accountId: account.id,
    serviceAreas: account.serviceAreas
  }))
});

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import UserFragment from "~/src/graphql/fragments/user";
import { handleErrors } from "~/src/utils/errors";

export const GET_USER = gql`
  ${UserFragment}
  query getUser($userId: ID!) {
    account {
      id
      user(userId: $userId) {
        ...UserFragment
      }
    }
  }
`;

export default graphql(GET_USER, {
  skip: props => !props.isOpen || !props.userId,
  props: handleErrors(({ data: { loading, account, refetch } }) => ({
    isLoading: loading,
    user: account?.user ?? {},
    refetchUser: refetch
  }))
});

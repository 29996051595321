import styled from "styled-components";

const bonusStyles = ({ enlargedAddIcon }) => ({
  iconContainer: enlargedAddIcon ? { marginRight: 3 } : {}
});

export default bonusStyles;

export const BonusWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BonusInputContainer = styled.div`
  width: 80px;
  padding-left: 3px;
  ${({ isReward }) => !isReward && `margin-top: -4px;`}
  input {
    background: transparent;
    color: ${({ theme }) => theme.text.primary};
  }
`;
export const AddBonusContainer = styled.div`
  padding: 0 3px 3px 3px;
`;

export const AddBonusLabel = styled.span`
  font-size: ${({ isReward }) => (isReward ? "17px" : "10px")};
  padding-left: ${({ isReward }) => (isReward ? `6px` : "3px")};
`;

export const BonusFieldContainer = styled.div`
  display: flex;
  flex-direction: ${({ isColumn }) => (isColumn ? "column" : "row")};
  position: relative;
  padding-bottom: 5px;
  justify-content: center;
  align-items: center;
`;

export const BonusFieldInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const Label = styled.span`
  color: ${({ isHighlighted, isLight, theme }) =>
    isHighlighted
      ? theme.primary.main
      : isLight
      ? theme.white
      : theme.neutral.main};
  font-size: ${({ isReward, customFontSize }) => {
    if (isReward) return "17px";
    if (customFontSize) return `${customFontSize}px`;
    return "10px";
  }};
  ${({ isBold }) => isBold && `font-weight: bold;`};
  ${({ noPadding, isReward }) =>
    !noPadding && isReward ? `padding-left: 6px` : `padding-left: 3px`};
  ${({ noPadding }) => noPadding && `padding: 0;`};
`;

export const BonusDropDownContainer = styled.div`
  margin: 0 2px;
`;

export const SubTotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 2px;
`;

export const StyledMainButton = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;

  p {
    color: ${({ theme }) => theme.primary.main};
    font-size: 12px;
    text-decoration: underline;
  }
`;

export const StyledWrapperForm = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.neutral.light};
  margin-bottom: 16px;
  gap: 10px;
`;

export const StyledBonusItemsToBeAdd = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0 6px;

  li {
    font-size: 14px;
    color: ${({ theme }) => theme.primary.main};
  }

  small {
    color: ${({ theme }) => theme.neutral.main};
    font-size: 12px;
    margin-right: 6px;
  }
`;

export const StyledBonus = styled.div`
  border: 1px solid ${({ theme }) => theme.primary.main};
  border-radius: 5px;
  display: inline-block;
  padding: 15px;
  margin: 20px 0;
  min-width: 450px;
  width: auto;
  & > div:first-of-type {
    border-bottom: ${({ areThereBonusInProgress }) =>
      areThereBonusInProgress && "1px solid orange"};
    margin-bottom: ${({ areThereBonusInProgress }) =>
      areThereBonusInProgress && "20px"};
    padding-bottom: ${({ areThereBonusInProgress }) =>
      areThereBonusInProgress && "20px"};
    padding-right: 45px;
    position: relative;
  }
`;

export const StyledWrapperList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  button {
    background: transparent;
    color: orange;
    border: none;
    font-size: 10px;
    height: 24px;
    cursor: pointer;

    &:after {
      content: ${({ isAllBonusesDisplayed }) =>
        isAllBonusesDisplayed ? "'⌃'" : "'⌄'"};
      margin-left: 5px;
      height: 30px;
      position: relative;
      top: ${({ isAllBonusesDisplayed }) =>
        isAllBonusesDisplayed ? "3px" : "-2px"};
    }
  }
`;
export const StyledWrapperBonusList = styled.div`
  margin-top: ${({ areExistingBonusesDisplayed }) =>
    areExistingBonusesDisplayed && "15px"};
  border-top: ${({ areExistingBonusesDisplayed, theme }) =>
    areExistingBonusesDisplayed && `1px solid ${theme.primary.main}`};
  padding-top: ${({ areExistingBonusesDisplayed }) =>
    areExistingBonusesDisplayed && `10px`};
`;

export const StyledBonusList = styled.ul`
  height: ${({ isAllBonusesDisplayed }) =>
    isAllBonusesDisplayed ? "auto" : "28px"};
  overflow: hidden;

  li {
    display: flex;
    font-size: 12px;
    color: ${({ theme }) => theme.primary.main};
    margin-bottom: 5px;
    font-weight: 500;
    align-items: center;
    border-top: 1px solid ${({ theme }) => theme.neutral.light};
    padding: 15px 0 10px;
    justify-content: ${({ isBonusesToBeAdded }) =>
      isBonusesToBeAdded ? "space-between" : "flex-start"};
    height: 30px;

    &:first-of-type {
      border-top: none;
      padding: 6px 0 8px;
    }
    &:last-of-type {
      margin-bottom: 0;
    }

    span,
    small {
      margin-right: 6px;
    }

    > span {
      background: ${({ theme }) => theme.primary.main};
      border-radius: 50%;
      display: flex;
      justify-content: center;
      height: 16px;
      align-items: center;
      line-height: 9px;
      font-size: 8px;
      color: ${({ theme }) => theme.white};
      width: 16px;
    }

    small {
      font-size: 10px;
    }
  }

  small {
    color: ${({ theme }) => theme.neutral.main};
    font-weight: 400;
  }

  div {
    display: flex;
    align-items: center;
  }
`;

export const StyledSmallFeature = styled.small`
  margin-top: 15px;
  display: inline-block;
  font-size: 12px;

  > span {
    color: ${({ theme }) => theme.text.primary};
    font-weight: 500;
  }
`;

export const StyledWrapperTitleForm = styled.div`
  color: ${({ theme }) => theme.primary.main};
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
  margin-top: ${({ areThereBonusInProgress }) =>
    areThereBonusInProgress ? "20px" : "0"};
`;

export const StyledActionsButtons = styled.div`
  display: flex;
`;

export const StyledWrapperButtonAdd = styled.div`
  border-left: 1px solid ${({ theme }) => theme.primary.main};
  position: absolute;
  right: 0;
  height: 30px;
`;

import moment from "moment-timezone";
import sortBy from "lodash/fp/sortBy";
import { ZonedDate } from "@teamrota/rota-common";

/**
 * Get monday of date week
 */
export function dateToStartOfWeek(date) {
  const mon = new ZonedDate(date.getTime());
  mon.setHours(0, 0, 0);
  const diff = mon.getDate() - mon.getDay() + (mon.getDay() === 0 ? -6 : 1);
  return new ZonedDate(mon.setDate(diff));
}

/**
 * Get day index staring at monday rather than sunday.
 */
export function getIndexOfDay(dateContext) {
  return [1, 2, 3, 4, 5, 6, 0].indexOf(dateContext.getDay());
}

/**
 * Based on the Y offset of the mouse what are the minutes from the start of the day.
 */
export function getMinutesFromPos(top, pixelsPerMinute) {
  return Math.round(top / pixelsPerMinute);
}

/**
 * Based on a coordinate left position what is the day it lands on.
 */
export function getCoordDate(left, gridWidth, calendarWeekDate) {
  // Left offset of event / column width gets the day offset from start of week
  const dayOffset = Math.ceil(left / (gridWidth / 7)) - 1;

  // Add the dayOffset to start of week
  return moment(calendarWeekDate)
    .add(dayOffset, "days")
    .toDate();
}

/**
 * Based on the coordinates of the coordinates of the newly dragged event
 * calculate the start and end time.
 * (Note): calOffsetScroll takes into account if the user has scrolled before
 * dragging down to create a shift and if so adds the px difference from the top
 * of the element to calcualte the correct time for the shift start
 */
export function getStartEndFromPos(
  { dragStartCoords, dragEndCoords },
  pixelsPerMinute,
  gridWidth,
  calendarWeekDate,
  dragHasSpannedWeeks
) {
  /**
   * NOTE: moment().add(minutes) was changed to date.setMinutes(startMinutes).
   * This is because the former wont hande daylight savings adjustements. Keep
   * this in mind for other areas of the app!
   */

  // Start time minutes + date = start date/time
  const startMinutes = getMinutesFromPos(dragStartCoords.top, pixelsPerMinute);
  const startTime = getCoordDate(
    dragStartCoords.left,
    gridWidth,
    calendarWeekDate
  );
  startTime.setMinutes(startMinutes);

  // End time minutes + date = end date/time
  const endMinutes = getMinutesFromPos(dragEndCoords.top, pixelsPerMinute);
  const endTime = getCoordDate(dragEndCoords.left, gridWidth, calendarWeekDate);
  endTime.setMinutes(endMinutes);

  if (dragHasSpannedWeeks === "forward") {
    startTime.setDate(startTime.getDate() - 7);
  } else if (dragHasSpannedWeeks === "backward") {
    endTime.setDate(endTime.getDate() + 7);
  }

  const [start, end] = sortBy(v => new ZonedDate(v), [startTime, endTime]);
  return { startTime: start, endTime: end };
}

/**
 * Based on the top position of the mouse get formatted time.
 * NOTE: If performance is an issue, change the Math.round function calls to
 * use bitwise '| 0' operation. Function calls are expensive.
 */
export function getCursorTime(top, pixelsPerMinute) {
  const minutes = getMinutesFromPos(top, pixelsPerMinute);
  const toNearestFifteen = Math.round(minutes / 15) * 15;

  // We are only interested in the time so just add minutes to todays date.
  return moment()
    .startOf("day")
    .add(toNearestFifteen, "minutes")
    .format("h:mm a");
}

import React, { useState } from "react";
import Big from "big.js";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import styled from "styled-components";
import {
  colors,
  RotaButton,
  RotaInput,
  RotaTextarea,
  RotaModal,
  RotaSnackBar
} from "@teamrota/rota-design";

import { DELETE_ROLE } from "~/src/containers/roles/graphql/delete-role";
import DeleteModal from "~/src/components/new-pay-rate/delete-modal";
import PaymentInfo from "../payment-info";
import useCreateOrUpdateRole from "../../graphql/use-create-or-update-role";

const StyledSectionWrapper = styled.div`
  padding: 0 0 16px;
  border-bottom: ${({ border }) => `1px solid ${border}`};
`;

const StyledWrapButton = styled.div`
  width: 100%;
  padding: 16px 48px 48px;
`;

const StyledWrapTitle = styled.p`
  font-size: 18px;
  font-weight: 700;
  color: ${colors.darkGrey};
`;

const getInitialValues = role => ({
  id: role?.id || null,
  name: role?.name || "",
  defaultPayRate: role?.defaultPayRate / 100 || 0,
  defaultChargeRate: role?.defaultChargeRate / 100 || 0,
  internalId: role?.internalId || "",
  description: role?.description || "",
  isDayRate: role?.isDayRate || false,
  isOvertimeRate: role?.isOvertimeRate || false,
  isOverstayRate: role?.isOverstayRate || false,
  defaultOvertimePayRate: role?.defaultOvertimePayRate / 100 || 0,
  defaultOvertimeChargeRate: role?.defaultOvertimeChargeRate / 100 || 0,
  defaultOvertimeHoursLimit: role?.defaultOvertimeHoursLimit || 0,
  defaultOverstayPayRate: role?.defaultOverstayPayRate / 100 || 0,
  defaultOverstayChargeRate: role?.defaultOverstayChargeRate / 100 || 0,
  defaultOverstayHoursLimit: role?.defaultOverstayHoursLimit || 0
});

const getRateValue = rate =>
  Big(rate)
    .times(100)
    .toNumber();

const AddEditRoleModal = ({ role, roles, onClose }) => {
  const [{ isOvertimeRate, isOverstayRate }, setRole] = useState({
    isOvertimeRate: role?.isOvertimeRate || false,
    isOverstayRate: role?.isOverstayRate || false
  });
  const [snackbar, setSnackBar] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { createOrUpdateRole, loading, error } = useCreateOrUpdateRole({
    onCompleted: () =>
      setTimeout(() => {
        onClose();
      }, 1000),
    onError: () =>
      setSnackBar({
        open: true,
        severity: "error",
        message: "Something went wrong."
      })
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: getInitialValues(role),
    mode: "onChange"
  });

  const existingRoleNames = roles.map(role => role.name);

  const handleRateTypeChange = ({ target: { name, checked } }) => {
    if (name === "isOvertimeRate" && checked && isOverstayRate) {
      setRole(state => ({ ...state, [name]: checked, isOverstayRate: false }));
    } else if (name === "isOverstayRate" && checked && isOvertimeRate) {
      setRole(state => ({ ...state, [name]: checked, isOvertimeRate: false }));
    } else {
      setRole(state => ({ ...state, [name]: checked }));
    }
  };

  const onSubmit = async data => {
    const variables = {
      ...data,
      isOvertimeRate,
      isOverstayRate,
      defaultPayRate: getRateValue(data.defaultPayRate),
      defaultChargeRate: getRateValue(data.defaultChargeRate),
      defaultOvertimePayRate: getRateValue(data.defaultOvertimePayRate),
      defaultOvertimeChargeRate: getRateValue(data.defaultOvertimeChargeRate),
      defaultOverstayPayRate: getRateValue(data.defaultOverstayPayRate),
      defaultOverstayChargeRate: getRateValue(data.defaultOverstayChargeRate)
    };
    await createOrUpdateRole(variables);
    if (error) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Something went wrong. Please try again."
      });
    } else {
      setSnackBar({
        open: true,
        severity: "success",
        message: "Created successfully."
      });
    }
  };

  const [deleteRole, { error: err }] = useMutation(DELETE_ROLE, {
    refetchQueries: ["getRoles"]
  });

  const handleDeleteRole = async id => {
    await deleteRole({
      variables: { id }
    });

    if (err) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Something went wrong. Please try again."
      });
    } else {
      setIsDeleteModalOpen(false);
      setSnackBar({
        open: true,
        severity: "success",
        message: "Deleted successfully."
      });
      setTimeout(() => {
        onClose();
      }, 1000);
    }
  };

  const onCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const onDelete = () => {
    role
      ? setIsDeleteModalOpen(true)
      : setSnackBar({
          open: true,
          severity: "error",
          message: "No role to delete."
        });
  };

  return (
    <>
      <RotaModal
        onClose={onClose}
        onDelete={() => onDelete()}
        name={role ? role.name : "New role"}
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <StyledSectionWrapper border={colors.darkGrey}>
              <StyledWrapTitle>General info</StyledWrapTitle>
              <RotaInput
                label="Role name:"
                isError={errors.name}
                errorMessage={errors.name?.message}
                autocomplete="off"
                {...register("name", {
                  required: "A role must have a name",
                  validate: value =>
                    role ||
                    (!role && !existingRoleNames.includes(value)) ||
                    `A role with name "${value}" already exist`
                })}
              />
              <RotaTextarea
                label="Role descripton: "
                {...register("description")}
              />
              <RotaInput label="Internal ID:" {...register("internalId")} />
            </StyledSectionWrapper>
            <StyledSectionWrapper style={{ paddingTop: "0px" }}>
              <PaymentInfo
                register={register}
                control={control}
                errors={errors}
                initialIsDayRate={!!role?.isDayRate}
                isOvertimeRate={isOvertimeRate}
                isOverstayRate={isOverstayRate}
                handleRateTypeChange={handleRateTypeChange}
              />
            </StyledSectionWrapper>
            <StyledWrapButton>
              <RotaButton
                type="submit"
                disabled={loading}
                loading={loading}
                style={{ float: "right" }}
              >
                Save
              </RotaButton>
            </StyledWrapButton>
          </form>
        </div>
        {isDeleteModalOpen && (
          <DeleteModal
            onCancel={() => onCancel()}
            deleteRoleName={role ? role.name : "New role"}
            handleDeleteRole={() => handleDeleteRole(role?.id)}
            deleteRoleId={role?.id}
          />
        )}
        <RotaSnackBar
          onClose={() => setSnackBar({})}
          snackOpen={snackbar?.open}
          severity={snackbar?.severity}
          message={snackbar?.message}
        />
      </RotaModal>
    </>
  );
};

export default AddEditRoleModal;

import styled from "styled-components";
import { COLORS } from "~/src/styles/config.style";

export const LinkedShiftCheckbox = styled.div`
  align-self: center;
  padding: 10px 0;
`;

export const DateTitle = styled.p`
  font-weight: bold;
  margin-right: 5px;
`;

export const DateTime = styled.div`
  width: 50px;
  flex: none;

  input {
    text-align: center;
    border-radius: 0;
    border-bottom: 1px solid transparent;
    padding: 3px;
  }

  input:hover,
  input:focus {
    border-bottom: 1px solid ${({ theme }) => theme.primary.main};
  }
`;

export const BreakInputWrapper = styled.div`
  width: 35px;
  flex: none;
  margin-left: 20;
  font-weight: 100;

  input {
    text-align: center;
    border-radius: 0;
    border-bottom: 1px solid transparent;
    padding: 3px;
  }

  input:hover,
  input:focus {
    border-bottom: 1px solid ${({ theme }) => theme.primary.main};
  }
`;

export const BreakWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const BreakMinText = styled.p`
  padding-bottom: 2px;
`;

export const AdditionalNoteText = styled.p`
  padding-bottom: 2px;
  font-style: italic;
`;

export const Group = styled.div`
  display: flex;
  margin: 0 -10px 30px;
`;

export const Item = styled.div`
  ${props => props.width && `width: ${props.width};`}
  padding: 0 10px;
  position: relative;
`;

export const ScrollerContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  padding-top: 30px;
  left: 0;
  top: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &::after {
    content: "";
    position: absolute;
    height: 50px;
    bottom: 0;
    width: 100%;
    left: 0;
    background: linear-gradient(to top, #fff, rgba(255, 255, 255, 0));
    z-index: 1;
    pointer-events: none;
  }
`;

export const Scroller = styled.div`
  overflow: auto;
  height: 100%;
  padding-bottom: 50px;
`;

export const DateTable = styled.table`
  margin: 0 auto;
`;

export const TableHeader = styled.p`
  text-align: center;
  margin: 0;
`;

export const ErrorText = styled.p`
  color: ${COLORS.RED};
`;

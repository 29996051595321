import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import getOr from "lodash/fp/getOr";
import { errorModal } from "~/src/utils/errors";
import LoadMore from "~/src/components/load-more";
import Members from "../components/members";
import { onUpdateRecipients } from "../reducer";
import {
  Container,
  Divider,
  FullHeightScroll,
  MembersContainer
} from "../notifications.styles";
import { RotaCheckbox } from "@teamrota/rota-design";

const bindActions = dispatch =>
  bindActionCreators(
    {
      onUpdateRecipients
    },
    dispatch
  );

@connect(null, bindActions)
export default class extends Component {
  state = {
    offset: 0
  };

  handleLoadMore = async () => {
    if (!this.props.areMembersLoading && this.canLoadMoreMembers()) {
      try {
        const newOffset = this.state.offset + this.props.limit;
        await this.props.fetchMore(newOffset);
        this.setState({ offset: newOffset });
      } catch (e) {
        errorModal(e);
      }
    }
  };

  canLoadMoreMembers = () => this.state.offset < this.props.totalResults;

  render() {
    const { props } = this;
    const members = getOr([], "members", props);
    const isNoResults = members.length === 0 && !props.areMembersLoading;
    const isNoMoreResults = members.length === props.totalResults;

    return (
      <FullHeightScroll>
        <Container>
          <RotaCheckbox
            isChecked={props.isToAllMembers}
            label={`All (${props.totalResults ?? 0})`}
            onClick={props.onToggleAllMembers}
          />
        </Container>

        <Divider />

        {isNoResults && <p>No members found...</p>}

        <MembersContainer>
          <Members
            members={members}
            fetchMore={props.fetchMore}
            totalResults={props.totalResults}
            onUpdateRecipients={props.onUpdateRecipients}
            limit={props.limit}
          />

          {!isNoResults && !isNoMoreResults && (
            <LoadMore
              isLoading={props.areMembersLoading}
              onLoadMore={this.handleLoadMore}
            />
          )}
        </MembersContainer>
      </FullHeightScroll>
    );
  }
}

import React, { createContext, useState } from "react";

export const SnackContext = createContext({});

const SnackContextProvider = ({ children }) => {
  const [severity, setSeverity] = useState("");
  const [snackText, setSnackText] = useState("");
  const [snackOpen, setSnackOpen] = useState(false);
  const [isColor, setIsColor] = useState("");

  return (
    <SnackContext.Provider
      value={{
        severity,
        setSeverity,
        snackOpen,
        setSnackOpen,
        snackText,
        setSnackText,
        isColor,
        setIsColor
      }}
    >
      {children}
    </SnackContext.Provider>
  );
};

export default SnackContextProvider;

import React from "react";
import Stars from "~/src/components/stars";

export default function StarsInput(props) {
  return (
    <Stars
      shouldOverrideClick={props.shouldOverrideClick}
      value={props.input.value}
      onChange={props.input.onChange}
      isEditable={!props.readOnly}
      id={props.id}
      upcomingAccepted={props.upcomingAccepted}
      isMember={props.isMember}
      memberRating={props.previousRating}
    />
  );
}

import { graphql } from "@apollo/client/react/hoc";
import findIndex from "lodash/fp/findIndex";
import set from "lodash/fp/set";
import CREATE_OR_UPDATE_NOTES from "./annotate-item.query";

export default graphql(CREATE_OR_UPDATE_NOTES, {
  name: "annotateItem",
  props: ownProps => ({
    annotateItem: (tableId, tableName, note) => {
      ownProps.annotateItem({
        variables: { tableId, tableName, note },
        updateQueries: {
          getProvideShifts(currentResult, { mutationResult: { data } }) {
            const currentShifts = currentResult.account.shifts.data;
            const noteUpdate = data.createOrUpdateNotes;
            if (noteUpdate) {
              const shiftIndex = findIndex(
                { id: noteUpdate.tableId },
                currentShifts
              );

              if (shiftIndex < 0) return currentResult;
              return set(
                "account.shifts.data",
                // Update the shift in the array
                Object.assign([], currentShifts, {
                  [shiftIndex]: {
                    ...currentShifts[shiftIndex],
                    notes: noteUpdate.note
                  }
                }),
                currentResult
              );
            }

            return currentResult;
          }
        }
      });
    }
  })
});

export const reducer = (state, action) => {
  switch (action.type) {
    case "SUBMIT_START": {
      return { ...state, errorMessage: "", isSaving: true };
    }
    case "SUBMIT_END": {
      return { ...state, isInEditMode: false, isSaving: false, hasSaved: true };
    }
    case "SUBMIT_ERROR": {
      return {
        ...state,
        isInEditMode: true,
        isSaving: false,
        hasSaved: false,
        isSnackOpen: true,
        errorMessage: action.errorMessage
      };
    }
    case "SET_EDIT_MODE_ON": {
      return { ...state, hasSaved: false, isInEditMode: true };
    }
    case "SET_EDIT_MODE_OFF": {
      return { ...state, isInEditMode: false };
    }
    case "CLOSE_SNACK": {
      return { ...state, isSnackOpen: false };
    }
    default:
      throw new Error();
  }
};

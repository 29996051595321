import { gql } from "@apollo/client";

export const CREATE_UPDATE_MEMBER_NOTES = gql`
  mutation Mutation($tableName: String!, $tableId: ID!, $note: String!) {
    createOrUpdateNotes(tableName: $tableName, tableId: $tableId, note: $note) {
      tableName
      tableId
      note
    }
  }
`;

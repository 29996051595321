import styled from "styled-components";
import { COLORS, STYLE_CONSTS } from "~/src/styles/config.style";

export const Header = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.CORN_YELLOW};
  font-size: ${STYLE_CONSTS.FONT_XSMALL};
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_MEDIUM};
  text-transform: capitalize;
`;

export const Time = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.text.primary};
  font-size: ${STYLE_CONSTS.FONT_XSMALL};
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_MEDIUM};
  padding: 0 2px;
`;

export const IconWrapper = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const GridCardWrapper = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  border: 2px solid ${COLORS.MEDIUM_YELLOW};
  border-radius: 7px;
  padding: 5px;
  background: repeating-linear-gradient(
    -60deg,
    ${COLORS.WHITE},
    ${COLORS.WHITE} 10px,
    ${COLORS.LIGHT_YELLOW} 10px,
    ${COLORS.LIGHT_YELLOW} 20px
  );
  :hover {
    background-color: ${({ theme }) => theme.white};
    border: 2px solid silver;
  }
  :hover ${Header} {
    display: none;
  }
  :hover ${Time} {
    display: none;
  }
  :hover ${IconWrapper} {
    display: flex;
  }
`;

import React, { useState } from "react";
import { RotaButton, colors } from "@teamrota/rota-design";

import {
  StyledWrapDialog,
  StyledWrapIcon,
  StyledWrapQuestion,
  StyledWrapAfirmation,
  StyledWrapTextArea,
  StyledWrapButtons,
  StyledErrorMessage
} from "./dialog-style";

const DialogTurnedAway = ({
  icon,
  firstName,
  lastName,
  onSaveNotes,
  notesUpdate,
  onConfirm,
  onCancel
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  return (
    <StyledWrapDialog>
      <StyledWrapIcon color="#FFBE64" borderColor={colors.rotaOrange}>
        {icon}
      </StyledWrapIcon>
      <StyledWrapQuestion color={colors.rotaOrange}>
        Are you sure you want to mark {firstName} {lastName} as turned away?
      </StyledWrapQuestion>
      <StyledWrapAfirmation>
        Please specify reason for turning away:
      </StyledWrapAfirmation>

      <StyledWrapTextArea
        type="textarea"
        rows="8"
        cols="40"
        defaultValue={notesUpdate}
        onBlur={e => onSaveNotes(e.target.value)}
      />

      <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
      <StyledWrapButtons>
        <RotaButton variant="secondary" onClick={() => onCancel()}>
          Cancel
        </RotaButton>
        <RotaButton
          onClick={() => {
            notesUpdate
              ? onConfirm()
              : setErrorMessage(
                  "*You need to specify a reason in order to confirm. "
                );
          }}
        >
          Confirm
        </RotaButton>
      </StyledWrapButtons>
    </StyledWrapDialog>
  );
};

export default DialogTurnedAway;

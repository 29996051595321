import styled from "styled-components";

import { RotaButtonIcon } from "@teamrota/rota-design";

export const TabWrapper = styled.div`
  margin: 0 0 10px 0;
  padding-bottom: 5px;
  border-bottom: solid 1px ${({ theme }) => theme.neutral.light};
`;

export const SettingsWrapper = styled.div`
  min-height: 500px;
  padding: 20px;
`;

export const OptionsButton = styled(RotaButtonIcon)`
  position: relative;
`;

interface ErrorProps {
  isShow: boolean;
}

export const Error = styled.div<ErrorProps>`
  position: relative;

  width: 100%;
  height: ${({ isShow }) => (isShow ? "100px" : "0")};
  overflow: hidden;

  margin: ${({ isShow }) => (isShow ? "5px 0" : "0")};
  padding: ${({ isShow }) => (isShow ? "10px" : "0")};
  border: ${({ isShow }) => (isShow ? "dashed 1px red" : "none")};

  font-family: monospace;
  background-color: white;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  &:before {
    flex-grow: 0;
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: white;
    font-size: 200%;
    text-align: center;
    background-color: red;
    border-radius: 20px;
    content: "!";
  }

  transition: all 0.1s;
`;

export const ErrorMessage = styled.div`
  flex-grow: 0;
  max-height: 100%;
  max-width: calc(100% - 60px);
  overflow-y: scroll;
`;

import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";

export const GET_CONNECTIONS = gql`
  query getConnections(
    $offset: Int!
    $limit: Int!
    $searchText: String
    $orderByProp: ConnectionsOrderByPropEnumType
    $orderByDirection: OrderByEnumType
    $hasVenuesInServiceArea: ID
  ) {
    account {
      id
      connections(
        accountType: provider
        offset: $offset
        limit: $limit
        searchText: $searchText
        orderByProp: $orderByProp
        orderByDirection: $orderByDirection
        hasVenuesInServiceArea: $hasVenuesInServiceArea
      ) {
        data {
          targetAccount {
            id
            accountName
            roleRates(accountType: provider) {
              id
              roleId
              roleName
              isUncalculatedRate
            }
            venues {
              id
              name
            }
          }
        }
        totalResults
      }
    }
  }
`;

export const usePartners = () => {
  const { loading, data } = useQuery(GET_CONNECTIONS, {
    variables: {
      offset: 0,
      limit: 1000,
      orderByProp: "accountName"
    }
  });

  const partners = data?.account.connections.data.map(
    connection => connection.targetAccount
  );

  const partnerIds = partners?.map(partner => partner.id);

  return { loading, partners, partnerIds };
};

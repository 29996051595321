import React, { useEffect, useRef } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { SHIFT_STATUS } from "../../restrict";
import {
  Wrapper,
  InnerWrapper,
  MainContent,
  StyledButton,
  Title,
  Text,
  TitleWrapper,
  ButtonWrapper,
  StyledExitButton,
  POP_UP_ANIMATION,
  ENTER_TIME,
  LEAVE_TIME
} from "./styles";

const RestrictShiftModal = ({
  isVisible,
  disable24Hours,
  onUpdate,
  onClose
}) => {
  const node = useRef(null);

  const handleRestrict = () => {
    onUpdate(SHIFT_STATUS.SKIPALGO);
    onClose(true);
  };

  const handleRestrictWithOpening = () => {
    onUpdate(SHIFT_STATUS.DELAYED());
    onClose(true);
  };

  const handleClose = () => onClose(true);

  const handleClick = e => {
    if (!node.current?.contains(e.target)) onClose(true);
  };

  useEffect(() => {
    if (isVisible) {
      document.addEventListener("mousedown", handleClick, false);
    } else {
      document.removeEventListener("mousedown", handleClick, false);
    }
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <CSSTransitionGroup
      transitionName={POP_UP_ANIMATION}
      transitionEnterTimeout={ENTER_TIME}
      transitionLeaveTimeout={LEAVE_TIME}
      transitionAppear
      transitionAppearTimeout={500}
    >
      <Wrapper>
        <InnerWrapper ref={node}>
          <MainContent>
            <StyledExitButton isGrey isXSmall onClick={handleClose} size="8" />
            <TitleWrapper>
              <Title>Fulfilment alert</Title>
            </TitleWrapper>
            <Text>
              This shift is restricted and visible for 24 hours to the specific
              staff you’ve chosen.
            </Text>
            <Text>
              To keep this shift restricted for the duration between booking and
              the shift occurring, select ‘Keep restricted’ below.{" "}
            </Text>
            <Text>
              Selecting open shift will make this available to recommended staff
              once 24 hours has passed.
            </Text>
          </MainContent>
          <ButtonWrapper>
            <StyledButton onClick={handleRestrict}>
              Keep Restricted
            </StyledButton>
            <StyledButton
              onClick={handleRestrictWithOpening}
              isDisabled={disable24Hours}
            >
              Open shift after 24 hours
            </StyledButton>
          </ButtonWrapper>
        </InnerWrapper>
      </Wrapper>
    </CSSTransitionGroup>
  );
};

export default RestrictShiftModal;

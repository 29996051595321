import React from "react";
import moment from "moment-timezone";
import { Role } from "@teamrota/authlib";
import { RotaButtonIcon, iconNew } from "@teamrota/rota-design";
import {
  DOCUMENT_TYPES,
  DOCUMENT_TYPES_LABELS,
  RIGHT_TO_WORK_SUBTYPES_LABELS
} from "~/src/consts";
import useAuth from "~/src/auth/hooks/use-auth";

import { DocumentsTable, ChecklistIcon } from "./styles";

const DocumentTypeLabel = ({ document }) => {
  let label = "N/A";

  if (document.type === DOCUMENT_TYPES.RIGHT_TO_WORK && document.subtype) {
    label = RIGHT_TO_WORK_SUBTYPES_LABELS[document.subtype];
  } else if (document.type) {
    label =
      document.type === DOCUMENT_TYPES.OTHER
        ? document.fileName || DOCUMENT_TYPES_LABELS.OTHER
        : DOCUMENT_TYPES_LABELS[document.type];
  }
  return document.url ? (
    <a href={document.url} target="_blank">
      {label}
    </a>
  ) : (
    { label }
  );
};

const { Bin } = iconNew;

const ComplianceDocumentsTable = ({ complianceDocuments, deleteDocument }) => {
  const auth = useAuth();

  return (
    <DocumentsTable>
      <thead>
        <tr>
          <th>&nbsp;</th>
          <th>Type</th>
          <th>File</th>
          <th>Issue Date</th>
          <th>Expiry Date</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {complianceDocuments.map((document, index) => (
          <tr key={index}>
            <td>
              <ChecklistIcon />
            </td>
            <td align={"left"}>
              <DocumentTypeLabel document={document} />
            </td>
            <td>{document.format.split("/").pop()}</td>
            <td>
              {document.issuedAt
                ? moment(document.issuedAt).format("DD/MM/YYYY")
                : "N/A"}
            </td>
            <td>
              {document.expiresAt
                ? moment(document.expiresAt).format("DD/MM/YYYY")
                : "N/A"}
            </td>
            <td>
              {auth.hasRole(Role.MEMBER_COMPLIANCE_EDIT) && (
                <RotaButtonIcon
                  onClick={() =>
                    deleteDocument(
                      document.id,
                      document.format,
                      document.__typename
                    )
                  }
                >
                  <Bin />
                </RotaButtonIcon>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </DocumentsTable>
  );
};

export default ComplianceDocumentsTable;

import React from "react";
import Panel from "~/src/components/panel";

const TemplatePanel = ({ title, isEditMode, isSticky, children }) => {
  const getTabs = () => (isEditMode ? [{ title: "Edit" }] : [{ title: title }]);
  return (
    <Panel tabs={getTabs()} isSticky={isSticky}>
      {children}
    </Panel>
  );
};

export default TemplatePanel;

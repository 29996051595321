import styled from "styled-components";

export const InputsRow = styled.div`
  display: flex;
  gap: 50px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 16px;
`;

import styled from "styled-components";

export const Title = styled.div`
  position: sticky;
  top: 0;
  height: 40px;
  font-size: 120%;
  line-height: 40px;
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.neutral.main};
  background-color: ${({ theme }) => theme.white};
  border-bottom: solid 1px black;
  z-index: 500;
  overflow: hidden;
`;

export const Separator = styled.span`
  color: ${({ theme }) => theme.primary.main};
  padding: 0 5px;
  font-weight: 500;

  &::after {
    content: "|";
  }
`;

import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  & > div {
    width: 100%;
  }
`;

import React, { createContext, useEffect, useState } from "react";

import getUserTypeTemplates from "~/src/containers/settings-page/graphql/get-user-type-templates";
import { langs, trees } from "@teamrota/authlib";

export const RolesContext = createContext({});

const RolesContextProvider = ({ children, isLoading, userTypeTemplates }) => {
  const [roles, setRoles] = useState([]);
  const [hasChanged, setHasChanged] = useState(false);
  const [selectedUserTypeId, setSelectedUserTypeId] = useState(1);
  const [isEditable, setIsEditable] = useState(true);
  const [isSearched, setIsSearched] = useState("");
  const [searchInput, setSearchInput] = useState([]);

  useEffect(() => {
    if (isLoading) {
      setRoles([]);
    } else {
      setRoles(
        userTypeTemplates.find(t => t.id == selectedUserTypeId)?.roles ?? []
      );
    }
    let searchName = [];
    trees.forEach(firstLevel => {
      searchName.push(langs.en[firstLevel.name]);
      firstLevel.branches.forEach(secLevel => {
        searchName.push(langs.en[secLevel.name]);
        secLevel.branches.forEach(tirLevel => {
          searchName.push(langs.en[tirLevel.name]);
        });
      });
    });
    setSearchInput(isLoading ? [] : searchName);
  }, [isLoading]);

  return (
    <RolesContext.Provider
      value={{
        roles,
        setRoles,
        selectedUserTypeId,
        setSelectedUserTypeId,
        hasChanged,
        setHasChanged,
        isEditable,
        setIsEditable,
        isSearched,
        setIsSearched,
        searchInput,
        setSearchInput
      }}
    >
      {children}
    </RolesContext.Provider>
  );
};

export default getUserTypeTemplates(RolesContextProvider);

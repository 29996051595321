import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

export default graphql(
  gql`
    mutation updateBonus(
      $shiftId: ID
      $id: ID
      $amount: Int
      $period: String
      $type: String
      $reason: String
    ) {
      updateBonus(
        shiftId: $shiftId
        id: $id
        amount: $amount
        period: $period
        type: $type
        reason: $reason
      ) {
        shiftId
        bonuses {
          id
          amount
          period
          type
          reason
          createdAt
          deletedAt
          createdBy
          deletedBy
        }
        __typename
      }
    }
  `,
  {
    name: "createOrUpdateBonusReward",
    props: ownProps => ({
      createOrUpdateBonusReward: variables =>
        ownProps.createOrUpdateBonusReward({
          variables
        })
    })
  }
);

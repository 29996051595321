import styled from "styled-components";
import { COLORS, STYLE_CONSTS } from "~/src/styles/config.style";
import Button from "~/src/components/button";
import Icon from "~/src/components/icon";

export const SmallText = styled.div`
  font-size: ${STYLE_CONSTS.FONT_SMALL};
`;

export const GreyText = styled.div`
  color: ${COLORS.COOL_GREY};
`;

export const OpenShiftText = styled.div`
  margin-top: ${STYLE_CONSTS.UNIT_1};
`;

export const StyledButton = styled(Button)`
  margin-right: ${STYLE_CONSTS.UNIT_2};
`;

export const Tab = styled.span`
  margin-right: ${STYLE_CONSTS.UNIT_3};
  display: ${({ shouldHide }) => (shouldHide ? "none" : "")};
  color: ${({ isDisabled }) => (isDisabled ? COLORS.CLOUDY_BLUE : "inherit")};
  cursor: pointer;
`;

export const NoResults = styled.div`
  text-align: center;
  margin: ${STYLE_CONSTS.UNIT_8};
`;

export const LinkedShiftTag = styled.div`
  background-color: ${({ bgColor }) => bgColor || COLORS.AZURE};
  padding: 0 ${STYLE_CONSTS.UNIT_2};
  line-height: ${STYLE_CONSTS.UNIT_4};
  display: inline-flex;
  align-items: center;
  vertical-align: text-top;
  font-size: ${STYLE_CONSTS.FONT_SMALL};
  color: white;
  border-radius: ${STYLE_CONSTS.BORDER_RADIUS_XL};
  cursor: pointer;
`;

export const StyledIcon = styled(Icon)`
  margin-right: ${STYLE_CONSTS.UNIT_1};
`;

import React, { useState } from "react";
import moment from "moment-timezone";
import { ZonedDate } from "@teamrota/rota-common";

import DateInput from "~/src/components/form-components/date-input";
import TextInput from "~/src/components/form-components/text-input";
import { isValidDate, dateToString } from "~/src/utils/validators";
import createOrUpdateMember from "~/src/containers/modals/view-edit-member/graphql/create-or-update-member";
import { COLORS } from "~/src/styles/config.style";
import { Wrapper, Field, Label, Error } from "./dbs.styles";

const Dbs = ({
  memberId,
  createOrUpdateMember,
  certificateNumber,
  issueDate,
  isDisabled
}) => {
  const [dbsCertNum, setDbsCertNum] = useState(certificateNumber);
  const [dbsIssuedAt, setDbsIssuedAt] = useState(
    issueDate ? dateToString(issueDate) : ""
  );
  const [certError, setCertError] = useState("");
  const [dateError, setDateError] = useState("");

  const validator = field => {
    switch (field) {
      case "number":
        return dbsCertNum
          ? dbsCertNum?.length === 12 && !isNaN(dbsCertNum)
          : true;
      case "date":
        return dbsIssuedAt
          ? isValidDate(dbsIssuedAt) &&
              !moment(dbsIssuedAt, "DD/MM/YYYY").isAfter(new ZonedDate(), "day")
          : true;
      default:
        return false;
    }
  };

  const handleNumberChange = () => {
    setCertError("");
    if (validator("number"))
      createOrUpdateMember(memberId, {
        dbsCertificateNumber: dbsCertNum
      });
    else if (dbsCertNum)
      setCertError("Certificate Number should be exactly 12 digits");
  };

  const handleDateChange = () => {
    setDateError("");
    if (validator("date"))
      createOrUpdateMember(memberId, {
        dbsIssueDate: moment(dbsIssuedAt, "DD/MM/YYYY")
      });
    else if (dbsIssuedAt === "DD/MM/YYYY")
      setDbsIssuedAt(issueDate ? dateToString(issueDate) : "");
    else if (isValidDate(dbsIssuedAt))
      setDateError("Issue date should be on/before today");
  };

  return (
    <Wrapper>
      <Field>
        <Label>Certificate Number</Label>
        <TextInput
          placeholder="Enter Certificate Number"
          isBlock
          isInline
          maxLength={12}
          fontSizeOveride="12px"
          fontColor={validator("number") ? null : COLORS.RED}
          value={dbsCertNum}
          onChangeValue={value => setDbsCertNum(value)}
          onBlur={handleNumberChange}
          isDisabled={isDisabled}
        />
        {certError && <Error>{certError}</Error>}
      </Field>
      <Field>
        <Label>Issue Date</Label>
        <DateInput
          placeholder="Enter Issue Date"
          value={dbsIssuedAt}
          onChange={value => setDbsIssuedAt(value)}
          onBlur={handleDateChange}
          isError={!validator("date")}
          isDisabled={isDisabled}
        />
        {dateError && <Error>{dateError}</Error>}
      </Field>
    </Wrapper>
  );
};

export default createOrUpdateMember(Dbs);

import React from "react";
import styled from "styled-components";
import { iconNew } from "@teamrota/rota-design";

import { typography, colors } from "@teamrota/rota-design";

import { hexToRGBA } from "~/src/styles/style-helpers";

const { StyledSmallText } = typography;

export const CARD_WIDTH = 100;
export const CARD_MARGIN = 5;

const { Cancel } = iconNew;
// dnd library does not accept style component

const card = {
  position: "relative",
  border: `1px solid ${colors.dustGrey}`,
  borderRadius: "5px",
  display: "flex",
  flexDirection: "column",
  margin: `0 ${CARD_MARGIN}px 0 0`,
  padding: "5px 0",
  width: `${CARD_WIDTH}px`,
  minWidth: `${CARD_WIDTH}px`,
  height: "100%",
  overflow: "hidden"
};

const styles = {
  card,
  cardDisabled: {
    ...card,
    border: `1px solid ${colors.smokeGrey}`
  },
  drop: {
    display: "flex",
    minWidth: "100%"
  }
};

export const StyledName = styled(props => (
  <StyledSmallText color="#9B9B9B" {...props} />
))`
  line-height: 10px;
  font-size: 12px;
  text-align: center;
  word-wrap: break-word;
  white-space: initial;
  display: block;
`;

export const StyledRedBox = styled.div`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
`;

export const StyledWrapImage = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 8px;
`;

export const StyledWrapText = styled.div`
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`;

export const StyledWrapContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledRemoveIcon = styled(Cancel)`
  position: absolute;
  right: 2px;
  top: 2px;
  cursor: pointer;
`;

export const StyledDisabledOverlay = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: ${hexToRGBA(colors.white, 0.8)};
  z-index: 50;
`;

export default styles;

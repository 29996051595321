import { STYLE_CONSTS } from "~/src/styles/config.style";
import styled, { keyframes, css } from "styled-components";

const loading = keyframes`
  from {
    background-color: ${({ theme }) => theme.neutral.lighter};
    opacity: 1;
  }

  to {
    background-color: ${({ theme }) => theme.neutral.light};
    opacity: .8;
  }
`;

const LOADING_ANIMATION = css`
  ${loading} .6s infinite alternate ease-in-out
`;

export const Container = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
`;

export const Strip = styled.div`
  animation: ${LOADING_ANIMATION};
  height: ${STYLE_CONSTS.UNIT_2};
  margin-bottom: ${STYLE_CONSTS.UNIT_2};
  width: ${p => p.width || "200px"};
`;

export const Circle = styled.div`
  animation: ${LOADING_ANIMATION};
  height: 70px;
  width: 70px;
  margin-right: ${STYLE_CONSTS.UNIT_1};
  border-radius: 50%;
`;

export const TextContainer = styled.div`
  flex: 1;
  flex-direction: column;
`;

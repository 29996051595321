import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

const styles = {
  checkboxWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "left"
  },
  checkboxLabel: {
    marginTop: 6
  }
};

export default styles;

export const Section = styled.div`
  margin-bottom: ${STYLE_CONSTS.UNIT_4};
`;

export const Title = styled.div`
  margin-bottom: ${STYLE_CONSTS.UNIT_1};
  font-size: ${STYLE_CONSTS.FONT_MEDIUM};
  color: ${({ theme }) => theme.neutral.main};
`;

export const Content = styled.div`
  font-size: ${STYLE_CONSTS.FONT_MEDIUM};
  color: ${({ theme }) => theme.text.primary};
`;

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.primary.main};
  margin-bottom: ${STYLE_CONSTS.UNIT_3};
`;

export const Cell = styled.div`
  display: table-cell;
  flex: ${({ width }) => (width ? "none" : 1)};
  position: relative;
  font-size: ${STYLE_CONSTS.FONT_MEDIUM};
  padding-bottom: ${STYLE_CONSTS.UNIT_2};
  width: ${({ width }) => width || "auto"};
  max-width: ${({ width }) => width || "auto"};
  text-align: ${({ center }) => (center ? "center" : "default")};
`;

export const Row = styled.div`
  display: flex;
  padding-left: ${STYLE_CONSTS.UNIT_2};
  ${({ isItalic }) => isItalic && "font-style: italic;"};
  ${({ heading, theme }) =>
    heading &&
    `
  	padding-top: ${STYLE_CONSTS.UNIT_2};
  	font-size: ${STYLE_CONSTS.FONT_MEDIUM};
  	color: ${theme.neutral.main};
  `};
`;

export const OrangeText = styled.div`
  display: inline-block;
  color: ${({ editMode, theme }) =>
    editMode ? theme.primary.main : theme.text.primary};
`;

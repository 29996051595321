import { graphql } from "@apollo/client/react/hoc";
import { gql } from "@apollo/client";

const REFRESH_QUERY = gql`
  query getRefreshedBonus($shiftId: ID) {
    account {
      id
      shift(id: $shiftId) {
        id
        bonuses {
          id
          type: period
          amount
          reason
        }
      }
    }
  }
`;

export default graphql(REFRESH_QUERY, {
  props: ({ data: { refetch } }) => ({
    refreshBonusRewards: refetch
  }),
  options: props => ({
    skip: !props.shift && !props.shift.id,
    variables: {
      __online__: true,
      shiftId: props.shift.id
    }
  })
});

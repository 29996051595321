import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query getUsersList(
    $searchTerm: String
    $accountId: ID
    $limit: Int!
    $offset: Int!
  ) {
    users(
      searchTerm: $searchTerm
      accountId: $accountId
      limit: $limit
      offset: $offset
    ) {
      id
      firstName
      lastName
      email
      extraRoles
    }
  }
`;

import styled from "styled-components";
import TimeField from "time-input";
import Textarea from "react-textarea-autosize";
import { inputStyles } from "../input-styles";

export const Input = styled.input.attrs({
  tabIndex: props => (props.noFocus ? "-1" : "0")
})`
${inputStyles};
${({ bgColor, theme }) =>
  bgColor && `background-color: ${theme.neutral.light};`}
  text-align: ${({ centerText }) => (centerText ? "center" : "unset")};
  height: 36px;
  width: 100%;
  border: ${({ isBorderless, theme }) =>
    isBorderless ? "" : `1px solid ${theme.neutral.light}`};
  border-radius: ${({ borderRadiusSize }) => borderRadiusSize || "5px"};
`;

export const TimeInput = styled(TimeField)`
  > input {
    ${inputStyles};
  }
  height: 36px;
`;

export const MultilineInput = styled(Textarea)`
  ${inputStyles};
`;

import styled, { keyframes } from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const Id = styled.div`
  color: ${({ theme }) => theme.neutral.main};
  margin-bottom: ${STYLE_CONSTS.UNIT_2};
`;

export const Details = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-left: ${STYLE_CONSTS.UNIT_2};
  position: relative;
`;

const fadeOutUp = keyframes`
  from {
    opacity: 1;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -300%, 0);
  }
`;

export const CopySuccess = styled.div`
  position: absolute;
  animation: ${fadeOutUp} 1s forwards ease;
  background-color: ${({ theme }) => theme.neutral.lighter};
  border-radius: 5px;
  display: inline-block;
  padding: ${STYLE_CONSTS.UNIT_HALF};
`;

export const DetailRow = styled.div`
  color: ${({ theme }) => theme.neutral.main};
  font-size: ${STYLE_CONSTS.FONT_SMALL};
`;

export const DetailSummaryRow = styled.div`
  color: ${({ theme }) => theme.neutral.dark};
  font-size: ${STYLE_CONSTS.FONT};
  position: relative;
  left: -72px;
  top: ${props =>
    props.hasDivider ? STYLE_CONSTS.UNIT_4 : STYLE_CONSTS.UNIT_2};
  margin-bottom: 10px;
  white-space: pre-wrap;
  width: calc(100% - 72px);
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const MaybeError = styled.div`
  color: ${({ isError, theme }) => (isError ? theme.error.main : "inherit")};
`;

export const HoverHighlight = styled.div`
  transition: opacity 0.2s ease-in;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`;

export const CurrentShiftStatus = styled.div`
  display: flex;
`;

export const Dot = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${({ isDonut, theme }) =>
    isDonut ? theme.white : theme.success.main};
  border: ${({ isDonut, theme }) =>
    isDonut ? `2px solid ${theme.success.main}` : ""};
  margin-right: ${STYLE_CONSTS.UNIT_HALF};
`;

export const Notes = styled.div`
  margin-top: 16px;
`;

import { gql, useQuery } from "@apollo/client";

const GET_UPCOMING_BOOKINGS_COUNT = gql`
  query getUpcomingBookingsCount($memberId: ID!) {
    account {
      id
      member(id: $memberId) {
        id
        bookingsCount {
          upcomingAccepted
        }
      }
    }
  }
`;

export const useUpcomingBookingsCount = ({ memberId }) => {
  const { data, loading } = useQuery(GET_UPCOMING_BOOKINGS_COUNT, {
    fetchPolicy: "cache-and-network",
    variables: { memberId },
    skip: !memberId,
    onError: error => {
      throw error;
    }
  });

  return {
    isLoadingUpcomingBookingsCount: loading,
    upcomingBookingsCount:
      data?.account?.member?.bookingsCount?.upcomingAccepted
  };
};

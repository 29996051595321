import React from "react";
import { getOr, isUndefined } from "lodash/fp";
import { TableData, TableRow } from "~/src/components/floating-table";
import Textarea from "~/src/components/form-components/textarea";
import Icon from "~/src/components/icon";

import { PAGESCROLL_ELEMENT_ID } from "~/src/consts";

import { formatDateMonthTime } from "~/src/utils/formatting";
import BookingList from "../booking-list";

import MoreOptionsMenu from "../more-options-menu";
import ShiftColumn from "../shift-column";
import {
  GreyText,
  OpenShiftText,
  SmallText,
  StyledButton
} from "../../provide.styles";

export const ShiftRow = ({
  shift,
  handleOpenAccountModal,
  setOverbookShiftId,
  setOverbookPercentage,
  onUpdateFilters,
  autoAcceptUpdates,
  handleSetAutoAccept,
  handleSetFeaturedShift,
  alignNotes,
  handleSaveNotes,
  openShiftBookingList,
  handleOpenShiftModal,
  refetch,
  setTabIndex,
  shiftBookingList
}) => {
  return (
    <TableRow key={shift.id}>
      <TableData width={5 / 12}>
        <ShiftColumn
          onPartnerClick={() => handleOpenAccountModal(shift.sourceAccount.id)}
          onSetAutoAccept={value => handleSetAutoAccept(shift.id, value)}
          shift={shift}
          onOverbookShift={() => {
            setOverbookShiftId(shift.id);
            setOverbookPercentage(
              shift.overbookPercentage
                ? String(shift.overbookPercentage)
                : "0.00"
            );
          }}
          onActivateLinkingMode={() => {
            onUpdateFilters({
              shiftGroupId: shift.shiftGroupId
            });

            document.getElementById(PAGESCROLL_ELEMENT_ID).scrollTop = 0;
          }}
          isAutoAcceptEnabled={
            isUndefined(autoAcceptUpdates[shift.id])
              ? shift.isAutoAcceptEnabled
              : autoAcceptUpdates[shift.id]
          }
          isFeatured={shift.isFeatured}
          onSetUpFeaturedShift={value =>
            handleSetFeaturedShift(shift.id, value)
          }
        />
      </TableData>
      <TableData verticalAlign={alignNotes(shift.notes)} width={3 / 12}>
        <Textarea
          isMultilineAuto
          isBorderless
          placeholder="Shift notes..."
          defaultValue={shift.notes || undefined}
          onBlur={({ target }) => handleSaveNotes(shift.id, target.value)}
        />
      </TableData>
      <TableData width={2 / 12}>
        <SmallText>
          {formatDateMonthTime(shift.postedAt)}
          {shift.openedAt && (
            <OpenShiftText>
              <div>Opened at</div>
              <GreyText>{formatDateMonthTime(shift.openedAt)}</GreyText>
            </OpenShiftText>
          )}
          {shift.finalisedAt && (
            <OpenShiftText>
              <div>Finalised at</div>
              <GreyText>{formatDateMonthTime(shift.finalisedAt)}</GreyText>
            </OpenShiftText>
          )}
        </SmallText>
      </TableData>
      <TableData width={2 / 12}>
        <StyledButton
          iconName={Icon.names.SHIFTS}
          isIconOnly
          isSmall
          isBorderless
          onClick={() => openShiftBookingList(shift)}
        />
        <StyledButton
          iconName={Icon.names.INFO}
          isSmall
          isIconOnly
          isBorderless
          onClick={() => handleOpenShiftModal(shift.id)}
        />
        <MoreOptionsMenu shift={shift} refetch={refetch} />
      </TableData>
      <div>
        <BookingList
          shift={shift}
          setTabIndex={setTabIndex}
          shiftItem={getOr({}, shift.id, shiftBookingList)}
          refetch={refetch}
        />
      </div>
    </TableRow>
  );
};

import React from "react";
import { useMutation } from "@apollo/client";
import MatcherConfigForm from "~/src/components/matcher-config-form";
import UPDATE_TARGET_MATCHER from "../graphql/update-target-matcher";

const UserInfo = ({ isPartner, account, matcherConfig }) => {
  const [updateTargetMatcher] = useMutation(UPDATE_TARGET_MATCHER);
  return (
    <MatcherConfigForm
      isPartner={isPartner}
      isAccount
      onSave={matcherConfig =>
        updateTargetMatcher({
          variables: {
            ...matcherConfig,
            accountId: account.id
          },
          refetchQueries: ["getTargetAccount", "getProfile"]
        })
      }
      matcherConfig={matcherConfig || {}}
    />
  );
};

export default UserInfo;

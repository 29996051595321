import isArray from "lodash/fp/isArray";
import flow from "lodash/fp/flow";
import { isValidDate } from "~/src/utils/validators";
import pickBy from "lodash/fp/pickBy";
import reduce from "lodash/fp/reduce";

const uncapped = reduce.convert({ cap: false });

export default function parseFiltersToTags(filters) {
  const presentSelectOptions = flow(
    pickBy(isArray),
    pickBy(arr => arr.length > 0),
    uncapped((arr, value, key) => {
      const label = value.map(({ name }) => name).join(", ");
      return [...arr, { keys: [key], label }];
    }, [])
  )(filters);
  const isStartTimeValid = filters.startTime && isValidDate(filters.startTime);
  const isEndTimeValid = filters.endTime && isValidDate(filters.endTime);
  if (isStartTimeValid && isEndTimeValid) {
    presentSelectOptions.push({
      keys: ["startTime", "endTime"],
      label: `From ${filters.startTime} to ${filters.endTime}`
    });
  } else if (isStartTimeValid) {
    presentSelectOptions.push({
      label: `From ${filters.startTime}`,
      keys: ["startTime"]
    });
  } else if (isEndTimeValid) {
    presentSelectOptions.push({
      label: `To ${filters.endTime}`,
      keys: ["endTime"]
    });
  }

  presentSelectOptions.push({
    label: `Finalised: ${getShiftStateLabel(filters.isFinalised)}`,
    keys: ["isFinalised"]
  });
  presentSelectOptions.push({
    label: `Cancelled: ${getShiftStateLabel(filters.isCancelled)}`,
    keys: ["isCancelled"]
  });

  return presentSelectOptions;
}

function getShiftStateLabel(stateBoolOrNull) {
  if (stateBoolOrNull === null || stateBoolOrNull === undefined) {
    return "Both";
  }

  if (stateBoolOrNull) {
    return "Yes";
  }

  return "No";
}

import React from "react";
import { connect } from "react-redux";
import find from "lodash/fp/find";
import isEmpty from "lodash/fp/isEmpty";
import last from "lodash/fp/last";
import { Wrapper, Title, OuterWrapper } from "./completed-shift.styles";
import { extractShiftTimesText } from "../shared";
import { EditButton } from "../styles";
import { mapStateToProps, mapDispatchToProps } from "./selectors";

const CompletedShift = ({
  shifts,
  roleRates,
  handleShiftChange,
  handleRemoveShift,
  shiftOpenIndex
}) => {
  if (!shifts || shifts.length <= 1) return null;

  return (
    <OuterWrapper>
      {shifts?.map(shift => {
        const selectedRole = find(
          ({ id }) => id === shift.roleRateId,
          roleRates
        );
        const s = shift.numberRequested === 1 ? "" : "s";
        const members =
          !shift.numberRequested || !selectedRole
            ? "Members not set"
            : `${shift.numberRequested} ${selectedRole.roleName}${s}`;

        return (
          <Wrapper
            key={shift.index}
            onClick={() => handleShiftChange(shift.index)}
          >
            <Title isActive={shift.isActive}>
              {shift.isNew && isEmpty(shift.times)
                ? "New Shift"
                : `${extractShiftTimesText(shift, true)} • ${members}`}
            </Title>
            <EditButton
              onClick={e => {
                e.stopPropagation();
                handleRemoveShift(shift.index);
                if (shift.index === shiftOpenIndex)
                  handleShiftChange(
                    last(shifts.filter(s => s.index !== shiftOpenIndex)).index
                  );
              }}
            >
              Remove
            </EditButton>
          </Wrapper>
        );
      })}
    </OuterWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CompletedShift);

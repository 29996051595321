import styled from "styled-components";
import TimeField from "time-input";

export const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.neutral.light};
  box-sizing: border-box;
  border-radius: 4px;
  width: 100px;
  height: 32px;
  display: flex;
  align-items: center;
`;

export const Input = styled(TimeField)`
  > input {
    width: 100%;
    border: none;
    text-align: center;
    &:focus {
      outline: none;
    }
  }
`;

import { COLORS, STYLE_CONSTS } from "~/src/styles/config.style";

const styles = {
  btnSpan: {
    fontSize: "10px",
    color: "#9E9E9E"
  },
  rightPanelContainer: {
    width: "100%",
    height: "100%",
    backgroundColor: COLORS.WHITE,
    margin: "0 55px 0 32px",
    borderRadius: "10px",
    boxShadow: STYLE_CONSTS.FLOATING_LARGE,
    padding: "5px 30px 30px 30px"
  },
  headerTabs: {
    display: "flex",
    borderBottom: "solid 1px #D3D3D3",
    height: "60px"
  }
};

export default styles;

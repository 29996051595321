import { gql, useMutation } from "@apollo/client";

const DELETE_SUBVENUE = gql`
  mutation deleteSubvenue($id: ID!) {
    deleteSubvenue(id: $id) {
      name
    }
  }
`;

export const useDeleteSubvenue = ({ onCompleted, onError }) => {
  const [deleteSubvenue, { loading, error }] = useMutation(DELETE_SUBVENUE, {
    refetchQueries: ["getProfile"],
    onCompleted,
    onError
  });

  return { deleteSubvenue, loading, error };
};

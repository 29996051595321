import React from "react";
import Icon from "~/src/components/icon";
import { Wrapper, Image, IconWrapper, StyledIcon } from "./account-logo.styles";

export default function AccountLogo(props) {
  return (
    <Wrapper>
      <Image src={props.src} onClick={props.onClick} />
      {props.isLinked && (
        <IconWrapper color={props.color} onClick={props.onClickLink}>
          <StyledIcon
            color={Icon.colors.WHITE}
            name={Icon.names.LINKED_SHIFTS}
            size={Icon.sizes.XSMALL}
          />
        </IconWrapper>
      )}
    </Wrapper>
  );
}

import { gql } from "@apollo/client";

export default gql`
  mutation createOrUpdateNotes(
    $tableId: ID!
    $tableName: String!
    $note: String!
  ) {
    createOrUpdateNotes(tableId: $tableId, tableName: $tableName, note: $note) {
      note
      tableName
      tableId
    }
  }
`;

import styled from "styled-components";

export const Text = styled.div`
  margin: 32px 0;
  font-size: 20px;
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

import React from "react";
import createOrUpdateMember from "~/src/containers/modals/view-edit-member/graphql/create-or-update-member";
import { getOr, compose } from "lodash/fp";
import { RotaButtonNew } from "@teamrota/rota-design";
import Ellipses from "~/src/components/ellipsis";
import Member from "~/src/components/member-details";
import Button from "~/src/components/button";
import ActionDropdown from "~/src/components/action-dropdown";
import Icon from "~/src/components/icon";
import { formatDateFull, getLengthInClockHours } from "~/src/utils/formatting";
import { COLORS } from "~/src/styles/config.style";
import { errorModal } from "~/src/utils/errors";
import getPoolType from "~/src/utils/get-pool-type";
import TimeDisplay from "~/src/components/shift-time-display";
import {
  BOOKING_STATES,
  MEMBER_STATES,
  MENU_OPTIONS,
  SHIFT_TYPE
} from "~/src/consts";
import tabs from "./pastShiftTabs";
import BookingRating from "./booking-rating";
import { BOOKING_LIMIT } from "../../../reducer";
import { shouldLoadMoreBookings } from "../helpers";
import {
  FlexContainer,
  FlexBasis,
  EmptyBookings,
  LoadMoreWrapper,
  TabContainer,
  Tab,
  ButtonWrapper
} from "../booking-list.styles";
import { CheckRow, Feedback, SmallText } from "./past-bookings.styles";

// Render button title based on current onboarding state
const renderTitle = ({ member: { state } }) => {
  switch (state) {
    case MEMBER_STATES.PENDING:
      return "Onboarding";
    case MEMBER_STATES.ACTIVE:
      return "Accepted";
    case MEMBER_STATES.REJECTED:
      return "Rejected";
    default:
      return "Oh No!";
  }
};

// If 'partner' CONST 'ONBOARDING'
const isAnOnboardingSession = shiftObj => {
  const isOnboardingAccount =
    getOr(false, "sourceAccount.accountName", shiftObj) ===
    SHIFT_TYPE.ONBOARDING;
  if (isOnboardingAccount) {
    return true;
  }
  return false;
};

const PastShift = props => {
  const handleLoadMore = async () => {
    try {
      await props.fetchMore();
    } catch (e) {
      errorModal(e);
    }
  };

  const { bookings } = props;
  const bookingsExist = bookings && bookings.data && bookings.data.length;
  const isOnboardingLayout = isAnOnboardingSession(props.shift);
  console.log(BOOKING_LIMIT);

  return (
    <div>
      <TabContainer>
        {tabs.map((tab, key) => (
          <Tab
            isActive={tab.keyProp === props.shiftItem.keyProp}
            key={key}
            onClick={() =>
              props.setTabIndex({
                shiftId: props.shift.id,
                activeBookingStates: tab.bookingStates,
                keyProp: tab.keyProp
              })
            }
          >
            {tab.label} {props.shift.bookingCounts[tab.keyProp]}
          </Tab>
        ))}
      </TabContainer>
      {!bookingsExist ? (
        <EmptyBookings>No bookings</EmptyBookings>
      ) : (
        bookings.data.map((booking, index) => {
          const { startFinal, endFinal } = booking.bookingState;
          return (
            <FlexContainer key={index}>
              <FlexBasis>
                <Member
                  {...booking.member}
                  approvalState={booking.approvalState}
                  canRenderMemberModal
                  shouldHighlightMemberPhoto
                  poolType={getPoolType(
                    booking.member.relationships,
                    props.shift.sourceAccount.id
                  )}
                />
              </FlexBasis>
              {!isOnboardingLayout && <BookingRating booking={booking} />}
              {!isOnboardingLayout && (
                <Feedback>
                  <Ellipses>
                    {`${booking.feedbackForMember || ""} ${(
                      bookings.feedbackLabelsForMember || []
                    ).join``}`.trim() ||
                      `${booking.bookingNotes || ""}`.trim() ||
                      "No feedback"}
                  </Ellipses>
                </Feedback>
              )}
              {![BOOKING_STATES.NO_SHOW, BOOKING_STATES.TURNED_AWAY].includes(
                booking.state
              ) &&
                !isOnboardingLayout && (
                  <div>
                    <div>
                      <CheckRow>
                        <TimeDisplay
                          shouldNotShowCarryOver
                          optionalLabelText="Check-In: "
                          displayDate={formatDateFull(startFinal)}
                          startTime={startFinal}
                          endTime={endFinal}
                        />
                      </CheckRow>
                    </div>
                    <CheckRow>
                      <TimeDisplay
                        optionalLabelText="Check-Out: "
                        displayDate={formatDateFull(endFinal)}
                        startTime={startFinal}
                        endTime={endFinal}
                      />
                    </CheckRow>
                    <SmallText>
                      <Icon
                        name={Icon.names.CLOCK}
                        color={COLORS.CLOUDY_BLUE}
                        size="xsmall"
                      />
                      {`${getLengthInClockHours(
                        booking.bookingState.lengthFinal
                      )}h`}
                      {booking.bookingState.breakFinal
                        ? `${booking.bookingState.breakFinal}mins break`
                        : "No break"}
                    </SmallText>
                  </div>
                )}

              <FlexBasis basis="7%">{booking.bucketReason}</FlexBasis>
              <ButtonWrapper>
                {isOnboardingLayout && (
                  <ActionDropdown
                    leftMargin
                    isSmall
                    onChange={option =>
                      props.createOrUpdateMember(booking.member.id, {
                        state:
                          option.value === MEMBER_STATES.ACTIVE
                            ? MEMBER_STATES.ACTIVE
                            : MEMBER_STATES.REJECTED
                      })
                    }
                    value={renderTitle(booking)}
                    options={MENU_OPTIONS.ONBOARDING}
                  />
                )}
                <RotaButtonNew
                  variant="outlined"
                  onClick={() => props.onViewBooking(booking.id)}
                >
                  View Booking
                </RotaButtonNew>
              </ButtonWrapper>
            </FlexContainer>
          );
        })
      )}
      {!shouldLoadMoreBookings(props) && (
        <LoadMoreWrapper>
          <Button onClick={handleLoadMore} isSmall isLoading={props.isLoading}>
            Load more
          </Button>
        </LoadMoreWrapper>
      )}
    </div>
  );
};
// }

export default compose(createOrUpdateMember)(PastShift);

import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { ThemeProvider } from "@teamrota/rota-design/dist/components/theme-provider";

import printComponent from "~/src/utils/print-component";
import PrintableTimesheet from "~/src/components/printable/timesheet";

import { useCurrency } from "~/src/containers/profile-loader";
import useAuth from "~/src/auth/hooks/use-auth";
import { AuthContext } from "~/src/auth/contexts";

import AsyncCollapse from "~/src/components/async-collapse";

import { GET_SHIFT_BY_ID } from "~/src/containers/timesheets/graphql/shift-by-id";

import ShiftRow from "../shift-row";
import Timesheet from "../timesheet";

import { StyledWrapper } from "./block-styles";

export const TimesheetBlock = ({
  sourceAccountId,
  shift,
  openTimesheetId,
  setOpenTimesheetId,
  handleInternalIdSave,
  onApprovalBegin,
  onApprovalEnded,
  setSnackMessage
}) => {
  const auth = useAuth();
  const { currencyCode } = useCurrency();

  const [isApproving, setIsApproving] = useState(false);

  const [isPrinting, setIsPrinting] = useState(false);
  const [approvalState, setApprovalState] = useState(shift.approvalState);
  const [reviewedAt, setReviewedAt] = useState(shift.reviewedAt);

  const {
    data: shiftDetails,
    loading: isLoadingShiftDetails,
    refetch: refetchShiftDetails
  } = useQuery(GET_SHIFT_BY_ID, {
    skip: shift?.id !== openTimesheetId && !isPrinting,
    variables: {
      shiftId: shift?.id
    }
  });

  useEffect(() => {
    // detect when updated (i.e. by requested refetch)
    // so we can stop showing the approving... spinner

    if (
      approvalState !== shift.approvalState ||
      reviewedAt !== shift.reviewedAt
    ) {
      setIsApproving(false);
      setApprovalState(shift.approvalState);
      setReviewedAt(shift.reviewedAt);

      onApprovalEnded?.(shift?.id);
    }
  }, [shift.approvalState, shift.reviewedAt]);

  useEffect(() => {
    return () => {
      // this component may be moved in its parent list
      // it may also be umounted, catch both these possibilities
      onApprovalEnded?.(shift?.id);
    };
  }, []);

  const handleApprovalBegin = () => {
    setIsApproving(true);

    if (openTimesheetId === shift?.id) setOpenTimesheetId(null);
    onApprovalBegin?.(shift?.id);
  };

  useEffect(() => {
    if (isPrinting && !isLoadingShiftDetails) {
      setIsPrinting(false);

      printComponent(
        <AuthContext.Provider value={{ auth }}>
          <ThemeProvider>
            <PrintableTimesheet
              auth={auth}
              shift={shift}
              shiftPrinted={shiftDetails}
              currencyCode={currencyCode}
            />
          </ThemeProvider>
        </AuthContext.Provider>
      );
    }
  }, [isPrinting, isLoadingShiftDetails]);

  useEffect(() => {
    if (shift?.id === openTimesheetId) refetchShiftDetails();
  }, [openTimesheetId]);

  return (
    <StyledWrapper key={shift?.id}>
      <ShiftRow
        key={shift?.id}
        shift={shift}
        handleInternalIdSave={handleInternalIdSave}
        setOpenTimesheetId={setOpenTimesheetId}
        openTimesheetId={openTimesheetId}
        isExpanded={shift?.id === openTimesheetId}
        sourceAccountId={sourceAccountId}
        isApproving={isApproving}
        onPrintRequest={() => setIsPrinting(true)}
      />

      <AsyncCollapse
        isLoading={isLoadingShiftDetails && shift?.id === openTimesheetId}
        isExpanded={shift?.id === openTimesheetId}
      >
        <Timesheet
          key={shift?.id}
          sourceAccountId={sourceAccountId}
          shift={shiftDetails}
          shiftInfo={shift}
          refetchShift={refetchShiftDetails}
          onApprovalBegin={handleApprovalBegin}
          setSnackMessage={setSnackMessage}
        />
      </AsyncCollapse>
    </StyledWrapper>
  );
};

import { gql } from "@apollo/client";

export const GET_OPTIONAL_FEATURES = gql`
  query getOptionalFeatures($accountId: ID!) {
    optionalFeatures(accountId: $accountId) {
      scheduler
      providerScheduler
      isOverLimitWorkAllowed
      isShiftCancellingAllowed
      isComplianceExpiryEnabled
      isProviderPayRateOverride
      isPayCalculationOnCheckInOutTimes
      isSmsOptionAvailable
    }
  }
`;

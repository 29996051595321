import React from "react";
import moment from "moment-timezone";

import { PARTNER_MANAGED_THRESHOLD } from "~/src/consts";
import TimeDisplay from "~/src/components/shift-time-display";
import Icon from "~/src/components/icon";
import {
  CalendarEventBox,
  EventHeader,
  EventFooter,
  EventName,
  Progress,
  ProgressLabel,
  GroupIcon,
  EventRole,
  EventRestricted,
  ManagedIcon
} from "./styles";
// Logic to decide text that can show based on height/time of event
const ROOM_TO_SHOW = ({ eventName: name }) => ({
  SINGLE_CELL: 34,
  EVENT_NAME: 10,
  VENUE_NAME: name ? 128 : 108,
  EVENT_ROLE: name ? 30 : 20,
  SHIFT_TIME: name ? 65 : 48,
  FULLFILL: name ? 100 : 80
});

const formatStartEnd = ({ startTime, endTime }) =>
  `${moment(startTime).format("HH:mm")} - ${moment(endTime).format("HH:mm")}`;

export default function CalendarEvent(props) {
  const { shifts, data, spanning } = props.event;
  const isCancelled = shifts.every(shift => shift.cancelledAt);
  const totalAccepted = shifts.reduce(
    (sum, shift) => sum + shift.bookingCounts.accepted,
    0
  );
  const totalRequested = shifts.reduce(
    (sum, shift) => sum + shift.numberRequested,
    0
  );
  const progress = (totalAccepted / totalRequested) * 100;
  const [shift] = props.event.shifts;
  const { startTime, endTime } = props.event.data;

  const calendarHeight = (({
    event: {
      position: { top, bottom }
    }
  }) => bottom - top)(props);

  const isPartnerManaged =
    shift.isPartnerManaged &&
    moment()
      .add(PARTNER_MANAGED_THRESHOLD, "days")
      .isBefore(moment(startTime));

  return (
    <CalendarEventBox
      eventBoxHeight={calendarHeight}
      onClick={e => props.onClick(e)}
      isToNext={spanning.toNext}
      isFromPrevious={spanning.fromPrevious}
      isCancelled={isCancelled}
    >
      {!spanning.fromPrevious ? (
        <EventHeader eventBoxHeight={calendarHeight}>
          {calendarHeight > ROOM_TO_SHOW(data).EVENT_NAME && data.eventName && (
            <EventName>{data.eventName}</EventName>
          )}

          {calendarHeight > ROOM_TO_SHOW(data).EVENT_ROLE && (
            <EventRole>
              {`${shift.numberRequested} x ${shift.roleRate.roleName}`}
            </EventRole>
          )}

          {calendarHeight > ROOM_TO_SHOW(data).VENUE_NAME && (
            <EventRole>{data.venueName}</EventRole>
          )}

          {calendarHeight > ROOM_TO_SHOW(data).EVENT_ROLE &&
            shift.isRestricted &&
            !shift.isShiftRebook && (
              <EventRestricted>Restricted</EventRestricted>
            )}

          {calendarHeight > ROOM_TO_SHOW(data).EVENT_ROLE &&
            shift.isShiftRebook && <EventRestricted>Rebook</EventRestricted>}

          {calendarHeight > ROOM_TO_SHOW(data).SHIFT_TIME && (
            <TimeDisplay
              displayDate={formatStartEnd(data)}
              startTime={startTime}
              endTime={endTime}
            />
          )}
        </EventHeader>
      ) : null}
      {!spanning.toNext && calendarHeight > ROOM_TO_SHOW(data).FULLFILL ? (
        <EventFooter>
          <Progress progress={progress} />
          <ProgressLabel>
            {totalAccepted} / {totalRequested}
          </ProgressLabel>
          <GroupIcon
            backgrouColor={shift.shiftGroupColor}
            isVisible={shift.totalInGroup > 1}
            name={Icon.names.LINKED_SHIFTS}
            size={14}
            color={Icon.colors.WHITE}
          />
          <ManagedIcon
            isVisible={isPartnerManaged}
            name={Icon.names.SELF_MANAGED}
            size={20}
            color={Icon.colors.WHITE}
            isAlone={shift.totalInGroup === 1}
          />
        </EventFooter>
      ) : null}
    </CalendarEventBox>
  );
}

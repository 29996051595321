import React from "react";
import PolicyForm from "~/src/components/policy-form";
import createOrUpdateShift from "../graphql/create-or-update-shift";

const EditPolicies = props => {
  const { shift, createOrUpdateShift } = props;
  return (
    <PolicyForm
      onSave={policy =>
        createOrUpdateShift(shift.id, {
          policy
        })
      }
      policy={
        shift.policy || {
          minimumShiftLength: 0,
          minimumShiftPercentage: 0,
          cancellationPeriod: 0
        }
      }
    />
  );
};

export default createOrUpdateShift(EditPolicies);

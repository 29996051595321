import { gql } from "@apollo/client";

export const GET_UNAVAILABILITY_UPLOADS = gql`
  query unavailabilityUploads($offset: Int, $limit: Int) {
    account {
      id
      unavailabilityUploads(offset: $offset, limit: $limit) {
        id
        format
        fileName
        status
        validatedAt
        importedAt
        validationErrors
        uploader {
          firstName
          lastName
        }
      }
    }
  }
`;

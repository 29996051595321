import React from "react";
import InputWrapper from "../input-wrapper";
import { RotaSwitch } from "@teamrota/rota-design";

export default props => (
  <InputWrapper {...props}>
    <RotaSwitch
      disabled={props.readOnly}
      checked={props.input.value}
      onChange={() => props.input.onChange(!props.input.value)}
      label={props.label}
    />
  </InputWrapper>
);

import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const MODAL_WIDTH = "1090px";
export const SMALL_MODAL_WIDTH = "800px";

export const Content = styled.div`
  padding: ${STYLE_CONSTS.UNIT_10};
  width: 100vw;
  height: 100vh;
  @media screen and (min-width: ${STYLE_CONSTS.BREAK_POINT_XLARGE}) {
    width: ${MODAL_WIDTH};
    height: ${({ isLarge }) => (isLarge ? `97vh` : "90vh")};
  }
`;

export const SmallContent = styled.div`
  padding: ${STYLE_CONSTS.UNIT_10};
  width: 100vw;
  height: auto;
  @media screen and (min-width: ${SMALL_MODAL_WIDTH}) {
    width: ${SMALL_MODAL_WIDTH};
  }
`;

export const Divider = styled.div`
  border-bottom: ${STYLE_CONSTS.SMALL_DIVIDER_BORDER};
  width: ${({ width }) => (width ? `${width}px` : "100%")};
`;

export const Title = styled.div`
  font-size: ${STYLE_CONSTS.FONT_XLARGE};
  color: ${({ theme }) => theme.text.primary};
  padding-bottom: 20px;
`;

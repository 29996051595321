import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const StyledContent = styled.div`
  overflow: auto;
  padding: 30px;
  width: 50vw;
  height: auto;
`;
export const BonusWrapper = styled.div`
  padding-top: 8px;
`;
export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Divider = styled.div`
  border-bottom: ${STYLE_CONSTS.SMALL_DIVIDER_BORDER};
  padding-bottom: 5px;
  width: 100%;
`;

export const Title = styled.div`
  font-size: ${STYLE_CONSTS.FONT_XLARGE};
  color: ${({ theme }) => theme.text.primary};
`;

import styled from "styled-components";

export const TabsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: solid 1px ${({ theme }) => theme.neutral.light};
  margin-bottom: 32px;
`;

export const TabsTitle = styled.p`
  margin-right: 16px;
  font-size: 14px;
  font-weight: 400;
`;

export const RoleAndCountWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ oneColumn }) => (oneColumn ? "1fr" : "1fr 1fr")};
  gap: 16px;
`;

import React from "react";
import HoursWorked from "./hours-worked";
import LimitedHours from "./limited-hours";
import { StyledAvailability } from "./styles";

const Availability = ({ member }) => {
  return (
    <StyledAvailability>
      <HoursWorked memberId={member?.id} />
      <LimitedHours member={member} />
    </StyledAvailability>
  );
};

export default Availability;

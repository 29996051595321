import { getOr, get, pick, omit } from "lodash/fp";
import moment from "moment-timezone";

import { Role } from "@teamrota/authlib";
import {
  combineCountryPhoneNumber,
  countryByCode,
  regex
} from "@teamrota/rota-common";

import {
  isValidDate,
  dateToString,
  stringToDate
} from "~/src/utils/validators";

export const getFields = (auth, isSave = false) => {
  const fields = [
    "title",
    "nickname",
    "firstName",
    "lastName",
    "middleName",
    "gender",
    "nationality",
    "ethnicity",
    "disability",
    "notes",
    "email",
    "phone",
    "houseNumber",
    "streetName",
    "town",
    "postcode",
    "county",
    "country",
    "emergencyContactTitle",
    "emergencyContactName",
    "emergencyContactNumber",
    "emergencyContactRelationship",
    "emergencyContactEmail",
    "venueId",
    "internalId",
    "startDate",
    "endDate",
    "tags",
    "registeredNumber",
    "registeredAddress",
    "invoicingAddress",
    "isVATRegistered",
    "isCompanyRegistrationCertificateAttached",
    "isHMRCSelfEmployedConfirmationCertificateAttached",
    "isConfirmationCertificateChecked",
    "isConfirmationOfRightToWork",
    "isPublicLiabilityProofAttached",
    "isContractForServicesSigned",
    "isConfirmationOfBankDetailsAttached",
    "isVATCertificateAttached",
    "isVATCertificateChecked",
    "memberType",
    "ownerId",
    "annualSalary",
    "weeklyContractedHours",
    "isInsideIR35",
    "p46Question",
    "requiresTravel",
    "isEligibleForRebook"
  ];

  if (
    (isSave && auth.hasRole(Role.MEMBERS_STATE_EDIT)) ||
    (!isSave && auth.hasRole(Role.MEMBERS_STATE))
  ) {
    fields.push(...["state"]);
  }

  if (
    (isSave && auth.hasRole(Role.MEMBERS_BANK_EDIT)) ||
    (!isSave && auth.hasRole(Role.MEMBERS_BANK))
  ) {
    fields.push(
      ...[
        "bankName",
        "accountName",
        "sortCode",
        "rollNumber",
        "accountNumber",
        "NINumber",
        "UTRNumber",
        "VATRegistrationNumber"
      ]
    );
  }

  return fields;
};

const getDefaultValues = auth =>
  Object.fromEntries(getFields(auth).map(val => [val, null]));

export const getValuesFromProps = (auth, props) => ({
  ...getDefaultValues(auth),
  ...pick(getFields(auth, false))(props.member),
  serviceAreas: getOr([], "member.serviceAreas", props).map(area => area.id),
  birthDate: get("member.birthDate", props)
    ? dateToString(props.member.birthDate)
    : null,
  startDate: get("member.startDate", props)
    ? dateToString(props.member.startDate)
    : null,
  endDate: get("member.endDate", props)
    ? dateToString(props.member.endDate)
    : null,
  metadata: props?.member?.metadata?.membersArea || null,
  ownerId: get("member.ownerId", props) || auth.info.id
});

export const getValuesToSave = (auth, values) => ({
  ...pick(getFields(auth, true))(values),
  phone: combineCountryPhoneNumber(
    countryByCode[values.phoneCountry],
    values.phoneNumber
  ),
  emergencyContactNumber: combineCountryPhoneNumber(
    countryByCode[values.emergencyPhoneCountry],
    values.emergencyPhoneNumber
  ),
  serviceAreas: values.serviceAreas,
  birthDate: stringToDate(values.birthDate),
  startDate: stringToDate(values.startDate),
  endDate: stringToDate(values.endDate),
  metadata: { membersArea: values.membersArea },
  tags: (get("tags", values) || []).map(omit(["__typename"]))
});

export const validate = values =>
  Object.keys(values).reduce((acc, current) => {
    let error = "";
    if (current === "birthDate") {
      if (!isValidDate(values.birthDate)) {
        error = "Please enter a valid date DD/MM/YYYY";
      } else if (
        moment(values.birthDate, "DD/MM/YYYY").isBefore(
          moment("01/01/1930", "DD/MM/YYYY")
        ) ||
        moment().isBefore(moment(values.birthDate, "DD/MM/YYYY"))
      ) {
        error = "Please enter a valid birth date";
      }
    }

    if (current === "startDate") {
      if (!isValidDate(values.startDate)) {
        error = "Please enter a valid date DD/MM/YYYY";
      }
    }

    if (current === "endDate") {
      if (!isValidDate(values.endDate)) {
        error = "Please enter a valid date DD/MM/YYYY";
      }
    }

    if (current === "sortCode") {
      if (!!values.sortCode && !/^\d{6}$/.test(values.sortCode)) {
        error = "Please enter a 6 digit bank sort code";
      }
    }

    if (current === "accountNumber") {
      if (!!values.accountNumber && !/^\d{8}$/.test(values.accountNumber)) {
        error = "Please enter an 8 digit bank account number";
      }
    }

    if (current === "email") {
      if (!regex.EMAIL_ADDRESS.test(values.email)) {
        error = "Please enter a valid email";
      }
    }
    return {
      ...acc,
      [current]: error
    };
  }, {});

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const ADD_GROUP_MEMBER = gql`
  mutation addGroupMember($memberId: ID, $groupId: ID) {
    addGroupMember(memberId: $memberId, groupId: $groupId) {
      id
      __typename
    }
  }
`;

export default graphql(ADD_GROUP_MEMBER, {
  name: "addGroupMember",
  props: ownProps => ({
    addGroupMember: data =>
      ownProps.addGroupMember({
        variables: {
          memberId: data.memberId,
          groupId: data.groupId
        },
        refetchQueries: ["getGroups"]
      })
  })
});

import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "@apollo/client";

import { RotaButton, RotaInput } from "@teamrota/rota-design";

import MemberPhoto from "~/src/components/member-photo";
import { getLabel } from "~/src/components/StaffZonesPanel/components/MemberCarousel/labels";

import { GET_ACCOUNT_SETTINGS } from "./graphql/get-account-detail";

import {
  StyledModal,
  StyledExitButton,
  StyledTitle,
  StyledMemberName,
  StyledPhotoWrapper,
  StyledIssue,
  StyledEmph,
  StyledButtonWrapper,
  StyledConfirmWrapper,
  StyledMemberWrapper,
  StyledMembersWrapper
} from "./styles";

const ShiftUpdateMemberConflict = ({
  warning,
  accountType,
  roleRateId,
  onClose
}) => {
  const node = useRef(null);
  const [confirmationInput, setConfirmationInput] = useState("");

  const { data: accountData } = useQuery(GET_ACCOUNT_SETTINGS, {
    variables: { accountType, roleRateId },
    fetchPolicy: "network-only"
  });

  const handleUpdate = () => {
    warning?.callback();
    onClose();
  };

  const handleClose = () => onClose();

  const handleClick = e => {
    if (!node.current?.contains(e.target)) onClose();
  };

  useEffect(() => {
    if (warning) {
      document.addEventListener("mousedown", handleClick, false);
    } else {
      document.removeEventListener("mousedown", handleClick, false);
    }
  }, [warning]);

  if (!warning) {
    return null;
  }

  const { members } = warning;

  const isMembersGreaterThanOne = members.length > 1;

  const isConfirmed = confirmationInput?.trim()?.toLowerCase() == "yes";

  const isOverLimitError = !accountData?.account?.isOverLimitWorkAllowed;

  return (
    <StyledModal>
      <div ref={node}>
        <StyledExitButton isGrey isXSmall onClick={handleClose} size="8" />
        <StyledTitle>
          {isOverLimitError ? "Error Updating Shift" : "Confirm Shift Update"}
        </StyledTitle>

        <StyledMembersWrapper>
          {members.map(member => {
            const memberName = `${member.firstName.trim()} ${member.lastName.trim()}`;
            const label = getLabel({ ...member, isHitWorkingHoursLimit: true });
            return (
              <StyledMemberWrapper key={member.id}>
                <StyledPhotoWrapper>
                  <MemberPhoto
                    src={member.photo}
                    poolType={member.relationship?.poolType}
                    status={member.state}
                    isAsleep={member.isAsleep}
                    errorLabelColor={label?.color}
                    errorLabel={label?.text}
                    isShowTooltip={false}
                    size="small"
                  />
                </StyledPhotoWrapper>
                <StyledMemberName>{memberName}</StyledMemberName>
              </StyledMemberWrapper>
            );
          })}
        </StyledMembersWrapper>

        {isOverLimitError ? (
          <StyledIssue style={{ border: "none" }}>
            {isMembersGreaterThanOne
              ? "These above members are"
              : "The above member is"}{" "}
            over their weekly hour working limit, so the shift cannot be
            updated.
          </StyledIssue>
        ) : (
          <StyledIssue>
            {isMembersGreaterThanOne ? "These members have" : "This member has"}{" "}
            reached their working hours limit.
            <StyledEmph>
              By overriding, working hours will exceed the limit you set for
              them this week:
            </StyledEmph>
          </StyledIssue>
        )}

        {!isOverLimitError && (
          <StyledButtonWrapper>
            <StyledConfirmWrapper>
              <RotaInput
                label={`Confirm you understand this warning by typing "Yes" below:`}
                isLabelTop
                placeholder="Yes"
                onChange={({ target }) => setConfirmationInput(target.value)}
                isSuccess={isConfirmed}
              />
            </StyledConfirmWrapper>

            <RotaButton
              onClick={handleUpdate}
              className="option"
              disabled={!isConfirmed}
            >
              Confirm Shift Update
            </RotaButton>

            <RotaButton
              onClick={handleClose}
              className="option"
              variant="outlined"
            >
              Cancel
            </RotaButton>
          </StyledButtonWrapper>
        )}
      </div>
    </StyledModal>
  );
};

export default ShiftUpdateMemberConflict;

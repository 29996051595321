import styled from "styled-components";

export const MemberListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const MemberPhotoWrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 1rem;
  padding: 0.1rem;
`;

export const InfoWrapper = styled.div`
  margin: 0.8rem;
  align-items: center;
  flex-direction: column;
`;

export const PhotoInfoWrapper = styled.div`
  border-bottom: 1px solid #c0ccda;
  font-size: 12px;
  display: flex;
  flex-directon: row;
  width: 100%;
`;

import React from "react";
import { RotaButtonNew } from "@teamrota/rota-design";

import { Role } from "@teamrota/authlib";

import useAuth from "~/src/auth/hooks/use-auth";
import { PublishMessageWrapper, StyledIcon, Label } from "./styles";
import publishShifts from "./graphql";

const PublishContainer = ({ countOfDraftShifts, publishShifts }) => {
  const auth = useAuth();

  if (auth.hasRole(Role.SHIFTS_PUBLISH)) {
    return (
      <RotaButtonNew
        onClick={publishShifts}
        disabled={countOfDraftShifts > 0 ? false : true}
      >
        Publish {countOfDraftShifts} shifts
      </RotaButtonNew>
    );
  }

  return (
    <PublishMessageWrapper>
      <StyledIcon countOfDraftShifts={countOfDraftShifts} />
      <Label>
        {countOfDraftShifts || "No"} unpublished
        {countOfDraftShifts !== 1 ? " shifts" : " shift"}
      </Label>
    </PublishMessageWrapper>
  );
};

export default publishShifts(PublishContainer);

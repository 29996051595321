import React from "react";
import { connect } from "react-redux";
import FormStepList from "./FormStepList";
import { Intro, Header, Title, Wrapper, Container } from "./styles";
import useGetLastUsedUniformAndBriefing from "~/src/containers/requester-calendar/graphql/get-last-used-uniform-and-briefing";

import { mapDispatchToProps, mapStateToProps } from "./selectors";

import Modal from "~/src/components/modal";

// Form Segemnts
import FormAudience from "./FormAudience";
import FormCalendar from "./FormCalendar";
import FormMembers from "./FormMembers";
import FormRequirements from "./FormRequirements";
import Summary from "./Summary";
import CompletedShift from "./CompletedShift";

const ShiftCreateModal = props => {
  if (!props.isOpen) return false;

  const {
    lastUsedUniform,
    lastUsedBriefing,
    isLoadingLastUsedUniformAndBriefing
  } = useGetLastUsedUniformAndBriefing({
    venueId: props.shift.venueId,
    roleRateId: props.shift.roleRateId
  });

  return (
    <Modal onClose={props.onClose} isOpen={props.isOpen}>
      <Wrapper>
        <Container>
          <CompletedShift
            roleRates={props.roleRates}
            targetAccountId={props.targetAccountId}
          />
          <Intro>
            <Header>
              <Title>Create a Shift</Title>
            </Header>
          </Intro>

          <FormStepList
            passedProps={{
              handleShiftUpdate: props.handleShiftUpdate,
              lastUsedUniform,
              lastUsedBriefing,
              isLoadingLastUsedUniformAndBriefing,
              ...props
            }}
            openStateIndex={props.shiftOpenIndex}
            segments={[
              FormAudience,
              FormCalendar,
              FormRequirements,
              FormMembers,
              Summary
            ]}
          />
        </Container>
      </Wrapper>
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ShiftCreateModal);

import { useMutation, gql } from "@apollo/client";

const CREATE_UPDATE_RELATIONSHIP = gql`
  mutation Mutation($poolType: poolTypeEnumType!, $memberId: ID!) {
    createOrUpdateRelationship(poolType: $poolType, memberId: $memberId) {
      poolType
      memberId
    }
  }
`;

export const useCreateOrUpdateRelationship = () => {
  return useMutation(CREATE_UPDATE_RELATIONSHIP);
};

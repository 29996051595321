import { gql, useQuery } from "@apollo/client";
import adminClient from "~/src/containers/admin-dashboard/graphql/admin-client";

const GET_ACCOUNT_ROLES = gql`
  query getRolesOnAccount($accountId: ID!) {
    accountRoles(accountId: $accountId) {
      id
      name
    }
  }
`;

export const useAccountRoles = ({ accountId }) => {
  const { data, loading, refetch } = useQuery(GET_ACCOUNT_ROLES, {
    variables: {
      accountId
    },
    client: adminClient,
    skip: !accountId
  });

  const accountRoles = data?.accountRoles || [];

  return {
    accountRoles,
    loading,
    refetch
  };
};

import moment from "moment-timezone";

export function makeData(results: any): object[] {
  try {
    return [
      [
        "Block",
        "Day",
        "Length",
        "Attributes",
        "Final Rule",
        "Pay Rates",
        "Pay/Hour",
        "Pay",
        "Charge Rates",
        "Charge/Hour",
        "Charge"
      ],
      ...results?.blocks.map((block: any) => [
        block?.begins,
        moment(block?.begins).format("ddd"),
        block?.length,
        block?.attributes?.join?.(" "),
        block?.finalRule,
        block?.rates?.PAY?.join?.(" "),
        block?.rateAmounts.PAY,
        block?.totals?.PAY,
        block?.rates?.CHARGE?.join?.(" "),
        block?.rateAmounts.CHARGE,
        block?.totals?.CHARGE
      ]),
      [
        "EXTRAS",
        "",
        "",
        results?.extras?.flags?.join?.(" "),
        results?.extras?.finalRule,
        results?.extras?.rates?.PAY?.join?.(" "),
        "",
        results?.extras?.totals?.PAY,
        results?.extras?.rates?.CHARGE?.join?.(" "),
        "",
        results?.extras?.totals?.CHARGE
      ],
      [
        "TOTAL",
        "",
        results?.facts?.shiftDuration,
        "",
        "",
        "",
        "",
        results?.totals?.PAY,
        "",
        "",
        results?.totals?.CHARGE
      ]
    ];
  } catch (e) {
    console.error(e);
    return [];
  }
}

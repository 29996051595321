import styled from "styled-components";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";

export const Wrapper = styled.div`
  display: inline-block;
  padding: 0px 10px;
  text-align: center;
  font-size: ${STYLE_CONSTS.FONT_SMALL};
`;

export const StarsWrapper = styled.div`
  margin-top: ${STYLE_CONSTS.UNIT_1};
`;

export const LightText = styled.div`
  font-size: ${STYLE_CONSTS.FONT_XSMALL};
  color: ${COLORS.COOL_GREY};
`;

export const ShouldShowContainer = styled.div`
  display: ${({ shouldNotShow }) => (shouldNotShow ? "none" : "inline-block")};
`;

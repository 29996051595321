import React from "react";
import TimeDisplay from "~/src/components/shift-time-display";
import { Content } from "./scheduled-hours.styles";
import ScheduledHoursTimeField from "../scheduled-hours-time-field";
import getContent from "./get-content";
import TimesheetToolTip from "~/src/containers/timesheets/tooltip";

const ScheduledHours = ({
  booking,
  bookingState,
  startTimeUpdate,
  endTimeUpdate,
  isNoShow,
  isTurnedAway,
  isEditMode,
  onUpdateStartTime,
  onUpdateEndTime
}) => {
  const startTimeTrue = startTimeUpdate || booking?.startFinal;
  const endTimeTrue = endTimeUpdate || booking?.endFinal;
  const canShow = !isNoShow && !isTurnedAway;

  return (
    <div style={{ display: "flex" }}>
      <Content>
        {isEditMode && canShow ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <ScheduledHoursTimeField
              start={startTimeTrue}
              end={endTimeTrue}
              onStartChange={onUpdateStartTime}
              onEndChange={onUpdateEndTime}
            />
            <TimesheetToolTip
              style={{ margin: "1px 0 0 5px" }}
              id="schedule-hours"
              text={
                getContent(
                  booking,
                  bookingState,
                  startTimeUpdate,
                  endTimeUpdate,
                  isNoShow,
                  isTurnedAway
                ).actionBy
              }
            />
          </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <TimeDisplay
              displayDate={
                getContent(
                  booking,
                  bookingState,
                  startTimeUpdate,
                  endTimeUpdate,
                  isNoShow,
                  isTurnedAway
                ).times
              }
              startTime={startTimeTrue}
              endTime={endTimeTrue}
            />
            <TimesheetToolTip
              style={{ margin: "1px 0 0 5px" }}
              id="schedule-hours"
              text={
                getContent(
                  booking,
                  bookingState,
                  startTimeUpdate,
                  endTimeUpdate,
                  isNoShow,
                  isTurnedAway
                ).actionBy
              }
            />
          </div>
        )}
      </Content>
    </div>
  );
};

export default ScheduledHours;

import styled from "styled-components";
import { RotaButtonNew } from "@teamrota/rota-design";
import { Grid } from "@material-ui/core";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const StyledGrid = styled(Grid)`
  margin-top: ${({ isMarginTop }) => isMarginTop && "15px"};
  border: ${({ isBorder }) => isBorder && "1px solid transparent"};
`;

export const Label = styled.label`
  font-size: ${STYLE_CONSTS.FONT};
  font-style: normal;
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_MEDIUM};
  line-height: 17px;
  letter-spacing: -0.18px;
`;

export const RedBox = styled.div`
  height: 8px;
  width: 8px;
`;

export const Title = styled.h2`
  font-size: ${STYLE_CONSTS.FONT_MEDIUM};
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
  color: ${({ theme }) => theme.black};
`;

export const SubTitle = styled.p`
  font-size: ${STYLE_CONSTS.FONT};
  font-style: normal;
  font-weight: normal;
  line-height: 17px;
  color: ${({ theme }) => theme.neutral.main};
`;

export const ToolTip = styled.p`
  color: ${({ theme }) => theme.primary.main};
  & strong {
    font-weight: bold;
  }
`;

export const FileNote = styled.p`
  font-size: 11px;
  line-height: 13px;
`;

export const StyledValidationError = styled.p`
  color: ${({ theme }) => theme.error.main};
  font-size: 12px;
`;

export const StyledButton = styled(RotaButtonNew)`
  margin: 10px 0;
`;

import { gql } from "@apollo/client";

export const GET_UNAVAILABILITY_UPLOAD = gql`
  query getUnavailabilityUpload($id: ID!) {
    account {
      id
      unavailabilityUpload(id: $id) {
        id
        format
        fileName
        status
        validationErrors
      }
    }
  }
`;

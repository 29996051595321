import { gql, useMutation } from "@apollo/client";
import adminClient from "~/src/containers/admin-dashboard/graphql/admin-client";

const CREATE_REBOOK_ROLE = gql`
  mutation createRebookRole(
    $accountId: ID!
    $roleId: ID
    $roleIdMappedToAlternateRoleId: ID
  ) {
    createRebookRole(
      accountId: $accountId
      roleId: $roleId
      roleIdMappedToAlternateRoleId: $roleIdMappedToAlternateRoleId
    ) {
      id
      roleId
      roleIdMappedToAlternateRoleId
    }
  }
`;

export const useCreateRebookRole = ({ onCompleted, onError, accountId }) => {
  const [create, { loading, error }] = useMutation(CREATE_REBOOK_ROLE, {
    client: adminClient,
    onCompleted,
    onError
  });

  const createRebookRole = async ({
    roleId,
    roleIdMappedToAlternateRoleId
  }) => {
    await create({
      variables: {
        accountId,
        roleId,
        roleIdMappedToAlternateRoleId
      }
    });
  };

  return { createRebookRole, loading, error };
};

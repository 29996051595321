import styled from "styled-components";

import { STYLE_CONSTS } from "~/src/styles/config.style";

export const Wrapper = styled.div`
  box-shadow: ${STYLE_CONSTS.FLOATING_LARGE};
  background-color: ${({ theme }) => theme.white};
  height: 100%;
  position: relative;
  line-height: ${STYLE_CONSTS.MENU_SIZE};
  border-radius: ${STYLE_CONSTS.BORDER_RADIUS_S};
`;

export const NavItem = styled.span`
  transition: ${STYLE_CONSTS.STANDARD_TRANSITON};
  font-size: ${STYLE_CONSTS.FONT};
  padding: 0 ${STYLE_CONSTS.UNIT_4};
  display: inline-block;
  cursor: pointer;
  color: ${({ isActive, theme }) =>
    isActive ? theme.primary.main : theme.text.primary};
  &:hover {
    color: ${({ theme }) => theme.primary.main};
  }
`;

export const NotifCountWrapper = styled.span`
  margin: 0 ${STYLE_CONSTS.UNIT_2};
  transform: translateY(-2px);
`;

export const Divider = styled.div`
  border: none;
  border-bottom: ${STYLE_CONSTS.SMALL_DIVIDER_BORDER};
  margin: ${STYLE_CONSTS.UNIT_1} 0;
  width: 80px;
`;

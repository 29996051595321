import styled from "styled-components";

import { STYLE_CONSTS } from "~/src/styles/config.style";
import ExitButton from "~/src/components/button/exit-button";

export const StyledModal = styled.div`
  transition: all 0.15s ease-in;

  background-color: #ffffff;
  cursor: default;
  height: fit-content;
  width: 420px;
  z-index: 200;
  box-shadow: 0 2px 20px 100vmax rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  padding-bottom: 15px;

  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;

  overflow-x: hidden;
  overflow-y: auto;

  & svg {
    margin: 4px 0;
  }
`;

export const StyledExitButton = styled(ExitButton)`
  position: absolute;
  right: 0;
  margin: 4px;
`;

export const StyledPhotoWrapper = styled.div`
  margin: 10px;
`;

export const StyledIssue = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 10px;
  font-size: ${STYLE_CONSTS.FONT_MEDIUM};
  text-align: center;
  border-bottom: solid 1px grey;
`;

export const StyledEmph = styled.div`
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_BOLD};
  padding: 5px 0 0 0;
`;

export const StyledHighlight = styled.div`
  font-size: ${STYLE_CONSTS.FONT_LARGE};
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_BOLD};
  text-align: center;
  margin-top: 10px;
`;

export const StyledTitle = styled.div`
  font-size: ${STYLE_CONSTS.FONT_LARGE};
  width: 80%;
  padding: 25px 0 5px 0;
  margin: 0 auto;
  text-align: center;
  font-weight: bolder;
  border-bottom: solid 1px grey;
`;

export const StyledMemberName = styled.div`
  text-align: center;
  font-size: ${STYLE_CONSTS.FONT_MEDIUM};
  font-weight: bolder;
`;

export const StyledConfirmWrapper = styled.div`
  width: 80%;
  margin: 0 auto 10px auto;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  margin: 10px 20px;

  & .option {
    height: 50px;
    width: 300px;
  }
`;

export const StyledMemberWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledMembersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 600px);
  overflow-y: scroll;
  margin: 0px 25px;
`;

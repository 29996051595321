import get from "lodash/fp/get";
import { BOOKING_STATES, BOOKING_STATES_LABELS } from "~/src/consts";
import { formatDateMonthTime } from "~/src/utils/formatting";

const readOnlyValues = [
  "shiftId",
  "bookingId",
  "requester",
  "provider",
  "venue",
  "role",
  "startOnApply",
  "startOnShift",
  "startOnShiftManager",
  "startOnApproval",
  "endOnApply",
  "endOnShift",
  "endOnApproval",
  "endOnShiftManager",
  "breakMinutes",
  "breakOnShift",
  "breakOnShiftManager",
  "breakOnApproval"
];

export const getValuesFromBooking = booking => ({
  state: get("state", booking),
  startOnPoolManagerOverride: get(
    "bookingState.startOnPoolManagerOverride",
    booking
  ),
  endOnPoolManagerOverride: get(
    "bookingState.endOnPoolManagerOverride",
    booking
  ),
  breakOnPoolManagerOverride: get(
    "bookingState.breakOnPoolManagerOverride",
    booking
  ),
  ratingOfMember: get("ratingOfMember", booking),
  ratingOfManager: get("ratingOfManager", booking),

  // readonly values
  shiftId: get("shift.id", booking),
  bookingId: get("id", booking),
  shiftEndTime: formatDateMonthTime(get("shift.endTime", booking)),
  shiftStartTime: formatDateMonthTime(get("shift.startTime", booking)),
  requester: get("shift.sourceAccount.accountName", booking),
  provider: get("shift.targetAccount.accountName", booking),
  role: get("shift.role.name", booking),
  startOnApply: formatDateMonthTime(get("bookingState.startOnApply", booking)),
  startOnShift: formatDateMonthTime(get("bookingState.startOnShift", booking)),
  startOnApproval: formatDateMonthTime(
    get("bookingState.startOnApproval", booking)
  ),
  startOnShiftManager: formatDateMonthTime(
    get("bookingState.startOnShiftManager", booking)
  ),
  endOnApply: formatDateMonthTime(get("bookingState.endOnApply", booking)),
  endOnShift: formatDateMonthTime(get("bookingState.endOnShift", booking)),
  endOnApproval: formatDateMonthTime(
    get("bookingState.endOnApproval", booking)
  ),
  endOnShiftManager: formatDateMonthTime(
    get("bookingState.endOnShiftManager", booking)
  ),
  venue: get("shift.venue.name", booking),
  isUnderReview: get("isUnderReview", booking),
  breakMinutes: get("bookingState.breakMinutes", booking) || 0,
  breakOnShift: get("bookingState.breakOnShift", booking) || 0,
  breakOnShiftManager: get("bookingState.breakOnShiftManager", booking) || 0,
  breakOnApproval: get("bookingState.breakOnApproval", booking) || 0,
  feedbackForMember: get("feedbackForMember", booking) || "",
  feedbackForManager: get("feedbackForManager", booking) || "",
  upcomingAccepted: get("upcomingAccepted", booking.member.bookingCounts || 0)
});

export const getValuesToSave = values =>
  Object.keys(values).reduce((acc, current) => {
    if (readOnlyValues.includes(current)) {
      return acc;
    }

    let value = values[current];
    if (current === "breakMinutes") {
      if (!value) {
        value = 0;
      } else {
        value = Number(values.breakMinutes);
      }
    }
    return {
      ...acc,
      [current]: value
    };
  }, {});

export default booking => [
  {
    name: "shiftId",
    readOnly: true,
    isSingleLine: true,
    label: "Shift ID"
  },
  {
    name: "bookingId",
    readOnly: true,
    isSingleLine: true,
    label: "Booking ID",
    value: booking.id
  },
  {
    name: "requester",
    readOnly: true,
    isSingleLine: true,
    label: "Requester"
  },
  {
    name: "provider",
    readOnly: true,
    isSingleLine: true,
    label: "Provider"
  },
  {
    name: "venue",
    readOnly: true,
    isSingleLine: true,
    label: "Venue"
  },
  {
    name: "role",
    readOnly: true,
    isSingleLine: true,
    label: "Role"
  },
  {
    name: "state",
    isSingleLine: true,
    label: "Booking state",
    component: "dropdown",
    options: Object.keys(BOOKING_STATES).map(bs => ({
      value: bs,
      label: BOOKING_STATES_LABELS[bs]
    }))
  },
  {
    name: "isUnderReview",
    isSingleLine: true,
    label: "Under review",
    component: "checkbox-input"
  },
  {
    title: "Start time",
    fields: [
      {
        name: "shiftStartTime",
        label: "Requested",
        readOnly: true,
        isSingleLine: true
      },
      {
        name: "startOnShiftManager",
        label: "Manager check in",
        readOnly: true,
        isSingleLine: true
      },
      {
        name: "startOnShift",
        label: "Check in",
        readOnly: true,
        isSingleLine: true
      },
      {
        name: "startOnApproval",
        label: "Approved",
        readOnly: true,
        isSingleLine: true
      },
      {
        name: "startOnPoolManagerOverride",
        label: "Override",
        shouldShowTime: true,
        component: "date-picker",
        start: get("startTime", booking.shift),
        placeholder: "Add an override date",
        isSingleLine: true,
        kilo: "largeNTings"
      }
    ]
  },
  {
    title: "End time",
    fields: [
      {
        name: "shiftEndTime",
        label: "Requested",
        readOnly: true,
        isSingleLine: true
      },
      {
        name: "endOnShiftManager",
        label: "Manager check out",
        readOnly: true,
        isSingleLine: true
      },
      {
        name: "endOnShift",
        label: "Check out",
        readOnly: true,
        isSingleLine: true
      },
      {
        name: "endOnApproval",
        label: "Approved",
        readOnly: true,
        isSingleLine: true
      },
      {
        name: "endOnPoolManagerOverride",
        label: "Override",
        component: "date-picker",
        placeholder: "Add an override date",
        isSingleLine: true,
        shouldShowTime: true,
        start: get("endTime", booking.shift)
      }
    ]
  },
  {
    title: "Break (minutes)",
    fields: [
      {
        name: "breakMinutes",
        label: "Requested",
        readOnly: true,
        isSingleLine: true
      },
      {
        name: "breakOnShift",
        label: "On shift (member)",
        readOnly: true,
        isSingleLine: true
      },
      {
        name: "breakOnShiftManager",
        label: "On shift (partner)",
        readOnly: true,
        isSingleLine: true
      },
      {
        name: "breakOnApproval",
        label: "Approved",
        readOnly: true,
        isSingleLine: true
      },
      {
        name: "breakOnPoolManagerOverride",
        label: "Override",
        placeholder: "Add a break",
        component: "number-input",
        noLeadingZeroes: true,
        isSingleLine: true
      }
    ]
  },
  {
    title: "Ratings",
    fields: [
      {
        name: "ratingOfMember",
        label: "Rating of member",
        shouldOverrideClick: true,
        isMember: true,
        isSingleLine: true,
        component: "stars-input",
        id: "star-container",
        previousRating: get("ratingOfMember", booking),
        upcomingAccepted: get(
          "upcomingAccepted",
          booking.member.bookingsCount || 0
        )
      },
      {
        name: "feedbackForMember",
        label: "Feedback from Manager",
        readOnly: true,
        isSingleLine: true,
        component: "multiline-text-input"
      },
      {
        name: "ratingOfManager",
        label: "Rating of Manager",
        isSingleLine: true,
        component: "stars-input",
        id: "star-container-2",
        upcomingAccepted: get(
          "upcomingAccepted",
          booking.member.bookingsCount || 0
        )
      },
      {
        name: "feedbackForManager",
        label: "Feedback from member",
        readOnly: true,
        isSingleLine: true,
        component: "multiline-text-input"
      }
    ]
  }
];

import { COLORS, STYLE_CONSTS } from "~/src/styles/config.style";

const styles = {
  avatarRow: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
    margin: "0 0 43px 0"
  },
  button: {
    textTransform: "capitalize",
    backgroundColor: `${COLORS.MAIN_ORANGE}`,
    width: "142px",
    height: "36px",
    color: `${COLORS.WHITE}`
  },
  dropdownRow: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center"
  },
  gridHeader: {
    margin: "12px 0 44px 0",
    backgroundColor: "#F4F4F4",
    borderRadius: "10px",
    padding: "12px 0 12px 0",
    color: `${COLORS.COOL_GREY}`
  },
  introContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    margin: "32px 0 0 0"
  },
  introText: { display: "flex", width: "443px", flexDirection: "column" },
  introTitle: {
    fontWeight: `${STYLE_CONSTS.FONT_WEIGHT_MEDIUM}`,
    fontSize: `${STYLE_CONSTS.FONT_LARGE}`
  },
  userName: {
    fontWeight: `${STYLE_CONSTS.FONT_WEIGHT_MEDIUM}`,
    marginLeft: "50px"
  }
};

export default styles;

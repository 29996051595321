import React from "react";
import Modal from "~/src/components/modal";
import SearchMemberList from "../member-search-list";
import {
  StyledContent,
  Title,
  Divider,
  StyledSearchInput
} from "./booking-search-modal.styles";

// eslint-disable-next-line react/prefer-stateless-function
export default class BookingSearchModal extends React.Component {
  state = {
    searchText: ""
  };

  props;

  handleChange = e => {
    this.setState({ searchText: e });
  };

  handleOnClose = () => {
    this.props.onClose();
    this.setState({ searchText: "" });
  };

  render() {
    const { state } = this;
    return (
      <Modal isOpen={this.props.isOpen} onClose={this.handleOnClose}>
        <StyledContent>
          <Divider>
            <Title>Search bookings</Title>
          </Divider>
          <StyledSearchInput
            isInline
            isSmall
            onChange={this.handleChange}
            value={state.searchText}
            placeholder="Search member by name"
          />
          <SearchMemberList
            searchText={state.searchText}
            shiftId={this.props.shiftId}
          />
        </StyledContent>
      </Modal>
    );
  }
}

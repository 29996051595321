import React from "react";
import { RotaModal } from "@teamrota/rota-design";

import DynamicForm from "~/src/components/dynamic-form";
import { addOwnerOptions } from "~/src/containers/modals/utils.js";

import schema from "./schema";
import validate from "./validate";
import useAuth from "~/src/auth/hooks/use-auth";
import { usePartnerOwners } from "../../partners/graphql/use-partner-owners";

const FORM_ID = "add-partner-account";

const CreateAccount = ({ onClose, createAccount, loading }) => {
  const auth = useAuth();
  const { partnerOwners } = usePartnerOwners();

  const updatedSchema = addOwnerOptions(partnerOwners, schema);
  const initialValues = {
    ownerId: auth.info.id
  };

  return (
    <RotaModal
      isSmall
      onClose={onClose}
      isLoading={loading}
      name="New Partner Account"
      styles={{ width: "500px" }}
    >
      <DynamicForm
        isCreateNewPartner
        isEditOnly
        form={FORM_ID}
        onSubmit={createAccount}
        schema={updatedSchema}
        validate={validate}
        onCancel={onClose}
        initialValues={initialValues}
      />
    </RotaModal>
  );
};

export default CreateAccount;

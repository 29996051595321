import React, { useEffect, useState } from "react";
import { FormContainer } from "../styles";
import { RotaCheckbox, RotaInput, RotaTextarea } from "@teamrota/rota-design";
import { Controller } from "react-hook-form";
import RotaDropdownV2 from "~/src/components/dropdown";
import {
  TIMESHEET_MEMBER_STATUS,
  TIMESHEET_STATUS,
  ZonedDate
} from "@teamrota/rota-common";
import moment from "moment";
import { MAX_SHIFT_DURATION_HOURS } from "~/src/consts";

const TimesheetDetailsComponent = ({
  errors,
  register,
  initialValues,
  isInEditMode,
  venues,
  control,
  clearErrors,
  timesheetMemberRow,
  timesheetStatus,
  watch,
  setValue,
  trigger
}) => {
  const [selectedSubvenueId, setSelectedSubvenueId] = useState(null);
  const [isApproveMember, setIsApproveMember] = useState(false);

  useEffect(() => {
    if (venues?.length > 0 && initialValues?.venueId) {
      setSelectedSubvenueId(initialValues.subvenueId);
    }
  }, [venues, initialValues?.subvenueId]);

  const isCanApprove =
    ![TIMESHEET_STATUS.LOCKED, TIMESHEET_STATUS.APPROVED].includes(
      timesheetStatus
    ) && timesheetMemberRow.status !== TIMESHEET_MEMBER_STATUS.APPROVED;

  const subvenues =
    venues?.find(venue => venue.id === initialValues?.venueId)?.subvenues || [];

  const getDropDownOptions = optionArray => {
    if (!optionArray?.length) return [];

    const options = optionArray.map(item => ({
      value: item.id,
      label: item.name ?? item.identifier
    }));

    return options.sort((a, b) => a.label.localeCompare(b.label));
  };

  const onChangeStartDate = (event, onChange) => {
    const newStartDate = moment(event.target.value);
    const oldStartDate = moment(watch("startDate") || initialValues?.startDate);
    const daysDifference = newStartDate.diff(oldStartDate, "days");

    const currentEndDate = watch("endDate");

    if (currentEndDate) {
      const proposedEndDate = moment(currentEndDate)
        .add(daysDifference, "days")
        .format("YYYY-MM-DD");

      // Check if the proposed dates would exceed 36 hours
      const startDateTime = new ZonedDate(
        `${event.target.value} ${watch("startTime")}`
      );
      const endDateTime = new ZonedDate(
        `${proposedEndDate} ${watch("endTime")}`
      );

      const minutesDifference = moment(endDateTime).diff(
        moment(startDateTime),
        "minutes"
      );

      if (minutesDifference <= MAX_SHIFT_DURATION_HOURS * 60) {
        // Only update end date if it doesn't exceed max duration
        setValue("endDate", proposedEndDate, { shouldValidate: false });
      }
      onChange(event);

      clearErrors(["startTime", "endTime", "endDate", "startDate"]);
      trigger(["startDate", "endDate"]);
    } else {
      onChange(event);
      clearErrors(["startTime", "endTime", "endDate"]);
    }
  };

  return (
    <FormContainer>
      <Controller
        control={control}
        name="startDate"
        render={({ field: { onChange, value } }) => (
          <RotaInput
            placeholder={initialValues?.startDate}
            disabled={!isInEditMode}
            label={"Start Date"}
            errorMessage={errors.startDate?.message}
            isError={errors.startDate?.message}
            type={"date"}
            value={value}
            onChange={e => onChangeStartDate(e, onChange)}
          />
        )}
      />
      <Controller
        control={control}
        name="startTime"
        render={({ field: { onChange, value } }) => (
          <RotaInput
            placeholder={initialValues?.startTime}
            disabled={!isInEditMode}
            label={"Start Time"}
            errorMessage={errors.startTime?.message}
            isError={errors.startTime?.message}
            value={value}
            onChange={e => {
              clearErrors(["endDate", "endTime", "startDate"]);
              onChange(e);
            }}
          />
        )}
      />
      <Controller
        control={control}
        name="endDate"
        render={({ field: { onChange, value } }) => {
          return (
            <RotaInput
              placeholder={initialValues?.endDate}
              disabled={!isInEditMode}
              label={"End Date"}
              errorMessage={errors.endDate?.message}
              isError={errors.endDate?.message}
              type={"date"}
              value={value}
              onChange={e => {
                clearErrors(["startTime", "endTime", "startDate"]);
                onChange(e);
              }}
            />
          );
        }}
      />
      <Controller
        control={control}
        name="endTime"
        render={({ field: { onChange, value } }) => (
          <RotaInput
            placeholder={initialValues?.endTime}
            disabled={!isInEditMode}
            label={"End Time"}
            errorMessage={errors.endTime?.message}
            isError={errors.endTime?.message}
            value={value}
            onChange={e => {
              clearErrors(["startTime", "endDate", "startDate"]);
              onChange(e);
            }}
          />
        )}
      />
      <Controller
        control={control}
        name="breakMinutes"
        render={({ field: { onChange, value } }) => (
          <RotaInput
            {...register("breakMinutes")}
            disabled={!isInEditMode}
            label={"Break"}
            errorMessage={errors.breakMinutes?.message}
            isError={errors.breakMinutes?.message}
            value={value}
            onChange={onChange}
          />
        )}
      />
      <Controller
        control={control}
        name="purchaseOrderNumber"
        render={({ field: { onChange, value } }) => (
          <RotaInput
            {...register("purchaseOrderNumber")}
            disabled={!isInEditMode}
            label={"PO Number"}
            errorMessage={errors.purchaseOrderNumber?.message}
            isError={errors.purchaseOrderNumber?.message}
            value={value}
            onChange={onChange}
          />
        )}
      />
      <Controller
        control={control}
        name="subvenueId"
        render={({ field: { onChange } }) => (
          <RotaDropdownV2
            label={"SubVenue"}
            options={getDropDownOptions(subvenues)}
            errorMessage={errors.venueId?.message}
            isError={errors.venueId?.message}
            value={selectedSubvenueId}
            isDisabled={!isInEditMode || !venues?.length}
            onChange={onChange}
          />
        )}
      />
      {isCanApprove && (
        <Controller
          control={control}
          name="approveMember"
          render={({ field: { onChange } }) => (
            <RotaCheckbox
              label={"Approve"}
              errorMessage={errors.approveMember?.message}
              isError={errors.approveMember?.message}
              isChecked={isApproveMember}
              isDisabled={!isInEditMode}
              onClick={e => {
                setIsApproveMember(e.target.checked);
                onChange(e);
              }}
            />
          )}
        />
      )}
      {isApproveMember && isCanApprove && (
        <Controller
          control={control}
          name="feedbackForMember"
          render={({ field: { onChange } }) => (
            <RotaTextarea
              label={"Feedback"}
              isLabelTop={false}
              {...register("feedbackForMember")}
              disabled={!isInEditMode}
              errorMessage={errors.feedbackForMember?.message}
              isError={errors.feedbackForMember?.message}
              onChange={onChange}
            />
          )}
        />
      )}
    </FormContainer>
  );
};

export default TimesheetDetailsComponent;

import moment from "moment-timezone";

const toDateString = input =>
  moment(input)
    .toDate()
    .toString();

export const toDateRange = ({ startTime, endTime }) => ({
  startTime: toDateString(startTime),
  endTime: toDateString(endTime)
});

import moment from "moment-timezone";

// use sessionStorage to persist data between multiple instantiations
// of this component within the same session (e.g. route away & back)

export const getLastCalendarWeekDate = () => {
  const lastCalendarWeekDate = sessionStorage.getItem("lastCalendarWeekDate");
  return lastCalendarWeekDate ? moment(lastCalendarWeekDate) : null;
};

export const setLastCalendarWeekDate = date =>
  sessionStorage.setItem("lastCalendarWeekDate", moment(date).toISOString());

export const hasSeenTimesheet = () =>
  !!sessionStorage.getItem("hasSeenTimesheet");

export const markHasSeenTimesheet = () =>
  sessionStorage.setItem("hasSeenTimesheet", true);

import React, { useState } from "react";
import { MEMBER_STATES } from "~/src/consts";
import ReactTooltip from "react-tooltip";
import asyncConfirm from "~/src/utils/async-confirm";
import {
  Wrapper,
  State,
  Text,
  ModalTitle,
  ModalText,
  Action
} from "./member-state-input.styles";
import AlertDialog from "~/src/containers/settings-page/dialog";

const keywordMap = {
  [MEMBER_STATES.LOCKED]: "Lock",
  [MEMBER_STATES.GOOD_LEAVER]: "Remove",
  [MEMBER_STATES.REMOVED]: "Remove"
};

const states = [
  MEMBER_STATES.ACTIVE,
  MEMBER_STATES.PENDING,
  MEMBER_STATES.LOCKED,
  MEMBER_STATES.GOOD_LEAVER,
  MEMBER_STATES.REMOVED
];

export const tooltipText = {
  [MEMBER_STATES.ACTIVE]: "Active",
  [MEMBER_STATES.REMOVED]: "Removed",
  [MEMBER_STATES.LOCKED]: "Locked",
  [MEMBER_STATES.GOOD_LEAVER]: "Good Leaver",
  [MEMBER_STATES.PENDING]: "Pending"
};

const leavingStates = [
  MEMBER_STATES.LOCKED,
  MEMBER_STATES.REMOVED,
  MEMBER_STATES.GOOD_LEAVER
];

const ModalMessage = ({ newState, bookingsCount }) => {
  const actionText = newState === MEMBER_STATES.LOCKED ? "lock" : "remove";
  const action = <Action newState={newState}>{actionText}</Action>;
  return (
    <div>
      <ModalTitle>Do you want to {action} this member?</ModalTitle>
      <ModalText>
        This member is accepted to {bookingsCount || 0} upcoming shifts.
      </ModalText>
      <ModalText>This will cancel all accepted shifts.</ModalText>
    </div>
  );
};

export default function MemberStateInput({
  upcomingBookingsCount,
  inEditMode,
  setMemberState,
  memberState,
  isRightToWorkValid
}) {
  const [isModalOpen, setIsMessageOpen] = useState(false);
  const [newState, setNewState] = useState(false);
  const handleChange = async (newState, acceptedState) => {
    if (inEditMode && newState) {
      if (leavingStates.includes(newState)) {
        const message = (
          <ModalMessage
            bookingsCount={upcomingBookingsCount}
            newState={newState}
            isRightToWorkValid={isRightToWorkValid}
          />
        );
        if (
          await asyncConfirm(message, {
            confirmButtonText: keywordMap[newState]
          })
        ) {
          setMemberState(newState);
        }
      } else if (
        newState === MEMBER_STATES.ACTIVE &&
        !isRightToWorkValid &&
        !acceptedState
      ) {
        setIsMessageOpen(true);
      } else {
        setMemberState(newState);
      }
    }
  };

  return (
    <Wrapper>
      {states.map((state, index) => (
        <span key={index}>
          <ReactTooltip id={state} effect="solid">
            {tooltipText[state]}
          </ReactTooltip>
          <State
            inEditMode={inEditMode}
            state={state}
            data-tip
            data-for={state}
            isActive={memberState === state}
            onClick={() => {
              //I've purposefully been explicit here because these variables
              //could be undefined and then appear to be false
              setNewState(state),
                inEditMode === true ? handleChange(state, false) : null;
            }}
          />
        </span>
      ))}
      <Text state={memberState}>{tooltipText[memberState]}</Text>
      <AlertDialog
        openDialog={isModalOpen}
        onClickHandlerIgnore={() => setIsMessageOpen(false)}
        onClickHandler={() => {
          handleChange(newState, true);
          setIsMessageOpen(false);
        }}
        dialogTitle="This member does not have a valid Right to Work uploaded."
        acceptBtnText="Ok, I understand"
        ignoreBtnText="Cancel"
      >
        Reactivating this member will allow them to work, even if they don't
        have a valid Right To Work. Please upload a valid Right to Work document
        for this member before setting them to Active.
      </AlertDialog>
    </Wrapper>
  );
}

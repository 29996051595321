import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import { STYLE_CONSTS } from "~/src/styles/config.style";

const COLUMN_SIZE = 1.5 / 10;
const FIRST_COLUMN_SIZE = 2.5 / 10;

export const StyledToolTip = styled(ReactTooltip)`
  width: 300px;
  line-height: 20px;
  border-radius: 10px !important;
  box-shadow: 0px 4px 15px 0px #a6a6a626;
`;

export const StyledTextAlert = styled.p`
  color: ${({ theme }) => theme.primary.main};
  font-weight: 500;
  margin-top: 30px;
  line-height: 21px;
`;

export const StyledSelectedType = styled.span`
  color: ${({ theme }) => theme.primary.main};
`;

export const FloatingTable = styled.div`
  margin: 0 40px;
  width: calc(100% - 80px);
`;

export const ColumnHeading = styled.div`
  display: ${props => (props.widthSmall === 0 ? "none" : "inline-block")};
  width: ${props =>
    (props.widthSmall || props.width || FIRST_COLUMN_SIZE) * 100}%;

  height: 80px;
  line-height: 80px;
  font-size: ${props =>
    props.overideFontSize
      ? `${props.overideFontSize}px`
      : `${STYLE_CONSTS.FONT_LARGE}`};
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_MEDIUM};
  color: ${({ theme }) => theme.neutral.main};
  &:first-child {
    padding-left: ${STYLE_CONSTS.UNIT_2};
    width: ${props =>
      (props.widthSmall || props.width || FIRST_COLUMN_SIZE) * 100}%;
  }
  &:last-child {
    text-align: ${props => (props.noRight ? null : "right")};
    padding-right: ${STYLE_CONSTS.UNIT_3};
  }
  @media screen and (min-width: ${STYLE_CONSTS.BREAK_POINT_XLARGE}) {
    display: inline-block;
    width: ${props => (props.width || COLUMN_SIZE) * 100}%;
    &:first-child {
      width: ${props => (props.width || FIRST_COLUMN_SIZE) * 100}%;
    }
  }
`;

export const TableBody = styled.div`
  border-radius: ${STYLE_CONSTS.BORDER_RADIUS};
  box-shadow: ${STYLE_CONSTS.FLOATING_LARGE};
  background-color: ${({ theme }) => theme.white};
`;

export const TableData = styled.div`
  word-wrap: ${({ wordWrap }) => wordWrap};
  display: ${props => (props.widthSmall === 0 ? "none" : "inline-block")};
  vertical-align: ${({ verticalAlign }) => verticalAlign || "middle"};
  width: ${props => (props.widthSmall || props.width || COLUMN_SIZE) * 100}%;
  padding-top: ${STYLE_CONSTS.UNIT_4};
  padding-bottom: ${STYLE_CONSTS.UNIT_4};
  padding-right: ${STYLE_CONSTS.UNIT_4};
  &:first-child {
    padding-left: ${STYLE_CONSTS.UNIT_4};
    width: ${props =>
      (props.widthSmall || props.width || FIRST_COLUMN_SIZE) * 100}%;
  }
  &:last-child {
    text-align: right;
  }
  @media screen and (min-width: ${STYLE_CONSTS.BREAK_POINT_XLARGE}) {
    display: inline-block;
    width: ${props => (props.width || COLUMN_SIZE) * 100}%;
    &:first-child {
      width: ${props => (props.width || FIRST_COLUMN_SIZE) * 100}%;
    }
  }
`;

export const TableRow = styled.div`
  position: relative;
  padding-left: 8px;
  &:after {
    content: "";
    background-color: ${({ isExpanded, theme }) =>
      !isExpanded && theme.neutral.main};
    position: absolute;
    top: calc(100% - 1px);
    width: calc(100% - ${STYLE_CONSTS.UNIT_8});
    height: 1px;
    left: ${STYLE_CONSTS.UNIT_4};
  }
`;

export const StyledButtonWrap = styled.div`
  text-align: right;
  padding: 20px 0 0 0;
  margin-left: 20%;
  width: 60%;
`;
export const StyledRedBox = styled.div`
  height: 100px;
  width: 60%;
`;
export const StyledWrapContractor = styled.div`
  display: ${({ isContractor }) => isContractor && "flex"};
  flex-direction: ${({ isContractor }) => isContractor && "column"};
  & > * {
    margin: 0;
  }
  p {
    margin-top: 10px;
  }
`;
export const StyledWrapContractorCheckbox = styled.div`
  display: ${({ isContractor }) => isContractor && "flex"};
  flex-direction: ${({ isContractor }) => isContractor && "column"};
  justify-content: ${({ isContractor }) => isContractor && "center"};
  height: ${({ isContractor }) => isContractor && "80px"};
  align-items: ${({ isContractor }) => isContractor && "flex-start"};
`;

export const StyledWrapButton = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledWrapButtonHeader = styled.div`
  margin-right: 22px;
`;

export const StylesWrapSpan = styled.span`
  color: ${({ isApproved, theme }) =>
    isApproved ? theme.success.main : theme.primary.main};

  font-size: 16px;
  font-weight: 400;
`;

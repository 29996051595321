import React from "react";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import { STYLE_CONSTS } from "~/src/styles/config.style";
import Icon from "~/src/components/icon";

export const StyledToolTip = styled(props => (
  <ReactTooltip {...props} place="left" />
))`
  opacity: 1 !important;
  max-width: 250px;
  border-radius: ${STYLE_CONSTS.BORDER_RADIUS_S};
  box-shadow: ${STYLE_CONSTS.FLOATING_LARGE};

  z-index: 1500 !important;
`;

export const ToolTipIconWrapper = styled.div`
  display: inline-block;
`;

export const ToolTipIcon = styled(Icon)`
  position: relative;
  top: 3px;
`;

export const ToolTipTrigger = styled.div`
  position: relative;
  width: 24px;
  height 24px;
`;

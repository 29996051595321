import React from "react";
import SVGInline from "react-svg-inline";
import styled from "styled-components";

export const StyledSVG = styled(({ ...rest }) => <SVGInline {...rest} />)`
  display: inline-flex;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  align-items: center;
  justify-content: center;
  ${({ $isNotCentered }) => $isNotCentered && `margin: 1px 0 0 1px;`};
`;

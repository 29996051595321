import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const Container = styled.div`
  width: 600px;
  padding: ${STYLE_CONSTS.UNIT_2};
`;

export const Header = styled.h2`
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: ${({ shouldAddPadding }) =>
    shouldAddPadding && STYLE_CONSTS.UNIT_8};
  text-align: center;
`;

export const Text = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.text.secondary};
  margin-bottom: ${({ hasMarginBottom }) =>
    hasMarginBottom && STYLE_CONSTS.UNIT_4};
`;

export const ButtonContainer = styled.div`
  justify-content: center;
  display: grid;
  grid-template-columns: auto auto;
  gap: 24px;
`;

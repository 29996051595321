import React, { useState, useMemo, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_ACCOUNT_ID } from "../tags/graphql/tag-queries";
import { GET_UNAVAILABILITY_UPLOAD } from "../../graphql/queries/get-unavailability-upload/get-unavailability-upload";
import { IMPORT_OR_REJECT_MEMBER_UNAVAILABILITY } from "../../graphql/mutations/import-or-reject-member-unavailability/import-or-reject-member-unavailability";

import DropZoneFileUpload from "./DropZoneFileUpload";
import UploadedFileTable from "./UploadedFileTable";
import ErrorContainer from "./ErrorContainer";
import UploadStatus from "./UploadStatus";
import uploadFile from "~/src/services/upload-file";
import { DOCUMENT_TYPES } from "~/src/consts";
import SuccessModal from "./modals/SuccessModal";
import ErrorModal from "./modals/ErrorModal";
import { RotaButton, RotaSnackBar } from "@teamrota/rota-design";

import {
  Styled_PaddingContainer,
  Styled_ComponentContainer,
  Styled_BusyMembersFiles
} from "./styles";

export const UPLOAD_STATUSES = {
  DEFAULT: "DEFAULT",
  LOADING: "LOADING",
  VALIDATING: "VALIDATING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR"
};

const BusyMembersFiles = () => {
  const [isSizeOrFileTypeError, setIsSizeOrFileTypeError] = useState(false);
  const [validationErrors, setValidationErrors] = useState();

  const [file, setFile] = useState();
  const [uploadStatus, setUploadStatus] = useState(UPLOAD_STATUSES.DEFAULT);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [uploadedFileId, setUploadedFileId] = useState();
  const [isSuccessSnackBarOpen, setIsSuccessSnackBarOpen] = useState(false);

  const { data } = useQuery(GET_ACCOUNT_ID);

  const { data: validationData, stopPolling } = useQuery(
    GET_UNAVAILABILITY_UPLOAD,
    {
      variables: { id: uploadedFileId },
      skip: !uploadedFileId,
      pollInterval: 4000,
      notifyOnNetworkStatusChange: true
    }
  );

  const [
    importOrRejectMemberUnavailability,
    { data: mutationData }
  ] = useMutation(IMPORT_OR_REJECT_MEMBER_UNAVAILABILITY, {
    refetchQueries: ["unavailabilityUploads"]
  });
  const isImportOrRejectSuccessful =
    mutationData?.importOrRejectMemberUnavailability?.success;

  const validationStatus = useMemo(() => {
    return validationData?.account?.unavailabilityUpload?.status;
  }, [validationData]);

  const resetToDefault = () => {
    setFile(null);
    setUploadedFileId(null);
    setIsSizeOrFileTypeError(false);
    setUploadStatus(UPLOAD_STATUSES.DEFAULT);
  };

  useEffect(() => {
    if (validationData && validationStatus === "VALIDATING") {
      setUploadStatus(UPLOAD_STATUSES.VALIDATING);
    }
    if (validationData && validationStatus === "INVALID") {
      const _validationErrors =
        validationData?.account?.unavailabilityUpload?.validationErrors;
      setValidationErrors(_validationErrors);
      stopPolling();
      setUploadStatus(UPLOAD_STATUSES.ERROR);
      setIsErrorModalOpen(true);
    }
    if (validationData && validationStatus === "VALID") {
      stopPolling();
      setUploadStatus(UPLOAD_STATUSES.SUCCESS);
      setIsSuccessModalOpen(true);
    }
  }, [validationData]);

  useEffect(() => {
    if (isImportOrRejectSuccessful === true) {
      resetToDefault();
      setIsSuccessSnackBarOpen(true);
    }
  }, [isImportOrRejectSuccessful]);

  const accountId = data?.account?.id;

  const handleUpload = async () => {
    setUploadStatus(UPLOAD_STATUSES.LOADING);
    try {
      const result = await uploadFile({
        file,
        type: DOCUMENT_TYPES.UNAVAILABILITY_DOCUMENT,
        id: accountId,
        fileName: file.name
      });
      setUploadedFileId(result?.data?.id);
    } catch (error) {
      setUploadStatus(UPLOAD_STATUSES.ERROR);
      setIsErrorModalOpen(true);
    }
  };

  const handleImport = () => {
    importOrRejectMemberUnavailability({ variables: { id: uploadedFileId } });
    setIsSuccessModalOpen(false);
  };

  return (
    <Styled_PaddingContainer>
      <Styled_ComponentContainer>
        <Styled_BusyMembersFiles>
          <div className="header">Staffing Unavailability</div>
          <div className="heading">UPLOAD YOUR FILE</div>
          {file ? (
            <UploadStatus
              fileSizeBytes={file?.size}
              fileName={file?.name}
              status={uploadStatus}
            />
          ) : (
            <DropZoneFileUpload
              setIsSizeOrFileTypeError={setIsSizeOrFileTypeError}
              setFile={setFile}
            />
          )}

          <div className="file_requirements">
            Max 25MB of Excel file (.xlsx) can be uploaded
          </div>
          <div className="buttons_wrapper">
            <div>
              <RotaButton
                disabled={!file}
                variant="outlined"
                onClick={() => {
                  setFile(null);
                  setUploadedFileId(null);
                  setIsSizeOrFileTypeError(false);
                  setUploadStatus(UPLOAD_STATUSES.DEFAULT);
                }}
                className="btn_width cancel_btn"
              >
                Cancel
              </RotaButton>
              <RotaButton
                className="btn_width"
                disabled={!file || isSizeOrFileTypeError}
                onClick={handleUpload}
              >
                Upload
              </RotaButton>
            </div>
          </div>
          {isSizeOrFileTypeError && (
            <div className="error_container_wrapper">
              <ErrorContainer
                textContent={`The specific file ${file?.name} does not meet the requirements above. Please check that it is under 25MB and .xlsx file`}
              />
            </div>
          )}
          <UploadedFileTable />
        </Styled_BusyMembersFiles>
        {isSuccessModalOpen && (
          <SuccessModal
            handleClose={() => {
              resetToDefault();
              setIsSuccessModalOpen(false);
            }}
            handleImport={handleImport}
          />
        )}
        {isErrorModalOpen && (
          <ErrorModal
            handleClose={() => {
              resetToDefault();
              setIsErrorModalOpen(false);
            }}
            list={validationErrors}
          />
        )}
        <RotaSnackBar
          snackOpen={isSuccessSnackBarOpen}
          onClose={() => setIsSuccessSnackBarOpen(false)}
          message="Imported Successfully"
          severity="success"
        />
      </Styled_ComponentContainer>
    </Styled_PaddingContainer>
  );
};

export default BusyMembersFiles;

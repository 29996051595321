import styled from "styled-components";
import { colors, iconNew } from "@teamrota/rota-design";

const { ReturnBack } = iconNew;

export const StyledBackButton = styled(ReturnBack)`
  cursor: pointer;
  min-width: 24px;
  margin: 23px 5px 0;
`;
export const StyledCreateShiftContainer = styled.div`
  position: relative;
  max-width: 10vw;
  min-width: 10vw;
  height: 100%;
`;

export const StyledDailyView = styled.div`
  padding: 10px;
  background: white;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const StyledDayViewShiftsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 100%;
`;

export const StyledScrollContainer = styled.div`
  flex-direction: column;
  width: 100%;
  justify-content: space-around;
  align-items: flex-start;
  position: absolute;
  top: 5px;
  left: ${({ shiftsPageWidth, pageNumber }) =>
    `-${shiftsPageWidth * pageNumber}px`};

  transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
`;

export const StyledOpenShiftsRow = styled.div`
  display: flex;
  width: 100%;
  margin: 10px 0;
  min-height: 50px;
`;

export const StyledScrollControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  > * {
    cursor: pointer;
  }
`;

export const StyledDot = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 100%;
  margin: 0 10px;
  background-color: ${({ isActive }) =>
    isActive ? colors.rotaOrange : colors.brightGrey};
`;

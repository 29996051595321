import { ZonedDate } from "@teamrota/rota-common";

/**
 * Given a day/array of calendar events, returns arrays of overlapping events.
 */
export default function packClusters(calendarEvents) {
  let whileIndex = 0;
  let clusters = calendarEvents.map(event => ({
    id: Math.random(),
    events: [event],
    position: { ...event.position },
    dateContext: new ZonedDate(event.dateContext)
  }));

  do {
    // eslint-disable-next-line no-restricted-syntax
    for (const [index, cluster] of clusters.entries()) {
      const overlappingClusters = clusters.filter(
        (clust, i) => index !== i && clusterDoesOverlap(cluster, clust)
      );

      if (overlappingClusters.length) {
        clusters[index] = mergeClusters(cluster, ...overlappingClusters);
        const idsToRemove = overlappingClusters.map(({ id: uid }) => uid);
        clusters = clusters.filter(({ id }) => !idsToRemove.includes(id));
        break;
      }
    }

    whileIndex += 1;
  } while (clustersHaveOverlapping(clusters) && whileIndex < 100);

  // Remove the ID's
  return clusters.map(({ events, position, dateContext }) => ({
    events,
    position,
    dateContext
  }));
}

function mergeClusters(...clusters) {
  return reBoundCluster({
    id: Math.random(),
    position: {
      // These can always be inherited from original
      left: clusters[0].position.left,
      width: clusters[0].position.width
    },
    events: clusters.reduce((agg, next) => [...agg, ...next.events], []),
    dateContext: new ZonedDate(clusters[0].dateContext)
  });
}

export function clustersHaveOverlapping(clusters) {
  return clusters.some((cluster, i) =>
    clusters.some(
      (cluster2, j) => i !== j && clusterDoesOverlap(cluster, cluster2)
    )
  );
}

export function clusterDoesOverlap(cluster, cluster1) {
  return (
    cluster1.position.top <= cluster.position.bottom &&
    cluster.position.top <= cluster1.position.bottom
  );
}

export function reBoundCluster(cluster) {
  const newTop = Math.min(
    ...cluster.events.map(({ position }) => position.top)
  );
  const newBottom = Math.max(
    ...cluster.events.map(({ position }) => position.bottom)
  );
  return {
    ...cluster,
    position: { ...cluster.position, top: newTop, bottom: newBottom }
  };
}

import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const IconWrapper = styled.span`
  padding: ${({ isSmall }) => (isSmall ? "5px" : STYLE_CONSTS.UNIT_HALF)};
`;

export const StarsContainer = styled.span`
  opacity: ${({ isDisabled }) => (isDisabled ? ".6" : "1")};
  cursor: ${({ isEditable }) => (isEditable ? "pointer" : "default")};
  filter: ${({ isGrey }) => (isGrey ? "grayscale(100%)" : "none")};
`;

export const Rating = styled.div`
  color: ${({ theme }) => theme.neutral.main};
  font-size: ${STYLE_CONSTS.FONT_XSMALL};
  padding-left: ${STYLE_CONSTS.UNIT_HALF};
  height: 20px;
  line-height: 20px;
  vertical-align: text-bottom;
  display: inline-block;
`;

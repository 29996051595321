import styled from "styled-components";
import { COLORS, STYLE_CONSTS } from "~/src/styles/config.style";

export const Wrapper = styled.div`
  display: flex;
  margin: ${STYLE_CONSTS.UNIT_2} 0};
  justify-content: space-between
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  width: 42%;
  margin-left: 0;
`;

export const RedBox = styled.div`
  height: 8px;
  width: 8px;
`;

export const Label = styled.div`
  color: ${COLORS.COOL_GREY};
  margin-bottom: 16px;
`;

export const Error = styled.div`
  color: ${COLORS.RED};
  font-size: 10px;
  margin-left: 0;
`;

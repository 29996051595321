import { updateShiftForm } from "../reducer";

export const DATE_FORMAT = "DD/MM/YYYY";

// Map dispatch to props<handleShiftUpdate>
export const mapDispatchToProps = dispatch => ({
  handleShiftUpdate: update => {
    dispatch((_, getState) => {
      const {
        calendar: { shiftForm }
      } = getState();
      const shift = shiftForm.shifts[shiftForm.shiftOpenIndex] || {};
      const shifts = [...shiftForm.shifts];
      shifts[shiftForm.shiftOpenIndex] = {
        ...shift,
        ...update
      };

      dispatch(
        updateShiftForm({
          shifts
        })
      );
    });
  }
});

export const mapStateToProps = ({
  calendar: {
    shiftForm: { shifts, shiftOpenIndex }
  }
}) => ({
  shift: shifts[shiftOpenIndex],
  shiftOpenIndex
});

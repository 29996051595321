import React from "react";
import { Controller } from "react-hook-form";
import {
  RotaDivider,
  RotaSwitchWithLabel,
  RotaInput
} from "@teamrota/rota-design";

import RadioButtons from "./radio-buttons";
import {
  SectionWrapper,
  Title,
  RadioWrapper,
  PayTypeLabel,
  PayRateGrid,
  PayRateHeading,
  RateTitle,
  RateSubtitle,
  ApplyAfterWrapper
} from "./styles";
import { useCurrency } from "~/src/containers/profile-loader";

const PaymentInfo = ({
  register,
  control,
  errors,
  initialIsDayRate,
  isOvertimeRate,
  isOverstayRate,
  handleRateTypeChange,
  isRoleRatesModal
}) => {
  const { currencyCode, currencySymbol } = useCurrency();

  const validateInput = value =>
    value <= 10000 ||
    `Max. 10,000.00 ${currencyCode}
`;
  return (
    <SectionWrapper>
      <Title>Payment info</Title>
      <RadioWrapper>
        <PayTypeLabel>Pay by:</PayTypeLabel>
        <Controller
          name="isDayRate"
          control={control}
          defaultValue={initialIsDayRate}
          render={({ field, ...props }) => {
            return (
              <RadioButtons
                isRoleRatesModal={isRoleRatesModal}
                {...field}
                {...props}
              />
            );
          }}
        />
      </RadioWrapper>
      <RotaDivider style={{ margin: "24px 0 " }} />
      <PayRateGrid>
        <PayRateHeading>Type of rate</PayRateHeading>
        <PayRateHeading>Pay rate</PayRateHeading>
        <PayRateHeading>Charge rate</PayRateHeading>
        <PayRateHeading>Apply after</PayRateHeading>

        <div>
          <RateTitle>Default Rate</RateTitle>
          <RateSubtitle>This rate will be the automatic rate</RateSubtitle>
        </div>
        <RotaInput
          type="number"
          min={0}
          iconLeft={currencySymbol}
          step=".01"
          isError={errors?.defaultPayRate}
          errorMessage={errors?.defaultPayRate?.message}
          {...register("defaultPayRate", {
            valueAsNumber: true,
            validate: value => validateInput(value)
          })}
        />
        <RotaInput
          type="number"
          min={0}
          iconLeft={currencySymbol}
          step="any"
          isError={errors?.defaultChargeRate}
          errorMessage={errors?.defaultChargeRate?.message}
          {...register("defaultChargeRate", {
            valueAsNumber: true,
            validate: value => validateInput(value)
          })}
        />
        <div />
        <RotaSwitchWithLabel
          name="isOvertimeRate"
          checked={isOvertimeRate}
          onChange={handleRateTypeChange}
        >
          Overtime rate
        </RotaSwitchWithLabel>
        <RotaInput
          type="number"
          min={0}
          iconLeft={currencySymbol}
          step="any"
          isDisabled={!isOvertimeRate}
          isError={errors?.defaultOvertimePayRate}
          errorMessage={errors?.defaultOvertimePayRate?.message}
          {...register("defaultOvertimePayRate", {
            valueAsNumber: true,
            validate: value => validateInput(value)
          })}
        />
        <RotaInput
          type="number"
          min={0}
          iconLeft={currencySymbol}
          step="any"
          isDisabled={!isOvertimeRate}
          isError={errors?.defaultOvertimeChargeRate}
          errorMessage={errors?.defaultOvertimeChargeRate?.message}
          {...register("defaultOvertimeChargeRate", {
            valueAsNumber: true,
            validate: value => validateInput(value)
          })}
        />
        <ApplyAfterWrapper isDisabled={!isOvertimeRate}>
          <RotaInput
            type="number"
            min={0}
            isDisabled={!isOvertimeRate}
            {...register("defaultOvertimeHoursLimit", {
              valueAsNumber: true
            })}
          />
          hours/week
        </ApplyAfterWrapper>

        <RotaSwitchWithLabel
          name="isOverstayRate"
          checked={isOverstayRate}
          onChange={handleRateTypeChange}
        >
          Overstay rate
        </RotaSwitchWithLabel>
        <RotaInput
          type="number"
          min={0}
          iconLeft={currencySymbol}
          step="any"
          isDisabled={!isOverstayRate}
          isError={errors?.defaultOverstayPayRate}
          errorMessage={errors?.defaultOverstayPayRate?.message}
          {...register("defaultOverstayPayRate", {
            valueAsNumber: true,
            validate: value => validateInput(value)
          })}
        />
        <RotaInput
          type="number"
          min={0}
          iconLeft={currencySymbol}
          step="any"
          isDisabled={!isOverstayRate}
          isError={errors?.defaultOverstayChargeRate}
          errorMessage={errors?.defaultOverstayChargeRate?.message}
          {...register("defaultOverstayChargeRate", {
            valueAsNumber: true,
            validate: value => validateInput(value)
          })}
        />
        <ApplyAfterWrapper isDisabled={!isOverstayRate}>
          <RotaInput
            type="number"
            min={0}
            isDisabled={!isOverstayRate}
            {...register("defaultOverstayHoursLimit", {
              valueAsNumber: true
            })}
          />
          hours/shift
        </ApplyAfterWrapper>
      </PayRateGrid>
    </SectionWrapper>
  );
};

export default PaymentInfo;

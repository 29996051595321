import axios from "axios";
import { useState } from "react";

import { getCurrentAccessToken } from "~/src/auth/manager";
import { TIMESHEETS_DOWNLOAD } from "~/src/config";

interface DownloadParams {
  timesheetId: string;
  timesheetMemberRowIds: string;
  fields: string;
}
export const useDownloadTimesheetXlsx = () => {
  const [loading, setLoading] = useState(false);
  const downloadTimesheetXlsx = async ({
    timesheetId,
    timesheetMemberRowIds,
    fields
  }: DownloadParams) => {
    setLoading(true);
    const token = await getCurrentAccessToken();
    axios
      .get(
        `${TIMESHEETS_DOWNLOAD}?timesheetId=${timesheetId}&timesheetMemberRowIds=${timesheetMemberRowIds}&fields=${fields}`,
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(response => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `timesheet_${timesheetId}.xlsx`);
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .finally(() => setLoading(false));
  };

  return { downloadTimesheetXlsx, loading };
};

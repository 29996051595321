import styled from "styled-components";
import { RotaButtonIcon } from "@teamrota/rota-design";

export const StyledPopupBackground = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export const StyledPopupContainer = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
  max-width: 50%;
  padding: 40px 20px;
  font-size: 16px;
`;

export const StyledCloseButton = styled(RotaButtonIcon)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const StyledTitle = styled.div`
  font-weight: bold;
  margin: 20px;
`;

export const StyledContent = styled.div`
  margin-bottom: 40px;
`;

export const StyledButtonsContainer = styled.div`
  margin-top: 40px;

  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
`;

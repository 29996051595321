import axios from "axios";

import { POOL_TYPES } from "@teamrota/rota-common";

import { getCurrentAccessToken } from "~/src/auth/manager";
import { TIMESHEETS_ENDPOINT } from "~/src/config";
import { APPROVAL_STATES, SALARIED_MEMBER_TYPES } from "~/src/consts";
import { convertDateFromString } from "./util";

export const mergeBookingUpdates = ({ bookings, bookingUpdates }) => {
  const updateMap = Object.fromEntries(
    bookingUpdates.map(bookingUpdate => [`${bookingUpdate.id}`, bookingUpdate])
  );

  const bookingApprovals = bookings.map(({ booking, member }) => {
    const id = `${booking.id}`;

    const original = {
      id,
      startOnApproval: booking?.startFinal,
      endOnApproval: booking?.endFinal,
      breakOnApproval: booking?.breakFinal || 0,
      ratingOfMember: booking?.ratingOfMember,
      feedbackForMember: booking?.feedbackForMember,
      feedbackLabelsForMember: booking?.feedbackLabelsForMember,
      isFavourited: member?.poolType === POOL_TYPES.FAVOURITED,
      notes: booking?.notes,
      isNoShow: booking?.isNoShow,
      isTurnedAway: booking?.isTurnedAway
    };

    const approval = updateMap[id]
      ? { ...original, ...updateMap[id] }
      : original;

    return approval;
  });

  return bookingApprovals;
};

export const validateApprovals = ({
  bookingApprovals,
  isApprovingShift,
  isRatingRequired,
  isTTM = false
}) => {
  let error = "";

  // check each booking has the required fields or that it's been updated

  bookingApprovals.forEach(approval => {
    if (
      !approval.ratingOfMember &&
      !approval.isNoShow &&
      !approval.isTurnedAway &&
      isApprovingShift &&
      isRatingRequired
    ) {
      error = "Please check that you have rated all members";
    } else if (
      approval.ratingOfMember <= 2 &&
      approval.ratingOfMember !== null &&
      isRatingRequired
    ) {
      if (
        !approval.feedbackLabelsForMember?.length &&
        !approval.feedbackForMember &&
        !approval.isNoShow &&
        !approval.isTurnedAway &&
        isRatingRequired &&
        !isTTM
      ) {
        error =
          "You must provide feedback for all Members rated two stars or less";
      }
    } else if (approval.isTurnedAway && !approval.notes) {
      error =
        'Please check you have given a reason to all members marked as "Turned away"';
    }
  });

  return error;
};

export const removeNoBookings = shift => {
  const bookings = shift?.bookings?.data;

  // Keep cancellations where policy has been applied
  if (
    bookings.length >= 1 &&
    shift?.bookings?.data[0]?.result &&
    shift.approvalState === APPROVAL_STATES.NO_BOOKINGS
  ) {
    return shift.bookings.data.some(booking => booking.result.isPolicyApplied);
  }

  // Keep shifts where bookings have been applied
  if (
    bookings.length >= 1 &&
    shift.approvalState !== APPROVAL_STATES.NO_BOOKINGS
  ) {
    const totalShiftCost = bookings.reduce(
      (acc, booking) =>
        booking?.member?.annualSalary &&
        SALARIED_MEMBER_TYPES.includes(booking?.member?.memberType)
          ? 1
          : booking?.bookingState?.costFinal ?? 1,
      0
    );
    return totalShiftCost >= 1;
  }
  return false;
};

export const printAllShifts = async (
  allShiftPrint,
  picturesHidden,
  filters,
  shiftBookingCount,
  shiftBookingCountTotalCost
) => {
  const shiftData = allShiftPrint?.account?.shifts?.data;
  const shiftsWithBookings = shiftData?.filter(removeNoBookings);
  /*
    Generating in-between dates PDF timesheet via axios POST.
    Request: shift details for generating the timesheet.
    Response: ready-to-print Timesheet in PDF format in new window.
  */
  const isSafari =
    navigator.userAgent.indexOf("Safari") !== -1 &&
    navigator.userAgent.indexOf("Chrome") === -1;

  /*
    PROBLEM: Safari blocks window.open(url) when its called from async function.
    SOLUTION: Pre-opening a window before making the async call and pass the PDF.
  */
  let windowRef;
  if (isSafari) windowRef = window.open();
  if (!allShiftPrint) return null;

  await axios
    .post(
      TIMESHEETS_ENDPOINT,
      {
        shifts: shiftsWithBookings,
        bookingsTotalCount: shiftBookingCount,
        bookingsTotalCost: shiftBookingCountTotalCost,
        startDate: convertDateFromString(filters.startTime),
        endDate: convertDateFromString(filters.endTime),
        isHidden: picturesHidden,
        account: { logo: allShiftPrint?.account?.logo }
      },
      {
        headers: {
          Authorization: `Bearer ${await getCurrentAccessToken()}`
        },
        responseType: "arraybuffer"
      }
    )
    .catch(error => {
      if (error.response)
        console.log(
          "There was a problem with the server response:",
          console.log(error.response)
        );
      else if (error.request)
        console.log(
          "There was a problem with the request to the server:",
          console.log(error.request)
        );
      else console.log("Error:", console.log(error));
    })
    .then(res => {
      if (!res?.data) {
        return;
      }

      const blob = new Blob([res.data], { type: "application/pdf" });
      const objectUrl = URL.createObjectURL(blob);
      if (isSafari) {
        windowRef.location = objectUrl;
      } else {
        window.open(objectUrl);
      }
    });
};

const getSchema = ({ venueIds } = {}, allVenues) => {
  const usersSelectedVenuesIds = venueIds || [];

  const schema = [
    {
      label: "First name",
      name: "firstName"
    },
    {
      label: "Last name",
      name: "lastName"
    },
    {
      label: "Email",
      name: "email"
    },
    {
      label: "Phone",
      name: "phoneNumber",
      component: "phone-number-input"
    },
    {
      label: "Notes",
      name: "notes",
      width: "100%",
      component: "multiline-text-input"
    }
  ];

  const homeVenues = {
    label: "Home Venues",
    name: "venueIds",
    width: "100%",
    component: "home-venues-selection",
    usersSelectedVenuesIds: usersSelectedVenuesIds,
    allVenues: allVenues
  };

  if (allVenues?.length) schema.push(homeVenues);

  return schema.map(field => ({
    ...field,
    placeholder: field.label
  }));
};

export default getSchema;

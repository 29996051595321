import styled from "styled-components";
import { COLORS } from "~/src/styles/config.style";
import Icon from "~/src/components/icon";
import { colors } from "@teamrota/rota-design";
import { MAIN_COLUMN_WIDTH } from "../../utils";

export const StyledIconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 auto;
  padding: 0 0 15px 0;
  color: ${({ theme }) => theme.primary.main};

  > * {
    margin: 20px 0;
  }
`;

export const StyledOpenShifts = styled.div`
  padding-right: 0.85%;
  border-radius: 4px;
  display: flex;
  height: ${({ isWeeklyView }) => (isWeeklyView ? "140px" : "170px")};
  margin: 10px 0;
  width: 100%;
`;

export const StyledMainColumn = styled.div`
  min-width: ${MAIN_COLUMN_WIDTH};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  background: ${({ theme }) => theme.white};
  border: ${({ isDailyView }) =>
    isDailyView ? "none" : `0.5px solid ${colors.brightGrey}`};
`;

export const TableRow = styled.tr`
  display: flex;
  flex-direction: row;
`;

export const StyledIcon = styled(Icon)`
  vertical-align: middle;
  margin-right: 20px;
`;

const styles = {
  memberColumn: {
    height: 60,
    display: "flex",
    flexDirection: "column"
  },
  leftSideContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "-webkit-fill-available",
    width: "100%",
    padding: "0 0 0 10px"
  },
  leftIconContainer: { display: "flex" },
  countContainer: {
    display: "flex",
    alignItems: "center"
  },
  title: { fontWeight: 500, fontSize: 12, margin: 0 },
  innerContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "0 0 0 5px"
  },
  tableDiv: {
    border: `1px solid ${COLORS.GRID_GREY}`,
    width: 144,
    height: 60,
    paddingLeft: "3px",
    paddingRight: "3px"
  },
  text: {
    fontWeight: "500",
    fontSize: "10px",
    color: "#9B9B9B"
  },
  openShiftCellBorder: {
    borderTop: `1px solid ${COLORS.GRID_GREY}`,
    borderBottom: `1px solid ${COLORS.GRID_GREY}`,
    borderLeft: `1px solid ${COLORS.GRID_GREY}`,
    minWidth: "175px",
    width: "200px",
    height: "100px"
  }
};

export default styles;

import { gql } from "@apollo/client";

export const CREATE_UPDATE_TAGS = gql`
  mutation createOrUpdateVenueTags(
    $partnerId: ID!
    $venueId: ID!
    $tagIds: [ID]
    $roleWithTags: [venueRoleInputType]
  ) {
    createOrUpdateVenueTags(
      partnerId: $partnerId
      venueId: $venueId
      tagIds: $tagIds
      roleWithTags: $roleWithTags
    )
  }
`;

/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
import { graphql } from "@apollo/client/react/hoc";
import { gql } from "@apollo/client";
import { getOr, groupBy, head, last } from "lodash/fp";

export const manageableShiftsQueryGQL = gql`
  query getManageableShifts {
    account {
      id
      manageableShiftsCount
      manageableShifts {
        id
        name
        numberRequested
        startTime
        endTime
        role {
          name
        }
        shiftGroupId
        shiftGroupColor
        bookings(offset: 0, limit: 5, stateEquals: APPLIED) {
          totalResults
          data {
            state
            id
            member {
              id
              rating
              firstName
              lastName
              photo
            }
          }
        }
        acceptedBookings: bookings(offset: 0, limit: 0, stateEquals: ACCEPTED) {
          totalResults
        }
      }
    }
  }
`;

export const manageableShiftsCountQuery = gql`
  query QuickRefetch {
    account {
      id
      manageableShiftsCount
    }
  }
`;
export default graphql(manageableShiftsQueryGQL, {
  name: "manageableShiftsQuery",
  skip: props => !props.shouldLoadShifts,
  options: props => ({ fetchPolicy: "network-only" }),
  props: ({ manageableShiftsQuery }) => {
    const manageableShiftsFromQuery = getOr(
      [],
      "account.manageableShifts",
      manageableShiftsQuery
    );

    const manageableShifts = [];

    if (manageableShiftsFromQuery.length > 0) {
      const groupedLinkedShifts = groupBy(
        shift => shift.shiftGroupId,
        manageableShiftsFromQuery
      );

      for (const [, shiftsInGroup] of Object.entries(groupedLinkedShifts)) {
        if (shiftsInGroup.length === 1) {
          manageableShifts.push(head(shiftsInGroup));
        }

        if (shiftsInGroup.length > 1) {
          manageableShifts.push({
            ...head(shiftsInGroup),
            isLinkedShift: true,
            lastLinkedShift: last(shiftsInGroup).startTime
          });
        }
      }
    }

    return {
      manageableShifts,
      isLoading: manageableShiftsQuery.loading
    };
  }
});

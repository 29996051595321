import { useQuery, gql } from "@apollo/client";

export const GET_MEMBER_HOURS_WORKED = gql`
  query getMemberHoursWorked(
    $id: ID!
    $startBefore: Date!
    $startAfter: Date!
    $isExceptionDates: Boolean!
  ) {
    getMemberHoursWorked(
      id: $id
      startBefore: $startBefore
      startAfter: $startAfter
      isExceptionDates: $isExceptionDates
    )
  }
`;

export const getMemberHoursWorked = (
  memberId,
  startDate,
  endDate,
  isExceptionDates = false
) => {
  const { data } = useQuery(GET_MEMBER_HOURS_WORKED, {
    variables: {
      id: memberId,
      startBefore: endDate,
      startAfter: startDate,
      isExceptionDates
    },
    fetchPolicy: "network-only",
    skip: !startDate || !endDate
  });

  const hoursCompleted = data ? data.getMemberHoursWorked : 0;

  return {
    hoursCompleted
  };
};

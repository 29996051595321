import { gql } from "@apollo/client";

export const GET_ACCOUNT_SETTINGS = gql`
  query getAccountSettings {
    account {
      id
      isOverLimitWorkAllowed
    }
  }
`;

import React from "react";
import moment from "moment-timezone";
import { Row, DateRowDate } from "./styles";

const DateRow = ({ calendarWeekDate }) => {
  const date = (_, dayIndex) => {
    const mom = moment(calendarWeekDate).add(dayIndex, "d");
    const isToday = mom.isSame(moment(), "day");

    return (
      <DateRowDate isToday={isToday} key={dayIndex}>
        {isToday && "Today"} {mom.format("ddd D")}
      </DateRowDate>
    );
  };

  return (
    <Row>
      {Array(7)
        .fill(null)
        .map(date)}
    </Row>
  );
};

export default React.memo(DateRow);

import React, { useContext, useState } from "react";
import { TextField, IconButton, Menu } from "@material-ui/core";

import { Autocomplete } from "@material-ui/lab";
import { RotaButtonNew, iconNew } from "@teamrota/rota-design";
import MenuBtns from "./menu-btn";
import ContainerUtility from "./container-utility";
import { RolesContext } from "~/src/containers/settings-page/context/roles-context";
import { TableBody } from "~/src/containers/settings-page/right-panel/permissions-tab-content/table-body";
import { tableHeaders } from "~/src/containers/settings-page/right-panel/permissions-tab-content/utils";

import getUserTypeTemplates from "../../graphql/get-user-type-templates";

import {
  StyledButton,
  StyledTableWrapper,
  StyledPermissionTypePanel,
  StyledPermissionTypeTitle,
  StyledPermissionWrapperContainer,
  StyledPermissionsWrapButton,
  StyledTypesWrapButton,
  StyledTable,
  useStyles,
  thStyle,
  StyledTdHeaders,
  StyledTH
} from "./permissions-tab-content.styles";

const { Kebab } = iconNew;

const PermissionsTabContent = ({
  userTypeTemplates,
  refetch,
  loading,
  isLoading
}) => {
  const {
    setRoles,
    selectedUserTypeId,
    setSelectedUserTypeId,
    hasChanged,
    setIsSearched,
    searchInput
  } = useContext(RolesContext);

  const classes = useStyles();
  const [changeId, setChangeId] = useState();
  const [openDotMenu, setOpenDotMenu] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [openChangeDialog, setOpenChangeDialog] = useState(false);
  const [openRenameDialog, setOpenRenameDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState("");

  const handleUserTypeChange = (newId, updateChange) => {
    setIsSearched("");
    if (updateChange) {
      setChangeId(newId);
      setOpenChangeDialog(true);
    } else {
      setSelectedUserTypeId(newId);
      setRoles(userTypeTemplates?.find(({ id }) => id == newId).roles);
    }
  };

  const onChangeSearch = e => {
    setIsSearched(e?.target?.value);
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
    setIsSearched("");
  };

  const handleOpenMenu = (id, event, hasChanged) => {
    if (hasChanged) {
      handleUserTypeChange(id, hasChanged);
      setAnchorEl(event?.currentTarget);
      setOpenDotMenu(!openDotMenu);
      setIsSearched("");
    } else {
      handleUserTypeChange(id, hasChanged);
      setAnchorEl(event?.currentTarget);
      setOpenDotMenu(!openDotMenu);
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setOpenDotMenu(false);
  };

  return (
    <>
      <StyledTableWrapper id="table-wrapper">
        {!loading && (
          <StyledPermissionTypePanel id="permission-type-panel">
            <StyledPermissionTypeTitle>TYPES</StyledPermissionTypeTitle>
            <RotaButtonNew
              onClick={handleClickOpenDialog}
              variant="outlined"
              style={{ marginBottom: 16 }}
            >
              Create New
            </RotaButtonNew>
            {hasChanged && (
              <RotaButtonNew
                onClick={setOpenSaveDialog}
                loading={isLoading}
                style={{ marginBottom: 16 }}
              >
                Save
              </RotaButtonNew>
            )}
            <StyledPermissionWrapperContainer id="permission-wrapper-container">
              {userTypeTemplates?.map(({ id, name }) => {
                const selected = id == selectedUserTypeId;
                return (
                  <StyledPermissionsWrapButton key={id}>
                    <StyledTypesWrapButton>
                      <StyledButton
                        selected={selected}
                        color="#AEB4BD"
                        onClick={() => handleUserTypeChange(id, hasChanged)}
                        isHover
                      >
                        {name}
                      </StyledButton>
                      <IconButton
                        value={id}
                        onClick={event => handleOpenMenu(id, event, hasChanged)}
                        className={selected ? classes.activeSvg : classes.svg}
                      >
                        <Kebab />
                      </IconButton>
                    </StyledTypesWrapButton>
                  </StyledPermissionsWrapButton>
                );
              })}
              <Menu
                anchorEl={anchorEl}
                id="long-menu"
                keepMounted
                open={openDotMenu}
                onClose={handleCloseMenu}
                classes={{
                  paper:
                    selectedUserTypeId < 99
                      ? classes.menuDot
                      : classes.bigMenuDot
                }}
              >
                <MenuBtns
                  setOpenRenameDialog={setOpenRenameDialog}
                  setOpenDeleteDialog={setOpenDeleteDialog}
                  setOpenDotMenu={setOpenDotMenu}
                />
              </Menu>
            </StyledPermissionWrapperContainer>
          </StyledPermissionTypePanel>
        )}
        <div id="container">
          <StyledTable>
            <thead>
              <tr>
                {tableHeaders.map((header, idx) =>
                  idx === 0 ? (
                    <StyledTH key={header.id} style={thStyle(idx)}>
                      <StyledTdHeaders>{header.name}</StyledTdHeaders>
                      <Autocomplete
                        freeSolo
                        id="free-solo-2-demo"
                        onSelect={onChangeSearch}
                        options={searchInput?.map(name => name)}
                        renderInput={params => (
                          <TextField
                            {...params}
                            placeholder="Filter by Field"
                            margin="dense"
                            variant="outlined"
                            className={classes.input}
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                              style: {
                                width: "385px",
                                height: "38px",
                                boxSizing: "border-box",
                                borderRadius: "5px"
                              }
                            }}
                          />
                        )}
                      />
                    </StyledTH>
                  ) : (
                    <StyledTH style={thStyle(idx)} key={header.id}>
                      <StyledTdHeaders>{header.name}</StyledTdHeaders>
                    </StyledTH>
                  )
                )}
              </tr>
            </thead>
            <TableBody />
          </StyledTable>
        </div>
      </StyledTableWrapper>
      <ContainerUtility
        handleUserTypeChange={handleUserTypeChange}
        loading={loading}
        setOpenDotMenu={setOpenDotMenu}
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
        setOpenChangeDialog={setOpenChangeDialog}
        openChangeDialog={openChangeDialog}
        setOpenSaveDialog={setOpenSaveDialog}
        openSaveDialog={openSaveDialog}
        changeId={changeId}
        setOpenRenameDialog={setOpenRenameDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        openDeleteDialog={openDeleteDialog}
        openRenameDialog={openRenameDialog}
        userTypeTemplates={userTypeTemplates}
        refetchUserTypeTemplates={refetch}
      />
    </>
  );
};

export default getUserTypeTemplates(PermissionsTabContent);

import axios from "axios";
import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useQuery } from "@apollo/client";
import { Role } from "@teamrota/authlib";
import { RotaButton, RotaCheckbox, RotaDivider } from "@teamrota/rota-design";

import { GET_ROLE_RATE } from "~/src/components/RateBreakdown/graphql";
import useAuth from "~/src/auth/hooks/use-auth";
import BonusReward from "~/src/components/bonus-reward";
import TextInput from "~/src/components/form-components/text-input";
import { CHECK_ROLE_PARTNER_MANAGE_ENDPOINT } from "~/src/config";
import { MODAL_TYPES } from "~/src/containers/global-modals";
import * as reducerActions from "~/src/containers/scheduler/reducer";
import { formatLinkedShifts } from "~/src/containers/scheduler/utils";
import getProfile from "~/src/graphql/queries/get-profile/get-profile-query.decorator";
import { COLORS } from "~/src/styles/config.style";
import asyncConfirm from "~/src/utils/async-confirm";
import { isWithinThreeDays } from "~/src/utils/time-utils";
import { PARTNER_MANAGED_THRESHOLD } from "~/src/consts";
import { useCurrency } from "~/src/containers/profile-loader";

import { mapStateToProps, useTotals } from "./selectors";
import styles, {
  Bold,
  Cost,
  EditButton,
  Main,
  Text,
  Title,
  TitleWrapper,
  Wrapper,
  DisclaimerP
} from "./styles";
import { Actions, Header, HeaderTitle } from "../styles";
import FormStepSegment from "../FormStepSegment";
import ShiftRebooking from "./ShiftRebooking";

const bindActions = dispatch =>
  bindActionCreators(
    {
      addBonus: reducerActions.addBonus,
      removeBonus: reducerActions.removeBonus,
      updateBonusType: reducerActions.updateBonusType,
      updateBonusAmount: reducerActions.updateBonusAmount,
      updateTotalBonus: reducerActions.updateTotalBonus
    },
    dispatch
  );

const FormSummary = props => {
  const {
    shift,
    handleShiftUpdate,
    isOpen,
    stepNumber,
    shiftForm,
    bonuses,
    addBonus,
    updateBonusAmount,
    removeBonus,
    updateTotalBonus,
    updateBonusType,
    handlePostShift,
    goToSelf,
    hasVisited,
    isShiftPostLoading,
    isProviderScheduler,
    roleRates,
    filteredPartners,
    selectedPartnerId,
    isAccountRebookingEnabled
  } = props;

  const auth = useAuth();
  const { totalWithoutTax, totalWithTax } = useTotals(props);
  const [roleCanBeManaged, setRoleCanBeManaged] = useState(false);

  const { taxRate } = useCurrency();

  const {
    roleRateId,
    name,
    identifier,
    times,
    isPartnerManaged,
    isLinkedShifts,
    shiftIndex,
    isUncalculatedRate
  } = shift;

  useEffect(() => {
    if (roleRateId && !isProviderScheduler) {
      try {
        axios
          .post(CHECK_ROLE_PARTNER_MANAGE_ENDPOINT, {
            roleRateId
          })
          .then(res => {
            setRoleCanBeManaged(res.data);
          });
      } catch (error) {
        console.log("error", error);
      }
    }
  }, [roleRateId]);

  const shiftIndexWithDefault = shiftIndex || 0;

  const { data } = useQuery(GET_ROLE_RATE, {
    variables: { roleRateId },
    skip: !roleRateId
  });

  const roleRateById = data?.account?.roleRateById;
  const isOvertimeOrOverstay =
    roleRateById?.isOvertimeRate || roleRateById?.isOverstayRate;

  const roleName = roleRates?.find(role => role.id === shift.roleRateId)
    ?.roleName;
  const partnerName = filteredPartners?.find(
    partner => partner.id === selectedPartnerId
  )?.accountName;

  return (
    <FormStepSegment
      isOpen={isOpen}
      stepNumber={stepNumber}
      title={() => (
        <Header>
          <HeaderTitle>Summary</HeaderTitle>
        </Header>
      )}
    >
      {() => (
        <Main>
          <Wrapper>
            {isOpen && (
              <div>
                <TextInput
                  shouldFixLabelHeight
                  isSmall
                  fontColor={COLORS.BLACK}
                  label="Event Name"
                  placeholder="Give your event a name"
                  value={name}
                  onChangeValue={name => {
                    handleShiftUpdate({ name });
                  }}
                />
                <TextInput
                  shouldFixLabelHeight
                  isSmall
                  fontColor={COLORS.BLACK}
                  label="My ID"
                  placeholder="Add an internal identifier or Purchase Order number"
                  value={identifier}
                  onChangeValue={identifier => {
                    handleShiftUpdate({ identifier });
                  }}
                />
                {isAccountRebookingEnabled && (
                  <ShiftRebooking
                    roleName={roleName}
                    partnerName={partnerName}
                    handleShiftUpdate={handleShiftUpdate}
                    shift={shift}
                  />
                )}
                {!!roleCanBeManaged && (
                  <div>
                    <RotaCheckbox
                      label="Manually manage shift"
                      isDisabled={isWithinThreeDays(times)}
                      isChecked={isPartnerManaged}
                      onClick={() => {
                        if (isPartnerManaged) {
                          handleShiftUpdate({ isPartnerManaged: false });
                        } else {
                          asyncConfirm(
                            null,
                            {
                              subComponent: () => (
                                <span>
                                  <TitleWrapper>
                                    <Title>Manage Shifts</Title>
                                  </TitleWrapper>
                                  <Text>
                                    Managed shifts are shifts that you are able
                                    to manage up until{" "}
                                    {`${PARTNER_MANAGED_THRESHOLD}`} days before
                                    the scheduled start time of the shift.
                                  </Text>
                                  <Text>
                                    Your agency will generally not look to fill
                                    the shift until this time.
                                  </Text>
                                  <Text>
                                    You will be able to see all applicants to
                                    the shift before choosing to accept,
                                    decline, or add them to a waiting list.
                                  </Text>
                                </span>
                              ),
                              confirmButtonText: "Manage Shift",
                              falseButtonText: "Leave to Agency",
                              onConfirm: () => {
                                handleShiftUpdate({ isPartnerManaged: true });
                              }
                            },
                            MODAL_TYPES.BLACK_AND_ORANGE
                          );
                        }
                      }}
                    />
                    {isWithinThreeDays(times) && (
                      <p>
                        NOTE: Partner manually managed shift cannot be activated
                        as shift start time is less than{" "}
                        {`${PARTNER_MANAGED_THRESHOLD}`} days away.
                      </p>
                    )}
                  </div>
                )}
              </div>
            )}
            <Actions justify="space-between" hasTopMargin>
              {!isProviderScheduler && (
                <div>
                  {auth.hasAnyRole(Role.SHIFTS_PAY, Role.SHIFTS_COST) &&
                    isOpen && (
                      <BonusReward
                        customFontSize={12}
                        enlargedAddIcon
                        hideSubTotal
                        roleOptions={[]}
                        formData={shiftForm}
                        onAddBonus={() => addBonus(shiftIndexWithDefault)}
                        onUpdateBonusType={(index, type) =>
                          updateBonusType({
                            index,
                            type,
                            shiftIndex: shiftIndexWithDefault
                          })
                        }
                        onDeleteBonus={index =>
                          removeBonus({
                            index,
                            shiftIndex: shiftIndexWithDefault
                          })
                        }
                        onUpdateBonusAmount={(index, amount) =>
                          updateBonusAmount({
                            index,
                            amount,
                            shiftIndex: shiftIndexWithDefault
                          })
                        }
                        onUpdateBonusTotal={totalBonus => {
                          updateTotalBonus({
                            totalBonus,
                            shiftIndex: shiftIndexWithDefault
                          });
                        }}
                        bonuses={bonuses}
                        linkedMultiple={
                          isLinkedShifts ? formatLinkedShifts(shift).length : 1
                        }
                        surgeFactor={false}
                      />
                    )}
                  {auth.hasRole(Role.SHIFTS_COST) &&
                    isOpen &&
                    !isUncalculatedRate && (
                      <div style={styles.totalContainer}>
                        <Cost>
                          Total:{" "}
                          <Bold>
                            {totalWithoutTax} + {taxRate}% VAT
                          </Bold>
                        </Cost>
                        <Cost>
                          Grand Total: <Bold>{totalWithTax}</Bold>
                        </Cost>
                      </div>
                    )}
                </div>
              )}
              <div>
                {isOpen && (
                  <RotaButton
                    size="small"
                    onClick={handlePostShift}
                    isLoading={isShiftPostLoading}
                    disabled={isShiftPostLoading}
                  >
                    Post
                  </RotaButton>
                )}
                {!isOpen && hasVisited && (
                  <EditButton onClick={goToSelf}>Edit</EditButton>
                )}
              </div>
            </Actions>
            {auth.hasRole(Role.SHIFTS_COST) && isOpen && isOvertimeOrOverstay && (
              <>
                <RotaDivider />
                <DisclaimerP>
                  NOTE: Overtime or Overstay rates may apply extra dependent on
                  the Members assigned and hours worked.
                </DisclaimerP>
              </>
            )}
          </Wrapper>
        </Main>
      )}
    </FormStepSegment>
  );
};

export default getProfile(connect(mapStateToProps, bindActions)(FormSummary));

import { graphql } from "@apollo/client/react/hoc";
import { gql } from "@apollo/client";
import { flow } from "lodash";

import { addAuthVars } from "@teamrota/authlib";

import { withAuth } from "~/src/auth/hoc";
import { handleErrors } from "~/src/utils/errors";

import LogFragment from "~/src/graphql/fragments/log/log.fragment";

const GET_BOOKING = addAuthVars(gql`
  ${LogFragment}
  query getBooking($bookingId: ID!, $sourceAccountId: ID) {
    booking(id: $bookingId) {
      id

      ... on booking {
        logs {
          ...LogFragment
        }
      }

      state
      memberId
      ratingOfMember
      ratingOfManager
      feedbackForMember
      feedbackForManager
      approvalState
      isUnderReview
      compatability
      factorScore

      policy {
        id
        minimumShiftLength
        minimumShiftPercentage
        cancellationPeriod
      }

      member {
        id
        internalId
        firstName
        bookingsCount(sourceAccountId: $sourceAccountId) {
          upcomingAccepted
        }
      }
      result {
        startFinal
        endFinal
        isPolicyApplied
        lengthFinal

        ... on bookingResult {
          costFinal
        }
      }
      bookingState {
        id
        startOnApply
        startOnShift
        startOnApproval
        startOnPoolManagerOverride
        startOnShiftManager
        endOnApply
        endOnShift
        endOnApproval
        endOnPoolManagerOverride
        endOnShiftManager
        breakMinutes
        breakOnShift
        breakOnShiftManager
        breakOnApproval
        breakOnPoolManagerOverride
      }

      ... on booking {
        totalCostBonusesAndRewards(predicted: true)
        bonuses {
          id
          type
        }
      }

      shift {
        id
        startTime
        endTime
        numberRequested

        sourceAccount {
          id
          accountName
        }

        targetAccount {
          id
          accountName
        }

        role {
          id
          name
          isDayRate
        }

        venue {
          id
          name
        }
      }
    }
  }
`);

export default flow(
  graphql(GET_BOOKING, {
    skip: props => !props.isOpen,
    options: ({ auth, bookingId }) => ({
      variables: auth.addVals(GET_BOOKING, { bookingId })
    }),
    props: handleErrors(({ data: { loading, booking } }) => ({
      isLoading: loading,
      booking
    }))
  }),
  withAuth
);

import { createReducer, createAction } from "~/src/utils";
import moment from "moment-timezone";

export const ON_TOGGLE_TIMESHEET = "timesheets/ON_TOGGLE_TIMESHEET";
export const ON_TOGGLE_NO_SHOW = "timesheets/ON_TOGGLE_NO_SHOW";
export const ON_TOGGLE_TURNED_AWAY = "timesheets/ON_TOGGLE_TURNED_AWAY";
export const ON_TOGGLE_FAVOURITE = "timesheets/ON_TOGGLE_FAVOURITE";
export const ON_CHANGE_RATING = "timesheets/ON_CHANGE_RATING";
export const ON_TOGGLE_EDIT_MODE = "timesheets/ON_TOGGLE_EDIT_MODE";
export const ON_UPDATE_START_TIME = "timesheets/ON_UPDATE_START_TIME";
export const ON_UPDATE_END_TIME = "timesheets/ON_UPDATE_END_TIME";
export const ON_TOGGLE_FEEDBACK = "timesheets/ON_TOGGLE_FEEDBACK";
export const ON_UPDATE_FEEDBACK_LABELS = "timesheets/ON_UPDATE_FEEDBACK_LABELS";
export const ON_UPDATE_FEEDBACK = "timesheets/ON_UPDATE_FEEDBACK";
export const ON_UPDATE_FILTERS = "timesheets/ON_UPDATE_FILTERS";
export const ON_UPDATE_BREAK = "timesheets/ON_UPDATE_BREAK";
export const ON_UPDATE_NOTES = "timesheets/ON_UPDATE_NOTES";

export const onToggleTimesheet = createAction(ON_TOGGLE_TIMESHEET);
export const onToggleNoShow = createAction(ON_TOGGLE_NO_SHOW);
export const onToggleTurnedAway = createAction(ON_TOGGLE_TURNED_AWAY);
export const onToggleEditMode = createAction(ON_TOGGLE_EDIT_MODE);
export const onToggleFavourite = createAction(ON_TOGGLE_FAVOURITE);
export const onUpdateStartTime = createAction(ON_UPDATE_START_TIME);
export const onToggleFeedback = createAction(ON_TOGGLE_FEEDBACK);
export const onUpdateEndTime = createAction(ON_UPDATE_END_TIME);
export const onUpdateFeedbackLabels = createAction(ON_UPDATE_FEEDBACK_LABELS);
export const onFeedbackUpdate = createAction(ON_UPDATE_FEEDBACK);
export const onUpdateFilters = createAction(ON_UPDATE_FILTERS);
export const onUpdateBreak = createAction(ON_UPDATE_BREAK);
export const onUpdateBookingNotes = createAction(ON_UPDATE_NOTES);
export const onChangeRating = (bookingId, value) => ({
  type: ON_CHANGE_RATING,
  payload: { bookingId, value }
});

const DEFAULT_STATE = {
  openTimesheetId: null,
  editModes: {},
  feedbacksVisible: {},
  noShowUpdates: {},
  turnedAwayUpdates: {},
  favouriteUpdates: {},
  ratingUpdates: {},
  startTimeUpdates: {},
  endTimeUpdates: {},
  breakUpdates: {},
  feedbackLabelUpdates: {},
  feedbackUpdates: {},
  notesUpdates: {},
  filters: {
    startTime: moment()
      .subtract(1, "month")
      .format("DD/MM/YYYY"),
    endTime: moment().format("DD/MM/YYYY")
  }
};

const toggleUpdate = prop => (state, { payload }) => ({
  ...state,
  [prop]: { ...state[prop], [payload]: !state[prop][payload] }
});

const setUpdate = prop => (state, { payload }) => ({
  ...state,
  [prop]: { ...state[prop], [payload.bookingId]: payload.value }
});

export default createReducer(
  {
    [ON_TOGGLE_TIMESHEET]: (state, { payload }) => ({
      ...state,
      openTimesheetId: state.openTimesheetId === payload ? null : payload
    }),
    [ON_UPDATE_FILTERS]: (state, { payload }) => ({
      ...state,
      filters: {
        ...state.filters,
        ...payload
      }
    }),
    [ON_TOGGLE_NO_SHOW]: toggleUpdate("noShowUpdates"),
    [ON_TOGGLE_TURNED_AWAY]: toggleUpdate("turnedAwayUpdates"),
    [ON_TOGGLE_FAVOURITE]: setUpdate("favouriteUpdates"),
    [ON_TOGGLE_FEEDBACK]: toggleUpdate("feedbacksVisible"),
    [ON_CHANGE_RATING]: setUpdate("ratingUpdates"),
    [ON_TOGGLE_EDIT_MODE]: toggleUpdate("editModes"),
    [ON_UPDATE_START_TIME]: setUpdate("startTimeUpdates"),
    [ON_UPDATE_END_TIME]: setUpdate("endTimeUpdates"),
    [ON_UPDATE_FEEDBACK_LABELS]: setUpdate("feedbackLabelUpdates"),
    [ON_UPDATE_FEEDBACK]: setUpdate("feedbackUpdates"),
    [ON_UPDATE_BREAK]: setUpdate("breakUpdates"),
    [ON_UPDATE_NOTES]: setUpdate("notesUpdates")
  },
  DEFAULT_STATE
);

import React from "react";

import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { ApolloProvider } from "@apollo/client";

import App from "~/src/app";
import AuthProvider from "~/src/auth/provider";
import store, { persistor } from "~/src/store";
import client from "~/src/services/graphql";
import { PersistGate } from "redux-persist/integration/react";

const Root = () => (
  <ApolloProvider client={client}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div id="app">
          <AuthProvider>
            <DndProvider backend={HTML5Backend}>
              <Router>
                <App />
              </Router>
            </DndProvider>
          </AuthProvider>
        </div>
      </PersistGate>
    </Provider>
  </ApolloProvider>
);

export default Root;

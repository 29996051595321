import React from "react";
import { Main, Header, Count, Content } from "./form-step-segment.styles";

const FormStepSegment = props => (
  <Main>
    <Header>
      <Count isOpen={props.isOpen}>
        <span>{props.stepNumber}</span>
      </Count>
      {props.title()}
    </Header>

    <Content noPadding={props.noPadding} isOpen={props.isOpen}>
      {props.children()}
    </Content>
  </Main>
);

export default FormStepSegment;

import React from "react";
import { RotaButtonNew } from "@teamrota/rota-design";
import { Role } from "@teamrota/authlib";
import useAuth from "~/src/auth/hooks/use-auth";

import {
  Wrapper,
  EditItem,
  EditCancelButtons,
  EditContainer,
  ErrorText,
  Scrollable
} from "./index.styles";

const EditCancel = ({
  showErrors,
  isCreate,
  inEditMode,
  onEdit,
  onCancel,
  onSave,
  isSaving,
  hasSaved,
  children,
  hideCancel,
  hasEditBtn,
  isSaveDisabled,
  isPartner,
  isScrollable = true
}) => {
  const getSaveButtonText = () => {
    if (isCreate) {
      return hasSaved ? "Created" : "Create";
    }
    return hasSaved ? "Saved" : "Save";
  };

  const auth = useAuth();
  const hasPartnerAuthorisation = auth.hasRole(Role.PARTNER_EDIT);
  return (
    <Wrapper>
      {!inEditMode && (
        <EditContainer>
          {showErrors && (
            <ErrorText>
              Please fix the error(s) below in order to save
            </ErrorText>
          )}
          <EditItem isVisible={!inEditMode}>
            {hasEditBtn && (hasPartnerAuthorisation || !isPartner) && (
              <RotaButtonNew onClick={onEdit} variant="outlined">
                Edit
              </RotaButtonNew>
            )}
          </EditItem>
        </EditContainer>
      )}
      {isScrollable && <Scrollable>{children}</Scrollable>}
      {!isScrollable && <Wrapper>{children}</Wrapper>}
      <EditCancelButtons isVisible={inEditMode}>
        {!hideCancel && (
          <RotaButtonNew
            onClick={onCancel}
            variant="outlined"
            style={{ marginRight: 24 }}
          >
            Cancel
          </RotaButtonNew>
        )}
        <RotaButtonNew
          onClick={onSave}
          isLoading={isSaving}
          isSuccess={hasSaved}
          disabled={isSaveDisabled}
        >
          {getSaveButtonText()}
        </RotaButtonNew>
      </EditCancelButtons>
    </Wrapper>
  );
};

export default EditCancel;

import styled from "styled-components";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";

export const Content = styled.div`
  padding: ${STYLE_CONSTS.UNIT_2};
`;

export const ModalTitle = styled.div`
  border-bottom: ${STYLE_CONSTS.SMALL_DIVIDER_BORDER_LIGHT};
  padding-bottom: ${STYLE_CONSTS.UNIT_2};
  margin-bottom: ${STYLE_CONSTS.UNIT_4};
`;

// display wide version of Field Row using flex
export const WideFieldRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

// Display flex child and add basis size
export const FieldRowFlex = styled.div`
  flex: ${props => props.flexSize};
`;

export const FieldRow = styled.div`
  display: block;
  width: 200px;
  margin: ${STYLE_CONSTS.UNIT_1} auto;
  position: relative;
  text-align: center;
`;

// Error label styling
export const ErrorLabel = styled.div`
  margin: 0 auto;
  width: 100%;
  color: red;
  vertical-align: middle;
  margin-top: 1rem;
`;

export const FieldCol = styled.div`
  margin: ${props => props.marginOveride};
  ${props => (props.center ? "text-align: center" : "")};
  text-align: ${({ center }) => center && "center"};
  display: inline-block;
  width: ${({ width }) => width}%;
  vertical-align: middle;
  &:last-child {
    text-align: left;
    padding-left: ${STYLE_CONSTS.UNIT_1};
  }
`;

export const SummaryText = styled.div`
  text-align: center;
  margin-top: ${STYLE_CONSTS.UNIT_2};
`;

export const LightText = styled.span`
  color: ${COLORS.COOL_GREY};
  font-size: ${STYLE_CONSTS.FONT_SMALL};
`;

export const ButtonFooter = styled.div`
  text-align: center;
  margin-top: ${STYLE_CONSTS.UNIT_4};
`;

import React from "react";
import { iconNew } from "@teamrota/rota-design";
import CheckInLocationForm from "./check-in-location-form";

import {
  SetUpModalWrapper,
  CheckInFormWrapper,
  ModalHeaderBar,
  ModalTitleDiv
} from "~/src/containers/templates/containers/venue-templates/check-in/check-in-styles";

const { Cancel } = iconNew;

const CheckInSetupModalContent = ({ setIsModalOpen }) => {
  return (
    <SetUpModalWrapper>
      <ModalHeaderBar>
        <Cancel onClick={() => setIsModalOpen(false)} />
      </ModalHeaderBar>
      <ModalTitleDiv>How do you want workers to check in or out?</ModalTitleDiv>
      <CheckInFormWrapper>
        <CheckInLocationForm setIsModalOpen={setIsModalOpen} />
      </CheckInFormWrapper>
    </SetUpModalWrapper>
  );
};

export default CheckInSetupModalContent;

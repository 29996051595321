import React from "react";
import { RotaModal } from "@teamrota/rota-design";
import { StyledModalWrapper } from "./styles";
import { RotaButton } from "@teamrota/rota-design";

const ConfirmRejectModal = ({ onClose, handleReject }) => (
  <RotaModal onClose={onClose} isSmall>
    <StyledModalWrapper>
      <div className="top_block">
        <div className="title">Erase File</div>
      </div>
      <div className="bottom_block">
        <div className="title">
          Are you sure you want to erase the existing file and its data? This
          action cannot be undone.
        </div>
        <div className="buttons">
          <RotaButton onClick={onClose} variant="secondary">
            Cancel
          </RotaButton>
          <RotaButton onClick={handleReject}>Confirm</RotaButton>
        </div>
      </div>
    </StyledModalWrapper>
  </RotaModal>
);

export default ConfirmRejectModal;

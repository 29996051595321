import React from "react";
import {
  Heading,
  Title,
  Subtext,
  TextContainer,
  StyledRedBox
} from "./page-header.styles";

export default function PageHeader({
  overridePadding,
  alignItems,
  children,
  title,
  subtext,
  ...props
}) {
  return (
    <Heading
      overridePadding={overridePadding}
      alignItems={alignItems}
      {...props}
    >
      <TextContainer isAddMinWidth={!!children?.length}>
        <Title>{title}</Title>
        <StyledRedBox />
        <Subtext>{subtext}</Subtext>
      </TextContainer>
      {children}
    </Heading>
  );
}

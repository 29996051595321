import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const createOrUpdateUniformTemplate = graphql(
  gql`
    mutation Mutation($id: ID, $identifier: String, $items: [String]) {
      createOrUpdateUniform(id: $id, identifier: $identifier, items: $items) {
        id
        identifier
        items
        createdAt
      }
    }
  `,
  {
    name: "createOrUpdateUniformTemplate",
    props: ownProps => ({
      createOrUpdateUniformTemplate: (id, identifier, items) =>
        ownProps.createOrUpdateUniformTemplate({
          variables: {
            id,
            identifier,
            items
          },
          refetchQueries: ["getProfile"]
        })
    })
  }
);

export default createOrUpdateUniformTemplate;

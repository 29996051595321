import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const CREATE_CUSTOM_USER_TYPE = gql`
  mutation createUpdateUserTypeTemplate(
    $id: ID
    $name: String
    $roles: [UserRoleType]
  ) {
    createUpdateUserTypeTemplate(id: $id, name: $name, roles: $roles) {
      id
      name
      roles
    }
  }
`;

export default graphql(CREATE_CUSTOM_USER_TYPE, {
  name: "createUpdateUserTypeTemplate",
  props: ownProps => ({
    createUpdateUserTypeTemplate: userType =>
      ownProps.createUpdateUserTypeTemplate({
        variables: userType,
        isLoading: userType.loading,
        refetchQueries: ["getUserTypeTemplates"]
      })
  })
});

import React from "react";
import { ProgressBackground, Progress } from "./styles";

export default function CircleProgress(props) {
  const radius = 8;
  const borderWidth = 1;
  const xAxis = radius + borderWidth;
  const yAxis = radius + borderWidth;
  const circum = Math.PI * (radius * 2);
  const dashOffset = calculateDashOffset(radius, props.progress, circum);
  const style = { height: yAxis * 2, width: xAxis * 2 };
  return (
    <svg style={style} className={props.className}>
      <ProgressBackground
        r={radius}
        cx={xAxis}
        cy={yAxis}
        fill="transparent"
        strokeDasharray={circum}
        strokeDashoffset="0"
      />
      <Progress
        r={radius}
        cx={xAxis}
        cy={yAxis}
        fill="transparent"
        strokeDasharray={circum}
        strokeDashoffset={dashOffset}
      />
    </svg>
  );
}

function calculateDashOffset(radius, perc, circum) {
  // Max at 100 min at 0.
  // eslint-disable-next-line no-nested-ternary
  const safePercentrage = perc > 100 ? 100 : perc < 0 ? 0 : perc;
  return ((100 - safePercentrage) / 100) * circum;
}

export const validate = (values: any) => {
  const errors: any = {};

  if (!values.selectedPartnerId) {
    errors.selectedPartnerId = "Required";
  }

  if (!values.selectedRoles.length) {
    errors.selectedRoles = "Required";
  }

  if (!values.selectedVenues.length) {
    errors.selectedVenues = "Required";
  }

  if (!values.selectedRate) {
    errors.selectedRate = "Required";
  }

  if (!values.selectedCharge) {
    errors.selectedCharge = "Required";
  }

  if (!values.selectedScale) {
    errors.selectedScale = "Required";
  }

  if (!values.selectedRule) {
    errors.selectedRule = "Required";
  }

  return errors;
};

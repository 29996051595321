import { useQuery, gql } from "@apollo/client";
import { addAuthVars } from "@teamrota/authlib";

const GET_CANCELLED_BOOKINGS = addAuthVars(gql`
  query getCancelledBookings($shiftId: ID!) {
    account {
      id
      shift(id: $shiftId) {
        id

        bookings(
          stateEquals: CANCELLED
          offset: 0
          limit: 100
          hasReplacementBookingId: false
        ) {
          totalResults
          data {
            id
            state
            replacementBookingId
            member {
              id
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`);

export const useCancelledBookings = shiftId => {
  const { loading, error, data } = useQuery(GET_CANCELLED_BOOKINGS, {
    variables: { shiftId }
  });

  return {
    loading,
    error,
    bookings: data?.account?.shift?.bookings?.data || []
  };
};

import React, { useState, useCallback } from "react";
import { debounce } from "lodash";

import { iconNew, RotaButtonIcon } from "@teamrota/rota-design";
import { RotaInput } from "@teamrota/rota-design";

import {
  useStyles,
  StyledTooltip
} from "~/src/containers/scheduler/SearchInput/search-input.styles";

import FilterContent from "./FilterContent";

const { Search, Filter } = iconNew;

const SearchInput = ({
  schedule,
  setRoleIds,
  setSortBy,
  sortBy,
  setVenueIds,
  setSearchTerm,
  setHomeVenueIds,
  searchTerm
}) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const [searchInput, setSearchInput] = useState(searchTerm);

  const debounceLoadData = useCallback(debounce(setSearchTerm, 1000), [
    searchInput
  ]);

  const handleSearch = value => {
    setSearchInput(value);
    debounceLoadData(value);
  };

  return (
    <>
      <RotaInput
        placeholder={"Search..."}
        iconLeft={<Search />}
        onChange={event => handleSearch(event.target.value)}
      />
      <StyledTooltip>
        <div style={classes.filterContainer}>
          <RotaButtonIcon onClick={() => setIsOpen(!isOpen)}>
            <Filter />
          </RotaButtonIcon>

          <FilterContent
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            schedule={schedule}
            setRoleIds={setRoleIds}
            setSortBy={setSortBy}
            sortBy={sortBy}
            setVenueIds={setVenueIds}
            setHomeVenueIds={setHomeVenueIds}
          />
        </div>
      </StyledTooltip>
    </>
  );
};

export default SearchInput;

import styled from "styled-components";

export const Center = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  width: 100vw;
  text-align: center;
  transform: translateY(-50%);
`;

export const Inline = styled.div`
  display: inline-block;
`;

import React, { useState } from "react";
import { last } from "lodash/fp";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Role } from "@teamrota/authlib";
import { DOCUMENT_TYPES, MAX_UPLOAD_SIZE_MB } from "~/src/consts";
import uploadFile from "~/src/services/upload-file";
import { errorModal } from "~/src/utils/errors";
import DocumentImage, { LoadingImage } from "~/src/components/document-image";
import deleteItem from "~/src/graphql/mutations/delete-item/delete-item.decorator";
import asyncConfirm from "~/src/utils/async-confirm";
import createOrUpdateMember from "../graphql/create-or-update-member";
import updateMemberPhoto from "../graphql/update-member-photo";
import { onPhotoHover, onStoreImage, onEditDecision } from "../reducer";
import {
  FilesContainer,
  Scrollable,
  Text,
  Title
} from "./member-documents.styles";
import { RotaSnackBar } from "@teamrota/rota-design";
import FileUploader from "./components/FileUploader";
import Dbs from "./components/dbs";

import ComplianceDocuments from "./components/ComplianceDocuments";
import useAuth from "~/src/auth/hooks/use-auth";

const MemberDocuments = ({
  memberPhoto,
  memberId,
  documents,
  refetchMember,
  deleteItem,
  updateMemberPhoto,
  isOnboardingMember,
  dbsCertificateNumber,
  dbsIssueDate,
  ...props
}) => {
  const [isSaving, setIsSaving] = useState(null);
  const [isUploadSizeError, setIsUploadSizeError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const auth = useAuth();
  const isAllowedToEditMemberCompliance = auth.hasRole(
    Role.MEMBER_COMPLIANCE_EDIT
  );

  const setRef = ({ varName, ref }) => {
    if (varName) {
      varName = ref;
    }
  };

  const handleUploadFile = async (type, id, subtype, issuedAt, expiresAt) => {
    setIsUploadSizeError(false);
    setIsSaving(type);
    try {
      let [selectedFile] = document.getElementById(id).files;
      const name = selectedFile.name;
      const sizeInMB = (selectedFile?.size / (1024 * 1024)).toFixed(2);
      if (parseInt(sizeInMB) <= MAX_UPLOAD_SIZE_MB) {
        await uploadFile({
          file: selectedFile,
          type,
          id: memberId,
          table: "members",
          fileName: name,
          subtype,
          issuedAt,
          expiresAt,
          setUploadPercentage
        });
        await refetchMember();
      } else {
        setIsUploadSizeError(true);
        setIsSaving(null);
        return null;
      }
    } catch (e) {
      alert("File upload error. Please try again");
      setIsSaving(null);

      errorModal(e);
    }
    setIsSaving(null);
  };

  const handleUpdateMemberPhoto = async newPhotoId => {
    try {
      await updateMemberPhoto(memberId, newPhotoId);
      await refetchMember();
    } catch (e) {
      errorModal(e);
    }
  };

  const handlePDFOpen = pdfUrl => (window.location = pdfUrl);

  /**
   * handles document deletion and specifies the table it should delete from
   * @param {number| string} uploadId documentId or uploadId
   * @param {string=} format document format can be png,jpg, pdf, application/pdf
   * @param {string=} typeName graphql typeName: this is to differentiate files from documents against files from uploads
   */
  const handleDeleteDocument = async (uploadId, format, typeName) => {
    const tableType = typeName === "s3DocumentType" ? "documents" : "uploads";

    try {
      if (
        await asyncConfirm("Are you sure you want to delete this?", {
          falseButtonText: "Cancel",
          confirmButtonText: "Delete"
        })
      ) {
        await deleteItem({
          variables: {
            type: tableType,
            id: uploadId
          }
        });
        setIsError(false);
        setSnackOpen(true);
        await refetchMember();
      }
    } catch (e) {
      setIsError(true);
      setSnackOpen(true);
      errorModal(e);
    }
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };

  const missingText = <Text type="missing">(Missing)</Text>;

  const { photos, complianceDocuments } =
    documents?.reduce(
      (acc, curr) => {
        if (!curr?.url && curr?.format !== "application/pdf") return acc;
        switch (curr?.type) {
          case DOCUMENT_TYPES.MEMBER_PHOTO:
            return { ...acc, photos: [...acc?.photos, curr] };
          case DOCUMENT_TYPES.RIGHT_TO_WORK:
          case DOCUMENT_TYPES.DBS:
          case DOCUMENT_TYPES.DBS_BASIC:
          case DOCUMENT_TYPES.DBS_ENHANCED:
          case DOCUMENT_TYPES.ENHANCED_CHILD_DBS:
          case DOCUMENT_TYPES.REFERENCE:
          case DOCUMENT_TYPES.REFERENCE_2:
          case DOCUMENT_TYPES.HEALTH_SAFETY_LEVEL_1:
          case DOCUMENT_TYPES.HEALTH_SAFETY_LEVEL_2:
          case DOCUMENT_TYPES.HEALTH_SAFETY_LEVEL_3:
          case DOCUMENT_TYPES.ALLERGEN_AWARENESS:
          case DOCUMENT_TYPES.FLOW_TRAINING:
          case DOCUMENT_TYPES.PROOF_OF_ADDRESS:
          case DOCUMENT_TYPES.NATIONAL_INSURANCE:
          case DOCUMENT_TYPES.COVID_VACCINE_PASSPORT:
          case DOCUMENT_TYPES.CV:
          case DOCUMENT_TYPES.TRADE_TEST:
          case DOCUMENT_TYPES.OTHER:
          case DOCUMENT_TYPES.EVOLVE_SIGNUP_FORM:
          case DOCUMENT_TYPES.EVOLVE_KEY_INFORMATION_DOCUMENT:
          case DOCUMENT_TYPES.EVOLVE_TERMS_OF_ENGAGEMENT:
          case DOCUMENT_TYPES.EVOLVE_MEDICAL_STATEMENT:
          case DOCUMENT_TYPES.EVOLVE_UNIFORM_STATEMENT:
          case DOCUMENT_TYPES.EVOLVE_GDPR_CONSENT:
          case DOCUMENT_TYPES.DEED_OF_NAME_CHANGE:
          case DOCUMENT_TYPES.MARRIAGE_CERTIFICATE:
          case DOCUMENT_TYPES.UNIVERSITY_ENROLMENT_LETTER:
          case DOCUMENT_TYPES.UNIVERSITY_TERM_DATES:
          case DOCUMENT_TYPES.HOME_OFFICE_LETTER:
          case DOCUMENT_TYPES.SPOUSAL_DOCUMENT:
          case DOCUMENT_TYPES.BIRTH_CERTIFICATE:
          case DOCUMENT_TYPES.FOOD_SAFETY_LEVEL_1:
          case DOCUMENT_TYPES.FOOD_SAFETY_LEVEL_2:
          case DOCUMENT_TYPES.FOOD_SAFETY_LEVEL_3:
          case DOCUMENT_TYPES.BOOKING_CONTRACT:
          case DOCUMENT_TYPES.MANUAL_HANDLING:
          case DOCUMENT_TYPES.COSHH:
          case DOCUMENT_TYPES.HACCP:
          case DOCUMENT_TYPES.FIRE_SAFETY:
          case DOCUMENT_TYPES.SLIPS_TRIPS_FALLS_AWARENESS:
          case DOCUMENT_TYPES.APPLICATION_FORM:
          case DOCUMENT_TYPES.BASIC_FIRST_AID:
          case DOCUMENT_TYPES.BUCCAL:
          case DOCUMENT_TYPES.CHILD_PROTECTION:
          case DOCUMENT_TYPES.CLEARANCE:
          case DOCUMENT_TYPES.CORU:
          case DOCUMENT_TYPES.CORU_PIN_CHECK:
          case DOCUMENT_TYPES.DRIVERS_LICENSE:
          case DOCUMENT_TYPES.EVIDENCE_OF_PPS:
          case DOCUMENT_TYPES.FIRE_SAFETY_AWARENESS_ONLINE:
          case DOCUMENT_TYPES.GARDA_VETTING_FW:
          case DOCUMENT_TYPES.GARDA_VETTING_NFW:
          case DOCUMENT_TYPES.GARDA_VETTING_APPLICATION_FORM:
          case DOCUMENT_TYPES.GDPR:
          case DOCUMENT_TYPES.HAND_HYGIENE:
          case DOCUMENT_TYPES.HEALTHIER_BUSINESS_FORM:
          case DOCUMENT_TYPES.HEARTSAVER_AED_CPR:
          case DOCUMENT_TYPES.INFECTION_PREVENTION_CONTROL:
          case DOCUMENT_TYPES.INTERVIEW_NOTES:
          case DOCUMENT_TYPES.IRISH_MEDICAL_COUNCIL:
          case DOCUMENT_TYPES.IRISH_MEDICAL_COUNCIL_PIN_CHECK:
          case DOCUMENT_TYPES.MAPA:
          case DOCUMENT_TYPES.MEDICATION_MANAGEMENT:
          case DOCUMENT_TYPES.NMBI_PIN_CHECK:
          case DOCUMENT_TYPES.NMBI_REGISTRATION:
          case DOCUMENT_TYPES.OCCUPATIONAL_FIRST_AID:
          case DOCUMENT_TYPES.OCCUPATIONAL_HEALTH:
          case DOCUMENT_TYPES.PASSPORT_PHOTO:
          case DOCUMENT_TYPES.PATIENT_MOVING_HANDLING:
          case DOCUMENT_TYPES.QC_NOTE:
          case DOCUMENT_TYPES.QUALIFICATIONS:
          case DOCUMENT_TYPES.REFERENCE_3:
          case DOCUMENT_TYPES.RISK_ASSESSMENT:
          case DOCUMENT_TYPES.SAFE_ADMINISTRATION_OF_MEDICATION:
          case DOCUMENT_TYPES.SAFEGUARDING_OF_VULNERABLE_ADULTS:
          case DOCUMENT_TYPES.SIGN_OFF:
          case DOCUMENT_TYPES.SKYPE_SCREENING_CALL:
          case DOCUMENT_TYPES.TCI:
          case DOCUMENT_TYPES.VACCINATIONS:
          case DOCUMENT_TYPES.VERIFICATION_OF_SERVICE:
            return {
              ...acc,
              complianceDocuments: [...acc?.complianceDocuments, curr]
            };

          case DOCUMENT_TYPES.ADDITIONAL_MEMBER_DOCUMENT:
            return { ...acc, other: [...acc.other, curr] };

          default:
            return acc;
        }
      },
      { photos: [], complianceDocuments: [], other: [] }
    ) || {};

  const isSamePhoto = (photo1, photo2) => {
    if (!photo1 || !photo2) return false;
    return last(photo1.split("/")) === last(photo2.split("/"));
  };

  return (
    <Scrollable>
      {isAllowedToEditMemberCompliance && (
        <Title>Member Photo {photos?.length === 0 ? missingText : ""}</Title>
      )}

      <FilesContainer isMargin={isAllowedToEditMemberCompliance}>
        {isAllowedToEditMemberCompliance && (
          <FileUploader
            type={DOCUMENT_TYPES.MEMBER_PHOTO}
            isOnboardingMember={isOnboardingMember}
            setRef={setRef}
            handleUploadFile={handleUploadFile}
          />
        )}
        {isSaving === DOCUMENT_TYPES.MEMBER_PHOTO && <LoadingImage isRound />}
        {photos?.map((item, index) => (
          <DocumentImage
            key={index}
            isActive={isSamePhoto(item.url, memberPhoto)}
            src={item.url}
            isRound
            onSelect={
              isAllowedToEditMemberCompliance &&
              !isSamePhoto(item.url, memberPhoto)
                ? () => handleUpdateMemberPhoto(item.id)
                : undefined
            }
            onDelete={
              isAllowedToEditMemberCompliance &&
              !isSamePhoto(item.url, memberPhoto)
                ? () => handleDeleteDocument(item.id)
                : undefined
            }
          />
        ))}
      </FilesContainer>
      <Title>DBS</Title>
      <Dbs
        certificateNumber={dbsCertificateNumber}
        issueDate={dbsIssueDate}
        memberId={memberId}
        isDisabled={!isAllowedToEditMemberCompliance}
      />
      <FilesContainer>
        <ComplianceDocuments
          uploadPercentage={uploadPercentage}
          setUploadPercentage={setUploadPercentage}
          handleUploadFile={handleUploadFile}
          complianceDocuments={complianceDocuments}
          handleDeleteDocument={handleDeleteDocument}
          handlePDFOpen={handlePDFOpen}
          isUploadSizeError={isUploadSizeError}
        />
      </FilesContainer>
      <RotaSnackBar
        onClose={handleSnackClose}
        snackOpen={snackOpen}
        severity={isError ? "error" : "success"}
        message={
          isError
            ? "Sorry. Something went wrong, try again"
            : "Successfully deleted"
        }
      />
    </Scrollable>
  );
};

const mapStateToProps = state => ({
  shouldShowActionDropDown: state.modalEditMember.onPrintHover,
  currentSnapShot: state.modalEditMember.currentSnapShot,
  inCaptureMode: state.modalEditMember.inCaptureMode
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onPhotoHover,
      onStoreImage,
      onEditDecision
    },
    dispatch
  );

export default createOrUpdateMember(
  updateMemberPhoto(
    deleteItem(connect(mapStateToProps, mapDispatchToProps)(MemberDocuments))
  )
);

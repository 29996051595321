import React from "react";
import SortBy from "~/src/containers/scheduler/FilterDropdown/SortBy";
import JobRole from "~/src/containers/scheduler/FilterDropdown/JobRole";
import HomeVenueFilter from "../FilterDropdown/HomeVenue";
import {
  StyledFiltersContainer,
  styles
} from "~/src/containers/scheduler/SearchInput/search-input.styles";

const FilterContent = ({
  schedule,
  setRoleIds,
  setSortBy,
  sortBy,
  setVenueIds,
  setHomeVenueIds,
  isOpen,
  setIsOpen
}) => {
  const filterModalRef = React.useRef(null);

  const handleClickOutside = e => {
    if (isOpen && !filterModalRef?.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <StyledFiltersContainer ref={filterModalRef} isOpen={isOpen}>
      <SortBy schedule={schedule} setSortBy={setSortBy} sortBy={sortBy} />
      <hr className={styles?.divider} />

      <JobRole schedule={schedule} setRoleIds={setRoleIds} />
      <hr className={styles?.divider} />

      <HomeVenueFilter
        setVenueIds={setVenueIds}
        setHomeVenueIds={setHomeVenueIds}
        schedule={schedule}
      />
    </StyledFiltersContainer>
  );
};

export default FilterContent;

import React, { useState } from "react";
import { MenuItem, TextField, Select, FormControl } from "@material-ui/core";
import { RotaButtonIcon, iconNew } from "@teamrota/rota-design";

import { countries } from "@teamrota/rota-common";

import getProfile from "~/src/graphql/queries/get-profile/get-profile-query.decorator";
import styles, { SectionTitle } from "./account-tab-content.styles";

const prioritisedCountries = [
  ...countries.filter(({ priority }) => priority),
  ...countries
];

const { ChevronUp, ChevronDown } = iconNew;

const InvoiceDetails = ({ state: account, dispatch }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOnChange = e => {
    dispatch({
      value: e?.target?.value,
      path: e?.target?.name
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <SectionTitle id="account-details">
        Invoice Details{" "}
        <RotaButtonIcon onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? <ChevronUp /> : <ChevronDown />}
        </RotaButtonIcon>
      </SectionTitle>

      {isExpanded && (
        <>
          <div id="form-row-2" style={styles.formRow}>
            <div id="form-column-3" style={styles.formColumn}>
              <span style={styles.InputPlaceholder} id="input-address-1">
                Address Line 1
              </span>
              <TextField
                variant="outlined"
                fullWidth
                name="account.invoiceAddress.houseNumber"
                value={account?.invoiceAddress?.houseNumber || ""}
                onChange={handleOnChange}
              />
            </div>

            <div id="form-column-4" style={styles.formColumn}>
              <span style={styles.InputPlaceholder} id="input-address-2">
                Address Line 2
              </span>
              <TextField
                variant="outlined"
                fullWidth
                name="account.invoiceAddress.streetName"
                value={account?.invoiceAddress?.streetName || ""}
                onChange={handleOnChange}
              />
            </div>
          </div>

          <div id="form-row-3" style={styles.formRow}>
            <div id="form-column-5" style={styles.formColumn}>
              <span style={styles.InputPlaceholder} id="input-town">
                Town/City
              </span>
              <TextField
                variant="outlined"
                fullWidth
                name="account.invoiceAddress.town"
                value={account?.invoiceAddress?.town || ""}
                onChange={handleOnChange}
              />
            </div>

            <div id="form-column-6" style={styles.formColumn}>
              <span style={styles.InputPlaceholder} id="input-county">
                County
              </span>
              <TextField
                variant="outlined"
                fullWidth
                name="account.invoiceAddress.county"
                value={account?.invoiceAddress?.county || ""}
                onChange={handleOnChange}
              />
            </div>
          </div>

          <div id="form-row-5" style={styles.formRow}>
            <div id="form-column-7" style={styles.formColumn}>
              <span style={styles.InputPlaceholder} id="input-country">
                Country
              </span>
              <FormControl>
                <Select
                  variant="outlined"
                  displayEmpty
                  open={open}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  inputProps={{ "aria-label": "Without label" }}
                  name="account.invoiceAddress.country"
                  value={account?.invoiceAddress?.country || ""}
                  onChange={handleOnChange}
                >
                  <MenuItem value="">
                    <em>Please Select</em>
                  </MenuItem>

                  {prioritisedCountries.map(({ name, code, flag }, i) => (
                    <MenuItem key={name + i} value={code}>
                      {flag} {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div id="form-column-8" style={styles.formColumn}>
              <span style={styles.InputPlaceholder} id="postcode">
                PostCode
              </span>
              <TextField
                variant="outlined"
                fullWidth
                name="account.invoiceAddress.postcode"
                value={account?.invoiceAddress?.postcode || ""}
                onChange={handleOnChange}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default getProfile(InvoiceDetails);

import React, { useState } from "react";

import {
  RotaButton,
  RotaButtonIcon,
  RotaDropdown
} from "@teamrota/rota-design";

import AccountsList from "~/src/containers/admin-dashboard/modules/accounts-list";

import {
  StyledMainHeading,
  StyledMainDetail,
  StyledMainHeaderWrapper,
  StyledWrapper,
  StyledFeaturesWrapper,
  StyledFeatureHeader
} from "../styles";

import { StyledButtonWrapper, StyledTableCell } from "./styles";
import { useRebookRoles } from "./graphql/use-rebook-roles";
import { useCreateRebookRole } from "./graphql/use-create-rebook-role";

import { RotaModal } from "@teamrota/rota-design";
import { useAccountRoles } from "./graphql/use-account-roles";

const AddDivisions = ({ setSnack }) => {
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [isCreateModalShow, setIsCreateModalShow] = useState(false);
  const [selectedAccountName, setSelectedAccountName] = useState();
  const [selectedRebookRoles, setSelectedRebookRoles] = useState({
    from: null,
    to: null
  });

  const { rebookRoles, loading, refetch } = useRebookRoles({
    accountId: selectedAccountId
  });

  const { accountRoles } = useAccountRoles({
    accountId: selectedAccountId
  });

  const accountRoleOptions = accountRoles.map(({ id, name }) => ({
    value: id,
    label: name
  }));

  const accountRoleOptionsWithoutMapping = accountRoleOptions.filter(
    ({ value }) => rebookRoles.every(({ roleId }) => roleId !== value)
  );

  const { createRebookRole } = useCreateRebookRole({
    accountId: selectedAccountId,
    onCompleted: () => setSnack({ message: "Success!", severity: "success" }),
    onError: () =>
      setSnack({ message: "Something Went Wrong.", severity: "error" })
  });

  const handleCreateRebookRole = async () => {
    await createRebookRole({
      roleId: selectedRebookRoles.from,
      roleIdMappedToAlternateRoleId: selectedRebookRoles.to
    });
    handleModalClose();
    refetch();
  };

  const handleModalClose = () => {
    setSelectedRebookRoles({
      from: null,
      to: null
    });
    setIsCreateModalShow(false);
  };

  return (
    <>
      <StyledMainHeaderWrapper>
        <StyledMainHeading>Add Rebook Roles</StyledMainHeading>
        <StyledMainDetail>
          Manage rebook roles for all accounts
        </StyledMainDetail>
      </StyledMainHeaderWrapper>
      <StyledWrapper>
        <AccountsList
          onSelect={(id, accountName) => {
            setSelectedAccountId(id);
            setSelectedAccountName(accountName);
          }}
          selectedAccountId={selectedAccountId}
          selectedAccountName={selectedAccountName}
        />
        {selectedAccountId && (
          <StyledFeaturesWrapper>
            <StyledFeatureHeader>
              <h3>{selectedAccountName} Rebook Roles</h3>
              <RotaButtonIcon
                icon="add"
                onClick={() => setIsCreateModalShow(true)}
              />
            </StyledFeatureHeader>
            {loading && <p>Loading...</p>}
            {!!rebookRoles.length && (
              <table>
                <tr>
                  <th>From</th>
                  <th>To</th>
                </tr>
                {rebookRoles.map(({ roleName, mappedToName }) => (
                  <tr>
                    <StyledTableCell>{roleName}</StyledTableCell>
                    <StyledTableCell>{mappedToName}</StyledTableCell>
                  </tr>
                ))}
              </table>
            )}
          </StyledFeaturesWrapper>
        )}
      </StyledWrapper>
      {isCreateModalShow && (
        <RotaModal
          isSmall
          onClose={handleModalClose}
          name={"Create Rebook Role"}
        >
          <div>From</div>
          <RotaDropdown
            value={selectedRebookRoles.from}
            placeholder="Select a role"
            options={accountRoleOptionsWithoutMapping}
            onChange={option => {
              setSelectedRebookRoles({
                ...selectedRebookRoles,
                from: option
              });
            }}
          />
          <div>To</div>
          <RotaDropdown
            value={selectedRebookRoles.to}
            placeholder="Select a role"
            options={accountRoleOptions}
            onChange={option => {
              setSelectedRebookRoles({
                ...selectedRebookRoles,
                to: option
              });
            }}
          />
          <StyledButtonWrapper>
            <RotaButton variant="secondary" onClick={handleModalClose}>
              Cancel
            </RotaButton>
            <RotaButton
              onClick={handleCreateRebookRole}
              disabled={!(selectedRebookRoles.from && selectedRebookRoles.to)}
            >
              Add
            </RotaButton>
          </StyledButtonWrapper>
        </RotaModal>
      )}
    </>
  );
};

export default AddDivisions;

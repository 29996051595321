import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";

const NavigationContext = createContext();

export const NavigationWatcher = ({ children }) => {
  const history = useHistory();

  const [isInternalNavigation, setIsInternalNavigation] = useState(false);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === "PUSH") {
        setIsInternalNavigation(true);
      } else {
        setIsInternalNavigation(false);
      }
    });

    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <NavigationContext.Provider value={{ isInternalNavigation }}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigationContext = () => useContext(NavigationContext);

import styled from "styled-components";
import { Map } from "react-leaflet";
import Icon from "~/src/components/icon";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const MapWrapper = styled(Map)`
  height: ${({ height }) => height || "200px"};
  width: ${({ width }) => width || "100%"};
  position: relative;
  z-index: 1;
  border-radius: ${STYLE_CONSTS.BORDER_RADIUS};
`;

// Position the icon where the default marker would appear
export const StyledIcon = styled(Icon)`
  transform: translate(-14px, -32px);
`;

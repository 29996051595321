import React from "react";
import { RotaButtonNew, RotaInput } from "@teamrota/rota-design";

import {
  StyledWrapButton,
  StyledButtonWrapper,
  StyledConfirmWrapper,
  StyledWrapConfirmation
} from "../styles";

const BottomModal = ({
  handleCancel,
  handleAdd,
  isConfirmed,
  memberName,
  mustConfirm,
  onChangeInput,
  disableOverride
}) => (
  <StyledButtonWrapper>
    {mustConfirm && (
      <StyledConfirmWrapper>
        <StyledWrapConfirmation>
          Confirm you understand this warning by typing the member's name below
        </StyledWrapConfirmation>
        <RotaInput
          isLabelTop
          placeholder={memberName}
          onChange={e => onChangeInput(e)}
        />
      </StyledConfirmWrapper>
    )}
    <StyledWrapButton>
      <RotaButtonNew onClick={handleCancel} variant="outlined">
        Cancel
      </RotaButtonNew>

      {!disableOverride && (
        <RotaButtonNew onClick={handleAdd} isDanger isDisabled={!isConfirmed}>
          Add member
        </RotaButtonNew>
      )}
    </StyledWrapButton>
  </StyledButtonWrapper>
);

export default BottomModal;

import { gql } from "@apollo/client";

export const UPDATE_OPTIONAL_FEATURES = gql`
  mutation(
    $accountId: ID!
    $scheduler: Boolean
    $providerScheduler: Boolean
    $isOverLimitWorkAllowed: Boolean
    $isShiftCancellingAllowed: Boolean
    $isComplianceExpiryEnabled: Boolean
    $isProviderPayRateOverride: Boolean
    $isPayCalculationOnCheckInOutTimes: Boolean
    $isSmsOptionAvailable: Boolean
  ) {
    updateOptionalFeatures(
      accountId: $accountId
      scheduler: $scheduler
      providerScheduler: $providerScheduler
      isOverLimitWorkAllowed: $isOverLimitWorkAllowed
      isShiftCancellingAllowed: $isShiftCancellingAllowed
      isComplianceExpiryEnabled: $isComplianceExpiryEnabled
      isProviderPayRateOverride: $isProviderPayRateOverride
      isPayCalculationOnCheckInOutTimes: $isPayCalculationOnCheckInOutTimes
      isSmsOptionAvailable: $isSmsOptionAvailable
    )
  }
`;

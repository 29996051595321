import { SUB_VENUE_CHECK_IN_OPTIONS } from "@teamrota/rota-common";

const schema = [
  {
    label: "Display name",
    name: "accountName"
  },
  {
    label: "Email",
    name: "contactEmail"
  },
  {
    label: "Phone",
    name: "contactPhone",
    component: "phone-number-input"
  },
  {
    label: "Website",
    name: "contactWeb"
  },
  {
    label: "Enable Request All",
    name: "isRequestAllEnabled",
    component: "switch-input"
  },
  {
    label: "Enable Rebooking",
    name: "isRebookingEnabled",
    component: "switch-input"
  },
  {
    label: "Enable Sleep Time",
    name: "isSleepTimeEnabled",
    component: "switch-input"
  },
  {
    label: "Subvenue Selection",
    name: "subvenueSelection",
    options: [
      {
        label: "Off",
        value: SUB_VENUE_CHECK_IN_OPTIONS.OFF
      },
      {
        label: "Member selects sub-venue on Check In",
        value: SUB_VENUE_CHECK_IN_OPTIONS.ON_CHECK_IN
      },
      {
        label: "Manager selects sub-venue on Check Out",
        value: SUB_VENUE_CHECK_IN_OPTIONS.ON_CHECK_OUT
      }
    ],
    component: "dropdown"
  },
  {
    label: "Enable Autoaccept",
    name: "isAutoAcceptEnabled",
    component: "switch-input",
    isSingleLine: true
  },
  {
    label: "Tags",
    name: "tags",
    component: "tags",
    isFieldArray: true
  },
  {
    label: "Notes",
    name: "notes",
    width: "100%",
    component: "multiline-text-input"
  },
  {
    label: "Assigned to",
    name: "ownerId",
    component: "dropdown"
  }
];

export default schema.map(field => ({
  ...field,
  placeholder: field.label
}));

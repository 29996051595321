import React from "react";

import { StyledPhotoWrapper } from "../styles";
import MemberPhoto from "~/src/components/member-photo";
import { getLabel } from "../../MemberCarousel/labels";

const Members = ({ member }) => {
  const label = getLabel(member);
  return (
    <div style={{ width: "200px" }}>
      <StyledPhotoWrapper>
        <MemberPhoto
          src={member?.photo}
          size="small"
          errorLabelColor={label?.color}
          errorLabel={label?.text}
        />
        <p>
          {member.firstName} {member.lastName}
        </p>
      </StyledPhotoWrapper>
    </div>
  );
};
export default Members;

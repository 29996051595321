import { RotaCheckbox } from "@teamrota/rota-design";
import React from "react";

import CurrencyInput from "~/src/components/form-field/inputs/currency-input";
import { Cell, Row } from "./style";
import { PayrollForm } from "./payroll-form";

const RoleList = ({
  roles,
  isEditing,
  updatedMemberRoles,
  isShowPayRateColumn,
  isShowPayTypeColumn,
  isShowPayScaleLevelColumn,
  setUpdatedMemberRoles,
  isUncalculatedRoles
}) => {
  const onToggleRole = ({
    id: roleId,
    name,
    defaultPayRate,
    isDayRate,
    payScaleLevel,
    currentCreditsNumber,
    incrementDate
  }) => {
    const isChecked = updatedMemberRoles.find(
      selectedRole => selectedRole.roleId === roleId
    );

    if (isChecked) {
      const updatedRoles = updatedMemberRoles.filter(
        selectedRole => selectedRole.roleId !== roleId
      );

      setUpdatedMemberRoles(updatedRoles);
    } else {
      setUpdatedMemberRoles([
        ...(updatedMemberRoles || []),
        {
          roleId,
          name,
          payRateOverride: defaultPayRate,
          isDayRate,
          payScaleLevel: payScaleLevel ?? null,
          currentCreditsNumber: currentCreditsNumber ?? null,
          incrementDate: incrementDate ?? null
        }
      ]);
    }
  };

  const onChangeMemberRoleRate = ({ payRateOverride, roleId }) => {
    const updatedRoles = updatedMemberRoles.map(role => {
      if (role.roleId === roleId) {
        return { ...role, payRateOverride };
      }

      return role;
    });

    setUpdatedMemberRoles(updatedRoles);
  };

  return (
    <>
      {roles.map(role => {
        const matchingSelectedRole = updatedMemberRoles.find(
          selectedRole => selectedRole.roleId === role.id
        );

        return (
          <>
            <Row key={role.id}>
              <Cell alignLeft>
                {isEditing ? (
                  <RotaCheckbox
                    label={role.name}
                    isChecked={!!matchingSelectedRole}
                    onClick={() => onToggleRole(role)}
                  />
                ) : (
                  role.name
                )}
              </Cell>

              {isShowPayTypeColumn && (
                <Cell>{role.isDayRate ? "Daily" : "Hourly"}</Cell>
              )}

              {isShowPayRateColumn && (
                <Cell center width="120px">
                  <CurrencyInput
                    hasNoErrorText
                    isAlignCenter
                    input={{
                      value: role?.payRateOverride || role?.defaultPayRate,
                      onChange: payRateOverride =>
                        onChangeMemberRoleRate({
                          payRateOverride,
                          roleId: role.id
                        })
                    }}
                    meta={{}}
                    readOnly={
                      !isEditing || (isEditing && !matchingSelectedRole)
                    }
                  />
                </Cell>
              )}

              {isShowPayScaleLevelColumn && <Cell>{role?.payScaleLevel}</Cell>}
            </Row>

            <Row>
              {isUncalculatedRoles && isEditing && (
                <Cell alignLeft>
                  <PayrollForm
                    isChecked={matchingSelectedRole}
                    role={role}
                    setSelectedRoles={setUpdatedMemberRoles}
                  />
                </Cell>
              )}
            </Row>
          </>
        );
      })}
    </>
  );
};

export default RoleList;

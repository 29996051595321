import styled from "styled-components";

import {
  COLORS,
  STYLE_CONSTS,
  MEMBER_STATES_COLORS
} from "~/src/styles/config.style";

export const Wrapper = styled.div`
  margin-top: ${STYLE_CONSTS.UNIT_1};
  margin-left: ${STYLE_CONSTS.UNIT_HALF};
`;

export const State = styled.div`
  position: relative;
  display: inline-block;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: ${({ state }) => MEMBER_STATES_COLORS[state]};
  margin-right: ${STYLE_CONSTS.UNIT_1};
  cursor: ${({ readOnly }) => (readOnly ? "default" : "pointer")};
  &:before {
    content: "";
    display: ${({ isActive }) => (isActive ? "block" : "none")};
    position: absolute;
    border: 1px solid ${COLORS.COOL_GREY};
    height: 20px;
    width: 20px;
    border-radius: 50%;
    left: -3px;
    top: -3px;
  }
`;

export const Text = styled.div`
  color: ${({ state }) => MEMBER_STATES_COLORS[state]};
`;

export const ModalTitle = styled.h2`
  margin-top: 0;
  text-align: center;
`;

export const ModalText = styled.p`
  text-align: center;
  color: ${COLORS.COOL_GREY};
`;

export const Action = styled.span`
  display: inline-block;
  color: ${({ newState }) => MEMBER_STATES_COLORS[newState]};
`;

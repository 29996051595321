import React from "react";

import styled from "styled-components";
import Icon from "~/src/components/icon";
import { Link } from "react-router-dom";
import { STYLE_CONSTS } from "~/src/styles/config.style";

// As per design.
export const ROTA_LOGO_HEIGHT = 45;
const MENU_ITEM_HEIGHT = 80;
const LOGO_Y = (parseInt(STYLE_CONSTS.MENU_SIZE, 10) - ROTA_LOGO_HEIGHT) / 2;

export const MenuNavWrapper = styled.div`
  background-color: ${({ theme }) => theme.background.secondary};
  height: 100%;
`;

export const LogoWrapper = styled.div`
  height: ${STYLE_CONSTS.MENU_SIZE};
  text-align: center;
  margin-bottom: ${STYLE_CONSTS.UNIT_3};
`;

export const Logo = styled(Icon)`
  transform: translateY(${LOGO_Y}px);
  display: inline-block;
`;

export const LogoWhite = styled.img`
  transform: translateY(${LOGO_Y}px);
  display: inline-block;
  padding: 2px;
`;

export const IconWrapper = styled.div`
  transition: ${STYLE_CONSTS.STANDARD_TRANSITON};
  text-align: center;
  cursor: pointer;
  height: ${MENU_ITEM_HEIGHT}px;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.3)};
  &:hover {
    opacity: 1;
  }
  &:hover > #side-menu-option-name {
    opacity: 1;
  }
`;

export const NoUnderline = styled(props => <Link {...props} />)`
  text-decoration: none;
`;

export const OptionName = styled.div`
  color: ${({ theme }) => theme.white};
  font-size: ${STYLE_CONSTS.FONT_XSMALL};
  padding: 0 ${STYLE_CONSTS.UNIT_2};
`;

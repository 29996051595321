import React from "react";
import getOr from "lodash/fp/getOr";
import truncate from "lodash/fp/truncate";
import MemberPhoto from "~/src/components/member-photo";
import {
  MemberName,
  MemberContainer,
  MemberWrapper
} from "../notifications.styles";

export default props => {
  const members = getOr([], "members", props);

  return (
    <MemberContainer flexWrap="wrap">
      {members.map((member, i) => (
        <MemberWrapper key={i} onClick={() => props.onUpdateRecipients(member)}>
          <MemberPhoto src={member.photo} />
          <MemberName>
            {truncate({ length: 15 }, `${member.firstName} ${member.lastName}`)}
          </MemberName>
        </MemberWrapper>
      ))}
    </MemberContainer>
  );
};

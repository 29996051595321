import { gql } from "@apollo/client";

export const GET_TAGS = gql`
  query getAccountTags($accountId: ID!) {
    tags(accountId: $accountId) {
      id
      name
    }
  }
`;

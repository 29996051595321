import styled from "styled-components";
import { UPLOAD_STATUSES } from "..";

const getColor = status => {
  switch (status) {
    case UPLOAD_STATUSES.DEFAULT:
      return "#ffecd3";
    case UPLOAD_STATUSES.LOADING:
      return "#ffecd3";
    case UPLOAD_STATUSES.SUCCESS:
      return "#DCF1E5";
    case UPLOAD_STATUSES.ERROR:
      return "#FFD3D3";
    default:
      return "#ffecd3";
  }
};
export const Styled_UploadStatus = styled.div`
  background-color: ${({ status }) => getColor(status)};
  border: 1px dashed ${({ theme }) => theme.text.primary};
  height: 240px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  .wrapper {
    display: flex;
    width: 70%;
    align-items: center;
    justify-content: center;

    .document_img {
      width: 24px;
      margin-right: 16px;
    }

    .status_message {
      font-size: 14px;
      font-weight: 500;
      margin-top: 8px;
    }

    .file_name {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 4px;
      max-width: 80%;
    }

    .file_size {
      font-size: 14px;
      font-weight: 500;
      color: #9c9c9c;
    }

    .progress_wrapper {
      width: 100%;

      .MuiLinearProgress-barColorPrimary {
        background-color: #ff9300;
      }

      .MuiLinearProgress-colorPrimary {
        background-color: #ffe1b9;
      }

      .MuiLinearProgress-root {
        height: 9px;
        border-radius: 20px;
      }
    }

    .success_wrapper {
      width: 100%;
      display: flex;

      .left_block {
        width: 100%;
        margin-right: 32px;
      }

      .bar {
        width: 100%;
        height: 9px;
        border-radius: 20px;
        background-color: #33ad64;
      }
    }

    .error_wrapper {
      width: 100%;

      .bar {
        width: 100%;
        height: 9px;
        border-radius: 20px;
        background-color: #f60000;
      }

      .error {
        color: #f60000;
      }
    }
  }
`;

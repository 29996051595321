import { createReducer, createAction } from "~/src/utils";

const DEFAULT_STATE = {
  onPrintHover: false,
  currentSnapShot: null,
  inCaptureMode: true,
  annualSalary: 0,
  weeklyContractedHours: 0,
  isInsideIR35: false,
  member: null
};

const UPDATE_MEMBER = "view-edit/UPDATE_MEMBER";
export const updateMember = createAction(UPDATE_MEMBER);

const UPDATE_MEMBER_TYPE = "view-edit/UPDATE_MEMBER_TYPE";
export const updateMemberType = createAction(UPDATE_MEMBER_TYPE);

const UPDATE_ANNUAL_SALARY = "view-edit/UPDATE_ANNUAL_SALARY";
export const updateAnnualSalary = createAction(UPDATE_ANNUAL_SALARY);

const UPDATE_WEEKLY_CONTRACTED_HOURS =
  "view-edit/UPDATE_WEEKLY_CONTRACTED_HOURS";
export const updateWeeklyContractedHours = createAction(
  UPDATE_WEEKLY_CONTRACTED_HOURS
);

const UPDATE_IS_INSIDE_IR35 = "view-edit/UPDATE_IS_INSIDE_IR35";
export const updateIsInsideIR35 = createAction(UPDATE_IS_INSIDE_IR35);

// Set boolean based on hover over upload
const ON_HOVER = "view-edit/ON_HOVER";
export const onPhotoHover = createAction(ON_HOVER);

// Current Base64 binary of snapshot
const ON_STORE_IMAGE = "view-edit/ON_STORE_IMAGE";
export const onStoreImage = createAction(ON_STORE_IMAGE);

// Reset store on user action
const ON_EDIT_DECISION = "view-edit/ON_EDIT_DECISION";
export const onEditDecision = createAction(ON_EDIT_DECISION);

const handlers = {
  [UPDATE_MEMBER]: (state, { payload }) => ({
    ...state,
    member: payload
  }),
  [UPDATE_MEMBER_TYPE]: (state, { payload }) => ({
    ...state,
    memberType: payload
  }),
  [UPDATE_ANNUAL_SALARY]: (state, { payload }) => ({
    ...state,
    annualSalary: payload
  }),
  [UPDATE_WEEKLY_CONTRACTED_HOURS]: (state, { payload }) => ({
    ...state,
    weeklyContractedHours: payload
  }),
  [UPDATE_IS_INSIDE_IR35]: (state, { payload }) => ({
    ...state,
    isInsideIR35: payload
  }),
  [ON_HOVER]: (state, { payload }) => ({
    ...state,
    onPrintHover: payload
  }),
  [ON_STORE_IMAGE]: (state, { payload }) => ({
    ...state,
    currentSnapShot: payload,
    inCaptureMode: false
  }),
  [ON_EDIT_DECISION]: (state, { payload }) => ({
    ...state,
    currentSnapShot: null,
    inCaptureMode: true,
    ...payload.reset
  })
};

export default createReducer(handlers, DEFAULT_STATE);

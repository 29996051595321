import styled from "styled-components";

export const OutputWrapper = styled.div`
  margin: 16px 0;
  display: flex;
  flex-direction: column;

  & table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    font-size: 130%;
    font-weight: 400;

    & th {
      font-size: 80%;
      border: solid 1px ${({ theme }) => theme.neutral.light};
      background-color: ${({ theme }) => theme.neutral.lighter};
      padding: 10px 5px;
    }

    & td {
      font-size: 80%;
      border: solid 1px ${({ theme }) => theme.neutral.light};
      padding: 10px 5px;
    }

    & td:nth-child(1) {
      text-align: center;
      width: 8%;
      font-size: 70%;
    }

    & td:nth-child(2) {
      text-align: center;
      width: 6%;
      font-size: 70%;
    }

    & td:nth-child(3) {
      text-align: center;
      width: 6%;
      font-size: 70%;
    }

    & td:nth-child(4) {
      text-align: center;
      width: 8%;
      font-size: 70%;
    }

    & td:nth-child(5) {
      text-align: center;
      width: 10%;
      font-size: 70%;
    }

    & td:nth-child(6) {
      text-align: center;
      width: 14%;
      font-size: 70%;
    }

    & td:nth-child(7) {
      text-align: right;
      width: 8%;
      font-size: 70%;
    }

    & td:nth-child(8) {
      text-align: right;
      width: 8%;
      font-size: 70%;
    }

    & td:nth-child(9) {
      text-align: center;
      width: 14%;
      font-size: 70%;
    }

    & td:nth-child(10) {
      text-align: right;
      width: 8%;
      font-size: 70%;
    }

    & td:nth-child(11) {
      text-align: right;
      width: 8%;
      font-size: 70%;
    }

    & tr:last-child {
      & td {
        font-weight: 750;
        background-color: ${({ theme }) => theme.neutral.lighter};
        padding: 30px 5px;
      }
    }
  }
`;

export const LinkWrapper = styled.div`
  margin: 20px 0;

  & a {
    padding-right: 10px;
  }
`;

import React from "react";
import PhoneInput from "react-phone-number-input/input";
import { StyledInputWrapper } from "./index.styles";
import InputWrapper from "../input-wrapper";
import { getOr } from "lodash/fp";

const PhoneNumberInput = props => {
  const { meta } = props;

  const handleChange = value => {
    props.input.onChange(value);
  };
  return (
    <StyledInputWrapper>
      <InputWrapper
        {...props}
        error={meta?.submitFailed && meta?.error}
        isSingleLine
      >
        <PhoneInput
          value={getOr("", "input.value", props)}
          onChange={handleChange}
          maxLength="25"
          className="fancyInput"
          placeholder={!props.readOnly ? props.placeholder : ""}
          international
          disabled={props.readOnly}
        />
      </InputWrapper>
    </StyledInputWrapper>
  );
};

export default PhoneNumberInput;

import React from "react";
import Icon from "~/src/components/icon";
import { ExitButtonWrapper } from "./button.styles";

export default function ExitButton(props) {
  return (
    <ExitButtonWrapper
      isIconOnly
      onClick={props.onClick}
      className={props.className}
      isGrey={props.isGrey}
      isLarge={props.isLarge}
      isOutline={props.isOutline}
      isSmall={props.isSmall}
      isXSmall={props.isXSmall}
      isGreen={props.isGreen}
      isRed={props.isRed}
    >
      <Icon
        name={Icon.names.EXIT}
        size={props.size}
        color={Icon.colors.WHITE}
      />
    </ExitButtonWrapper>
  );
}

import React from "react";
import InputRange from "react-input-range";
import InputWrapper from "../input-wrapper";
import { SliderWrapper } from "./index.styles";

const Slider = props => (
  <InputWrapper {...props}>
    <SliderWrapper>
      <InputRange
        step={props.step}
        disabled={props.readOnly}
        formatLabel={props.labelFormat}
        minValue={props.min}
        maxValue={props.max}
        value={props.input.value}
        onChange={props.input.onChange}
      />
    </SliderWrapper>
  </InputWrapper>
);

export default Slider;

import { gql, useMutation } from "@apollo/client";

const RECALCULATE_PAY_AND_CHARGE = gql`
  mutation($id: ID!) {
    recalculatePayAndCharge(id: $id)
  }
`;

export const useRecalculatePayAndCharge = () => {
  const [recalculate, { loading, error }] = useMutation(
    RECALCULATE_PAY_AND_CHARGE,
    {
      refetchQueries: ["payrollHistory"]
    }
  );

  const recalculatePayAndCharge = async ({ id }: { id: any }) => {
    await recalculate({
      variables: {
        id
      }
    });
  };

  return { recalculatePayAndCharge, loading, error };
};

import { RotaButtonNew } from "@teamrota/rota-design";
import styled from "styled-components";

export const Heading = styled.p`
  font-weight: bold;
  font-size: 16px;
  padding: 10px 0px;
`;

export const SubText = styled.p`
  weight: 400;
  font-size: 13px;
  color: ${({ theme }) => theme.neutral.main};
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const Label = styled.label`
  margin-right: 20px;
`;

export const SaveButton = styled(RotaButtonNew)`
  float: right;
  margin-top: 200px;
`;

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import AccountRoleFragment from "~/src/graphql/fragments/account-role";
import { handleErrors } from "~/src/utils/errors";

const GET_FILTER_SUGGESTIONS = gql`
  ${AccountRoleFragment}

  query getFilterSuggestions($searchText: String) {
    account {
      id
      roles {
        ...AccountRoleFragment
      }
      serviceAreas {
        id
        name
      }
      connections(
        accountType: provider
        limit: 6
        offset: 0
        searchText: $searchText
      ) {
        data {
          targetAccount {
            id
            accountName
          }
        }
      }
    }
  }
`;

export default graphql(GET_FILTER_SUGGESTIONS, {
  options: props => ({
    variables: {
      searchText: props.partnerQuery
    }
  }),
  props: handleErrors(({ data: { account } }) => ({
    roles: account?.roles ?? [],
    serviceAreas: (account?.serviceAreas ?? []).map(area => ({
      ...area,
      label: area.name
    })),
    partners: (account?.connections?.data ?? []).map(({ targetAccount }) => ({
      id: targetAccount.id,
      name: targetAccount.accountName
    }))
  }))
});

import React, { memo, useEffect, useState } from "react";

import useAuth from "~/src/auth/hooks/use-auth";
import { getAgent } from "~/src/auth/agent";

import {
  UserDropDown,
  UserNameLink,
  Username,
  StyledPhoto,
  NavTray,
  TrayLink
} from "./index.styles";

const contactUsUrl = "https://help.rota.com/en/knowledge/kb-tickets/new";
const userGuideUrl = "https://userguide.rota.com";

const getName = info => `${info?.first_name || ""} ${info?.last_name || ""}`;

export default memo(() => {
  const auth = useAuth();

  const [username, setUsername] = useState("");
  const [photo, setPhoto] = useState("");

  useEffect(() => {
    (async () => {
      const agent = await getAgent();

      let username = getName(auth.info);
      if (agent) {
        const agentName = getName(agent.profile);
        username = `${agentName} (agent) logged in as ${username}`;
      }

      setUsername(username);
      setPhoto(auth.info?.photo || "");
    })();
  }, auth.deps);

  return (
    <UserDropDown>
      <UserNameLink to="/settings">
        <Username>{username || ""}</Username>
        <StyledPhoto isInline size="small" src={photo} />
      </UserNameLink>
      <NavTray id="nav-tray-show-hide">
        <TrayLink to={{ pathname: userGuideUrl }} target="_blank">
          User Guide
        </TrayLink>
        <TrayLink to={{ pathname: contactUsUrl }} target="_blank">
          Contact Us
        </TrayLink>
        <TrayLink to="/change-password">Change Password</TrayLink>
        <TrayLink to="/logout">Log Out</TrayLink>
      </NavTray>
    </UserDropDown>
  );
});

import React, { useState, useEffect } from "react";
import { flow } from "lodash/fp";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import getOr from "lodash/fp/getOr";
import getProfile from "~/src/graphql/queries/get-profile/get-profile-query.decorator";
import PageHeader from "~/src/components/page-header";
import PoolConnections from "~/src/components/pool-connections";
import PageContainer from "~/src/components/page-container";
import { errorModal } from "~/src/utils/errors";
import { POOL_LIMITS } from "~/src/consts";
import { POOL_TYPES } from "@teamrota/rota-common";
import asyncConfirm from "~/src/utils/async-confirm";
import { Column, Row } from "~/src/components/grid";
import { PoolConnectionsWrapper } from "./pools.styles";
import MemberModal from "./components/member-modal";
import Panel from "./components/panel";
import GroupPanel from "./components/panel-group";
import { CREATE_UPDATE_RELATIONSHIP } from "./graphql/create-update-relationship";
import addGroupMember from "../../graphql/mutations/groups/add-group-member.decorator";
import * as actions from "./reducer";
import { useMutation, useQuery } from "@apollo/client";
import GET_MEMBER_POOLS from "../../graphql/queries/get-pool/get-pool.query";

const Pools = props => {
  const [currentGroup, setCurrentGroup] = useState(0);

  const [createOrUpdateRelationship] = useMutation(CREATE_UPDATE_RELATIONSHIP);

  const {
    data: favouritedMembersData,
    loading: isFavouritedLoading,
    refetch: refetchFavourited,
    fetchMore: fetchMoreFavourited
  } = useQuery(GET_MEMBER_POOLS, {
    fetchPolicy: "network-only",
    variables: {
      offset: 0,
      limit: POOL_LIMITS.SMALL,
      searchText: props.searchText[POOL_TYPES.FAVOURITED],
      poolType: POOL_TYPES.FAVOURITED,
      targetAccountId: props.selectedConnectionId
    }
  });

  const favouritedMembers =
    favouritedMembersData?.account?.memberPools?.data || [];
  const favouritedMembersTotal =
    favouritedMembersData?.account?.memberPools?.totalResults || 0;

  const {
    data: recommendedMembersData,
    loading: isRecommendedLoading,
    refetch: refetchRecommended,
    fetchMore: fetchMoreRecommended
  } = useQuery(GET_MEMBER_POOLS, {
    fetchPolicy: "network-only",
    variables: {
      offset: 0,
      limit: POOL_LIMITS.LARGE,
      searchText: props.searchText[POOL_TYPES.RECOMMENDED],
      poolType: POOL_TYPES.RECOMMENDED,
      targetAccountId: props.selectedConnectionId
    }
  });

  const recommendedMembers =
    recommendedMembersData?.account?.memberPools?.data || [];
  const recommendedMembersTotal =
    recommendedMembersData?.account?.memberPools?.totalResults || 0;

  const {
    data: blacklistedMembersData,
    loading: isBlacklistedLoading,
    refetch: refetchBlacklisted,
    fetchMore: fetchMoreBlacklisted
  } = useQuery(GET_MEMBER_POOLS, {
    fetchPolicy: "network-only",
    variables: {
      offset: 0,
      limit: POOL_LIMITS.SMALL,
      searchText: props.searchText[POOL_TYPES.BLACKLISTED],
      poolType: POOL_TYPES.BLACKLISTED,
      targetAccountId: props.selectedConnectionId
    }
  });

  const blacklistedMembers =
    blacklistedMembersData?.account?.memberPools?.data || [];
  const blacklistedMembersTotal =
    blacklistedMembersData?.account?.memberPools?.totalResults || 0;

  useEffect(() => {
    if (
      !props.selectedConnectionId &&
      props?.user &&
      props?.user.account.requesterConnections.data[0]
    ) {
      props.selectConnection(
        getOr(
          null,
          "user.account.requesterConnections.data[0].sourceAccount.id",
          props
        )
      );
    }
  }, [props]);

  const handleDropMember = async (targetPoolType, member) => {
    if (targetPoolType === POOL_TYPES.BLACKLISTED) {
      const totalUpcoming = member?.bookingsCount?.upcomingAccepted || 0;

      const confirmMessage = totalUpcoming
        ? `This member is accepted onto ${totalUpcoming} upcoming shifts. By making this member unsuitable, they will be removed from any future shifts you have booked them onto.`
        : "This member has no more upcoming shifts with you.";
      const result = await asyncConfirm(
        "This member will not be coming back.",
        {
          subText: confirmMessage,
          confirmButtonText: "Confirm",
          isConfirmButtonGreen: true
        }
      );

      if (!result) return;
    }

    try {
      await createOrUpdateRelationship({
        variables: {
          memberId: member.id,
          poolType: targetPoolType
        }
      });
      switch (targetPoolType) {
        case POOL_TYPES.FAVOURITED:
          await refetchFavourited();
          break;
        case POOL_TYPES.RECOMMENDED:
          await refetchRecommended();
          break;
        case POOL_TYPES.BLACKLISTED:
          await refetchBlacklisted();
      }
    } catch (e) {
      errorModal(e);
    }
  };

  const handleAddMemberToGroup = async member => {
    try {
      await props.addGroupMember({
        memberId: parseInt(member.id, 10),
        groupId: parseInt(currentGroup, 10)
      });

      // forces group member update
      setCurrentGroup(currentGroup);
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  };

  const handleCurrentGroup = groupId => {
    setCurrentGroup(groupId);
  };

  const connections = getOr(
    [],
    "user.account.requesterConnections.data",
    props
  );

  return (
    <div>
      <PageHeader
        title="Pools"
        subtext="Customise your pool here to help you build a pool of your favourite staff"
      />
      <PageContainer>
        {props.selectedMemberId && (
          <MemberModal
            isOpen={!!props.selectedMemberId}
            onClose={props.hideMember}
            memberId={props.selectedMemberId}
            sourceAccountId={props?.user.account.id}
          />
        )}

        <PoolConnectionsWrapper>
          <PoolConnections
            overidePoolWrapperStyle
            connections={connections}
            selectedConnectionId={props.selectedConnectionId}
            onSelectConnection={id => props.selectConnection(id)}
          />
        </PoolConnectionsWrapper>
        <Row>
          <Column small={1} medium={1} large={1}>
            <Panel
              size="small"
              title="Favourites"
              isLoading={isFavouritedLoading}
              poolType={POOL_TYPES.FAVOURITED}
              description="Favourites will be the first people to see your shifts."
              staff={favouritedMembers}
              placeholderTitle="Start adding favourites!"
              placeholderDescription="You can drag people from the Recommended into here to start your pool"
              onLoadMore={() => {
                fetchMoreFavourited({
                  variables: {
                    offset: favouritedMembers?.length || 0,
                    limit: POOL_LIMITS.SMALL
                  },
                  updateQuery: (prevResult, { fetchMoreResult }) => {
                    fetchMoreResult.account.memberPools.data = [
                      ...prevResult.account.memberPools.data,
                      ...fetchMoreResult.account.memberPools.data
                    ];
                    return fetchMoreResult;
                  }
                });
              }}
              totalResults={favouritedMembersTotal}
              searchText={props.searchText[POOL_TYPES.FAVOURITED]}
              onSearchChange={searchText =>
                props.search(POOL_TYPES.FAVOURITED, searchText)
              }
              onShowMemberDetails={id => props.showMember(id)}
              onDropMember={member =>
                handleDropMember(POOL_TYPES.FAVOURITED, member)
              }
              dropMessage="Drop the member here to add them to your Favourites"
            />

            <GroupPanel
              selectedConnectionId={props.selectedConnectionId}
              onDropMember={member => handleAddMemberToGroup(member)}
              onShowMemberDetails={id => props.showMember(id)}
              onChangeCurrentGroup={groupId => handleCurrentGroup(groupId)}
              currentGroup={currentGroup}
            />

            <Panel
              size="medium"
              title="Recommended"
              isLoading={isRecommendedLoading}
              poolType={POOL_TYPES.RECOMMENDED}
              description="Drag and drop Members to move them between the sections. You can edit these at any time."
              staff={recommendedMembers}
              placeholderDescription="You have no Recommended members in your pool"
              onLoadMore={() => {
                fetchMoreRecommended({
                  variables: {
                    offset: recommendedMembers?.length || 0,
                    limit: POOL_LIMITS.LARGE
                  },
                  updateQuery: (prevResult, { fetchMoreResult }) => {
                    fetchMoreResult.account.memberPools.data = [
                      ...prevResult.account.memberPools.data,
                      ...fetchMoreResult.account.memberPools.data
                    ];
                    return fetchMoreResult;
                  }
                });
              }}
              totalResults={recommendedMembersTotal}
              searchText={props.searchText[POOL_TYPES.RECOMMENDED]}
              onSearchChange={searchText =>
                props.search(POOL_TYPES.RECOMMENDED, searchText)
              }
              onShowMemberDetails={id => props.showMember(id)}
              onDropMember={member =>
                handleDropMember(POOL_TYPES.RECOMMENDED, member)
              }
              dropMessage="Drop the member here to add them to your recommended pool"
            />

            <Panel
              size="small"
              title="Unsuitable"
              isLoading={isBlacklistedLoading}
              poolType={POOL_TYPES.BLACKLISTED}
              description="You will not see these Members on your shifts. You can make them unsuitable by rating 3 or less  or dragging them into this section."
              staff={blacklistedMembers}
              placeholderDescription="You can drag people from the Recommended or Favourites into here"
              onLoadMore={() => {
                fetchMoreBlacklisted({
                  variables: {
                    offset: blacklistedMembers?.length || 0,
                    limit: POOL_LIMITS.SMALL
                  },
                  updateQuery: (prevResult, { fetchMoreResult }) => {
                    fetchMoreResult.account.memberPools.data = [
                      ...prevResult.account.memberPools.data,
                      ...fetchMoreResult.account.memberPools.data
                    ];
                    return fetchMoreResult;
                  }
                });
              }}
              totalResults={blacklistedMembersTotal}
              searchText={props.searchText[POOL_TYPES.BLACKLISTED]}
              onSearchChange={searchText =>
                props.search(POOL_TYPES.BLACKLISTED, searchText)
              }
              onShowMemberDetails={id => props.showMember(id)}
              onDropMember={member =>
                handleDropMember(POOL_TYPES.BLACKLISTED, member)
              }
              dropMessage="Drop the member here to add them to your unsuitable pool"
              isCollapsed
            />
          </Column>
        </Row>
      </PageContainer>
    </div>
  );
};

const mapStateToProps = ({ pools }) => ({
  selectedMemberId: pools.selectedMemberId,
  selectedConnectionId: pools.selectedConnectionId,
  searchText: pools.searchText
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMember: actions.showMemberDetails,
      hideMember: actions.hideMemberDetails,
      selectConnection: actions.updateSelectedConnection,
      search: actions.updateSearch
    },
    dispatch
  );

export default flow(
  getProfile,
  addGroupMember,
  connect(mapStateToProps, mapDispatchToProps)
)(Pools);

import React from "react";
import moment from "moment-timezone";
import { AVAILABILITY_TYPES } from "~/src/consts";
import {
  Container,
  Badge,
  BadgeLabel,
  AvailableTime,
  Tooltip
} from "./available-member-badge.styles";

const AvailableMemberBadge = ({
  availability: { startsAt, endsAt, shiftType },
  isDailyView,
  isWeeklyView,
  isNightShift,
  isCurrentDayBooking,
  isSameShiftType,
  onClick
}) => {
  const avalableTime =
    moment(startsAt).format("HH:mm") + " - " + moment(endsAt).format("HH:mm");

  const isShowAvailableTime =
    !isSameShiftType || (!isCurrentDayBooking && isSameShiftType);

  return (
    <>
      <Container onClick={() => onClick(startsAt)}>
        <Badge>
          <BadgeLabel>A</BadgeLabel>
        </Badge>
        {isWeeklyView && (
          <Tooltip
            tooltipText={`Available for ${
              shiftType === AVAILABILITY_TYPES.ALL
                ? "all day"
                : shiftType.toLowerCase()
            } shift (${avalableTime})`}
          />
        )}
        {isDailyView && isCurrentDayBooking && isSameShiftType && (
          <Tooltip tooltipText={avalableTime} />
        )}
        {isDailyView && isShowAvailableTime && (
          <AvailableTime isNightShift={isNightShift}>
            {avalableTime}
          </AvailableTime>
        )}
      </Container>
    </>
  );
};

export default AvailableMemberBadge;

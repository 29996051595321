import { get, getOr } from "lodash/fp";

export const CUSTOM_UNIFORM_ID = "mock-id-custom-uniform";

const getUniformTemplateId = (uniformTemplates, identifier) => {
  const template = uniformTemplates.find(t => t.identifier === identifier);
  if (template) return template.id;
  return CUSTOM_UNIFORM_ID;
};

export const getValuesFromProps = props => ({
  shiftId: get("shift.id", props),
  shiftGroupId: get("shift.shiftGroupId", props),
  numberRequested: get("shift.numberRequested", props),
  uniformTemplateId: getUniformTemplateId(
    getOr([], "uniformTemplates", props),
    get("shift.uniform.identifier", props)
  ),
  venue: get("shift.venue.name", props),
  venueId: get("shift.venue.id", props),
  when: {
    start: get("shift.startTime", props),
    end: get("shift.endTime", props)
  },
  briefing: get("shift.briefing", props) || "No information provided",
  directions: get("shift.venue.directions", props) || "No information provided",
  meetingPoint:
    get("shift.venue.meetingPoint", props) || "No information provided",
  name: get("shift.name", props),
  identifier: get("shift.identifier", props),
  role: get("shift.role.name", props),
  algoSkew: get("shift.algoSkew", props) || 100,
  tags: get("shift.tags", props),
  startTime: get("shift.startTime", props),
  endTime: get("shift.endTime", props)
});

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

import { addAuthVars } from "@teamrota/authlib";

import useAuth from "~/src/auth/hooks/use-auth";
import MemberRoleFragment from "~/src/graphql/fragments/member-role";
import { GET_MEMBER } from "./get-member";

export const UPDATE_MEMBER = addAuthVars(gql`
  ${MemberRoleFragment}
  mutation createOrUpdateMember(
    $id: ID
    $title: String
    $nickname: String
    $firstName: String
    $lastName: String
    $middleName: String
    $birthDate: Date
    $gender: String
    $nationality: String
    $ethnicity: String
    $disability: String
    $notes: String
    $email: String
    $phone: String
    $houseNumber: String
    $streetName: String
    $town: String
    $postcode: String
    $county: String
    $country: String
    $emergencyContactTitle: String
    $emergencyContactName: String
    $emergencyContactNumber: String
    $emergencyContactRelationship: String
    $emergencyContactEmail: String
    $bankName: String
    $accountName: String
    $sortCode: String
    $rollNumber: String
    $accountNumber: String
    $NINumber: String
    $serviceAreas: [ID]
    $venueId: ID
    $state: String
    $internalId: String
    $metadata: JSON
    $roles: [JSON]
    $rightToWorkExpiresAt: Date
    $isRightToWorkValid: Boolean
    $startDate: Date
    $endDate: Date
    $tags: [TagsInputType]
    $memberType: String
    $ownerId: ID
    $isInsideIR35: Boolean
    $annualSalary: Float
    $weeklyContractedHours: Int
    $UTRNumber: String
    $registeredNumber: String
    $name: String
    $registeredAddress: String
    $invoicingAddress: String
    $isVATRegistered: Boolean
    $VATRegistrationNumber: String
    $isCompanyRegistrationCertificateAttached: Boolean
    $isHMRCSelfEmployedConfirmationCertificateAttached: Boolean
    $isConfirmationCertificateChecked: Boolean
    $isConfirmationOfRightToWork: Boolean
    $isPublicLiabilityProofAttached: Boolean
    $isContractForServicesSigned: Boolean
    $isConfirmationOfBankDetailsAttached: Boolean
    $isVATCertificateAttached: Boolean
    $isVATCertificateChecked: Boolean
    $dbsCertificateNumber: String
    $dbsIssueDate: Date
    $p46Question: String
    $requiresTravel: Boolean
    $isEligibleForRebook: Boolean
  ) {
    createOrUpdateMember(
      id: $id
      title: $title
      nickname: $nickname
      firstName: $firstName
      lastName: $lastName
      middleName: $middleName
      birthDate: $birthDate
      gender: $gender
      nationality: $nationality
      ethnicity: $ethnicity
      disability: $disability
      notes: $notes
      email: $email
      phone: $phone
      houseNumber: $houseNumber
      streetName: $streetName
      town: $town
      postcode: $postcode
      county: $county
      country: $country
      emergencyContactTitle: $emergencyContactTitle
      emergencyContactName: $emergencyContactName
      emergencyContactNumber: $emergencyContactNumber
      emergencyContactRelationship: $emergencyContactRelationship
      emergencyContactEmail: $emergencyContactEmail
      bankName: $bankName
      accountName: $accountName
      sortCode: $sortCode
      rollNumber: $rollNumber
      accountNumber: $accountNumber
      NINumber: $NINumber
      serviceAreas: $serviceAreas
      venueId: $venueId
      state: $state
      internalId: $internalId
      metadata: $metadata
      roles: $roles
      rightToWorkExpiresAt: $rightToWorkExpiresAt
      isRightToWorkValid: $isRightToWorkValid
      startDate: $startDate
      endDate: $endDate
      tags: $tags
      memberType: $memberType
      ownerId: $ownerId
      isInsideIR35: $isInsideIR35
      annualSalary: $annualSalary
      weeklyContractedHours: $weeklyContractedHours
      UTRNumber: $UTRNumber
      name: $name
      registeredNumber: $registeredNumber
      registeredAddress: $registeredAddress
      invoicingAddress: $invoicingAddress
      isVATRegistered: $isVATRegistered
      VATRegistrationNumber: $VATRegistrationNumber
      isCompanyRegistrationCertificateAttached: $isCompanyRegistrationCertificateAttached
      isHMRCSelfEmployedConfirmationCertificateAttached: $isHMRCSelfEmployedConfirmationCertificateAttached
      isConfirmationCertificateChecked: $isConfirmationCertificateChecked
      isConfirmationOfRightToWork: $isConfirmationOfRightToWork
      isPublicLiabilityProofAttached: $isPublicLiabilityProofAttached
      isContractForServicesSigned: $isContractForServicesSigned
      isConfirmationOfBankDetailsAttached: $isConfirmationOfBankDetailsAttached
      isVATCertificateAttached: $isVATCertificateAttached
      isVATCertificateChecked: $isVATCertificateChecked
      dbsCertificateNumber: $dbsCertificateNumber
      dbsIssueDate: $dbsIssueDate
      p46Question: $p46Question
      requiresTravel: $requiresTravel
      isEligibleForRebook: $isEligibleForRebook
    ) {
      id
      title
      nickname
      firstName
      lastName
      middleName
      birthDate
      gender
      nationality
      ethnicity
      disability
      notes
      email
      phone
      houseNumber
      streetName
      town
      postcode
      county
      country
      emergencyContactTitle
      emergencyContactName
      emergencyContactNumber
      emergencyContactRelationship
      emergencyContactEmail
      serviceAreas {
        id
        name
      }
      venueId
      state
      internalId
      metadata
      roles {
        ...MemberRoleFragment
      }
      memberCompany {
        name
        registeredNumber
        registeredAddress
        invoicingAddress
        isVATRegistered
        isCompanyRegistrationCertificateAttached
        isHMRCSelfEmployedConfirmationCertificateAttached
        isConfirmationCertificateChecked
        isConfirmationOfRightToWork
        isPublicLiabilityProofAttached
        isContractForServicesSigned
        isConfirmationOfBankDetailsAttached
        isVATCertificateAttached
        isVATCertificateChecked

        ... on memberCompany {
          UTRNumber
          VATRegistrationNumber
        }
      }
      rightToWorkExpiresAt
      isRightToWorkValid
      startDate
      endDate
      memberType
      ownerId
      isInsideIR35
      annualSalary
      weeklyContractedHours

      ... on member {
        bankName
        accountName
        sortCode
        rollNumber
        accountNumber
        NINumber
      }
    }
  }
`);

export default graphql(UPDATE_MEMBER, {
  name: "createOrUpdateMember",

  props: ({ createOrUpdateMember, isAddMember }) => {
    const auth = useAuth();

    return {
      createOrUpdateMember: async (memberId, values) => {
        const memberMutationResult = await createOrUpdateMember({
          variables: auth.addVals(GET_MEMBER, {
            ...values,
            id: memberId,
            ownerId: values.ownerId !== "" ? parseInt(values.ownerId) : null
          }),
          refetchQueries:
            !isAddMember && memberId
              ? [
                  {
                    query: GET_MEMBER,
                    variables: auth.addVals(GET_MEMBER, { memberId })
                  }
                ]
              : []
        });

        return memberMutationResult.data.createOrUpdateMember;
      }
    };
  }
});

import React, { Component } from "react";
import SearchInput from "~/src/components/form-components/search-input";
import isEqual from "lodash/fp/isEqual";
import ToggleTray from "~/src/components/toggle-tray";
import TextInput from "../text-input";
import Textarea from "../textarea";
import Icon from "../../icon";
import { optionsToList, flattenOptions, findKeyIndex } from "./helpers";
import {
  SelectTray,
  SelectOption,
  Placeholder,
  SelectHeader
} from "./select.styles";

/*
    HACK: (like most things in this component) but I've no time to refactor #startupLife
    for briefing templates we need to show the template text as content of the selected value
    I added a content props to the option and this boolean is used to show it
    Sorry if you need to debug this in the future
  */

export default class Select extends Component {
  state = {
    inputValue: "",
    isFieldFocused: false,
    keyIndex: -1
  };

  componentDidMount = () => {
    this.checkAlwaysOpen();
  };

  onOpen = () => {
    this.setState({
      isFieldFocused: true
    });
  };

  onClose = () => {
    if (this.props.isAlwaysOpen) {
      this.setState({
        isFieldFocused: true,
        inputValue: this.props.isEditable ? this.state.inputValue : ""
      });
    } else {
      this.setState({
        isFieldFocused: false,
        inputValue: this.props.isEditable ? this.state.inputValue : "",
        keyIndex: -1
      });
    }
  };

  onChangeValue = val => {
    this.setState({ inputValue: val, keyIndex: 0 });
  };

  getSelectedOptionLabel(options, value) {
    const opts = options || this.props.options;
    const val = value || this.props.value;
    const option = optionsToList(opts).find(opt => isEqual(opt.value, val));
    return option ? `${option.label}` : "";
  }

  getFilteredOptions() {
    const { options } = this.props;
    if (!this.state.inputValue || this.props.isEditable) return options;
    const filter = arr =>
      arr.filter(({ label }) =>
        label
          .toString()
          .toLowerCase()
          .includes(this.state.inputValue)
      );

    if (Array.isArray(options)) {
      return filter(options);
    }

    return Object.keys(options).reduce(
      (curr, next) => ({
        ...curr,
        [next]: filter(options[next])
      }),
      {}
    );
  }

  getInputValue() {
    if (this.props.isEditable || this.props.shouldShowContentAsSelectedLabel) {
      return this.props.value;
    }
    if (this.state.isFieldFocused) {
      return this.state.inputValue;
    }
    return this.getSelectedOptionLabel();
  }

  getValueProp() {
    if (this.props.isEditable) return "label";
    return "value";
  }

  handleSelect(opt) {
    return () => {
      if (this.props.shouldShowContentAsSelectedLabel) {
        this.props.onChange(opt.content, opt.label);
      } else {
        this.props.onChange(opt[this.getValueProp()]);
      }

      this.setState({ isFieldFocused: false });
    };
  }

  checkAlwaysOpen() {
    if (this.props.isAlwaysOpen) {
      this.setState({ isFieldFocused: true });
    }
  }

  handleKeyDown = e => {
    const UP = 38;
    const DOWN = 40;
    const RETURN = 13;
    const MAX = flattenOptions(this.getFilteredOptions()).length - 1;
    if (e.keyCode === UP && this.state.keyIndex > -1) {
      this.setState({
        keyIndex: findKeyIndex(this.props.options, this.state.keyIndex, false)
      });
    } else if (e.keyCode === DOWN && this.state.keyIndex < MAX) {
      this.setState({
        keyIndex: findKeyIndex(this.props.options, this.state.keyIndex, true)
      });
    } else if (e.keyCode === RETURN && this.state.keyIndex > -1) {
      this.props.onChange(
        flattenOptions(this.getFilteredOptions())[this.state.keyIndex][
          this.getValueProp()
        ]
      );
      if (this.input) this.input.blur();
    }
  };

  handleInputChange = val => {
    if (this.props.isEditable) {
      this.props.onChange(val);
    } else {
      this.setState({ inputValue: val, keyIndex: -1 });
    }
  };

  input = null;

  render() {
    const { props, state } = this;
    const options = flattenOptions(this.getFilteredOptions());

    // eslint-disable-next-line no-nested-ternary
    const Field = props.isMultiline
      ? Textarea
      : props.isSearch
      ? SearchInput
      : TextInput;

    return (
      <ToggleTray
        isOpen={state.isFieldFocused}
        onClose={() => this.setState({ isFieldFocused: false })}
        toggle={
          // $FlowFixMe - propTypes!
          <Field
            shouldFixLabelHeight={props.shouldFixLabelHeight}
            onRegister={ref => {
              this.input = ref;
            }}
            isInline={props.isInline}
            isSmall={props.isSmall}
            placeholder={props.placeholder}
            label={props.label}
            isDisabled={props.isDisabled}
            onFocus={this.onOpen}
            isError={props.isError || !!props.errorLabel}
            isBorderless={props.isBorderless}
            iconRight={
              <Icon
                onClick={() => this.input.focus()}
                name={
                  props.isLoading ? Icon.names.LOADING : Icon.names.ARROW_DOWN
                }
                size={props.isLoading ? Icon.sizes.SMALL : Icon.sizes.XSMALL}
                color={
                  props.isLoading
                    ? Icon.colors.DARK_GREY
                    : Icon.colors.CLOUDY_BLUE
                }
              />
            }
            onKeyDown={this.handleKeyDown}
            onChangeValue={this.handleInputChange}
            value={this.getInputValue()}
            errorLabel={props.errorLabel}
            isRounded={props.isRounded}
          />
        }
        tray={
          <SelectTray totalOptions={options.length}>
            {options.length ? (
              options.map((opt, i) =>
                opt.isHeader ? (
                  <SelectHeader key={i}>{opt.label}</SelectHeader>
                ) : (
                  <SelectOption
                    isHighlighted={this.state.keyIndex === i}
                    isSmall={props.isSmall}
                    onMouseDown={this.handleSelect(opt, i)}
                    key={i}
                    borderedOptions={props.borderedOptions}
                  >
                    {opt.label}
                  </SelectOption>
                )
              )
            ) : (
              <Placeholder>Found no results...</Placeholder>
            )}
          </SelectTray>
        }
      />
    );
  }
}

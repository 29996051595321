import React, { useState } from "react";
import { RotaButton, colors, RotaCheckbox } from "@teamrota/rota-design";
import { useQuery, useMutation } from "@apollo/client";
import { Container, Row, Col } from "react-grid-system";

import { errorModal } from "~/src/utils/errors";
import useAuth from "~/src/auth/hooks/use-auth";
import { styles } from "~/src/containers/settings-page/right-panel/notifications-tab-content/notifications-tab-content.styles";

import { UPDATE_USER } from "./graphql/create-update-user";
import GET_PROFILE from "./graphql/get-profile";

const NotificationsTabContent = () => {
  const auth = useAuth();

  const {
    data: { user }
  } = useQuery(GET_PROFILE, {
    variables: auth.addVals(GET_PROFILE, {
      connectionLimit: 10,
      connectionOffset: 0
    })
  });

  const [createUpdateUser, { loading }] = useMutation(UPDATE_USER);

  const {
    pin,
    id,
    email,
    account: { isMinimumOnePartnerManagedConnection }
  } = user;

  const [userValues, setUserValues] = useState({
    canRecieveAllEmails: user?.canRecieveAllEmails,
    applicantAlerts: user?.usersMeta?.applicantAlerts,
    hourlyAlerts: user?.usersMeta?.hourlyAlerts
  });

  const handleChange = (optionName, isChecked) => {
    setUserValues({ ...userValues, [optionName]: !isChecked });
  };

  const onClickHandler = async () => {
    try {
      await createUpdateUser({
        variables: {
          id,
          email,
          ...userValues
        }
      });
    } catch (e) {
      errorModal(e);
    }
  };

  const handleGeneratePin = async () => {
    try {
      await createUpdateUser({
        variables: { id, email, shouldRegeneratePin: true }
      });
    } catch (e) {
      errorModal(e);
    }
  };

  return (
    <div id="tab-content">
      <div id="checkbox-row">
        <Container fluid>
          <div id="Alerts-container" style={styles.alertsContainer}>
            Alerts
          </div>
          <Row>
            <Col sm={6} md={6} lg={6}>
              <RotaCheckbox
                label={"Receive all emails"}
                onClick={() =>
                  handleChange(
                    "canRecieveAllEmails",
                    userValues.canRecieveAllEmails
                  )
                }
                isChecked={userValues.canRecieveAllEmails}
              />
            </Col>
            {isMinimumOnePartnerManagedConnection && (
              <Col sm={6} md={6} lg={6}>
                <RotaCheckbox
                  label={"Receive all applicant alerts as they come"}
                  onClick={() =>
                    handleChange("applicantAlerts", userValues.applicantAlerts)
                  }
                  isChecked={userValues.applicantAlerts}
                />
              </Col>
            )}
            {isMinimumOnePartnerManagedConnection && (
              <Col sm={6} md={6} lg={6}>
                <RotaCheckbox
                  label={"Receive all applicant summaries hourly"}
                  onClick={() =>
                    handleChange("hourlyAlerts", userValues.hourlyAlerts)
                  }
                  isChecked={userValues.hourlyAlerts}
                />
              </Col>
            )}
          </Row>
        </Container>
      </div>

      <Container fluid>
        <div id="Alerts-container" style={styles.alertsContainer}>
          Pin Code
        </div>
        <Row>
          <Col sm={6} md={6} lg={6}>
            <div
              style={{
                color: colors.rotaOrange,
                fontWeight: 500
              }}
            >
              {pin}
            </div>
          </Col>
        </Row>
        <div style={{ width: "10px", height: "18px" }}></div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <RotaButton onClick={handleGeneratePin} variant="outlined">
            Add new pin
          </RotaButton>
          <RotaButton
            onClick={onClickHandler}
            isLoading={loading}
            disabled={loading}
          >
            Save
          </RotaButton>
        </div>
      </Container>
    </div>
  );
};

export default NotificationsTabContent;

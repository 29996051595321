import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";

import {
  RotaSearchInput,
  RotaButtonIcon,
  RotaButton,
  RotaInput
} from "@teamrota/rota-design";

import { GET_MEMBERS } from "../../graphql/get-members";
import adminClient from "../../graphql/admin-client";

import useLoadMore from "~/src/containers/admin-dashboard/hooks/load-more";
import DashboardScrollHandler from "~/src/components/dashboard-scroll-handler";

import AccountsList from "../accounts-list";
import Modal from "~/src/components/modal";
import ModalContent from "~/src/containers/modals/components/modal-content";
import Member from "~/src/containers/admin-dashboard/components/member";

import { StyledContentModal } from "./styles";
import {
  StyledMainHeading,
  StyledMainDetail,
  StyledMainHeaderWrapper,
  StyledWrapper,
  StyledFeaturesWrapper,
  StyledFeatureHeader,
  StyledWrapperList
} from "../styles";

import { DELETE_MEMBERS } from "../../graphql/delete-members";

const GDPRRemovals = ({ setSnack }) => {
  const [selectedAccountId, setSelectedAccountId] = useState();
  const [selectedAccountName, setSelectedAccountName] = useState();
  const [selectedMember, setSelectedMember] = useState();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isMemberNameValid, setIsMemberNameValid] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const { data, fetchMore, loading } = useQuery(GET_MEMBERS, {
    variables: {
      searchTerm,
      accountId: selectedAccountId,
      limit: 10,
      offset: 0
    },
    client: adminClient,
    skip: !selectedAccountId
  });

  const [deleteMembers, { loading: isLoading }] = useMutation(DELETE_MEMBERS, {
    variables: { accountId: selectedAccountId },
    client: adminClient,
    refetchQueries: ["getMembersList"]
  });

  const SCROLL_ELEMENT_ID = "members_list_scroll";

  const dataLength = data?.members.length;

  const [handleLoadMore] = useLoadMore({
    dataLength,
    fetchMore,
    nameData: "members"
  });

  const openModalAndGetMember = member => {
    setSelectedMember(member);
    setIsConfirmationModalOpen(true);
  };

  const fullName = ({ firstName, lastName }) => `${firstName} ${lastName}`;

  const getMemberValid = e => {
    setIsMemberNameValid(e.target.value === fullName(selectedMember));
  };

  const removeMember = async selectedMemberId => {
    try {
      await deleteMembers({
        variables: { memberIds: [selectedMemberId] }
      });
      setSnack({ message: "Success!", severity: "success" });
      setIsConfirmationModalOpen(false);
    } catch (e) {
      setSnack({ message: "Something Went Wrong.", severity: "error" });
    }
  };
  return (
    <>
      {isConfirmationModalOpen && (
        <Modal
          isOpen={isConfirmationModalOpen}
          onClose={() => setIsConfirmationModalOpen(false)}
        >
          <ModalContent isSmallContent title="Delete member">
            <StyledContentModal>
              <div>
                <h3>
                  Are you sure you want to delete{" "}
                  <span>{fullName(selectedMember)}</span> member?
                </h3>
              </div>

              <RotaInput
                label="Please, enter full name of the member to delete"
                isLabelTop
                onChange={getMemberValid}
              />

              <div>
                <RotaButton
                  variant="secondary"
                  onClick={() => setIsConfirmationModalOpen(false)}
                >
                  cancel
                </RotaButton>
                <RotaButton
                  disabled={!isMemberNameValid || isLoading}
                  onClick={() => removeMember(selectedMember.id)}
                >
                  {isLoading ? "Deleting" : "Delete"}
                </RotaButton>
              </div>
            </StyledContentModal>
          </ModalContent>
        </Modal>
      )}
      <StyledMainHeaderWrapper>
        <StyledMainHeading>GDPR Removals</StyledMainHeading>
        <StyledMainDetail>Delete / Anonymize members</StyledMainDetail>
      </StyledMainHeaderWrapper>

      <StyledWrapper>
        <AccountsList
          onSelect={(id, accountName) => {
            setSelectedAccountId(id);
            setSelectedAccountName(accountName);
          }}
          selectedAccountId={selectedAccountId}
          selectedAccountName={selectedAccountName}
        />
        {selectedAccountId && (
          <StyledFeaturesWrapper>
            <StyledFeatureHeader>
              <h3>Members: {selectedAccountName}</h3>
              <RotaSearchInput
                placeholder="search member"
                onChange={value => {
                  setSearchTerm(value);
                }}
              />
            </StyledFeatureHeader>
            <DashboardScrollHandler
              onReachEnd={() => {
                handleLoadMore();
              }}
              scrollElementId={SCROLL_ELEMENT_ID}
            >
              <StyledWrapperList id={SCROLL_ELEMENT_ID}>
                {loading && <p>loading...</p>}
                {data?.members?.map(member => (
                  <Member key={member.id} member={member}>
                    <RotaButtonIcon
                      icon="delete"
                      onClick={() => openModalAndGetMember(member)}
                    />
                  </Member>
                ))}
              </StyledWrapperList>
            </DashboardScrollHandler>
          </StyledFeaturesWrapper>
        )}
      </StyledWrapper>
    </>
  );
};

export default GDPRRemovals;

import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";
import { getBreakPoint } from "~/src/styles/style-helpers";

const ms = size => `@media screen and (min-width: ${size})`;
const mx = point => ms(getBreakPoint(point));

function pad(props) {
  if (typeof props.gutter === "number") {
    return `${props.gutter}px`;
  }

  switch (props.gutter) {
    case "none":
      return "0px";
    case "xsmall":
      return STYLE_CONSTS.UNIT_1;
    case "small":
      return STYLE_CONSTS.UNIT_2;
    case "medium":
      return STYLE_CONSTS.UNIT_4;
    case "large":
      return STYLE_CONSTS.UNIT_8;
    default:
      return STYLE_CONSTS.UNIT_4;
  }
}

const p = n => (typeof n !== "undefined" ? `${n * 100}%` : null);

const width = key => props => (props[key] ? `width: ${p(props[key])};` : null);

export const Column = styled("div")`
  box-sizing: border-box;
  display: inline-block;
  vertical-align: ${props => props.align || "top"};
  padding-left: ${pad};
  padding-right: ${pad};
  text-align: ${({ textAlign }) => textAlign || "left"};
  width: 100%;
  ${width("xs")} ${mx("small")} {
    ${width("small")};
  }
  ${mx("medium")} {
    ${width("medium")};
  }
  ${mx("large")} {
    ${width("large")};
  }
  ${mx("xlarge")} {
    ${width("xlarge")};
  }
`;

export const Row = styled.div``;

import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import Photo from "~/src/components/member-photo";
import { STYLE_CONSTS } from "~/src/styles/config.style";
import Icon from "~/src/components/icon";

const TRAY_SIZE = 170;

export const StyledIcon = styled(Icon)`
  vertical-align: middle;
`;

export const Username = styled.span`
  cursor: pointer;
  font-size: ${STYLE_CONSTS.FONT};
  color: ${({ theme }) => theme.text.primary};
`;

export const UserDropDown = styled.div`
  display: flex;
  min-width: ${TRAY_SIZE}px;
  text-align: right;
  float: right;
  margin-right: ${STYLE_CONSTS.UNIT_8};
  color: ${({ theme }) => theme.text.secondary};

  & > #nav-tray-show-hide {
    padding: 0 ${STYLE_CONSTS.UNIT_2};
    display: none;
  }
  &:hover > #nav-tray-show-hide {
    display: block;
  }
`;

export const NavTray = styled.div`
  box-shadow: ${STYLE_CONSTS.FLOATING};
  width: ${TRAY_SIZE}px;
  text-align: left;
  background-color: ${({ theme }) => theme.white};
  padding: ${STYLE_CONSTS.UNIT_1};
  position: absolute;
  right: ${STYLE_CONSTS.UNIT_4};
  top: ${STYLE_CONSTS.MENU_SIZE};
  line-height: ${STYLE_CONSTS.FONT_LARGE};
  font-size: ${STYLE_CONSTS.FONT_SMALL};
`;

export const TrayLink = styled(props => <Link {...props} />)`
  transition: ${STYLE_CONSTS.STANDARD_TRANSITON};
  text-decoration: none;
  display: block;
  color: ${({ theme }) => theme.text.secondary};
  font-size: ${STYLE_CONSTS.FONT};
  margin: ${STYLE_CONSTS.UNIT_2} 0;
  &:hover {
    color: ${({ theme }) => theme.text.secondary};
  }
`;

export const UserNameLink = styled(props => <Link {...props} />)`
  min-width: ${TRAY_SIZE}px;
  text-decoration: none;
`;

export const StyledPhoto = styled(Photo)`
  vertical-align: middle;
  margin-left: ${STYLE_CONSTS.UNIT_2};
`;

export const SettingsSpan = styled.span`
  margin: 0 10px 0 44px;
  color: black;
`;

import { gql } from "@apollo/client";
import { addAuthVars } from "@teamrota/authlib";

import MemberFragment from "~/src/graphql/fragments/member/member.fragment";

export const GET_MEMBER = addAuthVars(gql`
  ${MemberFragment}

  query getMember(
    $selectedAccountId: ID
    $id: ID!
    $dates: [DateRangeType]!
    $shiftType: ShiftTypeEnumType!
    $roleRateId: ID!
    $venueId: ID
    $tagIds: [ID]
  ) {
    account(sourceAccountId: $selectedAccountId) {
      id
      isOverLimitWorkAllowed
      isMemberAvailabilityEnabled

      member(id: $id) {
        ...MemberFragment
        isQualifiedWithRole(roleRateId: $roleRateId)
        isQualifiedWithTags(tagIds: $tagIds)
        isInVenueServiceArea(venueId: $venueId)
        isConflicted: isConflictedWithDates(dates: $dates)
        isUnavailable: isUnavailableWithDates(
          dates: $dates
          shiftType: $shiftType
        )
        isHitWorkingHoursLimit(dates: $dates)
        memberAvailability(dates: $dates) {
          startsAt
          endsAt
          shiftType
        }
      }
    }
  }
`);

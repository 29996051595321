import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

import { addAuthVars } from "@teamrota/authlib";

export const CREATE_PAYROLL_CALCULATION = addAuthVars(gql`
  mutation createPayrollCalculation($name: String!, $config: [JSONObject]!) {
    createPayrollCalculation(name: $name, config: $config) {
      name
      config
    }
  }
`);

export const useCreatePayrollCalculation = () => {
  const [create, { loading, error }] = useMutation(CREATE_PAYROLL_CALCULATION, {
    onError: error => {
      throw error;
    }
  });
  const createPayrollCalculation = async ({
    name,
    config
  }: {
    name: string;
    config: [JSON];
  }) => {
    await create({
      variables: {
        name,
        config
      },
      refetchQueries: ["payrollCalculations"]
    });
  };

  return { createPayrollCalculation, loading, error };
};

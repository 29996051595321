import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const GroupWrapper = styled.div`
  display: inline-block;
  width: 50%;
  padding-right: ${({ isMarginRight }) =>
    isMarginRight ? STYLE_CONSTS.UNIT_1 : 0};
`;

export const Label = styled.div`
  font-weight: bold;
  color: ${({ theme }) => theme.neutral.main};
`;

export const FieldColumn = styled.div`
  display: inline-block;
  width: ${({ width }) => width}%;
  padding-right: ${STYLE_CONSTS.UNIT_2};
`;

import { gql, useQuery } from "@apollo/client";

export const GET_VENUES = gql`
  query getVenues($partnerId: ID) {
    account {
      partnerVenueWithoutExclusion(partnerId: $partnerId) {
        name
        id
        address
      }
    }
  }
`;

export const useGetVenues = partnerId => {
  const { data } = useQuery(GET_VENUES, {
    variables: { partnerId }
  });

  return { venues: data?.account?.partnerVenueWithoutExclusion ?? [] };
};

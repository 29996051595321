import styled from "styled-components";
import { COLORS, STYLE_CONSTS } from "~/src/styles/config.style";
import Icon from "~/src/components/icon";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
  &:hover {
    cursor: default;
  }
  justify-content: ${({ shouldBeCenter }) =>
    shouldBeCenter ? "center" : "flex-start"};
  align-items: ${({ shouldBeCenter }) =>
    shouldBeCenter ? "center" : "flex-start"};
`;

export const Title = styled.div`
  width: 100%;
  font-size: ${({ isSubTitle }) =>
    isSubTitle ? STYLE_CONSTS.FONT_SMALL : STYLE_CONSTS.FONT_LARGE};
  font-weight: 400;
  padding: 5px 0;
  ${({ isSubTitle }) =>
    isSubTitle &&
    `color: ${COLORS.WARM_GREY}; text-transform: uppercase; font-size: 9px;`};
`;

export const LoadingWrapper = styled.div`
  padding: 10px 0;
`;

export const StyledIcon = styled(Icon).attrs(props => ({
  name: props.name,
  size: props.isLarge ? "large" : "small",
  color: props.isLarge ? COLORS.MAIN_ORANGE : COLORS.WARM_GREY
}))`
  margin: 0px 6px;
  top: 0.4em;
  position: relative;
  ${({ name }) => name === Icon.names.UNCHECK && `margin: 0 3px;`}
`;

export const ManageableShiftsWrapper = styled.div`
  width: 100%;
`;

export const IconWrapper = styled.div``;

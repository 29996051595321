import React, { useState, useEffect } from "react";
import { mapValues } from "lodash";
import { gql } from "@apollo/client";
import styles from "../styles";
import client from "~/src/services/graphql";
import { RotaCheckbox } from "@teamrota/rota-design";

const JobRoleFilter = ({ schedule, setRoleIds }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [memberBookings, setMemberBookings] = useState([]);
  const [rolesChecked, setRolesChecked] = useState({});
  const [allRoles, setAllRoles] = useState();

  const GET_ROLES = gql`
    query getRoles {
      account {
        id
        roles {
          id
          name
        }
      }
    }
  `;
  const setRoles = async () => {
    const result = await client.query({
      query: GET_ROLES
    });
    if (result) {
      setAllRoles(result?.data?.account?.roles);
    }
  };

  useEffect(() => {
    setRoles();
  }, []);

  useEffect(() => {
    const scheduledMembers = schedule?.scheduledMembers;
    if (scheduledMembers?.length > 0) {
      const scheduleMembersDays = [].concat(
        ...scheduledMembers?.map(member => member.scheduledDays)
      );
      setMemberBookings(scheduleMembersDays);
    }
  }, [schedule]);

  const handleRolesChecked = roleId => {
    setRolesChecked({
      ...rolesChecked,
      [roleId]: !rolesChecked[roleId]
    });
  };

  const getNumberOfRolesBooked = roleId => {
    const totalRolesPerBooking = memberBookings.reduce((total, member) => {
      const memberRoleId = member?.bookings[0]?.shift?.roleRate?.roleId;
      return memberRoleId === roleId ? total + 1 : total;
    }, 0);
    return totalRolesPerBooking > 0 ? totalRolesPerBooking : null;
  };

  useEffect(() => {
    if (Object.keys(rolesChecked).length > 0) {
      const roles = Object.keys(rolesChecked).filter(
        roles => rolesChecked[roles]
      );
      setRoleIds(roles);
    }
  }, [rolesChecked]);

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const clearSelectedRole = () => {
    setRolesChecked(mapValues(rolesChecked, () => false));
  };

  return (
    <span>
      <div onClick={toggleIsExpanded} style={styles.header}>
        <span>JOB ROLE</span>
        <span style={styles.expandArrow}>
          {/* Using the alternative && conditional rendering will cause 
          flickering bugs which seems to be to do with React re-rendering */}
          {isExpanded ? (
            <img src="/images/up_arrow.svg" style={styles.image} />
          ) : (
            <img src="/images/down_arrow.svg" style={styles.image} />
          )}
        </span>
      </div>
      {isExpanded &&
        allRoles?.map(role => {
          const numberOfRolesBooked = getNumberOfRolesBooked(role?.id);
          return (
            <label key={role?.id} style={styles.label}>
              <RotaCheckbox
                onClick={() => handleRolesChecked(role?.id)}
                isChecked={rolesChecked[role?.id]}
                label={role?.name}
              />
              {numberOfRolesBooked && (
                <span style={styles.roleNo}> {numberOfRolesBooked}</span>
              )}
            </label>
          );
        })}
      {isExpanded && (
        <p style={styles.clear} onClick={clearSelectedRole}>
          Clear
        </p>
      )}
    </span>
  );
};

export default JobRoleFilter;

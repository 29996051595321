import React, { memo } from "react";

import { Dialog } from "@material-ui/core";

import DialogTitle from "~/src/containers/settings-page/dialog-title";
import { RotaButtonNew } from "@teamrota/rota-design";

import {
  Styled_DialogActions,
  Styled_DialogContent,
  Styled_DialogTitleText,
  Styled_DialogText,
  useStyles
} from "./dialog.styles";

const AlertDialog = ({
  openDialog,
  handleClose,
  children,
  dialogTitle,
  declineBtnText
}) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.dialogPaperInput }}
      fullWidth
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={openDialog}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose} />
      <div>
        <Styled_DialogTitleText>{dialogTitle}</Styled_DialogTitleText>
      </div>
      <Styled_DialogContent>
        <Styled_DialogText>{children}</Styled_DialogText>
      </Styled_DialogContent>
      <Styled_DialogActions>
        <RotaButtonNew onClick={handleClose}>{declineBtnText}</RotaButtonNew>
      </Styled_DialogActions>
    </Dialog>
  );
};

export default memo(AlertDialog);

import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const Container = styled.div`
  margin: ${STYLE_CONSTS.UNIT_3};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

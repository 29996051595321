import { gql } from "@apollo/client";

export const REVERT_MEMBER_UNAVAILABILITY_IMPORT = gql`
  mutation revertMemberUnavailabilityImport($id: ID!) {
    revertMemberUnavailabilityImport(id: $id) {
      success
      count
    }
  }
`;

import { get, getOr } from "lodash/fp";
import moment from "moment-timezone";
import React from "react";
import Textarea from "~/src/components/form-components/textarea";
import BookingAccount from "~/src/services/accounting";
import { DEFAULT_SURGE_RATE, SurgeString } from "~/src/services/surge-pricing";
import Money from "~/src/utils/money";
import MoneyTransaction from "~/src/utils/money/money-transaction";
import ActionDropdown from "../action-dropdown";
import CurrencyInput from "../form-field/inputs/currency-input";
import IconButton from "../icon-button";
import bonusStyles, {
  BonusDropDownContainer,
  BonusFieldContainer,
  BonusFieldInnerContainer,
  BonusInputContainer,
  BonusWrapper,
  Label,
  SubTotalContainer
} from "./bonus.styles";
import { useTheme } from "styled-components";

const BonusRewardComponent = ({
  surgeFactor = DEFAULT_SURGE_RATE,
  linkedMultiple = 1,
  isReward,
  formData,
  roleOptions,
  alreadyExistingBonuses,
  bonuses,
  isLight,
  onAddBonus,
  hideSubTotal,
  onUpdateBonusTotal,
  onUpdateBonusReason,
  onUpdateBonusAmount,
  onUpdateBonusType,
  isEditShift,
  enlargedAddIcon,
  customFontSize,
  onDeleteBonus
}) => {
  const theme = useTheme();

  const options = [
    {
      value: 0,
      label: "hourly"
    },
    {
      value: 1,
      label: "fixed"
    }
  ];

  let shiftSubTotal = () => {};

  if (!isReward) {
    shiftSubTotal = () => {
      if (!formData) return Money().toFormat();

      const { numberRequested, startTime, endTime, roleRateId } = formData;

      const options = roleRateId
        ? Object.values(roleOptions).map(o =>
            o.filter(option => option?.value === roleRateId)
          )
        : null;

      let chargeRate;
      if (options) {
        chargeRate = get("[0][0]", options)
          ? getOr(Money(), "[0][0].chargeRate", options)
          : getOr(Money(), "[1][0].chargeRate", options);
      } else {
        chargeRate = Money();
      }

      const subTotal = new MoneyTransaction(`subTotal bonus-reward`);
      const totalHours = moment(endTime).diff(moment(startTime), "hours", true);

      subTotal
        .add(chargeRate)
        .multiply(totalHours)
        .multiply(parseInt(numberRequested || 1, 10));

      if (alreadyExistingBonuses || bonuses.length) {
        const totalBonus = new MoneyTransaction(`totalBonus bonus-reward`);
        if (alreadyExistingBonuses && alreadyExistingBonuses.length) {
          alreadyExistingBonuses.forEach(bonus => {
            if (bonus.amount && bonus.period) {
              bonus = { ...bonus, type: bonus.period };
              const bonusMoney = BookingAccount.convertBonusToMoney(
                bonus,
                totalHours,
                linkedMultiple
              );

              totalBonus.add(bonusMoney);
            }
          });
        }

        if (bonuses.length) {
          bonuses.forEach(bonus => {
            if (bonus?.amount && bonus?.type) {
              const bonusMoney = BookingAccount.convertBonusToMoney(
                bonus,
                totalHours,
                linkedMultiple
              );

              totalBonus.add(bonusMoney);
            }
          });
        }

        onUpdateBonusTotal(totalBonus.current.getAmount());
        totalBonus.multiply(parseInt(numberRequested || 1, 10));
        return subTotal
          .add(totalBonus.current)
          .multiply(linkedMultiple)
          .toFormat();
      }

      return subTotal.multiply(linkedMultiple).toFormat();
    };
  }

  const renderExistingBonuses = () => {
    if (alreadyExistingBonuses?.length === 1) {
      return (
        <SubTotalContainer>
          <Label isLight={isLight} noPadding>
            <Label isLight={isLight}>There is already a</Label>
            <Label isLight={isLight} isHighlighted>
              {alreadyExistingBonuses?.[0]?.period}
            </Label>
            <Label isLight={isLight}>bonus of </Label>
            <Label isLight={isLight} isHighlighted>
              {Money({
                amount: alreadyExistingBonuses?.[0]?.amount
              }).toFormat()}
            </Label>
          </Label>
        </SubTotalContainer>
      );
    } else if (alreadyExistingBonuses.length > 1) {
      return (
        <SubTotalContainer>
          <Label isLight={isLight}>Existing bonuses:</Label>
          {alreadyExistingBonuses.map(bonus => (
            <Label isLight={isLight} key={bonus.id}>
              {`- ${bonus.period} of  ${Money({
                amount: bonus.amount
              }).toFormat()}`}
            </Label>
          ))}
        </SubTotalContainer>
      );
    }
    return null;
  };

  return (
    <BonusWrapper isReward={isReward} isEditShift={isEditShift}>
      {bonuses &&
        bonuses.length > 0 &&
        bonuses.map((bonus, index) => (
          <BonusFieldContainer
            key={bonus.id || index}
            isReward={isReward}
            isColumn={isReward}
          >
            <BonusFieldInnerContainer>
              <IconButton
                iconName={IconButton.iconNames.CLOSE}
                size={
                  isReward ? IconButton.sizes.SIZE_2 : IconButton.sizes.SIZE_1
                }
                color={theme.primary.light}
                noEffectOnHover
                onClick={() => onDeleteBonus(index, bonus.id)}
                isNotCentered={isReward}
                borderRadius={"80%"}
              />
              <Label isLight={isLight} isReward={isReward}>
                Add
              </Label>
              <BonusDropDownContainer>
                <ActionDropdown
                  defaultValue="select type"
                  value={bonus.type || null}
                  options={options}
                  onChange={option => {
                    const type = option.label;
                    onUpdateBonusType(index, type, bonus.id);
                  }}
                  isSpan
                  isFullWidth
                  displayNormal
                  shouldCloseOnSelect
                  isRewardDropDown={!!isReward}
                />
              </BonusDropDownContainer>
              <Label noPadding isLight={isLight} isReward={!!isReward}>
                {`${isReward ? "reward" : "bonus"} of`}
              </Label>
              <BonusInputContainer isReward={isReward}>
                <CurrencyInput
                  shouldNotAllowNegative
                  isSmall={!isReward}
                  value={bonus.amount || null}
                  input={{
                    value: bonus.amount,
                    onChange: amount =>
                      onUpdateBonusAmount(index, amount, bonus.id)
                  }}
                  meta={{}}
                />
              </BonusInputContainer>
            </BonusFieldInnerContainer>
            {isReward && (
              <BonusFieldInnerContainer>
                <Textarea
                  style={{ flex: 1 }}
                  value={bonus.reason}
                  minRows={1}
                  maxRows={3}
                  placeholder="Add reason for reward"
                  isMultilineAuto
                  overrideOrange
                  isDisabled={!bonus.id.startsWith("TEMP")}
                  onChangeValue={reason =>
                    onUpdateBonusReason(index, reason, bonus.id)
                  }
                />
              </BonusFieldInnerContainer>
            )}
          </BonusFieldContainer>
        ))}
      <BonusFieldContainer key="MAIN" isReward={isReward}>
        <div
          style={
            bonusStyles({ enlargedAddIcon: enlargedAddIcon }).iconContainer
          }
        >
          <IconButton
            iconName={IconButton.iconNames.ADD}
            size={
              isReward || enlargedAddIcon
                ? IconButton.sizes.SIZE_2
                : IconButton.sizes.SIZE_1
            }
            color={theme.primary.light}
            isBorderless
            noEffectOnHover
            isNotCentered={isReward}
            onClick={() => onAddBonus()}
          />
          <Label
            noPadding
            isLight={isLight}
            isReward={isReward}
            customFontSize={customFontSize}
          >
            {` Add ${isReward ? "reward" : "bonus (100% goes to the member)"}`}
          </Label>
        </div>
      </BonusFieldContainer>
      {isEditShift && renderExistingBonuses()}
      {!isReward && !hideSubTotal && (
        <SubTotalContainer>
          <Label isLight={isLight} isBold>
            {`Subtotal: ${shiftSubTotal()}`}
            {SurgeString({ surgeFactor })}
          </Label>
        </SubTotalContainer>
      )}
    </BonusWrapper>
  );
};

export default BonusRewardComponent;

import { RotaButtonNew, RotaInput, RotaModal } from "@teamrota/rota-design";
import React from "react";

import { SubvenuesModalButtonsContainer } from "./subvenues.styles";
import { useCreateOrUpdateSubvenue } from "./graphql/use-create-or-update-subvenue";

const SubvenueModal = ({
  closeSubvenueModal,
  subvenueForm,
  updateSubvenueForm,
  venueId,
  isTTM
}) => {
  const { createOrUpdateSubvenue } = useCreateOrUpdateSubvenue({
    onCompleted: () => {
      closeSubvenueModal();
    },
    onError: error => {
      throw new Error(error);
    }
  });

  const handleSave = () => {
    createOrUpdateSubvenue({
      variables: {
        venueId,
        name: subvenueForm.name,
        mmCode: subvenueForm.mmCode,
        costCode: subvenueForm.costCode,
        integraId: subvenueForm.integraId,
        id: subvenueForm.id
      }
    });
  };

  return (
    <div>
      <RotaModal
        name={"Create sub-venue"}
        isSmall={true}
        onClose={closeSubvenueModal}
      >
        <RotaInput
          isLabelTop
          label="Sub-venue Name"
          placeholder="Sub-venue Name"
          onChange={e => updateSubvenueForm("name", e.target.value)}
          value={subvenueForm.name}
        />
        <RotaInput
          isLabelTop
          label={isTTM ? "Integra ID" : "Payroll ID"}
          placeholder={isTTM ? "Integra ID" : "Payroll ID"}
          onChange={e => updateSubvenueForm("integraId", e.target.value)}
          value={subvenueForm.integraId}
        />
        <RotaInput
          isLabelTop
          label={isTTM ? "Cost code" : "Billing ID"}
          placeholder={isTTM ? "Cost code" : "Billing ID"}
          onChange={e => updateSubvenueForm("costCode", e.target.value)}
          value={subvenueForm.costCode}
        />
        <RotaInput
          isLabelTop
          label={isTTM ? "MM code" : "Internal ID"}
          placeholder={isTTM ? "MM code" : "Internal ID"}
          onChange={e => updateSubvenueForm("mmCode", e.target.value)}
          value={subvenueForm.mmCode}
        />

        <SubvenuesModalButtonsContainer>
          <RotaButtonNew variant="outlined" onClick={closeSubvenueModal}>
            Cancel
          </RotaButtonNew>
          <RotaButtonNew onClick={handleSave} isDisabled={!subvenueForm.name}>
            Save
          </RotaButtonNew>
        </SubvenuesModalButtonsContainer>
      </RotaModal>
    </div>
  );
};

export default SubvenueModal;

import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${STYLE_CONSTS.SMALL_DIVIDER_BORDER};
  margin-bottom: ${STYLE_CONSTS.UNIT_2};
  padding-bottom: ${STYLE_CONSTS.UNIT_2};
  &:last-child {
    border: none;
  }
`;

export const Scrollable = styled.div`
  height: calc(100% - 50px);
  overflow-y: auto;
`;

export const StyledButton = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Spacer = styled.div`
  height: ${STYLE_CONSTS.UNIT_8};
`;

import { gql, useQuery } from "@apollo/client";

import { addAuthVars } from "@teamrota/authlib";

const GET_USER_HOME_VENUES = addAuthVars(gql`
  query getUserHomeVenues {
    user {
      id
      homeVenues {
        id
        name
      }
      account {
        id
      }
    }
  }
`);

export const useHomeVenues = () => {
  const { loading, data } = useQuery(GET_USER_HOME_VENUES);

  const homeVenues = data?.user?.homeVenues || [];

  return { loading, homeVenues, accountId: data?.user?.account?.id };
};

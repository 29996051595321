import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const HistoryContainer = styled.div`
  height: calc(100% - 50px);
  overflow-y: auto;
`;

export const Spacer = styled.div`
  height: ${STYLE_CONSTS.UNIT_8};
`;

export function optionsToList(options) {
  if (Array.isArray(options)) return options;
  return Object.keys(options).reduce(
    (curr, next) => [...curr, ...options[next]],
    []
  );
}

export function flattenOptions(options) {
  if (Array.isArray(options)) return options;
  return Object.keys(options)
    .sort()
    .reduce(
      (curr, next) => [
        ...curr,
        { label: next, isHeader: true },
        ...options[next]
      ],
      []
    );
}

export function findKeyIndex(options, currentKeyIndex, isDown) {
  const multiplier = isDown ? 1 : -1;
  if (Array.isArray(options)) return currentKeyIndex + 1 * multiplier;
  const flatOpts = flattenOptions(options);
  const inc = flatOpts[currentKeyIndex + 1 * multiplier].isHeader ? 2 : 1;
  return currentKeyIndex + inc * multiplier;
}

import styled from "styled-components";

export const AppliedMemberWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 10px;
  position: relative;
`;

export const MemberPhotoWrapper = styled.div`
  padding-left: 5px;
`;

export const MemberNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 5px;
  flex: 1;
  padding-left: 10px;
  text-align: left;
`;

export const ActionButtonsWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

export const MemberField = styled.div``;

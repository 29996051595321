import { graphql } from "@apollo/client/react/hoc";
import { gql } from "@apollo/client";

import { handleErrors } from "~/src/utils/errors";

const GET_MEMBER_RELATIONSHIPS = gql`
  query getMemberRelationships($memberId: ID!) {
    account {
      id
      member(id: $memberId) {
        id
        relationships {
          id
          account {
            id
            accountName
          }
          updatedAt
          poolType
        }
      }
    }
  }
`;

export default graphql(GET_MEMBER_RELATIONSHIPS, {
  skip: ({ isOpen }) => !isOpen,
  props: handleErrors(({ data: { loading, account } }) => ({
    isLoading: loading,
    relationships: account?.member?.relationships ?? []
  }))
});

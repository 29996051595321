import styled from "styled-components";
import DateInputRaw from "~/src/components/form-components/date-input-raw";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const Input = styled(DateInputRaw)`
  width: 100%;
  font-size: ${STYLE_CONSTS.FONT_MEDIUM};
  border: none;
  &:focus {
    outline: none;
  }
  &:disabled {
    color: ${({ theme }) => theme.text.primary};
  }
  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.neutral.light};
  }
`;

export const Disabled = styled.div`
  font-size: ${STYLE_CONSTS.FONT_MEDIUM};
  color: ${({ theme }) => theme.text.primary};
`;

import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

const CREATE_ACCOUNT = gql`
  mutation createAccount(
    $accountName: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $phoneNumber: String!
    $ownerId: ID!
  ) {
    createAccount(
      accountName: $accountName
      firstName: $firstName
      lastName: $lastName
      email: $email
      phoneNumber: $phoneNumber
      ownerId: $ownerId
    ) {
      accountName
      firstName
      lastName
      email
      phoneNumber
      ownerId
    }
  }
`;
export const useCreateAccount = ({ onCompleted }) => {
  const [create, { loading, error }] = useMutation(CREATE_ACCOUNT, {
    onCompleted,
    onError: error => {
      throw error;
    }
  });
  const createAccount = async variables => {
    await create({
      variables
    });
  };

  return { createAccount, loading, error };
};

import React from "react";
import { RotaAvatar, RotaDivider } from "@teamrota/rota-design";
import { reasonOptions } from "../../consts";

import { Container, Wrapper, Label, Text } from "./view-exclusions.styles";

const getExlusionReason = reasonId =>
  reasonOptions.find(({ value }) => value === reasonId).label;

const ViewExclusions = ({ owner, excludedMembers }) => {
  return (
    <>
      {owner ? (
        <>
          <Container>
            <Label>Assigned to: </Label>
            <Text isBold>{`${owner.firstName} ${owner.lastName}`}</Text>
          </Container>
          <RotaDivider />
        </>
      ) : null}

      {excludedMembers?.length ? (
        <>
          {excludedMembers.map(
            ({ firstName, lastName, memberId, reason, photo }) => (
              <Container key={memberId}>
                <Wrapper>
                  <Label>Excluded: </Label>
                  <Wrapper>
                    <RotaAvatar alt="member photo" src={photo} />
                    <Text isMarginLeft>{`${firstName} ${lastName}`}</Text>
                  </Wrapper>
                </Wrapper>
                <Wrapper>
                  <Label>Reason: </Label>
                  <Text>{getExlusionReason(reason)}</Text>
                </Wrapper>
              </Container>
            )
          )}
          <RotaDivider />
        </>
      ) : null}
    </>
  );
};

export default ViewExclusions;

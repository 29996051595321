import styled from "styled-components";
import { RotaChip } from "@teamrota/rota-design";

export const StyledChipsDropdown = styled.div`
  width: fit-content;
  position: relative;
  margin: 10px 0 0;
  border-radius: 4px;
  z-index: 1;

  & > :first-child .chip-wrapper {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  & > :last-child .chip-wrapper {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

export const StyledChipInDropdown = styled(RotaChip)`
  position: relative;
  width: 100%;

  .chip-wrapper {
    width: 100%;
    border-radius: 0;
    margin: -1px 0 0;
  }
`;

import { useCallback } from "react";
import { gql, useMutation } from "@apollo/client";

const CREATE_UPDATE_OWNER = gql`
  mutation createOrUpdateVenueOwner(
    $partnerId: ID!
    $venueId: ID!
    $userId: ID!
  ) {
    createOrUpdateVenueOwner(
      partnerId: $partnerId
      venueId: $venueId
      userId: $userId
    ) {
      id
    }
  }
`;

export const useCreateUpdateOwner = () => {
  const [createOrUpdateVenueOwner] = useMutation(CREATE_UPDATE_OWNER, {
    refetchQueries: ["getVenues", "getVenueOwnerExclusionsAndTags"]
  });

  const createOrUpdateOwner = useCallback(
    (partnerId, venueId, userId) =>
      createOrUpdateVenueOwner({
        variables: {
          partnerId,
          venueId,
          userId
        }
      }),
    [createOrUpdateVenueOwner]
  );

  return { createOrUpdateOwner };
};

import React from "react";
import { useQuery } from "@apollo/client";

import Tags from "../../components/tag-input-suggestions";

import {
  SHIFT_TAGS_SUGGESTIONS,
  GET_ACCOUNT_ID,
  GET_TARGET_ACCOUNT
} from "./graphql/tag-queries";

const TagsContainer = ({
  isFieldArray,
  fields,
  addedTags,
  placeholder,
  readOnly,
  isInputDisabled,
  isSingleLine,
  emptyMessage,
  onAddTag,
  onDeleteTag,
  accountId
}) => {
  const allTags = isFieldArray ? fields.getAll() : addedTags;

  const { accountIdData } = useQuery(GET_ACCOUNT_ID, {
    fetchPolicy: "network-only"
  });

  const { data: shiftTagsData, refetch } = useQuery(SHIFT_TAGS_SUGGESTIONS, {
    fetchPolicy: "network-only",
    variables: {
      accountId: accountIdData?.account?.id
    }
  });

  const { data: targetAccountData } = useQuery(GET_TARGET_ACCOUNT, {
    fetchPolicy: "network-only",
    variables: {
      accountId
    }
  });

  const targetAccountRequesterTags =
    targetAccountData?.account?.targetAccount?.tags;
  const availableProviderAccountTags =
    shiftTagsData?.shiftTagsSuggestions?.suggestions;

  const addTags = (tag, tags) => {
    if (tags.some(availableTag => availableTag.name === tag?.name)) return null;
    if (isFieldArray) {
      fields.push(tag);
    } else {
      onAddTag(tag);
    }
  };

  return (
    <Tags
      refetch={refetch}
      tags={allTags || []}
      suggestions={
        targetAccountRequesterTags?.length
          ? targetAccountRequesterTags
          : availableProviderAccountTags
      }
      placeholder={placeholder}
      isInputDisabled={readOnly || isInputDisabled}
      isSingleLine={isSingleLine}
      emptyMessage={emptyMessage}
      onAddTag={(tag, tags) => addTags(tag, tags)}
      onDeleteTag={index => {
        if (isFieldArray) {
          if (!readOnly) {
            fields.remove(index);
          }
        } else {
          onDeleteTag(index, addedTags);
        }
      }}
    />
  );
};

export default TagsContainer;

import styled from "styled-components";

export const StyledHoursWorkedContainer = styled.div`
  margin-top: 6px;
  display: flex;
  justify-content: start;
  align-items: center;
`;

export const StyledHoursWorkedText = styled.span`
  font-size: 12px;
  color: #939ba7;
  margin-left: 5px;
`;

import React from "react";

import Loading from "~/src/components/loading";
import CenterScreen from "../center-screen";

export default () => (
  <CenterScreen>
    <Loading color="black" />
  </CenterScreen>
);

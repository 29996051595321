import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const Wrapper = styled.div`
  min-height: ${STYLE_CONSTS.UNIT_5};
  margin-bottom: ${({ isSingleLine }) =>
    isSingleLine ? STYLE_CONSTS.UNIT_1 : STYLE_CONSTS.UNIT_3};
  display: inline-block;
  width: ${({ isSingleLine, width }) =>
    isSingleLine ? "100%" : width || "50%"};
  vertical-align: top;
  padding-right: ${({ isSingleLine }) =>
    isSingleLine ? 0 : STYLE_CONSTS.UNIT_2};
`;

export const Label = styled.div`
  vertical-align: top;
  color: ${({ theme }) => theme.neutral.main};
  font-size: ${({ isSingleLine }) =>
    isSingleLine ? STYLE_CONSTS.FONT_MEDIUM : "inherit"};
  width: ${({ isSingleLine }) => (isSingleLine ? "40%" : "100%")};
  display: ${({ isSingleLine }) => (isSingleLine ? "inline-block" : "block")};
`;

export const ErrorText = styled.div`
  height: ${STYLE_CONSTS.UNIT_2};
  font-size: ${STYLE_CONSTS.FONT_XSMALL};
  color: ${({ theme }) => theme.error.main};
`;

import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";

const GET_TAGS_FOR_ACCOUNT = gql`
  query getTagsForAccount(
    $roleRateAccountId: ID
    $roleRateId: ID
    $partnerAccountId: ID
  ) {
    account {
      id
      sourceAccounts {
        accountName
      }
      tagsAvailableForShift(roleRateAccountId: $roleRateAccountId) {
        id
        name
      }
      tagsCompulsoryForShift(
        roleRateAccountId: $roleRateAccountId
        roleRateId: $roleRateId
        partnerAccountId: $partnerAccountId
      ) {
        id
        name
      }
    }
  }
`;

export const useTagsForAccount = ({
  roleRateId,
  roleRateAccountId,
  partnerAccountId
}) => {
  const { data, loading, error } = useQuery(GET_TAGS_FOR_ACCOUNT, {
    fetchPolicy: "cache-and-network",
    skip: !roleRateId,
    variables: {
      roleRateId,
      roleRateAccountId,
      partnerAccountId
    },
    onError: error => {
      throw error;
    }
  });

  const tagsAccountName = data?.account?.sourceAccounts?.[0]?.accountName ?? "";
  const tagsAvailableForShift = data?.account?.tagsAvailableForShift ?? [];
  const tagsCompulsoryForShift = data?.account?.tagsCompulsoryForShift ?? [];

  return {
    tagsAccountName,
    tagsAvailableForShift,
    tagsCompulsoryForShift,
    loading,
    error
  };
};

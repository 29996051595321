import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

export const CREATE_OR_UPDATE_SHIFT = gql`
  mutation createOrUpdateShift(
    $id: ID
    $numberRequested: Int
    $name: String
    $identifier: String
    $startTime: Date
    $endTime: Date
    $uniformTemplateId: ID
    $venueId: ID
    $briefing: String
    $meetingPoint: String
    $directions: String
    $policy: PolicyInputType
    $isPartnerManaged: Boolean
    $isDraft: Boolean
  ) {
    createOrUpdateShift(
      shouldSkipTimeValidation: true
      shifts: [
        {
          identifier: $identifier
          name: $name
          id: $id
          numberRequested: $numberRequested
          startTime: $startTime
          endTime: $endTime
          uniformTemplateId: $uniformTemplateId
          venueId: $venueId
          briefing: $briefing
          meetingPoint: $meetingPoint
          directions: $directions
          policy: $policy
          isPartnerManaged: $isPartnerManaged
          isDraft: $isDraft
        }
      ]
    ) {
      id
    }
  }
`;

const createOrUpdateShift = graphql(CREATE_OR_UPDATE_SHIFT, {
  name: "createOrUpdateShift",
  props: props => ({
    createOrUpdateShift: async (shiftId, values, queryToRefetch) => {
      const shiftMutationResult = await props.createOrUpdateShift({
        variables: { ...values, id: shiftId },
        refetchQueries: [queryToRefetch || "getShift"]
      });
      return shiftMutationResult.data.createOrUpdateShift;
    }
  })
});

export default createOrUpdateShift;

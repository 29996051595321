import React, { useEffect, useState } from "react";
import Modal from "~/src/components/modal";

import { RotaButton } from "@teamrota/rota-design";

import { ZENDESK_SUPPORT_LINK } from "~/src/consts";

import {
  Container,
  Header,
  ButtonContainer,
  MessageContainer,
  backgroundColor,
  Image,
  ContactLink,
  Text
} from "./modal-error.styles";

export default ({
  title,
  icon = "error",
  children,
  showHelp = true,
  buttonText,
  autoClose = false,
  onClose
}) => {
  const [currentCountdown, setCurrentCountdown] = useState(10);

  if (autoClose) {
    useEffect(() => {
      if (currentCountdown > 0) {
        const id = setTimeout(() => {
          setCurrentCountdown(currentCountdown - 1);
        }, 1000);

        return () => {
          clearTimeout(id);
        };
      } else {
        onClose();
      }
    });
  }

  const currentButtonText =
    buttonText + (autoClose ? ` (${currentCountdown}s...)` : "");

  return (
    <Modal isOpen isOverlay onClose={onClose} background={backgroundColor}>
      <Container>
        <Header>{title}</Header>
        <MessageContainer>
          <Image src={`/images/${icon}.png`} />
          <Text>{children}</Text>
          {showHelp && (
            <Text>
              If this problem persists, please{" "}
              <ContactLink href={ZENDESK_SUPPORT_LINK} target="_blank">
                contact us
              </ContactLink>
            </Text>
          )}
        </MessageContainer>
        <ButtonContainer>
          <RotaButton variant="outlined" onClick={onClose}>
            {currentButtonText}
          </RotaButton>
        </ButtonContainer>
      </Container>
    </Modal>
  );
};

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding: 0 15px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const Label = styled.h4`
  font-size: 16px;
  font-weight: 500;
  margin-right: 10%;
`;

export const Text = styled.p`
  font-size; 16px;
  margin-left: ${({ isMarginLeft }) => (isMarginLeft ? "5%" : 0)};
  ${({ isBold }) => isBold && ` font-weight: 500; font-size: 18px; `}
`;

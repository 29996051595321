import React from "react";
import { connect } from "react-redux";
import { RotaButton } from "@teamrota/rota-design";

import FormStepSegment from "~/src/components/form-step-segment";
import Textarea from "~/src/components/form-components/textarea";
import Select from "~/src/components/form-components/select";
import { Actions, HeaderTitle, Grow, Header, EditButton } from "../styles";
import {
  useBriefingTemplates,
  useUniformOptions,
  getFormTitle,
  mapStateToProps
} from "./selectors";
import { useValidateRequirements } from "../shared";
import { sortOptionsMakingLastUsedFirst } from "../../utils";

const FormRequirements = props => {
  const isRequirementsValid = useValidateRequirements(props);
  const titles = getFormTitle(props);

  const sortedUniformOptions = sortOptionsMakingLastUsedFirst(
    props.uniformOptions,
    props.lastUsedUniform?.id,
    "identifier"
  );
  const uniformOptions = useUniformOptions({
    uniformOptions: sortedUniformOptions
  });

  const sortedBriefingTemplates = sortOptionsMakingLastUsedFirst(
    props.briefingTemplates,
    props.lastUsedBriefing?.id,
    "identifier"
  );
  const briefingTemplates = useBriefingTemplates({
    briefingTemplates: sortedBriefingTemplates
  });

  const onUpdateBriefing = briefingId => {
    const selectedBriefing = briefingTemplates.find(
      option => option.value === briefingId
    );

    const briefing = selectedBriefing.content;
    const fixedLabel = selectedBriefing.label;

    props.handleShiftUpdate({
      briefing,
      briefingId,
      privates: {
        ...props.shift.privates,
        fixedLabel
      }
    });
  };

  return (
    <FormStepSegment
      isOpen={props.isOpen}
      stepNumber={props.stepNumber}
      title={() => (
        <Header>
          {props.hasVisited && !props.isOpen ? (
            <Grow>
              {titles.map((title, index) => (
                <HeaderTitle key={index}>{title}</HeaderTitle>
              ))}
            </Grow>
          ) : (
            <HeaderTitle>Uniform & Briefing</HeaderTitle>
          )}
          {props.hasVisited && !props.isOpen && (
            <EditButton onClick={props.goToSelf}>Edit</EditButton>
          )}
        </Header>
      )}
    >
      {() => (
        <div>
          <Select
            label="Uniform"
            shouldFixLabelHeight
            placeholder="Select a Uniform Template"
            value={props.shift.uniformTemplateId}
            options={uniformOptions}
            onChange={uniformTemplateId => {
              props.handleShiftUpdate({ uniformTemplateId });
            }}
            isSmall
          />

          <Select
            label="Briefing"
            shouldFixLabelHeight
            options={briefingTemplates}
            placeholder="Select a Briefing Template"
            onChange={onUpdateBriefing}
            value={props.shift.briefingId}
            isSmall
          />

          <Textarea
            value={props.shift.briefing}
            minRows={4}
            maxRows={4}
            isMultilineAuto
            overrideOrange
            isExtendable
            onChangeValue={briefing => {
              props.handleShiftUpdate({ briefing });
            }}
          />

          {isRequirementsValid && (
            <Actions gutterTop justify="center">
              <RotaButton onClick={props.goToNext}>
                {props.hasCompletedAllSteps ? "Done" : "Next"}
              </RotaButton>
            </Actions>
          )}
        </div>
      )}
    </FormStepSegment>
  );
};

export default connect(mapStateToProps)(FormRequirements);

import { createSelector } from "reselect";
import moment from "moment-timezone";
import getOr from "lodash/fp/getOr";

import { DATE_FORMAT } from "~/src/containers/requester-calendar/ShiftCreateModal/selectors.js";

export const useUpdateTime = createSelector(
  [props => props.handleShiftUpdate, props => props.shift],
  (handleShiftUpdate, shift) => (dateType, currentDate, updateVal) => {
    const timekey = moment(currentDate.startTime).format(DATE_FORMAT);

    let startTime = moment(currentDate.startTime).clone();
    let endTime = moment(currentDate.endTime).clone();

    const startTimeHours = startTime.format("HH:mm");
    const endTimeHours = endTime.format("HH:mm");

    let [hour, minute] = updateVal.split(":");

    // to avoid moment converting the date to next day
    if (parseInt(hour) > 23) {
      hour = "20";
    }
    const newUpdateVal = `${hour}:${minute}`;

    // when startTime is updated
    if (dateType === "startTime") {
      startTime = startTime.set({ hour, minute, second: 0, millisecond: 0 });

      if (newUpdateVal > endTimeHours) {
        //end time becomes NEXT day after start time
        endTime = moment(startTime)
          .clone()
          .add(1, "days")
          .set({ hour: endTime.hour(), minute: endTime.minute() });
      } else {
        //end time becomes SAME day as start time
        endTime = moment(startTime)
          .clone()
          .set({ hour: endTime.hour(), minute: endTime.minute() });
      }
    }

    // when endTime is updated
    if (dateType === "endTime") {
      endTime = endTime.set({ hour, minute, second: 0, millisecond: 0 });

      if (newUpdateVal < startTimeHours) {
        // end time becomes NEXT day after start time
        endTime = moment(startTime)
          .clone()
          .add(1, "days")
          .set({ hour, minute });
      } else {
        // end time becomes SAME day as start time
        endTime = moment(startTime)
          .clone()
          .set({ hour, minute });
      }
    }

    const prevTimes = getOr({}, "times", shift);

    handleShiftUpdate({
      times: {
        ...prevTimes,
        [timekey]: {
          ...prevTimes[timekey],
          startTime,
          endTime
        }
      }
    });
  }
);

import { RotaButtonNew, iconNew as RotaIcon } from "@teamrota/rota-design";
import styled from "styled-components";
import { RotaAccordion } from "@teamrota/rota-design";

const { Edit } = RotaIcon;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const Heading = styled.p`
  font-weight: 500;
  font-size: 20px;
  margin: 35px 0 30px;
`;

export const VenueName = styled.h1`
  margin-left: 50px;
`;

export const StyledSaveButton = styled(RotaButtonNew)`
  float: right;
  margin-top: 150px;
`;

export const StyledRotaAccordion = styled(RotaAccordion)`
  margin-top: 35px;
`;

export const StyledEditButton = styled(Edit)`
  display: flex;
  margin-left: auto;
  margin-right: 15px;
`;

import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const StyledOpenShift = styled.div`
  width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 80%;
  box-shadow: 5px 15px 15px -10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  background: ${({ backgroundColor }) => backgroundColor};
  border: ${({ isDraft }) => (isDraft ? "2px dashed" : "2px solid")};
  border-color: ${({ borderColor }) => borderColor};
  border-radius: 5px;
  padding: ${({ padding }) => padding || "5px"};
  line-height: ${({ lineHeight }) => lineHeight};
  overflow: ${({ isMultipleShifts }) =>
    isMultipleShifts ? "visible" : "hidden"};
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: ${({ margin }) => margin || "0 1px"};
  cursor: pointer;
  position: absolute;
  top: ${({ isMultipleShifts }) => (isMultipleShifts ? "7%" : "10%")};
  left: ${({ isMultipleShifts }) => (isMultipleShifts ? "2%" : "5%")};

  &:after {
    display: ${({ isMultipleShifts }) => (isMultipleShifts ? "block" : "none")};
    position: absolute;
    z-index: -1;
    top: 5px;
    left: 5px;
    content: "";
    width: 100%;
    height: 100%;
    background: ${({ backgroundColor }) => backgroundColor};
    border: ${({ isDraft }) => (isDraft ? "2px dashed" : "2px solid")};
    border-color: ${({ borderColor }) => borderColor};
    border-radius: 5px;
    opacity: 0.8;
  }
`;

export const Div = styled.div`
  display: flex;
  width: ${({ width }) => width};
  justify-content: center;
  align-items: center;
  line-height: ${({ lineHeight }) => lineHeight};
  color: ${({ color, theme }) => color || theme.text.primary};
  font-size: ${({ fontSize }) => fontSize || STYLE_CONSTS.FONT};
  font-weight: ${({ fontWeight }) => fontWeight || STYLE_CONSTS.FONT_WEIGHT};
  text-transform: ${({ textTransform }) => textTransform};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  text-overflow: ellipsis;
  white-space: ${({ wrap }) => wrap || "nowrap"};
`;

import React from "react";

import { Definite, Possible } from "./styles";

interface RuleNameProps {
  name: string;
  isRate?: boolean;
  isPossible?: boolean;
  color?: string;
  id?: string;
  payrollRateCards?: any[];
}

const RuleName: React.FC<RuleNameProps> = ({
  name,
  isRate,
  isPossible,
  color,
  id,
  payrollRateCards
}) => {
  const payrollRateCard = payrollRateCards?.find?.(rc => rc.id === id);

  const handleClick = () => {
    // figure out if we can do anything for rates here but they may be in one of three multiple places
    if (!isRate) {
      window.open(
        `/payroll/rules/${payrollRateCard.payrollRule.id}?search=${name}`
      );
    }
  };

  if (!name) {
    return <>-</>;
  }

  if (isPossible) {
    return (
      <Possible onDoubleClick={handleClick} color={color}>
        {name}
      </Possible>
    );
  } else {
    return (
      <Definite onDoubleClick={handleClick} color={color}>
        {name}
      </Definite>
    );
  }
};

export default RuleName;

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { PAGESCROLL_ELEMENT_ID } from "~/src/consts";
import TermsAndConditions from "~/src/components/terms-and-conditions";

import { PageScrollViewDiv } from "./index.styles";

export default ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    const me = document.getElementById(PAGESCROLL_ELEMENT_ID);
    if (me) me.scrollTop = 0;
  }, [pathname]);

  return (
    <PageScrollViewDiv id={PAGESCROLL_ELEMENT_ID}>
      {children}
      <TermsAndConditions />
    </PageScrollViewDiv>
  );
};

import React, { useState } from "react";
import { DOCUMENT_TYPES } from "~/src/consts";
import uploadFile from "~/src/services/upload-file";
import { errorModal } from "~/src/utils/errors";
import DocumentUpload from "~/src/components/document-upload";
import DocumentImage, { LoadingImage } from "~/src/components/document-image";
import deleteItem from "~/src/graphql/mutations/delete-item/delete-item.decorator";
import asyncConfirm from "~/src/utils/async-confirm";
import { FilesContainer, Scrollable, Title } from "./user-documents.styles";

const UserDocuments = ({
  targetAccountId,
  documents,
  refetchTargetAccount,
  deleteItem
}) => {
  const [isSaving, setIsSaving] = useState(null);
  const [isUploadSizeError, setIUploadSizeError] = useState(false);

  const handleUploadFile = async (type, id, opts) => {
    setIUploadSizeError(false);
    setIsSaving(type);
    try {
      let selectedFile;
      if (opts && opts.alternativeSelection) {
        selectedFile = opts.alternativeSelection;
      } else {
        [selectedFile] = document.getElementById(id).files;
      }
      const name = selectedFile.name;
      const sizeInMB = (selectedFile?.size / (1024 * 1024)).toFixed(2);
      if (parseInt(sizeInMB) <= 10) {
        await uploadFile({
          file: selectedFile,
          type,
          id: targetAccountId,
          table: "accounts",
          fileName: name
        });
        await refetchTargetAccount();
      } else {
        setIUploadSizeError(true);
        setIsSaving(null);
        return null;
      }
    } catch (e) {
      alert("File upload error. Please try again");
      setIsSaving(null);

      errorModal(e);
    }
    setIsSaving(null);
  };

  const handleDeleteDocument = async (uploadId, format) => {
    const tableType = format === "application/pdf" ? "documents" : "uploads";
    try {
      if (
        await asyncConfirm("Are you sure you want to delete this?", {
          falseButtonText: "Cancel",
          confirmButtonText: "Delete"
        })
      ) {
        await deleteItem({
          variables: {
            type: tableType,
            id: uploadId
          }
        });
        await refetchTargetAccount();
      }
    } catch (e) {
      errorModal(e);
    }
  };

  const handlePDFOpen = pdfUrl => (window.location = pdfUrl);

  return (
    <Scrollable>
      <Title>Documents</Title>
      <FilesContainer>
        <DocumentUpload
          type={DOCUMENT_TYPES?.ADDITIONAL_USER_DOCUMENT}
          onChange={handleUploadFile}
          accept="image/*,.pdf"
          isUploadSizeError={isUploadSizeError}
        />

        {isSaving === DOCUMENT_TYPES?.ADDITIONAL_USER_DOCUMENT && (
          <LoadingImage />
        )}

        {documents?.map((item, index) => (
          <DocumentImage
            key={index}
            src={item?.url}
            fileType={item?.format.includes("pdf") ? "PDF" : "IMAGE"}
            onPdfOpen={handlePDFOpen}
            fileName={item.fileName}
            onDelete={() => handleDeleteDocument(item?.id, item?.format)}
          />
        ))}
      </FilesContainer>
    </Scrollable>
  );
};

export default deleteItem(UserDocuments);

import React from "react";
import Icon from "~/src/components/icon";
import { MEMBER_STATES } from "~/src/consts";
import {
  Bar,
  FlexContainer,
  RatingContainer,
  Label,
  CountContainer,
  Count,
  BarContainer,
  Rating,
  IconWrapper
} from "./rating-graph.style";

export default function RatingsGraph(props) {
  const ratings = [...(props.ratings || [])];
  while (ratings.length < 6) ratings.unshift(0);
  const isHiddenDueToOnboarding = props.memberState === MEMBER_STATES.PENDING;

  if (isHiddenDueToOnboarding) {
    return null;
  }
  return (
    <RatingContainer>
      <Label>{props.label}</Label>
      <FlexContainer>
        {ratings.map((rating, i) => (
          <BarContainer key={i}>
            <Bar isOverlay rating={rating} />
            <Bar rating={rating} />
          </BarContainer>
        ))}
        <CountContainer>
          <Count>
            <IconWrapper>X</IconWrapper>
            <Rating>{props.totalRatings}</Rating>
          </Count>
          <Count>
            <Icon size={Icon.sizes.XSMALL} name={Icon.names.STAR_FILLED} />
            <Rating>{props.averageRating}</Rating>
          </Count>
        </CountContainer>
      </FlexContainer>
    </RatingContainer>
  );
}

import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { RotaModal, RotaButtonNew, RotaSnackBar } from "@teamrota/rota-design";
import ARCHIVE_TARGET_ACCOUNT from "~/src/containers/modals/view-edit-account/graphql/archive-target-account";

import {
  StyledBinIconWrap,
  StyledMainText,
  StyledRedBar,
  StyledSubText,
  StyledButtonWrapper
} from "~/src/containers/roles/styles";

const DeleteAlertDialog = ({ setIsOpenDialog, account, onClose }) => {
  const [snackbar, setSnackbar] = useState({});
  const [archiveTargetAccount, { error }] = useMutation(ARCHIVE_TARGET_ACCOUNT);

  const handleDeleteAccount = async () => {
    await archiveTargetAccount({
      variables: {
        id: account.id
      },
      refetchQueries: ["getTargetAccount", "getProfile"]
    });
    if (error) {
      setSnackbar({
        open: true,
        message: "something went wrong. Try again",
        severity: "error"
      });
    } else {
      setIsOpenDialog(false);
      onClose();
    }
  };

  return (
    <RotaModal isSmall onClose={() => setIsOpenDialog(false)}>
      <StyledBinIconWrap>
        <img src={"/images/bin.svg"} />
      </StyledBinIconWrap>
      <StyledMainText>
        Are you sure you want to delete this account?
      </StyledMainText>
      <StyledRedBar />
      <StyledSubText>Deleting this account is not reversible.</StyledSubText>
      <StyledButtonWrapper>
        <RotaButtonNew
          onClick={() => setIsOpenDialog(false)}
          variant="outlined"
        >
          Cancel
        </RotaButtonNew>
        <RotaButtonNew onClick={() => handleDeleteAccount()} isDanger>
          Delete
        </RotaButtonNew>
      </StyledButtonWrapper>
      <RotaSnackBar
        snackOpen={snackbar.open}
        onClose={() => {}}
        message={snackbar.message}
        severity={snackbar.severity}
      />
    </RotaModal>
  );
};

export default DeleteAlertDialog;

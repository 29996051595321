import React, { useEffect, useState } from "react";

import AceEditor from "react-ace";
import { Ace } from "ace-builds";

// eslint-disable-next-line import/no-unassigned-import
import "ace-builds/src-noconflict/mode-json5";

// eslint-disable-next-line import/no-unassigned-import
import "ace-builds/src-noconflict/ext-language_tools";

// eslint-disable-next-line import/no-unassigned-import
import "ace-builds/src-noconflict/ext-searchbox";

interface JsonEditorProps {
  name: string;
  canEdit: boolean;
  value: string;
  initialSearch?: string;
  height: string;
  onChange: (value: string) => void;
}

const JsonEditor: React.FC<JsonEditorProps> = ({
  name,
  value,
  initialSearch,
  height,
  canEdit,
  onChange
}) => {
  const [editor, setEditor] = useState<Ace.Editor | undefined>(undefined);

  useEffect(() => {
    if (editor !== undefined) {
      editor.moveCursorTo(0, 0);
      editor.focus();

      if (initialSearch !== undefined) {
        editor.find(initialSearch, {
          wrap: true,
          caseSensitive: true,
          wholeWord: true,
          regExp: false,
          preventScroll: false
        });

        editor.execCommand("find");
      }
    }
  }, [editor]);

  return (
    <AceEditor
      readOnly={!canEdit}
      onLoad={editor => setEditor(editor)}
      mode="json5"
      value={value}
      fontSize={14}
      onChange={newValue => onChange(newValue)}
      name={name}
      editorProps={{ $blockScrolling: true }}
      style={{ width: "100%", border: "solid 1px black" }}
      height={height}
      setOptions={{
        showLineNumbers: true,
        tabSize: 2
      }}
      enableBasicAutocompletion={true}
      enableLiveAutocompletion={true}
      commands={[
        {
          name: "find",
          bindKey: { win: "Ctrl-F", mac: "Command-F" },
          exec: "find"
        }
      ]}
    />
  );
};

export default JsonEditor;

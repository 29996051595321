import { gql } from "@apollo/client";

export const GET_SHIFT_BY_ID = gql`
  query getShiftById($shiftId: ID!) {
    account {
      id
      isCasualMemberTypeRatings
      isPermanentMemberTypeRatings
      isAgencyMemberTypeRatings
      isContractorLimitedCompanyMemberTypeRatings
      isContractorUmbrellaCompanyMemberTypeRatings
      isContractorSelfEmployedMemberTypeRatings
      isPayCalculationOnCheckInOutTimes
      shiftById(shiftId: $shiftId) {
        id
        paidHours
        chargeRate
        bonuses
        totalWithBonus
        totalVat
        totalWithVat
        isDayRate
        extraHours
        extraPaymentValue
        extraPaymentRate
        isUncalculatedRate
        bookings {
          booking {
            id
            isPolicyApplied
            startFinal
            endFinal
            breakFinal
            feedbackForMember
            isWithinProximityAtCheckIn
            checkInMethod
            checkOutMethod
            isNoShow
            isTurnedAway
            chargeRate
            ratingOfMember
            isWithinCancellationPeriod
            payRate
            overtimePayRate
            overtimeChargeRate
            overstayPayRate
            overstayChargeRate
            isDayRate
            isOvertimeRate
            isOverstayRate
            overtimeHoursLimit
            overstayHoursLimit
            isOverstayBooking
            totalWorkedHoursPerWeek
            totalRegularWorkedHoursPerWeek
            isOvertimeBooking
            overtimeHoursWorked
            regularHoursWorked
            startTime
            endTime
          }
          member {
            id
            state
            firstName
            lastName
            photo
            rating
            annualSalary
            memberType
            poolType
          }
          bookingState {
            paidHours
            bonusFinal
            totalWithBonus
            totalVat
            totalWithVat
            isDayRate
            startOnShift
            endOnShift
            startOnShiftAt
            endOnShiftAt
            startOnShiftManager
            endOnShiftManager
          }
        }
      }
    }
  }
`;

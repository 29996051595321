import React from "react";
import ToolTip from "react-tooltip";

import { APPROVAL_STATES } from "~/src/consts";
import BookingManager from "~/src/services/accounting/booking-manager";

import { isMemberSalariedWorker } from "~/src/utils/money/consts";
import { calculateBookingCosts } from "~/src/containers/timesheets/util";

import Icon from "~/src/components/icon";
import Text from "~/src/containers/modals/components/text";

import {
  HeaderBookingInfo,
  HeaderBookingText,
  Flex,
  TooltipContainer
} from "~/src/containers/timesheets/timesheets.styles";

const renderToolTip = () => (
  <TooltipContainer>
    <Text noMarginBottom pointer data-tip data-for="predict-tooltip">
      <Icon name={Icon.names.INFO} size="smallmid" />
    </Text>
    <ToolTip multiline id="predict-tooltip" effect="solid" place="right">
      * Predicted cost: Due to unapproved timesheets we cannot provide you with
      a definitive cost until all timesheets have been approved.
    </ToolTip>
  </TooltipContainer>
);

// Boolean based on whether current set of shifts contain
// any timesheets which have not been approved yet
const hasPendingTimesheets = shifts => {
  const areAllShiftsApproved = shifts?.every(
    shift =>
      shift.approvalState === APPROVAL_STATES.PENDING_MEMBER ||
      shift.approvalState === APPROVAL_STATES.APPROVED
  );
  return !areAllShiftsApproved;
};

export default ({
  shifts,
  shiftBookingCount,
  shiftBookingCountTotalCost,
  isLoading,
  displayIfNotLoading,
  setPrintVariables
}) => {
  if (!shifts) return null;
  const hasPending = hasPendingTimesheets(shifts);

  const bookings = shifts?.reduce(
    (acc, shift) => [
      ...acc,
      ...(shift.bookings ? shift.bookings.data : [shift])
    ],
    []
  );

  const { costInPounds, vatInCost } = calculateBookingCosts(
    shiftBookingCountTotalCost
  );

  const unsalariedBookings = bookings?.filter(
    booking =>
      !isMemberSalariedWorker(
        booking?.member?.annualSalary,
        booking?.member?.memberType
      )
  );

  const grandManager = new BookingManager(
    `containers timesheets [${unsalariedBookings?.map(({ id }) => id)}]`,
    unsalariedBookings
  );

  setPrintVariables({
    costBefore: costInPounds ? costInPounds.toFormat() : null,
    costAfter: costInPounds ? costInPounds.add(vatInCost).toFormat() : null,
    bookingCount: shiftBookingCount || "Error"
  });

  return (
    <>
      <HeaderBookingInfo>
        <Flex>
          <HeaderBookingText isBold>Total cost </HeaderBookingText>
          {hasPending && renderToolTip()}
        </Flex>
        {displayIfNotLoading(
          <HeaderBookingText cloudyBlue>
            {costInPounds.add(grandManager?.totalBonus).toFormat()}
          </HeaderBookingText>,
          isLoading
        )}
      </HeaderBookingInfo>

      <HeaderBookingInfo>
        <Flex>
          <HeaderBookingText isBold inline>
            Total cost inc VAT
          </HeaderBookingText>
          {hasPending && renderToolTip()}
        </Flex>
        {displayIfNotLoading(
          <HeaderBookingText cloudyBlue>
            {costInPounds
              .add(vatInCost)
              .add(grandManager?.totalBonus)
              .add(grandManager?.bonusVAT)
              .toFormat()}
          </HeaderBookingText>,
          isLoading
        )}
      </HeaderBookingInfo>
    </>
  );
};

import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  typography,
  RotaButton,
  RotaModal,
  colors,
  RotaSnackBar
} from "@teamrota/rota-design";

import useAuth from "~/src/auth/hooks/use-auth";
import { roleLabel, shiftTimes } from "~/src/utils/formatting";
import { toDateRange } from "~/src/utils/date-range";

import StaffZonesPanel from "~/src/components/StaffZonesPanel";

import { GET_SHIFT_WITH_BOOKINGS } from "../graphql/get-shift-with-bookings";
import { REQUEST_ASSIGN_MEMBERS } from "../graphql/request-assign-members";

import ConfirmModal from "./confirm-modal";

import {
  StyledHeader,
  StyledWrapButtons,
  StyledSubHeader
} from "./request-assign-modal.styles";

import { BOOKING_STATES } from "~/src/consts";
import { useTheme } from "styled-components";

import { useStore } from "~/src/useStore";

const RequestAndAssignModal = ({
  onClose,
  shiftId,
  venueId,
  roleRateId,
  refetch,
  dates,
  shiftType,
  sourceAccountId
}) => {
  const theme = useTheme();
  const { StyledSmallText } = typography;

  const replacementBookings = useStore(state => state.replacementBookings);

  const auth = useAuth();

  const [newAssignedBookings, setNewAssignedBookings] = useState([]);
  const [newRequestedBookings, setNewRequestedBookings] = useState([]);

  const [snack, setSnack] = useState({});
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);

  const { loading, data } = useQuery(GET_SHIFT_WITH_BOOKINGS, {
    variables: auth.addVals(GET_SHIFT_WITH_BOOKINGS, {
      bookingsOffset: 0,
      bookingsLimit: 10,
      shiftId,
      dates,
      shiftType,
      roleRateId,
      venueId,
      sourceAccountId
    }),
    fetchPolicy: "network-only"
  });

  // XXX POOL_TYPES.BLACKLISTED

  const account = data?.account;
  const shift = data?.account?.shift;
  const maxAssigned = shift
    ? Math.floor(
        shift?.numberRequested +
          (shift?.numberRequested * shift?.overbookPercentage) / 100
      )
    : 0;

  const selectedAccountId = shift?.sourceAccount?.id;

  const [createBookings] = useMutation(REQUEST_ASSIGN_MEMBERS);

  useEffect(() => {
    if (snack?.message && !snack?.noClose) {
      setTimeout(() => {
        setSnack({});
      }, 5000);
    }
  }, [snack]);

  const resetBookingsState = () => {
    setNewRequestedBookings([]);
    setNewRequestedBookings([]);
  };

  const saveChanges = async () => {
    try {
      setSnack({
        message: "Saving changes...",
        severity: "warning",
        noClose: true
      });

      const members = [...newAssignedBookings, ...newRequestedBookings].filter(
        member =>
          !replacementBookings.some(booking => booking.memberId === member.id)
      );

      await createBookings({
        variables: {
          shiftId,
          members,
          replacementBookings
        }
      });

      resetBookingsState();
      setSnack({ message: "Changes saved", severity: "success" });
      refetch();
    } catch (err) {
      setSnack({
        message: `Something went wrong - ${err.message}`,
        severity: "error"
      });
    } finally {
      setIsOpenCancelModal(false);
      setTimeout(() => {
        onClose();
      }, 2000);
    }
  };

  const cancelChanges = async () => {
    resetBookingsState();
    setIsOpenCancelModal(false);
    onClose();
  };

  const handleClose = async () => {
    if (newAssignedBookings.length > 0 || newRequestedBookings.length > 0) {
      setIsOpenCancelModal(true);
    } else onClose();
  };

  const handleAssign = assignedMemberIds =>
    setNewAssignedBookings(
      assignedMemberIds.map(id => ({ id, isAssigned: true }))
    );

  const handleRequest = requestedMemberIds =>
    setNewRequestedBookings(
      requestedMemberIds.map(id => ({ id, isRequested: true }))
    );

  if (loading) {
    return false;
  }

  return (
    <>
      {isOpenCancelModal && (
        <ConfirmModal
          handleSave={saveChanges}
          handleCancel={cancelChanges}
          handleClose={() => setIsOpenCancelModal(false)}
        />
      )}
      <RotaModal
        onClose={onClose}
        name={shift && (shift.name || shift.sourceAccount.accountName)}
      >
        {shift && (
          <>
            <StyledHeader>
              <StyledSmallText
                color={theme.text.secondary}
                style={{ fontSize: "13px" }}
              >
                Place: <StyledSubHeader>{shift?.venue?.name}</StyledSubHeader>
              </StyledSmallText>
              <StyledSmallText
                color={theme.text.secondary}
                style={{ fontSize: "13px" }}
              >
                Number of employees needed:{" "}
                <StyledSubHeader>
                  {` ${roleLabel(
                    shift.roleRate.roleName,
                    undefined,
                    parseInt(shift.numberRequested, 10)
                  )}`}
                </StyledSubHeader>
              </StyledSmallText>
              <StyledSmallText
                color={theme.text.secondary}
                style={{ fontSize: "13px" }}
              >
                Time and date:{" "}
                <StyledSubHeader>
                  {`${shiftTimes(shift.startTime, shift.endTime)}`}{" "}
                </StyledSubHeader>
              </StyledSmallText>
            </StyledHeader>

            {account && shift && (
              <StaffZonesPanel
                isProvide
                shiftId={shift.id}
                isShiftCancelled={shift.cancelledAt}
                targetAccountId={account.id}
                selectedAccountId={selectedAccountId}
                roleRateId={roleRateId}
                memberType={null}
                venueId={venueId}
                dates={[toDateRange(shift)]}
                shiftType={shift.type}
                maxAssigned={maxAssigned}
                acceptedBookings={shift.accepted.data.filter(
                  booking => booking.state == BOOKING_STATES.ACCEPTED
                )}
                requestedBookings={shift.requested.data}
                declinedBookings={shift.declined.data}
                onRequest={handleRequest}
                onAssign={handleAssign}
                tags={shift.tags}
                hasShiftUnfilledCancelledBookings={
                  shift?.cancelledBookings?.totalResults > 0
                }
                totalNumberOfCancelledBookings={
                  shift?.cancelledBookings?.totalResults
                }
              />
            )}

            <StyledWrapButtons>
              <RotaButton
                style={{
                  backgroundColor: colors.lightGrey,
                  borderColor: colors.lightGrey
                }}
                variant="secondary"
                onClick={handleClose}
              >
                Cancel
              </RotaButton>
              <RotaButton
                disabled={
                  newRequestedBookings.length === 0 &&
                  newAssignedBookings.length === 0
                }
                onClick={saveChanges}
              >
                Save
              </RotaButton>
            </StyledWrapButtons>

            <RotaSnackBar
              snackOpen={!!snack?.message}
              severity={snack?.severity}
              message={snack?.message}
              onClose={() => setSnack({})}
            />
          </>
        )}
        {!shift && false}
      </RotaModal>
    </>
  );
};

export default RequestAndAssignModal;

import React, { useState } from "react";

import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";

import getUserStats from "../gql/get-user-stats";
import getMemberStats from "../gql/get-member-stats";
import getUnassigned from "../gql/get-unassigned";

import uploadFile from "~/src/services/upload-file";
import { DOCUMENT_TYPES } from "~/src/consts";
import { LoadingImage } from "~/src/components/document-image";
import { errorModal } from "~/src/utils/errors";
import Loading from "~/src/components/loading";

import styles, { StyledIcon } from "./left-panel.styles";
import { Tooltip } from "@material-ui/core";
import { flow } from "lodash";
import { refreshUser } from "~/src/auth/manager";

export const INPUT_IDS = {
  [DOCUMENT_TYPES.MEMBER_PHOTO]: "file-input-member-photo",
  [DOCUMENT_TYPES.USER_PHOTO]: "file-input-user-photo",
  [DOCUMENT_TYPES.ADDITIONAL_MEMBER_DOCUMENT]:
    "file-input-additional-member-document",
  [DOCUMENT_TYPES.RIGHT_TO_WORK]: "file-input-right-to-work",
  [DOCUMENT_TYPES.ACCOUNT_LOGO]: "file-input-account-logo",
  [DOCUMENT_TYPES.ACCOUNT_BANNER]: "file-input-account-banner"
};

const LeftPanel = ({
  user,
  refetchProfile,
  activeUsersCount,
  schedule,
  newUsersCount,
  isUsersCountLoading,
  activeMembersCount,
  membersCount,
  isMembersCountLoading
}) => {
  const [saving, setSaving] = useState(null);

  const {
    account: { accountName, venues },
    photo,
    firstName,
    lastName
  } = user;

  const handleUploadFile = async (type, id) => {
    setSaving(type);
    try {
      const selectedFile = document.getElementById(id)?.files[0];
      await uploadFile({
        file: selectedFile,
        type,
        id: user.id,
        table: "users"
      });
      await refetchProfile();
      refreshUser(); // also force update of oidc user-info
    } catch (e) {
      errorModal(e);
    } finally {
      setSaving(null);
    }
  };

  const isProvider = user?.roles?.indexOf("PROVIDER") >= 0;

  return (
    <div style={styles.leftPanelContainer} id="left-panel-container">
      <div id="avatar-container" style={styles.avatarContainer}>
        <div style={styles.avatar} id="avatar">
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            badgeContent={
              <>
                <input
                  accept="image/*"
                  style={styles.input}
                  type="file"
                  name="file"
                  id={INPUT_IDS[DOCUMENT_TYPES.USER_PHOTO]}
                  onChange={() =>
                    handleUploadFile(
                      DOCUMENT_TYPES.USER_PHOTO,
                      INPUT_IDS[DOCUMENT_TYPES.USER_PHOTO]
                    )
                  }
                />
                <label htmlFor={INPUT_IDS[DOCUMENT_TYPES.USER_PHOTO]}>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <span style={styles.iconWrapper}>
                      <StyledIcon name="CAMERA" size="MEDIUM" color="white" />
                    </span>
                  </IconButton>
                </label>
              </>
            }
          >
            {saving === DOCUMENT_TYPES.USER_PHOTO && <LoadingImage isRound />}

            <Avatar
              alt={firstName}
              src={photo}
              style={{ height: "117px", width: "117px" }}
            />
          </Badge>
        </div>
        <div>
          <span id="member-name" style={styles.memberName}>
            {firstName} {lastName}
          </span>
          <br />
          <span id="member-address" style={styles.memberAddress}>
            {accountName}
          </span>
        </div>
      </div>

      <div id="venues-row" style={styles.settingsStats}>
        {venues?.length > 0 && (
          <div id="row-wrapper" style={styles.rowWrapper}>
            <span style={styles.statsTitle}>Venues</span>
            <span style={styles.statsNumber}>{venues?.length + ""}</span>
          </div>
        )}

        {!venues && <Loading color="black" />}
      </div>

      <div id="users-row" style={styles.settingsStats}>
        <div id="row-wrapper" style={styles.rowWrapper}>
          <span style={styles.statsTitle}>Users</span>
          <span style={styles.statsNumber}>{activeUsersCount}</span>
          {isUsersCountLoading && <Loading color="black" />}
        </div>
      </div>
      <div id="newly-registered-row" style={styles.settingsStats}>
        <div id="row-wrapper" style={styles.rowWrapper}>
          <span style={styles.statsTitle}>Newly Registered</span>
          <Tooltip
            title="Created within the last 14 days"
            aria-label="new-user-tooltip"
          >
            <span style={styles.statsNumber}>{newUsersCount}</span>
          </Tooltip>
          {isUsersCountLoading && <Loading color="black" />}
        </div>
      </div>
      <div id="users-row" style={styles.settingsStats}>
        <div id="row-wrapper" style={styles.rowWrapper}>
          <span style={styles.statsTitle}>Outstanding Timesheets</span>
          <Tooltip
            title="Number of timesheets awaiting approval"
            aria-label="outstanding-timesheets-tooltip"
          >
            <span style={styles.statsNumber}>
              {user?.account?.totalOutstandingTimesheets + ""}
            </span>
          </Tooltip>
        </div>
      </div>
      {!!isProvider && (
        <div id="users-row" style={styles.settingsStats}>
          <div id="row-wrapper" style={styles.rowWrapper}>
            <span style={styles.statsTitle}>Partners</span>
            <Tooltip title="Number of Partners" aria-label="partners-tooltip">
              <span style={styles.statsNumber}>
                {user?.account?.providerConnections?.totalResults + ""}
              </span>
            </Tooltip>
          </div>
        </div>
      )}
      <div id="users-row" style={styles.settingsStats}>
        <div id="row-wrapper" style={styles.rowWrapper}>
          <span style={styles.statsTitle}>Staff</span>

          <Tooltip title="Number of Staff" aria-label="staff-tooltip">
            <span style={styles.statsNumber}>{membersCount}</span>
          </Tooltip>

          {isMembersCountLoading && <Loading color="black" />}
        </div>
      </div>
      <div id="users-row" style={styles.settingsStats}>
        <div id="row-wrapper" style={styles.rowWrapper}>
          <span style={styles.statsTitle}>Active Staff</span>

          <Tooltip
            title="Number of active Staff"
            aria-label="active-staff-tooltip"
          >
            <span style={styles.statsNumber}>{activeMembersCount}</span>
          </Tooltip>

          {isMembersCountLoading && <Loading color="black" />}
        </div>
      </div>
      <div id="unassigned-row" style={styles.settingsStats}>
        <div id="row-wrapper" style={styles.rowWrapper}>
          <span style={styles.statsTitle}>Unassigned Shifts</span>
          {schedule && (
            <Tooltip
              title="Number of unassigned shifts"
              aria-label="unassigned-shifts-tooltip"
            >
              <span style={styles.statsNumber}>
                {schedule?.unassignedShifts?.[0]?.shifts?.length + ""}
              </span>
            </Tooltip>
          )}
          {!schedule?.unassignedShifts && <Loading color="black" />}
        </div>
      </div>
    </div>
  );
};

export default flow(getUserStats, getMemberStats, getUnassigned)(LeftPanel);

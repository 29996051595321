import React, { useEffect } from "react";
import { PAGESCROLL_ELEMENT_ID } from "~/src/consts";

const END_THRESHOLD = 100;

const DashboardScrollHandler = ({
  children,
  onReachEnd,
  scrollElementId,
  pixelsBeforeReachingEnd
}) => {
  useEffect(() => {
    const dashboardScrollView = getScrollView();
    if (dashboardScrollView) {
      dashboardScrollView.addEventListener("scroll", handleScrollViewScroll);
    }
    return function cleanup() {
      if (dashboardScrollView) {
        dashboardScrollView.removeEventListener(
          "scroll",
          handleScrollViewScroll
        );
      }
    };
  });

  const getScrollView = () =>
    document.getElementById(scrollElementId || PAGESCROLL_ELEMENT_ID);

  const handleScrollViewScroll = e => {
    const scrollTop = e.target.scrollTop + e.target.offsetHeight;
    if (
      scrollTop >=
      e.target.scrollHeight - (pixelsBeforeReachingEnd || END_THRESHOLD)
    ) {
      onReachEnd();
    }
  };

  return <div>{children}</div>;
};

export default DashboardScrollHandler;

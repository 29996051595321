import { gql } from "@apollo/client";

export const GET_CURRENCY_REGION = gql`
  query getAccountId {
    account {
      id
      currencyRegion
    }
  }
`;

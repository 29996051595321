import moment from "moment-timezone";
import { regex } from "@teamrota/rota-common";

const DATE_FORMAT = "DD/MM/YYYY";

export const dateToString = date => moment(date).format(DATE_FORMAT);

export const stringToDate = dateString =>
  moment.utc(dateString, DATE_FORMAT).toDate();

// validates a date string in DD/MM/YYYY
export const isValidDate = date => {
  const correctNumDigits = /^\d{2}\/\d{2}\/\d{4}$/.test(date);
  const validDate = moment(date, DATE_FORMAT).isValid();
  return !date || (correctNumDigits && validDate);
};

export function isValidEmail(email) {
  return regex.EMAIL_ADDRESS.test(email);
}

export function isValidPin(pin) {
  return /^\d{4}$/.test(pin);
}

export function getOs() {
  // eslint-disable-next-line
  const userAgent = window.navigator.userAgent,
    { platform } = window.navigator,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"];
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac OS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (!os && /Linux/.test(platform)) {
    os = "Linux";
  }

  return os;
}

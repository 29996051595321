import moment from "moment-timezone";
import assign from "lodash/fp/assign";
import map from "lodash/fp/map";
import flow from "lodash/fp/flow";
import omit from "lodash/fp/omit";
import set from "lodash/fp/set";
import max from "lodash/fp/max";
import get from "lodash/fp/get";
import getOr from "lodash/fp/getOr";
import head from "lodash/fp/head";
import find from "lodash/fp/find";
import { ZonedDate } from "@teamrota/rota-common";

import Money from "~/src/utils/money";
import MoneyTransaction from "~/src/utils/money/money-transaction";
import { XMAS_RATE } from "~/src/services/surge-pricing";
import { PAGESCROLL_ELEMENT_ID, ROTA_ACCOUNT_ID } from "~/src/consts";
import BookingAccount from "~/src/services/accounting";

import {
  useValidateAudience,
  useValidateRequirements,
  useValidateCalendar
} from "./ShiftCreateModal/shared";
import { matchSleepTimesDayToShiftTimes } from "~/src/utils";

export const getFixedChargeRate = chargeRate =>
  Money({ amount: chargeRate || 0 });

export function createVenueOptions(venues) {
  return venues?.map(venue => ({
    value: venue.id,
    label: venue.name
  }));
}

export function createSubvenueOptions(subvenues) {
  return subvenues?.map(venue => ({
    value: venue.id,
    label: venue.name
  }));
}

export function createUniformOptions(uniformTemplates) {
  return uniformTemplates.map(uniformTemplate => ({
    value: uniformTemplate.id,
    label: uniformTemplate.identifier
  }));
}

export function createBriefingOptions(briefingTemplates) {
  return briefingTemplates.map(briefingTemplate => ({
    value: briefingTemplate.id,
    label: briefingTemplate.identifier,
    content: briefingTemplate.content
  }));
}

/**
 * generates an array used as select option in shift form
 * @param {Object[]} roles Role object
 * @param {String || Number} accountId target account Id
 * @param {Object} shift shift details
 * @param {Boolean} isDayRoles if role is of type Daily
 * @param {Boolean} isUncalculatedRate if role selection is uncalculated
 * @returns {Object[]} Array of Object
 */
export function createRoleRateOptions({
  roleRates,
  accountId,
  shift,
  isDayRoles = false,
  isUncalculatedRate,
  allRoles = false
}) {
  // This groups roleRates into and object with account.accountName as the key.
  // eslint-disable-next-line
  let currentRoles = [...roleRates].filter(roleRate => {
    if (allRoles) {
      return true;
    }
    if (isDayRoles) {
      return roleRate.isDayRate && !isUncalculatedRate;
    }
    if (isUncalculatedRate) {
      return roleRate.isUncalculatedRate;
    }
    return !roleRate.isUncalculatedRate;
  });

  // check if the current shift roleRate is archived and add it if so to the roleOptions
  if (shift && shift.roleRate) {
    if (!roleRates.some(role => role.id === shift.roleRate.id)) {
      currentRoles.push({ ...shift.roleRate, account: roleRates[0].account });
    }
  }
  const newRoles = {};
  currentRoles.forEach(role => {
    newRoles[role.account.accountName] = getOr(
      [],
      `${role.account.accountName}`,
      newRoles
    );
    newRoles[role.account.accountName].push({
      value: role.id,
      label: role.roleName,
      chargeRate: [
        getFixedChargeRate(role.chargeRate),
        getFixedChargeRate(role.chargeRate).multiply(XMAS_RATE)
      ],
      labelPrice: [
        role.chargeRate ? getFixedChargeRate(role.chargeRate).toFormat() : null,
        role.chargeRate
          ? accountId === ROTA_ACCOUNT_ID
            ? getFixedChargeRate(role.chargeRate)
                .multiply(XMAS_RATE)
                .toFormat()
            : getFixedChargeRate(role.chargeRate).toFormat()
          : null
      ]
    });
  });
  return newRoles;
}

// check if the current shift roleRate is archived and add it if so to the roleRates
export const getRoleRates = (roles, shift) => {
  if (shift && shift.roleRate) {
    if (roles && !roles.some(role => role.id === shift.roleRate.id)) {
      return [...roles, { ...shift.roleRate, account: roles[0].account }];
    }
  }
  return roles;
};

export const getHours = policyShift =>
  moment(policyShift.endTime).diff(policyShift.startTime, "hours", true);

export const getIsPolicyApplied = (policy, policyShift) =>
  !!policy && getHours(policyShift) < policy.minimumShiftLength;

export function cleanDataForSave(formData) {
  const { shifts } = formData;
  const { times, isLinkedShifts, isSleepTimesEnabled } = shifts;

  const keysToOmit = [
    "times",
    "privates",
    "isLinkedShifts",
    "selectedSleepTimes"
  ];

  if (!isSleepTimesEnabled) {
    keysToOmit.push("isSleepTimesEnabled");
  }

  const baseShift = omit(keysToOmit, shifts);

  const updatedShifts = Object.values(times).map(shiftTimes => {
    let newShift = {
      ...baseShift,
      ...shiftTimes
    };

    if (isSleepTimesEnabled) {
      const { startTime, endTime } = matchSleepTimesDayToShiftTimes(
        shiftTimes,
        shifts.selectedSleepTimes
      );

      newShift = {
        ...newShift,
        sleepStartTime: startTime,
        sleepEndTime: endTime
      };
    }

    return newShift;
  });

  const transform = flow(
    omit("shiftOpenIndex"),
    set("isLinkedShifts", isLinkedShifts),
    set("shifts", updatedShifts)
  );

  return transform(formData);
}

export function validateShift(shifts) {
  for (const shift of shifts) {
    const errMsg = fieldMsg => {
      throw new Error(`Missing ${fieldMsg} for shift ${shift.name || ""}`);
    };

    if (!shift.numberRequested) errMsg("number of requested staff");
    if (!shift.roleRateId) errMsg("type of staff");
    if (!shift.venueId) errMsg("venue");
    if (!shift.briefing) errMsg("briefing");
    if (!shift.uniformTemplateId) errMsg("dress code");
  }
}

export function setAllErrorsVisible(shifts) {
  return map(set("privates.shouldShowErrors", true), shifts);
}

export function addADay(date) {
  return moment(date)
    .add(1, "days")
    .toDate();
}

export function getFinalShiftTime(formData, time) {
  return formData.shifts[formData.shifts.length - 1][time] || new ZonedDate();
}

export function conditinallyRunPropSync(shifts, isLinkedShifts) {
  if (!isLinkedShifts) return shifts;
  return map(
    shift =>
      assign(head(shifts), {
        startTime: shift.startTime,
        endTime: shift.endTime,
        briefing: shift.briefing || head(shifts).briefing
      }),
    shifts
  );
}

export function getErrorMessageCreateShift(shift) {
  const errors = [
    validateBriefing(shift.briefing),
    validateNumberRequested(shift.numberRequested),
    validateUniform(shift.uniformTemplateId),
    validateRoleRate(shift.roleRateId),
    validateIdentifier(
      shift.account,
      getOr("", "privates.roleRateAccount.id", shift),
      shift.identifier
    ),
    useValidateAudience({ shift }) && "Staff Requirements",
    useValidateRequirements({ shift }) && "Uniform and briefing",
    useValidateCalendar({ shift }) && "Shift Times"
  ].filter(s => s !== null);
  return errors.length ? errors : null;
}
// eslint-disable-next-line complexity
export function getErrorMessageForShift(shift) {
  const errors = [
    validateShiftStartEnd(shift.startTime, shift.endTime),
    validateBriefing(shift.briefing),
    validateNumberRequested(shift.numberRequested),
    validateUniform(shift.uniformTemplateId),
    validateVenue(shift.venueId),
    validateRoleRate(shift.roleRateId),
    validateIdentifier(
      shift.account,
      getOr("", "privates.roleRateAccount.id", shift),
      shift.identifier
    )
  ].filter(s => s !== null);
  // $FlowFixMe - flow thinks that errors could include a null value.
  return errors.length ? errors : null;
}

export function validateIdentifier(account, currentRoleAccountId, id) {
  let message = null;
  const accountsArray = [
    221,
    254,
    326,
    335,
    341,
    343,
    347,
    359,
    365,
    376,
    380,
    383,
    386,
    395,
    408,
    410,
    411,
    419,
    423,
    424,
    427,
    463,
    481,
    482,
    502,
    534,
    552,
    560,
    610,
    619,
    620,
    627,
    628,
    629,
    630,
    660,
    730,
    760,
    807,
    965,
    1083,
    1095,
    1109,
    1153,
    1013,
    1241,
    1246
  ];
  if (
    accountsArray.includes(parseInt(account, 10)) &&
    parseInt(currentRoleAccountId, 10) === 1
  ) {
    if (!id) {
      message = "My ID is required";
    } else {
      // eslint-disable-next-line
      (id.length === 5 && /([A-Z][0-9]*){1,}/.test(id)) ||
        (message =
          "My ID's value is invalid. Please enter your five digit approval code in the correct format.");
    }
  }

  return message;
}

export function validateShiftStartEnd(startTime, endTime) {
  if (!startTime || !endTime) return "start and end time are required";
  if (moment(endTime).isBefore(startTime))
    return "start time must be before the end";
  return null;
}

export function validateBriefing(briefing) {
  if (!briefing) return "Briefing is required";
  return null;
}

export function validateVenue(venueId) {
  if (!venueId) return "Venue is required";
  return null;
}

export function validateUniform(uniformTemplateId) {
  if (!uniformTemplateId) return "Uniform is required";
  return null;
}

export function validateNumberRequested(numberRequested) {
  if (!parseInt(numberRequested, 10)) return "Number of staff is required";
  return null;
}

export function validateRoleRate(roleRateId) {
  if (!roleRateId) return "Role is required";
  return null;
}

export function mouseEventFallsInBox(mouse, position) {
  // Top left <= mouse X
  return (
    position.left <= mouse.left &&
    // Mouse X <= bottom right
    mouse.left <= position.left + position.width &&
    // Top left <= mouse Y
    position.top <= mouse.top &&
    // Mouse Y <= Bottom right corner
    mouse.top <= position.bottom
  );
}

export function definePixelsPerMinute(top) {
  const topHeight = top;
  const MINUTES_IN_DAY = 24 * 60;

  // TODO: Remove magic and get hard height from footer. Somehow.
  const FOOTER_HEIGHT = 43;
  const MIN_PPM = 0.3;

  const actualCalHeight =
    getWindowHeight() -
    (FOOTER_HEIGHT + topHeight) -
    document.getElementById(PAGESCROLL_ELEMENT_ID).scrollTop;

  const pixelsPerMinute = actualCalHeight / MINUTES_IN_DAY;

  return pixelsPerMinute < MIN_PPM ? MIN_PPM : pixelsPerMinute;
}

function getWindowHeight() {
  return (
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.getElementsByTagName("body")[0]
  );
}

export const getTargetAccount = requesterConnections =>
  requesterConnections.data
    .map(({ sourceAccount }) => get("id", sourceAccount))
    .filter(x => !!x)
    .includes(ROTA_ACCOUNT_ID.toString()) && ROTA_ACCOUNT_ID;

export const formatLinkedShiftDates = (shiftStartDate, shiftEndDate) => {
  const formatString = "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ";
  const startDate = moment(shiftStartDate, formatString);
  const endDate = moment(shiftEndDate, formatString);

  const dates = [];
  // Check if it's not an overnight shift, by hours, if it is, one day to be added
  const overnight = startDate.hour() <= endDate.hour() ? 0 : 1;
  // We need to check for same day too if linkedshift is not going overnight
  const addExtraDayCheck = start =>
    overnight
      ? start.isBefore(endDate, "day")
      : start.isSameOrBefore(endDate, "day");

  while (addExtraDayCheck(startDate)) {
    const end = moment(startDate)
      .add(parseInt(overnight, 10), "day")
      .hour(endDate.hour())
      .minute(endDate.minute())
      .toISOString();

    dates.push({
      start: startDate.toISOString(),
      end
    });

    startDate.add(1, "days");
  }
  return dates;
};

export const formatLinkedShifts = shift =>
  formatLinkedShiftDates(shift.startTime, shift.endTime).map(date => ({
    ...shift,
    startTime: date.start,
    endTime: date.end
  }));

export const bonusTotalLinkedShifts = (nonFormattedshift, bonuses) => {
  const perDay = new MoneyTransaction("bonusTotalLinkedShiftsWithVAT perDay");
  const total = new MoneyTransaction("bonusTotalLinkedShiftsWithVAT total");

  if (bonuses && nonFormattedshift && find({ shiftIndex: 0 }, bonuses)) {
    const { numberRequested } = head(nonFormattedshift);
    const formattedShifts = formatLinkedShifts(head(nonFormattedshift));

    const currentBonuses = find({ shiftIndex: 0 }, bonuses).items;

    // Abs in case error in start and end times
    const shiftLength = Math.abs(
      moment(head(formattedShifts).endTime).diff(
        moment(head(formattedShifts).startTime),
        "hours",
        true
      )
    );

    currentBonuses.forEach(bonus => {
      if (bonus.type && bonus.amount) {
        const divisor = bonus.type === "fixed" ? formattedShifts.length : 1;

        let bonusMoney = Money({ amount: bonus.amount });
        if (bonus.type === "hourly") {
          bonusMoney = bonusMoney.multiply(shiftLength);
        }

        perDay.add(bonusMoney.divide(divisor));
      }
    });

    perDay.multiply(max([parseInt(numberRequested, 10), 1]));
    total.add(perDay.current).multiply(formattedShifts.length);
  }

  // Returns MoneyTransactions
  return {
    perDay,
    total
  };
};

export const bonusTotalForShift = (shiftForm, bonuses) => {
  const shift = shiftForm.shifts[shiftForm.shiftOpenIndex];
  const { startTime, endTime } = shift;

  const linkedMultiple = shiftForm.isLinkedShifts
    ? formatLinkedShifts(shift).length
    : 1;

  const totalHours = moment(endTime).diff(startTime, "hours", true);

  const totalBonus = new MoneyTransaction(`totalBonus bonus-reward`);

  const flatBonuses = bonuses.reduce(
    (all, { items }) => [...all, ...items],
    []
  );

  flatBonuses.forEach(bonus => {
    if (!bonus.amount || !bonus.type) return;

    const bonusMoney = BookingAccount.convertBonusToMoney(
      bonus,
      totalHours,
      linkedMultiple
    );

    totalBonus.add(bonusMoney);
  });

  return totalBonus;
};

export const checkIfBonusExists = (shiftIndex, bonuses) =>
  !!find({ shiftIndex }, bonuses);

export const getTotalBonuses = (state, shiftIndex) => {
  // Calculate total bonus
  const totalBonus = bonusTotalForShift(
    state.shiftForm,
    state.bonuses
  ).current.getAmount();

  // Update total bonus
  const totalBonuses =
    state.totalBonuses.length === 0 ||
    !checkIfBonusExists(shiftIndex, state.totalBonuses)
      ? [
          ...state.totalBonuses,
          {
            shiftIndex,
            totalBonus
          }
        ]
      : state.totalBonuses.map(bonus =>
          bonus.shiftIndex === shiftIndex ? { ...bonus, totalBonus } : bonus
        );

  return totalBonuses;
};

/**
 * ensures that option list is sorted alphabetically and the last used item as first item
 * @param {Array<Object>} options options such as array of venues, uniforms object etc fetched from database
 * @param {String|Number} lastUsedId last used Id such as last used venueId, uniformId or as the case might be by the user in creating shift
 * @param {String} sortBy the property of the object to sort by eg name, identifier etc
 * @returns {Array<Object>}
 */
export const sortOptionsMakingLastUsedFirst = (
  options,
  lastUsedId,
  sortBy = "name"
) => {
  if (options?.length < 2) {
    return options;
  }

  const optionsCopy = [...options];
  optionsCopy.sort((a, b) => a[sortBy].localeCompare(b[sortBy]));
  if (!lastUsedId) {
    return optionsCopy;
  }
  const lastItemUsedIndex = optionsCopy.findIndex(
    item => item.id === lastUsedId
  );
  if (lastItemUsedIndex === -1) {
    return optionsCopy;
  }
  const optionsSortedCopy = [...optionsCopy];
  optionsCopy.splice(lastItemUsedIndex, 1);
  return [optionsSortedCopy[lastItemUsedIndex], ...optionsCopy];
};

/**
 * Checks if last used item exists in options
 * @param {Array<Object>} options options such as array of venues, uniforms object etc fetched from database
 * @param {String|Number} lastUsedId last used Id such as last used venueId, uniformId or as the case might be by the user in creating shift
 * @returns {Boolean}
 */
export const checkIfLastUsedExistInOptions = (options, lastUsedId) => {
  if (!lastUsedId || options.length === 0) {
    return false;
  }
  const lastItemUsedIndex = options.findIndex(item => item.id === lastUsedId);
  if (lastItemUsedIndex === -1) {
    return false;
  }
  return true;
};

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const UPDATE_ACCOUNT = gql`
  mutation updateAccount(
    $accountName: String
    $customIdentifier: String
    $contactPhone: String
    $contactEmail: String
    $postalHouseNumber: String
    $postalStreetName: String
    $postalTown: String
    $postalPostcode: String
    $postalCounty: String
    $postalCountry: String
    $invoiceHouseNumber: String
    $invoiceStreetName: String
    $invoiceTown: String
    $invoicePostcode: String
    $invoiceCounty: String
    $invoiceCountry: String
  ) {
    updateAccount(
      accountName: $accountName
      customIdentifier: $customIdentifier
      contactPhone: $contactPhone
      contactEmail: $contactEmail
      postalHouseNumber: $postalHouseNumber
      postalStreetName: $postalStreetName
      postalTown: $postalTown
      postalPostcode: $postalPostcode
      postalCounty: $postalCounty
      postalCountry: $postalCountry
      invoiceHouseNumber: $invoiceHouseNumber
      invoiceStreetName: $invoiceStreetName
      invoiceTown: $invoiceTown
      invoicePostcode: $invoicePostcode
      invoiceCounty: $invoiceCounty
      invoiceCountry: $invoiceCountry
    ) {
      id
    }
  }
`;

export default graphql(UPDATE_ACCOUNT, {
  name: "updateAccount",
  props: ownProps => ({
    updateAccount: ({
      accountName,
      customIdentifier,
      contactPhone,
      contactEmail,
      postalAddress,
      invoiceAddress
    }) => {
      ownProps.updateAccount({
        variables: {
          accountName: accountName,
          customIdentifier: customIdentifier,
          contactPhone,
          contactEmail,
          postalHouseNumber: postalAddress?.houseNumber,
          postalStreetName: postalAddress?.streetName,
          postalTown: postalAddress?.town,
          postalPostcode: postalAddress?.postcode,
          postalCounty: postalAddress?.county,
          postalCountry: postalAddress?.country,
          invoiceHouseNumber: invoiceAddress?.houseNumber,
          invoiceStreetName: invoiceAddress?.streetName,
          invoiceTown: invoiceAddress?.town,
          invoicePostcode: invoiceAddress?.postcode,
          invoiceCounty: invoiceAddress?.county,
          invoiceCountry: invoiceAddress?.country
        },
        refetchQueries: ["getProfile"]
      });
    }
  })
});

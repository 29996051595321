import { gql } from "@apollo/client";

export const DELETE_MEMBERS = gql`
  mutation($memberIds: [ID]!, $accountId: ID!) {
    deleteMembers(accountId: $accountId, memberIds: $memberIds) {
      success
      data {
        deleted
        anonymized
        deletedAssets
      }
    }
  }
`;

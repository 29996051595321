import styled from "styled-components";

export const Wrapper = styled.div`
  height: calc(100% - 45px); //45px for the main tabs
  display: flex;
  flex-direction: column;
  position: relative;
`;
export const TabsWrapper = styled.div`
  margin-bottom: 16px;
  position: relative;
  left: -16px; // -16px to align with main tabs
`;
export const RoleListWrapper = styled.div`
  overflow-y: auto;
`;

export const EditCancelButtons = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: auto auto;
  margin-left: auto;
  margin-top: 32px;
  margin-bottom: 32px;
`;

export const Cell = styled.div`
  display: flex;
  flex: ${({ width }) => (width ? "none" : 1)};
  flex-direction: row;
  position: relative;
  font-size: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
  width: ${({ width }) => width || "auto"};
  max-width: ${({ width }) => width || "auto"};
  text-align: ${({ center }) => (center ? "center" : "default")};
  align-items: flex-start;
  font-weight: ${({ isBold }) => (isBold ? "bold" : "")};
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1;
  ${({ isItalic }) => isItalic && "font-style: italic;"};
`;

export const EnableButtonWrapper = styled.div`
  margin-left: auto;
  margin-bottom: 16px;
`;

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 16px 16px;
  border-bottom: ${({ theme }) => `1px solid ${theme.neutral.dark}`};
  margin-bottom: 16px;
  display: ${({ isShow }) => (isShow ? "block" : "none")};
`;

export const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
`;

export const DatePickerLabel = styled.div`
  margin-right: 14px;
  font-size: 14px;
  align-self: center;
  font-weight: 400;
  color: ${({ theme }) => theme.neutral.main};
`;

import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";

import { addAuthVars } from "@teamrota/authlib";

export const GET_PAY_RULES_INFO = addAuthVars(gql`
  query getPayRulesInfo(
    $id: ID!
    $shiftType: ShiftTypeEnumType!
    $attribute: PayEngineBlockAttributeInputType!
    $start: Date!
    $end: Date!
  ) {
    getPayRulesInfo(
      id: $id
      shiftType: $shiftType
      attribute: $attribute
      start: $start
      end: $end
    )
  }
`);

interface PayRulesInfoProps {
  id: string | undefined;
  shiftType: string | undefined;
  attribute: string | undefined;
  start: Date;
  end: Date;
}

export const usePayRulesInfo = ({
  id,
  shiftType,
  attribute,
  start,
  end
}: PayRulesInfoProps) => {
  const { data, loading, error } = useQuery(GET_PAY_RULES_INFO, {
    variables: { id, shiftType, attribute, start, end },
    fetchPolicy: "network-only",
    skip: !id || !shiftType || !start || !end || !attribute
  });

  return { entries: data?.getPayRulesInfo, loading, error };
};

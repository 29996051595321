import { RotaCheckbox } from "@teamrota/rota-design";
import React from "react";
import { OptionKey, optionTranslations } from "./types";
import styled from "styled-components";

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background-color: white;
  position: absolute;
  right: -40px;
  top: 100%;
  z-index: 100;
  width: 200px;
  padding: 20px;
`;
const OptionsTitle = styled.div`
  font-weight: bold;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 0 10px;
`;
const OptionsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 0 0 0;
`;

interface Props {
  selectedOptions: OptionKey[];
  handleOptionChange: (option: OptionKey) => void;
  options: OptionKey[];
}

export const TimesheetBreakdownOptions = ({
  selectedOptions,
  handleOptionChange,
  options
}: Props) => {
  return (
    <OptionsContainer onClick={e => e.stopPropagation()}>
      <OptionsTitle>See / hide columns</OptionsTitle>
      <OptionsList>
        {options.map(option => (
          <RotaCheckbox
            name={option}
            label={optionTranslations[option]}
            isChecked={selectedOptions.includes(option)}
            onClick={() => handleOptionChange(option)}
          />
        ))}
      </OptionsList>
    </OptionsContainer>
  );
};

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { handleErrors } from "~/src/utils/errors";

const GET_ACCOUNT_ID = gql`
  query getAccountId {
    account {
      id
    }
  }
`;
export default graphql(GET_ACCOUNT_ID, {
  props: handleErrors(({ data: { account } }) => ({
    id: parseInt(account?.id)
  }))
});

import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";
import { hexToRGBA } from "~/src/styles/style-helpers";
import Button from "~/src/components/button";

export const PICKER_HEIGHT = 280;
export const PICKER_TIME_HEIGHT = 340;
export const PICKER_WIDTH = 240;
export const TIP_SIZE = 7;
export const TIP_SIDE_LENGTH = (TIP_SIZE / Math.sqrt(2)) * 2;
const PADDING_X = parseInt(STYLE_CONSTS.UNIT_2, 10);
const DATE_MARGIN = parseInt(STYLE_CONSTS.UNIT_HALF, 10) / 2;
const DATE_WIDTH = (PICKER_WIDTH - PADDING_X * 2) / 7 - DATE_MARGIN * 2;

export const PickerWrapper = styled.div`
  pointer-events: ${({ isDisabled }) => (isDisabled ? "none" : "auto")};
  position: absolute;
  min-height: ${({ isDateOnly }) =>
    isDateOnly ? `${PICKER_HEIGHT}px` : `${PICKER_TIME_HEIGHT}px`};
  width: ${PICKER_WIDTH}px;
  border-radius: ${STYLE_CONSTS.BORDER_RADIUS};
  box-shadow: ${STYLE_CONSTS.FLOATING_LARGE};
  top: 0px;
  left: 0px;
  text-align: left;
  z-index: ${STYLE_CONSTS.Z_INDEX_DATE_PICKER};
  background-color: ${({ theme }) => theme.white};
`;

export const PickerInner = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.white};
  border-radius: ${STYLE_CONSTS.BORDER_RADIUS};
  padding: ${STYLE_CONSTS.UNIT_2} ${PADDING_X}px;
  position: relative;
`;

export const PickerHeader = styled.div`
  color: ${({ theme }) => theme.neutral.main};
  font-weight: bold;
`;

export const PickerHeaderSection = styled.div`
  display: inline-block;
  width: 50%;
  line-height: ${STYLE_CONSTS.BTN_HEIGHT_XS};
  &:last-child {
    text-align: right;
  }
`;

export const IconButton = styled(Button)`
  margin-left: ${STYLE_CONSTS.UNIT_1};
`;

export const PickerCalendar = styled.div`
  margin-top: ${STYLE_CONSTS.UNIT_2};
`;

export const CalendarHeader = styled.div`
  font-weight: bold;
`;

export const CalendarDate = styled.span`
  display: inline-block;
  width: ${DATE_WIDTH}px;
  height: ${DATE_WIDTH}px;
  line-height: ${DATE_WIDTH}px;
  border-radius: ${({
    hasRangeEnd,
    isSameStartEnd,
    isSelectedBetween,
    isSelectedStart,
    isSelectedEnd
  }) => {
    if (isSelectedStart && hasRangeEnd && !isSameStartEnd)
      return `${DATE_WIDTH}px 0 0 ${DATE_WIDTH}px`;
    if (isSelectedEnd && !isSameStartEnd)
      return `0 ${DATE_WIDTH}px ${DATE_WIDTH}px 0`;
    if (isSelectedBetween && !isSameStartEnd) return 0;
    return `${DATE_WIDTH}px`;
  }};
  text-align: center;
  background: ${({
    isSelectedStart,
    isSelectedEnd,
    isSelectedBetween,
    theme
  }) => {
    if (isSelectedStart || isSelectedEnd)
      return STYLE_CONSTS.BACKGROUND_GRADIENT;
    if (isSelectedBetween) return hexToRGBA(theme.primary.main, 0.5);
    return "transparent";
  }};
  margin: ${DATE_MARGIN}px;
  color: ${({
    isSelectedStart,
    isSelectedEnd,
    isPast,
    highlightToday,
    theme
  }) => {
    if (isSelectedStart || isSelectedEnd) return theme.white;
    if (highlightToday) return theme.primary.main;
    if (isPast) return theme.neutral.main;
    return theme.neutral.dark;
  }};
  pointer-events: ${({ isDateSelectable }) =>
    isDateSelectable ? "none" : "auto"};
  &:hover {
    background-color: ${({ isClickable, theme }) =>
      isClickable ? hexToRGBA(theme.primary.main, 0.5) : theme.white};
    cursor: ${({ isClickable }) => (isClickable ? "pointer" : "default")};
    color: ${({ isClickable, theme }) =>
      isClickable ? theme.white : theme.neutral.dark};
  }
`;

export const FooterWrapper = styled.div`
  display: inline-block;
  width: 50%;
  margin-top: ${STYLE_CONSTS.UNIT_2};
  &:last-child {
    padding-left: ${STYLE_CONSTS.UNIT_2};
  }
`;

export const ErrorMessage = styled.div`
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
  color: ${({ theme }) => theme.error.main};
  text-align: center;
  margin-top: ${STYLE_CONSTS.UNIT_2};
`;

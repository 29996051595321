import { User } from "oidc-client";

import { baseUrl, userManager } from "./manager";

const AGENT_KEY = "agent";

export const actAsAgent = async loginEmail => {
  // copy current user somewhere safe while we're an agent
  sessionStorage.setItem(
    AGENT_KEY,
    (await userManager.getUser()).toStorageString()
  );

  // redirect for new sign in with special 'actAs' param
  await userManager.signinRedirect({
    prompt: "login consent",
    extraQueryParams: {
      actas: loginEmail,
      cancel: `${baseUrl}/`
    }
  });
};

export const isAgent = () => sessionStorage.getItem(AGENT_KEY) !== null;

export const getAgent = async () => {
  const actorString = sessionStorage.getItem(AGENT_KEY);
  const actor = actorString ? User.fromStorageString(actorString) : null;

  if (actor != null) {
    // check subs are not the same (happens if you cancel an agent login)
    const user = await userManager.getUser();
    if (user.profile?.sub !== actor.profile?.sub) {
      return actor;
    } else {
      clearAgent();
    }
  }

  return null;
};

export const clearAgent = () => {
  const isAgent = sessionStorage.getItem(AGENT_KEY) !== null;
  if (isAgent) {
    sessionStorage.removeItem(AGENT_KEY);
    return true;
  }

  return false;
};

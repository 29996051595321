import moment from "moment-timezone";

const DEFAULT_DELAY_HRS = 24;

export const SHIFT_STATUS = {
  DEFAULT: {
    delayHoursAlgoShift: 0,
    skipAlgoShift: false
  },
  DELAYED: (delayHoursAlgoShift = DEFAULT_DELAY_HRS) => ({
    delayHoursAlgoShift,
    skipAlgoShift: false
  }),
  SKIPALGO: {
    delayHoursAlgoShift: 0, // in this case the delay doesn't really matter!
    skipAlgoShift: true
  }
};

export const canShiftBeRestricted = (date, memberCount) => {
  const getFutureTime = delay =>
    moment()
      .add(delay, "hours")
      .format("YYYY-MM-DD HH:mm:ss");

  // TODO The value 60 has to be changed to shift.delayHoursAlgoShift + matcherConfig.hoursBeforeShift
  const startDate = moment(date).format("YYYY-MM-DD HH:mm:ss");
  const DELAY_TIME = 0;

  const baseResponse = {
    message: "",
    canRestrict: true,
    disable24Hours: false
  };

  if (moment(startDate).isBefore(getFutureTime(DELAY_TIME))) {
    return {
      ...baseResponse,
      message: `\u00A0 (Cannot restrict if start time within ${DELAY_TIME} hours)`,
      canRestrict: false
    };
  }

  if (memberCount === 0) {
    return {
      ...baseResponse,
      message: `\u00A0 (Cannot restrict if no requested members)`,
      canRestrict: false
    };
  }

  return {
    ...baseResponse,
    disable24Hours: moment(startDate).isBefore(getFutureTime(60))
  };
};

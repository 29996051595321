import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { useToast } from "@teamrota/rota-design";
import { useCallback } from "react";

const TIMESHEET_UPDATE = gql`
  mutation updateTimesheet($id: ID!, $purchaseOrderNumber: String) {
    updateTimesheet(id: $id, purchaseOrderNumber: $purchaseOrderNumber) {
      timesheets {
        id
        purchaseOrderNumber
        status
      }
    }
  }
`;

interface Params {
  onCompleted?: () => void;
}

export const useUpdateTimesheet = ({ onCompleted }: Params) => {
  const { showToast } = useToast();
  const [update, { loading }] = useMutation(TIMESHEET_UPDATE, {
    onCompleted,
    onError: error => {
      showToast(error.message, { severity: "error" });
    }
  });

  const updateTimesheet = useCallback(
    async ({ id, purchaseOrderNumber }) => {
      await update({ variables: { id, purchaseOrderNumber } });
    },
    [update]
  );

  return {
    updateTimesheet,
    loading
  };
};

import styled from "styled-components";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";
import { POOL_TYPE_COLOR, STAFF_POOLS_SIZE } from "~/src/consts";
import { POOL_TYPES } from "@teamrota/rota-common";

export const ContentWrapper = styled.div`
  display: ${({ isVisible }) => (!isVisible ? "block" : "none")};
`;
export const FadedEnd = styled.div`
  display: ${({ isVisible }) => (!isVisible ? "block" : "none")};
  position: relative;
  overflow: hidden;
  &:after {
    display: ${({ isVisible }) => (!isVisible ? "block" : "none")};
    content: "";
    background: black;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
  }
`;
export const Title = styled.h1`
  margin-left: 10px;
  font-size: ${STYLE_CONSTS.FONT_LARGE};
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_MEDIUM};
  color: ${({ poolType }) => COLORS[POOL_TYPE_COLOR[poolType]]};
`;

export const Content = styled.div`
  border-radius: ${STYLE_CONSTS.BORDER_RADIUS};
  background-color: ${({ theme }) => theme.white};
  box-shadow: ${STYLE_CONSTS.FLOATING_LARGE};
  padding: ${STYLE_CONSTS.UNIT_4};
  padding-bottom: 0;
  min-height: ${({ size }) => (size === "large" ? "300px" : "230px")};
  overflow: hidden;
  border-bottom: 2px solid;
  border-color: ${({ poolType }) => COLORS[POOL_TYPE_COLOR[poolType]]};
  ${({ isOpen }) =>
    isOpen
      ? `
    min-height: 10px;
    height: 10px;
    transition:0.5s linear;
    `
      : `
      transition: 0.5s linear;
      min-height: ${({ size }) => (size === "large" ? "300px" : "230px")};

    `};
`;

export const Divider = styled.div`
  border-bottom: ${STYLE_CONSTS.SMALL_DIVIDER_BORDER};
`;

export const Description = styled.p`
  display: ${({ isVisible }) => (!isVisible ? "block" : "none")};

  margin-bottom: ${STYLE_CONSTS.UNIT_2};
  margin-top: 0;
  font-size: ${STYLE_CONSTS.FONT_SMALL};
`;

export const StaffContainer = styled.div`
  padding-top: ${STYLE_CONSTS.UNIT_2};
  overflow-y: scroll;

  height: ${({ size }) => STAFF_POOLS_SIZE[size]};
  border: ${({ isOver }) => (isOver ? "red" : "none")};

  ${({ poolType }) =>
    poolType === "RECOMMENDED" ? `flex-wrap: wrap;` : `white-space: nowrap;`};
`;

export const PlaceholderWrapper = styled.div`
  text-align: center;
  margin-top: ${({ poolType }) =>
    POOL_TYPES[poolType] === "FAVOURITED" ? "20px" : "40px"};
  ${({ noMargin }) => noMargin && `margin: 0`};
`;

export const PlaceholderTitle = styled.h1`
  font-size: ${({ isSmall }) =>
    isSmall ? `${STYLE_CONSTS.FONT_MEDIUM}` : `${STYLE_CONSTS.FONT_XLARGE}`};
  color: ${({ poolType }) => COLORS[POOL_TYPE_COLOR[poolType]]};
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_MEDIUM};
`;

export const InputWrapper = styled.div`
  display: ${({ isVisible }) => (!isVisible ? "block" : "none")};
  width: 374px;
  margin-bottom: ${STYLE_CONSTS.UNIT_2};
`;

export const NoResultsTitle = styled.h1`
  font-size: ${STYLE_CONSTS.FONT_XLARGE};
  color: ${COLORS.DARK_GREY};
`;

export const NoResultsDescription = styled.p`
  color: ${COLORS.COOL_GREY};
  font-size: ${STYLE_CONSTS.FONT_LARGE};
`;

export const Overlay = styled.div`
  transition: ${STYLE_CONSTS.STANDARD_TRANSITON};
  opacity: ${({ isVisible }) => (isVisible ? "0.7" : "0")};
  z-index: 1;
  pointer-events: none;
  position: absolute;
  top: ${({ isGroupPanel }) => (isGroupPanel ? "2px" : "-2px")};
  bottom: 0;
  left: ${({ isGroupPanel }) =>
    isGroupPanel ? "0" : `-${STYLE_CONSTS.UNIT_4}`};
  right: ${({ isGroupPanel }) =>
    isGroupPanel ? "0" : `-${STYLE_CONSTS.UNIT_4}`};
  background-color: ${({ theme }) => theme.text.primary};
  border-bottom-left-radius: ${STYLE_CONSTS.BORDER_RADIUS};
  border-bottom-right-radius: ${STYLE_CONSTS.BORDER_RADIUS};
  ${({ isGroupPanel }) => isGroupPanel && `padding: 0 24px;`};
`;

export const OverlayMessage = styled.div`
  opacity: ${({ isVisible }) => (isVisible ? "1" : "0")};
  top: 50%;
  left: 50%;
  position: absolute;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.white};
`;

export const Results = styled.div`
  position: relative;
  display: ${({ isVisible }) => (!isVisible ? "block" : "none")};
`;

export const PlaceholderDescription = styled.div`
  color: ${COLORS.COOL_GREY};
  font-size: ${({ isSmall }) =>
    isSmall ? `${STYLE_CONSTS.FONT}` : `${STYLE_CONSTS.FONT_MEDIUM}`};
`;

export const LoadingWrapper = styled.div`
  text-align: center;
  margin: 20px;
`;

import React, { Fragment, useContext, useRef, useEffect } from "react";

import { langs, trees } from "@teamrota/authlib";

import { RolesContext } from "~/src/containers/settings-page/context/roles-context";

import { renderBranch } from "~/src/containers/settings-page/right-panel/permissions-tab-content/branch-row";

import {
  StyledTableTr,
  StyledFieldTd,
  StyledDefaultTd
} from "./permissions-tab-content.styles";

export const TableBody = () => {
  const { isSearched } = useContext(RolesContext);
  const arr = [1, 2, 3];
  const headerRef = useRef([]);

  useEffect(() => {
    headerRef?.current?.map(
      el =>
        isSearched == el?.id &&
        el?.scrollIntoView({ behavior: "smooth", block: "center" })
    );
  }, [isSearched]);

  return (
    <tbody>
      {trees?.map(({ name, branches }, idx) => (
        <Fragment key={idx}>
          <StyledTableTr
            key={name + idx}
            isSelect={isSearched == langs.en[name]}
          >
            <StyledFieldTd>
              <div
                id={langs.en[name]}
                ref={element =>
                  element !== null &&
                  !headerRef?.current?.includes(element) &&
                  headerRef?.current?.push(element)
                }
              >
                {langs.en[name]}
              </div>
            </StyledFieldTd>
            {arr.map(idx => {
              return <StyledDefaultTd key={idx}></StyledDefaultTd>;
            })}
          </StyledTableTr>
          {branches.map((branch, id) => {
            return renderBranch(branch, id, isSearched == langs.en[name]);
          })}
        </Fragment>
      ))}
    </tbody>
  );
};

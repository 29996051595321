import React, { useEffect, useState } from "react";
import { useTheme } from "styled-components";

import set from "lodash/fp/set";
import remove from "lodash/fp/remove";
import truncate from "lodash/fp/truncate";
import { RotaButton } from "@teamrota/rota-design";
import { Role } from "@teamrota/authlib";
import PageHeader from "~/src/components/page-header";
import { Row, Column } from "~/src/components/grid";
import TextInput from "~/src/components/form-components/text-input";
import Textarea from "~/src/components/form-components/textarea";
import LabelValue from "~/src/components/label-value";
import TemplateItem from "../../components/template-item";
import TemplatePanel from "../../components/template-panel";
import AlertDialog from "~/src/containers/templates/components/dialog";

import { errorModal } from "~/src/utils/errors";
import asyncConfirm from "~/src/utils/async-confirm";

import deleteItem from "~/src/graphql/mutations/delete-item/delete-item.decorator";
import getProfile from "~/src/graphql/queries/get-profile/get-profile-query.decorator";
import createOrUpdateBriefingTemplate from "./graphql/create-update-briefing-templates";

import {
  ContentPadding,
  Divider,
  StyledGrid,
  StyledRow,
  Title,
  TitleContainer
} from "../../templates.styles";
import { Text } from "./briefing-templates.styles";
import { HasAnyRole } from "~/src/containers/has-role";
import useAuth from "~/src/auth/hooks/use-auth";

const DEFAULT_TEMPLATE = {
  identifier: "",
  content: ""
};

const DEFAULT_STATE = {
  activeTemplate: DEFAULT_TEMPLATE,
  isShowSaved: false,
  isLoading: false,
  identifierIsInvalid: false,
  contentIsInvalid: false
};

const BriefingTemplates = ({
  user,
  deleteItem,
  createOrUpdateBriefingTemplate
}) => {
  const theme = useTheme();
  const [state, setState] = useState({
    activeTemplate: DEFAULT_TEMPLATE,
    isShowSaved: false,
    isLoading: false,
    identifierIsInvalid: false,
    contentIsInvalid: false
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const auth = useAuth();

  const isEditMode = Boolean(state.activeTemplate.id);

  useEffect(() => {
    return () => {
      clearTimeout(timer1);
    };
  }, []);

  const timer1 = () =>
    setTimeout(() => {
      setState(DEFAULT_STATE);
    }, 1000);

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleDeleteClicked = template => {
    handleDelete(template.id);
  };

  const handleEditClicked = template => {
    handleEditMode(template);
  };

  const handleDelete = async briefingTemplateId => {
    try {
      if (
        await asyncConfirm("Are you sure you want to delete this template?", {
          confirmButtonText: "Delete"
        })
      ) {
        await deleteItem({
          updateQueries: {
            getProfile: prevData => {
              const prevBriefings = prevData.user.account?.briefingTemplates;
              return set(
                "user.account.briefingTemplates",
                remove({ id: briefingTemplateId }, prevBriefings)
              )(prevData);
            }
          },
          variables: {
            type: "briefingTemplates",
            id: briefingTemplateId
          }
        });
      }
    } catch (e) {
      errorModal(e);
    }
  };

  const saveBriefing = async event => {
    event.preventDefault();

    const { identifier, content, id } = state.activeTemplate;

    if (!identifier || !content) {
      setState({
        ...state,
        identifierIsInvalid: !identifier,
        contentIsInvalid: !content
      });

      return;
    }

    setState({
      ...state,
      isLoading: true
    });

    try {
      await createOrUpdateBriefingTemplate(id, identifier, content);

      setState({
        ...state,
        isLoading: false,
        isShowSaved: true,
        activeTemplate: isEditMode ? state.activeTemplate : DEFAULT_TEMPLATE
      });

      timer1();
    } catch (e) {
      setState({
        ...state,
        isLoading: false
      });
      errorModal(e);
    }
  };

  const handleEditMode = briefingTemplate => {
    const { identifier, content } = briefingTemplate;
    setState({
      ...state,
      activeTemplate: briefingTemplate,
      identifierIsInvalid: !identifier,
      contentIsInvalid: !content
    });
  };

  const handleChange = key => value =>
    setState({
      ...state,
      [`${key}IsInvalid`]: !value.length,
      activeTemplate: {
        ...state.activeTemplate,
        [key]: value
      }
    });

  const { identifierIsInvalid, contentIsInvalid } = state;
  const nameErrorLabel = identifierIsInvalid
    ? "Please enter a template name"
    : "";
  const contentErrorLabel = contentIsInvalid ? "Please enter a briefing" : "";

  return (
    <div>
      <PageHeader
        title="Briefing Templates"
        subtext="Create preset briefings to let staff know what they will be doing"
      />
      <StyledRow>
        <Column medium={1 / 2}>
          <HasAnyRole
            roles={[
              Role.BRIEFING_TEMPLATES_CREATE,
              Role.BRIEFING_TEMPLATES_EDIT
            ]}
          >
            <TemplatePanel
              onReset={() =>
                setState({ ...state, activeTemplate: DEFAULT_TEMPLATE })
              }
              isEditMode={isEditMode}
              isSticky
            >
              <ContentPadding>
                <TextInput
                  errorLabel={nameErrorLabel}
                  isRequired
                  fontColor={theme.primary.main}
                  label="Template name"
                  placeholder={"Write here"}
                  isBorderless
                  isDisabled={state.isLoading || state.isShowSaved}
                  value={state.activeTemplate.identifier}
                  onChangeValue={handleChange("identifier")}
                />
                <Divider isMarginless />
              </ContentPadding>
              <ContentPadding>
                <LabelValue label="Briefing">
                  <Textarea
                    isRequired
                    placeholder={"Write here"}
                    errorLabel={contentErrorLabel}
                    rowCount={15 /* MAGIC */}
                    isDisabled={state.isLoading || state.isShowSaved}
                    value={state.activeTemplate.content}
                    onChangeValue={handleChange("content")}
                  />
                </LabelValue>
              </ContentPadding>
              <Divider />
              <ContentPadding style={{ textAlign: "right" }}>
                <RotaButton
                  disabled={
                    !auth.hasRole(Role.BRIEFING_TEMPLATES_CREATE) && !isEditMode
                  }
                  onClick={saveBriefing}
                  isSuccess={state.isShowSaved}
                  isLoading={state.isLoading}
                  isUsingFlexbox
                >
                  {state.isShowSaved ? "Saved" : "Save"}
                </RotaButton>
              </ContentPadding>
            </TemplatePanel>
          </HasAnyRole>
        </Column>
        <Column medium={1 / 2}>
          <TitleContainer>
            <Title>My Templates</Title>
          </TitleContainer>
          <Row>
            {user.account?.briefingTemplates?.map((template, index) => (
              <StyledGrid key={index} gutter={15} xlarge={1 / 2}>
                <TemplateItem
                  item={template}
                  title={template.identifier}
                  createdAt={template.createdAt}
                  handleEdit={
                    auth.hasRole(Role.BRIEFING_TEMPLATES_EDIT)
                      ? () => handleEditClicked(template)
                      : undefined
                  }
                  handleDelete={
                    auth.hasRole(Role.BRIEFING_TEMPLATES_EDIT)
                      ? () => handleDeleteClicked(template)
                      : undefined
                  }
                >
                  <Text>{truncate({ length: 50 }, template.content)}</Text>
                </TemplateItem>
              </StyledGrid>
            ))}
          </Row>
        </Column>
      </StyledRow>
      <AlertDialog
        onHide={handleDialogClose}
        openDialog={isDialogOpen}
        handleClose={handleDialogClose}
        dialogTitle="You do not have the permission to edit/delete this template"
        declineBtnText="OK"
      />
    </div>
  );
};

export default deleteItem(
  createOrUpdateBriefingTemplate(getProfile(BriefingTemplates))
);

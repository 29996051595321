import React, { useState } from "react";

import take from "lodash/fp/take";
import TemplateItem from "~/src/containers/templates/components/template-item";
import AlertDialog from "~/src/containers/templates/components/dialog";
import {
  ItemsWrapper,
  Bullet,
  List,
  UniformImageWrapper,
  UniformImage,
  VisualWrapper,
  UniformDescription
} from "./index.styles";
import { StyledGrid } from "~/src/containers/templates/templates.styles";
import { DRESS_CODE_IMAGES } from "~/src/consts";

const Templates = ({ items, onEdit, onDelete, size }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleDeleteClicked = template => {
    onDelete(template.id);
  };

  const handleEditClicked = template => {
    onEdit(template);
  };

  return (
    <div>
      {items.map((template, index) => (
        <StyledGrid key={index} gutter="small" xlarge={size}>
          <TemplateItem
            item={template}
            title={template?.identifier}
            createdAt={template?.createdAt}
            handleEdit={() => handleEditClicked(template)}
            handleDelete={() => handleDeleteClicked(template)}
          >
            {template?.dressCodeId ? (
              <VisualWrapper>
                <UniformImageWrapper>
                  <UniformImage
                    resizeMode="contain"
                    src={DRESS_CODE_IMAGES[template.dressCodeId].uri}
                  />
                </UniformImageWrapper>
                <UniformDescription>{template.items[0]}</UniformDescription>
              </VisualWrapper>
            ) : (
              <ItemsWrapper>
                {template?.items?.length} items
                <List>
                  {take(4, template?.items).map((item, itemIndex) => (
                    <Bullet key={itemIndex}>{item}</Bullet>
                  ))}
                </List>
              </ItemsWrapper>
            )}
          </TemplateItem>
        </StyledGrid>
      ))}
      <AlertDialog
        onHide={handleDialogClose}
        openDialog={isDialogOpen}
        handleClose={handleDialogClose}
        dialogTitle="You do not have the permission to edit/delete this template"
        declineBtnText="OK"
      />
    </div>
  );
};

export default Templates;

import { gql } from "@apollo/client";

export const TimesheetFragment = gql`
  fragment TimesheetFragment on TimesheetRecord {
    id
    shiftName
    shiftId
    peopleCount
    time
    cost
    purchaseOrderNumber
    status
    shiftStartTime
    shiftEndTime
    venueId
    venueName
    venueAddress
    isUncalculatedRole
    providerName
    numberRequested
    subvenueId
    subvenueName
    memberNames
  }
`;

export const TimesheetMemberFragment = gql`
  fragment TimesheetMemberFragment on TimesheetMemberRecord {
    id
    shiftId
    shiftName
    timesheetId
    shiftStartTime
    shiftEndTime
    roleName
    totalMinutes
    purchaseOrderNumber
    status
    venueName
    isUncalculatedRole
    memberId
    memberName
    memberAvatar
    checkInTime
    checkOutTime
    isNoShow
    isTurnedAway
    scheduledStartTime
    scheduledEndTime
    venueId
    subvenueId
    subvenueName
    venueAddress
    numberRequested
  }
`;

import styled from "styled-components";

export const StyledHeader = styled.div`
  border: 1px solid transparent;
  border-bottom-color: ${({ theme }) => theme.neutral.light};
  margin-bottom: 8px;
`;

export const StyledSubHeader = styled.span`
  color: ${({ theme }) => theme.text.primary};
`;

export const StyledWrapButtons = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 24px;
  float: right;
  margin-top: 32px;
`;

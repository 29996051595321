import moment from "moment-timezone";

// NOTE: Remember to duplicate any changes in api/src/services/utils/approval-state.js!!
// Feature also known as Unlimited Timesheets
const CLIENTS_WITHOUT_48H_APPROVAL_LIMIT = [
  "2389", // GI_GROUP
  "2082", // THE_RECRUITMENT_GROUP
  "2074", // SELECTIVE_PERSONNEL
  "2097", // JACK SPRAT
  "2081", // Change
  "2091", // Change Live
  "1948", // etc Live
  "2080", // ECB Services
  "2843", // FCMG Services
  "2847", // Change BOH
  "3507", // Ashton
  "3509", // Cool
  "3505", // Mego
  "3511", // ChefShare
  "2853", // Flutes
  "3519", // Rock Recruitment
  "3515", // Hospitality Pro
  "2561", // Bri's Burgers,
  "2841", // TTM/RS
  "2849", // TTM/RS
  "2587", // Harrison
  "2829", // Rapport
  "3569", //City of London Academy
  "4509" // TTM UK
];

/**
 * Create an object with matching values to its keys.
 */
export function keyMirror(object) {
  const keys = Array.isArray(object) ? object : Object.keys(object);
  return keys.reduce((cur, key) => ({ ...cur, [key.toUpperCase()]: key }), {});
}

export async function stallPromise(promise, minTime = 1000) {
  const start = Date.now();
  const result = await promise;
  await new Promise(res => setTimeout(res, minTime - (Date.now() - start)));
  return result;
}

export const getDefaultShiftPostProps = user => {
  const { briefingTemplates = [], uniformTemplates = [], venues = [] } =
    user?.account ?? {};

  const defaults = {};
  if (briefingTemplates.length === 1)
    defaults.briefing = briefingTemplates[0].content;
  if (uniformTemplates.length === 1)
    defaults.uniformTemplateId = uniformTemplates[0].id;
  if (venues.length === 1) defaults.venueId = venues[0].id;

  return defaults;
};

export const getIfTimesheetModalShouldBeShown = shift =>
  moment(shift.endTime).isoWeekday() > 4 &&
  [5, 6, 7].includes(moment().isoWeekday())
    ? null
    : shift;

export const getCountDownHours = (
  shiftEndTime,
  targetAccountId,
  sourceAccountId
) =>
  CLIENTS_WITHOUT_48H_APPROVAL_LIMIT.includes(targetAccountId) ||
  CLIENTS_WITHOUT_48H_APPROVAL_LIMIT.includes(sourceAccountId)
    ? moment(shiftEndTime).add(100, "years")
    : moment(shiftEndTime).isoWeekday() >= 5
    ? // get the next Monday 17.00pm
      moment(shiftEndTime)
        .startOf("isoWeek")
        .add(1, "week")
        .add(17, "hours")
        .format()
    : moment(shiftEndTime).add(48, "hours");

export function createReducer(handlers, defaultState) {
  return function reducer(state, action) {
    const currState = state || defaultState;
    if (typeof handlers[action.type] === "function") {
      return handlers[action.type](currState, action);
    }
    return currState;
  };
}

export function createAction(type, parsePayload) {
  return payload => ({
    type,
    payload: parsePayload ? parsePayload(payload) : payload
  });
}

// remove from array at index
export const removeAtIndex = (collection, index) => {
  return [...collection.slice(0, index), ...collection.slice(index + 1)];
};

export const matchSleepTimesDayToShiftTimes = (shiftTimes, sleepTimes) => {
  // Extract hour and minute from the selectedSleepTimes
  const sleepStartHour = moment(sleepTimes.startTime).hour();
  const sleepStartMinute = moment(sleepTimes.startTime).minute();
  const sleepEndHour = moment(sleepTimes.endTime).hour();
  const sleepEndMinute = moment(sleepTimes.endTime).minute();

  // Create moment objects for the next occurrence of the sleep times after the shift start time
  const newSleepStart = moment(shiftTimes.startTime)
    .hour(sleepStartHour)
    .minute(sleepStartMinute);
  if (newSleepStart.isBefore(shiftTimes.startTime)) {
    newSleepStart.add(1, "days");
  }

  const newSleepEnd = moment(newSleepStart)
    .hour(sleepEndHour)
    .minute(sleepEndMinute);
  if (newSleepEnd.isBefore(newSleepStart)) {
    newSleepEnd.add(1, "days");
  }

  return { startTime: newSleepStart, endTime: newSleepEnd };
};

import React, { useState } from "react";

import { AuthContext, NOT_AUTHED } from "~/src/auth/contexts";

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(NOT_AUTHED);
  const [isLanding, setIsLanding] = useState(true);
  const [isUnloaded, setIsUnloaded] = useState(false);
  const [isLogout, setIsLogout] = useState(false);

  const value = {
    auth,
    setAuth,
    isLanding,
    setIsLanding,
    isUnloaded,
    setIsUnloaded,
    isLogout,
    setIsLogout
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

import React, { useState, useRef, useEffect } from "react";

import { InputContainer, BoldText } from "./member-information.styles";
import { Input } from "~/src/components/form-components/text-input/text-input.styles";
import flow from "lodash/fp/flow";

import { Role } from "@teamrota/authlib";

import HasRole from "~/src/containers/has-role";
import { errorModal } from "~/src/utils/errors";

import { Scrollable } from "../member-documents/member-documents.styles";
import {
  InputInnerWrapper,
  Label,
  ErrorLabel
} from "~/src/components/form-components/input-styles";
import createOrUpdateMember from "~/src/containers/modals/view-edit-member/graphql/create-or-update-member";
import EditCancel from "~/src/components/edit-cancel";
import { stallPromise } from "~/src/utils";
import getMember from "../graphql/get-member";
import useAuth from "~/src/auth/hooks/use-auth";
import { RotaCheckbox } from "@teamrota/rota-design";

const MemberCompanyInformation = ({
  memberId,
  selfEmployed,
  member,
  createOrUpdateMember
}) => {
  const auth = useAuth();

  const isVATRegistered = member?.memberCompany?.isVATRegistered || false;
  const isHMRCSelfEmployedConfirmationCertificateAttached =
    member?.memberCompany?.isHMRCSelfEmployedConfirmationCertificateAttached ||
    false;
  const isConfirmationCertificateChecked =
    member?.memberCompany?.isConfirmationCertificateChecked || false;
  const isConfirmationOfRightToWork =
    member?.memberCompany?.isConfirmationOfRightToWork || false;
  const isCompanyRegistrationCertificateAttached =
    member?.memberCompany?.isCompanyRegistrationCertificateAttached || false;
  const isConfirmationOfBankDetailsAttached =
    member?.memberCompany?.isConfirmationOfBankDetailsAttached || false;
  const isVATCertificateAttached =
    member?.memberCompany?.isVATCertificateAttached || false;
  const isVATCertificateChecked =
    member?.memberCompany?.isVATCertificateChecked || false;
  const isPublicLiabilityProofAttached =
    member?.memberCompany?.isPublicLiabilityProofAttached || false;
  const isContractForServicesSigned =
    member?.memberCompany?.isContractForServicesSigned || false;

  const [inputs, setInputs] = useState({});
  const [inEditMode, setInEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [hasSaved, setHasSaved] = useState(false);
  const [checkedItems, setCheckedItems] = useState({
    isVATRegistered,
    isHMRCSelfEmployedConfirmationCertificateAttached,
    isConfirmationCertificateChecked,
    isConfirmationOfRightToWork,
    isCompanyRegistrationCertificateAttached,
    isConfirmationOfBankDetailsAttached,
    isVATCertificateAttached,
    isVATCertificateChecked,
    isPublicLiabilityProofAttached,
    isContractForServicesSigned
  });
  const [validationMessage, setValidationMessage] = useState(null);
  const isCurrent = useRef(true);

  const handleInputChange = event => {
    event.persist();
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value
    }));
  };

  const toggleEditMode = () => {
    setInEditMode(!inEditMode);
  };

  useEffect(() => {
    setInputs(inputs => ({
      ...inputs,
      name: member?.memberCompany?.name || ""
    }));
    return () => {
      isCurrent.current = false;
    };
  }, []);

  const handleSave = async () => {
    setIsSaving(true);

    try {
      await stallPromise(
        createOrUpdateMember(memberId, {
          ...inputs,
          ...checkedItems
        })
      );
      setIsSaving(false);
      setHasSaved(true);
      setTimeout(() => setHasSaved(false), 1000);
    } catch (e) {
      setIsSaving(false);
      const message = e.message.includes(
        'duplicate key value violates unique constraint "membercompanies_name_unique"'
      );
      if (message) {
        setValidationMessage("This company name is already registered.");
      } else {
        errorModal(e);
      }
    }
  };

  const canEdit = inEditMode;

  const handleCheckBoxChange = itemId => {
    setCheckedItems({
      ...checkedItems,
      [itemId]: !checkedItems[itemId]
    });
  };

  return (
    <Scrollable>
      <EditCancel
        inEditMode={canEdit}
        onEdit={toggleEditMode}
        onCancel={toggleEditMode}
        onSave={handleSave}
        isSaving={isSaving}
        hasSaved={hasSaved}
        hasEditBtn={true}
        isSaveDisabled={!inputs.name}
      >
        <form>
          {selfEmployed ? (
            <div>
              <InputContainer>
                <Label isLabelGrey={true} isVisible={true}>
                  Registered Name
                </Label>
                <InputInnerWrapper>
                  <Input
                    placeholder="Registered Name"
                    name="name"
                    label="Registered Name"
                    onChange={event => handleInputChange(event)}
                    value={inputs.name}
                    readOnly={!canEdit}
                  />
                </InputInnerWrapper>
              </InputContainer>
              <HasRole role={Role.MEMBERS_BANK}>
                <InputContainer>
                  <Label isLabelGrey={true} isVisible={true}>
                    UTR Name
                  </Label>
                  <InputInnerWrapper>
                    <Input
                      type="input"
                      name="UTRNumber"
                      placeholder="UTR Name"
                      label="UTR Name"
                      onChange={event => handleInputChange(event)}
                      value={
                        inputs.UTRNumber ||
                        member?.memberCompany?.UTRNumber ||
                        ""
                      }
                      readOnly={
                        !canEdit || !auth.hasRole(Role.MEMBERS_BANK_EDIT)
                      }
                    />
                  </InputInnerWrapper>
                </InputContainer>
              </HasRole>
              <div>
                <InputContainer>
                  <RotaCheckbox
                    label="Copy of confirmation self employed from HMRC"
                    onClick={() =>
                      handleCheckBoxChange(
                        "isHMRCSelfEmployedConfirmationCertificateAttached"
                      )
                    }
                    isDisabled={!canEdit}
                    isChecked={
                      checkedItems.isHMRCSelfEmployedConfirmationCertificateAttached
                    }
                  />
                </InputContainer>
                <InputContainer>
                  <RotaCheckbox
                    label="Confirmation of certificate"
                    onClick={() =>
                      handleCheckBoxChange("isConfirmationCertificateChecked")
                    }
                    isDisabled={!canEdit}
                    isChecked={checkedItems.isConfirmationCertificateChecked}
                  />
                </InputContainer>
              </div>
            </div>
          ) : (
            <div>
              <InputContainer>
                <Label isLabelGrey={true} isVisible={true}>
                  Company Name
                </Label>
                <InputInnerWrapper>
                  <Input
                    type="input"
                    name="name"
                    placeholder="Company Name"
                    label="Company Name"
                    onChange={event => handleInputChange(event)}
                    readOnly={!canEdit}
                    value={inputs.name}
                  />
                  {!inputs.name && inEditMode && (
                    <ErrorLabel>Please enter the company name</ErrorLabel>
                  )}
                  {validationMessage && inputs.name && (
                    <ErrorLabel>{validationMessage}</ErrorLabel>
                  )}
                </InputInnerWrapper>
              </InputContainer>
              <InputContainer>
                <Label isLabelGrey={true} isVisible={true}>
                  Registered Number
                </Label>
                <InputInnerWrapper>
                  <Input
                    type="input"
                    name="registeredNumber"
                    placeholder="Registered Number"
                    label="Registered Number"
                    onChange={event => handleInputChange(event)}
                    value={
                      inputs.registeredNumber ||
                      member?.memberCompany?.registeredNumber ||
                      ""
                    }
                    readOnly={!canEdit}
                  />
                </InputInnerWrapper>
              </InputContainer>
              <div>
                <InputContainer>
                  <RotaCheckbox
                    label="Copy of company registration certificate"
                    onClick={() =>
                      handleCheckBoxChange(
                        "isCompanyRegistrationCertificateAttached"
                      )
                    }
                    isDisabled={!canEdit}
                    isChecked={
                      checkedItems.isCompanyRegistrationCertificateAttached
                    }
                  />
                </InputContainer>
                <InputContainer>
                  <RotaCheckbox
                    label="Confirmation of certificate"
                    isDisabled={!canEdit}
                    isChecked={checkedItems.isConfirmationCertificateChecked}
                    onClick={() =>
                      handleCheckBoxChange("isConfirmationCertificateChecked")
                    }
                  />
                </InputContainer>
              </div>
            </div>
          )}
          <BoldText>Registered Address</BoldText>
          <InputContainer>
            <Label isLabelGrey={true} isVisible={true}>
              Registered Address
            </Label>
            <InputInnerWrapper>
              <Input
                type="input"
                name="registeredAddress"
                placeholder="Full address"
                label="Full address"
                onChange={event => handleInputChange(event)}
                value={
                  inputs.registeredAddress ||
                  member?.memberCompany?.registeredAddress ||
                  ""
                }
                readOnly={!canEdit}
              />
            </InputInnerWrapper>
          </InputContainer>
          <BoldText>Invoice Address</BoldText>
          <InputContainer>
            <Label isLabelGrey={true} isVisible={true}>
              Invoice address
            </Label>
            <InputInnerWrapper>
              <Input
                type="input"
                name="invoicingAddress"
                placeholder="Full address"
                label="Full address"
                onChange={event => handleInputChange(event)}
                value={
                  inputs.invoicingAddress ||
                  member?.memberCompany?.invoicingAddress ||
                  ""
                }
                readOnly={!canEdit}
              />
            </InputInnerWrapper>
          </InputContainer>

          <div>
            <InputContainer>
              <RotaCheckbox
                label="Right to work"
                isChecked={checkedItems.isConfirmationOfRightToWork}
                onClick={() =>
                  handleCheckBoxChange("isConfirmationOfRightToWork")
                }
                isDisabled={!canEdit}
              />
            </InputContainer>
            <InputContainer>
              <RotaCheckbox
                label="VAT Registered"
                isChecked={checkedItems.isVATRegistered}
                onClick={() => handleCheckBoxChange("isVATRegistered")}
                isDisabled={!canEdit}
              />
            </InputContainer>
          </div>

          {checkedItems.isVATRegistered && (
            <HasRole role={Role.MEMBERS_BANK}>
              <InputContainer>
                <Label isLabelGrey={true} isVisible={true}>
                  VAT Number
                </Label>
                <InputInnerWrapper>
                  <Input
                    type="input"
                    name="VATRegistrationNumber"
                    placeholder="VAT Number"
                    label="VAT Number"
                    onChange={event => handleInputChange(event)}
                    value={
                      inputs.VATRegistrationNumber ||
                      member?.memberCompany?.VATRegistrationNumber ||
                      ""
                    }
                    readOnly={!canEdit || !auth.hasRole(Role.MEMBERS_BANK_EDIT)}
                  />
                </InputInnerWrapper>
              </InputContainer>
            </HasRole>
          )}
          <div>
            <InputContainer>
              <RotaCheckbox
                label="VAT Certificate attached"
                isChecked={checkedItems.isVATCertificateAttached}
                onClick={() => handleCheckBoxChange("isVATCertificateAttached")}
                isDisabled={!canEdit}
              />
            </InputContainer>
            <InputContainer>
              <RotaCheckbox
                label="VAT Confirmation of Certificate"
                isDisabled={!canEdit}
                isChecked={checkedItems.isVATCertificateChecked}
                onClick={() => handleCheckBoxChange("isVATCertificateChecked")}
              />
            </InputContainer>
            <InputContainer>
              <RotaCheckbox
                label="Public Liability Insurance Proof"
                isDisabled={!canEdit}
                isChecked={checkedItems.isPublicLiabilityProofAttached}
                onClick={() =>
                  handleCheckBoxChange("isPublicLiabilityProofAttached")
                }
              />
            </InputContainer>
            <InputContainer>
              <RotaCheckbox
                label="Contract for services agreed and signed attached "
                isDisabled={!canEdit}
                isChecked={checkedItems.isContractForServicesSigned}
                onClick={() =>
                  handleCheckBoxChange("isContractForServicesSigned")
                }
              />
            </InputContainer>
          </div>
          <HasRole role={Role.MEMBERS_BANK}>
            <BoldText>Bank Details</BoldText>
            <InputContainer>
              <Label isLabelGrey={true} isVisible={true}>
                Bank Name
              </Label>
              <InputInnerWrapper>
                <Input
                  type="input"
                  name="bankName"
                  placeholder="Bank Name"
                  label="Bank Name"
                  onChange={event => handleInputChange(event)}
                  value={
                    inputs.bankName || member?.memberCompany?.bankName || ""
                  }
                  readOnly={!canEdit || !auth.hasRole(Role.MEMBERS_BANK_EDIT)}
                />
              </InputInnerWrapper>
            </InputContainer>
            <InputContainer>
              <Label isLabelGrey={true} isVisible={true}>
                Account Name
              </Label>
              <InputInnerWrapper>
                <Input
                  type="input"
                  name="accountName"
                  placeholder="Account Name"
                  label="Account Name"
                  onChange={event => handleInputChange(event)}
                  value={
                    inputs.accountName ||
                    member?.memberCompany?.accountName ||
                    ""
                  }
                  readOnly={!canEdit || !auth.hasRole(Role.MEMBERS_BANK_EDIT)}
                />
              </InputInnerWrapper>
            </InputContainer>
            <InputContainer>
              <Label isLabelGrey={true} isVisible={true}>
                Sort Code
              </Label>
              <InputInnerWrapper>
                <Input
                  type="input"
                  name="sortCode"
                  placeholder="Sort Code"
                  label="Sort Code"
                  onChange={event => handleInputChange(event)}
                  value={
                    inputs.sortCode || member?.memberCompany?.sortCode || ""
                  }
                  readOnly={!canEdit || !auth.hasRole(Role.MEMBERS_BANK_EDIT)}
                />
              </InputInnerWrapper>
            </InputContainer>
            <InputContainer>
              <Label isLabelGrey={true} isVisible={true}>
                Company Account Number
              </Label>
              <InputInnerWrapper>
                <Input
                  type="input"
                  name="accountNumber"
                  placeholder="Company Account Number"
                  label="Company Account Number"
                  onChange={event => handleInputChange(event)}
                  value={
                    inputs.accountNumber ||
                    member?.memberCompany?.accountNumber ||
                    ""
                  }
                  readOnly={!canEdit || !auth.hasRole(Role.MEMBERS_BANK_EDIT)}
                />
              </InputInnerWrapper>
            </InputContainer>
            {canEdit && (
              <InputContainer>
                <RotaCheckbox
                  label="Copy of bank statement showing above details"
                  isDisabled={!canEdit}
                  isChecked={checkedItems.isConfirmationOfBankDetailsAttached}
                  onClick={() =>
                    handleCheckBoxChange("isConfirmationOfBankDetailsAttached")
                  }
                />
              </InputContainer>
            )}
          </HasRole>
        </form>
      </EditCancel>
    </Scrollable>
  );
};

export default flow(createOrUpdateMember, getMember)(MemberCompanyInformation);

import styled from "styled-components";
import ExitButton from "~/src/components/button/exit-button";

export const StyledExitButton = styled(ExitButton)`
  position: absolute;
  right: 0;
  margin-top: 10px;
  margin-right: 44px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 -5px;
`;

export const Wrapper = styled.div`
  transition: all 0.15s ease-in;

  background-color: #ffffff;
  cursor: default;
  height: 260px;
  width: 428px;
  z-index: 200;
  border-radius: 8px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin-right: -50%;
`;

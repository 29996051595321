import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const UPDATE_USER = gql`
  mutation createUpdateUser(
    $id: ID
    $firstName: String
    $lastName: String
    $email: String
    $phoneNumber: String
    $notes: String
    $shouldRegeneratePin: Boolean
    $canRecieveAllEmails: Boolean
    $applicantAlerts: Boolean
    $hourlyAlerts: Boolean
    $pin: String
    $venueIds: [Int]
  ) {
    createUpdateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      phoneNumber: $phoneNumber
      notes: $notes
      shouldRegeneratePin: $shouldRegeneratePin
      canRecieveAllEmails: $canRecieveAllEmails
      applicantAlerts: $applicantAlerts
      hourlyAlerts: $hourlyAlerts
      pin: $pin
      venueIds: $venueIds
    ) {
      id
      firstName
      lastName
      email
      phoneNumber
      notes
      pin
      canRecieveAllEmails
      usersMeta {
        applicantAlerts
        hourlyAlerts
      }
      venueIds
    }
  }
`;

export default graphql(UPDATE_USER, {
  name: "createUpdateUser",
  props: ownProps => ({
    createUpdateUser: refetchQueries => user =>
      ownProps.createUpdateUser({
        variables: {
          id: user.id, // mandatory when updating
          email: user.email, // mandatory when updating
          firstName: user.firstName,
          lastName: user.lastName,
          phoneNumber: user.phoneNumber,
          notes: user.notes,
          shouldRegeneratePin: user.shouldRegeneratePin,
          canRecieveAllEmails: user.canRecieveAllEmails,
          applicantAlerts: user.applicantAlerts,
          hourlyAlerts: user.hourlyAlerts,
          pin: user.pin,
          venueIds: user.venueIds
        },
        refetchQueries: refetchQueries || []
      })
  })
});

import React, { useState } from "react";
import get from "lodash/fp/get";
import getOr from "lodash/fp/getOr";
import { RotaButtonNew } from "@teamrota/rota-design";
import AsyncCollapse from "~/src/components/async-collapse";
import { Column } from "~/src/components/grid";
import { errorModal } from "~/src/utils/errors";
import Stars from "~/src/components/stars";
import Icon from "~/src/components/icon";
import TimeDisplay from "~/src/components/shift-time-display";
import asyncConfirm from "~/src/utils/async-confirm";
import ActionDropdown from "~/src/components/action-dropdown";
import ViewEditShift from "~/src/containers/modals/view-edit-shift";
import {
  shiftTimes,
  formatDateFull,
  getLengthInClockHours,
  getLengthInClockHoursFromMinutes
} from "~/src/utils/formatting";
import {
  BOOKING_STATES,
  BOOKING_STATES_LABELS,
  UPDATABLE_BOOKING_STATES
} from "~/src/consts";
import ViewEditBooking from "~/src/containers/modals/view-edit-booking";
import { connect } from "react-redux";
import updateBooking from "~/src/graphql/mutations/update-booking/update-booking.decorator";
import FETCH_MEMBER_BOOKINGS from "~/src/graphql/queries/get-member-bookings/get-member-bookings.query";

import tabStructure from "./tab-structure";
import { setTabIndex } from "../../reducer";
import {
  ListContainer,
  EmptyBookings,
  StyledButton,
  Text,
  BookingState,
  Tab,
  Row,
  TabContainer,
  SumBookings,
  LoadMoreWrapper,
  CheckRow,
  CheckContainer,
  StarContaier
} from "./member-shift-list.styles";
import { useQuery } from "@apollo/client";
import useAuth from "~/src/auth/hooks/use-auth";
import ViewEditTimesheet from "~/src/containers/modals/view-edit-timesheet";

const getOrderByDirection = memberQueryData => {
  const bookingState = memberQueryData.bookingState || "";
  const bookingStates = memberQueryData.bookingStates || [];

  const shouldDescend =
    (BOOKING_STATES[bookingState] === BOOKING_STATES.ACCEPTED ||
      bookingStates.includes(BOOKING_STATES.ACCEPTED)) &&
    memberQueryData.startBefore;

  if (shouldDescend) {
    return "DESC";
  }

  return "ASC";
};

const MemberShiftList = props => {
  const auth = useAuth();

  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [selectedShiftId, setSelectedShiftId] = useState(null);
  const [sourceAccountId, setSourceAccountId] = useState(null);
  const [
    selectedTimesheetBookingId,
    setSelectedTimesheetBookingId
  ] = useState();
  const [selectedTimesheetStatus, setSelectedTimesheetStatus] = useState("");

  const memberQueryData = props.memberBookingList[props.memberId] || {};

  const { data, loading, fetchMore } = useQuery(FETCH_MEMBER_BOOKINGS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    variables: auth.addVals(FETCH_MEMBER_BOOKINGS, {
      limit: 10,
      offset: 0,
      memberId: props.memberId,
      stateEquals: get("bookingState", memberQueryData)
        ? BOOKING_STATES[memberQueryData.bookingState]
        : undefined,
      stateIn: memberQueryData.bookingStates || undefined,
      startBefore: memberQueryData.startBefore || undefined,
      startAfter: memberQueryData.startAfter || undefined,
      isWithAccount: true,
      orderByColumn: "shifts.startTime",
      orderByDirection: getOrderByDirection(memberQueryData)
    })
  });

  const bookings = data?.account?.member?.bookings?.data || [];
  const bookingsCount = data?.account?.member?.bookingsCount || {};

  const loadMore = async () => {
    try {
      await fetchMore({
        variables: {
          offset: bookings?.length || 10
        }
      });
    } catch (e) {
      errorModal(e);
    }
  };

  const getTotalBookings = () => {
    const keysToSum = [
      "appliedCount",
      "upcomingAccepted",
      "previouslyAccepted",
      "waitingListCount",
      "cancelledCount"
    ];

    if (bookingsCount) {
      return keysToSum.reduce((sum, key) => sum + bookingsCount[key], 0);
    }

    return 0;
  };

  const selectOptions = Object.keys(UPDATABLE_BOOKING_STATES).map(state => ({
    label: UPDATABLE_BOOKING_STATES[state],
    value: state
  }));

  const handleStateChange = async (booking, newState) => {
    const message =
      "Are you sure you want to update this booking to" +
      ` '${BOOKING_STATES_LABELS[newState]}'`;

    if (
      await asyncConfirm(message, {
        shouldHideSubText: true,
        shouldAddPadding: true,
        confirmButtonText: "Yes",
        isConfirmButtonGreen: true
      })
    ) {
      try {
        await props.updateBooking({
          refetchQueries: ["fetchMemberBookings"],
          variables: {
            id: booking.id,
            state: newState,
            accountType: "provider"
          }
        });
      } catch (e) {
        errorModal(e);
      }
    }
  };

  const handleCloseModal = () => {
    setSelectedBookingId(null);
    setSourceAccountId(null);
    setSelectedTimesheetBookingId(null);
  };

  const handleOpenModal = (selectedBookingId, sourceAccountId) => {
    setSelectedBookingId(selectedBookingId);
    setSourceAccountId(sourceAccountId);
  };

  const handleHideShiftModal = () => {
    setSelectedShiftId(null);
  };

  const handleOpenShiftModal = selectedShiftId => {
    setSelectedShiftId(selectedShiftId);
  };

  const handleOpenTimesheetModal = (
    selectedTimesheetBookingId,
    sourceAccountId,
    timesheetStatus
  ) => {
    setSelectedTimesheetBookingId(selectedTimesheetBookingId);
    setSourceAccountId(sourceAccountId);
    setSelectedTimesheetStatus(timesheetStatus);
  };

  const shouldDisableLoadMore = () => {
    const activeTab = getOr(
      "",
      `memberBookingList.${props.memberId}.activeTab`,
      props
    );

    if (!bookings || !bookings.length || !activeTab) {
      return true;
    }

    // Only enable load more if there is more to load 🙈
    if (bookingsCount[activeTab] <= bookings.length) {
      return true;
    }

    return false;
  };

  const totalBookings = getTotalBookings();

  const activeTab = getOr(
    "",
    `memberBookingList.${props.memberId}.activeTab`,
    props
  );

  return (
    <AsyncCollapse isExpanded={props.isExpanded} isLoading={loading}>
      <ViewEditShift
        isOpen={!!selectedShiftId}
        onClose={handleHideShiftModal}
        shiftId={selectedShiftId}
      />
      <ViewEditBooking
        isOpen={!!selectedBookingId}
        onClose={handleCloseModal}
        bookingId={selectedBookingId}
        sourceAccountId={sourceAccountId}
      />
      <ViewEditTimesheet
        isOpen={!!selectedTimesheetBookingId}
        onClose={handleCloseModal}
        bookingId={selectedTimesheetBookingId}
        sourceAccountId={sourceAccountId}
        selectedTimesheetStatus={selectedTimesheetStatus}
      />
      <ListContainer>
        <TabContainer>
          <SumBookings>All {totalBookings}</SumBookings>
          {tabStructure.map((tab, key) => (
            <Tab
              isActive={tab.keyProp === activeTab}
              key={key}
              onClick={() => {
                props.dispatch(
                  setTabIndex({
                    memberId: props.memberId,
                    bookingState: tab.bookingState,
                    bookingStates: tab.bookingStates,
                    activeTab: tab.keyProp,
                    ...tab.queryConditions
                  })
                );
              }}
            >
              {tab.title} {getOr(0, tab.keyProp, bookingsCount)}
            </Tab>
          ))}
        </TabContainer>
        {bookings?.length ? (
          bookings.map((booking, index) => {
            let { startFinal, endFinal } = booking.bookingState;
            let lengthFinal = getLengthInClockHours(
              booking.bookingState.lengthFinal
            );
            let breakFinal = booking.bookingState?.breakFinal;
            if (booking.timesheetMemberRow?.id) {
              const {
                totalMinutes,
                breakMinutes,
                scheduledStartTime,
                scheduledEndTime
              } = booking.timesheetMemberRow;

              startFinal = scheduledStartTime;
              endFinal = scheduledEndTime;
              lengthFinal = getLengthInClockHoursFromMinutes(totalMinutes);
              breakFinal = breakMinutes;
            }
            return (
              <Row key={index}>
                <Column align="middle" gutter="none" small={8 / 12}>
                  <Text>
                    {booking.shift.sourceAccount.accountName}&nbsp;
                    {shiftTimes(booking.shift.startTime, booking.shift.endTime)}
                    {activeTab === "cancelledCount" ? (
                      <BookingState>
                        &nbsp;({BOOKING_STATES_LABELS[booking.state]})
                      </BookingState>
                    ) : null}
                  </Text>
                  <Text isSmall>{get("shift.venue.name", booking)}</Text>
                  <Text isSmall>{get("shift.role.name", booking)}</Text>
                </Column>
                <Column
                  align="middle"
                  textAlign="right"
                  gutter="none"
                  small={4 / 12}
                >
                  {activeTab === "previouslyAccepted" ? (
                    <CheckContainer>
                      <div>Check-In: {formatDateFull(startFinal)}</div>
                      <CheckRow>
                        <TimeDisplay
                          displayDate={`Check-Out:
                            ${formatDateFull(endFinal)}`}
                          startTime={startFinal}
                          endTime={endFinal}
                        />
                      </CheckRow>
                      <Text isSmall>
                        <Icon
                          name={Icon.names.CLOCK}
                          color={Icon.colors.CLOUDY_BLUE}
                          size="xsmall"
                        />
                        &nbsp;
                        {lengthFinal}h &nbsp;
                        {breakFinal ? `${breakFinal}mins break` : "No break"}
                      </Text>
                    </CheckContainer>
                  ) : null}
                  {booking.ratingOfMember ? (
                    <StarContaier>
                      <Stars
                        isSmall
                        value={parseInt(booking.ratingOfMember, 10)}
                      />
                    </StarContaier>
                  ) : null}
                  <ActionDropdown
                    isSmall
                    isPrimary
                    onChange={option => {
                      handleStateChange(booking, option.value);
                    }}
                    value={BOOKING_STATES_LABELS[booking.state]}
                    options={selectOptions.filter(
                      option => option.value !== BOOKING_STATES[booking.state]
                    )}
                  />
                  {booking.timesheetMemberRow?.id && (
                    <StyledButton
                      isSmall
                      isOutline
                      onClick={() =>
                        handleOpenTimesheetModal(
                          booking.id,
                          booking.shift.sourceAccount.id,
                          booking.timesheetMemberRow?.timesheet.status
                        )
                      }
                    >
                      View Timesheet
                    </StyledButton>
                  )}
                  <StyledButton
                    isSmall
                    isOutline
                    onClick={() =>
                      handleOpenModal(
                        booking.id,
                        booking.shift.sourceAccount.id
                      )
                    }
                  >
                    View Booking
                  </StyledButton>
                  <StyledButton
                    isSmall
                    isOutline
                    onClick={() => handleOpenShiftModal(booking.shift.id)}
                  >
                    View Shift
                  </StyledButton>
                </Column>
              </Row>
            );
          })
        ) : (
          <EmptyBookings>No bookings</EmptyBookings>
        )}
        {!shouldDisableLoadMore() && (
          <LoadMoreWrapper>
            <RotaButtonNew onClick={loadMore}>Load more</RotaButtonNew>
          </LoadMoreWrapper>
        )}
      </ListContainer>
    </AsyncCollapse>
  );
};

const mapStateToProps = s => ({
  memberBookingList: s.myStaff.memberBookingList
});

export default connect(mapStateToProps)(updateBooking(MemberShiftList));

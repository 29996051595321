import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { handleErrors } from "~/src/utils/errors";

const QUERY = gql`
  query getUniformItemSuggestions {
    account {
      id
      uniformItemSuggestions
    }
  }
`;

export default graphql(QUERY, {
  props: handleErrors(({ data: { account, loading } }) => ({
    uniformItemSuggestions: account?.uniformItemSuggestions,
    isLoading: loading
  }))
});

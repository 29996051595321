import { createReducer, createAction } from "~/src/utils";

export const ON_SEARCH = "notifications/history/ON_SEARCH";
export const ON_UPDATE_FILTERS = "notifications/history/ON_UPDATE_FILTERS";
export const onSearch = createAction(ON_SEARCH);
export const onUpdateFilters = createAction(ON_UPDATE_FILTERS);

const DEFAULT_STATE = {
  searchText: "",
  filters: {}
};

export default createReducer(
  {
    [ON_SEARCH]: (state, { payload }) => ({
      ...state,
      searchText: payload
    }),
    [ON_UPDATE_FILTERS]: (state, { payload }) => ({
      ...state,
      filters: {
        ...state.filters,
        ...payload
      }
    })
  },
  DEFAULT_STATE
);

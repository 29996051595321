import styled from "styled-components";

export const Terms = styled.div`
  color: ${({ theme }) => theme.text.secondary};
  padding: 30px;
  text-align: center;
`;

export const Link = styled.a`
  text-decoration: underline;
  color: ${({ theme }) => theme.text.secondary} !important;
  cursor: pointer;
`;

import Dinero from "dinero.js";
import difference from "lodash/difference";

Dinero.defaultCurrency = "GBP";
Dinero.globalRoundingMode = "HALF_UP";
Dinero.globalFormat = "$0,0.00";

const Money = Dinero;

export const moneyType = obj =>
  !difference(
    Object.keys(Money()),
    obj instanceof Object ? Object.keys(obj) : {}
  ).length;

export const moneyString = val => {
  if (!moneyType(val)) return "";

  return val.toFormat();
};

export default Money;

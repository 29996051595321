import React from "react";
import Stars from "~/src/components/stars";
import {
  FlexContainer,
  CheckRow,
  LabelContainer
} from "./past-bookings.styles";

export default function BookingRating(props) {
  return (
    <FlexContainer>
      <LabelContainer>
        <CheckRow>Staff&nbsp;</CheckRow>
        <CheckRow>Requester&nbsp;</CheckRow>
      </LabelContainer>
      <div>
        <CheckRow>
          <Stars value={parseInt(props.booking.ratingOfMember, 10)} isSmall />
        </CheckRow>
        {/* TODO: implement feedback for member - awaiting timesheets */}
        <CheckRow>
          <Stars value={parseInt(props.booking.ratingOfManager, 10)} isSmall />
        </CheckRow>
        {/* TODO: implement feedback for manager - awaiting timesheets */}
      </div>
    </FlexContainer>
  );
}

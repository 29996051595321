import React from "react";

import {
  StyledMemberName,
  StyledIssue,
  StyledHighlight,
  StyledEmph
} from "../styles";
import { useTheme } from "styled-components";

export const WorkingHoursLimit = ({ memberName, children, member }) => {
  const theme = useTheme();
  return (
    <>
      <StyledMemberName>
        The member <br></br> "{memberName}" has reached their working hours
        limit.
      </StyledMemberName>
      <StyledIssue style={{ border: "none" }}>
        {children}
        <StyledHighlight style={{ color: theme.error.main }}>
          Limited to {member.limitedHours ?? 0} hours / week
        </StyledHighlight>
      </StyledIssue>
    </>
  );
};

export const QualifiedAndUnavailable = ({ memberName, children }) => (
  <>
    <StyledMemberName>{memberName}</StyledMemberName>
    <StyledIssue style={{ paddingBottom: "24px" }}>{children}</StyledIssue>
  </>
);

export const Conflicted = ({ memberName, conflictTimes, member }) => {
  const theme = useTheme();
  return (
    <>
      <StyledMemberName>
        The member <br></br>"{memberName}" has a conflict.
      </StyledMemberName>
      <StyledIssue>
        <StyledEmph>
          By overriding, {member.firstName}'s will be booked on to two different
          shifts at the same time:
        </StyledEmph>
        <StyledHighlight style={{ color: theme.error.main }}>
          {conflictTimes}
        </StyledHighlight>
      </StyledIssue>
    </>
  );
};

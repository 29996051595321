import React from "react";
import moment from "moment-timezone";
import { ZonedDate } from "@teamrota/rota-common";

import Button from "~/src/components/button";
import { getCalendarDateRange } from "../../helpers";
import TimeField from "../time-field";
import {
  IconButton,
  PickerWrapper,
  PickerHeader,
  PickerHeaderSection,
  PickerCalendar,
  CalendarDate,
  CalendarHeader,
  FooterWrapper,
  ErrorMessage,
  PickerInner
} from "./picker.styles";

const Picker = ({
  visibleDate,
  isDisabled,
  rangeStart,
  rangeEnd,
  isDateOnly,
  shouldNotSelectPastDate,
  shouldNotSelectFutureDate,
  highlightToday,
  isFieldError,
  isStartOnly,
  startTime,
  endTime,
  handleStartChange,
  handleEndChange,
  onClose,
  onVisibleDateChange,
  onSelectDate
}) => {
  const handleSelect = dateItem => {
    onSelectDate(
      moment(dateItem)
        .startOf("day")
        .toDate()
    );
  };
  const date = moment(visibleDate).startOf("month");
  const dates = getCalendarDateRange(date);
  const dateChange = change => () => {
    onVisibleDateChange(
      date
        .clone()
        .add(change, "month")
        .toDate()
    );
  };

  return (
    <PickerWrapper isDisabled={isDisabled} isDateOnly={isDateOnly}>
      <PickerInner>
        <PickerHeader>
          <PickerHeaderSection>{date.format("MMMM YYYY")}</PickerHeaderSection>
          <PickerHeaderSection>
            <IconButton
              iconName={Button?.iconNames?.ARROW_LEFT}
              onClick={dateChange(-1)}
              isIconOnly
              isGrey
              isXSmall
            />
            <IconButton
              iconName={Button?.iconNames?.ARROW_RIGHT}
              onClick={dateChange(1)}
              isIconOnly
              isGrey
              isXSmall
            />
          </PickerHeaderSection>
        </PickerHeader>
        <PickerCalendar>
          <CalendarHeader>
            {["M", "T", "W", "T", "F", "S", "S"].map((char, i) => (
              <CalendarDate key={i}>{char}</CalendarDate>
            ))}
          </CalendarHeader>
          {dates.map((dateItem, i) => (
            <CalendarDate
              onClick={() => handleSelect(dateItem)}
              isPast={dateItem.isBefore(date)}
              isSelectedStart={rangeStart && dateItem.isSame(rangeStart, "day")}
              isSelectedEnd={rangeEnd && dateItem.isSame(rangeEnd, "day")}
              hasRangeEnd={Boolean(rangeEnd)}
              isSameStartEnd={
                rangeStart &&
                rangeEnd &&
                moment(rangeEnd).isSame(moment(rangeStart), "day")
              }
              isSelectedBetween={
                !isDateOnly &&
                rangeStart &&
                dateItem.isBetween(rangeStart, rangeEnd, "day")
              }
              isClickable
              isDateSelectable={
                (shouldNotSelectPastDate && dateItem.isBefore(moment())) ||
                (shouldNotSelectFutureDate && dateItem.isAfter(moment()))
              }
              key={i}
              highlightToday={
                highlightToday && dateItem.isSame(new ZonedDate(), "day")
              }
            >
              {dateItem.date()}
            </CalendarDate>
          ))}
        </PickerCalendar>

        {!isDateOnly && (
          <div>
            <FooterWrapper>
              <TimeField
                isError={isFieldError}
                label={!isStartOnly ? "Start" : ""}
                isMarginRight
                value={startTime}
                onChange={handleStartChange}
              />
              {!isStartOnly ? (
                <TimeField
                  isError={isFieldError}
                  label="End"
                  value={endTime}
                  onChange={handleEndChange}
                />
              ) : null}
            </FooterWrapper>

            <FooterWrapper>
              <Button onClick={onClose} isBlock>
                Apply
              </Button>
            </FooterWrapper>
          </div>
        )}

        <ErrorMessage isVisible={isFieldError}>
          Start time is before the end
        </ErrorMessage>
      </PickerInner>
    </PickerWrapper>
  );
};

export default Picker;

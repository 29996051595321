import moment from "moment-timezone";
import get from "lodash/fp/get";

export default function handleEditsWithinCancellationPolicyPeriod(
  shift,
  policy
) {
  const minimumShiftLength = get("minimumShiftLength", policy);
  const minimumShiftPercentage = get("minimumShiftPercentage", policy);
  const cancellationPeriod = get("cancellationPeriod", policy);
  if (
    [minimumShiftLength, minimumShiftPercentage, cancellationPeriod].every(
      plcy => plcy !== undefined
    )
  ) {
    const originalStartTime = shift.originalStartTime || shift.startTime;
    const originalEndTime = shift.originalEndTime || shift.endTime;

    const cancelPeriodStart = moment(originalStartTime).subtract(
      cancellationPeriod,
      "hours"
    );

    if (moment().isBetween(cancelPeriodStart, moment(originalStartTime))) {
      const oldLengthOfShift = moment(originalEndTime).diff(
        originalStartTime,
        "minutes"
      );
      const newLengthOfShift = moment(shift.endTime).diff(
        shift.startTime,
        "minutes"
      );

      if (
        newLengthOfShift < oldLengthOfShift * (minimumShiftPercentage / 100) ||
        newLengthOfShift < minimumShiftLength
      ) {
        const errorMessage =
          `The shift must be a minimum of ${minimumShiftLength} hours ` +
          `or ${minimumShiftPercentage}% of the original shift length, ` +
          `whichever is the greater, ` +
          `as it is within ${cancellationPeriod} hours of the shift start time. ` +
          `In accordance with our `;

        return errorMessage;
      }
    }
  }

  return "";
}

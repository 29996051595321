import React from "react";
import InputWrapper from "../input-wrapper";
import { RotaCheckbox } from "@teamrota/rota-design";

export default props => (
  <InputWrapper {...props}>
    <RotaCheckbox
      isDisabled={props.readOnly}
      isChecked={props.input.value}
      onClick={() => props.input.onChange(!props.input.value)}
      label={props.label}
    />
  </InputWrapper>
);

import moment from "moment-timezone";
import { ZonedDate } from "@teamrota/rota-common";
import { PARTNER_MANAGED_THRESHOLD } from "~/src/consts";

export function isNotSameDay(startTime, endTime) {
  return !moment(startTime).isSame(endTime, "day");
}

export const isWithinThreeDays = shiftTimes => {
  const firstShiftStartTime = Object.values(shiftTimes)[0].startTime;

  return (
    moment(firstShiftStartTime).diff(moment(), "days") <
    PARTNER_MANAGED_THRESHOLD
  );
};

export function isNotWithin24HoursFromStart(startTime, endTime) {
  return moment(endTime).diff(moment(startTime), "hours") > 24;
}

export function isStartTimeGreaterThanEndTime(startTime, endTime) {
  return moment(endTime).isSameOrAfter(startTime);
}

export function createValidDateFromString(value) {
  let correctDate;
  switch (true) {
    case moment(value).isValid():
      correctDate = moment(value).toDate();
      break;
    case moment(value, "Do MMM [at] hh:mm").isValid():
      correctDate = moment(value, "Do MMM [at] hh:mm").toDate();
      break;
    default:
      correctDate = new ZonedDate(value);
  }
  return correctDate;
}

export const prefillToToday = () => {
  const startTime = moment().set({ second: 0, millisecond: 0 });
  const endTime = startTime.clone().add(moment.duration(4, "hours"));
  return { startTime, endTime };
};

export function hoursFromShift(startTime) {
  return moment(startTime).diff(moment(), "hours", true);
}

export function isWithinTimeRangeHours(startTime, hours) {
  return moment(startTime).diff(moment(), "hours", true) < hours;
}

export const isFormattedDateValid = date => {
  return moment(date, "DD/MM/YYYY", true).isValid();
};

export const getTimeFromDate = date =>
  date ? moment(date).format("HH:mm") : "";

export const setDate = time => {
  if (!time) return null;

  return moment(new ZonedDate()).set({
    hour: time.split(":")[0],
    minute: 0,
    second: 0,
    millisecond: 0
  });
};

export const getShiftTimes = () => {
  const hours = Array.from(Array(24).keys());

  return hours.map(hour => {
    const time = moment({
      hour,
      minutes: 0
    }).format("HH:mm");

    return { value: time, label: time };
  });
};

export const getDateString = date => {
  return date ? new ZonedDate(moment(date, "DD/MM/YYYY", true)) : "";
};

import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { RotaButton, RotaButtonIcon } from "@teamrota/rota-design";

import AlertDialog from "~/src/containers/settings-page/dialog";

import PageHeader from "~/src/components/page-header";
import Details from "~/src/components/member-details";
import {
  FloatingTable,
  ColumnHeading,
  TableBody,
  TableData,
  TableRow
} from "~/src/components/floating-table";
import TablePlaceholder from "~/src/components/table-placeholder";
import NoResults from "~/src/components/no-results";

import { formatDateMonthYear } from "~/src/utils/formatting";
import { errorModal } from "~/src/utils/errors";

import ServiceAreasModal from "../modals/service-areas";

import { GET_SERVICE_AREAS } from "./graphql/get-service-areas";
import DELETE_ITEM from "~/src/graphql/mutations/delete-item/delete-item.query";

import { ButtonsContainer } from "./service-areas.styles";

const ServiceAreas = () => {
  const [isServiceAreaModalOpen, setIsServiceAreaModalOpen] = useState(false);
  const [
    isServiceAreaDeleteModalOpen,
    setIsServiceAreaDeleteModalOpen
  ] = useState(false);
  const [activeServiceArea, setActiveServiceArea] = useState(null);

  const { data, loading: isLoading } = useQuery(GET_SERVICE_AREAS);
  const serviceAreas = data?.account?.serviceAreas || [];

  const [deleteItem] = useMutation(DELETE_ITEM, {
    refetchQueries: ["getServiceAreas"]
  });

  const handleOpenEditModal = activeServiceArea => {
    setActiveServiceArea(activeServiceArea);
    setIsServiceAreaModalOpen(true);
  };

  const handleOpenCreateModal = () => {
    setActiveServiceArea(null);
    setIsServiceAreaModalOpen(true);
  };

  const handleCloseModal = () => {
    setActiveServiceArea(null);
    setIsServiceAreaModalOpen(false);
  };

  const handleOpenDeleteModal = activeServiceArea => {
    setActiveServiceArea(activeServiceArea);
    setIsServiceAreaDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    if (!isServiceAreaModalOpen) setActiveServiceArea(null);
    setIsServiceAreaDeleteModalOpen(false);
  };

  const deleteArea = async () => {
    try {
      await deleteItem({
        variables: {
          type: "serviceAreas",
          id: activeServiceArea.id
        }
      });
    } catch (e) {
      errorModal(e);
    } finally {
      setActiveServiceArea(null);
      setIsServiceAreaModalOpen(false);
      setIsServiceAreaDeleteModalOpen(false);
    }
  };

  const isNoResults = serviceAreas.length === 0 && !isLoading;

  return (
    <>
      <ServiceAreasModal
        isOpen={isServiceAreaModalOpen}
        serviceArea={activeServiceArea}
        onClose={handleCloseModal}
        deleteArea={handleOpenDeleteModal}
      />
      <PageHeader
        title="Service Areas"
        subtext={`${(serviceAreas || []).length} found`}
      >
        <RotaButton onClick={handleOpenCreateModal}>Add new</RotaButton>
      </PageHeader>
      <FloatingTable>
        <>
          <ColumnHeading width={6 / 12}>Area</ColumnHeading>
          <ColumnHeading width={6 / 12}>&nbsp;</ColumnHeading>
        </>
        <TableBody>
          {isNoResults ? (
            <NoResults />
          ) : (
            <>
              {isLoading && (
                <TablePlaceholder columnWidths={[6 / 12, 6 / 12]} />
              )}
              {serviceAreas.map((serviceArea, index) => (
                <TableRow key={index}>
                  <TableData width={11 / 12}>
                    <Details
                      key={index}
                      id={serviceArea.id}
                      email={formatDateMonthYear(serviceArea.createdAt)}
                      firstName={serviceArea.name}
                    />
                  </TableData>
                  <TableData width={1 / 12}>
                    <ButtonsContainer>
                      <RotaButtonIcon
                        title="Edit"
                        icon="edit"
                        onClick={() => handleOpenEditModal(serviceArea)}
                      />
                      <RotaButtonIcon
                        title="Delete"
                        icon="delete"
                        onClick={() => handleOpenDeleteModal(serviceArea)}
                      />
                    </ButtonsContainer>
                  </TableData>
                </TableRow>
              ))}
            </>
          )}
        </TableBody>
      </FloatingTable>
      <AlertDialog
        openDialog={isServiceAreaDeleteModalOpen}
        handleClose={handleCloseDeleteModal}
        onClickHandler={deleteArea}
        dialogTitle="Are you sure you want to 
        delete this Service Area?"
        declineBtnText="Cancel"
        acceptBtnText="Delete"
        isDelete
      >
        If you delete this Service Area you won’t be able to recover it later.
      </AlertDialog>
    </>
  );
};

export default ServiceAreas;

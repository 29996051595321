import { POOL_TYPES } from "@teamrota/rota-common";

export default function getPoolType(relationships, sourceAccountId) {
  const relationship = (relationships || []).find(
    rel => rel.account.id === sourceAccountId
  );

  if (relationship) {
    return relationship.poolType;
  }

  return POOL_TYPES.RECOMMENDED;
}

import styled from "styled-components";
import Icon from "~/src/components/icon";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";

export const MenuContainer = styled.div`
  position: relative;
  display: inline-block;
  width: auto;
`;

export const Dropdown = styled.div`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  position: absolute;
  background-color: ${({ theme }) => theme.white};
  width: 10em;
  position: absolute;
  height: auto;
  box-shadow: ${STYLE_CONSTS.FLOATING};
  border: ${({ theme }) => `solid 1.2px ${theme.neutral.lighter}`};
  border-radius: ${STYLE_CONSTS.BORDER_RADIUS};
  z-index: ${STYLE_CONSTS.Z_INDEX_SELECT};
  transform: translateX(-82%);
`;

export const ListItem = styled.div`
  font-size: ${STYLE_CONSTS.FONT_SMALL};
  color: ${COLORS.COOL_GREY};
  margin: ${STYLE_CONSTS.UNIT_2};
  cursor: pointer;
`;

export const StyledIcon = styled(Icon)`
  display: ${({ isHidden }) => (isHidden ? "none" : "")};
  margin-left: ${STYLE_CONSTS.UNIT_1};
`;

import { CLUSTER_EVENT_HEIGHT, CLUSTER_MARGIN_BOTTOM } from "./styles";

const C_EVENT_HEIGHT = parseInt(CLUSTER_EVENT_HEIGHT, 10);
const C_MARGIN_BOTTOM = parseInt(CLUSTER_MARGIN_BOTTOM, 10);

export const getOverrideHeight = (cluster, scrollViewHeight) => {
  if (cluster.events.length <= 1) return null;
  const contentHeight =
    cluster.events.length * (C_EVENT_HEIGHT + C_MARGIN_BOTTOM);
  if (contentHeight >= scrollViewHeight) return null;
  return (
    (scrollViewHeight - cluster.events.length * C_MARGIN_BOTTOM) /
    cluster.events.length
  );
};

import { createSelector } from "reselect";
import get from "lodash/fp/get";
import find from "lodash/fp/find";
import { surgeRoleOptions } from "~/src/services/surge-pricing";
import { extractShiftTitle } from "../shared";
import * as utils from "../../utils";

export const useDayRoleOptions = createSelector(
  [
    props => props.initialPostStart,
    props => props.targetAccountId,
    props => props.dayRoleOptions
  ],
  (initialPostStart, targetAccountId, dayRoleOptions) => {
    return surgeRoleOptions({
      startTime: initialPostStart,
      targetAccountId,
      roleOptions: dayRoleOptions
    });
  }
);

export const useCalculatedRoleOptions = createSelector(
  [
    props => props.initialPostStart,
    props => props.targetAccountId,
    props => props.calculatedRoleOptions
  ],
  (initialPostStart, targetAccountId, calculatedRoleOptions) => {
    return surgeRoleOptions({
      startTime: initialPostStart,
      targetAccountId,
      roleOptions: calculatedRoleOptions
    });
  }
);

export const useUncalculatedRoleOptions = createSelector(
  [
    props => props.initialPostStart,
    props => props.targetAccountId,
    props => props.uncalculatedRoleOptions
  ],
  (initialPostStart, targetAccountId, uncalculatedRoleOptions) => {
    return surgeRoleOptions({
      startTime: initialPostStart,
      targetAccountId,
      roleOptions: uncalculatedRoleOptions
    });
  }
);

// Map props<venueOptions> to utls.createVenueOptions
export const useVenueOptions = createSelector(
  props => props.venueOptions,
  venueOptions => {
    return utils.createVenueOptions(venueOptions);
  }
);

// Handle roleRate select change
export const useUpdateRoleRate = createSelector(
  [
    props => props.handleShiftUpdate,
    props => props.shift,
    props => props.roleRates
  ],
  (handleShiftUpdate, shift, roleRates) => roleRateId => {
    const currentRoleAccountId = get("privates.roleRateAccount.id", shift);
    const roleRate = find(({ id }) => id === roleRateId, roleRates);
    const account = get("account", roleRate);
    const isAccountUpdated = currentRoleAccountId !== get("id", account);

    handleShiftUpdate({
      roleRateId,
      isUncalculatedRate: roleRate?.isUncalculatedRate,
      assignedMemberIds: isAccountUpdated ? [] : shift.assignedMemberIds,
      requestedMemberIds: isAccountUpdated ? [] : shift.requestedMemberIds,
      privates: {
        ...shift.privates,
        roleRateAccount: account
      }
    });
  }
);

// Handle venue select change
export const useUpdateVenue = createSelector(
  [props => props.handleShiftUpdate],
  handleShiftUpdate => venueId => {
    handleShiftUpdate({
      venueId
    });
  }
);

// Map props to formTitle
export const getFormTitle = createSelector(
  [props => props.shift, props => props.venueOptions, props => props.roleRates],
  extractShiftTitle
);

// Map state to props<roleRateId, venueId>
export const mapStateToProps = ({
  calendar: {
    shiftForm: { shifts, shiftOpenIndex }
  }
}) => ({
  shift: shifts[shiftOpenIndex]
});

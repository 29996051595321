import { gql } from "@apollo/client";

export const SHIFT_TAGS_SUGGESTIONS = gql`
  query shiftTagsSuggestions($roleRateId: ID, $accountId: ID) {
    shiftTagsSuggestions(roleRateId: $roleRateId, accountId: $accountId) {
      tags {
        id
        name
      }
      suggestions {
        id
        name
      }
    }
  }
`;

export const GET_ACCOUNT_ID = gql`
  query getAccountId {
    account {
      id
    }
  }
`;

export const GET_TARGET_ACCOUNT = gql`
  query getTargetAccount($accountId: ID!) {
    account {
      id
      targetAccount(accountId: $accountId) {
        id
        accountName
        logo
        createdAt
        accountName
        contactEmail
        contactWeb
        contactPhone
        isAutoAcceptEnabled
        notes
        tags {
          id
          name
        }
      }
    }
  }
`;

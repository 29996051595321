import { gql, useMutation } from "@apollo/client";
import { useToast } from "@teamrota/rota-design";

const UPDATE_PARTNER_BREAK_RULES = gql`
  mutation UpdatePartnerBreakRules(
    $partnerId: ID!
    $applyToAllVenues: Boolean
    $payrollBreakId: ID
    $partnerBreakRulesInput: [PartnerBreakRulesInputType!]
  ) {
    updatePartnerBreakRules(
      partnerId: $partnerId
      applyToAllVenues: $applyToAllVenues
      payrollBreakId: $payrollBreakId
      partnerBreakRulesInput: $partnerBreakRulesInput
    ) {
      partnerBreakRules {
        id
        venue {
          id
          name
        }
        payrollBreak {
          id
          name
        }
      }
      connection {
        id
      }
    }
  }
`;

function useUpdatePartnerBreakRules() {
  const [updatePartnerBreakRules, { data, loading }] = useMutation(
    UPDATE_PARTNER_BREAK_RULES,
    {
      refetchQueries: ["getConnections"]
    }
  );

  const { showToast } = useToast();

  const updateRules = async variables => {
    try {
      const response = await updatePartnerBreakRules({ variables });

      showToast("Updated", { severity: "success" });

      return response.data.updatePartnerBreakRules;
    } catch (e) {
      showToast(e.message, { severity: "error" });
    }
  };

  return {
    updatePartnerBreakRules: updateRules,
    data,
    loading
  };
}

export default useUpdatePartnerBreakRules;

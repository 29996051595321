import { gql } from "@apollo/client";

export const REQUEST_ASSIGN_MEMBERS = gql`
  mutation createBookings(
    $shiftId: ID!
    $members: [memberBookingType]!
    $replacementBookings: [replacementBookingInputType]
  ) {
    createBookings(
      members: $members
      shiftId: $shiftId
      replacementBookings: $replacementBookings
    ) {
      id
    }
  }
`;

import React from "react";
import { Link } from "react-router-dom";

import Notifier from "~/src/components/notifier";
import LocalTime from "../local-time";
import UserMenu from "../../components/user-menu";
import { Wrapper, NavItem, NotifCountWrapper } from "./index.styles";
import { useLocation } from "react-router";

export default React.memo(({ options }) => {
  const { pathname } = useLocation();
  const currentRoot = pathname;

  return (
    <Wrapper>
      <UserMenu />
      <LocalTime />

      <div>
        {options?.map(
          (opt, i) =>
            opt.isVisible && (
              <Link key={i} to={opt.to}>
                <NavItem
                  isActive={currentRoot.split("/")[1] === opt.to.split("/")[1]}
                >
                  {opt.label}
                  {opt.notifCoun && (
                    <NotifCountWrapper>
                      <Notifier number={opt.notifCount} />
                    </NotifCountWrapper>
                  )}
                </NavItem>
              </Link>
            )
        )}
      </div>
    </Wrapper>
  );
});

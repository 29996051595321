import React, { useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RotaCheckbox, RotaDivider } from "@teamrota/rota-design";
import { useQuery } from "@apollo/client";
import { Role } from "@teamrota/authlib";
import { GET_ROLE_RATE } from "~/src/components/RateBreakdown/graphql";
import { CHECK_ROLE_PARTNER_MANAGE_ENDPOINT } from "~/src/config";
import { PARTNER_MANAGED_THRESHOLD } from "~/src/consts";
import useAuth from "~/src/auth/hooks/use-auth";
import BonusReward from "~/src/components/bonus-reward";
import TextInput from "~/src/components/form-components/text-input";
import { MODAL_TYPES } from "~/src/containers/global-modals";
import * as reducerActions from "~/src/containers/requester-calendar/reducer";
import { formatLinkedShifts } from "~/src/containers/requester-calendar/utils";
import getProfile from "~/src/graphql/queries/get-profile/get-profile-query.decorator";
import { COLORS } from "~/src/styles/config.style";
import asyncConfirm from "~/src/utils/async-confirm";
import { isWithinThreeDays } from "~/src/utils/time-utils";
import { useCurrency } from "~/src/containers/profile-loader";

import { mapStateToProps, useTotals } from "./selectors";
import styles, {
  Bold,
  Cost,
  EditButton,
  Main,
  PostButton,
  Text,
  Title,
  TitleWrapper,
  Wrapper,
  DisclaimerP
} from "./styles";
import { Actions } from "../styles";

const bindActions = dispatch =>
  bindActionCreators(
    {
      addBonus: reducerActions.addBonus,
      removeBonus: reducerActions.removeBonus,
      updateBonusType: reducerActions.updateBonusType,
      updateBonusAmount: reducerActions.updateBonusAmount,
      updateTotalBonus: reducerActions.updateTotalBonus
    },
    dispatch
  );

const FormSummary = props => {
  const {
    shift,
    shiftIndex,
    shiftForm,
    isOpen,
    bonuses,
    handleShiftUpdate,
    addBonus,
    updateBonusType,
    removeBonus,
    updateBonusAmount,
    updateTotalBonus,
    onNewShift,
    goToStart,
    onSave,
    isLoadingPostShift,
    hasVisited,
    goToSelf
  } = props;

  const auth = useAuth();
  const { totalWithoutTax, totalWithTax } = useTotals(props);
  const [roleCanBeManaged, setRoleCanBeManaged] = useState(false);

  const { taxRate } = useCurrency();

  useEffect(() => {
    if (shift?.roleRateId) {
      try {
        axios
          .post(CHECK_ROLE_PARTNER_MANAGE_ENDPOINT, {
            roleRateId: shift?.roleRateId
          })
          .then(res => {
            setRoleCanBeManaged(res.data);
          });
      } catch (error) {
        console.log("error", error);
      }
    }
  }, [shift?.roleRateId]);

  const { data } = useQuery(GET_ROLE_RATE, {
    variables: { roleRateId: shift?.roleRateId },
    skip: !shift?.roleRateId
  });

  const roleRateById = data?.account?.roleRateById;
  const isOvertimeOrOverstay =
    roleRateById?.isOvertimeRate || roleRateById?.isOverstayRate;

  return (
    <Main>
      <Wrapper>
        {isOpen && (
          <div>
            <TextInput
              shouldFixLabelHeight
              isSmall
              fontColor={COLORS.BLACK}
              label="Event Name"
              placeholder="Give your event a name"
              value={shift.name}
              onChangeValue={name => {
                handleShiftUpdate({ name });
              }}
            />
            <TextInput
              shouldFixLabelHeight
              isSmall
              fontColor={COLORS.BLACK}
              label="My ID"
              placeholder="Add an internal identifier or Purchase Order number"
              value={shift.identifier}
              onChangeValue={identifier => {
                handleShiftUpdate({ identifier });
              }}
            />
            {roleCanBeManaged && (
              <div>
                <RotaCheckbox
                  label="Manually manage shift"
                  isDisabled={false}
                  isChecked={shift?.isPartnerManaged}
                  onClick={() => {
                    if (shift?.isPartnerManaged) {
                      handleShiftUpdate({ isPartnerManaged: false });
                    } else {
                      asyncConfirm(
                        null,
                        {
                          subComponent: () => (
                            <span>
                              <TitleWrapper>
                                <Title>Manage Shifts</Title>
                              </TitleWrapper>
                              <Text>
                                Managed shifts are shifts that you are able to
                                manage up until {`${PARTNER_MANAGED_THRESHOLD}`}{" "}
                                days before the scheduled start time of the
                                shift.
                              </Text>
                              <Text>
                                Your agency will generally not look to fill the
                                shift until this time.
                              </Text>
                              <Text>
                                You will be able to see all applicants to the
                                shift before choosing to accept, decline, or add
                                them to a waiting list.
                              </Text>
                            </span>
                          ),
                          confirmButtonText: "Manage Shift",
                          falseButtonText: "Leave to Agency",
                          onConfirm: () => {
                            handleShiftUpdate({ isPartnerManaged: true });
                          }
                        },
                        MODAL_TYPES.BLACK_AND_ORANGE
                      );
                    }
                  }}
                />
              </div>
            )}
            {isWithinThreeDays(shift?.times) && (
              <p>
                NOTE: Partner manually managed shift cannot be activated as
                shift start time is less than {`${PARTNER_MANAGED_THRESHOLD}`}{" "}
                days away.
              </p>
            )}
          </div>
        )}

        <Actions justify="space-between">
          <div>
            {auth.hasAnyRole(Role.SHIFTS_PAY, Role.SHIFTS_COST) && isOpen && (
              <BonusReward
                customFontSize={12}
                enlargedAddIcon
                isLight
                hideSubTotal
                roleOptions={[]}
                formData={shiftForm}
                onAddBonus={() => addBonus(shiftIndex)}
                onUpdateBonusType={(index, type) =>
                  updateBonusType({
                    index,
                    type,
                    shiftIndex
                  })
                }
                onDeleteBonus={index => removeBonus({ index, shiftIndex })}
                onUpdateBonusAmount={(index, amount) =>
                  updateBonusAmount({
                    index,
                    amount,
                    shiftIndex
                  })
                }
                onUpdateBonusTotal={totalBonus => {
                  updateTotalBonus({
                    totalBonus,
                    shiftIndex
                  });
                }}
                bonuses={bonuses}
                linkedMultiple={
                  shift.isLinkedShifts ? formatLinkedShifts(shift).length : 1
                }
                surgeFactor={false}
              />
            )}
            {auth.hasRole(Role.SHIFTS_COST) &&
              isOpen &&
              !shift.isUncalculatedRate && (
                <div style={styles.totalContainer}>
                  <Cost>
                    Total:{" "}
                    <Bold>
                      {totalWithoutTax} + {taxRate}% VAT
                    </Bold>
                  </Cost>
                  <Cost>
                    Grand Total: <Bold>{totalWithTax}</Bold>
                  </Cost>
                </div>
              )}
          </div>
          <div>
            {isOpen && (
              <PostButton
                onClick={async () => {
                  await onNewShift();
                  await goToStart();
                }}
              >
                Add
              </PostButton>
            )}
            {isOpen && (
              <PostButton
                onClick={onSave}
                isLoading={isLoadingPostShift}
                isDisabled={isLoadingPostShift}
              >
                Post
              </PostButton>
            )}
            {!isOpen && hasVisited && (
              <EditButton onClick={goToSelf}>Edit</EditButton>
            )}
          </div>
        </Actions>
        {auth.hasRole(Role.SHIFTS_COST) && isOpen && isOvertimeOrOverstay && (
          <>
            <RotaDivider />
            <DisclaimerP>
              NOTE: Overtime or Overstay rates may apply extra dependent on the
              Members assigned and hours worked.
            </DisclaimerP>
          </>
        )}
      </Wrapper>
    </Main>
  );
};

export default getProfile(connect(mapStateToProps, bindActions)(FormSummary));

import { get, getOr } from "lodash/fp";

const DEFAULT_HOURS_BEFORE = 96;
/*
 * NOTE: Conversion between hoursBeforeShift and daysBeforeShift
 * occurs between the two object parsers
 */
export const getValuesToSave = config => ({
  id: get("id", config),
  hoursBeforeShift: config.daysBeforeShift
    ? config.daysBeforeShift * 24
    : DEFAULT_HOURS_BEFORE,
  favouriteFactor: get("favouriteFactor", config),
  isPartnerManagedEnabled: config.isPartnerManagedEnabled,
  relationshipFactor: get("relationshipFactor", config),
  roleFactor: get("roleFactor", config),
  globalRatingFactor: get("globalRatingFactor", config),
  algoSkew: get("algoSkew", config),
  autoAcceptThreshold: get("autoAcceptThreshold", config)
});

export const getValuesFromProps = matcherConfig => ({
  id: get("id", matcherConfig),
  daysBeforeShift:
    get("daysBeforeShift", matcherConfig) ||
    getOr(DEFAULT_HOURS_BEFORE, "hoursBeforeShift", matcherConfig) / 24,
  favouriteFactor: get("favouriteFactor", matcherConfig),
  isPartnerManagedEnabled: !!matcherConfig?.isPartnerManagedEnabled,
  relationshipFactor: get("relationshipFactor", matcherConfig),
  roleFactor: get("roleFactor", matcherConfig),
  globalRatingFactor: get("globalRatingFactor", matcherConfig),
  algoSkew: get("algoSkew", matcherConfig),
  autoAcceptThreshold: get("autoAcceptThreshold", matcherConfig)
});

export const factorSettingOptions = [
  {
    value: 0,
    label: "Not used"
  },
  {
    value: 100,
    label: "Medium importance"
  },
  {
    value: 50,
    label: "Low importance"
  },
  {
    value: 150,
    label: "High importance"
  },
  {
    value: 200,
    label: "Very High importance"
  }
];

export const autoAcceptOptions = [
  {
    value: 100,
    label: "Auto-accept members only when auto-accept is on."
  },
  {
    value: 90,
    label: "Auto-accept members with a compatability rating higher than 90."
  },
  {
    value: 80,
    label: "Auto-accept members with a compatability rating higher than 80."
  },
  {
    value: 70,
    label: "Auto-accept members with a compatability rating higher than 70."
  }
];

import {
  ModalHeader,
  RotaButton,
  RotaButtonIcon,
  RotaNewModal,
  RotaSelect,
  iconNew
} from "@teamrota/rota-design";
import React, { useState } from "react";
import { ButtonsContainer, Option, Text } from "./index.styles";
import { useCancelledBookings } from "./useCancelledBookings";

const { Cancel } = iconNew;

const BookingReplacementModal = ({
  onClose,
  onConfirm,
  shiftId,
  replacementBookingIds = []
}) => {
  const [selectedBookingId, setSelectedBookingId] = useState(null);

  const { loading, bookings } = useCancelledBookings(shiftId);

  const formatBookings = bookings => {
    return [
      {
        label: "Select a candidate",
        value: null
      },
      ...bookings
        .filter(booking => {
          return !replacementBookingIds.some(id => id === booking.id);
        })
        .map(booking => ({
          label: `${booking?.member?.firstName} ${booking?.member?.lastName}`,
          value: booking?.id
        }))
    ];
  };

  return (
    <RotaNewModal
      onClose={onClose}
      size={"small"}
      header={
        <ModalHeader
          title="Replace an existing cancelled candidate"
          endAction={
            <RotaButtonIcon onClick={onClose}>
              <Cancel />
            </RotaButtonIcon>
          }
        />
      }
      footer={
        <ButtonsContainer>
          <RotaButton onClick={onClose}>No</RotaButton>
          <RotaButton
            disabled={!selectedBookingId}
            onClick={() =>
              onConfirm({
                cancelledBookingId: selectedBookingId
              })
            }
          >
            Yes
          </RotaButton>
        </ButtonsContainer>
      }
    >
      <Text>Please choose a cancelled candidate to be replaced.</Text>
      <Text>
        By replacing the cancelled candidate, the shift will be updated to show
        <span style={{ fontWeight: "bold" }}> the new candidate</span> as the
        current accepted person.
      </Text>
      <RotaSelect
        loading={loading}
        value={selectedBookingId}
        onChange={e => setSelectedBookingId(e.target.value)}
      >
        {formatBookings(bookings).map(option => (
          <Option
            value={option?.value}
            disabled={option?.value === null && selectedBookingId}
          >
            {option?.label}
          </Option>
        ))}
      </RotaSelect>
    </RotaNewModal>
  );
};

export default BookingReplacementModal;

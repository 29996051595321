import styled from "styled-components";
import Icon from "~/src/components/icon";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const InfoWrapper = styled.div`
  flex: 1;
  padding-left: ${STYLE_CONSTS.UNIT_2};
`;

export const XSmallText = styled.span`
  font-size: ${STYLE_CONSTS.FONT_XSMALL};
  display: inline-block;
`;

export const StyledIcon = styled(Icon)`
  margin-left: ${STYLE_CONSTS.UNIT_2};
  margin-right: ${STYLE_CONSTS.UNIT_1};
`;

export const GreyText = styled.span`
  display: inline-block;
  color: ${COLORS.COOL_GREY};
`;

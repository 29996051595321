import { gql } from "@apollo/client";

export const CREATE_UPDATE_EXCLUSIONS = gql`
  mutation createOrUpdateMemberVenueExclusion(
    $memberVenueExclusions: [memberVenueExclusionInputType]!
    $partnerId: ID!
  ) {
    createOrUpdateMemberVenueExclusion(
      memberVenueExclusions: $memberVenueExclusions
      partnerId: $partnerId
    ) {
      id
    }
  }
`;

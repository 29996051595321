import styled from "styled-components";
import Icon from "~/src/components/icon";

export const FooterLegendContainer = styled.div`
  position: absolute;
  bottom: 15px;
  left: 70px;
  display: flex;
  width: 100%;
}
`;
export const ItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 15px;
`;

export const Label = styled.div`
  padding-left: 10px;
  padding-left: 5px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 400;
`;

export const LegendIcon = styled(Icon).attrs({
  size: 16
})``;

import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const LogItem = styled.div`
  padding-bottom: ${STYLE_CONSTS.UNIT_2};
  border-bottom: ${STYLE_CONSTS.SMALL_DIVIDER_BORDER_LIGHT};
`;

export const Date = styled.div`
  color: ${({ theme }) => theme.neutral.main};
`;

export const Author = styled.div`
  color: ${({ theme }) => theme.neutral.main};
`;

export const Action = styled.div`
  font-weight: 500;
  color: ${({ theme }) => theme.neutral.main};
`;

// shared log item styles
export const FormattedLog = styled.div`
  color: ${({ theme }) => theme.neutral.main};
`;

export const Highlight = styled.span`
  color: ${({ theme }) => theme.text.primary};
  margin-right: ${STYLE_CONSTS.UNIT_1};
`;

export const Bullet = styled.span`
  color: ${({ theme }) => theme.text.primary};
  margin-right: ${STYLE_CONSTS.UNIT_1};
`;

export const Text = styled.span`
  margin-right: ${STYLE_CONSTS.UNIT_1};
`;

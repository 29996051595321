import { graphql } from "@apollo/client/react/hoc";
import flow from "lodash/fp/flow";
import set from "lodash/fp/set";
import { withAuth } from "~/src/auth/hoc";
import GET_PROFILE from "./get-profile.query";

export default flow(
  (Component, args) =>
    graphql(GET_PROFILE, {
      props: ({ data: { loading, error, user, refetch } }) => ({
        user: setConnections(user),
        isLoadingAccount: loading,
        errorLoadingAccount: error,
        refetchProfile: refetch
      }),
      options: ({ auth, connectionLimit, connectionOffset }) => ({
        variables: auth.addVals(GET_PROFILE, {
          connectionLimit: connectionLimit || 10,
          connectionOffset: connectionOffset || 0,
          isRequestPage: args?.isRequestPage || false
        })
      })
    })(Component),
  withAuth
);

const updateConnections = connectionName => user => {
  const bumpSelfConnectionToStart = (accumulator, current) => {
    if (current.targetAccount.id === current.sourceAccount.id)
      return [current, ...accumulator];
    return [...accumulator, current];
  };

  return set(
    `account.${connectionName}.data`,
    user.account[connectionName].data.reduce(bumpSelfConnectionToStart, [])
  )(user);
};

function setConnections(user) {
  if (user) {
    return flow(
      updateConnections("requesterConnections"),
      updateConnections("providerConnections")
    )(user);
  }
  return undefined;
}

import { gql } from "@apollo/client";
import ConnectionFragment from "~/src/graphql/fragments/connections/connection-fragment";

const GET_CONNECTIONS = gql`
  ${ConnectionFragment}
  query getConnections(
    $offset: Int!
    $limit: Int!
    $searchText: String
    $orderByProp: ConnectionsOrderByPropEnumType
    $orderByDirection: OrderByEnumType
    $hasVenuesInServiceArea: ID
  ) {
    account {
      id
      connections(
        accountType: provider
        offset: $offset
        limit: $limit
        searchText: $searchText
        orderByProp: $orderByProp
        orderByDirection: $orderByDirection
        hasVenuesInServiceArea: $hasVenuesInServiceArea
      ) {
        data {
          ...ConnectionFragment
        }
        totalResults
      }
    }
  }
`;

export default GET_CONNECTIONS;

import styled from "styled-components";
import Icon from "~/src/components/icon";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const ManageableShiftWrapper = styled.div`
  position: relative;
  padding: 5px;
  text-align: left;
  background-color: ${({ theme }) => theme.white};
  border-radius: ${STYLE_CONSTS.BORDER_RADIUS};
  &:hover {
    cursor: pointer;
  }
`;

export const ManageableShiftField = styled.div`
  width: 100%;
  padding: 3px 5px;
  ${({ isSmall }) => (isSmall ? `font-size: 12px;` : `font-weight: 400`)}
  ${({ shouldFlex }) => shouldFlex && `display: flex; flex: 1;`}
`;

export const ClockIcon = styled(Icon).attrs({
  name: Icon.names.CLOCK,
  size: "xsmall"
})``;

export const LinkedShiftIcon = styled(Icon).attrs({
  name: Icon.names.LINKED_SHIFTS,
  size: "xxsmall",
  color: "white"
})`
  margin-left: 5px;
`;

export const LinkedShiftWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
  background: ${({ color }) => color};
  width: 115px;
  height: 20px;
  line-height: 20px;
  border-radius: 5px;
  font-size: 9px;
  text-transform: uppercase;
  text-align: center;
  color: white;
  letter-spacing: 1px;
  font-weight: 400;
`;

export const ManageableShiftContainer = styled.div`
  box-shadow: ${STYLE_CONSTS.FLOATING_LARGE};
  border-radius: ${STYLE_CONSTS.BORDER_RADIUS};
  margin: 15px 0;
  width: 100%;
`;

export const ApplicantsContainer = styled.div`
  ${({ haveMultipleApplicants }) =>
    haveMultipleApplicants &&
    `
    height: auto;
max-height: 200px;
overflow: scroll;
position: relative;`}
`;

export const NumberOfApplicants = styled.div`
  font-weight: 400;
  ${({ isMain }) =>
    isMain &&
    `    font-size: 20px;
color: orange;
padding-right: 4px;
`}
`;

export const ApplicantsWrapper = styled.div`
  padding: 5px 10px;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const SeeAllWrapper = styled.div`
  text-transform: uppercase;
  font-size: 13px;
  color: ${({ theme }) => theme.primary.main};
  font-weight: 400;
  &:hover {
    cursor: pointer;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const FadeOut = styled.div`
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 4em;
  border-radius: 6px;
  bottom: 28px;
  left: 0;
  background: linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
`;

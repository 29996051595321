import styled from "styled-components";

interface DefiniteProps {
  color?: string;
}

export const Definite = styled.div<DefiniteProps>`
  display: inline-block;
  width: auto;
  font-size: 110%;
  font-weight: 500;
  color: white;
  background-color: ${({ theme, color }) => color ?? theme.primary.main};
  padding: 5px 7px;
  margin: 3px;
  border-radius: 10px;
  cursor: default;
`;

interface PossibleProps {
  color?: string;
}

export const Possible = styled.div<PossibleProps>`
  display: inline-block;
  width: auto;
  font-size: 110%;
  font-weight: 500;
  border: dashed 2px ${({ theme, color }) => color ?? theme.primary.main};
  padding: 5px 7px;
  margin: 3px;
  border-radius: 10px;
  cursor: default;
`;

import { gql } from "@apollo/client";

const UPDATE_TARGET_ACCOUNT_ROLE_RATES = gql`
  mutation updateTargetAccount($id: ID!, $roleRates: [JSON]) {
    updateTargetAccount(id: $id, roleRates: $roleRates) {
      id
    }
  }
`;
export default UPDATE_TARGET_ACCOUNT_ROLE_RATES;

import React from "react";
import Icon from "~/src/components/icon";
import { StyledIcon, StyledButton } from "./action-dropdown.styles";

const ActionButton = ({
  children,
  isIconPrimary,
  isFontPrimary,
  isPrimary,
  isSmall,
  isBold,
  isAlternate,
  isSquare,
  onClick,
  isDisabled
}) => (
  <StyledButton
    isFontPrimary={isFontPrimary}
    isPrimary={isPrimary}
    isSmall={isSmall}
    isBold={isBold}
    isAlternate={isAlternate}
    isSquare={isSquare}
    onClick={onClick}
    isDisabled={isDisabled}
    disabled={isDisabled}
  >
    <span>{children}</span>
    <StyledIcon
      isSmall={isSmall}
      color={isIconPrimary || isPrimary ? null : Icon.colors.CLOUDY_BLUE}
      size="xsmall"
      name={Icon.names.ARROW_DOWN}
    />
  </StyledButton>
);

export default ActionButton;

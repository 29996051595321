import React from "react";
import MemberPhoto from "~/src/components/member-photo";
import Stars from "~/src/components/stars";
import {
  Wrapper,
  StarsWrapper,
  LightText,
  ShouldShowContainer
} from "./member.styles";

export default function Member({
  replacementBooking,
  showMemberModal,
  member
}) {
  const replacementMember = replacementBooking?.member;

  return (
    <Wrapper onClick={showMemberModal} onKeyDown={showMemberModal}>
      <MemberPhoto
        isInline
        src={member.photo}
        poolType={member.relationship.poolType}
      />
      <StarsWrapper>
        <Stars isEditable={false} value={member.rating} isSmall />
      </StarsWrapper>
      {member.firstName} {member.lastName}
      <LightText>
        <ShouldShowContainer shouldNotShow>
          {member.bookingsCount.allPreviouslyAccepted} shifts completed
        </ShouldShowContainer>
        <div>{member.bookingsCount.previouslyAccepted} shifts with you</div>
      </LightText>
      {replacementMember &&
        `Replaced by ${replacementMember?.firstName} ${replacementMember?.lastName}`}
    </Wrapper>
  );
}

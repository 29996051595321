import { MODAL_TYPES } from "~/src/containers/global-modals";
import store from "../store";
import appActions from "../actions/app";
import { SHOW_DEBUG } from "../config";

/**
 * Rethrow any errors from GraphQL so they bubble up to the ErrorBoundary.
 * Use this version with the hoc, wrapping the props function.
 * Pass the query name.
 *
 * E.g.:
 *
 * props:
 *   handleErrors(
 *     ({ data: { loading, bar} }) => ({
 *       isLoading: loading,
 *       bar?.baz
 *     })
 *   )
 */
export const handleErrors = propsFn => args => {
  if (args?.data?.error) {
    throw args.data.error;
  }

  return propsFn(args);
};

/**
 * Rethrow any errors from GraphQL so they bubble up to the ErrorBoundary.
 * Use this version with useQuery
 *
 * E.g.:
 *
 * const { data } = checkErrors(useQuery(GET_FOO);
 */
export const checkErrors = result => {
  if (result?.error) {
    throw result.error;
  }

  return result;
};

export function parseError(error, message) {
  if (typeof error === "string") return error;

  if (error.graphQLErrors && error.graphQLErrors.length) {
    const [{ displayMessage }] = error.graphQLErrors;
    return displayMessage;
  }

  if (error.message) {
    return error.message;
  }

  return (
    message ||
    "Something went wrong, please try again or report the issue if it persists"
  );
}

export function errorModal(body, errorList, title) {
  if (SHOW_DEBUG) {
    console.log("ERROR:", body);
  }

  store.dispatch(
    appActions.showModal(MODAL_TYPES.ERROR, {
      message: typeof body === "string" ? body : parseError(body),
      title,
      errors: errorList,
      onClose: () => store.dispatch(appActions.closeModal())
    })
  );
}

export function errorModalNoClose(body, errorList, title) {
  if (SHOW_DEBUG) {
    console.log("ERROR:", body);
  }

  store.dispatch(
    appActions.showModal(MODAL_TYPES.ERROR, {
      message: typeof body === "string" ? body : parseError(body),
      title,
      errors: errorList
    })
  );
}

import { gql } from "@apollo/client";

export default gql`
  mutation updateMemberAvailability(
    $id: ID
    $isLimitedHours: Boolean
    $limitedHours: Int
    $isLimitedHoursExpire: Boolean
    $limitedHoursExpireAt: Date
  ) {
    createOrUpdateMember(
      id: $id
      isLimitedHours: $isLimitedHours
      limitedHours: $limitedHours
      isLimitedHoursExpire: $isLimitedHoursExpire
      limitedHoursExpireAt: $limitedHoursExpireAt
    ) {
      id
      isLimitedHours
      limitedHours
      isLimitedHoursExpire
      limitedHoursExpireAt
    }
  }
`;

import { gql } from "@apollo/client";
import { addAuthVars } from "@teamrota/authlib";
import ShiftFragment from "../../fragments/shift/shift.fragment";
import LogFragment from "../../fragments/log/log.fragment";

export default addAuthVars(gql`
  ${ShiftFragment}
  ${LogFragment}
  mutation updateShiftState(
    $shiftId: ID!
    $newState: stateEnumType!
    $note: String
  ) {
    updateShiftState(shiftId: $shiftId, newState: $newState, note: $note) {
      ...ShiftFragment
      ... on shift {
        logs {
          ...LogFragment
        }
      }
    }
  }
`);

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const UPDATE_ACCOUNT = gql`
  mutation createUpdateUser($venueIds: [Int]) {
    createUpdateUser(venueIds: $venueIds) {
      id
    }
  }
`;
export default graphql(UPDATE_ACCOUNT, {
  name: "updateUser",
  props: ({ updateUser }) => ({
    updateUser: async venueIds => {
      const mutationResult = await updateUser({
        variables: {
          venueIds
        }
      });
      return mutationResult;
    }
  })
});

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

import UserFragment from "~/src/graphql/fragments/user";
import { handleErrors } from "~/src/utils/errors";

const GET_USERS = gql`
  ${UserFragment}
  query getUsers {
    account {
      id
      users {
        ...UserFragment
      }
    }
  }
`;

export default graphql(GET_USERS, {
  props: handleErrors(({ data: { loading, account, refetch } }) => ({
    isLoading: loading,
    users: account?.users ?? [],
    refetchUsers: refetch
  }))
});

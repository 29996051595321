import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  width: 100%;
  height: 100%;
  padding: 32px;
`;

const Subtitle = styled.p`
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
`;

export const EmptyView = () => {
  return (
    <Wrapper>
      <Subtitle>
        No more timesheets to view. Please change dates or filters to view more.
      </Subtitle>
    </Wrapper>
  );
};

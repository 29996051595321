import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const Wrapper = styled.div`
  display: block;
`;

export const ToggleWrapper = styled.div``;

export const TrayPositioner = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  z-index: ${STYLE_CONSTS.Z_INDEX_SELECT};
`;

export const PICKER_ANI_NAME = "tray-toggle-animation";
export const ENTER_TIME = 90;
export const LEAVE_TIME = 200;

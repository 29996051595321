import React, { useEffect, useState } from "react";
import { RotaSwitchWithLabel, iconNew } from "@teamrota/rota-design";

import TimeSelect from "../../payroll/components/TimeSelect";
import { ControlWrapper, Error, Label } from "./styles";

const { Tick, Locked } = iconNew;

interface SettingsProps {
  value: any;
  onChange: (value: any) => void;
}

const Settings: React.FC<SettingsProps> = ({ value, onChange }) => {
  const [isTwlightEnabled, setIsTwilightEnabled] = useState(
    value.twilightStart !== null && value.twilightEnd !== null
  );

  const handleSetTwilight = (
    twilightStart: string | null,
    twilightEnd: string | null
  ) => onChange({ ...value, twilightStart, twilightEnd });

  useEffect(() => {
    if (!isTwlightEnabled) {
      handleSetTwilight(null, null);
    }
  }, [isTwlightEnabled]);

  return (
    <div style={{ width: "50%" }}>
      <h2>Twilight</h2>
      <RotaSwitchWithLabel
        iconOff={<Locked />}
        iconOn={<Tick />}
        checked={isTwlightEnabled}
        onChange={e => setIsTwilightEnabled(e.target.checked)}
      >
        Enable Twilight
      </RotaSwitchWithLabel>

      <p>
        If you wish to use Twilight rules, please set the range of times over
        which Twilight may apply. Whether twilight actually applies to
        particular blocks/times and what rates it results in is still controlled
        by isTwilight rules in your ruleset.
      </p>

      <ControlWrapper>
        <Label>Start:</Label>
        <TimeSelect
          time={value.twilightStart}
          onChangeTime={time => handleSetTwilight(time, value.twilightEnd)}
          isDisabled={!isTwlightEnabled}
        />
      </ControlWrapper>

      <ControlWrapper>
        <Label>End:</Label>
        <TimeSelect
          time={value.twilightEnd}
          onChangeTime={time => handleSetTwilight(value.twilightStart, time)}
          isDisabled={!isTwlightEnabled}
        />
      </ControlWrapper>

      {isTwlightEnabled &&
        value.twilightStart !== null &&
        value.twilightEnd === null && (
          <Error>You must also set Twilight End</Error>
        )}

      {isTwlightEnabled &&
        value.twilightStart === null &&
        value.twilightEnd !== null && (
          <Error>You must also set Twilight Start</Error>
        )}
    </div>
  );
};

export default Settings;

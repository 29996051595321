import React, { useState } from "react";
import { RotaButton, RotaButtonIcon } from "@teamrota/rota-design";
import {
  FloatingTable,
  ColumnHeading,
  TableBody,
  TableData,
  TableRow,
  StyledWrapButton
} from "~/src/components/floating-table";
import Details from "~/src/components/member-details";
import PageHeader from "~/src/components/page-header";
import CreateEditUserModal from "~/src/containers/modals/create-edit-user";
import Textarea from "~/src/components/form-components/textarea";
import updateUser from "~/src/graphql/mutations/create-update-user";
import TablePlaceholder from "~/src/components/table-placeholder";
import NoResults from "~/src/components/no-results";
import getUsers from "./graphql/get-users";
import { Tab } from "./users.styles";

const Users = ({ users, refetchUsers, createUpdateUser, isLoading }) => {
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [userId, setUserId] = useState(undefined);

  const handleUserModal = (isUserModalOpen, userId) => {
    setIsUserModalOpen(isUserModalOpen);
    setUserId(userId);
  };

  const afterSubmitNewUser = mutationResult => {
    setUserId(mutationResult.data.createUpdateUser.id);
  };

  const isNoResults = users?.length === 0 && !isLoading;

  return (
    <div>
      <CreateEditUserModal
        isOpen={isUserModalOpen}
        userId={userId}
        onClose={() => handleUserModal(false)}
        afterSubmitNewUser={afterSubmitNewUser}
        afterDeleteUser={refetchUsers}
      />
      <PageHeader title="My Team" subtext={`${users.length} users`}>
        <RotaButton
          onClick={() => handleUserModal(true)}
          style={{ marginRight: "22px" }}
        >
          Add a new user
        </RotaButton>
      </PageHeader>
      <FloatingTable>
        <div>
          <ColumnHeading width={6 / 12}>
            <Tab>User</Tab>
          </ColumnHeading>
          <ColumnHeading width={4 / 12}>Notes</ColumnHeading>
          <ColumnHeading width={2 / 12}>&nbsp;</ColumnHeading>
        </div>
        <TableBody>
          {isNoResults ? (
            <NoResults />
          ) : (
            <div>
              {isLoading && (
                <TablePlaceholder columnWidths={[6 / 12, 4 / 12, 2 / 12]} />
              )}
              {users?.map((user, i) => (
                <TableRow key={i}>
                  <TableData width={6 / 12}>
                    <Details
                      id={user.id}
                      firstName={user.firstName}
                      lastName={user.lastName}
                      photo={user.photo}
                      phone={user.phoneNumber}
                      email={user.email}
                    />
                  </TableData>
                  <TableData width={4 / 12}>
                    <Textarea
                      isMultilineAuto
                      isBorderless
                      placeholder="User notes..."
                      defaultValue={user.notes || undefined}
                      onBlur={({ target }) =>
                        createUpdateUser(["getUsers"])({
                          id: user.id,
                          email: user.email,
                          notes: target.value
                        })
                      }
                    />
                  </TableData>
                  <TableData width={2 / 12}>
                    <StyledWrapButton>
                      <RotaButtonIcon
                        icon="edit"
                        onClick={() => handleUserModal(true, user.id)}
                      />
                    </StyledWrapButton>
                  </TableData>
                </TableRow>
              ))}
            </div>
          )}
        </TableBody>
      </FloatingTable>
    </div>
  );
};

export default getUsers(updateUser(Users));

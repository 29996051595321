import styled from "styled-components";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const Content = styled.div`
  display: inline-block;
`;

export const SmallText = styled.div`
  font-size: ${STYLE_CONSTS.FONT_SMALL};
  color: ${COLORS.COOL_GREY};
`;

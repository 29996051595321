import styled from "styled-components";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: right;
`;

export const SubWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TimeDisplay = styled.span`
  color: ${({ error }) => (error ? "red" : "black")};
`;

export const SmallText = styled.div`
  margin-left: 5px;
  font-size: ${STYLE_CONSTS.FONT_SMALL};
  color: ${COLORS.COOL_GREY};
`;

import React, { useEffect, useState, useRef } from "react";
import { useTheme } from "styled-components";

import Icon from "~/src/components/icon";
import InputWrapper from "../input-wrapper";
import {
  DropdownContent,
  Option,
  DropdownWrapper,
  StyledIcon,
  StyledButton,
  ReadOnly,
  Placeholder
} from "./index.styles";

const ActionDropdown = ({
  options,
  readOnly,
  placeholder,
  input,
  isSingleLine
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const dropdownRef = useRef(null);

  const theme = useTheme();

  useEffect(() => {
    if (!selectedOption) {
      const option = options?.filter(
        option => option.value === input?.value
      )[0];
      setSelectedOption(option?.label);
    }
  }, [input]);

  useEffect(() => {
    const handleClose = e => {
      if (dropdownRef?.current && !dropdownRef?.current?.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClose);

    return () => {
      document.removeEventListener("click", handleClose);
    };
  }, [isOpen]);

  const onSelectOption = option => {
    input?.onChange(option.value);
    setSelectedOption(option.label);
    setIsOpen(false);
  };

  if (readOnly) {
    return (
      <InputWrapper isSingleLine={isSingleLine}>
        <ReadOnly>{selectedOption || ""}</ReadOnly>
      </InputWrapper>
    );
  }

  return (
    <InputWrapper isSingleLine={isSingleLine}>
      <DropdownWrapper ref={dropdownRef}>
        <StyledButton onClick={() => setIsOpen(!isOpen)} type="button">
          {selectedOption}
          {!selectedOption && <Placeholder>{placeholder}</Placeholder>}
          <StyledIcon
            color={theme.primary.main}
            name={Icon.names.ARROW_DOWN}
            size="xsmall"
          />
        </StyledButton>
        {isOpen && options && (
          <DropdownContent>
            {options
              .filter(({ value }) => value !== input?.value)
              .map((option, i) => (
                <Option key={i} onClick={() => onSelectOption(option)}>
                  {option.label}
                </Option>
              ))}
          </DropdownContent>
        )}
      </DropdownWrapper>
    </InputWrapper>
  );
};

export default ActionDropdown;

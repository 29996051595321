import { MODAL_TYPES } from "~/src/containers/global-modals";
import appActions from "../actions/app";
import store from "../store";
/**
 * Resolves true or false - never throws
 * Use like: if (await asyncConfirm('Are you sure?'))
 */
export default async function asyncConfirm(message, config, modalType) {
  try {
    const result = await new Promise(res =>
      store.dispatch(
        appActions.showModal(modalType || MODAL_TYPES.ASYNC_CONFIRM, {
          message,
          config,
          callback: res,
          onClose: () => store.dispatch(appActions.closeModal())
        })
      )
    );
    return result;
  } catch (e) {
    return false;
  }
}

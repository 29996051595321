import styled from "styled-components";

export const ProgressBackground = styled.circle`
  stroke: ${({ theme }) => theme.white};
  opacity: 0.3;
`;

export const Progress = styled.circle`
  stroke: ${({ theme }) => theme.white};
`;

import { gql, useMutation } from "@apollo/client";

const CREATE_OR_UPDATE_SUBVENUE = gql`
  mutation createOrUpdateSubvenue(
    $venueId: ID!
    $name: String
    $mmCode: String
    $costCode: String
    $integraId: String
    $id: ID
  ) {
    createOrUpdateSubvenue(
      venueId: $venueId
      name: $name
      mmCode: $mmCode
      costCode: $costCode
      integraId: $integraId
      id: $id
    ) {
      name
      id
      venueId
      mmCode
      costCode
      integraId
    }
  }
`;

export const useCreateOrUpdateSubvenue = ({ onCompleted, onError }) => {
  const [createOrUpdateSubvenue, { loading, error }] = useMutation(
    CREATE_OR_UPDATE_SUBVENUE,
    {
      refetchQueries: ["getProfile"],
      onCompleted,
      onError
    }
  );

  return { createOrUpdateSubvenue, loading, error };
};

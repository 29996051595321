import { gql } from "@apollo/client";

const GET_ACCOUNT_VENUES = gql`
  query getServiceAreaVenues {
    account {
      venues {
        name
        id
      }
    }
  }
`;

const GET_USER_VENUES = gql`
  query getUserVenueIds {
    user {
      venueIds
    }
  }
`;

export { GET_ACCOUNT_VENUES, GET_USER_VENUES };

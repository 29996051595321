import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

export const CREATE_OR_UPDATE_SHIFT = gql`
  mutation createOrUpdateShift($shiftId: ID!, $identifier: String!) {
    createOrUpdateShift(
      shifts: [{ identifier: $identifier, id: $shiftId }]
      shouldSkipTimeValidation: true
    ) {
      id
      identifier
    }
  }
`;

const createOrUpdateShift = graphql(CREATE_OR_UPDATE_SHIFT, {
  name: "createOrUpdateShift",
  props: props => ({
    createOrUpdateShift: async (shiftId, identifier) => {
      const shiftMutationResult = await props.createOrUpdateShift({
        variables: { identifier, shiftId }
      });
      return shiftMutationResult.data.createOrUpdateShift;
    }
  })
});

export default createOrUpdateShift;

import React from "react";
import { Section, Title, Body, InlineField } from "../../policy-form.styles";

export default props => (
  <div>
    <Section>
      <Title>Reducing Bookings Length</Title>
      <Body>
        If the booking length is reduced during check in and out or timesheet
        review to less than{" "}
        <InlineField
          isEditMode={props.isEditMode}
          onChange={props.onChange("minimumShiftPercentage")}
          onBlur={props.onBlur("minimumShiftPercentage")}
          value={props.minimumShiftPercentage}
        />
        % of the original length, then charge {props.minimumShiftPercentage}% of
        the original booking length or{" "}
        <InlineField
          isEditMode={props.isEditMode}
          onChange={props.onChange("minimumShiftLength")}
          onBlur={props.onBlur("minimumShiftLength")}
          value={props.minimumShiftLength}
        />{" "}
        hours minimum booking length, whichever is greater.
      </Body>
    </Section>
    <Section>
      <Title>Cancelling Bookings</Title>
      <Body>
        If the booking is cancelled with less than{" "}
        <InlineField
          isEditMode={props.isEditMode}
          onChange={props.onChange("cancellationPeriod")}
          onBlur={props.onBlur("cancellationPeriod")}
          value={props.cancellationPeriod}
        />{" "}
        hours notice before the shift starts, then charge{" "}
        {props.minimumShiftPercentage}% of the original booking length or{" "}
        {props.minimumShiftLength} hours minimum booking length, whichever is
        greater.
      </Body>
    </Section>
  </div>
);

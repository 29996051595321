import React from "react";
import flow from "lodash/fp/flow";
import Modal from "~/src/components/modal";
import Button from "~/src/components/button";
import TextInput from "~/src/components/form-components/text-input";
import {
  ModalTitle,
  WideFieldRow,
  FieldCol,
  LightText,
  SummaryText,
  ButtonFooter,
  Content
} from "./overbook-shift-modal.styles";
import { useTheme } from "styled-components";

export const stringToFloat = value => {
  if (!value) return 0;
  return parseFloat(value).toFixed(2);
};

export const getNumOverbooked = (overbookPercentage, numberRequested) =>
  Math.round(
    (stringToFloat(overbookPercentage) / 100) * (numberRequested || 0)
  );

export const getPercentageOverbooked = numRequested => numOverbooked =>
  flow(String, stringToFloat)((numOverbooked / numRequested) * 100);

export const getSanitisedPercentage = value => {
  const positiveVal = value.replace("-", "");
  if (positiveVal === "") return positiveVal;
  const decimals = positiveVal.split(".");
  if (decimals.length === 1) return flow(Number, String)(positiveVal);
  return `${flow(Number, String)(decimals[0])}.${decimals[1].substr(0, 2)}`;
};

const isNumeric = val => /(^$|^-*[0-9,.]+$)/.test(val);

export default props => {
  const numberOverbooked = getNumOverbooked(
    props.overbookPercentage,
    props.numberRequested
  );

  const theme = useTheme();

  return (
    <Modal isOpen={props.isOpen} onClose={props.onModalClose}>
      <Content>
        <ModalTitle>Overbook shift</ModalTitle>

        <WideFieldRow>
          <FieldCol width={10} marginOveride="auto 0" center>
            Add
          </FieldCol>
          <FieldCol width={20}>
            <TextInput
              onChangeValue={val => {
                if (isNumeric(val)) {
                  flow(getSanitisedPercentage, props.onChange)(val);
                }
              }}
              value={props.overbookPercentage}
              fontColor={theme.primary.main}
              isSmall
            />
          </FieldCol>
          <FieldCol width={10} marginOveride="auto 0" center>
            % /
          </FieldCol>

          <FieldCol width={20}>
            <TextInput
              onChangeValue={val => {
                if (isNumeric(val)) {
                  flow(
                    getPercentageOverbooked(props.numberRequested),
                    props.onChange
                  )(val);
                }
              }}
              value={numberOverbooked}
              fontColor={theme.primary.main}
              isSmall
            />
          </FieldCol>
          <FieldCol width={20} marginOveride="auto 0" center>
            more
          </FieldCol>
        </WideFieldRow>

        <SummaryText>
          {numberOverbooked + props.numberRequested} staff{" "}
          <LightText>
            ({props.numberRequested} Requested + {numberOverbooked} Overbooked)
          </LightText>
        </SummaryText>

        <ButtonFooter>
          <Button isLoading={props.isLoading} onClick={props.onSaveOverbook}>
            Confirm
          </Button>
        </ButtonFooter>
      </Content>
    </Modal>
  );
};

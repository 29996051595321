import { gql } from "@apollo/client";

const UPDATE_ACCOUNT_MEMBER_AVAILABILITY = gql`
  mutation updateAccountMemberAvailability(
    $isMemberAvailabilityEnabled: Boolean
    $memberAvailabilityDefaultStartTime: Date
    $memberAvailabilityDefaultEndTime: Date
  ) {
    updateAccountMemberAvailability(
      isMemberAvailabilityEnabled: $isMemberAvailabilityEnabled
      memberAvailabilityDefaultStartTime: $memberAvailabilityDefaultStartTime
      memberAvailabilityDefaultEndTime: $memberAvailabilityDefaultEndTime
    ) {
      id
    }
  }
`;

export default UPDATE_ACCOUNT_MEMBER_AVAILABILITY;

import styled, { css } from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";
import Icon from "~/src/components/icon";

const TRAY_HEIGHT = 200;

export const DropdownWrapper = styled.div`
  position: relative;
  ${({ displayNormal }) =>
    !displayNormal && `display: inline-block;`} width: ${({ isFullWidth }) =>
  isFullWidth ? "100%" : "auto"};
  ${({ leftMargin }) => leftMargin && `margin-left: ${STYLE_CONSTS.UNIT_1}`};
`;

export const ActionSpanContainer = styled.div`
  padding: 0 3px;

  margin-top: ${({ isRewardDropDown }) => (isRewardDropDown ? "0" : "-4px")};
`;

export const ActionSpan = styled.span`
  font-size: ${({ isRewardDropDown }) => (isRewardDropDown ? "17px" : "10px")};
  color: ${({ isFontPrimary, isPrimary, theme }) =>
    isFontPrimary || isPrimary ? theme.primary.main : theme.text.primary};
  padding: 0 2px;
`;

export const ActionSpanIcon = styled(Icon)``;

export const DropdownContent = styled.div`
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.neutral.main};
  width: 100%;
  position: absolute;
  overflow: auto;
  max-height: ${TRAY_HEIGHT}px;
  height: auto;
  box-shadow: ${STYLE_CONSTS.FLOATING};
  border: ${({ theme }) => `solid 1.2px ${theme.neutral.lighter}`};
  border-top: none;
  border-radius: ${STYLE_CONSTS.BORDER_RADIUS};
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  z-index: ${STYLE_CONSTS.Z_INDEX_SELECT};
  text-align: left;
  ${({ shouldLeftShiftDropdown }) =>
    shouldLeftShiftDropdown &&
    css`
      width: 200%;
      transform: translateX(-50%);
    `};
`;

export const Option = styled.div`
  ${({ isSpan, isRewardDropDown, isSmall, isHighlighted, theme }) =>
    !isSpan
      ? `
    font-size:
      ${isRewardDropDown ? "15px" : "10px"};
      padding: 5px;
      &:hover {
        cursor: pointer;
        background-color: ${theme.neutral.lighter};
      }`
      : `  padding: ${isSmall ? STYLE_CONSTS.UNIT_1 : STYLE_CONSTS.UNIT_2};
          font-size: ${isSmall ? STYLE_CONSTS.FONT_SMALL : STYLE_CONSTS.FONT};
          background-color: ${
            isHighlighted ? theme.neutral.lighter : theme.white
          };
          &:hover {
            background-color: ${theme.neutral.lighter};
            cursor: pointer;
          };`};
`;

export const StyledIcon = styled(Icon)`
  margin-left: ${({ isSmall }) =>
    isSmall ? STYLE_CONSTS.UNIT_1 : STYLE_CONSTS.UNIT_2};
  background-color: ${({ color }) => (color === null ? "inherit" : color)};
`;

export const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  align-content: center;
  width: 100%;
  font-size: ${({ isSmall }) =>
    isSmall ? STYLE_CONSTS.FONT_SMALL : STYLE_CONSTS.FONT};
  font-weight: ${({ isBold }) =>
    isBold ? STYLE_CONSTS.FONT_WEIGHT_MEDIUM : STYLE_CONSTS.FONT_WEIGHT};
  justify-content: space-between;
  padding: ${({ isSmall }) =>
    isSmall
      ? `${STYLE_CONSTS.UNIT_1} ${STYLE_CONSTS.UNIT_2}`
      : STYLE_CONSTS.UNIT_2};
  color: ${({ isFontPrimary, isPrimary, isAlternate, theme }) =>
    isFontPrimary || isPrimary
      ? theme.primary.main
      : isAlternate
      ? theme.neutral.light
      : theme.neutral.main};
  border: 1px solid
    ${({ isPrimary, isAlternate, theme }) =>
      isPrimary
        ? theme.primary.main
        : isAlternate
        ? theme.neutral.lighter
        : theme.neutral.main};
  border-radius: ${({ isSquare }) => (isSquare ? "3px" : STYLE_CONSTS.UNIT_6)};
  background: ${({ isAlternate, theme }) =>
    isAlternate ? theme.neutral.lighter : "transparent"};
  transition: all 0.4 ease;
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  &:focus {
    outline: none;
  }
  &:active {
    opacity: 0.5;
  }
  &:hover {
    opacity: 0.7;
  }
`;

import React, { useEffect, useState } from "react";
import { RotaChip } from "@teamrota/rota-design";
import {
  StyledChipInDropdown,
  StyledChipsDropdown
} from "./filter-chips.styles";

const MAX_CHIP_WIDTH = 170;
const GROUP_CHIP_WIDTH = 40;

const FilterChips = ({
  filters,
  handleClear,
  setDropdownOpen,
  containerWidth
}) => {
  const [chips, setChips] = useState([]);

  const numberOfVisibleChips = Math.floor(
    (containerWidth - GROUP_CHIP_WIDTH) / MAX_CHIP_WIDTH
  );
  const visibleChips = chips.slice(0, numberOfVisibleChips);
  const notVisibleChips = chips.slice(numberOfVisibleChips);

  const createChipsFromFilters = () => {
    const formatChipName = (itemsList, filterDisplayName) => {
      if (filterDisplayName === "Created By") {
        return itemsList?.length === 1
          ? itemsList[0].label
          : `${itemsList?.length} Users`;
      }

      if (filterDisplayName === "Availability") {
        return itemsList?.length === 1
          ? `${itemsList[0].label} Available`
          : `${itemsList?.length} Days Available`;
      }

      return itemsList?.length === 1
        ? itemsList[0].label
        : `${itemsList?.length} ${filterDisplayName}`;
    };

    return Object.values(filters)
      .map(filter => {
        if (filter.selectedItems.length) {
          return {
            chipName: formatChipName(filter.selectedItems, filter.displayName),
            filterName: filter.name
          };
        } else return null;
      })
      .filter(chip => chip);
  };

  useEffect(() => {
    setChips(createChipsFromFilters());
  }, [filters]);

  return (
    <>
      {!!visibleChips?.length &&
        visibleChips.map((chip, i) => (
          <RotaChip
            key={i}
            chipText={chip.chipName}
            onClose={() => handleClear(chip.filterName)}
            onClick={() => setDropdownOpen(chip.filterName)}
          />
        ))}
      {!!notVisibleChips?.length && (
        <RotaChip chipText={`+${notVisibleChips?.length}`} isGroup={true}>
          <StyledChipsDropdown>
            {notVisibleChips?.map((chip, i) => (
              <StyledChipInDropdown
                key={i}
                chipText={chip.chipName}
                onClose={() => handleClear(chip.filterName)}
                onClick={() => setDropdownOpen(chip.filterName)}
              />
            ))}
          </StyledChipsDropdown>
        </RotaChip>
      )}
    </>
  );
};

export default FilterChips;

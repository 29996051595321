import React, { useState, useEffect } from "react";
import { find, flow, get, getOr } from "lodash/fp";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ZonedDate } from "@teamrota/rota-common";

import { BOOKING_STATES, ROTA_ACCOUNT } from "~/src/consts";
import { errorModal } from "~/src/utils/errors";

import DashboardScrollHandler from "~/src/components/dashboard-scroll-handler";
import {
  ColumnHeading,
  FloatingTable,
  TableBody
} from "~/src/components/floating-table";
import Icon from "~/src/components/icon";
import LoadMore from "~/src/components/load-more";
import NoResults from "~/src/components/no-results";
import TablePlaceholder from "~/src/components/table-placeholder";
import ViewEditAccount from "~/src/containers/modals/view-edit-account";
import ViewEditShift from "~/src/containers/modals/view-edit-shift";
import annotateItem from "~/src/graphql/mutations/annotate-item/annotate-item.decorator";
import updateShiftState from "~/src/graphql/mutations/update-shift-state/update-shift-state.decorator";
import getProfile from "~/src/graphql/queries/get-profile/get-profile-query.decorator";

import Header from "./components/header";
import OverbookShiftModal from "./components/overbook-shift-modal";
import getProvideShifts from "./graphql/get-shifts";
import updateShiftOverbookPercentage from "./graphql/update-shift-overbook-percentage";
import { LinkedShiftTag, StyledIcon, Tab } from "./provide.styles";
import * as reducerActions from "./reducer";
import { ShiftRow } from "./components/shift-row";

const Provide = ({
  shifts,
  shiftBookingList,
  user,
  filters,
  sortBy,
  roleOptions,
  totalResults,
  isLoading,
  onUpdateFilters,
  onRemoveFilters,
  onResetState,
  onSearch,
  fetchMore,
  annotateItem,
  onUpdateSortBy,
  openShiftBookings,
  updateShiftState,
  updateShiftOverbookPercentage,
  setTabIndex,
  refetch,
  searchText
}) => {
  const [overbookShiftId, setOverbookShiftId] = useState(undefined);
  const [isOverbookLoading, setIsOverbookLoading] = useState(false);
  const [overbookPercentage, setOverbookPercentage] = useState("0.00");
  const [autoAcceptUpdates, setAutoAcceptUpdates] = useState({});
  const [selectedShiftId, setSelectedShiftId] = useState(undefined);
  const [selectedAccountId, setSelectedAccountId] = useState(undefined); // targetAccountId in modal
  const [isGraphicalLoadAllowed, setIsGraphicalLoadAllowed] = useState(true);

  useEffect(() => {
    onUpdateFilters({ startTime: moment().format("DD/MM/YYYY") });
  }, []);

  const handleLoadMore = async () => {
    if (!isLoading && isGraphicalLoadAllowed) {
      setIsGraphicalLoadAllowed(false);
      try {
        await fetchMore();
      } catch (e) {
        errorModal(e);
      }
      setIsGraphicalLoadAllowed(true);
    }
  };

  const handleSaveNotes = async (shiftId, notes) => {
    try {
      await annotateItem(shiftId, "shifts", notes);
    } catch (e) {
      errorModal(e);
    }
  };

  const handleSetAutoAccept = async (shiftId, value) => {
    const setVal = newValue =>
      setAutoAcceptUpdates({
        autoAcceptUpdates: {
          ...autoAcceptUpdates,
          [shiftId]: newValue
        }
      });

    setVal(value);

    try {
      await updateShiftState(shiftId, "auto_accept");
    } catch (e) {
      errorModal(e);
      setVal(!value);
    }
  };

  const handleSetFeaturedShift = async shiftId => {
    try {
      await updateShiftState(shiftId, "featured");
    } catch (e) {
      console.log("There was an error while updating the shift state: ", e);
    }
  };

  const handleSaveOverbook = async () => {
    setIsOverbookLoading(true);
    try {
      if (overbookShiftId) {
        await updateShiftOverbookPercentage(
          overbookShiftId,
          overbookPercentage
            ? Math.ceil(parseFloat(overbookPercentage).toFixed(2))
            : "0.00"
        );
        await updateShiftState(overbookShiftId, "unfinalised");
      }
      setOverbookShiftId(undefined);
      setIsOverbookLoading(false);
    } catch (e) {
      errorModal(e);
      setIsOverbookLoading(false);
    }
  };

  const openShiftBookingList = shift => {
    const shiftIsPast = new ZonedDate(shift.startTime) < new ZonedDate();
    const defaultStates =
      shiftIsPast || shift.finalisedAt
        ? [BOOKING_STATES.ACCEPTED]
        : [BOOKING_STATES.APPLIED];
    const defaultKeyProp =
      shiftIsPast || shift.finalisedAt ? "accepted" : "applied";
    openShiftBookings({
      shiftId: shift.id,
      defaultStates,
      defaultKeyProp
    });
  };

  const handleHideShiftModal = () => {
    setSelectedShiftId(undefined);
  };

  const handleOpenShiftModal = selectedShiftId => {
    setSelectedShiftId(selectedShiftId);
  };

  const handleHideAccountModal = () => {
    setSelectedAccountId(null);
  };

  const handleOpenAccountModal = selectedAccountId => {
    setSelectedAccountId(selectedAccountId);
  };

  // When the notes get longer align them to the top
  const alignNotes = notes => {
    const MAX_LINES = 10;
    const MAX_CHARS = 50;

    if (
      notes.split(/\r\n|\r|\n/).length > MAX_LINES ||
      notes.length > MAX_CHARS
    ) {
      return "top";
    }

    return "middle";
  };

  const isNoResults = shifts.length === 0 && !isLoading;
  const isNoMoreResults = totalResults === shifts.length;
  const canLoadMore = !isNoResults && !isNoMoreResults;

  return (
    <DashboardScrollHandler
      onReachEnd={() => canLoadMore && handleLoadMore()}
      pixelsBeforeReachingEnd={300}
    >
      <div className="member-modal" />
      <ViewEditShift
        isOpen={!!selectedShiftId}
        onClose={handleHideShiftModal}
        shiftId={selectedShiftId}
      />
      <ViewEditAccount
        isOpen={!!selectedAccountId}
        onClose={handleHideAccountModal}
        targetAccountId={selectedAccountId}
      />
      <OverbookShiftModal
        isOpen={Boolean(overbookShiftId)}
        onModalClose={() => setOverbookShiftId(null)}
        isLoading={isOverbookLoading}
        overbookPercentage={Math.ceil(overbookPercentage)}
        onChange={overbookPercentage =>
          setOverbookPercentage(overbookPercentage)
        }
        numberRequested={flow(
          find({ id: overbookShiftId }),
          get("numberRequested")
        )(shifts)}
        onSaveOverbook={handleSaveOverbook}
      />
      <Header
        totalResults={totalResults}
        searchText={searchText}
        onSearch={text => onSearch(text)}
        onUpdateFilter={updates => onUpdateFilters(updates)}
        onRemoveFilters={filterKeys => onRemoveFilters(filterKeys)}
        filters={filters}
        sortBy={sortBy}
        onUpdateSortBy={sort => onUpdateSortBy(sort || sortBy)}
        onResetState={onResetState}
        isRotaAccount={getOr([], "account.accountName", user) === ROTA_ACCOUNT}
        serviceAreaOptions={getOr([], "account.serviceAreas", user)}
        roleOptions={roleOptions}
        isLoading={isLoading}
      />
      <FloatingTable>
        <div>
          <ColumnHeading width={5 / 12}>
            <Tab
              isDisabled={filters.shiftGroupId}
              onClick={() => onUpdateFilters({ shiftGroupId: null })}
            >
              Shift
            </Tab>
            <Tab shouldHide={!filters.shiftGroupId}>Linked Shift</Tab>
            {!!filters.shiftGroupId && (
              <LinkedShiftTag
                onClick={() => onUpdateFilters({ shiftGroupId: null })}
                bgColor={
                  shifts && shifts[0] && shifts[0].shiftGroupColor
                    ? shifts[0].shiftGroupColor
                    : null
                }
              >
                <StyledIcon
                  name={Icon.names.EXIT}
                  color={Icon.colors.WHITE}
                  size={Icon.sizes.XSMALL}
                />
                Group ID {filters.shiftGroupId}
              </LinkedShiftTag>
            )}
          </ColumnHeading>
          <ColumnHeading width={3 / 12}>Notes</ColumnHeading>
          <ColumnHeading width={3 / 12}>Requested</ColumnHeading>
          <ColumnHeading width={1 / 12}>&nbsp;</ColumnHeading>
        </div>
        <TableBody>
          {isNoResults ? (
            <NoResults />
          ) : (
            <div>
              {isLoading && isGraphicalLoadAllowed && !shifts.length && (
                <TablePlaceholder
                  columnWidths={[5 / 12, 3 / 12, 2 / 12, 2 / 12]}
                />
              )}
              {shifts.map(shift => (
                <ShiftRow
                  shift={shift}
                  key={shift.id}
                  handleOpenAccountModal={handleOpenAccountModal}
                  setOverbookShiftId={setOverbookShiftId}
                  setOverbookPercentage={setOverbookPercentage}
                  onUpdateFilters={onUpdateFilters}
                  autoAcceptUpdates={autoAcceptUpdates}
                  handleSetAutoAccept={handleSetAutoAccept}
                  handleSetFeaturedShift={handleSetFeaturedShift}
                  alignNotes={alignNotes}
                  handleSaveNotes={handleSaveNotes}
                  openShiftBookingList={openShiftBookingList}
                  handleOpenShiftModal={handleOpenShiftModal}
                  refetch={refetch}
                  setTabIndex={setTabIndex}
                  shiftBookingList={shiftBookingList}
                />
              ))}
            </div>
          )}
        </TableBody>
      </FloatingTable>

      {canLoadMore && (
        <LoadMore isLoading={isLoading} onLoadMore={handleLoadMore} />
      )}
    </DashboardScrollHandler>
  );
};

const mapStateToProps = ({
  provide: { filters, searchText, sortBy, shiftBookingList }
}) => ({
  filters,
  searchText,
  sortBy,
  shiftBookingList
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onSearch: reducerActions.onSearch,
      onUpdateFilters: reducerActions.onUpdateFilters,
      onResetState: reducerActions.onResetState,
      onUpdateSortBy: reducerActions.onUpdateSortBy,
      onRemoveFilters: reducerActions.onRemoveFilters,
      openShiftBookings: reducerActions.openShiftBookings,
      setTabIndex: reducerActions.setTabIndex
      //refetchBookings:
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  annotateItem(
    getProfile(
      getProvideShifts(updateShiftState(updateShiftOverbookPercentage(Provide)))
    )
  )
);

import { gql, useMutation } from "@apollo/client";
import adminClient from "~/src/containers/admin-dashboard/graphql/admin-client";

const CREATE_DIVISION = gql`
  mutation createDivison($accountId: ID!, $name: String!) {
    createDivision(accountId: $accountId, name: $name) {
      id
      name
    }
  }
`;

export const useCreateDivision = ({ onCompleted, onError, accountId }) => {
  const [create, { loading, error }] = useMutation(CREATE_DIVISION, {
    client: adminClient,
    onCompleted,
    onError
  });

  const createDivision = async ({ name }) => {
    await create({
      variables: {
        accountId,
        name
      }
    });
  };

  return { createDivision, loading, error };
};

import { gql } from "@apollo/client";

export const CREATE_TAG = gql`
  mutation($accountId: ID!, $tags: [String]!) {
    createTags(accountId: $accountId, tags: $tags) {
      id
      name
    }
  }
`;

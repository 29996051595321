import React from "react";
import { skewOptions } from "~/src/consts";
import Dropdown from "~/src/components/form-field/inputs/dropdown";
import Slider from "~/src/components/form-field/inputs/slider";

import styles, {
  Section,
  Title,
  Content,
  Cell,
  Row,
  OrangeText
} from "./matcher-config.styles";
import { factorSettingOptions, autoAcceptOptions } from "./utils";
import { RotaCheckbox } from "@teamrota/rota-design";

const AccountMatcher = ({
  isPartnerManagedEnabled,
  isEditMode,
  isAccount,
  daysBeforeShift,
  algoSkew,
  autoAcceptThreshold,
  globalRatingFactor,
  relationshipFactor,
  favouriteFactor,
  roleFactor,
  onChange
}) => (
  <div>
    <Section>
      <Title>Shift Control</Title>
      <div style={styles.checkboxWrapper}>
        <RotaCheckbox
          isChecked={isPartnerManagedEnabled}
          isDisabled={!isEditMode}
          onClick={() => {
            onChange("isPartnerManagedEnabled")(!isPartnerManagedEnabled);
          }}
          label={"Allowed to manage shifts"}
        />
      </div>
    </Section>
    <Section>
      <Title>Fulfillment Control</Title>
      <Content>
        The {isAccount ? "default" : "set"} fulfillment is{" "}
        <Dropdown
          readOnly={!isEditMode}
          isSingleLine
          options={skewOptions}
          input={{
            value: algoSkew,
            onChange: onChange("algoSkew")
          }}
        />
        <br />
        The shift will be opened up to all qualified members{" "}
        <OrangeText editMode={isEditMode}>{daysBeforeShift}</OrangeText> day
        {daysBeforeShift > 1 && "s"} before shift starts.
        <Slider
          min={1}
          max={30}
          readOnly={!isEditMode}
          labelFormat={val => `${val} day${val > 1 ? "s" : ""}`}
          input={{
            value: daysBeforeShift,
            onChange: onChange("daysBeforeShift")
          }}
        />
      </Content>
    </Section>
    <Section>
      <Title>Quality Control</Title>
      <Content>
        <Dropdown
          readOnly={!isEditMode}
          options={autoAcceptOptions}
          input={{
            value: autoAcceptThreshold,
            onChange: onChange("autoAcceptThreshold")
          }}
        />
        <br />
        Factors {isAccount ? "by which we can influence" : "set to affect"}{" "}
        member applications to shifts:
        <Row heading>
          <Cell>Factors</Cell>
          <Cell>Setting</Cell>
        </Row>
        <Row>
          <Cell>Member is favourited:</Cell>
          <Cell>
            <Dropdown
              readOnly={!isEditMode}
              isSingleLine={!isEditMode}
              options={factorSettingOptions}
              input={{
                value: favouriteFactor,
                onChange: onChange("favouriteFactor")
              }}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>Overall rating of the member:</Cell>
          <Cell>
            <Dropdown
              readOnly={!isEditMode}
              options={factorSettingOptions}
              isSingleLine={!isEditMode}
              input={{
                value: globalRatingFactor,
                onChange: onChange("globalRatingFactor")
              }}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>Rating of the member for that role:</Cell>
          <Cell>
            <Dropdown
              readOnly={!isEditMode}
              isSingleLine={!isEditMode}
              options={factorSettingOptions}
              input={{
                value: roleFactor,
                onChange: onChange("roleFactor")
              }}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>Member has worked with this partner previously:</Cell>
          <Cell>
            <Dropdown
              readOnly={!isEditMode}
              isSingleLine={!isEditMode}
              options={factorSettingOptions}
              input={{
                value: relationshipFactor,
                onChange: onChange("relationshipFactor")
              }}
            />
          </Cell>
        </Row>
      </Content>
    </Section>
  </div>
);

export default AccountMatcher;

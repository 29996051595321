import React from "react";
import { DOCUMENT_TYPES_LABELS } from "~/src/consts";
import { FormattedLog, Bullet, Highlight, Text } from "../../index.styles";

export default props => {
  if (props.actionType === "CREATE") {
    return (
      <FormattedLog>
        <Bullet>&bull;</Bullet>
        <Text>Added a</Text>
        <Highlight>
          <a href={props.url} target="_blank" rel="noopener noreferrer">
            {DOCUMENT_TYPES_LABELS[props.documentType]}
          </a>
        </Highlight>
      </FormattedLog>
    );
  }

  if (props.actionType === "DELETE") {
    return (
      <FormattedLog>
        <Bullet>&bull;</Bullet>
        <Text>Archived a</Text>
        <Highlight>
          <a href={props.url} target="_blank" rel="noopener noreferrer">
            {DOCUMENT_TYPES_LABELS[props.documentType]}
          </a>
        </Highlight>
      </FormattedLog>
    );
  }
  return null;
};

import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Content = styled.div`
  margin-left: ${STYLE_CONSTS.UNIT_2};
`;

import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import { ZonedDate } from "@teamrota/rota-common";

import { MAX_WEEKLY_HOURS } from "~/src/consts";
import { isFormattedDateValid } from "~/src/utils/time-utils";

import {
  StyledDropdownHeading,
  StyledHoursWorkedBarContainer,
  StyledHoursWorkedHeader,
  StyledInput,
  StyledP,
  StyledSmallBoldP,
  StyledBreak,
  StyledSelect,
  StyledErrorMessage
} from "./styles";

import HoursWorkedBar from "./hours-worker-bar";
import { getMemberHoursWorked } from "./graphql/get-member-hours-worked";

const HoursWorked = ({ memberId }) => {
  const [selectedPeriod, setSelectedPeriod] = useState("week");
  const [startDate, setStartDate] = useState(
    new ZonedDate(
      moment()
        .startOf("isoWeek")
        .format()
    )
  );
  const [endDate, setEndDate] = useState(null);
  const [maxHours, setMaxHours] = useState(MAX_WEEKLY_HOURS);
  const [isError, setIsError] = useState(false);
  const list = ["week", "month", "quarter", "year"];

  useEffect(() => {
    getEndDate(startDate, selectedPeriod);
    hoursCalculation();
  }, []);

  const { hoursCompleted } = getMemberHoursWorked(memberId, startDate, endDate);

  const getEndDate = async (date, timePeriod) => {
    //change time period text to work with moment
    let timeToAdd = "week";
    switch (timePeriod) {
      case "week":
        timeToAdd = "week";
        break;
      case "month":
        timeToAdd = "months";
        break;
      case "year":
        timeToAdd = "years";
        break;
      default:
        "week";
    }

    let endDate;
    if (timePeriod === "quarter") {
      endDate = moment(date)
        .add(4, "months")
        .format();
    } else {
      endDate = moment(date)
        .add(1, timeToAdd)
        .format();
    }

    // Don't run over into new time period
    // E.g. Mon 0:00:00 - Sun 11:59:59 instead of Mon 0:00:00 - Mon 0:00:00
    const finalDate = endDate
      ? moment(endDate)
          ?.subtract({ seconds: 1 })
          ?.format()
      : null;

    setEndDate(new ZonedDate(finalDate));
  };

  const updateSelectedPeriod = period => {
    setSelectedPeriod(period);
    getEndDate(startDate, period);
    hoursCalculation(period);
  };

  const updateStartTime = date => {
    if (date) {
      setStartDate(date);
      getEndDate(date, selectedPeriod);
    }
  };

  const hoursCalculation = period => {
    const selectedPeriod = period || selectedPeriod;
    switch (selectedPeriod) {
      case "quarter":
        setMaxHours(MAX_WEEKLY_HOURS * 4 * 3);
        break;
      case "month":
        setMaxHours(MAX_WEEKLY_HOURS * 4);
        break;
      case "year":
        setMaxHours(MAX_WEEKLY_HOURS * 52);
        break;
      default:
        setMaxHours(MAX_WEEKLY_HOURS);
    }
  };

  return (
    <>
      <StyledDropdownHeading>
        <StyledSmallBoldP>Hours booked this </StyledSmallBoldP>{" "}
        <StyledSelect
          list={list}
          onChange={e => {
            updateSelectedPeriod(e?.target?.value);
          }}
          value={selectedPeriod}
        >
          {list.map((label, i) => (
            <option key={i} value={label}>
              {label}
            </option>
          ))}
        </StyledSelect>
      </StyledDropdownHeading>
      <StyledHoursWorkedBarContainer>
        <StyledP>0 hrs</StyledP>
        <HoursWorkedBar
          hoursCompleted={hoursCompleted}
          hoursCompletedPercentage={Math.round(
            (hoursCompleted / maxHours) * 100
          )}
        />
        <StyledP>{maxHours} hrs</StyledP>
      </StyledHoursWorkedBarContainer>
      <StyledHoursWorkedHeader>
        <StyledP style={{ paddingRight: "5px" }}>For the period:</StyledP>
        <StyledSmallBoldP>from</StyledSmallBoldP>
        <>
          <DatePicker
            placeholderText={"Start date"}
            selected={startDate}
            onChange={date => updateStartTime(ZonedDate.fromSelectedDate(date))}
            onChangeRaw={e => setIsError(!isFormattedDateValid(e.target.value))}
            open={false}
            dateFormat="dd/MM/yyyy"
            strictParsing
            customInput={<StyledInput isError={isError} />}
          />
        </>
        {endDate && (
          <>
            <StyledSmallBoldP>until</StyledSmallBoldP>
            <DatePicker
              disabled={true}
              placeholderText={"End date"}
              selected={endDate}
              dateFormat="dd/MM/yyyy"
              customInput={<StyledInput disabled />}
            />
          </>
        )}
      </StyledHoursWorkedHeader>
      <StyledErrorMessage>
        {isError && "Invalid date format."}
      </StyledErrorMessage>
      <StyledBreak></StyledBreak>
    </>
  );
};

export default HoursWorked;

import React, { Component } from "react";
import moment from "moment-timezone";

import MemberPhoto from "~/src/components/member-photo";
import LoadMore from "~/src/components/load-more";
import { errorModal } from "~/src/utils/errors";

import memberSearch from "../graphql/member-search";
import {
  MemberListContainer,
  MemberPhotoWrapper,
  InfoWrapper,
  PhotoInfoWrapper
} from "./member-search-list.styles";

@memberSearch
export default class MemberSearchList extends Component {
  canLoadMoreMembers = () =>
    this.props.members.length < this.props.totalResults;

  handleLoadMore = async () => {
    if (this.canLoadMoreMembers() && !this.props.isLoading) {
      try {
        this.props.fetchMoreMembers();
      } catch (e) {
        errorModal(e);
      }
    }
  };

  render() {
    const { props } = this;
    const isNoResults = props.members.length === 0 && !props.isLoading;
    const isNoMoreResults = !this.canLoadMoreMembers();

    return (
      <MemberListContainer>
        {isNoResults && <p>No results, please search for another member</p>}

        {props.members.map((m, index) => (
          <PhotoInfoWrapper key={index}>
            <MemberPhotoWrapper>
              <MemberPhoto isInline size="medium" src={m.member.photo} />
            </MemberPhotoWrapper>
            <InfoWrapper>
              <p> ID: {m.member.id} </p>
              <p>
                Name: {m.member.firstName} {m.member.lastName}
              </p>
              <p>
                {" "}
                Visible at time:{" "}
                {moment.utc(m.visibleAt).format("HH:mm , DD MMM. YYYY")}
              </p>{" "}
              <span> State: {m.state} </span>
            </InfoWrapper>
          </PhotoInfoWrapper>
        ))}
        {!isNoResults && !isNoMoreResults && (
          <LoadMore
            isLoading={props.isLoading}
            onLoadMore={this.handleLoadMore}
          />
        )}
      </MemberListContainer>
    );
  }
}

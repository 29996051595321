import React from "react";
import { Input, Wrapper } from "./date-input.styles";

export default function DateInput(props) {
  return (
    <Wrapper isError={props.isError}>
      <Input
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onKeyDown={event => {
          if (props.onEnter && event.keyCode === 13) {
            props.onEnter();
          }
        }}
        isDisabled={props.isDisabled}
      />
    </Wrapper>
  );
}

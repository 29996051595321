import React, { useState, useRef, useEffect } from "react";
import ActionButton from "./action-button";
import ActionSpan from "./action-span";
import {
  DropdownContent,
  Option,
  DropdownWrapper
} from "./action-dropdown.styles";
import Loading from "../loading";
import { useTheme } from "styled-components";

const ActionDropdown = ({
  onChange,
  shouldCloseOnSelect,
  children,
  leftMargin,
  displayNormal,
  isRewardDropDown,
  isPrimary,
  isFontPrimary,
  isIconPrimary,
  isSmall,
  isBold,
  isAlternate,
  isSpan,
  isAlignedLeft,
  isSquare,
  isFullWidth,
  shouldLeftShiftDropdown,
  value,
  defaultValue,
  options,
  isLoading,
  isDisabled
}) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const inputEl = useRef(null);

  const onSelectOption = option => {
    onChange(option);
    if (shouldCloseOnSelect) {
      hide();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", handleClose);
      return () => document.removeEventListener("click", handleClose);
    }
  }, [isOpen]);

  const hide = () => {
    setIsOpen(false);
  };

  const handleClose = e => {
    if (children) {
      // Note: null indicates clicking on a submenu, want to keep menu open in that case
      const isClickInside = inputEl?.current?.contains(e?.target);
      if (isClickInside === false) hide();
    } else {
      hide();
    }
  };

  const show = () => {
    if (isOpen) hide();
    else setIsOpen(true);
  };

  const placeholder =
    value?.label || value || defaultValue || options?.[0]?.label;

  return (
    <DropdownWrapper
      leftMargin={leftMargin}
      isFullWidth={isFullWidth}
      displayNormal={displayNormal}
    >
      {isLoading ? (
        <Loading color={theme.primary.main} />
      ) : (
        <>
          {!!isSpan && (
            <ActionSpan
              isRewardDropDown={isRewardDropDown}
              onClick={show}
              isPrimary={isPrimary}
              isSmall={isSmall}
              isFontPrimary={isFontPrimary}
              placeholder={placeholder}
            />
          )}
          {!isSpan && (
            <ActionButton
              isFontPrimary={isFontPrimary}
              isIconPrimary={isIconPrimary}
              isPrimary={isPrimary}
              isSmall={isSmall}
              isBold={isBold}
              isAlternate={isAlternate}
              isSquare={isSquare}
              onClick={show}
              isDisabled={isDisabled}
            >
              {placeholder}
            </ActionButton>
          )}
        </>
      )}
      {isOpen && options && (
        <DropdownContent
          isFullWidth={isFullWidth}
          shouldLeftShiftDropdown={shouldLeftShiftDropdown}
          isAlignedLeft={isAlignedLeft}
        >
          {options.map((option, i) => (
            <Option
              key={i}
              isSmall={isSmall}
              onClick={() => onSelectOption(option)}
              isSpan={!isSpan}
              isRewardDropDown={isRewardDropDown}
            >
              {option.label}
            </Option>
          ))}
        </DropdownContent>
      )}
      {isOpen && <div ref={inputEl}>{children}</div>}
    </DropdownWrapper>
  );
};

export default ActionDropdown;

import React from "react";
import moment from "moment-timezone";

import { RotaCheckbox } from "@teamrota/rota-design";

import Icon from "~/src/components/icon";
import {
  CalendarHeaderBox,
  ToolBar,
  HeaderMonth,
  HeaderItem,
  HeaderItemLeft
} from "./styles";
import ManageShiftsHeaderComponent from "../ManageShiftsHeader";

export const getMonthRange = weekCommencing => {
  const startDate = moment(weekCommencing);
  const endDate = moment(weekCommencing).add(6, "day");

  const monthStarting = startDate.format("MMMM");
  const yearStarting = startDate.format("YYYY");
  const monthEnding = endDate.format("MMMM");
  const yearEnding = endDate.format("YYYY");

  const isSameMonth = monthStarting === monthEnding;
  const isSameYear = yearStarting === yearEnding;

  if (isSameMonth && isSameYear) return `${monthStarting} ${yearEnding}`;
  if (isSameYear) return `${monthStarting}/${monthEnding} ${yearEnding}`;
  return `${monthStarting} ${yearStarting} / ${monthEnding} ${yearEnding}`;
};

const CalendarHeader = ({
  calendarWeekDate,
  manageableShiftsCount,
  isMinimumOnePartnerManagedConnection,
  shouldShowCancelled,
  handleShowCancelShifts,
  onDateChange,
  onToggleSideBar
}) => {
  const handleClick = change => e => {
    e.preventDefault();
    onDateChange(change);
  };

  return (
    <CalendarHeaderBox>
      <ToolBar>
        {isMinimumOnePartnerManagedConnection && (
          <HeaderItemLeft onClick={onToggleSideBar}>
            <ManageShiftsHeaderComponent
              manageableShiftsCount={manageableShiftsCount}
            />
          </HeaderItemLeft>
        )}
        <HeaderItem>
          <RotaCheckbox
            onClick={handleShowCancelShifts}
            isChecked={shouldShowCancelled}
            label="Cancelled Shifts"
          />
        </HeaderItem>
        <HeaderItem>
          <HeaderMonth>{getMonthRange(calendarWeekDate)}</HeaderMonth>
        </HeaderItem>

        <HeaderItem onClick={handleClick(-1)}>
          <Icon
            size={12}
            color={Icon.colors.BLACK}
            name={Icon.names.ARROW_LEFT}
          />
        </HeaderItem>
        <HeaderItem onClick={handleClick(0)}>This week</HeaderItem>
        <HeaderItem onClick={handleClick(1)}>
          <Icon
            size={12}
            color={Icon.colors.BLACK}
            name={Icon.names.ARROW_RIGHT}
          />
        </HeaderItem>
      </ToolBar>
    </CalendarHeaderBox>
  );
};

export default React.memo(CalendarHeader);

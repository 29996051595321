import styled, { css } from "styled-components";

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 32px 0;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0px;
  ${({ withBorder }) =>
    withBorder &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.neutral.light};
    `}
`;

export const StyledToolBarLeft = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  width: fill-available;
`;

export const StyledToolBarRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
`;

export const StyledFilterContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: fill-available;
  height: 100%;
  margin-right: 0;
`;

export const PublishContainerWrapper = styled.div`
  margin-left: 16px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

import isFinite from "lodash/isFinite";
import { useCurrency } from "~/src/containers/profile-loader";
import Money, { moneyString, moneyType } from "../money";

// MoneyTransaction is a class wrapper around the basic Money (Dinero.js) object
// It allows chaining of .add, .subtract, .divide etc. methods on a single MoneyTransaction
// .applyVAT can be called, but it must be the last operation in a chain
class MoneyTransaction {
  constructor(transactionDescription, metadata, isRequiredToBePositiveValue) {
    this.currentValue = new Money(); // Dinero.js object, defaults to zero
    this.isVATApplied = false; // Boolean state flag. Once VAT is applied no further operations can be made

    this.transactionDescription = transactionDescription; // Descriptive name used to help in tracing errors
    this.metadata = metadata; // Useful to store shiftId: string, bookingId?: string, memberId?: string
    this.isRequiredToBePositiveValue = !!isRequiredToBePositiveValue; // Flag if values should be checked for positivity (aka can't have negative amounts of Money)
  }

  get current() {
    return this.currentValue;
  }

  // Pass to underlying Dinero.js method
  toFormat = () => this.currentValue.toFormat();

  // Pass to underlying Dinero.js method
  toObject = () => this.currentValue.toObject();

  // value can be a float, or it can be a Money object
  chain = (opName, func, value, isCheckMoneyType) => {
    try {
      // Prevent operations on a value with VAT applied
      if (this.isVATApplied) throw new Error("VAT already applied.");

      // Type check input value
      if (value) {
        if (isCheckMoneyType && !moneyType(value)) {
          throw new Error(
            "TypeError: Function expect input type Money returned typeof ",
            typeof value
          );
        } else if (!isCheckMoneyType && !isFinite(value)) {
          throw new Error(
            "TypeError: Function expect input type number returned typeof ",
            typeof value
          );
        }
      }

      const newValue = func(value);

      // Ensure result is a Money object, and positive if required
      if (!moneyType(newValue))
        throw new Error(
          "TypeError: Function expect Money type returned typeof ",
          typeof newValue
        );

      if (!!this.isRequiredToBePositiveValue && newValue.isNegative())
        throw new Error(
          `Result of function must always be a postivive float, isRequiredToBePositiveValue Error: ${moneyString(
            newValue
          )}`
        );

      // Update value, and return this for chaining
      this.currentValue = newValue;
      return this;
    } catch (e) {
      throw new Error(
        `${e}\nAccounting Chain Corrupted(${
          this.transactionDescription
        }): ${moneyString(value)} ${opName}`
      );
    }
  };

  add = val => this.chain("add", val => this.currentValue.add(val), val, true);

  subtract = val =>
    this.chain("subtract", val => this.currentValue.subtract(val), val, true);

  multiply = val =>
    this.chain("multiply", val => this.currentValue.multiply(val), val);

  divide = val =>
    this.chain("divide", val => this.currentValue.divide(val), val);

  percentage = val =>
    this.chain("percentage", perc => this.currentValue.percentage(perc), val);

  // Must be the final method in a chain
  applyVAT = (taxRate = 1 + useCurrency().taxRate / 100) =>
    this.chain(
      "applyVAT",
      val => {
        const newValue = this.currentValue.multiply(val);
        this.isVATApplied = true;
        return newValue;
      },
      taxRate
    );
}

export default MoneyTransaction;

import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router";

import { Authorization, LoginType } from "@teamrota/authlib";

import { AuthContext, NOT_AUTHED } from "~/src/auth/contexts";

import Spinner from "~/src/components/full-screen-spinner";
import ModalError from "~/src/components/modal-error";

import {
  userManager,
  userInfoService,
  signinRedirect,
  refreshUser
} from "~/src/auth/manager";

const Authenticate = ({ children }) => {
  const { pathname } = useLocation();

  const {
    auth,
    setAuth,
    isLanding,
    setIsLanding,
    isUnloaded,
    setIsUnloaded,
    isLogout
  } = useContext(AuthContext);

  useEffect(() => {
    const handleUserLoaded = async user => {
      try {
        const userInfo = await userInfoService.getUserInfo(user.access_token);

        setAuth(
          new Authorization({
            authed: true,
            accessToken: user.access_token,
            userInfo
          })
        );

        if (isLanding) {
          setIsLanding(false);
        }
      } catch (err) {
        console.error(err);
        // something went wrong, e.g. user info call
        if (isLanding) {
          signinRedirect(pathname);
        } else {
          userManager.removeUser();
        }
      }
    };

    const handleUserUnloaded = () => {
      if (!isLogout) {
        setIsUnloaded(true);
        if (auth.authed) {
          setAuth(NOT_AUTHED);
        }
      }
    };

    userManager.events.addUserLoaded(handleUserLoaded);
    userManager.events.addUserUnloaded(handleUserUnloaded);

    const handleLanding = async () => {
      const url = window.location.href;

      if (
        url.includes("?") &&
        url.match(/[?&]code=/) &&
        url.match(/[?&]state=/)
      ) {
        try {
          await userManager.signinRedirectCallback();
          window.history.replaceState({}, "", "/");
        } catch (err) {
          // can't match returned state, force login
          signinRedirect(pathname);
        }
      }

      if (
        url.includes("?") &&
        url.match(/[?&]error=/) &&
        url.match(/[?&]state=/)
      ) {
        // auth error, force login
        signinRedirect(pathname);
      }

      await refreshUser();
      const user = await userManager.getUser();
      if (user === null || user.expired) {
        signinRedirect(pathname);
      } else {
        // userManager reloads its user on initialization
        // before event handler can be register
        // so call it ourselves to kick things off on landing
        handleUserLoaded(user);
      }
    };

    handleLanding();

    return () => {
      userManager.events.removeUserLoaded(handleUserLoaded);
      userManager.events.removeUserUnloaded(handleUserUnloaded);
    };
  }, []);

  if (isLanding || isLogout) {
    return <Spinner />;
  } else if (isUnloaded) {
    return (
      <ModalError
        title="Signed Out"
        buttonText="Sign In"
        onClose={() => signinRedirect(pathname, true)}
        showHelp={false}
      >
        You were signed out
      </ModalError>
    );
  } else if (auth.info.type === LoginType.MEMBER) {
    // member ended up here! send them to the app
    // do this with a deep link eventually, for now, just send to join.rota.com
    window.location.href = "https://join.rota.com/";
    return "";
  } else {
    return children;
  }
};

export default Authenticate;

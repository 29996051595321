import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

import { addAuthVars } from "@teamrota/authlib";

export const UPDATE_PAYROLL_RATE = addAuthVars(gql`
  mutation updatePayrollRate($id: ID!, $name: String!, $config: [JSONObject]!) {
    updatePayrollRate(id: $id, name: $name, config: $config) {
      id
      name
      config
    }
  }
`);

export const useUpdatePayrollRate = () => {
  const [update, { loading, error }] = useMutation(UPDATE_PAYROLL_RATE, {
    onError: error => {
      throw error;
    }
  });
  const updatePayrollRate = async ({
    id,
    name,
    config
  }: {
    id: string;
    name: string;
    config: JSON;
  }) => {
    await update({
      variables: {
        id,
        name,
        config
      },
      refetchQueries: ["payrollRates"]
    });
  };

  return { updatePayrollRate, loading, error };
};

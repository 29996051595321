import React from "react";
import { connect } from "react-redux";

import DefaultError from "./default-error";
import AsyncConfirm from "./async-confirm";
import AddMember from "./add-member";
import DownloadStaffData from "./download-staff-data";
import BlackAndOrangeModal from "./BlackAndOrangeModal";

export const MODAL_TYPES = {
  ERROR: "ERROR",
  ASYNC_CONFIRM: "ASYNC_CONFIRM",
  ASYNC_CONFIRM_LINKEDSHIFT: "ASYNC_CONFIRM_LINKEDSHIFT",
  ADD_MEMBER: "ADD_MEMBER",
  DOWNLOAD_STAFF_DATA: "DOWNLOAD_STAFF_DATA",
  BLACK_AND_ORANGE: "BLACK_AND_ORANGE"
};

const modals = {
  [MODAL_TYPES.ERROR]: DefaultError,
  [MODAL_TYPES.ASYNC_CONFIRM]: AsyncConfirm,
  [MODAL_TYPES.ADD_MEMBER]: AddMember,
  [MODAL_TYPES.DOWNLOAD_STAFF_DATA]: DownloadStaffData,
  [MODAL_TYPES.BLACK_AND_ORANGE]: BlackAndOrangeModal
};

function Modals({ modalType, modalProps = {} }) {
  const Modal = modals[modalType];

  if (Modal) {
    return <Modal {...modalProps} isOpen />;
  }

  return null;
}

export default connect(state => ({
  modalProps: state.app.modalProps,
  modalType: state.app.modalType
}))(Modals);

import { gql, useQuery } from "@apollo/client";

const GET_AVAILABLE_MEMBER_TYPES = gql`
  query getAvailableMemberTypes {
    account {
      id
      memberTypesAvailableForAccount {
        isCasualMemberTypeAvailable
        isPermanentMemberTypeAvailable
        isAgencyMemberTypeAvailable
        isContractorLimitedCompanyMemberTypeAvailable
        isContractorLimitedCompanyMemberTypeInfoRequired
        isContractorUmbrellaCompanyMemberTypeAvailable
        isContractorUmbrellaCompanyMemberTypeInfoRequired
        isContractorSelfEmployedMemberTypeAvailable
        isContractorSelfEmployedMemberTypeInfoRequired
      }
    }
  }
`;

export const useDefaultMemberAccount = () => {
  const { data, loading } = useQuery(GET_AVAILABLE_MEMBER_TYPES, {
    fetchPolicy: "cache-and-network",
    onError: error => {
      throw error;
    }
  });

  return {
    memberTypesAvailableForAccount:
      data?.account?.memberTypesAvailableForAccount,
    isLoadingAccountMemberTypes: loading
  };
};

import React from "react";
import { get } from "lodash/fp";
import { flow } from "lodash";
import { Role } from "@teamrota/authlib";

import { withAuth } from "~/src/auth/hoc";
import Modal from "~/src/components/modal";
import Tabs from "~/src/containers/modals/components/tabs";
import History from "~/src/containers/modals/components/history";
import { showShiftTimeRange } from "~/src/utils/formatting";
import getProfile from "~/src/graphql/queries/get-profile/get-profile-query.decorator";
import { TIMESHEET_MEMBER_STATUS_LABELS } from "~/src/consts";
import TimeDisplay from "~/src/components/shift-time-display";

import getTimesheetMember from "./graphql/get-timesheet-member";
import getAccountVenues from "./graphql/get-account-venues";
import ViewEditTimesheetFormComponent from "./view-edit-timesheet-form";
import ModalContent from "../components/modal-content";
import Title from "../components/title";
import Text from "../components/text";
import { LeftPane, RightPane } from "../components/content-wrapper";
import { TIMESHEET_STATUS } from "@teamrota/rota-common";
import { RotaStatusLabel } from "@teamrota/rota-design";
import { getMemberStatusType } from "../utils";

const ViewEditTimesheetComponent = ({
  timesheetMemberRow,
  venues,
  isOpen,
  onClose,
  isLoading,
  auth
}) => {
  if (!isOpen) return false;

  const timesheetStatus = get("timesheet.status", timesheetMemberRow);
  const status = get("status", timesheetMemberRow);

  const tabs = isOpen
    ? [
        {
          name: "Info",
          content: (
            <ViewEditTimesheetFormComponent
              timesheetMemberRow={timesheetMemberRow}
              venues={venues}
              timesheetStatus={timesheetStatus}
            />
          )
        },
        ...(auth?.hasRole(Role?.SHIFTS_HISTORY)
          ? [
              {
                name: "History",
                content: <History logs={get("logs", timesheetMemberRow)} />
              }
            ]
          : [])
      ]
    : null;

  const startTime = get("scheduledStartTime", timesheetMemberRow);
  const endTime = get("scheduledEndTime", timesheetMemberRow);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isLoading={isLoading}>
      <ModalContent title="Timesheet Details">
        <LeftPane>
          <Title marginBottom>Summary</Title>
          <Text>{get("timesheet.accountName", timesheetMemberRow)}</Text>
          <Text>{`${get("role.name", timesheetMemberRow)}`}</Text>
          <Text>
            <TimeDisplay
              displayDate={showShiftTimeRange(startTime, endTime, {
                includeYear: true
              })}
              startTime={startTime}
              endTime={endTime}
            />
          </Text>

          <Title marginTop>Status</Title>
          <RotaStatusLabel type={getMemberStatusType(status, timesheetStatus)}>
            {timesheetStatus === TIMESHEET_STATUS.LOCKED
              ? "Locked"
              : TIMESHEET_MEMBER_STATUS_LABELS[status]}
          </RotaStatusLabel>
        </LeftPane>
        <RightPane>
          <Tabs tabs={tabs} defaultTab="Info" />
        </RightPane>
      </ModalContent>
    </Modal>
  );
};

export default flow(
  getTimesheetMember,
  getAccountVenues,
  getProfile,
  withAuth
)(ViewEditTimesheetComponent);

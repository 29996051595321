import { RotaCalendar, RotaDropdown } from "@teamrota/rota-design";
import React, { useState } from "react";

import { FormContainer, DatePickerContainer, DatePickerLabel } from "./style";
import moment from "moment";

const LEVEL_OPTIONS = Array.from({ length: 20 }, (_, i) => {
  return { value: i + 1, label: `${i + 1}` };
});
const CREDITS_OPTIONS = Array.from({ length: 50 }, (_, i) => {
  return { value: i + 1, label: `${i + 1}` };
});

export const PayrollForm = ({ isChecked, role, setSelectedRoles }) => {
  const [level, setLevel] = useState(role.payScaleLevel || null);
  const [credits, setCredits] = useState(role.currentCreditsNumber || null);
  const [date, setDate] = useState(
    role.incrementDate ? moment(role.incrementDate).toDate() : null
  );

  React.useEffect(() => {
    if (!isChecked) {
      setLevel(role.payScaleLevel || null);
      setCredits(role.currentCreditsNumber || null);
      setDate(role.incrementDate ? moment(role.incrementDate).toDate() : null);
    }
  }, [isChecked]);

  const updateRoleInformation = () => {
    setSelectedRoles(prevState => {
      const newState = prevState.map(r => {
        if (r.roleId === role.id) {
          return {
            ...r,
            payScaleLevel: level,
            currentCreditsNumber: credits,
            incrementDate: date
          };
        }
        return r;
      });

      return newState;
    });
  };

  React.useEffect(() => {
    updateRoleInformation();
  }, [level, credits, date]);

  return (
    <FormContainer isShow={isChecked}>
      <RotaDropdown
        label={"Pay Scale Level"}
        options={LEVEL_OPTIONS}
        errorMessage={""}
        isError={false}
        value={level}
        isDisplayValueDisabled={false}
        onChange={value => setLevel(value)}
        isDisabled={!isChecked}
      />

      <RotaDropdown
        label={"Current Credits"}
        options={CREDITS_OPTIONS}
        errorMessage={""}
        isError={false}
        value={credits}
        isDisplayValueDisabled={false}
        onChange={value => setCredits(value)}
        isDisabled={!isChecked}
      />

      <DatePickerContainer>
        <DatePickerLabel>Increment Date</DatePickerLabel>
        <RotaCalendar
          value={date}
          onChangeDate={setDate}
          disabled={!isChecked}
        />
      </DatePickerContainer>
    </FormContainer>
  );
};

import { createReducer } from "../utils";

const defaultState = {
  modalType: null,
  modalProps: null,
  isSaving: false,
  hasSaved: false
};

const handlers = {
  OPEN_MODAL(state, { payload: { modalType, modalProps } }) {
    return {
      ...state,
      modalType,
      modalProps
    };
  }
};

export default createReducer(handlers, defaultState);

import React, { useEffect, useState } from "react";
import { uniqBy } from "lodash";
import { RotaMultiSelectMenu, RotaChip } from "@teamrota/rota-design";
import { useGetAccountTags } from "../../graphql/get-account-tags";
import { getTagOptions, getSortedTags, sortTagsByLength } from "../../utils";

import {
  TagsContainer,
  Heading,
  TagsWrapper,
  StyledRotaDropdownHeader,
  ChipsContainer
} from "./edit-tags.styles";

const EditTags = ({
  id,
  title,
  selectedTags,
  setSelectedTags,
  children,
  isParentTag = false,
  selectedParentTag,
  setSelectedParentTag,
  setIsButtonDisabled
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filteredTags, setFilteredTags] = useState([]);

  const { accountTags } = useGetAccountTags();

  const tagOptions = getTagOptions(accountTags, isParentTag);

  useEffect(() => {
    if (accountTags) setFilteredTags(tagOptions);
  }, [accountTags]);

  useEffect(() => {
    if (selectedParentTag) {
      setSelectedTags(
        selectedTags.map(tag => {
          const roleTags = selectedParentTag.selected
            ? tag.roleTags?.filter(
                ({ value }) => value !== selectedParentTag.value
              )
            : uniqBy([selectedParentTag, ...tag.roleTags], "value");
          return { ...tag, roleTags };
        })
      );
    }
  }, [selectedParentTag]);

  const currentTags = isParentTag
    ? selectedTags
    : selectedTags?.find(({ roleId }) => roleId === id)?.roleTags;

  const isCurrentTags = !!currentTags?.length;
  const selectedTagIds = currentTags?.map(item => item.value);

  const handleClearTags = () => {
    if (isParentTag) setSelectedTags([]);
    else {
      const tags = selectedTags?.map(tag =>
        tag.roleId === id ? { ...tag, roleTags: [] } : tag
      );
      setSelectedTags(sortTagsByLength(tags));
    }
  };

  const handleAddTag = selectedTag => {
    if (isParentTag) {
      setSelectedTags([...selectedTags, selectedTag]);
      setSelectedParentTag(selectedTag);
    } else {
      const tags = selectedTags?.map(tag =>
        tag.roleId === id
          ? { ...tag, roleTags: [...tag.roleTags, selectedTag] }
          : tag
      );
      setSelectedTags(sortTagsByLength(tags));
    }
  };

  const handleRemoveTag = selectedTag => {
    if (isParentTag) {
      setSelectedTags(
        selectedTags?.filter(({ value }) => value !== selectedTag.value)
      );
      setSelectedParentTag({ ...selectedTag, selected: true });
    } else {
      const tags = selectedTags?.map(tag =>
        tag.roleId === id
          ? {
              ...tag,
              roleTags: tag.roleTags?.filter(
                ({ value }) => value !== selectedTag.value
              )
            }
          : tag
      );
      setSelectedTags(sortTagsByLength(tags));
    }
  };

  const onSelect = selectedTag => {
    setIsButtonDisabled(false);
    if (selectedTagIds.includes(selectedTag.value)) {
      handleRemoveTag(selectedTag);
    } else handleAddTag(selectedTag);
  };

  const onClear = selectedTag => {
    setIsButtonDisabled(false);
    if (selectedTag) handleRemoveTag(selectedTag);
    else handleClearTags();
  };

  const onSearch = value => {
    setFilteredTags(
      tagOptions.filter(({ label }) =>
        label.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const getFilteredTagItems = () => {
    return filteredTags
      ?.filter(tag =>
        isParentTag ? tag : !selectedTagIds?.includes(tag.value)
      )
      ?.map(tag => ({
        ...tag,
        selected: selectedTagIds?.includes(tag.value)
      }));
  };

  return (
    <TagsContainer isParentTag={isParentTag}>
      <Heading isParentTag={isParentTag}>{title}:</Heading>
      <TagsWrapper>
        <StyledRotaDropdownHeader
          labelText={"Search for tags"}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          shouldShowDot={!!currentTags?.length}
        >
          <RotaMultiSelectMenu
            items={getFilteredTagItems()}
            onClear={() => onClear()}
            onSelect={value => onSelect(value)}
            onSearch={value => onSearch(value)}
          />
        </StyledRotaDropdownHeader>
        <ChipsContainer>
          {isCurrentTags &&
            getSortedTags(currentTags).map(tag => {
              const isSharedTag = !isParentTag && tag.isParentTag;
              return (
                <RotaChip
                  isDisabled={isSharedTag}
                  key={tag.value}
                  chipText={tag.label}
                  onClose={!isSharedTag ? () => onClear(tag) : null}
                />
              );
            })}
        </ChipsContainer>
        {children}
      </TagsWrapper>
    </TagsContainer>
  );
};

export default EditTags;

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import findIndex from "lodash/fp/findIndex";
import { set } from "lodash/fp";

import { addAuthVars } from "@teamrota/authlib";

import useAuth from "~/src/auth/hooks/use-auth";
import LogFragment from "~/src/graphql/fragments/log/log.fragment";

const UPDATE_SHIFT_OVERBOOK_PERCENTAGE = addAuthVars(gql`
  ${LogFragment}
  mutation updateShiftOverbookPercentage(
    $shiftId: ID!
    $overbookPercentage: Float!
  ) {
    createOrUpdateShift(
      shifts: [{ id: $shiftId, overbookPercentage: $overbookPercentage }]
      shouldSkipTimeValidation: true
    ) {
      id
      ... on shift {
        logs {
          ...LogFragment
        }
      }
    }
  }
`);

export default graphql(UPDATE_SHIFT_OVERBOOK_PERCENTAGE, {
  name: "updateShiftOverbookPercentage",
  props: ownProps => {
    const auth = useAuth();
    return {
      updateShiftOverbookPercentage: (shiftId, overbookPercentage) =>
        ownProps.updateShiftOverbookPercentage({
          variables: auth.addVals(UPDATE_SHIFT_OVERBOOK_PERCENTAGE, {
            shiftId,
            overbookPercentage
          }),
          updateQueries: {
            getProvideShifts(original) {
              const shiftIndex = findIndex(
                { id: shiftId },
                original.account.shifts.data
              );

              if (shiftIndex < 0) return original;

              return set(
                `account.shifts.data[${shiftIndex}].overbookPercentage`,
                overbookPercentage,
                original
              );
            }
          }
        })
    };
  }
});

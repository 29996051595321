import React, { useEffect, useState } from "react";
import pick from "lodash/fp/pick";
import DynamicForm from "~/src/components/dynamic-form";
import updateHomeVenueIds from "../graphql/updateHomeVenueIds";
import getSchema from "./schema";
import validate from "./validate";
import { gql } from "@apollo/client";
import client from "~/src/services/graphql";

const GET_VENUES = gql`
  query getVenues {
    account {
      venues {
        name
        id
      }
    }
  }
`;

const FORM_ID = "create-edit-user";

const UserInfo = ({ user, createUpdateUser, afterSubmitNewUser }) => {
  const isAddUser = !user?.id;
  const [schema, setSchema] = useState([]);

  const getVenues = async () => {
    const result = await client.query({
      query: GET_VENUES
    });
    const accountVenues = result?.data?.account?.venues;
    if (accountVenues) setSchema(getSchema(user, accountVenues));
  };

  useEffect(() => {
    getVenues();
  }, []);

  return (
    <DynamicForm
      form={FORM_ID}
      isEditOnly={isAddUser}
      schema={schema}
      validate={validate}
      onSubmit={createUpdateUser}
      afterSubmit={isAddUser ? afterSubmitNewUser : undefined}
      initialValues={pick(
        [
          "firstName",
          "lastName",
          "email",
          "phoneNumber",
          "id",
          "notes",
          "venueIds"
        ],
        user
      )}
    />
  );
};

export default updateHomeVenueIds(UserInfo);

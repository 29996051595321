import { gql } from "@apollo/client";

export const GET_MEMBERS_OVER_LIMIT = gql`
  query shiftMembersOverLimit($shiftId: ID!, $dates: [DateRangeType]!) {
    shiftMembersOverLimit(shiftId: $shiftId, dates: $dates) {
      id
      firstName
      lastName
    }
  }
`;

import styled, { css } from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

const ERROR_HEIGHT = "14px";

export const inputStyles = css`
  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.neutral.main};
  }
  background: ${({ theme }) => theme.white};
  border: ${({ isBorderless, isError, isOutline, isUnderline, theme }) => {
    if (isBorderless && !isError) return "none";
    if (isOutline || isUnderline) return `1px solid ${theme.neutral.main}`;
    return `solid 1px ${isError ? theme.error.main : theme.neutral.light}`;
  }};
  ${({ isUnderline }) => isUnderline && `border-width: 0 0 1px;`}
  font-size: ${({ fontSizeOveride }) =>
    fontSizeOveride || `${STYLE_CONSTS.FONT}`};
  line-height: ${STYLE_CONSTS.FONT};
  padding: ${({ isSmall }) =>
    isSmall ? STYLE_CONSTS.UNIT_1 : STYLE_CONSTS.UNIT_2};
  padding-right: ${({ hasIconRight }) =>
    hasIconRight ? `${parseInt(STYLE_CONSTS.UNIT_2, 10) * 3}px` : null};
  padding-left: ${({ hasIconLeft }) =>
    hasIconLeft ? `${parseInt(STYLE_CONSTS.UNIT_2, 10) * 3}px` : null};
  padding-left: ${({ isBorderless, isError }) =>
    isBorderless && !isError ? 0 : null};
  border-radius: ${({ isRounded, borderRadiusSize, isUnderline }) => {
    if (isRounded) return STYLE_CONSTS.UNIT_2;
    if (borderRadiusSize) return borderRadiusSize;
    if (isUnderline) return "0px";
    return STYLE_CONSTS.BORDER_RADIUS_S;
  }};
  width: ${({ isInline }) => (isInline ? "auto" : "100%")};
  display: ${({ isInline }) => (isInline ? "inline-block" : "block")};
  color: ${({ fontColor }) => fontColor || "inherit"};
  margin-bottom: ${props =>
    props.shouldFixLabelHeight && !props.errorLabel ? ERROR_HEIGHT : 0};
  resize: ${({ isExtendable }) => (isExtendable ? "vertical" : "none")};
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.primary.main};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.neutral.light};
    border-color: ${({ theme }) => theme.neutral.main};
    color: ${({ theme }) => theme.text.primary};
    cursor: not-allowed;
  }
  &:invalid {
    box-shadow: none;
  }
  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.neutral.main};
  }
  &::-moz-placeholder {
    color: ${({ theme }) => theme.neutral.main};
  }
  &:-ms-input-placeholder {
    color: ${({ theme }) => theme.neutral.main};
  }
  &:-moz-placeholder {
    color: ${({ theme }) => theme.neutral.main};
  }
`;

export const InputWrapper = styled.div`
  width: ${({ isInline }) => (isInline ? "auto" : "100%")};
  display: ${({ isInline }) => (isInline ? "inline-block" : "block")};
`;

export const InputInnerWrapper = styled.div`
  display: ${({ isInline }) => (isInline ? "inline-block" : "block")};
  position: relative;
`;

export const IconWrapper = styled.span`
  position: absolute;
  right: ${({ isRight }) => (isRight ? STYLE_CONSTS.UNIT_2 : "auto")};
  left: ${({ isLeft }) => (isLeft ? STYLE_CONSTS.UNIT_2 : "auto")};
  top: ${({ isSmall }) =>
    isSmall ? STYLE_CONSTS.UNIT_1 : STYLE_CONSTS.UNIT_2};
`;

export const Label = styled.label`
  font-size: ${STYLE_CONSTS.FONT_SMALL};
  display: ${({ isVisible }) => (isVisible ? "inline-block" : "none")};
  color: ${({ isLabelGrey, theme }) =>
    isLabelGrey ? theme.neutral.main : "inherit"};
`;

export const ErrorLabel = styled.div`
  font-size: ${STYLE_CONSTS.FONT_SMALL};
  line-height: ${ERROR_HEIGHT};
  color: ${({ theme }) => theme.error.main};
`;

export const CharLimit = styled.span`
  position: absolute;
  right: ${STYLE_CONSTS.UNIT_2};
  top: ${STYLE_CONSTS.UNIT_2};
`;

import styled, { keyframes, css } from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

// Safari browser requires load hack as it does not seem to
// support animate embedded in SVG, use keyframe instead
const spinLoaderInSafari = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`;

export const BlockWrapper = styled.div`
  ${({ center }) =>
    center &&
    css`
      text-align: center;
    `};
`;

export const LoadingIconWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  ${props =>
    props.implementSafariLoadHack &&
    css`
      animation: ${spinLoaderInSafari} 0.8s linear infinite;
    `};
`;
export const LoadingText = styled.div`
  display: ${props => (props.hideText ? css`none` : css`inline-block`)};
  height: 30px;
  color: ${({ color }) => color};
  font-size: ${STYLE_CONSTS.FONT_MEDIUM};
  margin-left: ${STYLE_CONSTS.UNIT_2};
`;

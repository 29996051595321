import React from "react";
import { isEqual } from "lodash";
import CalendarEvent from "../CalendarEvent";
import {
  HEADER_FOOTER_HEIGHT,
  CONTAINER_PADDING,
  ClusterContainer,
  Cluster,
  ClusterWrapper,
  ScrollView,
  ClusterEnd
} from "./styles";
import { getOverrideHeight } from "./helpers";

const PADDING = parseInt(CONTAINER_PADDING, 10);
const F_H_HEIGHT = parseInt(HEADER_FOOTER_HEIGHT, 10);

const CalendarCluster = ({ cluster, onEventClick }) => {
  const { position, events } = cluster;
  const height = position.bottom - position.top;
  const scrollViewHeight = height - F_H_HEIGHT - PADDING * 2;
  const overrideHeight = getOverrideHeight(cluster, scrollViewHeight);

  const handleClick = (e, event) => {
    e.preventDefault();
    e.stopPropagation();

    onEventClick(event);
  };

  return (
    <ClusterContainer
      top={position.top}
      left={position.left}
      width={position.width}
      height={height}
    >
      {events.length > 1 ? (
        <Cluster>
          <ClusterEnd>{events.length} Shifts</ClusterEnd>
          <ScrollView height={scrollViewHeight}>
            {events.map((event, i) => (
              <ClusterWrapper overrideHeight={overrideHeight} key={i}>
                <CalendarEvent
                  onClick={e => handleClick(e, event)}
                  event={event}
                />
              </ClusterWrapper>
            ))}
          </ScrollView>
        </Cluster>
      ) : (
        <CalendarEvent
          onClick={e => handleClick(e, events[0])}
          event={events[0]}
        />
      )}
    </ClusterContainer>
  );
};

export default React.memo(
  CalendarCluster,
  ({ cluster: prevCluster }, { cluster: nextCluster }) =>
    isEqual(prevCluster, nextCluster)
);

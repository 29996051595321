import { createSelector } from "reselect";
import getOr from "lodash/fp/getOr";
import find from "lodash/fp/find";
import { calculateTotals } from "../shared";

export const mapStateToProps = ({ calendar: { shiftForm, bonuses } }) => ({
  bonuses: getOr(
    [],
    "items",
    find({ shiftIndex: shiftForm.shiftOpenIndex }, bonuses)
  ),
  shift: shiftForm.shifts[shiftForm.shiftOpenIndex],
  shiftIndex: shiftForm.shiftOpenIndex,
  shiftForm
});

export const useTotals = createSelector(
  [
    props => props.shift,
    props => props.roleOptions,
    props => props.policy,
    props => props.isRotaProvided,
    props => props.bonuses,
    props => props.roleRates
  ],
  calculateTotals
);

import get from "lodash/fp/get";
import getOr from "lodash/fp/getOr";
import MoneyTransaction from "~/src/utils/money/money-transaction";
import BookingAccount from "../accounting";

class BookingManager {
  constructor(callerName, bookings, shift) {
    this.bookingIDs = bookings.map(({ id }) => id);
    this.callerName = callerName;

    this._bookings = bookings;
    this.bookingAccounts = bookings.map(
      booking =>
        new BookingAccount(
          booking.id,
          {
            ...getOr({}, "bookingState", booking),
            chargeRate: booking.chargeRate,
            isDayRate: booking.isDayRate
          },
          get("bonuses", shift?.shift)
        )
    );

    this._totalCost = new MoneyTransaction(
      `BookingManager totalCost ${this.bookingIDs}`
    );

    this.totalCostCalc = false;

    this._totalVAT = new MoneyTransaction(
      `BookingManager totalVAT ${this.bookingIDs}`
    );

    this.totalVATCalc = false;

    this._totalBonus = new MoneyTransaction(
      `BookingManager totalBonus ${this.bookingIDs}`
    );

    this.totalBonusCalc = false;
  }

  booking = id => this.bookingAccounts.find(item => item.bookingId === id);

  /**** Total Cost ******/

  get totalCost() {
    this._calcCost();

    return this._totalCost.current;
  }

  get totalCostWithBonus() {
    return this.totalCost.add(this.totalBonus);
  }

  _calcCost = () => {
    if (!this.totalCostCalc) {
      this.bookingAccounts.forEach(account => {
        this._totalCost.add(account.calcChargeRate().getCost());
      });
      this.totalCostCalc = true;
    }
  };

  get totalCostWithBonusAndVAT() {
    return this.totalCostWithBonus.add(this.totalVAT);
  }

  /**** Total VAT ******/

  get totalVAT() {
    this._calcVAT();

    return this._totalVAT.current;
  }

  _calcVAT() {
    if (!this.totalVATCalc) {
      const tempTrans = new MoneyTransaction("_calcVAT");

      const totalAmount = this.totalCostWithBonus;
      tempTrans.add(totalAmount).applyVAT();

      this._totalVAT.add(tempTrans.current).subtract(totalAmount);
      this.totalVATCalc = true;
    }
  }

  /**** Total Bonus ******/

  get totalBonus() {
    this._calcBonus();

    return this._totalBonus.current;
  }

  _calcBonus = () => {
    if (!this.totalBonusCalc) {
      this.bookingAccounts.forEach(account => {
        this._totalBonus.add(account.getBonus());
      });
      this.totalBonusCalc = true;
    }
  };

  get bonusVAT() {
    const tempTrans = new MoneyTransaction("bonusVAT");

    const totalAmount = this.totalBonus;
    tempTrans.add(totalAmount).applyVAT();

    return tempTrans.current.subtract(totalAmount);
  }
}

export default BookingManager;

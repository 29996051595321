import { RotaBadge, RotaTooltip } from "@teamrota/rota-design";
import styled from "styled-components";

export const Badge = styled(RotaBadge)`
  width: 18px;
  height: 18px;
`;

export const BadgeLabel = styled.div`
  font-size: 10px;
  font-weight: 400;
`;

export const AvailableTime = styled.p`
  padding-left: 5px;
  font-size: 10px;
  font-weight: 500;
  color: ${({ isNightShift, theme }) =>
    isNightShift ? theme.white : theme.text.primary};
`;

export const Tooltip = styled(RotaTooltip)`
  display: none;
  position: absolute;
  top: 25px;
  right: -100%;
  width: max-content;
  z-index: 1000;
  font-size: 14px;
  &::after {
    left: 85%;
  }
  &::before {
    border: none;
    left: 85%;
    border-radius: 0;
    border-left: ${({ theme }) => `1px solid ${theme.neutral.main}`};
    border-top: ${({ theme }) => `1px solid ${theme.neutral.main}`};
  }
`;

export const Container = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: 13px;
  right: 13px;
  cursor: pointer;
  &:hover ${Tooltip} {
    display: block;
  }
`;

import React, { Fragment, useState } from "react";
import moment from "moment-timezone";
import {
  RotaButton,
  RotaButtonIcon,
  RotaButtonCircle,
  RotaDropdown,
  RotaInput
} from "@teamrota/rota-design";
import Icon from "~/src/components/icon";
import {
  StyledWrapField,
  StyledLabel,
  StyledButtonIcon
} from "~/src/containers/requester-calendar/shift-modal/components/edit-shift/edit-shift.styles";
import {
  StyledBonusItemsToBeAdd,
  StyledBonus,
  StyledBonusList,
  StyledWrapperList,
  StyledWrapperTitleForm,
  StyledActionsButtons,
  StyledWrapperBonusList,
  StyledMainButton,
  StyledWrapperForm,
  StyledSmallFeature,
  StyledWrapperButtonAdd
} from "./bonus.styles";
import { getFormatterMoney } from "~/src/utils/formatting";
import { useCurrency } from "~/src/containers/profile-loader";

const getAmountFormatted = ({ amount, operationType = "divide" }) => {
  const base = 100;
  const calc = operationType === "multiply" ? a => a * base : a => a / base;
  return calc(amount);
};

const NewBonusRewardComponent = ({
  alreadyExistingBonuses,
  bonuses,
  onAddBonus,
  onDeleteBonus,
  formData
}) => {
  const { currencyCode } = useCurrency();

  const formatter = getFormatterMoney(currencyCode);
  const [isAllBonusesDisplayed, setIsAllBonusesDisplayed] = useState();
  const [bonusesInProgress, setBonusesInProgress] = useState([]);

  const endTime = moment(formData.endTime);
  const startTime = moment(formData.startTime);
  const duration = moment.duration(endTime.diff(startTime));
  const hourlyRate = duration.asHours();

  const options = [
    {
      value: 0,
      label: "hourly"
    },
    {
      value: 1,
      label: "fixed"
    }
  ];

  const getValueFromBonusTypeSelected = ({ bonus, options }) => {
    const optionSelected = options?.find(({ label }) => label === bonus.type);
    return optionSelected?.value;
  };

  const isBonusesEmpty = () => {
    if (
      alreadyExistingBonuses.length === 0 &&
      bonuses.length === 0 &&
      bonusesInProgress.length === 0
    )
      return true;
  };

  const isThereOneBonusAtLeast = () => {
    if (alreadyExistingBonuses.length >= 1) return true;
    if (bonuses.length >= 1) return true;
  };

  const addBonusesInProgress = () =>
    setBonusesInProgress([...bonusesInProgress, { type: "", amount: 0 }]);

  return (
    <Fragment>
      {isBonusesEmpty() ? (
        <StyledMainButton>
          <p>Add Bonus (100% goes to the member)</p>
          <RotaButtonCircle onHandle={addBonusesInProgress} />
        </StyledMainButton>
      ) : (
        <StyledBonus
          areThereBonusInProgress={
            isThereOneBonusAtLeast() && bonusesInProgress?.length >= 1
          }
        >
          <div>
            {isThereOneBonusAtLeast() && (
              <StyledWrapperButtonAdd>
                <RotaButtonCircle onHandle={addBonusesInProgress} />
              </StyledWrapperButtonAdd>
            )}
            {alreadyExistingBonuses.length >= 1 && (
              <StyledWrapperList
                isAllBonusesDisplayed={!!isAllBonusesDisplayed}
              >
                <StyledBonusList
                  isAllBonusesDisplayed={!!isAllBonusesDisplayed}
                >
                  {alreadyExistingBonuses.map((bonus, index, id) => {
                    return (
                      <li key={id}>
                        {isAllBonusesDisplayed && <span>{index + 1}</span>}
                        <small>
                          Bonus added:
                          {bonus.period === "hourly" &&
                            `(${hourlyRate} hrs x ${formatter.format(
                              getAmountFormatted({ amount: bonus.amount })
                            )})`}
                        </small>
                        {formatter.format(
                          getAmountFormatted({ amount: bonus.amount }) *
                            (bonus.period === "hourly" ? hourlyRate : 1)
                        )}
                      </li>
                    );
                  })}
                  {alreadyExistingBonuses.length > 1 && (
                    <StyledSmallFeature>
                      Total bonuses added:{" "}
                      <span>{alreadyExistingBonuses.length}</span>
                    </StyledSmallFeature>
                  )}
                </StyledBonusList>
                {alreadyExistingBonuses.length > 1 && (
                  <button
                    onClick={() =>
                      setIsAllBonusesDisplayed(!isAllBonusesDisplayed)
                    }
                  >
                    {isAllBonusesDisplayed
                      ? "show less bonuses"
                      : "show all bonuses"}
                  </button>
                )}
              </StyledWrapperList>
            )}
            <StyledWrapperBonusList
              areExistingBonusesDisplayed={
                alreadyExistingBonuses.length >= 1 && bonuses.length >= 1
              }
            >
              <StyledBonusList isAllBonusesDisplayed isBonusesToBeAdded="true">
                {bonuses.map((bonus, index) => {
                  return (
                    <li key={index}>
                      <div>
                        <small>
                          Bonus added:
                          {bonus.type === "hourly" &&
                            `(${hourlyRate} hrs x ${formatter.format(
                              getAmountFormatted({ amount: bonus.amount })
                            )})`}
                        </small>
                        {formatter.format(
                          getAmountFormatted({ amount: bonus.amount }) *
                            (bonus.type === "hourly" ? hourlyRate : 1)
                        )}
                      </div>
                      <StyledActionsButtons>
                        <RotaButtonIcon
                          icon="edit"
                          onClick={() => {
                            setBonusesInProgress([
                              ...bonusesInProgress,
                              {
                                ...bonus,
                                amount: getAmountFormatted({
                                  amount: bonus.amount
                                }),
                                isEdit: true,
                                lastAmountConfirmed: bonus.amount,
                                lastTypeConfirmed: bonus.type
                              }
                            ]);
                            onDeleteBonus(index);
                          }}
                        />
                        <RotaButtonIcon
                          icon="delete"
                          onClick={() => onDeleteBonus(index)}
                        />
                      </StyledActionsButtons>
                    </li>
                  );
                })}
              </StyledBonusList>
            </StyledWrapperBonusList>
          </div>
          {bonusesInProgress?.map((bonus, index) => {
            const removingCurrentBonus = bonusesInProgress?.filter(
              (_, indexBonusInProgress) => indexBonusInProgress !== index
            );

            return (
              <div key={index}>
                <StyledWrapperTitleForm
                  areThereBonusInProgress={bonusesInProgress.length > 1}
                >
                  Add Bonus (100% goes to the member)
                  <StyledButtonIcon
                    onClick={() => {
                      setBonusesInProgress(removingCurrentBonus);
                      if (bonus.isEdit) {
                        onAddBonus({
                          type: bonus.lastTypeConfirmed,
                          amount: bonus.lastAmountConfirmed,
                          withConfirm: false
                        });
                      }
                    }}
                  >
                    <Icon name="CLOSE" color="white" size="10" />
                  </StyledButtonIcon>
                </StyledWrapperTitleForm>
                <Fragment>
                  <StyledWrapperForm>
                    <StyledWrapField>
                      <StyledLabel htmlFor="bonusType">
                        Choose type of bonus
                      </StyledLabel>
                      <RotaDropdown
                        value={getValueFromBonusTypeSelected({
                          bonus,
                          options
                        })}
                        placeholder="select bonus type"
                        options={options}
                        onChange={option => {
                          const type = options[option].label;
                          setBonusesInProgress(
                            bonusesInProgress.map(
                              (bonus, indexBonusInProgress) => {
                                if (indexBonusInProgress === index)
                                  return { ...bonus, type };
                                return bonus;
                              }
                            )
                          );
                        }}
                      />
                    </StyledWrapField>
                    <StyledWrapField>
                      <StyledLabel htmlFor="amount">Amount</StyledLabel>
                      <RotaInput
                        type="number"
                        value={bonus.amount}
                        onChange={e =>
                          setBonusesInProgress(
                            bonusesInProgress.map(
                              (bonus, indexBonusInProgress) => {
                                if (indexBonusInProgress === index)
                                  return {
                                    ...bonus,
                                    amount: Number(e.target.value)
                                  };
                                return bonus;
                              }
                            )
                          )
                        }
                      />
                    </StyledWrapField>
                  </StyledWrapperForm>
                  <StyledBonusItemsToBeAdd>
                    <li>
                      <small>Amount to be added:</small>

                      {bonus.type === "hourly" && (
                        <Fragment>
                          <small>
                            ({hourlyRate} hrs x {formatter.format(bonus.amount)}
                            )
                          </small>
                          {formatter.format(hourlyRate * bonus.amount)}
                        </Fragment>
                      )}
                      {bonus.type === "fixed" && (
                        <span>{formatter.format(bonus.amount)}</span>
                      )}
                    </li>

                    <RotaButton
                      size="small"
                      onClick={async () => {
                        setBonusesInProgress(removingCurrentBonus);
                        await onAddBonus({
                          type: bonus.type,
                          amount: getAmountFormatted({
                            amount: bonus.amount,
                            operationType: "multiply"
                          })
                        });
                      }}
                    >
                      {bonus.isEdit ? "Save" : "Add"}
                    </RotaButton>
                  </StyledBonusItemsToBeAdd>
                </Fragment>
              </div>
            );
          })}
        </StyledBonus>
      )}
    </Fragment>
  );
};

export default NewBonusRewardComponent;

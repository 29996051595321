import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";

const GET_ROLES = gql`
  query getRoles {
    account {
      id
      roles {
        id
        name
        defaultPayRate
        defaultChargeRate
        internalId
        description
        isDayRate
        isOvertimeRate
        defaultOvertimePayRate
        defaultOvertimeChargeRate
        defaultOvertimeHoursLimit
        isOverstayRate
        defaultOverstayPayRate
        defaultOverstayChargeRate
        defaultOverstayHoursLimit
        isUncalculatedRate
      }
    }
  }
`;

export const useRoles = () => {
  const { data, loading, error, refetch } = useQuery(GET_ROLES, {
    fetchPolicy: "cache-and-network",
    onError: error => {
      throw error;
    }
  });

  const roles = data?.account?.roles || [];
  const accountId = data?.account.id;
  const calculatedRoles = roles.filter(role => !role.isUncalculatedRate);
  const uncalculatedRoles = roles.filter(role => role.isUncalculatedRate);

  return {
    accountId,
    roles,
    calculatedRoles,
    uncalculatedRoles,
    loading,
    error,
    refetch
  };
};

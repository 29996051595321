import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const ExpandableSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto !important;
  text-align: center;
  opacity: 0.7;
  background-color: ${({ theme }) => theme.background.secondary};
  padding: ${STYLE_CONSTS.UNIT_8};
  > div {
    margin: 0 auto;
  }
`;

import moment from "moment-timezone";
import flow from "lodash/fp/flow";
import set from "lodash/fp/set";
import assign from "lodash/fp/assign";
import concat from "lodash/fp/concat";
import omit from "lodash/fp/omit";
import cloneDeep from "lodash/cloneDeep";
import { ZonedDate } from "@teamrota/rota-common";

import { BONUS_TYPES, SHIFT_TYPES } from "~/src/consts";
import { createReducer, createAction } from "~/src/utils";
import {
  conditinallyRunPropSync,
  setAllErrorsVisible,
  checkIfBonusExists,
  getTotalBonuses
} from "./utils";

export const POST_SHIFT = "scheduler/POST_SHIFT";
export const CANCEL_POST_SHIFT = "scheduler/CANCEL_POST_SHIFT";
export const UPDATE_FORM = "scheduler/UPDATE_FORM";
export const ADD_SHIFT = "scheduler/ADD_SHIFT";
export const REMOVE_SHIFT = "scheduler/REMOVE_SHIFT";
export const UPDATE_BONUS = "scheduler/UPDATE_BONUS";
export const ADD_BONUS = "scheduler/ADD_BONUS";
export const REMOVE_BONUS = "scheduler/REMOVE_BONUS";
export const UPDATE_BONUS_TYPE = "scheduler/UPDATE_BONUS_TYPE";
export const UPDATE_BONUS_AMOUNT = "scheduler/UPDATE_BONUS_AMOUNT";
export const CLEAR_BONUS_STATE = "scheduler/CLEAR_BONUS_STATE";
export const UPDATE_TOTAL_BONUS = "scheduler/UPDATE_TOTAL_BONUS";
export const UPDATE_BONUS_WITH_TOTAL = "scheduler/UPDATE_BONUS_WITH_TOTAL";
export const TOGGLE_CANCELLED_SHIFTS = "scheduler/TOGGLE_CANCELLED_SHIFTS";
export const COUNT_DRAFT_SHIFTS = "scheduler/COUNT_DRAFT_SHIFTS";
export const SET_DRAFT_SHIFT_IDS = "scheduler/SET_DRAFT_SHIFT_IDS";

export const cancelPostShift = createAction(CANCEL_POST_SHIFT);
export const updateShiftForm = createAction(UPDATE_FORM);
export const addShift = createAction(ADD_SHIFT);
export const removeShift = createAction(REMOVE_SHIFT);
export const clearBonusState = createAction(CLEAR_BONUS_STATE);
export const updateBonus = createAction(UPDATE_BONUS);
export const addBonus = createAction(ADD_BONUS);
export const removeBonus = createAction(REMOVE_BONUS);
export const updateBonusType = createAction(UPDATE_BONUS_TYPE);
export const updateBonusAmount = createAction(UPDATE_BONUS_AMOUNT);
export const updateTotalBonus = createAction(UPDATE_TOTAL_BONUS);
export const updateBonusWithTotal = createAction(UPDATE_BONUS_WITH_TOTAL);
export const toggleCancelledShifts = createAction(TOGGLE_CANCELLED_SHIFTS);
export const countDraftShifts = createAction(COUNT_DRAFT_SHIFTS);
export const setDraftShiftIds = createAction(SET_DRAFT_SHIFT_IDS);

const getTomorrowMoment = () =>
  moment()
    .set({ second: 0, millisecond: 0 })
    .add(1, "days")
    .add(15, "minute");

const getShiftType = ({ startTime, endTime }) => {
  //shift starts at or after 6pm or shift crosses midnight
  if (
    moment(startTime).hour() >= 18 ||
    moment(startTime).day() !== moment(endTime).day()
  )
    return SHIFT_TYPES.NIGHT;

  return SHIFT_TYPES.DAY;
};

export const postShift = createAction(POST_SHIFT, config => {
  const startTime =
    config?.startTime ||
    getTomorrowMoment()
      .clone()
      .toDate();
  let endTime =
    config?.endTime ||
    getTomorrowMoment()
      .clone()
      .endOf("day")
      .toDate();

  const duration = moment.duration(moment(endTime).diff(moment(startTime)));

  // Don't allow shifts to be longer than 24 hours
  if (duration.asHours() > 24) {
    endTime = moment(startTime)
      .clone()
      .add(1, "days");
  }

  return {
    times: {
      [moment(startTime).format("DD/MM/YYYY")]: {
        startTime,
        endTime,
        breakMinutes: config?.defaultBreakMinutes || 0
      }
    },
    isGridSelectedShift: config?.isGridSelectedShift || false,
    uniformTemplateId: config?.uniformTemplateId,
    venueId: config?.venueId,
    subvenueId: config?.subvenueId,
    briefing: config?.briefing,
    assignedMemberIds: config?.assignedMemberIds || [],
    assignedMemberName: config?.assignedMemberName || "",
    assignedMemberRoleIds: config?.assignedMemberRoleIds || [],
    memberType: config?.memberType || null,
    startTime,
    endTime,
    type: getShiftType({ startTime, endTime })
  };
});

export const DEFAULT_SHIFT = {
  times: {},
  numberRequested: 1,
  roleRateId: null,
  uniformTemplateId: null,
  venueId: null,
  subvenueId: null,
  isSleepTimesEnabled: false,
  selectedSleepTimes: {
    startTime: moment()
      .month(0)
      .date(1)
      .hour(23)
      .minute(0)
      .second(0),
    endTime: moment()
      .month(0)
      .date(1)
      .hour(7)
      .minute(0)
      .second(0)
      .add(1, "days")
  },
  briefing: "",
  name: "",
  assignedMemberIds: [],
  assignedMemberRoleIds: [],
  requestedMemberIds: [],
  assignedMemberName: "",
  memberType: null,
  identifier: "",
  isShiftRebookEnabled: false,
  isShiftRebookRestrictedToVenue: false,
  // Props not to be saved to the database
  privates: {
    roleRateAccount: null,
    shouldShowErrors: false,
    fixedLabel: ""
  },
  algoSkew: 100,
  tags: [],
  isPartnerManaged: false,
  isDraft: true,
  skipAlgoShift: false,
  delayHoursAlgoShift: 0,
  isLinkedShifts: false,
  isGridSelectedShift: false,
  type: ""
};

export const DEFAULT_STATE = {
  initialPostStart: null,
  initialPostEnd: null,
  searchText: "",
  shiftForm: {
    shiftOpenIndex: 0,
    shifts: [{ ...DEFAULT_SHIFT }]
  },
  bonuses: [],
  totalBonuses: [],
  showCancelledShifts: true,
  countOfDraftShifts: 0,
  draftShiftIds: []
};

const reducer = createReducer(
  {
    [UPDATE_TOTAL_BONUS]: (state, { payload }) => ({
      ...state,
      totalBonuses: getTotalBonuses(state, payload.shiftIndex)
    }),
    [CLEAR_BONUS_STATE]: state => ({
      ...state,
      bonuses: [],
      totalBonuses: []
    }),
    [UPDATE_BONUS_AMOUNT]: (state, { payload }) => ({
      ...state,
      bonuses: state.bonuses.map(bonus =>
        bonus.shiftIndex === payload.shiftIndex
          ? {
              ...bonus,
              items: bonus.items.map((item, index) =>
                index === payload.index
                  ? { ...item, amount: payload.amount }
                  : item
              )
            }
          : bonus
      )
    }),

    [UPDATE_BONUS_WITH_TOTAL]: (state, { payload }) => {
      const { shiftIndex, index, ...updatedValues } = payload;

      const bonuses = state.bonuses.map(bonus =>
        bonus.shiftIndex === shiftIndex
          ? {
              ...bonus,
              items: bonus.items.map((item, i) =>
                i === index ? { ...item, ...updatedValues } : item
              )
            }
          : bonus
      );

      const totalBonuses = getTotalBonuses(
        {
          ...state,
          bonuses
        },
        shiftIndex
      );

      return {
        ...state,
        bonuses,
        totalBonuses
      };
    },
    [UPDATE_BONUS_TYPE]: (state, { payload }) => ({
      ...state,
      bonuses: state.bonuses.map(bonus =>
        bonus.shiftIndex === payload.shiftIndex
          ? {
              ...bonus,
              items: bonus.items.map((item, index) =>
                index === payload.index ? { ...item, type: payload.type } : item
              )
            }
          : bonus
      )
    }),
    [REMOVE_BONUS]: (state, { payload }) => {
      const { shiftIndex } = payload;
      const bonuses = state.bonuses.map(bonus =>
        bonus.shiftIndex === shiftIndex
          ? {
              ...bonus,
              items: bonus.items.filter(
                (item, index) => index !== payload.index
              )
            }
          : bonus
      );

      const totalBonuses = getTotalBonuses(
        {
          ...state,
          bonuses
        },
        shiftIndex
      );

      return {
        ...state,
        bonuses,
        totalBonuses
      };
    },
    [ADD_BONUS]: (state, { payload }) => ({
      ...state,
      bonuses:
        state.bonuses.length === 0 ||
        !checkIfBonusExists(payload, state.bonuses)
          ? [
              ...state.bonuses,
              {
                shiftIndex: payload,
                items: [
                  {
                    type: BONUS_TYPES.HOURLY,
                    amount: 0,
                    id: new ZonedDate().getTime()
                  }
                ]
              }
            ]
          : state.bonuses.map(bonus =>
              bonus.shiftIndex === payload
                ? {
                    ...bonus,
                    items: [
                      ...bonus.items,
                      {
                        type: BONUS_TYPES.HOURLY,
                        amount: 0,
                        id: new ZonedDate().getTime()
                      }
                    ]
                  }
                : bonus
            )
    }),

    [UPDATE_FORM]: (state, { payload }) => {
      return flow(
        set("shiftForm", assign(state.shiftForm, payload)),
        set(
          "shiftForm.shifts",
          conditinallyRunPropSync(
            payload.shifts || state.shiftForm.shifts,
            payload.isLinkedShifts || state.shiftForm.isLinkedShifts
          )
        )
      )(state);
    },

    [REMOVE_SHIFT]: (state, { payload }) =>
      set(`shiftForm.shifts[${payload}].isDeleted`, true, state),

    [ADD_SHIFT]: (state, { payload }) => {
      const updatedState = set(
        "shiftForm.shifts",
        setAllErrorsVisible(state.shiftForm.shifts),
        state
      );

      const shiftToCopy = omit(
        ["name", "identifier", "times"],
        state.shiftForm.shifts[state.shiftForm.shiftOpenIndex]
      );

      const updated = flow(
        set("shiftForm.shiftOpenIndex", state.shiftForm.shifts.length),
        set(
          "shiftForm.shifts",
          concat(
            state.shiftForm.shifts,
            assign(DEFAULT_SHIFT, cloneDeep(shiftToCopy))
          )
        )
      )(updatedState);

      return updated;
    },

    [POST_SHIFT]: (state, { payload }) =>
      set("shiftForm.shifts", [
        {
          ...DEFAULT_SHIFT,
          uniformTemplateId:
            payload.uniformTemplateId || DEFAULT_SHIFT.uniformTemplateId,
          venueId: payload.venueId || DEFAULT_SHIFT.venueId,
          subvenueId: payload.subvenueId || DEFAULT_SHIFT.subvenueId,
          briefing: payload.briefing || DEFAULT_SHIFT.briefing,
          times: payload.times,
          assignedMemberIds: payload.assignedMemberIds,
          assignedMemberRoleIds: payload.assignedMemberRoleIds,
          assignedMemberName: payload.assignedMemberName,
          memberType: payload.memberType,
          type: payload.type
        }
      ])({
        ...state,
        isPostingShift: true,
        isDetailMode: !payload.isGridSelectedShift,
        initialPostStart: payload.startTime,
        initialPostEnd: payload.endTime,
        isGridSelectedShift: payload.isGridSelectedShift || false,
        searchText: ""
      }),

    [CANCEL_POST_SHIFT]: state => ({
      ...state,
      isPostingShift: false,
      initialPostStart: null,
      initialPostEnd: null,
      shiftForm: {
        ...DEFAULT_STATE.shiftForm
      },
      searchText: "",
      isGridSelectedShift: false
    }),

    [COUNT_DRAFT_SHIFTS]: (state, { payload }) => ({
      ...state,
      countOfDraftShifts: payload
    }),

    [TOGGLE_CANCELLED_SHIFTS]: state => ({
      ...state,
      showCancelledShifts: !state.showCancelledShifts
    }),

    [SET_DRAFT_SHIFT_IDS]: (state, { payload }) => ({
      ...state,
      draftShiftIds: payload || []
    })
  },
  DEFAULT_STATE
);

export default reducer;

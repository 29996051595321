import { gql } from "@apollo/client";

import { addAuthVars } from "@teamrota/authlib";

import ShiftFragment from "~/src/graphql/fragments/shift/shift.fragment";
import { TimesheetBookingFragment } from "./get-timesheet-bookings";

// Return a list of time sheet printables based on the date provided
export const GET_TIMESHEET_SHIFTS = addAuthVars(gql`
  ${ShiftFragment}
  ${TimesheetBookingFragment}
  query getTimesheetShifts(
    $startDate: Date!
    $endDate: Date!
    $limit: Int!
    $offset: Int!
    $sourceAccountId: ID
    $isTimesheetPage: Boolean
  ) {
    account {
      id
      logo
      shifts(
        startTimeRangeStart: $startDate
        startTimeRangeEnd: $endDate
        limit: $limit
        offset: $offset
        isTimesheetPage: $isTimesheetPage
      ) {
        data {
          ...ShiftFragment

          bonuses(type: "bonus", isActive: true) {
            id
            type: period
            amount
          }

          bookings(
            stateIn: [ACCEPTED, NO_SHOW, SHIFT_CANCELLED, TURNED_AWAY]
            limit: $limit
            offset: $offset
          ) {
            data {
              ...TimesheetBookingFragment
            }
          }
        }

        totalResults
        limit
        offset
      }
    }
  }
`);

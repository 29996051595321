import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

// 100% / 7 (as there's 7 days in a week)
const dayWidth = `14.28571%`;

export const Main = styled.div`
  max-width: 100%;
  user-select: none;
`;

export const Header = styled.div`
  display: flex;
  padding-bottom: ${STYLE_CONSTS.UNIT_1};
  border-bottom: ${STYLE_CONSTS.SMALL_DIVIDER_BORDER_LIGHT};
`;

export const Title = styled.h2`
  flex: 1;
  margin: 0;
  text-align: center;
`;

export const Month = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const DayName = styled.p`
  width: ${dayWidth};
  flex: none;
  text-align: center;
  font-weight: bold;
`;

export const Day = styled.div`
  position: relative;
  flex: none;
  width: ${dayWidth};

  ${({ isDisabled, isSelected, isGreyed, theme }) => {
    if (isSelected)
      return `
        cursor: pointer;
        background: ${theme.primary.main};
        color: ${theme.white};
      `;

    if (isDisabled && isGreyed)
      return `
      color: ${theme.neutral.light};
        background: ${theme.neutral.lighter};
        pointer-events: none;
      `;

    if (isDisabled)
      return `
        background: ${theme.neutral.lighter};
        pointer-events: none;
      `;

    if (isGreyed)
      return `
        cursor: pointer;
        color: ${theme.neutral.light};
      `;

    return `
      cursor: pointer;
    `;
  }}

  &::before {
    content: "";
    padding-top: 100%;
    display: block;
  }
`;

export const DayContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  padding: ${STYLE_CONSTS.UNIT_1};
`;

export const ArrowButton = styled.button`
  appearance: none;
  padding: 0 ${STYLE_CONSTS.UNIT_1};
  margin: 0;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
`;

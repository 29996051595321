import styled from "styled-components";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";

export const TagsWrapper = styled.div`
  margin-top: 5px;
  z-index: 0;
  ${props =>
    props.isSingleLine &&
    `width: 60%;
    display: inline-block;
    margin-top: -10px;`}
`;

export const TagItem = styled.div`
  display: inline-block;
  background: ${props =>
    props.disabled ? COLORS.CLOUDY_BLUE : STYLE_CONSTS.BACKGROUND_GRADIENT};
  border-radius: ${STYLE_CONSTS.BTN_HEIGHT_M};
  padding: ${STYLE_CONSTS.UNIT_1};
  color: ${({ theme }) => theme.white};
  margin-right: ${STYLE_CONSTS.UNIT_1};
  font-size: 12px;
`;

export const DeleteButton = styled.button`
  margin: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
  padding: 0;
  padding-left: ${STYLE_CONSTS.UNIT_1};
  vertical-align: middle;
`;

import styled from "styled-components";
import { COLORS, STYLE_CONSTS } from "~/src/styles/config.style";

export const PHOTO_SIZE = "100px";
const TRAY_SIZE_DROPDOWN = "170px";
const TRAY_POSITION = "20px";

export const DropDownContainer = styled.div`
  display: inline-block;
  position: relative;
`;

export const UploadImageDropDownMenu = styled.div`
  height: 100px;
  width: 100px;
  z-index: 100;
  position: absolute;
  text-align: right;
  color: ${COLORS.CLOUDY_BLUE};
  & > #nav-tray-show-hide {
    display: none;
    transition: all 1s ease-in;
  }
  &:hover > #nav-tray-show-hide {
    display: block;
    z-index: 100;
    width: 130px;
  }
`;

export const NavTray = styled.div`
  box-shadow: ${STYLE_CONSTS.FLOATING};
  width: ${TRAY_SIZE_DROPDOWN};
  text-align: left;
  color: ${({ theme }) => theme.text.primary};
  background-color: ${COLORS.PALE_GREY};
  padding: ${STYLE_CONSTS.UNIT_1};
  position: absolute;
  top: ${TRAY_POSITION};
  line-height: ${STYLE_CONSTS.FONT_LARGE};
  font-size: ${STYLE_CONSTS.FONT_SMALL};
`;

export const NavTitle = styled.p`
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.primary.main};
  }
`;

export const NavLabel = styled.label`
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.primary.main};
  }
`;

import styled from "styled-components";

export const SubvenuesContainer = styled.div``;

// DeleteModalText
// DeleteModalButtonsContainer

export const DeleteModalText = styled.div`
  margin-bottom: 24px;
`;

export const DeleteModalButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const SubvenuesHeader = styled.div`
  font-weight: 400;
  font-size: large;
  margin-bottom: 24px;
`;
export const SubvenuesList = styled.div`
  margin-bottom: 24px;
`;

export const StyleSubvenueCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  margin: 0 0 12px 0;
  border-radius: 4px;
  height: 40px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
`;

export const SubvenueCardName = styled.div`
  font-weight: 400;
  margin: 0 10px;
`;
export const SubvenueCardDetails = styled.div`
  display: flex;
  justify-content: flex-start;
  width: fit-content;
  color: ${({ theme }) => theme.neutral.main};
  overflow: hidden;
  max-width: 80%;

  & > * {
    margin-right: 10px;
  }
`;

export const SubvenueCardButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > * {
    margin: 0 0 0 4px;
  }
`;

export const SubvenuesModalButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 24px;
`;

import React from "react";
import Icon from "~/src/components/icon";
import {
  LoadingIconWrapper,
  LoadingText,
  BlockWrapper
} from "./loading.styles";

const Loading = ({
  color,
  alignCenter,
  implementSafariLoadHack,
  done,
  hideText
}) => {
  const chosenColor = color || Icon.colors.WHITE;
  return (
    <BlockWrapper center={alignCenter}>
      <LoadingIconWrapper
        implementSafariLoadHack={implementSafariLoadHack}
        test={implementSafariLoadHack}
      >
        <Icon
          name={done ? Icon.names.DONE : Icon.names.LOADING}
          color={chosenColor}
        />
      </LoadingIconWrapper>
      <LoadingText hideText={hideText} color={chosenColor}>
        {done ? "Done!" : "Loading..."}
      </LoadingText>
    </BlockWrapper>
  );
};

export default Loading;

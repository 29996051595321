import React from "react";
import {
  FooterLegendContainer,
  ItemContainer,
  LegendIcon,
  Label
} from "./styles";
import Icon from "~/src/components/icon";

const LEGEND_ITEMS = [
  {
    label: "Linked shift",
    icon: Icon.names.LINKED_SHIFTS
  },
  {
    label: "Self managed shift",
    icon: Icon.names.SELF_MANAGED
  }
];

export const FooterLegend = () => (
  <FooterLegendContainer>
    {LEGEND_ITEMS.map((item, index) => (
      <ItemContainer key={index}>
        <LegendIcon name={item.icon} />
        <Label>{item.label}</Label>
      </ItemContainer>
    ))}
  </FooterLegendContainer>
);

export default FooterLegend;

import styled from "styled-components";

const StyledAdminDashboard = styled.section`
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 40px;
  height: calc(100vh - 160px);
  padding: 50px 50px 0;

  @media only screen and (max-width: 1480px) {
    gap: 25px;
    grid-template-columns: 250px 1fr;
    padding: 50px 35px 0;
  }

  aside,
  & > div {
    background-color: white;
    border-radius: 10px;
  }

  & > div {
    padding: 40px;
  }

  h2 {
    padding: 20px;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      padding: 20px;
      border-bottom: 1px solid ${({ theme }) => theme.neutral.light};
    }
  }

  aside ul {
    a,
    a:link {
      color: ${({ theme }) => theme.neutral.main};
      text-decoration: none;
      &.active {
        color: ${({ theme }) => theme.primary.main};
      }
    }
  }
`;

export default StyledAdminDashboard;

import { gql } from "@apollo/client";
import { addAuthVars } from "@teamrota/authlib";

import MemberFragment from "~/src/graphql/fragments/member/member.fragment";

export const GET_MEMBER_POOLS = addAuthVars(gql`
  ${MemberFragment}

  query getMemberPools(
    $offset: Int!
    $limit: Int!
    $searchText: String!
    $poolTypes: [poolTypeEnumType]!
    $shiftId: ID
    $targetAccountId: ID
    $selectedAccountId: ID
    $dates: [DateRangeType]!
    $shiftType: ShiftTypeEnumType
    $hasRoleRateId: ID
    $roleRateId: ID!
    $memberType: String
    $venueId: ID!
    $tagIds: [ID]
  ) {
    account {
      id
      accountName
      memberPools(
        poolTypes: $poolTypes
        targetAccountId: $targetAccountId
        selectedAccountId: $selectedAccountId
        roleRateId: $hasRoleRateId
        venueId: $venueId
        memberType: $memberType
        searchText: $searchText
        offset: $offset
        limit: $limit
      ) {
        totalResults
        data {
          ...MemberFragment
          isQualifiedWithRole(roleRateId: $roleRateId, shiftId: $shiftId)
          isQualifiedWithTags(shiftId: $shiftId, tagIds: $tagIds)
          isInVenueServiceArea(venueId: $venueId)
          isConflicted: isConflictedWithDates(shiftId: $shiftId, dates: $dates)
          isUnavailable: isUnavailableWithDates(
            shiftId: $shiftId
            dates: $dates
            shiftType: $shiftType
          )
          isHitWorkingHoursLimit(shiftId: $shiftId, dates: $dates)
          isLocationConflict: isConflictedWithShift(
            shiftId: $shiftId
            dates: $dates
            venueId: $venueId
          )

          memberAvailability(dates: $dates) {
            startsAt
            endsAt
            shiftType
          }
        }
      }
    }
  }
`);

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import moment from "moment-timezone";

import { handleErrors } from "~/src/utils/errors";

export const GET_UNASSIGNED = gql`
  query getUnassigned($startTime: Date!, $endTime: Date!) {
    account {
      id
      schedule(startTime: $startTime, endTime: $endTime) {
        days
        unassignedShifts {
          date
          shifts {
            id
          }
        }
      }
    }
  }
`;

export default graphql(GET_UNASSIGNED, {
  props: handleErrors(({ data: { refetch, account } }) => ({
    refetchSchedules: refetch,
    schedule: account?.schedule
  })),
  options: props => ({
    variables: {
      startTime: moment.utc(props?.startTime).format(),
      endTime: moment
        .utc(props?.endTime)
        .endOf("day")
        .format()
    }
  })
});

import React, { useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import { Role } from "@teamrota/authlib";

import {
  RotaBox,
  RotaButtonNew,
  RotaSearchInput,
  RotaButtonIcon,
  iconNew
} from "@teamrota/rota-design";

import HasRole from "~/src/containers/has-role";

import {
  Wrapper,
  HeaderWrapper,
  Box,
  Heading,
  Title,
  Actions
} from "~/src/components/payroll/style";

import calculationsImage from "../../images/payroll/calculations.png";
import payScalesImage from "../../images/payroll/pay-scales.png";
import ratesImage from "../../images/payroll/rates.png";
import rulesImage from "../../images/payroll/rules.png";
import breaksImage from "../../images/payroll/breaks.png";

import { usePayrollRateCards } from "./graphql/use-payroll-rate-cards";

import CreateRateModal from "./components/CreateRateModal";

import HistoryModal from "./components/HistoryModal";
import RulesInfoModal from "./components/RulesInfoModal";
import RateCardModal from "./components/RateCardModal";
import SimulatorModal from "./components/SimulatorModal";

import Table from "./Table";

const { Filter } = iconNew;
const subpages = [
  {
    route: "pay-rate",
    label: "Pay rates",
    image: ratesImage
  },
  {
    route: "calculations",
    label: "Calculations",
    image: calculationsImage
  },
  {
    route: "pay-scales",
    label: "Pay scales",
    image: payScalesImage
  },
  {
    route: "rules",
    label: "Rules",
    image: rulesImage
  },
  {
    route: "breaks",
    label: "Breaks",
    image: breaksImage
  }
];

const SubpagesWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin: 8px 32px;
`;

const SubpageBox = styled(RotaBox)`
  cursor: pointer;
  padding: 0px;
  overflow: hidden;
`;

const SubpageTitle = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 8px;
`;

const SubpageContentWrapper = styled.div`
  padding: 16px;
`;

export const Image = styled.span<{ src: string }>`
  display: inline-block;
  width: 100%;
  height: 100px;
  background-size: cover;
  background-image: ${({ src }) => `url(${src})`};
`;

const Payroll = () => {
  const { payrollRateCards } = usePayrollRateCards("");

  const [isShowCreateModal, setIsShowCreateModal] = useState(false);

  const [isShowRateCardModal, setIsShowRateCardModal] = useState("");
  const [isShowRulesInfoModal, setIsShowRulesInfoModal] = useState("");
  const [isShowSimulatorModal, setIsShowSimulatorModal] = useState("");
  const [isShowHistoryModal, setIsShowHistoryModal] = useState("");

  const history = useHistory();

  return (
    <Wrapper>
      <HeaderWrapper>
        <RotaSearchInput onChange={() => {}} />
        <HasRole role={Role.PAYROLL_EDIT}>
          <RotaButtonNew onClick={() => setIsShowCreateModal(true)}>
            Create rate card
          </RotaButtonNew>
        </HasRole>
      </HeaderWrapper>
      <SubpagesWrapper>
        {subpages.map(page => {
          return (
            <SubpageBox onClick={() => history.push(`/payroll/${page.route}`)}>
              <Image src={page.image} />
              <SubpageContentWrapper>
                <SubpageTitle>{page.label}</SubpageTitle>
              </SubpageContentWrapper>
            </SubpageBox>
          );
        })}
      </SubpagesWrapper>

      <Box>
        <Heading>
          <Title>{`All ${payrollRateCards?.length ?? 0} records`}</Title>
          <Actions>
            <RotaButtonIcon onClick={() => {}}>
              <Filter />
            </RotaButtonIcon>
          </Actions>
        </Heading>

        <Table
          payrollRateCards={payrollRateCards}
          showSimulatorModal={setIsShowSimulatorModal}
          showHistoryModal={setIsShowHistoryModal}
          showRateCardModal={setIsShowRateCardModal}
          showRulesInfoModal={setIsShowRulesInfoModal}
        />
      </Box>

      {isShowCreateModal && (
        <CreateRateModal onClose={() => setIsShowCreateModal(false)} />
      )}

      {isShowSimulatorModal && (
        <SimulatorModal
          id={isShowSimulatorModal}
          payrollRateCards={payrollRateCards}
          onClose={() => setIsShowSimulatorModal("")}
        />
      )}

      {isShowHistoryModal && (
        <HistoryModal
          id={isShowHistoryModal}
          payrollRateCards={payrollRateCards}
          onClose={() => setIsShowHistoryModal("")}
        />
      )}

      {isShowRateCardModal && (
        <RateCardModal
          id={isShowRateCardModal}
          payrollRateCards={payrollRateCards}
          onClose={() => setIsShowRateCardModal("")}
        />
      )}

      {isShowRulesInfoModal && (
        <RulesInfoModal
          id={isShowRulesInfoModal}
          payrollRateCards={payrollRateCards}
          onClose={() => setIsShowRulesInfoModal("")}
        />
      )}
    </Wrapper>
  );
};

export default Payroll;

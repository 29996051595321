import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";
import Icon from "../icon";

const centeredFlexbox = {
  height: "inherit",
  display: "flex",
  justifyItems: "center",
  alignItems: "center"
};

const styles = {
  centeredFlexbox,
  centeredFlexboxIconLeft: {
    ...centeredFlexbox,
    marginRight: 5
  },
  centeredFlexboxIconRight: {
    ...centeredFlexbox,
    marginLeft: 5
  }
};

export default styles;

const ICON_Y =
  parseInt(STYLE_CONSTS.BTN_HEIGHT_M, 10) / 2 - parseInt(STYLE_CONSTS.FONT, 10);

export const ButtonComponent = styled.button`
display: inline-flex;
align-items: center;
justify-content: center;
  transition: all 0.4 ease;
  background: ${({
    isOutline,
    isBorderless,
    isLoading,
    isDisabled,
    isGrey,
    isGreen,
    isRed,
    background,
    theme
  }) => {
    if (background) return background;
    if (isOutline || isBorderless) return "transparent";
    if (isGrey || isLoading || isDisabled) return theme.neutral.main;
    if (isGreen) return theme.success.main;
    if (isRed) return theme.error.main;
    return STYLE_CONSTS.BACKGROUND_GRADIENT;
  }};
  width: ${({ isBlock, isIconOnly, isXSmall, isSmall, isLarge, btnWidth }) => {
    if (btnWidth) return btnWidth;
    if (isBlock) return "100%";
    if (isIconOnly) {
      if (isXSmall) return STYLE_CONSTS.BTN_HEIGHT_XS;
      if (isSmall) return STYLE_CONSTS.BTN_HEIGHT_S;
      if (isLarge) return STYLE_CONSTS.BTN_HEIGHT_L;
      return STYLE_CONSTS.BTN_HEIGHT_M;
    }
    return "auto";
  }};

  color: ${({ isOutline, isBorderless, theme }) =>
    isOutline || isBorderless ? theme.neutral.main : theme.white};
  ${({ color }) => (color ? `color: ${color};` : "")}
  position: relative;
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  border-radius: ${({ borderRadius }) =>
    borderRadius || STYLE_CONSTS.BTN_HEIGHT_M};
  font-size: ${({ isXSmall, isSmall }) =>
    isXSmall || isSmall ? STYLE_CONSTS.FONT_XSMALL : STYLE_CONSTS.FONT_SMALL};
  ${({ fontSize }) => (fontSize ? `font-size: ${fontSize};` : "")}
  ${({ fontStyle }) => (fontStyle ? `font-style: ${fontStyle};` : "")}
  border: ${({ isOutline, theme }) =>
    isOutline ? `1px solid ${theme.neutral.main}` : "none"};
  height: ${({ isXSmall, isSmall, isLarge }) => {
    if (isXSmall) return STYLE_CONSTS.BTN_HEIGHT_XS;
    if (isSmall) return STYLE_CONSTS.BTN_HEIGHT_S;
    if (isLarge) return STYLE_CONSTS.BTN_HEIGHT_L;
    return STYLE_CONSTS.BTN_HEIGHT_M;
  }};
  line-height: ${({ isSmall, isXSmall }) =>
    isSmall || isXSmall
      ? STYLE_CONSTS.BTN_HEIGHT_S
      : STYLE_CONSTS.BTN_HEIGHT_M};
  padding: ${({ isIconOnly, isSmall }) =>
    isIconOnly
      ? "5px 0 0 0"
      : `0 ${isSmall ? STYLE_CONSTS.UNIT_2 : STYLE_CONSTS.BTN_HEIGHT_M}`};
  text-align: ${({ isIconOnly }) => (isIconOnly ? "center" : "left")};
  &:focus {
    outline: none;
  }
  &:active {
    opacity: 0.5;
  }
  &:hover {
    opacity: 0.7;
  }

  &.isSecondCancelButton {
    margin-left: 12px;
  }
`;

export const ButtonIcon = styled(Icon)`
  transform: ${({ size, iconTranslateY }) => {
    if (iconTranslateY) return `translateY(${iconTranslateY})`;
    if (size === "small") return "translateY(1px)";
    if (size === "xsmall") return "translateY(-3px)";
    return `translateY(${ICON_Y}px)`;
  }};
`;

export const ExitButtonWrapper = styled(ButtonComponent)`
  display: flex;
  justify-content: center;
  padding: 0;
`;

import React from "react";

import { PAGESCROLL_ELEMENT_ID } from "~/src/consts";

import { Title, Content, Container, Tab, TabContainer } from "./panel.styles";

export default class Panel extends React.Component {
  state = {
    offsetTop: 0
  };

  componentDidMount() {
    if (this.props.isSticky) {
      this.dashBoardElement = document.getElementById(PAGESCROLL_ELEMENT_ID);
      this.dashBoardElement.addEventListener("scroll", this.handleScroll);

      this.boundingRect = this.panelRef.getBoundingClientRect();

      this.contentRect = this.contentRef.getBoundingClientRect();
    }
  }

  componentWillUnmount() {
    if (this.props.isSticky) {
      this.dashBoardElement.removeEventListener("scroll", this.handleScroll);
    }
  }

  handleScroll = e => {
    if (this.props.isSticky) {
      this.setState({
        offsetTop: e.target.scrollTop
      });
    }
  };

  dashBoardElement = null;
  panelRef = null;
  boundingRect = null;
  contentRef = null;
  contentRect = null;

  props;

  render() {
    const { props } = this;

    return (
      <Container
        className={props.className}
        ref={ref => {
          this.panelRef = ref;
        }}
        isSticky={this.props.isSticky}
        offset={this.state.offsetTop}
        height={this.props.panelHeight}
        minHeight={this.props.minHeight}
      >
        {props.title ? <Title>{props.title}</Title> : null}
        {props.tabs ? (
          <TabContainer>
            {props.tabs.map((tab, i) => (
              <Tab
                key={i}
                color={tab.color}
                onClick={tab.onClick || (() => {})}
                isClickable={tab.isClickable}
              >
                {tab.title}
              </Tab>
            ))}
          </TabContainer>
        ) : null}
        <Content
          ref={ref => {
            this.contentRef = ref;
          }}
          minHeight={props.minHeight}
          height={props.height}
        >
          {props.children}
        </Content>
      </Container>
    );
  }
}

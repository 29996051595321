import { ZonedDate } from "@teamrota/rota-common";
import { createReducer, createAction } from "~/src/utils";
import { BOOKING_STATES } from "~/src/consts";

export const SET_TAB_INDEX = "myStaff/SET_TAB_INDEX";
export const setTabIndex = createAction(SET_TAB_INDEX);

export const OPEN_MEMBER_BOOKINGS = "myStaff/OPEN_MEMBER_BOOKINGS";
export const openMemberBookings = createAction(OPEN_MEMBER_BOOKINGS);

export const UPDATE_FILTER_FIELDS = "myStaff/UPDATE_FILTER_FIELDS";
export const updateFilterFields = createAction(UPDATE_FILTER_FIELDS);

export const SET_IS_CASUAL_MEMBER_TYPE_CHECKED =
  "myStaff/SET_IS_CASUAL_MEMBER_TYPE_CHECKED";
export const setIsCasualMemberTypeChecked = createAction(
  SET_IS_CASUAL_MEMBER_TYPE_CHECKED
);

export const SET_IS_AGENCY_MEMBER_TYPE_CHECKED =
  "myStaff/SET_IS_AGENCY_MEMBER_TYPE_CHECKED";
export const setIsAgencyMemberTypeChecked = createAction(
  SET_IS_AGENCY_MEMBER_TYPE_CHECKED
);

export const SET_IS_PERMANENT_MEMBER_TYPE_CHECKED =
  "myStaff/SET_IS_PERMANENT_MEMBER_TYPE_CHECKED";
export const setIsPermanentMemberTypeChecked = createAction(
  SET_IS_PERMANENT_MEMBER_TYPE_CHECKED
);
export const SET_IS_CONTRACTOR_LIMITED_COMPANY_MEMBER_TYPE_CHECKED =
  "myStaff/SET_IS_CONTRACTOR_LTD_COMPANY_MEMBER_TYPE_CHECKED";
export const setIsContractorLimitedCompanyMemberTypeChecked = createAction(
  SET_IS_CONTRACTOR_LIMITED_COMPANY_MEMBER_TYPE_CHECKED
);
export const SET_IS_CONTRACTOR_UMBRELLA_COMPANY_MEMBER_TYPE_CHECKED =
  "myStaff/SET_IS_CONTRACTOR_UMBRELLA_COMPANY_MEMBER_TYPE_CHECKED";
export const setIsContractorUmbrellaCompanyMemberTypeChecked = createAction(
  SET_IS_CONTRACTOR_UMBRELLA_COMPANY_MEMBER_TYPE_CHECKED
);
export const SET_IS_CONTRACTOR_SELF_EMPLOYED_MEMBER_TYPE_CHECKED =
  "myStaff/SET_IS_CONTRACTOR_SELF_EMPLOYED_MEMBER_TYPE_CHECKED";
export const setIsContractorSelfEmployedMemberTypeChecked = createAction(
  SET_IS_CONTRACTOR_SELF_EMPLOYED_MEMBER_TYPE_CHECKED
);

export const RESET_FILTERS = "myStaff/RESET_FILTERS";
export const resetFilters = createAction(RESET_FILTERS);

export const DEFAULT_STATE = {
  // Hash map of bookings where key is memberId, created dynamically
  memberBookingList: {},
  filters: {
    searchText: "",
    memberType: [],
    isCasualMemberTypeChecked: false,
    isAgencyMemberTypeChecked: false,
    isPermanentMemberTypeChecked: false,
    isContractorLimitedCompanyMemberTypeChecked: false,
    isContractorUmbrellaCompanyMemberTypeChecked: false,
    isContractorSelfEmployedMemberTypeChecked: false,
    venue: undefined,
    maxRating: "",
    minRating: "",
    orderByProp: {
      value: "firstName",
      label: "Name",
      orderDirection: "orderByPropAsc"
    },
    stateIn: [
      { name: "Active", id: "ACTIVE" },
      { name: "Asleep", id: "ASLEEP" }
    ]
  }
};

export const handlers = {
  [SET_TAB_INDEX]: (state, { payload }) => ({
    ...state,
    memberBookingList: {
      ...state.memberBookingList,
      [payload.memberId]: {
        ...(state.memberBookingList[payload.memberId] || {}),
        activeTab: payload.activeTab,
        startAfter: payload.startAfter ? payload.startAfter() : undefined,
        startBefore: payload.startBefore ? payload.startBefore() : undefined,
        bookingState: payload.bookingState || undefined,
        bookingStates: payload.bookingStates || undefined
      }
    }
  }),
  [OPEN_MEMBER_BOOKINGS]: (state, { payload }) => ({
    ...state,
    memberBookingList: {
      ...state.memberBookingList,
      [payload.memberId]: {
        ...(state.memberBookingList[payload.memberId] || {}),
        isOpen: !(state.memberBookingList[payload.memberId] || {}).isOpen,
        // Default to appliedCount tab
        bookingState: BOOKING_STATES.APPLIED,
        startAfter: new ZonedDate(),
        startBefore: undefined,
        activeTab: "appliedCount"
      }
    }
  }),
  [UPDATE_FILTER_FIELDS]: (state, { payload: filters }) => ({
    ...state,
    filters: {
      ...state.filters,
      ...filters
    }
  }),
  [SET_IS_CASUAL_MEMBER_TYPE_CHECKED]: (state, { payload: value }) => ({
    ...state,
    filters: {
      ...state.filters,
      isCasualMemberTypeChecked: value
    }
  }),
  [SET_IS_AGENCY_MEMBER_TYPE_CHECKED]: (state, { payload: value }) => ({
    ...state,
    filters: {
      ...state.filters,
      isAgencyMemberTypeChecked: value
    }
  }),
  [SET_IS_PERMANENT_MEMBER_TYPE_CHECKED]: (state, { payload: value }) => ({
    ...state,
    filters: {
      ...state.filters,
      isPermanentMemberTypeChecked: value
    }
  }),
  [SET_IS_CONTRACTOR_LIMITED_COMPANY_MEMBER_TYPE_CHECKED]: (
    state,
    { payload: value }
  ) => ({
    ...state,
    filters: {
      ...state.filters,
      isContractorLimitedCompanyMemberTypeChecked: value
    }
  }),
  [SET_IS_CONTRACTOR_UMBRELLA_COMPANY_MEMBER_TYPE_CHECKED]: (
    state,
    { payload: value }
  ) => ({
    ...state,
    filters: {
      ...state.filters,
      isContractorUmbrellaCompanyMemberTypeChecked: value
    }
  }),
  [SET_IS_CONTRACTOR_SELF_EMPLOYED_MEMBER_TYPE_CHECKED]: (
    state,
    { payload: value }
  ) => ({
    ...state,
    filters: {
      ...state.filters,
      isContractorSelfEmployedMemberTypeChecked: value
    }
  }),
  [RESET_FILTERS]: () => DEFAULT_STATE
};

export default createReducer(handlers, DEFAULT_STATE);

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { handleErrors } from "~/src/utils/errors";

const GET_USER_TYPE_TEMPLATES = gql`
  query getUserTypeTemplates {
    account {
      id
      userTypeTemplates {
        id
        name
        isBuiltIn
        roles
        isAssigned
      }
    }
  }
`;

export default graphql(GET_USER_TYPE_TEMPLATES, {
  props: handleErrors(({ data: { loading, account, refetch } }) => ({
    isLoading: loading,
    userTypeTemplates: account?.userTypeTemplates ?? [],
    refetch
  }))
});

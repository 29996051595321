import moment from "moment-timezone";

export const setprovideScheduleStartDate = ({ startDate }) =>
  sessionStorage.setItem("provideScheduleStartDate", startDate.toISOString());

export const getprovideScheduleStartDate = () => {
  const date = sessionStorage.getItem("provideScheduleStartDate");
  return date ? moment(date) : null;
};

export const removeProvideScheduleStartDate = () =>
  sessionStorage.removeItem("provideScheduleStartDate");

export const setProvideScheduleFilters = ({ id, filters }) =>
  localStorage.setItem(`psFilters-${id}`, JSON.stringify(filters));

export const getProvideScheduleFilters = ({ id }) => {
  const filters = JSON.parse(localStorage.getItem(`psFilters-${id}`));

  if (filters) {
    // these fields have been removed from the filters
    delete filters.memberTypeFilter;
    delete filters.memberTypeFilter;
  }

  return filters;
};

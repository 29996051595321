import React from "react";
import { useMutation } from "@apollo/client";
import PolicyForm from "~/src/components/policy-form";
import UPDATE_TARGET_ACCOUNT from "../graphql/update-target-account";

const EditPolicies = ({ accountId, policy }) => {
  const [updateTargetAccount] = useMutation(UPDATE_TARGET_ACCOUNT);
  return (
    <PolicyForm
      confirmationText="Changes will only apply to future shifts posted, all current shifts will remain unchanged"
      onSave={policy =>
        updateTargetAccount({
          variables: {
            id: accountId,
            policy
          }
        })
      }
      policy={
        policy || {
          minimumShiftLength: 0,
          minimumShiftPercentage: 0,
          cancellationPeriod: 0
        }
      }
    />
  );
};

export default EditPolicies;

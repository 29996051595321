import { BOOKING_STATES } from "~/src/consts";

export default [
  {
    label: "Applied",
    keyProp: "applied",
    bookingStates: [BOOKING_STATES.APPLIED]
  },
  {
    label: "Accepted",
    keyProp: "accepted",
    bookingStates: [BOOKING_STATES.ACCEPTED]
  },
  {
    label: "Waiting list",
    keyProp: "waitingList",
    bookingStates: [BOOKING_STATES.WAITING_LIST]
  },
  {
    label: "No show",
    keyProp: "noShow",
    bookingStates: [BOOKING_STATES.NO_SHOW]
  },
  {
    label: "Turned away",
    keyProp: "turnedAway",
    bookingStates: [BOOKING_STATES.TURNED_AWAY]
  },
  {
    label: "Cancelled",
    keyProp: "cancelled",
    bookingStates: [
      BOOKING_STATES.APP_CANCELLED,
      BOOKING_STATES.CANCELLED,
      BOOKING_STATES.SHIFT_CANCELLED
    ]
  }
];

import styled, { css } from "styled-components";
import { COLORS, STYLE_CONSTS } from "~/src/styles/config.style";
import Button from "~/src/components/button";
import Ellipsis from "~/src/components/ellipsis";

const textStyles = css`
  color: ${COLORS.COOL_GREY};
`;

export const StyledEllipsis = styled(Ellipsis)`
  ${textStyles};
`;

export const Title = styled.div`
  font-size: ${STYLE_CONSTS.FONT_LARGE};
  color: ${({ color, theme }) =>
    COLORS[color] ? COLORS[color] : theme.primary.main};
`;

export const FooterContainer = styled.div`
  line-height: ${STYLE_CONSTS.BTN_HEIGHT_M};
`;

export const Text = styled.div`
  ${textStyles} font-size: ${STYLE_CONSTS.FONT_SMALL};
  display: inline-block;
  vertical-align: middle;
`;

export const ButtonContainer = styled.div`
  display: flex;
  float: right;
  vertical-align: middle;
`;

export const StyledButton = styled(Button)`
  margin-right: ${STYLE_CONSTS.UNIT_1};
`;

export const ChildrenContainer = styled.div`
  min-height: 96px;
  display: flex;
`;

export const StyledRedBox = styled.div`
  width: 5px;
  height: 5px;
`;

import React from "react";
import styled from "styled-components";
import { RotaModal, typography, RotaButton } from "@teamrota/rota-design";

const { StyledH7 } = typography;

const StyledTopContent = styled.div`
  background-color: ${({ theme }) => theme.white};
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
`;

const StyledBottomContent = styled.div`
  background-color: ${({ theme }) => theme.white};
  border: 1px solid transparent;
  border-top-color: #e0e0e0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
`;

const StyledTextWrap = styled.p`
  width: 285px;
  line-height: 23px;
  margin-bottom: 24px;
`;

const StyledWrapButton = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 16px;
`;

const ConfirmModal = ({ handleSave, handleCancel, handleClose }) => {
  return (
    <RotaModal onClose={handleClose} isSmall>
      <>
        <StyledTopContent>
          <StyledH7 style={{ fontWeight: "400", marginLeft: "10px" }}>
            Save Changes?
          </StyledH7>
        </StyledTopContent>
        <StyledBottomContent>
          <StyledTextWrap>
            Your unsaved changes will be lost. Update changes before exiting?
          </StyledTextWrap>
          <StyledWrapButton>
            <RotaButton variant="secondary" onClick={handleCancel}>
              Cancel
            </RotaButton>
            <RotaButton onClick={handleSave}> Save</RotaButton>
          </StyledWrapButton>
        </StyledBottomContent>
      </>
    </RotaModal>
  );
};

export default ConfirmModal;

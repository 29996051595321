import styled from "styled-components";

export const Container = styled.div`
  width: 200px;
  display: flex;
  align-items: center;
`;

export const Separator = styled.div`
  line-height: 30px;
  margin: 0 5px;
  font-weight: 500;
`;

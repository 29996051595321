import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const DELETE_GROUP = gql`
  mutation deleteGroup($id: ID) {
    deleteGroup(id: $id)
  }
`;

export default graphql(DELETE_GROUP, {
  name: "deleteGroup",
  props: ownProps => ({
    deleteGroup: groupId =>
      ownProps.deleteGroup({
        variables: {
          id: groupId
        },
        refetchQueries: ["getGroups"]
      })
  })
});

import React, { memo } from "react";

import { Role } from "@teamrota/authlib";
import useAuth from "~/src/auth/hooks/use-auth";

import truncate from "lodash/fp/truncate";
import Panel from "~/src/components/panel";
import { RotaButtonIcon } from "@teamrota/rota-design";

import { formatDateMonth } from "~/src/utils/formatting";
import {
  Text,
  Title,
  FooterContainer,
  ButtonContainer,
  ChildrenContainer,
  StyledRedBox
} from "./template-item.styles";

const TemplateItem = ({
  createdAt,
  title,
  children,
  handleDelete,
  handleEdit
}) => {
  const auth = useAuth();
  const canEditUniform = auth.hasRole(Role.UNIFORM_TEMPLATES_EDIT);

  return (
    <Panel minHeight={180}>
      <Title>{truncate({ length: 21 }, title)}</Title>
      <ChildrenContainer>{children}</ChildrenContainer>
      <FooterContainer>
        {createdAt && <Text>Created on {formatDateMonth(createdAt)}</Text>}
        {canEditUniform && (
          <ButtonContainer>
            {handleDelete && (
              <RotaButtonIcon icon="delete" onClick={handleDelete} />
            )}
            <StyledRedBox />
            {handleEdit && (
              <RotaButtonIcon
                icon="edit"
                onClick={handleEdit}
                iconStyles={{
                  fontSize: "22px",
                  marginTop: "3px"
                }}
              />
            )}
          </ButtonContainer>
        )}
      </FooterContainer>
    </Panel>
  );
};

export default memo(TemplateItem);

import {
  TIMESHEET_MEMBER_STATUS,
  TIMESHEET_STATUS
} from "@teamrota/rota-common";

export const createVenueOptions = venues =>
  venues?.map(venue => ({
    value: venue.id,
    label: venue.name
  }));

export const updateSchema = (schema, options, fieldName) => {
  return schema.map(field => ({
    ...field,
    ...(field.name === fieldName ? { options } : {})
  }));
};

export const addOwnerOptions = (owners, schema) => {
  const options = owners?.map(({ firstName, lastName, id }) => ({
    value: Number(id),
    label: `${firstName} ${lastName}`
  }));

  return updateSchema(schema, options, "ownerId");
};

export const getMemberStatusType = (status, timesheetStatus) => {
  if (timesheetStatus === TIMESHEET_STATUS.LOCKED) return "error";
  if (status === TIMESHEET_MEMBER_STATUS.APPROVED) return "success";
  return "warning";
};

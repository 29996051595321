import { gql, useMutation } from "@apollo/client";
import { FormattedState } from "../types";
import { useToast } from "@teamrota/rota-design";

const CREATE_TIMESHEET = gql`
  mutation createQuickAddTimesheet(
    $timesheetData: QuickaddTimesheetInputType
    $memberIds: [ID]
  ) {
    createQuickAddTimesheet(
      timesheetData: $timesheetData
      memberIds: $memberIds
    ) {
      id
    }
  }
`;

export const useCreateQuickAddTimesheet = ({
  onCompleted
}: {
  onCompleted: () => void;
}) => {
  const { showToast } = useToast();

  const [create, { loading, error }] = useMutation(CREATE_TIMESHEET, {
    onError: error => {
      showToast(error.message, { severity: "error" });
    },
    onCompleted,
    refetchQueries: ["TimesheetsSummary"]
  });

  const createQuickAddTimesheet = async (variables: FormattedState) => {
    await create({
      variables: {
        timesheetData: variables.timesheetData,
        memberIds: variables.memberIds
      }
    });
  };

  return { createQuickAddTimesheet, loading, error };
};

import React from "react";
import moment from "moment-timezone";
import { flow } from "lodash";
import { ZonedDate } from "@teamrota/rota-common";
import { Role } from "@teamrota/authlib";

import useAuth from "~/src/auth/hooks/use-auth";
import { formatFullName } from "~/src/utils/formatting";
import { LeftPane, RightPane } from "../components/content-wrapper";

import Member from "~/src/components/member-details";
import Modal from "~/src/components/modal";
import { MEMBER_STATES } from "~/src/consts";
import History from "~/src/containers/modals/components/history";
import Divider from "../components/divider";
import ModalContent from "../components/modal-content";
import Tabs from "../components/tabs";
import Title from "../components/title";
import Details from "./details";
import MemberDocuments from "./member-documents";
import MemberExperience from "./member-experience";
import MemberInfo from "./member-info";
import MemberRoles from "./member-roles";
import MemberCompanyInformation from "./member-company-information";

import getMember from "./graphql/get-member";
import getAccountAvailableMemberTypes from "./graphql/get-account-member-types";
import Availability from "./member-availability";

export const ViewEditMemberComponent = ({
  member,
  isOpen,
  isAddMember,
  serviceAreas,
  onClose,
  onCreate,
  refetchMember,
  isLoading,
  memberTypesAvailableForAccount,
  ...props
}) => {
  const auth = useAuth();
  const isAllowedToViewMemberCompliance = auth.hasRole(Role.MEMBER_COMPLIANCE);

  // Only show the onboarding layout if the member is pending or
  // rejected, also taking right to work into consideration

  const isMemberStatePending = [
    MEMBER_STATES?.PENDING,
    MEMBER_STATES?.REJECTED
  ].includes(member?.state);

  const isRightToWorkCompleted =
    !!member?.isRightToWorkValid && !!member?.rightToWorkExpiresAt;

  const isTabClickable = () => {
    if (isRightToWorkCompleted && isMemberStatePending) {
      return true;
    }
    if (!isMemberStatePending) {
      return true;
    }
    return false;
  };

  if (!isOpen) return false;
  const isExpired = date => {
    if (!date) return false;
    return moment(date).isBefore(new ZonedDate());
  };

  const isCompanyInformationRequired =
    memberTypesAvailableForAccount?.isContractorLimitedCompanyMemberTypeInfoRequired ||
    memberTypesAvailableForAccount?.isContractorUmbrellaCompanyMemberTypeInfoRequired ||
    memberTypesAvailableForAccount?.isContractorSelfEmployedMemberTypeInfoRequired;

  const isDisplayCompanyInformationTab =
    isCompanyInformationRequired &&
    [
      "CONTRACTOR_SELF_EMPLOYED",
      "CONTRACTOR_LIMITED_COMPANY",
      "CONTRACTOR_UMBRELLA_COMPANY"
    ].includes(member?.memberType);

  const isRightToWorkExpired = isExpired(member?.rightToWorkExpiresAt);

  const isSetToSelfEmployed =
    memberTypesAvailableForAccount?.isContractorSelfEmployedMemberTypeInfoRequired &&
    member?.memberType === "CONTRACTOR_SELF_EMPLOYED";

  const tabsArray = [
    {
      name: "Info",
      isClickable: isTabClickable(),
      content: (
        <MemberInfo
          isAddMember={isAddMember}
          member={member}
          memberId={member?.id}
          serviceAreas={serviceAreas || []}
          onClose={onClose}
          onCreate={onCreate}
          isOpen={isOpen}
        />
      )
    },
    {
      name: "Experience",
      isClickable: true,
      content: (
        <MemberExperience
          memberId={member?.id}
          memberExperiences={member?.profile?.experience || null}
        />
      )
    },
    {
      name: "Company Information",
      isClickable: true,
      content: (
        <MemberCompanyInformation
          memberId={member?.id}
          member={member}
          selfEmployed={isSetToSelfEmployed}
        />
      )
    },
    {
      name: "Roles",
      isClickable: isTabClickable(),
      content: <MemberRoles memberId={member?.id} />
    },
    {
      name: "Availability",
      isClickable: true,
      content: <Availability member={member} />
    }
  ];

  if (isAllowedToViewMemberCompliance) {
    tabsArray.push({
      name: "Compliance",
      isClickable: true,
      notifCount: isRightToWorkExpired ? 1 : 0,
      content: (
        <MemberDocuments
          isOnboardingMember={member?.state === MEMBER_STATES.PENDING}
          memberId={member?.id}
          memberPhoto={member?.photo}
          documents={member?.documents?.concat(...member?.s3Documents)}
          isRightToWorkValid={member?.isRightToWorkValid}
          rightToWorkExpiresAt={member?.rightToWorkExpiresAt}
          isRightToWorkExpired={isRightToWorkExpired}
          refetchMember={refetchMember}
          dbsCertificateNumber={member?.dbsCertificateNumber}
          dbsIssueDate={member?.dbsIssueDate}
        />
      )
    });
  }

  if (auth.hasRole(Role.MEMBERS_HISTORY)) {
    tabsArray.push({
      name: "History",
      isClickable: isTabClickable(),
      content: <History logs={member?.logs} />
    });
  }

  const companyInfoIndex = 2;
  const tabs = tabsArray.filter((_, i) => {
    return isDisplayCompanyInformationTab ? true : i !== companyInfoIndex;
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} isLoading={isLoading}>
      <ModalContent title={isAddMember ? "New Member" : formatFullName(member)}>
        <LeftPane>
          {isMemberStatePending && (
            <Title marginBottom> Onboarding Member </Title>
          )}
          <div>
            <Title>Summary</Title>
            <Member {...member} showNotes />
            <Divider width={270} />
          </div>

          <Details member={member} />
        </LeftPane>
        <RightPane>
          {isMemberStatePending ? (
            <Tabs
              tabs={tabs}
              defaultTab={
                isAllowedToViewMemberCompliance ? "Compliance" : "Info"
              }
            />
          ) : (
            <Tabs tabs={isAddMember ? [tabs[0]] : tabs} defaultTab="Info" />
          )}
        </RightPane>
      </ModalContent>
    </Modal>
  );
};

export default flow(
  getMember,
  getAccountAvailableMemberTypes
)(ViewEditMemberComponent);

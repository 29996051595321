import styled from "styled-components";
import Select from "~/src/components/form-components/select";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";

export const InputLabel = styled.div`
  ${props => (props.hasPadding ? `padding: 10px 0;` : `width: 90px;`)};
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 60px);
`;

export const ItemContainer = styled.div`
  margin-left: 80px;
  margin-top: ${STYLE_CONSTS.UNIT_2};
  display: flex;
  align-items: center;
`;

export const ItemInputWrapper = styled.div`
  width: calc(100% - 60px);
  margin-right: ${STYLE_CONSTS.UNIT_2};
`;

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
`;

export const TabOption = styled.div`
  ${props => props.shouldBeHidden && `display: none;`};
  font-weight: 400;
  ${props =>
    props.isFirstChild
      ? `padding-right: 5px;
    border-right: 1px solid ${COLORS.CLOUDY_BLUE} `
      : `padding-left: 5px;`};
  color: ${props =>
    props.isActive ? `${COLORS.DARK_GREY}` : `${COLORS.CLOUDY_BLUE}`};
  ${props => props.inEditMode && `padding: 0; border-right: 0 solid white`};
  &:hover {
    cursor: pointer;
    color: ${COLORS.DARK_GREY};
  }
`;

export const Wrapper = styled.div``;

export const VisualWrapper = styled.div`
  padding: 6px;
  display: flex;
  flex-direction: row;
`;
export const SelectWrapper = styled.div`
  flex: 2;
  padding-right: 50px;
`;

export const UniformSelect = styled(Select)``;

export const UniformInputsWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10px;
`;

export const ImageWrapper = styled.div`
  height: 250px;
  width: 250pxpx;
  justify-content: center;
`;

export const UniformImage = styled.img`
  border-radius: 20px;
  width: inherit;
  height: inherit;
`;

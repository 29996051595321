import React from "react";
import Icon from "~/src/components/icon";
import { DOCUMENT_TYPES } from "~/src/consts";

import {
  Upload,
  FileInput,
  Container,
  ErrorText
} from "./document-upload.styles";

export const INPUT_IDS = {
  [DOCUMENT_TYPES.MEMBER_PHOTO]: "file-input-member-photo",
  [DOCUMENT_TYPES.USER_PHOTO]: "file-input-user-photo",
  [DOCUMENT_TYPES.ADDITIONAL_MEMBER_DOCUMENT]:
    "file-input-additional-member-document",
  [DOCUMENT_TYPES.ADDITIONAL_USER_DOCUMENT]:
    "file-input-additional-user-document",
  [DOCUMENT_TYPES.RIGHT_TO_WORK]: "file-input-right-to-work",
  [DOCUMENT_TYPES.ACCOUNT_LOGO]: "file-input-account-logo",
  [DOCUMENT_TYPES.ACCOUNT_BANNER]: "file-input-account-banner"
};

const DocumentUpload = ({
  type,
  onChange,
  isRound,
  accept,
  isUploadSizeError
}) => {
  return (
    <Container>
      {isUploadSizeError && (
        <ErrorText>
          This file exceeds the 10MB file limit, please upload a smaller file.
        </ErrorText>
      )}
      <FileInput
        type="file"
        name="file"
        id={INPUT_IDS[type]}
        accept={accept}
        onChange={() => onChange(type, INPUT_IDS[type])}
      />
      <Upload
        htmlFor={INPUT_IDS[type]}
        isRound={type === DOCUMENT_TYPES.MEMBER_PHOTO || isRound}
      >
        <Icon
          name={Icon.names.UPLOAD}
          color={Icon.colors.COOL_GREY}
          size={Icon.sizes.LARGE}
        />
      </Upload>
    </Container>
  );
};

export default DocumentUpload;

import _ from "lodash";
import moment from "moment-timezone";
import { COLORS } from "./consts";

// this is a standard Matrix transpose function
// i.e.
// [
//  [1, 2, 3],
//  [4, 5, 6],
// ]
//    to
// [
//  [1, 4],
//  [2, 5],
//  [3, 6],
// ]
export const transpose = <M>(matrix: M[][]) =>
  matrix[0].map((_, colIndex) => matrix.map(row => row[colIndex]));

export const makeTable = (entries: any[][] | undefined) => {
  const availableColors = [...COLORS];
  const usedColors: Record<string, string> = {};

  if (entries !== undefined) {
    const temp: any[][] = [[], [], [], [], [], [], []];

    for (const [date, info] of Object.entries(entries)) {
      const key = JSON.stringify(info);
      const mm = moment(date);
      const dayOfWeek = mm.day() === 0 ? 6 : mm.day() - 1; // MON first

      let color = undefined;
      if (usedColors[key]) {
        color = usedColors[key];
      } else {
        if (availableColors.length === 0) availableColors.push(...COLORS);
        color = availableColors.shift();
        if (color !== undefined) usedColors[key] = color;
      }

      temp[dayOfWeek].push({
        time: mm.format("HH:mm"),
        colSpan: 1,
        rowSpan: 1,
        info,
        color
      });
    }

    const table = transpose(temp);

    // combine when it's the same over an hour (easy)
    for (let i = 0; i < table.length; i += 4) {
      for (let j = 0; j < table[i].length; j++) {
        const cellA = table[i][j];
        if (cellA.info !== null) {
          for (let ii = i + 1; ii < i + 4; ii++) {
            const cellB = table[ii][j];
            if (cellB.info !== null) {
              if (_.isEqual(cellA.info, cellB.info)) {
                cellA.rowSpan++;
                cellB.info = null;
              }
            }
          }
        }
      }
    }

    return table;
  }

  return undefined;
};

export const removeNonNumbers = (value: string) =>
  value.replace(/[^0-9.]/g, "");

export const pad = (input: string, padding: number, padChar = " ") =>
  (padding > input.length ? padChar.repeat(padding - input.length) : "") +
  input;

export const formatDuration = (minutes: number) =>
  pad(`${Math.floor(minutes / 60)}`, 2, " ") +
  "h " +
  pad(`${minutes % 60}`, 2, "0") +
  "m";

export function removeWhitespace(val: string) {
  return val?.replace?.(/\s/g, "");
}

export const getFilename = (id: string, payrollRateCards: any[]) => {
  const payrollRateCard = payrollRateCards?.find?.((p: any) => p.id === id);

  if (!payrollRateCard) return "";
  else
    return (
      removeWhitespace(payrollRateCard.partnerAccount.accountName) +
      "-" +
      removeWhitespace(payrollRateCard.role.name) +
      "-" +
      removeWhitespace(
        payrollRateCard.allVenues ? "All venues" : payrollRateCard.venue?.name
      ) +
      "-" +
      removeWhitespace(payrollRateCard.payrollScale.name) +
      "-" +
      removeWhitespace(payrollRateCard.payrollRate.name) +
      "-" +
      removeWhitespace(payrollRateCard.payrollCalculation.name) +
      "-" +
      removeWhitespace(payrollRateCard.payrollRule.name)
    );
};

export const tableHeaders = [
  {
    name: "Field",
    id: 1
  },
  {
    name: "View",
    id: 2
  },

  {
    name: "Edit",
    id: 3
  },
  {
    name: "Create",
    id: 4
  }
];

import styled from "styled-components";
import Cleave from "cleave.js/react";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const Input = styled(Cleave)`
  font-size: ${STYLE_CONSTS.FONT_MEDIUM};
  border: none;
  background-color: ${({ theme }) => theme.white};
  &:focus {
    outline: none;
  }
  &:disabled {
    color: ${({ theme }) => theme.text.primary};
  }
  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.neutral.main};
    background-color: ${({ theme }) => theme.white};
  }
`;

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import set from "lodash/fp/set";

import { addAuthVars } from "@teamrota/authlib";

import useAuth from "~/src/auth/hooks/use-auth";
import { GET_SHIFT_GROUP } from "~/src/containers/requester-calendar/shift-modal/graphql/get-shift-group/get-shift-group.query";
import { GET_SHIFT } from "~/src/containers/modals/view-edit-shift/graphql/get-shift";
import ShiftFragment from "../../fragments/shift/shift.fragment";
import LogFragment from "../../fragments/log/log.fragment";

const STOP_ALGO_DELAY = addAuthVars(gql`
  ${ShiftFragment}
  ${LogFragment}
  mutation stopAlgoDelay($shiftId: ID!) {
    stopAlgoDelay(shiftId: $shiftId) {
      ...ShiftFragment
      ... on shift {
        logs {
          ...LogFragment
        }
      }
    }
  }
`);

export default graphql(STOP_ALGO_DELAY, {
  name: "stopAlgoDelay",
  props: props => {
    const auth = useAuth();
    return {
      stopAlgoDelay: shiftId => {
        return props.stopAlgoDelay({
          variables: auth.addVals(STOP_ALGO_DELAY, { shiftId }),
          update(store, { data: { stopAlgoDelay } }) {
            try {
              const data = store.readQuery({
                query: GET_SHIFT_GROUP,
                variables: { id: stopAlgoDelay.id }
              });
              store.writeQuery({
                query: GET_SHIFT_GROUP,
                data: set(
                  "account.shift",
                  { ...data.account.shift, ...stopAlgoDelay },
                  data
                )
              });
            } catch (e) {
              // eslint-disable-next-line no-console
              console.warn(
                "STOP_ALGO_DELAY:::Update GET_SHIFT_GROUP was not possible"
              );
            }

            try {
              const getShiftData = store.readQuery({
                query: GET_SHIFT,
                variables: { shiftId }
              });

              store.writeQuery({
                query: GET_SHIFT,
                variables: { shiftId },
                data: set(
                  "account.shift.logs",
                  stopAlgoDelay.logs,
                  getShiftData
                )
              });
            } catch (e) {
              // eslint-disable-next-line no-console
              console.warn(
                "STOP_ALGO_DELAY:::Update GET_SHIFT was not possible"
              );
            }
          }
        });
      }
    };
  }
});

import React from "react";
import PolicyForm from "~/src/components/policy-form";
import updateBooking from "../graphql/update-booking";

const EditPolicies = props => (
  <PolicyForm
    isBookingPolicy
    onSave={policy =>
      props.updateBooking(props.booking.id, {
        policy
      })
    }
    policy={
      props.booking.policy || {
        minimumShiftLength: 0,
        minimumShiftPercentage: 0,
        cancellationPeriod: 0
      }
    }
  />
);

export default updateBooking(EditPolicies);

import {
  DataOption,
  DataOptionsType,
  FormattedState,
  State,
  memberType
} from "./types";

export const formatData = (
  formState: State,
  selectedMembers: memberType[]
): FormattedState => {
  const formattedData: FormattedState = {
    timesheetData: {
      venueId: formState.venueId,
      subvenueId: formState.subvenueId || null,
      shiftName: formState.shiftName,
      shiftType: formState.shiftType,
      roleRateId: formState.roleRateId,
      scheduledStartTime: formState.scheduledStartTime.toISOString(),
      scheduledEndTime: formState.scheduledEndTime.toISOString(),
      scheduledBreakMinutes: formState.scheduledBreakMinutes,
      actualBreakMinutes: formState.actualBreakMinutes,
      autoApprove: formState.autoApprove,
      numberRequested: selectedMembers.length
    },

    memberIds: selectedMembers.map(member => member.id)
  };

  if (formState.isDifferentCheckInTime) {
    formattedData.timesheetData.actualBreakMinutes =
      formState.actualBreakMinutes;

    formattedData.timesheetData.checkInTime = formState.checkInTime.toISOString();
    formattedData.timesheetData.checkOutTime = formState.checkOutTime.toISOString();
  }

  if (formState.isSleepTime) {
    formattedData.timesheetData.sleepStartTime = formState.sleepStartTime.toISOString();
    formattedData.timesheetData.sleepEndTime = formState.sleepEndTime.toISOString();
    formattedData.timesheetData.actualSleepStartTime = formState.actualSleepStartTime.toISOString();
    formattedData.timesheetData.actualSleepEndTime = formState.actualSleepEndTime.toISOString();
  }

  if (formState.isDisturbedHours) {
    formattedData.timesheetData.disturbedHoursStart = formState.disturbedHoursStart.toISOString();
    formattedData.timesheetData.disturbedHoursEnd = formState.disturbedHoursEnd.toISOString();
  }

  return formattedData;
};

export const makeOptions = (data: DataOptionsType) => {
  const options = data.map((option: DataOption) => ({
    label: option.name,
    value: option.id
  }));
  return options;
};

import useAuth from "~/src/auth/hooks/use-auth";

import { Role } from "@teamrota/authlib";

const getValidRoles = ({ role, roles }) => {
  // either role or roles must be set

  let rolesParam = role || roles;
  if (!rolesParam) {
    throw new Error("Must specify one of role/roles to HasRole/HasAnyRole");
  }

  if (!Array.isArray(rolesParam)) {
    rolesParam = [rolesParam];
  }

  rolesParam.forEach(role => {
    if (!role || !Role[role]) {
      throw new Error("Param specified in roles/role is not a role");
    }
  });

  return rolesParam;
};

export const HasAnyRole = ({ children, ...props }) =>
  useAuth().hasAnyRole(...getValidRoles(props)) ? children : null;

const HasRole = ({ children, ...props }) =>
  useAuth().hasRoles(...getValidRoles(props)) ? children : null;

export default HasRole;

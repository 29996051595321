import ReactDOM from "react-dom";

const css = `
  img { -webkit-print-color-adjust: exact; }
`;

// original code from https://www.npmjs.com/package/computed-style-to-inline-style
const computedStyleToInlineStyle = (element, recursive) => {
  if (!element) {
    throw new Error("No element specified.");
  }
  if (!(element instanceof Element)) {
    throw new Error("Specified element is not an instance of Element.");
  }
  if (recursive) {
    Array.prototype.forEach.call(element.children, child => {
      computedStyleToInlineStyle(child, recursive);
    });
  }
  const computedStyle = getComputedStyle(element, null);
  for (let i = 0; i < computedStyle.length; i += 1) {
    const property = computedStyle.item(i);
    if (property !== "layout-grid-mode") {
      // ^^ this fails in IE11 otherwise
      const value = computedStyle.getPropertyValue(property);
      element.style[property] = value;
    }
  }
};

const printComponent = component =>
  new Promise((res, rej) => {
    setTimeout(
      ReactDOM.render(
        component,
        document.getElementById("hidden-elements"),
        () => {
          const elm = document.getElementById("hidden-elements");
          if (elm) {
            const printWindow = window.open("", "", "width=1000,height=500");

            if (!printWindow) {
              rej(Error("Please disable your popup blocker."));
              return;
            }

            const printableMarkup = elm.firstElementChild;
            computedStyleToInlineStyle(printableMarkup, true);
            const style = window.document.createElement("style");
            style.setAttribute("type", "text/css");
            window.document.head.appendChild(style);
            const isChrome = Boolean(printWindow.chrome);

            if (style.styleSheet) {
              style.styleSheet.cssText = css;
            } else {
              style.appendChild(document.createTextNode(css));
            }

            printWindow.document.write(
              printableMarkup && printableMarkup.outerHTML
            );

            printWindow.document.close();

            if (isChrome) {
              printWindow.onload = () => {
                setTimeout(() => {
                  printWindow.focus();
                  printWindow.print();
                  printWindow.close();
                  res();
                }, 10); // 250
              };
            } else {
              setTimeout(() => {
                printWindow.document.close();
                printWindow.focus();
                printWindow.print();
                printWindow.close();
                res();
              }, 10);
            }
          } else {
            console.warn("Printable wrapper was not found");
            rej();
          }
        }
      ),
      200
    );
  });

export default printComponent;

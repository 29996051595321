import { ZonedDate } from "@teamrota/rota-common";
import { BOOKING_STATES_LABELS, BOOKING_STATES } from "~/src/consts";

export default [
  {
    title: BOOKING_STATES_LABELS.APPLIED,
    keyProp: "appliedCount",
    bookingState: BOOKING_STATES.APPLIED,
    bookingStates: undefined,
    queryConditions: { startAfter: () => new ZonedDate() }
  },
  {
    title: BOOKING_STATES_LABELS.ACCEPTED,
    keyProp: "upcomingAccepted",
    bookingState: BOOKING_STATES.ACCEPTED,
    bookingStates: undefined,
    queryConditions: { startAfter: () => new ZonedDate() }
  },
  {
    title: "Previous",
    keyProp: "previouslyAccepted",
    bookingState: undefined,
    bookingStates: [
      BOOKING_STATES.ACCEPTED,
      BOOKING_STATES.NO_SHOW,
      BOOKING_STATES.TURNED_AWAY
    ],
    queryConditions: { startBefore: () => new ZonedDate() }
  },
  {
    title: BOOKING_STATES_LABELS.WAITING_LIST,
    keyProp: "waitingListCount",
    bookingState: BOOKING_STATES.WAITING_LIST,
    bookingStates: undefined,
    queryConditions: { startAfter: () => new ZonedDate() }
  },
  {
    title: "Cancelled",
    keyProp: "cancelledCount",
    bookingState: undefined,
    bookingStates: [
      BOOKING_STATES.CANCELLED,
      BOOKING_STATES.SHIFT_CANCELLED,
      BOOKING_STATES.APP_CANCELLED
    ],
    queryConditions: {}
  }
];

import React, { useState } from "react";
import styled from "styled-components";
import {
  iconNew,
  RotaBox,
  RotaButtonIcon,
  THead,
  TrHead,
  Th,
  TBody,
  InnerCell,
  RotaCheckbox,
  useSelectedItems,
  RotaPlaceholder
} from "@teamrota/rota-design";
import RotaButtonNew, {
  Variant
} from "@teamrota/rota-design/dist/components/button";
import { TimesheetRow, TimesheetRowLoader } from "./timesheet-row";
import { EmptyView } from "./empty-view";
import { RotaTableScrollable } from "./style";
import PreviewTimesheetDetails from "./preview-timesheet-details";
import { TimesheetMemberStatus } from "./types";
import { TimesheetMemberWithStats } from "./use-timesheet-members-with-stats";
import TimesheetBreakdownModal from "./timesheet-breakdown-modal";
import { useApproveTimesheetMembers } from "./use-approve-timesheet-members";

const { Filter, Expand, Contract } = iconNew;

const Subheader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 32px;
  min-height: 38px;
`;

const SelectedItemsInfo = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.text.secondary};
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  max-height: calc(100% - 72px);
`;

const TableWrapper = styled.div`
  width: 100%;
`;

const Box = styled(RotaBox)`
  margin: 8px 32px;
  padding: 16px 32px;
  width: auto;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
`;

const Actions = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 16px;
  align-items: center;
`;

export const TableLoader = () => {
  return (
    <Box>
      <Heading>
        <RotaPlaceholder size={32} maxWidth="250px" />
        <Actions>
          <RotaPlaceholder size={32} circle />
        </Actions>
      </Heading>
      <InnerWrapper>
        <RotaTableScrollable>
          <THead>
            <TrHead>
              <Th>
                <InnerCell>
                  <RotaCheckbox isChecked={false} onClick={() => {}} />
                </InnerCell>
              </Th>
              <Th />
              <Th style={{ textAlign: "left", width: 266 }}>Shift</Th>
              <Th>ID</Th>
              <Th>Date</Th>
              <Th>People</Th>
              <Th>Time</Th>
              <Th>Cost</Th>
              <Th style={{ width: 132 }}>PO no.</Th>
              <Th />
              <Th />
            </TrHead>
          </THead>
          <TBody>
            {Array.from({ length: 10 }).map((_, index) => (
              <TimesheetRowLoader key={index} />
            ))}
          </TBody>
        </RotaTableScrollable>
      </InnerWrapper>
    </Box>
  );
};

interface Props {
  timesheets: TimesheetMemberWithStats[];
  hasMore: boolean;
  loadMore: () => Promise<void>;
  logo?: string;
  currencyCode: string;
  totalCount: number;
  showSummary: boolean;
  onToggleExpand: () => void;
  onOpenSidebar: () => void;
  refetch: () => void;
}

export const Table = ({
  timesheets = [],
  hasMore,
  loadMore,
  currencyCode,
  totalCount,
  showSummary,
  onToggleExpand,
  onOpenSidebar,
  refetch
}: Props) => {
  const [
    expandedTimesheet,
    setExpandedTimesheet
  ] = useState<TimesheetMemberWithStats | null>(null);
  const {
    selectedItemsArray,
    hasSelectedAll,
    onToggleOneItem,
    onToggleAllItems,
    reset
  } = useSelectedItems(
    timesheets.reduce<string[]>((acc, timesheet) => {
      const nonSelectableTimesheets =
        [TimesheetMemberStatus.APPROVED].includes(timesheet.status) ||
        timesheet.isPreview;

      return nonSelectableTimesheets ? acc : [...acc, timesheet.id];
    }, [])
  );

  const { approveTimesheetMembers } = useApproveTimesheetMembers({
    onCompleted: () => {
      reset();
      refetch();
    }
  });

  if (expandedTimesheet) {
    if (expandedTimesheet.isPreview) {
      return (
        <PreviewTimesheetDetails
          timesheet={expandedTimesheet}
          showSummary={showSummary}
          onToggleExpand={onToggleExpand}
          onClose={() => setExpandedTimesheet(null)}
        />
      );
    }
    return (
      <TimesheetBreakdownModal
        timesheet={expandedTimesheet}
        onClose={() => setExpandedTimesheet(null)}
      />
    );
  }

  return (
    <>
      <Box>
        <Heading>
          <Title>{`All ${totalCount} records`}</Title>
          <Actions>
            <RotaButtonIcon onClick={onOpenSidebar}>
              <Filter />
            </RotaButtonIcon>
            <RotaButtonIcon onClick={onToggleExpand}>
              {showSummary ? <Expand /> : <Contract />}
            </RotaButtonIcon>
          </Actions>
        </Heading>
        <Subheader>
          <RotaButtonNew
            disabled={!selectedItemsArray.length}
            onClick={() => {
              approveTimesheetMembers({
                timesheetMemberIds: selectedItemsArray
              });
            }}
          >
            Approve
          </RotaButtonNew>
          {!!selectedItemsArray.length && (
            <SelectedItemsInfo>{`Selected ${selectedItemsArray.length} timesheets`}</SelectedItemsInfo>
          )}
        </Subheader>
        <InnerWrapper>
          <TableWrapper>
            <RotaTableScrollable>
              <THead>
                <TrHead>
                  <Th>
                    <InnerCell>
                      <RotaCheckbox
                        isMinusIcon={
                          selectedItemsArray.length < timesheets.length
                        }
                        isChecked={
                          hasSelectedAll || !!selectedItemsArray.length
                        }
                        onClick={() => onToggleAllItems()}
                      />
                    </InnerCell>
                  </Th>
                  <Th />
                  <Th style={{ textAlign: "left", minWidth: 250 }}>Name</Th>
                  <Th style={{ minWidth: 150 }}>Date</Th>
                  <Th style={{ minWidth: 250 }}>Shift</Th>
                  <Th style={{ minWidth: 120 }}>In/Out</Th>
                  <Th style={{ minWidth: 180 }}>Time</Th>
                  <Th style={{ minWidth: 120 }}>PO no.</Th>
                  <Th style={{ minWidth: 120 }}>Status</Th>
                  <Th />
                </TrHead>
              </THead>
              <TBody>
                {timesheets.map(timesheet => (
                  <TimesheetRow
                    key={timesheet.id}
                    timesheet={timesheet}
                    currencyCode={currencyCode}
                    isChecked={selectedItemsArray.includes(timesheet.id)}
                    onToggle={() => onToggleOneItem(timesheet.id)}
                    setExpandedTimesheet={(
                      timesheet: TimesheetMemberWithStats
                    ) => setExpandedTimesheet(timesheet)}
                    refetch={refetch}
                  />
                ))}
              </TBody>
            </RotaTableScrollable>
          </TableWrapper>
          {hasMore && (
            <RotaButtonNew
              variant={Variant.text}
              onClick={loadMore}
              style={{ margin: 16 }}
            >
              Load more timesheets
            </RotaButtonNew>
          )}
          {timesheets.length === 0 && <EmptyView />}
        </InnerWrapper>
      </Box>
    </>
  );
};

import React, { useState } from "react";
import { DOCUMENT_TYPES } from "~/src/consts";
import uploadFile from "~/src/services/upload-file";
import { errorModal } from "~/src/utils/errors";
import DocumentUpload from "~/src/components/document-upload";
import DocumentImage, { LoadingImage } from "~/src/components/document-image";

const Documents = ({ user, refetchUser }) => {
  const [isSaving, setIsSaving] = useState(undefined);

  const handleUploadFile = async (type, id) => {
    setIsSaving(type);

    try {
      const selectedFile = document.getElementById(id)?.files?.[0];
      await uploadFile({
        file: selectedFile,
        type,
        id: user?.id,
        table: "users"
      });
      await refetchUser();
    } catch (e) {
      errorModal(e);
    } finally {
      setIsSaving(null);
    }
  };

  return (
    <div>
      <p>User photo</p>
      <p>
        Upload a user profile picture. Accepted file types include .png, .jpg.
        Arbitrary file types (.pdf, .word) etc. not accepted and cannot be
        stored on User profiles (unlike Member profiles).
      </p>
      <DocumentUpload
        isRound
        type={DOCUMENT_TYPES.USER_PHOTO}
        onChange={handleUploadFile}
      />
      {isSaving === DOCUMENT_TYPES.USER_PHOTO && <LoadingImage isRound />}
      {user?.photo && <DocumentImage isRound src={user?.photo} />}
    </div>
  );
};

export default Documents;

import { gql } from "@apollo/client";

export default gql`
  fragment RoleRateFragment on roleRate {
    id
    roleName
    roleId
    chargeRate
    payRate
    account {
      id
      logo
      accountName
    }
    isDayRate
    isUncalculatedRate
  }
`;

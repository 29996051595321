import styled from "styled-components";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";

export const TagItem = styled.div`
  display: inline-block;
  background: ${({ isCompulsory }) =>
    isCompulsory ? COLORS.SILVER : STYLE_CONSTS.BACKGROUND_GRADIENT};
  border-radius: ${STYLE_CONSTS.BTN_HEIGHT_M};
  padding: ${STYLE_CONSTS.UNIT_HALF} ${STYLE_CONSTS.UNIT_3};
  color: ${({ theme }) => theme.white};
  margin-right: ${STYLE_CONSTS.UNIT_1};
  font-size: 12px;
  margin-bottom: ${STYLE_CONSTS.UNIT_2};
  ${({ isCompulsory }) => !isCompulsory && "cursor: pointer"}
`;

export const TagName = styled.p`
  ${({ isCheckIconVisible }) => isCheckIconVisible && "padding-right: 15px"};
`;

export const TagItemContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const TagsWrapper = styled.div`
  padding-top: ${STYLE_CONSTS.UNIT_4};
`;

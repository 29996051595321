import styled from "styled-components";
import { RotaBox } from "@teamrota/rota-design";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const HeaderWrapper = styled.div`
  right: 0;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px;
`;

export const Box = styled(RotaBox)`
  margin: 8px 32px;
  padding: 16px 32px;
  width: auto;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 35px;
`;

export const Actions = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 16px;
  align-items: center;
`;

export const SubheaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 40px auto;
  padding: 16px 32px;
  grid-gap: 16px;
  align-items: start;
`;

export const Subheader = styled.div`
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
`;

export const EmptyText = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin: 40px auto;
  text-align: center;
  width: 100%;
`;

export const IconDiv = styled.div`
  display: block;
  white-space: nowrap;
`;

import { RotaButtonNew, RotaModal } from "@teamrota/rota-design";
import React from "react";
import {
  DeleteModalButtonsContainer,
  DeleteModalText
} from "./subvenues.styles";

export const DeleteConfirmation = ({
  handleDelete,
  setIsShowDeleteConfirmation
}) => {
  return (
    <RotaModal name={"Delete subvenue"} isSmall={true}>
      <DeleteModalText>
        Are you sure you want to delete this subvenue?
      </DeleteModalText>

      <DeleteModalButtonsContainer>
        <RotaButtonNew
          onClick={() => {
            setIsShowDeleteConfirmation(false);
          }}
          variant="outlined"
        >
          Cancel
        </RotaButtonNew>
        <RotaButtonNew isDanger onClick={handleDelete}>
          Delete
        </RotaButtonNew>
      </DeleteModalButtonsContainer>
    </RotaModal>
  );
};

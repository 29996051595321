import styled from "styled-components";
import Button from "~/src/components/button";

import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";

export const ListContainer = styled.div`
  min-height: 130px;
  padding: ${STYLE_CONSTS.UNIT_3};
`;

export const TabContainer = styled.div`
  border-bottom: ${STYLE_CONSTS.SMALL_DIVIDER_BORDER};
  margin: 0 ${STYLE_CONSTS.UNIT_1};
  padding-bottom: ${STYLE_CONSTS.UNIT_3};
`;

export const Tab = styled.span`
  margin-right: ${STYLE_CONSTS.UNIT_4};
  color: ${({ isActive, theme }) =>
    isActive ? theme.primary.main : COLORS.COOL_GREY};
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_MEDIUM};
  cursor: pointer;
`;

export const EmptyBookings = styled.div`
  text-align: center;
  padding: ${STYLE_CONSTS.UNIT_8};
  color: ${COLORS.COOL_GREY};
`;

export const StyledButton = styled(Button)`
  margin-left: ${STYLE_CONSTS.UNIT_1};
`;

export const StarContaier = styled.div`
  margin-right: ${STYLE_CONSTS.UNIT_1};
`;

export const LoadMoreWrapper = styled.div`
  margin-top: ${STYLE_CONSTS.UNIT_3};
  text-align: center;
`;

export const Text = styled.div`
  font-size: ${({ isSmall }) =>
    isSmall ? STYLE_CONSTS.FONT_SMALL : STYLE_CONSTS.FONT};
  color: ${({ isSmall }) => isSmall && COLORS.COOL_GREY};
`;

export const BookingState = styled.span`
  color: ${COLORS.COOL_GREY};
`;

export const SumBookings = styled.div`
  margin-right: ${STYLE_CONSTS.UNIT_2};
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_MEDIUM};
`;

export const Row = styled.div`
  border-bottom: ${STYLE_CONSTS.SMALL_DIVIDER_BORDER};
  &:last-child {
    border-bottom: none;
    padding: 0;
    padding-top: ${STYLE_CONSTS.UNIT_3};
  }
  margin: 0 ${STYLE_CONSTS.UNIT_1};
  padding: ${STYLE_CONSTS.UNIT_3} 0;
`;

export const CheckRow = styled.div`
  padding: ${STYLE_CONSTS.UNIT_1} 0;
`;

export const CheckContainer = styled.div`
  margin-bottom: ${STYLE_CONSTS.UNIT_1};
`;

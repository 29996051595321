import { gql } from "@apollo/client";
import { addAuthVars } from "@teamrota/authlib";

import MemberFragment from "~/src/graphql/fragments/member/member.fragment";

// XXX this is missing isLocationConflict, should be added

export const GET_MEMBER_LAZY = addAuthVars(gql`
  ${MemberFragment}

  query getMember(
    $shiftId: ID
    $sourceAccountId: ID
    $selectedAccountId: ID
    $id: ID!
    $dates: [DateRangeType]!
    $shiftType: ShiftTypeEnumType
    $roleRateId: ID!
  ) {
    account(sourceAccountId: $sourceAccountId) {
      id
      isOverLimitWorkAllowed

      member(id: $id) {
        ...MemberFragment
        isQualifiedWithRole(shiftId: $shiftId, roleRateId: $roleRateId)
        isQualifiedWithTags(shiftId: $shiftId)
        isConflicted: isConflictedWithDates(shiftId: $shiftId, dates: $dates)
        isUnavailable: isUnavailableWithDates(
          shiftId: $shiftId
          dates: $dates
          shiftType: $shiftType
        )
        isHitWorkingHoursLimit(shiftId: $shiftId, dates: $dates)

        memberAvailability(dates: $dates) {
          startsAt
          endsAt
          shiftType
        }
      }
    }
  }
`);

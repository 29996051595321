import React from "react";
import { useTheme } from "styled-components";

import RolesContextProvider from "~/src/containers/settings-page/context/roles-context";
import getProfile from "~/src/graphql/queries/get-profile/get-profile-query.decorator";

import LeftPanel from "~/src/containers/settings-page/left-panel/left-panel";
import RightPanel from "~/src/containers/settings-page/right-panel/right-panel";

import { STYLE_CONSTS } from "~/src/styles/config.style";
import styles from "./settings-page-content.styles";
import Loading from "~/src/components/loading";
import { LoadingWrapper } from "~/src/components/load-more/load-more.styles";

const SettingsPage = ({ user, refetchProfile }) => {
  const theme = useTheme();
  return user ? (
    <div>
      <RolesContextProvider>
        <div style={styles.settingsTitle}>
          <span
            style={{
              color: theme.primary.main,
              fontWeight: STYLE_CONSTS.FONT_WEIGHT_MEDIUM
            }}
          >
            Settings
          </span>
        </div>
        <div style={{ display: "flex" }}>
          <LeftPanel user={user} />
          <RightPanel user={user} refetchProfile={refetchProfile} />
        </div>
      </RolesContextProvider>
    </div>
  ) : (
    <LoadingWrapper>
      <Loading />
    </LoadingWrapper>
  );
};

export default getProfile(SettingsPage);

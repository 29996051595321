import React from "react";
import ToolTip from "react-tooltip";
import { icon } from "@teamrota/rota-design";

const TimesheetToolTip = ({ text, style, id }) => {
  const { InfoIcon } = icon;
  return (
    <div style={{ ...style }}>
      <p data-tip={id} data-for={id}>
        <InfoIcon />
      </p>
      <ToolTip data-place="bottom" multiline id={id} effect="solid">
        {text}
      </ToolTip>
    </div>
  );
};

export default TimesheetToolTip;

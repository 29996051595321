import React from "react";
import { Main, Header, Count, Content, Row } from "./styles";

const FormStepSegment = props => (
  <Main>
    <Header>
      <Row>
        <Count isOpen={props.isOpen}>
          <span>{props.stepNumber}</span>
        </Count>
        {props.title()}
      </Row>
    </Header>

    <Content isOpen={props.isOpen}>{props.children()}</Content>
  </Main>
);

export default FormStepSegment;

import React, { useState } from "react";

import { RotaTab } from "@teamrota/rota-design";

import AccountTabContent from "./account-tab-content/account-tab-content";
// import TimesheetTabContent from "./timesheet-tab-content/timesheet-tab-content";
import NotificationsTabContent from "./notifications-tab-content/notifications-tab-content";
import CheckInOutTabContent from "./check-in-out-tab-content/check-in-out-tab-content";
import PermissionsTabContent from "./permissions-tab-content/permissions-tab-content";
import UsersTabContent from "./users-tab-content/users-tab-content";
import AvailabilityTabContent from "./availability-tab-content";

import SnackContextProvider from "~/src/containers/settings-page/context/snack-context";

import { Role } from "@teamrota/authlib";

import styles from "./right-panel.styles";
import useAuth from "~/src/auth/hooks/use-auth";

const RightPanel = ({ user, refetchProfile }) => {
  const auth = useAuth();
  const hasViewPermission = auth.hasRoles(Role.TYPE_TEMPLATES);

  const [activeButton, setActiveButton] = useState(1);

  const btnList = [
    {
      id: 1,
      title: "Account",
      isDefault: true
    },
    {
      id: 2,
      title: "Notifications",
      isDefault: false
    },
    {
      id: 3,
      title: "Check in/out",
      isDefault: false
    }
    // {
    //   id: 4,
    //   title: "Timesheets",
    //   isDefault: false
    // },
  ];

  if (hasViewPermission) {
    btnList.push(
      {
        id: 5,
        title: "Permissions",
        isDefault: false
      },
      {
        id: 6,
        title: "Users",
        isDefault: false
      },
      {
        id: 7,
        title: "Availability",
        isDefault: false
      }
    );
  }

  return (
    <SnackContextProvider>
      <div style={styles.rightPanelContainer} id="right-panel-container">
        <div id="header-tabs" style={styles.headerTabs}>
          {btnList.map(({ id, title }) => (
            <RotaTab
              label={title}
              onClick={() => {
                setActiveButton(id);
              }}
              size="sm"
              isActive={activeButton === id}
            />
          ))}
        </div>
        {{
          1: <AccountTabContent user={user} refetchProfile={refetchProfile} />,
          2: <NotificationsTabContent />,
          3: <CheckInOutTabContent />,
          // 4: timesheets: <TimesheetTabContent />,
          5: <PermissionsTabContent />,
          6: <UsersTabContent />,
          7: <AvailabilityTabContent />
        }[activeButton] || <AccountTabContent />}
      </div>
    </SnackContextProvider>
  );
};

export default RightPanel;

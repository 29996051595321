const styles = {
  filter: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-end"
  }
};

export default styles;

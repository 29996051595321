import React, { useEffect, useState } from "react";
import { reduxForm } from "redux-form";
import EditCancel from "~/src/components/edit-cancel";
import Field from "~/src/components/form-field";
import { errorModal } from "~/src/utils/errors";

import {
  Title,
  ErrorText,
  StyledSubtitleForm,
  StyledWrapDynamicForm
} from "./index.styles";

const Form = ({
  schema,
  onSubmit,
  isEditOnly,
  invalid,
  submitFailed,
  afterSubmit,
  onCancel,
  handleSubmit,
  postSubmit,
  initialValues,
  reset,
  memberType,
  annualSalary,
  isInsideIR35,
  weeklyContractedHours,
  memberId,
  member,
  resetMemberTypeInputs,
  hasEditBtn = true,
  isPartner,
  parentSaving,
  parentSaved,
  isCreateNewPartner,
  isScrollable = true,
  ...props
}) => {
  const [inEditMode, setInEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [hasSaved, setHasSaved] = useState(false);
  const [copiedValue, setCopiedValue] = useState("");
  const [memberTypeFiledError, setMemberTypeFiledError] = useState("");

  const toggleEditMode = () => {
    setInEditMode(!inEditMode);
    reset();
  };

  const handleCopyToClipboard = ({ target: { defaultValue } }) => {
    setCopiedValue(defaultValue);
  };

  useEffect(() => {
    if (memberType !== "PERMANENT") {
      setMemberTypeFiledError("");
    }
  }, [memberType]);

  useEffect(() => {
    if (initialValues?.memberType !== "PERMANENT") {
      setMemberTypeFiledError("");
    }
  }, [initialValues]);

  const saveMemberInfo = async args => {
    setMemberTypeFiledError("");
    const updatedArgs = {
      ...args,
      ...(weeklyContractedHours === 0
        ? member?.weeklyContractedHours
        : { weeklyContractedHours }),
      ...(annualSalary === 0 ? member?.annualSalary : { annualSalary }),
      ...(isInsideIR35 ? { isInsideIR35 } : member?.isInsideIR35)
    };
    try {
      setIsSaving(true);
      const newItem = await onSubmit(updatedArgs);
      if (resetMemberTypeInputs) {
        resetMemberTypeInputs();
      }
      if (afterSubmit) await afterSubmit(newItem);
      setIsSaving(false);
      setHasSaved(true);
      if (postSubmit) postSubmit();
    } catch (e) {
      if (resetMemberTypeInputs) {
        resetMemberTypeInputs();
      }
      setIsSaving(false);
      setHasSaved(false);
      errorModal(e);
    }
  };
  const handleSave = args => {
    saveMemberInfo(args);
  };

  const canEdit = inEditMode || isEditOnly;

  return (
    <EditCancel
      showErrors={invalid && submitFailed}
      hideCancel={isEditOnly}
      inEditMode={canEdit}
      onEdit={toggleEditMode}
      onCancel={onCancel || toggleEditMode}
      onSave={handleSubmit(handleSave)}
      isSaving={parentSaving ?? isSaving}
      hasSaved={parentSaved ?? hasSaved}
      hasEditBtn={hasEditBtn}
      isPartner={isPartner}
      isScrollable={isScrollable}
    >
      <form onSubmit={handleSubmit(handleSave)}>
        <StyledWrapDynamicForm>
          {schema.map((field, i) => {
            if (field?.title) {
              return (
                <div key={field?.title}>
                  <Title>{field?.title}</Title>
                  {!!memberTypeFiledError &&
                    field?.title === "Member types" && (
                      <ErrorText>
                        Please enter weekly contracted hours
                      </ErrorText>
                    )}
                  {field.fields.map(f => (
                    <Field
                      memberId={memberId}
                      key={f?.name}
                      readOnly={field.doNotEdit ? true : !canEdit}
                      {...f}
                    />
                  ))}
                </div>
              );
            }
            return (
              <>
                <Field
                  key={field.name}
                  readOnly={!canEdit}
                  onClick={handleCopyToClipboard}
                  copiedValue={copiedValue}
                  {...field}
                />
                {isCreateNewPartner && i === 1 && (
                  <StyledSubtitleForm>New User</StyledSubtitleForm>
                )}
              </>
            );
          })}
        </StyledWrapDynamicForm>
      </form>
    </EditCancel>
  );
};

export default reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(Form);

import React, { useState } from "react";
import { flow } from "lodash/fp";
import { RotaButtonNew } from "@teamrota/rota-design";
import Modal from "~/src/components/modal";

import {
  Container,
  Header,
  ButtonContainer,
  Text
} from "../async-confirm/async-confirm.styles";

const DownloadStaffData = ({ onClose, onConfirm, isOpen }) => {
  const [isLoading, setIsLoading] = useState(false);

  const toggleLoading = () => {
    setIsLoading(!isLoading);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isOverlay>
      <Container>
        {isLoading && <Header>Generating your Excel file...</Header>}
        {!isLoading && (
          <div>
            <Header>Do you want to download Excel file?</Header>
            <Text hasMarginBottom>Note selected filters will be applied.</Text>
          </div>
        )}
        {isLoading && (
          <Text>Please be patient, this may take a little while</Text>
        )}
        <ButtonContainer>
          <RotaButtonNew
            variant="outlined"
            onClick={onClose}
            isDisabled={isLoading}
          >
            No, thanks
          </RotaButtonNew>
          <RotaButtonNew
            onClick={flow(toggleLoading, onConfirm)}
            isLoading={isLoading}
          >
            Download
          </RotaButtonNew>
        </ButtonContainer>
      </Container>
    </Modal>
  );
};

export default DownloadStaffData;

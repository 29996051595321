import React, { useState } from "react";
import { RotaSearchInput } from "@teamrota/rota-design";
import { useQuery } from "@apollo/client";
import { GET_ACCOUNTS } from "../../graphql/get-accounts";
import adminClient from "../../graphql/admin-client";
import useLoadMore from "~/src/containers/admin-dashboard/hooks/load-more";
import DashboardScrollHandler from "~/src/components/dashboard-scroll-handler";

import {
  StyledAccountDetail,
  StyledAccountName,
  StyledHeading,
  StyledAccountWrapper,
  StyledAccountsListWrapper
} from "./styles";
import { StyledContainer } from "../styles";

const SCROLL_ELEMENT_ID = "account_list_scroll";

const AccountsList = ({ selectedAccountId, onSelect }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { data, fetchMore } = useQuery(GET_ACCOUNTS, {
    variables: { searchTerm, limit: 10, offset: 0 },
    client: adminClient
  });
  const dataLength = data?.accounts.length;

  const [handleLoadMore] = useLoadMore({
    dataLength,
    fetchMore,
    nameData: "accounts"
  });

  return (
    <StyledContainer>
      <RotaSearchInput
        placeholder="search account"
        onChange={value => {
          setSearchTerm(value);
        }}
      ></RotaSearchInput>
      <StyledHeading>Select an Account</StyledHeading>
      <DashboardScrollHandler
        onReachEnd={handleLoadMore}
        scrollElementId={SCROLL_ELEMENT_ID}
      >
        <StyledAccountsListWrapper id={SCROLL_ELEMENT_ID}>
          {data?.accounts?.map(({ id, accountName }) => (
            <StyledAccountWrapper
              key={id}
              onClick={() => {
                onSelect(id, accountName);
              }}
            >
              <StyledAccountName selected={id === selectedAccountId}>
                {accountName}
              </StyledAccountName>
              <StyledAccountDetail>ID: {id}</StyledAccountDetail>
            </StyledAccountWrapper>
          ))}
        </StyledAccountsListWrapper>
      </DashboardScrollHandler>
    </StyledContainer>
  );
};

export default AccountsList;

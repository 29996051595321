import styled from "styled-components";
import Button from "~/src/components/button";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";

export const Container = styled.div`
  width: 600px;
  padding: ${STYLE_CONSTS.UNIT_2};
`;

export const Header = styled.h2`
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
`;

export const Text = styled.p`
  text-align: center;
  color: ${COLORS.COOL_GREY};
  margin-bottom: ${({ hasMarginBottom }) =>
    hasMarginBottom && STYLE_CONSTS.UNIT_4};
`;

export const ButtonContainer = styled.div`
  text-align: center;
  padding: ${STYLE_CONSTS.UNIT_1} 0;
`;

export const StyledButton = styled(Button)`
  margin-right: ${STYLE_CONSTS.UNIT_2};
`;

import styled from "styled-components";

export const StyledContainer = styled.div`
  margin-right: 20px;
  width: 100%;
`;

export const StyledMainHeaderWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.neutral.light};
  border-radius: 0px !important;
  margin-bottom: 20px;
`;

export const StyledMainHeading = styled.p`
  font-size: 16px;
`;

export const StyledMainDetail = styled.p`
  weight: 400;
  font-size: 13px;
  color: ${({ theme }) => theme.neutral.light};
`;

export const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  height: calc(100vh - 370px);
`;

export const StyledFeaturesWrapper = styled.div`
  padding: ${({ noPadding }) => (noPadding ? "0px" : "20px")};
  border: ${({ noBorder, theme }) =>
    noBorder ? "none" : `1px solid ${theme.neutral.light}`};
  min-width: 62%;
  overflow: auto;

  @media only screen and (min-width: 1280px) {
    gap: 30px;
    min-width: 64%;
  }

  @media only screen and (min-width: 1580px) {
    min-width: 70%;
  }
`;

export const StyledFeatureHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  h3 {
    font-weight: 700;
    color: ${({ theme }) => theme.text.primary};
    font-size: 16px;
    margin: 0;
  }
`;

export const StyledWrapperList = styled.ul`
  max-height: calc(100vh - 500px);
  overflow: auto;

  input::placeholder {
    font-size: 13px;
  }
`;

import React from "react";
import { RotaModal, RotaButtonNew } from "@teamrota/rota-design";

import { Text, ButtonWrapper } from "./delete-modal.styles.js";

const DeleteModal = ({ onCancel, handleDelete }) => {
  return (
    <RotaModal isSmall onClose={onCancel}>
      <Text>Are you sure you want to delete this exclusion?</Text>
      <ButtonWrapper>
        <RotaButtonNew onClick={onCancel} variant="text">
          Cancel
        </RotaButtonNew>
        <RotaButtonNew onClick={handleDelete} isDanger>
          Delete
        </RotaButtonNew>
      </ButtonWrapper>
    </RotaModal>
  );
};

export default DeleteModal;

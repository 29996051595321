import React from "react";
import { useCurrency } from "~/src/containers/profile-loader";
import { removeNonNumbers } from "../util";

interface MoneyProps {
  value: string | number | undefined | null;
}

const Money: React.FC<MoneyProps> = ({ value }) => {
  const currency = useCurrency();

  if (value !== undefined && value !== null) {
    const num = parseFloat(removeNonNumbers(`${value}`));

    if (!isNaN(num)) {
      const str = num.toFixed(2);

      return (
        <div
          style={{
            position: "relative",
            textAlign: "right",
            padding: "5px 0px"
          }}
        >
          <div style={{ position: "absolute", left: "5px" }}>
            {currency.currencySymbol}
          </div>
          {str}
        </div>
      );
    }
  }

  return <>{value}</>;
};

export default Money;

import React from "react";
import { PartnerImage, PartnerInfo, Container, Text } from "./index.styles";

export default function Partner(props) {
  return (
    <div>
      <Text marginBottom>{`ID ${props.id}`}</Text>
      <Container>
        <PartnerImage src={props.logo} />
        <PartnerInfo>{props.accountName}</PartnerInfo>
      </Container>
    </div>
  );
}

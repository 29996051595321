import React from "react";
import Icon from "~/src/components/icon";

import {
  StyledToolTip,
  ToolTipIcon,
  ToolTipIconWrapper,
  ToolTipTrigger
} from "./styles";

let nextId = 0;

export default ({ children }) => {
  const id = `tool-tip-${nextId++}`;

  return (
    <>
      <ToolTipIconWrapper data-tip data-for={id}>
        <ToolTipTrigger>
          <ToolTipIcon
            name={Icon.names.INFO}
            size={Icon.sizes.SMALL}
            isNoGutter
            pointer
          />
        </ToolTipTrigger>
      </ToolTipIconWrapper>

      <StyledToolTip
        multiline
        id={id}
        place="bottom"
        type="light"
        effect="solid"
        isAlignToolTip
      >
        {children}
      </StyledToolTip>
    </>
  );
};

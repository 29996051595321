import React from "react";
import { StyledSurround, StyledWrapArrow } from "./styles";
import { iconNew } from "@teamrota/rota-design";

const { ChevronRight } = iconNew;
const Arrow = ({ isLeft, onClick }) => {
  const handleClick = e => {
    e.preventDefault();
    onClick();
  };

  return (
    <StyledSurround $left={isLeft} $reverse={isLeft} onClick={handleClick}>
      <StyledWrapArrow $reverse={isLeft}>
        <ChevronRight className="icon" />
      </StyledWrapArrow>
    </StyledSurround>
  );
};

export const RightArrow = props => Arrow({ ...props, isRight: true });
export const LeftArrow = props => Arrow({ ...props, isLeft: true });

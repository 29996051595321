import React from "react";
import { Redirect } from "react-router-dom";

import { Role } from "@teamrota/authlib";
import useAuth from "~/src/auth/hooks/use-auth";

export default () => {
  const { authed, roles } = useAuth();
  if (authed) {
    if (roles.includes(Role.REQUESTER)) {
      return <Redirect to="/request" />;
    } else if (roles.includes(Role.PROVIDER)) {
      return <Redirect to="/provide" />;
    } else if (roles.includes(Role.GENERAL_SETTINGS_VIEW)) {
      return <Redirect to="/settings" />;
    }
  }
};

import styled from "styled-components";
import { COLORS, flexCentered, STYLE_CONSTS } from "~/src/styles/config.style";
import Icon from "~/src/components/icon";

export const StyledIcon = styled(Icon)`
  vertical-align: middle;
`;

const styles = {
  input: {
    display: "none"
  },
  leftPanelContainer: {
    minWidth: "317px",
    height: "100%",
    backgroundColor: COLORS.WHITE,
    margin: "0 0 0 32px",
    borderRadius: "10px",
    boxShadow: STYLE_CONSTS.FLOATING_LARGE
  },
  avatarContainer: {
    height: "284px",
    borderBottom: "border: 1px solid #F1EFEF"
  },
  avatar: {
    ...flexCentered,
    margin: "43px 0 30px 0"
  },
  iconWrapper: {
    backgroundColor: "orange",
    padding: "7px",
    border: "1px solid white",
    borderRadius: "50%"
  },
  memberName: {
    ...flexCentered,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "20px",
    textAlign: "center",
    letterSpacing: "-0.248889px"
  },
  memberAddress: {
    ...flexCentered,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "20px",
    textAlign: "center",
    letterSpacing: "-0.248889px",
    color: "#A9ACB3"
  },
  rowWrapper: {
    width: "100%",
    padding: "21px 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  settingsStats: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 0",
    padding: "0 30px",
    border: "solid #F1EFEF",
    borderWidth: "1px 0 1px 0"
  },
  statsTitle: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "15px",
    color: "#939BA7"
  },
  statsNumber: {
    fontWeight: " 500",
    fontSize: " 15px",
    lineHeight: " 16px",
    textAlign: " right",
    letterSpacing: " -0.177778px",
    color: "#FDA53A"
  }
};

export default styles;

import { isEqual } from "lodash";
import React from "react";
import CalendarCluster from "../CalendarCluster";

const CalendarClusters = ({ calendarClusters, onEventClick }) => (
  <div>
    {calendarClusters.map((cluster, index) => (
      <CalendarCluster
        onEventClick={onEventClick}
        key={index}
        cluster={cluster}
      />
    ))}
  </div>
);

export default React.memo(
  CalendarClusters,
  ({ calendarClusters: prevClusters }, { calendarClusters: nextClusters }) =>
    isEqual(prevClusters, nextClusters)
);

import Icon from "~/src/components/icon";
import { COLORS } from "~/src/styles/config.style";
import { BOOKING_STATES } from "~/src/consts";

export const PREVENT_POP_UP_CLICK = "prevent-pop-up-click";
export const MAIN_CALENDAR_CONTAINER = "calendar-main-container";

export const MEMBER_GRID_ACTIONS = [
  {
    bookingActionType: BOOKING_STATES.ACCEPTED,
    label: "Accept",
    information: "",
    color: COLORS.GREEN,
    iconName: Icon.names.CHECK
  },
  {
    bookingActionType: BOOKING_STATES.WAITING_LIST,
    label: "Waiting list",
    shouldHaveToolTip: true,
    information:
      "The member will be moved to waiting list in case you need them later.",
    color: COLORS.CLOUDY_BLUE,
    iconName: Icon.names.CLOCK
  },
  {
    bookingActionType: BOOKING_STATES.PARTNER_DECLINED,
    label: "Decline",
    shouldHaveToolTip: true,
    information: "Once declined the member can no longer work on this shift.",
    color: COLORS.RED,
    iconName: Icon.names.UNCHECK
  }
];

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { handleErrors } from "~/src/utils/errors";

const GET_AVAILABLE_MEMBER_TYPES = gql`
  query getAvailableMemberTypes {
    account {
      id
      memberTypesAvailableForAccount {
        isCasualMemberTypeAvailable
        isPermanentMemberTypeAvailable
        isAgencyMemberTypeAvailable
        isContractorLimitedCompanyMemberTypeAvailable
        isContractorLimitedCompanyMemberTypeInfoRequired
        isContractorUmbrellaCompanyMemberTypeAvailable
        isContractorUmbrellaCompanyMemberTypeInfoRequired
        isContractorSelfEmployedMemberTypeAvailable
        isContractorSelfEmployedMemberTypeInfoRequired
      }
    }
  }
`;

export default graphql(GET_AVAILABLE_MEMBER_TYPES, {
  props: handleErrors(({ data: { loading, account } }) => ({
    memberTypesAvailableForAccount: account?.memberTypesAvailableForAccount,
    accountId: account?.id,
    isLoadingAccountMemberTypes: loading
  }))
});

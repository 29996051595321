import React, { useState } from "react";
import { RotaRadio } from "@teamrota/rota-design";

const RadioButtons = ({ onChange, value, isRoleRatesModal }) => {
  const [radioState, setRadioState] = useState(value);
  return (
    <>
      <RotaRadio
        label="Hourly rate"
        name="isDayRate"
        value={false}
        disabled={isRoleRatesModal && radioState}
        onChange={() => {
          setRadioState(false);
          onChange(false);
        }}
        checked={!radioState}
      />
      <RotaRadio
        label="Daily Rate"
        name="isDayRate"
        value={true}
        disabled={isRoleRatesModal && !radioState}
        onChange={() => {
          setRadioState(true);
          onChange(true);
        }}
        checked={radioState}
      />
    </>
  );
};

export default RadioButtons;

import React from "react";
import getOr from "lodash/fp/getOr";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ReactTags from "react-tag-autocomplete";

import { FilterOptions } from "../../notifications.styles";
import { onFilterOptionAdd, onFilterOptionDelete } from "../../reducer";

const bindActions = dispatch =>
  bindActionCreators(
    {
      onFilterOptionAdd,
      onFilterOptionDelete
    },
    dispatch
  );

const FilterSelect = props => (
  <FilterOptions>
    <ReactTags
      maxSuggestionsLength={100}
      handleInputChange={props.handleInputChange}
      placeholder={props.label}
      handleAddition={tag => props.onFilterOptionAdd({ name: props.name, tag })}
      handleDelete={index =>
        props.onFilterOptionDelete({ name: props.name, index })
      }
      tags={getOr([], props.name, props.selectedFilterOptions)}
      suggestions={getOr([], "suggestions", props)}
    />
  </FilterOptions>
);

export default connect(
  s => ({
    selectedFilterOptions: s.notifications.selectedFilterOptions
  }),
  bindActions
)(FilterSelect);

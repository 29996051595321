import { gql, useQuery } from "@apollo/client";

const GET_USERS = gql`
  query getUsers {
    account {
      id
      users {
        id
        firstName
        lastName
        photo
        phoneNumber
        email
        notes
        accountId
        pin
        createdAt
        account {
          id
          logo
          accountName
        }
      }
    }
  }
`;

export const useUsers = () => {
  const { loading, data } = useQuery(GET_USERS, {
    variables: {
      offset: 0,
      limit: 1000,
      orderByProp: "accountName"
    }
  });

  return { isUsersLoading: loading, users: data?.account?.users || [] };
};

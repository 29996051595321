import React from "react";
import { StyledMember } from "./styles";

const Member = ({ member, children }) => {
  return (
    <StyledMember key={member.id}>
      <div>
        <p>
          {member.firstName} {member.lastName}
        </p>
        <span>ID: {member.id}</span>
        <span>{member.email}</span>
      </div>
      {children}
    </StyledMember>
  );
};

export default Member;

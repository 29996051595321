import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { TIMESHEET_MEMBER_STATUS } from "@teamrota/rota-common";
import { currencySupport } from "@teamrota/rota-common";

const TIMESHEET_DETAILS = gql`
  query TimesheetsDetails(
    $id: ID!
    $memberId: ID
    $offset: Int!
    $limit: Int!
  ) {
    account {
      id
      currencyRegion
      timesheetDetails(
        id: $id
        memberId: $memberId
        offset: $offset
        limit: $limit
      ) {
        id
        memberId
        name
        memberAvatar
        roleName
        totalMinutes
        totalPay
        totalCharge
        payRate
        chargeRate
        status
        checkInTime
        checkOutTime
        status
        ratingOfMember
        feedbackForMember
        break
        purchaseOrderNumber
        isTurnedAway
        isNoShow
        memberId
        isNoShow
        isTurnedAway
        approvedAt
        approvedBy
        scheduledStartTime
        scheduledEndTime
        subvenueId
        subvenueName

        plannedSleepStartTime
        plannedSleepEndTime
        plannedSleepTimeMinutes

        sleepStartTime
        sleepEndTime
        sleepTimeMinutes

        disturbedHoursStart
        disturbedHoursEnd
        disturbedHoursMinutes
      }
    }
  }
`;

export interface MemberBreakdown {
  id: string;
  memberId: string;
  name: string;
  memberAvatar: string;
  roleName: string;
  totalMinutes: number;
  totalPay: number;
  totalCharge: number;
  payRate: number;
  chargeRate: number;
  status: keyof typeof TIMESHEET_MEMBER_STATUS;
  checkInTime: string;
  checkOutTime: string;
  ratingOfMember: number;
  feedbackForMember: string;
  break: number;
  purchaseOrderNumber: string;
  isTurnedAway: boolean;
  isNoShow: boolean;
  approvedAt: string;
  approvedBy: string;
  scheduledStartTime: string;
  scheduledEndTime: string;
  subvenueId: string;
  subvenueName: string;

  plannedSleepStartTime: string;
  plannedSleepEndTime: string;
  plannedSleepTimeMinutes: number;

  sleepStartTime: string;
  sleepEndTime: string;
  sleepTimeMinutes: number;

  disturbedHoursStart: string;
  disturbedHoursEnd: string;
  disturbedHoursMinutes: number;
}

interface Params {
  id: string;
  memberId: string;
}

export const useTimesheetBreakdown = ({ id, memberId }: Params) => {
  const { data, loading, error, refetch } = useQuery(TIMESHEET_DETAILS, {
    fetchPolicy: "cache-and-network",
    variables: { id, memberId, offset: 0, limit: 100 },
    onError: error => {
      throw error;
    }
  });

  const timesheetMembers: MemberBreakdown[] =
    data?.account?.timesheetDetails || [];

  const currencyCode =
    data?.account?.currencyRegion &&
    currencySupport[data?.account?.currencyRegion].code;

  return {
    timesheetMembers,
    currencyCode,
    loading,
    error,
    refetch
  };
};

import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const DashboardContainer = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.background.primary};
  overflow: hidden;
`;

export const SideNavWrapper = styled.div`
  z-index: ${STYLE_CONSTS.Z_INDEX_NAV_BAR};
  width: ${STYLE_CONSTS.MENU_SIZE};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
`;

export const TopNavWrapper = styled.div`
  z-index: ${STYLE_CONSTS.Z_INDEX_NAV_BAR};
  position: fixed;
  height: ${STYLE_CONSTS.MENU_SIZE};
  top: 0;
  left: 0;
  right: 0;
  ${({ shouldHideSideMenu }) =>
    !shouldHideSideMenu &&
    `
  padding-left: ${STYLE_CONSTS.MENU_SIZE}`};
`;

export const PageContentWrapper = styled.div`
  padding-top: ${STYLE_CONSTS.MENU_SIZE};
  ${({ shouldHideSideMenu }) =>
    !shouldHideSideMenu && `margin-left: ${STYLE_CONSTS.MENU_SIZE}`};
  display: block;
  position: relative;
  height: 100%;
  overflow: hidden;
`;

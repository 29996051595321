import React from "react";
import { hoursFromShift, isWithinTimeRangeHours } from "~/src/utils/time-utils";
import { RotaButtonNew } from "@teamrota/rota-design";

export default function OpenShiftButton(props) {
  const { shift } = props;
  let isOpenable = false;

  if (
    hoursFromShift(shift.startTime) > 36 &&
    isWithinTimeRangeHours(shift.postedAt, shift.delayHoursAlgoShift)
  ) {
    isOpenable = true;
  }
  return (
    <RotaButtonNew
      isLoading={props.isOpeningShift}
      onClick={props.onOpenShift}
      isDisabled={!isOpenable}
      variant="outlined"
    >
      Open shift
    </RotaButtonNew>
  );
}

import React from "react";
import { icon as RotaIcon, colors } from "@teamrota/rota-design";
import {
  StyledHoursWorkedContainer,
  StyledHoursWorkedText
} from "./hours-worked.styles";

const { ClockIcon } = RotaIcon;

const HoursWorked = ({ isLimitedHours, limitedHours, hoursCompleted }) => {
  return (
    <StyledHoursWorkedContainer>
      <ClockIcon color={colors.rotaOrange} />
      <StyledHoursWorkedText>
        {hoursCompleted || 0}
        {isLimitedHours && ` / ${limitedHours} `}
      </StyledHoursWorkedText>
    </StyledHoursWorkedContainer>
  );
};

export default HoursWorked;

import styled from "styled-components";
import { Column } from "~/src/components/grid";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";
import TextInput from "~/src/components/form-components/text-input";

export const StyledRow = styled.div``;

export const StyledTextInput = styled(TextInput)`
  color: ${({ theme }) => theme.primary.main};
`;

export const MultiFieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const FieldWrapper = styled.div`
  margin: 0 ${STYLE_CONSTS.UNIT_1};

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const ContentPadding = styled.div`
  padding: ${({ isVerticalPaddingOnly }) =>
    isVerticalPaddingOnly
      ? `${STYLE_CONSTS.UNIT_1} 0`
      : `${STYLE_CONSTS.UNIT_1}`};
  text-align: ${props => (props.isCenter ? "center" : "inherit")};
  width: ${props => (props.isHalfWide ? "50%" : "auto")};
`;

export const Divider = styled.div`
  height: ${STYLE_CONSTS.SMALL_DIVIDER_HEIGHT};
  border: ${STYLE_CONSTS.SMALL_DIVIDER_BORDER_LIGHT};
  margin: ${({ isMarginless }) =>
    isMarginless ? null : `${STYLE_CONSTS.UNIT_2} 0`};
`;

// Designs want bottom margin to be 30px
export const StyledGrid = styled(Column)`
  padding-bottom: ${STYLE_CONSTS.UNIT_5};
`;

export const TitleContainer = styled.div`
  margin: ${STYLE_CONSTS.UNIT_3} 0;
`;

export const VenueButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;

  > * {
    margin-left: ${STYLE_CONSTS.UNIT_2};
  }
`;

export const Title = styled.span`
  margin-left: ${STYLE_CONSTS.UNIT_2};
  font-size: ${STYLE_CONSTS.FONT_LARGE};
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_MEDIUM};
  color: ${({ color }) => (COLORS[color] ? COLORS[color] : COLORS.COOL_GREY)};
`;

export const SubTitle = styled.span`
  margin-left: ${STYLE_CONSTS.UNIT_2};
  font-size: ${({ fontSize }) => fontSize || STYLE_CONSTS.FONT_LARGE};
  font-weight: ${({ fontWeight }) => fontWeight || STYLE_CONSTS.FONT_WEIGHT};
  color: ${({ color }) => color || COLORS.COOL_GREY};
`;

export const SetUpWrapperDiv = styled.div`
  margin: 0 0 41px 0;
  padding: 0 12px 0 0;
`;

export const SetUpRowDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

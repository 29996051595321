import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  useRouteMatch,
  Redirect
} from "react-router-dom";

import * as Modules from "./modules";

import StyledAdminDashboard from "./admin-dashboard.styles";
import { RotaSnackBar } from "@teamrota/rota-design";

const AdminDashboard = () => {
  let { path } = useRouteMatch();
  const [snack, setSnack] = useState({});

  useEffect(() => {
    if (snack.message !== "") {
      const timer = setTimeout(() => {
        setSnack({});
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [snack]);

  const navOptions = [
    {
      name: "GDPR Removal",
      to: `${path}/GDPR-removals`,
      Component: Modules.GDPRRemovals
    },
    {
      name: "Optional Account Features",
      to: `${path}/enabling-optional-features`,
      Component: Modules.EnablingOptionalFeatures
    },
    {
      name: "Optional User Features",
      to: `${path}/enabling-user-features`,
      Component: Modules.EnablingUserFeatures
    },
    {
      name: "Create Account",
      to: `${path}/create-accounts`,
      Component: Modules.CreateAccounts
    },
    {
      name: "Timesheets",
      to: `${path}/new-timesheets`,
      Component: Modules.Timesheets
    },
    {
      name: "Add Accounts Tags",
      to: `${path}/add-accounts-tags`,
      Component: Modules.AddAccountsTags
    },
    {
      name: "Add Divisions",
      to: `${path}/add-divisions`,
      Component: Modules.AddDivisions
    },
    {
      name: "Add Rebook Roles",
      to: `${path}/add-rebook-roles`,
      Component: Modules.RebookRoles
    },
    {
      name: "Update Members Email",
      to: `${path}/update-members-email`,
      Component: Modules.UpdateMembersEmail
    },
    {
      name: "Resend Invite Email",
      to: `${path}/resend-invite-email`,
      Component: Modules.ResendInviteEmail
    }
  ];

  return (
    <Router>
      <StyledAdminDashboard>
        <aside>
          <h2>Options</h2>
          <ul>
            {navOptions.map(({ name, to }) => (
              <li key={name}>
                <NavLink activeClassName="active" to={to}>
                  {name}
                </NavLink>
              </li>
            ))}
          </ul>
        </aside>
        <div>
          <Switch>
            <Route
              path={path}
              exact
              render={() => <Redirect to={`${path}/GDPR-removals`} />}
            />
            {navOptions.map(({ name, to, Component }) => (
              <Route
                key={name}
                path={to}
                render={() => <Component setSnack={setSnack} />}
              />
            ))}
          </Switch>
        </div>
      </StyledAdminDashboard>
      <RotaSnackBar
        snackOpen={!!snack?.message}
        message={snack.message}
        severity={snack.severity}
        onClose={() => {
          setSnack({});
        }}
      />
    </Router>
  );
};

export default AdminDashboard;

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

export const CREATE_UPDATE_RELATIONSHIP = gql`
  mutation Mutation($poolType: poolTypeEnumType!, $memberId: ID!) {
    createOrUpdateRelationship(poolType: $poolType, memberId: $memberId) {
      poolType
      memberId
    }
  }
`;

export default graphql(CREATE_UPDATE_RELATIONSHIP, {
  name: "createOrUpdateRelationship"
});

import { gql } from "@apollo/client";

export const UPDATE_USER = gql`
  mutation createUpdateUser(
    $id: ID
    $firstName: String
    $lastName: String
    $email: String
    $phoneNumber: String
    $notes: String
    $shouldRegeneratePin: Boolean
    $canRecieveAllEmails: Boolean
    $applicantAlerts: Boolean
    $hourlyAlerts: Boolean
    $pin: String
    $venueIds: [Int]
  ) {
    createUpdateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      phoneNumber: $phoneNumber
      notes: $notes
      shouldRegeneratePin: $shouldRegeneratePin
      canRecieveAllEmails: $canRecieveAllEmails
      applicantAlerts: $applicantAlerts
      hourlyAlerts: $hourlyAlerts
      pin: $pin
      venueIds: $venueIds
    ) {
      id
      firstName
      lastName
      email
      phoneNumber
      notes
      pin
      canRecieveAllEmails
      usersMeta {
        applicantAlerts
        hourlyAlerts
      }
      venueIds
    }
  }
`;

import { gql } from "@apollo/client";

export default gql`
  fragment AddressFragment on address {
    houseNumber
    streetName
    town
    postcode
    county
    country
  }
`;

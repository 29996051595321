import { applyMiddleware, createStore, combineReducers, compose } from "redux";
import reduxPromise from "redux-promise";
import thunk from "redux-thunk";
import { reducer as formReducer } from "redux-form";
import { createLogger } from "redux-logger";

import { SHOW_DEBUG } from "./config";
import appReducer from "./reducers/app";
import calendarReducer from "./containers/requester-calendar/reducer";
import shiftModalReducer from "./containers/requester-calendar/shift-modal/reducer";
import provideReducer from "./containers/provide/reducer";
import poolsReducer from "./containers/pools/reducer";
import myStaffReducer from "./containers/my-staff/reducer";
import timesheetsReducer from "./containers/timesheets/reducer";
import notificationsReducer from "./containers/notifications-send/reducer";
import partnersReducer from "./containers/partners/reducer";
import memberEditReducer from "./containers/modals/view-edit-member/reducer";
import reportsReducer from "./containers/reports/reducer";
import notificationsHistory from "./containers/notifications-history/reducer";
import schedulerReducer from "./containers/scheduler/reducer";
import { persistStore, persistReducer, createTransform } from "redux-persist";
import storage from "redux-persist/lib/storage";

const logger = SHOW_DEBUG ? [createLogger({ collapsed: true })] : [];
const middleware = [reduxPromise, thunk, ...logger];

const createStoreWithMiddleware = compose(
  applyMiddleware(...middleware),
  window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : f => f
)(createStore);

const reducer = combineReducers({
  app: appReducer,
  calendar: calendarReducer,
  pools: poolsReducer,
  myStaff: myStaffReducer,
  shiftModal: shiftModalReducer,
  provide: provideReducer,
  timesheets: timesheetsReducer,
  form: formReducer,
  notifications: notificationsReducer,
  partners: partnersReducer,
  modalEditMember: memberEditReducer,
  reports: reportsReducer,
  notificationsHistory,
  scheduler: schedulerReducer
});

const provideTransform = createTransform(
  // eslint-disable-next-line
  (inboundState, key) => {
    // eslint-disable-next-line
    const { startTime, endTime, ...filtersRest } = inboundState.filters || {};
    return {
      ...inboundState,
      filters: filtersRest
    };
  },
  // eslint-disable-next-line
  (outboundState, key) => {
    return outboundState;
  },
  { whitelist: ["provide"] }
);

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["provide"],
  transforms: [provideTransform]
};

const persistedReducer = persistReducer(persistConfig, (state, action) => {
  if (action.type === "RESET_STATE") {
    state = undefined;
  }
  return reducer(state, action);
});

const store = createStoreWithMiddleware(persistedReducer);
export const persistor = persistStore(store);

export default store;

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const UPDATE_BOOKING = gql`
  mutation updateBooking(
    $id: ID!
    $state: String
    $startOnPoolManagerOverride: Date
    $endOnPoolManagerOverride: Date
    $breakOnPoolManagerOverride: Int
    $isUnderReview: Boolean
    $ratingOfMember: Int
    $ratingOfManager: Int
    $policy: PolicyInputType
  ) {
    updateBooking(
      id: $id
      state: $state
      startOnPoolManagerOverride: $startOnPoolManagerOverride
      endOnPoolManagerOverride: $endOnPoolManagerOverride
      breakOnPoolManagerOverride: $breakOnPoolManagerOverride
      isUnderReview: $isUnderReview
      ratingOfManager: $ratingOfManager
      ratingOfMember: $ratingOfMember
      policy: $policy
    ) {
      id
      state
      bookingState {
        id
        startOnPoolManagerOverride
        endOnPoolManagerOverride
        breakOnPoolManagerOverride
      }
      isUnderReview
    }
  }
`;
export default graphql(UPDATE_BOOKING, {
  name: "updateBooking",
  props: props => ({
    updateBooking: async (bookingId, values) => {
      const bookingMutationResult = await props.updateBooking({
        variables: {
          ...values,
          id: bookingId
        },
        refetchQueries: ["getBooking"]
      });
      return bookingMutationResult.data.updateBooking;
    }
  })
});

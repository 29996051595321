import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";

const GET_PARTNER_OWNERS = gql`
  query getPartnerOwners {
    account {
      id
      users {
        id
        firstName
        lastName
      }
    }
  }
`;

export const usePartnerOwners = () => {
  const { data, loading, error, refetch } = useQuery(GET_PARTNER_OWNERS, {
    onError: error => {
      throw error;
    }
  });

  const partnerOwners = data?.account?.users ?? [];

  return {
    partnerOwners,
    loading,
    error,
    refetch
  };
};

import React from "react";
import { ZonedDate } from "@teamrota/rota-common";
import { Terms, Link } from "./terms-and-conditions.styles";

export default function TermsAndConditions() {
  const year = new ZonedDate().getFullYear();
  const termsUrl = "https://doc.rota.com/policy/Terms+and+Conditions.pdf";
  return (
    <Terms>
      © {year} Be Rota Ltd. |{" "}
      <Link href={termsUrl} target="_blank">
        Terms & Conditions
      </Link>
    </Terms>
  );
}

import styled from "styled-components";
import Icon from "~/src/components/icon";

export const ManageShiftsHeaderWrapper = styled.div`
  display: flex;
`;

export const StyledIcon = styled(Icon).attrs({
  size: 13,
  color: Icon.colors.BLACK,
  name: Icon.names.SHIFTS
})``;

export const Label = styled.div`
  padding-left: 5px;
  line-height: 15px;
`;

export const NotifierWrapper = styled.div`
  margin-left: 4px;
  margin-top: -2px;
`;

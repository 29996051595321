import * as React from "react";
import { useState } from "react";
import { RotaButton } from "@teamrota/rota-design";
import toNumber from "lodash/fp/toNumber";
import isInteger from "lodash/fp/isInteger";
import { flow } from "lodash/fp";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NOTIFICATION_TYPES } from "~/src/consts";
import { STYLE_CONSTS } from "~/src/styles/config.style";
import { errorModal } from "~/src/utils/errors";
import asyncConfirm from "~/src/utils/async-confirm";
import PageHeader from "~/src/components/page-header";
import Modal from "~/src/components/modal";
import ComposeMessage from "./components/compose-message";
import Members from "./components/members";
import AddMembers from "./components/add-members";
import Shifts from "./components/shifts";
import Filters from "./components/filters";
import {
  onToggleNotificationType,
  onComposeMessage,
  onUpdateRecipients,
  onUpdateActiveTab,
  onResetReducer,
  setIsToAllMembers
} from "./reducer";
import getMembers from "./graphql/get-members";
import sendNotifications from "./graphql/send-notifications";
import {
  Container,
  StyledButton,
  Divider,
  Panel,
  Title,
  ModalContent,
  ButtonContainer,
  Scroll
} from "./notifications.styles";

const NOTIFICATION_TYPE_BUTTONS = [
  {
    label: "Email",
    value: NOTIFICATION_TYPES.EMAIL
  },
  {
    label: "SMS",
    value: NOTIFICATION_TYPES.TEXT
  },
  {
    label: "Push",
    value: NOTIFICATION_TYPES.PUSH_NOTIFICATION
  }
];

const LIMIT = 18;

const Notifications = ({
  members,
  isToAllMembers,
  setIsToAllMembers,
  messageBody,
  activeNotificationTypes,
  memberSearchText,
  selectedFilters,
  selectedFilterOptions,
  shiftId,
  recipientMembers,
  subject,
  sendNotifications,
  onResetReducer,
  totalResults,
  isSmsOptionAvailable,
  onUpdateActiveTab,
  activeTab,
  areMembersLoading,
  fetchMore,
  onUpdateRecipients,
  onToggleNotificationType,
  onComposeMessage
}) => {
  const [composeOpen, setComposeOpen] = useState(false);

  const onSubmitMessage = async () => {
    const body = messageBody.toString("markdown");

    const errors = [];

    if (!body.trim().length) {
      errors.push("You must supply a message");
    }

    let shiftId = null;
    if (
      activeNotificationTypes.includes(NOTIFICATION_TYPES.PUSH_NOTIFICATION) &&
      memberSearchText.includes(",")
    ) {
      shiftId = toNumber(shiftId);
      if (shiftId && !isInteger(shiftId)) {
        errors.push("The supplied shift ID is not a number");
      } else if (!shiftId) {
        errors.push(
          "Must provide shift ID when providing a list of member IDs"
        );
      }
    }

    if (!isToAllMembers && !recipientMembers.length) {
      errors.push("You must choose some members");
    }

    if (errors.length) {
      errorModal("Notifications", errors);
      return;
    }

    if (
      await asyncConfirm(getConfirmMessage(), {
        confirmButtonText: "Send",
        isConfirmButtonGreen: true,
        shouldHideSubText: true
      })
    ) {
      try {
        await sendNotifications({
          memberIds: recipientMembers.map(member => member.id),
          isToAllMembers: isToAllMembers,
          body, // Raw body (no formatting) for text and push notifs
          subject: subject,
          bodyHTML: messageBody.toString("html"), // For emails
          notificationTypes: activeNotificationTypes,
          shiftId,
          memberSearchText: memberSearchText,
          selectedFilters: selectedFilters,
          selectedFilterOptions: selectedFilterOptions
        });

        onResetReducer();
        setComposeOpen(false);
      } catch (e) {
        errorModal(e);
      }
    }
  };

  const onToggleAllMembers = () => {
    setIsToAllMembers(!isToAllMembers);
  };

  function getConfirmMessage() {
    const memberCount = getMemberCount();

    const plural = memberCount > 1 ? "s" : "";

    return `${memberCount} recipient${plural} to receive: ${getNotificationLabels()}`;
  }

  function getNotificationLabels() {
    return activeNotificationTypes
      .map(
        notif =>
          (NOTIFICATION_TYPE_BUTTONS.find(({ value }) => value === notif) || {})
            .label
      )
      .join(", ");
  }

  function getMemberCount() {
    const memberCount = isToAllMembers ? totalResults : recipientMembers.length;

    return memberCount;
  }

  const filterSmsOption = ({ label }) =>
    label !== "SMS" || isSmsOptionAvailable;

  return (
    <div>
      <PageHeader
        alignItems="center"
        title="New message"
        subtext="Sends communications"
      />
      <Container flexDirection="column" margin={`0 ${STYLE_CONSTS.UNIT_4}`}>
        <div>
          <Container>
            <Container
              width="360px"
              flex="none"
              flexDirection="column"
              margin={`0 ${STYLE_CONSTS.UNIT_3} 0 0`}
            >
              <Filters
                onUpdateActiveTab={onUpdateActiveTab}
                activeTab={activeTab}
              />
            </Container>
            <Container
              flex="1"
              flexDirection="column"
              justifyContent="flex-start"
            >
              <Title>Add Recipients</Title>
              <Panel flex="1" margin={`0 0 ${STYLE_CONSTS.UNIT_4} 0`}>
                {activeTab === "members" && (
                  <AddMembers
                    areMembersLoading={areMembersLoading}
                    members={members}
                    totalResults={totalResults}
                    fetchMore={fetchMore}
                    limit={LIMIT}
                    onToggleAllMembers={onToggleAllMembers}
                    isToAllMembers={isToAllMembers}
                  />
                )}
                {activeTab === "shifts" && <Shifts />}
              </Panel>
              <Panel>
                <h3>
                  Recipients (
                  {isToAllMembers ? totalResults : recipientMembers.length})
                </h3>
                <Divider />
                <Scroll>
                  <Members
                    members={isToAllMembers ? members : recipientMembers}
                    onUpdateRecipients={onUpdateRecipients}
                  />
                </Scroll>
              </Panel>
              {(recipientMembers.length > 0 || isToAllMembers) && (
                <ButtonContainer>
                  <RotaButton size="large" onClick={() => setComposeOpen(true)}>
                    Message members
                  </RotaButton>
                </ButtonContainer>
              )}
            </Container>
          </Container>
        </div>

        <Modal isOpen={composeOpen} onClose={() => setComposeOpen(false)}>
          <ModalContent>
            Type&nbsp;&nbsp;
            {NOTIFICATION_TYPE_BUTTONS.filter(filterSmsOption).map(type => (
              <StyledButton
                key={type.value}
                isOutline={!activeNotificationTypes.includes(type.value)}
                onClick={() => onToggleNotificationType(type.value)}
              >
                {type.label}
              </StyledButton>
            ))}
          </ModalContent>

          <ComposeMessage
            onComposeMessage={onComposeMessage}
            subject={subject}
            messageBody={messageBody}
            shiftId={shiftId}
            onSubmit={onSubmitMessage}
            activeNotificationTypes={activeNotificationTypes}
            canSendShiftID={memberSearchText.includes(",")}
          />
        </Modal>
      </Container>
    </div>
  );
};

const bindActions = dispatch =>
  bindActionCreators(
    {
      onToggleNotificationType,
      onComposeMessage,
      onUpdateRecipients,
      onUpdateActiveTab,
      onResetReducer,
      setIsToAllMembers
    },
    dispatch
  );

export default flow(
  sendNotifications,
  getMembers(LIMIT),
  connect(
    s => ({
      activeNotificationTypes: s.notifications.activeNotificationTypes,
      subject: s.notifications.subject,
      messageBody: s.notifications.messageBody,
      shiftId: s.notifications.shiftId,
      recipientMembers: s.notifications.recipientMembers,
      activeTab: s.notifications.activeTab,
      memberSearchText: s.notifications.memberSearchText,
      selectedFilters: s.notifications.selectedFilters,
      selectedFilterOptions: s.notifications.selectedFilterOptions,
      isToAllMembers: s.notifications.isToAllMembers
    }),
    bindActions
  )
)(Notifications);

import React from "react";
import { RotaButtonNew, RotaModal } from "@teamrota/rota-design";

import {
  StyledBinIconWrap,
  StyledMainText,
  StyledRedBar,
  StyledSubText,
  StyledButtonWrapper
} from "~/src/containers/roles/styles";

const DeleteModal = ({
  onCancel,
  deleteRoleName,
  handleDeleteRole,
  deleteRoleId
}) => {
  return (
    <RotaModal isSmall onClose={onCancel}>
      <StyledBinIconWrap>
        <img src={"/images/bin.svg"} />
      </StyledBinIconWrap>
      <StyledMainText>
        Are you sure you want to delete the role "{deleteRoleName}"?
      </StyledMainText>
      <StyledRedBar />
      <StyledSubText>Deleting this role is not reversible.</StyledSubText>
      <StyledButtonWrapper>
        <RotaButtonNew onClick={onCancel} variant="outlined">
          Cancel
        </RotaButtonNew>
        <RotaButtonNew onClick={() => handleDeleteRole(deleteRoleId)} isDanger>
          Delete
        </RotaButtonNew>
      </StyledButtonWrapper>
    </RotaModal>
  );
};

export default DeleteModal;

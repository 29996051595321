import styled from "styled-components";
import { icon } from "@teamrota/rota-design";

const { ArrowDown } = icon;

export const StyledWrapRole = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  border-left: 1px solid ${({ theme }) => theme.neutral.lighter};
  border-right: 1px solid ${({ theme }) => theme.neutral.lighter};
  padding: 0 8px;
`;

export const StyledGridCell = styled.div`
  grid-column: ${({ column }) => column};
  grid-row: ${({ row }) => row};
  white-space: nowrap;
  height: 30px;
  padding: 10px;
  color: ${({ color }) => color};
  font-size: ${({ font }) => font};
  display: ${({ display }) => display};
  justify-content: ${({ justify }) => justify};
`;

export const StyledSpan = styled.span`
  white-space: nowrap;
  padding-right: 8px;
  color: ${({ theme }) => theme.neutral.main};
  font-size: 14px;
`;

export const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(4, 1fr);
  border: 1px solid ${({ theme }) => theme.neutral.lighter};
  padding: 0 0 12px 0;
  overflow: auto;
`;

export const StyledWrapSubtitle = styled.div`
  grid-column-start: 1;
  grid-row-start: 1;
  white-space: nowrap;
  height: 30px;
  font-size: 14px;
  border: 1px solid ${({ theme }) => theme.neutral.lighter};
  border-top-color: transparent;
  border-left-color: transparent;
  font-weight: bold;
  padding-left: 12px;
  display: flex;
  align-items: center;
`;

export const StyledWrapTag = styled.div`
  display: flex;
  overflow: ${({ overflow }) => overflow};
  flex-wrap: ${({ wrap }) => wrap};
  align-items: center;
  margin: ${({ margin }) => margin};
`;

export const StyledRole = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.neutral.main};
  align-items: center;
`;

export const StyledSpanWrap = styled.span`
  display: flex;
  color: ${({ theme }) => theme.text.primary};
  font-size: 18px;
  font-weight: 700;
  align-items: center;
  margin: 5px 0;
`;

export const StyledWrapModalEditTags = styled.div`
  border-top: 1px solid ${({ theme }) => theme.neutral.dark};
  padding: 28px 0 0;
  margin-top: 33px;
`;

export const StyledWrapTagsTitle = styled.p`
  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.text.primary};
`;

export const StyledWrapButtonFixed = styled.span`
  width: 100%;
  background-color: ${({ theme }) => theme.white};
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 16px 48px 48px;
  border-radius: 4px;
`;

export const StyledArrowDown = styled(ArrowDown)`
  cursor: pointer;
  margin-left: 5px;
`;

import React from "react";
import Icon from "../../icon";
import { TextareaElement, MultilineInput } from "./textarea.styles";
import {
  InputWrapper,
  IconWrapper,
  InputInnerWrapper,
  Label,
  ErrorLabel
} from "../input-styles";
import { useTheme } from "styled-components";

const Textarea = ({
  onChange,
  onChangeValue,
  value,
  defaultValue,
  type,
  placeholder,
  onRegister,
  label,
  onFocus,
  onBlur,
  isInline,
  isSmall,
  isError,
  isRounded,
  iconRight,
  iconLeft,
  isRequired,
  isMultilineAuto,
  isBorderless,
  errorLabel,
  onKeyDown,
  rowCount,
  isDisabled,
  isExtendable,
  maxRows,
  minRows,
  overrideOrange,
  isUnderline
}) => {
  const theme = useTheme();

  const Field = isMultilineAuto ? MultilineInput : TextareaElement;

  const error = isError || Boolean(errorLabel);

  return (
    <InputWrapper>
      <Label isVisible={Boolean(label)}>{label}</Label>
      <InputInnerWrapper>
        {iconLeft && (
          <IconWrapper isSmall={isSmall} isLeft>
            {iconLeft}
          </IconWrapper>
        )}
        <Field
          bgColor={isDisabled}
          ref={onRegister}
          onKeyDown={onKeyDown}
          isRounded={isRounded}
          isError={error}
          value={value}
          defaultValue={defaultValue}
          type={type || "text"}
          hasIconRight={iconRight}
          hasIconLeft={iconLeft}
          disabled={isDisabled}
          onFocus={onFocus}
          onBlur={onBlur}
          isInline={isInline}
          isSmall={isSmall}
          isBorderless={isBorderless}
          isUnderline={isUnderline}
          onChange={handleChange(onChange, onChangeValue)}
          placeholder={placeholder}
          rows={rowCount}
          required={isRequired}
          fontColor={overrideOrange ? theme.text.primary : theme.primary.main}
          isExtendable={isExtendable}
          maxRows={maxRows}
          minRows={minRows}
        />
        {iconRight && (
          <IconWrapper isSmall={isSmall} isRight>
            {iconRight}
          </IconWrapper>
        )}
      </InputInnerWrapper>
      {errorLabel && <ErrorLabel>{errorLabel}</ErrorLabel>}
    </InputWrapper>
  );
};

Textarea.iconNames = Icon.names;

const handleChange = (onChange, onChangeValue) => {
  return e => {
    if (onChange) onChange(e);
    if (onChangeValue) onChangeValue(e.target ? e.target.value : e);
  };
};

export default Textarea;

import React from "react";
import { camelCaseToNormalText } from "~/src/utils/formatting";
import { FormattedLog, Bullet, Highlight } from "../../index.styles";

export default props => (
  <FormattedLog>
    <Bullet>&bull;</Bullet>
    <Highlight>{camelCaseToNormalText(props.notificationType)}</Highlight>
  </FormattedLog>
);

import React, { useEffect } from "react";

import { CHANGE_PASSWORD_URL } from "~/src/config";
import { useNavigationContext } from "~/src/nav";

import useAuth from "~/src/auth/hooks/use-auth";
import Spinner from "~/src/components/full-screen-spinner";
import { useHistory } from "react-router";

function ChangePassword() {
  const auth = useAuth();
  const navigationContext = useNavigationContext();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      if (navigationContext.isInternalNavigation) {
        const form = document.createElement("form");

        form.action = CHANGE_PASSWORD_URL;
        form.method = "POST";

        const input = document.createElement("input");

        input.type = "hidden";
        input.name = "token";

        input.value = auth.token;

        form.appendChild(input);

        document.body.appendChild(form);
        form.submit();
      } else {
        history.push("/");
      }
    })();
  }, [navigationContext.isInternalNavigation, ...auth.deps]);

  return <Spinner />;
}

export default ChangePassword;

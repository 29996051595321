import React, { useEffect, useMemo, useState } from "react";
import SwitchInput from "~/src/components/form-field/inputs/switch-input";
import { RotaButton, RotaDropdown } from "@teamrota/rota-design";
import {
  Container,
  Row,
  InputContainer,
  IconContainer,
  RowsContainer
} from "./index.styles";
import { RotaButtonIcon } from "@teamrota/rota-design";
import { usePayrollBreaks } from "~/src/containers/payroll-breaks/graphql/use-payroll-breaks.tsx";
import usePartnerVenues from "../hooks/usePartnerVenues";
import useUpdatePartnerBreakRules from "./hooks/useUpdatePartnerBreakRules";
import { usePartnerBreakRules } from "./hooks/usePartnerBreakRules";

const PartnerBreaks = ({
  targetAccountId,
  partnerPayrollBreakId,
  setSelectedPartnerPayrollBreakId
}) => {
  const [isBreakForAllVenues, setIsBreakForAllVenues] = useState(false);
  const [accountBreakRule, setAccountBreakRule] = useState("");
  const [breakRules, setBreakRules] = useState([
    { venueId: null, payrollBreakId: null }
  ]);

  const {
    updatePartnerBreakRules,
    loading: updateLoading
  } = useUpdatePartnerBreakRules();

  const {
    partnerBreakRules,
    loading: partnerBreakRulesLoading,
    loadingMore,
    hasMore,
    loadMore,
    error
  } = usePartnerBreakRules(targetAccountId);

  useEffect(() => {
    if (partnerBreakRules.length > 0) {
      setBreakRules(
        partnerBreakRules.map(rule => ({
          venueId: rule.venue.id,
          payrollBreakId: rule.payrollBreak.id
        }))
      );
    }
  }, [partnerBreakRules]);

  const handleSave = async () => {
    setSelectedPartnerPayrollBreakId(
      isBreakForAllVenues ? accountBreakRule : null
    );
    await updatePartnerBreakRules({
      partnerId: targetAccountId,
      applyToAllVenues: isBreakForAllVenues,
      payrollBreakId: accountBreakRule,
      partnerBreakRulesInput: breakRules.filter(
        item => item?.venueId && item?.payrollBreakId
      )
    });
  };

  useEffect(() => {
    if (partnerPayrollBreakId) {
      setAccountBreakRule(partnerPayrollBreakId);
      setIsBreakForAllVenues(true);
    }
  }, []);

  const { payrollBreaks, loading: payrollBreaksLoading } = usePayrollBreaks("");
  const { venues, loading: venuesLoading } = usePartnerVenues({
    partnerId: targetAccountId
  });

  const getOptions = array =>
    array?.map(item => ({ label: item.name, value: item.id }));

  const getFilteredVenueOptions = (allVenues, currentIndex) => {
    const selectedVenues = breakRules
      .filter((_, index) => index !== currentIndex)
      .map(rule => rule.venueId);
    return allVenues.filter(venue => !selectedVenues.includes(venue.id));
  };

  const breakOptions = getOptions(payrollBreaks);

  const handleAddRule = () => {
    setBreakRules([...breakRules, { venueId: null, payrollBreakId: null }]);
  };

  const handleDeleteRule = index => {
    const newRules = breakRules.filter((_, i) => i !== index);
    if (newRules.length === 0) {
      setBreakRules([{ venueId: null, payrollBreakId: null }]);
    } else {
      setBreakRules(newRules);
    }
  };

  const handleRuleChange = (index, field, value) => {
    const newRules = [...breakRules];
    newRules[index][field] = value;
    setBreakRules(newRules);
  };

  const availableVenues = useMemo(() => {
    return getFilteredVenueOptions(venues, -1);
  }, [venues, breakRules]);

  const isAddButtonDisabled = () => {
    const lastRule = breakRules[breakRules.length - 1];
    return (
      isBreakForAllVenues ||
      !lastRule.venueId ||
      !lastRule.payrollBreakId ||
      availableVenues.length === 0
    );
  };

  const isSaveButtonDisabled = () => {
    if (isBreakForAllVenues) {
      return !accountBreakRule;
    }
    if (
      breakRules.length === 1 &&
      !breakRules[0].venueId &&
      !breakRules[0].payrollBreakId
    ) {
      return false;
    }
    const lastRule = breakRules[breakRules.length - 1];
    return !lastRule.venueId || !lastRule.payrollBreakId;
  };

  return (
    <Container>
      <Row>
        <InputContainer>
          <SwitchInput
            label="Same break for all venues"
            name="isBreakForAllVenues"
            input={{
              value: isBreakForAllVenues,
              onChange: v => {
                setIsBreakForAllVenues(v);
              }
            }}
          />
        </InputContainer>

        <InputContainer>
          {!payrollBreaksLoading && (
            <RotaDropdown
              placeholder="Select break rule"
              label="Select break rule"
              options={breakOptions}
              isError={false}
              value={accountBreakRule}
              isDisabled={!isBreakForAllVenues}
              onChange={value => {
                setAccountBreakRule(value);
              }}
            />
          )}
        </InputContainer>
      </Row>

      <RowsContainer>
        {!venuesLoading &&
          !payrollBreaksLoading &&
          breakRules?.map((rule, index) => (
            <Row key={index}>
              <InputContainer>
                <RotaDropdown
                  placeholder="Select venue"
                  options={getOptions(getFilteredVenueOptions(venues, index))}
                  isError={false}
                  value={rule.venueId}
                  isDisabled={isBreakForAllVenues || venuesLoading}
                  onChange={value => handleRuleChange(index, "venueId", value)}
                />
              </InputContainer>

              <InputContainer>
                <RotaDropdown
                  placeholder="Select break rule"
                  options={breakOptions}
                  isError={false}
                  value={rule.payrollBreakId}
                  isDisabled={isBreakForAllVenues}
                  onChange={value =>
                    handleRuleChange(index, "payrollBreakId", value)
                  }
                />
              </InputContainer>
              <IconContainer>
                <RotaButtonIcon
                  icon="delete"
                  onClick={() => handleDeleteRule(index)}
                  disabled={
                    isBreakForAllVenues ||
                    (breakRules.length === 1 &&
                      !breakRules[0].venueId &&
                      !breakRules[0].payrollBreakId)
                  }
                />
              </IconContainer>
            </Row>
          ))}
        <RotaButton
          disabled={isAddButtonDisabled()}
          variant={"outlined"}
          onClick={handleAddRule}
        >
          Add another
        </RotaButton>
      </RowsContainer>

      <RotaButton
        style={{ marginLeft: "auto" }}
        disabled={isSaveButtonDisabled() || partnerBreakRulesLoading}
        onClick={handleSave}
        loading={updateLoading}
      >
        Save
      </RotaButton>

      {hasMore && (
        <RotaButton
          onClick={loadMore}
          loading={loadingMore}
          disabled={!hasMore || loadingMore}
        >
          Load More
        </RotaButton>
      )}

      {error && <p>Error: {error.message}</p>}
    </Container>
  );
};

export default PartnerBreaks;

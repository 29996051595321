import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { flow } from "lodash";

import { addAuthVars } from "@teamrota/authlib";

import { withAuth } from "~/src/auth/hoc";
import { handleErrors } from "~/src/utils/errors";

import RoleRateFragment from "~/src/graphql/fragments/role-rate/role-rate.fragment";

export const GET_ALL_ROLE_RATES = addAuthVars(gql`
  ${RoleRateFragment}
  query getAllRoleRates {
    user {
      id
      account {
        id
        allRoleRates {
          ...RoleRateFragment
        }
      }
    }
  }
`);

export default flow(
  graphql(GET_ALL_ROLE_RATES, {
    options: ({ auth }) => ({
      fetchPolicy: "network-only",
      variables: auth.addVals(GET_ALL_ROLE_RATES)
    }),
    props: handleErrors(({ data: { loading, user } }) => ({
      isLoadingRoles: loading,
      allRoleRates: user?.account?.allRoleRates
    }))
  }),
  withAuth
);

import React, { useState, useEffect, useContext } from "react";
import { useQuery } from "@apollo/client";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RotaSnackBar, RotaButtonNew } from "@teamrota/rota-design";

import debounce from "lodash/debounce";
import truncate from "lodash/fp/truncate";

import { AuthContext } from "~/src/auth/contexts";
import ActionDropdown from "~/src/components/action-dropdown";
import CreateAccount from "~/src/containers/modals/create-account";
import Ellipsis from "~/src/components/ellipsis";
import FiltersDropdown from "~/src/components/filters-dropdown";
import LoadMore from "~/src/components/load-more";
import NoResults from "~/src/components/no-results";
import PageHeader from "~/src/components/page-header";
import Partner from "~/src/components/partner";
import TablePlaceholder from "~/src/components/table-placeholder";
import ViewEditAccount from "~/src/containers/modals/view-edit-account";
import {
  FloatingTable,
  ColumnHeading,
  TableBody,
  TableData,
  TableRow
} from "~/src/components/floating-table";

import GET_CONNECTIONS from "./graphql/get-connections";
import GET_PROFILE from "~/src/graphql/queries/get-profile/get-profile.query";
import { setConnections } from "~/src/utils/get-profile-utils";

import {
  onUpdateSearch,
  onUpdateOrderBy,
  onUpdateServiceArea,
  ORDER_OPTIONS
} from "./reducer";

import { StyledSearchInput, SortedBy } from "./partners.styles";
import { useCreateAccount } from "./use-create-account";

const getServiceAreaOptions = user => {
  return [
    { label: "Any", value: "" },
    (user?.account?.serviceAreas || []).map(sa => ({
      label: sa.name,
      value: sa.id
    }))
  ];
};

const Partners = ({
  onUpdateSearch,
  serviceAreaId,
  onUpdateServiceArea,
  searchText,
  orderBy,
  onUpdateOrderBy
}) => {
  const [selectedAccountId, setSelectedAccountId] = useState(undefined);
  const [
    selectedPartnerPayrollBreakId,
    setSelectedPartnerPayrollBreakId
  ] = useState(null);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [isCreateAccount, setIsCreateAccount] = useState(false);
  const [connection, setConnection] = useState([]);
  const [user, setUser] = useState("");
  const { auth } = useContext(AuthContext);
  const { data: newUser } = useQuery(GET_PROFILE, {
    variables: auth.addVals(GET_PROFILE, {
      connectionLimit: 10,
      connectionOffset: 0
    })
  });

  const { data: account, loading: isLoading, fetchMore, refetch } = useQuery(
    GET_CONNECTIONS,
    {
      variables: {
        offset: 0,
        limit: 10,
        searchText,
        orderByProp: orderBy.value,
        orderByDirection: orderBy.orderDirection,
        hasVenuesInServiceArea: serviceAreaId || undefined
      }
    }
  );

  const totalResults = account?.account?.connections?.totalResults ?? 0;

  useEffect(() => {
    if (newUser) {
      setUser(setConnections(newUser));
    }
  }, [newUser]);

  useEffect(() => {
    if (account?.account?.connections?.data) {
      setConnection(account?.account?.connections?.data);
    }
  }, [account]);

  const selectConnection = accountId => {
    setSelectedAccountId(accountId);
  };

  const unselectConnection = () => {
    setSelectedAccountId(null);
  };

  const toggleAddAccount = () => {
    setIsCreateAccount(!isCreateAccount);
  };

  const onCompleted = () => {
    setIsSnackbarOpen(true);
    toggleAddAccount();
    refetch();
  };

  const onLoadMore = () =>
    fetchMore({
      variables: {
        offset: connection?.length
      }
    }).then(fetchMoreResult => {
      setConnection([
        ...connection,
        ...fetchMoreResult?.data?.account?.connections?.data
      ]);
    });

  const { createAccount, loading } = useCreateAccount({ onCompleted });

  const debounceSearchTextDispatch = debounce(onUpdateSearch, 300);

  const serviceAreaOptions = getServiceAreaOptions(user);

  const isNoResults = connection?.length === 0 && !isLoading;
  const isNoMoreResults = connection?.length === totalResults;

  return (
    <>
      <div>
        <ViewEditAccount
          isOpen={Boolean(selectedAccountId)}
          targetAccountId={selectedAccountId}
          partnerPayrollBreakId={selectedPartnerPayrollBreakId}
          setSelectedPartnerPayrollBreakId={setSelectedPartnerPayrollBreakId}
          onClose={unselectConnection}
          isPartner
        />
        {isCreateAccount && (
          <CreateAccount
            onClose={toggleAddAccount}
            createAccount={createAccount}
            loading={loading}
          />
        )}
        <PageHeader title="Partners" subtext={`${totalResults} Found`}>
          <div>
            <StyledSearchInput
              isInline
              isLarge
              isLoading={isLoading}
              onChange={debounceSearchTextDispatch}
              placeholder="Search accounts by name or ID"
            />
            <FiltersDropdown>
              <p>Service Area</p>
              <ActionDropdown
                defaultValue="Any"
                isFullWidth
                isBlock
                isOutline
                value={serviceAreaOptions.find(
                  ({ value }) => value === serviceAreaId
                )}
                onChange={serviceArea => onUpdateServiceArea(serviceArea.value)}
                options={serviceAreaOptions}
              />
            </FiltersDropdown>
          </div>
          <div>
            <SortedBy>Sort by</SortedBy>
            <ActionDropdown
              defaultValue="Sort"
              isFontPrimary
              isIconPrimary
              shouldLeftShiftDropdown
              value={{
                label: truncate({ length: 8 }, orderBy.label),
                value: orderBy.value
              }}
              options={ORDER_OPTIONS}
              onChange={onUpdateOrderBy}
            />
          </div>
        </PageHeader>
        <FloatingTable>
          <ColumnHeading width={5 / 12}>
            Partner{" "}
            <RotaButtonNew variant="outlined" onClick={toggleAddAccount}>
              Add a new partner
            </RotaButtonNew>
          </ColumnHeading>
          <ColumnHeading width={4 / 12} widthSmall={3 / 12}>
            Notes
          </ColumnHeading>
          <ColumnHeading width={3 / 12} widthSmall={3 / 12}>
            &nbsp;
          </ColumnHeading>
          <TableBody>
            {isNoResults ? (
              <NoResults />
            ) : (
              <div>
                {isLoading && (
                  <TablePlaceholder columnWidths={[10 / 12, 2 / 12]} />
                )}
                {connection.map((connection, index) => (
                  <TableRow key={index}>
                    <TableData width={5 / 12}>
                      <Partner {...connection.targetAccount} />
                    </TableData>
                    <TableData width={4 / 12} widthSmall={3 / 12}>
                      <Ellipsis maxLength={81}>
                        {connection.targetAccount.notes}
                      </Ellipsis>
                    </TableData>
                    <TableData width={3 / 12}>
                      <RotaButtonNew
                        variant="outlined"
                        onClick={() => {
                          selectConnection(connection.targetAccount.id);
                          setSelectedPartnerPayrollBreakId(
                            connection.payrollBreakId
                          );
                        }}
                      >
                        Details
                      </RotaButtonNew>
                    </TableData>
                  </TableRow>
                ))}
              </div>
            )}
          </TableBody>
        </FloatingTable>
        {!isNoResults && !isNoMoreResults && (
          <LoadMore isLoading={isLoading} onLoadMore={onLoadMore} />
        )}
      </div>
      <RotaSnackBar
        snackOpen={isSnackbarOpen}
        message={"Account has been created"}
        onClose={() => setIsSnackbarOpen(false)}
      />
    </>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onUpdateSearch,
      onUpdateOrderBy,
      onUpdateServiceArea
    },
    dispatch
  );

const mapStateToProps = state => ({
  searchText: state.partners.searchText,
  orderBy: state.partners.orderBy,
  serviceAreaId: state.partners.serviceAreaId
});

export default connect(mapStateToProps, mapDispatchToProps)(Partners);

import React from "react";
import { get, getOr, flow, omit, keys } from "lodash/fp";
import { formatDateMonthTime } from "~/src/utils/formatting";
import Log from "./components/log";
import LogRole from "./components/log-role";
import LogDocument from "./components/log-document";
import LogNotification from "./components/log-notification";
import LogAction from "./components/log-action";
import { Date, Author, LogItem } from "./index.styles";
import { getAuthor, formatLog, groupLogs } from "./helpers";

export default ({ logs }) => {
  if (!logs?.length) {
    return <div>No history to display</div>;
  }

  return (
    <div>
      {groupLogs(logs).map((log, i) => (
        <LogItem key={i}>
          <Date>{formatDateMonthTime(log.createdAt)}</Date>
          <Author>{getAuthor(log)}</Author>
          <LogAction log={log} />

          {flow(
            getOr({}, "metadata.updatedValues"),
            omit("accountUser"),
            keys
          )(log).map((item, j) => (
            <Log
              key={j}
              {...formatLog(log.metadata.updatedValues[item], item)}
            />
          ))}

          {flow(
            get("metadata"),
            omit("updatedValues"),
            keys
          )(log).map((item, j) => {
            if (log.logSubject === "MEMBER_ROLE") {
              return (
                <LogRole
                  key={j}
                  actionType={log.actionType}
                  name={item}
                  payRate={log.metadata[item]}
                />
              );
            }
            if (log.logSubject === "MEMBER_DOCUMENT") {
              return (
                <LogDocument
                  key={j}
                  actionType={log.actionType}
                  documentType={item}
                  url={log.metadata[item]}
                />
              );
            }
            if (
              [
                "EMAIL_SENT",
                "EMAIL_FAILED",
                "TEXT_SENT",
                "TEXT_FAILED"
              ].includes(log.actionType)
            ) {
              return (
                <LogNotification
                  key={j}
                  notificationType={log.metadata[item]}
                />
              );
            }
            return null;
          })}
        </LogItem>
      ))}
    </div>
  );
};

import moment from "moment-timezone";
import get from "lodash/fp/get";
import getOr from "lodash/fp/getOr";
import head from "lodash/fp/head";
import last from "lodash/fp/last";
import find from "lodash/fp/find";
import truncate from "lodash/truncate";
import Money from "~/src/utils/money";
import { getShiftTotals, getTotalBonusAmount } from "~/src/utils/formatting";

// @NOTE: These are the text extractors used by the form components and the sidebar
// for consistency

// Validate props<roleRateId, venueId, numberRequested> for Audience
export const useValidateAudience = ({ shift }) => {
  return (
    shift.roleRateId &&
    shift.venueId &&
    parseInt(shift.numberRequested || 0, 10) > 0
  );
};

// Validate props<uniformTemplateId, privates.fixedLabel> for Requirements
export const useValidateRequirements = ({ shift }) => {
  return shift.uniformTemplateId && shift.briefing;
};

// Validate props<shift.times>
export const useValidateCalendar = ({ shift }) => {
  return Object.keys(shift.times).length > 0;
};

export function extractShiftTitle(shift, venueOptions, roleRates) {
  try {
    const { venueId, roleRateId } = shift;
    const selectedVenue = find(({ id }) => id === venueId, venueOptions);
    const selectedRoleRate = find(({ id }) => id === roleRateId, roleRates);

    return `${shift.numberRequested} x ${selectedRoleRate.roleName} @ ${selectedVenue.name}`;
  } catch (e) {
    return "Staff Requirements";
  }
}

export function extractBonusShiftText({ bonuses }) {
  return `${bonuses.length} x Bonus Offered`;
}

export function extractShiftTimesText(shift, withTimes) {
  try {
    const times = getOr({}, "times", shift);
    const sortedDates = Object.values(times).sort((a, b) =>
      moment(a.startTime).diff(b.startTime, "days")
    );

    const { startTime } = head(sortedDates);
    const { endTime } = last(sortedDates);

    const isSame = moment(startTime).isSame(endTime, "day");
    const total = Object.values(times).length;

    const timeFormat = withTimes ? "Do MMM HH:mm" : "Do MMM";
    const range =
      isSame && !withTimes ? "" : `- ${moment(endTime).format(timeFormat)}`;
    const timeStamp = `${moment(startTime).format(timeFormat)} ${range}`;

    return withTimes
      ? timeStamp
      : `${total} x Day${total === 1 ? "" : "s"} Selected: ${timeStamp}`;
  } catch (e) {
    return "Confirm Your Dates";
  }
}

export function extractTotalReqText(shift) {
  if (shift.isRequestAll) {
    return "All Qualified & Recommended Staff Requested";
  } else {
    return `${shift.requestedMemberIds.length} x Staff Requested`;
  }
}

export function extractBriefingUniformText(shift, uniformOptions) {
  const { uniformTemplateId } = shift;
  const selectedUniform = find(
    ({ id }) => id === uniformTemplateId,
    uniformOptions
  );

  const briefing = getOr("unselected", "briefing", shift);
  const selectedBriefingLabel =
    get("privates.fixedLabel", shift) ||
    truncate(briefing, {
      length: 20,
      separator: " "
    });

  return [
    `Uniform: ${getOr("unselected", "identifier", selectedUniform)}`,
    `Briefing: ${selectedBriefingLabel}`
  ];
}

export function calculateTotals(
  shift,
  roleOptions,
  policy,
  targetAccountId,
  bonuses,
  roleRates
) {
  const times = Object.values(getOr({}, "times", shift));
  const currentRoleRate = roleRates.find(rate => rate.id === shift.roleRateId);
  const isDayRate = currentRoleRate?.isDayRate;
  const shiftTotals = times.map(({ startTime, endTime }) =>
    getShiftTotals({
      roleRateId: shift.roleRateId,
      numberRequested: shift.numberRequested || 0,
      endTime,
      startTime,
      roleOptions,
      policy,
      targetAccountId,
      isDayRate
    })
  );

  let bonusTotal = Money();
  let bonusTotalWithVat = Money();
  try {
    const { total, totalWithVat } = getTotalBonusAmount({
      bonuses,
      policy,
      targetAccountId,
      times
    });
    bonusTotal = total;
    bonusTotalWithVat = totalWithVat;
  } catch (e) {
    console.log(e);
  }

  const baseTotalsWithoutTax = shiftTotals.reduce(
    (acc, curr) => acc.add(curr.totalWithoutTax),
    Money()
  );
  const baseTotalsWithTax = shiftTotals.reduce(
    (acc, curr) => acc.add(curr.totalWithTax),
    Money()
  );

  const totalWithoutTax = baseTotalsWithoutTax.add(bonusTotal).toFormat();

  const totalWithTax = baseTotalsWithTax.add(bonusTotalWithVat).toFormat();

  return {
    totalWithoutTax,
    totalWithTax
  };
}

export const getMinTime = (shift, defaultTime) =>
  Object.values(shift.times).reduce((min, { startTime }) => {
    const current = moment(startTime);
    if (!min || current.isBefore(min)) {
      return current;
    }
    return min;
  }, null) ||
  defaultTime ||
  moment();

import styled from "styled-components";

export const Styled_ErrorContainer = styled.div`
  padding: 8px 16px;
  border-radius: 5px;
  border-left: 8px solid #f60000;
  background-color: #fcf4f2;
  display: flex;

  .text_content {
    margin-left: 16px;
    color: #f60000;
    font-weight: 500;
    font-size: 12px;
    line-height: 125%;
  }
`;

import styled from "styled-components";
import Button from "~/src/components/button";
import ExitButton from "~/src/components/button/exit-button";

export const StyledExitButton = styled(ExitButton)`
  position: absolute;
  right: 0;
  margin: 4px;
`;

export const ENTER_TIME = 300;
export const LEAVE_TIME = 300;

export const POP_UP_ANIMATION = "restrict-shift-pop-up-animation";
export const TitleWrapper = styled.div``;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

export const Title = styled.div`
  width: 80%;
  margin: 0 auto;
  text-align: center;
  font-size: 20px;
  font-weight: bolder;
  padding: 25px 0 5px 0;
  border-bottom: solid 1px grey;
`;
export const Text = styled.div`
  text-align: center;
  padding: 12px;
`;

export const Wrapper = styled.div`
  transition: all 0.15s ease-in;

  background-color: #ffffff;
  cursor: default;
  height: 400px;
  width: 420px;
  z-index: 200;
  box-shadow: 0 2px 20px 100vmax rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
`;

export const InnerWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const MainContent = styled.div``;

export const StyledButton = styled(Button)`
  margin: 0 5px;
`;

import React, { useEffect, useState } from "react";
import Collapse from "react-collapse";
import Loading from "~/src/components/loading";
import { ExpandableSection } from "./async-collapse.styles";

const MIN_LOADING_TIME = 1000;

const AsyncCollapse = ({
  isExpanded = false,
  children,
  minLoadingTime,
  isLoading,
  className
}) => {
  const [startedLoadingAt, setStartedLoadingAt] = useState(null);

  useEffect(prevProps => {
    const newMinLoadingTime = minLoadingTime || MIN_LOADING_TIME;

    // If current or previous props are loading and state isn't
    if (!startedLoadingAt && (prevProps?.isLoading || isLoading)) {
      setStartedLoadingAt(Date.now());

      // Is currently loading but the props are not, and hasn't already set timeout
    } else if (startedLoadingAt && !isLoading && !hasInitialisedEnd) {
      // Prevent running this again until set timeout through
      hasInitialisedEnd = true;
      setTimeout(() => {
        hasInitialisedEnd = false;
        setStartedLoadingAt(null);

        // Load for a minimum amount of time
      }, newMinLoadingTime - (Date.now() - startedLoadingAt));
    }
  });

  // When set timeout has been set, use this to prevent it happening again
  let hasInitialisedEnd = false;

  return (
    <Collapse className={className} isOpened={Boolean(isExpanded || isLoading)}>
      {startedLoadingAt ? (
        <ExpandableSection>
          <Loading type="spin" color="#eee" delay={0} />
        </ExpandableSection>
      ) : (
        // Handle the required 'children' prop on ReactHeight
        (isExpanded && children) || <div />
      )}
    </Collapse>
  );
};

export default AsyncCollapse;

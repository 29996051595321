import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const RESEND_ACCEPTED_EMAIL = gql`
  mutation queueBossJob($payload: JSON!) {
    queueBossJob(payload: $payload, channelName: automation)
  }
`;
export default graphql(RESEND_ACCEPTED_EMAIL, {
  name: "queueBossJob",
  props: ({ queueBossJob, ownProps }) => ({
    isResendingEmail: queueBossJob.loading,
    resendAcceptedEmail: async () =>
      queueBossJob({
        variables: {
          payload: {
            bookingId: ownProps.bookingId,
            actionType: "sendBookingAcceptedEmail"
          }
        }
      })
  })
});

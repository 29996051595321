const fields = [
  {
    name: "accountName",
    label: "Account name"
  },
  {
    name: "ownerId",
    label: "Assigned to",
    component: "dropdown"
  },
  {
    name: "firstName",
    label: "First name"
  },
  {
    name: "lastName",
    label: "Last name"
  },
  {
    name: "email",
    label: "Email"
  },
  {
    name: "phoneNumber",
    label: "Phone number",
    component: "phone-number-input"
  }
];

export default fields.map(field => ({
  ...field,
  placeholder: field.label,
  isSingleLine: true
}));

import styled from "styled-components";
import Button from "~/src/components/button";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";

export const StyledButton = styled(Button)`
  margin-right: ${STYLE_CONSTS.UNIT_3};
  @media screen and (max-width: ${STYLE_CONSTS.BREAK_POINT_XXLARGE}) {
    margin-right: ${STYLE_CONSTS.UNIT_HALF};
  }
`;

export const SortedBy = styled.span`
  font-size: ${STYLE_CONSTS.FONT_SMALL};
  color: ${COLORS.COOL_GREY};
  margin-right: ${STYLE_CONSTS.UNIT_1};
`;

export const Buttons = styled.div`
  float: right;
`;

import { gql, useQuery } from "@apollo/client";

export const GET_VENUE_OWNER_EXCLUSIONS_AND_TAGS = gql`
  query getVenueOwnerExclusionsAndTags($partnerId: ID!) {
    account {
      venueOwnerExclusionsAndTags(partnerId: $partnerId) {
        id
        name
        owner {
          id
          lastName
          firstName
        }
        excludedMembers {
          id
          lastName
          firstName
          reason
          photo
          memberVenueExclusionId
          memberId
        }
        tags {
          id
          name
        }
        roleTags {
          id
          roleId
          roleName
          tags {
            id
            name
          }
        }
      }
    }
  }
`;

export const useGetVenues = partnerId => {
  const { data } = useQuery(GET_VENUE_OWNER_EXCLUSIONS_AND_TAGS, {
    variables: { partnerId }
  });

  return {
    venueOwnerExclusionsAndTags:
      data?.account?.venueOwnerExclusionsAndTags ?? []
  };
};

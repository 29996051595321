import React from "react";
import EditRoles from "./edit-roles";

const CalculatedUncalculatedRolesLists = ({
  accountId,
  uncalculatedRoles,
  roleRates,
  tags,
  isPartner
}) => {
  return (
    <EditRoles
      accountId={accountId}
      roles={uncalculatedRoles}
      roleRates={roleRates}
      tags={tags}
      isPartner={isPartner}
      disableEditRole
    />
  );
};

export default CalculatedUncalculatedRolesLists;

import styled from "styled-components";

const MODAL_WIDTH = "600px";

export const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: ${MODAL_WIDTH};
  margin: 0 auto;
`;

export const DatepickerRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  margin-bottom: 10px;

  & > div {
    width: 50%;
    margin: 0px 20px;

    & > div {
      font-size: 12px;
      font-weight: 300;
      margin: 0 0 0px 0;
    }
  }
`;

export const InputsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  & > * {
    font-size: 12px;
    width: 50%;
    margin: 0 20px;

    & > label {
      font-size: 12px;
      margin-bottom: 0px;
    }
  }
`;

export const EmptySpace = styled.div`
  width: 50%;

  &::after {
    content: " ";
  }
`;

export const CheckboxRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  margin: 10px 0;
`;

export const SwitchWrapper = styled.div`
  scale: 0.9;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 20px 20px 10px;
  width: 50%;

  background-color: pink;

  & > label {
    margin: 0px;
  }
`;

export const ChipsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  padding: 0 15px;
  margin: 10px;
`;

interface SeparatorProps {
  extraMargin?: boolean;
}

export const Separator = styled.div<SeparatorProps>`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.neutral.light};
  ${({ extraMargin = false }) => (extraMargin ? "margin-bottom: 10px;" : "")};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  max-width: ${MODAL_WIDTH};
  margin: 0 auto;
`;

import React from "react";
import DatePicker from "~/src/components/form-components/date-picker";
import { dateRangeToHuman } from "~/src/utils/formatting";
import TextInput from "../text-input";
import InputWrapper from "../input-wrapper";

import { Disabled } from "../date-picker/index.styles";

const DatePickerField = ({
  readOnly,
  isSingleLine,
  input,
  isMediumText,
  children,
  error
}) => {
  if (readOnly) {
    const date = input?.value
      ? dateRangeToHuman(input?.value?.start, input?.value?.end)
      : "";
    return (
      <InputWrapper
        isSingleLine={isSingleLine}
        isMediumText={isMediumText}
        error={error}
      >
        <Disabled>{date}</Disabled>
        {children}
      </InputWrapper>
    );
  }

  return (
    <InputWrapper
      isSingleLine={isSingleLine}
      isMediumText={isMediumText}
      error={error}
    >
      <DatePicker
        isFormPicker
        isSmall
        onChange={input.onChange}
        start={input.value.start}
        end={input.value.end}
        input={TextInput}
        inputProps={{
          placeholder: dateRangeToHuman(input.value.start, input.value.end),
          input: {
            onChange: () => {},
            value: input.value
              ? dateRangeToHuman(input.value.start, input.value.end)
              : ""
          }
        }}
      />
      {children}
    </InputWrapper>
  );
};

export default DatePickerField;

import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";
import Icon from "../../../../components/icon";

export const LoadingWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

export const PlaceholderWrapper = styled.div`
  width: 100%;
`;

export const GroupMembersContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  height: 220px;
  overflow-x: scroll;
  padding: 10px 35px 0 24px;

  @media (min-width: 1270px) {
    padding: 10px 35px 0 24px;
  }

  @media (min-width: 1490px and max-width: 1550px) {
    padding: 10px 24px 0 24px;
  }
`;

export const TabsContainer = styled.div`
  display: ${({ isVisible }) => (!isVisible ? "block" : "none")};
  position: relative;
  flex: 1;
`;

export const TabContent = styled.div`
  display: ${({ isVisible }) => (!isVisible ? "block" : "none")};
  position: relative;
  flex: 1.5;
  margin-top: 37px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const TitleIcon = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
  ${({ isArrowToggled }) =>
    isArrowToggled
      ? `transform: rotate(180deg);
  transition: transform 0.5s linear;`
      : `  transform: rotate(0deg);
  transition: transform 0.5s linear;`}};
`;

export const Title = styled.h1`
  margin-left: 5px;
  font-size: ${STYLE_CONSTS.FONT_LARGE};
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_MEDIUM};
  color: #8c8c8c;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid #8c8c8c;
  border-radius: ${STYLE_CONSTS.BORDER_RADIUS};
  background-color: ${({ theme }) => theme.white};
  box-shadow: ${STYLE_CONSTS.FLOATING_LARGE};
  padding-bottom: 0;
  min-height: ${({ size }) => (size === "large" ? "300px" : "260px")};
  ${({ isOpen }) =>
    isOpen
      ? `
    min-height: 23px;
    transition: 0.5s;
    `
      : `
      transition: height 0.5s;
      min-height: ${({ size }) => (size === "large" ? "300px" : "230px")};

    `};
`;

import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import moment from "moment-timezone";

import { RotaSnackBar, iconNew, RotaButtonIcon } from "@teamrota/rota-design";

import { GET_UNAVAILABILITY_UPLOADS } from "~/src/graphql/queries/get-unavailability-uploads/get-unavailability-uploads";
import { REVERT_MEMBER_UNAVAILABILITY_IMPORT } from "~/src/graphql/mutations/revert-member-unavailability-import/revert-member-unavailability-import";
import ConfirmRejectModal from "../modals/ConfirmRejectModal";
import { StyledUploadedFileTable } from "./styles";

const { Bin, Checklist } = iconNew;

const UploadedFileTable = () => {
  const [isConfirmRejectModalOpen, setIsConfirmRejectModalOpen] = useState(
    false
  );
  const [isSuccessSnackBarOpen, setIsSuccessSnackBarOpen] = useState(false);

  const { data } = useQuery(GET_UNAVAILABILITY_UPLOADS, {
    variables: { offset: 0, limit: 50 }
  });

  const actualFile = data?.account?.unavailabilityUploads?.filter(
    upload => upload.status === "IMPORTED" || upload.status === "REVERTED"
  )[0];

  const [
    revertMemberUnavailabilityImport,
    { data: mutationData }
  ] = useMutation(REVERT_MEMBER_UNAVAILABILITY_IMPORT, {
    refetchQueries: ["unavailabilityUploads"]
  });
  const isImportOrRejectSuccessful =
    mutationData?.revertMemberUnavailabilityImport?.success;

  useEffect(() => {
    if (isImportOrRejectSuccessful === true) {
      setIsSuccessSnackBarOpen(true);
    }
  }, [isImportOrRejectSuccessful]);

  const handleReject = () => {
    revertMemberUnavailabilityImport({
      variables: { id: actualFile?.id }
    });
    setIsConfirmRejectModalOpen(false);
  };

  return (
    <>
      <StyledUploadedFileTable>
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>File Name</th>
            <th>Uploaded By</th>
            <th>Date of Upload</th>
            <th>Status</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {actualFile?.status === "IMPORTED" ? (
            <tr>
              <td>
                <Checklist />
              </td>
              <td align="left">{actualFile.fileName}</td>
              <td>{`${actualFile.uploader.firstName} ${actualFile.uploader.lastName}`}</td>
              <td>
                {actualFile?.importedAt &&
                  moment(actualFile.importedAt).format("hh:mm MMM Do YYYY")}
              </td>
              <td>{actualFile.status}</td>
              <td>
                <RotaButtonIcon
                  onClick={() => setIsConfirmRejectModalOpen(true)}
                >
                  <Bin />
                </RotaButtonIcon>
              </td>
            </tr>
          ) : (
            <tr></tr>
          )}
        </tbody>
      </StyledUploadedFileTable>
      {isConfirmRejectModalOpen && (
        <ConfirmRejectModal
          onClose={() => setIsConfirmRejectModalOpen(false)}
          handleReject={handleReject}
        />
      )}
      <RotaSnackBar
        snackOpen={isSuccessSnackBarOpen}
        onClose={() => setIsSuccessSnackBarOpen(false)}
        message="Erased Successfully"
        severity="success"
      />
    </>
  );
};

export default UploadedFileTable;

import styled from "styled-components";

export const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const StyledWrapDivisions = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  padding: 20px;
`;

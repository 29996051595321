export const TABLE_CONTENT_MEASURES = {
  HEADING: {
    FIRST: 0.41,
    MIDDLE: 0.45,
    LAST: 0.09
  },
  ROW: {
    FIRST: 0.5,
    MIDDLE: 0.42,
    LAST: 0.05
  }
};

export const MEMBER_PAY_TYPES = {
  PAYE: "PAYE",
  CONTRACTOR: "CONTRACTOR"
};

export const MEMBER_TYPES_FORMATS = {
  CASUAL: {
    PRETTY: "Casual",
    CONST: "CASUAL",
    FIELD_NAME: "isCasualMemberTypeAvailable"
  },
  PERMANENT: {
    PRETTY: "Permanent",
    CONST: "PERMANENT",
    FIELD_NAME: "isPermanentMemberTypeAvailable"
  },
  AGENCY: {
    PRETTY: "Agency",
    CONST: "AGENCY",
    FIELD_NAME: "isAgencyMemberTypeAvailable"
  },
  CONTRACTOR_LIMITED_COMPANY: {
    PRETTY: "Limited Company",
    CONST: "CONTRACTOR_LIMITED_COMPANY",
    FIELD_NAME: "isContractorLimitedCompanyMemberTypeAvailable",
    FIELD_NAME_INFO_REQUIRED: "isContractorLimitedCompanyMemberTypeInfoRequired"
  },
  CONTRACTOR_UMBRELLA_COMPANY: {
    PRETTY: "Umbrella Company",
    CONST: "CONTRACTOR_UMBRELLA_COMPANY",
    FIELD_NAME: "isContractorUmbrellaCompanyMemberTypeAvailable",
    FIELD_NAME_INFO_REQUIRED:
      "isContractorUmbrellaCompanyMemberTypeInfoRequired"
  },
  CONTRACTOR_SELF_EMPLOYED: {
    PRETTY: "Self Employed",
    CONST: "CONTRACTOR_SELF_EMPLOYED",
    FIELD_NAME: "isContractorSelfEmployedMemberTypeAvailable",
    FIELD_NAME_INFO_REQUIRED: "isContractorSelfEmployedMemberTypeInfoRequired"
  }
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key]["PRETTY"] === value);
};

import React from "react";
import MemberPhoto from "~/src/components/member-photo";
import Stars from "~/src/components/stars";
import {
  AppliedMemberWrapper,
  MemberPhotoWrapper,
  MemberNameWrapper,
  ActionButtonsWrapper,
  MemberField
} from "./applied-member.styles";

import CircleActionButton from "../circle-action-button";

import { MEMBER_GRID_ACTIONS } from "../../containers/requester-calendar/consts";

const AppliedMemberComponent = ({
  member,
  onUpdateMemberState,
  onMemberPhotoClicked,
  actionButtonsShouldHaveLabels,
  bookingState
}) => (
  <AppliedMemberWrapper>
    <MemberPhotoWrapper onClick={onMemberPhotoClicked}>
      <MemberPhoto src={member.photo} isInline size="medium" />
    </MemberPhotoWrapper>
    <MemberNameWrapper>
      <MemberField>{`${member.firstName} ${member.lastName}`}</MemberField>
      <MemberField>
        <Stars isSmall showRating value={member.rating} readOnly />
      </MemberField>
    </MemberNameWrapper>

    <ActionButtonsWrapper>
      {MEMBER_GRID_ACTIONS.filter(
        action => action.bookingActionType !== bookingState
      ).map((action, index) => (
        <CircleActionButton
          key={index}
          color={action.color}
          onActionTaken={() => onUpdateMemberState(action.bookingActionType)}
          iconName={action.iconName}
          iconSize="small"
          label={actionButtonsShouldHaveLabels && action.label}
        />
      ))}
    </ActionButtonsWrapper>
  </AppliedMemberWrapper>
);

export default AppliedMemberComponent;

export const NO_REMOVE_ACCEPTED =
  "You can't remove staff who accepted, the provider must cancel them";

export const NO_REMOVE_DECLINED =
  "Staff who declined can't be removed, as they can't be requested again";

export const NO_REMOVE_PENDING =
  "You can't remove staff you've requested until they've accepted or declined";

export const SHIFT_FULL =
  "This shift is full, increase the number requested to assign more staff";

export const REQUEST_FULL =
  "This shift is full - you can increase the number requested to request more staff";

export const NO_REQUEST_ASSIGNED =
  "You can't move accepted staff to request, they're already working this shift";

export const REQUEST_ALL =
  "Request All is set, all members who are qualified will be requested";

export const SHIFT_CANCELLED = "This shift is cancelled";

import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import moment from "moment-timezone";
import {
  RotaCheckbox,
  RotaCalendar,
  RotaSnackBar
} from "@teamrota/rota-design";
import { ZonedDate } from "@teamrota/rota-common";

import Loading from "~/src/components/loading";
import AccountsList from "~/src/containers/admin-dashboard/modules/accounts-list";
import adminClient from "../../graphql/admin-client";
import { useGetNewTimesheetsStartDate } from "../../graphql/use-new-timesheets-start-date";
import { UPDATE_NEW_TIMESHEETS_START_DATE } from "../../graphql/update-new-timesheets-start-date";

import {
  Heading,
  SubText,
  Container,
  Label,
  SaveButton
} from "./timesheets.styles";
import {
  StyledMainHeading,
  StyledMainDetail,
  StyledMainHeaderWrapper,
  StyledWrapper,
  StyledFeaturesWrapper
} from "../styles";

const FeaturesWrapper = ({ selectedAccountId }) => {
  const [snackbar, setSnackBar] = useState({});
  const [newStartDate, setNewStartDate] = useState(null);
  const [isNewTimeSheetsEnabled, setIsNewTimeSheetsEnabled] = useState(false);

  const { newTimesheetsStartDate, loading } = useGetNewTimesheetsStartDate(
    selectedAccountId
  );

  const isNewTimesheetsActive =
    newTimesheetsStartDate &&
    new ZonedDate(moment(newTimesheetsStartDate).endOf("day")) <=
      new ZonedDate(moment().startOf("day"));

  useEffect(() => {
    setIsNewTimeSheetsEnabled(!!newTimesheetsStartDate);

    if (newTimesheetsStartDate) {
      setNewStartDate(moment(newTimesheetsStartDate).toDate());
    } else {
      setNewStartDate(null);
    }
  }, [newTimesheetsStartDate]);

  const [updateNewTimesheetsStartDate, { loading: loadingSave }] = useMutation(
    UPDATE_NEW_TIMESHEETS_START_DATE,
    {
      client: adminClient,
      refetchQueries: ["getNewTimesheetsStartDate"],
      onCompleted: () =>
        setSnackBar({
          open: true,
          severity: "success",
          message: isNewTimeSheetsEnabled
            ? "Start date updated successfully."
            : "New timesheets successfully deactivated."
        }),
      onError: () =>
        setSnackBar({
          open: true,
          severity: "error",
          message: "Something went wrong. Please try again."
        })
    }
  );

  const handleOnSave = async () => {
    await updateNewTimesheetsStartDate({
      variables: {
        accountId: selectedAccountId,
        newTimesheetsStartDate: isNewTimeSheetsEnabled ? newStartDate : null
      }
    });
  };

  const maxDate = moment().add(1, "year");

  const isDisabled = isNewTimesheetsActive || !isNewTimeSheetsEnabled;

  return (
    <>
      <StyledFeaturesWrapper>
        {!loading ? (
          <>
            <Container>
              <div>
                <Heading>Activate New Timesheets</Heading>
                <SubText>
                  Switching to new timesheets will make older timesheets data
                  inaccessible from this account. PLEASE make sure the client is
                  informed and is up to date with their current timesheets
                  before changing their settings.
                </SubText>
              </div>
              <RotaCheckbox
                isDisabled={isNewTimesheetsActive}
                isChecked={isNewTimeSheetsEnabled}
                onClick={() =>
                  setIsNewTimeSheetsEnabled(!isNewTimeSheetsEnabled)
                }
              />
            </Container>
            <Container>
              <Label>Start Date: </Label>
              <RotaCalendar
                value={newStartDate}
                onChangeDate={setNewStartDate}
                minDate={new ZonedDate()}
                maxDate={new ZonedDate(maxDate)}
                disabled={isDisabled}
              />
            </Container>
            <SaveButton
              isLoading={loadingSave}
              disabled={isNewTimesheetsActive || !newStartDate}
              onClick={() => {
                handleOnSave();
                setSnackBar({});
              }}
            >
              Save
            </SaveButton>
          </>
        ) : (
          <Loading color="black" />
        )}
      </StyledFeaturesWrapper>
      <RotaSnackBar
        onClose={() => setSnackBar({})}
        snackOpen={snackbar?.open}
        severity={snackbar?.severity}
        message={snackbar?.message}
      />
    </>
  );
};

const Timesheets = () => {
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [selectedAccountName, setSelectedAccountName] = useState();

  return (
    <>
      <StyledMainHeaderWrapper>
        <StyledMainHeading>Timesheets</StyledMainHeading>
        <StyledMainDetail>
          Manage new timesheets for all accounts
        </StyledMainDetail>
      </StyledMainHeaderWrapper>
      <StyledWrapper>
        <AccountsList
          onSelect={(id, accountName) => {
            setSelectedAccountId(id);
            setSelectedAccountName(accountName);
          }}
          selectedAccountId={selectedAccountId}
          selectedAccountName={selectedAccountName}
        />

        {selectedAccountId && (
          <FeaturesWrapper selectedAccountId={selectedAccountId} />
        )}
      </StyledWrapper>
    </>
  );
};

export default Timesheets;

import styled from "styled-components";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";
import { hexToRGBA } from "~/src/styles/style-helpers";

export const BOX_WIDTH = 770;

export const Intro = styled.div`
  padding: ${STYLE_CONSTS.UNIT_3} ${STYLE_CONSTS.UNIT_2} ${STYLE_CONSTS.UNIT_2};
`;

export const Title = styled.h2`
  flex: 1;
  font-size: 25px;
  text-align: center;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  cursor: default;
  box-sizing: border-box;
  background: ${hexToRGBA(COLORS.WHITE, 0.8)};
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

export const Container = styled.div`
  position: relative;
  width: ${BOX_WIDTH}px;
  max-width: 100%;
  margin: 0 auto;
  background: ${({ theme }) => theme.white};
  border-radius: ${STYLE_CONSTS.BORDER_RADIUS_L};
  box-shadow: ${STYLE_CONSTS.FLOATING};
`;

export const Actions = styled.div`
  display: flex;
  justify-content: ${({ justify }) => justify || "center"};
  align-items: center;
  flex-direction: ${({ isVertical }) => (isVertical && "column") || "row"};
  ${({ hasTopMargin }) => hasTopMargin && `margin-top: ${STYLE_CONSTS.UNIT_3}`}
`;

export const Fieldset = styled.div`
  max-width: 100%;
  margin: 0 auto;
`;

export const Header = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

export const HeaderTitle = styled.h2`
  flex: 1;
  font-weight: regular;
  font-size: 16px;
  text-align: left;
`;

export const Grow = styled.div`
  flex: 1;
`;

export const EditButton = styled.button`
  appearance: none;
  border: none;
  padding: 0;
  background: transparent;
  outline: none;
  cursor: pointer;
  color: ${({ theme }) => theme.primary.main};
  font-size: 12px;
`;

export const FormButton = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: ${({ isButtonClickable, theme }) =>
    isButtonClickable ? theme.primary.main : "#d8d8d8"};
  width: 100px;
  height: 30px;
  border-radius: 10px;
  display: flex;
  color: white;
  ${({ isButtonClickable }) => isButtonClickable && "cursor: pointer;"}
`;

import React, { useCallback, useEffect, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { iconNew } from "@teamrota/rota-design";

import ProgressBar from "~/src/components/progress-bar/progress-bar";

import {
  acceptStyle,
  activeStyle,
  baseStyle,
  rejectStyle,
  Styled_Anchor,
  Styled_Percentage,
  Styled_PercentWrapper,
  Styled_ProgressWrapper,
  Styled_ProgressContainer
} from "~/src/components/drop-zone/drop-zone.styles";
import { MAX_UPLOAD_SIZE_MB } from "~/src/consts";
import { useTheme } from "styled-components";

const { Tick, Checklist } = iconNew;
const MAX_UPLOAD_SIZE_KB = MAX_UPLOAD_SIZE_MB * 1024;

const CustomDropzone = ({ id, setDocumentRef, progress }) => {
  const theme = useTheme();

  const onDrop = useCallback((accepted, rejected, { dataTransfer }) => {
    inputRef.current.files = dataTransfer?.files;
  }, []);

  const {
    getRootProps,
    getInputProps,
    inputRef,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles
  } = useDropzone({
    onDrop,
    accept: "application/pdf,image/*",
    maxFiles: 1
  });

  useEffect(() => {
    setDocumentRef(inputRef);
  }, [inputRef]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const acceptedFileItems = acceptedFiles.map(file => {
    const insertDecimal = num => {
      return Number((num / 1000).toFixed());
    };
    const formatted = Math.round((file.size + Number.EPSILON) * 10) / 10;
    const fileSize = insertDecimal(formatted);
    return (
      <>
        <li key={file.path}>
          {file.path} - {fileSize} KB
        </li>
        {fileSize >= MAX_UPLOAD_SIZE_KB && (
          <li>
            <p style={{ color: theme.error.main }}>
              File size exceeds {MAX_UPLOAD_SIZE_MB} MB limit
            </p>
          </li>
        )}
      </>
    );
  });

  const filesExist = acceptedFiles?.length > 0;

  return (
    <section>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} id={id} />
        {!filesExist && (
          <p>
            📎 <Styled_Anchor>Add</Styled_Anchor> or drop file here
          </p>
        )}
        {!!filesExist && (
          <Styled_ProgressWrapper>
            <Styled_ProgressWrapper>
              <Checklist />
              <ProgressBar progress={progress} />
              <Tick />
            </Styled_ProgressWrapper>
            <Styled_PercentWrapper>
              <Styled_ProgressContainer>
                <Styled_Percentage>{`${progress}%`}</Styled_Percentage>
                {progress === 100 && <span>Uploaded</span>}
              </Styled_ProgressContainer>
            </Styled_PercentWrapper>
          </Styled_ProgressWrapper>
        )}
      </div>

      <aside>
        <ul>{acceptedFileItems}</ul>
      </aside>
    </section>
  );
};

export default CustomDropzone;

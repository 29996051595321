import React, { useContext } from "react";
import { Role } from "@teamrota/authlib";
import { RotaDivider } from "@teamrota/rota-design";
import randomWords from "random-words";

import { IconButton } from "@material-ui/core";

import useAuth from "~/src/auth/hooks/use-auth";
import createUpdateUserTypeTemplate from "~/src/containers/settings-page/graphql/create-custom-user-type";
import { RolesContext } from "~/src/containers/settings-page/context/roles-context";
import { SnackContext } from "~/src/containers/settings-page/context/snack-context";

import styles, {
  useStyles,
  StyledSpanTitle
} from "./permissions-tab-content.styles";

const MenuBtns = ({
  setOpenRenameDialog,
  setOpenDeleteDialog,
  setOpenDotMenu,
  createUpdateUserTypeTemplate
}) => {
  const {
    selectedUserTypeId,
    roles,
    setHasChanged,
    setIsEditable
  } = useContext(RolesContext);
  const { setSnackOpen, setSeverity, setSnackText, setIsColor } = useContext(
    SnackContext
  );
  const classes = useStyles();

  const auth = useAuth();
  const hasPermission = auth.hasRoles(Role.TYPE_TEMPLATES);

  const renameFunction = () => {
    setOpenRenameDialog(true);
    setOpenDotMenu(false);
  };

  const duplicate = async () => {
    if (hasPermission) {
      try {
        await createUpdateUserTypeTemplate({
          name: randomWords(),
          roles: roles
        });
        setSeverity("success");
        setSnackText("Successfully duplicated template");
        setSnackOpen(true);
      } catch (error) {
        console.log(error);
        setSeverity("error");
        setSnackText("Error duplicating template");
        setSnackOpen(true);
      }
    }
    setOpenDotMenu(false);
  };

  const edit = () => {
    setHasChanged(true);
    setIsEditable(false);
    setSeverity("info");
    setIsColor("warning");
    setSnackText("You can now edit the permissions");
    setOpenDotMenu(false);
    setSnackOpen(true);
  };

  const deleteFunction = () => {
    setOpenDeleteDialog(true);
    setOpenDotMenu(false);
  };

  const menu = [
    {
      id: 1,
      title: "Rename",
      action: renameFunction
    },
    {
      id: 2,
      title: "Duplicate",
      action: duplicate
    },
    {
      id: 3,
      title: "Edit",
      action: edit
    },
    {
      id: 4,
      title: "Delete",
      action: deleteFunction
    }
  ];

  return (
    <div>
      {menu.map(({ title, id, action }, idx) => (
        <div key={idx}>
          {selectedUserTypeId >= 99 && id === 4 && <RotaDivider />}
          <IconButton
            style={
              selectedUserTypeId <= 99 && id !== 2
                ? { display: "none" }
                : styles.customizedMenu
            }
            className={
              selectedUserTypeId <= 99 && id !== 2
                ? classes.btnHidden
                : classes.btn
            }
            onClick={action}
          >
            <StyledSpanTitle>{title}</StyledSpanTitle>
          </IconButton>
        </div>
      ))}
    </div>
  );
};

export default createUpdateUserTypeTemplate(MenuBtns);

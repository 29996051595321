import React from "react";
import compose from "lodash/fp/compose";
import { connect } from "react-redux";
import { RotaButton } from "@teamrota/rota-design";

import FormStepSegment from "~/src/components/form-step-segment";

import { Actions, HeaderTitle, Header, EditButton } from "../styles";

import RequestZonesPanel from "~/src/components/RequestZonesPanel";
import { mapStateToProps } from "./selectors";
import { extractTotalReqText } from "../shared";

const FormMembers = props => {
  const { shifts, shiftOpenIndex } = props.shiftForm;
  const shift = shifts[shiftOpenIndex];

  return (
    <FormStepSegment
      noPadding
      isOpen={props.isOpen}
      stepNumber={props.stepNumber}
      title={() => (
        <Header>
          <HeaderTitle>
            {props.hasVisited && !props.isOpen
              ? extractTotalReqText(shift)
              : "Request Staff"}
          </HeaderTitle>
          {props.hasVisited && !props.isOpen && (
            <EditButton onClick={props.goToSelf}>Edit</EditButton>
          )}
        </Header>
      )}
    >
      {() => (
        <div>
          <RequestZonesPanel
            isProvide={props.sProviderScheduler}
            isVisible={props.isOpen}
            connections={props.connections}
            formData={props.shiftForm}
            requested
            onUpdate={({ shifts, shiftOpenIndex }) => {
              const {
                assignedMemberIds,
                requestedMemberIds,
                isPartnerManaged,
                skipAlgoShift,
                delayHoursAlgoShift,
                isRequestAll,
                isIncludeUnavailable
              } = shifts[shiftOpenIndex];

              props.handleShiftUpdate({
                assignedMemberIds,
                requestedMemberIds,
                isPartnerManaged,
                skipAlgoShift,
                delayHoursAlgoShift,
                isRequestAll,
                isIncludeUnavailable
              });
            }}
          />

          <Actions justify="center">
            <RotaButton onClick={props.goToNext}>
              {props.hasCompletedAllSteps ? "Done" : "Next"}
            </RotaButton>
          </Actions>
        </div>
      )}
    </FormStepSegment>
  );
};

export default compose(connect(mapStateToProps), C => props => (
  <C {...props} isCreate />
))(FormMembers);

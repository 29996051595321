import React from "react";
import { RotaCheckbox } from "@teamrota/rota-design";
import RotaRadio from "./radio";
import InfoIcon from "./infoIcon";
import { HalfWidthFloatingTable } from "../../styles";
import { TABLE_CONTENT_MEASURES } from "../../utils";
import {
  ColumnHeading,
  TableBody,
  TableData,
  TableRow,
  StyledToolTip,
  StyledWrapContractor,
  StyledWrapContractorCheckbox
} from "~/src/components/floating-table";
import { useTheme } from "styled-components";

const MemberTypesTable = ({
  preferences,
  isContractor,
  onToggleCheckbox,
  onToggleAdditionalInfoCheckbox,
  onToggleRadioCheckbox
}) => {
  const theme = useTheme();
  return (
    <HalfWidthFloatingTable>
      <ColumnHeading width={TABLE_CONTENT_MEASURES.HEADING.FIRST}>
        {(!!isContractor && "CONTRACTOR") || "PAYE"}
      </ColumnHeading>
      {!isContractor && (
        <ColumnHeading width={TABLE_CONTENT_MEASURES.HEADING.MIDDLE}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <InfoIcon data-tip data-for="is-available" />
            <StyledToolTip
              multiline
              place="left"
              id="is-available"
              effect="solid"
              type="light"
              textColor={theme.text.primary}
              offset={{ bottom: 50, right: 10 }}
              arrowColor="transparent"
            >
              This feature will pre-define all of the member types you have
              available when you want to change some member type.
            </StyledToolTip>
            Is available
          </div>
        </ColumnHeading>
      )}
      {!isContractor && (
        <ColumnHeading width={TABLE_CONTENT_MEASURES.HEADING.LAST}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <InfoIcon data-tip data-for="default-info" />
            <StyledToolTip
              multiline
              place="left"
              id="default-info"
              effect="solid"
              type="light"
              textColor={theme.text.primary}
              offset={{ bottom: 40, right: 10 }}
              arrowColor="transparent"
            >
              This feature will pre-define all of the member types for all of
              the new members you are going to add.
            </StyledToolTip>
            Default
          </div>
        </ColumnHeading>
      )}
      <TableBody>
        <div>
          {preferences?.map((preference, index) => (
            <TableRow key={index}>
              <TableData width={TABLE_CONTENT_MEASURES.ROW.FIRST}>
                <StyledWrapContractor isContractor={!!isContractor}>
                  <h3>{preference.fieldName}</h3>
                  {!!isContractor && !!preference.isChecked && (
                    <p>Require additional info upon member registration?</p>
                  )}
                </StyledWrapContractor>
              </TableData>
              <TableData width={TABLE_CONTENT_MEASURES.ROW.MIDDLE}>
                <StyledWrapContractorCheckbox isContractor={!!isContractor}>
                  <RotaCheckbox
                    isChecked={preference.isChecked}
                    onClick={() => onToggleCheckbox(preference)}
                  />
                  {!!isContractor && !!preference.isChecked && (
                    <RotaCheckbox
                      isChecked={preference.isAdditionalInfoRequired}
                      onClick={() => onToggleAdditionalInfoCheckbox(preference)}
                    />
                  )}
                </StyledWrapContractorCheckbox>
              </TableData>
              <TableData width={TABLE_CONTENT_MEASURES.ROW.LAST}>
                <RotaRadio
                  isDisabled={false}
                  value={preference.isDefault}
                  onChange={() => onToggleRadioCheckbox(preference)}
                />
              </TableData>
            </TableRow>
          ))}
        </div>
      </TableBody>
    </HalfWidthFloatingTable>
  );
};

export default MemberTypesTable;

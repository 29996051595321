import React from "react";
import {
  ManageShiftsHeaderWrapper,
  StyledIcon,
  Label,
  NotifierWrapper
} from "./manage-shifts.styles";

import Notifier from "~/src/components/notifier";

const ManageShiftsHeaderComponent = ({ manageableShiftsCount }) => (
  <ManageShiftsHeaderWrapper>
    <StyledIcon />
    <Label>Manage Shifts</Label>

    {manageableShiftsCount > 0 && (
      <NotifierWrapper>
        <Notifier isSmall number={manageableShiftsCount} />
      </NotifierWrapper>
    )}
  </ManageShiftsHeaderWrapper>
);

export default ManageShiftsHeaderComponent;

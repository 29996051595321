import React from "react";
import moment from "moment-timezone";

import Icon from "~/src/components/icon";
import {
  GridCardWrapper,
  Header,
  Time,
  IconWrapper
} from "./unavailable-grid-card.styles";

const UnavailableGridCard = ({
  unavailability: { startsAt, endsAt },
  onClick
}) => {
  return (
    <GridCardWrapper onClick={onClick}>
      <Header>Unavailable</Header>
      <Time>
        {startsAt ? moment(startsAt).format("HH:mm") : ""}
        {"-"}
        {endsAt ? moment(endsAt).format("HH:mm") : ""}
      </Time>
      <IconWrapper>
        <Icon
          name={Icon.names.PLUS}
          color={Icon.colors.SILVER}
          size={Icon.sizes.SMALL}
        />
      </IconWrapper>
    </GridCardWrapper>
  );
};

export default UnavailableGridCard;

import React, { ReactNode } from "react";
import styled from "styled-components";
import {
  RotaSidebar,
  RotaBadge,
  RotaButtonIcon,
  iconNew,
  RotaAccordion,
  RotaAccordionItem,
  useAccordion,
  RotaCheckbox
} from "@teamrota/rota-design";

import { TimesheetStatus, FilterType } from "./types";
import useVenues from "./use-venues";
import useRoleRates from "./use-role-rates";

const { Cancel, Unlocked, Checklist, Locked, Tick: BaseTick } = iconNew;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
`;

const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: start;
  height: calc(100% - 80px);
  overflow: auto;
`;

const Badges = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
  margin: 0 12px 24px;
`;

const BadgeWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 32px;
  line-height: 35px;
`;

const ViewText = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 19px;
  margin-bottom: 8px;
`;

const ItemsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  max-height: 200px;
  overflow: auto;
`;

const Tick = styled(BaseTick)`
  position: absolute;
  left: -24px;
`;

const BadgeDescriptionBase = styled.div<{ isSelected: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: ${({ isSelected, theme }) =>
    isSelected ? theme.primary.main : theme.text.primary};
`;

const BadgeDescription = ({
  isSelected,
  children
}: {
  isSelected: boolean;
  children: ReactNode;
}) => {
  return (
    <BadgeDescriptionBase isSelected={isSelected}>
      {isSelected && <Tick />}
      {children}
    </BadgeDescriptionBase>
  );
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  selectedStatuses: TimesheetStatus[];
  toggleSelectedStatus: (status: TimesheetStatus) => void;
  selectedVenueIds: string[];
  selectedRoleIds: string[];
  toggleFilters: (filterType: FilterType, id: string) => void;
}

export const Sidebar = ({
  isOpen,
  onClose,
  selectedStatuses,
  toggleSelectedStatus,
  selectedVenueIds,
  selectedRoleIds,
  toggleFilters
}: Props) => {
  const accordionProps = useAccordion({});
  const venues = useVenues();
  const roleRates = useRoleRates();

  return (
    <RotaSidebar isOpen={isOpen} onClose={onClose} style={{ width: 400 }}>
      <Header>
        <Title>Filters</Title>
        <RotaButtonIcon onClick={onClose}>
          <Cancel />
        </RotaButtonIcon>
      </Header>
      <MainContentWrapper>
        <ViewText>View:</ViewText>
        <Badges>
          <BadgeWrapper
            onClick={() => toggleSelectedStatus(TimesheetStatus.Approved)}
          >
            <RotaBadge type="success">
              <Unlocked />
            </RotaBadge>
            <BadgeDescription
              isSelected={selectedStatuses?.includes(TimesheetStatus.Approved)}
            >
              Approved
            </BadgeDescription>
          </BadgeWrapper>
          <BadgeWrapper
            onClick={() => toggleSelectedStatus(TimesheetStatus.Open)}
          >
            <RotaBadge type="warning">
              <Checklist />
            </RotaBadge>
            <BadgeDescription
              isSelected={selectedStatuses?.includes(TimesheetStatus.Open)}
            >
              Open
            </BadgeDescription>
          </BadgeWrapper>
          <BadgeWrapper
            onClick={() => toggleSelectedStatus(TimesheetStatus.Locked)}
          >
            <RotaBadge type="error">
              <Locked />
            </RotaBadge>
            <BadgeDescription
              isSelected={selectedStatuses?.includes(TimesheetStatus.Locked)}
            >
              Locked
            </BadgeDescription>
          </BadgeWrapper>
        </Badges>
        <RotaAccordion {...accordionProps}>
          <RotaAccordionItem label="Venue">
            <ItemsList>
              {venues.map(venue => {
                return (
                  <RotaCheckbox
                    key={venue.id}
                    name={venue.id}
                    label={venue.name}
                    isChecked={selectedVenueIds.includes(venue.id)}
                    onClick={() => toggleFilters("selectedVenueIds", venue.id)}
                  />
                );
              })}
            </ItemsList>
          </RotaAccordionItem>
          <RotaAccordionItem label="Roles">
            <ItemsList>
              {roleRates.map(roleRate => {
                return (
                  <RotaCheckbox
                    key={roleRate.id}
                    name={roleRate.roleName}
                    label={roleRate.roleName}
                    isChecked={selectedRoleIds.includes(roleRate.roleId)}
                    onClick={() =>
                      toggleFilters("selectedRoleIds", roleRate.roleId)
                    }
                  />
                );
              })}
            </ItemsList>
          </RotaAccordionItem>
        </RotaAccordion>
      </MainContentWrapper>
    </RotaSidebar>
  );
};

export default function upsertArray(callback, itemToUpsert, array) {
  const itemIndex = array.findIndex(callback);

  return itemIndex > -1
    ? // $FlowFixMe - Does not like Object.assign to be used on array.
      Object.assign([], array, {
        [itemIndex]: itemToUpsert
      })
    : [itemToUpsert, ...array];
}

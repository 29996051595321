import styled from "styled-components";

interface SurroundProps {
  isHovering: boolean;
  isButton: boolean;
  isDisabled: boolean;
  size: number;
  backgroundColor: string;
}

export const Surround = styled.div<SurroundProps>`
  display: inline-block;

  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};

  margin: ${({ isButton }) => (isButton ? "2px" : "0")};
  padding: 5px;

  border: solid 1px transparent;
  border-radius: 50%;

  ${({ theme, isHovering }) =>
    isHovering ? `border-color: ${theme.primary.main};` : ""}

  ${({ backgroundColor, isHovering }) =>
    isHovering ? `background-color: ${backgroundColor};` : ""}

  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "cursor")};
`;

import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: ${STYLE_CONSTS.UNIT_2};
`;

export const InputWrapper = styled.div`
  padding: 20px 0px;
  font-size: 14px;
  input {
    font-weight: bold;
    margin-left: 10px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const VerticalDivider = styled.div`
  height: 80%;
  margin: 0px 5px;
  border-right: 1px solid #dadada;
`;

import React from "react";

import { StyledOpenShift, Div } from "./open-shift-weekly-card.styles";
import { formatHours } from "~/src/containers/scheduler/utils";
import { iconNew } from "@teamrota/rota-design";
import { SHIFT_TYPES } from "~/src/consts";
import { useTheme } from "styled-components";

const { Moon, Sun } = iconNew;

const OpenShiftWeeklyCard = ({
  shifts,
  onPressShiftCell,
  setSelectedDayIndex,
  dayIndex
}) => {
  const isMultipleShifts = shifts?.length > 1;
  const theme = useTheme();

  const dayShiftsLength = shifts?.filter(
    shift => shift.type !== SHIFT_TYPES.NIGHT
  ).length;
  const nightShiftsLength = shifts?.filter(
    shift => shift.type === SHIFT_TYPES.NIGHT
  ).length;

  const isCancelled = isMultipleShifts ? false : !!shifts?.[0]?.cancelledAt;
  const isDraft = isMultipleShifts ? false : !!shifts?.[0]?.isDraft;
  const hasCancelledBookings = isMultipleShifts
    ? false
    : !!shifts?.[0]?.hasCancelledBookings;

  const isNightShift =
    !isMultipleShifts && shifts?.[0]?.type === SHIFT_TYPES.NIGHT;

  const textColor =
    isNightShift && !(isDraft || isCancelled || hasCancelledBookings)
      ? theme.white
      : theme.text.primary;

  return (
    <StyledOpenShift
      backgroundColor={
        isCancelled
          ? "repeating-linear-gradient(-60deg,transparent 0px 10px,#ffeaea 10px 20px)"
          : isDraft
          ? "white"
          : hasCancelledBookings
          ? "repeating-linear-gradient(-60deg,transparent 0px 10px,#eebb0055 10px 20px)"
          : isNightShift
          ? theme.background.secondary
          : theme.primary.lighter
      }
      isNightShift={isNightShift}
      borderColor={
        isCancelled
          ? theme.error.main
          : hasCancelledBookings
          ? theme.warning.main
          : theme.primary.main
      }
      isDraft={isDraft}
      isMultipleShifts={isMultipleShifts}
      onClick={() =>
        isMultipleShifts
          ? setSelectedDayIndex(dayIndex)
          : onPressShiftCell(shifts?.[0]?.id, shifts?.[0]?.sourceAccountId)
      }
    >
      {isMultipleShifts && (
        <>
          <Div fontSize="14px" fontWeight="500" margin="5px 0">
            <Sun />
            <Div fontSize="14px" fontWeight="500" margin="0 0 0 5px">
              {`${dayShiftsLength} ${
                dayShiftsLength === 1 ? "shift" : "shifts"
              }`}
            </Div>
          </Div>
          <Div fontSize="14px" fontWeight="500" margin="5px 0">
            <Moon />
            <Div fontSize="14px" fontWeight="500" margin="0 0 0 5px">
              {" "}
              {`${nightShiftsLength} ${
                nightShiftsLength === 1 ? "shift" : "shifts"
              }`}
            </Div>
          </Div>
        </>
      )}
      {!isMultipleShifts && (
        <>
          <Div fontSize="14px" margin="5px 0" color={textColor}>
            {shifts?.[0]?.roleRate.roleName}
          </Div>
          <Div
            fontSize="14px"
            fontWeight="500"
            margin="5px 0"
            color={textColor}
          >
            {formatHours(shifts?.[0])}
          </Div>
          <Div fontSize="14px" margin="5px 0" color={textColor}>
            {`${shifts?.[0]?.bookings.totalResults}/${shifts?.[0]?.numberRequested}`}
          </Div>
        </>
      )}
    </StyledOpenShift>
  );
};

export default OpenShiftWeeklyCard;

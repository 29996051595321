import { gql } from "@apollo/client";

const UPDATE_TARGET_ACCOUNT = gql`
  mutation updateTargetAccount(
    $id: ID!
    $accountName: String
    $contactEmail: String
    $contactWeb: String
    $contactPhone: String
    $isAutoAcceptEnabled: Boolean
    $isRequestAllEnabled: Boolean
    $isRebookingEnabled: Boolean
    $isSleepTimeEnabled: Boolean
    $policy: PolicyInputType
    $tags: [TagsInputType]
    $notes: String
    $ownerId: ID
    $subvenueSelection: String
  ) {
    updateTargetAccount(
      id: $id
      accountName: $accountName
      contactEmail: $contactEmail
      contactWeb: $contactWeb
      contactPhone: $contactPhone
      isAutoAcceptEnabled: $isAutoAcceptEnabled
      isRequestAllEnabled: $isRequestAllEnabled
      isRebookingEnabled: $isRebookingEnabled
      isSleepTimeEnabled: $isSleepTimeEnabled
      policy: $policy
      tags: $tags
      notes: $notes
      ownerId: $ownerId
      subvenueSelection: $subvenueSelection
    ) {
      id
      accountName
      contactEmail
      contactWeb
      contactPhone
      isAutoAcceptEnabled
      isRequestAllEnabled
      isRebookingEnabled
      isSleepTimeEnabled
      tags {
        id
      }
      notes
      ownerId
      subvenueSelection
    }
  }
`;

export default UPDATE_TARGET_ACCOUNT;

import { gql, useQuery } from "@apollo/client";
import { addAuthVars } from "@teamrota/authlib";
import { orderBy } from "lodash";
import { useEffect, useState } from "react";

export const GET_MEMBERS = addAuthVars(gql`
  query getMemberPools(
    $offset: Int!
    $limit: Int!
    $searchText: String!
    $poolTypes: [poolTypeEnumType]!
    $targetAccountId: ID
    $selectedAccountId: ID
    $roleRateId: ID!
    $venueId: ID!
  ) {
    account {
      id
      accountName
      memberPools(
        poolTypes: $poolTypes
        targetAccountId: $targetAccountId
        selectedAccountId: $selectedAccountId
        roleRateId: $roleRateId
        venueId: $venueId
        searchText: $searchText
        offset: $offset
        limit: $limit
      ) {
        totalResults
        data {
          id
          firstName
          lastName
          isQualifiedWithRole(roleRateId: $roleRateId)
          isInVenueServiceArea(venueId: $venueId)
        }
      }
    }
  }
`);

type UseMembersProps = {
  targetAccountId: string;
  selectedAccountId: string;
  venueId: string | null;
  roleRateId: string | null;
};

const useMembers = ({
  targetAccountId,
  selectedAccountId,
  venueId,
  roleRateId
}: UseMembersProps) => {
  const [members, setMembers] = useState<object[]>([]);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { data, loading, fetchMore, refetch } = useQuery(GET_MEMBERS, {
    variables: {
      limit: 50,
      offset: 0,
      venueId,
      roleRateId,
      targetAccountId,
      selectedAccountId,
      searchText: "",
      poolTypes: ["FAVOURITED", "RECOMMENDED"]
    },
    skip: !venueId || !roleRateId
  });

  const totalResults = data?.account?.memberPools?.totalResults;

  useEffect(() => {
    setMembers(data?.account?.memberPools?.data || []);
  }, [data]);

  useEffect(() => {
    if (offset === 0) return;

    if (!loading && !isLoading && offset < totalResults) {
      setIsLoading(true);

      fetchMore({
        variables: { offset },
        updateQuery: (_, { fetchMoreResult }) => {
          setMembers(members => {
            const allMembers = [
              ...members,
              ...(fetchMoreResult?.account?.memberPools?.data || [])
            ];

            return orderBy(allMembers, "firstName");
          });
        }
      });

      setIsLoading(false);
    }
  }, [offset]);

  const onLoadMore = () => {
    setOffset(offset => offset + 100);
  };

  const onFilter = (searchText: string) => {
    setOffset(0);
    refetch({ searchText });
  };

  const formattedMembers = members.map((member: any) => ({
    ...member,
    name: `${member.firstName} ${member.lastName}`
  }));

  return {
    members: formattedMembers,
    isLoading: loading && isLoading,
    onLoadMore,
    onFilter
  };
};

export default useMembers;

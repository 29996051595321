import styled from "styled-components";

export const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledFeaturesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  min-width: 70%;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.neutral.light};
`;

export const StyledFeaturesList = styled.div`
  width: 100%;
  height: calc(100vh - 450px);
  overflow: auto;
`;

export const StyledFeatureWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.neutral.light};
  border-radius: 0px !important;
`;

export const StyledHeading = styled.p`
  font-size: 16px;
  padding: 10px 0px;
`;

export const StyledDetail = styled.p`
  weight: 400;
  font-size: 13px;
  color: ${({ theme }) => theme.neutral.light};
`;

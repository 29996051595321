import React, { useState } from "react";

import { Role } from "@teamrota/authlib";

import Icon from "~/src/components/icon";
import { SHIFT_STATES } from "~/src/consts";
import { HasAnyRole } from "~/src/containers/has-role";
import refreshBonusRewards from "~/src/containers/provide/graphql/refresh-bonus-rewards";
import { StyledButton } from "~/src/containers/provide/provide.styles";
import { errorModal } from "~/src/utils/errors";
import BookingSearchModal from "./booking-search-modal";
import updateShiftState from "./graphql/update-shift-state";
import {
  Dropdown,
  ListItem,
  MenuContainer,
  StyledIcon
} from "./more-options-menu.styles";
import RequestAndAssignModal from "./request-assign-modal";
import RewardsModal from "./rewards-modal";

const MoreOptionsMenu = ({
  shift,
  updateShiftState,
  refreshBonusRewards,
  refetch
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isRequestAndAssignOpen, setIsRequestAndAssignOpen] = useState(false);
  const [isRewardsModalOpen, setIsRewardsModalOpen] = useState(false);
  const [isBookingsModalOpen, setIsBookingsModalOpen] = useState(false);

  const [finalisedIsLoading, setFinalisedIsLoading] = useState(false);

  const [visibleIsLoading, setVisibleIsLoading] = useState(false);

  const [unlinkedIsLoading, setUnlinkedIsLoading] = useState(false);

  const { roleId } = shift.roleRate;
  const roleRateId = shift.roleRate.id;
  const { id: sourceAccId } = shift.sourceAccount;
  const { rewards, startTime, endTime } = shift;

  const hide = () => {
    setIsOpen(false);
    document.removeEventListener("click", handleClickOffElement);
  };

  const toggleOpenClose = () => {
    if (isOpen) {
      hide();
    } else {
      setIsOpen(true);
      document.addEventListener("click", handleClickOffElement);
    }
  };

  const handleClickOffElement = e => {
    const specifiedElement = document.getElementById(menuId);
    const isClickInside =
      specifiedElement && specifiedElement.contains(e.target);
    if (!isClickInside) {
      hide();
    }
  };

  const setState = (state, bool) => {
    switch (state) {
      case SHIFT_STATES.FINALISED:
      case SHIFT_STATES.UNFINALISED:
        setFinalisedIsLoading(bool);
        break;
      case SHIFT_STATES.VISIBLE:
        setVisibleIsLoading(bool);
        break;
      case SHIFT_STATES.UNLINKED:
        setUnlinkedIsLoading(bool);
        break;
      default:
    }
  };

  const setNewShiftState = async state => {
    try {
      setState(state, true);
      await updateShiftState(shift.id, state.toLowerCase());
      setState(state, false);
    } catch (e) {
      setState(state, false);
      errorModal(e);
    }
  };

  const toggleRewardsModal = async () => {
    if (!isRewardsModalOpen) {
      await refreshBonusRewards();
    }
    setIsRewardsModalOpen(!isRewardsModalOpen);
    setIsOpen(false);
  };

  const toggleBookingsModal = async () => {
    setIsBookingsModalOpen(!isBookingsModalOpen);
    setIsOpen(false);
  };

  let menuId = `provide-options-menu-${Math.random()}`.replace(".", "");
  return (
    <MenuContainer id={shift.id}>
      <StyledButton
        iconName={Icon.names.MORE}
        onClick={toggleOpenClose}
        isIconOnly
        isSmall
        isBorderless
      />
      <StyledIcon
        isHidden={
          !visibleIsLoading && !unlinkedIsLoading && !finalisedIsLoading
        }
        name={Icon.names.LOADING}
        size="small"
      />
      <Dropdown isOpen={isOpen}>
        <ListItem onClick={() => setIsRequestAndAssignOpen(true)}>
          Request/Assign Staff
        </ListItem>
        <ListItem onClick={toggleBookingsModal}>Search bookings</ListItem>
        <ListItem onClick={() => setNewShiftState(SHIFT_STATES.VISIBLE)}>
          Open to all Staff
          <StyledIcon
            isHidden={!visibleIsLoading}
            name={Icon.names.LOADING}
            size="xsmall"
            color={Icon.colors.BLACK}
          />
        </ListItem>
        <ListItem
          onClick={() =>
            setNewShiftState(
              shift.finalisedAt
                ? SHIFT_STATES.UNFINALISED
                : SHIFT_STATES.FINALISED
            )
          }
        >
          {`${shift.finalisedAt ? "Unfinalise" : "Finalise"} shift`}
          <StyledIcon
            isHidden={!finalisedIsLoading}
            name={Icon.names.LOADING}
            size="xsmall"
            color={Icon.colors.BLACK}
          />
        </ListItem>
        {shift.totalInGroup > 1 && (
          <ListItem onClick={() => setNewShiftState(SHIFT_STATES.UNLINKED)}>
            Unlink shift
            <StyledIcon
              isHidden={!unlinkedIsLoading}
              name={Icon.names.LOADING}
              size="xsmall"
              color={Icon.colors.BLACK}
            />
          </ListItem>
        )}

        <HasAnyRole roles={[Role.SHIFTS_COST, Role.SHIFTS_PAY]}>
          <ListItem onClick={toggleRewardsModal}>Add Reward</ListItem>
        </HasAnyRole>
      </Dropdown>
      {!!shift?.id && !!shift?.venue && isRequestAndAssignOpen && (
        <RequestAndAssignModal
          roleId={roleId}
          sourceAccountId={sourceAccId}
          shiftId={shift.id}
          onClose={() => setIsRequestAndAssignOpen(false)}
          venueId={shift.venue.id}
          roleRateId={roleRateId}
          refetch={refetch}
          dates={{ startTime, endTime }}
          shiftType={shift.shiftType}
        />
      )}
      {shift.id && (
        <RewardsModal
          isOpen={isRewardsModalOpen}
          onClose={toggleRewardsModal}
          shiftId={shift.id}
          existingRewards={rewards}
        />
      )}
      {shift.id && (
        <BookingSearchModal
          isOpen={isBookingsModalOpen}
          onClose={toggleBookingsModal}
          shiftId={shift.id}
        />
      )}
    </MenuContainer>
  );
};

export default updateShiftState(refreshBonusRewards(MoreOptionsMenu));

import React from "react";
import { RotaModal, RotaButton } from "@teamrota/rota-design";

import errorCircle from "~/src/images/error_circle.png";
import SaveList from "../../SaveList";
import { StyledModalWrapper } from "./styles";

const ErrorModal = ({ handleClose, list }) => {
  return (
    <RotaModal onClose={handleClose} isSmall>
      <StyledModalWrapper>
        <div className="top_block">
          <img src={errorCircle} className="icon_img" />
          <div className="title">Upload Failed</div>
        </div>
        <div className="bottom_block">
          <div className="title">
            The file has not met the criteria and cannot be imported. You can
            download a feedback file containing the errors. Please amend the
            file and try again.
          </div>
          <div className="buttons">
            <SaveList list={list} />
            <RotaButton onClick={handleClose} className="ok_button">
              Ok
            </RotaButton>
          </div>
        </div>
      </StyledModalWrapper>
    </RotaModal>
  );
};

export default ErrorModal;

import React from "react";

import { Content, SmallContent, Divider, Title } from "./index.styles";

export default function ModalContent({
  title,
  children,
  isSmallContent,
  isLarge
}) {
  const Wrapper = isSmallContent ? SmallContent : Content;
  return (
    <Wrapper isLarge={isLarge}>
      <Title>{title}</Title>
      <Divider />
      {children}
    </Wrapper>
  );
}

import styled from "styled-components";
import { COLORS, STYLE_CONSTS } from "~/src/styles/config.style";

export const Wrapper = styled.div`
  width: 400px;
  text-align: center;
  padding: 15px;
  overflow: auto;
  height: 600px;
  max-height: 80%;
`;

export const StarsWrapper = styled.div`
  margin: ${STYLE_CONSTS.UNIT_1};
`;

export const Name = styled.h2`
  margin: 0;
  font-size: ${STYLE_CONSTS.FONT_LARGE};
  color: ${COLORS.DARK_GREY};
`;

export const Roles = styled.div`
  color: ${COLORS.COOL_GREY};
`;

export const ToggleMoreRoles = styled.div`
  color: ${({ theme }) => theme.primary.main};
  font-size: ${STYLE_CONSTS.FONT_XSMALL};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const Shift = styled.div`
  width: calc(50% - 1px);
  text-align: center;
  display: ${({ shouldNotShow }) => (shouldNotShow ? "none" : "inline-block")};
`;

export const NumberWrapper = styled.div`
  color: ${({ theme }) => theme.primary.main};
  font-size: ${STYLE_CONSTS.FONT_XXLARGE};
`;

export const TextInputWrapper = styled.div`
  text-align: left;
  padding: 0 ${STYLE_CONSTS.UNIT_3};
  margin: 0 auto;
  margin-bottom: ${STYLE_CONSTS.UNIT_4};
`;

export const Divider = styled.div`
  display: ${({ shouldNotShow }) => (shouldNotShow ? "none" : "inline-block")};
  border-left: 2px solid ${COLORS.COOL_GREY};
  height: 20px;
`;

export const LinkedInProfileWrapper = styled.div`
  padding: 3px;
`;

export const SummaryWrapper = styled.div`
  padding: 5px;
`;

export const ExperienceTimelineWrapper = styled.div`
  display: ${({ hasExperience }) =>
    Array.isArray(hasExperience) && hasExperience.length ? "block" : "none"};
  padding-left: 15px;
`;

export const TitleWrapper = styled.div`
  font-size: 16px;
  text-align: center;
  margin-top: 15px;
  margin-left: -25px;
`;

export const LinkWrapper = styled.a``;

export const ContainerWrapper = styled.div``;

import styled from "styled-components";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";

export const HEADER_FOOTER_HEIGHT = STYLE_CONSTS.UNIT_4;
export const CONTAINER_PADDING = "2px";
export const CLUSTER_EVENT_HEIGHT = "140px";
export const CLUSTER_MARGIN_BOTTOM = STYLE_CONSTS.UNIT_1;

export const ClusterContainer = styled.div`
  border-radius: ${STYLE_CONSTS.BORDER_RADIUS_L};
  position: absolute;
  overflow: hidden;
  user-select: none;
  padding: ${CONTAINER_PADDING};
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
`;

export const Cluster = styled.div`
  background: ${({ theme }) => theme.neutral.lighter};
  border-radius: ${STYLE_CONSTS.BORDER_RADIUS_L};
  position: relative;
  height: 100%;
  padding: 0 ${STYLE_CONSTS.UNIT_1};
`;

export const ClusterWrapper = styled.div`
  height: ${props =>
    props.overrideHeight ? `${props.overrideHeight}px` : CLUSTER_EVENT_HEIGHT};
  margin-bottom: ${CLUSTER_MARGIN_BOTTOM};
  margin-right: ${STYLE_CONSTS.UNIT_2};
`;

export const ScrollView = styled.div`
  height: ${({ height }) => `${height}px`};
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const ClusterEnd = styled.div`
  font-size: ${STYLE_CONSTS.FONT_SMALL};
  color: ${COLORS.CLOUDY_BLUE};
  height: ${HEADER_FOOTER_HEIGHT};
  line-height: ${HEADER_FOOTER_HEIGHT};
`;

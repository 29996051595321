import styled, { keyframes } from "styled-components";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";

const TRAY_SIZE = 100;
const TRAY_SIZE_DROPDOWN = 170;
const TRAY_POSITION = "123px";

export const ShiftId = styled.div`
  color: ${COLORS.CLOUDY_BLUE};
  font-size: ${STYLE_CONSTS.FONT_SMALL};
  margin-bottom: ${STYLE_CONSTS.UNIT_2};
`;

export const Flex = styled.div`
  margin-top: 3px;
  ${props => props.width && `width: ${props.width}%;`};
  display: flex;
  ${({ paddingLeft }) => (paddingLeft ? "padding-left: 25px;" : null)};
`;

export const InternalShiftId = styled(ShiftId)`
  margin-left: ${({ leftMargin }) => leftMargin || ""};
`;

export const ShiftIdWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

export const HeaderInnerWrapper = styled.div`
  background-color: "green";
  width: 100%;
  display: flex;
`;

export const HeaderBookingInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${props => (props.noPadLeft ? 0 : "25px")};
  ${props =>
    props.marginRight
      ? `margin-right: ${props.marginRight}; margin-left: auto;`
      : null};
`;

const FadeIn = keyframes`
  from {
      opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const TooltipContainer = styled.div`
  display: inline-block;
  position: relative;
  left: 5px;
  top: 4px;
`;

export const TooltipPrintContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 20px;
  top: -22px;
`;

export const PrintLink = styled.p`
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.primary.main};
  }
`;

export const HeaderBookingText = styled.p`
  ${props => props.inline && "display: inline;"};
  animation: 1s ${FadeIn} ease;
  margin-bottom: 0;
  color: ${({ cloudyBlue }) =>
    cloudyBlue ? `${COLORS.CLOUDY_BLUE}` : `${COLORS.BLACK}`};
  font-size: ${STYLE_CONSTS.FONT_SMALL};
  ${({ isBold }) => (isBold ? "font-weight: 400;" : null)};
`;

export const PrintDropDown = styled.div`
  min-width: ${TRAY_SIZE}px;
  text-align: right;
  float: right;
  color: ${COLORS.CLOUDY_BLUE};

  & > #nav-tray-show-hide {
    padding: 0 ${STYLE_CONSTS.UNIT_2};
    display: none;
  }
  &:hover > #nav-tray-show-hide {
    display: block;
  }
`;

export const NavTray = styled.div`
  box-shadow: ${STYLE_CONSTS.FLOATING};
  width: ${TRAY_SIZE_DROPDOWN};
  text-align: left;
  background-color: ${({ theme }) => theme.white};
  padding: ${STYLE_CONSTS.UNIT_1};
  position: absolute;
  right: ${STYLE_CONSTS.UNIT_2};
  top: ${TRAY_POSITION};
  line-height: ${STYLE_CONSTS.FONT_LARGE};
  font-size: ${STYLE_CONSTS.FONT_SMALL};
`;

export const Divider = styled.div`
  border: none;
  border-bottom: ${STYLE_CONSTS.SMALL_DIVIDER_BORDER};
  margin: ${STYLE_CONSTS.UNIT_1} 0;
  width: 80px;
`;

export const StyledWrapFooter = styled.div`
  position: fixed;
  bottom: 0;
  right: 40px;
  left: 115px;
  background-color: ${({ theme }) => theme.white};
  padding: 0 10px 0 24px;
`;

export const StyledWrapLegend = styled.div`
  height: 47px;
  border-top: 1px solid ${({ theme }) => theme.neutral.main};
  display: grid;
  grid-template-columns: 0.15fr 0.2fr 0.25fr 0.3fr 0.3fr 1fr;
  grid-gap: 2px;
  padding-left: 6px;
  padding-right: 17px;
  align-items: center;
`;

export const StyledWrapParagraph = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutral.main};
  grid-column-start: ${({ start }) => start};
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify};
`;

export const StyledWrapIcon = styled.div`
  border-right: 1px solid ${({ theme }) => theme.neutral.lighter};
  grid-column-start: ${({ start }) => start};
  display: flex;
  align-items: center;
  height: 16px;
  padding-left: ${({ padding }) => padding};
`;

import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const Main = styled.div`
  overflow: hidden;
  border-bottom: ${STYLE_CONSTS.SMALL_DIVIDER_BORDER_LIGHT};
`;

export const Count = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 100%;
  font-size: 12px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  border: 2px solid ${({ theme }) => theme.neutral.main};
  margin-right: ${STYLE_CONSTS.UNIT_1};
  box-sizing: border-box;
  ${({ isOpen, theme }) =>
    isOpen &&
    `
      font-weight: bold;
      color: ${theme.white};
      background: ${theme.primary.main};
      border-color: ${theme.primary.main};
    `}
`;

export const Content = styled.div`
  ${props => !props.isOpen && "display: none;"}
  padding: ${props => (props.noPadding ? "0px" : STYLE_CONSTS.UNIT_3)};
  border-top: ${STYLE_CONSTS.SMALL_DIVIDER_BORDER_LIGHT};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: ${STYLE_CONSTS.UNIT_2};
  flex-direction: row;
  justify-content: center;
`;

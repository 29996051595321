import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Wrapper, Connection, AccountName } from "./pool-connections.styles";

const PoolsConnections = ({
  connections,
  overidePoolWrapperStyle,
  onSelectConnection
}) => {
  const sortedConnections = connections.sort((a, b) =>
    _.get(a, "sourceAccount.accountName", "").toUpperCase() >
    _.get(b, "sourceAccount.accountName", "").toUpperCase()
      ? 1
      : -1
  );

  const [highlightedConnection, setHighlightedConnection] = useState(
    sortedConnections?.[0]?.sourceAccount?.id
  );

  if (!connections) return <Wrapper />;

  useEffect(() => {
    onSelectConnection(highlightedConnection);
  }, []);

  const onChange = event => {
    const connectionId = event?.target?.value;
    setHighlightedConnection(connectionId);
    onSelectConnection(connectionId);
  };

  const isDisplayConnections = sortedConnections?.length > 1;

  return (
    <Wrapper overidePoolWrapperStyle={overidePoolWrapperStyle}>
      <div onChange={onChange}>
        {isDisplayConnections &&
          sortedConnections.map(({ sourceAccount }) => (
            <Connection className="radio" key={sourceAccount?.id}>
              <label>
                <input
                  type="radio"
                  value={sourceAccount?.id}
                  checked={sourceAccount?.id === highlightedConnection}
                />
                <AccountName>{sourceAccount?.accountName}</AccountName>
              </label>
            </Connection>
          ))}
      </div>
    </Wrapper>
  );
};

export default PoolsConnections;

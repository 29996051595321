import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";

import {
  RotaTag,
  RotaInput,
  RotaButton,
  RotaButtonCircle
} from "@teamrota/rota-design";

import Modal from "~/src/components/modal";
import ModalContent from "~/src/containers/modals/components/modal-content";

import adminClient from "~/src/containers/admin-dashboard/graphql/admin-client";
import { GET_TAGS } from "~/src/containers/admin-dashboard/graphql/get-tags";
import { CREATE_TAG } from "~/src/containers/admin-dashboard/graphql/create-tag";
import AccountsList from "~/src/containers/admin-dashboard/modules/accounts-list";

import {
  StyledMainHeading,
  StyledMainDetail,
  StyledMainHeaderWrapper,
  StyledWrapper,
  StyledFeaturesWrapper,
  StyledFeatureHeader
} from "../styles";

import { StyledWrapTags, StyledContentModal } from "./styles";

const AddAccountsTags = ({ setSnack }) => {
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [isAddTagModalOpen, setIsAddTagModalOpen] = useState(false);
  const [createTagInput, setCreateTagInput] = useState("");
  const [createTagInputError, setCreateTagInputError] = useState("");
  const [selectedAccountName, setSelectedAccountName] = useState();

  // this does not need the adminClient
  // mutation exists in the regular graphql space
  // reason being it will eventually be added to account settings
  const [createTag] = useMutation(CREATE_TAG, {
    variables: {
      accountId: selectedAccountId
    }
  });

  const { data, loading, refetch } = useQuery(GET_TAGS, {
    variables: {
      accountId: selectedAccountId
    },
    client: adminClient,
    skip: !selectedAccountId
  });

  const tags = data?.tags;

  const handleCreateTag = async () => {
    if (!createTagInput) {
      setCreateTagInputError("tag name is required");
    } else {
      try {
        await createTag({ variables: { tags: [createTagInput] } });
        setSnack({ message: "Success!", severity: "success" });
      } catch (e) {
        setSnack({ message: "Something Went Wrong.", severity: "error" });
      } finally {
        handleModalClose();
        refetch();
      }
    }
  };

  const handleModalClose = () => {
    setCreateTagInput("");
    setCreateTagInputError(null);
    setIsAddTagModalOpen(false);
  };

  return (
    <>
      <StyledMainHeaderWrapper>
        <StyledMainHeading>Add Account Tags</StyledMainHeading>
        <StyledMainDetail>Manage tags for all accounts</StyledMainDetail>
      </StyledMainHeaderWrapper>
      <StyledWrapper>
        <AccountsList
          onSelect={(id, accountName) => {
            setSelectedAccountId(id);
            setSelectedAccountName(accountName);
          }}
          selectedAccountId={selectedAccountId}
          selectedAccountName={selectedAccountName}
        />
        {selectedAccountId && (
          <StyledFeaturesWrapper>
            <StyledFeatureHeader>
              <h3>{selectedAccountName} Tags</h3>
              <RotaButtonCircle onHandle={() => setIsAddTagModalOpen(true)} />
            </StyledFeatureHeader>
            <StyledWrapTags>
              {loading && <p>loading...</p>}
              <RotaTag tags={tags || []} isReadOnly />
            </StyledWrapTags>
          </StyledFeaturesWrapper>
        )}
      </StyledWrapper>
      <Modal isOpen={isAddTagModalOpen} onClose={handleModalClose}>
        <ModalContent isSmallContent title="Create tag">
          <StyledContentModal>
            <RotaInput
              label="Tag name"
              placeholder="write tag"
              onChange={({ target }) => setCreateTagInput(target.value)}
              value={createTagInput}
              isError={!!createTagInputError}
              errorMessage={createTagInputError}
            />
            <div>
              <RotaButton variant="secondary" onClick={handleModalClose}>
                cancel
              </RotaButton>
              <RotaButton onClick={handleCreateTag}>Create tag</RotaButton>
            </div>
          </StyledContentModal>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddAccountsTags;

import { createReducer } from "~/src/utils";
import { POOL_TYPES } from "@teamrota/rota-common";

export const SHOW_MEMBER_DETAILS = "pools/SHOW_MEMBER_DETAILS";
export const HIDE_MEMBER_DETAILS = "pools/HIDE_MEMBER_DETAILS";
export const UPDATE_SELECTED_CONNECTION = "pools/UPDATE_SELECTED_CONNECTION";
export const UPDATE_SEARCH = "pools/UPDATE_SEARCH";
export const TOGGLE_GROUP_EDIT = "pools/TOGGLE_GROUP_EDIT";
export const UNTOGGLE_GROUP_EDIT = "pools/UNTOGGLE_GROUP_EDIT";
export const CLEAR_INPUT_VALUE = "pools/CLEAR_INPUT_VALUE";
export const UPDATE_INPUT_VALUE = "pools/UPDATE_INPUT_VALUE";

export const updateInputValue = (inputValue, isSafari) => ({
  type: UPDATE_INPUT_VALUE,
  payload: { inputValue, isSafari }
});

export const clearInputValue = inputValue => ({
  type: CLEAR_INPUT_VALUE,
  payload: { inputValue }
});

export const unToggleGroupNameEdit = status => ({
  type: UNTOGGLE_GROUP_EDIT,
  payload: { status }
});

export const toggleGroupNameEdit = status => ({
  type: TOGGLE_GROUP_EDIT,
  payload: { status }
});

export const showMemberDetails = id => ({
  type: SHOW_MEMBER_DETAILS,
  payload: { id }
});

export const hideMemberDetails = () => ({
  type: HIDE_MEMBER_DETAILS
});

export const updateSelectedConnection = id => ({
  type: UPDATE_SELECTED_CONNECTION,
  payload: { id }
});

export const updateSearch = (poolType, searchText) => ({
  type: UPDATE_SEARCH,
  payload: { poolType, searchText }
});

export const DEFAULT_STATE = {
  selectedMemberId: null,
  selectedConnectionId: null,
  searchText: {
    [POOL_TYPES.RECOMMENDED]: "",
    [POOL_TYPES.FAVOURITED]: "",
    [POOL_TYPES.BLACKLISTED]: ""
  },
  isGroupNameEditable: false,
  isSafari: false,
  inputValue: ""
};

const formatInputForSafari = (prevInput, nextInput) => {
  if (!prevInput || nextInput.startsWith(prevInput)) return nextInput;
  if (nextInput.length === 1) return prevInput + nextInput;
  if (!nextInput) return prevInput.substring(0, prevInput.length - 1);
  return "";
};

export const handlers = {
  [UPDATE_INPUT_VALUE]: (state, { payload }) => ({
    ...state,
    inputValue: payload.isSafari
      ? formatInputForSafari(state.inputValue, payload.inputValue)
      : payload.inputValue
  }),
  [CLEAR_INPUT_VALUE]: state => ({
    ...state,
    inputValue: ""
  }),
  [UNTOGGLE_GROUP_EDIT]: state => ({
    ...state,
    isGroupNameEditable: false
  }),
  [TOGGLE_GROUP_EDIT]: state => ({
    ...state,
    isGroupNameEditable: true
  }),

  [SHOW_MEMBER_DETAILS]: (state, { payload }) => ({
    ...state,
    selectedMemberId: payload.id
  }),
  [HIDE_MEMBER_DETAILS]: state => ({
    ...state,
    selectedMemberId: null
  }),
  [UPDATE_SELECTED_CONNECTION]: (state, { payload }) => ({
    ...state,
    selectedConnectionId: payload.id,
    searchText: { ...DEFAULT_STATE.searchText }
  }),
  [UPDATE_SEARCH]: (state, { payload }) => ({
    ...state,
    searchText: {
      ...state.searchText,
      [payload.poolType]: payload.searchText
    }
  })
};

export default createReducer(handlers, DEFAULT_STATE);

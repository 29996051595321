import React from "react";
import { uniqBy } from "lodash";
import { RotaChip } from "@teamrota/rota-design";
import {
  Label,
  MainContainer,
  TagsContainer,
  ChipsContainer
} from "./view-tags.styles";

const ViewTags = ({ venueRoles, venueRoleTags }) => {
  const venueTags = venueRoleTags?.tags || [];
  const roleTags = venueRoleTags?.roleTags || [];

  const combineRoleAndVenueTags = (allRoles, venueTags, allRoleTags) => {
    return allRoles.map(role => {
      const roleTags =
        allRoleTags.find(roleTag => roleTag.roleId === role.roleId)?.tags || [];

      return {
        id: role.roleId,
        name: role.roleName,
        tags: uniqBy([...venueTags, ...roleTags], tag => tag.id)
      };
    });
  };

  const removeRolesWithNoTags = roleTags =>
    roleTags.filter(roleTags => roleTags.tags.length > 0);

  const roleAndVenueTags = removeRolesWithNoTags(
    combineRoleAndVenueTags(venueRoles, venueTags, roleTags)
  );

  if (!venueTags?.length && !roleTags.length) return null;

  return (
    <>
      <MainContainer>
        {!!venueTags.length && (
          <TagsContainer>
            <Label>Venue tag(s):</Label>
            <ChipsContainer>
              {venueTags?.map(tag => (
                <RotaChip isDisabled={true} chipText={tag.name} key={tag.id} />
              ))}
            </ChipsContainer>
          </TagsContainer>
        )}
        {roleAndVenueTags?.map(role => (
          <TagsContainer key={role.id}>
            <Label>{role.name} tag(s):</Label>
            <ChipsContainer>
              {role?.tags?.map(tag => (
                <RotaChip isDisabled={true} chipText={tag.name} key={tag.id} />
              ))}
            </ChipsContainer>
          </TagsContainer>
        ))}
      </MainContainer>
    </>
  );
};

export default ViewTags;

import React from "react";
import omit from "lodash/fp/omit";
import styled from "styled-components";
import TextareaAutoSize from "react-textarea-autosize";
import { inputStyles } from "../input-styles";

const textareaOnlyProps = [
  "isRounded",
  "isError",
  "hasIconRight",
  "hasIconLeft",
  "isInline",
  "isSmall",
  "isBorderless",
  "fontColor",
  "bgColor",
  "isExtendable"
];

export const TextareaElement = styled.textarea`
  ${inputStyles};
  background: ${({ bgColor, theme }) =>
    bgColor ? theme.neutral.light : "inherit"};
  color: ${props => (props.bgColor ? "black" : "inherit")};
`;

export const MultilineInput = styled(props => (
  <TextareaAutoSize {...omit(textareaOnlyProps, props)} />
))`
  ${inputStyles};
`;

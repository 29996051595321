import React, { useRef } from "react";
import {
  StyledWrapRole,
  StyledSpan,
  StyledWrapTag,
  FormContainer
} from "../styles";
import {
  RotaButtonIcon,
  RotaDropdown,
  RotaInput,
  RotaTag,
  RotaTextarea
} from "@teamrota/rota-design";
import { Controller } from "react-hook-form";
import { skewOptions } from "~/src/consts";

const ShiftDetailsComponent = ({
  errors,
  register,
  initialValues,
  inEditMode,
  uniformTemplates,
  venueTemplates,
  briefingOptions,
  control,
  setValue,
  clearErrors
}) => {
  const getDropDownOptions = optionArray => {
    const options = [];
    optionArray?.map(item => {
      options.push({ value: item.id, label: item.name ?? item.identifier });
    });
    return options;
  };
  const getBriefingContentFromObject = value => {
    const brief = briefingOptions.filter(item => item.value === value);
    return brief[0].content;
  };
  const onChangeBriefing = value => {
    const briefValue = getBriefingContentFromObject(value);
    setValue("briefing", briefValue);
  };

  const ref = useRef();
  return (
    <FormContainer>
      <RotaInput
        {...register("shiftId")}
        placeholder={initialValues?.shiftId}
        disabled={true}
        label={"Shift ID"}
        errorMessage={errors.shiftId?.message}
        isError={errors.shiftId?.message}
      />
      <RotaInput
        {...register("shiftGroupId")}
        placeholder={initialValues?.shiftGroupId}
        disabled={true}
        label={"Group ID"}
        errorMessage={errors.shiftGroupId?.message}
        isError={errors.shiftGroupId?.message}
      />
      <RotaInput
        {...register("numberRequested")}
        placeholder={initialValues?.numberRequested}
        disabled={!inEditMode}
        label={"Number Requested"}
        errorMessage={errors.numberRequested?.message}
        isError={errors.numberRequested?.message}
      />
      <RotaInput
        {...register("role")}
        placeholder={initialValues?.role}
        disabled={true}
        label={"Role"}
        errorMessage={errors.role?.message}
        isError={errors.role?.message}
      />
      <Controller
        control={control}
        name="algoSkew"
        render={({ field: { onChange, value } }) => (
          <RotaDropdown
            label={"Fulfilment"}
            options={skewOptions}
            errorMessage={errors.algoSkew?.message}
            isError={errors.algoSkew?.message}
            value={value}
            onChange={onChange}
            isDisabled={!inEditMode}
          />
        )}
      />
      <Controller
        control={control}
        name="uniformTemplateId"
        render={({ field: { onChange, value } }) => (
          <RotaDropdown
            label={"Uniform"}
            options={getDropDownOptions(uniformTemplates)}
            errorMessage={errors.uniformTemplateId?.message}
            isError={errors.uniformTemplateId?.message}
            value={value}
            onChange={onChange}
            isDisabled={!inEditMode}
          />
        )}
      />
      <Controller
        control={control}
        name="venueId"
        render={({ field: { onChange, value } }) => (
          <RotaDropdown
            label={"Venue"}
            options={getDropDownOptions(venueTemplates)}
            errorMessage={errors.venueId?.message}
            isError={errors.venueId?.message}
            value={value}
            isDisabled={!inEditMode}
            onChange={onChange}
          />
        )}
      />
      <RotaDropdown
        options={briefingOptions}
        label={"Briefing"}
        isDisabled={!inEditMode}
        onChange={onChangeBriefing}
      />
      <RotaTextarea
        isLabelTop={false}
        {...register("briefing")}
        placeholder={initialValues?.briefing}
        disabled={!inEditMode}
        errorMessage={errors.briefing?.message}
        isError={errors.briefing?.message}
      />
      <RotaTextarea
        isLabelTop={false}
        {...register("meetingPoint")}
        placeholder={initialValues?.meetingPoint}
        disabled={!inEditMode}
        label={"Instructions upon arrival"}
        errorMessage={errors.meetingPoint?.message}
        isError={errors.meetingPoint?.message}
      />
      <RotaTextarea
        isLabelTop={false}
        {...register("Directions")}
        placeholder={initialValues?.directions}
        disabled={!inEditMode}
        label={"Directions"}
        errorMessage={errors.directions?.message}
        isError={errors.directions?.message}
      />
      <RotaInput
        {...register("name")}
        placeholder={initialValues?.name}
        disabled={true}
        label={"Event Name"}
        errorMessage={errors.name?.message}
        isError={errors.name?.message}
      />
      <RotaInput
        {...register("identifier")}
        placeholder={initialValues?.identifier}
        disabled={!inEditMode}
        label={"My ID"}
        errorMessage={errors.identifier?.message}
        isError={errors.identifier?.message}
      />
      <Controller
        control={control}
        name="startDate"
        render={({ field: { onChange, value } }) => (
          <RotaInput
            placeholder={initialValues?.startDate}
            disabled={!inEditMode}
            label={"Start Date"}
            errorMessage={errors.startDate?.message}
            isError={errors.startDate?.message}
            type={"date"}
            value={value}
            onChange={e => {
              clearErrors(["startTime", "endTime", "endDate"]);
              onChange(e);
            }}
          />
        )}
      />
      <Controller
        control={control}
        name="startTime"
        render={({ field: { onChange, value } }) => (
          <RotaInput
            placeholder={initialValues?.startTime}
            disabled={!inEditMode}
            label={"Start Time"}
            errorMessage={errors.startTime?.message}
            isError={errors.startTime?.message}
            value={value}
            onChange={e => {
              clearErrors(["endDate", "endTime", "startDate"]);
              onChange(e);
            }}
          />
        )}
      />
      <Controller
        control={control}
        name="endDate"
        render={({ field: { onChange, value } }) => {
          return (
            <RotaInput
              placeholder={initialValues?.endDate}
              disabled={!inEditMode}
              label={"End Date"}
              errorMessage={errors.endDate?.message}
              isError={errors.endDate?.message}
              type={"date"}
              value={value}
              onChange={e => {
                clearErrors(["startTime", "endTime", "startDate"]);
                onChange(e);
              }}
            />
          );
        }}
      />
      <Controller
        control={control}
        name="endTime"
        render={({ field: { onChange, value } }) => (
          <RotaInput
            placeholder={initialValues?.endTime}
            disabled={!inEditMode}
            label={"End Time"}
            errorMessage={errors.endTime?.message}
            isError={errors.endTime?.message}
            value={value}
            onChange={e => {
              clearErrors(["startTime", "endDate", "startDate"]);
              onChange(e);
            }}
          />
        )}
      />
      <StyledWrapRole>
        <>
          <StyledSpan>Toggle tags:</StyledSpan>
          {initialValues?.tags?.length > 0 && (
            <RotaButtonIcon
              onClick={() => {
                ref.current.scrollLeft -= 100;
              }}
              icon="arrowLeft"
            />
          )}
          <StyledWrapTag ref={ref} overflow="hidden" margin="0 10px">
            <RotaTag
              tags={initialValues?.tags || []}
              isReadOnly
              emptyMessage={"No tags found"}
              styleTag={{
                marginRight: "16px"
              }}
            />
          </StyledWrapTag>
          {initialValues?.tags?.length > 0 && (
            <RotaButtonIcon
              onClick={() => {
                ref.current.scrollLeft += 100;
              }}
              icon="arrowRight"
            />
          )}
        </>
      </StyledWrapRole>
    </FormContainer>
  );
};

export default ShiftDetailsComponent;

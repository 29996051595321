import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { transform, uniqBy } from "lodash";
import { Role } from "@teamrota/authlib";
import { RotaButton, RotaTab } from "@teamrota/rota-design";

import { ROLE_TABS } from "~/src/consts";
import useAuth from "~/src/auth/hooks/use-auth";
import { useCurrency } from "~/src/containers/profile-loader";
import asyncConfirm from "~/src/utils/async-confirm";
import FormStepSegment from "~/src/components/form-step-segment";
import Select from "~/src/components/form-components/select";
import TextInput from "~/src/components/form-components/text-input";
import RateBreakdown from "~/src/components/RateBreakdown";
import { useVenueRoleTags } from "~/src/graphql/queries/use-venue-role-tags/use-venue-role-tags";

import ShiftTagsViewModal from "./shift-tags-view-modal";
import { useTagsForAccount } from "./use-tags-for-account";
import {
  useDayRoleOptions,
  useCalculatedRoleOptions,
  useUncalculatedRoleOptions,
  useVenueOptions,
  useUpdateRoleRate,
  useUpdateVenue,
  getFormTitle,
  mapStateToProps
} from "./selectors";
import { useValidateAudience } from "../shared";

import { TabsWrapper, TabsTitle, RoleAndCountWrapper } from "./styles";
import { Actions, Fieldset, Header, HeaderTitle, EditButton } from "../styles";

const FormAudience = props => {
  const {
    shift,
    isOpen,
    hasVisited,
    hasCompletedAllSteps,
    goToSelf,
    goToNext,
    handleShiftUpdate,
    stepNumber,
    lastUsedVenueId,
    lastUsedUniform,
    lastUsedBriefing,
    isLoadingLastUsedUniformAndBriefing,
    targetAccount,
    connections
  } = props;

  const {
    tagsAccountName,
    tagsAvailableForShift,
    tagsCompulsoryForShift
  } = useTagsForAccount({
    roleRateId: shift.roleRateId,
    roleRateAccountId: shift?.privates?.roleRateAccount?.id
  });

  const sourceAccount = connections?.data[0]?.sourceAccount;

  const { venuesWithTags } = useVenueRoleTags(
    targetAccount?.id,
    sourceAccount?.id
  );

  const { currencySymbol } = useCurrency();

  React.useEffect(() => {
    const uniformTemplateId = lastUsedUniform?.id || null;
    const briefing = lastUsedBriefing?.content || "";
    const briefingId = lastUsedBriefing?.id || null;
    const fixedLabel = lastUsedBriefing?.identifier || "";

    if (!isLoadingLastUsedUniformAndBriefing) {
      handleShiftUpdate({
        uniformTemplateId,
        briefing,
        briefingId,
        privates: {
          ...shift.privates,
          fixedLabel
        }
      });
    }
  }, [isLoadingLastUsedUniformAndBriefing]);

  const [isDayRoles, setIsDayRoles] = useState(false);
  const [isRoleSelected, setIsRoleSelected] = useState(false);
  const [selectedTab, setSelectedTab] = useState(ROLE_TABS[0]);

  const { tags, roleRateId, numberRequested, venueId } = shift;

  const handleTagsModalOpen = async () => {
    const initialSelectedTags = tags;

    const confirmResult = await asyncConfirm(
      "Select the tags to add to this shift",
      {
        confirmButtonText: "Save",
        falseButtonText: "Cancel",
        isConfirmButtonGreen: true,
        subComponent: () => (
          <ShiftTagsViewModal
            tagsAvailableForShift={tagsAvailableForShift}
            tagsCompulsoryForShift={compulsoryShiftTags}
            selectedShiftTags={tags}
            handleShiftUpdate={handleShiftUpdate}
            tagsAccountName={tagsAccountName}
          />
        )
      }
    );

    if (!confirmResult) {
      handleShiftUpdate({ tags: initialSelectedTags });
    }
  };

  const dayRoleOptions = useDayRoleOptions(props);
  const calculatedRoleOptions = useCalculatedRoleOptions(props);
  const uncalculatedRoleOptions = useUncalculatedRoleOptions(props);
  const venueOptions = useVenueOptions(props);
  const onRoleRateChange = useUpdateRoleRate(props);
  const onVenueChange = useUpdateVenue(props);
  const isAudienceValid = useValidateAudience(props);

  // Requester accounts cannot see the pay rate set by the Provider
  // Therefore the charge rate needs removing from the labels
  const { authed, roles } = useAuth();
  const isProviderAccount = authed && roles.includes(Role.PROVIDER);
  const providerOptions =
    selectedTab === "Uncalculated"
      ? uncalculatedRoleOptions
      : isDayRoles
      ? dayRoleOptions
      : calculatedRoleOptions;
  // Compensate for the weird "object instead of array" structure that is used
  // https://dev.internal.rota.com/docs/docs/developers/mobile-app/introduction-mobile-app#confusion-of-arrays-and-objects
  const requesterOptions = transform(
    providerOptions,
    function(result, value, key) {
      const newValue = value?.map(role => {
        const label = role?.label?.split(` - ${currencySymbol}`)?.[0];
        return { ...role, label };
      });
      result[key] = newValue;
      return true;
    },
    {}
  );

  const roleOptions = isProviderAccount ? providerOptions : requesterOptions;

  const selectedRoleName = roleOptions[sourceAccount?.accountName]?.find(
    ({ value }) => value === shift.roleRateId
  )?.label;

  const venueTags =
    venuesWithTags?.find(({ id }) => id === venueId)?.tags || [];
  const venueRoleTags =
    venuesWithTags
      ?.find(({ id }) => id === venueId)
      ?.roleTags?.find(({ roleName }) => selectedRoleName?.includes(roleName))
      ?.tags || [];

  const compulsoryShiftTags = uniqBy(
    [...venueTags, ...venueRoleTags, ...tagsCompulsoryForShift],
    "id"
  );

  const formattedCompulsoryShiftTags =
    compulsoryShiftTags?.map(({ id, name }) => ({
      id,
      name,
      isCompulsory: true
    })) || [];

  const saveCompulsoryTagsToShift = () => {
    handleShiftUpdate({
      tags: formattedCompulsoryShiftTags
    });
  };

  useEffect(() => {
    if (venueId || roleRateId) saveCompulsoryTagsToShift();
  }, [venueId, roleRateId]);

  useEffect(() => {
    setIsRoleSelected(true);
    onRoleRateChange(Object.values(roleOptions).flat()[0]?.value);
  }, []);

  const shouldShowTagsButton =
    roleRateId &&
    (tagsAvailableForShift?.length !== 0 || compulsoryShiftTags?.length !== 0);

  const lastUsedOrCurrentVenueId = venueId ?? lastUsedVenueId;

  if (lastUsedOrCurrentVenueId && !venueId) {
    handleShiftUpdate({
      venueId: lastUsedOrCurrentVenueId
    });
  }

  useEffect(() => {
    handleShiftUpdate({
      tags: formattedCompulsoryShiftTags
    });
  }, [formattedCompulsoryShiftTags.length]);

  return (
    <FormStepSegment
      isOpen={isOpen}
      stepNumber={stepNumber}
      title={() => (
        <Header>
          <HeaderTitle>
            {!!isAudienceValid && !isOpen
              ? getFormTitle(props)
              : "Staff Requirements"}
          </HeaderTitle>
          {!!hasVisited && !isOpen && (
            <EditButton onClick={goToSelf}>
              {isAudienceValid ? "Edit" : "Missing Info!"}
            </EditButton>
          )}
        </Header>
      )}
    >
      {() => (
        <div>
          <Fieldset>
            {selectedTab === "Calculated" && (
              <TabsWrapper>
                <TabsTitle>Choose a type of rate:</TabsTitle>
                <RotaTab
                  label="Hourly Rate"
                  onClick={() => {
                    setIsDayRoles(false);
                    setIsRoleSelected(false);
                  }}
                  size="sm"
                  isActive={!isDayRoles}
                />
                <RotaTab
                  label="Daily Rate"
                  onClick={() => {
                    setIsDayRoles(true);
                    setIsRoleSelected(false);
                  }}
                  size="sm"
                  isActive={isDayRoles}
                />
              </TabsWrapper>
            )}

            <TabsWrapper>
              <TabsTitle>Choose a type of role:</TabsTitle>
              {ROLE_TABS.map(tab => (
                <RotaTab
                  key={tab}
                  label={tab}
                  onClick={() => {
                    setSelectedTab(tab);
                    setIsRoleSelected(false);
                  }}
                  size="sm"
                  isActive={selectedTab === tab}
                />
              ))}
              <RotaButton
                variant="outlined"
                size="small"
                onClick={handleTagsModalOpen}
                disabled={!shouldShowTagsButton}
                style={{ marginLeft: "auto" }}
              >
                View Tags
              </RotaButton>
            </TabsWrapper>
            <RoleAndCountWrapper>
              <Select
                label="What type of staff do you need?"
                options={roleOptions}
                shouldFixLabelHeight
                placeholder="Role"
                value={roleRateId}
                onChange={roleRateId => {
                  setIsRoleSelected(true);
                  onRoleRateChange(roleRateId);
                }}
                isSmall
              />
              <TextInput
                label="How many do you need?"
                isSmall
                min={1}
                isError={parseInt(numberRequested || 0, 10) <= 0}
                value={numberRequested}
                onChangeValue={numberRequested => {
                  handleShiftUpdate({
                    numberRequested
                  });
                }}
                shouldFixLabelHeight
                type="number"
              />
            </RoleAndCountWrapper>
            <Select
              label="Where do you need them?"
              options={venueOptions}
              shouldFixLabelHeight
              placeholder="Venue"
              value={lastUsedOrCurrentVenueId}
              onChange={onVenueChange}
              isSmall
            />
            {isRoleSelected && <RateBreakdown roleRateId={roleRateId} />}
          </Fieldset>

          {!!isAudienceValid && (
            <Actions justify="center">
              <RotaButton onClick={goToNext}>
                {hasCompletedAllSteps ? "Done" : "Next"}
              </RotaButton>
            </Actions>
          )}
        </div>
      )}
    </FormStepSegment>
  );
};

export default connect(mapStateToProps)(FormAudience);

import React from "react";
import { useMutation } from "@apollo/client";
import { omit } from "lodash/fp";

import DynamicForm from "~/src/components/dynamic-form";

import { usePartnerOwners } from "~/src/containers/partners/graphql/use-partner-owners";
import { addOwnerOptions } from "~/src/containers/modals/utils.js";
import UPDATE_TARGET_ACCOUNT from "../graphql/update-target-account";

import schema from "./schema";
import validate from "./validate";

const FORM_ID = "edit-account-info";

const UserInfo = ({ account, isPartner }) => {
  const [updateTargetAccount] = useMutation(UPDATE_TARGET_ACCOUNT);
  const { partnerOwners } = usePartnerOwners();

  const updatedSchema = addOwnerOptions(partnerOwners, schema);

  const initialValues = {
    ...account,
    ownerId: account.ownerId !== null ? parseInt(account.ownerId) : null,
    subvenueSelection: account.subvenueSelection
  };

  return (
    <DynamicForm
      form={FORM_ID}
      schema={updatedSchema}
      validate={validate}
      onSubmit={updatedValues =>
        updateTargetAccount({
          variables: {
            ...updatedValues,
            ...(updatedValues?.accountName && {
              tags: updatedValues?.tags
                ? updatedValues?.tags.map(omit(["__typename"]))
                : []
            })
          },
          refetchQueries: ["getTargetAccount", "getProfile"]
        })
      }
      initialValues={initialValues}
      isPartner={isPartner}
      isScrollable={false}
    />
  );
};

export default UserInfo;

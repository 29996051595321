import { chunk } from "lodash/fp";
import React, { useState } from "react";
import { BOOKING_STATES, MEMBER_GRID_LABELS } from "~/src/consts";
import AppliedMember from "~/src/containers/requester-calendar/AppliedMember";
import { manageableShiftsCountQuery } from "~/src/containers/requester-calendar/manage-shifts-sidebar/manage-shifts/get-manageable-shifts.query";
import Member from "~/src/containers/requester-calendar/shift-modal/components/shift-details/components/member";
import {
  AppliedMemberWrapper,
  Column,
  Container,
  FadeOut,
  Header,
  Heading,
  Label,
  Number,
  Placeholder,
  Row,
  SeeMoreWrapper,
  StyledButton,
  StyledIcon,
  Wrapper
} from "./member-grid.styles";

const MemberGrid = ({ shift, bookings, onPrint, showMember, bookingState }) => {
  const [isOpen, setIsOpen] = useState(bookingState === BOOKING_STATES.APPLIED);

  const isActionableGrid =
    bookingState === BOOKING_STATES.APPLIED ||
    bookingState === BOOKING_STATES.WAITING_LIST;

  return (
    <Container>
      <Header>
        <Column shouldFlex>
          <Number>{bookings.length}</Number>
          <Heading>{MEMBER_GRID_LABELS[bookingState]}</Heading>
        </Column>
        {bookingState === BOOKING_STATES.ACCEPTED && (
          <Column isPrintButton>
            <StyledButton onClick={() => onPrint(bookings)}>Print</StyledButton>
          </Column>
        )}
      </Header>
      <Wrapper
        isOpen={isOpen && bookings.length > 1}
        isEmpty={!bookings.length}
      >
        {!bookings.length ? (
          <Placeholder>No staff found yet</Placeholder>
        ) : null}
        {bookingState === BOOKING_STATES.APPLIED && bookings.length > 0 && (
          <Heading isSubHeading>
            If you press 'Decline' the member won't be able to work on your
            shift anymore. Press 'Waiting list' to move them to the waiting list
            in case you change your mind.
          </Heading>
        )}

        {bookings.length
          ? chunk(5, bookings).map((bookings, i) => (
              <Row key={i} shouldFlex={isActionableGrid}>
                {bookings.map((booking, j) =>
                  isActionableGrid ? (
                    <AppliedMemberWrapper key={j}>
                      <AppliedMember
                        bookingState={booking.state}
                        member={booking.member}
                        bookingId={booking.id}
                        actionButtonsShouldHaveLabels
                        shouldActionWaitingList={
                          bookingState === BOOKING_STATES.APPLIED
                        }
                        queryToRefetch={{
                          query: manageableShiftsCountQuery,
                          variables: { shouldLoadShifts: true }
                        }}
                        numberOfShiftRequestedMembers={shift.numberRequested}
                        numberOfAcceptedMembers={
                          shift.bookings[BOOKING_STATES.ACCEPTED].length
                        }
                        shiftId={shift.id}
                      />
                    </AppliedMemberWrapper>
                  ) : (
                    <Member
                      key={j}
                      member={booking.member}
                      showMemberModal={() => showMember(booking.member.id)}
                      replacementBooking={
                        booking.replacementBookingId &&
                        booking.replacementBooking
                      }
                    />
                  )
                )}
              </Row>
            ))
          : null}
      </Wrapper>
      {bookings.length > 5 && (
        <SeeMoreWrapper onClick={() => setIsOpen(!isOpen)}>
          <StyledIcon isOpen={isOpen} />
          <Label>{isOpen ? "Close" : "See more"}</Label>
        </SeeMoreWrapper>
      )}
      {bookings.length > 5 && <FadeOut />}
    </Container>
  );
};

export default MemberGrid;

import cloneDeep from "lodash/fp/cloneDeep";
import set from "lodash/fp/set";
import flow from "lodash/fp/flow";
import getOr from "lodash/fp/getOr";
import { get, assign, first } from "lodash/fp";
import uniq from "lodash/fp/uniq";
import isEqual from "lodash/fp/isEqual";
import map from "lodash/fp/map";
import concat from "lodash/fp/concat";
import filter from "lodash/fp/filter";
import { ZonedDate } from "@teamrota/rota-common";

import { createReducer, createAction } from "~/src/utils";

const WARNING_FIELDS = [
  { field: "venueId", label: "venue" },
  { field: "uniformTemplateId", label: "Uniform" }
];

export const DEFAULT_STATE = {
  warningFields: [],
  formState: {
    shifts: [
      {
        id: null,
        name: "",
        numberRequested: "",
        startTime: new ZonedDate(),
        endTime: new ZonedDate(),
        roleRateId: null,
        venueId: null,
        subvenueId: null,
        briefing: null,
        identifier: null,
        uniformTemplateId: null,
        assignedMemberIds: [],
        requestedMemberIds: [],
        unassignedMemberIds: [],
        isUncalculatedRate: false,
        isRestricted: false,
        isRequestAll: false,
        isIncludeUnavailable: false,
        delayHoursAlgoShift: null,
        postedAt: null,
        algoSkew: null,
        isPartnerManaged: false,
        type: "",
        privates: {
          shouldShowErrors: false,
          roleRateAccount: {
            id: null,
            accountName: null
          }
        }
      }
    ],
    uniformChanges: [],
    name: "",
    internalId: "",
    isLinkedShifts: false,
    shiftOpenIndex: 0
  }
};

export const UPDATE_FORM_STATE = "shiftModal/UPDATE_FORM_STATE";
export const ON_MODAL_CLOSE = "shiftModal/ON_MODAL_CLOSE";
export const SET_FORM_STATE = "shiftModal/SET_FORM_STATE";
export const ON_UPDATE_UNIFORM = "shiftModal/ON_UPDATE_UNIFORM";

export const updateFormState = createAction(UPDATE_FORM_STATE);
export const onModalClose = createAction(ON_MODAL_CLOSE);
export const setFormState = createAction(SET_FORM_STATE);
export const onUpdateUniform = createAction(ON_UPDATE_UNIFORM);

const getShiftProp = (prop, payload) => get(`shifts[0].${prop}`, payload);

export default createReducer(
  {
    [SET_FORM_STATE]: (state, { payload }) => ({
      ...state,
      formState: { ...(payload || cloneDeep(DEFAULT_STATE.formState)) }
    }),
    [ON_UPDATE_UNIFORM]: (state, { payload }) => ({
      ...state,
      formState: {
        ...state.formState,
        uniformChanges: [
          ...getOr([], "formState.uniformChanges", state),
          payload
        ]
      }
    }),
    [UPDATE_FORM_STATE]: (state, action) =>
      flow(
        set(
          "formState.shifts[0]",
          assign(first(state.formState.shifts), first(action.payload.shifts))
        ),
        set(
          "warningFields",
          flow(concat, uniq)(
            state.warningFields,
            flow(
              filter(
                ({ field }) =>
                  getShiftProp(field, action.payload) &&
                  !isEqual(
                    getShiftProp(field, state.formState),
                    getShiftProp(field, action.payload)
                  )
              ),
              map(({ label }) => label)
            )(WARNING_FIELDS)
          )
        )
      )(state),

    [ON_MODAL_CLOSE]: () => cloneDeep(DEFAULT_STATE)
  },
  DEFAULT_STATE
);

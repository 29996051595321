import React, { Component } from "react";
import asyncConfirm from "~/src/utils/async-confirm";
import Icon from "~/src/components/icon";
import createUpdateGroup from "~/src/graphql/mutations/groups/create-update-group.decorator";
import deleteGroupMember from "~/src/graphql/mutations/groups/delete-group-member.decorator";
import deleteGroup from "~/src/graphql/mutations/groups/delete-group.decorator";
import getGroups from "~/src/graphql/queries/get-group/get-group.decorator";
import { Tab, Tabs } from "./custom-tabs/";
import {
  Content,
  IconWrapper,
  TabsContainer,
  Title,
  TitleIcon,
  TitleWrapper
} from "./panel-group.styles";
import DropZone from "./drop-zone";

@getGroups
@createUpdateGroup
@deleteGroupMember
@deleteGroup
export default class GroupPanel extends Component {
  constructor() {
    super();

    this.handleTabSwitch = this.handleTabSwitch.bind(this);
    this.handleTabPositionChange = this.handleTabPositionChange.bind(this);
    this.handleTabAdd = this.handleTabAdd.bind(this);
    this.handleTabDelete = this.handleTabDelete.bind(this);
    this.removeMemberFromGroup = this.removeMemberFromGroup.bind(this);
    this.handleMemberDrag = this.handleMemberDrag.bind(this);

    this.state = {
      isArrowToggled: false,
      isMemberBeingDragged: false,
      activeTab: 0
    };
  }

  async componentDidUpdate(prevProps) {
    // commented out the line below during the migration to Apollo Client 3.0
    // because it caused the infinity 'getGroups' query calls

    // await this.props.refetchGroups();

    if (prevProps.selectedConnectionId !== this.props.selectedConnectionId) {
      if (this.props.groups.length > 0) {
        this.setState({ activeTab: 0 });
        this.props.onChangeCurrentGroup(this.props.groups[0].id);
      } else {
        this.props.onChangeCurrentGroup(0);
      }
    } else if (
      this.props.groups.length !== 0 &&
      prevProps.groups.length !== this.props.groups.length &&
      prevProps.currentGroup === 0
    ) {
      this.props.onChangeCurrentGroup(this.props.groups[0].id);
    }
  }

  tabs = [];

  handlePanelVisibility = () => {
    this.setState({ isArrowToggled: !this.state.isArrowToggled });
  };

  handleTabSwitch(active) {
    this.setState({ activeTab: active });
    this.props.onChangeCurrentGroup(this.props.groups[active].id);
  }

  handleTabPositionChange = async (a, b) => {
    // swap the sortNumbers of the two transposed tabs
    const { groups, createUpdateGroup } = this.props;

    try {
      await createUpdateGroup({
        id: groups[a].id,
        sortNumber: groups[b].sortNumber,
        shouldRefetchQuery: true
      });

      await createUpdateGroup({
        id: groups[b].id,
        sortNumber: groups[a].sortNumber,
        shouldRefetchQuery: true
      });

      if (this.state.activeTab === a) {
        this.setState({ activeTab: b });
      } else if (this.state.activeTab === b) {
        this.setState({ activeTab: a });
      }
    } catch (e) {
      console.log(e);
    }
  };

  handleTabAdd = async () => {
    // if there are some tabs, look at the rightmost which will have
    // the highest sortNumber, our new tab will slot in afterwards
    let sortNumber = 1;
    let tabsCount = this.props.groups.length;
    if (tabsCount > 0) {
      sortNumber = this.props.groups[tabsCount - 1].sortNumber + 1;
    }

    await this.props.createUpdateGroup({
      name: "New Group",
      sourceAccountId: this.props.selectedConnectionId,
      sortNumber,
      shouldRefetchQuery: true
    });

    this.setState({
      activeTab: tabsCount
    });
  };

  handleTabRename = newTabName => {
    try {
      this.props.createUpdateGroup({
        id: this.props.currentGroup,
        name: newTabName,
        shouldRefetchQuery: true
      });
    } catch (e) {
      console.log("An error has occurred while trying to rename a  group:", e);
    }
  };

  handleTabDelete = async id => {
    try {
      if (
        await asyncConfirm(
          "Are you sure you want to delete the group and it's members?",
          { confirmButtonText: "Delete" }
        )
      ) {
        await this.props.deleteGroup(id);
        this.props.refetchGroups();
      }
    } catch (e) {
      console.log("An error has occurred while trying to delete a group:", e);
    } finally {
      if (this.state.activeTab >= this.tabs.length) {
        this.setState({
          activeTab: this.state.activeTab === 0 ? 0 : this.state.activeTab - 1
        });
      }

      this.props.onChangeCurrentGroup(
        this.props.groups[this.state.activeTab].id
      );
    }
  };

  handleMemberDrag = status => {
    this.setState({ isMemberBeingDragged: status });
  };

  removeMemberFromGroup = droppedMember => {
    try {
      this.props.deleteGroupMember(parseInt(droppedMember.groupMemberId, 10));
    } catch (e) {
      console.log(
        "An error has occurred while trying to remove a member from a group:",
        e
      );
    }
  };

  render() {
    return (
      <div>
        <TitleWrapper>
          <IconWrapper onClick={this.handlePanelVisibility}>
            <TitleIcon
              isArrowToggled={this.state.isArrowToggled}
              name={Icon.names.ARROW_DOWN}
              size={Icon.sizes.SMALL}
              color="#8c8c8c"
            />
          </IconWrapper>
          <Title>Groups</Title>
        </TitleWrapper>
        <Content isOpen={this.state.isArrowToggled}>
          <TabsContainer isVisible={this.state.isArrowToggled}>
            <Tabs
              color="#8c8c8c"
              active={this.state.activeTab}
              onTabSwitch={el => {
                this.handleTabSwitch(el);
              }}
              onTabPositionChange={this.handleTabPositionChange}
              onTabAdd={this.handleTabAdd}
              onTabDelete={this.handleTabDelete}
              onTabRename={this.handleTabRename}
              currentGroup={this.props.currentGroup}
              draggable
              showAdd
            >
              {this.props.groups.map((tab, index) => (
                <Tab key={index} id={tab.id} title={tab.name} showClose />
              ))}
            </Tabs>
          </TabsContainer>

          <DropZone
            onDropMember={this.props.onDropMember}
            groups={this.props.groups}
            activeTab={this.state.activeTab}
            isArrowToggled={this.state.isArrowToggled}
            onShowMemberDetails={this.props.onShowMemberDetails}
            handleMemberDrag={this.handleMemberDrag}
            removeMemberFromGroup={this.removeMemberFromGroup}
            isMemberBeingDragged={this.state.isMemberBeingDragged}
          />
        </Content>
      </div>
    );
  }
}

import { COLORS } from "~/src/styles/config.style";
import { withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import styled from "styled-components";
import { MAIN_COLUMN_WIDTH } from "../utils";

export const StyledTooltip = styled.div`
  position: relative;
`;

export const StyledFiltersContainer = styled.div`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  width: 300px;
  max-height: calc(100vh - 110px);
  overflow-y: auto;
  position: absolute;
  top: 100%;
  left: -100px;
  z-index: 1000;
  background-color: ${({ theme }) => theme.white};
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 0 50px -25px rgba(0, 0, 0, 0.4);
`;

export const useStyles = () => ({
  root: {
    width: "100%",
    maxWidth: MAIN_COLUMN_WIDTH,
    height: "32px",
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "14px",
    position: "relative",
    background: "red",

    "&&:hover": {
      background: "white"
    },
    "&&:focus": {
      background: "white"
    }
  },

  filterContainer: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },

  input: {
    fontFamily: "Gotham A, Gotham B, Open Sans, sans-serif",
    fontSize: 12
  },
  underline: {
    "&&&:before": {
      borderBottom: "none"
    },
    "&&:after": {
      borderBottom: "none"
    }
  }
});

export const SearchInputWrapper = withStyles({
  root: {
    width: "100%",
    "& .Mui-focused": {
      background: "white"
    },
    "& label.Mui-focused": {
      color: `${COLORS.MAIN_ORANGE}`
    },
    "& .MuiOutlinedInput-adornedStart": {
      padding: "0",
      margin: 0
    },
    "& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) ": {
      margin: 0
    },
    "& .MuiInputAdornment-positionStart": {
      padding: "3px 8px 3px 10px",
      margin: 0,
      color: "#BCBCBC"
    },
    "& .MuiInputAdornment-positionEnd": {
      padding: "0px"
    },
    "& .MuiFilledInput-adornedEnd": {
      padding: 0
    },
    "& .MuiFilledInput-adornedStart": {
      padding: 0
    },
    "& .MuiIconButton-root": {
      border: `solid 1px #BABABA`,
      padding: "11px 0 11px 0",
      color: "#4A4A4A",
      borderRadius: "14px",
      height: "32px",
      "&:hover": {
        border: `solid 1px ${COLORS.MAIN_ORANGE}`
      }
    },
    "& .MuiIconButton-label": {
      width: "50px",
      margin: "2px 0 2px 0",
      padding: "0px",
      zIndex: 8
    },
    "& .MuiFilledInput-input": {
      padding: "4px",
      fontSize: 12
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: `none`
    },
    "& .MuiOutlinedInput-root": {}
  }
})(TextField);

export const styles = {
  divider: {
    opacity: 0.1,
    marginTop: 10,
    marginBottom: 15
  },
  margin: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "32px",
    background: "#F5F5F5",
    borderRadius: "14px",
    "&:hover": {
      border: `solid 1px ${COLORS.MAIN_ORANGE}`
    }
  }
};

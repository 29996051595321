import styled from "styled-components";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";

export const ItemsWrapper = styled.div`
  min-height: 80px;
  color: ${COLORS.COOL_GREY};
`;

export const List = styled.ul`
  color: ${({ theme }) => theme.text.primary};
  list-style-position: inside;
  padding: 0;
`;

export const Bullet = styled.li`
  list-style-type: circle;
  margin-bottom: ${STYLE_CONSTS.UNIT_2};
`;
export const UniformImageWrapper = styled.div`
  width: 120px;
  justify-content: center;
`;

export const UniformImage = styled.img`
  border-radius: 20px;
  width: inherit;
  height: inherit;
`;

export const VisualWrapper = styled.div`
  display: flex;
  padding: 10px 0;
`;

export const UniformDescription = styled.div``;

import styled from "styled-components";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";

const Form = styled.form`
  height: 100%;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 120px);
  overflow-x: hidden;
  overflow-y: auto;
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  width: 100%;
  font-size: ${STYLE_CONSTS.FONT_MEDIUM};
`;

const Label = styled.label`
  font-size: 14px;
  line-height: 17px;
  color: ${COLORS.COOL_GREY};
  padding-bottom: 5px;
`;

const Input = styled.input`
  width: 100%;
  font-size: ${STYLE_CONSTS.FONT_MEDIUM} !important;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 5px;
  background-color: ${({ theme }) => theme.white};
  height: 38px;
  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.primary.main};
  }
  &:disabled {
    background-color: #f0f0f0;
  }
  &::-webkit-input-placeholder {
    color: ${COLORS.CLOUDY_BLUE};
    background-color: ${({ theme }) => theme.white};
  }
`;

const Select = styled.select`
  width: 100%;
  height: 38px;
  outline: none;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 5px;
  background-color: ${({ theme }) => theme.white};
  &:disabled {
    appearance: none;
    background-color: #f0f0f0;
  }
  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.primary.main};
  }
`;

const Button = styled.button`
  height: 36px;
  padding: 0 36px;
  border-radius: 36px;
  border: none;
  font-weight: 500;
  font-size: 12px;
  color: ${({ theme }) => theme.white};
  background: ${({ disabled, theme }) =>
    disabled ? COLORS.PALE_GREY : theme.primary.main};
  cursor: pointer;
  margin-top: 10px;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  padding: ${({ noPadding }) => (noPadding ? "0" : "0 10px")};
`;

const Content = styled.div`
  width: 100%;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
  color: #939ba7;
`;

const StyledErrorMessage = styled.p`
  color: ${COLORS.RED};
  font-size: 12px;
  margin-top: 4px;
`;

const styles = {
  Checkbox: {
    marginRight: "5px",
    width: "20px",
    height: "20px",
    verticalAlign: "middle"
  }
};

const DateTime = styled.div`
  width: 50px;
  flex: none;

  input {
    text-align: center;
    border-radius: 0;
    border-bottom: 1px solid transparent;
    padding: 3px;
  }

  input:hover,
  input:focus {
    border-bottom: 1px solid ${({ theme }) => theme.primary.main};
  }
`;

export {
  Form,
  Section,
  Field,
  Input,
  Select,
  Row,
  Label,
  Button,
  Content,
  StyledErrorMessage,
  DateTime,
  styles as default
};

export const StyledWrapRole = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  border-left: 1px solid ${({ theme }) => theme.neutral.lighter};
  border-right: 1px solid ${({ theme }) => theme.neutral.lighter};
  padding: 0 8px;
`;

export const StyledSpan = styled.span`
  white-space: nowrap;
  padding-right: 8px;
  color: ${({ theme }) => theme.neutral.main};
  font-size: 14px;
`;

export const StyledWrapTag = styled.div`
  display: flex;
  overflow: ${({ overflow }) => overflow};
  flex-wrap: ${({ wrap }) => wrap};
  align-items: center;
  margin: ${({ margin }) => margin};
`;

export const FormContainer = styled.div`
  margin-right: 24px;
`;

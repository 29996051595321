import React from "react";
import InputWrapper from "../input-wrapper";

import { Input } from "./sort-code-input.styles";

export default function SortCodeInput(props) {
  return (
    <InputWrapper {...props}>
      <Input
        options={{
          delimiter: "-",
          blocks: [2, 2, 2],
          numericOnly: true
        }}
        value={props.input.value || ""}
        placeholder={props.placeholder}
        disabled={props.readOnly}
        onChange={event => props.input.onChange(event.target.rawValue)}
      />
    </InputWrapper>
  );
}

import React, { useState } from "react";
import {
  RotaButton,
  RotaButtonIcon,
  RotaSnackBar,
  RotaDivider,
  RotaTab,
  icon
} from "@teamrota/rota-design";
import { useMutation } from "@apollo/client";

import { FloatingTable, TableBody } from "~/src/components/floating-table";
import {
  StyledRole,
  StyledSpanWrap,
  StyledArrowDown
} from "~/src/components/new-pay-rate/style";
import TablePlaceholder from "~/src/components/table-placeholder";
import NoResults from "~/src/components/no-results";
import AddEditRoleModal from "./components/add-edit-role-modal";
import Role from "~/src/components/new-pay-rate/role";
import DeleteModal from "~/src/components/new-pay-rate/delete-modal";
import { useRoles } from "./graphql/use-roles";
import { DELETE_ROLE } from "~/src/containers/roles/graphql/delete-role";
import { errorModal } from "~/src/utils/errors";
import ImportModal from "./components/import-modal";
import UncalculatedRoleDetails from "./components/uncalculated-role-details";
import { ROLE_TABS } from "~/src/consts";

import {
  PageHeader,
  Header,
  HeaderContentWrapper,
  Subheader,
  Title,
  TabsWrapper,
  RolesCount,
  Subtitle,
  ButtonsWrapper
} from "./styles";

const { ArrowTop } = icon;

const Roles = () => {
  const [isSuccessSnackBarOpen, setIsSuccessSnackBarOpen] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteRoleId, setDeleteRoleId] = useState(null);
  const [deleteRoleName, setDeleteRoleName] = useState("");
  const [{ isOpen, role }, setModal] = useState({ isOpen: false, role: null });
  const [isImportRolesModalOpen, setIsImportRolesModalOpen] = useState(false);
  const {
    accountId,
    roles,
    calculatedRoles,
    uncalculatedRoles,
    loading,
    refetch
  } = useRoles();
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [selectedTab, setSelectedTab] = useState(ROLE_TABS[0]);

  const isNoResults = roles?.length === 0 && !loading;
  const isShowTabs = !!(calculatedRoles.length && uncalculatedRoles.length);
  const isCalculatedTabSeleced = selectedTab === "Calculated";
  const rolesToDisplay = isCalculatedTabSeleced
    ? calculatedRoles
    : uncalculatedRoles;

  const [deleteRole] = useMutation(DELETE_ROLE, {
    refetchQueries: ["getRoles"]
  });

  const handleDeleteRole = async id => {
    try {
      await deleteRole({
        variables: { id }
      });
      setDeleteRoleId(null);
      setDeleteRoleName("");
      setIsDeleteModalVisible(false);
      setIsSuccessSnackBarOpen(true);
    } catch (err) {
      setIsDeleteModalVisible(false);
      errorModal(err);
    }
  };

  const onImportRoles = () => {
    setIsImportRolesModalOpen(true);
  };

  const onCancel = () => {
    setDeleteRoleId(null);
    setDeleteRoleName("");
    setIsDeleteModalVisible(false);
  };

  return (
    <div>
      {isOpen && (
        <AddEditRoleModal
          role={role}
          roles={roles}
          onClose={() => {
            setModal(state => ({ ...state, isOpen: false, role: null }));
          }}
          isOpen={isOpen}
          onDeleteRole={handleDeleteRole}
        />
      )}
      {isImportRolesModalOpen && (
        <ImportModal
          id={accountId}
          onClose={() => setIsImportRolesModalOpen(false)}
          initialSelectedTab={selectedTab}
          refetch={refetch}
        />
      )}
      {isDeleteModalVisible && (
        <DeleteModal
          onCancel={onCancel}
          deleteRoleName={deleteRoleName}
          handleDeleteRole={handleDeleteRole}
          deleteRoleId={deleteRoleId}
        />
      )}

      <PageHeader>
        <Header>
          <Title>Roles</Title>
          <HeaderContentWrapper>
            <TabsWrapper>
              {isShowTabs &&
                ROLE_TABS.map(tab => (
                  <RotaTab
                    key={tab}
                    label={tab}
                    isActive={tab === selectedTab}
                    onClick={() => setSelectedTab(tab)}
                  />
                ))}
            </TabsWrapper>
            <ButtonsWrapper>
              <RotaButton
                variant={isCalculatedTabSeleced ? "outlined" : "contained"}
                onClick={() => {
                  onImportRoles();
                }}
              >
                Import roles
              </RotaButton>
              {isCalculatedTabSeleced && (
                <RotaButton
                  onClick={() => {
                    setModal(state => ({ ...state, isOpen: true }));
                  }}
                  style={{ marginLeft: "22px" }}
                >
                  Add a new role
                </RotaButton>
              )}
            </ButtonsWrapper>
          </HeaderContentWrapper>
        </Header>
        <RotaDivider margin={0} />
        <Subheader>
          <RolesCount>
            <span>{rolesToDisplay.length}</span>
            {rolesToDisplay.length === 1 ? "role found" : "roles found"}
          </RolesCount>
          <Subtitle>
            {isCalculatedTabSeleced
              ? "Calculated roles use Pay Rates information to show the value of a shift. They can be added/edited here or in the Import role screen."
              : "Uncalculated roles do not show shift value as pay is managed via the Payroll download. These roles are added/edited via the Import roles screen only."}
          </Subtitle>
        </Subheader>
      </PageHeader>
      <FloatingTable>
        <TableBody>
          {isNoResults && <NoResults />}
          {!isNoResults && (
            <div style={{ padding: "32px" }}>
              {loading && (
                <TablePlaceholder
                  columnWidths={[3 / 12, 3 / 12, 3 / 12, 3 / 12]}
                />
              )}
              {rolesToDisplay.map(role => (
                <div key={role.id}>
                  <StyledRole>
                    <StyledSpanWrap>
                      <p>{role.name}</p>
                      {selectedRoleId === role.id ? (
                        <span
                          onClick={() => {
                            setSelectedRoleId("");
                          }}
                        >
                          <ArrowTop
                            styles={{
                              cursor: "pointer",
                              fontWeight: "400",
                              marginLeft: "5px"
                            }}
                          />
                        </span>
                      ) : (
                        <StyledArrowDown
                          onClick={() => {
                            setSelectedRoleId(role.id);
                          }}
                        />
                      )}
                    </StyledSpanWrap>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {isCalculatedTabSeleced && (
                        <RotaButtonIcon
                          icon="edit"
                          onClick={() => {
                            setModal(state => ({
                              ...state,
                              isOpen: true,
                              role
                            }));
                          }}
                          style={{ marginRight: "10px" }}
                        />
                      )}
                      <RotaButtonIcon
                        icon="delete"
                        onClick={() => {
                          setIsDeleteModalVisible(true);
                          setDeleteRoleId(role?.id);
                          setDeleteRoleName(role?.name);
                        }}
                      />
                    </div>
                  </StyledRole>
                  {selectedRoleId === role.id &&
                    (selectedTab === "Calculated" ? (
                      <Role role={role} />
                    ) : (
                      <UncalculatedRoleDetails roleId={role.id} />
                    ))}
                </div>
              ))}
            </div>
          )}
        </TableBody>
      </FloatingTable>
      <RotaSnackBar
        snackOpen={isSuccessSnackBarOpen}
        onClose={() => setIsSuccessSnackBarOpen(false)}
        message="Deleted Successfully"
        severity="success"
      />
    </div>
  );
};

export default Roles;

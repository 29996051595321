import { gql } from "@apollo/client";

const GET_ACCOUNT_MEMBER_AVAILABILITY = gql`
  query getAccountMemberAvailability {
    user {
      id
      account {
        id
        isMemberAvailabilityEnabled
        memberAvailabilityDefaultStartTime
        memberAvailabilityDefaultEndTime
      }
    }
  }
`;
export default GET_ACCOUNT_MEMBER_AVAILABILITY;

import React from "react";
import ActionDropdown from "~/src/components/action-dropdown";
import { DropdownContent } from "./filters-dropdown.styles";

const FiltersDropdown = ({ children }) => {
  return (
    <ActionDropdown
      value="Advanced Search"
      isAlignedLeft
      isIconPrimary
      isFontPrimary
    >
      <DropdownContent>{children}</DropdownContent>
    </ActionDropdown>
  );
};

export default FiltersDropdown;

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import set from "lodash/fp/set";
import upsertArray from "~/src/utils/upsert-array";

const createOrUpdateBriefingTemplate = graphql(
  gql`
    mutation Mutation($id: ID, $identifier: String!, $content: String!) {
      createOrUpdateBriefing(
        id: $id
        identifier: $identifier
        content: $content
      ) {
        id
        identifier
        content
        createdAt
      }
    }
  `,
  {
    name: "createOrUpdateBriefingTemplate",
    props: ownProps => ({
      createOrUpdateBriefingTemplate: (id, identifier, content) =>
        ownProps.createOrUpdateBriefingTemplate({
          variables: {
            id,
            identifier,
            content
          },
          updateQueries: {
            getProfile: (prevData, { mutationResult }) => {
              const prevBriefings = prevData.user.account.briefingTemplates;

              const updatedBriefings = upsertArray(
                briefing =>
                  briefing.id === mutationResult.data.createOrUpdateBriefing.id,
                mutationResult.data.createOrUpdateBriefing,
                prevBriefings
              );

              return set(
                "user.account.briefingTemplates",
                updatedBriefings,
                prevData
              );
            }
          }
        })
    })
  }
);

export default createOrUpdateBriefingTemplate;

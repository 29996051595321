import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

import { addAuthVars } from "@teamrota/authlib";

export const CREATE_PAYROLL_RATE = addAuthVars(gql`
  mutation createPayrollRate($name: String!, $config: [JSONObject]!) {
    createPayrollRate(name: $name, config: $config) {
      name
      config
    }
  }
`);

export const useCreatePayrollRate = () => {
  const [create, { loading, error }] = useMutation(CREATE_PAYROLL_RATE, {
    onError: error => {
      throw error;
    }
  });
  const createPayrollRate = async ({
    name,
    config
  }: {
    name: string;
    config: [JSON];
  }) => {
    await create({
      variables: {
        name,
        config
      },
      refetchQueries: ["payrollRates"]
    });
  };

  return { createPayrollRate, loading, error };
};

import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { Role } from "@teamrota/authlib";

import useAuth from "~/src/auth/hooks/use-auth";
import { MEMBER_TYPES } from "~/src/consts";

import styles, {
  Field,
  Label,
  Input,
  Select,
  Row,
  StyledErrorMessage
} from "./styles";

const MemberTypeDropdown = ({
  member,
  memberTypesAvailableForAccount,
  initialValues,
  register,
  errors,
  control,
  inEditMode,
  children
}) => {
  const auth = useAuth();

  const initialMemberType =
    initialValues?.memberType?.replace(/_/g, " ") ||
    memberTypesAvailableForAccount?.defaultMemberType?.replace(/_/g, " ");

  const [selectedValue, setSelectedValue] = useState(initialMemberType);

  const existingMemberType = member?.memberType?.replace(/_/g, " ");

  const accountsMemberTypes = [
    ...(memberTypesAvailableForAccount?.isCasualMemberTypeAvailable
      ? ["CASUAL"]
      : []),
    ...(memberTypesAvailableForAccount?.isAgencyMemberTypeAvailable
      ? ["AGENCY"]
      : []),
    ...(memberTypesAvailableForAccount?.isPermanentMemberTypeAvailable
      ? ["PERMANENT"]
      : []),
    ...(memberTypesAvailableForAccount?.isContractorLimitedCompanyMemberTypeAvailable
      ? ["CONTRACTOR_LIMITED_COMPANY"]
      : []),
    ...(memberTypesAvailableForAccount?.isContractorSelfEmployedMemberTypeAvailable
      ? ["CONTRACTOR_SELF_EMPLOYED"]
      : []),
    ...(memberTypesAvailableForAccount?.isContractorUmbrellaCompanyMemberTypeAvailable
      ? ["CONTRACTOR_UMBRELLA_COMPANY"]
      : [])
  ];

  const memberTypeFilteredOptions = MEMBER_TYPES.filter(({ value }) =>
    accountsMemberTypes?.includes(value.toUpperCase())
  );

  const checkWorkerType = () =>
    [
      "PERMANENT",
      "CONTRACTOR LIMITED COMPANY",
      "CONTRACTOR SELF EMPLOYED",
      "CONTRACTOR UMBRELLA COMPANY"
    ].includes(selectedValue);

  const isUnavailableMemberType = existingMemberType
    ? !accountsMemberTypes?.includes(existingMemberType)
    : false;

  return (
    <div>
      <Row>
        {memberTypeFilteredOptions?.length > 0 ? (
          <Field>
            <Label>Member type</Label>
            {isUnavailableMemberType && (
              <Label>
                NOTE: This member is currently Member Type '{existingMemberType}
                ', however that Member Type is no longer available on this
                account. Edit Member Type to be one of the available options, or
                contact your account administrator.
              </Label>
            )}
            <Controller
              control={control}
              defaultValue={initialMemberType}
              name="memberType"
              render={({ field }) => {
                return (
                  <Select
                    placeholder={initialMemberType}
                    disabled={!inEditMode}
                    defaultValue={initialMemberType}
                    onChange={e => {
                      field.onChange(e?.target?.value);
                      setSelectedValue(e?.target?.value);
                    }}
                  >
                    {/* If existing type no longer available, show it by default, but once changed it will disappear from the options list */}
                    {isUnavailableMemberType && (
                      <option
                        key={existingMemberType}
                        value={existingMemberType}
                      >
                        {existingMemberType}
                      </option>
                    )}
                    {memberTypeFilteredOptions?.map(({ label }) => (
                      <option key={label} value={label}>
                        {label}
                      </option>
                    ))}
                  </Select>
                );
              }}
            />
            <StyledErrorMessage>{errors.homeVenue?.message}</StyledErrorMessage>
          </Field>
        ) : (
          <Field>No member types available </Field>
        )}
        {children}
      </Row>
      {checkWorkerType() && (
        <Row>
          <Field>
            <Label>Annual Salary</Label>
            <Input
              type="number"
              {...register("annualSalary")}
              placeholder={initialValues?.annualSalary}
              disabled={!inEditMode || !auth.hasRole(Role.MEMBERS_BANK_EDIT)}
            />
            <StyledErrorMessage>
              {errors.annualSalary?.message}
            </StyledErrorMessage>
          </Field>
          <Field>
            <Label>Weekly Contracted Hours</Label>
            <Input
              type="number"
              {...register("weeklyContractedHours")}
              placeholder={initialValues?.weeklyContractedHours}
              disabled={!inEditMode || !auth.hasRole(Role.MEMBERS_BANK_EDIT)}
            />
            <StyledErrorMessage>
              {errors.weeklyContractedHours?.message}
            </StyledErrorMessage>
          </Field>
        </Row>
      )}
      {selectedValue === "LIMITED" && (
        <Row>
          <Field>
            <Label>
              <input
                type="checkbox"
                style={styles.checkbox}
                {...register("isInsideIR35")}
                disabled={!inEditMode}
              />{" "}
              Is inside IR35?
            </Label>
            <StyledErrorMessage>
              {errors.isInsideIR35?.message}
            </StyledErrorMessage>
          </Field>
        </Row>
      )}
    </div>
  );
};

export default MemberTypeDropdown;

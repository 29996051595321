import React, { Component } from "react";
import { COLORS, STYLE_CONSTS } from "~/src/styles/config.style";
import { Input } from "../text-input/text-input.styles";
import { InputWrapper, InputInnerWrapper, Label } from "../input-styles";

export default class StatefulInput extends Component {
  state = {
    value: this.props.initialValue,
    color: COLORS.CLOUDY_BLUE
  };

  render() {
    const { props, state } = this;
    return (
      <InputWrapper>
        <Label isVisible={Boolean(props.label)}>{props.label}</Label>
        <InputInnerWrapper>
          {props.inlineLabel && <span>{props.inlineLabel}</span>}
          <Input
            fontSizeOveride={STYLE_CONSTS.FONT_SMALL}
            iconLeft={props.iconLeft}
            value={state.value}
            type="text"
            onFocus={() => this.setState({ color: COLORS.MAIN_ORANGE })}
            fontColor={state.color}
            onBlur={props.onBlur}
            isInline={props.isInline}
            isSmall={props.isSmall}
            onChange={e => this.setState({ value: e.target.value })}
            placeholder={props.placeholder}
            isBorderless={props.isBorderless}
          />
        </InputInnerWrapper>
      </InputWrapper>
    );
  }
}

import React from "react";
import { MEMBER_STATES } from "~/src/consts";
import asyncConfirm from "~/src/utils/async-confirm";
import ReactTooltip from "react-tooltip";

import {
  Wrapper,
  State,
  Text,
  ModalTitle,
  ModalText,
  Action
} from "./member-state-input.styles";

const states = [
  MEMBER_STATES.ACTIVE,
  MEMBER_STATES.PENDING,
  MEMBER_STATES.LOCKED,
  MEMBER_STATES.GOOD_LEAVER,
  MEMBER_STATES.REMOVED
];

export const tooltipText = {
  [MEMBER_STATES.ACTIVE]: "Active",
  [MEMBER_STATES.REMOVED]: "Removed",
  [MEMBER_STATES.LOCKED]: "Locked",
  [MEMBER_STATES.GOOD_LEAVER]: "Good Leaver",
  [MEMBER_STATES.PENDING]: "Pending"
};

const leavingStates = [
  MEMBER_STATES.LOCKED,
  MEMBER_STATES.REMOVED,
  MEMBER_STATES.GOOD_LEAVER
];

const keywordMap = {
  [MEMBER_STATES.LOCKED]: "Lock",
  [MEMBER_STATES.GOOD_LEAVER]: "Remove",
  [MEMBER_STATES.REMOVED]: "Remove"
};

const ModalMessage = props => {
  const actionText =
    props.newState === MEMBER_STATES.LOCKED ? "lock" : "remove";
  const action = <Action newState={props.newState}>{actionText}</Action>;
  return (
    <div>
      <ModalTitle>Do you want to {action} this member?</ModalTitle>
      <ModalText>
        This member is accepted to {props.bookingsCount || 0} upcoming shifts.
      </ModalText>
      <ModalText>This will cancel all accepted shifts.</ModalText>
    </div>
  );
};

export default function MemberStateInput(props) {
  const handleChange = async newValue => {
    if (!props.readOnly && newValue !== props.input.value) {
      if (leavingStates.includes(newValue)) {
        const message = (
          <ModalMessage
            bookingsCount={props.upcomingBookingsCount}
            newState={newValue}
          />
        );
        if (
          await asyncConfirm(message, {
            confirmButtonText: keywordMap[newValue]
          })
        ) {
          props.input.onChange(newValue);
        }
      }
      props.input.onChange(newValue);
    }
  };

  return (
    <Wrapper>
      {states.map((state, index) => (
        <span key={index}>
          <ReactTooltip id={state} effect="solid">
            {tooltipText[state]}
          </ReactTooltip>
          <State
            readOnly={props.readOnly}
            state={state}
            data-tip
            data-for={state}
            isActive={props.input.value === state}
            onClick={() => handleChange(state)}
          />
        </span>
      ))}
      <Text state={props.input.value}>{tooltipText[props.input.value]}</Text>
    </Wrapper>
  );
}

import React from "react";
import { useMutation } from "@apollo/client";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { RotaInput, RotaButton, RotaDropdown } from "@teamrota/rota-design";

import { PASSWORD_REGEX, PHONE_NUMBER_REGEX } from "~/src/consts";

import {
  StyledMainDetail,
  StyledMainHeaderWrapper,
  StyledMainHeading
} from "../styles";
import { Row } from "./styles";
import adminClient from "../../graphql/admin-client";
import { CREATE_ACCOUNT_AND_USER } from "../../graphql/create-account-and-user";
import { parseError } from "~/src/utils/errors";

const schema = yup.object().shape({
  accountName: yup.string().required("this field is required"),
  accountType: yup.string().required("this field is required"),
  partnerAccountId: yup
    .string()
    .matches(/^[0-9]+$/, "must be a number")
    .nullable()
    .transform((_, val) => val || null),
  firstName: yup.string().required("this field is required"),
  lastName: yup.string().required("this field is required"),
  email: yup
    .string()
    .required("this field is required")
    .email("must be a valid email"),
  phoneNumber: yup
    .string()
    .required("this field is required")
    .matches(
      PHONE_NUMBER_REGEX,
      "must be a valid phone number e.g. +12345678900"
    ),
  password: yup
    .string()
    .required("this field is required")
    .matches(
      PASSWORD_REGEX,
      "minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character (@$!%*?&)"
    ),
  confirmPassword: yup
    .string()
    .required("this field is required")
    .oneOf([yup.ref("password"), null], "passwords must match")
});

const accountTypeOptions = [
  {
    value: "PROVIDER",
    label: "Provider"
  },
  {
    value: "REQUESTER",
    label: "Requester"
  },
  {
    value: "SELF_PROVIDER",
    label: "Self-Provider"
  }
];

const defaultValues = {
  accountName: "",
  accountType: "",
  partnerAccountId: null,
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  password: "",
  confirmPassword: ""
};

const CreateAccounts = ({ setSnack }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { ...defaultValues }
  });

  const watchAccountType = watch("accountType");

  const [createAccountAndUser] = useMutation(CREATE_ACCOUNT_AND_USER, {
    client: adminClient
  });

  const onSubmit = async values => {
    delete values.confirmPassword;
    try {
      await createAccountAndUser({ variables: values });
      reset({
        ...defaultValues
      });
      setSnack({ message: "Success!", severity: "success" });
    } catch (e) {
      setSnack({ message: parseError(e), severity: "error" });
    }
  };

  return (
    <>
      <StyledMainHeaderWrapper>
        <StyledMainHeading>Create Account</StyledMainHeading>
        <StyledMainDetail>Create new account</StyledMainDetail>
      </StyledMainHeaderWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <RotaInput
            isLabelTop
            label="Account Name"
            {...register("accountName")}
            isError={errors.accountName?.message}
            errorMessage={errors.accountName?.message}
          />
          <Controller
            control={control}
            name="accountType"
            render={({ field: { onChange, value } }) => (
              <RotaDropdown
                isLabelTop
                label="Account Type"
                options={accountTypeOptions}
                value={value}
                onChange={onChange}
                isError={errors.accountType?.message}
                errorMessage={errors.accountType?.message}
              />
            )}
          />
          {watchAccountType === "REQUESTER" && (
            <RotaInput
              isLabelTop
              label="Partner Account ID"
              {...register("partnerAccountId")}
              isError={errors.partnerAccountId?.message}
              errorMessage={errors.partnerAccountId?.message}
            />
          )}
        </Row>
        <Row>
          <RotaInput
            isLabelTop
            label="First Name"
            {...register("firstName")}
            isError={errors.firstName?.message}
            errorMessage={errors.firstName?.message}
          />
          <RotaInput
            isLabelTop
            label="Last Name"
            {...register("lastName")}
            isError={errors.lastName?.message}
            errorMessage={errors.lastName?.message}
          />
        </Row>
        <Row>
          <RotaInput
            isLabelTop
            label="Email Address"
            {...register("email")}
            isError={errors.email?.message}
            errorMessage={errors.email?.message}
          />
          <RotaInput
            isLabelTop
            label="Phone"
            {...register("phoneNumber")}
            isError={errors.phoneNumber?.message}
            errorMessage={errors.phoneNumber?.message}
          />
        </Row>
        <Row>
          <RotaInput
            type="password"
            isLabelTop
            label="Password"
            {...register("password")}
            isError={errors.password?.message}
            errorMessage={errors.password?.message}
          />
          <RotaInput
            type="password"
            isLabelTop
            label="Confirm password"
            {...register("confirmPassword")}
            isError={errors.confirmPassword?.message}
            errorMessage={errors.confirmPassword?.message}
          />
        </Row>
        <Row>
          <RotaButton type="submit">Create Account</RotaButton>
        </Row>
      </form>
    </>
  );
};

export default CreateAccounts;

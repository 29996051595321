import React, { Component } from "react";
import noop from "lodash/noop";

const initialIndex = 0;

class FormStepList extends Component {
  state = {
    openIndex: initialIndex,
    visitedIndexes: { 0: [initialIndex] }
  };

  goToNext = () => {
    const { segments, openStateIndex } = this.props;

    const { visitedIndexes, openIndex } = this.state;
    const currentIndex = visitedIndexes[openStateIndex];
    const nextUnvisitedStepIndex = segments.findIndex(
      (_, i) => currentIndex[i] === noop()
    );

    const latestVisitedStepIndex = currentIndex[currentIndex.length - 1];

    const newIndex =
      latestVisitedStepIndex !== openIndex
        ? latestVisitedStepIndex
        : nextUnvisitedStepIndex;

    this.setState({
      openIndex: newIndex,
      visitedIndexes: {
        ...visitedIndexes,
        [openStateIndex]: Array.from(new Set([...currentIndex, newIndex]))
      }
    });
  };

  goToSelf = openIndex => () => {
    this.setState({
      openIndex
    });
  };

  goToStart = async () => {
    const { openStateIndex } = this.props;
    const { visitedIndexes } = this.state;

    if (visitedIndexes[openStateIndex]) {
      this.goToSelf(initialIndex)();
    } else {
      await this.setState({
        openIndex: initialIndex,
        visitedIndexes: {
          ...visitedIndexes,
          [openStateIndex]: [initialIndex]
        }
      });
    }
  };

  render() {
    const { props, state } = this;
    const openStateIndex = props.openStateIndex || 0;

    if (!state.visitedIndexes[openStateIndex]) return null;

    const hasCompletedAllSteps =
      props.segments.length === state.visitedIndexes[openStateIndex].length;

    return (
      <div>
        {props.segments.map((Segment, index) => {
          return (
            <Segment
              {...props.passedProps}
              key={index}
              stepNumber={index + 1}
              hasVisited={
                state.visitedIndexes[openStateIndex][index] !== noop()
              }
              isOpen={index === state.openIndex}
              goToSelf={this.goToSelf(index)}
              goToNext={this.goToNext}
              goToStart={this.goToStart}
              hasCompletedAllSteps={hasCompletedAllSteps}
            />
          );
        })}
      </div>
    );
  }
}

export default FormStepList;

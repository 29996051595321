import {
  ModalHeader,
  RotaButton,
  RotaButtonIcon,
  RotaChip,
  RotaNewModal,
  iconNew
} from "@teamrota/rota-design";
import React, { useState } from "react";
import styled from "styled-components";
import Select from "~/src/components/form-components/select";
import { usePartners } from "~/src/graphql/queries/use-partners.js";
import { Props, Role, State, Venue } from "./types";
import usePartnerDetails from "./hooks/use-partner-details";
import { InputsRow, ButtonContainer } from "./styles";
import { validate } from "./validate";
import { usePayrollRates } from "~/src/containers/payroll-rate/graphql/use-payroll-rates";
import { usePayrollCalculations } from "~/src/containers/payroll-calculations/graphql/use-payroll-calculations";
import { usePayrollScales } from "~/src/containers/payroll-scale/graphql/use-payroll-scales";
import { usePayrollRules } from "~/src/containers/payroll-rules/graphql/use-payroll-rules";
import { useCreateRateCards } from "~/src/containers/payroll/graphql/use-create-payroll-rate-card";

const { Cancel } = iconNew;
const OptionsButton = styled(RotaButtonIcon)`
  position: relative;
`;

const getDropdownValuesFromData = (data: any) => {
  return (
    data?.map((item: any) => ({
      value: item.id,
      label: item.accountName || item.name
    })) || []
  );
};

const initialState: State = {
  selectedPartnerId: "",
  selectedRate: "",
  selectedCharge: "",
  selectedScale: "",
  selectedRule: ""
};

const CreateRateModal = ({ onClose }: Props) => {
  const [formState, setFormState] = useState<State>(initialState);
  const [selectedRoles, setSelectedRoles] = useState<Role[]>([]);
  const [selectedVenues, setSelectedVenues] = useState<Venue[]>([]);

  const formErrors = validate({ ...formState, selectedRoles, selectedVenues });

  const updateForm = <K extends keyof State>(field: K, value: State[K]) => {
    setFormState(prevState => ({ ...prevState, [field]: value }));
  };

  const { partners } = usePartners();
  const { roles, venues, loading: loadingPartnerDetails } = usePartnerDetails({
    partnerId: formState.selectedPartnerId
  });

  const handleSelectRole = (roleId: string) => {
    const role = roles.find((role: Role) => role.id === roleId);

    const isRoleSelected = selectedRoles.some(role => role.id === roleId);
    if (isRoleSelected) {
      setSelectedRoles(prevState =>
        prevState.filter(role => role.id !== roleId)
      );
    } else {
      setSelectedRoles(prevState => {
        if (role) {
          return [...prevState, role];
        }
        return prevState;
      });
    }
  };

  const handleSelectVenue = (venueId: string) => {
    const venue = venues.find((venue: Venue) => venue.id === venueId);

    const isVenueSelected = selectedVenues.some(venue => venue.id === venueId);
    if (isVenueSelected) {
      setSelectedVenues(prevState =>
        prevState.filter(venue => venue.id !== venueId)
      );
    } else {
      setSelectedVenues(prevState => {
        if (venue) {
          return [...prevState, venue];
        }
        return prevState;
      });
    }
  };

  const {
    createPayrollRateCards,
    loading: creatingRateCards
  } = useCreateRateCards();

  const onSave = async () => {
    try {
      await createPayrollRateCards({
        partnerAccountId: formState.selectedPartnerId,
        roleIds: selectedRoles.map(role => role.id),
        venueIds:
          selectedVenues.length > 0
            ? selectedVenues.map(venue => venue.id)
            : undefined,
        payrollRateId: formState.selectedRate,
        payrollScaleId: formState.selectedScale,
        payrollCalculationId: formState.selectedCharge,
        payrollRuleId: formState.selectedRule,
        allVenues: selectedVenues.length === 0,
        effectiveFrom: new Date()
      });
      onClose();
    } catch (error) {
      console.error("Error creating rate card:", error);
    }
  };

  const { payrollRates } = usePayrollRates("");
  const { payrollCalculations } = usePayrollCalculations("");
  const { payrollScales } = usePayrollScales("");
  const { payrollRules } = usePayrollRules("");

  return (
    <RotaNewModal
      onClose={onClose}
      header={
        <ModalHeader
          title={"Create a rate card"}
          endAction={
            <OptionsButton onClick={onClose}>
              <Cancel />
            </OptionsButton>
          }
        />
      }
      footer={
        <ButtonContainer>
          <RotaButton
            disabled={Object.keys(formErrors).length > 0 || creatingRateCards}
            onClick={onSave}
          >
            {creatingRateCards ? "Saving..." : "Save"}
          </RotaButton>
        </ButtonContainer>
      }
    >
      <Select
        label="Select the partner"
        options={getDropdownValuesFromData(partners)}
        shouldFixLabelHeight
        placeholder="Partner"
        value={formState.selectedPartnerId}
        onChange={(value: string) => {
          updateForm("selectedPartnerId", value);
          setSelectedRoles([]);
          setSelectedVenues([]);
        }}
        isSmall
      />

      <InputsRow>
        <div style={{ width: "50%" }}>
          <Select
            label="Select the role"
            options={getDropdownValuesFromData(roles)}
            shouldFixLabelHeight
            placeholder="Role"
            onChange={(value: string) => {
              handleSelectRole(value);
            }}
            isSmall
            value={null}
            isLoading={loadingPartnerDetails}
            isDisabled={!formState.selectedPartnerId}
          />

          <div
            style={{ display: "flex", flexWrap: "wrap", marginBottom: "32px" }}
          >
            {selectedRoles.map(role => (
              <RotaChip
                key={role.id}
                chipText={role.name || ""}
                onClose={() => handleSelectRole(role.id)}
              />
            ))}
          </div>
        </div>

        <div style={{ width: "50%" }}>
          <Select
            label="Select the venue"
            options={getDropdownValuesFromData(venues)}
            shouldFixLabelHeight
            placeholder="Venue"
            onChange={(value: string) => {
              handleSelectVenue(value);
            }}
            isSmall
            value={null}
            isLoading={loadingPartnerDetails}
            isDisabled={!formState.selectedPartnerId}
          />

          <div
            style={{ display: "flex", flexWrap: "wrap", marginBottom: "32px" }}
          >
            {selectedVenues.map(venue => (
              <RotaChip
                key={venue.id}
                chipText={venue.name || ""}
                onClose={() => handleSelectVenue(venue.id)}
              />
            ))}
          </div>
        </div>
      </InputsRow>

      <InputsRow>
        <div style={{ width: "50%" }}>
          <Select
            label="Rate"
            options={getDropdownValuesFromData(payrollRates)}
            shouldFixLabelHeight
            placeholder="Rate"
            onChange={(value: string) => {
              updateForm("selectedRate", value);
            }}
            isSmall
            value={formState.selectedRate}
          />
        </div>

        <div style={{ width: "50%" }}>
          <Select
            label="Charge"
            options={getDropdownValuesFromData(payrollCalculations)}
            shouldFixLabelHeight
            placeholder="Charge"
            onChange={(value: string) => {
              updateForm("selectedCharge", value);
            }}
            isSmall
            value={formState.selectedCharge}
          />
        </div>
      </InputsRow>

      <InputsRow>
        <div style={{ width: "50%" }}>
          <Select
            label="Scale"
            options={getDropdownValuesFromData(payrollScales)}
            shouldFixLabelHeight
            placeholder="Scale"
            onChange={(value: string) => {
              updateForm("selectedScale", value);
            }}
            isSmall
            value={formState.selectedScale}
          />
        </div>

        <div style={{ width: "50%" }}>
          <Select
            label="Rule"
            options={getDropdownValuesFromData(payrollRules)}
            shouldFixLabelHeight
            placeholder="Rule"
            onChange={(value: string) => {
              updateForm("selectedRule", value);
            }}
            isSmall
            value={formState.selectedRule}
          />
        </div>
      </InputsRow>
    </RotaNewModal>
  );
};

export default CreateRateModal;

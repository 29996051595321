import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useDrop } from "react-dnd";

import { COLORS } from "~/src/styles/config.style";
import { DND_ITEM_TYPES, POOL_TYPE_COLOR } from "~/src/consts";
import SearchInput from "~/src/components/form-components/search-input";
import Loading from "~/src/components/loading";
import Member from "../member";
import Icon from "~/src/components/icon";
import Placeholder from "./placeholder";

import {
  Title,
  Content,
  Description,
  StaffContainer,
  InputWrapper,
  Overlay,
  OverlayMessage,
  Results,
  LoadingWrapper,
  FadedEnd
} from "./panel.styles";

import {
  TitleWrapper,
  IconWrapper,
  TitleIcon
} from "../panel-group/panel-group.styles";

const Panel = ({
  isCollapsed,
  onSearchChange,
  isLoading,
  poolType,
  title,
  size,
  description,
  searchText,
  dropMessage,
  onLoadMore,
  totalResults,
  staff,
  onShowMemberDetails,
  placeholderTitle,
  placeholderDescription,
  onDropMember
}) => {
  const [isSearch, setIsSearch] = useState(false); // user has typed in search box (show empty data template if no results)
  const [isArrowToggled, setIsArrowToggled] = useState(isCollapsed);

  const [{ canDrop, isOver, memberDropped }, drop] = useDrop(
    () => ({
      accept: DND_ITEM_TYPES.POOL_MEMBER,
      drop: member => onDropMember(member),
      canDrop: member => poolType !== member.poolType,
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        memberDropped: monitor.getItem()
      })
    }),
    [onDropMember]
  );

  const handleChange = text => {
    setIsSearch(!!text);
    onSearchChange(text);
  };

  const handlePanelVisibility = () => {
    setIsArrowToggled(!isArrowToggled);
  };

  const isDrop =
    isOver && canDrop && memberDropped ? !memberDropped?.isGroupMember : false;
  const showLoading = isLoading;

  const Loader = !staff.length && (
    <LoadingWrapper>
      <Loading color={COLORS.BLACK} />
    </LoadingWrapper>
  );

  return (
    <div>
      <TitleWrapper>
        <IconWrapper onClick={handlePanelVisibility}>
          <TitleIcon
            color={COLORS[POOL_TYPE_COLOR[poolType]]}
            name={Icon.names.ARROW_DOWN}
            size={Icon.sizes.SMALL}
            isArrowToggled={isArrowToggled}
          />
          <Title poolType={poolType}>{title}</Title>
        </IconWrapper>
      </TitleWrapper>
      <Content poolType={poolType} size={size} isOpen={isArrowToggled}>
        <Description>{description}</Description>
        <InputWrapper>
          <SearchInput
            placeholder="Search by name"
            value={searchText}
            onChange={text => handleChange(text)}
            iconColor={POOL_TYPE_COLOR[poolType]}
          />
        </InputWrapper>

        <Results>
          <Overlay isVisible={showLoading}>
            <OverlayMessage isVisible={showLoading}>
              <Loading done={!isLoading} />
            </OverlayMessage>
          </Overlay>

          <div ref={drop}>
            <Overlay isVisible={isDrop}>
              <OverlayMessage isVisible={isDrop}>{dropMessage}</OverlayMessage>
            </Overlay>
            <FadedEnd>
              <StaffContainer poolType={poolType} size={size}>
                <InfiniteScroll
                  pageStart={0}
                  loadMore={onLoadMore}
                  hasMore={totalResults > staff?.length}
                  loader={Loader}
                  useWindow={false}
                  threshold={200}
                  initialLoad={false}
                >
                  {!!staff?.length &&
                    staff?.map((member, index) => {
                      return (
                        <Member
                          normalWhiteSpacing
                          key={index}
                          {...member}
                          poolType={poolType}
                          onClick={() => onShowMemberDetails(member?.id)}
                        />
                      );
                    })}
                  {!staff?.length && !isLoading && (
                    <Placeholder
                      noResults={isSearch}
                      title={placeholderTitle}
                      description={placeholderDescription}
                      poolType={poolType}
                    />
                  )}
                </InfiniteScroll>
              </StaffContainer>
            </FadedEnd>
          </div>
        </Results>
      </Content>
    </div>
  );
};

export default Panel;

import React, { useEffect, useState } from "react";
import {
  RotaButton,
  RotaCheckbox,
  RotaRadio,
  RotaSnackBar
} from "@teamrota/rota-design";

import AlertDialog from "~/src/containers/settings-page/dialog";

import updateTargetAccount from "~/src/containers/settings-page/graphql/update-target-account.decorator";
import getMemberTypeRatings from "~/src/containers/settings-page/graphql/get-member-type-ratings.decorator";

import styles from "./check-in-out-tab-content.styles";

const CheckInOutTabContent = ({ loading, account, updateTargetAccount }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [checkInOutState, setCheckInOutState] = useState(null);
  const [snackOpen, setSnackOpen] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);

  useEffect(() => {
    setCheckInOutState(account);
  }, [account]);

  const onClickHandler = async () => {
    if (!isLoading) {
      setIsLoading(true);
      try {
        await updateTargetAccount(checkInOutState);
        setIsLoading(false);
        setOpenDialog(false);
        setHasErrors(false);
        setSnackOpen(true);
      } catch (error) {
        setIsLoading(false);
        setHasErrors(true);
        setSnackOpen(true);
        console.log("save error", error);
      }
    }
  };

  const handleStateChange = e => {
    const temp = { ...checkInOutState };
    temp[e.target.name] = e.target.checked;
    setCheckInOutState(temp);
  };

  const handleRadioChange = e => {
    const temp = { ...checkInOutState };
    temp[e.target.name] = e.target.value === "true";
    setCheckInOutState(temp);
  };

  return (
    !loading && (
      <>
        <div id="tab-content">
          <div id="row-title" style={styles.rowTitle}>
            Timesheet Automation
          </div>
          <div style={styles.row}>
            <RotaRadio
              label="Pay calculated based on Check in/out times"
              name="isPayCalculationOnCheckInOutTimes"
              value={true}
              onChange={handleRadioChange}
              checked={
                checkInOutState?.isPayCalculationOnCheckInOutTimes === true
              }
            />
            <RotaRadio
              label="Keep scheduled work times regardless of Check in/out times"
              name="isPayCalculationOnCheckInOutTimes"
              value={false}
              onChange={handleRadioChange}
              checked={
                checkInOutState?.isPayCalculationOnCheckInOutTimes === false
              }
            />
          </div>
          <div style={styles.row}>
            <RotaCheckbox
              name="isPinAutomateTimesheetApproval"
              onClick={handleStateChange}
              isChecked={
                checkInOutState?.isPinAutomateTimesheetApproval || false
              }
              label="Automatically approve timesheets if member uses Pin"
            />
          </div>

          <div style={{ padding: "31px 0 22px 0" }}>
            <span style={styles.rowTitle}>
              <span
                className="emoji"
                role="img"
                aria-label=""
                aria-hidden="true"
              >
                ☆ Star rating required for:
              </span>
            </span>
            <div style={styles.row}>
              <RotaCheckbox
                name="isCasualMemberTypeRatings"
                onClick={handleStateChange}
                isChecked={checkInOutState?.isCasualMemberTypeRatings || false}
                label="Casual"
              />
              <RotaCheckbox
                name="isPermanentMemberTypeRatings"
                onClick={handleStateChange}
                isChecked={
                  checkInOutState?.isPermanentMemberTypeRatings || false
                }
                label="Permanent"
              />
            </div>
            <div style={styles.row}>
              <RotaCheckbox
                name="isAgencyMemberTypeRatings"
                onClick={handleStateChange}
                isChecked={checkInOutState?.isAgencyMemberTypeRatings || false}
                label="Agency"
              />
              <RotaCheckbox
                name="isContractorLimitedCompanyMemberTypeRatings"
                onClick={handleStateChange}
                isChecked={
                  checkInOutState?.isContractorLimitedCompanyMemberTypeRatings ||
                  false
                }
                label="Limited Company"
              />
            </div>
            <div style={styles.row}>
              <RotaCheckbox
                name="isContractorUmbrellaCompanyMemberTypeRatings"
                onClick={handleStateChange}
                isChecked={
                  checkInOutState?.isContractorUmbrellaCompanyMemberTypeRatings ||
                  false
                }
                label="Umbrella Company"
              />
              <RotaCheckbox
                name="isContractorSelfEmployedMemberTypeRatings"
                onClick={handleStateChange}
                isChecked={
                  checkInOutState?.isContractorSelfEmployedMemberTypeRatings ||
                  false
                }
                label="Self Employed"
              />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <RotaButton onClick={() => setOpenDialog(true)}>
              {!isLoading && "Save"}
            </RotaButton>
          </div>
          <AlertDialog
            setOpenDialog={setOpenDialog}
            openDialog={openDialog}
            handleClose={() => setOpenDialog(false)}
            onClickHandler={onClickHandler}
            dialogTitle="Are you sure you want to save these changes?"
            declineBtnText="Cancel"
            acceptBtnText="Save Changes"
            isLoading={isLoading}
          ></AlertDialog>
        </div>
        {hasErrors && snackOpen ? (
          <RotaSnackBar
            snackOpen={snackOpen}
            severity={"error"}
            message={
              "Your changes were not saved, check the entered details and try again"
            }
            onClose={() => setSnackOpen(false)}
          />
        ) : (
          <RotaSnackBar
            snackOpen={snackOpen}
            severity={"success"}
            message={"Saved successfully"}
            onClose={() => setSnackOpen(false)}
          />
        )}
      </>
    )
  );
};

export default getMemberTypeRatings(updateTargetAccount(CheckInOutTabContent));

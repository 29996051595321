import { STYLE_CONSTS } from "~/src/styles/config.style";

export function getBreakPoint(size) {
  return STYLE_CONSTS[`BREAK_POINT_${size.toUpperCase()}`];
}

// "small" | "medium" | "large" | "xlarge";
export function mediaQuery(size) {
  return `@media screen and (min-width: ${getBreakPoint(size)})`;
}

export function hexToRGBA(hexString, opacity = 1) {
  const hex = hexString.replace("#", "");
  return `rgba(${parseInt(hex.substring(0, 2), 16)},
  ${parseInt(hex.substring(2, 4), 16)},
  ${parseInt(hex.substring(4, 6), 16)}, ${opacity})`;
}

export function clearFix(parent = "&") {
  const pseudoSelector = `${parent}::after`;
  return {
    [pseudoSelector]: {
      clear: "both",
      content: '""',
      display: "table"
    }
  };
}

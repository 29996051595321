export const isMemberSalariedWorker = (annualSalary = 0, memberType = "") => {
  return (
    !!annualSalary &&
    [
      "PERMANENT",
      "CONTRACTOR_LIMITED_COMPANY",
      "CONTRACTOR_UMBRELLA_COMPANY",
      "CONTRACTOR_SELF_EMPLOYED"
    ].includes(memberType)
  );
};

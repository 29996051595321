import moment from "moment-timezone";
import { ZonedDate } from "@teamrota/rota-common";

import * as dateHelpers from "../date-helpers";
import packClusters from "./pack-clusters";

const MILLIS_PER_DAY = 1000 * 3600 * 24;
const DATE_KEY_FORMAT = "YYYY-MM-DD";
const MIDNIGHT_HHMM_FORMAT = "00:00";
const MIN_EVENT_HEIGHT = 90;

export default function buildEventLayout(
  shifts,
  pixelsPerMinute,
  gridWidth,
  calendarWeekDate
) {
  // eslint-disable-next-line no-param-reassign
  shifts = shifts.map(shift => ({
    ...shift,
    startTime: new ZonedDate(shift.startTime),
    endTime: new ZonedDate(shift.endTime)
  }));

  /**
   * First put events into their respective days
   * NOTE that this means that if a shift spans 2 days, it will result in
   * 2 events being made with their respective 'dateContexts'
   */

  // Grouped shifts by dateContext
  const groupedShifts = {};

  shifts.forEach(shift => {
    const totalSpannedDays = totalDaysSpannedByShift(shift);

    const endAtMidnight = Boolean(
      moment(shift.endTime).format("HH:mm") === MIDNIGHT_HHMM_FORMAT
    );

    // For the total amount of days spanned add to the grouped shift object
    // for the given date context.

    Array(totalSpannedDays)
      .fill(null)
      .forEach((_, index) => {
        const dateContext = moment(shift.startTime)
          .add(index, "d")
          .startOf("day");
        const key = moment(dateContext).format(DATE_KEY_FORMAT);
        // If we havent pushed to this date then create an empty array
        if (!groupedShifts[key]) groupedShifts[key] = [];

        // Add the 'CalendarEvent'
        const calendarEvent = {
          position: {},
          // TODO: This should become `shift`
          shifts: [{ ...shift }],
          data: {
            id: shift.id,
            eventName: shift.name,
            venueName: shift.venue.name,
            startTime: shift.startTime,
            endTime: shift.endTime
          },
          dateContext: dateContext.toDate(),
          spanning: {
            fromPrevious: dateContext.startOf("day").isAfter(shift.startTime),
            toNext:
              dateContext.endOf("day").isBefore(shift.endTime) && !endAtMidnight
          }
        };

        if (!dateContext.isSame(calendarWeekDate, "isoweek")) {
          return null;
        }

        // Calculate the base positioning
        const top = calculateEventTop(calendarEvent, pixelsPerMinute);
        const height = calculateEventHeight(calendarEvent, pixelsPerMinute);
        const width = gridWidth / 7;
        const left =
          dateHelpers.getIndexOfDay(calendarEvent.dateContext) * width;

        let bottom = top + Math.max(height, MIN_EVENT_HEIGHT);

        // Add the element to the array
        return groupedShifts[key].push({
          ...calendarEvent,
          position: { top, bottom, width, left }
        });
      });
  });

  // Build object into array of positioned calendar events
  // For each day we need to cluster the events and do some final positioning
  return Object.keys(groupedShifts).reduce(
    (eventsArray, key) => [
      ...eventsArray,
      // Layout the events within their columns
      ...packClusters(groupedShifts[key], pixelsPerMinute, gridWidth)
    ],
    []
  );
}

/**
 * Get the height of the event based on start end and whether it is spanning.
 */
function calculateEventHeight(event, pixelsPerMinute) {
  let start = moment(event.data.startTime);
  let end = moment(event.data.endTime);
  // If spanned from prev the mutate the 'start' and set to start of day
  if (event.spanning.fromPrevious)
    start = moment(event.dateContext).startOf("day");
  if (event.spanning.toNext) end = moment(event.dateContext).endOf("day");
  return end.diff(start, "minutes") * pixelsPerMinute;
}

/**
 * Calculate the pixel distance from the top of the calendar depending on the
 * spanning and start time.
 */
function calculateEventTop(event, pixelsPerMinute) {
  const start = moment(event.data.startTime);
  if (event.spanning.fromPrevious) return 0;
  return (start.hours() * 60 + start.minutes()) * pixelsPerMinute;
}

function totalDaysSpannedByShift(shift) {
  // Get diff in days of start ending
  const startEndingTimeDiff = Math.abs(
    shift.endTime.getTime() - shift.startTime.getTime()
  );

  // Beginng of start date
  const rangeStart = new ZonedDate(shift.startTime);
  rangeStart.setHours(0, 0, 0, 0);

  // Get start of first day and
  const startOffset = shift.startTime.getTime() - rangeStart.getTime();

  return Math.ceil((startOffset + startEndingTimeDiff) / MILLIS_PER_DAY);
}

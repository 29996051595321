import { graphql } from "@apollo/client/react/hoc";
import { gql } from "@apollo/client";
import { flow } from "lodash";

import { addAuthVars } from "@teamrota/authlib";

import { withAuth } from "~/src/auth/hoc";
import { handleErrors } from "~/src/utils/errors";

import LogFragment from "~/src/graphql/fragments/log/log.fragment";

export const GET_SHIFT = addAuthVars(gql`
  ${LogFragment}
  query getShift($shiftId: ID!) {
    account {
      id
      shift(id: $shiftId) {
        id
        roleRate {
          id

          ... on roleRate {
            chargeRate
          }
        }

        shiftGroupId

        ... on shift {
          bonuses(isActive: true) {
            id
            type
          }
          totalCostBonusesAndRewards(predicted: true)
        }

        cancelledAt
        approvalState
        briefing
        isPartnerManaged
        name
        identifier
        numberRequested
        role {
          id
          name
          isUncalculatedRate
        }
        startTime
        endTime
        policy {
          id
          minimumShiftLength
          minimumShiftPercentage
          cancellationPeriod
        }
        uniform {
          id
          items
          identifier
        }
        venue {
          id
          address
          name
          directions
          meetingPoint
        }

        ... on shift {
          logs {
            ...LogFragment
          }
        }

        targetAccount {
          id
        }

        algoMeta {
          id
          hoursBeforeShift
          algoSkew
          favouriteFactor
          globalRatingFactor
          roleFactor
          relationshipFactor
          autoAcceptThreshold
        }

        sourceAccount {
          id
          venues {
            id
            address
            name
            directions
            meetingPoint
          }
          uniformTemplates {
            id
            identifier
          }
        }

        tags {
          id
          name
          isCompulsory
        }
        isDayRate
      }
    }
  }
`);

export default flow(
  graphql(GET_SHIFT, {
    skip: props => !props.isOpen,
    props: handleErrors(({ data: { loading, account } }) => ({
      isLoading: loading,
      shift: account?.shift,
      uniformTemplates: account?.shift.sourceAccount.uniformTemplates,
      venueTemplates: account?.shift.sourceAccount.venues
    })),
    options: ({ auth, shiftId }) => ({
      fetchPolicy: "network-only",
      variables: auth.addVals(GET_SHIFT, { shiftId })
    })
  }),
  withAuth
);

import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

import { addAuthVars } from "@teamrota/authlib";

export const CREATE_PAYROLL_SCALE = addAuthVars(gql`
  mutation createPayrollScale($name: String!, $config: [JSONObject]!) {
    createPayrollScale(name: $name, config: $config) {
      name
      config
    }
  }
`);

export const useCreatePayrollScale = () => {
  const [create, { loading, error }] = useMutation(CREATE_PAYROLL_SCALE, {
    onError: error => {
      throw error;
    }
  });
  const createPayrollScale = async ({
    name,
    config
  }: {
    name: string;
    config: [JSON];
  }) => {
    await create({
      variables: {
        name,
        config
      },
      refetchQueries: ["payrollScales"]
    });
  };

  return { createPayrollScale, loading, error };
};

import React from "react";
import Money from "~/src/utils/money";
import { FormattedLog, Bullet, Highlight, Text } from "../../index.styles";

export default props => {
  const payRateFormat = props.payRate
    ? Money({ amount: props.payRate }).toFormat()
    : "";

  if (props.actionType === "CREATE") {
    return (
      <FormattedLog>
        <Bullet>&bull;</Bullet>
        <Text>Added</Text>
        <Highlight>{props.name}</Highlight>
        <Text>with pay rate</Text>
        <Highlight>{payRateFormat}</Highlight>
      </FormattedLog>
    );
  }

  if (props.actionType === "UPDATE") {
    return (
      <FormattedLog>
        <Bullet>&bull;</Bullet>
        <Text>Updated the pay rate of</Text>
        <Highlight>{props.name}</Highlight>
        <Text>to</Text>
        <Highlight>{payRateFormat}</Highlight>
      </FormattedLog>
    );
  }

  if (props.actionType === "DELETE") {
    return (
      <FormattedLog>
        <Bullet>&bull;</Bullet>
        <Text>Removed</Text>
        <Highlight>{props.name}</Highlight>
      </FormattedLog>
    );
  }
  return null;
};

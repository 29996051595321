import MoneyTransaction from "~/src/utils/money/money-transaction";
import Money from "~/src/utils/money";

class BookingAccount {
  constructor(bookingId, bookingData, bonusData) {
    this.bookingId = bookingId;
    this.bookingData = bookingData;
    this.bonusData = bonusData || [];

    this.pay = new MoneyTransaction(`pay [${bookingId}]`, { bookingId }, true);
    this.cost = new MoneyTransaction(
      `cost [${bookingId}]`,
      { bookingId },
      true
    );
    this.bonus = new MoneyTransaction(
      `bonus [${bookingId}]`,
      { bookingId },
      true
    );
    this.reward = new MoneyTransaction(
      `reward [${bookingId}]`,
      { bookingId },
      true
    );
    this.payCalc = false;
    this.costCalc = false;
    this.bonusCalc = false;
  }

  /**** Bonuses and Rewards ******/

  getBonus = () => {
    this.calcBonus();
    return this.bonus.current;
  };

  getReward = () => this.reward.current;

  hasBonus = () => !this.bonus.current.isZero();
  hasReward = () => !this.reward.current.isZero();

  addBonusData = data => {
    this.bonusData = data;
    if (this.bonusCalc) {
      this.bonus = new MoneyTransaction();
      this.bonusCalc = false;
    }
  };

  calcBonus = () => {
    if (!this.bonusCalc) {
      const { lengthFinal } = this.bookingData;

      try {
        this.bonusData.forEach(bonus => {
          const money = BookingAccount.convertBonusToMoney(bonus, lengthFinal);
          this.addBonus(money);
        });
      } catch (e) {
        console.log(`BookingAccount [${this.bookingId}]`, e);
      }
      this.bonusCalc = true;
    }
  };

  static convertBonusToMoney = (bonus, lengthFinal, numDays) => {
    let bonusMoney = Money({ amount: bonus.amount });

    if (bonus.type === "hourly") {
      bonusMoney = bonusMoney.multiply(lengthFinal);
    } else {
      const divisor = numDays || 1;
      bonusMoney = bonusMoney.divide(divisor);
    }

    return bonusMoney;
  };

  addBonus = bonus => {
    this.bonus.add(bonus);
    return this;
  };

  addReward = reward => {
    this.reward.add(reward);
    return this;
  };

  /**** Cost and Pay ******/

  getCost = () => this.cost.current;

  getPay = () => this.pay.current;

  calcPayRate = () => {
    const { payRate, lengthFinal, isDayRate } = this.bookingData;

    if (!this.payCalc) {
      if (isDayRate) {
        this.pay.add(Money({ amount: payRate }));
      } else {
        this.pay.add(Money({ amount: payRate })).multiply(lengthFinal);
      }

      this.payCalc = true;
    }

    return this;
  };

  calcChargeRate = () => {
    const { chargeRate, lengthFinal, isDayRate } = this.bookingData;

    if (!this.costCalc) {
      if (isDayRate) {
        this.cost.add(Money({ amount: chargeRate }));
      } else {
        this.cost.add(Money({ amount: chargeRate })).multiply(lengthFinal);
      }
      this.costCalc = true;
    }

    return this;
  };

  /**** Final Cost and Final Pay ******/

  _finalCost = () => {
    const finalCost = new MoneyTransaction(`finalCost [${this.bookingId}]`);

    if (!this.costCalc) this.calcChargeRate();

    // Reward isn't part of the cost to the Partner
    finalCost.add(this.getCost()).add(this.getBonus());

    return finalCost;
  };

  getFinalCost = () => this._finalCost().current;
  getFinalCostWithVat = taxRate => this._finalCost().applyVAT(taxRate).current;

  _finalPay = () => {
    const finalPay = new MoneyTransaction(`finalPay [${this.bookingId}]`);

    if (!this.payCalc) this.calcPayRate();

    finalPay
      .add(this.getPay())
      .add(this.getBonus())
      .add(this.getReward());

    return finalPay;
  };

  getFinalPay = () => this._finalPay().current;
  getFinalPayWithVat = taxRate => this._finalPay().applyVAT(taxRate).current;
}

export default BookingAccount;

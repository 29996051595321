import { gql } from "@apollo/client";

export default gql`
  fragment UserFragment on user {
    id
    firstName
    lastName
    photo
    phoneNumber
    email
    notes
    accountId
    pin
    venueIds
    createdAt
    account {
      id
      logo
      accountName
    }
  }
`;

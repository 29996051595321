import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

import { addAuthVars } from "@teamrota/authlib";

import useAuth from "~/src/auth/hooks/use-auth";
import MemberRoleFragment from "~/src/graphql/fragments/member-role";
import { GET_MEMBER } from "../graphql/get-member";

export const UPDATE_MEMBER_ROLEs = addAuthVars(gql`
  ${MemberRoleFragment}
  mutation createOrUpdateMember($id: ID, $roles: [JSON]) {
    createOrUpdateMember(id: $id, roles: $roles) {
      id
      roles {
        ...MemberRoleFragment
      }
    }
  }
`);

export const useUpdateMemberRoles = () => {
  const auth = useAuth();
  const [update, { loading, error }] = useMutation(UPDATE_MEMBER_ROLEs, {
    onError: error => {
      throw error;
    }
  });
  const updateMemberRoles = async ({ memberId, roles }) => {
    await update({
      variables: auth.addVals(GET_MEMBER, { id: memberId, roles })
    });
  };

  return { updateMemberRoles, loading, error };
};

import React from "react";
import styled from "styled-components";

//this file will be added to rota-library

const RadioWrapper = styled.div`
  display: inline-block;
`;

const Mark = styled.span`
  display: inline-block;
  position: relative;
  border: 2px solid ${({ theme }) => theme.neutral.light};
  width: 20px;
  height: 20px;
  left: 0;
  border-radius: 50%;
  &::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.primary.main};
    opacity: 0;
    left: 50%;
    top: 50%;
    position: absolute;
    transition: all 110ms;
  }
`;

const Input = styled.input`
  position: absolute;
  visibility: hidden;
  display: none;
  &:checked + ${Mark} {
    border-color: ${({ theme }) => theme.primary.main};
    &::after {
      width: 10px;
      height: 10px;
      opacity: 1;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
    }
  }
`;

const Label = styled.label`
  display: flex;
  cursor: pointer;
  padding: 5px 10px 5px 0;
  align-items: center;
  justify-content: center;
  position: relative;
  ${props =>
    props.disabled &&
    `
        cursor: not-allowed;
        opacity: 0.4;
    `}
`;

const RotaRadio = ({ name, children, isDisabled, value, onChange }) => {
  return (
    <>
      <RadioWrapper>
        <Label>
          <Input
            name={name}
            type="radio"
            disabled={isDisabled}
            checked={value || false}
            onChange={e => onChange(e.target.checked)}
          />
          <Mark />
          {children}
        </Label>
      </RadioWrapper>
    </>
  );
};

export default RotaRadio;

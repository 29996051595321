import styled from "styled-components";
import { getColorToRGBA } from "@teamrota/rota-design";

export const ARROW_WIDTH = 32;

export const StyledSurround = styled.span`
  position: absolute;
  top: 0;

  ${({ $reverse }) => ($reverse ? "left" : "right") + ": 0"};

  height: 100%;
  width: 32px;
  cursor: pointer;
  display: flex;
  padding: 0;

  ${({ $reverse }) => "padding-" + ($reverse ? "right" : "left") + `: 4px`};

  z-index: 100;

  background: transparent;
`;

export const StyledWrapArrow = styled.span`
  position: absolute;

  top: 20%;
  ${({ $reverse }) => ($reverse ? "left" : "right") + ": 0px"};

  width: 24px;
  height: 60%;

  border-radius: 5px;
  border: solid 1px
    ${({ theme }) => getColorToRGBA(theme.neutral.lighter, 0.25)};

  background: ${({ theme }) => getColorToRGBA(theme.neutral.lighter, 0.25)};
  cursor: pointer;

  ${({ $reverse }) => ($reverse ? "transform: rotate(180deg)" : "")};

  z-index: 100;

  &:hover {
    border: solid 1px ${({ theme }) => theme.primary.main};
  }

  & .icon {
    position: absolute;
    left: calc(50% - 12px);
    top: calc(50% - 12px);
    width: 24px;
    height: 24px;
  }
`;

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { flow } from "lodash";

import { addAuthVars } from "@teamrota/authlib";

import { withAuth } from "~/src/auth/hoc";
import { handleErrors } from "~/src/utils/errors";

import MemberFragment from "~/src/graphql/fragments/member/member.fragment";
import MemberDocumentsFragment from "~/src/graphql/fragments/member-documents/member-documents.fragment";
import LogFragment from "~/src/graphql/fragments/log/log.fragment";

export const GET_MEMBER = addAuthVars(gql`
  ${MemberFragment}
  ${MemberDocumentsFragment}
  ${LogFragment}
  query getMember($memberId: ID!, $selectedAccountId: ID) {
    account {
      serviceAreas {
        id
        name
      }
      id
      member(id: $memberId) {
        p46Question
        requiresTravel
        isEligibleForRebook
        ...MemberFragment
        ...MemberDocumentsFragment

        memberCompany {
          name

          registeredNumber
          registeredAddress
          invoicingAddress

          isVATRegistered
          isVATCertificateAttached
          isVATCertificateChecked

          ... on memberCompany {
            UTRNumber
            VATRegistrationNumber
            bankName
            accountName
            sortCode
            accountNumber
          }

          isCompanyRegistrationCertificateAttached
          isHMRCSelfEmployedConfirmationCertificateAttached
          isConfirmationCertificateChecked
          isConfirmationOfRightToWork
          isPublicLiabilityProofAttached
          isContractForServicesSigned
          isConfirmationOfBankDetailsAttached
        }
        profile {
          summary
          linkedInProfile
          experience {
            role
            venue
            startDate
            endDate
            supervisor
            supervisorEmail
            isCurrentRole
          }
        }
        ... on member {
          logs {
            ...LogFragment
          }
        }
        memberAvailabilityExceptions {
          id
          startDate
          endDate
        }
        tags {
          id
          name
        }
      }
    }
  }
`);

export default flow(
  graphql(GET_MEMBER, {
    skip: props => !props.isOpen || props.isAddMember,
    options: ({ memberId, auth }) => ({
      variables: auth.addVals(GET_MEMBER, { memberId }),
      fetchPolicy: "network-only"
    }),
    props: handleErrors(({ data: { loading, account, refetch } }) => ({
      isLoading: loading,
      member: account?.member ?? {},
      serviceAreas: account?.serviceAreas ?? [],
      refetchMember: refetch
    }))
  }),
  withAuth
);

import { getCountryValidations } from "@teamrota/rota-common";

export const validatePostcode = (countryCode, value, required = false) => {
  const { name, validate } = getCountryValidations(countryCode).postcode;

  if (value?.length > 0) {
    if (validate(value)) {
      return true;
    } else {
      return `Please enter a valid ${countryCode ?? ""} ${name}`;
    }
  } else if (required) {
    return `Please enter a ${name}`;
  } else {
    return true;
  }
};

export const validatePhoneNumber = (countryCode, value, required = false) => {
  const { name, validate } = getCountryValidations(countryCode).phoneNumber;

  if (value?.length > 0) {
    if (validate(value)) {
      return true;
    } else {
      return `Please enter a valid ${countryCode ?? ""} ${name}`;
    }
  } else if (required) {
    return `Please enter a ${name}`;
  } else {
    return true;
  }
};

export const validateNiNumber = (countryCode, value, required = false) => {
  const { name, validate } = getCountryValidations(countryCode).niNumber;

  if (value?.length > 0) {
    if (validate(value)) {
      return true;
    } else {
      return `Please enter a valid ${name}`;
    }
  } else if (required) {
    return `Please enter a ${name}`;
  } else {
    return true;
  }
};

export const validateAccountNumber = (countryCode, value, required = false) => {
  const { name, validate } = getCountryValidations(countryCode).accountNumber;

  if (value?.length > 0) {
    if (validate(value)) {
      return true;
    } else {
      return `Please enter a valid ${name}`;
    }
  } else if (required) {
    return `Please enter a ${name}`;
  } else {
    return true;
  }
};

export const validateSortCode = (countryCode, value, required = false) => {
  const { name, validate } = getCountryValidations(countryCode).sortCode;

  if (value?.length > 0) {
    if (validate(value)) {
      return true;
    } else {
      return `Please enter a valid ${name}`;
    }
  } else if (required) {
    return `Please enter a ${name}`;
  } else {
    return true;
  }
};

export const validatePhoneCountry = (phoneNumber, value) => {
  if (phoneNumber?.trim()?.length > 0 && !value) {
    return "Please include country code of the phone number";
  }

  return true;
};

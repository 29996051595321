/* eslint-disable consistent-return */

import React from "react";
import Loading from "~/src/components/loading";
import Icon from "~/src/components/icon";

import {
  Wrapper,
  Title,
  ManageableShiftsWrapper,
  LoadingWrapper,
  StyledIcon
} from "./manage-shifts.styles";
import getManageableShifts from "./get-manageable-shifts.query";
import ManageableShiftComponent from "./manageable-shift";

const ManageShiftsComponent = ({
  shouldLoadShifts,
  isLoading,
  manageableShifts,
  onOpenShift
}) => {
  if (!shouldLoadShifts) return null;

  if (!isLoading && manageableShifts?.length === 0) {
    return (
      <Wrapper shouldBeCenter>
        <StyledIcon name={Icon.names.CHECK} isLarge />
        <Title>Nothing to manage! You are all set.</Title>
      </Wrapper>
    );
  }

  const title = isLoading
    ? "Preparing your shifts"
    : "The following shifts needs action:";

  return (
    <Wrapper shouldBeCenter={isLoading}>
      <Title>{title}</Title>
      {isLoading && (
        <LoadingWrapper>
          <Loading color="black" />
        </LoadingWrapper>
      )}

      {!isLoading && (
        <>
          <Title isSubTitle>
            If you press the
            <StyledIcon name={Icon?.names?.CHECK} />
            the member will be ACCEPTED on the shift. Pressing
            <StyledIcon name={Icon?.names?.CLOCK} />
            will move them to a waiting list. If you are sure you don't want
            them press <StyledIcon name={Icon?.names?.UNCHECK} />
          </Title>
          <ManageableShiftsWrapper>
            {manageableShifts?.length > 0 &&
              manageableShifts?.map((shift, i) => (
                <ManageableShiftComponent
                  onOpenShift={() => onOpenShift(shift)}
                  key={i}
                  shift={shift}
                />
              ))}
          </ManageableShiftsWrapper>
        </>
      )}
    </Wrapper>
  );
};
export default getManageableShifts(ManageShiftsComponent);

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import FormStepList from "./FormStepList";
import { Intro, Header, Title, Wrapper, Container } from "./styles";
import { Role } from "@teamrota/authlib";
import { getOr, find, omit } from "lodash/fp";
import * as utils from "~/src/containers/scheduler/utils";
import asyncConfirm from "~/src/utils/async-confirm";
import { getPolicyQueryParams } from "~/src/utils/formatting";
import createOrUpdateShift from "~/src/containers/requester-calendar/graphql/create-or-update-shift";
import { errorModal } from "~/src/utils/errors";
import MoneyTransaction from "~/src/utils/money/money-transaction";
import createOrUpdateBonusReward from "~/src/containers/scheduler/updateBonus";
import Money from "~/src/utils/money";
import useAuth from "~/src/auth/hooks/use-auth";

import { mapDispatchToProps, mapStateToProps } from "./selectors";

import Modal from "~/src/components/modal";

// Form Segments
import FormAudience from "./FormAudience";
import FormCalendar from "./FormCalendar";
import FormMembers from "./FormMembers";
import FormRequirements from "./FormRequirements";
import Summary from "./Summary";
import CompletedShift from "./CompletedShift";

const ShiftCreateModal = ({
  shiftForm,
  isOpen,
  handleShiftUpdate,
  shiftOpenIndex,
  isGridSelectedShift,
  isProviderScheduler,
  searchText,
  user,
  policy,
  filteredPartners,
  createOrUpdateShift,
  refetchSchedules,
  setIsShiftCreateModalOpen,
  bonuses,
  createOrUpdateBonusReward,
  requesterProfileLoading,
  getRequesterProfile
}) => {
  const [isShiftPostLoading, setIsShiftPostLoading] = useState(false);
  const [selectedPartnerId, setSelectedPartnerId] = useState(null);
  const auth = useAuth();

  const memberId = shiftForm?.shifts[0]?.assignedMemberIds[0];

  useEffect(() => {
    if (selectedPartnerId) {
      getRequesterProfile({
        variables: {
          userId: selectedPartnerId,
          memberId
        }
      });
    }
  }, [selectedPartnerId]);

  const venueOptions =
    user?.venueIds?.length > 0
      ? user?.account?.venues.filter(({ id }) =>
          user?.venueIds.includes(parseInt(id))
        )
      : user?.account?.venues;

  const handleClosePopUp = async () => {
    if (
      await asyncConfirm("Do you want to cancel this schedule?", {
        shouldHideSubText: true,
        shouldAddPadding: true,
        confirmButtonText: "Yes, cancel this schedule"
      })
    ) {
      setIsShiftCreateModalOpen(false);
      isProviderScheduler && setSelectedPartnerId(null);
    }
  };

  const accountRoleRates = user?.account?.roleRates;
  const targetAccountId = user?.account?.id;

  const roleOptions = user
    ? utils.createRoleRateOptions({
        roleRates: accountRoleRates,
        accountId: targetAccountId,
        allRoles: true
      })
    : {};

  const dayRoleOptions =
    user &&
    utils.createRoleRateOptions({
      roleRates: accountRoleRates,
      accountId: targetAccountId,
      isDayRoles: true
    });

  const calculatedRoleOptions = user
    ? utils.createRoleRateOptions({
        roleRates: user.account.roleRates,
        accountId: targetAccountId,
        isUncalculatedRate: false
      })
    : {};

  const uncalculatedRoleOptions = user
    ? utils.createRoleRateOptions({
        roleRates: user.account.roleRates,
        accountId: targetAccountId,
        isUncalculatedRate: true
      })
    : {};

  const handlePostShift = async () => {
    if (!auth.hasRole(Role.SHIFTS_CREATE)) return;
    const shifts = shiftForm.shifts.filter(s => !s.isDeleted);
    const policyShift = find(
      shift => utils.getIsPolicyApplied(policy, shift),
      shifts
    );

    if (
      !policyShift ||
      (await asyncConfirm("Are you sure you want to post this shift?", {
        confirmButtonText: "Post",
        falseButtonText: "Back",
        isConfirmButtonGreen: true,
        subComponent: () => (
          <span>
            The shift length is {utils.getHours(policyShift)} hours so a minimum
            of {policy.minimumShiftLength} hours will be charged in accordance
            with our{" "}
            <a href={getPolicyQueryParams(policy)} target="_blank">
              Minimum Shift Length Policy.
            </a>
          </span>
        )
      }))
    ) {
      try {
        utils.validateShift(shifts);

        for (const shift of shifts) {
          try {
            const formattedData = {
              ...shiftForm,
              shifts: omit(["isUncalculatedRate", "briefingId"], shift)
            };

            if (isProviderScheduler) {
              formattedData.partnerId = selectedPartnerId;
            }

            setIsShiftPostLoading(true);
            const cleanedData = utils.cleanDataForSave(formattedData);
            const savedShift = await createOrUpdateShift(cleanedData);

            if (auth.hasAnyRole(Role.SHIFTS_COST, Role.SHIFTS_PAY)) {
              await addBonusToShift(savedShift.data.createOrUpdateShift);
            }

            setIsShiftPostLoading(false);
            refetchSchedules();
          } catch (e) {
            await errorModal(e);
          }
        }

        setIsShiftCreateModalOpen(false);
      } catch (e) {
        console.log(e);
        await errorModal(e.message);
      }
    }
  };

  const addBonusToShift = async createdShifts => {
    try {
      if (bonuses && find({ shiftIndex: shiftOpenIndex }, bonuses)) {
        const currentBonuses = getOr(
          [],
          "items",
          find({ shiftIndex: shiftOpenIndex }, bonuses)
        );

        await Promise.all(
          currentBonuses
            .filter(item => item.type && item.amount)
            .map(async bonus => {
              const bonusAmount = new MoneyTransaction("addBonusToShift");
              bonusAmount.add(Money({ amount: bonus.amount }));

              await Promise.all(
                createdShifts.map(shift =>
                  createOrUpdateBonusReward({
                    variables: {
                      shiftId: shift?.id,
                      amount: bonusAmount?.current?.getAmount(),
                      period: bonus?.type,
                      type: "bonus"
                    }
                  })
                )
              );
            })
        );
      }
    } catch (e) {
      console.log("There was a problem adding the bonus/es to the shift: ", e);
    }
  };

  const briefingTemplates = user?.account?.briefingTemplates;
  const connections = user?.account?.requesterConnections;
  const roleRates = user?.account?.roleRates;
  const uniformOptions = user?.account?.uniformTemplates;
  const defaultBreakMinutes = user?.account?.defaultBreakMinutes;
  const isAccountRebookingEnabled = user?.account?.isRebookingEnabled;
  const isAccountSleepTimeEnabled = user?.account?.isSleepTimeEnabled;

  const formStepListProps = {
    venueOptions: venueOptions || [],
    handlePostShift,
    handleClosePopUp,
    isShiftPostLoading,
    roleOptions,
    dayRoleOptions,
    calculatedRoleOptions,
    uncalculatedRoleOptions,
    briefingTemplates,
    connections,
    uniformOptions,
    roleRates,
    defaultBreakMinutes,
    isProviderScheduler,
    filteredPartners,
    selectedPartnerId,
    setSelectedPartnerId,
    requesterProfileLoading,
    isAccountRebookingEnabled,
    isAccountSleepTimeEnabled
  };

  return (
    <>
      {isOpen ? (
        <Modal onClose={handleClosePopUp} isOpen={isOpen}>
          <Wrapper>
            <Container>
              <CompletedShift
                roleRates={roleRates}
                targetAccountId={targetAccountId}
              />
              <Intro>
                <Header>
                  <Title>Create a Shift</Title>
                </Header>
              </Intro>

              <FormStepList
                passedProps={{
                  targetAccountId,
                  handleShiftUpdate,
                  shiftForm,
                  searchText: searchText || "",
                  ...formStepListProps
                }}
                selectedDates={[]}
                openStateIndex={shiftOpenIndex}
                isGridSelectedShift={isGridSelectedShift}
                segments={[
                  FormAudience,
                  FormCalendar,
                  FormRequirements,
                  FormMembers,
                  Summary
                ]}
              />
            </Container>
          </Wrapper>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(createOrUpdateShift(createOrUpdateBonusReward(ShiftCreateModal)));

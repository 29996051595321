import styled from "styled-components";

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 50px;
  gap: 10px;
`;

export const Option = styled.option``;

export const Text = styled.p`
  margin-bottom: 20px;
  font-size: 18px;
  text-align: center;
`;

import React from "react";
import { TextField } from "@material-ui/core";

import styles from "./account-tab-content.styles";

const UserDetails = ({ state, dispatch }) => {
  const handleOnChange = e => {
    dispatch({ field: e.target.name, value: e.target.value });
  };

  const { firstName, lastName, phoneNumber, email } = state;

  return (
    <div>
      <div id="account-details" style={styles.sectionTitle}>
        User Details
      </div>
      <div id="form-row-1" style={styles.formRow}>
        <div id="form-column-1" style={styles.formColumn}>
          <span style={styles.InputPlaceholder} id="input-firstName">
            First Name
          </span>
          <TextField
            variant="outlined"
            onChange={handleOnChange}
            fullWidth
            name="firstName"
            value={firstName}
          />
        </div>
        <div id="form-column-2" style={styles.formColumn}>
          <span id="input-lastName" style={styles.InputPlaceholder}>
            Last Name
          </span>
          <TextField
            variant="outlined"
            onChange={handleOnChange}
            fullWidth
            name="lastName"
            value={lastName}
          />
        </div>
      </div>
      <div id="form-row-2" style={styles.formRow}>
        <div id="form-column-3" style={styles.formColumn}>
          <span style={styles.InputPlaceholder} id="input-email">
            Email
          </span>
          <TextField
            variant="outlined"
            onChange={handleOnChange}
            fullWidth
            value={email}
            name="email"
            type="email"
          />
        </div>
        <div id="form-column-4" style={styles.formColumn}>
          <span style={styles.InputPlaceholder} id="input-phone">
            Phone Number
          </span>
          <TextField
            variant="outlined"
            onChange={handleOnChange}
            fullWidth
            value={phoneNumber}
            name="phoneNumber"
            type="number"
          />
        </div>
      </div>
    </div>
  );
};

export default UserDetails;

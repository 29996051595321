import moment from "moment-timezone";
import * as yup from "yup";
import { ZonedDate } from "@teamrota/rota-common";

const isSameOrBefore = (startTime, endTime) => {
  return moment(startTime, "HH:mm").isSameOrBefore(moment(endTime, "HH:mm"));
};

const REQUIRED_MESSAGE = "This field is required";
export const validationSchema = yup.object().shape({
  numberRequested: yup
    .number()
    .typeError("Number requested must be a number")
    .required(REQUIRED_MESSAGE),
  briefing: yup.string().required(REQUIRED_MESSAGE),
  directions: yup.string().required(REQUIRED_MESSAGE),
  meetingPoint: yup.string().required(REQUIRED_MESSAGE),
  identifier: yup.string(),
  startTime: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .matches(
      /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
      "Ensure to use the format HH:mm"
    )
    .test(
      "start_time_test",
      "Shift start time must be before the shift end time",
      function(value) {
        const { endTime, endDate, startDate } = this.parent;
        const endDateTime = new ZonedDate(`${endDate} ${endTime}`);
        const startDateTime = new ZonedDate(`${startDate} ${value}`);
        return isSameOrBefore(startDateTime, endDateTime);
      }
    ),
  startDate: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .test(
      "start_date_test",
      "Shift start date must be same or before the shift end date",
      function(value) {
        const { endTime, endDate, startTime } = this.parent;
        const endDateTime = new ZonedDate(`${endDate} ${endTime}`);
        const startDateTime = new ZonedDate(`${value} ${startTime}`);
        return isSameOrBefore(startDateTime, endDateTime);
      }
    ),
  endDate: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .test(
      "end_date_test",
      "Shift end date must be same or after the shift start date",
      function(value) {
        const { endTime, startDate, startTime } = this.parent;
        const endDateTime = new ZonedDate(`${value} ${endTime}`);
        const startDateTime = new ZonedDate(`${startDate} ${startTime}`);
        return isSameOrBefore(startDateTime, endDateTime);
      }
    ),
  endTime: yup
    .string()
    .matches(
      /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
      "Ensure to use the format HH:mm"
    )
    .test(
      "end_time_test",
      "Shift end time must be after the shift start time",
      function(value) {
        const { endDate, startDate, startTime } = this.parent;
        const endDateTime = new ZonedDate(`${endDate} ${value}`);
        const startDateTime = new ZonedDate(`${startDate} ${startTime}`);
        return isSameOrBefore(startDateTime, endDateTime);
      }
    )
    .required(REQUIRED_MESSAGE)
});

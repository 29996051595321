import { gql, useQuery } from "@apollo/client";

export const USE_HAS_BREAKS_CONFIG = gql`
  query hasBreaksConfig($roleRateId: ID!, $venueId: ID!) {
    hasBreaksConfig(roleRateId: $roleRateId, venueId: $venueId)
  }
`;

export const useHasBreaksConfig = ({ roleRateId, venueId }) => {
  const { data } = useQuery(USE_HAS_BREAKS_CONFIG, {
    variables: { roleRateId, venueId },
    fetchPolicy: "network-only",
    skip: !roleRateId || !venueId
  });

  return data?.hasBreaksConfig ?? false;
};

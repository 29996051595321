import React from "react";
import LogChangedValue from "../log-changed-value";
import LogAddedValue from "../log-added-value";
import LogRemovedValue from "../log-removed-value";

const hasValue = val => val !== null && val !== undefined;

export default function Log(props) {
  if (hasValue(props.newValue) && !hasValue(props.oldValue)) {
    return <LogAddedValue {...props} />;
  }
  if (!hasValue(props.newValue) && hasValue(props.oldValue)) {
    return <LogRemovedValue {...props} />;
  }
  if (hasValue(props.newValue) && hasValue(props.oldValue)) {
    return <LogChangedValue {...props} />;
  }
  return null;
}

import React, { useState, useEffect } from "react";
import client from "~/src/services/graphql";

import styles from "../styles";
import { GET_ACCOUNT_VENUES, GET_USER_VENUES } from "./graphql";
import { RotaCheckbox } from "@teamrota/rota-design";

const HomeVenueFilter = ({ schedule, setHomeVenueIds }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [allVenues, setAllVenues] = useState([]);
  const [venuesChecked, setVenuesChecked] = useState({});

  const setVenues = async () => {
    const { data: accountVenuesData } = await client.query({
      query: GET_ACCOUNT_VENUES
    });
    const { data: userVenuesData } = await client.query({
      query: GET_USER_VENUES
    });

    const accountVenues = accountVenuesData?.account?.venues;
    const userHomeVenueIds = userVenuesData?.user?.venueIds;

    if (userHomeVenueIds.length > 0)
      setAllVenues(
        accountVenues.filter(({ id }) =>
          userHomeVenueIds.includes(parseInt(id))
        )
      );
    else setAllVenues(accountVenues);
  };

  useEffect(() => {
    setVenues();
  }, []);

  const handleVenuesChecked = venueId => {
    setVenuesChecked({
      ...venuesChecked,
      [venueId]: !venuesChecked[venueId]
    });
  };

  useEffect(() => {
    if (Object.keys(venuesChecked).length > 0) {
      const venues = Object.keys(venuesChecked).filter(
        venues => venuesChecked[venues]
      );
      if (venues.length) {
        setHomeVenueIds(venues);
      }
    }
  }, [venuesChecked]);

  const getNumberOfTimesVenueBooked = venueId => {
    const totalVenuesPerBooking = schedule?.scheduledMembers?.reduce(
      (total, scheduledMember) =>
        scheduledMember?.member?.venueId === venueId ? total + 1 : total,
      0
    );
    return totalVenuesPerBooking > 0 ? totalVenuesPerBooking : null;
  };

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <span>
      <p style={styles.header} onClick={toggleIsExpanded}>
        <span>HOME VENUE</span>
        <span style={styles.expandArrow}>
          {/* Using the alternative && conditional rendering will cause 
          flickering bugs which seems to be to do with React re-rendering */}
          {isExpanded ? (
            <img src="/images/up_arrow.svg" style={styles.image} />
          ) : (
            <img src="/images/down_arrow.svg" style={styles.image} />
          )}
        </span>
      </p>
      {isExpanded &&
        allVenues?.map(venue => {
          const numberOfTimesVenueBooked = getNumberOfTimesVenueBooked(
            venue.id
          );
          return (
            <label key={venue?.id} style={styles.label}>
              <RotaCheckbox
                label={venue?.name}
                onClick={() => handleVenuesChecked(venue?.id)}
                isChecked={venuesChecked[venue?.id]}
              />
              {numberOfTimesVenueBooked && (
                <span style={styles.roleNo}> {numberOfTimesVenueBooked}</span>
              )}
            </label>
          );
        })}
      {isExpanded && (
        <p style={styles.clear} onClick={() => setVenuesChecked({})}>
          Clear
        </p>
      )}
    </span>
  );
};

export default HomeVenueFilter;

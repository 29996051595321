import moment from "moment-timezone";
import flow from "lodash/fp/flow";
import range from "lodash/fp/range";
import map from "lodash/fp/map";

export function getCalendarDateRange(visibleDate) {
  const firstOfMonth = visibleDate.startOf("month");
  return flow(
    range,
    map(day => moment(firstOfMonth).add(day, "days"))
  )(
    firstOfMonth
      .clone()
      .startOf("isoweek")
      .diff(firstOfMonth, "days"),
    moment(visibleDate).daysInMonth()
  );
}

export function mergeDateTime(date, time) {
  const [hours, minutes] = time.split(":");
  return moment(date)
    .set("hours", hours || 0)
    .set("minutes", minutes || 0)
    .toDate();
}

export function getIsValid(start, end) {
  return !start || !end || moment(start).isBefore(moment(end));
}

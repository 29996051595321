import React, { useState } from "react";
import moment from "moment-timezone";
import Grid from "@material-ui/core/Grid";
import Modal from "~/src/components/modal";
import GridCardContent from "~/src/containers/scheduler/GridCard/grid-card-content";

import {
  MultipleShiftsCard,
  Div
} from "~/src/containers/scheduler/GridCard/grid-card.styles";

const GridCard = ({ booking, onClick, openCreateShift }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openShiftModal = event => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {booking?.length === 1 && (
        <GridCardContent
          booking={booking}
          onClick={booking?.[0]?.shift?.cancelledAt ? openCreateShift : onClick}
        />
      )}

      {booking?.length > 1 && (
        <MultipleShiftsCard onClick={e => openShiftModal(e)}>
          <Div fontSize="10px" fontWeight="500" justifyContent="center">
            {`${booking?.length} ${booking?.length === 1 ? "Shift" : "Shifts"}`}
          </Div>
        </MultipleShiftsCard>
      )}
      <Modal isOpen={isOpen} onClose={() => setIsOpen(!isOpen)} isOverlay>
        <div style={{ width: "351px", margin: "0 103px 29px 103px" }}>
          <div style={{ margin: "15px 0 29px 0" }}>
            <Div color="black" fontSize="24px" overFlowX="none">
              {moment(booking?.[0]?.shift?.startTime).format(
                "dddd, MMMM Do YYYY"
              )}
            </Div>

            <Div color="black" fontSize="10px"></Div>
          </div>

          <Grid container spacing={1}>
            {booking?.map((shiftData, i) => {
              return (
                <Grid item xs={6} s={6} key={i}>
                  <GridCardContent
                    shiftData={shiftData}
                    onClick={(id, sourceAccountId) => {
                      if (shiftData?.shift?.cancelledAt) {
                        setIsOpen(false);
                        openCreateShift();
                      } else {
                        onClick(id, sourceAccountId);
                        setIsOpen(!isOpen);
                      }
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </div>
      </Modal>
    </>
  );
};

export default GridCard;

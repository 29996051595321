import styled from "styled-components";

export const StyledModalWrapper = styled.div`
  background-color: #ffffff;
  min-width: 200px;
  overflow: hidden;

  .top_block {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 32px;
    border-bottom: 1px solid #e0e0e0;

    .title {
      font-size: 20px;
    }
  }

  .bottom_block {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 0 0;

    .title {
      width: 80%;
      font-size: 14px;
      line-height: 150%;
      margin-bottom: 24px;
    }

    .buttons {
      width: 80%;
      display: flex;
      justify-content: space-between;

      button {
        width: 144px;
      }
    }
  }
`;

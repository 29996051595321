import { gql } from "@apollo/client";

export const GET_NOTIFS = gql`
  query getNotifications(
    $offset: Int!
    $limit: Int!
    $searchText: String
    $startTime: Date
    $endTime: Date
  ) {
    account {
      id
      notificationsList(
        offset: $offset
        limit: $limit
        searchText: $searchText
        startTime: $startTime
        endTime: $endTime
      ) {
        data {
          id
          recipient
          body
          dateSent
          notificationType
          status
          sender {
            firstName
            lastName
          }
        }
        offset
        limit
        totalResults
      }
    }
  }
`;

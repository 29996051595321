import styled from "styled-components";
import { RotaDropdownHeader } from "@teamrota/rota-design";

export const StyledDropdownsContainer = styled.div`
  border-radius: 4px;
  border: ${({ theme }) => `solid 1px ${theme.neutral.light}`};
  background-color: ${({ theme }) => theme.white};

  & > * {
    border-radius: 0;
  }
  & > :first-child {
    border-radius: 4px 4px 0 0;
  }
  & > :last-child {
    border-radius: 4px 4px 0 0;
  }
`;

export const StyledRotaDropdownHeader = styled(RotaDropdownHeader)`
  width: 100%;
  opacity: 0;

  animation: fade-in 0.3s forwards;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  & > div:first-of-type {
    border: none;
    padding: 9px 12px;
    width: 100%;
  }
`;

export const StyledSeparator = styled.div`
  width: 80%;
  margin: 0 auto;
  height: 1px;
  background-color: ${({ theme }) => theme.neutral.light};
`;

export const StyledFilterWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: fill-available;
  height: 100%;
`;

export const StyledChipsContainer = styled.div`
  width: 100%;
  max-width: 100%;
  margin-left: 20px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`;

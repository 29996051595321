import { gql, useQuery } from "@apollo/client";

export const USE_VENUE_ROLE_TAGS = gql`
  query getVenueRoleTags($partnerId: ID!, $sourceAccountId: ID) {
    account {
      venueOwnerExclusionsAndTags(
        partnerId: $partnerId
        sourceAccountId: $sourceAccountId
      ) {
        id
        name

        tags {
          id
          name
        }

        roleTags {
          id
          roleId
          roleName
          tags {
            id
            name
          }
        }
      }
    }
  }
`;

export const useVenueRoleTags = (partnerId, sourceAccountId) => {
  const { data } = useQuery(USE_VENUE_ROLE_TAGS, {
    variables: { partnerId, sourceAccountId },
    fetchPolicy: "network-only",
    skip: !partnerId
  });

  const venuesWithTags =
    data?.account?.venueOwnerExclusionsAndTags?.filter(
      venueRoletags =>
        venueRoletags.tags.length > 0 || venueRoletags.roleTags.length > 0
    ) || [];

  return {
    venuesWithTags
  };
};

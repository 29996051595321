import React from "react";
import InputWrapper from "../input-wrapper";
import { Input } from "./index.style";

const PinInput = ({ readOnly, placeholder, input }) => {
  const handleChange = ({ target: { value } }) => {
    input.onChange(value?.replace(/[a-z ]/gi, ""));
  };

  return (
    <InputWrapper>
      <Input
        value={input?.value || ""}
        disabled={readOnly}
        placeholder={readOnly ? "" : placeholder}
        onChange={handleChange}
        maxLength={4}
      />
    </InputWrapper>
  );
};

export default PinInput;

import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

const MARGIN_LEFT = `margin-left: ${STYLE_CONSTS.UNIT_1}`;

export const Title = styled.div`
  margin: ${STYLE_CONSTS.UNIT_3} 0;
  ${MARGIN_LEFT} font-size: ${STYLE_CONSTS.FONT_LARGE};
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_MEDIUM};
  font-size: ${STYLE_CONSTS.FONT_LARGE};
  color: ${({ theme }) => theme.text.secondary};
`;

const calculateHeight = props => {
  if (typeof props.height === "number") {
    return `${props.height}px`;
  } else if (typeof props.height === "string") {
    return props.height;
  }

  return "inherit";
};

export const Content = styled.div`
  border-radius: ${STYLE_CONSTS.BORDER_RADIUS};
  background-color: ${({ theme }) => theme.white};
  box-shadow: ${STYLE_CONSTS.FLOATING_LARGE};
  padding: ${STYLE_CONSTS.UNIT_3};
  ${props => (props.minHeight ? `min-height: ${props.minHeight}px` : "")};
  height: ${calculateHeight};
`;

const menuSize = parseInt(STYLE_CONSTS.MENU_SIZE, 10);
const largeUnit = parseInt(STYLE_CONSTS.UNIT_4, 10);

const OFFSET = menuSize - largeUnit;

export const Container = styled.div`
  height: ${({ height }) => height};
  @media screen and (min-width: ${STYLE_CONSTS.BREAK_POINT_MEDIUM}) {
    transition: transform 0.6s ease;
    transform: ${props =>
      props.isSticky && props.offset >= OFFSET
        ? `translateY(${props.offset - OFFSET}px)`
        : ""};
  }
`;

export const TabContainer = styled.div`
  margin: ${STYLE_CONSTS.UNIT_3} 0;
`;

export const Tab = styled.span`
  ${MARGIN_LEFT};
  margin-right: ${STYLE_CONSTS.UNIT_2};
  font-size: ${STYLE_CONSTS.FONT_LARGE};
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_MEDIUM};
  color: ${({ theme }) => theme.text.secondary};
  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "auto")};
`;

import React from "react";
import { isNotSameDay } from "~/src/utils/time-utils";

export default function DayDifference(props) {
  const { displayDate, endTime, startTime } = props;
  return (
    <div>
      {props.optionalLabelText && props.optionalLabelText}
      {displayDate}
      {props.shouldNotShowCarryOver
        ? ""
        : isNotSameDay(startTime, endTime) && <sup>+1</sup>}
      {props.children}
    </div>
  );
}

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

export const DELETE_GROUP_MEMBER = gql`
  mutation deleteGroupMember($id: ID) {
    deleteGroupMember(id: $id)
  }
`;

export default graphql(DELETE_GROUP_MEMBER, {
  name: "deleteGroupMember",
  props: ownProps => ({
    deleteGroupMember: groupMemberId =>
      ownProps.deleteGroupMember({
        variables: {
          id: groupMemberId
        },
        refetchQueries: ["getGroups"]
      })
  })
});

import { RotaCheckbox } from "@teamrota/rota-design";
import React, { useState } from "react";
import RebookableShiftPopup from "./RebookableShiftPopup";

const ShiftRebooking = ({
  handleShiftUpdate,
  shift,
  roleName,
  partnerName
}) => {
  const [isShowPopup, setIsShowPopup] = useState(false);

  const handlePopupSave = isRestrictedToVenue => {
    handleShiftUpdate({ isShiftRebookRestrictedToVenue: isRestrictedToVenue });
    setIsShowPopup(false);
  };

  const handlePopupClose = () => {
    handleShiftUpdate({ isShiftRebookEnabled: false });
    setIsShowPopup(false);
  };

  const handleShiftRebookCheckboxToggle = () => {
    handleShiftUpdate({
      isShiftRebookEnabled: !shift.isShiftRebookEnabled,
      isShiftRebookRestrictedToVenue: shift.isShiftRebookEnabled
        ? false
        : shift.isShiftRebookRestrictedToVenue
    });
    setIsShowPopup(!shift.isShiftRebookEnabled);
  };

  return (
    <div>
      <RotaCheckbox
        label={"Make shift rebookable"}
        isChecked={shift.isShiftRebookEnabled}
        onClick={handleShiftRebookCheckboxToggle}
      />
      {isShowPopup && (
        <RebookableShiftPopup
          roleName={roleName}
          partnerName={partnerName}
          onClose={handlePopupClose}
          onSave={handlePopupSave}
        />
      )}
    </div>
  );
};

export default ShiftRebooking;

import styled from "styled-components";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";
import Button from "~/src/components/button";
import { colors } from "@teamrota/rota-design";

export const Wrapper = styled.div`
  padding: 0 7px 0 32px;
`;

export const TableHead = styled.div`
  display: flex;
  font-size: ${STYLE_CONSTS.FONT_SMALL};
  color: ${COLORS.COOL_GREY};
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_MEDIUM};
`;

export const TableCell = styled.div`
  display: flex;
  align-items: center;
  padding-right: ${STYLE_CONSTS.UNIT_1};
  justify-content: center;
  flex: ${props => props.span};
  &:first-child {
    justify-content: flex-start;
  }
  &:last-child {
    padding-right: 0;
    justify-content: flex-end;
  }
`;

export const TotalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TotalColumn = styled.div`
  margin-left: auto;
  text-align: right;
  display: inline-block;
  padding: ${STYLE_CONSTS.UNIT_2} 0;
`;

export const Divider = styled.div`
  border-bottom: ${STYLE_CONSTS.SMALL_DIVIDER_BORDER};
  margin: ${STYLE_CONSTS.UNIT_2} 0;
`;

export const BoldText = styled.div`
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_MEDIUM};
`;

export const ApproveButton = styled(Button).attrs({ isSmall: true })`
  margin-top: ${STYLE_CONSTS.UNIT_2};
`;

export const ApprovedButton = styled(Button).attrs({
  isSmall: true,
  isGreen: true,
  isOutline: true,
  color: COLORS.GREEN,
  isDisabled: true
})`
  border-color: ${COLORS.GREEN};
  margin-top: ${STYLE_CONSTS.UNIT_2};
`;

export const EmptyBookings = styled.div`
  text-align: center;
  padding: ${STYLE_CONSTS.UNIT_8};
  color: ${COLORS.COOL_GREY};
`;

export const PolicyText = styled.div`
  text-align: center;
  font-size: ${STYLE_CONSTS.FONT_SMALL};
`;

export const TimeSheetText = styled.p`
  margin-bottom: 0;
  color: ${({ cloudyBlue }) =>
    cloudyBlue ? `${COLORS.CLOUDY_BLUE}` : `${COLORS.BLACK}`};
  font-size: ${STYLE_CONSTS.FONT_SMALL};
  ${({ isBold }) => (isBold ? "font-weight: 400;" : null)};
`;

export const HoursInfo = styled.p`
  color: ${COLORS.COOL_GREY};
  font-size: ${STYLE_CONSTS.FONT_SMALL};
  align-self: end;
`;

export const InfoText = styled.p`
  font-style: italic;
  font-size: ${STYLE_CONSTS.FONT_SMALL};
`;

export const StyledWrapTime = styled.div`
  min-width: 150px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  align-items: center;
`;

export const StyledWrapTimesheetRow = styled.div`
  border: 1px solid ${colors.darkGrey};
  border-top-color: transparent;
`;

export const StyledStatus = styled.span`
  color: ${({ isApproved }) => (isApproved ? colors.green : colors.rotaOrange)};
  font-size: 16px;
  font-weight: 700;
`;

export const StyledWrapShiftInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  min-height: 132px;
  height: auto;
  max-height: 179px;
`;

export const StyledWrapShiftStatus = styled.div`
  grid-column-start: 1;
  grid-row-start: 1;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${({ theme }) => theme.neutral.main};
  font-weight: 400;
  height: 65px;
  padding-left: 10px;
  border-top: 1px solid ${colors.darkGrey};
`;

export const StyledWrapInfo = styled.div`
  grid-column-start: 1;
  grid-row-start: 2;
  height: 67px;
  padding: 5px 10px;
`;

export const StyledWrapShiftResult = styled.div`
  grid-column-start: 2;
  grid-row-start: 1;
  grid-row-end: 3;
  font-size: 16px;
  border: 1px solid orange;
  border-bottom-color: transparent;
  border-right-color: transparent;
  padding: 13px 7px 13px 10px;
  width: 100%;
`;

export const StyledWrapShiftValue = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 27px;
  margin-top: ${({ top }) => top && "4px"};
`;

export const StyledShiftTotal = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 27px;
  margin-top: 4px;
  border-top: 1px solid ${({ theme }) => theme.neutral.main};
  padding-top: 9px;
  font-weight: 700;
`;

export const StyledTotalHoursWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
  padding-bottom: 9px;
`;

export const StyledOrangeText = styled.span`
  color: ${({ theme }) => theme.primary.main};
`;

export const StyledBoldText = styled.span`
  font-weight: 700;
`;

import React from "react";
import DocumentUpload from "~/src/components/document-upload";
import { FileInput } from "~/src/components/document-upload/document-upload.styles";
import {
  DropDownContainer,
  UploadImageDropDownMenu,
  NavTray,
  NavLabel,
  NavTitle
} from "./fileUploader.styles";

const FileUploader = ({
  isOnboardingMember,
  handleUploadFile,
  setRef,
  accept,
  type
}) => {
  if (isOnboardingMember) {
    return (
      <DropDownContainer>
        <FileInput
          id={`inputse-${type}`}
          type="file"
          name="file"
          accept="image/*"
          onChange={() => handleUploadFile(type, `inputse-${type}`)}
          ref={ref => setRef({ varName: "fileInput", ref })}
        />
        <UploadImageDropDownMenu>
          <NavTray id="nav-tray-show-hide">
            <NavTitle>
              <NavLabel htmlFor={`inputse-${type}`}>Upload image</NavLabel>
            </NavTitle>
          </NavTray>
        </UploadImageDropDownMenu>
        <DocumentUpload
          sendToBack
          accept={accept}
          type={type}
          onChange={handleUploadFile}
        />
      </DropDownContainer>
    );
  }
  return (
    <DocumentUpload accept={accept} type={type} onChange={handleUploadFile} />
  );
};

export default FileUploader;

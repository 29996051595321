import styled from "styled-components";
import { colors } from "@teamrota/rota-design";

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  margin: 0 0 24px;
`;

export const PayTypeLabel = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin-right: 16px;
  color: ${({ theme }) => theme.neutral.main};
`;

export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const PayRateGrid = styled.div`
  display: grid;
  grid-template-columns: 250px 150px 150px 0.5fr;
  gap: 0px 28px;
  align-items: center;
  overflow: auto;
`;

export const PayRateHeading = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutral.main};
`;

export const RateTitle = styled.p`
  font-size: 16px;
  font-weight: 400;
  color=${colors.darkGrey}
`;
export const RateSubtitle = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.neutral.light};
`;

export const ApplyAfterWrapper = styled.div`
  display: grid;
  grid-template-columns: 95px 1fr;
  grid-gap: 8px;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  color: ${({ isDisabled }) => (isDisabled ? colors.grey : colors.darkGrey)};
`;

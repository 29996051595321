import styled from "styled-components";

export const StyledWrapDialog = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledWrapIcon = styled.div`
  background: ${({ color }) => color};
  border: 2px solid;
  border-color: ${({ borderColor }) => borderColor};
  width: 90px;
  height: 90px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledWrapQuestion = styled.div`
  font-weight: 400;
  font-size: 20px;
  border-bottom: 1px solid;
  border-bottom-color: ${({ color }) => color};
  padding: 27px 0 32px;
`;

export const StyledWrapAfirmation = styled.div`
  padding: 32px 0 32px 0;
  font-weight: 400;
  font-size: 14px;
`;

export const StyledWrapButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const StyledWrapTextArea = styled.textarea`
  border: 1px solid ${({ theme }) => theme.neutral.light};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  outline: none;
`;

export const StyledErrorMessage = styled.div`
  margin: 6px 0 22px;
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.error.main};
  height: 23px;
  font-style: italic;
`;

import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

const ICON_SIZE = "30px";
const ICON_SIZE_SMALL = "18px";

export const SelectedImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${props => (props.isSmall ? ICON_SIZE_SMALL : ICON_SIZE)};
  width: ${props => (props.isSmall ? ICON_SIZE_SMALL : ICON_SIZE)};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.primary.main};
  vertical-align: middle;
  padding: ${props => (props.isSmall ? "4px" : "10px")};
  opacity: 0.9;
`;

export const Connection = styled.div`
  display: flex;
  padding-top: 4.5px;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

export const Wrapper = styled.div`
  height: ${props => (props.isSmall ? ICON_SIZE_SMALL : ICON_SIZE)};
  ${props => props.overidePoolWrapperStyle && "height: 100%;"};
`;

export const AccountName = styled.div`
  transition: ${STYLE_CONSTS.STANDARD_TRANSITON};
  padding: 0 20px 0 10px;
  display: inline-block;
  font-size: ${props =>
    props.isSmall ? STYLE_CONSTS.FONT_XSMALL : STYLE_CONSTS.FONT};
  &:hover {
    color: ${({ theme }) => theme.primary.main};
  }
`;

import styled from "styled-components";
import Icon from "~/src/components/icon";
import defaultPhoto from "../../images/worker-default-photo.png"; // TODO: Default logo

const config = {
  medium: {
    imageSize: "70px",
    iconSize: "20px",
    iconBGSize: "24px",
    iconOffset: "2px",
    iconTop: "4px"
  }
};
const configProp = prop => props =>
  (config[(props && props.size) || "medium"] || config.medium)[prop];

export const Wrapper = styled.span`
  display: inline-block;
  position: relative;
`;

export const Image = styled.span`
  display: inline-block;
  height: ${configProp("imageSize")};
  width: ${configProp("imageSize")};
  border-radius: 50%;
  background-size: cover;
  background-image: ${({ src }) =>
    src ? `url(${src}), url(${defaultPhoto})` : `url(${defaultPhoto})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
`;

export const IconWrapper = styled.span`
  background-color: white;
  cursor: pointer;
  height: ${configProp("iconBGSize")};
  width: ${configProp("iconBGSize")};
  display: inline-block;
  position: absolute;
  text-align: center;
  border-radius: 50%;
  transform: translateY(-14px);
  bottom: 0;
  right: 0;
  &:after {
    content: "";
    display: inline-block;
    height: ${configProp("iconSize")};
    width: ${configProp("iconSize")};
    background-color: ${({ color, theme }) => color || theme.success.light};
    position: absolute;
    border-radius: 50%;
    bottom: ${configProp("iconOffset")};
    right: ${configProp("iconOffset")};
  }
`;

export const StyledIcon = styled(Icon)`
  position: relative;
  top: ${configProp("iconTop")};
  z-index: 1;
`;

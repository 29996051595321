import styled from "styled-components";

export const CalendarContainer = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.white};
  min-height: 100%;
  min-width: min-content;
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const GridWrapper = styled.div`
  padding: 10px 20px 0;
  background: ${({ theme }) => theme.background.primary};
`;

export const OpenShiftContainer = styled.div`
  margin: 30px;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

import { gql } from "@apollo/client";

import { addAuthVars } from "@teamrota/authlib";

import ShiftFragment from "../../fragments/shift/shift.fragment";
import BookingFragment from "../../fragments/booking/booking.fragment";

export default addAuthVars(gql`
  # Fragment imports
  ${ShiftFragment}
  ${BookingFragment}

  # Global get shifts query
  query getShifts(
    $accountType: AccountAccessType
    $displayShiftTypeOnly: ShiftType
    $shiftState: ShiftStateType
    $accountId: ID
    $shiftGroupId: ID
    $serviceAreaId: ID
    $serviceAreaIds: [ID]
    $startTimeRangeStart: Date
    $startTimeRangeEnd: Date
    $createdTimeRangeStart: Date
    $createdTimeRangeEnd: Date
    $overlapsWeek: Date
    $searchText: String
    $orderByPropAsc: String
    $orderByPropDesc: String
    $isFeatured: Boolean
    $isFinalised: Boolean
    $isUnderReview: Boolean
    $isRestricted: Boolean
    $isShiftRebook: Boolean
    $isRegistration: Boolean
    $isCancelled: Boolean
    $isPartnerManaged: Boolean
    $shouldExcludeWhereCancellationPolicyIsNotApplied: Boolean
    $shouldShowUnapprovedFirst: Boolean
    $limit: Int!
    $offset: Int!
    $role: String
    $roleIds: [ID]
    $tagIds: [ID]
    $hasBookingWithState: BookingStateType
    $hasBookingWithStateIn: [BookingStateType]
    # Booking query
    $withBookings: Boolean!
    $bookingsLimit: Int!
    $bookingsOffset: Int!
    $bookingsStateNotIn: [BookingStateType]
    $bookingStateIn: [BookingStateType]
    $bookingsStateEquals: BookingStateType
    $bookingsMemberId: ID
    $bookingsMemberPhone: String
    $bookingsMemberEmail: String
    $collateBookingStastics: Boolean
    $selectedAccountId: ID
    $isTimesheetPage: Boolean
    $shiftType: String
    $hasCancelledBookings: Boolean
    $hasAppliedBookings: Boolean
  ) {
    account {
      id
      shifts(
        accountType: $accountType
        displayShiftTypeOnly: $displayShiftTypeOnly
        accountId: $accountId
        shiftState: $shiftState
        isFeatured: $isFeatured
        isPartnerManaged: $isPartnerManaged
        isFinalised: $isFinalised
        isUnderReview: $isUnderReview
        isRestricted: $isRestricted
        isShiftRebook: $isShiftRebook
        isCancelled: $isCancelled
        serviceAreaId: $serviceAreaId
        serviceAreaIds: $serviceAreaIds
        startTimeRangeStart: $startTimeRangeStart
        startTimeRangeEnd: $startTimeRangeEnd
        createdTimeRangeStart: $createdTimeRangeStart
        createdTimeRangeEnd: $createdTimeRangeEnd
        shouldExcludeWhereCancellationPolicyIsNotApplied: $shouldExcludeWhereCancellationPolicyIsNotApplied
        shouldShowUnapprovedFirst: $shouldShowUnapprovedFirst
        searchText: $searchText
        overlapsWeek: $overlapsWeek
        orderByPropAsc: $orderByPropAsc
        orderByPropDesc: $orderByPropDesc
        limit: $limit
        offset: $offset
        role: $role
        roleIds: $roleIds
        tagIds: $tagIds
        hasBookingWithState: $hasBookingWithState
        hasBookingWithStateIn: $hasBookingWithStateIn
        isRegistration: $isRegistration
        shiftGroupId: $shiftGroupId
        collateBookingStastics: $collateBookingStastics
        isTimesheetPage: $isTimesheetPage
        shiftType: $shiftType
        hasCancelledBookings: $hasCancelledBookings
        hasAppliedBookings: $hasAppliedBookings
      ) {
        roleOptions {
          id
          name
        }
        totalResults
        offset
        limit
        totalHours
        bookingCount

        ... on ShiftResultSet {
          bookingCountTotalCost
        }

        data {
          ...ShiftFragment

          ... on shift {
            chargeRate
            rewards: bonuses(type: "reward", isActive: true) {
              id
              bookings
              type: period
              amount
              reason
            }

            bonuses(type: "bonus", isActive: true) {
              id
              bookings
              type: period
              amount
            }
          }

          # Bookings are heavey - so only include them if asked!
          bookings(
            stateNotIn: $bookingsStateNotIn
            stateEquals: $bookingsStateEquals
            stateIn: $bookingStateIn
            memberId: $bookingsMemberId
            memberPhone: $bookingsMemberPhone
            memberEmail: $bookingsMemberEmail
            limit: $bookingsLimit
            offset: $bookingsOffset
          ) @include(if: $withBookings) {
            data {
              ...BookingFragment

              ... on booking {
                bonuses(type: "bonus", hasBookings: true) {
                  id
                  bookings
                  type: period
                  amount
                }
                rewards: bonuses(type: "reward", isActive: true) {
                  id
                  bookings
                  type: period
                  amount
                  reason
                }
              }
            }
            offset
            limit
            totalResults
          }

          cancelledBookings: bookings(
            stateEquals: CANCELLED
            offset: 0
            limit: 100
            hasReplacementBookingId: false
          ) {
            totalResults
          }
        }
      }
    }
  }
`);

import { gql, useQuery } from "@apollo/client";
import { addAuthVars } from "@teamrota/authlib";

import MemberFragment from "~/src/graphql/fragments/member/member.fragment";

const GET_MEMBER = addAuthVars(gql`
  ${MemberFragment}

  query getMember(
    $selectedAccountId: ID!
    $id: ID!
    $dates: [DateRangeType]!
    $shiftType: ShiftTypeEnumType
    $roleRateId: ID!
    $shiftId: ID!
    $venueId: ID!
  ) {
    account {
      id
      isOverLimitWorkAllowed

      member(id: $id) {
        ...MemberFragment
        isQualifiedWithRole(roleRateId: $roleRateId, shiftId: $shiftId)
        isQualifiedWithTags(shiftId: $shiftId)
        isInVenueServiceArea(venueId: $venueId)
        isConflicted: isConflictedWithDates(shiftId: $shiftId, dates: $dates)
        isExcludedFromVenue: isExcludedFromVenue(shiftId: $shiftId)
        isUnavailable: isUnavailableWithDates(
          shiftId: $shiftId
          dates: $dates
          shiftType: $shiftType
        )
        isHitWorkingHoursLimit(shiftId: $shiftId, dates: $dates)
        isLocationConflict: isConflictedWithShift(
          shiftId: $shiftId
          dates: $dates
          venueId: $venueId
        )
      }
    }
  }
`);

export const useMemberDetails = ({ id, lastDroppedShift, dates }) => {
  const { data, loading: memberDataLoading } = useQuery(GET_MEMBER, {
    skip: !lastDroppedShift,
    variables: {
      id,
      dates,
      selectedAccountId: lastDroppedShift?.sourceAccountId,
      roleRateId: lastDroppedShift?.roleRate.id,
      shiftId: lastDroppedShift?.id,
      shiftType: lastDroppedShift?.type,
      venueId: lastDroppedShift?.venue.id
    },
    fetchPolicy: "network-only"
  });

  return { memberData: data?.account?.member, memberDataLoading };
};

import styled from "styled-components";
import Textarea from "react-textarea-autosize";

export const Input = styled(Textarea)`
  resize: none;
  border: none;
  color: ${({ theme }) => theme.primary.main};
  &:focus {
    outline: none;
  }
  &:disabled {
    color: ${({ theme }) => theme.text.primary};
  }
  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.neutral.main};
  }
`;

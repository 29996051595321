import React from "react";
import moment from "moment-timezone";
import {
  ManageableShiftWrapper,
  ManageableShiftField,
  ClockIcon,
  LinkedShiftIcon,
  LinkedShiftWrapper,
  ManageableShiftContainer,
  ApplicantsContainer,
  HeaderWrapper,
  SeeAllWrapper,
  Container,
  FadeOut
} from "./manageable-shift.styles";
import { dateRangeToHuman } from "~/src/utils/formatting";
import AppliedMember from "../../../AppliedMember";

const formatShiftTimes = (isLinkedShift, startTime, endTime, lastDay) =>
  isLinkedShift
    ? ` ${moment(startTime).format("ddd Do")} to ${moment(lastDay).format(
        "ddd Do"
      )} at ${moment(startTime).format("H:mma")} - ${moment(endTime).format(
        "H:mma"
      )}`
    : `  ${dateRangeToHuman(startTime, endTime, { includeDay: true })} `;

const ManageableShiftComponent = ({
  shift,
  onOpenShift,
  onOpenMemberDetails
}) => {
  return (
    <Container>
      <ManageableShiftContainer>
        <ManageableShiftWrapper onClick={onOpenShift}>
          <HeaderWrapper>
            {shift.isLinkedShift && (
              <LinkedShiftWrapper color={shift.shiftGroupColor}>
                Linked shift
                <LinkedShiftIcon />
              </LinkedShiftWrapper>
            )}
            <ManageableShiftField shouldFlex>
              {shift.name || "Untitled shift"}
            </ManageableShiftField>
          </HeaderWrapper>
          <ManageableShiftField isSmall>
            {`${shift.numberRequested} x ${shift.role.name}`}
          </ManageableShiftField>
          <ManageableShiftField isSmall>
            <ClockIcon color="black" />
            {formatShiftTimes(
              shift.isLinkedShift,
              shift.startTime,
              shift.endTime,
              shift.lastDay
            )}
          </ManageableShiftField>
        </ManageableShiftWrapper>
        <ApplicantsContainer
          haveMultipleApplicants={shift.bookings.data.length > 1}
        >
          {shift.bookings.data.map((booking, index) => (
            <AppliedMember
              key={index}
              member={booking.member}
              bookingId={booking.id}
              onOpenMemberDetails={onOpenMemberDetails}
              queryToRefetch="getManageableShifts"
              isInSideBar
              numberOfShiftRequestedMembers={shift.numberRequested}
              numberOfAcceptedMembers={shift.acceptedBookings.totalResults}
              shiftId={shift.id}
            />
          ))}
        </ApplicantsContainer>
        {shift.bookings.data.length > 1 && <FadeOut />}
      </ManageableShiftContainer>

      {shift.bookings.data.length > 1 && (
        <SeeAllWrapper onClick={onOpenShift}>
          See all ({shift.bookings.totalResults})
        </SeeAllWrapper>
      )}
    </Container>
  );
};

export default ManageableShiftComponent;

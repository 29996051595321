import React from "react";
import Map from "~/src/components/map";
import Geosuggest from "~/src/components/form-components/select/geosuggest";
import { Text } from "./map-searchable.styles";

export default function MapSearchable(props) {
  const {
    onChange,
    value,
    errorLabel,
    mapHeight,
    mapWidth,
    mapTheme,
    isDisabled
  } = props;

  return (
    <div>
      <Geosuggest
        isDisabled={isDisabled}
        onChange={onChange}
        value={value}
        errorLabel={errorLabel}
        placeholder="Enter address"
      />
      <Text>Drop the pin to the exact entry point for staff</Text>
      <Map
        height={mapHeight}
        width={mapWidth}
        theme={mapTheme}
        onDragMarker={marker =>
          onChange({
            address: value?.address,
            ...marker
          })
        }
        markers={[{ lng: value?.lng, lat: value?.lat }]}
      />
    </div>
  );
}

import { gql } from "@apollo/client";
import { addAuthVars } from "@teamrota/authlib";

export const PAY_CALCULATION = addAuthVars(gql`
  query getPayCalculation(
    $id: ID!
    $shift: PayEngineShiftInputType!
    $timesheet: PayEngineTimesheetInputType
    $worker: PayEngineWorkerInputType
  ) {
    getPayCalculation(
      id: $id
      shift: $shift
      timesheet: $timesheet
      worker: $worker
    )
  }
`);

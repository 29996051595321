import { compose } from "lodash/fp";
import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AppliedMemberComponent from "~/src/components/applied-member";
import TextInput from "~/src/components/form-components/text-input";
import Loading from "~/src/components/loading";
import { BOOKING_STATES } from "~/src/consts";
import BlackAndOrangeModal from "~/src/containers/global-modals/BlackAndOrangeModal";
import createOrUpdateShift from "~/src/containers/modals/view-edit-shift/graphql/create-or-update-shift";
import { showMemberDetails } from "~/src/containers/pools/reducer";
import updateBooking from "~/src/graphql/mutations/update-booking/update-booking.decorator";
import styles, {
  AppliedMemberWrapper,
  LoadingTitle,
  LoadingWrapper,
  Text,
  Title,
  TitleWrapper
} from "./styles";

// Redux
const bindActions = dispatch =>
  bindActionCreators(
    {
      showMemberDetails
    },
    dispatch
  );

// Main
const AppliedMember = ({
  actionButtonsShouldHaveLabels,
  bookingId,
  bookingState,
  createOrUpdateShift,
  member,
  numberOfAcceptedMembers,
  numberOfShiftRequestedMembers,
  shiftId,
  showMemberDetails,
  queryToRefetch,
  updateBooking
}) => {
  // Hooks
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [
    newNumberOfShiftRequestedMembers,
    setNewNumberOfShiftRequestedMembers
  ] = useState(() => numberOfShiftRequestedMembers + 1);

  const [newShiftNumbers, setNewShiftNumbers] = useState(
    newNumberOfShiftRequestedMembers
  );

  const { ACCEPTED, PARTNER_DECLINED } = BOOKING_STATES;

  // Handlers
  const postToServer = async memberUpdatedStatus => {
    try {
      if (!isLoading) {
        setIsLoading(true);
      }
      await updateBooking({
        ...(queryToRefetch && {
          refetchQueries: [queryToRefetch]
        }),
        variables: {
          id: bookingId,
          state: memberUpdatedStatus
        }
      });
      setIsLoading(false);
    } catch (e) {
      console.log("There was a problem updating the member.", e);
    }
  };

  const handleUpdateMember = async memberUpdatedStatus => {
    const isPartnerManagedShiftFull =
      numberOfAcceptedMembers >= numberOfShiftRequestedMembers;

    if (memberUpdatedStatus === PARTNER_DECLINED) {
      postToServer(memberUpdatedStatus);
      return;
    }

    if (isPartnerManagedShiftFull) {
      setShowModal(true);
    } else {
      postToServer(memberUpdatedStatus);
    }
  };

  // Helpers
  const pluralise = (count, label) => (count === 1 ? label : `${label}s`);

  const requestMoreMembersText = `You requested ${numberOfShiftRequestedMembers} ${pluralise(
    numberOfShiftRequestedMembers,
    "member"
  )} for this shift. To accept more members, you must increase your request to ${numberOfShiftRequestedMembers +
    1} or more members.`;

  const disableConfirmButtonLogic =
    !newNumberOfShiftRequestedMembers ||
    newNumberOfShiftRequestedMembers <= numberOfShiftRequestedMembers;

  const confirmButtonTextLogic = `${newNumberOfShiftRequestedMembers ||
    0} ${pluralise(newNumberOfShiftRequestedMembers, "member")}`;

  const falseButtonTextLogic = `Still ${numberOfShiftRequestedMembers} ${pluralise(
    numberOfShiftRequestedMembers,
    "member"
  )}`;

  const numberOfShiftRequestedMembersLogic =
    !newNumberOfShiftRequestedMembers ||
    newNumberOfShiftRequestedMembers < numberOfShiftRequestedMembers;

  // Components
  const renderSubComponent = () => (
    <span>
      <TitleWrapper>
        <Title>This shift is full.</Title>
      </TitleWrapper>
      <Text>{requestMoreMembersText}</Text>
      <div style={styles.textContentWrapper}>
        <div style={styles.numberOfMembersWrapper}>
          <Text>How many members do you need for this shift?</Text>
          <span style={styles.numberOfMembersInput}>
            <TextInput
              value={newShiftNumbers}
              isSmall
              min={numberOfShiftRequestedMembers + 1}
              onChangeValue={value => {
                setNewNumberOfShiftRequestedMembers(parseInt(value));
                setNewShiftNumbers(parseInt(value));
              }}
              type="number"
            />
          </span>
        </div>
        {numberOfShiftRequestedMembersLogic && (
          <Text style={styles.warningText}>
            Can't decrease the number of requested members.
          </Text>
        )}
      </div>
    </span>
  );

  return (
    <AppliedMemberWrapper isContainer>
      {showModal && (
        <BlackAndOrangeModal
          isOpen
          onClose={() => setShowModal(false)}
          isOverlay
          config={{
            subComponent: renderSubComponent,
            disableConfirmButton: disableConfirmButtonLogic,
            confirmButtonText: confirmButtonTextLogic,
            falseButtonText: falseButtonTextLogic,
            onConfirm: async () => {
              setIsLoading(true);
              await createOrUpdateShift(
                shiftId,
                {
                  numberRequested: newNumberOfShiftRequestedMembers
                },
                "getShiftGroup"
              ).then(() => {
                postToServer(ACCEPTED);
                setIsLoading(false);
                setNewNumberOfShiftRequestedMembers(
                  newNumberOfShiftRequestedMembers + 1
                );
              });
            }
          }}
        />
      )}
      {isLoading && (
        <LoadingWrapper>
          <LoadingTitle>Updating the member's booking</LoadingTitle>
          <Loading color="black" hideText />
        </LoadingWrapper>
      )}
      {!isLoading && (
        <AppliedMemberWrapper>
          <AppliedMemberComponent
            onMemberPhotoClicked={() => showMemberDetails(member.id)}
            member={member}
            onUpdateMemberState={handleUpdateMember}
            actionButtonsShouldHaveLabels={actionButtonsShouldHaveLabels}
            bookingState={bookingState}
          />
        </AppliedMemberWrapper>
      )}
    </AppliedMemberWrapper>
  );
};

export default compose(
  connect(null, bindActions),
  updateBooking,
  createOrUpdateShift
)(AppliedMember);

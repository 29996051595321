import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const DropdownContent = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.white};
  width: 220%;
  position: absolute;
  height: auto;
  box-shadow: ${STYLE_CONSTS.FLOATING};
  border: ${({ theme }) => `solid 1.2px ${theme.neutral.lighter}`};
  border-radius: ${STYLE_CONSTS.BORDER_RADIUS};
  padding: ${STYLE_CONSTS.UNIT_3};
  z-index: ${STYLE_CONSTS.Z_INDEX_SELECT};
  transform: translateX(-50%);
  margin-top: ${STYLE_CONSTS.UNIT_2};
`;

import React, { useEffect, useRef, useState } from "react";
import moment from "moment-timezone";

import GridCells from "./GridCells";
import HourColumn from "./HourColumn";
import DateRow from "./DateRow";
import { GridWrapper, Grid, GridEnd, Wrapper } from "./styles";

import buildEventLayout from "../services/build-event-layout";
import DraggedEvent from "../DraggedEvent";
import CalendarClusters from "../CalendarClusters";

import { definePixelsPerMinute } from "../utils";
import { getStartEndFromPos } from "../services/date-helpers";
import { PAGESCROLL_ELEMENT_ID } from "~/src/consts";

const DRAG_AT_MILLIS = 200;
const MOUSE_HOLD_IGNORE_DISTANCE = 20;
const DRAG_TICK_SIZE = 15; // Minutes

const DEFAULT_MOUSE_STATE = {
  dragAt: 0,
  downAt: 0,
  upAt: 0,
  dragStartCoords: null,
  dragEndCoords: null,
  dragHasSpannedWeeks: null
};

const checkMouseDown = e =>
  (e.nativeEvent.buttons === undefined
    ? e.nativeEvent.which
    : e.nativeEvent.buttons) === 1;

const CalendarGrid = ({
  container,
  isLoading,
  shifts,
  canDragNewShifts,
  shouldShowCancelled,
  calendarWeekDate,
  changeDate,
  onDraggedShift,
  onEventClick
}) => {
  const [mouseState, setMouseState] = useState(DEFAULT_MOUSE_STATE);

  const [gridBounds, setGridBounds] = useState(null);
  const [pixelsPerMinute, setPixelsPerMinute] = useState(0.6);
  const [scrollOffset, setScrollOffset] = useState(0);
  const [lastCoords, setLastCoords] = useState(null);

  const grid = useRef(null);

  useEffect(() => {
    setMouseState(DEFAULT_MOUSE_STATE);
  }, [canDragNewShifts]);

  const measureGridSize = () => {
    const boundingBox = grid?.current && grid?.current?.getBoundingClientRect();
    if (boundingBox) {
      const doc = document.documentElement;
      // Scroll offset top
      const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

      const box = {
        bottom: boundingBox.bottom,
        height: boundingBox.height,
        left: boundingBox.left,
        right: boundingBox.right,
        top: boundingBox.top + top,
        width: boundingBox.width
      };

      setPixelsPerMinute(definePixelsPerMinute(box.top));
      setGridBounds(box);
    }
  };

  const getEventCoords = event => {
    if (gridBounds) {
      // Round top position to the nearest DRAG_TICK_SIZE minutes.
      const pixelTickSize = DRAG_TICK_SIZE * pixelsPerMinute;
      const actualTop = event.pageY - gridBounds.top + scrollOffset;
      const roundedTop = Math.round(actualTop / pixelTickSize) * pixelTickSize;

      return {
        top: roundedTop,
        left: event.pageX - (gridBounds ? gridBounds.left : 0)
      };
    }

    return null;
  };

  const handleMouseDown = e => {
    if (!canDragNewShifts) return;

    const dragStartCoords = getEventCoords(e);
    setTimeout(() => {
      if (!mouseState.upAt) {
        // IF mouse hasnt come up
        setMouseState({
          ...mouseState,
          dragAt: Date.now(),
          dragStartCoords,
          dragEndCoords: null
        });
      }
    }, DRAG_AT_MILLIS);
  };

  const handleDragStopped = coords => {
    if (!canDragNewShifts) return;

    if (coords && mouseState.dragStartCoords && mouseState.dragEndCoords) {
      const mouseTravelDist = Math.abs(
        coords.top - mouseState.dragStartCoords.top
      );

      if (mouseTravelDist >= MOUSE_HOLD_IGNORE_DISTANCE && mouseState.dragAt) {
        onDraggedShift(
          getStartEndFromPos(
            {
              dragStartCoords: mouseState.dragStartCoords,
              dragEndCoords: coords
            },
            pixelsPerMinute,
            gridBounds?.width ?? 0,
            calendarWeekDate,
            mouseState.dragHasSpannedWeeks,
            scrollOffset
          )
        );

        setMouseState(DEFAULT_MOUSE_STATE);
        return;
      }
    }

    setMouseState({ ...DEFAULT_MOUSE_STATE, upAt: Date.now() });
  };

  const handleMouseMove = e => {
    if (!canDragNewShifts) return;

    const isMouseDown = checkMouseDown(e);
    const coords = getEventCoords(e);

    setLastCoords(isMouseDown ? coords : null);

    if (
      mouseState.dragHasSpannedWeeks &&
      !mouseState.dragEndCoords &&
      !isMouseDown
    ) {
      setMouseState({ ...mouseState, dragHasSpannedWeeks: null });
    }

    if (mouseState.dragStartCoords && isMouseDown) {
      if (
        !mouseState.dragEndCoords ||
        (coords && mouseState.dragEndCoords.top !== coords.top)
      ) {
        setMouseState({
          ...mouseState,
          dragEndCoords: coords
        });
      }
    }

    if (!isMouseDown) {
      setMouseState(DEFAULT_MOUSE_STATE);
    }
  };

  const handleMouseMoveEnd = e => {
    if (!canDragNewShifts) return;

    if (
      checkMouseDown(e) &&
      !mouseState.dragHasSpannedWeeks &&
      mouseState.dragEndCoords
    ) {
      changeDate(1, false);
      setMouseState({ ...mouseState, dragHasSpannedWeeks: "forward" });
    }
  };

  const handleMouseUp = e => handleDragStopped(getEventCoords(e));

  useEffect(() => {
    measureGridSize();
    container.current.addEventListener("scroll", handleContainerScroll);

    window.addEventListener("resize", measureGridSize);
    document
      .getElementById(PAGESCROLL_ELEMENT_ID)
      .addEventListener("scroll", measureGridSize);

    const handleContainerScroll = () =>
      setScrollOffset(container.current?.scrollTop ?? 0);

    return () => {
      window.removeEventListener("resize", measureGridSize);
      document
        .getElementById(PAGESCROLL_ELEMENT_ID)
        .removeEventListener("scroll", measureGridSize);

      container.current?.removeEventListener("scroll", handleContainerScroll);
    };
  }, []);

  useEffect(() => {
    // set listener on the parent that will be triggered if you mouseup
    // while outside the grid - it will use the last coords we saw in it
    const handleContainerMouseUp = () => handleDragStopped(lastCoords);
    container.current.addEventListener("mouseup", handleContainerMouseUp);
    return () => {
      container.current?.removeEventListener("mouseup", handleContainerMouseUp);
    };
  }, [lastCoords, canDragNewShifts]);

  const clusters = isLoading
    ? []
    : buildEventLayout(
        shouldShowCancelled
          ? shifts
          : shifts.filter(shift => shift.cancelledAt === null),
        pixelsPerMinute,
        gridBounds?.width ?? 0,
        calendarWeekDate
      ).filter(cluster =>
        moment(cluster.dateContext).isSame(calendarWeekDate, "isoweek")
      );

  return (
    <Wrapper>
      <GridWrapper>
        <DateRow calendarWeekDate={calendarWeekDate} />

        <div>
          <HourColumn
            pixelsPerMinute={pixelsPerMinute}
            calendarWeekDate={calendarWeekDate}
          />

          <Grid
            ref={grid}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
          >
            {!isLoading && (
              <>
                <GridCells
                  pixelsPerMinute={pixelsPerMinute}
                  calendarWeekDate={calendarWeekDate}
                />

                <CalendarClusters
                  calendarClusters={clusters}
                  onEventClick={onEventClick}
                />

                <DraggedEvent
                  dragStartOffset={scrollOffset}
                  dragStartCoords={mouseState.dragStartCoords}
                  dragEndCoords={mouseState.dragEndCoords}
                  gridWidth={gridBounds?.width ?? 0}
                  pixelsPerMinute={pixelsPerMinute}
                  dragHasSpannedWeeks={mouseState.dragHasSpannedWeeks}
                />
              </>
            )}
          </Grid>
        </div>
      </GridWrapper>

      <GridEnd
        onMouseMove={handleMouseMoveEnd}
        pixelsPerMinute={pixelsPerMinute}
      />
    </Wrapper>
  );
};

export default CalendarGrid;

import React from "react";
import { FormattedLog, Bullet, Highlight, Text } from "../../index.styles";

export default props => (
  <FormattedLog>
    <Bullet>&bull;</Bullet>
    <Text>removed</Text>
    <Highlight>{props.label}</Highlight>
    <Text>-</Text>
    <Highlight>{props.oldValue}</Highlight>
  </FormattedLog>
);

import styled from "styled-components";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";

export const FixedInputWrapper = styled.div`
  float: left;
  width: ${({ width }) => width}px;
  margin-right: ${STYLE_CONSTS.UNIT_1};
`;

export const DynamicInputWrapper = styled.div`
  float: none;
  overflow: hidden;
`;

export const FieldWrapper = styled.div`
  display: inline-block;
  width: 50%;
  padding-left: ${({ isRight }) => (isRight ? STYLE_CONSTS.UNIT_2 : 0)};
`;

export const ShiftDetailsWrapper = styled.div`
  display: flex;
`;

export const ManageShiftWrapper = styled.div`
  display: ${({ isVisible }) => (isVisible ? "inline-block" : "none")};
`;

export const Heading = styled.div`
  font-size: ${STYLE_CONSTS.FONT_LARGE};
  color: ${({ theme }) => theme.text.primary};
  padding: ${STYLE_CONSTS.UNIT_1} ${STYLE_CONSTS.UNIT_3};
  border-bottom: ${STYLE_CONSTS.SMALL_DIVIDER_BORDER_LIGHT};
`;

export const FormWrapper = styled.div`
  padding: ${STYLE_CONSTS.UNIT_2} ${STYLE_CONSTS.UNIT_3};
  border-bottom: ${STYLE_CONSTS.SMALL_DIVIDER_BORDER_LIGHT};
`;

export const SubHeading = styled.div`
  color: ${({ theme }) => theme.primary.main};
  font-size: ${STYLE_CONSTS.FONT};
`;

export const PricingWrapper = styled.div`
  display: inline-block;
  font-weight: bold;
`;

export const WarningsWrapper = styled.div`
  padding: ${STYLE_CONSTS.UNIT_2} ${STYLE_CONSTS.UNIT_4};
  color: ${({ theme }) => theme.primary.main};
  font-size: ${STYLE_CONSTS.FONT_SMALL};
  display: ${props => (props.isVisible ? "inline-block" : "none")};
  text-align: right;
`;

export const ManageShiftText = styled.span`
  display: inline-block;
  font-size: ${STYLE_CONSTS.FONT_SMALL};
  color: ${COLORS.COOL_GREY};
  margin-left: ${STYLE_CONSTS.UNIT_1};
`;

export const SmallText = styled.span`
  font-size: ${STYLE_CONSTS.FONT_SMALL};
`;

export const ButtonFooter = styled.div`
  margin: ${STYLE_CONSTS.UNIT_2} 0;
  display: flex;
  padding: 0 ${STYLE_CONSTS.UNIT_4};
  align-items: center;
`;

export const FlexItem = styled.div`
  flex: 1;
  width: 0;
  text-align: ${({ textAlign }) => textAlign && textAlign};
`;

export const TagsWrapper = styled.div`
  padding-bottom: 15px;
  ${props => props.isInnerWrapper && `padding-left: 15px;`}
`;

const styles = {
  manageShiftCheckbox: {
    marginTop: 15,
    marginBottom: 15
  }
};

export default styles;

export const TitleWrapper = styled.div``;

export const Title = styled.div`
  width: 80%;
  margin: 0 auto;
  text-align: center;
  font-size: 20px;
  font-weight: bolder;
  padding: 25px 0 5px 0;
  border-bottom: solid 1px grey;
`;
export const Text = styled.div`
  text-align: center;
  padding: 12px;
`;

export const StyledLabel = styled.label`
  color: ${({ theme }) => theme.neutral.main};
  font-size: 14px;
  width: ${({ width = "auto" }) => {
    return width;
  }};
`;

export const StyledWrapField = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  margin-bottom: 16px;
`;

export const StyledBonusItemsList = styled.div`
  display: flex;
  align-items: center;
  gap: 0 6px;
  border-top: 1px solid ${({ theme }) => theme.neutral.light};
  padding-top: 6px;

  > p {
    font-size: 14px;
    color: ${({ theme }) => theme.text.primary};
  }
  > span {
    color: ${({ theme }) => theme.primary.main};
    small {
      color: ${({ theme }) => theme.neutral.main};
      font-size: 12px;
      margin-right: 5px;
    }
  }
`;

export const StyledButtonIcon = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.primary.main};
  border-radius: 100%;
  display: flex;
  justify-content: center;
  border: none;
  height: 24px;
  width: 24px;
  cursor: pointer;
`;

export const StyledWrapperButtonIcon = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 100%;
  color: ${({ theme }) => theme.primary.main};
  cursor: pointer;
  display: flex;
  font-size: 12px;
  gap: 10px;
  margin-bottom: 10px;
  padding: 0;
  width: 100%;
`;

export const StyledIconWrapper = styled.div`
  padding: 0;
  margin-bottom: 30px;
  border-bottom: 1px solid ${({ theme }) => theme.primary.main};
  p {
    font-size: 20px;
  }
`;

export const StyledModalContainer = styled.div`
  padding: 0;
  padding: 0;
  text-align: center;

  p {
    line-height: 23px;
  }

  strong {
    font-weight: 600;
  }
`;

export const StyledModalButtons = styled.div`
  display: flex;
  gap: 20px;
  padding: 20px 0 0;
  justify-content: space-around;
`;

export const DayOrNightShiftWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 16px;
  align-items: center;
  margin-bottom: 8px;
`;

import { gql } from "@apollo/client";

export default gql`
  fragment MemberDocumentsFragment on member {
    documents {
      id
      url
      format
      type
      fileName
      label
      subtype
      issuedAt
      expiresAt
    }

    s3Documents {
      id
      format
      type
      fileName
      url
      subtype
      issuedAt
      expiresAt
    }
  }
`;

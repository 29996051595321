import { gql } from "@apollo/client";

export default gql`
  fragment LogFragment on log {
    __typename
    id
    actionType
    authorType
    authorId
    createdAt
    metadata
    logSubject
    member {
      id
      firstName
      lastName
    }
    user {
      id
      firstName
      lastName
      account {
        id
        accountName
      }
    }
  }
`;

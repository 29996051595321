import React from "react";

import Icon from "../icon";
import {
  ActionButtonWrapper,
  ActionButton,
  Label,
  StyledToolTip
} from "./circle-action-button.styles";

const CircleActionButton = ({
  onActionTaken,
  color,
  iconName,
  iconSize,
  colorIcon,
  label,
  toolTipInformation
}) => (
  <ActionButtonWrapper data-tip data-for={label}>
    {toolTipInformation && (
      <StyledToolTip
        id={label}
        place="bottom"
        type="warning"
        effect="solid"
        delayHide={100}
      >
        {toolTipInformation}
      </StyledToolTip>
    )}
    {label && <Label>{label}</Label>}
    <ActionButton onClick={onActionTaken} color={color}>
      <Icon
        name={iconName}
        size={iconSize || "xsmall"}
        color={colorIcon || "inherit"}
      />
    </ActionButton>
  </ActionButtonWrapper>
);

export default CircleActionButton;

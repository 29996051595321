import React from "react";
import { RotaButton, RotaInput } from "@teamrota/rota-design";

import {
  StyledWrapButton,
  StyledButtonWrapper,
  StyledConfirmWrapper,
  StyledWrapConfirmation
} from "../styles";

const BottomModal = ({
  handleCancel,
  handleAdd,
  isConfirmedGroup,
  onChangeInput
}) => (
  <StyledButtonWrapper>
    <StyledConfirmWrapper>
      <StyledWrapConfirmation>
        Confirm you understand this warning by typing "YES" below
      </StyledWrapConfirmation>
      <RotaInput
        isLabelTop
        onChange={e => onChangeInput(e)}
        placeholder="Yes"
      />
    </StyledConfirmWrapper>
    <StyledWrapButton>
      <RotaButton onClick={handleCancel} variant="secondary">
        Cancel
      </RotaButton>

      <RotaButton onClick={handleAdd} disabled={!isConfirmedGroup}>
        Confirm
      </RotaButton>
    </StyledWrapButton>
  </StyledButtonWrapper>
);

export default BottomModal;

import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const Wrapper = styled.div`
  font-size: ${({ isMediumText }) =>
    isMediumText ? STYLE_CONSTS.FONT_MEDIUM : "inherit"}};
  width: ${({ isSingleLine }) => (isSingleLine ? "60%" : "100%")};
  display: ${({ isSingleLine }) => (isSingleLine ? "inline-block" : "block")};
  > input {
    color: ${({ isError, theme }) =>
      isError ? theme.error.main : theme.primary.main};
  }
`;

export const ErrorText = styled.div`
  height: ${STYLE_CONSTS.UNIT_2};
  font-size: ${STYLE_CONSTS.FONT_XSMALL};
  color: ${({ theme }) => theme.error.main};
`;

import React from "react";
import moment from "moment-timezone";
import { ZonedDate } from "@teamrota/rota-common";

import { GridDayColumn, GridDayColumnHour } from "./styles";

function getHourStyle(ppm) {
  return { height: `${ppm * 60}px` };
}

function cellIsBeforeNow(calendarWeekDate, dayIndex, hourIndex) {
  const cellTime = moment(calendarWeekDate)
    .add(dayIndex, "d")
    .add(hourIndex + 1, "h");
  return cellTime.isBefore(new ZonedDate());
}

const GridCells = ({ pixelsPerMinute, calendarWeekDate }) => (
  <div>
    {Array(7)
      .fill(null)
      .map((_, dayIndex) => (
        <GridDayColumn key={dayIndex}>
          {Array(24)
            .fill(null)
            .map((__, hourIndex) => (
              <GridDayColumnHour
                key={hourIndex}
                style={getHourStyle(pixelsPerMinute)}
                isFilled={cellIsBeforeNow(
                  calendarWeekDate,
                  dayIndex,
                  hourIndex
                )}
              />
            ))}
        </GridDayColumn>
      ))}
  </div>
);

export default React.memo(GridCells);

export const ALL_GROUP_NAME = "All Staff";
export const ALL_GROUP_ID = "all";
export const ALL_GROUP_SORT = -2;

export const RECOMMENDED_NAME = "Recommended";
export const RECOMMENDED_ID = "recommended";
export const RECOMMENDED_SORT = -1;

export class StaffGroup {
  constructor(
    name,
    id,
    sortNumber,
    members,
    isUsing,
    isShowAll,
    isPreSearched,
    hasMore,
    fetchMore = () => {}
  ) {
    this.name = name;
    this.id = id;
    this.sortNumber = sortNumber;
    this.members = members;
    this.isUsing = isUsing;
    this.isShowAll = isShowAll;
    this.isPreSearched = isPreSearched;
    this.hasMore = hasMore;
    this.fetchMore = fetchMore;
  }
}

// a placeholder for loading that's still null safe
const LOADING_PLACEHOLDER = {
  [RECOMMENDED_ID]: new StaffGroup(
    RECOMMENDED_NAME,
    RECOMMENDED_ID,
    RECOMMENDED_SORT,
    [],
    false,
    false,
    false,
    false
  )
};

// build a unified structure that includes the special 'Recommended' group
// and all the user-defined groups

export const buildStaffGroupsMap = (
  recommendedHook,
  userGroupsHook,
  allMembers,
  usedMemberIds
) => {
  if (recommendedHook.loading || userGroupsHook.loading) {
    return LOADING_PLACEHOLDER;
  }

  // recommended group is 'special'

  const recommendedMembers = [];
  let isUsingRecommended = false;
  for (let member of recommendedHook.members) {
    allMembers.set(member.id, member);
    if (usedMemberIds.includes(member.id)) isUsingRecommended = true;
    else recommendedMembers.push(member);
  }

  const staffGroups = {
    [RECOMMENDED_ID]: new StaffGroup(
      RECOMMENDED_NAME,
      RECOMMENDED_ID,
      RECOMMENDED_SORT,
      recommendedMembers,
      isUsingRecommended,
      false,
      true, // because search is server-side
      recommendedHook.hasMore,
      recommendedHook.fetchMore
    )
  };

  // other groups defined from pools

  for (let group of userGroupsHook.groups) {
    const id = `user-group-${group.id}`;
    const members = [];
    let isUsing = false;

    for (let { member } of group.members) {
      allMembers.set(member.id, member);
      if (member.shouldShowInGroup) {
        if (usedMemberIds.includes(member.id)) isUsing = true;
        else members.push(member);
      }
    }

    staffGroups[id] = new StaffGroup(
      group.name,
      id,
      group.sortNumber,
      members,
      isUsing,
      members.length > 0,
      false, // search is client-side
      false // user groups are loaded all-at-once
    );
  }

  return staffGroups;
};

export const staffGroupComparator = (g1, g2) => g1.sortNumber - g2.sortNumber;

export const shouldShowMember = (
  isHideConflicted,
  isHideUnqualified,
  isHideLimited,
  isHideUnavailable,
  searchText = null
) => member => {
  if (isHideConflicted && member.isConflicted) return false;
  if (isHideUnqualified && !member.isQualifiedWithRole) return false;
  if (isHideUnqualified && !member.isQualifiedWithTags) return false;
  if (isHideLimited && member.isHitWorkingHoursLimit) return false;
  if (isHideUnavailable && member.isUnavailable) return false;

  if (searchText) {
    return `${member.firstName} ${member.lastName}`
      .toLowerCase()
      .includes(searchText.toLowerCase());
  }

  return true;
};

export const getAllRequestedMembers = (
  recommendedMembers,
  isIncludeUnavailable
) => {
  return recommendedMembers.filter(
    shouldShowMember(true, true, true, !isIncludeUnavailable)
  );
};

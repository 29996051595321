import { graphql } from "@apollo/client/react/hoc";
import { gql } from "@apollo/client";
import { handleErrors } from "~/src/utils/errors";

export const GET_MEMBER_TYPE_RATINGS = gql`
  query getMemberTypeRatings {
    user {
      id
      account {
        id
        isCasualMemberTypeRatings
        isPermanentMemberTypeRatings
        isAgencyMemberTypeRatings
        isContractorLimitedCompanyMemberTypeRatings
        isContractorUmbrellaCompanyMemberTypeRatings
        isContractorSelfEmployedMemberTypeRatings
        isPayCalculationOnCheckInOutTimes
        isPinAutomateTimesheetApproval
      }
    }
  }
`;

export default graphql(GET_MEMBER_TYPE_RATINGS, {
  props: handleErrors(({ data: { loading, user } }) => ({
    loading: loading,
    account: user?.account
  }))
});

import React from "react";
import { Controller } from "react-hook-form";
import useAuth from "~/src/auth/hooks/use-auth";
import { useMemberOwners } from "../graphql/use-member-owners";

import { Field, Label, Select, StyledErrorMessage } from "./styles";

const MemberOwner = ({
  initialValues,
  control,
  inEditMode,
  errors,
  isAddMember
}) => {
  const auth = useAuth();
  const { memberOwners } = useMemberOwners();

  return (
    memberOwners?.length > 0 && (
      <Field>
        <Label>Assigned to</Label>
        <Controller
          control={control}
          name="ownerId"
          render={({ field }) => (
            <Select
              disabled={!inEditMode}
              defaultValue={isAddMember ? auth.info.id : initialValues?.ownerId}
              onChange={e => {
                field.onChange(e?.target?.value);
              }}
            >
              <option key={0} value={""}>
                None
              </option>
              {memberOwners?.map(({ firstName, lastName, id }) => (
                <option key={id} value={id}>
                  {firstName} {lastName}
                </option>
              ))}
            </Select>
          )}
        />
        <StyledErrorMessage>{errors.ownerId?.message}</StyledErrorMessage>
      </Field>
    )
  );
};

export default MemberOwner;

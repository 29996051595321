import styled from "styled-components";

export const StyledDivWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
`;

export const StyledTable = styled.table`
  width: 100%;
  border: 1px double ${({ theme }) => theme.primary.main};
  border-collapse: collapse;
  text-align: center;
  margin-bottom: 16px;
`;

export const StyledTH = styled.th`
  border: 1px solid ${({ theme }) => theme.neutral.light};
  color: ${({ theme }) => theme.text.primary};
`;

export const StyledTD = styled.td`
  border: 1px solid ${({ theme }) => theme.neutral.light};
  color: ${({ theme }) => theme.text.primary};
`;

export const StyledPBold = styled.p`
  font-weight: bold;
  color: ${({ theme }) => theme.text.primary};
`;

export const StyledPBoldOrange = styled.p`
  font-weight: bold;
  color: ${({ theme }) => theme.primary.main};
`;

export const StyledPOrange = styled.p`
  color: ${({ theme }) => theme.primary.main};
`;

export const StyledPOrangeMargin = styled.p`
  margin-left: 4px;
  color: ${({ theme }) => theme.primary.main};
`;

export const Styled_P_small = styled.p`
  font-size: 12px;
`;

export const StyledUncalculatedWrapper = styled.div`
  margin-bottom: 24px;
`;

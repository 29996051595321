import { graphql } from "@apollo/client/react/hoc";
import { gql } from "@apollo/client";
import { handleErrors } from "~/src/utils/errors";

import TagFragment from "~/src/graphql/fragments/tags/tag.fragment";

const GET_TAGS_BY_ACCOUNT = gql`
  ${TagFragment}
  query getTags {
    account {
      id
      tags {
        ...TagFragment
      }
    }
  }
`;

export default graphql(GET_TAGS_BY_ACCOUNT, {
  props: handleErrors(({ data: { account } }) => ({
    tagsByAccount: account?.tags ?? []
  }))
});

import React from "react";
import styles from "../styles";

const SortByFilter = ({ setSortBy, sortBy }) => {
  const handleSortBy = e => {
    const { name } = e.target;
    e.persist();
    setSortBy(name);
  };
  return (
    <span>
      <p style={styles.header}>SORT BY</p>
      <div style={styles.radioButtonContainer}>
        <label style={styles.label}>
          <input
            type="radio"
            name="FIRSTNAME"
            style={styles.input}
            checked={sortBy === "FIRSTNAME"}
            onChange={e => handleSortBy(e)}
          />
          <span>First Name</span>
        </label>

        <label style={styles.label}>
          <input
            type="radio"
            name="LASTNAME"
            style={styles.input}
            checked={sortBy === "LASTNAME"}
            onChange={e => handleSortBy(e)}
          />
          <span>Last Name</span>
        </label>
      </div>
    </span>
  );
};

export default SortByFilter;

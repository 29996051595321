import styled from "styled-components";
import { COLORS, STYLE_CONSTS } from "~/src/styles/config.style";

export const Styled_Percentage = styled.span`
  color: ${({ theme }) => theme.primary.main};
`;

export const Styled_Anchor = styled.a`
  color: ${({ theme }) => theme.primary.main};
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_MEDIUM};
  font-size: ${STYLE_CONSTS.FONT_SMALL};
`;

export const Styled_ProgressWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.primary.main};
`;

export const Styled_ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Styled_PercentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 35px;
`;

export const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: COLORS.MAIN_ORANGE,
  borderStyle: "dashed",
  backgroundColor: "#F5F5F5",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer"
};

export const activeStyle = {
  borderColor: "#2196f3"
};

export const acceptStyle = {
  borderColor: "#00e676"
};

export const rejectStyle = {
  borderColor: "#ff1744"
};

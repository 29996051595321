import React from "react";

import {
  PlaceholderWrapper,
  PlaceholderTitle,
  PlaceholderDescription
} from "./panel.styles";

export default ({ title, description, isSmall, noMargin }) => (
  <PlaceholderWrapper isSmall={isSmall} noMargin={noMargin}>
    <PlaceholderTitle isSmall={isSmall}>{title}</PlaceholderTitle>
    <PlaceholderDescription isSmall={isSmall}>
      {description}
    </PlaceholderDescription>
  </PlaceholderWrapper>
);

import React from "react";
import Icon from "~/src/components/icon";
import { InputWrapper, Input, StyledIcon } from "./search-input.styles";

export default function SearchInput(props) {
  return (
    <InputWrapper isInline={props.isInline} className={props.className}>
      <StyledIcon
        isSmall={props.isSmall}
        name={Icon.names.SEARCH}
        size={13}
        color={props.iconColor || Icon.colors.MAIN_ORANGE}
        isLarge={props.isLarge}
        shouldShow
      />
      <Input
        isSmall={props.isSmall}
        value={props.value}
        onChange={event =>
          props.onChangeValue
            ? props.onChangeValue(event.target.value)
            : props.onChange(event.target.value)
        }
        ref={props.onRegister}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        placeholder={props.placeholder}
        isLarge={props.isLarge}
      />
      <StyledIcon
        shouldShow={props.isLoading}
        isSmall={props.isSmall}
        name={Icon.names.LOADING}
        isRight
        size={13}
        color={Icon.colors.BLACK}
        isLarge={props.isLarge}
      />
    </InputWrapper>
  );
}

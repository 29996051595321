import styled from "styled-components";
import { STYLE_CONSTS, MEMBER_STATES_COLORS } from "~/src/styles/config.style";
import { hexToRGBA } from "~/src/styles/style-helpers";

export const PHOTO_SIZE = "100px";

export const Image = styled.div`
  position: relative;
  vertical-align: top;
  height: ${PHOTO_SIZE};
  width: ${PHOTO_SIZE};
  margin-top: ${STYLE_CONSTS.UNIT_HALF};
  margin-left: ${STYLE_CONSTS.UNIT_1};
  margin-right: ${STYLE_CONSTS.UNIT_1};
  border-radius: ${({ isRound }) => (isRound ? "50%" : "4px")};
  background-size: cover;
  background-image: ${({ src }) => (src ? `url(${src})` : null)};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  border: ${({ isActive, theme }) =>
    `4px solid ${isActive ? theme.primary.main : theme.neutral.light}`};
  text-align: center;
  background-color: ${({ isLoading, theme }) =>
    isLoading ? theme.neutral.light : "none"};
  padding-top: ${({ isLoading }) => (isLoading ? "26px" : "0")};
  overflow-wrap: break-word;
`;

export const IconBackgound = styled.a`
  height: ${STYLE_CONSTS.UNIT_4};
  width: ${STYLE_CONSTS.UNIT_4};
  margin-top: ${({ pdf }) => (pdf ? 0 : STYLE_CONSTS.UNIT_6)};
  margin-right: ${STYLE_CONSTS.UNIT_HALF};
  padding-top: ${STYLE_CONSTS.UNIT_HALF};
  border-radius: 50%;
  background-color: ${hexToRGBA(MEMBER_STATES_COLORS.LOCKED, 0.7)};
  display: inline-block;
  cursor: pointer;
  vertical-align: top;
`;

export const ClickableArea = styled.div`
  transition: ${STYLE_CONSTS.STANDARD_TRANSITON};
  height: 100%;
  width: 100%;
  opacity: 0;
  &:hover {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  display: inline-block;
  margin-top: ${STYLE_CONSTS.UNIT_HALF};
  width: ${`${parseInt(PHOTO_SIZE, 10) + parseInt(STYLE_CONSTS.UNIT_2, 10)}px`};
`;

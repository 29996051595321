import React from "react";
import { colors, icon } from "@teamrota/rota-design";
import styled from "styled-components";

const StyledWrapIcons = styled.div`
  display: flex;
  align-items: center;
`;

const Attendance = ({
  isFavourited,
  isNoShow,
  isTurnedAway,
  onToggleFavourite,
  onToggleNoShow,
  onToggleTurnedAway,
  isEditMode
}) => {
  const { FavoriteIcon, NoShowIcon, TurnedAwayIcon } = icon;
  const favouriteColor = isFavourited ? colors.rotaOrange : colors.gainsboro;
  const noShowColor = isNoShow ? colors.rotaOrange : colors.gainsboro;
  const turnedAwayColor = isTurnedAway ? colors.rotaOrange : colors.gainsboro;
  const nowShowColorLine = isNoShow ? colors.black : colors.gainsboro;
  const turnedAwayColorLine = isTurnedAway ? colors.black : colors.gainsboro;

  return (
    <>
      {isEditMode ? (
        <div>
          <StyledWrapIcons>
            <div onClick={onToggleFavourite} title="add favorite">
              <FavoriteIcon
                color={favouriteColor}
                styles={{ cursor: "pointer" }}
              />
            </div>
            <div onClick={() => onToggleNoShow()} title="no show">
              <NoShowIcon
                styles={{ margin: "5px 10px 0", cursor: "pointer" }}
                color={noShowColor}
                colorLine={nowShowColorLine}
              />
            </div>
            <div onClick={() => onToggleTurnedAway()} title="turned away">
              <TurnedAwayIcon
                styles={{ cursor: "pointer" }}
                color={turnedAwayColor}
                colorLine={turnedAwayColorLine}
              />
            </div>
          </StyledWrapIcons>
        </div>
      ) : (
        <div>
          <StyledWrapIcons>
            <FavoriteIcon color={favouriteColor} onClick={onToggleFavourite} />

            <NoShowIcon
              styles={{ margin: "5px 10px 0" }}
              color={noShowColor}
              colorLine={nowShowColorLine}
            />

            <TurnedAwayIcon
              color={turnedAwayColor}
              colorLine={turnedAwayColorLine}
            />
          </StyledWrapIcons>
        </div>
      )}
    </>
  );
};

export default Attendance;

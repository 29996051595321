import React, { useState, useEffect } from "react";
import { set, omit, isEqual } from "lodash/fp";
import { parseError } from "~/src/utils/errors";
import EditCancel from "~/src/components/edit-cancel";
import asyncConfirm from "~/src/utils/async-confirm";
import AccountMatcher from "./account-matcher";
import { ErrorMessage } from "./matcher-config.styles";
import { getValuesToSave, getValuesFromProps } from "./utils";

const MatcherConfigForm = ({ matcherConfig, onSave, isAccount, isPartner }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [hasSaved, setHasSaved] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [matcherConfigState, setMatcherConfigState] = useState({});

  useEffect(() => {
    setMatcherConfigState({ ...getValuesFromProps(matcherConfig) });
  }, [matcherConfig]);

  const handleCancel = () => {
    setIsEditMode(false);
    setMatcherConfigState({ ...matcherConfig });
  };

  const handleBlur = prop => () => {
    if (!matcherConfigState[prop]) {
      setMatcherConfigState(set(matcherConfig, 100));
    }
  };

  const handleChange = prop => val => {
    setMatcherConfigState({
      ...matcherConfigState,
      [prop]: val
    });
  };

  const saveMatcherConfig = async () => {
    try {
      setIsSaving(true);
      setHasSaved(false);
      setError(null);
      await onSave(getValuesToSave(matcherConfigState));
      setIsSaving(false);
      setHasSaved(true);
      await new Promise(res => setTimeout(res, 1000));
      setHasSaved(false);
      setIsEditMode(false);
    } catch (e) {
      setIsSaving(false);
      setError(parseError(e));
    }
  };

  const handleSave = async () => {
    const prevState = { ...getValuesFromProps(matcherConfig) };
    const newState = { ...getValuesFromProps(matcherConfigState) };

    let confirmMessage;
    if (prevState.autoAcceptThreshold != newState.autoAcceptThreshold) {
      if (
        isEqual(
          omit("autoAcceptThreshold", prevState),
          omit("autoAcceptThreshold", newState)
        )
      ) {
        confirmMessage = "This will apply to all current shifts";
      } else {
        confirmMessage =
          "Auto-accept changes will apply to all current shifts. Other changes will only apply to future shifts and current shifts will remain unchanged";
      }
    } else {
      confirmMessage =
        "Changes will only apply to future shifts posted, all current shifts will remain unchanged";
    }

    if (
      await asyncConfirm(confirmMessage, {
        shouldHideSubText: true,
        confirmButtonText: "Save"
      })
    ) {
      await saveMatcherConfig();
    }
  };

  const formProps = {
    onChange: handleChange,
    isEditMode: isEditMode && isAccount,
    onBlur: handleBlur,
    isAccount: isAccount,
    ...getValuesFromProps(matcherConfigState)
  };

  return (
    <EditCancel
      inEditMode={isEditMode}
      onEdit={() => setIsEditMode(true)}
      onCancel={handleCancel}
      onSave={handleSave}
      isSaving={isSaving}
      hasSaved={hasSaved}
      hasEditBtn={true}
      isPartner={isPartner}
    >
      {error && <ErrorMessage>{error}</ErrorMessage>}

      <AccountMatcher {...formProps} />
    </EditCancel>
  );
};

export default MatcherConfigForm;

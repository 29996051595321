import moment from "moment-timezone";

import { ROTA_ACCOUNT_ID, ROTA_ACCOUNT } from "~/src/consts";
import {
  NO_SURGE_LIST,
  HALF_LIST,
  TWO_SIX,
  FIRST_JAN,
  THIRTY_FIRST
} from "./partner-lists";

export const DEFAULT_SURGE_RATE = 1; // Should be int cast anyway
const DECEMBER = 11;
const JANUARY = 0;
export const XMAS_RATE = 1;
const HALF_XMAS_RATE = 1;

const surgeRate = ({
  startTime,
  targetAccountId,
  sourceAccountId /* bookingId */
}) => {
  try {
    if (
      !targetAccountId ||
      !startTime ||
      parseInt(targetAccountId, 10) !== ROTA_ACCOUNT_ID ||
      NO_SURGE_LIST.includes(sourceAccountId)
    )
      return DEFAULT_SURGE_RATE;

    const momentStart = moment(startTime);
    const month = momentStart.month();
    const dayOfMonth = momentStart.date();

    const simpleSurgeCheck =
      (month === DECEMBER && [24, 25, 26, 31].includes(dayOfMonth)) ||
      (month === JANUARY && dayOfMonth === 1);

    if (simpleSurgeCheck) {
      const twoSixCheck =
        TWO_SIX.includes(sourceAccountId) &&
        month === DECEMBER &&
        26 === dayOfMonth;

      const firstJanCheck =
        FIRST_JAN.includes(sourceAccountId) &&
        month === JANUARY &&
        dayOfMonth === 1;

      const thirthyFirstCheck =
        THIRTY_FIRST.includes(sourceAccountId) &&
        month === DECEMBER &&
        dayOfMonth === 31;

      if (
        HALF_LIST.includes(sourceAccountId) ||
        twoSixCheck ||
        firstJanCheck ||
        thirthyFirstCheck
      )
        return HALF_XMAS_RATE;

      return XMAS_RATE;
    }
  } catch (e) {
    return DEFAULT_SURGE_RATE;
  }

  return DEFAULT_SURGE_RATE;
};

export const isSurged = ({
  startTime,
  targetAccountId,
  sourceAccountId,
  surgeFactor
}) =>
  surgeRate({ startTime, targetAccountId, sourceAccountId }) !==
    DEFAULT_SURGE_RATE ||
  (surgeFactor && surgeFactor !== DEFAULT_SURGE_RATE);

export function SurgeString(args) {
  if (!isSurged(args)) return "";

  let festiveMessage = "🎄";
  if (args.withDescription || args.fullDescription)
    festiveMessage += " festive rates";
  if (args.fullDescription) festiveMessage += " applied";

  return festiveMessage;
}

export const surgeRoleOptions = ({
  startTime,
  targetAccountId,
  sourceAccountId,
  roleOptions,
  assignedMemberRoleIds = [],
  isDayRate = null
}) => {
  const roleOptionIndex = isSurged({
    startTime,
    targetAccountId,
    sourceAccountId
  })
    ? 1
    : 0;

  const updatedRoleOptions = {
    ...roleOptions
  };

  Object.keys(updatedRoleOptions).forEach(key => {
    const idx = key === ROTA_ACCOUNT ? roleOptionIndex : 0;

    // filter returned role rates based on current member's assigned roles
    if (assignedMemberRoleIds.length > 0) {
      updatedRoleOptions[key] = updatedRoleOptions[key].filter(roleOption =>
        assignedMemberRoleIds.find(memberRole => {
          const roleIdCheck = memberRole.roleId === roleOption.roleId;

          if (isDayRate) {
            return roleIdCheck && memberRole.isDayRate === isDayRate;
          }

          return roleIdCheck;
        })
      );
    }

    updatedRoleOptions[key] = updatedRoleOptions[key].map(role => ({
      ...role,
      label:
        role?.label +
        (role?.labelPrice[idx] ? ` - ${role?.labelPrice[idx]}` : ""),
      chargeRate: role?.chargeRate[idx]
    }));
  });

  return updatedRoleOptions;
};

export default surgeRate;

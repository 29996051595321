import React, { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getOr } from "lodash/fp";
import { RotaButtonNew } from "@teamrota/rota-design";

import Modal from "~/src/components/modal";
import { errorModal } from "~/src/utils/errors";
import * as reducerActions from "../../../reducer";
import BonusRewardComponent from "~/src/components/bonus-reward/";
import {
  StyledContent,
  ButtonWrapper,
  Title,
  Divider,
  BonusWrapper
} from "./reward-modal.styles";
import { UPDATE_BONUS } from "./graphql/update-bonus";
import { DELETE_BONUS } from "~/src/graphql/mutations/bonus-rewards/delete-bonus-reward";

const RewardsModal = ({
  isOpen,
  shiftId,
  existingRewards,
  rewards,
  addExistingRewards,
  updateBonusId,
  toggleRewardSaving,
  clearDeletedRewards,
  onClose,
  addNewReward,
  removeReward,
  updateRewardType,
  updateRewardAmount,
  updateRewardReason,
  isRewardSaving
}) => {
  const [createOrUpdateBonusReward] = useMutation(UPDATE_BONUS);
  const [deleteBonusReward] = useMutation(DELETE_BONUS);

  useEffect(() => {
    if (isOpen) {
      if (existingRewards.length > 0 && !rewards[shiftId]) {
        const mappedRewards = {};
        const addToMappedRewards = item => {
          mappedRewards[item.id] = {
            type: item.type,
            amount: item.amount,
            reason: item.reason,
            modified: false
          };
        };
        existingRewards.forEach(addToMappedRewards);
        addExistingRewards({ shiftId, mappedRewards });
      }
    }
  }, [isOpen]);

  const deleteReward = async (shiftId, id) => {
    await deleteBonusReward({ variables: { shiftId, id } });
  };

  const handleCreateOrUpdateReward = async (rewardId, reward, shiftId) => {
    const reqFields = ["type", "amount", "reason"];

    const missingField = reqFields.find(field => !reward[field]);
    if (missingField) {
      throw new Error(`Please specify ${missingField} of reward to save.`);
    }

    const currentKeys = Object.keys(rewards[shiftId].all);

    const data = await createOrUpdateBonusReward({
      variables: {
        shiftId,
        id: rewardId.startsWith("TEMP") ? null : rewardId,
        amount: reward.amount,
        period: reward.type,
        reason: reward.reason,
        type: "reward"
      }
    });

    const newId = data.data.updateBonus.bonuses.filter(
      item =>
        item.type === "reward" &&
        !item.deletedAt &&
        !currentKeys.includes(item.id)
    );

    if (newId.length !== 1)
      throw new Error("Error selecting bonus id from API");

    await updateBonusId({
      rewardId,
      id: newId[0].id,
      shiftId,
      type: reward.type,
      amount: reward.amount,
      reason: reward.reason
    });
  };

  const saveRewards = async shiftId => {
    toggleRewardSaving();
    const shiftRewards = rewards[shiftId];
    try {
      const createKeys = Object.keys(shiftRewards.all).filter(
        rewardId =>
          (rewardId.startsWith("TEMP") ||
            shiftRewards.all[rewardId].modified) &&
          !getOr([], "deletedRewardIds", shiftRewards).includes(rewardId)
      );
      for (let i = 0; i < createKeys.length; i += 1) {
        const rewardId = createKeys[i];

        // eslint-disable-next-line
        await handleCreateOrUpdateReward(
          rewardId,
          shiftRewards.all[rewardId],
          shiftId
        );
      }

      const deleteKeys = getOr([], "deletedRewardIds", shiftRewards).filter(
        deletedId => !deletedId.startsWith("TEMP")
      );
      for (let i = 0; i < deleteKeys.length; i += 1) {
        // eslint-disable-next-line
        await deleteReward(shiftId, deleteKeys[i]);
      }

      clearDeletedRewards({ shiftId });

      onClose();
    } catch (e) {
      console.log("There was an error saving the rewards: ", e);
      errorModal(e.message);
    } finally {
      toggleRewardSaving();
    }
  };

  const shiftRewards = [];

  if (isOpen) {
    const propRewards = rewards[shiftId];

    const rewardKeys = Object.keys(getOr([], "all", propRewards));
    for (let i = 0; i < rewardKeys.length; i += 1) {
      const key = rewardKeys[i];

      if (!getOr([], "deletedRewardIds", propRewards).includes(key)) {
        shiftRewards.push({
          id: key,
          amount: propRewards.all[key].amount,
          type: propRewards.all[key].type,
          reason: propRewards.all[key].reason
        });
      }
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <StyledContent>
        <Divider>
          <Title>
            {`${
              shiftRewards.length > 0
                ? "Add or edit rewards"
                : "Add rewards to the shift"
            }`}
          </Title>
        </Divider>
        <BonusWrapper>
          <BonusRewardComponent
            bonuses={shiftRewards || []}
            isReward
            onAddBonus={() => addNewReward(shiftId)}
            onDeleteBonus={(index, rewardId) => {
              removeReward({ shiftId, rewardId });
            }}
            onUpdateBonusType={(index, type, rewardId) =>
              updateRewardType({ index, type, shiftId, rewardId })
            }
            onUpdateBonusAmount={(index, amount, rewardId) =>
              updateRewardAmount({
                index,
                amount,
                shiftId,
                rewardId
              })
            }
            onUpdateBonusReason={(index, reason, rewardId) =>
              updateRewardReason({
                index,
                reason,
                shiftId,
                rewardId
              })
            }
          />
        </BonusWrapper>
        <ButtonWrapper>
          <RotaButtonNew
            isLoading={isRewardSaving}
            onClick={() => saveRewards(shiftId)}
          >
            Save
          </RotaButtonNew>
        </ButtonWrapper>
      </StyledContent>
    </Modal>
  );
};

const mapStateToProps = state => ({
  rewards: state.provide.rewards,
  deletedRewardIds: state.provide.deletedRewardIds,
  isRewardSaving: state.provide.isRewardSaving
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addNewReward: reducerActions.addNewReward,
      removeReward: reducerActions.removeReward,
      updateRewardAmount: reducerActions.updateRewardAmount,
      updateRewardType: reducerActions.updateRewardType,
      updateRewardReason: reducerActions.updateRewardReason,
      addExistingRewards: reducerActions.addExistingRewards,
      clearRewardsState: reducerActions.clearRewardsState,
      updateBonusId: reducerActions.updateBonusId,
      clearDeletedRewards: reducerActions.clearDeletedRewards,
      toggleRewardSaving: reducerActions.toggleRewardSaving
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RewardsModal);

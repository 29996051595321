import React, { useState, useEffect } from "react";
import {
  RotaButton,
  RotaButtonIcon,
  RotaModal,
  RotaDivider,
  RotaTab
} from "@teamrota/rota-design";
import DropzoneFileUpload from "~/src/containers/BusyMembersFiles/DropZoneFileUpload";
import uploadFile from "~/src/services/upload-file";
import { ROLE_TABS } from "~/src/consts";
import {
  StyledUploadButtonWrapper,
  StyledRedParagraph,
  StyledFileRow
} from "../../styles";

import {
  RoleTabs,
  RoleTabsTitle,
  HeadingWrapper,
  HeadingText,
  DownloadButtonWrapper,
  DownloadButtonText,
  AddedFiles
} from "./styles";
import { useCurrency } from "~/src/containers/profile-loader";
import { getCurrencySubUnit } from "~/src/utils/formatting";

const ImportModal = ({ id, onClose, initialSelectedTab, refetch }) => {
  const [selectedTab, setSelectedTab] = useState(initialSelectedTab);
  const [isLoading, setIsLoading] = useState(false);
  const [isSizeOrFileTypeError, setIsSizeOrFileTypeError] = useState(false);
  const [file, setFile] = useState(null);

  const { currencyCode, currencySymbol } = useCurrency();

  const handleUpload = async ({ file, type }) => {
    setIsLoading(true);
    try {
      await uploadFile({
        file,
        type,
        id,
        fileName: file.name
      });
      refetch();
      onClose();
    } catch (error) {
      setIsSizeOrFileTypeError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setFile(null);
  }, [selectedTab]);

  return (
    <RotaModal name={"Import roles"} onClose={onClose}>
      <RoleTabs>
        <RoleTabsTitle>Choose a type of role:</RoleTabsTitle>
        {ROLE_TABS.map(tab => (
          <RotaTab
            key={tab}
            label={tab}
            onClick={() => setSelectedTab(tab)}
            size="md"
            isActive={selectedTab === tab}
          />
        ))}
      </RoleTabs>
      <HeadingWrapper>
        <HeadingText>
          <p>
            Please upload an Excel (.xlsx) file. Take a look at the example{" "}
            <a href={`/docs/${selectedTab}RolesUpload.xlsx`}>here</a> to see how
            it should be formatted.
          </p>
          {selectedTab === "Calculated" ? (
            <>
              <StyledRedParagraph>
                {`NOTE: Pay and Charge rates are defined in ${currencyCode} ${getCurrencySubUnit(
                  currencyCode
                )}, e.g. 100 =
                ${currencySymbol}1.`}
              </StyledRedParagraph>
              <p>
                A new role will be created from each row in the file, once
                Upload is pressed. Files listed below in "Added file" are ready
                for upload, but no roles have been created from them yet.
              </p>
            </>
          ) : (
            <span>
              Roles can be created or updated based on the contents of the file.
            </span>
          )}
        </HeadingText>
        <DownloadButtonWrapper>
          <RotaButton
            variant="outlined"
            size="large"
            onClick={() =>
              window.open(
                `/docs/${selectedTab}RolesUpload.xlsx`,
                "_self",
                "noopener,noreferrer"
              )
            }
          >
            Download template
          </RotaButton>
          <DownloadButtonText>
            *This is a template for <span>{selectedTab}</span> roles only.
          </DownloadButtonText>
        </DownloadButtonWrapper>
      </HeadingWrapper>
      <DropzoneFileUpload
        setFile={setFile}
        setIsSizeOrFileTypeError={setIsSizeOrFileTypeError}
      />
      <p>Max 25 MB can be uploaded.</p>
      {isSizeOrFileTypeError && (
        <StyledRedParagraph>
          File maximum exceeded or wrong file type. Please upload a new
          document.
        </StyledRedParagraph>
      )}
      <RotaDivider margin={32} />
      <AddedFiles>Added file{file ? "" : "s"}</AddedFiles>
      {!file && <p>No files uploaded.</p>}
      {!!file && (
        <StyledFileRow>
          <span>{file?.path}</span>
          <RotaButtonIcon
            icon="delete"
            style={{ marginLeft: 8 }}
            onClick={() => setFile(null)}
          />
        </StyledFileRow>
      )}
      <StyledUploadButtonWrapper>
        <RotaButton
          isLoading={isLoading}
          onClick={() =>
            handleUpload({
              file,
              type:
                selectedTab === "Calculated"
                  ? "MASS_ROLE_RATES_UPLOAD"
                  : "UNCALCULATED_MASS_ROLE_RATES_UPLOAD"
            })
          }
        >
          Upload
        </RotaButton>
      </StyledUploadButtonWrapper>
    </RotaModal>
  );
};

export default ImportModal;

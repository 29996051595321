import styled from "styled-components";
import Icon from "~/src/components/icon";
import { STYLE_CONSTS } from "~/src/styles/config.style";

const getBackgroundColor = props =>
  props.isOutline
    ? "transparent"
    : props.color || STYLE_CONSTS.PRIMARY_GRADIENT;

const getBorder = props =>
  props.isOutline && !props.isBorderless ? `1px solid ${props.color}` : "none";

export const Wrapper = styled.div`
  transition: ${STYLE_CONSTS.STANDARD_TRANSITON};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${getBackgroundColor};
  border: ${getBorder};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : "50%")};
  width: ${props => props.size};
  min-width: ${props => props.size};
  height: ${props => props.size};
  min-height: ${props => props.size};
  position: relative;
  cursor: ${({ noPointer }) => (noPointer ? "unset" : "pointer")};
  ${({ noEffectOnHover }) =>
    !noEffectOnHover &&
    `&:hover {
    transform: scale(1.1);
  }`};
`;

export const StyledIcon = styled(Icon).attrs(
  ({ isOutline, iconColor, theme }) => ({
    color: isOutline ? iconColor : theme.white
  })
)``;

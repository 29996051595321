import React from "react";
import { RotaStats } from "@teamrota/rota-design";

import { StatsEnum } from "./use-timesheets-with-stats";

interface Props {
  stats: { label: StatsEnum; value: string }[];
  loading?: boolean;
}

export const Stats = ({ stats }: Props) => {
  return <RotaStats stats={stats} />;
};

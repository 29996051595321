export const NO_SURGE_LIST = [
  518,
  769,
  1329,
  433,
  486,
  427,
  463,
  534,
  552,
  570,
  610,
  619,
  620,
  627,
  630,
  638,
  660,
  730,
  807,
  965,
  1013,
  1083,
  1095,
  1109,
  1154,
  1241,
  1153,
  326,
  335,
  341,
  347,
  359,
  365,
  917,
  601,
  376,
  380,
  383,
  386,
  395,
  560,
  628,
  408,
  410,
  411,
  419,
  423,
  481,
  482,
  565,
  573,
  567,
  522,
  502,
  505,
  557,
  221,
  424,
  425,
  487,
  519,
  591,
  592,
  420,
  421,
  422,
  639,
  559,
  1284,
  864
];

export const HALF_LIST = [234, 1048, 1077];
export const TWO_SIX = [320, 750];
export const FIRST_JAN = [682, 750];
export const THIRTY_FIRST = [750];

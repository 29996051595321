import React, { useState } from "react";
import { RotaButton, RotaCheckbox } from "@teamrota/rota-design";
import { get } from "lodash/fp";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Role } from "@teamrota/authlib";

import store from "~/src/store";
import HasRole from "~/src/containers/has-role";
import PageHeader from "~/src/components/page-header";
import { formatDateMonthYear } from "~/src/utils/formatting";
import {
  FloatingTable,
  ColumnHeading,
  TableBody,
  TableData,
  TableRow
} from "~/src/components/floating-table";
import Icon from "~/src/components/icon";
import FiltersDateRange from "~/src/components/form-components/filters-date-range";
import {
  REPORT_STATUS,
  REPORT_REQUEST_TYPES,
  ROTA_ACCOUNT
} from "~/src/consts";
import { stringToDate } from "~/src/utils/validators";
import { errorModal } from "~/src/utils/errors";
import TablePlaceholder from "~/src/components/table-placeholder";
import NoResults from "~/src/components/no-results";
import getReports from "./graphql/get-reports";
import { generateReport } from "~/src/services/reports";
import { Download, FlexContainer, FlexContainerEnd } from "./index.styles";
import {
  onUpdateSummaryCheckbox,
  onSetRangeStart,
  onSetRangeEnd
} from "./reducer";

const getLink = report => {
  switch (report.status) {
    case REPORT_STATUS.CREATED:
    case REPORT_STATUS.GENERATING:
      return (
        <RotaButton isLoading variant={"outlined"}>
          Generating
        </RotaButton>
      );

    case REPORT_STATUS.COMPLETE:
      return (
        <a href={report.url}>
          <RotaButton>Download</RotaButton>
        </a>
      );

    case REPORT_STATUS.NO_DATA:
      return <em>No data! Please try a different date range</em>;

    case REPORT_STATUS.ERROR:
    default:
      return <>Error</>;
  }
};

const isRotaProvide = storeObj => {
  return get("auth.user.account.accountName", storeObj) === ROTA_ACCOUNT;
};

const Reports = ({
  reports,
  rangeStart,
  rangeEnd,
  isLoading,
  isSummaryChecked,
  onSetRangeStart,
  onSetRangeEnd,
  refetchReports,
  onUpdateSummaryCheckbox
}) => {
  const [isRequesting, setIsRequesting] = useState(false);

  const handleRangeStartChange = rangeStart => {
    onSetRangeStart(rangeStart);
  };

  const handleRangeEndChange = rangeEnd => {
    onSetRangeEnd(rangeEnd);
  };

  const handleRequestReport = async () => {
    try {
      setIsRequesting(true);
      await generateReport(
        REPORT_REQUEST_TYPES.PAYROLL,
        {
          rangeStart: stringToDate(rangeStart),
          rangeEnd: stringToDate(rangeEnd),
          includeSummarySheet: isSummaryChecked
        },
        false
      );
      await refetchReports();
    } catch (e) {
      errorModal(e);
    } finally {
      setIsRequesting(false);
    }
  };

  const isRotaProvideAccount = isRotaProvide(store.getState());

  const reportsArr = reports ?? [];
  const isNoResults = reports.length === 0 && !isLoading;
  const hasIncomplete = !!reports.find(
    r =>
      r.status == REPORT_STATUS.CREATED || r.status == REPORT_STATUS.GENERATING
  );

  if (hasIncomplete) {
    setTimeout(() => refetchReports(), 1000);
  }

  return (
    <div>
      <PageHeader title="Reports" subtext="Download your report here.">
        <HasRole role={Role.REPORTS_PAYROLL_CREATE}>
          <FlexContainerEnd>
            <FiltersDateRange
              isInline
              startTime={rangeStart}
              endTime={rangeEnd}
              onChangeStartTime={handleRangeStartChange}
              onChangeEndTime={handleRangeEndChange}
            />
            {isRotaProvideAccount && (
              <RotaCheckbox
                isChecked={isSummaryChecked}
                onClick={onUpdateSummaryCheckbox}
                label="Include Summary sheet"
              />
            )}
            <RotaButton
              variant="outlined"
              isSuccess={isRequesting}
              onClick={handleRequestReport}
              style={{ marginLeft: "22px", marginRight: "22px" }}
            >
              Request
            </RotaButton>
          </FlexContainerEnd>
        </HasRole>
      </PageHeader>
      <FloatingTable>
        <div>
          <ColumnHeading width={3 / 12}>Range Start</ColumnHeading>
          <ColumnHeading width={3 / 12}>Range End</ColumnHeading>
          <ColumnHeading width={3 / 12}>Created At</ColumnHeading>
          <ColumnHeading width={3 / 12}>Download Link</ColumnHeading>
        </div>
        <TableBody>
          {isNoResults && <NoResults />}
          {!isNoResults && (
            <div>
              {isLoading && (
                <TablePlaceholder
                  columnWidths={[3 / 12, 3 / 12, 3 / 12, 3 / 12]}
                />
              )}
              {reportsArr.map((report, index) => (
                <TableRow key={index}>
                  <TableData width={3 / 12}>
                    <FlexContainer>
                      <Download>
                        <Icon
                          name={Icon.names.REPORT}
                          color={Icon.colors.COOL_GREY}
                        />
                      </Download>
                      {formatDateMonthYear(report?.rangeStart)}
                    </FlexContainer>
                  </TableData>
                  <TableData width={3 / 12}>
                    {formatDateMonthYear(report?.rangeEnd)}
                  </TableData>
                  <TableData width={3 / 12}>
                    {formatDateMonthYear(report?.createdAt)}
                  </TableData>
                  <TableData width={3 / 12}>{getLink(report)}</TableData>
                </TableRow>
              ))}
            </div>
          )}
        </TableBody>
      </FloatingTable>
    </div>
  );
};

const mapStateToProps = state => ({
  rangeStart: state.reports.rangeStart,
  rangeEnd: state.reports.rangeEnd,
  isSummaryChecked: state.reports.isIncludingSummarySheet
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onUpdateSummaryCheckbox,
      onSetRangeStart,
      onSetRangeEnd
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(getReports(Reports));

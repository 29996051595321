import styled from "styled-components";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";

export const Main = styled.div`
  overflow: hidden;
  border-bottom: ${STYLE_CONSTS.SMALL_DIVIDER_BORDER_LIGHT};
`;

export const Count = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 100%;
  font-size: 12px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  margin-right: ${STYLE_CONSTS.UNIT_3};
  border: 2px solid ${COLORS.CLOUDY_BLUE};
  box-sizing: border-box;
  ${({ isOpen, theme }) =>
    isOpen &&
    `
      font-weight: bold;
      color: ${theme.white};
      background: ${theme.primary.main};
      border-color: ${theme.primary.main};
    `}
`;

export const Content = styled.div`
  padding: ${STYLE_CONSTS.UNIT_3};
  border-top: ${STYLE_CONSTS.SMALL_DIVIDER_BORDER_LIGHT};
  ${props => !props.isOpen && "display: none;"}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: ${STYLE_CONSTS.UNIT_2};
  flex-direction: row;
  justify-content: flex-start;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

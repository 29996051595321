import React, { useEffect, useState } from "react";
import axios from "axios";

import { RotaButtonNew, RotaCheckbox, iconNew } from "@teamrota/rota-design";

import { QR_CODE_URL } from "~/src/config";
import { getCurrentAccessToken } from "~/src/auth/manager";
import { errorModal } from "~/src/utils/errors";

import createOrUpdateVenue from "../graphql/create-update-venue-templates";
import getProfile from "~/src/graphql/queries/get-profile/get-profile-query.decorator";
import styles, { TableDiv, Th, SaveButtonWrapper } from "./check-in-styles";

const { Download } = iconNew;

const CheckInLocationForm = ({ user, createOrUpdateVenue, setIsModalOpen }) => {
  const { venues } = user?.account;

  const [isStateLoading, setIsStateLoading] = useState(false);
  const [shouldShowSavedState, setShouldShowSavedState] = useState(false);
  const [updatedVenues, setUpdatedVenues] = useState();

  useEffect(() => {
    setUpdatedVenues(venues);
  }, []);

  const saveVenue = async event => {
    event.preventDefault();
    setIsStateLoading(true);

    try {
      updatedVenues.forEach(async venue => {
        await createOrUpdateVenue(venue);
      });

      setIsStateLoading(false);
      setShouldShowSavedState(true);
      setTimeout(() => {
        setIsModalOpen(false);
      }, 1500);
    } catch (e) {
      setIsStateLoading(false);
      errorModal(e);
    }
  };

  const handlePinCodeChecked = item => {
    setUpdatedVenues(
      updatedVenues?.map(venue => {
        if (venue?.id === item.id) {
          const newVenue = { ...venue };
          newVenue.isPinCodeCheckInEnabled = !item.isPinCodeCheckInEnabled;
          return newVenue;
        }
        return venue;
      })
    );
    setShouldShowSavedState(false);
  };

  const handleQrCodeChecked = item => {
    setUpdatedVenues(
      updatedVenues?.map(venue => {
        if (venue?.id === item?.id) {
          return {
            ...venue,
            isQrCodeCheckInEnabled: !item.isQrCodeCheckInEnabled
          };
        }
        return venue;
      })
    );
    setShouldShowSavedState(false);
  };

  const clearAllCheckBoxHandler = async event => {
    event.preventDefault();

    setUpdatedVenues(
      updatedVenues?.map(venue => {
        const newVenue = { ...venue };
        newVenue.isQrCodeCheckInEnabled = false;
        newVenue.isPinCodeCheckInEnabled = false;
        return newVenue;
      })
    );
  };

  const qrCodeDownload = async (venueId, venueName) => {
    axios
      .post(
        QR_CODE_URL,
        { venueId },
        {
          headers: {
            Authorization: `Bearer ${await getCurrentAccessToken()}`
          },
          responseType: "arraybuffer"
        }
      )
      .catch(error => {
        if (error.response)
          console.log(
            "There was a problem with the server response:",
            console.log(error.response)
          );
        else if (error.request)
          console.log(
            "There was a problem with the request to the server:",
            console.log(error.request)
          );
        else console.log("Error:", console.log(error));
      })
      .then(res => {
        if (res.data) {
          const blob = new Blob([res.data], { type: "application/pdf" });
          const objectUrl = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = objectUrl;
          a.download = `${venueName} QR.pdf`;
          a.click();
        }
      });
  };

  return (
    <div style={styles.formWrapper}>
      <div style={styles.tableWrapper}>
        <table style={styles.tg}>
          <thead>
            <tr>
              <Th textAlign="left">VENUES</Th>
              <Th>PIN</Th>
              <Th>QR CODE</Th>
              <Th>DOWNLOAD</Th>
            </tr>
          </thead>
          <tbody>
            {updatedVenues?.map((item, idx) => {
              return (
                <tr key={`${item?.name} + ${idx}`}>
                  <TableDiv>{item?.name}</TableDiv>
                  <td style={styles.pinCheckBox}>
                    <RotaCheckbox
                      isChecked={item?.isPinCodeCheckInEnabled}
                      onClick={() => handlePinCodeChecked(item)}
                    />
                  </td>
                  <td style={styles.qrCheckBox}>
                    <RotaCheckbox
                      isChecked={item?.isQrCodeCheckInEnabled}
                      onClick={() => handleQrCodeChecked(item)}
                    />
                  </td>
                  <td style={styles.tableDiv}>
                    <RotaButtonNew
                      variant="outlined"
                      isDisabled={!item?.isQrCodeCheckInEnabled}
                      iconLeft={<Download />}
                      onClick={() => qrCodeDownload(item?.id, item?.name)}
                    >
                      QR Code
                    </RotaButtonNew>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <RotaButtonNew
        variant="text"
        onClick={event => clearAllCheckBoxHandler(event)}
      >
        Clear All
      </RotaButtonNew>
      <SaveButtonWrapper>
        <RotaButtonNew
          onClick={saveVenue}
          isSuccess={shouldShowSavedState}
          isLoading={isStateLoading}
        >
          {shouldShowSavedState ? "Saved" : "Save"}
        </RotaButtonNew>
      </SaveButtonWrapper>
    </div>
  );
};

export default getProfile(createOrUpdateVenue(CheckInLocationForm));

import { createGlobalStyle } from "styled-components";
import { STYLE_CONSTS } from "./config.style";

const GlobalStyle = createGlobalStyle`
  body, html, #root, #app {
    height: 100vh;
    margin: 0;
  }

  #hidden-elements {
    display: none;
  }

  body {
    font-family: 'Gotham A', 'Gotham B', 'Open Sans', sans-serif;
    font-style: normal;
    font-size: ${STYLE_CONSTS.FONT};
    font-weight: ${STYLE_CONSTS.FONT_WEIGHT};
  }

  button, input, select, textarea {
    font-family: 'Gotham A', 'Gotham B', 'Open Sans', sans-serif;
  }

  a:link, a:active, a:hover, a:visited {
    color: ${({ theme }) => theme.primary.main};
    cursor: pointer;
  }

  * {
    box-sizing: border-box;
  }

  .tippy-popper {
    top: 192px !important;
    left: 163px !important;
    transform: none !important;
    background-color: white !important;
  }

  p {
    margin: ${STYLE_CONSTS.UNIT_1} 0;
  }
  .ReactModal__Overlay {
    transition: all 500ms ease-in-out;
    opacity: 0;
  
    &--after-open {
      opacity: 1;
    }
  
    &--before-close {
      opacity: 0;
    }
  }
  
  .ReactModal__Content {
    transition: all 500ms ease-in-out;
    opacity: 0;
  
    &--after-open {
      opacity: 1;
    }
  
    &--before-close {
      opacity: 0;
    }
  }
  
  .leaflet-div-icon {
    border: none;
    background: none;
  }

  .sr-only {
    display: none;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
`;

export default GlobalStyle;

import { gql } from "@apollo/client";

const GET_MEMBER_POOLS = gql`
  query getMemberPools(
    $offset: Int!
    $limit: Int!
    $searchText: String!
    $poolType: poolTypeEnumType!
    $targetAccountId: ID
    $sourceAccountId: ID
  ) {
    account {
      id
      accountName
      memberPools(
        offset: $offset
        limit: $limit
        searchText: $searchText
        poolTypes: [$poolType]
        targetAccountId: $targetAccountId
      ) {
        totalResults
        data {
          id
          firstName
          lastName
          rating
          photo
          bookingsCount(sourceAccountId: $sourceAccountId) {
            upcomingAccepted
          }
          relationship {
            id
            rating
          }
          roles {
            id: roleId
            name
          }
        }
      }
    }
  }
`;

export default GET_MEMBER_POOLS;

import React, { useState } from "react";
import Money from "~/src/utils/money";
import Big from "big.js";

import { Input, Wrapper, ErrorText } from "./index.styles";
import { useCurrency } from "~/src/containers/profile-loader";

const CurrencyInput = ({
  input,
  meta,
  isSingleLine,
  shouldNotAllowNegative,
  isAlignCenter,
  placeholder,
  isSmall,
  hasNoErrorText,
  readOnly,
  memberType
}) => {
  const { currencySymbol } = useCurrency();
  const initialInputValue = input?.value || 0;
  const [inputValue, setInputValue] = useState(initialInputValue);
  const [errorText, setErrorText] = useState("");

  const metaErrorText = meta?.submitFailed && meta?.error ? meta.error : "";
  const error = errorText || metaErrorText;

  const onFocus = () => {
    setInputValue(parseInt(initialInputValue));
  };

  const onBlur = () => {
    setInputValue(parseInt(initialInputValue));
  };

  const onChange = event => {
    if (shouldNotAllowNegative) {
      setErrorText("");
    }

    const rawValue = event?.target?.rawValue?.replace(currencySymbol, "");
    let value;
    try {
      value = rawValue
        ? Big(rawValue)
            .times(100)
            .toNumber()
        : null;
    } catch (err) {
      value = 0;
    }

    if (shouldNotAllowNegative && value !== null && value < 0) {
      setErrorText("Can't be negative");
      value = 0;
    }

    input?.onChange(value);
  };

  const options = {
    prefix: currencySymbol,
    numeral: true,
    numeralDecimalScale: 2
  };

  return (
    <Wrapper isError={!!error} isSingleLine={!!isSingleLine}>
      {!!memberType && <p>Annual salary</p>}
      <Input
        min={shouldNotAllowNegative ? 0 : null}
        isAlignCenter={isAlignCenter}
        options={options}
        value={Money({ amount: inputValue || 0 }).toFormat()}
        placeholder={placeholder}
        disabled={readOnly}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        isfontsmall={isSmall}
      />
      {!hasNoErrorText && error && <ErrorText>{error}</ErrorText>}
    </Wrapper>
  );
};

export default CurrencyInput;

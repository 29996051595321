import React from "react";
import UserInfoForm from "./user-info-form";
import MatcherForm from "./matcher-form";
import { FlexContainer, Scrollable } from "../index.styles";

const UserInfo = ({ account, matcherConfig, isPartner }) => {
  return (
    <Scrollable>
      <FlexContainer>
        <UserInfoForm account={account} isPartner={isPartner} />
      </FlexContainer>
      <FlexContainer>
        <MatcherForm
          account={account}
          matcherConfig={matcherConfig}
          isPartner={isPartner}
        />
      </FlexContainer>
    </Scrollable>
  );
};

export default UserInfo;

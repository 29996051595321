import React from "react";
import InputWrapper from "../input-wrapper";

import Ellipsis from "../../../ellipsis";
import { Input } from "./multiline-text-input.styles";

const MultilineTextInput = ({ readOnly, placeholder, input, ...props }) => {
  if (readOnly) {
    return (
      <InputWrapper {...props} isMediumText>
        <Ellipsis maxLength={70}>{input?.value}</Ellipsis>
      </InputWrapper>
    );
  }
  return (
    <InputWrapper {...props} isMediumText>
      <Input
        value={input?.value || ""}
        disabled={readOnly}
        placeholder={!readOnly ? placeholder : ""}
        onChange={event => input?.onChange(event.target.value)}
      />
    </InputWrapper>
  );
};
export default MultilineTextInput;

import { gql } from "@apollo/client";

import { addAuthVars } from "@teamrota/authlib";

export default addAuthVars(gql`
  query NotificationsTab_getProfile(
    $connectionOffset: Int!
    $connectionLimit: Int!
  ) {
    user {
      id
      email
      pin
      canRecieveAllEmails
      usersMeta {
        applicantAlerts
        hourlyAlerts
      }
      account {
        id
        isMinimumOnePartnerManagedConnection
      }
    }
  }
`);

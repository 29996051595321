const styles = {
  settingsTitle: {
    width: "100%",
    height: "75px",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    padding: "0 0 0 32px",
    margin: "0 0 32px 0"
  }
};

export default styles;

import styled from "styled-components";

export const Text = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.neutral.main};
`;

export const ButtonContainer = styled.div`
  text-align: center;
  padding: 6px 0;
`;

export const MessageContainer = styled.div`
  margin: 30px 0 20px;
`;

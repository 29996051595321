import React from "react";
import { getOr, set } from "lodash/fp";
import { parseError } from "~/src/utils/errors";
import EditCancel from "~/src/components/edit-cancel";
import asyncConfirm from "~/src/utils/async-confirm";
import ShiftPolicy from "./components/shift-policy";
import BookingPolicy from "./components/booking-policy";
import { ErrorMessage } from "./policy-form.styles";

class PolicyForm extends React.Component {
  state = {
    isEditMode: false,
    policy: this.props.policy,
    hasSaved: false,
    isSaving: false,
    error: null
  };

  handleCancel = () => {
    this.setState({
      isEditMode: false,
      policy: this.props.policy
    });
  };

  handleBlur = prop => () => {
    if (!this.state.policy[prop]) {
      this.setState(set(`policy.${prop}`, 0));
    }
  };

  handleChange = prop => e => {
    this.setState({
      policy: {
        ...this.state.policy,
        [prop]: e.target.value
      }
    });
  };

  savePolicy = async () => {
    try {
      const { policy } = this.state;
      this.setState({ isSaving: true, hasSaved: false, error: null });
      await this.props.onSave({
        minimumShiftLength: policy.minimumShiftLength,
        minimumShiftPercentage: policy.minimumShiftPercentage,
        cancellationPeriod: policy.cancellationPeriod
      });
      this.setState({ isSaving: false, hasSaved: true });
      await new Promise(res => setTimeout(res, 1000));
      this.setState({ hasSaved: false, isEditMode: false });
    } catch (e) {
      this.setState({ isSaving: false, error: parseError(e) });
    }
  };

  handleSave = async () => {
    if (this.props.confirmationText) {
      if (
        await asyncConfirm(this.props.confirmationText, {
          shouldHideSubText: true,
          confirmButtonText: "Save"
        })
      ) {
        await this.savePolicy();
      }
    } else {
      await this.savePolicy();
    }
  };

  render() {
    const { props, state } = this;
    const minimumShiftLength = getOr(0, "minimumShiftLength", state.policy);
    const minimumShiftPercentage = getOr(
      0,
      "minimumShiftPercentage",
      state.policy
    );
    const cancellationPeriod = getOr(0, "cancellationPeriod", state.policy);
    const formProps = {
      onChange: this.handleChange,
      isEditMode: state.isEditMode,
      onBlur: this.handleBlur,
      minimumShiftLength,
      minimumShiftPercentage,
      cancellationPeriod
    };

    return (
      <EditCancel
        inEditMode={state.isEditMode}
        onEdit={() => this.setState({ isEditMode: true })}
        onCancel={this.handleCancel}
        onSave={this.handleSave}
        isSaving={state.isSaving}
        hasSaved={state.hasSaved}
        hasEditBtn={true}
      >
        {state.error && <ErrorMessage>{state.error}</ErrorMessage>}

        {props.isBookingPolicy ? (
          <BookingPolicy {...formProps} />
        ) : (
          <ShiftPolicy {...formProps} />
        )}
      </EditCancel>
    );
  }
}

export default PolicyForm;

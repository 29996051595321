import { createSelector } from "reselect";
import get from "lodash/fp/get";
import find from "lodash/fp/find";
import { surgeRoleOptions } from "~/src/services/surge-pricing";
import { extractShiftTitle } from "../shared";
import * as utils from "../../utils";

export const useDayRoleOptions = createSelector(
  [
    props => props.initialPostStart,
    props => props.targetAccountId,
    props => props.dayRoleOptions,
    props => props?.shift?.assignedMemberRoleIds
  ],
  (
    initialPostStart,
    targetAccountId,
    dayRoleOptions,
    assignedMemberRoleIds
  ) => {
    return surgeRoleOptions({
      startTime: initialPostStart,
      targetAccountId,
      roleOptions: dayRoleOptions,
      assignedMemberRoleIds,
      isDayRate: true
    });
  }
);

export const useCalculatedRoleOptions = createSelector(
  [
    props => props.initialPostStart,
    props => props.targetAccountId,
    props => props.calculatedRoleOptions,
    props => props?.shift?.assignedMemberRoleIds
  ],
  (
    initialPostStart,
    targetAccountId,
    calculatedRoleOptions,
    assignedMemberRoleIds
  ) => {
    return surgeRoleOptions({
      startTime: initialPostStart,
      targetAccountId,
      roleOptions: calculatedRoleOptions,
      assignedMemberRoleIds,
      isDayRate: false
    });
  }
);

export const useUncalculatedRoleOptions = createSelector(
  [
    props => props.initialPostStart,
    props => props.targetAccountId,
    props => props.uncalculatedRoleOptions,
    props => props?.shift?.assignedMemberRoleIds
  ],
  (
    initialPostStart,
    targetAccountId,
    uncalculatedRoleOptions,
    assignedMemberRoleIds
  ) => {
    return surgeRoleOptions({
      startTime: initialPostStart,
      targetAccountId,
      roleOptions: uncalculatedRoleOptions,
      assignedMemberRoleIds,
      isDayRate: false
    });
  }
);

// Map props<venueOptions> to utls.createVenueOptions
export const useVenueOptions = createSelector(
  props => props.venueOptions,
  venueOptions => {
    return utils.createVenueOptions(venueOptions);
  }
);

// Handle roleRate select change
export const useUpdateRoleRate = createSelector(
  [
    props => props.handleShiftUpdate,
    props => props.shift,
    props => props.roleRates,
    props => props.isGridSelectedShift
  ],
  (handleShiftUpdate, shift, roleRates, isGridSelectedShift) => roleRateId => {
    const currentRoleAccountId = get("privates.roleRateAccount.id", shift);
    const roleRate = find(({ id }) => id === roleRateId, roleRates);
    const account = get("account", roleRate);
    const isAccountUpdated = currentRoleAccountId !== get("id", account);

    handleShiftUpdate({
      roleRateId,
      isUncalculatedRate: roleRate?.isUncalculatedRate,
      assignedMemberIds:
        isAccountUpdated && !isGridSelectedShift ? [] : shift.assignedMemberIds,
      requestedMemberIds:
        isAccountUpdated && !isGridSelectedShift
          ? []
          : shift.requestedMemberIds,
      privates: {
        ...shift.privates,
        roleRateAccount: account
      }
    });
  }
);

export const useUpdateMemberType = createSelector(
  [props => props.handleShiftUpdate],
  handleShiftUpdate => memberType => {
    handleShiftUpdate({
      memberType
    });
  }
);

// Handle venue select change
export const useUpdateVenue = createSelector(
  [props => props.handleShiftUpdate],
  handleShiftUpdate => venueId => {
    handleShiftUpdate({
      venueId
    });
  }
);

export const useUpdateSubvenue = createSelector(
  [props => props.handleShiftUpdate],
  handleShiftUpdate => subvenueId => {
    handleShiftUpdate({
      subvenueId
    });
  }
);

// Map props to formTitle
export const getFormTitle = createSelector(
  [props => props.shift, props => props.venueOptions, props => props.roleRates],
  extractShiftTitle
);

// Map state to props<roleRateId, venueId>
export const mapStateToProps = ({
  scheduler: {
    shiftForm: { shifts, shiftOpenIndex },
    isGridSelectedShift
  }
}) => ({
  shift: shifts[shiftOpenIndex],
  isGridSelectedShift
});

import React from "react";
import { Section, Title, Body, InlineField } from "../../policy-form.styles";

export default props => (
  <div>
    <Section>
      <Title>Posting Shifts</Title>
      <Body>
        If the shift length is less than{" "}
        <InlineField
          isEditMode={props.isEditMode}
          onChange={props.onChange("minimumShiftLength")}
          onBlur={props.onBlur("minimumShiftLength")}
          value={props.minimumShiftLength}
        />{" "}
        hours, then charge for {props.minimumShiftLength} hours.
      </Body>
    </Section>
    <Section>
      <Title>Reducing Shift Length</Title>
      <Body>
        If the length of a shift is changed to less than{" "}
        {props.minimumShiftLength} hours or to less than{" "}
        <InlineField
          isEditMode={props.isEditMode}
          onChange={props.onChange("minimumShiftPercentage")}
          onBlur={props.onBlur("minimumShiftPercentage")}
          value={props.minimumShiftPercentage}
        />
        % of the original shift length:
        <ul>
          <li>
            within {props.cancellationPeriod} hours of the start of the shift or
          </li>
          <li>changed during check in and out or</li>
          <li>during timesheet review</li>
        </ul>
        Then charge {props.minimumShiftPercentage}% of the original booking
        length or {props.minimumShiftLength} hours minimum booking length,
        whichever is greater.
      </Body>
    </Section>
    <Section>
      <Title>Cancelling Shifts</Title>
      <Body>
        If the shift is cancelled with less than{" "}
        <InlineField
          isEditMode={props.isEditMode}
          onChange={props.onChange("cancellationPeriod")}
          onBlur={props.onBlur("cancellationPeriod")}
          value={props.cancellationPeriod}
        />{" "}
        hours notice before the start, then charge{" "}
        {props.minimumShiftPercentage}% of the original shift length or{" "}
        {props.minimumShiftLength} hours minimum shift length, whichever is
        greater.
      </Body>
    </Section>
  </div>
);

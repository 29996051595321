import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

import { handleErrors } from "~/src/utils/errors";

import AccountRoleFragment from "~/src/graphql/fragments/account-role";

const GET_ROLES = gql`
  ${AccountRoleFragment}
  query getRoles {
    account {
      id
      roles {
        ...AccountRoleFragment
      }
    }
  }
`;

export default graphql(GET_ROLES, {
  props: handleErrors(({ data: { account } }) => ({
    roles: account?.roles
  }))
});

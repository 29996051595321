import React, { Component } from "react";
import * as Sentry from "@sentry/browser";

import { SENTRY_OPTS } from "~/src/config";
import ModalError from "~/src/components/modal-error";
import { withAuth } from "~/src/auth/hoc";

const reloadWindowClean = () => {
  window.history.replaceState({}, "", "/");
  window.location.reload();
};

try {
  // if configured, initialize Sentry
  if (SENTRY_OPTS) {
    Sentry.init(SENTRY_OPTS);
  }
} catch {
  // ignore any Sentry init errors
}

@withAuth
class ErrorBoundary extends Component {
  constructor() {
    super();

    this.state = {
      hasErrored: false
    };
  }

  static getDerivedStateFromError() {
    return { hasErrored: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error({ error, errorInfo });

    try {
      if (SENTRY_OPTS) {
        Sentry.withScope(scope => {
          scope.setExtras(errorInfo);

          scope.setUser({
            id: `${this?.props.auth?.info?.id}`,
            email: this?.props.auth?.info?.email
          });

          Sentry.captureException(error);
        });
      }
    } catch {
      // surpress errors from Sentry itself
    }
  }

  render() {
    const { hasErrored } = this.state;

    if (hasErrored) {
      return (
        <ModalError
          title="Error Occurred"
          buttonText="Reload Page"
          onClose={reloadWindowClean}
        >
          We're sorry but something went wrong.
        </ModalError>
      );
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;

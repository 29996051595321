import { RotaButton, RotaCheckbox, iconNew } from "@teamrota/rota-design";
import { useTheme } from "styled-components";
import React, { useState } from "react";
import {
  StyledPopupBackground,
  StyledPopupContainer,
  StyledCloseButton,
  StyledTitle,
  StyledContent,
  StyledButtonsContainer
} from "./index.styles";

const { Tick } = iconNew;

const RebookableShiftPopup = ({ onClose, onSave, roleName, partnerName }) => {
  const [isRestrictedToVenue, setIsRestrictedToVenue] = useState(false);

  const theme = useTheme();

  return (
    <StyledPopupBackground onClick={onClose}>
      <StyledPopupContainer onClick={e => e.stopPropagation()}>
        <StyledCloseButton onClick={onClose} icon={"close"} />
        <Tick color={theme.success.main} />
        <StyledTitle>Make this shift rebookable</StyledTitle>
        <StyledContent>
          Selecting this option will allow the selected member to rebook a shift
          for this role <span style={{ fontWeight: "bold" }}>({roleName})</span>{" "}
          and this partner{" "}
          <span style={{ fontWeight: "bold" }}>({partnerName})</span> at any
          venue for the partner for any date in the future.
        </StyledContent>
        <RotaCheckbox
          label={"Restricted to the selected venue only"}
          isChecked={isRestrictedToVenue}
          onClick={() => setIsRestrictedToVenue(!isRestrictedToVenue)}
        />
        <StyledButtonsContainer>
          <RotaButton onClick={() => onClose()} variant={"outlined"}>
            Cancel
          </RotaButton>
          <RotaButton onClick={() => onSave(isRestrictedToVenue)}>
            Save
          </RotaButton>
        </StyledButtonsContainer>
      </StyledPopupContainer>
    </StyledPopupBackground>
  );
};

export default RebookableShiftPopup;

import React, { useState } from "react";
import { TagsWrapper, TagItem, TagItemContent, TagName } from "./styles";
import Icon from "~/src/components/icon";

const Tag = ({ id, name, isCompulsory, handleTagPress, isSelected }) => {
  const isCheckIconVisible = isCompulsory || isSelected;
  return (
    <TagItem
      isCompulsory={isCompulsory}
      isSelected={isSelected}
      {...(!isCompulsory && {
        onClick: () => handleTagPress({ id, name, isCompulsory: false })
      })}
    >
      <TagItemContent>
        <TagName isCheckIconVisible={isCheckIconVisible}>{name}</TagName>
        {isCheckIconVisible && (
          <Icon
            name={Icon.names.CHECK}
            color={Icon.colors.WHITE}
            size={"15px"}
          />
        )}
      </TagItemContent>
    </TagItem>
  );
};

const ShiftTagsViewModal = ({
  tagsAvailableForShift,
  tagsCompulsoryForShift,
  selectedShiftTags,
  tagsAccountName,
  handleShiftUpdate
}) => {
  const [selectedModalTags, setSelectedModalTags] = useState(selectedShiftTags);

  let tagsAvailable = tagsAvailableForShift;

  tagsAvailable = tagsAvailable.filter(
    tagA => !tagsCompulsoryForShift.find(tagC => tagC.id === tagA.id)
  );

  const handleTagPress = newTag => {
    if (selectedModalTags) {
      const isTagAlreadySelected = selectedModalTags.find(
        shiftTag => shiftTag.id === newTag.id
      );

      const tags = isTagAlreadySelected
        ? selectedModalTags.filter(tag => tag.id !== newTag.id)
        : [...selectedModalTags, newTag];

      setSelectedModalTags(tags);
      handleShiftUpdate({ tags });
    }
  };

  return (
    <TagsWrapper>
      <h3 style={{ paddingBottom: 20 }}>
        Members will need to have the selected tags in order to apply for the
        shift.
      </h3>
      <p>{tagsAccountName}</p>
      {tagsCompulsoryForShift.map(({ id, name }) => (
        <Tag id={id} name={name} isCompulsory />
      ))}
      {tagsAvailable.map(({ id, name }) => (
        <Tag
          id={id}
          name={name}
          handleTagPress={handleTagPress}
          isSelected={
            selectedModalTags &&
            selectedModalTags.find(selected => selected.id === id)
          }
        />
      ))}
    </TagsWrapper>
  );
};

export default ShiftTagsViewModal;

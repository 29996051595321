import styled from "styled-components";
import { colors } from "@teamrota/rota-design";

export const ValueCell = styled.div`
  padding: 10px;
  color: ${colors.darkGrey};
  font-size: 16px;
`;

export const LabelCell = styled.div`
  padding: 10px;
  color: ${({ theme }) => theme.neutral.main};
  font-size: 14px;
`;

export const StyledGrid = styled.div`
  white-space: nowrap;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 32px;
  border: 1px solid ${({ theme }) => theme.neutral.light};
  padding-bottom: 12px;
  overflow: auto;
`;

export const StyledWrapSubtitle = styled.div`
  white-space: nowrap;
  font-size: 14px;
  border: 1px solid ${({ theme }) => theme.neutral.light};
  border-top-color: transparent;
  border-left-color: transparent;
  font-weight: bold;
  padding: 10px;
  display: flex;
  align-items: center;
`;

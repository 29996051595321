import styled from "styled-components";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
`;

export const CheckRow = styled.div`
  padding: ${STYLE_CONSTS.UNIT_1} 0;
`;

export const LabelContainer = styled.div`
  text-align: right;
`;

export const Feedback = styled.div`
  width: 150px;
  color: ${({ shouldGreyText }) =>
    shouldGreyText ? COLORS.CLOUDY_BLUE : COLORS.COOL_GREY};
`;

export const SmallText = styled.div`
  font-size: ${STYLE_CONSTS.FONT_SMALL};
`;

import React, { memo, useEffect, useState } from "react";
import moment from "moment-timezone";
import { ZonedDate } from "@teamrota/rota-common";

import { ACCOUNT_TIMEZONE } from "~/src/consts";

import {
  TimeSurround,
  StyledTime,
  StyledDate,
  StyledTitle
} from "./index.styles";

export default memo(() => {
  const [time, setTime] = useState(moment());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(moment());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const diff =
    new Date().getTimezoneOffset() - new ZonedDate().getTimezoneOffset();

  if (diff !== 0) {
    const dateString = time.format("ddd DD MMM");
    const timeString = time.format("HH:mm");

    return (
      <TimeSurround>
        <StyledTitle>{ACCOUNT_TIMEZONE}</StyledTitle>
        <StyledTime>{timeString}</StyledTime>
        <StyledDate>{dateString}</StyledDate>
      </TimeSurround>
    );
  } else {
    return "";
  }
});

import { RotaButtonIcon } from "@teamrota/rota-design";
import styled from "styled-components";

export const InputsRow = styled.div`
  display: flex;
  gap: 50px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 16px;
`;

export const OptionsButton = styled(RotaButtonIcon)`
  position: relative;
`;

export const InputRow = styled.div`
  position: sticky;
  top: 40px;
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px black;
  background-color: ${({ theme }) => theme.white};
  z-index: 500;
`;

export const Key = styled.div`
  position: absolute;
  top: 50%;
  right: 0;

  transform: translateY(-50%);
`;

export const KeyTitle = styled.div`
  color: ${({ theme }) => theme.neutral.main};
  display: inline-block;
  padding: 5px;
`;

interface DropDownContainerProps {
  width: number;
}

export const DropDownContainer = styled.div<DropDownContainerProps>`
  width: ${({ width }) => width};
  padding: 0 15px 0 0;
`;

interface SwitchContainerProps {
  width: number;
}

export const SwitchContainer = styled.div<SwitchContainerProps>`
  width: ${({ width }) => width};
  padding: 16px 25px 0 0;
`;

interface PlaceholderProps {
  isShow: boolean;
}

export const Placeholder = styled.div<PlaceholderProps>`
  min-height: 500px;
  text-align: center;
  line-height: 300px;
  font-size: 200%;
  color: #c0c0c0;

  display: ${({ isShow }) => (isShow ? "block" : "none")};
`;

export const ContentArea = styled.div`
  height: 500px;
  margin: 16px 0;

  & > table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    font-size: 130%;
    font-weight: 400;

    & th {
      font-size: 60%;
      border: solid 1px ${({ theme }) => theme.neutral.light};
      background-color: ${({ theme }) => theme.neutral.lighter};
    }

    & thead {
      & th {
        position: sticky;
        top: 105px;
        font-size: 70%;
        margin: 0;
        border: solid 1px ${({ theme }) => theme.neutral.light};
        padding: 10px;
        background-color: ${({ theme }) => theme.neutral.light};
      }
    }

    & th:nth-child(1) {
      width: 5.5%;
    }

    & th:nth-child(2) {
      width: 13.5%;
    }

    & th:nth-child(3) {
      width: 13.5%;
    }

    & th:nth-child(4) {
      width: 13.5%;
    }

    & th:nth-child(5) {
      width: 13.5%;
    }

    & th:nth-child(6) {
      width: 13.5%;
    }

    & th:nth-child(7) {
      width: 13.5%;
    }

    & th:nth-child(8) {
      width: 13.5%;
    }

    & td {
      margin: 0;
      border: solid 1px ${({ theme }) => theme.neutral.light};
      padding: 5px;
      text-align: center;
      font-size: 75%;
    }
  }
`;

import { graphql } from "@apollo/client/react/hoc";
import set from "lodash/fp/set";

import useAuth from "~/src/auth/hooks/use-auth";
import { GET_SHIFT_GROUP } from "~/src/containers/requester-calendar/shift-modal/graphql/get-shift-group/get-shift-group.query";
import { GET_SHIFT } from "~/src/containers/modals/view-edit-shift/graphql/get-shift";
import UPDATE_SHIFT_STATE from "./update-shift-state.mutation";

export default graphql(UPDATE_SHIFT_STATE, {
  name: "updateShiftState",
  props: props => {
    const auth = useAuth();
    return {
      updateShiftState: (shiftId, newState, note) =>
        props.updateShiftState({
          variables: auth.addVals(UPDATE_SHIFT_STATE, {
            shiftId,
            newState,
            note
          }),
          update: (store, { data: { updateShiftState } }) => {
            try {
              const getShiftGroupData = store.readQuery({
                query: GET_SHIFT_GROUP,
                variables: { id: updateShiftState.id }
              });
              if (getShiftGroupData) {
                store.writeQuery({
                  query: GET_SHIFT_GROUP,
                  data: set(
                    "account.shift",
                    { ...getShiftGroupData.account.shift, ...updateShiftState },
                    getShiftGroupData
                  )
                });
              }
            } catch (e) {
              // eslint-disable-next-line no-console
              console.warn("Update: GET_SHIFT_GROUP was not possible");
            }

            try {
              const getShiftData = store.readQuery({
                query: GET_SHIFT,
                variables: { shiftId }
              });
              if (getShiftData) {
                store.writeQuery({
                  query: GET_SHIFT,
                  variables: { shiftId },
                  data: set(
                    "account.shift.logs",
                    updateShiftState.logs,
                    getShiftData
                  )
                });
              }
            } catch (e) {
              // eslint-disable-next-line no-console
              console.warn("Update: GET_SHIFT was not possible");
            }
          },
          refetchQueries: ["getSchedules"]
        })
    };
  }
});

import React from "react";
import moment from "moment-timezone";

import { ZonedDate } from "@teamrota/rota-common";

import {
  Label,
  Wrapper,
  InputsWrapper,
  StyledRotaCalendar,
  StyledTimeDateInput,
  ErrorMessage
} from "./styles";

interface DateTimePickerProps {
  label: string;
  dateTime?: moment.Moment | null;
  setDateTime: (dateTime: moment.Moment) => void;
  isError?: boolean;
  errorMessage?: string;
  selectedDate?: Date;
  maxDate?: Date;
}

const DateTimePicker: React.FC<DateTimePickerProps> = ({
  label,
  dateTime,
  setDateTime,
  isError,
  errorMessage,
  maxDate
}) => {
  const handleDateChange = (date: Date) => {
    const newDate = moment(ZonedDate.fromSelectedDate(date)).startOf("day");

    if (dateTime) {
      newDate.hours(dateTime.hours()).minutes(dateTime.minutes());
    }

    setDateTime(newDate);
  };

  const handleTimeChange = (value: string) => {
    const [hours, minutes] = value.split(":").map(Number);
    if (dateTime) {
      const newDateTime = dateTime
        .clone()
        .hours(hours)
        .minutes(minutes)
        .seconds(0)
        .milliseconds(0);
      setDateTime(newDateTime);
    } else {
      setDateTime(
        moment()
          .hours(hours)
          .minutes(minutes)
          .seconds(0)
          .milliseconds(0)
      );
    }
  };

  return (
    <Wrapper>
      <Label>{label}</Label>
      <InputsWrapper>
        <StyledRotaCalendar
          value={dateTime ? new ZonedDate(dateTime).toSelectDate() : new Date()}
          onChangeDate={handleDateChange}
          maxDate={maxDate}
        />
        {/* @ts-expect-error TS-2769 */}
        <StyledTimeDateInput
          isSmall
          type="time"
          onChange={handleTimeChange}
          value={dateTime ? dateTime.format("HH:mm") : "00:00"}
        />
      </InputsWrapper>
      {isError && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Wrapper>
  );
};

export default DateTimePicker;

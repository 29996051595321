import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { FilterOptions } from "../../notifications.styles";
import { onFilterCheck } from "../../reducer";
import { RotaCheckbox } from "@teamrota/rota-design";

const bindActions = dispatch =>
  bindActionCreators(
    {
      onFilterCheck
    },
    dispatch
  );

const FilterCheckbox = props => (
  <div>
    <FilterOptions>
      <RotaCheckbox
        label={props.label}
        isChecked={props.selectedFilters[props.name]}
        onClick={() => {
          props.onFilterCheck({
            [props.name]: !props.selectedFilters[props.name]
          });
        }}
      />
    </FilterOptions>

    {props.openComponent &&
      props.selectedFilters[props.name] &&
      props.openComponent(props)}
  </div>
);

export default connect(
  s => ({
    selectedFilters: s.notifications.selectedFilters
  }),
  bindActions
)(FilterCheckbox);

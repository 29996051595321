import React, { useState } from "react";
import { RotaDropdown } from "@teamrota/rota-design";
import Venues from "./venues";
import { useGetVenues } from "./graphql/get-venues";

import { Container } from "./user-venues.styles";

const UserVenues = ({ targetAccountId }) => {
  const [selectedVenue, setSelectedVenue] = useState(null);

  const { venues } = useGetVenues(targetAccountId);

  const venueOptions = venues.map(({ id, name }) => {
    return { value: id, label: name };
  });

  const handleVenueSelection = venueId => {
    const venue = venues.find(({ id }) => id === venueId);
    setSelectedVenue(venue);
  };

  return (
    <Container isMarginTop={selectedVenue}>
      {!selectedVenue && !!venueOptions.length && (
        <RotaDropdown
          placeholder="Select a venue"
          label="Select Venue"
          options={venueOptions}
          onChange={handleVenueSelection}
        />
      )}

      <Venues
        venues={venues}
        partnerId={targetAccountId}
        selectedVenue={selectedVenue}
        setSelectedVenue={setSelectedVenue}
      />
    </Container>
  );
};

export default UserVenues;

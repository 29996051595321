import React, { useState, useEffect } from "react";
import {
  getCaratPosition,
  setCaretPosition,
  replaceCharAtIndex,
  isNumericKey,
  isDirectionKey,
  getIsAllSelected
} from "./helpers";

const FORMAT = "DD/MM/YYYY";
const DELETE_KEY_CODE = 8;
const TAB_KEY_CODE = 9;

const DateInputRaw = ({
  onChange,
  value,
  className,
  onBlur,
  onFocus,
  onKeyDown,
  placeholder,
  isDisabled
}) => {
  const [input, SetInput] = useState(null);
  const [caratPosition, SetCaratPosition] = useState(0);
  const [isFocused, SetIsFocused] = useState(false);

  useEffect(() => {
    if (input && isFocused) setCaretPosition(input, caratPosition);
  });

  const handleKeyDown = e => {
    if (onKeyDown) onKeyDown(e);
    if (isDirectionKey(e.keyCode) || e.keyCode === TAB_KEY_CODE) return;
    const existingValue = e.target.value || "";
    const caratPositionition = getCaratPosition(e.target);
    e.preventDefault();
    if (e.keyCode === DELETE_KEY_CODE) {
      if (getIsAllSelected(e.target)) {
        setCaretPosition(e.target, 0);
        SetCaratPosition(0);
        onChange(FORMAT);
      } else {
        const prevCaratPosition = caratPositionition - 1;
        const deletedChar = FORMAT.charAt(prevCaratPosition);
        onChange(
          replaceCharAtIndex(existingValue, prevCaratPosition, deletedChar)
        );
        setCaretPosition(e.target, prevCaratPosition);
        SetCaratPosition(prevCaratPosition);
      }
    } else if (isNumericKey(e.keyCode)) {
      let nextPos = caratPositionition + 1;
      const nextChar = FORMAT.charAt(nextPos);
      if (nextChar === "/") nextPos += 1;

      const nextValue = replaceCharAtIndex(
        existingValue,
        caratPositionition,
        String.fromCharCode(e.keyCode)
      );

      if (nextValue.length === FORMAT.length) {
        setCaretPosition(e.target, nextPos);
        SetCaratPosition(nextPos);
        onChange(nextValue);
      }
    }
  };

  const handleFocus = e => {
    if (placeholder) onChange(FORMAT);
    if (onFocus) onFocus(e);
    SetIsFocused(true);
  };

  const handleBlur = e => {
    if (onBlur) onBlur(e);
    SetIsFocused(false);
  };

  const handleChange = e => e.preventDefault();

  return (
    <input
      className={className}
      onFocus={handleFocus}
      onBlur={handleBlur}
      ref={ref => SetInput(ref)}
      value={value || (placeholder ? "" : FORMAT)}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      disabled={isDisabled}
    />
  );
};

export default DateInputRaw;

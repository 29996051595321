import { isValidEmail } from "~/src/utils/validators";

const validate = values => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = "First name is required";
  }

  if (!values.lastName) {
    errors.lastName = "Last name is required";
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = "Please enter a valid phone number";
  }

  if (!values.email) {
    errors.email = "Email is required";
  }

  if (!values?.venueIds?.length) {
    errors.venueIds = "A home venue is required";
  }

  if (!isValidEmail(values.email)) {
    errors.email = "Email is invalid";
  }
  return errors;
};

export default validate;

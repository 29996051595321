import { RotaButtonIcon } from "@teamrota/rota-design";
import styled from "styled-components";

export const InputsRow = styled.div`
  display: flex;
  gap: 50px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 16px;
`;

export const OptionsButton = styled(RotaButtonIcon)`
  position: relative;
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px black;
`;

interface DropDownContainerProps {
  width: number;
}

export const DropDownContainer = styled.div<DropDownContainerProps>`
  width: ${({ width }) => width};
  padding: 0 15px 0 0;
`;

export const CheckBoxContainer = styled.div`
  padding: 13px 15px 0 0;
`;

export const ContentArea = styled.div`
  margin: 16px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`;

interface GroupProps {
  width: number;
}

export const Group = styled.div<GroupProps>`
  position: relative;
  width: ${({ width }) => width}px;
  padding: 10px 10px;
  border: dotted 2px ${({ theme }) => theme.neutral.light};
`;

export const Bottom = styled.div`
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Input = styled.div`
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
`;

export const Label = styled.div`
  display: block;
  font-size: 110%;
  width: 150px;
`;

export const GroupTitle = styled.div`
  width: 180px;
  font-weight: 900;
  font-size: 120%;
  border-bottom: solid 1px ${({ theme }) => theme.neutral.light};
  padding: 0 0 5px 0;
  margin-bottom: 10px;
`;

export const Error = styled.div`
  color: red;
  font-weight: 900;
`;

export const CheckBoxGroup = styled.div`
  margin: 0px;
`;

export const SwitchWrapper = styled.div`
  padding: 8px 0 16px 0;

  & p {
    color: black;
    font-size: 95%;
    font-weight: 300;
  }
`;

export const InputFieldWrapper = styled.div`
  width: 100px;

  & > div {
    margin: 0;
  }
`;

export const DropDownWrapper = styled.div`
  & > div {
    margin: 0;
  }

  z-index: 2000;
`;

import styled from "styled-components";

export const StyledContentModal = styled.div`
  width: 80%;
  margin: 40px auto 0;

  h3 {
    font-size: 18px;
    text-align: center;
    font-weight: 700;

    span {
      color: ${({ theme }) => theme.primary.main};
    }
  }

  & > div:first-of-type {
    margin-bottom: 30px;
  }
  & > div:last-of-type {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    gap: 40px;
  }
`;

export const POINT_OF_SCALE_OPTIONS = Array.from(Array(12).keys()).map(i => ({
  label: `${i + 1}`,
  value: `${i + 1}`
}));

export const RATE_TYPE_OPTIONS = [
  { label: "Pay", value: "PAY" },
  { label: "Charge", value: "CHARGE" }
];

export const RATE_SHOW_OPTIONS = [
  { label: "Rules", value: "RULES" },
  ...RATE_TYPE_OPTIONS
];

export const ATTRIBUTE_OPTIONS = [
  { label: "Working", value: "WORKING" },
  { label: "Break", value: "BREAK" },
  { label: "Twilight", value: "TWILIGHT" },
  { label: "Sleep", value: "SLEEP" },
  { label: "Disturbed", value: "DISTURBED" },
  { label: "Overstay", value: "OVERSTAY" }
];

export const COLORS = [
  "#FF0000",
  "#FF8C00",
  "#BDB76B",
  "#BA55D3",
  "#483D8B",
  "#2E8B57",
  "#008080",
  "#BC8F8F",
  "#8B4513",
  "#696969"
];

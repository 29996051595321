import { gql } from "@apollo/client";
import { useState } from "react";
import { useQuery } from "@apollo/client";

const GET_PARTNER_BREAK_RULES = gql`
  query getPartnerBreakRules($partnerId: ID!, $offset: Int!, $limit: Int!) {
    account {
      id
      partnerBreakRules(partnerId: $partnerId, offset: $offset, limit: $limit) {
        id
        connection {
          id
        }
        venue {
          id
          name
        }
        payrollBreak {
          id
          name
        }
      }
    }
  }
`;

const PAGE_SIZE = 100;

export const usePartnerBreakRules = partnerId => {
  const [isInitialFetch, setIsInitialFetch] = useState(true);
  const [limit, setLimit] = useState(PAGE_SIZE);
  const [offset, setOffset] = useState(0);

  const { loading, data, error, fetchMore } = useQuery(
    GET_PARTNER_BREAK_RULES,
    {
      variables: { partnerId, offset, limit },
      fetchPolicy: isInitialFetch ? "network-only" : "cache-first",
      notifyOnNetworkStatusChange: true,
      onError: error => {
        throw error;
      }
    }
  );

  const partnerBreakRules = data?.account?.partnerBreakRules ?? [];
  const totalResults = partnerBreakRules.length;

  const loadMore = () => {
    if (partnerBreakRules.length < totalResults) {
      setIsInitialFetch(false);
      const newOffset = offset + limit;
      setOffset(newOffset);

      fetchMore({
        variables: {
          partnerId,
          offset: newOffset,
          limit: PAGE_SIZE
        },
        fetchPolicy: "network-only"
      }).then(({ data }) => {
        setLimit(limit + data.account.partnerBreakRules.length);
      });
    }
  };

  return {
    loading: isInitialFetch && loading,
    loadingMore: !isInitialFetch && loading,
    partnerBreakRules,
    hasMore: partnerBreakRules.length < totalResults,
    loadMore,
    error
  };
};

import styled, { css } from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const Section = styled.div`
  margin-bottom: ${STYLE_CONSTS.UNIT_4};
`;

export const Title = styled.div`
  margin-bottom: ${STYLE_CONSTS.UNIT_1};
  font-size: ${STYLE_CONSTS.FONT_MEDIUM};
  color: ${({ theme }) => theme.text.secondary};
`;

export const Body = styled.div`
  font-size: ${STYLE_CONSTS.FONT_MEDIUM};
  color: ${({ theme }) => theme.text.primary};
`;

const readOnlyCSS = css`
  user-select: none;
  color: ${({ theme }) => theme.text.primary};
`;

export const InlineField = styled.input.attrs({
  type: "number",
  readOnly: props => !props.isEditMode
})`
  color: ${({ theme }) => theme.primary.main};
  font-size: ${STYLE_CONSTS.FONT_MEDIUM};
  border: none;
  padding: 0;
  display: inline-block;
  width: 20px;
  text-align: center;
  ${props => (props.isEditMode ? "" : readOnlyCSS)};
  &:focus {
    outline: none;
  }
  -moz-appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.primary.main};
  margin-bottom: ${STYLE_CONSTS.UNIT_3};
`;

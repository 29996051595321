import { gql } from "@apollo/client";

import MemberFragment from "../member/member.fragment";

export default gql`
  # Fragments
  ${MemberFragment}

  # Parent booking fragment
  fragment BookingFragment on booking {
    id
    state
    visibleAt
    ratingOfMember
    ratingOfManager
    chargeRate
    metadata
    notes
    isNoShow
    bookingNotes
    isDayRate
    bookingState {
      id
      startFinal
      endFinal
      breakFinal
      lengthFinal
      approvalMethod

      ... on bookingState {
        costFinal
      }
    }
    member {
      ...MemberFragment
      annualSalary
      memberType
    }
  }
`;

import React from "react";
import { useDrag } from "react-dnd";

import MemberPhoto from "~/src/components/member-photo";
import { DND_ITEM_TYPES } from "~/src/consts";

import { MemberWrapper } from "./member.styles";

const Member = ({
  id,
  bookingsCount,
  poolType,
  firstName,
  lastName,
  photo,
  relationship,
  isGroupMember,
  groupMemberId,
  onClick,
  normalWhiteSpacing
}) => {
  const [, drag] = useDrag(() => ({
    type: DND_ITEM_TYPES.POOL_MEMBER,
    item: {
      id,
      groupMemberId,
      poolType,
      isGroupMember: isGroupMember || false,
      bookingsCount
    }
  }));

  return (
    <MemberWrapper
      ref={drag}
      isGroupMember={isGroupMember}
      onClick={onClick}
      normalWhiteSpacing={normalWhiteSpacing}
    >
      <MemberPhoto src={photo} poolType={poolType || relationship.poolType} />
      <div>{`${firstName} ${lastName}`}</div>
    </MemberWrapper>
  );
};

export default Member;

import React from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";

import { Role } from "@teamrota/authlib";

import appActions from "~/src/actions/app";

import { ICON_NAMES } from "~/src/components/icon";

import { REPORT_REQUEST_TYPES } from "~/src/consts";

import { MODAL_TYPES } from "~/src/containers/global-modals";
import { errorModal } from "~/src/utils/errors";
import getProfile from "~/src/graphql/queries/get-profile/get-profile-query.decorator";
import { generateReport } from "~/src/services/reports";
import SideMenu from "./components/side-menu";
import TopMenu from "./components/top-menu";
import PageScrollView from "./components/page-scroll-view";
import {
  DashboardContainer,
  PageContentWrapper,
  SideNavWrapper,
  TopNavWrapper
} from "./dashboard.styles";

import useAuth from "~/src/auth/hooks/use-auth";
import ErrorBoundary from "~/src/containers/error-boundary";

const DashboardComponent = ({
  children,
  user,
  filters,
  generateMyStaff,
  addMember
}) => {
  const { pathname } = useLocation();

  const auth = useAuth();

  const sideMenuOptions = {
    "my-staff": [
      ...(auth.hasRole(Role.MEMBERS_EDIT)
        ? [
            {
              icon: ICON_NAMES.ADD_MEMBER,
              name: "Add member",
              to: "/my-staff#", // doesn't go anywhere but prop is required
              onClick: addMember,
              isVisible: auth.hasRoles(Role.PROVIDER)
            }
          ]
        : []),
      {
        icon: ICON_NAMES.TAG,
        name: "Roles",
        to: "/my-staff/roles",
        isVisible: auth.hasRoles(Role.PROVIDER)
      },
      ...(auth.hasRole(Role.REPORTS_MY_STAFF)
        ? [
            {
              icon: ICON_NAMES.DOWNLOAD,
              name: "Download staff data",
              to: "/my-staff#",
              onClick: () => generateMyStaff(filters),
              isVisible: auth.hasRoles(Role.PROVIDER, Role.REPORTS_MEMBERS)
            },
            {
              icon: ICON_NAMES.DOWNLOAD,
              name: "Download company data",
              to: "/my-staff#",
              onClick: () => generateMyStaff(filters, true),
              isVisible: auth.hasRoles(Role.PROVIDER, Role.REPORTS_MEMBERS)
            }
          ]
        : []),
      {
        icon: ICON_NAMES.MY_TEAM,
        name: "Member Types",
        to: "/my-staff/member-types",
        isVisible: auth.hasRole(Role.PROVIDER)
      },
      {
        icon: ICON_NAMES.UPLOADS,
        name: "Uploads",
        to: "/my-staff/unavailability-files",
        isVisible: auth.hasRole(Role.PROVIDER)
      }
    ],
    request: [
      {
        icon: ICON_NAMES.ADD_SHIFT,
        name: "Add shift",
        to: "/request/add",
        isVisible: auth.hasRoles(Role.REQUESTER, Role.SHIFTS_CREATE)
      },
      {
        icon: ICON_NAMES.BRIEFING_TEMPLATE,
        to: "/request/briefing-templates",
        name: "Briefing Templates",
        isVisible: auth.hasRoles(Role.REQUESTER, Role.BRIEFING_TEMPLATES_VIEW)
      },
      {
        icon: ICON_NAMES.UNIFORM_TEMPLATE,
        to: "/request/uniform-templates",
        name: "Uniform Templates",
        isVisible: auth.hasRole(Role.REQUESTER)
      },
      {
        icon: ICON_NAMES.VENUE_TEMPLATE,
        to: "/request/venue-templates",
        name: "Venues",
        isVisible: auth.hasRole(Role.REQUESTER)
      },
      {
        icon: ICON_NAMES.POOLS,
        to: "/request/pools",
        name: "Pools",
        isVisible: auth.hasRoles(Role.REQUESTER, Role.MEMBER_POOLS_VIEW)
      }
    ],
    schedule: [
      {
        icon: ICON_NAMES.BRIEFING_TEMPLATE,
        to: "/request/briefing-templates",
        name: "Briefing Templates",
        isVisible: auth.hasRole(Role.REQUESTER)
      },
      {
        icon: ICON_NAMES.UNIFORM_TEMPLATE,
        to: "/request/uniform-templates",
        name: "Uniform Templates",
        isVisible: auth.hasRole(Role.REQUESTER)
      },
      {
        icon: ICON_NAMES.VENUE_TEMPLATE,
        to: "/request/venue-templates",
        name: "Venues",
        isVisible: auth.hasRole(Role.REQUESTER)
      },
      {
        icon: ICON_NAMES.POOLS,
        to: "/request/pools",
        name: "Pools",
        isVisible: auth.hasRoles(Role.REQUESTER, Role.MEMBER_POOLS_VIEW)
      }
    ],
    provide: [
      {
        icon: ICON_NAMES.EDIT,
        to: "/provide/notifications",
        name: "Message",
        isVisible: auth.hasRole(Role.PROVIDER)
      },
      {
        icon: ICON_NAMES.MESSAGES_CHAT,
        to: "/provide/notifications/history",
        name: "History",
        isVisible: auth.hasRole(Role.PROVIDER)
      }
    ],
    timesheets: [],
    settings: [
      {
        icon: ICON_NAMES.PARTNER,
        to: "/settings/partners",
        name: "Partners",
        isVisible: auth.hasRole(Role.PROVIDER)
      },
      {
        icon: ICON_NAMES.MY_TEAM,
        to: "/settings/users",
        name: "My Team",
        isVisible: true
      },
      {
        icon: ICON_NAMES.VENUE_TEMPLATE,
        to: "/settings/service-areas",
        name: "Service Areas",
        isVisible: auth.hasRole(Role.PROVIDER)
      },
      {
        icon: ICON_NAMES.REPORT,
        to: "/settings/reports",
        name: "Reports",
        isVisible: auth.hasRoles(Role.PROVIDER, Role.REPORTS_PAYROLL)
      }
    ]
  };

  const currentRoot = pathname.split("/")[1];
  const sideMenu = sideMenuOptions[currentRoot];

  const topMenuOptions = [
    {
      label: "Request",
      to: "/request",
      isVisible: auth.hasRole(Role.REQUESTER)
    },
    {
      label: "Schedule",
      to: "/schedule",
      isVisible: auth.hasRoles(Role.REQUESTER, Role.SCHEDULER)
    },
    {
      label: "Timesheets",
      to: "/timesheets",
      notifCount: (user?.account?.outstandingTimesheets || []).length,
      isVisible: auth.hasRoles(Role.REQUESTER, Role.TIMESHEETS)
    },

    {
      label: "Timesheets New",
      to: "/timesheets-new",
      notifCount: (user?.account?.outstandingTimesheets || []).length,
      isVisible:
        auth.hasRoles(Role.REQUESTER, Role.TIMESHEETS) &&
        (user?.account?.newTimesheetsStartDate ||
          user?.account.requesterConnections?.data[0]?.sourceAccount
            .newTimesheetsStartDate)
    },
    {
      label: "My Staff",
      to: "/my-staff",
      isVisible: auth.hasRole(Role.PROVIDER)
    },
    {
      label: "Provide",
      to: "/provide",
      isVisible: auth.hasRole(Role.PROVIDER)
    },
    {
      label: "Provide-Schedule",
      to: "/provide-schedule",
      isVisible: auth.hasRoles(Role.PROVIDER, Role.PROVIDER_SCHEDULER)
    },
    {
      label: "Payroll",
      to: "/payroll",
      isVisible: auth.hasRole(Role.PROVIDER) && auth.hasRole(Role.PAYROLL_VIEW)
    },
    {
      label: "Settings",
      to: "/settings",
      isVisible: auth.hasRole(Role.GENERAL_SETTINGS_VIEW)
    },
    {
      label: "Admin",
      to: "/admin-dashboard",
      isVisible: auth.hasRole(Role.ROTA_ADMIN)
    }
  ];

  return (
    <DashboardContainer>
      <SideNavWrapper>
        <SideMenu options={sideMenu || []} />
      </SideNavWrapper>
      <TopNavWrapper>
        <TopMenu options={topMenuOptions} />
      </TopNavWrapper>
      <PageContentWrapper>
        <PageScrollView>
          <ErrorBoundary>{children}</ErrorBoundary>
        </PageScrollView>
      </PageContentWrapper>
    </DashboardContainer>
  );
};

const mapStateToProps = state => ({
  filters: state?.myStaff?.filters
});

const mapDispatchToProps = (dispatch, props) => ({
  addMember: () =>
    dispatch(
      appActions.showModal(MODAL_TYPES.ADD_MEMBER, {
        onClose: () => dispatch(appActions.closeModal()),
        serviceAreas: props?.user?.account?.serviceAreas
      })
    ),
  generateMyStaff: (filters, isCompanyDataAttached = false) =>
    dispatch(
      appActions.showModal(MODAL_TYPES.DOWNLOAD_STAFF_DATA, {
        onConfirm: async () => {
          try {
            await generateReport(REPORT_REQUEST_TYPES.MY_STAFF, {
              role: filters?.roleNames?.map(role => role.id),
              minRating: parseInt(filters?.minRating),
              maxRating: parseInt(filters?.maxRating),
              memberStatus: filters?.stateIn?.map(state => state.id),
              serviceArea: filters?.serviceAreaIds?.map(area => area.id),
              memberType: filters?.memberType?.map(
                ({ memberType }) => memberType
              ),
              isCompanyDataAttached
            });
            dispatch(appActions.closeModal());
          } catch (e) {
            dispatch(appActions.closeModal());
            errorModal(e);
          }
        },
        onClose: () => dispatch(appActions.closeModal())
      })
    )
});

export default getProfile(
  connect(mapStateToProps, mapDispatchToProps)(DashboardComponent)
);

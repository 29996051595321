import { colors, RotaInput } from "@teamrota/rota-design";
import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { StyledArrowDown } from "../new-pay-rate/style";

const StyledDiv = styled.div<{ flexGrow: number; width: string }>`
  position: relative;
  cursor: pointer;
  flex-grow: ${({ flexGrow }) => flexGrow};
  width: ${({ width = "auto" }) => {
    return width;
  }};
`;

interface ButtonProps {
  isError: boolean;
  isFocused: boolean;
  isSuccess: boolean;
  isDisabled: boolean;
  isIconRotate: boolean;
}

const StyledButton = styled.button<ButtonProps>`
  font-size: 14px;
  appearance: none;
  background: ${({ isDisabled, theme }) =>
    isDisabled ? theme.neutral.lighter : theme.white};
  border-radius: 4px;
  border: ${({ isError, isSuccess, isDisabled, theme }) => {
    const color = isDisabled
      ? theme.neutral.main
      : isError
      ? theme.error.main
      : isSuccess
      ? theme.success.main
      : theme.neutral.main;

    return `1px solid ${color}`;
  }};
  color: ${({ isDisabled, theme }) =>
    isDisabled ? theme.text.secondary : theme.text.primary};
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  font-weight: 400;
  padding: 0 35px 0 12px;
  text-align: left;
  position: relative;
  overflow: hidden;
  outline: none;
  text-overflow: ellipsis;
  min-width: 90px;
  height: 32px;
  white-space: nowrap;
  width: 100%;
  &::after {
    content: "";
    border-radius: 1px;
    border-style: solid;
    border-color: transparent;
    border-top-color: ${colors?.coolGrey};
    border-left-color: ${colors?.coolGrey};
    border-width: 1px;
    padding: 2px;
    position: absolute;
    right: 12px;
    transition: all 0.3s ease-in-out;
    top: ${({ isIconRotate }) =>
      isIconRotate ? "calc(45% + 1px)" : "calc(35% + 1px)"};
    transform: ${({ isIconRotate }) =>
      isIconRotate ? "rotate(45deg)" : "rotate(-135deg)"};
  }
  &.active::after {
    border-color: transparent;
    border-bottom-color: ${colors?.lightGrey};
    top: calc(50% - 3px);
  }
  &:focus {
    border-color: ${({ theme }) => theme.primary.main}
    outline: none;
  }
  &::placeholder {
    color: ${({ theme }) => theme.neutral.light};
  }
`;

const StyledUl = styled.ul`
  background: ${colors?.white};
  box-shadow: 0px 2px 4px rgba(153, 153, 153, 0.25);
  box-sizing: border-box;
  border-radius: 5px;
  font-family: "Gotham", "Gotham B", "Open Sans", sans-serif;
  font-size: 14px;
  left: 0;
  list-style: none;
  margin: 0;
  max-height: 0;
  overflow: auto;
  position: absolute;
  padding: 5px;
  transition: max-height 0.3s linear;
  visibility: hidden;
  width: 100%;
  z-index: 10;
  &[aria-expanded="true"] {
    visibility: visible;
    max-height: 300px;
  }
  & > li {
    align-items: center;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 12px 10px 12px;
    margin: 5px 0;
    white-space: nowrap;
  }
  & > li:hover,
  & > li:focus {
    background: ${({ theme }) => theme.neutral.main};
    border-radius: 5px;
    color: ${({ theme }) => theme.text.primary};
    font-weight: 400;
    outline: none;
  }
  & span {
    color: ${colors?.white};
    border-radius: 3px;
    background-color: ${colors?.lightGrey};
    left: 1%;
    max-width: 85%;
    min-height: 20px;
    margin-top: 2px;
    position: absolute;
    padding: 5px;
    visibility: hidden;
  }
  & li:hover + span {
    visibility: visible;
  }
`;

const Label = styled.label<{ isLabelTop: boolean }>`
  color: ${({ theme }) => theme.text.secondary};
  font-size: 14px;
  font-weight: 400;
  ${({ isLabelTop }) =>
    isLabelTop
      ? css`
          margin-bottom: 4px;
        `
      : css`
          margin-right: 16px;
          white-space: nowrap;
        `}
`;

const ErrorMessage = styled.p`
  margin-top: 4px;
  margin-bottom: 0;
  position: absolute;
  font-weight: normal;
  font-style: italic;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.error.main};
`;

const InputWithLabelWrapper = styled.div<{ isLabelTop: boolean }>`
  margin: 16px 0;
  display: flex;
  ${({ isLabelTop }) =>
    isLabelTop
      ? css`
          flex-direction: column;
        `
      : css`
          align-items: center;
        `}
`;

const StyledInput = styled(RotaInput).attrs({
  style: { fontSize: "14px" },
  wrapperStyles: { margin: 0 }
})`
  color: ${({ isDisabled, theme }) =>
    isDisabled ? theme.text.secondary : theme.text.primary};
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
`;

const StyledArrow = styled(StyledArrowDown)<{ isIconRotate: boolean }>`
  width: 8px;
  color: ${colors?.coolGrey};
  transition: all 0.3s ease-in-out;
  transform: ${({ isIconRotate }) =>
    isIconRotate ? "rotate(180deg)" : "rotate(0deg)"};
`;

const StyledEmptyState = styled.p`
  color: ${({ theme }) => theme.neutral.main};
  text-align: center;
`;

interface Option {
  value: string;
  label: string;
}

export interface Props {
  options: Option[];
  onChange: (value: string) => void;
  value?: string;
  placeholder: string;
  flexGrow?: number;
  width: string;
  errorMessage: string;
  label: string;
  isLabelTop?: boolean;
  id: string;
  isError?: boolean;
  isSuccess?: boolean;
  isDisabled?: boolean;
  isFocused?: boolean;
  onSearchText?: (value: string) => void;
  isSearchable?: boolean;
  onLoadMore?: () => void;
  isLoading?: boolean;
  isDisplayValueDisabled?: boolean;
}

const RotaDropdownV2 = ({
  options,
  onChange,
  value,
  placeholder,
  flexGrow = 1,
  width,
  errorMessage,
  label,
  isLabelTop = false,
  id,
  isError = false,
  isSuccess = false,
  isDisabled = false,
  isFocused = false,
  isSearchable = false,
  onSearchText,
  isLoading = false,
  onLoadMore,
  isDisplayValueDisabled = false
}: Props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [displayValue, setDisplayValue] = useState<string>();
  const [dropdownOptions, setDropdownOptions] = useState<Option[]>([]);
  const selectNode = useRef<HTMLDivElement>(null);
  const dropdownOptionsRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (options) setDropdownOptions(options);
  }, [options]);

  useEffect(() => {
    const objType = options?.map(el => typeof el == "object");

    if (objType) {
      return setDisplayValue(
        value === undefined
          ? placeholder
          : options?.find(el => el.value == value)?.label
      );
    }
    return setDisplayValue(value);
  }, [value]);

  useEffect(() => {
    isDropdownOpen
      ? document.addEventListener("mousedown", handleClickOutside)
      : document.removeEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  useEffect(() => {
    if (onLoadMore) {
      dropdownOptionsRef?.current?.addEventListener("scroll", e => {
        const target = e.target as HTMLElement;
        const scrollTop = target.scrollTop + target.clientHeight;
        if (scrollTop >= target.scrollHeight - 20) onLoadMore();
      });
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClickOutside = (e: any) => {
    const currentNode = selectNode?.current?.contains(e.target);
    !currentNode && setIsDropdownOpen(false);
  };

  const handleInputChange = (value: string) => {
    if (onSearchText) onSearchText(value);

    setDisplayValue(value);
    setIsDropdownOpen(true);
    setDropdownOptions(
      options.filter(({ label }) =>
        label.toLowerCase().includes(value.toLowerCase().trim())
      )
    );
  };

  return (
    <InputWithLabelWrapper isLabelTop={isLabelTop}>
      {label && (
        <Label htmlFor={id} isLabelTop={isLabelTop}>
          {label}
        </Label>
      )}
      <StyledDiv ref={selectNode} width={width} flexGrow={flexGrow}>
        {isSearchable ? (
          <StyledInput
            value={displayValue ?? ""}
            placeholder={placeholder}
            onClick={() => {
              if (isDisabled) {
                return;
              }
              setIsDropdownOpen(!isDropdownOpen);
            }}
            onChange={e => handleInputChange(e.target.value)}
            isError={isError}
            isSuccess={isSuccess}
            isDisabled={isDisabled}
            iconRight={<StyledArrow isIconRotate={isDropdownOpen} />}
          />
        ) : (
          <StyledButton
            aria-haspopup="true"
            onClick={() => {
              if (isDisabled) {
                return;
              }
              setIsDropdownOpen(!isDropdownOpen);
            }}
            placeholder={placeholder}
            isIconRotate={isDropdownOpen}
            isError={isError}
            isSuccess={isSuccess}
            isFocused={isFocused}
            isDisabled={isDisabled}
            type="button"
          >
            {displayValue?.length ? displayValue : placeholder}
          </StyledButton>
        )}

        <StyledUl
          ref={dropdownOptionsRef}
          className="dropdown-menu"
          role="listbox"
          aria-expanded={isDropdownOpen}
        >
          {dropdownOptions.length ? (
            dropdownOptions.map(item => (
              <li
                key={item.value}
                value={item.value}
                tabIndex={0}
                aria-labelledby={item.label}
                data-tip
                onClick={() => {
                  setDisplayValue(isDisplayValueDisabled ? "" : item.label);
                  setIsDropdownOpen(false);
                  onChange(item.value);
                  isSearchable && setDropdownOptions(options);
                }}
              >
                {item.label || item}
              </li>
            ))
          ) : (
            <StyledEmptyState>Found no results...</StyledEmptyState>
          )}
          {isLoading && <li>Loading more...</li>}
        </StyledUl>
        {isError && errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </StyledDiv>
    </InputWithLabelWrapper>
  );
};
export default RotaDropdownV2;

import React from "react";
import EventItem from "./styles";

export default function DraggedEvent(props) {
  if (!props.dragStartCoords || !props.dragEndCoords) {
    return null;
  }
  // For each column we drag accross we will need a new left, top, height
  const draggedEvents = [];
  // Width in pixels of the calendar columns
  const dayWidth = props.gridWidth / 7;
  // Height of drag area
  const gridHeight = 24 * 60 * props.pixelsPerMinute;
  // Left position of the start of the new event set (just shift to 10 left if spanned weeks)
  const dragStartLeft = props.dragHasSpannedWeeks
    ? 10
    : props.dragStartCoords.left;

  // Total jumps across columns if any
  const totalJumps = getTotalColumnJumps(
    dragStartLeft,
    props.dragEndCoords.left,
    dayWidth
  );

  // Is the dragged event only covering one day (the day it started)
  const isDraggingOnInitialDragDay = !totalJumps;

  // If the drag is backwards then multiply
  const isDraggingDown = props.dragEndCoords.top > props.dragStartCoords.top;
  const isBackwards = totalJumps < 0;

  // If the drag is spanned weeks then only allow a simple single column event
  const spannedStart =
    props.dragHasSpannedWeeks === "forward"
      ? {
          left: 0,
          top: 0,
          height: props.dragEndCoords.top
        }
      : {
          left: props.gridWidth - dayWidth,
          top: props.dragEndCoords.top,
          height: gridHeight - props.dragEndCoords.top
        };

  // The first dragged event from the starting drag
  const startDragged = props.dragHasSpannedWeeks
    ? spannedStart
    : {
        //
        left: calculateInitialLeft(props.dragStartCoords.left, dayWidth),
        top: calculateInitialTop(
          isBackwards,
          isDraggingDown,
          props.dragEndCoords,
          props.dragStartCoords.top,
          isDraggingOnInitialDragDay
        ),
        // If is spanned to next column then set bottom to bottom of grid
        height: calculateInitialHeight(
          isBackwards,
          isDraggingDown,
          props.dragEndCoords,
          props.dragStartCoords.top,
          gridHeight,
          isDraggingOnInitialDragDay
        )
      };

  // Add to array of events to render
  draggedEvents.push(startDragged);

  // If the drag is spanned weeks then prevent the multi column behaviour
  if (!props.dragHasSpannedWeeks) {
    // For the total columns jumped add extra dragged events
    draggedEvents.push(
      ...Array(Math.abs(totalJumps))
        .fill(null)
        .map((_, index) => ({
          left: calculateLeft(isBackwards, startDragged, index, dayWidth),
          top: calculateTop(
            isBackwards,
            index,
            totalJumps,
            props.dragEndCoords
          ),
          // If this is at the end of the drag then only set height to that point
          height: calculateHeight(
            isBackwards,
            index,
            totalJumps,
            props.dragEndCoords,
            gridHeight
          )
        }))
    );
  }

  return (
    <div>
      {draggedEvents.map(({ left, top, height }, i) => (
        <EventItem key={i} transformX={left} transformY={top} height={height} />
      ))}
    </div>
  );
}

// Total columns spanned by the drag
function getTotalColumnJumps(startLeft, endLeft, dayWidth) {
  return Math.floor(endLeft / dayWidth) - Math.floor(startLeft / dayWidth);
}

/**
 * LEFT
 * The folloing functions calculate the positioning of the column that the drag
 * started in.
 */
function calculateInitialLeft(coordLeft, dayWidth) {
  return Math.floor(coordLeft / dayWidth) * dayWidth;
}

/**
 * TOP
 * Calculate the top position of the initial dragged event
 */
function calculateInitialTop(
  isBackwards,
  isDraggingDown,
  dragEndCoords,
  dragStartTop,
  isDraggingOnInitialDragDay
) {
  // If is dragging back accross dates on the the calendar from the start position
  if (isBackwards) {
    // If is on same day as start, top is what every the cur drag is. Otherwise
    // sit at the top of the calendar.
    return isDraggingOnInitialDragDay ? dragEndCoords.top : 0;
  }
  // If is dragging down the calendar and is on the same date as the start
  if (!isDraggingDown && isDraggingOnInitialDragDay) {
    // Start is where ever the mouse is.
    return dragEndCoords.top;
  }
  // Otherwise its just sherever we started.
  return dragStartTop;
}

/**
 * HEIGHT
 * Calculate the height of the initial dragged event
 */
function calculateInitialHeight(
  isBackwards,
  isDraggingDown,
  dragEndCoords,
  dragStartTop,
  gridHeight,
  isDraggingOnInitialDragDay
) {
  // Is on a different column to the start of dragging
  if (!isDraggingOnInitialDragDay) {
    // Is dragging backwards accross days
    if (isBackwards) {
      // Where ever the initial start coor was
      return dragStartTop;
    }
    // Diff between initail start and the height.
    return gridHeight - dragStartTop;
  }
  // Is dragging up the calendar
  if (!isDraggingDown) {
    // Diff between the initial start and the end Y coord
    return dragStartTop - dragEndCoords.top;
  }
  // Diff between the cur drag Y and the start Y.
  return dragEndCoords.top - dragStartTop;
}

/**
 * The following functions calculate the top, left and height of each column other
 * that the initial column that is created, if there are any.
 */

// Is final column of events
function isLastDragColumn(index, totalJumps) {
  return index + 1 === Math.abs(totalJumps);
}

// Top
function calculateTop(isBackwards, index, totalJumps, dragEndCoords) {
  if (isBackwards && isLastDragColumn(index, totalJumps)) {
    return dragEndCoords.top;
  }
  return 0;
}

// Height
function calculateHeight(
  isBackwards,
  index,
  totalJumps,
  dragEndCoords,
  gridHeight
) {
  if (isBackwards) {
    if (isLastDragColumn(index, totalJumps)) {
      return gridHeight - dragEndCoords.top;
    }
    return gridHeight;
  }
  return isLastDragColumn(index, totalJumps) ? dragEndCoords.top : gridHeight;
}

// Left
function calculateLeft(isBackwards, startDragged, index, dayWidth) {
  const multiplier = isBackwards ? -1 : 1;
  return startDragged.left + (index + 1) * dayWidth * multiplier;
}

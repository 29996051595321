import { useQuery } from "@apollo/client";
import { RoleRate } from "./types";

import { gql } from "@apollo/client";

const GET_ROLE_RATES = gql`
  query getRoleRates {
    user {
      id
      account {
        roleRates(accountType: requester) {
          id
          roleId
          roleName
        }
      }
    }
  }
`;

const useRoleRates = () => {
  const { data: roleRateData } = useQuery(GET_ROLE_RATES);
  const roleRates: RoleRate[] = roleRateData?.user?.account?.roleRates || [];

  return roleRates;
};

export default useRoleRates;

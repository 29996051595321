import moment from "moment-timezone";

export const isSleepTimesValid = (selectedDates, selectedSleepTimes) => {
  return selectedDates.every(shift => {
    // Extract hour and minute from the selectedSleepTimes
    const sleepStartHour = selectedSleepTimes.startTime.hour();
    const sleepStartMinute = selectedSleepTimes.startTime.minute();
    const sleepEndHour = selectedSleepTimes.endTime.hour();
    const sleepEndMinute = selectedSleepTimes.endTime.minute();

    // Create moment objects for the next occurrence of the sleep times after the shift start time
    const nextSleepStart = moment(shift.startTime)
      .hour(sleepStartHour)
      .minute(sleepStartMinute);
    if (nextSleepStart.isBefore(shift.startTime)) {
      nextSleepStart.add(1, "days");
    }

    const nextSleepEnd = moment(nextSleepStart)
      .hour(sleepEndHour)
      .minute(sleepEndMinute);
    if (nextSleepEnd.isBefore(nextSleepStart)) {
      nextSleepEnd.add(1, "days");
    }

    // Check if both sleep times are within the shift times
    return (
      nextSleepStart.isSameOrAfter(shift.startTime) &&
      nextSleepEnd.isSameOrBefore(shift.endTime)
    );
  });
};

export const onSleepTimeChange = (
  handleShiftUpdate,
  dateType,
  selectedSleepTimes,
  updateVal
) => {
  let startTime = moment(selectedSleepTimes.startTime).clone();
  let endTime = moment(selectedSleepTimes.endTime).clone();

  const [hour, minute] = updateVal.split(":");

  // Update startTime
  if (dateType === "startTime") {
    startTime = startTime.set({ hour, minute, second: 0, millisecond: 0 });
  }

  // Updated endTime
  if (dateType === "endTime") {
    endTime = endTime.set({ hour, minute, second: 0, millisecond: 0 });
  }

  // If endTime is before startTime, add a day to endTime
  const endTimeHours = endTime.format("HH:mm");
  const startTimeHours = startTime.format("HH:mm");
  if (endTimeHours < startTimeHours) {
    endTime = endTime.set({ date: startTime.get("date") + 1 });
  } else {
    endTime = endTime.set({ date: startTime.get("date") });
  }

  handleShiftUpdate({
    selectedSleepTimes: { startTime, endTime }
  });
};

export const getFormattedSleepTimeDuration = selectedSleepTimes => {
  const sleepStartTime = moment(selectedSleepTimes.startTime);
  const sleepEndTime = moment(selectedSleepTimes.endTime);

  const duration = moment.duration(sleepEndTime.diff(sleepStartTime));

  return duration.format("H [hrs] m [mins]");
};

import React, { useEffect, useState } from "react";
import Leaflet from "leaflet";
import { useMutation } from "@apollo/client";
import { RotaButton, RotaButtonIcon } from "@teamrota/rota-design";

import { errorModal } from "~/src/utils/errors";

import Modal from "~/src/components/modal";
import {
  Content,
  Divider,
  Title
} from "~/src/containers/modals/components/modal-content/index.styles";

import Input from "~/src/components/form-components/text-input";
import Map from "~/src/components/map";

import { CREATE_UPDATE_SERVICE_AREA } from "./graphql/create-update-service-area";

import {
  ButtonsContainer,
  Header,
  Wrapper,
  InputWrapper,
  VerticalDivider
} from "./service-areas-modal.styles";

const ServiceAreasModal = ({ serviceArea, onClose, isOpen, deleteArea }) => {
  const [serviceAreaName, setServiceAreaName] = useState();
  const [path, setPath] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [errorLabel, setErrorLabel] = useState("");

  useEffect(() => {
    setServiceAreaName(serviceArea?.name || "");
    setPath(serviceArea?.path || []);
  }, [serviceArea]);

  const [createUpdateServiceArea] = useMutation(CREATE_UPDATE_SERVICE_AREA, {
    refetchQueries: ["getServiceAreas"]
  });

  const saveServiceArea = async () => {
    if (!serviceAreaName) {
      setErrorLabel("Name is required");
      return;
    }
    if (path?.length === 0) {
      errorModal("You cannot save an empty service area.");
      return;
    }
    try {
      setIsLoading(true);
      await createUpdateServiceArea({
        variables: { id: serviceArea?.id, path: path, name: serviceAreaName }
      });
      onClose();
    } catch (e) {
      errorModal(e);
    } finally {
      setIsLoading(false);
    }
  };

  const getPolygonAttributes = polygon => {
    if (!polygon) return {};
    const bounds = polygon.getBounds();
    return {
      bounds,
      center: bounds.getCenter()
    };
  };

  const getLeafletPolygon = path => {
    if (!path || !path.length) return undefined;
    return Leaflet.polygon([path.map(coords => [coords.lat, coords.lng])]);
  };

  const leafletPolygon = getLeafletPolygon(path);

  return (
    <Modal hasBtn isOpen={isOpen} onClose={onClose}>
      <Wrapper>
        <Header>
          <Title style={{ fontWeight: "bold" }}>{`${
            serviceArea?.id ? "Edit" : "New"
          } Service Area`}</Title>
          <ButtonsContainer>
            {!!serviceArea && (
              <>
                <RotaButtonIcon
                  disabled
                  title="Edit"
                  icon="edit"
                  onClick={() => deleteArea(serviceArea)}
                />
                <RotaButtonIcon
                  title="Delete"
                  icon="delete"
                  onClick={() => deleteArea(serviceArea)}
                />
                <VerticalDivider />
              </>
            )}
            <RotaButtonIcon title="Close" icon="close" onClick={onClose} />
          </ButtonsContainer>
        </Header>
        <Divider />
        <Content style={{ padding: "0px" }}>
          <InputWrapper>
            <Input
              errorLabel={errorLabel}
              onChangeValue={serviceAreaName => {
                setServiceAreaName(serviceAreaName);
                setErrorLabel("");
              }}
              value={serviceAreaName}
              label="Name:"
              placeholder="Write here"
              isInline
            />
          </InputWrapper>

          <Map
            style={{ marginBottom: "20px" }}
            height="80%"
            markers={[getPolygonAttributes(leafletPolygon).center]}
            defaultZoom={8}
            isPathEditable
            shouldHideMarker
            fitBounds={getPolygonAttributes(leafletPolygon).bounds}
            polygonPath={path}
            onChangePath={path => setPath(path)}
          />
          <ButtonsContainer>
            <RotaButton
              isLoading={isLoading}
              onClick={onClose}
              variant="secondary"
            >
              Cancel
            </RotaButton>
            <RotaButton
              style={{ marginLeft: "10px" }}
              isLoading={isLoading}
              onClick={saveServiceArea}
            >
              Save
            </RotaButton>
          </ButtonsContainer>
        </Content>
      </Wrapper>
    </Modal>
  );
};

export default ServiceAreasModal;

import { COLORS, STYLE_CONSTS } from "~/src/styles/config.style";
import styled from "styled-components";
import Icon from "~/src/components/icon";

export const StyledIcon = styled(Icon)`
  vertical-align: middle;
`;

export const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  margin: 31px 0 18px 0;
`;

const styles = {
  button: {
    backgroundColor: `${COLORS.MAIN_ORANGE}`,
    color: COLORS.WHITE,
    width: "174px",
    height: "40px",
    borderRadius: "20px"
  },
  disabledButton: {
    backgroundColor: "gray",
    color: COLORS.BLACK,
    width: "174px",
    height: "40px",
    borderRadius: "20px"
  },
  errorText: {
    color: "#FF3333"
  },
  input: {
    display: "none"
  },
  iconWrapper: {
    backgroundColor: "orange",
    padding: "7px",
    border: "1px solid white",
    borderRadius: "50%"
  },
  photoRow: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: "0 46px 42px 0",
    borderBottom: "1px solid#F1EFEF"
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column"
  },
  imageTitle: {
    margin: "0 0 8px 0",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "15px",
    color: COLORS.COOL_GREY
  },
  InputPlaceholder: {
    marginBottom: "5px",
    fontWeight: `${STYLE_CONSTS.FONT_WEIGHT_MEDIUM}`,
    fontSize: `${STYLE_CONSTS.FONT_REGULAR}`,
    color: `${COLORS.COOL_GREY}`
  },
  formRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "0 0 34px 0"
  },
  formColumn: {
    display: "flex",
    flexDirection: "column",
    width: "394px",
    margin: "0 53px 0 0 "
  }
};

export default styles;

import axios from "axios";
import { UPLOAD_URL } from "../config";
import {
  DOCUMENT_TYPES_LABELS,
  RIGHT_TO_WORK_SUBTYPES_LABELS
} from "~/src/consts";
import { getCurrentAccessToken } from "~/src/auth/manager";

/**
 * @param {{ file: File, description: string, type: string,
 *   id: string | number, table: string, fileName: string, subtype: string,
 *   issuedAt: Date, expiresAt: Date, setUploadPercentage: function}}
 * @returns {Promise<*>}
 */
export default async function uploadFile({
  file,
  description,
  type,
  id,
  table = "members",
  fileName,
  subtype,
  issuedAt,
  expiresAt,
  setUploadPercentage = null
}) {
  // Build multi-part from data.
  const data = new FormData();

  if (description) data.append("description", description);
  if (type) {
    data.append("type", type);
    data.append("typeLabel", DOCUMENT_TYPES_LABELS[type]);
  }
  if (fileName) data.append("fileName", fileName);
  if (id) data.append("ownerId", id);
  if (table) data.append("ownerTable", table);
  if (subtype) {
    data.append("subtype", subtype);
    data.append("subtypeLabel", RIGHT_TO_WORK_SUBTYPES_LABELS[subtype]);
  }

  if (issuedAt) data.append("issuedAt", issuedAt.toISOString());
  if (expiresAt) data.append("expiresAt", expiresAt.toISOString());

  data.append("bytes", file.size);
  data.append("file", file);

  const token = await getCurrentAccessToken();

  return axios.post(UPLOAD_URL, data, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    ...(setUploadPercentage && {
      onUploadProgress: progressEvent => {
        const progress = Math.round(
          (100 * progressEvent.loaded) / progressEvent.total
        );
        setUploadPercentage(progress);
      }
    })
  });
}

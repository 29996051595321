import styled from "styled-components";

import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";

export const ListContainer = styled.div`
  min-height: 130px;
  padding: ${STYLE_CONSTS.UNIT_3};
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  padding: ${STYLE_CONSTS.UNIT_3} 0;
  border-bottom: ${STYLE_CONSTS.SMALL_DIVIDER_BORDER};
  &:last-child {
    border-bottom: none;
  }
`;

export const FlexBasis = styled.div`
  flex-basis: ${({ basis }) => basis || "25%"};
`;

export const FlexCol = styled.div`
  flex: ${({ width }) => `${width}px` || 0};
`;

export const EmptyBookings = styled.div`
  text-align: center;
  padding: ${STYLE_CONSTS.UNIT_8};
  color: ${COLORS.COOL_GREY};
`;

export const LoadMoreWrapper = styled.div`
  margin-top: ${STYLE_CONSTS.UNIT_3};
  text-align: center;
`;

export const Text = styled.div`
  font-size: ${({ isSmall }) =>
    isSmall ? STYLE_CONSTS.FONT_SMALL : STYLE_CONSTS.FONT};
  color: ${({ isSmall }) => isSmall && COLORS.COOL_GREY};
`;

export const TabContainer = styled.div`
  border-bottom: ${STYLE_CONSTS.SMALL_DIVIDER_BORDER};
  margin: 0 ${STYLE_CONSTS.UNIT_1};
  padding-bottom: ${STYLE_CONSTS.UNIT_3};
`;

export const Tab = styled.span`
  margin-right: ${STYLE_CONSTS.UNIT_4};
  color: ${({ isActive, theme }) =>
    isActive ? theme.primary.main : theme.neutral.main};
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_MEDIUM};
  cursor: pointer;
`;

export const HoverHighlight = styled.div`
  transition: opacity 0.2s ease-in;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`;

export const GraphContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
`;

export const ButtonWrapper = styled.div``;

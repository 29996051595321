import styled from "styled-components";
import { COLORS } from "~/src/styles/config.style";

export const StyledAvailability = styled.div`
  width: 100%;
  height: calc(100% - 50px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
`;

export const StyledDropdownHeading = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledHoursWorkedBarContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 10px 0;
`;

export const StyledBarContainer = styled.div`
  height: 20px;
  width: 80%;
  background-color: ${({ theme }) => theme.neutral.light};
  border-radius: 50px;
  display: flex;
  align-items: center;
`;

export const StyledBarFiller = styled.div`
  height: 80%;
  margin: 0px 2px;
  width: ${({ hoursCompletedPercentage }) => hoursCompletedPercentage}%;
  background-color: ${({ theme }) => theme.primary.main};
  border-radius: inherit;
  text-align: right;
  position: relative;
`;

export const StyledBarLabel = styled.div`
  height: 5px;
  width: 5px;
  padding: 5px;
  margin-top: 3px;
  margin-right: 3px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.white};
  position: absolute;
  right: ${({ hoursCompletedPercentage }) =>
    hoursCompletedPercentage === 0 ? "-20px" : "0px"};
`;

export const StyledBarNumber = styled.p`
  font-weight: bold;
  position: absolute;
  width: max-content;
  margin-top: -30px;
  padding: 0px 10px;
  border-left: 1px solid ${({ theme }) => theme.primary.main};
`;

export const StyledHoursWorkedHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledSubHeader = styled.h4`
  margin-right: 5px;
`;

export const StyledInput = styled.input`
  height: auto;
  border: ${({ isError, theme }) =>
    `1px solid ${isError ? theme.error.main : theme.neutral.main}`};
  border-radius: 35px;
  text-align: center;
  padding: 7px;
  width: 90px;
  margin: 0 5px;
  color: ${({ disabled, theme }) =>
    disabled ? theme.neutral.light : theme.primary.main};
  font-size: 12px;
  background: white;
  &:focus {
    outline: ${({ isError, theme }) =>
      `1px solid ${isError ? theme.error.main : theme.primary.main}`};
  }
`;

export const StyledSwitchHeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledNumberInput = styled.input`
  background: white;
  color: ${({ disabled, theme }) =>
    disabled ? theme.text.secondary : theme.primary.main};
  text-align: center;
  border: solid 1px ${({ theme }) => theme.neutral.light};
  width: 50px;
  height: 23px;
  line-height: 23px;
  border-radius: 20px;
  margin: 0 5px;
  font-size: 11px;
`;

export const StyledRowContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const StyledP = styled.p`
  color: ${({ disabled, theme }) =>
    disabled ? theme.text.secondary : theme.text.primary};
  font-size: 12px;
  font-family: Gotham A, Gotham B;
  line-height: 18px;
  margin: 10px 0;
`;

export const StyledCount = styled.span`
  color: ${({ disabled, theme }) =>
    disabled ? theme.text.secondary : theme.primary.main};
  font-weight: 400;
  font-size: 14px;
  font-family: Gotham A, Gotham B;
  padding-left: 5px;
`;

export const StyledSubHeading = styled.div`
  margin: 10px 0;
  font-size: 14px;
  color: ${({ disabled, theme }) =>
    disabled ? theme.text.secondary : theme.text.primary};
`;

export const StyledSmallBoldP = styled.p`
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  color: ${({ disabled, theme }) =>
    disabled ? theme.text.secondary : theme.text.primary};
`;

export const StyledCircle = styled.div`
  border-radius: 50%;
  border: solid 1px;
  margin-left: 5px;
  width: 17px;
  height: 17px;
  cursor: pointer;
  color: ${({ disabled, theme }) =>
    disabled ? theme.neutral.light : theme.neutral.dark};
  :hover {
    color: ${({ disabled, theme }) =>
      disabled ? theme.neutral.light : theme.white};
    background-color: ${({ disabled, theme }) =>
      disabled ? "none" : theme.primary.main};
  }
`;

export const StyledInfoBox = styled.div`
  display: none;
  margin-top: 40px;
  position: absolute;
  top: 5px;
  left: 10px;
  width: 296px;
  background-color: ${({ theme }) => theme.white};
  z-index: 999;
  padding: 19px;
  color: ${({ theme }) => theme.neutral.main};
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
`;

export const StyledInfo = styled.div`
  border-radius: 50%;
  border: solid 1px;
  margin-left: 5px;
  width: 15px;
  height: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin-right: 5px;
  line-height: 18px;
  padding-left: 1px;
  &:hover + ${StyledInfoBox} {
    display: block;
  }
  color: ${({ disabled, theme }) =>
    disabled ? theme.text.secondary : theme.text.primary};
`;

export const StyledPlus = styled.p`
  margin: 0;
  text-align: center;
  line-height: 16px;
`;

export const StyledBreak = styled.div`
  height: 10px;
  border-bottom: solid 0.6px #c0ccda;
`;

export const StyledExceptionsContainer = styled.div`
  padding-top: 10px;
  width: 100%;
  display: flex;
  flex-flow: wrap;
  align-content: flex-start;
`;

export const StyledException = styled.div`
  font-size: 12px;
  margin: 5px;
  padding: 0 10px;
  width: max-content;
  height: max-content;
  display: flex;
  border: 1px solid ${({ theme }) => theme.neutral.dark};
  border-radius: 50px;
  align-items: center;
  color: ${({ theme }) => theme.primary.main};
  p {
    display: inline;
  }
  button {
    width: 12px;
    height: 12px;
    margin-left: 10px;
    svg {
      width: 12px;
    }
  }
`;

export const StyledSelect = styled.select`
  border: none;
  font-weight: bold;
  border-bottom: black solid 1px;
  text-align-last: center;
`;

export const StyledButtonsContainer = styled.div`
  padding-top: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  bottom: 0;
  position: sticky;
  background: white;
`;

export const StyledErrorMessage = styled.p`
  font-size: 12px;
  font-family: Gotham A, Gotham B;
  color: ${COLORS.RED};
  padding-left: 10px;
`;

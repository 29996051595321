import { gql } from "@apollo/client";

export default gql`
  fragment MemberFragment on member {
    startDate
    endDate
    inviteCode
    isSignedUp
    isPushNotificationsEnabled
    lastSeenAt
    id
    firstName
    lastName
    middleName
    photo
    phone
    email
    rating
    state
    venueId
    isAsleep
    birthDate
    gender
    nationality
    ethnicity
    disability
    notes
    houseNumber
    streetName
    town
    postcode
    county
    country
    emergencyContactTitle
    emergencyContactName
    emergencyContactNumber
    emergencyContactRelationship
    emergencyContactEmail

    ... on member {
      bankName
      accountName
      sortCode
      rollNumber
      accountNumber
    }

    NINumber
    internalId
    serviceAreas {
      name
      id
    }

    metadata
    title
    nickname
    updatedBy
    appVersion
    platform

    relationship(selectedAccountId: $selectedAccountId) {
      id
      poolType
    }

    rightToWorkExpiresAt
    isRightToWorkValid
    memberType
    ownerId
    isInsideIR35
    annualSalary
    weeklyContractedHours
    dbsCertificateNumber
    dbsIssueDate
    isLimitedHours
    limitedHours
    isLimitedHoursExpire
    limitedHoursExpireAt

    accounts {
      isMemberAvailabilityEnabled
    }
  }
`;

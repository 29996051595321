import React, { useEffect } from "react";
import { connect } from "react-redux";
import { RotaButton } from "@teamrota/rota-design";
import get from "lodash/fp/get";

import FormStepSegment from "../FormStepSegment";
import Textarea from "~/src/components/form-components/textarea";
import Select from "~/src/components/form-components/select";
import { Actions, HeaderTitle, Grow, Header, EditButton } from "../styles";
import {
  useBriefingTemplates,
  useUniformOptions,
  useUpdateBriefing,
  getFormTitle,
  mapStateToProps
} from "./selectors";
import { useValidateRequirements } from "../shared";
import useGetLastUsedUniformAndBriefing from "~/src/containers/requester-calendar/graphql/get-last-used-uniform-and-briefing";
import { sortOptionsMakingLastUsedFirst } from "~/src/containers/requester-calendar/utils.js";

const FormRequirements = props => {
  const {
    lastUsedUniform,
    lastUsedBriefing,
    isLoadingLastUsedUniformAndBriefing
  } = useGetLastUsedUniformAndBriefing({
    venueId: props.shift.venueId,
    roleRateId: props.shift.roleRateId,
    partnerAccountId: props.targetAccountId
  });

  const sortedUniformOptions = sortOptionsMakingLastUsedFirst(
    props.uniformOptions || [],
    lastUsedUniform?.id,
    "identifier"
  );
  const sortedBriefingTemplates = sortOptionsMakingLastUsedFirst(
    props.briefingTemplates || [],
    lastUsedBriefing?.id,
    "identifier"
  );

  const briefingTemplates =
    useBriefingTemplates({ briefingTemplates: sortedBriefingTemplates }) || [];
  const uniformOptions =
    useUniformOptions({ uniformOptions: sortedUniformOptions }) || [];
  const onUpdateBriefing = useUpdateBriefing(props);
  const isRequirementsValid = useValidateRequirements(props);

  const selectedBriefing = get("shift.privates.fixedLabel", props);
  const selectedUniformId = get("shift.uniformTemplateId", props);
  const titles = getFormTitle(props);

  useEffect(() => {
    const uniformTemplateId = lastUsedUniform?.id || null;
    const briefing = lastUsedBriefing?.content || "";
    const briefingId = lastUsedBriefing?.id || null;
    const fixedLabel = lastUsedBriefing?.identifier || "";

    if (!isLoadingLastUsedUniformAndBriefing) {
      props.handleShiftUpdate({
        uniformTemplateId,
        briefing,
        briefingId,
        privates: {
          ...props.shift.privates,
          fixedLabel
        }
      });
    }
  }, [isLoadingLastUsedUniformAndBriefing]);

  return (
    <FormStepSegment
      isOpen={props.isOpen}
      stepNumber={props.stepNumber}
      title={() => (
        <Header>
          {props.hasVisited && !props.isOpen ? (
            <Grow>
              {titles.map((title, index) => (
                <HeaderTitle key={index}>{title}</HeaderTitle>
              ))}
            </Grow>
          ) : (
            <HeaderTitle>Uniform & Briefing</HeaderTitle>
          )}
          {props.hasVisited && !props.isOpen && (
            <EditButton onClick={props.goToSelf}>Edit</EditButton>
          )}
        </Header>
      )}
    >
      {() => (
        <div>
          <Select
            label="Select the Job Uniform"
            shouldFixLabelHeight
            placeholder="Uniform"
            value={selectedUniformId}
            options={uniformOptions}
            onChange={uniformTemplateId => {
              props.handleShiftUpdate({ uniformTemplateId });
            }}
            isSmall
          />

          <Select
            label="Select a Briefing"
            shouldFixLabelHeight
            shouldShowContentAsSelectedLabel
            options={briefingTemplates}
            placeholder="Briefing Templates"
            onChange={onUpdateBriefing}
            value={selectedBriefing}
            isSmall
          />

          <Textarea
            value={props.shift.briefing}
            placeholder="Notes..."
            minRows={4}
            maxRows={4}
            isMultilineAuto
            overrideOrange
            isExtendable
            onChangeValue={briefing => {
              props.handleShiftUpdate({ briefing });
            }}
          />

          <Actions hasTopMargin>
            <RotaButton
              {...(!!isRequirementsValid && {
                onClick: props.goToNext
              })}
              disabled={!isRequirementsValid}
            >
              {props.hasCompletedAllSteps ? "Done" : "Next"}
            </RotaButton>
          </Actions>
        </div>
      )}
    </FormStepSegment>
  );
};

export default connect(mapStateToProps)(FormRequirements);

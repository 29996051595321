import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";

export interface Venue {
  id: string;
  name: string;
}

const GET_PARTNER_VENUES = gql`
  query getPartnerVenues($partnerId: ID!) {
    account {
      id
      targetAccount(accountId: $partnerId) {
        id
        venues {
          id
          name
        }
      }
    }
  }
`;

const usePartnerVenues = ({ partnerId }: { partnerId: string | null }) => {
  const { data, loading, error } = useQuery(GET_PARTNER_VENUES, {
    variables: { partnerId },
    skip: !partnerId
  });

  const venues: Venue[] = data?.account?.targetAccount?.venues || [];

  return {
    venues,
    loading,
    error
  };
};

export default usePartnerVenues;

import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { useTheme } from "styled-components";
import { useQuery, useMutation } from "@apollo/client";
import {
  iconNew,
  RotaButtonNew,
  RotaSwitchWithLabel,
  RotaDropdown,
  RotaSnackBar
} from "@teamrota/rota-design";

import {
  setDate,
  getShiftTimes,
  getTimeFromDate
} from "~/src/utils/time-utils";
import GET_ACCOUNT_MEMBER_AVAILABILITY from "../../graphql/get-account-member-availability";
import UPDATE_ACCOUNT_MEMBER_AVAILABILITY from "../../graphql/update-account-member-availability";

import {
  Title,
  Text,
  StyledWrapper,
  ButtonWrapper,
  TimeWrapper,
  TimeDropDownWrapper,
  IconTextWrapper
} from "./availability-tab-content.styles";

const { Moon, Sun } = iconNew;

const AvailabilityTabContent = () => {
  const theme = useTheme();

  const { data } = useQuery(GET_ACCOUNT_MEMBER_AVAILABILITY, {
    fetchPolicy: "cache-and-network"
  });

  const [accountAvailability, setAccountAvailability] = useState({});
  const [isStartTimeError, setIsStartTimeError] = useState(false);
  const [isEndTimeError, setIsEndTimeError] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [snackbar, setSnackBar] = useState({});

  useEffect(() => {
    if (data) {
      const account = data?.user?.account;

      setAccountAvailability({
        isEnabled: account?.isMemberAvailabilityEnabled,
        startTime: getTimeFromDate(account?.memberAvailabilityDefaultStartTime),
        endTime: getTimeFromDate(account?.memberAvailabilityDefaultEndTime)
      });
    }
  }, [data]);

  const { isEnabled, startTime, endTime } = accountAvailability;

  const [updateAccountMemberAvailability, { loading }] = useMutation(
    UPDATE_ACCOUNT_MEMBER_AVAILABILITY,
    {
      refetchQueries: ["getAccountMemberAvailability"],
      onCompleted: () =>
        setSnackBar({
          open: true,
          severity: "success",
          message: "Updated successfully."
        }),
      onError: () =>
        setSnackBar({
          open: true,
          severity: "error",
          message: "Something went wrong. Please try again."
        })
    }
  );

  const handleOnSave = async () => {
    await updateAccountMemberAvailability({
      variables: {
        isMemberAvailabilityEnabled: isEnabled,
        memberAvailabilityDefaultStartTime: setDate(startTime),
        memberAvailabilityDefaultEndTime: setDate(endTime)
      }
    });
  };

  const handleUpdateTime = (type, time) => {
    if (type === "startTime") {
      if (moment(time, "HH:mm").isAfter(moment(endTime, "HH:mm"))) {
        setIsStartTimeError(true);
      } else {
        setIsStartTimeError(false);
        setIsButtonDisabled(false);
      }

      setIsEndTimeError(false);
      handleChange(type, time);
    }

    if (type === "endTime") {
      if (moment(time, "HH:mm").isAfter(moment(startTime, "HH:mm"))) {
        setIsEndTimeError(false);
        setIsButtonDisabled(false);
      } else {
        setIsEndTimeError(true);
      }

      setIsStartTimeError(false);
      handleChange(type, time);
    }
  };

  const handleChange = (id, value) => {
    setAccountAvailability({
      ...accountAvailability,
      [id]: value,
      ...(id === "isEnabled" && !value ? { startTime: "", endTime: "" } : {})
    });
  };

  return (
    <StyledWrapper>
      <Title>Availability</Title>
      <RotaSwitchWithLabel
        name="isEnabled"
        checked={isEnabled}
        onChange={e => {
          handleChange("isEnabled", e.target.checked);
          setIsButtonDisabled(false);
        }}
      >
        Enable Availability
      </RotaSwitchWithLabel>

      <Text marginTop={15}>
        {isEnabled
          ? "Members will be able to set their availability in the member app and will be displayed as available."
          : "Members will not see the availability feature in the app and will be displayed as available by default."}
      </Text>

      {isEnabled && (
        <Text marginBottom={10}>
          Select default start and end times for a day shift. Hours outside of
          this will be considered night shift
        </Text>
      )}

      {isEnabled && (
        <>
          <TimeWrapper>
            <TimeDropDownWrapper>
              <RotaDropdown
                value={startTime}
                label="Start time"
                isLabelTop
                width="300px"
                options={getShiftTimes()}
                isError={isStartTimeError}
                errorMessage={
                  isStartTimeError && "Start time must be before the end"
                }
                onChange={value => handleUpdateTime("startTime", value)}
              />
            </TimeDropDownWrapper>

            <TimeDropDownWrapper>
              <RotaDropdown
                value={endTime}
                label="End time"
                isLabelTop
                width="300px"
                options={getShiftTimes()}
                isError={isEndTimeError}
                errorMessage={
                  isEndTimeError && "End time must be after the start"
                }
                onChange={value => handleUpdateTime("endTime", value)}
              />
            </TimeDropDownWrapper>
          </TimeWrapper>

          <IconTextWrapper>
            <Sun color={theme.primary.main} />
            <Text marginLeft={8}>Day Shift: Selected Hours</Text>
          </IconTextWrapper>
          <IconTextWrapper>
            <Moon color={theme.primary.main} />
            <Text marginLeft={8}>Night Shift: Outside Hours</Text>
          </IconTextWrapper>
        </>
      )}

      <ButtonWrapper>
        <RotaButtonNew
          onClick={handleOnSave}
          isLoading={loading}
          isDisabled={isButtonDisabled || isStartTimeError || isEndTimeError}
        >
          Save
        </RotaButtonNew>
      </ButtonWrapper>

      <RotaSnackBar
        onClose={() => setSnackBar({})}
        snackOpen={snackbar?.open}
        severity={snackbar?.severity}
        message={snackbar?.message}
      />
    </StyledWrapper>
  );
};

export default AvailabilityTabContent;

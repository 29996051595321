import styled from "styled-components";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";

export default styled.div`
  margin: ${STYLE_CONSTS.UNIT_4} ${STYLE_CONSTS.UNIT_2};
  text-align: center;
  color: ${COLORS.COOL_GREY};
  padding: 30px;
  font-size: ${STYLE_CONSTS.FONT_LARGE};
  font-weight: 400;
`;

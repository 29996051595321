import React from "react";
import { useDrop } from "react-dnd";
import { DeleteBadgeWrapper, DeleteIcon } from "./delete-zone.styles";
import Icon from "../../../../../components/icon/";
import { DND_ITEM_TYPES } from "~/src/consts";

const DeleteZone = ({ onDropMember }) => {
  const [{ isOver, canDrop, isGroupMember }, drop] = useDrop(
    () => ({
      accept: DND_ITEM_TYPES.POOL_MEMBER,
      drop: member => onDropMember(member),
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        isGroupMember: monitor.getItem()?.isGroupMember
      })
    }),
    [onDropMember]
  );

  const isActive = canDrop && isOver;

  return (
    <div ref={drop}>
      <DeleteBadgeWrapper isVisible={isGroupMember && (canDrop || isOver)}>
        <DeleteIcon
          name={Icon.names.DELETE}
          size={Icon.sizes.XLARGE}
          color={isActive ? Icon.colors.RED : Icon.colors.COOL_GREY}
          isVisible={isGroupMember && (canDrop || isOver)}
        />
      </DeleteBadgeWrapper>
    </div>
  );
};

export default DeleteZone;

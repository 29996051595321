import React from "react";
import styled, { css } from "styled-components";
import omit from "lodash/fp/omit";
import DateInputRaw from "~/src/components/form-components/date-input-raw";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const Row = styled.div`
  display: flex;
`;

export const SmallText = styled.small`
  color: ${({ theme }) => theme.error.main};
`;

const inlineFlex = css`
  display: flex;
  align-items: center;
`;

export const Col = styled.div`
  &:first-child {
    margin-right: ${STYLE_CONSTS.UNIT_1};
  }
  &:last-child {
    margin-left: ${STYLE_CONSTS.UNIT_1};
  }
  ${props => (props.isInline ? inlineFlex : "")};
`;

export const Label = styled.span`
  margin-right: ${STYLE_CONSTS.UNIT_1};
  margin-top: -10px;
`;

export const Input = styled(props => (
  <DateInputRaw {...omit("isInvalid", props)} />
))`
  height: 34px;
  border: 1px solid ${({ theme }) => theme.neutral.main};
  width: 124px;
  padding: 0 ${STYLE_CONSTS.UNIT_2};
  border-radius: ${parseInt(STYLE_CONSTS.BTN_HEIGHT_L, 10) / 2}px;
  border-color: ${({ isInvalid, theme }) =>
    isInvalid ? theme.error.main : theme.neutral.main};
  &:focus {
    outline: none;
  }
`;

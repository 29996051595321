import React from "react";
import Modal from "~/src/components/modal";
import { Wrapper, ButtonWrapper, StyledExitButton } from "./styles";
import { RotaButton } from "@teamrota/rota-design";

const BlackAndOrangeModal = ({ isOpen, onClose, config = {} }) => {
  const {
    subComponent,
    falseButtonText,
    confirmButtonText,
    onConfirm,
    disableConfirmButton = false
  } = config;

  const handleOnConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isOverlay>
      <Wrapper>
        <StyledExitButton isGrey isXSmall onClick={onClose} size="8" />
        {subComponent()}
        <ButtonWrapper>
          <RotaButton variant="outlined" onClick={onClose}>
            {falseButtonText}
          </RotaButton>
          <span style={{ width: 10 }} />
          {disableConfirmButton && (
            <RotaButton disabled onClick={() => {}}>
              {confirmButtonText}
            </RotaButton>
          )}
          {!disableConfirmButton && (
            <RotaButton onClick={handleOnConfirm}>
              {confirmButtonText}
            </RotaButton>
          )}
        </ButtonWrapper>
      </Wrapper>
    </Modal>
  );
};

export default BlackAndOrangeModal;

import React from "react";
import MemberPhoto from "~/src/components/member-photo";
import { Wrapper, Content } from "./member.styles";

export default ({ member }) => (
  <Wrapper>
    <MemberPhoto isInline src={member?.photo} />
    <Content>
      {member?.firstName} {member?.lastName}
    </Content>
  </Wrapper>
);

import { graphql } from "@apollo/client/react/hoc";
import moment from "moment-timezone";
import { flow } from "lodash";
import getOr from "lodash/fp/getOr";
import get from "lodash/fp/get";
import update from "lodash/fp/update";

import { withAuth } from "~/src/auth/hoc";
import { handleErrors } from "~/src/utils/errors";

import SHIFTS_QUERY from "~/src/graphql/queries/get-shifts/get-shifts.query";

const getIfDateValid = dateStr =>
  dateStr && moment(dateStr, "DD/MM/YYYY").isValid()
    ? moment(dateStr, "DD/MM/YYYY").toString()
    : null;

export default limit =>
  flow(
    graphql(SHIFTS_QUERY, {
      props: handleErrors(({ data: { loading, fetchMore, account } }) => ({
        areShiftsLoading: loading,
        shifts: account?.shifts?.data ?? [],
        totalResults: account?.shifts?.totalResults ?? 0,
        fetchMore: offset => loadMore(offset, fetchMore)
      })),
      options: ({
        auth,
        selectedFilters,
        selectedFilterOptions,
        shiftSearchText
      }) => {
        const getFilterProp = path => get(path, selectedFilters) || undefined;

        return {
          variables: auth.addVals(SHIFTS_QUERY, {
            accountType: "provider",
            offset: 0,
            limit,
            withBookings: false, // Don't want bookings
            bookingsLimit: 0,
            bookingsOffset: 0,
            searchText: shiftSearchText,
            roleIds:
              getFilterProp("roles") &&
              getOr([], "roles", selectedFilterOptions).map(role => role.id),
            serviceAreaId:
              getFilterProp("service-area") &&
              get("service-area.id", selectedFilterOptions),
            isFinalised: getFilterProp("shift-finalised"),
            isCancelled: getFilterProp("shift-cancelled"),
            startTimeRangeStart:
              getFilterProp("shift-date") &&
              getIfDateValid(
                get(["shift-date", "startTime"], selectedFilterOptions)
              ),
            startTimeRangeEnd:
              getFilterProp("shift-date") &&
              getIfDateValid(
                get(["shift-date", "endTime"], selectedFilterOptions)
              )
          })
        };
      }
    }),
    withAuth
  );

export function loadMore(offset, fetchMore) {
  return fetchMore({
    notifyOnNetworkStatusChange: true,
    variables: { offset },
    updateQuery: (previousResult, { fetchMoreResult }) =>
      update(
        "account.shifts.data",
        current => [...current, ...get("account.shifts.data", fetchMoreResult)],
        previousResult
      )
  });
}

import { createReducer, createAction } from "~/src/utils";

export const ON_UPDATE_SEARCH = "provide/ON_UPDATE_SEARCH";
export const ON_UPDATE_ORDER_BY = "provide/ON_UPDATE_ORDER_BY";
export const ON_UPDATE_SERVICE_AREA = "provide/ON_UPDATE_SERVICE_AREA";

export const onUpdateSearch = createAction(ON_UPDATE_SEARCH);
export const onUpdateOrderBy = createAction(ON_UPDATE_ORDER_BY);
export const onUpdateServiceArea = createAction(ON_UPDATE_SERVICE_AREA);

export const ORDER_OPTIONS = [
  {
    label: "Name",
    value: "accountName",
    orderDirection: "ASC"
  },
  {
    label: "Posted most recently",
    value: "postedMostRecently",
    orderDirection: "DESC"
  },
  {
    label: "Most bookings posted",
    value: "mostBookingsPosted",
    orderDirection: "DESC"
  }
];

const DEFAULT_STATE = {
  searchText: "",
  orderBy: ORDER_OPTIONS[0],
  serviceAreaId: ""
};

export const BOOKING_LIMIT = 5;

export default createReducer(
  {
    [ON_UPDATE_SEARCH]: (state, { payload }) => ({
      ...state,
      searchText: payload
    }),
    [ON_UPDATE_ORDER_BY]: (state, { payload }) => ({
      ...state,
      orderBy: {
        value: payload.value,
        label: payload.label,
        orderDirection: payload.orderDirection
      }
    }),
    [ON_UPDATE_SERVICE_AREA]: (state, { payload }) => ({
      ...state,
      serviceAreaId: payload
    })
  },
  DEFAULT_STATE
);

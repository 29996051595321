import React, { useState } from "react";
import moment from "moment-timezone";
import styled from "styled-components";
import TimeField from "time-input";
import { RotaButtonIcon, iconNew } from "@teamrota/rota-design";

import TimeDisplay from "~/src/components/shift-time-display";

const { Edit, Tick } = iconNew;

const OuterWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.neutral.light};
  box-sizing: border-box;
  border-radius: 4px;
  width: 100px;
  height: 32px;
  display: flex;
  align-items: center;
`;

const Input = styled(TimeField)`
  > input {
    width: 100%;
    border: none;
    text-align: center;
    &:focus {
      outline: none;
    }
  }
`;

const syncEndDate = (startDate: moment.Moment, endDate: moment.Moment) => {
  const endClone = endDate.clone();
  if (endClone.isBefore(moment(startDate))) {
    endClone.add(1, "day");
  } else if (endClone.diff(startDate, "days") >= 1) {
    endClone.subtract(endClone.diff(startDate, "days"), "day");
  }
  return endClone;
};

const ensureValidTime = (time: string) => {
  if (moment(time).isValid()) {
    return moment(time).format("HH:mm");
  }
  return "00:00";
};

interface onChangeStartProps {
  startTime: moment.Moment;
  endTime: moment.Moment;
  onStartChange: (v: moment.Moment) => void;
  onEndChange: (v: moment.Moment) => void;
}

export const onChangeStart = ({
  startTime,
  endTime,
  onStartChange,
  onEndChange
}: onChangeStartProps) => (value: string) => {
  const dateValue = moment(value, "HH:mm");
  const date = moment(startTime)
    .set("hours", dateValue.hours())
    .set("minutes", dateValue.minutes());

  onEndChange(syncEndDate(date, moment(endTime)));
  onStartChange(date);
};

interface onChangeEndProps {
  startTime: moment.Moment;
  endTime: moment.Moment;
  onEndChange: (v: moment.Moment) => void;
}
export const onChangeEnd = ({
  startTime,
  endTime,
  onEndChange
}: onChangeEndProps) => (value: string) => {
  const [hour, minute] = value.split(":");
  const end = moment(endTime).set({
    hour: Number(hour),
    minute: Number(minute)
  });
  onEndChange(syncEndDate(moment(startTime), end));
};

interface UpdateProps {
  scheduledStartTime: string;
  scheduledEndTime: string;
}

interface Props {
  isDisabled: boolean;
  startTime: string;
  endTime: string;
  onUpdateScheduledTime: ({
    scheduledStartTime,
    scheduledEndTime
  }: UpdateProps) => void;
}

const ScheduledHours = ({
  isDisabled,
  startTime,
  endTime,
  onUpdateScheduledTime
}: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [start, setStart] = useState(moment(startTime));
  const [end, setEnd] = useState(moment(endTime));

  if (isEditing)
    return (
      <OuterWrapper>
        <Wrapper>
          <Input
            value={ensureValidTime(start.format())}
            disabled
            onChange={onChangeStart({
              startTime: start,
              endTime: end,
              onStartChange: setStart,
              onEndChange: setEnd
            })}
          />
          -
          <Input
            value={ensureValidTime(end.format())}
            onChange={onChangeEnd({
              startTime: start,
              endTime: end,
              onEndChange: setEnd
            })}
          />
        </Wrapper>
        <RotaButtonIcon
          onClick={() => {
            onUpdateScheduledTime({
              scheduledStartTime: start.format(),
              scheduledEndTime: end.format()
            });
            setIsEditing(false);
          }}
        >
          <Tick />
        </RotaButtonIcon>
      </OuterWrapper>
    );

  return (
    <OuterWrapper>
      <TimeDisplay
        startTime={startTime}
        endTime={endTime}
        displayDate={`${moment(startTime).format("HH:mm")} - ${moment(
          endTime
        ).format("HH:mm")}`}
      />
      {!isDisabled && (
        <RotaButtonIcon onClick={() => setIsEditing(true)}>
          <Edit />
        </RotaButtonIcon>
      )}
    </OuterWrapper>
  );
};

export default ScheduledHours;

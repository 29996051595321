import React from "react";

import { POOL_TYPES } from "@teamrota/rota-common";

import {
  StyledMemberName,
  StyledIssue,
  StyledEmph,
  StyledHighlight,
  StyledGroupReason,
  StyledGroupMembers
} from "../styles";

import BottomModal from "./bottom-modal";
import Members from "./members";

const GroupMember = ({
  member,
  roleName,
  setConfirmationInput,
  confirmationInput,
  onAdd,
  onCancel
}) => {
  const isConfirmedGroup = confirmationInput?.trim()?.toLowerCase() === "yes";

  const isMemberAvailabilityEnabled =
    member?.[0]?.accounts?.[0]?.isMemberAvailabilityEnabled;

  return (
    <>
      <StyledMemberName style={{ marginTop: "32px" }}>
        Confirm shift update!
      </StyledMemberName>
      {member?.some(el => el.isOverLimitError) ? (
        <>
          <StyledGroupReason>
            These members have reached their working hours limit.
          </StyledGroupReason>
          <StyledGroupMembers>
            {member?.map(
              m => m.isOverLimitError && <Members key={m.id} member={m} />
            )}
          </StyledGroupMembers>
          <StyledIssue style={{ border: "none" }}>
            These members are over their weekly hour working limit, and so
            cannot be booked onto the shift.
          </StyledIssue>
        </>
      ) : (
        member?.some(el => el.isHitWorkingHoursLimit) && (
          <>
            <StyledGroupReason>
              These members have reached their working hours limit.
            </StyledGroupReason>
            <StyledGroupMembers>
              {member?.map(
                m =>
                  m.isHitWorkingHoursLimit && <Members key={m.id} member={m} />
              )}
            </StyledGroupMembers>
            <StyledEmph style={{ paddingBottom: "32px" }}>
              By overriding, the working hours will exceed the limit you set for
              this week
            </StyledEmph>
          </>
        )
      )}
      {member?.some(el => el.isConflicted) && (
        <>
          <StyledGroupReason>These members have conflict.</StyledGroupReason>
          <StyledGroupMembers>
            {member?.map(
              m => m.isConflicted && <Members key={m.id} member={m} />
            )}
          </StyledGroupMembers>
          <StyledEmph style={{ paddingBottom: "32px" }}>
            By overriding, the members will be booked on to two different shift
            at the same time
          </StyledEmph>
        </>
      )}
      {member?.some(el => !el.isQualifiedWithRole) && (
        <>
          <StyledGroupReason>
            These members do not have the correct role for this shift.
          </StyledGroupReason>
          <StyledGroupMembers>
            {member?.map(
              m => !m.isQualifiedWithRole && <Members key={m.id} member={m} />
            )}
          </StyledGroupMembers>
          <StyledHighlight style={{ paddingBottom: "32px" }}>
            {roleName}
          </StyledHighlight>
        </>
      )}
      {member?.some(el => !el.isQualifiedWithTags) && (
        <>
          <StyledGroupReason>
            These members do not have the correct tags for this shift.
          </StyledGroupReason>
          <StyledGroupMembers>
            {member?.map(
              m => !m.isQualifiedWithTags && <Members key={m.id} member={m} />
            )}
          </StyledGroupMembers>
        </>
      )}
      {member?.some(el => !el.isInVenueServiceArea) && (
        <>
          <StyledGroupReason>
            These members do not belong to a service area that contains the
            venue.
          </StyledGroupReason>
          <StyledGroupMembers>
            {member?.map(
              m => !m.isInVenueServiceArea && <Members key={m.id} member={m} />
            )}
          </StyledGroupMembers>
        </>
      )}
      {member?.some(el => el.isUnavailable) && (
        <>
          <StyledGroupReason>
            {isMemberAvailabilityEnabled
              ? "These members are not available for this shift."
              : "These members are unavailable for this shift."}
          </StyledGroupReason>
          <StyledGroupMembers>
            {member?.map(
              m => m.isUnavailable && <Members key={m.id} member={m} />
            )}
          </StyledGroupMembers>
        </>
      )}
      {member?.some(
        el => el.relationship?.poolType === POOL_TYPES.BLACKLISTED
      ) && (
        <>
          <StyledGroupReason>
            These members were previously marked as unsuitable.
          </StyledGroupReason>
          <StyledGroupMembers>
            {member?.map(
              m =>
                m.relationship?.poolType === POOL_TYPES.BLACKLISTED && (
                  <Members key={m.id} member={m} />
                )
            )}
          </StyledGroupMembers>
        </>
      )}
      {member?.some(el => !el.isOverLimitError) && (
        <BottomModal
          isConfirmedGroup={isConfirmedGroup}
          onChangeInput={({ target }) => setConfirmationInput(target.value)}
          handleAdd={onAdd}
          handleCancel={onCancel}
        />
      )}
    </>
  );
};

export default GroupMember;

import { gql } from "@apollo/client";

export const IMPORT_OR_REJECT_MEMBER_UNAVAILABILITY = gql`
  mutation importOrRejectMemberUnavailability($id: ID!, $reject: Boolean) {
    importOrRejectMemberUnavailability(id: $id, reject: $reject) {
      success
      data {
        startsAt
        endsAt
      }
    }
  }
`;

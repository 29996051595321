import styled from "styled-components";

import Icon from "~/src/components/icon";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const Input = styled.input`
  border-radius: ${STYLE_CONSTS.UNIT_4};
  border: 1.2px solid ${({ theme }) => theme.neutral.light};
  height: ${({ isLarge, isSmall }) => {
    if (isLarge) return STYLE_CONSTS.UNIT_7;
    if (isSmall) return "25px";
    return "30px";
  }};
  width: 100%;
  padding-left: ${({ isLarge }) =>
    isLarge ? STYLE_CONSTS.UNIT_5 : STYLE_CONSTS.UNIT_4};
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.primary.main};
  }
  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.neutral.main};
  }
`;

const getIconOffset = isLarge => (isLarge ? 12 : 7);

export const StyledIcon = styled(Icon)`
  position: absolute;
  top: ${({ isLarge, isSmall }) => {
    if (isLarge) return 14;
    if (isSmall) return 4;
    return 7;
  }}px;
  ${({ isRight, isLarge }) =>
    isRight
      ? `right: ${getIconOffset(isLarge)}px`
      : `left: ${getIconOffset(isLarge)}px`};
  display: ${({ shouldShow }) => (shouldShow ? "inherit" : "none")};
`;

export const InputWrapper = styled.div`
  position: relative;
  display: ${({ isInline }) => (isInline ? "inline-block" : "block")};
`;

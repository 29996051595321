import { gql } from "@apollo/client";

export default gql`
  fragment RelationshipsFragment on relationship {
    id
    poolType
    account {
      id
    }
  }
`;

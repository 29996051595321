import styled from "styled-components";
import { POOL_TYPES } from "@teamrota/rota-common";
import { COLORS, STYLE_CONSTS } from "~/src/styles/config.style";

export const IconContainer = styled.div`
  display: inline-block;
  height: 15px;
  width: 15px;
  position: relative;
  margin-right: ${STYLE_CONSTS.UNIT_1};
  border-radius: 50%;
  background-color: ${({ poolType, theme }) => {
    if (poolType === POOL_TYPES.FAVOURITED) return COLORS.PINK;
    if (poolType === POOL_TYPES.BLACKLISTED) return COLORS.DARK_GREY;
    return theme.primary.main;
  }};
`;

export const IconWrapper = styled.div`
  left: 3px;
  position: absolute;
`;

export const RelationshipContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
`;

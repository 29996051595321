import React, { useState, useMemo, useEffect } from "react";
import { RotaTab, RotaButton } from "@teamrota/rota-design";
import { Role } from "@teamrota/authlib";

import getProfile from "~/src/graphql/queries/get-profile/get-profile-query.decorator";
import useAuth from "~/src/auth/hooks/use-auth";
import { ROLE_TABS } from "~/src/consts";
import { useMemberRoles } from "./use-member-roles";
import { useUpdateMemberRoles } from "./use-update-member-roles";
import RoleList from "./role-list";
import {
  Wrapper,
  TabsWrapper,
  RoleListWrapper,
  EditCancelButtons,
  EnableButtonWrapper,
  Cell,
  Row
} from "./style";

const MemberRolesComponent = ({ user, memberId }) => {
  const auth = useAuth();
  const [selectedTab, setSelectedTab] = useState(ROLE_TABS[0]);
  const [updatedMemberRoles, setUpdatedMemberRoles] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  const { allRoles, memberRoles, loading, error } = useMemberRoles({
    memberId
  });

  useEffect(() => {
    if (memberRoles) {
      setUpdatedMemberRoles(memberRoles);
    }
  }, [memberRoles]);

  const { updateMemberRoles, loading: loadingSave } = useUpdateMemberRoles();

  const isProvider = auth.hasRole(Role.PROVIDER);
  const isSelfProvider = auth.hasRoles(Role.PROVIDER, Role.REQUESTER);
  const hasMemberPayPermission = auth.hasRole(Role.MEMBERS_PAY);

  const isOverrideEnabled =
    isProvider && user?.account?.isProviderPayRateOverride;

  const onUpdateMemberRoles = async () => {
    await updateMemberRoles({ memberId, roles: updatedMemberRoles });
    setIsEditing(false);
  };

  const isShowPayRateColumn =
    (isSelfProvider || isOverrideEnabled) &&
    hasMemberPayPermission &&
    selectedTab === "Calculated";

  const isShowPayTypeColumn = selectedTab === "Calculated";

  const isShowPayScaleLevelColumn =
    selectedTab === "Uncalculated" && !isEditing;

  const rolesToDisplay = useMemo(() => {
    return allRoles?.filter(role => {
      if (!isEditing) {
        return (
          role.isEnabledForMember &&
          (selectedTab === "Calculated"
            ? !role.isUncalculatedRate
            : role.isUncalculatedRate)
        );
      } else {
        return selectedTab === "Calculated"
          ? !role.isUncalculatedRate
          : role.isUncalculatedRate;
      }
    });
  }, [isEditing, selectedTab, allRoles]);

  if (loading && (!allRoles || !memberRoles)) return "Loading...";
  if (error && (!allRoles || !memberRoles))
    return "Ooops... Something went wrong";
  if (!allRoles || !memberRoles) return null;

  return (
    <Wrapper>
      <TabsWrapper>
        {ROLE_TABS.map(tab => (
          <RotaTab
            key={tab}
            label={tab}
            isActive={tab === selectedTab}
            onClick={() => setSelectedTab(tab)}
            size="md"
          />
        ))}
      </TabsWrapper>

      {!isEditing && (
        <EnableButtonWrapper>
          <RotaButton variant="outlined" onClick={() => setIsEditing(true)}>
            Enable
          </RotaButton>
        </EnableButtonWrapper>
      )}

      <RoleListWrapper>
        {!rolesToDisplay.length ? (
          <p>
            {isEditing
              ? "No roles available."
              : "No roles selected for this member."}
          </p>
        ) : (
          <>
            <Row>
              <Cell isBold>Role</Cell>
              {isShowPayTypeColumn && <Cell isBold>Pay Type</Cell>}
              {isShowPayRateColumn && (
                <Cell isBold center width="120px">
                  Pay Rate
                </Cell>
              )}

              {isShowPayScaleLevelColumn && <Cell isBold>Pay Scale Level</Cell>}
            </Row>

            <RoleList
              roles={rolesToDisplay}
              isUncalculatedRoles={selectedTab === "Uncalculated"}
              allRoles={allRoles}
              isEditing={isEditing}
              isShowPayRateColumn={isShowPayRateColumn}
              isShowPayTypeColumn={isShowPayTypeColumn}
              isShowPayScaleLevelColumn={isShowPayScaleLevelColumn}
              updatedMemberRoles={updatedMemberRoles}
              setUpdatedMemberRoles={setUpdatedMemberRoles}
            />
          </>
        )}
      </RoleListWrapper>
      {isEditing && (
        <EditCancelButtons>
          <RotaButton
            onClick={() => setIsEditing(false)}
            variant="secondary"
            style={{ marginRight: 24 }}
          >
            Cancel
          </RotaButton>
          <RotaButton
            isLoading={loadingSave}
            onClick={() => onUpdateMemberRoles()}
          >
            Save
          </RotaButton>
        </EditCancelButtons>
      )}
    </Wrapper>
  );
};

export default getProfile(MemberRolesComponent);

import styled from "styled-components";
import { hexToRGBA } from "~/src/styles/style-helpers";
import { STYLE_CONSTS } from "~/src/styles/config.style";

const BAR_WIDTH = 12;
const MAX_BAR_HEIGHT = 54;
const MAX_RATING = 6;

function getBarHeight({ isOverlay, rating }) {
  const barHeight = MAX_RATING / parseInt(rating, 10);
  if (isOverlay) {
    return MAX_BAR_HEIGHT - MAX_BAR_HEIGHT / barHeight;
  }

  return MAX_BAR_HEIGHT / barHeight;
}

export const Bar = styled.div`
  height: ${getBarHeight}px;
  width: ${BAR_WIDTH}px;
  background: ${({ rating, isOverlay, theme }) => {
    if (isOverlay) return hexToRGBA(theme.background.primary, 0.25);
    if (rating <= 3) return theme.background.primary;
    return STYLE_CONSTS.PRIMARY_GRADIENT;
  }};
  margin-right: ${STYLE_CONSTS.UNIT_HALF};
`;

export const BarContainer = styled.div`
  position: relative;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-right: ${STYLE_CONSTS.UNIT_1};
  min-height: ${MAX_BAR_HEIGHT}px;
`;

export const RatingContainer = styled.div`
  position: relative;
`;

export const Label = styled.p`
  font-size: ${STYLE_CONSTS.FONT_SMALL};
  color: ${({ theme }) => theme.text.primary};
`;

export const CountContainer = styled.div`
  margin-left: ${STYLE_CONSTS.UNIT_1};
`;

export const Count = styled.div`
  font-size: ${STYLE_CONSTS.FONT_SMALL};
`;

export const Rating = styled.span`
  margin-left: ${STYLE_CONSTS.UNIT_HALF};
`;

export const IconWrapper = styled.div`
  float: left;
  width: 11px;
  text-align: center;
`;

import React, { Fragment, useContext, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { langs } from "@teamrota/authlib";

import { RolesContext } from "~/src/containers/settings-page/context/roles-context";

import {
  Styled_Tr,
  StyledIcon,
  StyledTdType,
  StyledChevronName,
  StyledName,
  StyledTdRead,
  StyledTdUpdated,
  StyledTdCreated
} from "./permissions-tab-content.styles";
import { RotaCheckbox } from "@teamrota/rota-design";

export const renderBranch = (branch, id, isParentSelect, nestingLevel = 0) => {
  const { roles, setRoles, setHasChanged, isEditable, isSearched } = useContext(
    RolesContext
  );
  const indent = <span style={{ marginLeft: 30 * nestingLevel }} />;
  const isCreate = roles?.includes(branch.crud.c) || false;
  const isRead = roles?.includes(branch.crud.r) || false;
  const isUpdate = roles?.includes(branch.crud.u) || false;
  const fragmentKey = uuidv4();
  const elRef = useRef("");

  const handleChange = (isChecked, role) => {
    setHasChanged(true);

    if (!isChecked && !roles.includes(role) && role === "PARTNER_EDIT") {
      setRoles([role, "PARTNER_VIEW", ...roles]);
    } else {
      setRoles([role, ...roles]);
    }

    if (isChecked && roles.includes(role)) {
      const newRoles = [...roles];
      newRoles.splice(newRoles.indexOf(role), 1);
      setRoles(newRoles);
    }
  };

  useEffect(() => {
    if (elRef?.current?.id == isSearched) {
      elRef?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  });

  const branchRow = (
    <Styled_Tr
      key={name + id}
      isOdd={Boolean(id % 2)}
      isSelect={isSearched == langs.en[branch.name] || isParentSelect}
    >
      {branch.branches.length > 0 ? (
        <StyledTdType>
          <div id={langs.en[branch.name]} ref={elRef}>
            {indent}
            <StyledIcon name="CHEVRON" size="SMALL" color="#939BA7" />
            <StyledChevronName>{langs.en[branch.name]}</StyledChevronName>
          </div>
        </StyledTdType>
      ) : (
        <StyledTdType>
          <div id={langs.en[branch.name]} ref={elRef}>
            {indent}
            <StyledName>{langs.en[branch.name]}</StyledName>
          </div>
        </StyledTdType>
      )}
      <StyledTdRead>
        {branch.crud.r && (
          <RotaCheckbox
            onClick={() =>
              handleChange(
                branch.name === "PARTNER" && isUpdate ? true : isRead,
                branch.crud.r
              )
            }
            isDisabled={isEditable}
            isChecked={branch.name === "PARTNER" && isUpdate ? true : isRead}
          />
        )}
      </StyledTdRead>
      <StyledTdUpdated>
        {branch.crud.u && (
          <RotaCheckbox
            onClick={() => handleChange(isUpdate, branch.crud.u)}
            isDisabled={isEditable}
            isChecked={isUpdate}
          />
        )}
      </StyledTdUpdated>
      <StyledTdCreated>
        {branch.crud.c && (
          <RotaCheckbox
            onClick={() => handleChange(isCreate, branch.crud.c)}
            isDisabled={isEditable}
            isChecked={isCreate}
          />
        )}
      </StyledTdCreated>
    </Styled_Tr>
  );

  const childBranchRows = [];
  for (let child of branch.branches) {
    childBranchRows.push(
      renderBranch(
        child,
        `${id}-${nestingLevel}|`,
        isParentSelect,
        nestingLevel + 1
      )
    );
  }

  return (
    <Fragment key={fragmentKey}>
      {branchRow}
      {childBranchRows}
    </Fragment>
  );
};

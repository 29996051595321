import React from "react";
import { StyledUser } from "./styles";

const User = ({ user, children }) => {
  return (
    <StyledUser key={user.id}>
      <div>
        <p>
          {user.firstName} {user.lastName}
        </p>
        <span>ID: {user.id}</span>
        <span>{user.email}</span>
      </div>
      {children}
    </StyledUser>
  );
};

export default User;

import React from "react";
import moment from "moment-timezone";
import { Wrapper, Input } from "./scheduled-hours-time-field.styles";

const syncEndDate = (startDate, endDate) => {
  const endClone = endDate.clone();
  if (endClone.isBefore(moment(startDate))) {
    endClone.add(1, "day");
  } else if (endClone.diff(startDate, "days") >= 1) {
    endClone.subtract(endClone.diff(startDate, "days"), "day");
  }
  return endClone;
};

const ensureValidTime = time => {
  if (moment(time).isValid()) {
    return moment(time).format("HH:mm");
  }
  return "00:00";
};

export const onChangeStart = ({
  start,
  end,
  onStartChange,
  onEndChange
}) => value => {
  const dateValue = moment(value, "HH:mm");
  const date = moment(start)
    .set("hours", dateValue.hours())
    .set("minutes", dateValue.minutes());

  onEndChange(syncEndDate(date, moment(end)).toDate());
  onStartChange(date.toDate());
};

export const onChangeEnd = ({ start, end, onEndChange }) => value => {
  const [hour, minute] = value.split(":");
  const endTime = moment(end).set({ hour, minute });
  onEndChange(syncEndDate(moment(start), endTime).toDate());
};

const ScheduledHoursTimeField = props => (
  <Wrapper>
    <Input
      value={ensureValidTime(props.start)}
      onChange={onChangeStart(props)}
    />
    -
    <Input value={ensureValidTime(props.end)} onChange={onChangeEnd(props)} />
  </Wrapper>
);

export default ScheduledHoursTimeField;

import { gql, useQuery } from "@apollo/client";

const GET_ACCOUNT_MEMBER_AVAILABILITY = gql`
  query getAccountMemberAvailability {
    user {
      id
      account {
        id
        isMemberAvailabilityEnabled
      }
    }
  }
`;

export const useAccountMemberAvailability = () => {
  const { data } = useQuery(GET_ACCOUNT_MEMBER_AVAILABILITY, {
    fetchPolicy: "cache-and-network"
  });

  const isMemberAvailabilityEnabled = data
    ? data?.user?.account.isMemberAvailabilityEnabled
    : false;

  return {
    isMemberAvailabilityEnabled
  };
};

import { graphql } from "@apollo/client/react/hoc";
import { gql } from "@apollo/client";
import { handleErrors } from "~/src/utils/errors";

export const GET_SINGLE_MEMBER = gql`
  query getMember($memberId: ID!, $sourceAccountId: ID!) {
    account {
      id
      member(id: $memberId) {
        bookingsCount(sourceAccountId: $sourceAccountId) {
          previouslyAccepted
          allPreviouslyAccepted
        }
        id
        firstName
        lastName
        rating
        photo
        roles {
          id: roleId
          name
        }
        notes
        relationship {
          id
          poolType
        }
        profile {
          summary
          linkedInProfile
          experience {
            role
            venue
            startDate
            endDate
            supervisor
            supervisorEmail
            isCurrentRole
          }
        }
      }
    }
  }
`;

export default graphql(GET_SINGLE_MEMBER, {
  skip: props => !props.isOpen,
  props: handleErrors(({ data: { loading, account } }) => ({
    isLoading: loading,
    member: account?.member
  }))
});

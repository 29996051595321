import React from "react";
import moment from "moment-timezone";

import { formatDateMonthYear } from "~/src/utils/formatting";
import { Input, Disabled } from "./date-input.styles";
import InputWrapper from "../input-wrapper";

export default function DateInput(props) {
  if (props.readOnly) {
    const date = props.input.value
      ? formatDateMonthYear(moment(props.input.value, "DD/MM/YYYY").toDate())
      : "";
    return (
      <InputWrapper {...props}>
        <Disabled>{date}</Disabled>
      </InputWrapper>
    );
  }

  return (
    <InputWrapper {...props}>
      <Input
        value={props.input.value}
        placeholder={props.placeholder}
        onChange={value => props.input.onChange(value)}
      />
    </InputWrapper>
  );
}

import { createSelector } from "reselect";
import { getMinTime } from "../shared";

const useShiftForm = createSelector(
  [state => state.scheduler.shiftForm],
  shiftForm => ({
    ...shiftForm,
    shifts: [...shiftForm.shifts].map(shift => ({
      ...shift,
      startTime: getMinTime(shift)
    }))
  })
);

export const mapStateToProps = state => ({
  shiftForm: useShiftForm(state),
  isGridSelectedShift: state.scheduler.isGridSelectedShift
});

import styled from "styled-components";

import { STYLE_CONSTS } from "~/src/styles/config.style";

const TRAY_SIZE = 120;

export const TimeSurround = styled.div`
  width: ${TRAY_SIZE}px;
  padding: 5px;
  cursor: default;
  text-align: center;
  float: right;
  line-height: 0px;
  color: ${({ theme }) => theme.text.secondary};
`;

export const StyledTitle = styled.span`
  display: inline-block;
  width: ${TRAY_SIZE - 10}px;
  padding: 3px;
  color: ${({ theme }) => theme.text.secondary};
  line-height: ${STYLE_CONSTS.FONT_SMALL};
  font-size: ${STYLE_CONSTS.FONT_SMALL};
`;

export const StyledTime = styled.span`
  display: inline-block;
  width: ${TRAY_SIZE - 10}px;
  padding: 3px;
  color: ${({ theme }) => theme.text.primary};
  line-height: ${STYLE_CONSTS.FONT_LARGE};
  font-size: ${STYLE_CONSTS.FONT_LARGE};
  font-family: monospace;
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_MEDIUM};
`;

export const StyledDate = styled.span`
  display: inline-block;
  width: ${TRAY_SIZE - 10}px;
  padding: 3px;
  color: ${({ theme }) => theme.text.primary};
  line-height: ${STYLE_CONSTS.FONT_SMALL};
  font-size: ${STYLE_CONSTS.FONT_SMALL};
`;

import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";

const GET_MEMBER_OWNERS = gql`
  query {
    account {
      id
      users {
        id
        firstName
        lastName
      }
    }
  }
`;

export const useMemberOwners = () => {
  const { data, loading, error, refetch } = useQuery(GET_MEMBER_OWNERS, {
    fetchPolicy: "cache-first",
    onError: error => {
      throw error;
    }
  });

  const memberOwners = data?.account?.users;

  return {
    memberOwners,
    loading,
    error,
    refetch
  };
};

import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";
import defaultPhoto from "~/src/images/worker-default-photo.png";

export const PartnerImage = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.neutral.lighter};
  display: inline-block;
  margin-right: ${STYLE_CONSTS.UNIT_2};
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-image: ${({ src }) => {
    const defaultImage = `url(${defaultPhoto})`;
    if (src) {
      return `url(${src}), ${defaultImage}`;
    }
    return defaultImage;
  }};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid ${({ theme }) => theme.neutral.light};
`;

export const PartnerInfo = styled.div`
  display: inline-block;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.text.secondary};
  font-size: ${STYLE_CONSTS.FONT_SMALL};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? STYLE_CONSTS.UNIT_1 : 0};
`;

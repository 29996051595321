import React from "react";
import RichText from "react-rte";
import { RotaInput } from "@teamrota/rota-design";
import { NOTIFICATION_TYPES } from "~/src/consts";
import Button from "~/src/components/button";
import {
  Container,
  ModalContent,
  ButtonContainer
} from "../notifications.styles";

const ComposeMessage = ({
  shiftId,
  subject,
  messageBody,
  onComposeMessage,
  canSendShiftID,
  activeNotificationTypes,
  onSubmit
}) => {
  return (
    <ModalContent>
      {activeNotificationTypes.includes(NOTIFICATION_TYPES.PUSH_NOTIFICATION) &&
        canSendShiftID && (
          <RotaInput
            value={shiftId}
            isLabelTop
            label="Shift ID (push only)"
            placeholder="Write here"
            onChange={e => onComposeMessage({ shiftId: e.target.value })}
          />
        )}
      {activeNotificationTypes.includes(NOTIFICATION_TYPES.EMAIL) && (
        <RotaInput
          value={subject}
          isLabelTop
          label="Subject (email only)"
          placeholder="Write here"
          onChange={e => onComposeMessage({ subject: e.target.value })}
        />
      )}
      <Container flexDirection="column">
        <RichText
          value={messageBody}
          onChange={value => onComposeMessage({ messageBody: value })}
        />
        <ButtonContainer>
          <Button onClick={onSubmit}>Send Message</Button>
        </ButtonContainer>
      </Container>
    </ModalContent>
  );
};

export default ComposeMessage;

import React from "react";
import { Role } from "@teamrota/authlib";

import { APPROVAL_STATES } from "~/src/consts";
import useAuth from "~/src/auth/hooks/use-auth";
import HasRole from "~/src/containers/has-role";

import {
  TableData,
  TableRow,
  StylesWrapSpan
} from "~/src/components/floating-table";
import { getIsSelfProvider } from "~/src/containers/timesheets/util";
import TextInput from "~/src/components/form-components/stateful-text-input";
import {
  ShiftId,
  InternalShiftId,
  ShiftIdWrapper
} from "~/src/containers/timesheets/timesheets.styles";

import ShiftActions from "../shift-actions";
import ApprovalState from "../approval-state";
import ShiftInfo from "../shift-info";

const ShiftRow = ({
  shift,
  handleInternalIdSave,
  isLoadingPrintTimesheet,
  openTimesheetId,
  setOpenTimesheetId,
  isExpanded,
  isApproving,
  onPrintRequest
}) => {
  const auth = useAuth();
  const isApproved = shift?.approvalState === APPROVAL_STATES.APPROVED;

  const openSelectedTimesheet = () => {
    setOpenTimesheetId(shift?.id);
  };

  return (
    <TableRow isExpanded={isExpanded}>
      <TableData width={(auth.hasRole(Role.SHIFTS_COST) ? 4 : 6) / 10}>
        <ShiftIdWrapper>
          <ShiftId>ID {shift?.id}</ShiftId>
          {auth.hasRole(Role.SHIFTS_EDIT) && (
            <InternalShiftId leftMargin="10px">
              <TextInput
                iconLeft="5px"
                isInline
                inlineLabel={shift?.identifier ? "Internal ID: " : null}
                isSmall
                placeholder={shift?.identifier ? "" : "Add internal Id"}
                initialValue={shift?.identifier || ""}
                onBlur={e => handleInternalIdSave(shift?.id, e)}
              />
            </InternalShiftId>
          )}
        </ShiftIdWrapper>
        <ShiftInfo shift={shift} />
      </TableData>
      <TableData width={2 / 10}>
        <ApprovalState shift={shift} isApproving={isApproving} />
      </TableData>
      <HasRole role={Role.SHIFTS_COST}>
        <TableData
          width={2 / 10}
          style={{ fontWeight: 400, fontSize: "14px", color: "#A1A1A1" }}
        >
          <StylesWrapSpan isApproved={isApproved}>
            {isApproved ? "Approved" : "Pending"}
          </StylesWrapSpan>
        </TableData>
      </HasRole>
      <TableData width={1 / 5}>
        <ShiftActions
          setOpenTimesheetId={setOpenTimesheetId}
          isTimesheetOpen={shift.id === openTimesheetId}
          isLoadingPrintTimesheet={isLoadingPrintTimesheet?.[shift?.id]}
          onOpenTimesheet={() => openSelectedTimesheet()}
          approvalState={shift?.approvalState}
          hasReviewedAt={!!shift?.reviewedAt}
          isSelfProvider={getIsSelfProvider(shift)}
          onPrintRequest={onPrintRequest}
        />
      </TableData>
    </TableRow>
  );
};

export default ShiftRow;

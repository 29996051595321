import React from "react";
import { connect } from "react-redux";
import find from "lodash/fp/find";
import isEmpty from "lodash/fp/isEmpty";
import last from "lodash/fp/last";
import { Wrapper, Title, OuterWrapper } from "./completed-shift.styles";
import { extractShiftTimesText } from "../shared";
import { EditButton } from "../styles";
import { mapStateToProps, mapDispatchToProps } from "./selectors";

const CompletedShift = props => {
  if (!props.shifts || props.shifts.length <= 1) return null;

  return (
    <OuterWrapper>
      {props.shifts.map(shift => {
        const selectedRole = find(
          ({ id }) => id === shift.roleRateId,
          props.roleRates
        );
        const s = shift.numberRequested === 1 ? "" : "s";
        const members =
          !shift.numberRequested || !selectedRole
            ? "Members not set"
            : `${shift.numberRequested} ${selectedRole.roleName}${s}`;

        return (
          <Wrapper
            key={shift.index}
            onClick={() => props.handleShiftChange(shift.index)}
          >
            <Title isActive={shift.isActive}>
              {shift.isNew && isEmpty(shift.times)
                ? "New Shift"
                : `${extractShiftTimesText(shift, true)} • ${members}`}
            </Title>
            <EditButton
              onClick={e => {
                e.stopPropagation();
                props.handleRemoveShift(shift.index);
                if (shift.index === props.shiftOpenIndex)
                  props.handleShiftChange(
                    last(
                      props.shifts.filter(s => s.index !== props.shiftOpenIndex)
                    ).index
                  );
              }}
            >
              Remove
            </EditButton>
          </Wrapper>
        );
      })}
    </OuterWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CompletedShift);

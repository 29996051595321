import { gql } from "@apollo/client";

export const GET_SHIFT_BOOKED_MEMBERS = gql`
  query getShiftBookedMembers(
    $shiftId: ID!
    $bookingsLimit: Int!
    $bookingsOffset: Int!
  ) {
    account {
      id
      shift(id: $shiftId) {
        id
        bookings(
          limit: $bookingsLimit
          offset: $bookingsOffset
          stateEquals: ACCEPTED
        ) {
          data {
            id
            state
            bucketReason
            visibleAt
            member {
              id
              firstName
              lastName
              photo
            }
          }
          offset
          limit
          totalResults
        }
      }
    }
  }
`;

export function orderKeys(original: Record<string, any>) {
  return Object.keys(original)
    .sort()
    .reduce((obj, key) => {
      const val = original[key];
      obj[key] =
        val !== null && val !== undefined && typeof val === "object"
          ? orderKeys(val)
          : val;
      return obj;
    }, {} as Record<string, any>);
}

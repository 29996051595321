import React from "react";
import { RotaButton } from "@teamrota/rota-design";
import { Role } from "@teamrota/authlib";

import { APPROVAL_STATES } from "~/src/consts";
import HasRole from "~/src/containers/has-role";

const ShiftActions = ({
  approvalState,
  hasReviewedAt,
  isTimesheetOpen,
  isLoadingPrintTimesheet,
  onOpenTimesheet,
  onPrintRequest,
  setOpenTimesheetId
}) => {
  const isPending =
    approvalState === APPROVAL_STATES?.PENDING_ACCOUNT ||
    (!hasReviewedAt && approvalState === APPROVAL_STATES?.UPCOMING);

  if (isPending) {
    return (
      <HasRole role={Role.SHIFTS_BOOKINGS}>
        <div>
          {isTimesheetOpen && (
            <RotaButton
              onClick={() => {
                setOpenTimesheetId(null);
              }}
              size="small"
              variant="outlined"
            >
              Close
            </RotaButton>
          )}
          {!isTimesheetOpen && (
            <RotaButton
              onClick={onOpenTimesheet}
              size="small"
              variant="outlined"
            >
              Open
            </RotaButton>
          )}
          <RotaButton
            size="small"
            variant="outlined"
            style={{ marginLeft: "10px", borderColor: "transparent" }}
            color="transparent"
            disabled
          ></RotaButton>
        </div>
      </HasRole>
    );
  } else {
    return (
      <HasRole role={Role.SHIFTS_BOOKINGS}>
        <div>
          {isTimesheetOpen && (
            <RotaButton
              onClick={() => {
                setOpenTimesheetId(null);
              }}
              size="small"
              variant="outlined"
            >
              Close
            </RotaButton>
          )}
          {!isTimesheetOpen && (
            <RotaButton
              onClick={onOpenTimesheet}
              size="small"
              variant="outlined"
            >
              Open
            </RotaButton>
          )}

          <HasRole role={Role.TIMESHEETS_PRINT}>
            <RotaButton
              isLoading={isLoadingPrintTimesheet}
              size="small"
              onClick={onPrintRequest}
              variant="outlined"
              style={{ marginLeft: "10px" }}
              disabled={!onPrintRequest}
            >
              Print
            </RotaButton>
          </HasRole>
        </div>
      </HasRole>
    );
  }
};

export default ShiftActions;

import { gql } from "@apollo/client";

export const GET_MEMBERS = gql`
  query getMembersList(
    $searchTerm: String
    $accountId: ID
    $limit: Int!
    $offset: Int!
  ) {
    members(
      searchTerm: $searchTerm
      accountId: $accountId
      limit: $limit
      offset: $offset
    ) {
      id
      internalId
      firstName
      lastName
      email
      isSignedUp
      inviteCode
    }
  }
`;

import styled from "styled-components";
import { COLORS, STYLE_CONSTS } from "~/src/styles/config.style";

export const Download = styled.div`
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: ${COLORS.PALE_GREY};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${STYLE_CONSTS.UNIT_2};
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexContainerEnd = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";

import { addAuthVars } from "@teamrota/authlib";

export const GET_PAY_RATE_CARD = addAuthVars(gql`
  query getPayRateCard(
    $id: ID!
    $rateType: PayEngineRateTypeInputType!
    $pointOfScale: Int!
  ) {
    getPayRateCard(id: $id, rateType: $rateType, pointOfScale: $pointOfScale)
  }
`);

interface PayRateCardProps {
  id: string;
  rateType: string | undefined;
  pointOfScale: string | undefined;
}

export const usePayRateCard = ({
  id,
  rateType,
  pointOfScale
}: PayRateCardProps) => {
  const { data, loading, error } = useQuery(GET_PAY_RATE_CARD, {
    variables: { id, rateType, pointOfScale },
    fetchPolicy: "network-only",
    skip: !id || !rateType || !pointOfScale
  });

  return { entries: data?.getPayRateCard, loading, error };
};

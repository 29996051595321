import React, { useContext, useEffect } from "react";

import { withApollo } from "@apollo/client/react/hoc";
import { connect } from "react-redux";

import appActions from "~/src/actions/app";
import Spinner from "~/src/components/full-screen-spinner";
import { baseUrl, loginPath, userManager } from "~/src/auth/manager";
import { clearAgent } from "../../agent";
import { AuthContext, NOT_AUTHED } from "../../contexts";
import { removeProvideScheduleStartDate } from "~/src/containers/provide-schedule/storage";

function Logout({ client: { resetStore }, resetReduxState }) {
  const { setAuth, setIsLogout } = useContext(AuthContext);

  useEffect(() => {
    (async () => {
      setIsLogout(true);
      setAuth(NOT_AUTHED);

      removeProvideScheduleStartDate();

      try {
        resetReduxState();
        resetStore();
      } catch (err) {
        console.error(err);
      }

      const signoutArgs = {};

      const wasAgent = clearAgent();
      if (wasAgent) {
        signoutArgs.post_logout_redirect_uri = `${baseUrl}/${loginPath}`;
      }

      try {
        await userManager.signoutRedirect(signoutArgs);
      } catch (err) {
        console.error(err);
        window.location.reload();
      }
    })();
  }, []);

  return <Spinner />;
}

const mapDispatchToProps = dispatch => ({
  resetReduxState: () => dispatch(appActions.resetReduxState())
});

export default withApollo(connect(null, mapDispatchToProps)(Logout));

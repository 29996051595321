import React from "react";
import { SvgIcon } from "@material-ui/core";
import { COLORS } from "~/src/styles/config.style";

const InfoIcon = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 26 22">
      <path
        d="M10.5 0C8.4233 0 6.39323 0.615814 4.66652 1.76957C2.9398 2.92332 1.59399 4.5632 0.799269 6.48182C0.0045495 8.40045 -0.203386 10.5116 0.201759 12.5484C0.606904 14.5852 1.60693 16.4562 3.07538 17.9246C4.54383 19.3931 6.41475 20.3931 8.45156 20.7982C10.4884 21.2034 12.5996 20.9954 14.5182 20.2007C16.4368 19.406 18.0767 18.0602 19.2304 16.3335C20.3842 14.6068 21 12.5767 21 10.5C21 7.71522 19.8938 5.04451 17.9246 3.07538C15.9555 1.10625 13.2848 0 10.5 0ZM10.5 19.25C8.76942 19.25 7.07769 18.7368 5.63876 17.7754C4.19983 16.8139 3.07833 15.4473 2.41606 13.8485C1.75379 12.2496 1.58051 10.4903 1.91813 8.79296C2.25575 7.09562 3.08911 5.53652 4.31282 4.31281C5.53653 3.08911 7.09563 2.25575 8.79296 1.91813C10.4903 1.58051 12.2496 1.75379 13.8485 2.41605C15.4473 3.07832 16.8139 4.19983 17.7754 5.63876C18.7368 7.07769 19.25 8.76941 19.25 10.5C19.25 12.8206 18.3281 15.0462 16.6872 16.6872C15.0462 18.3281 12.8206 19.25 10.5 19.25Z"
        fill={COLORS.COOL_GREY}
      />
      <path
        d="M10.5 8.48752C10.2679 8.48752 10.0454 8.57971 9.88127 8.7438C9.71718 8.90789 9.62499 9.13045 9.62499 9.36252V15.3125C9.62499 15.5446 9.71718 15.7671 9.88127 15.9312C10.0454 16.0953 10.2679 16.1875 10.5 16.1875C10.7321 16.1875 10.9546 16.0953 11.1187 15.9312C11.2828 15.7671 11.375 15.5446 11.375 15.3125V9.36252C11.375 9.13045 11.2828 8.90789 11.1187 8.7438C10.9546 8.57971 10.7321 8.48752 10.5 8.48752ZM10.5 4.90002C10.2631 4.90893 10.0386 5.00819 9.87266 5.17742C9.70668 5.34665 9.61179 5.57302 9.60749 5.81002V5.95002C9.60707 6.06418 9.63043 6.17718 9.67607 6.28183C9.72171 6.38647 9.78864 6.48047 9.87259 6.55783C9.95654 6.6352 10.0557 6.69424 10.1637 6.73121C10.2717 6.76817 10.3862 6.78224 10.5 6.77252C10.7293 6.76386 10.9468 6.66889 11.1091 6.50663C11.2714 6.34438 11.3663 6.12682 11.375 5.89752V5.68752C11.3757 5.57631 11.3528 5.46622 11.3079 5.36449C11.2629 5.26275 11.197 5.17169 11.1143 5.0973C11.0317 5.0229 10.9342 4.96686 10.8283 4.93285C10.7224 4.89885 10.6105 4.88766 10.5 4.90002Z"
        fill={COLORS.COOL_GREY}
      />
    </SvgIcon>
  );
};

export default InfoIcon;

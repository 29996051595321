import React, { Component } from "react";
import isString from "lodash/fp/isString";
import { RotaButtonNew } from "@teamrota/rota-design";
import Modal from "~/src/components/modal";
import Select from "~/src/components/form-components/select";
import {
  Container,
  Header,
  Text,
  ButtonContainer
} from "./async-confirm.styles";

export default class AsyncConfirm extends Component {
  state = {
    selection: null,
    showWarning: false
  };

  constructor() {
    super();
  }

  handleConfirm = ret => () => {
    if (!this.props.config.dropDownOptions) {
      this.props.callback(ret);
      this.props.onClose();
    } else {
      if (ret !== false && this.state.selection === null) {
        this.setState({ showWarning: true });
      } else {
        this.props.callback(ret, this.state.selection);
        this.props.onClose();
      }
    }
  };

  renderDropdown() {
    return (
      <Select
        shouldFixLabelHeight
        placeholder="Select one"
        errorLabel={this.state.showWarning ? "Required field" : null}
        isDisabled={false}
        value={this.state.selection}
        options={this.props.config.dropDownOptions}
        onChange={val => {
          this.setState({ selection: val, showWarning: false });
        }}
      />
    );
  }

  renderHideButton() {
    return (
      <RotaButtonNew variant="outlined" onClick={this.handleConfirm(false)}>
        {this.props.config.falseButtonText
          ? this.props.config.falseButtonText
          : "No, thanks"}
      </RotaButtonNew>
    );
  }

  renderCancelLinkedShiftButtons() {
    return (
      <ButtonContainer>
        <RotaButtonNew
          isDanger={!this.props.config.isConfirmButtonGreen}
          onClick={
            (this.props.config.cancelEntireLinkedShift &&
              this.handleConfirm({
                type: "cancelledIndividualLinkedShift",
                reason: this.state.selection
              })) ||
            this.handleConfirm({
              type: "cancelledShift",
              reason: this.state.selection
            })
          }
        >
          {this.props.config.confirmButtonText || "Close"}
        </RotaButtonNew>
        <RotaButtonNew
          isDanger={!this.props.config.isConfirmButtonGreen}
          onClick={this.handleConfirm({
            type: "cancelledShift",
            reason: this.state.selection
          })}
        >
          {this.props.config.cancelEntireLinkedShift}
        </RotaButtonNew>
      </ButtonContainer>
    );
  }

  renderButton() {
    return (
      <RotaButtonNew
        isDanger={this.props.config.isConfirmButtonGreen}
        onClick={this.handleConfirm(
          this.state.selection ? this.state.selection : true
        )}
      >
        {this.props.config.confirmButtonText || "Close"}
      </RotaButtonNew>
    );
  }

  renderHeader(message, body) {
    return (
      <div>
        <Header shouldAddPadding={this.props.config.shouldAddPadding}>
          {message}
        </Header>
        {this.props.config.shouldHideSubText ? null : (
          <Text hasMarginBottom>{body}</Text>
        )}
      </div>
    );
  }

  render() {
    const props = this.props;
    const { message, onClose, config = {} } = props;

    let body = config.subText
      ? config.subText
      : "This action is not reversible";

    if (config.subComponent) {
      body = config.subComponent();
    }

    const content = !isString(message)
      ? message
      : this.renderHeader(message, body);

    return (
      <Modal isOpen={props.isOpen} onClose={onClose} isOverlay>
        <Container>
          {content}
          {config.dropDownOptions && this.renderDropdown()}
          <ButtonContainer>
            {!config.shouldHideCloseButton && this.renderHideButton()}

            {config.isLinkedShift
              ? this.renderCancelLinkedShiftButtons()
              : this.renderButton()}
          </ButtonContainer>
        </Container>
      </Modal>
    );
  }
}

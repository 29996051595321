import { gql } from "@apollo/client";

export const GET_ACCOUNTS = gql`
  query($searchTerm: String, $limit: Int!, $offset: Int!) {
    accounts(searchTerm: $searchTerm, limit: $limit, offset: $offset) {
      id
      accountName
      emails
    }
  }
`;

import React from "react";
import Sidebar from "react-sidebar";
import ManageShiftsComponent from "./manage-shifts";

class ManageShiftsSideBar extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.isOpen !== nextProps.isOpen) {
      return true;
    }
    return false;
  }
  render() {
    return (
      <Sidebar
        sidebar={
          <ManageShiftsComponent
            shouldLoadShifts={this.props.isOpen}
            onOpenShift={this.props.onOpenShift}
          />
        }
        open={this.props.isOpen}
        onSetOpen={this.props.onToggleSideBar}
        pullRight
        transitions
        rootClassName={this.props.isOpen ? "sideBarVisible" : "sideBarHidden"}
        styles={{
          sidebar: {
            width: 400,
            backgroundColor: "#fafafa"
          }
        }}
      >
        {/* Sidebar component requires children */}
        <span />
      </Sidebar>
    );
  }
}

export default ManageShiftsSideBar;

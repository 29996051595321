import React, { Component } from "react";
import { get, flow, find } from "lodash/fp";
import DynamicForm from "~/src/components/dynamic-form";
import asyncConfirm from "~/src/utils/async-confirm";
import {
  isNotWithin24HoursFromStart,
  createValidDateFromString
} from "~/src/utils/time-utils";
import { errorModal } from "~/src/utils/errors";
import updateBooking from "../graphql/update-booking";
import getSchema, { getValuesFromBooking, getValuesToSave } from "./schema";

const getFirstTruthy = (props, booking) =>
  flow(
    find(prop => booking[prop] && booking[prop] !== "Missing date"),
    prop => booking[prop]
  )(props);

const FORM_ID = "view-edit-booking";

class ViewEditBookingComponent extends Component {
  state = {
    shouldReload: false
  };

  async onSubmit(args, props) {
    try {
      if (this.isValid(args, props)) {
        await props.updateBooking(
          get("booking.id", props),
          getValuesToSave(args)
        );
      } else if (
        await asyncConfirm("Warning!", {
          subText: "Shift duration exceeds 24 hours",
          confirmButtonText: "Save",
          falseButtonText: "Do not Save"
        })
      ) {
        await props.updateBooking(
          get("booking.id", props),
          getValuesToSave(args)
        );
      } else {
        // Set the state for shouldReload to true to re-render the child
        // component (Redux Form back to the original dates)
        this.setState({ shouldReload: true }, () =>
          this.setState({ shouldReload: false })
        );
      }
    } catch (e) {
      errorModal(e);
    }
  }

  isValid = args => {
    const { constants } = this.props;
    // if operator has either set start or end time override
    if (args.startOnPoolManagerOverride || args.endOnPoolManagerOverride) {
      const correctTimes = {
        start: createValidDateFromString(
          getFirstTruthy(
            [...constants.SHIFT_TIME_PRIORITY_START, "shiftStartTime"],
            args
          )
        ),
        end: createValidDateFromString(
          getFirstTruthy(
            [...constants.SHIFT_TIME_PRIORITY_END, "shiftEndTime"],
            args
          )
        )
      };

      // Check that the start and end time are within the 24 hour scope
      if (isNotWithin24HoursFromStart(correctTimes.start, correctTimes.end)) {
        this.setState({ shouldReload: true }, () =>
          this.setState({ shouldReload: false })
        );
        return false;
      }
      return true;
    }
    return true;
  };

  props;

  render() {
    const { state, props } = this;
    const initialValues = getValuesFromBooking(props.booking);
    const schema = getSchema(props.booking, initialValues);

    return (
      <DynamicForm
        shouldResetForm={state.shouldReload}
        form={FORM_ID}
        onSubmit={args => this.onSubmit(args, props, this.state.initialValues)}
        schema={schema}
        initialValues={initialValues}
      />
    );
  }
}

export default updateBooking(ViewEditBookingComponent);

import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
// eslint-disable-next-line import/no-unassigned-import
import "moment-duration-format";
import { APPROVAL_STATES, APPROVAL_STATES_LABELS } from "~/src/consts";
import { getCountDownHours } from "~/src/utils";
import Loading from "~/src/components/loading";
import { CountDown, Remaining, Wrapper } from "./approval-state.styles";

const TIMEOUT_APPROVE = 1;

const ApprovalState = ({ shift, isApproving }) => {
  const [countDown, setCountDown] = useState(null);
  const [remainingTime, setRemainingTime] = useState(null);
  const isCancelled = !!shift?.cancelledAt;
  const isApproved = [
    APPROVAL_STATES?.PENDING_MEMBER,
    APPROVAL_STATES?.APPROVED
  ].includes(shift?.approvalState);

  const isReviewPeriodExpired =
    isApproved &&
    shift?.sourceAccount?.id !== shift?.targetAccount?.id &&
    !shift?.reviewedAt;

  useEffect(() => {
    const {
      approvalState,
      endTime,
      targetAccount: { id: targetAccountId },
      sourceAccount: { id: sourceAccountId }
    } = shift;

    let interval;
    if (approvalState === APPROVAL_STATES.PENDING_ACCOUNT) {
      setCountDown(
        getCountDownHours(endTime, targetAccountId, sourceAccountId)
      );
      setRemainingTime(shiftApproveRemaining());

      interval = setInterval(() => {
        const time = shiftApproveRemaining();
        if (time < TIMEOUT_APPROVE) {
          clearInterval(interval);
          setRemainingTime(null);
        } else {
          setRemainingTime(time);
        }
      }, 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [remainingTime]);

  const shiftApproveRemaining = () => {
    const result = moment().isAfter(countDown)
      ? null
      : moment.duration(moment(countDown).diff(moment(), "seconds"), "seconds");

    if (!result) return result;

    if (result.asYears() >= 1) return "unlimited";

    return result.format("d[d] h:mm:ss");
  };

  if (isApproving) {
    return (
      <Wrapper>
        <span>
          Approving...
          <Loading color="black" hideText />
        </span>
      </Wrapper>
    );
  }

  if (isCancelled) {
    return (
      <Wrapper>
        <span>Shift cancelled (cancellation policy applied)</span>
      </Wrapper>
    );
  }

  if (shift?.approvalState === APPROVAL_STATES?.PENDING_ACCOUNT) {
    const label = "Awaiting approval";
    if (remainingTime === null) {
      return (
        <Wrapper>
          <span>Approved (review period expired)</span>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <div>{label}</div>
        <CountDown>{remainingTime}</CountDown>
        <Remaining>Remaining time</Remaining>
      </Wrapper>
    );
  }

  if (isApproved && shift?.reviewedAt) {
    return (
      <Wrapper>
        <span>Approved</span>
      </Wrapper>
    );
  } else if (isReviewPeriodExpired) {
    return (
      <Wrapper>
        <span>Approved (review period expired)</span>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <span>{APPROVAL_STATES_LABELS[shift?.approvalState]}</span>
    </Wrapper>
  );
};

export default ApprovalState;

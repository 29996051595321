import { inRange } from "lodash/fp";

const numericKeyCodeRange = [48, 58];
export const isNumericKey = inRange(...numericKeyCodeRange);

const directionKeyCodeRange = [37, 41];
export const isDirectionKey = inRange(...directionKeyCodeRange);

export const getCaratPosition = input => {
  let cursorPosition = 0;
  // IE Support
  if (document.selection) {
    input.focus();
    // $FlowFixMe
    const sel = document.selection.createRange();
    sel.moveStart("character", -input.value.length);
    cursorPosition = sel.text.length;
  } else if (input.selectionStart || input.selectionStart === "0") {
    cursorPosition = input.selectionStart;
  }
  return cursorPosition;
};

export const getIsAllSelected = input => {
  const start = input.selectionStart;
  const end = input.selectionEnd;
  return start === 0 && end === (input.value && input.value.length);
};

export const setCaretPosition = (input, caretPos) => {
  if (input.createTextRange) {
    const range = input.createTextRange();
    range.move("character", caretPos);
    range.select();
  } else if (input.selectionStart) {
    input.focus();
    input.setSelectionRange(caretPos, caretPos);
  } else {
    input.focus();
  }
};

export const replaceCharAtIndex = (string, index, replacement) =>
  string.substr(0, index) + replacement + string.substr(index + 1);

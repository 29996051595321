import { gql } from "@apollo/client";

export const GET_ACCOUNT_WITH_ROLE_RATES = gql`
  query getAccountWithRoleRates($accountType: AccountAccessType!) {
    account {
      id
      isOverLimitWorkAllowed
      isMemberAvailabilityEnabled
      roleRates(accountType: $accountType) {
        id
        role {
          name
        }
      }
    }
  }
`;

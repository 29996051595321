import styled from "styled-components";

export const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const StyledTableCell = styled.td`
  padding: 10px;
  border: solid 1px black;
`;

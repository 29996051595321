import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

const TRAY_HEIGHT = 200;

export const SelectTray = styled.div`
  background-color: ${({ theme }) => theme.white};
  width: 100%;
  position: absolute;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: ${TRAY_HEIGHT}px;
  height: auto;
  box-shadow: ${STYLE_CONSTS.FLOATING};
  border: ${({ theme }) => `solid 1.2px ${theme.neutral.lighter}`};
  border-top: none;
  border-radius: ${STYLE_CONSTS.BORDER_RADIUS};
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  z-index: ${STYLE_CONSTS.Z_INDEX_SELECT};
`;

export const SelectOption = styled.div`
  padding: ${({ isSmall }) =>
    isSmall ? STYLE_CONSTS.UNIT_1 : STYLE_CONSTS.UNIT_2};
  padding-left: ${STYLE_CONSTS.UNIT_2};
  font-size: ${({ isSmall }) =>
    isSmall ? STYLE_CONSTS.FONT_SMALL : STYLE_CONSTS.FONT};
  color: ${({ theme }) => theme.neutral.main};
  background-color: ${({ isHighlighted, theme }) =>
    isHighlighted ? theme.neutral.light : theme.white};
  &:hover {
    background-color: ${({ theme }) => theme.neutral.light};
    cursor: pointer;
  }
  border-top: 2px solid
    ${({ borderedOptions, theme }) =>
      borderedOptions ? theme.neutral.dark : theme.white};
`;

export const Placeholder = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.neutral.main};
  padding: ${STYLE_CONSTS.UNIT_2} ${STYLE_CONSTS.UNIT_1};
`;

export const SelectHeader = styled.div`
  padding: ${STYLE_CONSTS.UNIT_1} ${STYLE_CONSTS.UNIT_2};
  font-size: ${({ isSmall }) =>
    isSmall ? STYLE_CONSTS.FONT_SMALL : STYLE_CONSTS.FONT};
  color: ${({ theme }) => theme.primary.main};
  border-bottom: ${({ theme }) => `solid 1px ${theme.neutral.lighter}`};
`;

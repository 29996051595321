import React from "react";
import Loading from "~/src/components/loading";
import Member from "~/src/containers/pools/components/member";
import { Overlay, OverlayMessage } from "../panel/panel.styles";
import Placeholder from "../panel/placeholder";
import {
  GroupMembersContainer,
  LoadingWrapper,
  PlaceholderWrapper,
  TabContent
} from "./panel-group.styles";
import DeleteZone from "./delete-zone/delete-zone";
import { COLORS } from "~/src/styles/config.style";
import { useDrop } from "react-dnd";
import { DND_ITEM_TYPES } from "~/src/consts";

const DropZone = ({
  onDropMember,
  groups,
  activeTab,
  isArrowToggled,
  onShowMemberDetails,
  removeMemberFromGroup,
  isMemberBeingDragged,
  handleMemberDrag,
  poolType
}) => {
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: DND_ITEM_TYPES.POOL_MEMBER,
      drop: member => {
        onDropMember(member);
      },
      canDrop: member => poolType !== member.poolType,
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      })
    }),
    [onDropMember]
  );

  const groupMembers = groups.length > 0 && groups[activeTab];
  const members = groupMembers?.members || [];

  const placeholderTitle =
    groups.length === 0
      ? "You don't have any groups yet."
      : "Your group is empty!";

  const placeholderDescription =
    groups.length === 0
      ? "Click the '+' button to create one."
      : "Start dragging & dropping members here to fill in your group.";

  return (
    <TabContent isVisible={isArrowToggled}>
      <div ref={drop}>
        <Overlay isVisible={groups.length && canDrop && isOver} isGroupPanel>
          <OverlayMessage isVisible={groups.length && canDrop && isOver}>
            Drop to add the member to the current group.
          </OverlayMessage>
        </Overlay>
        <GroupMembersContainer>
          {!members ? (
            <LoadingWrapper>
              <Loading color={COLORS.BLACK} />
            </LoadingWrapper>
          ) : groups.length ? (
            members.map(({ member, id: groupMemberId }, index) => {
              return (
                member.shouldShowInGroup && (
                  <Member
                    key={index}
                    isGroupMember
                    normalWhiteSpacing
                    {...member}
                    onClick={() => onShowMemberDetails(member.id)}
                    memberDragStatus={handleMemberDrag}
                    groupMemberId={groupMemberId}
                  />
                )
              );
            })
          ) : (
            <></>
          )}
          {(members.length === 0 || !groups.length === 0) && (
            <PlaceholderWrapper>
              <Placeholder
                title={placeholderTitle}
                description={placeholderDescription}
              />
            </PlaceholderWrapper>
          )}
        </GroupMembersContainer>
        <DeleteZone
          onDropMember={removeMemberFromGroup}
          isVisible={isMemberBeingDragged}
        />
      </div>
    </TabContent>
  );
};

export default DropZone;

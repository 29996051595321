import styled from "styled-components";

export const StyledUser = styled.li`
  color: ${({ theme }) => theme.text.primary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: color 0.4s ease-in-out;

  span {
    display: block;
    color: ${({ theme }) => theme.neutral.main};
    margin-top: 2px;
    font-size: 12px;
  }
`;

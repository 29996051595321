import { isValidEmail } from "~/src/utils/validators";

export default values => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = "Please enter a first name";
  }

  if (!values.lastName) {
    errors.lastName = "Please enter a last name";
  }

  if (!values.accountName) {
    errors.accountName = "Please enter the name of the account";
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = "Please enter a phone number";
  }

  if (!isValidEmail(values.email)) {
    errors.email = "Please enter a valid email";
  }
  return errors;
};

import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";

const REPLACE_CANCELLED_BOOKING = gql`
  mutation replaceCancelledBookings(
    $shiftId: ID!
    $replacementBookings: [replacementBookingInputType]!
  ) {
    replaceCancelledBookings(
      shiftId: $shiftId
      replacementBookings: $replacementBookings
    ) {
      id
    }
  }
`;

export const useReplaceCancelledBooking = () => {
  const [replaceCancelledBookings, { loading, error }] = useMutation(
    REPLACE_CANCELLED_BOOKING,
    {
      refetchQueries: ["getSchedules", "getShifts", "getProvideBookings"]
    }
  );

  const replacebooking = useCallback(
    ({ shiftId, replacementBookings }) =>
      replaceCancelledBookings({
        variables: {
          shiftId,
          replacementBookings
        }
      }),
    [replaceCancelledBookings]
  );

  return { replacebooking, loading, error };
};

import { gql, useQuery } from "@apollo/client";
import adminClient from "~/src/containers/admin-dashboard/graphql/admin-client";

const GET_DIVISIONS = gql`
  query getAccountDivisions($accountId: ID!) {
    divisions(accountId: $accountId) {
      id
      name
    }
  }
`;

export const useDivisions = ({ accountId }) => {
  const { data, loading, refetch } = useQuery(GET_DIVISIONS, {
    variables: {
      accountId
    },
    client: adminClient,
    skip: !accountId
  });

  const divisions = data?.divisions;

  return {
    divisions,
    loading,
    refetch
  };
};

import styled from "styled-components";
import SearchInput from "~/src/components/form-components/search-input";
import { COLORS, STYLE_CONSTS } from "~/src/styles/config.style";

export const LoadMore = styled.div`
  text-align: center;
  margin-top: ${STYLE_CONSTS.UNIT_2};
  color: ${COLORS.COOL_GREY};
  transition: ${STYLE_CONSTS.STANDARD_TRANSITON};
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.primary.main};
  }
`;

export const StyledSearchInput = styled(SearchInput)`
  margin-right: ${STYLE_CONSTS.UNIT_3};
  width: 400px;
`;

export const SortedBy = styled.span`
  font-size: ${STYLE_CONSTS.FONT_SMALL};
  color: ${COLORS.COOL_GREY};
  margin-right: ${STYLE_CONSTS.UNIT_1};
`;

import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { RotaButton, RotaDivider } from "@teamrota/rota-design";

import useAuth from "~/src/auth/hooks/use-auth";
import { APPROVAL_STATES } from "~/src/consts";

import { useCurrency } from "~/src/containers/profile-loader";
import { APPROVE_SHIFT } from "~/src/containers/timesheets/graphql/approve-shift.js";
import {
  mergeBookingUpdates,
  validateApprovals
} from "~/src/containers/timesheets/helpers.js";

import { errorModal } from "~/src/utils/errors";
import { getFormatterMoney } from "~/src/utils/formatting";
import { hoursAndMinutesDisplay } from "~/src/utils/formatting-hours-timesheet";

import TimesheetRow from "./components/timesheet-row";
import {
  EmptyBookings,
  StyledBoldText,
  StyledOrangeText,
  StyledShiftTotal,
  StyledStatus,
  StyledTotalHoursWrapper,
  StyledWrapInfo,
  StyledWrapShiftInfo,
  StyledWrapShiftResult,
  StyledWrapShiftStatus,
  StyledWrapShiftValue,
  StyledWrapTimesheetRow,
  Wrapper
} from "./timesheet.styles";
import { TTM_PROVIDER_ACCOUNTS } from "@teamrota/rota-common";

const Timesheet = ({
  sourceAccountId,
  shift,
  shiftInfo,
  onApprovalBegin,
  onApprovalEnded,
  refetchShift,
  setSnackMessage
}) => {
  if (!shift?.account?.shiftById) return null;

  const {
    bookings,
    chargeRate,
    totalVat,
    totalWithBonus,
    totalWithVat,
    extraHours,
    extraPaymentValue,
    extraPaymentRate,
    id,
    isUncalculatedRate,
    isDayRate
  } = shift?.account?.shiftById;

  const { isPayCalculationOnCheckInOutTimes } = shift?.account;
  const { currencyCode, taxRate } = useCurrency();
  const formatterMoney = getFormatterMoney(currencyCode);

  const auth = useAuth();

  const [isApproveEnabled, setIsApproveEnabled] = useState(0);
  const [bookingUpdates, setBookingUpdates] = useState([]);

  const isUpcoming = shiftInfo?.approvalState === APPROVAL_STATES.UPCOMING;

  const isTTM = TTM_PROVIDER_ACCOUNTS.includes(shiftInfo?.targetAccount?.id);

  const {
    isCasualMemberTypeRatings,
    isPermanentMemberTypeRatings,
    isAgencyMemberTypeRatings,
    isContractorLimitedCompanyMemberTypeRatings,
    isContractorUmbrellaCompanyMemberTypeRatings,
    isContractorSelfEmployedMemberTypeRatings
  } = shift.account;

  const isRatingRequired =
    isCasualMemberTypeRatings ||
    isPermanentMemberTypeRatings ||
    isAgencyMemberTypeRatings ||
    isContractorLimitedCompanyMemberTypeRatings ||
    isContractorUmbrellaCompanyMemberTypeRatings ||
    isContractorSelfEmployedMemberTypeRatings;

  const [approveShift] = useMutation(APPROVE_SHIFT);

  const handleApprove = async ({ isApprovingShift, bookingForUpdate }) => {
    let bookingApprovals = isApprovingShift
      ? mergeBookingUpdates({ bookings, bookingUpdates })
      : [bookingForUpdate];

    if (
      !isApprovingShift &&
      bookings.length === 1 &&
      (bookingForUpdate?.isTurnedAway || bookingForUpdate?.isNoShow)
    ) {
      // this action will cancel the last remaining booking
      // there is no approval action for a 0 bookings shift, so just push it as full approval
      isApprovingShift = true;
    }

    // those without access to pay/charges can't see this field
    // so we have to do without this check in that circumstance

    if (!totalWithVat || totalWithVat >= 0) {
      const error = validateApprovals({
        bookingApprovals,
        isApprovingShift,
        isRatingRequired,
        isTTM
      });

      if (error) {
        errorModal(error);
        return false;
      } else {
        if (isApprovingShift) {
          onApprovalBegin?.();
        }

        try {
          await approveShift({
            variables: auth.addVals(APPROVE_SHIFT, {
              shiftId: id,
              bookings: bookingApprovals,
              sourceAccountId,
              isApproved: isApprovingShift
            })
          });

          if (isApprovingShift) {
            setSnackMessage("Approved successfully", "success");
            onApprovalEnded?.();
            return true;
          } else {
            setSnackMessage("Saved successfully", "success");
            return true;
          }
        } catch (e) {
          setSnackMessage(`Something went wrong. Please try again`, "error");
          return false;
        }
      }
    } else {
      errorModal(
        "There is a problem with this timesheet, please check that the hours are correct"
      );

      return false;
    }
  };

  if (!bookings?.length) {
    return <EmptyBookings> No Bookings </EmptyBookings>;
  }

  const sortedBookings = [...bookings]?.sort((a, b) => {
    if (a.member.firstName > b.member.firstName) {
      return 1;
    }
    if (a.member.firstName < b.member.firstName) {
      return -1;
    }
    return 0;
  });

  const isApproved = shiftInfo?.approvalState === APPROVAL_STATES.APPROVED;

  // Specific TTM Client Accounts cannot be Approved Again
  // This is a terrible hack, but what Product wants, Product gets
  // https://rota.atlassian.net/browse/RT-1209
  const isCannotApproveAgain =
    shiftInfo?.targetAccount?.id === "2841" ||
    shiftInfo?.targetAccount?.id === "4509";

  return (
    <Wrapper>
      <StyledWrapTimesheetRow>
        {sortedBookings.map(booking => (
          <TimesheetRow
            isTTM={isTTM}
            bookingData={booking}
            key={booking?.member.id}
            setBookingUpdates={setBookingUpdates}
            handleApprove={handleApprove}
            isApproved={isApproved}
            isUncalculatedRate={isUncalculatedRate}
            setIsApproveEnabled={setIsApproveEnabled}
            setSnackMessage={setSnackMessage}
            refetchShift={refetchShift}
            isPayCalculationOnCheckInOutTimes={
              isPayCalculationOnCheckInOutTimes
            }
          />
        ))}
        <StyledWrapShiftInfo>
          <StyledWrapShiftStatus>
            Member Acknowledgement:{" "}
            {isApproved ? (
              <StyledStatus isApproved>&nbsp; Approved</StyledStatus>
            ) : (
              <StyledStatus>&nbsp; Pending</StyledStatus>
            )}
          </StyledWrapShiftStatus>
          {!isTTM && (
            <StyledWrapInfo>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ fontWeight: 400 }}>Note:&nbsp;</span>
                <p>Salaried workers payment is not included in the total</p>
              </div>
            </StyledWrapInfo>
          )}
          <StyledWrapShiftResult>
            {isUncalculatedRate && (
              <>
                <StyledTotalHoursWrapper>
                  <span>Total Worked Hours</span>
                  <StyledOrangeText>
                    {hoursAndMinutesDisplay(shift?.account?.shiftById)}
                  </StyledOrangeText>
                </StyledTotalHoursWrapper>
                <RotaDivider />
                {!isTTM && (
                  <StyledBoldText>
                    Pay for this shift will be calculated in Payroll download.
                  </StyledBoldText>
                )}
              </>
            )}
            {!isUncalculatedRate && (
              <>
                <StyledWrapShiftValue>
                  {hoursAndMinutesDisplay(shift?.account?.shiftById)}{" "}
                  {isDayRate ? "@" : "x"} &nbsp;
                  {formatterMoney.format(chargeRate / 100)} =&nbsp;
                  <span style={{ fontWeight: 700 }}>
                    {formatterMoney.format(totalWithBonus / 100)}
                  </span>
                </StyledWrapShiftValue>
                <StyledWrapShiftValue top>
                  {hoursAndMinutesDisplay(
                    shift?.account?.shiftById,
                    extraHours
                  )}{" "}
                  {isDayRate ? "@" : "x"} &nbsp;
                  {formatterMoney.format(extraPaymentRate / 100)}
                  &nbsp;=&nbsp;
                  <span style={{ fontWeight: 700 }}>
                    {formatterMoney.format(extraPaymentValue / 100)}
                  </span>
                </StyledWrapShiftValue>
                <StyledWrapShiftValue top>
                  VAT ({taxRate}%) =&nbsp;
                  <span style={{ fontWeight: 700 }}>
                    {formatterMoney.format(totalVat / 100)}
                  </span>
                </StyledWrapShiftValue>
                <StyledShiftTotal>
                  <StyledOrangeText>Total</StyledOrangeText>
                  {formatterMoney.format(totalWithVat / 100)}
                </StyledShiftTotal>
              </>
            )}
            {!isApproved &&
              !isUpcoming &&
              !(isCannotApproveAgain && shiftInfo?.reviewedAt) && (
                <StyledWrapShiftValue style={{ marginTop: "12px" }}>
                  <RotaButton
                    variant={shiftInfo?.reviewedAt ? "outlined" : "contained"}
                    disabled={isApproveEnabled}
                    onClick={() => {
                      handleApprove({ isApprovingShift: true });
                    }}
                  >
                    {shiftInfo?.reviewedAt ? "Approve Again" : "Approve"}
                  </RotaButton>
                </StyledWrapShiftValue>
              )}
          </StyledWrapShiftResult>
        </StyledWrapShiftInfo>
      </StyledWrapTimesheetRow>
    </Wrapper>
  );
};

export default Timesheet;

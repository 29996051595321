import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const ButtonWrapper = styled.div`
  margin: ${STYLE_CONSTS.UNIT_2} 0;
`;

export const ResentSuccess = styled.span`
  color: ${({ theme }) => theme.primary.main};
`;

export const CostWrapper = styled.div`
  display: flex;
  border-top: ${STYLE_CONSTS.SMALL_DIVIDER_BORDER};
  border-bottom: ${STYLE_CONSTS.SMALL_DIVIDER_BORDER};
  padding: ${STYLE_CONSTS.UNIT_3} 0px;
  justify-content: space-between;
  margin-top: ${STYLE_CONSTS.UNIT_5};
  margin-bottom: ${STYLE_CONSTS.UNIT_5};
  margin-right: ${STYLE_CONSTS.UNIT_4};
`;

import styled from "styled-components";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";
import Button from "~/src/components/button";

const styles = {
  totalContainer: {
    width: 200,
    paddingTop: 5,
    borderTop: "1px solid grey",
    marginTop: 10
  }
};

export default styles;

export const Wrapper = styled.div`
  padding: ${STYLE_CONSTS.UNIT_3};
  background: ${({ theme }) => theme.white};
  margin-bottom: ${STYLE_CONSTS.UNIT_2};
  color: ${({ theme }) => theme.text.primary};
  border-radius: ${STYLE_CONSTS.BORDER_RADIUS};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Main = styled.div`
  padding: ${STYLE_CONSTS.UNIT_2};
`;

export const Bold = styled.b`
  font-weight: 400;
`;

export const Cost = styled.p`
  font-size: 14px;
  margin: 0;
`;

export const PostButton = styled(Button).attrs({
  color: COLORS.WHITE,
  background: COLORS.MAIN_ORANGE
})`
  line-height: unset;
  display: flex;
  align-self: flex-end;
`;

export const FooterButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const EditButton = styled.button`
  appearance: none;
  border: none;
  padding: 0;
  background: transparent;
  outline: none;
  cursor: pointer;
  color: ${({ theme }) => theme.text.primary};
  font-size: ${STYLE_CONSTS.FONT_MEDIUM};
`;

export const TitleWrapper = styled.div``;

export const Title = styled.div`
  width: 80%;
  margin: 0 auto;
  text-align: center;
  font-size: 20px;
  font-weight: bolder;
  padding: 25px 0 5px 0;
  border-bottom: solid 1px grey;
`;

export const Text = styled.div`
  text-align: center;
  padding: 12px;
`;

export const DisclaimerP = styled.div`
  margin-top: 10px;
  font-weight: bold;
  font-size: 14px;
  margin: 0;
`;

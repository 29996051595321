import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const StyledContainer = styled.div`
  padding-bottom: 10px;
`;

export const StyledCheckBoxContainer = styled.div`
  display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
  flex-direction: row;
  padding: 0 ${STYLE_CONSTS.UNIT_3};
  margin-left: ${({ isMarginLeft }) =>
    isMarginLeft ? `${isMarginLeft}px` : STYLE_CONSTS.UNIT_HALF};
  margin-top: ${STYLE_CONSTS.UNIT_HALF};
`;

export const EmptyPlaceholder = styled.div`
  text-align: center;
  padding: ${STYLE_CONSTS.UNIT_2} ${STYLE_CONSTS.UNIT_4};
  color: ${({ theme }) => theme.text.secondary};
  font-size: ${STYLE_CONSTS.FONT_SMALL};
`;

import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { regex } from "@teamrota/rota-common";

import { UPDATE_MEMBER_EMAIL } from "~/src/containers/admin-dashboard/graphql/update-member-email";
import adminClient from "~/src/containers/admin-dashboard/graphql/admin-client";
import Member from "~/src/containers/admin-dashboard/components/member";
import { RotaInput, RotaButton, RotaButtonIcon } from "@teamrota/rota-design";

import { StyledWrapperInput } from "../styles";

const MemberContainer = ({ member, setSnack }) => {
  const [isInputEmailOpen, setIsInputEmailOpen] = useState(false);
  const [emailEdited, setEmailEdited] = useState("");
  const [emailEditedInputError, setEmailEditedInputError] = useState("");
  const [isInputEmailValid, setIsInputEmailValid] = useState(false);

  const [updateMemberEmail] = useMutation(UPDATE_MEMBER_EMAIL, {
    refetchQueries: ["getMembersList"],
    client: adminClient
  });

  useEffect(() => {
    emailEdited.length >= 1 && isEmailValid(emailEdited);
  }, [emailEdited]);

  const isEmailValid = emailEdited => {
    if (!emailEdited) {
      return setEmailEditedInputError("new email is required");
    }
    if (!regex.EMAIL_ADDRESS.test(emailEdited)) {
      return setEmailEditedInputError("must be a valid email");
    }
    setIsInputEmailValid(true);
    setEmailEditedInputError("");
  };

  const handleChangeMemberEmail = async () => {
    try {
      await updateMemberEmail({
        variables: { memberId: member.id, email: emailEdited }
      });
      isEmailValid(emailEdited);
      setSnack({
        message: "Email was updated successfully!",
        severity: "success"
      });
    } catch (e) {
      setSnack({
        message: "Email was not saved: something Went Wrong",
        severity: "error"
      });
    } finally {
      setIsInputEmailOpen(false);
      setIsInputEmailValid(false);
      setEmailEdited("");
    }
  };

  return (
    <Member member={member}>
      {isInputEmailOpen && (
        <StyledWrapperInput>
          <RotaInput
            type="email"
            placeholder={member.email}
            value={emailEdited}
            onChange={({ target }) => setEmailEdited(target.value)}
            isError={!!emailEditedInputError}
            errorMessage={emailEditedInputError}
          />
          <RotaButton
            disabled={!isInputEmailValid}
            size="small"
            onClick={handleChangeMemberEmail}
          >
            Save
          </RotaButton>
        </StyledWrapperInput>
      )}
      {!isInputEmailOpen ? (
        <RotaButtonIcon icon="edit" onClick={() => setIsInputEmailOpen(true)} />
      ) : (
        <RotaButtonIcon
          icon="close"
          onClick={() => setIsInputEmailOpen(false)}
        />
      )}
    </Member>
  );
};

export default MemberContainer;

import { RotaButtonIcon } from "@teamrota/rota-design";
import styled from "styled-components";

export const OptionsButton = styled(RotaButtonIcon)`
  position: relative;
`;

export const InputRow = styled.div`
  position: sticky;
  top: 40px;
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.white};
  border-bottom: solid 1px black;
  z-index: 500;
`;

export const CheckBoxContainer = styled.div`
  padding: 13px 15px 0 0;
`;

interface DropDownContainerProps {
  width: number;
}

export const DropDownContainer = styled.div<DropDownContainerProps>`
  width: ${({ width }) => width};
  padding: 0 15px 0 0;
`;

export const ContentArea = styled.div`
  height: 500px;
  margin: 16px 0;

  & > table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    font-size: 110%;
    font-weight: 400;

    & th {
      position: sticky;
      font-size: 100%;
      top: 104px;
      margin: 0;
      border: solid 1px ${({ theme }) => theme.neutral.light};
      padding: 10px;
      background-color: ${({ theme }) => theme.neutral.light};
    }

    & th:nth-child(1) {
      width: 30%;
    }

    & th:nth-child(2) {
      width: 40%;
    }

    & th:nth-child(3) {
      width: 30%;
    }

    & tr {
      & td:nth-child(2) {
        text-align: right;
      }
    }

    & td {
      margin: 0;
      border: solid 1px ${({ theme }) => theme.neutral.light};
      padding: 10px;

      & table {
        border-spacing: 0;
        border-collapse: collapse;

        width: calc(100% - 20px);
        margin: 20px 20px 20px 0;

        & td {
          text-align: left;

          border-style: dashed none;
          border-width: 1px;
          border-color: ${({ theme }) => theme.neutral.main};

          padding: 3px 0px;
        }

        & td:nth-child(1) {
          text-align: left;
          width: 6%;
          border-style: none;
        }

        & td:nth-child(2) {
          text-align: left;
          width: 67%;
        }

        & td:nth-child(3) {
          width: 27%;
          text-align: right;
        }
      }
    }

    & tfoot {
      & td {
        padding: 10px 0 !important;
        font-weight: 500;
      }
    }
  }
`;

interface PlaceholderProps {
  isShow: boolean;
}

export const Placeholder = styled.div<PlaceholderProps>`
  min-height: 500px;
  text-align: center;
  line-height: 300px;
  font-size: 200%;
  color: #c0c0c0;

  display: ${({ isShow }) => (isShow ? "block" : "none")};
`;

export const Key = styled.div`
  position: absolute;
  top: 50%;
  right: 0;

  transform: translateY(-50%);
`;

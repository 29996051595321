import flow from "lodash/fp/flow";
import set from "lodash/fp/set";

const updateConnections = connectionName => ({ user }) => {
  const bumpSelfConnectionToStart = (accumulator, current) => {
    if (current?.targetAccount.id === current?.sourceAccount.id)
      return [current, ...accumulator];
    return [...accumulator, current];
  };
  return set(
    `account.${connectionName}.data`,
    user?.account?.[connectionName]?.data?.reduce(bumpSelfConnectionToStart, [])
  )(user);
};

export const setConnections = user => {
  if (user) {
    return flow(
      updateConnections("requesterConnections"),
      updateConnections("providerConnections")
    )(user);
  }
  return undefined;
};

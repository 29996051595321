import styled from "styled-components";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";
import { hexToRGBA } from "~/src/styles/style-helpers";
import Button from "~/src/components/button";

export const BOX_WIDTH = 750;

export const Intro = styled.div`
  padding: ${STYLE_CONSTS.UNIT_3} ${STYLE_CONSTS.UNIT_2} ${STYLE_CONSTS.UNIT_2};
`;

export const Title = styled.h2`
  flex: 1;
  font-size: 25px;
  margin: 0;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-height: calc(100vh - 50px);
  overflow-y: scroll;
  cursor: default;
  box-sizing: border-box;
  background: ${hexToRGBA(COLORS.WHITE, 0.8)};
`;

export const Container = styled.div`
  position: relative;
  width: ${BOX_WIDTH}px;
  max-width: 100%;
  margin: 0 auto;
  background: ${({ theme }) => theme.white};
`;

export const Actions = styled.div`
  display: flex;
  justify-content: ${props => props.justify || "flex-end"};
  align-items: flex-end;
  margin-left: -${STYLE_CONSTS.UNIT_2};
  ${props => props.gutterTop && `margin-top: ${STYLE_CONSTS.UNIT_3};`}
  ${props => props.padding && `padding: ${STYLE_CONSTS.UNIT_3};`}

  & > * {
    margin-left: ${STYLE_CONSTS.UNIT_2};
  }
`;

export const Fieldset = styled.div`
  max-width: 100%;
  margin: 0 auto;
`;

export const Header = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

export const HeaderTitle = styled.h2`
  flex: 1;
  font-weight: regular;
  font-size: 16px;
  margin: 0;
`;

export const Grow = styled.div`
  flex: 1;
`;

export const EditButton = styled.button`
  appearance: none;
  border: none;
  padding: 0;
  background: transparent;
  outline: none;
  cursor: pointer;
  color: ${({ theme }) => theme.primary.main};
  font-size: 12px;
`;

export const FormButton = styled(Button)`
  line-height: unset;
  border: 2px solid ${({ theme }) => theme.primary.main};
  margin-left: ${STYLE_CONSTS.UNIT_2};
`;

export const DayOrNightShiftWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 16px;
  align-items: center;
  margin-bottom: 24px;
`;

import { gql } from "@apollo/client";

export default gql`
  mutation updateBooking(
    $id: ID!
    $isNoShow: Boolean
    $startOnShift: Date
    $endOnShift: Date
    $breakOnShift: Int
    $state: String
    $metadata: JSON
  ) {
    updateBooking(
      id: $id
      isNoShow: $isNoShow
      startOnShift: $startOnShift
      endOnShift: $endOnShift
      breakOnShift: $breakOnShift
      state: $state
      metadata: $metadata
    ) {
      id
      state
      ratingOfMember
      ratingOfManager
    }
  }
`;

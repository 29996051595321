import styled from "styled-components";

export const Container = styled.div`
  width: 300px;
  display: flex;
  align-items: center;
`;

export const CalendarSurround = styled.div`
  background-color: blue;
  width: 180px;
  height: 32px;
  margin-right: 5px;
  overflow: hidden;
`;

export const NumberSurround = styled.div`
  margin-right: 3px;
  width: 50px;

  & > div {
    margin: 0;
  }
`;

export const Separator = styled.div`
  line-height: 30px;
  margin-right: 3px;
`;

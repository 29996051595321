import React, { useState, useEffect } from "react";
import PageHeader from "~/src/components/page-header";
import { TablesContainer } from "./styles";
import MemberTypesTable from "./components/MemberTypesTable";
import getAccountAvailableMemberTypes from "./graphql/get-account-member-types.decorator";
import getProfile from "./graphql/get-profile.decorator";
import updateTargetAccount from "./graphql/update-target-account.decorator";
import { MEMBER_PAY_TYPES, MEMBER_TYPES_FORMATS, getKeyByValue } from "./utils";
import { connect } from "react-redux";
import { MODAL_TYPES } from "~/src/containers/global-modals";
import appActions from "~/src/actions/app";
import {
  StyledButtonWrap,
  StyledTextAlert,
  StyledRedBox,
  StyledSelectedType
} from "~/src/components/floating-table";
import { RotaSnackBar, RotaButtonNew } from "@teamrota/rota-design";

const MemberTypes = ({
  memberTypesAvailableForAccount,
  updateTargetAccount,
  accountId,
  addMember
}) => {
  const [isDefaultChanged, setIsDefaultChanged] = useState(false);
  const [memberTypesPreferences, setMemberTypesPreferences] = useState({});
  const [snack, setSnack] = useState({});

  useEffect(() => {
    if (snack?.message) {
      setTimeout(() => {
        setSnack({});
      }, 5000);
    }
  }, [snack]);

  const message = () => {
    let paye = memberTypesPreferences[MEMBER_PAY_TYPES.PAYE]?.filter(
      el => el.isDefault
    );
    let contractor = memberTypesPreferences[
      MEMBER_PAY_TYPES.CONTRACTOR
    ]?.filter(el => el.isDefault);
    let selected;
    if (paye?.length) {
      selected = paye.map(el => el.fieldName);
    } else if (contractor) {
      selected = contractor.map(el => el.fieldName);
    }
    return (
      selected != "" && (
        <StyledTextAlert>
          Attention! <br></br> You have set
          <StyledSelectedType> "{selected}" </StyledSelectedType>
          as your default type. Once you are adding members to your profile this
          field will be predefined, but you can change it for certain members if
          you'd like.
        </StyledTextAlert>
      )
    );
  };

  const isDefaultMemberType = memberTypeName =>
    memberTypeName === memberTypesAvailableForAccount.defaultMemberType;

  const updateAccountMemberTypes = fieldsToUpdate => {
    updateTargetAccount(accountId)({ ...fieldsToUpdate });
    setTimeout(() => {
      setSnack({
        message: "Successfully changed",
        severity: "success"
      });
    }, 100);
  };

  const onToggleCheckbox = preference => {
    const isChecked = preference.isChecked;

    const memberTypeConstFormat = getKeyByValue(
      MEMBER_TYPES_FORMATS,
      preference.fieldName
    );
    const currentFieldName =
      MEMBER_TYPES_FORMATS[memberTypeConstFormat].FIELD_NAME;

    const fieldsToChange = {
      [currentFieldName]: !isChecked
    };

    if (isChecked) {
      const currentAdditionalInfoFieldName =
        MEMBER_TYPES_FORMATS[memberTypeConstFormat].FIELD_NAME_INFO_REQUIRED;

      fieldsToChange[currentAdditionalInfoFieldName] = false;
    }

    updateAccountMemberTypes(fieldsToChange);
    setTimeout(() => {
      setSnack({
        message: "Successfully changed",
        severity: "success"
      });
    }, 100);
  };

  const onToggleAdditionalInfoCheckbox = async preference => {
    const isAdditionalInfoRequired = preference.isAdditionalInfoRequired;

    const memberTypeConstFormat = getKeyByValue(
      MEMBER_TYPES_FORMATS,
      preference.fieldName
    );
    const currentFieldName =
      MEMBER_TYPES_FORMATS[memberTypeConstFormat].FIELD_NAME_INFO_REQUIRED;

    await updateAccountMemberTypes({
      [currentFieldName]: !isAdditionalInfoRequired
    });

    setSnack({
      message: "Successfully changed",
      severity: "success"
    });
  };

  const onToggleRadioCheckbox = async preference => {
    const memberTypeConstFormat = getKeyByValue(
      MEMBER_TYPES_FORMATS,
      preference.fieldName
    );

    await updateAccountMemberTypes({
      defaultMemberType: memberTypeConstFormat
    });

    setIsDefaultChanged(true);

    setSnack({
      message: "Successfully changed",
      severity: "success"
    });

    if (!preference.isChecked) {
      onToggleCheckbox(preference);
    }
  };

  useEffect(() => {
    if (memberTypesAvailableForAccount) {
      const initialMemberTypesPreferences = {
        PAYE: [
          {
            fieldName: MEMBER_TYPES_FORMATS.CASUAL.PRETTY,
            isChecked:
              memberTypesAvailableForAccount.isCasualMemberTypeAvailable,
            isDefault: isDefaultMemberType(MEMBER_TYPES_FORMATS.CASUAL.CONST)
          },
          {
            fieldName: MEMBER_TYPES_FORMATS.PERMANENT.PRETTY,
            isChecked:
              memberTypesAvailableForAccount.isPermanentMemberTypeAvailable,
            isDefault: isDefaultMemberType(MEMBER_TYPES_FORMATS.PERMANENT.CONST)
          },
          {
            fieldName: MEMBER_TYPES_FORMATS.AGENCY.PRETTY,
            isChecked:
              memberTypesAvailableForAccount.isAgencyMemberTypeAvailable,
            isDefault: isDefaultMemberType(MEMBER_TYPES_FORMATS.AGENCY.CONST)
          }
        ],
        CONTRACTOR: [
          {
            fieldName: MEMBER_TYPES_FORMATS.CONTRACTOR_LIMITED_COMPANY.PRETTY,
            isChecked:
              memberTypesAvailableForAccount.isContractorLimitedCompanyMemberTypeAvailable,
            isDefault: isDefaultMemberType(
              MEMBER_TYPES_FORMATS.CONTRACTOR_LIMITED_COMPANY.CONST
            ),
            isAdditionalInfoRequired:
              memberTypesAvailableForAccount.isContractorLimitedCompanyMemberTypeInfoRequired
          },
          {
            fieldName: MEMBER_TYPES_FORMATS.CONTRACTOR_UMBRELLA_COMPANY.PRETTY,
            isChecked:
              memberTypesAvailableForAccount.isContractorUmbrellaCompanyMemberTypeAvailable,
            isDefault: isDefaultMemberType(
              MEMBER_TYPES_FORMATS.CONTRACTOR_UMBRELLA_COMPANY.CONST
            ),
            isAdditionalInfoRequired:
              memberTypesAvailableForAccount.isContractorUmbrellaCompanyMemberTypeInfoRequired
          },
          {
            fieldName: MEMBER_TYPES_FORMATS.CONTRACTOR_SELF_EMPLOYED.PRETTY,
            isChecked:
              memberTypesAvailableForAccount.isContractorSelfEmployedMemberTypeAvailable,
            isDefault: isDefaultMemberType(
              MEMBER_TYPES_FORMATS.CONTRACTOR_SELF_EMPLOYED.CONST
            ),
            isAdditionalInfoRequired:
              memberTypesAvailableForAccount.isContractorSelfEmployedMemberTypeInfoRequired
          }
        ]
      };

      setMemberTypesPreferences(initialMemberTypesPreferences);
    }
  }, [memberTypesAvailableForAccount]);

  return (
    <div>
      <PageHeader
        title="Member Types"
        subtext="Select member types available for your account"
      />
      <TablesContainer>
        <MemberTypesTable
          preferences={memberTypesPreferences[MEMBER_PAY_TYPES.PAYE]}
          memberPayType={MEMBER_PAY_TYPES.PAYE}
          onToggleCheckbox={onToggleCheckbox}
          onToggleAdditionalInfoCheckbox={onToggleAdditionalInfoCheckbox}
          onToggleRadioCheckbox={onToggleRadioCheckbox}
        />
        {isDefaultChanged && <StyledRedBox>{message()}</StyledRedBox>}
        <MemberTypesTable
          preferences={memberTypesPreferences[MEMBER_PAY_TYPES.CONTRACTOR]}
          memberPayType={MEMBER_PAY_TYPES.CONTRACTOR}
          onToggleCheckbox={onToggleCheckbox}
          onToggleAdditionalInfoCheckbox={onToggleAdditionalInfoCheckbox}
          onToggleRadioCheckbox={onToggleRadioCheckbox}
          isContractor
        />
      </TablesContainer>
      <StyledButtonWrap>
        <RotaButtonNew variant="text" onClick={addMember}>
          Add a new member
        </RotaButtonNew>
      </StyledButtonWrap>
      <RotaSnackBar
        snackOpen={!!snack?.message}
        severity={snack?.severity}
        message={snack?.message}
        onClose={() => setSnack({})}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch, props) => ({
  addMember: () =>
    dispatch(
      appActions.showModal(MODAL_TYPES.ADD_MEMBER, {
        onClose: () => dispatch(appActions.closeModal()),
        serviceAreas: props?.serviceAreas
      })
    )
});

export default getProfile(
  connect(
    null,
    mapDispatchToProps
  )(getAccountAvailableMemberTypes(updateTargetAccount(MemberTypes)))
);

import { uniqBy } from "lodash";

export const getFormattedExclusions = (exclusions, venueId) => {
  return exclusions
    ?.filter(({ memberId, reason }) => memberId && reason)
    ?.map(({ memberId, reason }) => ({ memberId, reason, venueId }));
};

export const getVenueTags = tags => {
  return tags?.map(({ id, name }) => ({ value: id, label: name }));
};

export const getRolesWithTags = (venueRoles, roleTags, venueTags) => {
  const rolesWithTags = venueRoles.map(role => {
    const tags = roleTags.find(tag => tag.roleId === role.roleId)?.tags || [];

    return {
      ...role,
      roleTags: uniqBy([...venueTags, ...tags], "id").map(({ id, name }) => ({
        value: id,
        label: name,
        isParentTag: venueTags.some(tag => tag.id === id)
      }))
    };
  });

  return sortTagsByLength(rolesWithTags);
};

export const getRolesWithTagIds = (rolesWithTags, tagIds) => {
  return rolesWithTags
    ?.filter(tag => tag.roleTags.length)
    ?.map(({ roleId, roleTags }) => ({
      roleId,
      roleTagIds: roleTags
        .filter(({ value }) => !tagIds?.includes(value))
        .map(({ value }) => value)
    }));
};

export const getTagOptions = (tags, isParentTag) => {
  return [...tags]
    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    .map(({ id, name }) => ({ value: id, label: name, isParentTag }));
};

export const getMemberOptions = members => {
  return [...members].map(({ id, firstName, lastName }) => ({
    value: id,
    label: `${firstName} ${lastName}`
  }));
};

export const getPartnerOptions = partners => {
  return partners?.map(({ id, firstName, lastName }) => ({
    value: id,
    label: `${firstName} ${lastName}`
  }));
};

export const getSortedTags = tags => {
  return tags
    .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
    .sort((x, y) => y.isParentTag - x.isParentTag);
};

export const sortTagsByLength = tags => {
  return tags.sort((a, b) => b.roleTags.length - a.roleTags.length);
};

import React, { useState, useRef, useEffect } from "react";
import { RotaButtonIcon, RotaTag } from "@teamrota/rota-design";
import {
  StyledWrapRole,
  StyledGridCell,
  StyledSpan,
  StyledGrid,
  StyledWrapSubtitle,
  StyledWrapTag
} from "./style";

import { getFormatterMoney } from "~/src/utils/formatting";
import { useCurrency } from "~/src/containers/profile-loader";
import { useTheme } from "styled-components";

const Role = ({
  accountId,
  roleRate,
  role,
  isRoleRatesModal,
  selectedRoleRate
}) => {
  const { currencyCode } = useCurrency();
  const theme = useTheme();

  const formatterMoney = getFormatterMoney(currencyCode);

  const [isScroll, setIsScroll] = useState();
  const [value, setValue] = useState({});
  const ref = useRef();

  const shouldScroll = () => {
    const { scrollLeft, scrollWidth, clientWidth } = ref?.current;
    const isShowRight = scrollLeft < scrollWidth - clientWidth;
    const isShowLeft = scrollLeft > 0;

    setIsScroll({ isShowLeft, isShowRight });
  };

  useEffect(() => {
    isRoleRatesModal && shouldScroll();
  }, []);

  useEffect(() => {
    if (isRoleRatesModal) {
      setValue({
        isDayRate: selectedRoleRate?.isDayRate,
        payRate: roleRate?.payRate,
        chargeRate: roleRate?.chargeRate,
        isOvertimeRate: roleRate?.isOvertimeRate,
        isOverstayRate: roleRate?.isOverstayRate,
        overtimeHoursLimit: roleRate?.overtimeHoursLimit,
        overstayHoursLimit: roleRate?.overstayHoursLimit,
        overstayPayRate: roleRate?.overstayPayRate,
        overstayChargeRate: roleRate?.overstayChargeRate,
        overtimeChargeRate: roleRate?.overtimeChargeRate,
        overtimePayRate: roleRate?.overtimePayRate
      });
    } else {
      setValue({
        isDayRate: role?.isDayRate,
        payRate: role?.defaultPayRate,
        chargeRate: role?.defaultChargeRate,
        isOvertimeRate: role?.isOvertimeRate,
        isOverstayRate: role?.isOverstayRate,
        overtimeHoursLimit: role?.defaultOvertimeHoursLimit,
        overstayHoursLimit: role?.defaultOverstayHoursLimit,
        overstayPayRate: role?.defaultOverstayPayRate,
        overstayChargeRate: role?.defaultOverstayChargeRate,
        overtimeChargeRate: role?.defaultOvertimeChargeRate,
        overtimePayRate: role?.defaultOvertimePayRate
      });
    }
  }, [roleRate, role]);

  return (
    <>
      {isRoleRatesModal && (
        <StyledWrapRole>
          <>
            <StyledSpan>Toggle tags:</StyledSpan>
            {isScroll?.isShowLeft && (
              <RotaButtonIcon
                onClick={() => {
                  ref.current.scrollLeft -= 100;
                  shouldScroll();
                }}
                icon="arrowLeft"
              />
            )}
            <StyledWrapTag ref={ref} overflow="hidden" margin="0 10px">
              <RotaTag
                accountId={accountId}
                tags={selectedRoleRate?.tags || []}
                isReadOnly
                styleTag={{
                  marginRight: "16px"
                }}
              />
            </StyledWrapTag>
            {isScroll?.isShowRight && (
              <RotaButtonIcon
                onClick={() => {
                  ref.current.scrollLeft += 100;
                  shouldScroll();
                }}
                icon="arrowRight"
              />
            )}
          </>
        </StyledWrapRole>
      )}
      <StyledGrid>
        <StyledWrapSubtitle>Rate Info</StyledWrapSubtitle>
        <StyledGridCell
          column={3}
          row={1}
          color={theme.text.secondary}
          font="14px"
        >
          Pay Type
        </StyledGridCell>
        <StyledGridCell
          column={5}
          row={1}
          color={theme.text.secondary}
          font="14px"
        >
          Pay Rate
        </StyledGridCell>
        <StyledGridCell
          column={7}
          row={1}
          color={theme.text.secondary}
          font="14px"
        >
          Charge Rate
        </StyledGridCell>
        <StyledGridCell
          column={9}
          row={1}
          color={theme.text.secondary}
          font="14px"
          display="flex"
        >
          Applicable
        </StyledGridCell>

        <StyledGridCell
          column={1}
          row={2}
          color={theme.text.secondary}
          font="14px"
        >
          Regular Rate
        </StyledGridCell>
        <StyledGridCell
          column={3}
          row={2}
          color={theme.text.primary}
          font="16px"
        >
          {value?.isDayRate ? "Daily" : "Hourly"}
        </StyledGridCell>
        <StyledGridCell
          column={5}
          row={2}
          color={theme.text.primary}
          font="16px"
        >
          {formatterMoney.format(value?.payRate / 100)}
        </StyledGridCell>
        <StyledGridCell
          column={7}
          row={2}
          color={theme.text.primary}
          font="16px"
        >
          {formatterMoney.format(value?.chargeRate / 100)}
        </StyledGridCell>
        <StyledGridCell
          column={9}
          row={2}
          color={theme.text.primary}
          font="16px"
        >
          {!value?.isOvertimeRate && !value?.isOverstayRate && <p>Always</p>}
          {value?.isOvertimeRate && (
            <p>Applied 0-{value?.overtimeHoursLimit.toString()} hours / week</p>
          )}
          {value?.isOverstayRate && (
            <p>
              Applied 0-{value?.overstayHoursLimit.toString()} hours / shift
            </p>
          )}
        </StyledGridCell>

        <StyledGridCell
          column={1}
          row={3}
          color={theme.text.secondary}
          font="14px"
        >
          Overtime Rate
        </StyledGridCell>
        <StyledGridCell
          column={3}
          row={3}
          color={theme.text.primary}
          font="16px"
        >
          {value?.isDayRate ? "Daily" : "Hourly"}
        </StyledGridCell>
        <StyledGridCell
          column={5}
          row={3}
          color={theme.text.primary}
          font="16px"
        >
          {formatterMoney.format(value?.overtimePayRate / 100)}
        </StyledGridCell>
        <StyledGridCell
          column={7}
          row={3}
          color={theme.text.primary}
          font="16px"
        >
          {formatterMoney.format(value?.overtimeChargeRate / 100)}
        </StyledGridCell>
        <StyledGridCell
          column={9}
          row={3}
          color={theme.text.primary}
          font="16px"
        >
          {value?.isOvertimeRate && (
            <p>{`+${value?.overtimeHoursLimit} hours / week`}</p>
          )}
          {!value?.isOvertimeRate && <p>unavailable</p>}
        </StyledGridCell>

        <StyledGridCell
          column={1}
          row={4}
          color={theme.text.secondary}
          font="14px"
        >
          Overstay Rate
        </StyledGridCell>
        <StyledGridCell
          column={3}
          row={4}
          color={theme.text.primary}
          font="16px"
        >
          {value?.isDayRate ? "Daily" : "Hourly"}
        </StyledGridCell>
        <StyledGridCell
          column={5}
          row={4}
          color={theme.text.primary}
          font="16px"
        >
          {formatterMoney.format(value?.overstayPayRate / 100)}
        </StyledGridCell>
        <StyledGridCell
          column={7}
          row={4}
          color={theme.text.primary}
          font="16px"
        >
          {formatterMoney.format(value?.overstayChargeRate / 100)}
        </StyledGridCell>
        <StyledGridCell
          column={9}
          row={4}
          color={theme.text.primary}
          font="16px"
        >
          {value?.isOverstayRate && (
            <p>{`+${value?.overstayHoursLimit} hours / shift`}</p>
          )}
          {!value?.isOverstayRate && <p>unavailable</p>}
        </StyledGridCell>
      </StyledGrid>
    </>
  );
};

export default Role;

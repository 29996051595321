import { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { addAuthVars } from "@teamrota/authlib";

import useAuth from "~/src/auth/hooks/use-auth";
import { checkErrors } from "~/src/utils/errors";

export const GET_PAY_HISTORY = addAuthVars(gql`
  query payrollHistory($id: ID!, $offset: Int!, $limit: Int!) {
    account {
      payrollRateCardById(id: $id) {
        history(offset: $offset, limit: $limit) {
          id
          timesheetId
          status
          shiftId
          memberId
          input
          facts
          totalPay
          totalCharge
          totals
          blocks
          extras
          error
          recalculatePayAndCharge
          createdAt
          updatedAt
        }
      }
    }
  }
`);

const PAGE_SIZE = 200;

export const usePayrollHistory = (id: string) => {
  const auth = useAuth();
  const [isInitialFetch, setIsInitialFetch] = useState(true);
  const [limit, setLimit] = useState(PAGE_SIZE);
  const [offset, setOffset] = useState(0);

  const { loading, data, fetchMore } = checkErrors(
    useQuery(GET_PAY_HISTORY, {
      variables: auth.addVals(GET_PAY_HISTORY, {
        id,
        offset,
        limit
      }),
      pollInterval: 20000,
      fetchPolicy: isInitialFetch ? "network-only" : "cache-first",
      notifyOnNetworkStatusChange: true
    })
  );

  const history = data?.account?.payrollRateCardById?.history;
  const totalResults = history?.length;

  return {
    loading: isInitialFetch && loading,
    loadingMore: !isInitialFetch && loading,
    history,
    hasMore: history?.length < totalResults,
    fetchMore: () => {
      if (history.length < totalResults) {
        setIsInitialFetch(false);
        const newOffset = offset + limit;
        setOffset(newOffset);

        fetchMore({
          variables: {
            offset: newOffset,
            limit: PAGE_SIZE
          },
          fetchPolicy: "network-only"
        }).then(({ data }: { data: any }) => {
          setLimit(limit + data?.account?.payrollRateCardById?.history?.length);
        });
      }
    }
  };
};

import styled from "styled-components";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";

export const StyledSpan = styled.span`
  font-size: ${STYLE_CONSTS.FONT};
  text-overflow: ellipsis;
  color: ${COLORS.COOL_GREY};
`;

export const IconsWrapper = styled.div`
  ${({ isVisible }) => (isVisible ? "block" : "none")};
`;

export const StyledInput = styled.input`
  width: 100%;
  cursor: default;
  font-size: ${STYLE_CONSTS.FONT};
  text-overflow: ellipsis;
  background-color: transparent;
  border: none;
  user-select: none;
  &::-webkit-input-placeholder {
    color: ${COLORS.WARM_GREY};
    background-color: transparent;
  }
  ${({ readOnly }) =>
    !readOnly &&
    `&:focus {
    outline: none;
    background-color: transparent;
    box-shadow: 0 4px 2px -2px ${COLORS.SLATE};

    &::-webkit-input-placeholder {
      color: transparent;

    }

  }`};

  &:disabled {
    color: ${({ theme }) => theme.text.primary};
  }
`;

import React from "react";
import { RotaModal } from "@teamrota/rota-design";
import { StyledModalWrapper } from "./styles";
import successCircle from "../../../../images/success_circle.png";
import { RotaButton } from "@teamrota/rota-design";

const SuccessModal = ({ handleClose, handleImport }) => {
  return (
    <RotaModal onClose={handleClose} isSmall>
      <StyledModalWrapper>
        <div className="top_block">
          <img src={successCircle} className="icon_img" />
          <div className="title">Loading Successful</div>
        </div>
        <div className="bottom_block">
          <div className="title">
            The file has passed all criteria. Please confirm that you would like
            to upload the file and replace the current one if it exists
          </div>
          <div className="buttons">
            <RotaButton onClick={handleClose} variant="secondary">
              Cancel
            </RotaButton>
            <RotaButton onClick={handleImport}>Import</RotaButton>
          </div>
        </div>
      </StyledModalWrapper>
    </RotaModal>
  );
};

export default SuccessModal;

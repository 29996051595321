import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

const PHOTO_SIZE = "100px";

export const Upload = styled.label`
  vertical-align: top;
  display: inline-block;
  height: ${PHOTO_SIZE};
  width: ${PHOTO_SIZE};
  background-color: ${({ theme }) => theme.neutral.light};
  border-radius: ${({ isRound }) => (isRound ? "50%" : "4px")};
  padding: ${STYLE_CONSTS.UNIT_5};
  margin: ${STYLE_CONSTS.UNIT_1};
  position: relative;
  cursor: pointer;
`;

export const FileInput = styled.input`
  visibility: hidden;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

export const Container = styled.span`
  ${({ sendToBack }) => sendToBack && `position: relative; z-index: 1;`};
`;

export const ErrorText = styled.p`
  color: ${({ theme }) => theme.error.main};
`;

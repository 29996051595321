import React, { useState, useCallback } from "react";
import { RotaButton } from "@teamrota/rota-design";
import debounce from "lodash/debounce";
import Button from "~/src/components/button";
import ReactTags from "react-tag-autocomplete";
import { MEMBER_STATES } from "~/src/consts";
import flow from "lodash/fp/flow";
import filter from "lodash/fp/filter";
import isArray from "lodash/fp/isArray";
import map from "lodash/fp/map";
import omit from "lodash/fp/omit";
import capitalize from "lodash/fp/capitalize";
import FiltersDropdown from "~/src/components/filters-dropdown";
import MemberTypeCheckBox from "./member-type-check-box";
import {
  setIsCasualMemberTypeChecked,
  setIsAgencyMemberTypeChecked,
  setIsPermanentMemberTypeChecked,
  setIsContractorLimitedCompanyMemberTypeChecked,
  setIsContractorUmbrellaCompanyMemberTypeChecked,
  setIsContractorSelfEmployedMemberTypeChecked
} from "../../reducer";
import { removeAtIndex } from "~/src/utils";
import {
  StyledSearchInput,
  ButtonContainer,
  FlexContainer,
  StyledTextInput,
  RatingContainer,
  StyledButton
} from "./member-search.styles";
import getRoles from "../../graphql/get-roles";
import getTagsByAccount from "../../graphql/get-tags-by-account";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const MEMBER_STATE_OPTIONS = Object.entries(MEMBER_STATES).map(
  ([id, name]) => ({
    name: capitalize(name.replace("_", " ")),
    id
  })
);

const MemberSearch = ({
  filters,
  tagsByAccount = [],
  roles,
  updateField,
  setIsCasualMemberTypeChecked,
  setIsAgencyMemberTypeChecked,
  setIsPermanentMemberTypeChecked,
  setIsContractorLimitedCompanyMemberTypeChecked,
  setIsContractorUmbrellaCompanyMemberTypeChecked,
  setIsContractorSelfEmployedMemberTypeChecked,
  userProfile,
  isLoading,
  resetFilters
}) => {
  const [searchText, setSearchText] = useState("");
  const [isSearchLoading, setIsSearchLoading] = useState(false);

  const getFilterTag = filt => {
    const filterItem = filters[filt];

    if (filt === "memberType" && Array.isArray(filterItem)) {
      return filterItem.map(({ memberType }) => memberType).join(", ");
    }

    if (filt !== "memberType" && Array.isArray(filterItem)) {
      return filterItem.map(({ name }) => name).join(", ");
    }

    if (filt === "minRating") {
      return `Min rating of ${filterItem}`;
    }

    if (filt === "maxRating") {
      return `Max rating of ${filterItem}`;
    }

    if (isArray(filterItem)) {
      return filterItem.map(({ name }) => name).join(", ");
    }

    return filterItem;
  };

  const updateSearchText = searchText => {
    setSearchText(searchText);
    debounceSearchTextDispatch(searchText);
  };

  const updateFieldWithLoading = searchText => {
    setIsSearchLoading(false);
    updateField({ searchText });
  };

  const debounceSearchTextDispatch = useCallback(
    debounce(updateFieldWithLoading, 500),
    []
  );

  const validateRatingInput = ratingProp => rating => {
    if (!rating) {
      return updateField({ [ratingProp]: rating });
    }

    const parsedRating = parseFloat(rating, 10);

    if (!isNaN(parsedRating) && parsedRating >= 0 && !(parsedRating > 6)) {
      return updateField({ [ratingProp]: parsedRating });
    }

    return null;
  };

  const removeTagHandler = (e, filt) => {
    if (filt === "memberType") {
      setIsCasualMemberTypeChecked(false);
      setIsAgencyMemberTypeChecked(false);
      setIsPermanentMemberTypeChecked(false);
      setIsContractorLimitedCompanyMemberTypeChecked(false);
      setIsContractorUmbrellaCompanyMemberTypeChecked(false);
      setIsContractorSelfEmployedMemberTypeChecked(false);
    }
    updateField({ [filt]: undefined });
  };

  const tags = filters?.tags || [];
  const roleNames = filters?.roleNames || [];
  const stateIn = filters?.stateIn || [];
  const serviceAreaIds = filters?.serviceAreaIds || [];
  const memberType = filters?.memberType || [];

  return (
    <div>
      <StyledSearchInput
        isInline
        isLarge
        isLoading={isSearchLoading || isLoading}
        onChange={updateSearchText}
        value={searchText}
        placeholder="Search staff member by name, email, phone or ID"
      />
      <FiltersDropdown>
        <p>Role</p>
        <ReactTags
          autofocus={false}
          placeholder="Any"
          handleDelete={index => {
            updateField({
              roleNames: removeAtIndex(roleNames, index)
            });
          }}
          handleAddition={role => {
            updateField({
              roleNames: [...roleNames, role]
            });
          }}
          tags={filters?.roleNames || []}
          suggestions={roles || []}
        />

        <p>Tags</p>
        <ReactTags
          autofocus={false}
          placeholder="Any"
          handleDelete={index => {
            updateField({
              tags: removeAtIndex(tags, index)
            });
          }}
          handleAddition={tag => {
            updateField({
              tags: [...tags, tag]
            });
          }}
          tags={filters?.tags || []}
          suggestions={tagsByAccount}
        />

        <FlexContainer>
          <RatingContainer>
            <p>Rating from</p>
            <StyledTextInput
              type="number"
              placeholder="Any"
              minValue="1"
              maxValue="6"
              isOutline
              value={filters.minRating}
              onChangeValue={validateRatingInput("minRating")}
            />
          </RatingContainer>
          <div>
            <p>to</p>
            <StyledTextInput
              type="number"
              placeholder="Any"
              minValue="1"
              maxValue="6"
              isOutline
              value={filters.maxRating}
              onChangeValue={validateRatingInput("maxRating")}
            />
          </div>
        </FlexContainer>

        <p>Member Types</p>
        <MemberTypeCheckBox memberType={memberType} updateField={updateField} />

        <p>Member Status</p>
        <ReactTags
          autofocus={false}
          placeholder="Any"
          handleDelete={index => {
            updateField({
              stateIn: removeAtIndex(stateIn, index)
            });
          }}
          handleAddition={state => {
            updateField({
              stateIn: [...stateIn, state]
            });
          }}
          tags={filters?.stateIn}
          suggestions={MEMBER_STATE_OPTIONS}
        />

        <p>Service Area</p>
        <ReactTags
          autofocus={false}
          placeholder="Any"
          handleDelete={index => {
            updateField({
              serviceAreaIds: removeAtIndex(serviceAreaIds, index)
            });
          }}
          handleAddition={serviceArea => {
            updateField({
              serviceAreaIds: [...serviceAreaIds, serviceArea]
            });
          }}
          tags={filters?.serviceAreaIds || []}
          suggestions={userProfile?.account?.serviceAreas || []}
        />

        <ButtonContainer>
          <RotaButton
            variant="outlined"
            isLoading={isLoading}
            isOutline
            onClick={() => resetFilters()}
          >
            Reset filters
          </RotaButton>
        </ButtonContainer>
      </FiltersDropdown>
      <div>
        {flow(
          omit([
            "orderByProp",
            "searchText",
            "isCasualMemberTypeChecked",
            "isAgencyMemberTypeChecked",
            "isPermanentMemberTypeChecked",
            "isContractorLimitedCompanyMemberTypeChecked",
            "isContractorUmbrellaCompanyMemberTypeChecked",
            "isContractorSelfEmployedMemberTypeChecked"
          ]),
          Object.keys,
          filter(filt => {
            if (isArray(filters[filt])) {
              return filters[filt].length > 0;
            }
            return filters[filt];
          }),
          map(filt => (
            <StyledButton
              isIconRight
              iconName={Button.iconNames.EXIT}
              iconSize={11}
              key={filt}
              isSmall
              onClick={e => removeTagHandler(e, filt)}
              iconTranslateY="1"
              isUsingFlexbox
            >
              {getFilterTag(filt)}
            </StyledButton>
          ))
        )(filters)}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  isCasualMemberTypeChecked: state.myStaff.filters.isCasualMemberTypeChecked,
  isAgencyMemberTypeChecked: state.myStaff.filters.isAgencyMemberTypeChecked,
  isPermanentMemberTypeChecked:
    state.myStaff.filters.isPermanentMemberTypeChecked,
  isContractorLimitedCompanyMemberTypeChecked:
    state.myStaff.filters.isContractorLimitedCompanyMemberTypeChecked,
  isContractorUmbrellaCompanyMemberTypeChecked:
    state.myStaff.filters.isContractorUmbrellaCompanyMemberTypeChecked,
  isContractorSelfEmployedMemberTypeChecked:
    state.myStaff.filters.isContractorSelfEmployedMemberTypeChecked
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setIsCasualMemberTypeChecked,
      setIsAgencyMemberTypeChecked,
      setIsPermanentMemberTypeChecked,
      setIsContractorLimitedCompanyMemberTypeChecked,
      setIsContractorUmbrellaCompanyMemberTypeChecked,
      setIsContractorSelfEmployedMemberTypeChecked
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(getTagsByAccount(getRoles(MemberSearch)));

import React, { useEffect, useState } from "react";
import { debounce } from "lodash";
import { RotaDropdown, iconNew as RotaIcon } from "@teamrota/rota-design";
import { usePartnerOwners } from "~/src/containers/partners/graphql/use-partner-owners";
import DeleteModal from "../../delete-modal";
import { reasonOptions } from "../../consts";
import { useGetMembers } from "../../graphql/get-members";
import { getMemberOptions, getPartnerOptions } from "../../utils";

import {
  Heading,
  Container,
  Exclusions,
  DropdownWrapper,
  ActionsContainer,
  StyledButton,
  StyledPlusIcon,
  Divider,
  OwnerContainer
} from "./edit-exclusions.styles";

const { Bin } = RotaIcon;

const EditExclusions = ({
  venueId,
  partnerId,
  setSnackBar,
  ownerId,
  setOwnerId,
  exclusions,
  addExclusion,
  setExclusions,
  updateExclusion,
  removeExclusion,
  deleteExclusion,
  setIsButtonDisabled
}) => {
  const [isWarningModal, setIsWarningModal] = useState(false);
  const [selectedExclusionId, setSelectedExclusionId] = useState(null);
  const [searchName, _setSearchName] = useState("");

  const { members, isLoading, onLoadMore, onFilter } = useGetMembers(venueId);
  const { partnerOwners } = usePartnerOwners();

  const memberOptions = getMemberOptions(members);
  const partnerOptions = getPartnerOptions(partnerOwners);

  const isAddLastExclusion = !exclusions?.at(-1)?.isEditable;

  const setSearchName = debounce(_setSearchName, 300);

  useEffect(() => {
    onFilter(searchName);
  }, [searchName]);

  const handleAction = async (id, isExclusionId) => {
    if (isExclusionId) {
      setSelectedExclusionId(id);
      setIsWarningModal(true);
    } else {
      removeExclusion(id);
    }
  };

  const handleDeleteExclusion = async () => {
    try {
      await deleteExclusion(selectedExclusionId, partnerId);
      setExclusions(
        exclusions.filter(
          ({ memberVenueExclusionId }) =>
            memberVenueExclusionId !== selectedExclusionId
        )
      );
      setSnackBar({
        open: true,
        message: "Exclusion deleted successfully.",
        severity: "success"
      });
    } catch {
      setSnackBar({
        open: true,
        message: "Something went wrong. Please try again",
        severity: "error"
      });
    }
    setIsWarningModal(false);
  };

  if (!partnerOptions?.length) return null;

  return (
    <>
      <OwnerContainer>
        <Heading>Assign to:</Heading>
        <DropdownWrapper>
          <RotaDropdown
            value={ownerId}
            placeholder="Assign to"
            options={partnerOptions}
            onChange={value => {
              setOwnerId(value);
              setIsButtonDisabled(false);
            }}
          />
        </DropdownWrapper>
      </OwnerContainer>
      <Divider />
      <Heading>Exclude:</Heading>
      <Container>
        {exclusions.map(
          (
            {
              memberId,
              reason,
              isEditable,
              memberVenueExclusionId,
              firstName,
              lastName
            },
            index
          ) => {
            const id = memberVenueExclusionId || memberId;
            const isExclusionId = !!memberVenueExclusionId;
            const isError =
              exclusions.filter(exclusion => exclusion.memberId === memberId)
                .length > 1;
            const isActive = memberId && reason && !isError;
            const placeholder = memberVenueExclusionId
              ? `${firstName} ${lastName}`
              : "Select a member";

            return (
              <Exclusions key={memberId}>
                <DropdownWrapper>
                  <RotaDropdown
                    isSearchable
                    value={memberId}
                    placeholder={placeholder}
                    isDisabled={!isEditable}
                    options={memberOptions}
                    isError={isError}
                    errorMessage={"Exclusion exists for this member"}
                    onChange={value =>
                      updateExclusion(value, index, "memberId")
                    }
                    isLoading={isLoading}
                    onLoadMore={debounce(onLoadMore, 500)}
                    onSearchText={value => setSearchName(value)}
                  />
                </DropdownWrapper>
                <DropdownWrapper>
                  <RotaDropdown
                    value={reason}
                    placeholder="Select a reason"
                    options={reasonOptions}
                    isDisabled={!isEditable}
                    onChange={value => updateExclusion(value, index, "reason")}
                  />
                </DropdownWrapper>
                <ActionsContainer>
                  {isEditable && (
                    <StyledPlusIcon
                      isActive={isActive}
                      onClick={() => {
                        if (isActive) {
                          setIsButtonDisabled(false);
                          updateExclusion(false, index, "isEditable");
                        }
                      }}
                    />
                  )}
                  <Bin onClick={() => handleAction(id, isExclusionId)} />
                </ActionsContainer>
              </Exclusions>
            );
          }
        )}

        {isAddLastExclusion && (
          <StyledButton variant="outlined" onClick={addExclusion}>
            Add another
          </StyledButton>
        )}
      </Container>
      <Divider />
      {isWarningModal && (
        <DeleteModal
          onCancel={() => setIsWarningModal(false)}
          handleDelete={handleDeleteExclusion}
        />
      )}
    </>
  );
};

export default EditExclusions;

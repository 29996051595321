import React from "react";
import TextInput from "~/src/components/form-components/text-input";
import { GroupWrapper, Label } from "./time-field.styles";

export default function TextField(props) {
  const value =
    !props.value || props.value.length !== 5 ? "00:00" : props.value;
  return (
    <GroupWrapper isMarginRight={props.isMarginRight}>
      <Label>{props.label}</Label>
      <TextInput
        isError={props.isError}
        type="time"
        isSmall
        onChangeValue={val => props.onChange(cleanValue(val))}
        value={cleanValue(value)}
      />
    </GroupWrapper>
  );
}

function cleanValue(value) {
  let [hours, mins] = value.split(":");
  if (parseInt(hours, 10) > 23) hours = "23";
  if (parseInt(mins, 10) > 59) mins = "59";
  return `${hours}:${mins}`;
}

import React, { useState } from "react";
import { CSVLink } from "react-csv";
import moment from "moment-timezone";

import {
  ModalHeader,
  RotaButtonIcon,
  RotaNewModal,
  iconNew
} from "@teamrota/rota-design";

import { makeData } from "./csv";
import { formatDuration, getFilename } from "../util";

import Money from "../Money";
import RuleName from "../RuleName";

import { LinkWrapper, OutputWrapper } from "./styles";
import JsonEditor from "../JsonEditor";
import { orderKeys } from "./util";

const { Cancel } = iconNew;

interface OutputPanelProps {
  id: string;
  payrollRateCards: any[];

  input: any;
  blocks: any[];
  extras: any;
  facts: any;
  totals: any;
}

const OutputPanel: React.FC<OutputPanelProps> = ({
  id,
  payrollRateCards,
  input,
  facts,
  blocks,
  extras,
  totals
}) => {
  const [isViewFacts, setIsViewFacts] = useState(false);
  const [isViewInput, setIsViewInput] = useState(false);

  return (
    <OutputWrapper>
      <table>
        <thead>
          <tr>
            <th>Block</th>
            <th>Day</th>
            <th>Length</th>
            <th>Attributes</th>
            <th>Final Rule</th>
            <th>Pay Rates</th>
            <th>Pay/Hour</th>
            <th>Pay</th>
            <th>Charge Rates</th>
            <th>Charge/Hour</th>
            <th>Charge</th>
          </tr>
        </thead>

        <tbody>
          {blocks.map((block: any) => (
            <tr>
              <td>{moment(block?.begins).format("yyyy-MM-DD HH:mm")}</td>
              <td>{moment(block?.begins).format("ddd")}</td>
              <td>{formatDuration(block?.length)}</td>
              <td>
                {block?.attributes?.map?.((r: any) => (
                  <div>{r}</div>
                ))}
              </td>
              <td>
                <RuleName
                  name={block?.finalRule}
                  id={id}
                  payrollRateCards={payrollRateCards}
                />
              </td>
              <td>
                {block?.rates.PAY?.map?.((r: any) => (
                  <div>{r}</div>
                ))}
              </td>
              <td>
                <Money value={block?.rateAmounts?.PAY} />
              </td>
              <td>
                <Money value={block?.totals?.PAY} />
              </td>
              <td>
                {block?.rates.CHARGE?.map?.((r: any) => (
                  <div>{r}</div>
                ))}
              </td>
              <td>
                <Money value={block?.rateAmounts?.CHARGE} />
              </td>
              <td>
                <Money value={block?.totals?.CHARGE} />
              </td>
            </tr>
          ))}

          <tr>
            <td>Extras</td>
            <td>n/a</td>
            <td>n/a</td>
            <td>
              {extras?.flags?.map?.((r: any) => (
                <div>{r}</div>
              ))}
            </td>
            <td>
              <RuleName
                name={extras?.finalRule}
                id={id}
                payrollRateCards={payrollRateCards}
              />
            </td>
            <td>
              {extras?.rates?.PAY?.map?.((r: any) => (
                <div>{r}</div>
              ))}
            </td>
            <td>&nbsp;</td>
            <td>
              <Money value={extras?.totals?.PAY} />
            </td>
            <td>
              {extras?.rates?.CHARGE?.map?.((r: any) => (
                <div>{r}</div>
              ))}
            </td>
            <td>&nbsp;</td>
            <td>
              <Money value={extras?.totals?.CHARGE} />
            </td>
          </tr>

          <tr>
            <td>Totals</td>
            <td>&nbsp;</td>
            <td>{formatDuration(facts?.billable?.shiftDuration)}</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>
              <Money value={totals?.PAY} />
            </td>
            <td></td>
            <td>&nbsp;</td>
            <td>
              <Money value={totals?.CHARGE} />
            </td>
          </tr>
        </tbody>
      </table>

      {isViewFacts && (
        <RotaNewModal
          size={"small"}
          header={
            <ModalHeader
              title="Calculations Facts"
              endAction={
                <RotaButtonIcon onClick={() => setIsViewFacts(false)}>
                  <Cancel />
                </RotaButtonIcon>
              }
            />
          }
          onClose={() => setIsViewFacts(false)}
        >
          <JsonEditor
            name={`facts-json-editor`}
            canEdit={false}
            height="300px"
            value={JSON.stringify(orderKeys(facts), null, 2)}
            onChange={() => {}}
          />
        </RotaNewModal>
      )}

      {isViewInput && (
        <RotaNewModal
          size={"small"}
          header={
            <ModalHeader
              title="Calculation Input"
              endAction={
                <RotaButtonIcon onClick={() => setIsViewInput(false)}>
                  <Cancel />
                </RotaButtonIcon>
              }
            />
          }
          onClose={() => setIsViewInput(false)}
        >
          <JsonEditor
            name={`input-json-editor`}
            canEdit={false}
            height="300px"
            value={JSON.stringify(orderKeys(input), null, 2)}
            onChange={() => {}}
          />
        </RotaNewModal>
      )}

      <LinkWrapper>
        <a href="javascript://" onClick={() => setIsViewInput(true)}>
          View Input
        </a>

        <a href="javascript://" onClick={() => setIsViewFacts(true)}>
          View Facts
        </a>

        <CSVLink
          filename={"simulation-" + getFilename(id, payrollRateCards)}
          data={makeData({ blocks, extras, facts, totals })}
        >
          Download CSV
        </CSVLink>
      </LinkWrapper>
    </OutputWrapper>
  );
};

export default OutputPanel;

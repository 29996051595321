import React from "react";
import { useHistory } from "react-router";

import {
  RotaTable,
  THead,
  TrHead,
  TBody,
  Th,
  Tr,
  Td,
  InnerCell
} from "@teamrota/rota-design";

import { formatDateFull } from "~/src/utils/formatting";
import Icon from "~/src/components/icon";
import { EmptyText, IconDiv } from "~/src/components/payroll/style";

import IconLink from "./components/IconLink";
import { PayrollRateCardType } from "./graphql/use-payroll-rate-cards";

interface TableProps {
  payrollRateCards: any[];

  showRateCardModal: (id: string) => void;
  showRulesInfoModal: (id: string) => void;
  showHistoryModal: (id: string) => void;
  showSimulatorModal: (id: string) => void;
}

const Table: React.FC<TableProps> = ({
  payrollRateCards,
  showSimulatorModal,
  showHistoryModal,
  showRateCardModal,
  showRulesInfoModal
}) => {
  const history = useHistory();

  return (
    <RotaTable>
      <THead>
        <TrHead>
          <Th>Partner</Th>
          <Th>Role</Th>
          <Th>Venue</Th>
          <Th>Rates</Th>
          <Th>Scale</Th>
          <Th>Calculations</Th>
          <Th>Rules</Th>
          <Th>Effective From</Th>
          <Th>Last edited</Th>
          <Th>&nbsp;</Th>
        </TrHead>
      </THead>

      <TBody>
        {payrollRateCards?.length ? (
          payrollRateCards.map((rateCard: PayrollRateCardType) => (
            <Tr key={rateCard.id}>
              <Td>
                <InnerCell>
                  {rateCard.partnerAccount?.accountName || "N/A"}
                </InnerCell>
              </Td>
              <Td>
                <InnerCell>{rateCard.role?.name || "All Roles"}</InnerCell>
              </Td>
              <Td>
                <InnerCell>
                  {rateCard.venue?.name ||
                    (rateCard.allVenues ? "All Venues" : "N/A")}
                </InnerCell>
              </Td>
              <Td>
                <InnerCell>
                  {rateCard.payrollRate?.name || "N/A"}

                  <IconLink
                    name="edit"
                    color={Icon.colors.BLACK}
                    size={10}
                    onClick={() =>
                      history.push(
                        `/payroll/pay-rate/${rateCard.payrollRate?.id}`
                      )
                    }
                  />
                </InnerCell>
              </Td>
              <Td>
                <InnerCell>
                  {rateCard.payrollScale?.name || "N/A"}

                  <IconLink
                    name="EDIT"
                    color={Icon.colors.BLACK}
                    size={10}
                    onClick={() =>
                      history.push(
                        `/payroll/pay-scales/${rateCard.payrollScale?.id}`
                      )
                    }
                  />
                </InnerCell>
              </Td>
              <Td>
                <InnerCell>
                  {rateCard.payrollCalculation?.name || "N/A"}

                  <IconLink
                    name="EDIT"
                    color={Icon.colors.BLACK}
                    size={10}
                    onClick={() =>
                      history.push(
                        `/payroll/calculations/${rateCard.payrollCalculation?.id}`
                      )
                    }
                  />
                </InnerCell>
              </Td>
              <Td>
                <InnerCell>
                  {rateCard.payrollRule?.name || "N/A"}

                  <IconLink
                    name="EDIT"
                    color={Icon.colors.BLACK}
                    size={10}
                    onClick={() =>
                      history.push(`/payroll/rules/${rateCard.payrollRule?.id}`)
                    }
                  />
                </InnerCell>
              </Td>
              <Td>
                <InnerCell>{formatDateFull(rateCard.effectiveFrom)}</InnerCell>
              </Td>
              <Td>
                <InnerCell>
                  {rateCard.updatedAt
                    ? formatDateFull(rateCard.updatedAt)
                    : formatDateFull(rateCard.createdAt)}
                </InnerCell>
              </Td>
              <Td>
                <IconDiv>
                  <IconLink
                    name="SHIFTS"
                    size={25}
                    isButton
                    color={Icon.colors.MAIN_ORANGE}
                    onClick={() => showRateCardModal(rateCard.id)}
                  />

                  <IconLink
                    name="PLAY"
                    size={25}
                    isButton
                    color={Icon.colors.MAIN_ORANGE}
                    onClick={() => showSimulatorModal(rateCard.id)}
                  />
                </IconDiv>

                <IconDiv>
                  <IconLink
                    name="CALENDAR"
                    size={25}
                    isButton
                    color={Icon.colors.MAIN_ORANGE}
                    onClick={() => showRulesInfoModal(rateCard.id)}
                  />

                  <IconLink
                    name="HISTORY"
                    size={25}
                    isButton
                    color={Icon.colors.MAIN_ORANGE}
                    onClick={() => showHistoryModal(rateCard.id)}
                  />
                </IconDiv>
              </Td>
            </Tr>
          ))
        ) : (
          <Tr>
            <Td colSpan={11}>
              <EmptyText>Nothing to see here 👀 Try clicking Create!</EmptyText>
            </Td>
          </Tr>
        )}
      </TBody>
    </RotaTable>
  );
};

export default Table;

import React, { useState } from "react";

import { Role } from "@teamrota/authlib";
import useAuth from "~/src/auth/hooks/use-auth";

import set from "lodash/fp/set";
import remove from "lodash/fp/remove";
import PageHeader from "~/src/components/page-header";
import { Row, Column } from "~/src/components/grid";
import Button from "~/src/components/button";
import deleteItem from "~/src/graphql/mutations/delete-item/delete-item.decorator";
import getProfile from "~/src/graphql/queries/get-profile/get-profile-query.decorator";
import { errorModal } from "~/src/utils/errors";
import asyncConfirm from "~/src/utils/async-confirm";
import { stallPromise } from "~/src/utils";
import EditTemplate from "./components/edit-template";
import Templates from "./components/templates";
import createOrUpdateUniformTemplate from "./graphql/create-update-uniform-templates";
import getUniformItemSuggestions from "./graphql/get-uniform-item-suggestions";
import { StyledRow, Title, TitleContainer } from "../../templates.styles";

const DEFAULT_TEMPLATE = {
  identifier: "",
  items: [],
  newTag: "",
  id: undefined,
  identifierIsInvalid: false,
  itemsAreInvalid: false
};

const UniformTemplates = ({
  createOrUpdateUniformTemplate,
  deleteItem,
  user,
  isLoading: parentIsLoading
}) => {
  const auth = useAuth();

  const [dressCodeTemplates, setDressCodeTemplates] = useState([
    DEFAULT_TEMPLATE
  ]);
  const [shouldShowSavedState, setShouldShowSavedState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTemplate, setActiveTemplate] = useState(0);

  const handlePostSaveIndicator = async () => {
    setShouldShowSavedState(true);
    await Button.successDelay();
    setShouldShowSavedState(false);
  };

  const handleReset = () =>
    setDressCodeTemplates(
      Object.assign([...dressCodeTemplates], {
        [activeTemplate]: DEFAULT_TEMPLATE
      })
    );

  const handleDelete = async uniformTemplateId => {
    try {
      if (
        await asyncConfirm("Are you sure you want to delete this template?", {
          confirmButtonText: "Delete"
        })
      ) {
        await deleteItem({
          updateQueries: {
            getProfile: prevData => {
              const prevUniforms = prevData.user.account.uniformTemplates;
              return set(
                "user.account.uniformTemplates",
                remove({ id: uniformTemplateId }, prevUniforms)
              )(prevData);
            }
          },
          variables: {
            type: "uniformTemplates",
            id: uniformTemplateId
          }
        });
      }
    } catch (e) {
      errorModal(e);
    }
  };

  const saveUniform = async event => {
    event.preventDefault();

    const { identifier, items, id, dressCodeId, newTag } = dressCodeTemplates[
      activeTemplate
    ];

    const templateItems =
      dressCodeId !== undefined && dressCodeId !== null
        ? [id ? items[0] : newTag, `ITEM:ID:${dressCodeId}`]
        : items;

    if (!identifier || !templateItems?.length) {
      setDressCodeTemplates(
        Object.assign([...dressCodeTemplates], {
          [activeTemplate]: {
            ...dressCodeTemplates[activeTemplate],
            identifierIsInvalid: !identifier,
            itemsAreInvalid: !templateItems.length
          }
        })
      );
      return;
    }

    setIsLoading(true);

    try {
      await stallPromise(
        createOrUpdateUniformTemplate(id, identifier, templateItems)
      );
      setIsLoading(false);

      setDressCodeTemplates(
        Object.assign([...dressCodeTemplates], {
          [activeTemplate]: DEFAULT_TEMPLATE
        })
      );

      handlePostSaveIndicator();
    } catch (e) {
      setIsLoading(false);
      errorModal(e);
    }
  };

  const handleEditMode = uniformTemplate => {
    const { items } = uniformTemplate;

    const activeTemplate = uniformTemplate.dressCodeId !== null ? 0 : 1;

    setActiveTemplate(activeTemplate);

    setDressCodeTemplates(
      Object.assign([...dressCodeTemplates], {
        [activeTemplate]: {
          ...uniformTemplate,
          identifierIsInvalid: !uniformTemplate.identifier,
          itemsAreInvalid: !items.length
        }
      })
    );
  };

  const handleChange = key => value =>
    setDressCodeTemplates(
      Object.assign([...dressCodeTemplates], {
        [activeTemplate]: {
          ...dressCodeTemplates[activeTemplate],
          [`${key}IsInvalid`]: !value.length,
          [key]: value
        }
      })
    );

  const handleNewTag = (tag, shouldClearNewTag = false) => {
    const appendedTags = [...dressCodeTemplates[activeTemplate].items, tag];

    const items = [...new Set(appendedTags)];

    setDressCodeTemplates(
      Object.assign([...dressCodeTemplates], {
        [activeTemplate]: {
          ...dressCodeTemplates[activeTemplate],
          items,
          ...(shouldClearNewTag ? { newTag: "" } : {}),
          itemsAreInvalid: !items.length
        }
      })
    );
  };

  const handleRemoveItem = index => {
    const stateCopyItems = [...dressCodeTemplates[activeTemplate].items];
    stateCopyItems.splice(index, 1);

    setDressCodeTemplates(
      Object.assign([...dressCodeTemplates], {
        [activeTemplate]: {
          ...dressCodeTemplates[activeTemplate],
          items: stateCopyItems
        }
      })
    );
  };

  const handleUpdateItem = (index, text) => {
    const stateCopyItems = [...dressCodeTemplates[activeTemplate].items];
    stateCopyItems.splice(index, 1, text);

    setDressCodeTemplates(
      Object.assign([...dressCodeTemplates], {
        [activeTemplate]: {
          ...dressCodeTemplates[activeTemplate],
          items: stateCopyItems
        }
      })
    );
  };

  const handleVisualSelected = dressCodeId =>
    setDressCodeTemplates(
      Object.assign([...dressCodeTemplates], {
        [activeTemplate]: {
          ...dressCodeTemplates[activeTemplate],
          dressCodeId
        }
      })
    );

  const toggleActiveTab = () => {
    if (dressCodeTemplates.length === 1) {
      setDressCodeTemplates([...dressCodeTemplates, DEFAULT_TEMPLATE]);
    }
    setActiveTemplate(activeTemplate === 0 ? 1 : 0);
  };

  const formatTemplateUniforms = templates =>
    templates.map(template => {
      let dressCodeId = null;
      template.items.map(item => {
        if (/(ITEM:ID:)/g.exec(item)) {
          dressCodeId = item.replace(/\D/g, "");
        }
        return item;
      });
      return {
        ...template,
        items: template.items.filter(item => !/(ITEM:ID:)/g.exec(item)),
        dressCodeId
      };
    });

  const dressCodeTemplate = dressCodeTemplates[activeTemplate];

  const uniformTemplates = formatTemplateUniforms(
    user?.account?.uniformTemplates
  );

  const canCreateUniform = auth.hasRole(Role.UNIFORM_TEMPLATES_CREATE);
  const canEditUniform = auth.hasRole(Role.UNIFORM_TEMPLATES_EDIT);

  const showComponent = dressCodeTemplate?.id
    ? canEditUniform
    : canCreateUniform;

  return (
    <div>
      <PageHeader
        title="Uniform Templates"
        subtext="Use one of our standard uniforms, or add a text description to speed up posting shifts"
      />
      <StyledRow>
        {showComponent && (
          <Column medium={1 / 2}>
            <EditTemplate
              onReset={handleReset}
              inEditMode={
                dressCodeTemplate?.id !== null &&
                dressCodeTemplate?.id !== undefined
              }
              errorLabel={
                dressCodeTemplate?.identifierIsInvalid
                  ? "Please enter a template name"
                  : undefined
              }
              name={dressCodeTemplate?.identifier}
              onUpdateName={handleChange("identifier")}
              onEditNewTag={handleChange("newTag")}
              onEnter={() => {
                if (dressCodeTemplate?.newTag)
                  handleNewTag(dressCodeTemplate?.newTag, true);
              }}
              onUpdateItem={handleUpdateItem}
              onRemoveItem={handleRemoveItem}
              templateItems={dressCodeTemplate?.items}
              templateItemsErrorText={
                dressCodeTemplate?.itemsAreInvalid
                  ? "Press enter to add the Uniform item"
                  : undefined
              }
              isLoading={isLoading}
              hasSaved={shouldShowSavedState}
              newTagValue={dressCodeTemplate?.newTag}
              onSave={saveUniform}
              onVisualSelected={handleVisualSelected}
              selectedVisual={dressCodeTemplate?.dressCodeId}
              toggleActiveTab={toggleActiveTab}
              activeTemplate={activeTemplate}
            />
          </Column>
        )}
        <Column medium={showComponent ? 1 / 2 : 1}>
          <TitleContainer>
            <Title>My Templates</Title>
          </TitleContainer>
          <Row>
            <Templates
              items={parentIsLoading ? [] : uniformTemplates}
              onEdit={handleEditMode}
              onDelete={handleDelete}
              size={showComponent ? 1 / 2 : 1 / 4}
            />
          </Row>
        </Column>
      </StyledRow>
    </div>
  );
};

export default getProfile(
  createOrUpdateUniformTemplate(
    getUniformItemSuggestions(deleteItem(UniformTemplates))
  )
);

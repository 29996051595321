import React from "react";
import moment from "moment-timezone";

import FiltersDateRange from "~/src/components/form-components/filters-date-range";
import styles from "./filters.styles";

const Filters = ({ startTime, endTime, onUpdate }) => (
  <div style={styles.filter}>
    <FiltersDateRange
      isInline
      startTime={startTime ? moment(startTime, "DD/MM/YYYY") : ""}
      endTime={endTime ? moment(endTime, "DD/MM/YYYY") : ""}
      onChangeStartTime={startTime =>
        onUpdate({ startTime: moment(startTime).format("DD/MM/YYYY") })
      }
      onChangeEndTime={endTime =>
        onUpdate({ endTime: moment(endTime).format("DD/MM/YYYY") })
      }
    />
  </div>
);
export default Filters;

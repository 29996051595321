import React from "react";
import InputWrapper from "../input-wrapper";

import { Input } from "./number-input.styles";

const NumberInput = ({
  isString,
  input,
  noLeadingZeroes,
  readOnly,
  memberType,
  placeholder,
  required,
  title
}) => {
  const handleChange = ({ target: { value } }) => {
    if (!isString) {
      if (!value) {
        value = 0;
      }
      input?.onChange(parseInt(value));
    } else {
      if (/^\d*$/.test(value)) {
        input?.onChange(noLeadingZeroes ? `${value}` : value);
      }
    }
  };

  return (
    <InputWrapper memberType={memberType} title={title}>
      <Input
        required={required}
        value={input.value || ""}
        disabled={readOnly}
        placeholder={!readOnly ? placeholder : ""}
        onChange={handleChange}
      />
    </InputWrapper>
  );
};

export default NumberInput;

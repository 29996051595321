export const hoursAndMinutesDisplay = (
  shiftById,
  previewHours,
  isNoShow,
  isTurnedAway
) => {
  const decimalTimeString =
    previewHours !== undefined ? previewHours : shiftById?.paidHours;
  let decimalTime = parseFloat(decimalTimeString);
  decimalTime = decimalTime * 60 * 60;

  let hours = (decimalTime / (60 * 60)).toString().split(".")[0];
  decimalTime = decimalTime - parseInt(hours) * 60 * 60;

  let minutes = Math.round(decimalTime / 60);
  decimalTime = decimalTime - minutes * 60;

  let seconds = Math.round(decimalTime);
  if (hours < 10 && hours > 0) {
    hours = "0" + hours;
  }
  if (minutes < 10 && minutes > 0) {
    minutes = "0" + minutes;
  }
  if (seconds < 10 && seconds > 0) {
    seconds = "0" + seconds;
  }
  if (isNoShow || isTurnedAway) {
    hours = "0";
    minutes = "0";
    seconds = "0";
  }
  return `${hours} h ${minutes} min`;
};

import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";

interface Subvenue {
  id: string;
  name: string;
}

interface Venue {
  id: string;
  name: string;
  subvenues: Subvenue[];
}

const GET_VENUES = gql`
  query getVenues {
    user {
      id
      account {
        id
        venues {
          id
          name
          subvenues {
            id
            name
          }
        }
      }
    }
  }
`;

const useVenues = () => {
  const { data: venueData } = useQuery(GET_VENUES);
  const venues: Venue[] = venueData?.user?.account?.venues || [];

  return { venues };
};

export default useVenues;

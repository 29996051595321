import React from "react";
import { RotaButton, colors } from "@teamrota/rota-design";
import {
  StyledWrapDialog,
  StyledWrapIcon,
  StyledWrapQuestion,
  StyledWrapAfirmation,
  StyledWrapButtons
} from "./dialog-style";

const ratingLabels = [
  "Gross misconduct",
  "Bad shift",
  "Not for us",
  "Good shift",
  "Great Shift",
  "Perfect shift"
];

const DialogBadRate = ({
  icon,
  onConfirm,
  onCancel,
  value,
  upcomingAccepted
}) => {
  const confirmMessage = upcomingAccepted
    ? `This member is accepted onto ${upcomingAccepted} upcoming shifts.
  This will cancel all accepted shifts.`
    : "This member will no longer be shown for upcoming shifts.";
  return (
    <StyledWrapDialog>
      <StyledWrapIcon color="#f6afaf" borderColor={colors.red}>
        {icon}
      </StyledWrapIcon>
      <StyledWrapQuestion color={colors.red}>
        Are you sure you want to rate this shift as a “{ratingLabels[value - 1]}
        ”({value} stars)?
      </StyledWrapQuestion>
      <StyledWrapAfirmation>{confirmMessage}</StyledWrapAfirmation>
      <StyledWrapButtons>
        <RotaButton variant="secondary" onClick={() => onCancel()}>
          Cancel
        </RotaButton>
        <RotaButton color={colors.red} onClick={() => onConfirm()}>
          Confirm
        </RotaButton>
      </StyledWrapButtons>
    </StyledWrapDialog>
  );
};

export default DialogBadRate;

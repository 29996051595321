import { gql } from "@apollo/client";

export const GET_REQUESTER_PROFILE = gql`
  fragment RoleRateFragment on roleRate {
    id
    roleName
    roleId
    startDate
    endDate
    account {
      id
      logo
      accountName
    }
    isDayRate
    isUncalculatedRate
  }

  query getRequesterProfile($userId: ID!, $memberId: ID) {
    requesterProfile(userId: $userId) {
      id
      account {
        id
        briefingTemplates(
          orderByColumn: "identifier"
          orderByDirection: "ASC"
        ) {
          id
          identifier
          content
          createdAt
        }
        uniformTemplates {
          id
          identifier
          items
          createdAt
        }
        venues(memberId: $memberId, partnerId: $userId) {
          id
          name
          address
          meetingPoint
          directions
          lat
          lng
          createdAt
          isPinCodeCheckInEnabled
          isQrCodeCheckInEnabled
          subvenues {
            id
            name
            integraId
            mmCode
            costCode
            venueId
          }
        }

        roleRates(accountType: requester) {
          ...RoleRateFragment
        }
        isRebookingEnabled
        isSleepTimeEnabled
      }
    }
  }
`;

import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

import SearchInput from "~/src/components/form-components/search-input";

export const StyledSearchInput = styled(SearchInput)`
  margin: 1% 0;
  width: 35%;
`;
export const StyledContent = styled.div`
  overflow: auto;
  padding: 30px;
  width: 50vw;
  max-height: 90vh;
  min-height: 90vh;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Divider = styled.div`
  border-bottom: ${STYLE_CONSTS.SMALL_DIVIDER_BORDER};
  padding-bottom: 1%;
  width: 100%;
`;

export const Title = styled.div`
  font-size: ${STYLE_CONSTS.FONT_XLARGE};
  color: ${({ theme }) => theme.text.primary};
`;

import React from "react";
import { TableData, TableRow } from "~/src/components/floating-table";
import { Container, TextContainer, Strip, Circle } from "./index.styles";

const Placeholder = ({ withImage }) => (
  <Container>
    {withImage && <Circle />}
    <TextContainer>
      <Strip width="80%" />
      <Strip width="60%" />
    </TextContainer>
  </Container>
);

export default ({ columnWidths }) => (
  <div>
    {Array(5)
      .fill(null)
      .map((_, rowIndex) => (
        <TableRow key={rowIndex}>
          {columnWidths.map((columnsWidth, columnIndex) => (
            <TableData width={columnsWidth} key={columnIndex}>
              <Placeholder withImage={columnIndex === 0} />
            </TableData>
          ))}
        </TableRow>
      ))}
  </div>
);

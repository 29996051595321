import React from "react";

import DropZone from "./components/DropZone";
import { MemberCarousel, MembersSet } from "./components/MemberCarousel";
import ToolTip from "./components/ToolTip";

import {
  StyledWrapSection,
  StyledWrapAssignRequested,
  StyledWarning,
  StyledWrapText,
  StyledWrapElement,
  StyledZoneTitle,
  StyledWrapSearchRow
} from "./styles";
import { getAllRequestedMembers } from "./utils";

const RequestZone = ({
  requestedMembers,
  pendingMembers,
  declinedMembers,
  recommendedMembers,
  hasFreeSpace,
  isRequestAll,
  isIncludeUnavailable,
  canDragDrop,
  onDragHover,
  onDragDrop,
  onDragEnd,
  onRemoveMember,
  isLoading
}) => {
  const totalRequested =
    requestedMembers.length + pendingMembers.length + declinedMembers.length;

  return (
    <StyledWrapSection>
      <StyledWrapAssignRequested>
        <StyledWrapSearchRow>
          <ToolTip>
            Once you request a member, they will need to accept the shift in
            order to be assigned to it.
          </ToolTip>
          <StyledZoneTitle>
            {isRequestAll &&
              "Request All (Staff who are Qualified & Recommended)"}
            {!isRequestAll && `Request (${totalRequested} Requested)`}
            <StyledWarning>{!hasFreeSpace && " - Shift is full"}</StyledWarning>
          </StyledZoneTitle>
        </StyledWrapSearchRow>
        <StyledWrapText>Drag and drop to request staff</StyledWrapText>
      </StyledWrapAssignRequested>
      <StyledWrapElement>
        <DropZone
          onHoverEnter={onDragHover}
          onDropMember={onDragDrop}
          isForbidden={!canDragDrop || isRequestAll}
        >
          <MemberCarousel
            isLoading={isLoading}
            isShowPlaceholder={false}
            totalCardCount={totalRequested}
          >
            {isRequestAll && (
              <MembersSet
                isDragDisabled
                isDisabled
                members={getAllRequestedMembers(
                  recommendedMembers,
                  isIncludeUnavailable
                )}
              />
            )}

            {!isRequestAll && (
              <>
                <MembersSet
                  members={requestedMembers}
                  onDragEnd={onDragEnd}
                  onRemove={onRemoveMember}
                />

                <MembersSet
                  isPending
                  members={pendingMembers}
                  onDragEnd={onDragEnd}
                />

                <MembersSet
                  isDeclined
                  members={declinedMembers}
                  onDragEnd={onDragEnd}
                />
              </>
            )}
          </MemberCarousel>
        </DropZone>
      </StyledWrapElement>
    </StyledWrapSection>
  );
};

export default RequestZone;

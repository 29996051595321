import styled from "styled-components";
import { COLORS } from "~/src/styles/config.style";

const getColor = isDragActive => {
  if (isDragActive) {
    return COLORS.WHITE;
  }
  return COLORS.WILD_SAND_GREY;
};

export const Styled_DropZoneFileUpload = styled.div`
  background-color: ${({ isDragActive }) => getColor(isDragActive)};
  opacity: ${({ isDragActive }) => (isDragActive ? 0.5 : 1)};
  border: 1px dashed ${({ theme }) => theme.text.primary};
  height: 240px;
  border-radius: 5px;

  .dropzone_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 48px;

    .img {
      width: 72px;
      margin-bottom: 16px;
    }

    .description {
      font-size: 14px;
      font-weight: 500;
    }

    .sub_description {
      font-size: 12px;
      font-weight: 500;
      color: ${({ theme }) => theme.text.secondary};
      margin: 8px 0;
    }

    .browse_btn {
      width: 144px;
      margin-bottom: 32px;
    }
  }
`;

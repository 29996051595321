import { gql } from "@apollo/client";

export default gql`
  mutation addMemberAvailabilityExceptions(
    $memberId: ID!
    $startDate: Date
    $endDate: Date
  ) {
    addMemberAvailabilityExceptions(
      memberId: $memberId
      startDate: $startDate
      endDate: $endDate
    ) {
      id
    }
  }
`;

import React, { useEffect, useReducer, useRef, useState } from "react";
import { RotaButton } from "@teamrota/rota-design";

import set from "lodash/fp/set";

import AccountDetails from "./account-details";
import UserDetails from "./user-details";
import InvoiceDetails from "./invoice-details";

import getProfile from "~/src/graphql/queries/get-profile/get-profile-query.decorator";
import updateAccount from "../../gql/update-account";
import createUpdateUser from "~/src/graphql/mutations/create-update-user";

import { refreshUser } from "~/src/auth/manager";

const reducer = (state, action) => {
  const { field, value, path } = action;
  if (path) {
    return set(path, value, state);
  } else {
    return {
      ...state,
      [field]: value
    };
  }
};

const AccountTabForm = ({ createUpdateUser, updateAccount, user }) => {
  const [state, dispatch] = useReducer(reducer, user);
  const [isLoading, setIsLoading] = useState(false);

  const timer = useRef();

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const onClickHandler = async () => {
    if (!isLoading) {
      setIsLoading(true);
      try {
        await createUpdateUser(["getProfile"])({ ...state });
        updateAccount({ ...state?.account });
        refreshUser(); // force update of oidc user-info
      } catch (error) {
        setIsLoading(false);
        console.log("Form Save Error", error);
      }
      timer.current = setTimeout(() => {
        setIsLoading(false);
      }, 1700);
    }
  };

  return (
    <div>
      <UserDetails state={state} dispatch={dispatch} />
      <AccountDetails state={state?.account} dispatch={dispatch} />
      <InvoiceDetails state={state?.account} dispatch={dispatch} />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <RotaButton onClick={onClickHandler}>{!isLoading && "Save"}</RotaButton>
      </div>
    </div>
  );
};
export default updateAccount(createUpdateUser(getProfile(AccountTabForm)));

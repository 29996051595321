import React from "react";
import get from "lodash/fp/get";
import DatePicker from "~/src/components/form-components/date-picker";
import {
  formatDateMonthYear,
  formatDateMonthTime
} from "~/src/utils/formatting";

import TextInput from "../text-input";
import InputWrapper from "../input-wrapper";

import { Disabled } from "./index.styles";

export default function DatePickerField(props) {
  if (props.readOnly) {
    const date = get("input.value", props)
      ? formatDateMonthYear(get("input.value", props))
      : "";
    return (
      <InputWrapper {...props}>
        <Disabled>
          {props.shouldShowTime
            ? formatDateMonthTime(get("input.value", props))
            : date}
        </Disabled>
      </InputWrapper>
    );
  }

  return (
    <InputWrapper {...props}>
      <DatePicker
        isStartOnly
        isFormPicker
        isSmall
        onChange={({ start }) => props.input.onChange(start)}
        start={props.start ? props.start : props.input.value}
        end={props.input.value}
        input={TextInput}
        inputProps={{
          placeholder: "Select a date and time",
          input: {
            onChange: () => {},
            value: props.input.value
              ? formatDateMonthTime(props.input.value)
              : ""
          }
        }}
      />
    </InputWrapper>
  );
}

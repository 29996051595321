import { gql } from "@apollo/client";

import { REPORT_STATUS } from "~/src/consts";
import client from "~/src/services/graphql";

const GENERATE_REPORT = gql`
  mutation generateReport(
    $requestType: ReportRequestType!
    $rangeStart: Date
    $rangeEnd: Date
    $includeSummarySheet: Boolean
    $role: [Int]
    $minRating: Float
    $maxRating: Float
    $memberStatus: [String]
    $serviceArea: [Int]
    $memberType: [String]
    $isCompanyDataAttached: Boolean
  ) {
    generateReport(
      requestType: $requestType
      rangeStart: $rangeStart
      rangeEnd: $rangeEnd
      includeSummarySheet: $includeSummarySheet
      role: $role
      minRating: $minRating
      maxRating: $maxRating
      memberStatus: $memberStatus
      serviceArea: $serviceArea
      memberType: $memberType
      isCompanyDataAttached: $isCompanyDataAttached
    ) {
      id
    }
  }
`;

const GET_REPORT = gql`
  query getReport($id: ID!) {
    account {
      id
      report(id: $id) {
        id
        status
        url
      }
    }
  }
`;

const fetchPolicy = "no-cache";

const sleep = async time => new Promise(res => setTimeout(res, time));

export async function generateReport(requestType, variables, wait = true) {
  const id = await submit({ requestType, ...variables });

  if (wait) {
    let url = null;
    while (!url) {
      url = await poll(id);
      if (!url) {
        await sleep(500);
      }
    }

    // this doesn't actually navigate because the url points
    // to a download with content disposition, so is backgrounded
    window.location = url;
    return url;
  } else {
    return id;
  }
}

async function submit(variables) {
  const {
    data: {
      generateReport: { id }
    }
  } = await client.mutate({
    mutation: GENERATE_REPORT,
    variables,
    fetchPolicy
  });

  if (id) {
    return id;
  } else {
    throw new Error("No ID returned");
  }
}

async function poll(id) {
  const {
    data: {
      account: {
        report: { status, url }
      }
    }
  } = await client.query({
    query: GET_REPORT,
    variables: {
      id
    },
    fetchPolicy
  });

  if (status) {
    switch (status) {
      case REPORT_STATUS.CREATED:
      case REPORT_STATUS.GENERATING:
        return false;

      case REPORT_STATUS.COMPLETE:
        if (url) {
          return url;
        } else {
          throw new Error("No url returned");
        }

      case REPORT_STATUS.ERROR:
      default:
        throw new Error(status);
    }
  } else {
    throw new Error("No status returned");
  }
}

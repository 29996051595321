import React, { useEffect } from "react";
import { useDrag } from "react-dnd";
import { SHIFT_TYPES } from "~/src/consts";
import {
  StyledOpenShiftContainer,
  Div,
  GridCardWrapper
} from "~/src/containers/scheduler/OpenShifts/OpenShiftDailyCard/open-shift-daily-card.styles";
import { formatHours } from "~/src/containers/scheduler/utils";
import { StyledMaskContainer } from "../../GridCard/grid-card.styles";

import { DND_ITEM_TYPES } from "~/src/consts";

export const OpenShiftDailyCard = ({
  onPressShiftCell,
  shift,
  assignMemberLoading,
  lastDraggedShiftId,
  setLastDraggedShiftId
}) => {
  const isCancelled = !!shift?.cancelledAt;
  const hasCancelledBookings = !!shift?.hasCancelledBookings;

  const isDraft = !!shift?.isDraft;
  const isNightShift = shift.type === SHIFT_TYPES.NIGHT;

  const [{ draggedShift, isDragging }, drag] = useDrag(
    () => ({
      type: DND_ITEM_TYPES.OPEN_SHIFT,
      item: () => shift,
      canDrag: () => true,
      collect: monitor => ({
        isDragging: monitor.isDragging(),
        draggedShift: monitor.getItem()
      })
    }),
    [shift]
  );

  useEffect(() => {
    if (isDragging && draggedShift.id !== lastDraggedShiftId) {
      setLastDraggedShiftId(draggedShift?.id);
    }
  }, [isDragging]);

  const isShiftLoading =
    assignMemberLoading && lastDraggedShiftId === shift?.id;

  return (
    <StyledOpenShiftContainer>
      <GridCardWrapper
        ref={drag}
        lineHeight="12px"
        isDraft={isDraft}
        isCancelled={isCancelled}
        hasCancelledBookings={hasCancelledBookings}
        isNightShift={isNightShift}
        onClick={() => onPressShiftCell(shift?.id, shift?.sourceAccountId)}
        isLoading={isShiftLoading}
      >
        <StyledMaskContainer>
          <Div>{`${shift?.bookings.totalResults}/${shift?.numberRequested} ${shift.roleRate.roleName}`}</Div>
          <Div fontWeight="500">{formatHours(shift)}</Div>
          <Div>{`${shift?.venue?.name} (${shift?.sourceAccount.accountName})`}</Div>
        </StyledMaskContainer>
      </GridCardWrapper>
    </StyledOpenShiftContainer>
  );
};

import styled, { keyframes, css } from "styled-components";

import Icon from "../../../../../components/icon/";

const fadeOut = keyframes`
0% { opacity: 0.7}
100% { opacity: 0.0}
`;
const fadeIn = keyframes`
0% { opacity: 0}
100% { opacity: 0.7}
`;

const appearFromRight = keyframes`
  100% { right: 0}
`;

export const DeleteBadgeWrapper = styled.div`
  ${({ isVisible }) =>
    isVisible
      ? css`
          display: block;
          visibilty: visible;
          position: absolute;
          bottom: 60px;
          right: 17px;
          opacity: 1;
          animation: ${fadeIn} 1s;
        `
      : css`
          animation: ${fadeOut} 1s;
          display: none;
          visibilty: hidden;
        `};

  vertical-align: center;
  height: 80px;
  width: 65px;
  opacity: 0.7;
`;

export const DeleteIcon = styled(Icon)`
  margin-top: 10px;
  margin-left: 8px;
  position: absolute;
  right: -20px;
  ${({ isVisible }) =>
    isVisible &&
    css`
      animation: ${appearFromRight} 1s;
      animation-fill-mode: forwards;
      -webkit-animation-fill-mode: forwards;
    `};
`;

import styled from "styled-components";
import { POOL_TYPES } from "@teamrota/rota-common";

import { STYLE_CONSTS, MEMBER_STATES_COLORS } from "~/src/styles/config.style";
import { hexToRGBA } from "~/src/styles/style-helpers";

import defaultPhoto from "../../images/worker-default-photo.png";

export const sizes = {
  large: {
    imageSize: "100px",
    statusIconSize: "30px",
    statusIconInnerSize: "24px",
    statusIconInnerBottom: "3px",
    statusIconInnerRight: "3px",
    heartLeft: "8px",
    heartBottom: "2px",
    iconSize: "14",
    infoBottom: "2px",
    infoLeft: "9px"
  },
  medium: {
    imageSize: "60px",
    statusIconSize: "18px",
    statusIconInnerSize: "14px",
    statusIconInnerBottom: "2px",
    statusIconInnerRight: "2px",
    heartLeft: "5px",
    heartBottom: "0",
    iconSize: "8",
    infoBottom: "0",
    infoLeft: "6px"
  },
  small: {
    imageSize: "48px",
    statusIconSize: "15px",
    statusIconInnerSize: "13px",
    statusIconInnerBottom: "1px",
    statusIconInnerRight: "1px",
    heartLeft: "4px",
    heartBottom: "-1px",
    iconSize: "7",
    infoLeft: "5px",
    infoBottom: "-1px"
  }
};

export const Container = styled.div`
  position: relative;
`;

export const StyledImage = styled.div`
  height: ${({ size }) => sizes[size].imageSize};
  width: ${({ size }) => sizes[size].imageSize};
  min-height: ${({ size }) => sizes[size].imageSize};
  min-width: ${({ size }) => sizes[size].imageSize};
  border-radius: 50%;
  margin: ${({ isInline }) => (isInline ? "0" : "0 auto")};
  position: relative;
  background-size: cover;
  background-image: ${({ src }) => {
    const defaultImage = `url(${defaultPhoto})`;
    if (!!src && src !== "") {
      return `url(${src})`;
    }
    return defaultImage;
  }};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: ${({ isInline }) => (isInline ? "inline-block" : "block")};
  &:hover {
    cursor: drag;
  }

  &:active {
    cursor: dragging;
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  height: ${({ size }) => sizes[size].statusIconSize};
  width: ${({ size }) => sizes[size].statusIconSize};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.white};
  right: 4px;
  bottom: -2px;
  cursor: ${({ onClick }) => (onClick ? "pointer" : "auto")};
  &:after {
    content: "";
    position: absolute;
    height: ${({ size }) => sizes[size].statusIconInnerSize};
    width: ${({ size }) => sizes[size].statusIconInnerSize};
    border-radius: 50%;
    background-color: ${({ poolType, theme }) => {
      if (poolType === POOL_TYPES.FAVOURITED) return theme.error.light;
      if (poolType === POOL_TYPES.BLACKLISTED)
        return theme.background.secondary;
      if (poolType === "none") return theme.neutral.light;
      return theme.primary.main;
    }};
    bottom: ${({ size }) => sizes[size].statusIconInnerBottom};
    right: ${({ size }) => sizes[size].statusIconInnerRight};
    z-index: 1;
  }
`;

export const IconWrapper = styled.div`
  z-index: 2;
  left: ${({ size, icon }) => sizes[size][`${icon}Left`]};
  bottom: ${({ size, icon }) => sizes[size][`${icon}Bottom`]};
  position: absolute;
  transform: ${({ shouldRotate }) => (shouldRotate ? "rotate(90deg)" : "")};
`;

export const Status = styled.div`
  cursor: pointer;
  position: absolute;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.white};
  &:after {
    content: "";
    position: absolute;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: ${({ status, isAsleep, theme }) => {
      if (isAsleep) {
        return MEMBER_STATES_COLORS.ASLEEP;
      }
      if (MEMBER_STATES_COLORS[status]) {
        return MEMBER_STATES_COLORS[status];
      }
      return theme.primary.main;
    }};
    left: 2px;
    top: 2px;
  }
`;

export const ErrorOverlay = styled.div`
  position: absolute;
  color: ${({ theme }) => theme.white};
  line-height: ${({ size }) => sizes[size].imageSize};
  white-space: nowrap;
  overflow: hidden;
  padding: 0 ${STYLE_CONSTS.UNIT_HALF};
  font-size: ${({ isBigLabel }) =>
    isBigLabel ? STYLE_CONSTS.FONT_LARGE : STYLE_CONSTS.FONT_XXSMALL};
  text-align: center;
  text-overflow: ellipsis;
  height: ${({ size }) => sizes[size].imageSize};
  width: ${({ size }) => sizes[size].imageSize};
  background: ${({ errorLabelColor, theme }) =>
    errorLabelColor || hexToRGBA(theme.error.main, 0.5)};
  border-radius: 50%;
`;

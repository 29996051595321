import styled from "styled-components";
import { iconNew } from "@teamrota/rota-design";
import { STYLE_CONSTS } from "~/src/styles/config.style";

const { Checklist } = iconNew;

export const ChecklistIcon = styled(Checklist)`
  margin: 0 4px;
  color: ${({ theme }) => theme.neutral.main};
`;

export const DocumentsTable = styled.table`
  width: 100%;
  margin-top: 30px;
  border-collapse: collapse;
  text-slign: center;
  font-size: ${STYLE_CONSTS.FONT_SMALL};
  letter-spacing: -0.18px;
  & thead {
    height: 32px;
    background-color: #f4f4f4;
    border-radius: 10px;
    & th {
      font-weight: ${STYLE_CONSTS.FONT_WEIGHT_MEDIUM};
    }
  }
  & tbody {
    & tr {
      height: 42px;
    }
    & a {
      text-decoration: none;
    }
  }
`;

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const cleanGroupObject = group => {
  if (group.id) {
    // update group
    return {
      variables: {
        id: group.id,
        name: group.name,
        sortNumber: group.sortNumber
      },
      refetchQueries: group.shouldRefetchQuery ? ["getGroups"] : []
    };
  } else {
    // create group
    return {
      variables: {
        name: group.name,
        sourceAccountId: group.sourceAccountId,
        sortNumber: group.sortNumber
      },
      refetchQueries: group.shouldRefetchQuery ? ["getGroups"] : []
    };
  }
};

const CREATE_OR_UPDATE_GROUP = gql`
  mutation createOrUpdateGroup(
    $id: ID
    $sourceAccountId: ID
    $name: String
    $sortNumber: Int
  ) {
    createOrUpdateGroup(
      id: $id
      sourceAccountId: $sourceAccountId
      name: $name
      sortNumber: $sortNumber
    ) {
      id
    }
  }
`;

export default graphql(CREATE_OR_UPDATE_GROUP, {
  name: "createUpdateGroup",
  props: ownProps => ({
    createUpdateGroup: group =>
      ownProps.createUpdateGroup(cleanGroupObject(group))
  })
});

import { graphql } from "@apollo/client/react/hoc";
import { gql } from "@apollo/client";

import { addAuthVars } from "@teamrota/authlib";

import useAuth from "~/src/auth/hooks/use-auth";
import ShiftFragment from "~/src/graphql/fragments/shift/shift.fragment";

const UPDATE_SHIFT_STATE = addAuthVars(gql`
  ${ShiftFragment}
  mutation updateShiftState(
    $shiftId: ID!
    $newState: stateEnumType!
    $note: String
  ) {
    updateShiftState(shiftId: $shiftId, newState: $newState, note: $note) {
      ...ShiftFragment
    }
  }
`);

export default graphql(UPDATE_SHIFT_STATE, {
  name: "updateShiftState",
  props: props => {
    const auth = useAuth();

    return {
      updateShiftState: (shiftId, newState, note) => {
        props.updateShiftState({
          variables: auth.addVals(UPDATE_SHIFT_STATE, {
            shiftId,
            newState,
            note
          }),
          refetchQueries: ["getShift"]
        });
      }
    };
  }
});

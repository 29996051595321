import React, { useState, useEffect, useContext } from "react";
import { RotaButton } from "@teamrota/rota-design";
import { Container, Row, Col } from "react-grid-system";
import { FormControl, MenuItem, Select, TextField } from "@material-ui/core";
import Loading from "~/src/components/loading";

import { RotaAvatar, RotaSnackBar } from "@teamrota/rota-design";
import getUsers from "../../graphql/get-users";
import getUserTypeTemplates from "../../graphql/get-user-type-templates";
import getProfile from "~/src/graphql/queries/get-profile/get-profile-query.decorator";

import updateUserTypeTemplate from "~/src/containers/settings-page/graphql/update-user-type-template";
import styles from "./users-tab-content.styles";
import { SnackContext } from "../../context/snack-context";
import { flow } from "lodash/fp";

const UsersTabContent = ({
  user,
  users,
  userTypeTemplates,
  updateUserTypeTemplate,
  isLoading
}) => {
  const [selectedTemplates, setSelectedTemplates] = useState();

  const {
    snackOpen,
    setSnackOpen,
    severity,
    setSeverity,
    snackText,
    setSnackText
  } = useContext(SnackContext);

  const [userArray, setUserArray] = useState([]);

  useEffect(() => {
    setSelectedTemplates(
      Object.fromEntries(
        users.map(({ id, typeTemplate: { id: typeTemplateId } }) => [
          id,
          parseInt(typeTemplateId)
        ])
      )
    );
    setUserArray([...users].sort(a => (a.id === user.id ? -1 : 1)));
  }, [users]);

  const getUserTemplateById = templateId =>
    userTypeTemplates.find(({ id }) => parseInt(id) === parseInt(templateId));

  const handleChange = (event, userId) =>
    setSelectedTemplates({
      ...selectedTemplates,
      [userId]: parseInt(event.target.value.id)
    });

  const handleSave = async userId => {
    try {
      await updateUserTypeTemplate({
        targetUserId: parseInt(userId),
        userTypeTemplateId: parseInt(selectedTemplates[userId])
      });

      setSeverity("success");
      setSnackText("Updated successfully");
      setSnackOpen(true);
    } catch (error) {
      console.log("save error", error);

      setSeverity("error");
      setSnackText("Error occurred - please try again");
      setSnackOpen(true);
    }
  };

  return (
    <div>
      <div style={styles.introContainer}>
        <div style={styles.introText}>
          <p style={styles.introTitle}>Administrator roles available</p>
          <p>
            A role provides access to predefined menus and features so that
            depending on the assigned role (Super Admin, Manager, Accountant) an
            administrator can have access to what they need.
          </p>
        </div>
      </div>
      {!isLoading && (
        <div id="grid-wrapper" style={styles.gridHeader}>
          <Container>
            <Row>
              <Col sm={4}>User</Col>
              <Col sm={4}>Type</Col>
              <Col sm={4}>&nbsp;</Col>
            </Row>
          </Container>
        </div>
      )}
      {!isLoading ? (
        <div id="grid-body">
          <Container>
            {userArray?.map(({ id, photo, firstName, lastName }) => {
              const currentTemplate = getUserTemplateById(
                selectedTemplates?.[id]
              );
              if (currentTemplate) {
                return (
                  <Row key={id}>
                    <Col sm={4}>
                      <div id="avatar-row" style={styles.avatarRow}>
                        <RotaAvatar alt={firstName} src={photo} />
                        <span
                          style={styles.userName}
                        >{`${firstName} ${lastName}`}</span>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div id="dropdown-row" style={styles.dropdownRow}>
                        <FormControl>
                          {id === user?.id ? (
                            <TextField
                              style={{ width: 231 }}
                              fullWidth
                              disabled
                              id="outlined-disabled"
                              defaultValue={currentTemplate.name}
                              variant="outlined"
                            />
                          ) : (
                            <Select
                              style={{ width: "231px" }}
                              variant="outlined"
                              value={currentTemplate.name}
                              onChange={event => handleChange(event, id)}
                              displayEmpty
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              <MenuItem value={currentTemplate.name}>
                                <em>-- {currentTemplate.name} --</em>
                              </MenuItem>
                              {userTypeTemplates?.map(type => {
                                return (
                                  <MenuItem
                                    key={type.id}
                                    value={{ id: type?.id, name: type?.name }}
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {type.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          )}
                        </FormControl>
                      </div>
                    </Col>
                    <Col sm={4}>
                      {parseInt(user.id) !== parseInt(id) && (
                        <RotaButton
                          onClick={() => handleSave(id)}
                          style={{ marginTop: "13.25px" }}
                        >
                          Save
                        </RotaButton>
                      )}
                    </Col>
                  </Row>
                );
              }
            })}
          </Container>

          <RotaSnackBar
            snackOpen={snackOpen}
            severity={severity}
            message={snackText}
            onClose={() => setSnackOpen(false)}
          />
        </div>
      ) : (
        <Loading color="black" />
      )}
    </div>
  );
};
export default flow(
  getProfile,
  getUsers,
  getUserTypeTemplates,
  updateUserTypeTemplate
)(UsersTabContent);

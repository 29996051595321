import styled from "styled-components";
import SearchInput from "~/src/components/form-components/search-input";
import TextInput from "~/src/components/form-components/text-input";
import { STYLE_CONSTS } from "~/src/styles/config.style";
import Button from "~/src/components/button";

export const StyledSearchInput = styled(SearchInput)`
  margin-right: ${STYLE_CONSTS.UNIT_3};
  width: 400px;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
`;

export const StyledButton = styled(Button)`
  margin-top: ${STYLE_CONSTS.UNIT_2};
  margin-right: ${STYLE_CONSTS.UNIT_2};
`;

export const ButtonContainer = styled.div`
  margin-top: ${STYLE_CONSTS.UNIT_2};
  text-align: center;
`;

export const StyledTextInput = styled(TextInput)``;

export const RatingContainer = styled.div`
  margin-right: ${STYLE_CONSTS.UNIT_3};
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0px 10px 0px;
  border: 1px solid #c0ccda;
`;

import React from "react";
import {
  RotaTable,
  THead,
  TrHead,
  TBody,
  Th,
  Tr,
  Td,
  InnerCell,
  RotaButtonIcon,
  iconNew
} from "@teamrota/rota-design";

import { EmptyText } from "~/src/components/payroll/style";
import { PayrollCalculation } from "./types";
import { formatDateFull } from "~/src/utils/formatting";

const { Edit, Info } = iconNew;

type TableProps = {
  payrollCalculations: PayrollCalculation[];
  handleOpenCalculation: (id: string) => void;
  canEdit: boolean;
};

const Table = ({
  payrollCalculations,
  handleOpenCalculation,
  canEdit
}: TableProps) => {
  return (
    <RotaTable>
      <THead>
        <TrHead>
          <Th>Name</Th>
          <Th>User</Th>
          <Th>Created at</Th>
          <Th>Last edited</Th>
          <Th>Action</Th>
        </TrHead>
      </THead>

      <TBody>
        {payrollCalculations?.length ? (
          payrollCalculations.map((calc: PayrollCalculation) => (
            <Tr key={calc.id}>
              <Td>
                <InnerCell>{calc.name}</InnerCell>
              </Td>
              <Td>
                <InnerCell>USER</InnerCell>
              </Td>
              <Td>
                <InnerCell>{formatDateFull(calc.createdAt)}</InnerCell>
              </Td>
              <Td>
                <InnerCell>
                  {calc.updatedAt && formatDateFull(calc.updatedAt)}
                </InnerCell>
              </Td>
              <Td>
                <InnerCell>
                  <RotaButtonIcon
                    onClick={() => handleOpenCalculation(calc.id)}
                    style={{ margin: "auto" }}
                  >
                    {canEdit ? <Edit /> : <Info />}
                  </RotaButtonIcon>
                </InnerCell>
              </Td>
            </Tr>
          ))
        ) : (
          <Tr>
            <Td colSpan={5}>
              <EmptyText>Nothing to see here 👀 Try clicking Create!</EmptyText>
            </Td>
          </Tr>
        )}
      </TBody>
    </RotaTable>
  );
};

export default Table;

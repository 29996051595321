import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { handleErrors } from "~/src/utils/errors";

const GET_USERS = gql`
  query getUsers {
    account {
      id
      users {
        id
        firstName
        lastName
        photo
        phoneNumber
        email
        notes
        accountId
        pin
        createdAt
        typeTemplate {
          id
          name
          isBuiltIn
          createdAt
          updatedAt
          roles
          isAssigned
        }
        account {
          id
          logo
          accountName
        }
      }
    }
  }
`;

export default graphql(GET_USERS, {
  props: handleErrors(({ data: { loading, account, refetch } }) => ({
    isLoading: loading,
    users: account?.users || [],
    refetchUsers: refetch
  }))
});

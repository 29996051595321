import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import { COLORS, STYLE_CONSTS } from "~/src/styles/config.style";

export const CancelText = styled.div`
  margin-top: ${STYLE_CONSTS.UNIT_1};
  color: ${COLORS.COOL_GREY};
`;

export const Link = styled.a`
  display: inline-block;
  color: ${({ theme }) => theme.primary.main};
  margin-bottom: ${STYLE_CONSTS.UNIT_1};
`;

export const StyledTooltip = styled(ReactTooltip)`
  max-width: 400px;
`;

import styled from "styled-components";

export const StyledWrapper = styled.div`
  margin-top: 40px;
  width: 50%;
  height: 50vh;
`;

export const ButtonWrapper = styled.div`
  float: right;
  margin-top: 50px;
`;

export const TimeWrapper = styled.div`
  display: flex;
  justify-content: start;
`;

export const TimeDropDownWrapper = styled.div`
  padding: 10px 10px 10px 0;
`;

export const IconTextWrapper = styled.div`
  font-size: 16px;
  display: flex;
  align-items: center;
`;

export const Text = styled.p`
  font-size: 16px;
  line-height: 20px;
  padding: 8px 0;
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : "0")};
  margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft}px` : "0")};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : "0"};
  width: 100%;
  max-width: 400px;
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 38px;
`;

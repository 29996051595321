import getOr from "lodash/fp/getOr";
import padCharsStart from "lodash/fp/padCharsStart";

export function shouldLoadMoreBookings(props) {
  const { keyProp } = props.shiftItem;
  const bookings = getOr([], "bookings.data", props);
  if (!bookings.length || !keyProp) {
    return true;
  }

  if (props.shift.bookingCounts[keyProp] <= bookings.length) {
    return true;
  }

  return false;
}

// e.g. 08:02
export function getHoursWorked(hours) {
  const minutes = parseFloat(hours) * 60;
  const hourDuration = Math.floor(minutes / 60).toString();
  const minuteDuration = Math.round(minutes % 60).toString();

  return `${padCharsStart("0")(2)(hourDuration)}:${padCharsStart("0")(2)(
    minuteDuration
  )}`;
}

import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { icon } from "@teamrota/rota-design";

import Icon from "~/src/components/icon";
import documentImgOrange from "~/src/images/document.png";
import documentImgBlack from "~/src/images/document_black.png";

import { UPLOAD_STATUSES } from "..";
import { Styled_UploadStatus } from "./styles";

const UploadStatus = ({ fileName, fileSizeBytes, status }) => {
  const { SuccessIcon } = icon;

  const contentToRender = () => {
    switch (status) {
      case UPLOAD_STATUSES.DEFAULT:
        return (
          <div>
            <div className="file_name">{fileName}</div>
            <div className="file_size">{`${(fileSizeBytes / 1000000).toFixed(
              4
            )}MB`}</div>
          </div>
        );
      case UPLOAD_STATUSES.LOADING:
        return (
          <div className="progress_wrapper">
            <LinearProgress />
            <div className="status_message">Loading ...</div>
          </div>
        );
      case UPLOAD_STATUSES.VALIDATING:
        return (
          <div className="progress_wrapper">
            <LinearProgress />
            <div className="status_message">
              Upload has finished. Now validating...
            </div>
          </div>
        );
      case UPLOAD_STATUSES.SUCCESS:
        return (
          <div className="success_wrapper">
            <div className="left_block">
              <div className="bar"></div>
              <div className="status_message">Complete</div>
            </div>
            <SuccessIcon size={16} color={Icon.colors.GREEN} />
          </div>
        );
      case UPLOAD_STATUSES.ERROR:
        return (
          <div className="error_wrapper">
            <div className="bar"></div>
            <div className="status_message error">Validation failed</div>
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <Styled_UploadStatus status={status}>
      <div className="wrapper">
        <div>
          <img
            src={
              status === UPLOAD_STATUSES.DEFAULT
                ? documentImgOrange
                : documentImgBlack
            }
            className="document_img"
          />
        </div>
        {contentToRender()}
      </div>
    </Styled_UploadStatus>
  );
};

export default UploadStatus;

import React from "react";
import {
  StyledBarContainer,
  StyledBarLabel,
  StyledBarFiller,
  StyledBarNumber
} from "./styles";

const HoursWorkedBar = ({ hoursCompleted, hoursCompletedPercentage }) => {
  return (
    <StyledBarContainer>
      <StyledBarFiller hoursCompletedPercentage={hoursCompletedPercentage}>
        <StyledBarLabel hoursCompletedPercentage={hoursCompletedPercentage}>
          <StyledBarNumber hoursCompletedPercentage={hoursCompletedPercentage}>
            {hoursCompleted} hrs
          </StyledBarNumber>
        </StyledBarLabel>
      </StyledBarFiller>
    </StyledBarContainer>
  );
};

export default HoursWorkedBar;

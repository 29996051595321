import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useMutation } from "@apollo/client";
import { ZonedDate } from "@teamrota/rota-common";
import {
  RotaCheckbox,
  RotaButton,
  RotaSwitch,
  RotaSnackBar
} from "@teamrota/rota-design";

import { MAX_WEEKLY_HOURS } from "~/src/consts";
import { getDateString, isFormattedDateValid } from "~/src/utils/time-utils";

import {
  StyledSwitchHeaderContainer,
  StyledNumberInput,
  StyledRowContainer,
  StyledInput,
  StyledP,
  StyledSubHeading,
  StyledSmallBoldP,
  StyledInfo,
  StyledInfoBox,
  StyledButtonsContainer,
  StyledErrorMessage
} from "./styles";

import UPDATE_MEMBER_AVAILABILITY from "./graphql/update-member-availability";
import ExceptionDates from "./exception-dates";

const LimitedHours = ({ member }) => {
  if (!member?.id) return;

  const [isLimitedHours, setIsLimitedHours] = useState(member.isLimitedHours);
  const [limitedHours, setLimitedHours] = useState(member.limitedHours || 0);
  const [isLimitedHoursExpire, setIsLimitedHoursExpire] = useState(
    member.isLimitedHoursExpire
  );

  const [limitedHoursExpireAt, setLimitedHoursExpireAt] = useState(
    member?.limitedHoursExpireAt
      ? new ZonedDate(member.limitedHoursExpireAt)
      : new ZonedDate()
  );

  const [errors, setErrors] = useState({});
  const [snack, setSnack] = useState(null);

  const [updateMemberAvailability] = useMutation(UPDATE_MEMBER_AVAILABILITY);

  const updateLimitedHoursExpireAt = limitedHoursExpireAt => {
    limitedHoursExpireAt.setHours(23, 59, 59, 999);
    setLimitedHoursExpireAt(limitedHoursExpireAt);
  };

  const handleSave = async () => {
    setSnack({ severity: "warning", message: "Saving..." });

    try {
      await updateMemberAvailability({
        variables: {
          id: member.id,
          ...{
            isLimitedHours,
            limitedHours,
            isLimitedHoursExpire,
            limitedHoursExpireAt
          }
        }
      });

      setSnack({ severity: "success", message: "Saved!" });
    } catch (e) {
      setSnack({ severity: "error", message: "something went wrong" });
    }
  };

  const handleErrorsUpdate = (condition, key, message) => {
    const newErrors = { ...errors };

    newErrors[key] = condition ? message : "";

    setErrors(newErrors);
  };

  const handleExpiredAtError = condition => {
    return handleErrorsUpdate(
      condition,
      "limitedHoursExpireAt",
      "Expiry must be in the future."
    );
  };

  const onUpdateLimitedHoursExpiredAt = date => {
    handleErrorsUpdate(
      !isFormattedDateValid(date),
      "limitedHoursExpireAt",
      "Invalid date format."
    );
    if (isFormattedDateValid(date)) {
      handleExpiredAtError(getDateString(date) < new ZonedDate());
    }
  };

  useEffect(() => {
    handleErrorsUpdate(
      limitedHours > MAX_WEEKLY_HOURS,
      "limitedHours",
      `Max ${MAX_WEEKLY_HOURS} hours allowed.`
    );
  }, [limitedHours]);

  useEffect(() => {
    if (isLimitedHoursExpire) {
      handleExpiredAtError(limitedHoursExpireAt < new ZonedDate());
    } else {
      handleErrorsUpdate(true, "limitedHoursExpireAt", "");
    }
  }, [isLimitedHoursExpire]);

  return (
    <>
      <StyledSwitchHeaderContainer>
        <RotaSwitch
          label="Limited working hours"
          name="checkedC"
          checked={isLimitedHours}
          onChange={() => setIsLimitedHours(isLimitedHours => !isLimitedHours)}
        />
      </StyledSwitchHeaderContainer>
      <StyledRowContainer>
        <StyledSubHeading>
          Member will not be able to see or apply to shifts taking them over the
          limit from their mobile app.
        </StyledSubHeading>
      </StyledRowContainer>
      <StyledRowContainer>
        <StyledSubHeading>
          However, they are not guaranteed to fall under this cap. Partners can
          overload a member with additional shifts at their discretion. Also,
          members are not removed from shifts in excess of the limit if a limit
          is enabled after the shifts have been assigned.
        </StyledSubHeading>
      </StyledRowContainer>
      <StyledP disabled={!isLimitedHours}>Enter limitations:</StyledP>
      <StyledRowContainer>
        <StyledSubHeading disabled={!isLimitedHours}>
          Maximum hours allowed to work
        </StyledSubHeading>
        <StyledNumberInput
          min={0}
          disabled={!isLimitedHours}
          onChange={e => setLimitedHours(parseInt(e.target.value))}
          type="number"
          value={limitedHours}
        />
        <StyledSmallBoldP disabled={!isLimitedHours}>hrs/week</StyledSmallBoldP>
        <StyledErrorMessage>{errors.limitedHours}</StyledErrorMessage>
      </StyledRowContainer>
      <StyledRowContainer style={{ paddingLeft: "10px" }}>
        <RotaCheckbox
          isChecked={isLimitedHoursExpire}
          isDisabled={!isLimitedHours}
          onClick={() =>
            setIsLimitedHoursExpire(
              isLimitedHoursExpire => !isLimitedHoursExpire
            )
          }
        />
        <StyledSmallBoldP disabled={!isLimitedHours}>
          Ending date
        </StyledSmallBoldP>
      </StyledRowContainer>
      <StyledRowContainer>
        <StyledP disabled={!isLimitedHours}>Specify ending date</StyledP>
        <DatePicker
          placeholderText={limitedHoursExpireAt || "End date"}
          selected={limitedHoursExpireAt}
          onChange={date =>
            updateLimitedHoursExpireAt(ZonedDate.fromSelectedDate(date))
          }
          onChangeRaw={e => onUpdateLimitedHoursExpiredAt(e.target.value)}
          todayButton={true}
          open={false}
          dateFormat="dd/MM/yyyy"
          disabled={!isLimitedHours || !isLimitedHoursExpire}
          customInput={<StyledInput isError={errors.limitedHoursExpireAt} />}
        />
        <StyledErrorMessage>{errors.limitedHoursExpireAt}</StyledErrorMessage>
      </StyledRowContainer>
      <StyledRowContainer>
        <StyledInfo disabled={!isLimitedHours}>i</StyledInfo>
        <StyledInfoBox>
          On exemption days, the worker can work unlimited hours, which do not
          count towards their weekly cap.
        </StyledInfoBox>
        <StyledSubHeading disabled={!isLimitedHours}>
          Add exemption dates:
        </StyledSubHeading>
      </StyledRowContainer>

      <ExceptionDates
        memberId={member.id}
        memberAvailabilityExceptions={member.memberAvailabilityExceptions}
        isLimitedHours={isLimitedHours}
      />

      <RotaSnackBar
        snackOpen={!!snack}
        onClose={() => setSnack(null)}
        message={snack?.message || "Success"}
        severity={snack?.severity}
      />

      <StyledButtonsContainer>
        <RotaButton
          disabled={Object.values(errors).some(error => error)}
          onClick={handleSave}
        >
          Save
        </RotaButton>
      </StyledButtonsContainer>
    </>
  );
};

export default LimitedHours;

import {
  ApolloClient,
  ApolloLink,
  from,
  Observable,
  createHttpLink
} from "@apollo/client";

import { RetryLink } from "@apollo/client/link/retry";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { InMemoryCache } from "@apollo/client/cache";

import { GRAPHQL_ENDPOINT } from "~/src/config";
import { getCurrentAccessToken, userManager } from "~/src/auth/manager";
import { requireFetchIfNeeded } from "./fetch";

const UNAUTHORIZED = 401;
const FORBIDDEN = 403;

// Operations which do not require authorization
const ALLOWED_UNAUTHORIZED_OPERATIONS = ["checkMemberEmail"];

// this middleware adds the auth header
// setContext works asynchronously
// so can can getCurrentAccessToken but can't raise exceptions
const setAuth = setContext(async () => {
  const token = await getCurrentAccessToken();
  if (token) {
    return {
      headers: {
        authorization: `Bearer ${token}`
      }
    };
  } else {
    return {};
  }
});

// this middleware checks the auth header was added
// ApolloLink works synchronously
// so can't call getCurrentAccessToken but can raise exceptions
const checkAuth = new ApolloLink((operation, forward) => {
  const auth = operation.getContext()?.headers?.authorization;
  if (
    auth ||
    ALLOWED_UNAUTHORIZED_OPERATIONS.includes(operation.operationName)
  ) {
    return forward(operation);
  } else {
    return new Observable(subscriber => {
      // unload user
      // causes "You've been signed out" modal
      userManager.removeUser();
      subscriber.error(new Error("No auth token available"));
    });
  }
});

const errorLink = onError(({ networkError }) => {
  if (networkError) {
    if (
      networkError.statusCode === UNAUTHORIZED ||
      networkError.statusCode === FORBIDDEN
    ) {
      // unload user
      // causes "You've been signed out" modal
      userManager.removeUser();
    }
  }
});

export const getClient = uri =>
  new ApolloClient({
    link: from([
      new RetryLink({
        attempts: {
          max: 2
        },
        delay: {
          initial: 250
        }
      }),
      setAuth,
      checkAuth,
      errorLink,
      createHttpLink({ uri, fetch: requireFetchIfNeeded() })
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        account: {
          fields: {
            logo: {
              merge: (existing, incoming) => mergePhoto(existing, incoming)
            },

            memberPools: {
              keyArgs: [
                "poolTypes",
                "memberType",
                "targetAccountId",
                "roleRateId",
                "hasRoleRateId",
                "venueId",
                "dates",
                "searchText"
              ],

              merge(existing, incoming, { args }) {
                if (existing && args.offset > 0) {
                  return {
                    ...existing,
                    data: [
                      ...existing.data.slice(0, args.offset),
                      ...incoming.data
                    ]
                  };
                } else if (existing && args.offset === 0) {
                  return incoming;
                } else {
                  return { ...existing, ...incoming };
                }
              },
              read(existing) {
                return existing;
              }
            }
          }
        },
        user: {
          photo: {
            merge: (existing, incoming) => mergePhoto(existing, incoming)
          }
        },
        member: {
          fields: {
            photo: {
              merge: (existing, incoming) => mergePhoto(existing, incoming)
            },

            bookings: {
              merge(existing, incoming, { args }) {
                if (existing && args.offset > 0) {
                  // handles pagination, adds the new portion to the existing bookings
                  return {
                    ...existing,
                    data: [...existing.data, ...incoming.data]
                  };
                } else if (existing && args.offset === 0) {
                  // when switching between tabs (types of bookings) we don't want to mix them
                  // so we load first n bookings (limit) from the start (offset: 0) and ignores
                  // the existing ones
                  return incoming;
                } else {
                  return { ...existing, ...incoming };
                }
              },
              // from the first look we don't need this, but the pagination doesn't work
              // without it. It doesn't harm, but feel free to suggest if you have smth better
              read(existing) {
                return existing;
              }
            }
          }
        }
      }
    })
  });

export default getClient(GRAPHQL_ENDPOINT);

const cloudinaryRegex = /__cld_token__=exp=([0-9]+)~/;

const mergePhoto = (existing, incoming) => {
  if (existing && typeof existing === "string") {
    const expiry = existing.match(cloudinaryRegex)?.[1];
    if (expiry && expiry * 1000 < Date.now()) {
      return incoming;
    } else {
      return existing;
    }
  }

  return incoming;
};

import React, { useState } from "react";
import moment from "moment-timezone";
import { useTheme } from "styled-components";
import { get, flow } from "lodash/fp";
import { RotaButtonNew } from "@teamrota/rota-design";

import tabs from "./futureShiftTabs";
import { shouldLoadMoreBookings } from "../helpers";
import {
  FlexContainer,
  FlexBasis,
  TabContainer,
  Tab,
  LoadMoreWrapper,
  EmptyBookings,
  Text
} from "../booking-list.styles";

import {
  BOOKING_STATES,
  BOOKING_STATES_LABELS,
  MEMBER_STATES,
  MENU_OPTIONS
} from "~/src/consts";
import { errorModal } from "~/src/utils/errors";
import RatingGraph from "~/src/components/rating-graph";
import Button from "~/src/components/button";
import getPoolType from "~/src/utils/get-pool-type";
import ActionDropdown from "~/src/components/action-dropdown";
import createOrUpdateMember from "~/src/containers/modals/view-edit-member/graphql/create-or-update-member";
import Member from "~/src/components/member-details";
import Loading from "~/src/components/loading";
import { BOOKING_LIMIT } from "~/src/containers/provide/reducer";
import { getLastSixRatings } from "~/src/utils/formatting";

const FutureShift = ({
  fetchMore,
  bookings,
  isLoadingProvideBookings,
  shiftItem,
  shift,
  selectOptions,
  onViewBooking,
  createOrUpdateMember,
  setTabIndex,
  handleStateChange,
  isLoadingMemberStateChange
}) => {
  const [disableLoadingAnimation, setDisableLoadingAnimation] = useState(false);

  const theme = useTheme();

  const handleLoadMore = async () => {
    try {
      await fetchMore();
    } catch (e) {
      errorModal(e);
    }
  };

  const handleMemberStateChange = async (option, booking, bookingsLength) => {
    setDisableLoadingAnimation(true);
    await handleStateChange(booking, option.value, bookingsLength);
    setDisableLoadingAnimation(false);
  };

  const filteredBookings =
    bookings && bookings.data
      ? [...bookings.data].sort(
          (a, b) =>
            moment(a.bookingState.appliedAt).valueOf() -
            moment(b.bookingState.appliedAt).valueOf()
        )
      : [];

  const bookingsLimit =
    filteredBookings.length < BOOKING_LIMIT
      ? BOOKING_LIMIT
      : filteredBookings.length;

  const currentTab = shiftItem.keyProp;
  const currentTabCount = shift.bookingCounts[currentTab];
  const noBookings = currentTabCount === 0;

  const existingBookingsLoading =
    !disableLoadingAnimation &&
    !noBookings &&
    !bookings?.data?.length &&
    isLoadingProvideBookings;

  return (
    <div>
      <TabContainer>
        {tabs.map((tab, key) => (
          <Tab
            isActive={tab.keyProp === shiftItem.keyProp}
            key={key}
            onClick={() => {
              setTabIndex({
                shiftId: shift.id,
                activeBookingStates: tab.bookingStates,
                keyProp: tab.keyProp
              });
            }}
          >
            {tab.label} {shift.bookingCounts[tab.keyProp]}
          </Tab>
        ))}
      </TabContainer>
      {noBookings && <EmptyBookings>No bookings</EmptyBookings>}
      {existingBookingsLoading ? (
        <EmptyBookings>
          <Loading color={theme.primary.main} />
        </EmptyBookings>
      ) : (
        filteredBookings.map((booking, index) => (
          <FlexContainer key={index}>
            <FlexBasis>
              <Member
                {...booking.member}
                canRenderMemberModal
                shouldHighlightMemberPhoto
                approvalState={booking.approvalState}
                poolType={getPoolType(
                  booking.member.relationships,
                  shift.sourceAccount.id
                )}
              />
            </FlexBasis>
            <FlexBasis basis="7%">
              <Text isSmall>{booking.member.notes}</Text>
            </FlexBasis>
            <RatingGraph
              memberState={booking.member.state}
              label="Shifts at this venue"
              ratings={getLastSixRatings(
                get("memberRatingForShift.requesterRatings", booking.member)
              )}
              averageRating={get(
                "memberRatingForShift.averageRequesterRating",
                booking.member
              )}
              totalRatings={get(
                "memberRatingForShift.requesterShiftTotal",
                booking.member
              )}
            />
            <RatingGraph
              memberState={booking.member.state}
              label="Role shifts"
              ratings={getLastSixRatings(
                get("memberRatingForShift.roleRatings", booking.member)
              )}
              averageRating={get(
                "memberRatingForShift.averageRoleRating",
                booking.member
              )}
              totalRatings={get(
                "memberRatingForShift.roleTotal",
                booking.member
              )}
            />
            <RatingGraph
              memberState={booking.member.state}
              label="All shifts"
              ratings={getLastSixRatings(
                get("memberRatingForShift.allRatings", booking.member)
              )}
              averageRating={get(
                "memberRatingForShift.globalRating",
                booking.member
              )}
              totalRatings={get(
                "memberRatingForShift.globalTotal",
                booking.member
              )}
            />
            <div>
              <ActionDropdown
                isSmall
                isPrimary
                isDisabled={booking.state === "SHIFT_CANCELLED"}
                isLoading={isLoadingMemberStateChange === booking.member.id}
                onChange={option =>
                  handleMemberStateChange(option, booking, bookingsLimit)
                }
                value={BOOKING_STATES_LABELS[booking.state] || bookings.state}
                options={selectOptions.filter(
                  option => option.value !== BOOKING_STATES[booking.state]
                )}
              />
              {booking.member.state === MEMBER_STATES.PENDING && (
                <ActionDropdown
                  leftMargin
                  isSmall
                  onChange={option =>
                    createOrUpdateMember(booking.member.id, {
                      state:
                        option.value === MEMBER_STATES.ACTIVE
                          ? MEMBER_STATES.ACTIVE
                          : MEMBER_STATES.REJECTED
                    })
                  }
                  value="Onboard"
                  options={MENU_OPTIONS.ONBOARDING}
                />
              )}
              <RotaButtonNew
                variant="outlined"
                onClick={() => onViewBooking(booking.id)}
              >
                View Booking
              </RotaButtonNew>
            </div>
          </FlexContainer>
        ))
      )}
      {!shouldLoadMoreBookings({ shift, shiftItem, bookings }) && (
        <LoadMoreWrapper>
          <Button
            onClick={handleLoadMore}
            isSmall
            isLoading={isLoadingProvideBookings}
          >
            Load more
          </Button>
        </LoadMoreWrapper>
      )}
    </div>
  );
};

export default flow(createOrUpdateMember)(FutureShift);

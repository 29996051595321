import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";

const UPDATE_USER_TYPE_TEMPLATE = gql`
  mutation updateUserTypeTemplate($targetUserId: ID, $userTypeTemplateId: ID) {
    updateUserTypeTemplate(
      targetUserId: $targetUserId
      userTypeTemplateId: $userTypeTemplateId
    )
  }
`;

export default graphql(UPDATE_USER_TYPE_TEMPLATE, {
  name: "updateUserTypeTemplate",
  props: ({ updateUserTypeTemplate }) => ({
    updateUserTypeTemplate: userType =>
      updateUserTypeTemplate({
        variables: userType,
        refetchQueries: () => ["getUsers"]
      })
  })
});

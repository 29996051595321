import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";
import Icon from "~/src/components/icon";
import CircleProgress from "../CircleProgress";

const PADDING_LOSS_THRESHOLD = 35;
const RADIUS = STYLE_CONSTS.BORDER_RADIUS_L;

export const CalendarEventBox = styled.div`
  display: ${({ eventBoxHeight }) =>
    eventBoxHeight < PADDING_LOSS_THRESHOLD ? "flex" : "inherit"};
  border-radius: ${({ isToNext, isFromPrevious }) => {
    if (isToNext) return `${RADIUS} ${RADIUS} 0 0`;
    if (isFromPrevious) return `0 0 ${RADIUS} ${RADIUS}`;
    return RADIUS;
  }};
  position: relative;
  overflow: hidden;
  user-select: none;
  height: 100%;
  width: 100%;
  background: ${({ isCancelled }) =>
    isCancelled ? STYLE_CONSTS.GREY_GRADIENT : STYLE_CONSTS.PRIMARY_GRADIENT};
`;

export const EventHeader = styled.div`
  align-self: ${({ eventBoxHeight }) =>
    eventBoxHeight < PADDING_LOSS_THRESHOLD ? "center" : null};
  padding: ${({ eventBoxHeight }) =>
    eventBoxHeight < PADDING_LOSS_THRESHOLD
      ? `0 ${STYLE_CONSTS.UNIT_2}`
      : `${STYLE_CONSTS.UNIT_2}`};
  font-size: ${STYLE_CONSTS.FONT_SMALL};
  color: white;
`;

export const EventFooter = styled.div`
  position: absolute;
  bottom: ${STYLE_CONSTS.UNIT_2};
  left: ${STYLE_CONSTS.UNIT_2};
  right: ${STYLE_CONSTS.UNIT_2};
`;

export const EventName = styled.div`
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_MEDIUM};
  margin-bottom: ${STYLE_CONSTS.UNIT_1};
`;

export const EventRestricted = styled.div`
  margin-bottom: ${STYLE_CONSTS.UNIT_1};
`;
export const EventRole = styled.div`
  margin-bottom: ${STYLE_CONSTS.UNIT_1};
`;

export const Progress = styled(CircleProgress)`
  display: inline-block;
`;

export const ProgressLabel = styled.span`
  font-size: ${STYLE_CONSTS.FONT_XSMALL};
  display: inline-block;
  position: absolute;
  width: 80px;
  line-height: 25px;
  margin-left: ${STYLE_CONSTS.UNIT_1};
  color: white;
`;

export const GroupIcon = styled(Icon)`
  background-color: ${props => props.backgrouColor};
  height: 16px;
  width: 16px;
  padding: 3px;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
  display: ${props => (props.isVisible ? "flex" : "none")};
`;

export const ManagedIcon = styled(Icon)`
  background-color: ${props => props.backgrouColor};

  padding: 3px;
  border-radius: 50%;
  position: absolute;
  bottom: -1px;
  right: ${({ isAlone }) => (isAlone ? 0 : "20px")};
  display: ${props => (props.isVisible ? "flex" : "none")};
`;

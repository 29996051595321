import React, { memo } from "react";
import moment from "moment-timezone";
import {
  DateText,
  StyledDayContainer,
  Wrapper
} from "~/src/containers/scheduler/SchedulerGrid/scheduler-grid.styles";

const DateHeaders = ({
  startTime,
  selectedDayIndex,
  setSelectedDayIndex,
  isDailyView,
  isProviderScheduler
}) => (
  <Wrapper isProviderScheduler={isProviderScheduler}>
    {new Array(7).fill(0).map((_, i) => {
      const mom = moment(startTime)?.add(i, "d");
      const dateTitle = moment(mom)?.format("ddd Do");
      const isToday = mom?.isSame(moment(), "d");

      return (
        <StyledDayContainer>
          <DateText
            key={i}
            isToday={isToday}
            isActive={selectedDayIndex === i}
            isDailyView={isDailyView}
            onClick={() => setSelectedDayIndex(i)}
          >
            {dateTitle}
          </DateText>
        </StyledDayContainer>
      );
    })}
  </Wrapper>
);

export default memo(DateHeaders);

import styled from "styled-components";
import { getColorToRGBA } from "@teamrota/rota-design";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const CalendarHeaderBox = styled.div`
  border-bottom: 1px solid
    ${({ theme }) => getColorToRGBA(theme.neutral.lighter, 0.25)};
  display: flex;
`;

export const ToolBar = styled.div`
  background: "red";
  cursor: pointer;
  display: table;
  margin-left: auto;
  margin-right: ${STYLE_CONSTS.UNIT_4};
`;

export const HeaderMonth = styled.span`
  background: ${({ theme }) => getColorToRGBA(theme.neutral.lighter, 0.25)};
  border-radius: ${STYLE_CONSTS.BORDER_RADIUS_S};
  padding: ${STYLE_CONSTS.UNIT_HALF} ${STYLE_CONSTS.UNIT_1};
`;

export const HeaderItem = styled.span`
  position: ${props => props?.position};
  left: ${props => props?.left};
  font-size: ${STYLE_CONSTS.FONT};
  font-weight: ${props =>
    props?.weight && `${STYLE_CONSTS.FONT_WEIGHT_MEDIUM}`};
  display: table-cell;
  height: ${STYLE_CONSTS.UNIT_11};
  vertical-align: middle;
  horizontal-align: middle;
  padding-left: ${STYLE_CONSTS.UNIT_4};
  padding-right: ${STYLE_CONSTS.UNIT_4};
  color: ${({ orange, theme }) => orange && theme.primary.main};
`;

export const HeaderItemLeft = styled(HeaderItem)`
  left: 14px;
  position: absolute;
  top: 18px;
`;

export const HeaderItemRight = styled(HeaderItem)`
  top: 18px;
`;

export const CreateShiftText = styled.span`
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_MEDIUM};
  font-size: ${STYLE_CONSTS.FONT_MEDIUM};
  text-transform: capitalize;
  padding: 5px;
`;

const styles = {
  headerAlign: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  icon: {
    width: 25,
    paddingRight: 5
  }
};

export default styles;

import { gql, useQuery } from "@apollo/client";

const GET_DIVISIONS = gql`
  query getAccountDivisions($accountId: ID!) {
    account {
      divisions {
        id
        name
      }
    }
  }
`;

export const useDivisions = ({ accountId }) => {
  const {
    data: { account: { divisions } = {} } = {},
    loading,
    refetch
  } = useQuery(GET_DIVISIONS, {
    variables: {
      accountId
    }
  });

  return {
    divisions,
    loading,
    refetch
  };
};

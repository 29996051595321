import React, { useState } from "react";
import { RotaButton } from "@teamrota/rota-design";

import SubvenueCard from "./SubvenueCard";

import {
  SubvenuesContainer,
  SubvenuesHeader,
  SubvenuesList
} from "./subvenues.styles";
import SubvenueModal from "./SubvenueModal";

const DEFAULT_SUBVENUE_FORM = {
  name: "",
  integraId: "",
  costCode: "",
  mmCode: ""
};

const SubvenuesSection = ({ subvenues, venueId, isTTM }) => {
  const [isShowSubvenueModal, setIsShowSubvenueModal] = useState(false);
  const [subvenueForm, setSubvenueForm] = useState(DEFAULT_SUBVENUE_FORM);

  const updateSubvenueForm = (key, value) => {
    setSubvenueForm({
      ...subvenueForm,
      [key]: value
    });
  };

  const closeSubvenueModal = () => {
    setIsShowSubvenueModal(false);
    setSubvenueForm(DEFAULT_SUBVENUE_FORM);
  };

  const openSubvenueAddModal = () => {
    setIsShowSubvenueModal(true);
  };

  const openSubvenueEditModal = subvenue => {
    setIsShowSubvenueModal(true);
    setSubvenueForm({ ...subvenue });
  };

  return (
    <>
      <SubvenuesContainer>
        <SubvenuesHeader>Subvenues</SubvenuesHeader>
        <SubvenuesList>
          {subvenues.length > 0 ? (
            subvenues?.map(subvenue => (
              <SubvenueCard
                key={subvenue.id}
                subvenue={subvenue}
                openSubvenueEditModal={openSubvenueEditModal}
              />
            ))
          ) : (
            <div>No subvenues</div>
          )}
        </SubvenuesList>

        <RotaButton variant="outlined" onClick={openSubvenueAddModal}>
          Add subvenue
        </RotaButton>
      </SubvenuesContainer>
      {isShowSubvenueModal && (
        <SubvenueModal
          venueId={venueId}
          closeSubvenueModal={closeSubvenueModal}
          subvenueForm={subvenueForm}
          updateSubvenueForm={updateSubvenueForm}
          isTTM={isTTM}
        />
      )}
    </>
  );
};

export default SubvenuesSection;

import { gql } from "@apollo/client";
import set from "lodash/fp/set";
import { graphql } from "@apollo/client/react/hoc";
import upsertArray from "~/src/utils/upsert-array";

const createOrUpdateVenueMutation = graphql(
  gql`
    mutation Mutation(
      $id: ID
      $name: String
      $address: String
      $meetingPoint: String
      $directions: String
      $lat: Float
      $lng: Float
      $isPinCodeCheckInEnabled: Boolean
      $isQrCodeCheckInEnabled: Boolean
      $sageRef: String
      $integraId: String
      $isLive: Boolean
    ) {
      createOrUpdateVenue(
        id: $id
        name: $name
        address: $address
        meetingPoint: $meetingPoint
        directions: $directions
        lat: $lat
        lng: $lng
        isPinCodeCheckInEnabled: $isPinCodeCheckInEnabled
        isQrCodeCheckInEnabled: $isQrCodeCheckInEnabled
        sageRef: $sageRef
        integraId: $integraId
        isLive: $isLive
      ) {
        id
        name
        address
        meetingPoint
        directions
        lat
        lng
        createdAt
        isPinCodeCheckInEnabled
        isQrCodeCheckInEnabled
        sageRef
        integraId
        isLive
      }
    }
  `,
  {
    name: "createOrUpdateVenue",
    props: ownProps => ({
      createOrUpdateVenue: variables =>
        ownProps.createOrUpdateVenue({
          variables,
          updateQueries: {
            getProfile: (prevData, { mutationResult }) => {
              const prevVenues = prevData.user.account.venues;
              const updatedVenues = upsertArray(
                venue =>
                  venue.id === mutationResult.data.createOrUpdateVenue.id,
                mutationResult.data.createOrUpdateVenue,
                prevVenues
              );

              return set("user.account.venues", updatedVenues, prevData);
            }
          }
        })
    })
  }
);

export default createOrUpdateVenueMutation;

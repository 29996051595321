import styled, { css } from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const Wrapper = styled.div`
  height: 100%;
`;

export const TabNavWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  margin: 0px 12px;
  height: 100%;
`;

export const TabNav = styled.div`
  width: 100%;
  display: flex;
  overflow-x: auto;
  justify-content: ${({ center }) => (center ? "flex-start" : "space-between")};
  scroll-behavior: smooth;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-right: ${({ isScrolling }) => (isScrolling ? "40px" : "0")};
`;

export const Tab = styled.div`
  display: inline-flex;
  margin: ${STYLE_CONSTS.UNIT_2};
  font-size: ${STYLE_CONSTS.FONT_MEDIUM};
  color: ${({ isActive, isUnclickable, theme }) =>
    isActive
      ? theme.primary.main
      : isUnclickable
      ? theme.text.secondary
      : theme.text.primary};
  cursor: ${({ isUnclickable }) => (isUnclickable ? "not-allowed" : "pointer")};
  position: relative;
  ${({ isActive, theme }) =>
    isActive &&
    css`
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        bottom: -3px;
        background-color: ${theme.primary.main};
      }
    `}
`;

export const RotaButtonIconWrapper = styled.div`
  background-color: ${({ theme }) => theme.white};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`;

import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import ToolTip from "react-tooltip";
import { useQuery, useMutation } from "@apollo/client";
import { debounce } from "lodash";
import { connect } from "react-redux";

// this is a typescript import issue, it works fine
// eslint-disable-next-line import/no-unresolved
import { useAutoAnimate } from "@formkit/auto-animate/react";

import { RotaButton, colors, icon, RotaSnackBar } from "@teamrota/rota-design";
import { Role } from "@teamrota/authlib";

import useAuth from "~/src/auth/hooks/use-auth";
import HasRole from "~/src/containers/has-role";

import { BOOKING_STATES } from "~/src/consts";
import { isValidDate } from "~/src/utils/validators";

import GET_SHIFTS_QUERY from "~/src/graphql/queries/get-shifts/get-shifts.query";
import GET_PROFILE from "~/src/graphql/queries/get-profile/get-profile.query";
import { GET_TIMESHEET_SHIFTS } from "./graphql/get-timesheet-shifts";
import { CREATE_OR_UPDATE_SHIFT } from "./graphql/update-internalId";

import {
  FloatingTable,
  ColumnHeading,
  TableBody
} from "~/src/components/floating-table";

import { TimesheetBlock } from "./components/block";
import Icon from "~/src/components/icon";
import PageHeader from "~/src/components/page-header";
import { errorModal } from "~/src/utils/errors";
import Loading from "~/src/components/loading";
import Text from "~/src/containers/modals/components/text";
import LoadMore from "~/src/components/load-more";
import TablePlaceholder from "~/src/components/table-placeholder";
import NoResults from "~/src/components/no-results";
import Filters from "./components/filters";
import Costs from "./components/timesheet/components/costs";

import { printAllShifts } from "~/src/containers/timesheets/helpers";

import {
  HeaderInnerWrapper,
  HeaderBookingInfo,
  TooltipPrintContainer,
  HeaderBookingText,
  PrintDropDown,
  NavTray,
  PrintLink,
  Flex,
  StyledWrapFooter,
  StyledWrapLegend,
  StyledWrapParagraph,
  StyledWrapIcon
} from "./timesheets.styles";

import { onUpdateFilters } from "./reducer";
import { convertDateFromString } from "./util";

const getIfValid = date =>
  date && isValidDate(date)
    ? moment(date, "DD/MM/YYYY")
        .toDate()
        .toString()
    : null;

const renderPrintToolTip = shouldPull => (
  <TooltipPrintContainer pullToTheLeftOnload={shouldPull}>
    <Text pointer data-tip="print-tooltip" data-for="print-tooltip">
      <Icon name={Icon.names.INFO} size="smallmid" />
    </Text>
    <ToolTip data-place="bottom" multiline id="print-tooltip" effect="solid">
      Please make sure you have disabled your <br /> AdBlock or any similar type
      of software.
    </ToolTip>
  </TooltipPrintContainer>
);

const Timesheets = ({ filters, dispatch }) => {
  const { FavoriteIcon, LocationIcon, TurnedAwayIcon, NoShowIcon } = icon;
  const auth = useAuth();
  const [animationParent] = useAutoAnimate();

  const { data: user } = useQuery(GET_PROFILE, {
    variables: auth.addVals(GET_PROFILE, {
      connectionLimit: 10,
      connectionOffset: 0
    })
  });

  const {
    data: account,
    fetchMore,
    loading: isLoadingShifts,
    startPolling: startPollingShifts,
    stopPolling: stopPollingShifts
  } = useQuery(GET_SHIFTS_QUERY, {
    variables: auth.addVals(GET_SHIFTS_QUERY, {
      limit: 20,
      offset: 0,
      isTimesheetPage: true,
      withBookings: true,
      bookingsLimit: 200,
      bookingStateIn: [BOOKING_STATES.ACCEPTED, BOOKING_STATES.SHIFT_CANCELLED],
      bookingsOffset: 0,
      collateBookingStastics: true,
      shouldShowUnapprovedFirst: true,
      shouldExcludeWhereCancellationPolicyIsNotApplied: true,
      startTimeRangeStart: getIfValid(filters.startTime),
      startTimeRangeEnd: getIfValid(filters.endTime),
      hasBookingWithStateIn: [
        BOOKING_STATES.ACCEPTED,
        BOOKING_STATES.SHIFT_CANCELLED
      ]
    })
  });

  const sourceAccountId = user?.user?.account?.id;
  const startDate = convertDateFromString(filters.startTime);
  const endDate = convertDateFromString(filters.endTime);

  const { data: selectedAllPrint } = useQuery(GET_TIMESHEET_SHIFTS, {
    variables: auth.addVals(GET_TIMESHEET_SHIFTS, {
      sourceAccountId,
      startDate,
      endDate,
      offset: 0,
      limit: 200,
      isTimesheetPage: true
    })
  });

  const [createOrUpdateShift] = useMutation(CREATE_OR_UPDATE_SHIFT);

  const [shifts, setShifts] = useState([]);

  useEffect(() => {
    setShifts(account?.account?.shifts?.data ?? []);
  }, [account]);

  const [showLoading, setShowLoading] = useState(false);
  const [openTimesheetId, setOpenTimesheetId] = useState(null);
  const [isLoadingAll, setIsLoadingAll] = useState(false);
  const [snackBar, setSnackBar] = useState({ key: 1 });

  // we use polling to detect when approval is complete as there can
  // be several shifts in flight and refetches can clash

  const [currentlyApproving, setCurrentlyApproving] = useState([]);

  const handleApprovalBegin = shiftId => {
    if (!currentlyApproving.find(id => id === shiftId)) {
      setCurrentlyApproving([...currentlyApproving, shiftId]);
    }
  };

  const handleApprovalEnded = shiftId => {
    setCurrentlyApproving(currentlyApproving.filter(id => id !== shiftId));
  };

  useEffect(() => {
    if (currentlyApproving.length > 0) {
      startPollingShifts(2000);
    } else {
      stopPollingShifts();
    }
  }, [JSON.stringify(currentlyApproving)]);

  const setSnackMessage = (message = null, severity = null) => {
    // generate a new SnackBar each time to avoid it fading permanently
    if (message) {
      setSnackBar({
        key: snackBar.key + 1,
        open: true,
        message,
        severity
      });
    } else {
      setSnackBar({
        key: snackBar.key + 1,
        open: false,
        message: null,
        severity: null
      });
    }
  };

  const allShifts = account?.account?.shifts;
  const isNoResults = shifts?.length === 0 && !isLoadingShifts;
  const totalHours = allShifts?.totalHours ?? 0;
  const totalResults = allShifts?.totalResults ?? [];
  const shiftBookingCount = allShifts?.bookingCount;
  const shiftBookingCountTotalCost = allShifts?.bookingCountTotalCost;
  const isNoMoreResults = shifts?.length === totalResults;

  const displayIfNotLoading = (PassedComponent, isLoading) => {
    if (isLoading) {
      return (
        <Loading
          implementSafariLoadHack={navigator.userAgent.indexOf("Safari") > 1}
          hideText
          alignCenter
          color={colors.rotaOrange}
          done={!isLoading}
        />
      );
    }

    return PassedComponent;
  };

  const handlePrintAll = async (picturesHidden = false) => {
    setIsLoadingAll(true);
    try {
      printAllShifts(
        selectedAllPrint,
        picturesHidden,
        filters,
        shiftBookingCount,
        shiftBookingCountTotalCost
      );
    } catch (e) {
      setIsLoadingAll(false);
      errorModal(e);
    } finally {
      setTimeout(() => {
        setIsLoadingAll(false);
      }, 1500);
    }
  };

  const handleLoadMore = async () => {
    setShowLoading(true);
    try {
      fetchMore({
        variables: {
          offset: shifts.length
        },
        updateQuery: (_, { fetchMoreResult }) => {
          setShifts([
            ...shifts,
            ...(fetchMoreResult?.account?.shifts?.data || [])
          ]);
        }
      });
    } catch (e) {
      errorModal(e);
      setShowLoading(false);
    }
    setShowLoading(false);
  };

  const handleInternalIdSave = async (id, evt) => {
    const internalId = evt.target.value;

    try {
      createOrUpdateShift({
        variables: { shiftId: id, identifier: internalId }
      });
    } catch (e) {
      errorModal(e);
    }
  };

  return (
    <div>
      <PageHeader
        overridePadding="20px 24px"
        title="Timesheets"
        subtext={`${user?.user?.account?.totalOutstandingTimesheets} need approval`}
      >
        <HeaderInnerWrapper>
          <Flex width="50">
            <Filters
              onUpdate={updates => dispatch(onUpdateFilters(updates))}
              startTime={filters?.startTime}
              endTime={filters?.endTime}
            />
          </Flex>
          <Flex paddingLeft width="50">
            <HeaderBookingInfo margin style={{ marginTop: "3px" }}>
              <HeaderBookingText isBold>Bookings</HeaderBookingText>
              {displayIfNotLoading(
                <HeaderBookingText cloudyBlue>
                  {shiftBookingCount}
                </HeaderBookingText>,
                isLoadingShifts
              )}
            </HeaderBookingInfo>

            <HeaderBookingInfo margin style={{ marginTop: "3px" }}>
              <HeaderBookingText isBold>Total hours</HeaderBookingText>
              {displayIfNotLoading(
                <HeaderBookingText cloudyBlue>
                  {totalHours && parseFloat(totalHours).toFixed(2)}
                </HeaderBookingText>,
                isLoadingShifts
              )}
            </HeaderBookingInfo>

            <HasRole role={Role.SHIFTS_COST}>
              <Costs
                shift={shifts}
                isLoading={isLoadingShifts}
                displayIfNotLoading={displayIfNotLoading}
                setPrintVariables={printVariables => printVariables}
                shiftBookingCountTotalCost={shiftBookingCountTotalCost}
                shiftBookingCount={shiftBookingCount}
              />
            </HasRole>
            {!isNoResults && auth.hasRole(Role.TIMESHEETS_PRINT) && (
              <HeaderBookingInfo marginRight="25px">
                <PrintDropDown>
                  <RotaButton
                    size="small"
                    variant="outlined"
                    isLoading={isLoadingAll}
                  >
                    Print
                  </RotaButton>
                  <NavTray isPrint id="nav-tray-show-hide">
                    <PrintLink onClick={() => handlePrintAll()}>
                      Print with pictures{" "}
                    </PrintLink>
                    <PrintLink onClick={() => handlePrintAll(true)}>
                      Without pictures{" "}
                    </PrintLink>
                  </NavTray>
                </PrintDropDown>
                {renderPrintToolTip(isLoadingAll)}
              </HeaderBookingInfo>
            )}
          </Flex>
        </HeaderInnerWrapper>
      </PageHeader>

      <FloatingTable>
        <div>
          <ColumnHeading
            overideFontSize="18"
            width={(auth.hasRole(Role.SHIFTS_COST) ? 4 : 5) / 10}
          >
            Shift
          </ColumnHeading>
          <ColumnHeading overideFontSize="18" width={2 / 10}>
            Approval State{" "}
          </ColumnHeading>
          {/* Prevent the above being the last child, init. */}
          <ColumnHeading overideFontSize="18" width={2 / 10}>
            Member Acknowledgement{" "}
          </ColumnHeading>
        </div>
        <TableBody>
          {isNoResults && <NoResults />}
          {!isNoResults && (
            <div style={{ position: "relative" }} ref={animationParent}>
              {isLoadingShifts && (
                <TablePlaceholder columnWidths={[1 / 2, 1 / 4, 1 / 4]} />
              )}
              {shifts?.map(shiftMap => {
                return (
                  <TimesheetBlock
                    key={shiftMap?.id}
                    sourceAccountId={sourceAccountId}
                    shift={shiftMap}
                    openTimesheetId={openTimesheetId}
                    setOpenTimesheetId={setOpenTimesheetId}
                    handleInternalIdSave={handleInternalIdSave}
                    onApprovalBegin={handleApprovalBegin}
                    onApprovalEnded={handleApprovalEnded}
                    setSnackMessage={setSnackMessage}
                  />
                );
              })}
            </div>
          )}
        </TableBody>

        <StyledWrapFooter>
          <StyledWrapLegend>
            <StyledWrapParagraph start={1}>Legend:</StyledWrapParagraph>
            <StyledWrapIcon start={2}>
              <LocationIcon color="orange" /> &nbsp;- via pin
            </StyledWrapIcon>
            <StyledWrapIcon start={3} padding="16px">
              <FavoriteIcon color="orange" /> &nbsp;- favourite
            </StyledWrapIcon>
            <StyledWrapIcon start={4} padding="16px">
              <TurnedAwayIcon color="orange" colorLine="black" /> &nbsp;- turned
              away
            </StyledWrapIcon>
            <div
              style={{
                gridColumnStart: 5,
                display: "flex",
                alignItems: "center"
              }}
            >
              <NoShowIcon
                color={colors.rotaOrange}
                colorLine={colors.black}
                styles={{ marginTop: "5px" }}
              />
              &nbsp;- no show
            </div>

            <StyledWrapParagraph start={6} justify="flex-end">
              *Scheduled Hours will reflect allotted shift hours if actual time
              is not available/captured
            </StyledWrapParagraph>
          </StyledWrapLegend>
        </StyledWrapFooter>
      </FloatingTable>

      {!isNoMoreResults && (
        <LoadMore
          isLoading={isLoadingShifts || showLoading}
          onLoadMore={debounce(handleLoadMore, 100)}
        />
      )}

      <RotaSnackBar
        key={snackBar.key}
        snackOpen={snackBar.open}
        onClose={() => setSnackMessage(null)}
        message={snackBar.message}
        severity={snackBar.severity}
      />
    </div>
  );
};

const mapStateToProps = s => ({
  filters: s.timesheets?.filters
});

export default connect(mapStateToProps, null)(Timesheets);

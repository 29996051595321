import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  setIsCasualMemberTypeChecked,
  setIsAgencyMemberTypeChecked,
  setIsPermanentMemberTypeChecked,
  setIsContractorLimitedCompanyMemberTypeChecked,
  setIsContractorUmbrellaCompanyMemberTypeChecked,
  setIsContractorSelfEmployedMemberTypeChecked
} from "../../reducer";
import getAccountAvailableMemberTypes from "../../graphql/get-available-member-types";
import { CheckBoxContainer } from "./member-search.styles";
import { RotaCheckbox } from "@teamrota/rota-design";

const MemberTypeCheckBox = ({
  setIsCasualMemberTypeChecked,
  setIsAgencyMemberTypeChecked,
  setIsPermanentMemberTypeChecked,
  setIsContractorLimitedCompanyMemberTypeChecked,
  setIsContractorUmbrellaCompanyMemberTypeChecked,
  setIsContractorSelfEmployedMemberTypeChecked,
  isCasualMemberTypeChecked,
  isAgencyMemberTypeChecked,
  isPermanentMemberTypeChecked,
  isContractorLimitedCompanyMemberTypeChecked,
  isContractorUmbrellaCompanyMemberTypeChecked,
  isContractorSelfEmployedMemberTypeChecked,
  memberTypesAvailableForAccount,
  updateField,
  memberType
}) => {
  const {
    isCasualMemberTypeAvailable,
    isAgencyMemberTypeAvailable,
    isPermanentMemberTypeAvailable,
    isContractorLimitedCompanyMemberTypeAvailable,
    isContractorSelfEmployedMemberTypeAvailable,
    isContractorUmbrellaCompanyMemberTypeAvailable
  } = memberTypesAvailableForAccount || {};

  const toggleCasualMember = () => {
    setIsCasualMemberTypeChecked(!isCasualMemberTypeChecked);
    !isCasualMemberTypeChecked
      ? updateField({
          memberType: [...memberType, { memberType: "CASUAL" }]
        })
      : updateField({
          memberType: memberType.filter(item => item.memberType !== "CASUAL")
        });
  };

  const toggleAgencyMember = () => {
    setIsAgencyMemberTypeChecked(!isAgencyMemberTypeChecked);
    !isAgencyMemberTypeChecked
      ? updateField({
          memberType: [...memberType, { memberType: "AGENCY" }]
        })
      : updateField({
          memberType: memberType.filter(item => item.memberType !== "AGENCY")
        });
  };
  const togglePermanentMember = () => {
    setIsPermanentMemberTypeChecked(!isPermanentMemberTypeChecked);
    !isPermanentMemberTypeChecked
      ? updateField({
          memberType: [...memberType, { memberType: "PERMANENT" }]
        })
      : updateField({
          memberType: memberType.filter(item => item.memberType !== "PERMANENT")
        });
  };
  const toggleLimitedMember = () => {
    setIsContractorLimitedCompanyMemberTypeChecked(
      !isContractorLimitedCompanyMemberTypeChecked
    );
    !isContractorLimitedCompanyMemberTypeChecked
      ? updateField({
          memberType: [
            ...memberType,
            { memberType: "CONTRACTOR_LIMITED_COMPANY" }
          ]
        })
      : updateField({
          memberType: memberType.filter(
            item => item.memberType !== "CONTRACTOR_LIMITED_COMPANY"
          )
        });
  };
  const toggleUmbrellaMember = () => {
    setIsContractorUmbrellaCompanyMemberTypeChecked(
      !isContractorUmbrellaCompanyMemberTypeChecked
    );
    !isContractorUmbrellaCompanyMemberTypeChecked
      ? updateField({
          memberType: [
            ...memberType,
            { memberType: "CONTRACTOR_UMBRELLA_COMPANY" }
          ]
        })
      : updateField({
          memberType: memberType.filter(
            item => item.memberType !== "CONTRACTOR_UMBRELLA_COMPANY"
          )
        });
  };
  const toggleSelfEmployedMember = () => {
    setIsContractorSelfEmployedMemberTypeChecked(
      !isContractorSelfEmployedMemberTypeChecked
    );
    !isContractorSelfEmployedMemberTypeChecked
      ? updateField({
          memberType: [
            ...memberType,
            { memberType: "CONTRACTOR_SELF_EMPLOYED" }
          ]
        })
      : updateField({
          memberType: memberType.filter(
            item => item.memberType !== "CONTRACTOR_SELF_EMPLOYED"
          )
        });
  };

  return (
    <CheckBoxContainer>
      {isCasualMemberTypeAvailable && (
        <RotaCheckbox
          label="CASUAL"
          isChecked={isCasualMemberTypeChecked}
          onClick={() => {
            toggleCasualMember();
          }}
        />
      )}
      {isAgencyMemberTypeAvailable && (
        <RotaCheckbox
          label="AGENCY"
          isChecked={isAgencyMemberTypeChecked}
          onClick={() => {
            toggleAgencyMember();
          }}
        />
      )}
      {isPermanentMemberTypeAvailable && (
        <RotaCheckbox
          label="PERMANENT"
          isChecked={isPermanentMemberTypeChecked}
          onClick={() => {
            togglePermanentMember();
          }}
        />
      )}
      {isContractorLimitedCompanyMemberTypeAvailable && (
        <RotaCheckbox
          label="CONTRACTOR_LIMITED_COMPANY"
          isChecked={isContractorLimitedCompanyMemberTypeChecked}
          onClick={() => {
            toggleLimitedMember();
          }}
        />
      )}
      {isContractorUmbrellaCompanyMemberTypeAvailable && (
        <RotaCheckbox
          label="CONTRACTOR_UMBRELLA_COMPANY"
          isChecked={isContractorUmbrellaCompanyMemberTypeChecked}
          onClick={() => {
            toggleUmbrellaMember();
          }}
        />
      )}

      {isContractorSelfEmployedMemberTypeAvailable && (
        <RotaCheckbox
          label="CONTRACTOR_SELF_EMPLOYED"
          isChecked={isContractorSelfEmployedMemberTypeChecked}
          onClick={() => {
            toggleSelfEmployedMember();
          }}
        />
      )}
    </CheckBoxContainer>
  );
};

const mapStateToProps = state => ({
  isCasualMemberTypeChecked: state.myStaff.filters.isCasualMemberTypeChecked,
  isAgencyMemberTypeChecked: state.myStaff.filters.isAgencyMemberTypeChecked,
  isPermanentMemberTypeChecked:
    state.myStaff.filters.isPermanentMemberTypeChecked,
  isContractorLimitedCompanyMemberTypeChecked:
    state.myStaff.filters.isContractorLimitedCompanyMemberTypeChecked,
  isContractorUmbrellaCompanyMemberTypeChecked:
    state.myStaff.filters.isContractorUmbrellaCompanyMemberTypeChecked,
  isContractorSelfEmployedMemberTypeChecked:
    state.myStaff.filters.isContractorSelfEmployedMemberTypeChecked
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setIsCasualMemberTypeChecked,
      setIsAgencyMemberTypeChecked,
      setIsPermanentMemberTypeChecked,
      setIsContractorLimitedCompanyMemberTypeChecked,
      setIsContractorUmbrellaCompanyMemberTypeChecked,
      setIsContractorSelfEmployedMemberTypeChecked
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(getAccountAvailableMemberTypes(MemberTypeCheckBox));

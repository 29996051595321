import React, { memo } from "react";
import { useTheme } from "styled-components";

import { RotaButton } from "@teamrota/rota-design";
import TextInput from "~/src/components/form-components/text-input";

import IconButton from "~/src/components/icon-button";
import TextArea from "~/src/components/form-components/textarea";
import TemplatePanel from "~/src/containers/templates/components/template-panel";

import {
  Divider,
  ContentPadding
} from "~/src/containers/templates/templates.styles";
import {
  InputContainer,
  ItemContainer,
  ItemInputWrapper,
  InputLabel,
  TabsWrapper,
  Wrapper,
  TabOption,
  VisualWrapper,
  UniformSelect,
  ImageWrapper,
  SelectWrapper,
  UniformImage
} from "./index.styles";

import { DRESS_CODE_IMAGES } from "~/src/consts";

const UNIFORM_TEMPLATES = {
  VISUAL_UNIFORM: 0,
  BULLETED_UNIFORM: 1
};

const TABS = ["Visual", "Bulleted"];

const EditTemplate = ({
  onReset,
  inEditMode,
  errorLabel,
  name,
  onUpdateName,
  newTagValue,
  onEditNewTag,
  onEnter,
  onUpdateItem,
  onRemoveItem,
  templateItems,
  templateItemsErrorText,
  isLoading,
  hasSaved,
  onSave,
  selectedVisual,
  activeTemplate,
  onVisualSelected,
  toggleActiveTab
}) => {
  const theme = useTheme();
  const options = DRESS_CODE_IMAGES.map((img, index) => ({
    value: index,
    label: img.label
  }));

  const renderTabs = activeTemplate => {
    switch (activeTemplate) {
      case UNIFORM_TEMPLATES.VISUAL_UNIFORM:
        return (
          <VisualWrapper>
            <SelectWrapper>
              <InputLabel hasPadding>Select a uniform: </InputLabel>
              <UniformSelect
                value={parseInt(selectedVisual)}
                options={options}
                placeholder="Select one"
                isSmall
                isDisabled={false}
                onChange={value => {
                  onVisualSelected(value);
                }}
              />

              <InputLabel hasPadding>
                If something is missing, describe it below:
              </InputLabel>
              <TextArea
                onChangeValue={e =>
                  inEditMode ? onUpdateItem(0, e) : onEditNewTag(e)
                }
                isError={templateItemsErrorText}
                value={inEditMode ? templateItems[0] : newTagValue}
                minRows={4}
                maxRows={4}
                isDisabled={false}
                isMultilineAuto
                overrideOrange
              />
            </SelectWrapper>
            <Wrapper>
              <ImageWrapper>
                <UniformImage
                  src={DRESS_CODE_IMAGES[selectedVisual || 0].uri}
                />
              </ImageWrapper>
            </Wrapper>
          </VisualWrapper>
        );

      case UNIFORM_TEMPLATES.BULLETED_UNIFORM:
        return (
          <Wrapper>
            <InputContainer>
              <InputLabel>Add item</InputLabel>
              <TextInput
                fontColor={theme.primary.main}
                errorLabel={templateItemsErrorText}
                isRequired
                isDisabled={false}
                maxLength={200}
                showMaxLength
                placeholder="i.e. “Black shoes”. Press enter to add new item"
                value={newTagValue}
                onChangeValue={onEditNewTag}
                onKeyDown={({ key }) => {
                  if (key === "Enter") {
                    onEnter();
                  }
                }}
              />
            </InputContainer>
            {templateItems?.map((item, index) => (
              <ItemContainer key={index}>
                <ItemInputWrapper>
                  <TextInput
                    value={item}
                    onChange={event =>
                      onUpdateItem(index, event?.target?.value)
                    }
                  />
                </ItemInputWrapper>
                <IconButton
                  color={theme.neutral.light}
                  size={IconButton.sizes.SIZE_3}
                  isBorderless
                  iconName={IconButton.iconNames.EXIT}
                  onClick={() => onRemoveItem(index)}
                />
              </ItemContainer>
            ))}
          </Wrapper>
        );
      default:
        return null;
    }
  };

  return (
    <TemplatePanel
      title="Create new Uniform Template"
      onReset={onReset}
      isEditMode={inEditMode}
    >
      <ContentPadding>
        <TabsWrapper>
          {TABS.map((tab, index) => (
            <TabOption
              key={index}
              isFirstChild={index === 0}
              isActive={index === activeTemplate}
              shouldBeHidden={inEditMode && index !== activeTemplate}
              onClick={!inEditMode ? toggleActiveTab : null}
              inEditMode={inEditMode}
            >
              {tab}
            </TabOption>
          ))}
        </TabsWrapper>
        <TextInput
          errorLabel={errorLabel}
          isRequired
          fontColor={theme.primary.main}
          placeholder="Name your template"
          isBorderless
          value={name}
          isDisabled={false}
          onChangeValue={onUpdateName}
        />
        <Divider isMarginless />
      </ContentPadding>
      {renderTabs(activeTemplate)}
      <ContentPadding style={{ textAlign: "right" }}>
        <RotaButton
          isDisabled={false}
          onClick={onReset}
          isLoading={isLoading}
          isUsingFlexbox
          variant="secondary"
        >
          Cancel
        </RotaButton>{" "}
        <RotaButton
          isDisabled={false}
          onClick={onSave}
          isSuccess={hasSaved}
          isLoading={isLoading}
          isUsingFlexbox
        >
          {hasSaved ? "Saved" : "Save"}
        </RotaButton>
      </ContentPadding>
    </TemplatePanel>
  );
};

export default memo(EditTemplate);

import React, { useState } from "react";

import {
  RotaTag,
  RotaInput,
  RotaButton,
  RotaButtonIcon
} from "@teamrota/rota-design";

import AccountsList from "~/src/containers/admin-dashboard/modules/accounts-list";

import {
  StyledMainHeading,
  StyledMainDetail,
  StyledMainHeaderWrapper,
  StyledWrapper,
  StyledFeaturesWrapper,
  StyledFeatureHeader
} from "../styles";

import { StyledButtonWrapper, StyledWrapDivisions } from "./styles";
import { useDivisions } from "./graphql/use-divisions";
import { useCreateDivision } from "./graphql/use-create-division";

import { RotaModal } from "@teamrota/rota-design";

const AddDivisions = ({ setSnack }) => {
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [isAddDivisionModalOpen, setIsAddDivisionModalOpen] = useState(false);
  const [createDivisionInput, setCreateDivisionInput] = useState("");
  const [selectedAccountName, setSelectedAccountName] = useState();

  const { divisions, loading, refetch } = useDivisions({
    accountId: selectedAccountId
  });
  const { createDivision } = useCreateDivision({
    accountId: selectedAccountId,
    onCompleted: () => setSnack({ message: "Success!", severity: "success" }),
    onError: () =>
      setSnack({ message: "Something Went Wrong.", severity: "error" })
  });

  const handleCreateDivision = async () => {
    await createDivision({ name: createDivisionInput });
    handleModalClose();
    refetch();
  };

  const handleModalClose = () => {
    setCreateDivisionInput("");
    setIsAddDivisionModalOpen(false);
  };

  return (
    <>
      <StyledMainHeaderWrapper>
        <StyledMainHeading>Add Account Divisions</StyledMainHeading>
        <StyledMainDetail>Manage divisions for all accounts</StyledMainDetail>
      </StyledMainHeaderWrapper>
      <StyledWrapper>
        <AccountsList
          onSelect={(id, accountName) => {
            setSelectedAccountId(id);
            setSelectedAccountName(accountName);
          }}
          selectedAccountId={selectedAccountId}
          selectedAccountName={selectedAccountName}
        />
        {selectedAccountId && (
          <StyledFeaturesWrapper>
            <StyledFeatureHeader>
              <h3>{selectedAccountName} Divisions</h3>
              <RotaButtonIcon
                icon="add"
                onClick={() => setIsAddDivisionModalOpen(true)}
              />
            </StyledFeatureHeader>
            <StyledWrapDivisions>
              {loading && <p>Loading...</p>}
              <RotaTag tags={divisions || []} isReadOnly />
            </StyledWrapDivisions>
          </StyledFeaturesWrapper>
        )}
      </StyledWrapper>
      {isAddDivisionModalOpen && (
        <RotaModal isSmall onClose={handleModalClose} name={"Create division"}>
          <RotaInput
            label="Division name"
            placeholder="Division name"
            onChange={({ target }) => setCreateDivisionInput(target.value)}
            value={createDivisionInput}
          />
          <StyledButtonWrapper>
            <RotaButton variant="secondary" onClick={handleModalClose}>
              Cancel
            </RotaButton>
            <RotaButton
              onClick={handleCreateDivision}
              disabled={!createDivisionInput}
            >
              Create Division
            </RotaButton>
          </StyledButtonWrapper>
        </RotaModal>
      )}
    </>
  );
};

export default AddDivisions;

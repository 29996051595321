import React from "react";
import { RotaModal } from "@teamrota/rota-design";

import { Text, MessageContainer } from "./default-error.styles";

export const DefaultError = ({ onClose, message, errors, isOpen }) => (
  <RotaModal
    isOpen={isOpen}
    onClose={onClose}
    isOverlay
    name="Error"
    isSmall
    styles={{ height: "250px" }}
  >
    <MessageContainer>
      {message && <Text>{message}</Text>}
      {errors?.length &&
        errors.map((error, i) => (
          <ul key={i}>
            <li>{error}</li>
          </ul>
        ))}
    </MessageContainer>
  </RotaModal>
);

export default DefaultError;

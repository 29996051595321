import React, { useState } from "react";
import { MenuItem, Select, FormControl, TextField } from "@material-ui/core";
import { countries } from "@teamrota/rota-common";

import getProfile from "~/src/graphql/queries/get-profile/get-profile-query.decorator";

import styles from "./account-tab-content.styles";
import Divider from "~/src/containers/modals/components/divider";

const prioritisedCountries = [
  ...countries.filter(({ priority }) => priority),
  ...countries
];

const AccountDetails = ({ state: account, dispatch }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOnChange = e => {
    dispatch({
      value: e?.target?.value,
      path: e?.target?.name
    });
  };

  return (
    <div>
      <Divider />
      <div style={styles.sectionTitle}>Account Details</div>
      <div id="form-row-0" style={styles.formRow}>
        <div id="form-column-0a" style={styles.formColumn}>
          <span style={styles.InputPlaceholder} id="input-contact-phone">
            Contact Phone
          </span>
          <TextField
            variant="outlined"
            type="text"
            name="account.contactPhone"
            value={account?.contactPhone}
            onChange={handleOnChange}
          />
        </div>

        <div id="form-column-0b" style={styles.formColumn}>
          <span id="input-contact-email" style={styles.InputPlaceholder}>
            Contact Email
          </span>
          <TextField
            variant="outlined"
            fullWidth
            name="account.contactEmail"
            value={account?.contactEmail}
            onChange={handleOnChange}
          />
        </div>
      </div>
      <div id="form-row-1" style={styles.formRow}>
        <div id="form-column-1" style={styles.formColumn}>
          <span style={styles.InputPlaceholder} id="input-displayName">
            Display Name
          </span>
          <TextField
            variant="outlined"
            type="text"
            name="account.accountName"
            value={account?.accountName}
            onChange={handleOnChange}
          />
        </div>

        <div id="form-column-2" style={styles.formColumn}>
          <span id="input-custom-identifier" style={styles.InputPlaceholder}>
            Custom Identifier
          </span>
          <TextField
            variant="outlined"
            fullWidth
            name="account.customIdentifier"
            value={account?.customIdentifier}
            onChange={handleOnChange}
          />
        </div>
      </div>
      <div id="form-row-2" style={styles.formRow}>
        <div id="form-column-3" style={styles.formColumn}>
          <span style={styles.InputPlaceholder} id="input-address-1">
            Address Line 1
          </span>
          <TextField
            variant="outlined"
            fullWidth
            name="account.postalAddress.houseNumber"
            value={account?.postalAddress?.houseNumber}
            onChange={handleOnChange}
          />
        </div>

        <div id="form-column-4" style={styles.formColumn}>
          <span style={styles.InputPlaceholder} id="input-address-2">
            Address Line 2
          </span>
          <TextField
            variant="outlined"
            fullWidth
            name="account.postalAddress.streetName"
            value={account?.postalAddress?.streetName}
            onChange={handleOnChange}
          />
        </div>
      </div>

      <div id="form-row-3" style={styles.formRow}>
        <div id="form-column-5" style={styles.formColumn}>
          <span style={styles.InputPlaceholder} id="input-town">
            Town/City
          </span>
          <TextField
            variant="outlined"
            fullWidth
            name="account.postalAddress.town"
            value={account?.postalAddress?.town || ""}
            onChange={handleOnChange}
          />
        </div>

        <div id="form-column-6" style={styles.formColumn}>
          <span style={styles.InputPlaceholder} id="input-county">
            County
          </span>
          <TextField
            variant="outlined"
            fullWidth
            name="account.postalAddress.county"
            value={account?.postalAddress?.county || ""}
            onChange={handleOnChange}
          />
        </div>
      </div>

      <div id="form-row-5" style={styles.formRow}>
        <div id="form-column-7" style={styles.formColumn}>
          <span style={styles.InputPlaceholder} id="input-country">
            Country
          </span>
          <FormControl>
            <Select
              variant="outlined"
              displayEmpty
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              inputProps={{ "aria-label": "Without label" }}
              name="account.postalAddress.country"
              value={account?.postalAddress?.country || ""}
              onChange={handleOnChange}
            >
              <MenuItem value="">
                <em>Please Select</em>
              </MenuItem>

              {prioritisedCountries.map(({ name, code, flag }, i) => (
                <MenuItem key={name + i} value={code}>
                  {flag} {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div id="form-column-8" style={styles.formColumn}>
          <span style={styles.InputPlaceholder} id="postcode">
            PostCode
          </span>
          <TextField
            variant="outlined"
            fullWidth
            name="account.postalAddress.postcode"
            value={account?.postalAddress?.postcode}
            onChange={handleOnChange}
          />
        </div>
      </div>
    </div>
  );
};
export default getProfile(AccountDetails);

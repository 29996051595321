import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

import { addAuthVars } from "@teamrota/authlib";

export const UPDATE_PAYROLL_RULE = addAuthVars(gql`
  mutation updatePayrollRule(
    $id: ID!
    $name: String!
    $config: [JSONObject]!
    $twilightStart: String
    $twilightEnd: String
  ) {
    updatePayrollRule(
      id: $id
      name: $name
      config: $config
      twilightStart: $twilightStart
      twilightEnd: $twilightEnd
    ) {
      id
      name
      config
      twilightStart
      twilightEnd
    }
  }
`);

export const useUpdatePayrollRule = () => {
  const [update, { loading, error }] = useMutation(UPDATE_PAYROLL_RULE, {
    onError: error => {
      throw error;
    }
  });
  const updatePayrollRule = async ({
    id,
    name,
    config,
    twilightStart,
    twilightEnd
  }: {
    id: string;
    name: string;
    config: JSON;
    twilightStart: string | null;
    twilightEnd: string | null;
  }) => {
    await update({
      variables: {
        id,
        name,
        config,
        twilightStart,
        twilightEnd
      },
      refetchQueries: ["payrollRules"]
    });
  };

  return { updatePayrollRule, loading, error };
};

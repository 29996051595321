import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";

interface Role {
  id: string;
  roleName: string;
  roleId: string;
}

const GET_ROLES = gql`
  query getRoles {
    user {
      id
      account {
        id

        allRoleRates {
          id
          roleName
          roleId
        }
      }
    }
  }
`;

const useRoles = () => {
  const { data: roleData } = useQuery(GET_ROLES);
  const roles: Role[] = roleData?.user?.account?.allRoleRates || [];

  return { roles: roles.map(role => ({ ...role, name: role.roleName })) };
};

export default useRoles;

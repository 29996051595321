import { STYLE_CONSTS, COLORS, flexCentered } from "~/src/styles/config.style";

import Icon from "~/src/components/icon";

import styled from "styled-components";

export const useStyles = () => ({
  root: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  icon: {
    borderRadius: 30,
    width: 16,
    height: 16,
    boxShadow: `inset 0 0 0 1px ${COLORS.MAIN_ORANGE}, inset 0 -1px 0 ${COLORS.MAIN_ORANGE}`,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: `2px auto ${COLORS.MAIN_ORANGE}`,
      outlineOffset: 2
    },
    "input:disabled ~ &": {
      background: "white",
      boxShadow: `inset 0 0 0 1px rgba(206,217,224,.5), inset 0 -1px 0 rgba(206,217,224,.5)`
    }
  },
  checkedIcon: {
    backgroundColor: `${COLORS.MAIN_ORANGE}`,
    borderColor: `${COLORS.MAIN_ORANGE}`,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      border: " rgba(206,217,224,.5)",
      background: "rgba(206,217,224,.5)"
    }
  },
  inputDialog: {
    "& .MuiFormLabel-root": {
      color: "#D8DCE3"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: " 1px solid #D8DCE3"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: " 1px solid #D8DCE3"
    }
  },
  input: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D8DCE3",
      marginLeft: "-5px"
    },
    "& .MuiFormLabel-root": {
      color: "#D8DCE3",
      fontFamily: "Gotham",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: " 1px solid #D8DCE3"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: " 1px solid #D8DCE3"
    }
  },
  btn: {
    borderRadius: "0",
    padding: "0",
    margin: "3px 0",
    "& .MuiIconButton-label": {
      alignItems: "normal",
      marginTop: "6px"
    },
    "&:hover": {
      backgroundColor: "#FFEACF",
      color: "rgba(255, 147, 0, 1)"
    },
    "&:active": {
      backgroundColor: "#FFEACF",
      color: "rgba(255, 147, 0, 1)"
    }
  },
  btnHidden: {
    display: "none"
  },
  svg: {
    "& .MuiIconButton-label": {
      pointerEvents: "none",
      color: "#AEB4BD",
      backgroundColor: "none"
    }
  },
  activeSvg: {
    "& .MuiIconButton-label": {
      pointerEvents: "none",
      color: "black"
    }
  },
  menuDot: {
    width: "177px",
    marginLeft: "-130px",
    marginTop: "40px",
    background: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, .25)",
    borderRadius: "10px"
  },
  bigMenuDot: {
    width: "177px",
    marginLeft: "-130px",
    marginTop: "100px",
    background: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, .25)",
    borderRadius: "10px"
  }
});

export const thStyle = index => {
  if (index !== 0) {
    return {
      ...styles.th,
      ...styles.thBorder,
      minWidth: "85px",
      textAlign: "center"
    };
  } else {
    return {
      ...styles.th,
      ...styles.thBorder,
      minWidth: "395px"
    };
  }
};

export const StyledButton = styled.button`
  background-color: ${({ save, theme }) =>
    save ? theme.primary.main : "transparent"};
  color: ${({ color, selected, theme }) =>
    selected ? theme.text.primary : color};
  width: 177px;
  height: 38px;
  font-weight: 500;
  font-size: 15px;
  text-align: ${({ action }) => (action ? "center" : "left")};
  padding: ${({ action }) => (action ? "0" : "0 0 0 13px")};
  text-transform: capitalize;
  border-radius: 20px;
  border: 1px solid;
  border-color: ${({ addNew, theme }) =>
    addNew ? theme.primary.main : "transparent"};
  margin: 10px 0;
  &:hover {
    color: ${({ action }) => (action ? "#ffffff" : "rgba(125, 131, 141, 1)")};
    background-color: ${({ action }) =>
      action ? "rgba(236, 136, 0, 1)" : "transparent"};
  }
  &:focus {
    outline: none;
  }
  &:active {
    background-color: ${({ addNew, theme }) =>
      addNew ? theme.primary.main : "transparent"};
    color: ${({ color, addNew, theme }) => (addNew ? theme.white : color)};
  }
`;

export const StyledIcon = styled(Icon)`
  vertical-align: middle;
`;

export const Styled_Tr = styled.tr`
  padding: 0 10;
  background-color: ${({ isSelect }) => (isSelect ? "#FF93001A" : "#fff")};
`;

export const StyledPermissionTypePanel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex-direction: column;
  width: 185px;
  height: 100%;
  margin: 32px 41px 0 0;
  padding: 0 17px 17px;
  color: ${({ theme }) => theme.text.primary};
  font-weight: ${STYLE_CONSTS.FONT_WEIGHT_MEDIUM};
`;

export const StyledTableWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 32px;
`;

export const StyledPermissionTypeTitle = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.neutral.main};
  width: 113px;
`;

export const StyledPermissionWrapperContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f4f4f4;
  width: 177px;
  margintop: 20px;
  border-radius: 10px;
`;

export const StyledPermissionsWrapButton = styled.div`
  display: flex;
  flex-direction: column;
  width: 177px;
`;

export const StyledTypesWrapButton = styled.div`
  display: flex;
  flex-direction: row;
  width: 177px;
`;

export const StyledTable = styled.table`
  border-collapse: collapse;
  border-spacing: 5px 10px;
  margin-top: 32px;
`;

export const StyledTdHeaders = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 19.2px;
  letter-spacing: -0.25px;
  color: #939ba7;
`;

export const StyledTH = styled.th`
  height: 58px;
  border: solid #d3d3d3;
  border-width: 0 1px 0 0;
`;

export const StyledSpanTitle = styled.span`
  margin-left: 15px;
`;

export const StyledTextField = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledTextFieldSpan = styled.span`
  margin: 15px 0;
`;

export const StyledTableTr = styled.tr`
  border-right: #d3d3d3 1px solid;
  background-color: ${({ isSelect }) => (isSelect ? "#FF93001A" : "#fff")};
`;
export const StyledFieldTd = styled.td`
  font-weight: 500;
  border-right: #d3d3d3 1px solid;
  color: rgba(81, 81, 81, 1);
  size: 16px;
  line-height: 19.2px;
  letter-spacing: -0.25px;
  height: 60px;
`;
export const StyledDefaultTd = styled.td`
  border: solid #d3d3d3;
  border-width: 0 1px 0 0;
`;

export const StyledTdType = styled.td`
  text-align: left;
`;

export const StyledChevronName = styled.span`
  margin-left: 16px;
`;
export const StyledName = styled.span`
  margin-left: 35px;
`;
export const StyledTdRead = styled.td`
  text-align: center;
  border-left: 1px solid #d3d3d3;
  border-right: 1px solid #d3d3d3;
`;
export const StyledTdUpdated = styled.td`
  text-align: center;
  border-left: 1px solid #d3d3d3;
  border-right: 1px solid #d3d3d3;
`;
export const StyledTdCreated = styled.td`
  text-align: center;
  border-right: #d3d3d3 1px solid;
`;

const styles = {
  avatarWrapper: {
    ...flexCentered,
    flexDirection: "column",
    margin: "43px 5px 30px 5px"
  },
  button: {
    backgroundColor: `${COLORS.MAIN_ORANGE}`,
    color: "white",
    width: "174px",
    height: "40px",
    borderRadius: "20px"
  },
  inputWrapper: {
    margin: "21px 28px 0 0",
    height: "29px"
  },
  marginRuler: {
    padding: "0 10px"
  },
  memberName: {
    ...flexCentered,
    padding: "10px 0 0 0",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "13px",
    textAlign: "center",
    letterSpacing: "-0.248889px"
  },
  memberAddress: {
    ...flexCentered,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "15px",
    textAlign: "center",
    letterSpacing: "-0.248889px",
    color: "#A9ACB3"
  },
  iconButton: {
    width: "5px",
    backgroundColor: "transparent",
    color: "#AEB4BD"
  },

  permissionsButton: {
    color: "black",
    fontWeight: 500,
    fontSize: "16px",
    textAlign: "left",
    textTransform: "capitalize"
  },

  customizedMenuWrap: {
    width: "177px",
    background: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    alignSelf: "center"
  },

  customizedMenu: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    fontSize: "14px",
    fontWeight: "500",
    width: "167px",
    height: "33px",
    paddingLeft: "23px",
    borderRadius: "5px",
    marginLeft: "5px"
  },

  rightBorder: {
    border: "solid #D3D3D3",
    borderWidth: "0 1px 0 1px"
  },
  requestRow: {
    fontWeight: STYLE_CONSTS.FONT_WEIGHT_MEDIUM,
    fontSize: "18px",
    color: "#515151",
    padding: "28px 0 28px 10px",
    border: "solid #D3D3D3",
    borderWidth: "0 1px 0 0"
  },
  selectAll: {
    padding: "30px 10px ",
    minWidth: "215px",
    fontWeight: STYLE_CONSTS.FONT_WEIGHT_MEDIUM,
    color: "#595F68",
    backgroundColor: "#F9F9F9",
    border: "solid #D3D3D3",
    borderWidth: "0 1px 0 0"
  },
  td: {
    padding: "15px 10px",
    color: COLORS.COOL_GREY
  },
  tdBorder: {
    border: "solid #D3D3D3",
    borderWidth: "0 1px 0 0"
  },
  tdCheckbox: {
    padding: "15px 5px",
    color: COLORS.COOL_GREY,
    textAlign: "center",
    border: "solid #D3D3D3",
    borderWidth: "0 1px 0 1px"
  },
  tdGrayCheckbox: {
    padding: "15px 10px",
    color: COLORS.COOL_GREY,
    textAlign: "center",
    backgroundColor: "#F9F9F9",
    border: "solid #D3D3D3",
    borderWidth: "0 1px 0 0px"
  },
  th: {
    minWidth: "8px",
    textAlign: "left",
    height: "30px"
  },
  thBorder: {
    border: "solid #D3D3D3",
    borderWidth: "0 1px 0 0"
  },
  textInput: {
    padding: "0 10px"
  }
};

export default styles;

import styled from "styled-components";

export const HalfWidthFloatingTable = styled.div`
  margin: 0 40px;
  width: 60%;
  align-self: center;
`;

export const TablesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

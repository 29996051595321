import { useEffect, useState } from "react";
import { orderBy } from "lodash";
import { gql, useQuery } from "@apollo/client";

export const GET_MEMBERS = gql`
  query getMembers($limit: Int!, $offset: Int!, $searchName: String) {
    account {
      members(
        limit: $limit
        offset: $offset
        searchName: $searchName
        orderByPropAsc: "firstName"
        stateIn: [LOCKED, ACTIVE, PENDING, ASLEEP]
      ) {
        data {
          firstName
          lastName
          id
        }
        offset
        limit
        totalResults
      }
    }
  }
`;

export const useGetMembers = venueId => {
  const [members, setMembers] = useState([]);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { data, loading, fetchMore, refetch } = useQuery(GET_MEMBERS, {
    variables: { limit: 50, offset: 0 },
    skip: !venueId
  });

  const totalResults = data?.account?.members?.totalResults;

  useEffect(() => {
    setMembers(data?.account?.members?.data || []);
  }, [data]);

  useEffect(() => {
    if (offset === 0) return;

    if (!loading && !isLoading && offset < totalResults) {
      setIsLoading(true);

      fetchMore({
        variables: { offset },
        updateQuery: (_, { fetchMoreResult }) => {
          setMembers(members => {
            const allMembers = [
              ...members,
              ...(fetchMoreResult?.account?.members?.data || [])
            ];

            return orderBy(allMembers, "firstName");
          });
        }
      });

      setIsLoading(false);
    }
  }, [offset]);

  const onLoadMore = () => {
    setOffset(offset => offset + 100);
  };

  const onFilter = searchName => {
    setOffset(0);
    refetch({ searchName });
  };

  return {
    members,
    isLoading: loading && isLoading,
    onLoadMore,
    onFilter
  };
};

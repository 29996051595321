import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import { Role, Venue } from "../types";

const GET_PARTNER_DETAILS = gql`
  query getPartnerDetails($partnerId: ID!) {
    account {
      id
      targetAccount(accountId: $partnerId) {
        id

        roleRates(accountType: requester) {
          roleName
          roleId
        }

        venues {
          id
          name
        }
      }
    }
  }
`;

const usePartnerDetails = ({ partnerId }: { partnerId: string | null }) => {
  const { data, loading } = useQuery(GET_PARTNER_DETAILS, {
    variables: { partnerId },
    skip: !partnerId
  });
  const roles: Role[] =
    data?.account?.targetAccount?.roleRates.map((role: any) => ({
      id: role.roleId,
      name: role.roleName
    })) || [];
  const venues: Venue[] = data?.account?.targetAccount?.venues || [];

  return {
    roles,
    venues,
    loading
  };
};

export default usePartnerDetails;

import React, { useEffect, useState } from "react";
import { gql } from "@apollo/client";
import { withApollo } from "@apollo/client/react/hoc";
import { ZonedDate } from "@teamrota/rota-common";

import {
  formatDateMonth,
  formatDateMonthYear,
  formatDateFull
} from "~/src/utils/formatting";
import sendInviteEmail from "~/src/graphql/mutations/send-invite-email";
import Title from "../../components/title";
import { Note, Link, SentMessage, ResendEmail } from "./index.styles";

const DetailsComponent = ({ client, member, sendInviteEmail }) => {
  const [inviteCode, setInviteCode] = useState(null);
  const [inviteEmailSent, setInviteEmailSent] = useState(false);
  const [savedMemberId, setSavedMemberId] = useState(null);

  const GET_MEMBER = gql`
    query getMember($memberId: ID!) {
      account {
        id
        member(id: $memberId) {
          id
          inviteCode
        }
      }
    }
  `;

  useEffect(() => {
    let timeout = null;

    const refetchInviteCode = async () => {
      if (savedMemberId) {
        const { data } = await client.query({
          query: GET_MEMBER,
          variables: { memberId: savedMemberId },
          fetchPolicy: "network-only"
        });

        const inviteCode = data?.account?.member?.inviteCode;
        if (inviteCode) {
          setInviteCode(inviteCode);
        }
        if (!inviteCode) {
          timeout = setTimeout(refetchInviteCode, 2000);
        }
      }
    };

    if (member?.inviteCode) {
      setInviteCode(member?.inviteCode);
    } else {
      if (!savedMemberId) {
        return;
      }
      refetchInviteCode();
    }

    return () => clearTimeout(timeout);
  }, [savedMemberId]);

  if (member?.id && !savedMemberId) {
    setSavedMemberId(member.id);
  }

  const isTodayBirthday = () => {
    const today = formatDateMonth(new ZonedDate());
    const birthday = formatDateMonth(member?.birthDate);
    return today === birthday;
  };

  const handleSendInviteEmail = () => {
    if (!inviteEmailSent && member?.id) {
      sendInviteEmail(member.id);
      setInviteEmailSent(true);
    }
  };

  const isBirthday = isTodayBirthday();
  const lastSeen = member?.lastSeenAt
    ? formatDateFull(member.lastSeenAt)
    : "never";

  return (
    <div>
      <Title>Other</Title>
      <Note style={{ color: "black" }}>
        Right to work expires on:{" "}
        {formatDateMonthYear(member?.rightToWorkExpiresAt)}
      </Note>
      <Note>Start date: {formatDateMonthYear(member?.startDate)}</Note>
      {isBirthday && <Note>Today is their birthday! 🎂</Note>}
      {!isBirthday && (
        <Note>Birth date: {formatDateMonthYear(member?.birthDate)}</Note>
      )}
      <Note>Last seen: {lastSeen}</Note>
      {!member?.isSignedUp && (
        <ResendEmail>
          <Note marginTop>Not yet registered on the app</Note>
          <Link onClick={handleSendInviteEmail} isDisabled={inviteEmailSent}>
            Resend verification email
          </Link>
          {inviteEmailSent ? <SentMessage>Sent!</SentMessage> : null}
        </ResendEmail>
      )}
      <Note marginTop>
        Push notification status:{" "}
        {member?.isPushNotificationsEnabled ? "ON" : "OFF"}
      </Note>
      <Title marginTop>Invite Code</Title>
      {inviteCode && <Note uppercase>{inviteCode}</Note>}
      {!inviteCode && <Note>No invite code</Note>}
    </div>
  );
};

export default sendInviteEmail(withApollo(DetailsComponent));

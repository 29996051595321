import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { handleErrors } from "~/src/utils/errors";

const GET_MEMBER_STATS = gql`
  query getMemberStats {
    account {
      id

      memberStats {
        activeMembersCount
      }

      membersCount
    }
  }
`;

export const getQueryArgs = () => {
  return {
    props: handleErrors(({ data: { loading, account } }) => ({
      isMembersCountLoading: loading,
      activeMembersCount: account?.memberStats?.activeMembersCount,
      membersCount: account?.membersCount
    })),
    options(props) {
      return {
        notifyOnNetworkStatusChange: true
      };
    }
  };
};

export default graphql(GET_MEMBER_STATS, getQueryArgs());

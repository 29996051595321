import styled from "styled-components";
import SearchInput from "~/src/components/form-components/search-input";
import Button from "~/src/components/button";
import { STYLE_CONSTS, COLORS } from "~/src/styles/config.style";

const styles = {
  block: {
    display: "block"
  },
  groupedButton: {
    display: "inline-block",
    margin: 5
  }
};

export default styles;

export const StyledSearchInput = styled(SearchInput)`
  margin-right: ${STYLE_CONSTS.UNIT_3};
  width: 378px;
`;

export const SortedBy = styled.span`
  font-size: ${STYLE_CONSTS.FONT_SMALL};
  color: ${COLORS.COOL_GREY};
  margin-right: ${STYLE_CONSTS.UNIT_1};
`;

export const FilterTag = styled(Button)`
  margin-right: ${STYLE_CONSTS.UNIT_1};
  margin-top: ${STYLE_CONSTS.UNIT_1};
`;

import { gql } from "@apollo/client";

export const DELETE_BONUS = gql`
  mutation deleteBonus($shiftId: ID, $id: ID) {
    deleteBonus(shiftId: $shiftId, id: $id) {
      shiftId
      bonuses {
        id
        amount
        period
        type
        reason
        createdAt
        deletedAt
        createdBy
        deletedBy
        __typename
      }
      __typename
    }
  }
`;

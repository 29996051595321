import styled from "styled-components";
import { MAIN_COLUMN_WIDTH } from "../utils";
import { RotaAvatar } from "@teamrota/rota-design";

export const StyledAvatarCardContainer = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  min-width: ${MAIN_COLUMN_WIDTH};
  max-width: ${MAIN_COLUMN_WIDTH};
  max-height: 60px;
  background: ${({ theme }) => theme.white};
  border: solid 0.5px ${({ theme }) => theme.background.primary};
`;

export const StyledAvatarCard = styled.div`
  padding: 5px;
`;

export const StyledAvatar = styled(RotaAvatar)`
  margin-right: 5px;
`;

export const StyledAvatarCardText = styled.div`
  overflow: hidden;
  width: auto;
  font-weight: 500;
  font-size: 12px;
  max-height: 30px;
`;

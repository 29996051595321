import { gql, useQuery } from "@apollo/client";
import adminClient from "./admin-client";

const GET_NEW_TIMESHEETS_START_DATE = gql`
  query getNewTimesheetsStartDate($accountId: ID!) {
    newTimesheets(accountId: $accountId) {
      newTimesheetsStartDate
    }
  }
`;

export const useGetNewTimesheetsStartDate = accountId => {
  const { data, loading } = useQuery(GET_NEW_TIMESHEETS_START_DATE, {
    variables: { accountId },
    skip: !accountId,
    client: adminClient,
    fetchPolicy: "network-only"
  });

  return {
    newTimesheetsStartDate: data?.newTimesheets?.newTimesheetsStartDate,
    loading
  };
};

import React, { useState } from "react";

import { IconButton, Badge, Avatar } from "@material-ui/core";
import createUpdateUser from "~/src/graphql/mutations/create-update-user";
import { RotaAvatar } from "@teamrota/rota-design";
import uploadFile from "~/src/services/upload-file";
import { DOCUMENT_TYPES } from "~/src/consts";
import { LoadingImage } from "~/src/components/document-image";
import { errorModal } from "~/src/utils/errors";

import styles, { StyledIcon } from "./account-tab-content.styles";
import AccountTabForm from "~/src/containers/settings-page/right-panel/account-tab-content/account-tab-form";

export const INPUT_IDS = {
  [DOCUMENT_TYPES.MEMBER_PHOTO]: "file-input-member-photo",
  [DOCUMENT_TYPES.USER_PHOTO]: "file-input-user-photo",
  [DOCUMENT_TYPES.ADDITIONAL_MEMBER_DOCUMENT]:
    "file-input-additional-member-document",
  [DOCUMENT_TYPES.RIGHT_TO_WORK]: "file-input-right-to-work",
  [DOCUMENT_TYPES.ACCOUNT_LOGO]: "file-input-account-logo",
  [DOCUMENT_TYPES.ACCOUNT_BANNER]: "file-input-account-banner"
};

const AccountTabContent = ({ user, refetchProfile }) => {
  const { account } = user;

  const [saving, setSaving] = useState(undefined);

  const handleUploadFile = async (type, id) => {
    setSaving(type);

    try {
      const selectedFile = document.getElementById(id).files[0];

      await uploadFile({
        file: selectedFile,
        type,
        id: account.id,
        table: "accounts"
      });

      await refetchProfile();
    } catch (e) {
      errorModal(e);
    } finally {
      setSaving(undefined);
    }
  };

  return (
    <div id="tab-content">
      <div id="images" style={styles.sectionTitle}>
        Images
      </div>

      <div id="photo-row" style={styles.photoRow}>
        <div id="left-container" style={styles.leftContainer}>
          <span id="imageTitle" style={styles.imageTitle}>
            Logo
          </span>
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            badgeContent={
              <>
                <input
                  accept="image/*"
                  style={styles.input}
                  type="file"
                  name="file"
                  id={INPUT_IDS[DOCUMENT_TYPES.ACCOUNT_LOGO]}
                  onChange={() =>
                    handleUploadFile(
                      DOCUMENT_TYPES.ACCOUNT_LOGO,
                      INPUT_IDS[DOCUMENT_TYPES.ACCOUNT_LOGO]
                    )
                  }
                />
                <label htmlFor={INPUT_IDS[DOCUMENT_TYPES.ACCOUNT_LOGO]}>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <span style={styles.iconWrapper}>
                      <StyledIcon name="CAMERA" size="MEDIUM" color="white" />
                    </span>
                  </IconButton>
                </label>
              </>
            }
          >
            {saving === DOCUMENT_TYPES.ACCOUNT_LOGO && <LoadingImage />}
            <RotaAvatar alt="company logo" src={account?.logo} size="large" />
          </Badge>
        </div>
        <div
          id="banner"
          style={{
            display: "flex",
            flexDirection: "column"
          }}
        >
          <span style={styles.imageTitle}>Banner Photo</span>
          <Badge
            overlap="rectangular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            badgeContent={
              <>
                <input
                  accept="image/*"
                  style={styles.input}
                  type="file"
                  name="file"
                  id={INPUT_IDS[DOCUMENT_TYPES.ACCOUNT_BANNER]}
                  onChange={() =>
                    handleUploadFile(
                      DOCUMENT_TYPES.ACCOUNT_BANNER,
                      INPUT_IDS[DOCUMENT_TYPES.ACCOUNT_BANNER]
                    )
                  }
                />
                <label htmlFor={INPUT_IDS[DOCUMENT_TYPES.ACCOUNT_BANNER]}>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <span style={styles.iconWrapper}>
                      <StyledIcon name="CAMERA" size="MEDIUM" color="white" />
                    </span>
                  </IconButton>
                </label>
              </>
            }
          >
            {saving === DOCUMENT_TYPES.ACCOUNT_BANNER && <LoadingImage />}
            <Avatar
              variant="rounded"
              alt="Travis Howard"
              src={account?.banner}
              style={{ width: "394px", height: "123px" }}
            />
          </Badge>
        </div>
      </div>
      <AccountTabForm />
    </div>
  );
};

export default createUpdateUser(AccountTabContent);

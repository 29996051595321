import styled from "styled-components";

import { STYLE_CONSTS } from "~/src/styles/config.style";

export const backgroundColor = "rgba(255, 163, 28)";

export const Container = styled.div`
  width: 580px;
  height: 480px;
  padding: ${STYLE_CONSTS.UNIT_2};
  background-color: ${backgroundColor};
`;

export const Header = styled.h2`
  font-size: 180%;
  color: white;
  margin-top: 0;
  text-align: center;
`;

export const Text = styled.p`
  font-size: 120%;
  text-align: center;
  color: black;
`;

export const Image = styled.img`
  display: block;
  margin: auto;
  width: 435px;
  height: 287px;
`;

export const ContactLink = styled.a`
  color: white !important;
`;

export const ButtonContainer = styled.div`
  text-align: center;
  padding: ${STYLE_CONSTS.UNIT_1} 0;
`;

export const MessageContainer = styled.div`
  margin: ${STYLE_CONSTS.UNIT_2};
  text-align: center;
`;

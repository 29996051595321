import React from "react";
import { GridHourCol, GridHourColItem, GridHourColItemInner } from "./styles";

const HourColumn = ({ pixelsPerMinute }) => (
  <GridHourCol>
    {Array(25)
      .fill(null)
      .map((_, hourIndex) => (
        <GridHourColItem
          key={hourIndex}
          style={{ height: `${pixelsPerMinute * 60}px` }}
        >
          <GridHourColItemInner>
            {getHourString(hourIndex)}
          </GridHourColItemInner>
        </GridHourColItem>
      ))}
  </GridHourCol>
);

const getHourString = hour =>
  hour.toLocaleString("en", {
    minimumIntegerDigits: 2,
    useGrouping: false
  }) + ":00";

export default React.memo(HourColumn);

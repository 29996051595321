import styled from "styled-components";

export const RoleTabs = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: solid 1px ${({ theme }) => theme.neutral.lighter};
  margin-bottom: 32px;
`;

export const RoleTabsTitle = styled.p`
  margin-right: 16px;
  font-size: 14px;
  font-weight: 400;
`;

export const HeadingWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 180px;
  gap: 42px;
  align-items: center;
  margin-bottom: 36px;
`;
export const HeadingText = styled.div`
  font-size: 16px;
  & > span {
    font-weight: 500;
  }
`;

export const DownloadButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DownloadButtonText = styled.p`
  color: ${({ theme }) => theme.neutral.main};
  font-size: 12px;
  text-align: center;
  & > span {
    font-weight: 700;
  }
`;

export const AddedFiles = styled.p`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 12px;
`;

import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

import { addAuthVars } from "@teamrota/authlib";

export const CREATE_PAYROLL_RATE_CARDS = addAuthVars(gql`
  mutation createPayrollRateCards(
    $partnerAccountId: ID!
    $roleIds: [ID!]!
    $venueIds: [ID]
    $payrollRateId: ID!
    $payrollScaleId: ID!
    $payrollCalculationId: ID!
    $payrollRuleId: ID!
    $allVenues: Boolean
    $effectiveFrom: Date
  ) {
    createPayrollRateCards(
      partnerAccountId: $partnerAccountId
      roleIds: $roleIds
      venueIds: $venueIds
      payrollRateId: $payrollRateId
      payrollScaleId: $payrollScaleId
      payrollCalculationId: $payrollCalculationId
      payrollRuleId: $payrollRuleId
      allVenues: $allVenues
      effectiveFrom: $effectiveFrom
    ) {
      id
      account {
        id
      }
      partnerAccount {
        id
      }
      role {
        id
      }
      venue {
        id
      }
      payrollRate {
        id
      }
      payrollCalculation {
        id
      }
      payrollScale {
        id
      }
      payrollRule {
        id
      }
      allVenues
      effectiveFrom
      createdAt
      updatedAt
      updatedBy {
        id
      }
    }
  }
`);

export const useCreateRateCards = () => {
  const [create, { loading, error }] = useMutation(CREATE_PAYROLL_RATE_CARDS, {
    onError: error => {
      throw error;
    }
  });

  const createPayrollRateCards = async ({
    partnerAccountId,
    roleIds,
    venueIds,
    payrollRateId,
    payrollScaleId,
    payrollCalculationId,
    payrollRuleId,
    allVenues,
    effectiveFrom
  }: {
    partnerAccountId: string;
    roleIds: string[];
    venueIds?: string[];
    payrollRateId: string;
    payrollScaleId: string;
    payrollCalculationId: string;
    payrollRuleId: string;
    allVenues?: boolean;
    effectiveFrom?: Date;
  }) => {
    await create({
      variables: {
        partnerAccountId,
        roleIds,
        venueIds,
        payrollRateId,
        payrollScaleId,
        payrollCalculationId,
        payrollRuleId,
        allVenues,
        effectiveFrom
      },
      refetchQueries: ["payrollRateCards"]
    });
  };

  return { createPayrollRateCards, loading, error };
};

import React, { useEffect, useState } from "react";
import { debounce } from "lodash";

import { Role } from "@teamrota/authlib";
import { RotaSnackBar } from "@teamrota/rota-design";

import useAuth from "~/src/auth/hooks/use-auth";

import { useUserGroups, useMemberPools } from "./graphql/hooks";

import GroupsZone from "./groups";
import RequestZone from "./request";
import AssignZone from "./assign";

import { buildStaffGroupsMap, RECOMMENDED_ID } from "./utils";

import * as messages from "./messages";
import MemberAddWarningModal from "./components/MemberAddWarningModal";
import { POOL_TYPES } from "@teamrota/rota-common";
import { StyledWrapText } from "./styles";
import BookingReplacementModal from "~/src/components/BookingReplacementModal/index.js";
import { useStore } from "~/src/useStore";

const StaffZonesPanel = ({
  shiftId,
  isShiftCancelled,
  targetAccountId,
  selectedAccountId,
  roleRateId,
  memberType,
  venueId,
  dates,
  shiftType,
  maxAssigned = 0,
  acceptedBookings = [],
  requestedBookings = [],
  declinedBookings = [],
  requestedMemberIds: initialRequestedMemberIds = [],
  isRequestAll = false,
  isIncludeUnavailable = false,
  onRequest,
  assignedMemberIds: initialAssignedMemberIds = [],
  onAssign,
  onUnassign,
  isProvide = false,
  tags,
  isDraftShift,
  hasShiftUnfilledCancelledBookings,
  totalNumberOfCancelledBookings
}) => {
  const auth = useAuth();
  const canAssignStaff = auth.hasRole(Role.PROVIDER);
  const [requestedMemberIds, setRequestedMemberIds] = useState(
    initialRequestedMemberIds
  );

  useEffect(() => {
    onRequest(requestedMemberIds);
  }, [requestedMemberIds]);

  const [assignedMemberIds, setAssignedMemberIds] = useState(
    initialAssignedMemberIds
  );

  useEffect(() => {
    onAssign(assignedMemberIds);
  }, [assignedMemberIds]);

  const [unassignedMemberIds, setUnassignedMemberIds] = useState([]);

  useEffect(() => {
    isDraftShift && onUnassign(unassignedMemberIds);
  }, [unassignedMemberIds]);

  const [snackMessage, setSnackMessage] = useState("");

  useEffect(() => {
    if (snackMessage !== "") {
      const timer = setTimeout(() => setSnackMessage(""), 5000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [snackMessage]);

  const [dragWarning, setDragWarning] = useState(null);
  const [canDropMember, setCanDropMember] = useState(true);

  const [searchText, _setSearchText] = useState("");
  const setSearchText = debounce(_setSearchText, 300);

  const [isShowCancellationModal, setIsShowCancellationModal] = useState(false);
  const [droppedMemberId, setDroppedMemberId] = useState(null);

  const replacementBookings = useStore(state => state.replacementBookings);
  const setReplacementBookings = useStore(
    state => state.setReplacementBookings
  );

  useEffect(() => {
    return () => {
      setReplacementBookings([]);
    };
  }, []);

  const tagIds = tags ? tags.map(({ id }) => id) : [];

  const recommendedHook = useMemberPools(
    shiftId,
    targetAccountId,
    selectedAccountId !== false ? selectedAccountId : null,
    roleRateId,
    memberType,
    venueId,
    dates,
    shiftType,
    isRequestAll ? "" : searchText, // ignore text search when Request All
    tagIds
  );

  const userGroupsHook = useUserGroups(
    shiftId,
    isProvide,
    targetAccountId,
    roleRateId,
    dates,
    shiftType,
    venueId,
    selectedAccountId !== false ? selectedAccountId : null,
    tagIds
  );

  // build a map of all members

  const allMembers = new Map();

  // accepted members are locked in, declined/pending can be assigned

  const acceptedMemberIds = acceptedBookings.map(({ member }) => member.id);
  acceptedBookings.forEach(({ member }) => allMembers.set(member.id, member));

  const declinedMemberIds = declinedBookings.map(({ member }) => member.id);
  declinedBookings.forEach(({ member }) => allMembers.set(member.id, member));

  const pendingMemberIds = requestedBookings.map(({ member }) => member.id);
  requestedBookings.forEach(({ member }) => allMembers.set(member.id, member));

  // any member in accepted/requested/assigned/declined is filtered out from
  // recommended & groups

  const usedMemberIds = [
    ...acceptedMemberIds,
    ...pendingMemberIds,
    ...declinedMemberIds,
    ...assignedMemberIds,
    ...requestedMemberIds
  ].filter(memberId => !unassignedMemberIds.includes(memberId));

  // build the groups for display - this will be null when loading

  const staffGroups = buildStaffGroupsMap(
    recommendedHook,
    userGroupsHook,
    allMembers,
    usedMemberIds
  );

  // sourceAccountId should only be passed in Request/Schedule scenarios
  // it indicates which Provider the desired members are being sourced from
  const sourceAccountId = (!isProvide && targetAccountId) || undefined;

  // turn lists of ids into lists of members
  // when recommended/groups are loading these might 'miss' so filter undefined

  const toMembers = memberIds =>
    memberIds.map(id => {
      if (allMembers.has(id)) return allMembers.get(id);
      else {
        // some, e.g. blacklisted members will not be in the map
        // but can still be assigned by scheduler, so have to load lazy
        return {
          lazy: true,
          shiftId,
          shiftType,
          sourceAccountId,
          id,
          dates,
          roleRateId
        };
      }
    });

  const acceptedMembers = toMembers(acceptedMemberIds);
  const assignedMembers = toMembers(assignedMemberIds);
  const requestedMembers = toMembers(requestedMemberIds);
  const pendingMembers = toMembers(pendingMemberIds);
  const declinedMembers = toMembers(declinedMemberIds);

  const hasFreeSpace =
    assignedMembers.length + acceptedMembers.length < maxAssigned;

  // event handlers

  const confirmMemberDrag = (id, callback) => {
    let member = [];
    if (typeof id === "object") {
      for (let i = 0; i < id.length; i++) {
        member = [...member, allMembers.get(id[i])];
      }
    } else {
      member = allMembers.get(id);
    }

    if (member) {
      if (
        member.isHitWorkingHoursLimit ||
        member.isConflicted ||
        member.isLocationConflict ||
        member.isUnavailable ||
        !member.isQualifiedWithRole ||
        !member.isQualifiedWithTags ||
        !member.isInVenueServiceArea ||
        member.relationship?.poolType === POOL_TYPES.BLACKLISTED
      ) {
        setDragWarning({ member, callback });
      } else {
        callback();
      }
    } else {
      callback(); // some other type of add, e.g. "All Available"
    }
  };

  const handleRequestHover = id => {
    if (isRequestAll) {
      setCanDropMember(false);
      setSnackMessage(messages.REQUEST_ALL);
    } else if (acceptedMemberIds.includes(id)) {
      setCanDropMember(false);
      setSnackMessage(messages.NO_REQUEST_ASSIGNED);
    } else if (
      !acceptedMemberIds.includes(id) &&
      !pendingMemberIds.includes(id) &&
      !declinedMemberIds.includes(id) &&
      !assignedMemberIds.includes(id) &&
      !hasFreeSpace
    ) {
      setCanDropMember(false);
      setSnackMessage(messages.REQUEST_FULL);
    } else if (isShiftCancelled) {
      setCanDropMember(false);
      setSnackMessage(messages.SHIFT_CANCELLED);
    } else {
      setCanDropMember(true);
    }
  };

  const handleRequest = ({ id }) => {
    if (isShiftCancelled) {
      setSnackMessage(messages.SHIFT_CANCELLED);
      return;
    }
    confirmMemberDrag(id, () => {
      if (assignedMemberIds.includes(id))
        setAssignedMemberIds(assignedMemberIds.filter(nextId => nextId !== id));
      if (unassignedMemberIds.includes(id))
        setUnassignedMemberIds(
          unassignedMemberIds.filter(nextId => nextId !== id)
        );

      if (
        !acceptedMemberIds.includes(id) &&
        !pendingMemberIds.includes(id) &&
        !declinedMemberIds.includes(id) &&
        (hasFreeSpace || assignedMemberIds.includes(id))
      ) {
        setRequestedMemberIds([
          ...new Set([
            ...requestedMemberIds,
            ...(Array.isArray(id) ? id : [id])
          ])
        ]);
      }
    });
  };

  const handleRequestedRemoved = id => {
    if (requestedMemberIds.includes(id))
      setRequestedMemberIds(requestedMemberIds.filter(nextId => nextId !== id));
  };

  const handleAssignHover = id => {
    const isArray = Array.isArray(id);
    const canAssign =
      assignedMembers.length +
        acceptedMembers.length +
        (isArray ? id.length : 1) <=
      maxAssigned;

    if (
      !acceptedMemberIds.includes(id) &&
      !assignedMemberIds.includes(id) &&
      !hasFreeSpace &&
      !canAssign
    ) {
      setCanDropMember(false);
      setSnackMessage(messages.SHIFT_FULL);
    } else if (isShiftCancelled) {
      setCanDropMember(false);
      setSnackMessage(messages.SHIFT_CANCELLED);
    } else {
      setCanDropMember(true);
    }
  };

  const handleConfirmCancellationReplacement = ({ cancelledBookingId }) => {
    setReplacementBookings([
      ...replacementBookings,
      {
        cancelledBookingId,
        memberId: droppedMemberId
      }
    ]);
    handleMemberAssignDrop(droppedMemberId);

    setIsShowCancellationModal(false);
    setDroppedMemberId(null);
  };

  const handleAssign = ({ id }) => {
    const isArray = Array.isArray(id);
    const canAssign =
      assignedMembers.length +
        acceptedMembers.length +
        (isArray ? id.length : 1) <=
      maxAssigned;

    if (!canAssign) {
      setSnackMessage(messages.SHIFT_FULL);
      return;
    }
    if (isShiftCancelled) {
      setSnackMessage(messages.SHIFT_CANCELLED);
      return;
    }

    confirmMemberDrag(id, () => checkShiftCancellations(id));
  };

  const checkShiftCancellations = id => {
    const hasNotAssignedAllCancelledBookings =
      hasShiftUnfilledCancelledBookings &&
      replacementBookings.length < totalNumberOfCancelledBookings;

    if (hasNotAssignedAllCancelledBookings) {
      setIsShowCancellationModal(true);
      setDroppedMemberId(id);
    } else {
      handleMemberAssignDrop(id);
    }
  };

  const handleMemberAssignDrop = id => {
    if (requestedMemberIds.includes(id))
      setRequestedMemberIds(requestedMemberIds.filter(nextId => nextId !== id));

    if (unassignedMemberIds.includes(id))
      setUnassignedMemberIds(
        unassignedMemberIds.filter(nextId => nextId !== id)
      );

    if (!acceptedMemberIds.includes(id) && hasFreeSpace) {
      setAssignedMemberIds([
        ...new Set([...assignedMemberIds, ...(Array.isArray(id) ? id : [id])])
      ]);
    }
  };

  const handleAssignedRemoved = id => {
    if (assignedMemberIds.includes(id)) {
      setAssignedMemberIds(assignedMemberIds.filter(nextId => nextId !== id));

      const newReplacementBookings = replacementBookings.filter(
        ({ memberId }) => memberId !== id
      );
      setReplacementBookings(newReplacementBookings);
    }
  };

  const handleStaffHover = id => {
    if (acceptedMemberIds.includes(id) && !isDraftShift) {
      setCanDropMember(false);
      setSnackMessage(messages.NO_REMOVE_ACCEPTED);
    } else if (
      declinedMemberIds.includes(id) &&
      !assignedMemberIds.includes(id)
    ) {
      setCanDropMember(false);
      setSnackMessage(messages.NO_REMOVE_DECLINED);
    } else if (
      pendingMemberIds.includes(id) &&
      !assignedMemberIds.includes(id) &&
      !isDraftShift
    ) {
      setCanDropMember(false);
      setSnackMessage(messages.NO_REMOVE_PENDING);
    } else {
      setCanDropMember(true);
    }
  };

  const handleStaffDrop = ({ id }) => {
    if (assignedMemberIds.includes(id))
      setAssignedMemberIds(assignedMemberIds.filter(nextId => nextId !== id));

    if (requestedMemberIds.includes(id))
      setRequestedMemberIds(requestedMemberIds.filter(nextId => nextId !== id));

    const isAlreadyAssignedOrRequested =
      acceptedMemberIds.includes(id) || pendingMemberIds.includes(id);

    if (isDraftShift && isAlreadyAssignedOrRequested) {
      setUnassignedMemberIds([...new Set([...unassignedMemberIds, id])]);
    }
  };

  const handleDragEnd = () => setCanDropMember(true);

  return (
    <>
      <GroupsZone
        isLoading={recommendedHook.loading || userGroupsHook.loading}
        staffGroups={staffGroups}
        onSearch={searchText => setSearchText(searchText)}
        canDragDrop={canDropMember}
        onDragHover={handleStaffHover}
        onDragDrop={handleStaffDrop}
        onDragEnd={handleDragEnd}
      />

      <RequestZone
        isLoading={recommendedHook.loading || userGroupsHook.loading}
        recommendedMembers={staffGroups?.[RECOMMENDED_ID]?.members ?? []}
        requestedMembers={requestedMembers.filter(
          // filter to avoid brief flash during'save'
          ({ id }) =>
            !pendingMemberIds.includes(id) && !declinedMemberIds.includes(id)
        )}
        pendingMembers={pendingMembers.filter(
          ({ id }) =>
            !assignedMemberIds.includes(id) && !unassignedMemberIds.includes(id)
        )}
        declinedMembers={declinedMembers.filter(
          ({ id }) => !assignedMemberIds.includes(id)
        )}
        hasFreeSpace={hasFreeSpace}
        isRequestAll={isRequestAll}
        isIncludeUnavailable={isIncludeUnavailable}
        canDragDrop={canDropMember}
        onDragHover={handleRequestHover}
        onDragDrop={handleRequest}
        onDragEnd={handleDragEnd}
        onRemoveMember={handleRequestedRemoved}
      />

      {canAssignStaff && (
        <AssignZone
          isLoading={recommendedHook.loading || userGroupsHook.loading}
          assignedMembers={assignedMembers.filter(
            // filter to avoid brief flash during'save'
            ({ id }) => !acceptedMemberIds.includes(id)
          )}
          acceptedMembers={acceptedMembers.filter(({ id }) => {
            return !(
              requestedMemberIds.includes(id) ||
              unassignedMemberIds.includes(id)
            );
          })}
          hasFreeSpace={hasFreeSpace}
          canDragDrop={canDropMember}
          onDragHover={handleAssignHover}
          onDragDrop={handleAssign}
          onDragEnd={handleDragEnd}
          onRemoveMember={handleAssignedRemoved}
        />
      )}
      <StyledWrapText>
        - For linked shifts, members will only be added to future shifts.
      </StyledWrapText>
      {snackMessage !== "" && (
        <RotaSnackBar
          snackOpen
          message={snackMessage}
          severity="warning"
          onClose={() => setSnackMessage("")}
        />
      )}

      <MemberAddWarningModal
        accountType={selectedAccountId ? "provider" : "requester"}
        roleRateId={roleRateId}
        dates={dates}
        warning={dragWarning}
        onClose={() => {
          setDragWarning(null);
        }}
      />

      {isShowCancellationModal && (
        <BookingReplacementModal
          onClose={() => {
            // continue member assignment without replacement
            handleMemberAssignDrop(droppedMemberId);
            setIsShowCancellationModal(false);
          }}
          onConfirm={({ cancelledBookingId }) => {
            handleConfirmCancellationReplacement({
              cancelledBookingId
            });
          }}
          shiftId={shiftId}
          replacementBookingIds={replacementBookings.map(
            ({ cancelledBookingId }) => cancelledBookingId
          )}
        />
      )}
    </>
  );
};

export default StaffZonesPanel;

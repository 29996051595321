import React from "react";
import Modal from "~/src/components/modal";
import Button from "~/src/components/button";
import {
  Container,
  Header,
  Text,
  ButtonContainer,
  StyledButton
} from "./styles";

export default props => (
  <Modal isOpen={props.isOpen} onClose={props.onClose} isOverlay>
    <Container>
      <div>
        <Header>
          You have {props.totalOutstandingTimesheets} timesheet
          {props.totalOutstandingTimesheets === 1 ? "" : "s"} to approve
        </Header>
        <Text hasMarginBottom>
          {props.isUnlocked
            ? "Previous week’s timesheets must be approved by 17:00 Monday. If they’re not approved, Rota will automatically approve them and process the shift as normal."
            : `In order to finalise shifts ready for payroll, please go to timesheets and approve`}
        </Text>
      </div>
      <ButtonContainer>
        <StyledButton isOutline onClick={props.onClose}>
          No, thanks
        </StyledButton>
        <Button isGreen onClick={props.onConfirm}>
          Go to timesheets
        </Button>
      </ButtonContainer>
    </Container>
  </Modal>
);

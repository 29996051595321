import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const CalendarHeaderBox = styled.div`
  border-bottom: ${({ theme }) => `solid 1px ${theme.neutral.lighter}`};
  display: flex;
`;

export const ToolBar = styled.div`
  cursor: pointer;
  display: table;
  margin-left: auto;
  margin-right: ${STYLE_CONSTS.UNIT_4};
`;

export const HeaderMonth = styled.span`
  background: ${({ theme }) => theme.neutral.lighter};
  border-radius: ${STYLE_CONSTS.BORDER_RADIUS_S};
  padding: ${STYLE_CONSTS.UNIT_HALF} ${STYLE_CONSTS.UNIT_1};
`;

export const HeaderItem = styled.span`
  font-size: ${STYLE_CONSTS.FONT};
  display: table-cell;
  height: ${STYLE_CONSTS.UNIT_8};
  vertical-align: middle;
  padding-left: ${STYLE_CONSTS.UNIT_2};
`;

export const HeaderItemLeft = styled(HeaderItem)`
  left: 14px;
  position: absolute;
  top: 18px;
`;

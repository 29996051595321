import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const Scrollable = styled.div`
  height: calc(100% - 100px);
  overflow-y: auto;
  overflow-x: auto;
`;

export const Wrapper = styled.div`
  height: 100%;
  position: relative;
`;

export const EditContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: ${STYLE_CONSTS.UNIT_2};
  & > div:nth-child(1) {
    padding-right: 20px;
  }
`;

export const EditItem = styled.div`
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
  text-align: right;
`;

export const EditCancelButtons = styled.div`
  justify-content: flex-end;
  padding: 20px 0;
  display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
`;

export const ErrorText = styled.div`
  color: ${({ theme }) => theme.error.main};
`;

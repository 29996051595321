import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

import { Role } from "@teamrota/authlib";

import {
  RotaButtonNew,
  RotaSearchInput,
  RotaButtonIcon,
  iconNew
} from "@teamrota/rota-design";

import useAuth from "~/src/auth/hooks/use-auth";
import HasRole from "~/src/containers/has-role";

import {
  Wrapper,
  HeaderWrapper,
  Box,
  Heading,
  Title,
  Actions,
  Subheader,
  SubheaderWrapper
} from "~/src/components/payroll/style";

import JsonEditModal from "../payroll/components/JsonEditModal";

import Table from "./Table";

import { useCreatePayrollRate } from "./graphql/use-create-payroll-rate";
import { useValidatePayrollRate } from "./graphql/use-validate-payroll-rate";
import { useUpdatePayrollRate } from "./graphql/use-update-payroll-rate";

import { usePayrollRates } from "./graphql/use-payroll-rates";

const { Filter, ChevronLeft } = iconNew;

const ExampleJsonInput = {
  name: "Name",
  config: [
    {
      name: "SATURDAY",
      factor: 1.5
    }
  ]
};

const PayRate = () => {
  const history = useHistory();
  const params = useParams() as any;
  const auth = useAuth();

  const canEdit = auth.hasRole(Role.PAYROLL_EDIT);

  const { createPayrollRate } = useCreatePayrollRate();
  const { validatePayrollRate } = useValidatePayrollRate();
  const { updatePayrollRate } = useUpdatePayrollRate();

  const { payrollRates } = usePayrollRates("");

  const [isShowCreateModal, setIsShowCreateModal] = useState(false);
  const [selectedOpenId, setSelectedOpenId] = useState<string>("");

  useEffect(() => {
    if (params.id !== undefined) {
      if (payrollRates.find((p: any) => p.id === params.id)) {
        setSelectedOpenId(params.id);
      }
    }
  }, [params, payrollRates]);

  const handleOpenRate = (id: string) => setSelectedOpenId(id);

  const handleClose = () => {
    setSelectedOpenId("");
    history.push("/payroll/pay-rate");
  };

  return (
    <Wrapper>
      <HeaderWrapper>
        <RotaSearchInput onChange={() => {}} />
        <HasRole role={Role.PAYROLL_EDIT}>
          <RotaButtonNew onClick={() => setIsShowCreateModal(true)}>
            Create
          </RotaButtonNew>
        </HasRole>
      </HeaderWrapper>
      <SubheaderWrapper>
        <RotaButtonIcon onClick={() => history.push("/payroll")}>
          <ChevronLeft />
        </RotaButtonIcon>
        <Subheader>Pay rate</Subheader>
      </SubheaderWrapper>
      <Box>
        <Heading>
          <Title>{`All ${payrollRates?.length} records`}</Title>
          <Actions>
            <RotaButtonIcon onClick={() => {}}>
              <Filter />
            </RotaButtonIcon>
          </Actions>
        </Heading>

        <Table
          payrollRates={payrollRates}
          handleOpenRate={handleOpenRate}
          canEdit={canEdit}
        />
      </Box>

      {isShowCreateModal && (
        <JsonEditModal
          canEdit={canEdit}
          onSave={async ({ name, config }) => {
            await createPayrollRate({ name, config });
            setIsShowCreateModal(false);
          }}
          onValidate={({ name, config }) =>
            validatePayrollRate({ name, config })
          }
          onClose={() => setIsShowCreateModal(false)}
          value={ExampleJsonInput}
          title="Create pay rates"
        />
      )}

      {selectedOpenId && (
        <JsonEditModal
          canEdit={canEdit}
          onSave={async ({ name, config }) => {
            await updatePayrollRate({ id: selectedOpenId, name, config });
            handleClose();
          }}
          onValidate={({ name, config }) =>
            validatePayrollRate({ name, config })
          }
          onClose={handleClose}
          value={payrollRates.find(
            ({ id }: { id: string }) => id === selectedOpenId
          )}
          title="Edit pay rates"
        />
      )}
    </Wrapper>
  );
};

export default PayRate;

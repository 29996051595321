import { gql, useQuery } from "@apollo/client";

const GET_CONSULTANTS = gql`
  query GetConsultants {
    account {
      id
      users {
        id
        firstName
        lastName
      }
    }
  }
`;

export const useConsultants = () => {
  const { loading, data, refetch } = useQuery(GET_CONSULTANTS);

  return {
    loading,
    consultants: data?.account?.users || [],
    refetchUsers: refetch
  };
};

import React from "react";
import { FormattedLog, Bullet, Highlight, Text } from "../../index.styles";

export default props => (
  <FormattedLog>
    <Bullet>&bull;</Bullet>
    <Text>added</Text>
    <Highlight>{props.label}</Highlight>
    <Text>as</Text>
    <Highlight>{props.newValue}</Highlight>
  </FormattedLog>
);

import { COLORS } from "~/src/styles/config.style";

const styles = {
  button: {
    width: 53,
    height: 35,
    borderRadius: 17.5,
    border: `1px solid ${COLORS.MAIN_ORANGE}`,
    display: "flex",
    flex: "1",
    justifyContent: "center",
    alignItems: "center"
  },
  container: {
    width: 170
  },
  roleNo: {
    color: `${COLORS.MAIN_ORANGE}`,
    marginLeft: 8,
    fontWeight: "bold"
  },
  expandArrow: {
    width: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 10
  },
  image: {
    width: 20
  },
  header: {
    color: "#5D5D5D",
    fontWeight: 500,
    marginBottom: 15,
    textAlign: "left",
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  text: {
    textAlign: "left",
    paddingBottom: 10
  },
  divider: {
    opacity: 0.1,
    marginTop: 10,
    marginBottom: 15
  },
  radioButtonContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "left",
    flexDirection: "column"
  },
  label: {
    display: "flex",
    textAlign: "left",
    paddingBottom: 10
  },
  input: {
    marginTop: 2,
    marginRight: 10
  },
  clear: {
    color: COLORS.MAIN_ORANGE,
    display: "flex",
    textAlign: "left",
    paddingBottom: 10,
    fontWeight: 500,
    cursor: "pointer"
  }
};

export default styles;

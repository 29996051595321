import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export default styled.div`
  background: ${STYLE_CONSTS.BACKGROUND_GRADIENT};
  border-radius: ${STYLE_CONSTS.BORDER_RADIUS_L};
  position: absolute;
  top: 0;
  left: 0;
  width: ${(1 / 7) * 100}%;
  transform: ${({ transformX, transformY }) =>
    `translate(${transformX}px, ${transformY}px)`};
  height: ${({ height }) => `${height}px`};
`;

import styled from "styled-components";
import { COLORS, STYLE_CONSTS } from "~/src/styles/config.style";

export const Container = styled.div`
  max-width: 960px;
  width: 90%;
  margin: 0 auto;
  padding: ${STYLE_CONSTS.UNIT_4} 0;
`;

export const Title = styled.h1`
  border-bottom: ${STYLE_CONSTS.SMALL_DIVIDER_BORDER};
  padding: ${STYLE_CONSTS.UNIT_4} 0;
`;

export const Body = styled.p`
  margin-bottom: ${STYLE_CONSTS.UNIT_6};
`;

export const SubHeader = styled.div`
  font-size: ${STYLE_CONSTS.FONT_SMALL};
  color: ${COLORS.CLOUDY_BLUE};
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  bottom: ${STYLE_CONSTS.UNIT_4};
  left: 50%;
  margin-left: -${STYLE_CONSTS.BTN_MIN_WIDTH_M};
`;

import styled from "styled-components";

export const Styled_Container = styled.div`
  height: 20px;
  width: 100%;
  background-color: #e0e0de;
  border-radius: 20px;
  margin: 10px;
`;

export const Styled_Filler = styled.div`
  height: 100%;
  width: ${({ completed }) => `${completed}%`};
  background-color: ${({ theme }) => theme.primary.main};
  transition: width 1s ease-in-out;
  border-radius: 20px;
  text-align: right;
`;

export const Styled_Label = styled.span`
  padding: 5;
  color: transparent;
  font-weight: bold;
`;

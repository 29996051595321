import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import Icon from "~/src/components/icon";
import { MEMBER_STATES } from "~/src/consts";
import { POOL_TYPES } from "@teamrota/rota-common";
import {
  StyledImage,
  IconContainer,
  IconWrapper,
  sizes,
  Status,
  ErrorOverlay
} from "./member-photo.styles";

export const tooltipText = {
  [MEMBER_STATES.ACTIVE]: "Active",
  [MEMBER_STATES.REMOVED]: "Removed",
  [MEMBER_STATES.LOCKED]: "Locked",
  [MEMBER_STATES.GOOD_LEAVER]: "Good Leaver",
  [MEMBER_STATES.PENDING]: "Pending",
  [MEMBER_STATES.REJECTED]: "Rejected"
};

export const asleepText = "Asleep";

const heart = size => (
  <IconWrapper size={size} icon="heart">
    <Icon
      name={Icon.names.FAVOURITE_HEART}
      color={Icon.colors.WHITE}
      size={sizes[size].iconSize}
    />
  </IconWrapper>
);

const menu = (size, callback = () => {}) => (
  <IconContainer onClick={callback} poolType="none" size={size}>
    <IconWrapper icon="info" shouldRotate size={size}>
      <Icon
        name={Icon.names.MORE}
        color={Icon.colors.WHITE}
        size={sizes[size].iconSize}
      />
    </IconWrapper>
  </IconContainer>
);

const poolTypeIcon = (poolType, size) => (
  <IconContainer poolType={poolType} size={size}>
    {poolType === POOL_TYPES.FAVOURITED ? heart(size) : null}
  </IconContainer>
);

const tooltip = (isShowTooltip, isAsleep, status) => {
  const tooltipId = `${Math.random() * 10000000}-status-tooltip`;
  const shouldShowAsleep = isAsleep && status === MEMBER_STATES.ACTIVE;

  return (
    <span>
      {isShowTooltip && (
        <ReactTooltip id={tooltipId} effect="solid">
          {shouldShowAsleep ? asleepText : tooltipText[status]}
        </ReactTooltip>
      )}

      <Status
        status={status}
        data-tip
        data-for={tooltipId}
        isAsleep={shouldShowAsleep}
      />
    </span>
  );
};

export default ({
  src: defaultSrc,
  poolType,
  size = "small",
  isInline,
  status,
  isAsleep,
  actionCallback,
  errorLabelColor,
  className,
  errorLabel,
  isBigLabel,
  isShowTooltip = true
}) => {
  const [src, setSrc] = useState(null);

  //  check if the avatar exists before using it

  useEffect(() => {
    if (defaultSrc?.length > 0) {
      const image = new Image();
      image.src = defaultSrc;
      image.onload = image.onerror = () => {
        setSrc(image.width ? defaultSrc : null);
      };
    } else {
      setSrc(null);
    }
  }, [defaultSrc]);

  return (
    <StyledImage
      className={className}
      src={src}
      alt="member"
      size={size}
      isInline={isInline}
      title={errorLabel}
    >
      {errorLabel && (
        <ErrorOverlay
          errorLabelColor={errorLabelColor}
          size={size}
          isBigLabel={isBigLabel}
        >
          {errorLabel}
        </ErrorOverlay>
      )}
      {status && tooltip(isShowTooltip, isAsleep, status)}
      {poolType &&
        poolType !== POOL_TYPES.RECOMMENDED &&
        poolTypeIcon(poolType, size)}
      {actionCallback && menu(size, actionCallback)}
    </StyledImage>
  );
};

import React, { useState } from "react";

import { TIMESHEET_STATUS } from "@teamrota/rota-common";

import {
  InnerCell,
  ModalHeader,
  RotaButton,
  RotaNewModal,
  RotaTable,
  TBody,
  THead,
  Td,
  Th,
  Tr,
  TrHead,
  iconNew
} from "@teamrota/rota-design";

import Icon from "~/src/components/icon";
import { formatDateFull } from "~/src/utils/formatting";

import IconLink from "../IconLink";
import Money from "../Money";
import OutputPanel from "../OutputPanel";
import RuleDescription from "../RuleDescription";

import { usePayrollHistory } from "./graphql/use-payroll-history";
import { useRecalculatePayAndCharge } from "./graphql/use-recalculate";

import {
  ContentArea,
  Error,
  ErrorButtons,
  ErrorPanel,
  OptionsButton,
  Recalculating,
  Status
} from "./styles";

const { Cancel } = iconNew;

interface SimulatorModalProps {
  id: string;
  payrollRateCards: any[];
  onClose: () => void;
}

const HistoryModal: React.FC<SimulatorModalProps> = ({
  id,
  payrollRateCards,
  onClose
}) => {
  const { history } = usePayrollHistory(id);
  const { recalculatePayAndCharge } = useRecalculatePayAndCharge();

  const [openedId, setOpenedId] = useState<any>(null);

  const opened =
    openedId && history.find((entry: any) => entry.id === openedId);

  return (
    <>
      <RotaNewModal
        onClose={onClose}
        header={
          <ModalHeader
            title={"Pay History"}
            endAction={
              <OptionsButton onClick={onClose}>
                <Cancel />
              </OptionsButton>
            }
          />
        }
      >
        <RuleDescription id={id} payrollRateCards={payrollRateCards} />

        <ContentArea>
          <h2>
            {history?.length ? `At least ${history.length}` : "No"} records were
            calculated using this Rate Card
          </h2>

          {history?.length > 0 && (
            <RotaTable>
              <THead>
                <TrHead>
                  <Th>Status</Th>
                  <Th>ID</Th>
                  <Th>Timesheet</Th>
                  <Th>Shift</Th>
                  <Th>Member</Th>
                  <Th>Total Pay</Th>
                  <Th>Total Charge</Th>
                  <Th>Created</Th>
                  <Th>Updated</Th>
                  <Th>&nbsp;</Th>
                </TrHead>
              </THead>

              <TBody>
                {history?.map((entry: any) => {
                  let status = <Status className="success" />;

                  if (entry.recalculatePayAndCharge) {
                    status = (
                      <Status
                        className="recalculating"
                        title="Recalculation is scheduled"
                      />
                    );
                  } else if (entry.error) {
                    status = (
                      <Status
                        className="error"
                        title="Calculation error occurred"
                      />
                    );
                  }

                  return (
                    <Tr key={entry.id}>
                      <Td>
                        <InnerCell>{status}</InnerCell>
                      </Td>
                      <Td>
                        <InnerCell>{entry.id}</InnerCell>
                      </Td>
                      <Td>
                        <InnerCell>{entry.timesheetId}</InnerCell>
                      </Td>
                      <Td>
                        <InnerCell>
                          {entry.shiftId !== null ? entry.shiftId : "Quick Add"}
                        </InnerCell>
                      </Td>
                      <Td>
                        <InnerCell>{entry.memberId}</InnerCell>
                      </Td>
                      <Td>
                        <InnerCell>
                          <Money value={entry.totalPay} />
                        </InnerCell>
                      </Td>
                      <Td>
                        <InnerCell>
                          <Money value={entry.totalCharge} />
                        </InnerCell>
                      </Td>
                      <Td>
                        <InnerCell>{formatDateFull(entry.createdAt)}</InnerCell>
                      </Td>
                      <Td>
                        <InnerCell>
                          {formatDateFull(entry.updatedAt ?? entry.createdAt)}
                        </InnerCell>
                      </Td>
                      <Td>
                        <InnerCell>
                          <IconLink
                            name="VISIBLE"
                            size={25}
                            isButton
                            color={Icon.colors.MAIN_ORANGE}
                            onClick={() => setOpenedId(entry.id)}
                          />

                          <IconLink
                            name={
                              entry.status === TIMESHEET_STATUS.LOCKED
                                ? "LOCK"
                                : "REFRESH"
                            }
                            size={25}
                            isButton
                            isDisabled={
                              entry.status === TIMESHEET_STATUS.LOCKED ||
                              entry.recalculatePayAndCharge
                            }
                            color={Icon.colors.MAIN_ORANGE}
                            onClick={() =>
                              recalculatePayAndCharge({ id: entry.id })
                            }
                          />
                        </InnerCell>
                      </Td>
                    </Tr>
                  );
                })}
              </TBody>
            </RotaTable>
          )}
        </ContentArea>
      </RotaNewModal>

      {opened && (
        <RotaNewModal
          size={opened.error ? "medium" : "large"}
          onClose={onClose}
          header={
            <ModalHeader
              title={"Calculation Details"}
              subtitle={`Timesheet Member Row ${openedId}`}
              endAction={
                <OptionsButton onClick={() => setOpenedId(null)}>
                  <Cancel />
                </OptionsButton>
              }
            />
          }
        >
          <ContentArea>
            {opened.recalculatePayAndCharge && (
              <Recalculating>
                <h1>Recalculation is scheduled</h1>
              </Recalculating>
            )}

            {opened.error !== null && (
              <ErrorPanel>
                <Error>
                  <h1>{opened.error?.type}</h1>
                  <p>{opened.error?.message}</p>
                </Error>

                <ErrorButtons>
                  <RotaButton
                    disabled={opened.recalculatePayAndCharge}
                    onClick={() => {
                      recalculatePayAndCharge({ id: opened.id });
                      setOpenedId(null);
                    }}
                  >
                    Recalculate
                  </RotaButton>
                </ErrorButtons>
              </ErrorPanel>
            )}

            {opened.error === null && (
              <OutputPanel
                id={id}
                payrollRateCards={payrollRateCards}
                input={opened.input}
                blocks={opened.blocks}
                extras={opened.extras}
                facts={opened.facts}
                totals={opened.totals}
              />
            )}
          </ContentArea>
        </RotaNewModal>
      )}
    </>
  );
};

export default HistoryModal;

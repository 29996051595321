import React from "react";
import { DragPreviewImage, useDrag } from "react-dnd";
import { DND_ITEM_TYPES } from "~/src/consts";

import dragPreviewOverride from "./preview";

import styles, {
  StyledDisabledOverlay,
  StyledWrapImage,
  StyledWrapText,
  StyledWrapContainer,
  StyledRemoveIcon,
  StyledName
} from "./styles";

const DraggableMember = ({
  id,
  memberIds,
  name,
  isDisabled,
  isDragDisabled,
  onDragEnd,
  onRemove,
  showMemberModal,
  children
}) => {
  const [, drag, preview] = useDrag(() => ({
    type: DND_ITEM_TYPES.MEMBER,
    item: () => {
      return { id: memberIds || id };
    },
    canDrag: () => !isDragDisabled,
    end: () => {
      onDragEnd?.();
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  }));

  return (
    <>
      <DragPreviewImage connect={preview} src={dragPreviewOverride} />
      <div ref={drag} style={isDisabled ? styles.cardDisabled : styles.card}>
        {isDisabled && <StyledDisabledOverlay />}

        {!isDisabled && onRemove && (
          <StyledRemoveIcon onClick={() => onRemove(id)} />
        )}

        <div onClick={() => id && showMemberModal(id)}>
          <StyledWrapContainer>
            <StyledWrapImage>{children}</StyledWrapImage>
          </StyledWrapContainer>

          <StyledWrapText>
            <StyledName>{name}</StyledName>
          </StyledWrapText>
        </div>
      </div>
    </>
  );
};

export default DraggableMember;
